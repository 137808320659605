import { Location, LocationStrategy } from "@angular/common";
import { Compiler, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { globalConstants } from 'src/environments/globalConstants';
import { ConfigServiceService } from "../../../../app/config-service.service";
import { ShareLoginDataService } from "../../../data/share-login-data.service";
import { NavbarComponent } from "../../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../../login/login-data.service";
import { LoginComponent } from "../../../login/login.component";
import { LoginService } from "../../../services/login/login.service";
import { RegisterService } from "../../../services/register/register.service";
import { SuperadminService } from "../../../services/superadmin/superadmin.service";
declare var jQuery: any;
@Component({
  selector: 'app-customer-report-handler',
  templateUrl: './customer-report-handler.component.html',
  styleUrls: ['./customer-report-handler.component.css']
})
export class CustomerReportHandlerComponent implements OnInit {
  loginComponent: LoginComponent;
  Logindetails: any;
  userName: [];
  orgType: string;
  loginUserId: string;
  customerReportsTab: string = "active";
  anchors: any;
  editicons: any;
  rootcauseshareshow: boolean = false;
  userMail='';
  cust_reportId:any;
  MailreportId:any;
  isChecked:boolean=true;
  isProcessingClick = false;
  cust_report:any;
  report_Id:any;
  isVerifyingawb:any;
  nav:any;
  link:any;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  public static _TOTAL_GRIDDATA = [];
  public static TOTAL_CUST_GRIDDATA = [];
  constructor(
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private atp: AmazingTimePickerService,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public toast: ToastrService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private SidebarComponent: SidebarComponent,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private config: ConfigServiceService,
    private elementRef: ElementRef,
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
   }
  ngOnInit() {
    window.scroll(0, 0);
    this.spinner.show();
    this.Logindetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.loginUserId = this.Logindetails.userId;
    var orgId = this.Logindetails.orgId;
    this.orgType = this.Logindetails.orgType;
    this.spinner.hide();
  }
  
  onClickDamage_Reports() {
    this.spinner.show();
    setTimeout(() => {
        
        localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
        if (this.Logindetails.userType == 'S' && this.Logindetails.orgType == 'H') {
        this.router.navigateByUrl('/csa-dashboard');
        }
        else if (this.Logindetails.userType == 'S' && this.Logindetails.orgType == 'A') {
        this.router.navigateByUrl('/csa-airline-dashboard');
        }
        else if (this.Logindetails.userType == 'A' && this.Logindetails.orgType == 'A') {
        this.router.navigateByUrl('/admin-airline-dashboard');
        }
        else if (this.Logindetails.userType == 'A' && this.Logindetails.orgType == 'H') {
        this.router.navigateByUrl('/admin-handler-dashboard');
        }
        else if (this.Logindetails.userType == 'E') {
        this.router.navigateByUrl('/user-dashboard');
        }else if (this.Logindetails.orgType == 'E') {
        this.router.navigateByUrl('/HandlerDashboardComponent');
        }else{
        localStorage.clear()
        this.router.navigateByUrl('/Home');
        }
    }, 200);
  }

  onClickUldDamage_Reports() {
    localStorage.setItem("currentModule", JSON.stringify("ULD_DAMAGE"));
    this.router.navigateByUrl("/Uld/viewuld");
  }

  onClick_CargoClaim() {
    localStorage.setItem("currentModule", JSON.stringify("cargoClaim"));
    this.router.navigateByUrl("/cargoClaim/dashboard");
  }

  onClick_AirlineCreateRecovery() {
    console.log("onClick_AirlineCreateRecovery");
    localStorage.setItem("currentModule", JSON.stringify("addServiceRecovery"));
    this.router.navigateByUrl("/addservicerecovery");
  }
  onClick_CargoServiceRecovery() {
    localStorage.setItem(
      "currentModule",
      JSON.stringify("csa-service-recovery")
    );
    this.router.navigateByUrl("/csa-service-recovery");
  }

  onClick_Create_Customer_Report() {
    this.router.navigate(["/customer-report"]);
  }
  

  fn_OnTab_Changes_customer_report(tabName='') {
    this.customerReportsTab = tabName;
  }

//====================== for edit grid=======================
ngAfterViewChecked() {
  this.anchors = this.elementRef.nativeElement.querySelectorAll("a");
  this.editicons = this.elementRef.nativeElement.querySelectorAll("i");

  this.editicons.forEach((editicon: HTMLImageElement) => {
    if (editicon.id != "") {
      let href_Split_Array = [];
      href_Split_Array = editicon.id.split(":");
      let href_Valid_Check_Var = href_Split_Array[0];

      if (href_Valid_Check_Var === "edit") {
        editicon.addEventListener("click", this.handleiconClick);
      }
      if (href_Valid_Check_Var === "crpedit") {
        editicon.addEventListener("click", this.handleiconClick);
      }
    }
  });
}

public handleiconClick = (event: Event) => {
  event.preventDefault();
  const editicon = event.target as HTMLImageElement;
  this.fn_ClickIcon(editicon, event);
};

fn_ClickIcon(editicon, event) {
  let href_Split_Array = [];
  href_Split_Array = editicon.id.split(":");
  let href_Valid_Check_Var = href_Split_Array[1];
  if (href_Split_Array[0] === "crpedit") {
    console.log(
      "443 crpedit",
      href_Split_Array[1] + " " + href_Split_Array[2]
    );
    this.router.navigate(["/customer-report"], {
      queryParams: {
        id: href_Split_Array[1],
        report_id: href_Split_Array[2],
      },
    });
  }
}

//======================end edit=============================


  // customer service report grid for handler | start

  serviceReportSource: any = {
      
    datatype: "json",
    datafields: [
      { name: "createdon", type: "date" },
      { name: "id", type: "string" },
      { name: "customer_report_id", type: "string" },
      { name: "shipper", type: "string" },
      { name: "consignee", type: "string" },
      { name: "type_of_incident", type: "string" },
      { name: "incident_date", type: "date" },
      { name: "incident_time", type: "time" },
      { name: "station_code", type: "string" },
      { name: "notified_dt", type:"string"},
      { name: "master_awb_no", type: "string" },
      { name: "airline_name", type: "string" },
      { name: "flight_truck_no", type: "string" },
      { name: "flight_date", type: "string" },
      { name: "flight_details", type: "string" },
      { name: "damage_discovered", type: "string" },
      { name: "report", type: "string" },
      { name: "status", type: "string" },
      { name: "notified", type: "string" },
      { name: "is_address_email", type: "string" },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "data",
    totalrecords:100,
  cache: false,
    url:this.config.getConfig().URL +
    "customerService/customerServicePortal.php/getCustomerServiceByHandlerData?loginUserId="+
    JSON.parse(localStorage.getItem("loginDetails")).userId +
    "&orgId="+JSON.parse(localStorage.getItem("loginDetails")).orgId +
    "&orgType="+JSON.parse(localStorage.getItem("loginDetails")).orgType+
    "&orderType=A"+"&loginUserType="+JSON.parse(localStorage.getItem("loginDetails")).userType,
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata('filter');
    },
  beforeprocessing: (data: any) => {
    this.serviceReportSource.totalrecords = data.totalData;
    data.Rows = data.data;
    CustomerReportHandlerComponent._TOTAL_GRIDDATA = CustomerReportHandlerComponent._TOTAL_GRIDDATA.concat(data.data) ;
},
pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
  // callback called when a page or page size is changed.
  console.log(pagenum);
},
    sortcolumn: "createdon",
    sortdirection: "desc",
  };
  
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "100%";
    } else if (document.body.offsetWidth > 1400) {
      return "100%";
    }
    return "100%";
  }

  dataAdapter: any = new jqx.dataAdapter(this.serviceReportSource);

  columns: any[] = [
     {
        text: "Actions",
        datafield: "id",
        cellsalign: "center",
        filterable: false,
        align: "center",
        sortable: false,
        groupable: false,
        width: 60,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          columndata
        ) => {
          return "<div class='jq-actions' style='margin-left: 5px;'><a  href='crpedit:" +
            value +
            ":" +
            columndata.customer_report_id +
            "'><i id = 'crpedit:" +
            value +
            ":" +
            columndata.customer_report_id +
            "' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>"
          
        },
      },
    {
      text: "Status",
      datafield: "status",
      align: "center",
      filtertype: "list",
      filteritems: ["New","In Progress","Completed"],
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
        let statusText = '';
        if (value == 1) {
            statusText = 'New';
        } else if (value == 2) {
            statusText = 'In Progress';
        } else if (value == 3) {
            statusText = 'Completed';
        }
        return `<div style="text-align: center; margin-top: 8px;">${statusText}</div>`;
      },
      width: 130,
    },
    {
      text: "Report",
      datafield: "report",
      align: "center",
      width: 50,
      filterable: false,
      sortable: false,
      groupable: false,
  
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) => {
       // console.log("grid comulm 310 ==>",columndata);
        // return("<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href="+this.config.getConfig().URL +"/customerService/customerServicePortalPdf.php/getCustomerServicePdfCreate?id="+columndata.id+"&customer_report_id="+columndata.customer_report_id+"><i id = '' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>");
        return "<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href=" +
          this.config.getConfig().URL +
          "/customerService/customerServicePortal.php/getCustomerServicePdfCreate?id=" +
          columndata.id +
          "&customer_report_id=" +
          columndata.customer_report_id +
          "&org_id=" +
          JSON.parse(localStorage.getItem("loginDetails")).orgId+
          "> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;align:center;'></a></div>"
        ;
      },
    },
    {
      text: "Email",
      datafield: "notified",
      align: "center",
      width: 50,
      sortable: false,
      filterable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let icon = value == '1' ? 'fa fa-bell' : 'fa fa-bell-slash';
        let color = value == '1' ? '#28a745' : '#ffc107';
        const mailButtonId = `mailButton-${row}-${columnfield}`;
        const bellButtonId = `bellButton-${row}-${columnfield}`;
    
        let mailIconHtml;
        let bellIconHtml;
    
        if (rowdata.is_address_email === '') {
          mailIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
          bellIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
        } else {
          mailIconHtml = `<img id="${mailButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
          bellIconHtml = `<img id="${bellButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
        }
    
        setTimeout(() => {
          const mailIconElement = document.getElementById(mailButtonId);
          const bellIconElement = document.getElementById(bellButtonId);
          if (mailIconElement) {
            mailIconElement.addEventListener('click', function() {
              this.handleMailClick(rowdata);
            }.bind(this));
          }
          if (bellIconElement) {
            bellIconElement.addEventListener('click', function() {
              this.handleMailClick(rowdata);
            }.bind(this));
          }
        });
    
        let text;
        if (rowdata.is_address_email === '' && !rowdata.notified_dt) {
          text = 'Not Notified';
        } else if (rowdata.is_address_email !== '' && !rowdata.notified_dt) {
          text = 'Send Email';
        } else if (rowdata.is_address_email === '' && rowdata.notified_dt) {
          text = 'Notified';
        } else if (rowdata.is_address_email !== '' && rowdata.notified_dt) {
          text = 'Email Sent';
        }
    
        return `<div style="text-align: left; margin-top: 5px; margin-left: 10px;">
                  ${bellIconHtml}
                  
                </div>`;
                // <strong>
                //   ${text}
                //   </strong>
      }
    },
    //===== 21062024=============================================
    {
      text: "Agent Notified",
      datafield: "",
      cellsalign: "center",
      align: "center",
      filterable: false,
      width: 130,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        if(rowdata.notified == '1'){
          return `<div style="text-align: left; line-height:30px;margin-left: 11px;">
                   
                     <span> Notified</span>
                  </div>`;
        }else if(rowdata.notified == '0'){
          return `<div style="text-align: left; line-height:30px;margin-left: 11px;">
                   
                     <span> Not Notified</span>
                  </div>`;
        }
    
    
      }
    },
    //===============21062024 |end==============================
    {
      text: "Notified Date",
      datafield: "notified_dt",
      align: "center",
      filterable: false,
      cellsalign: "center",
      width: 150,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        if (!value && rowdata.is_address_email === '') {
          const calendarIconId = `calendar-icon-${row}`;
    
          setTimeout(() => {
            const calendarIconElement = document.getElementById(calendarIconId);
            if (calendarIconElement) {
              calendarIconElement.addEventListener('click', () => {
                this.handleCalendarClick(rowdata);
              });
            }
          }, 0);
    
          return `<div style="text-align: center;">
                    <i id="${calendarIconId}" class='fa fa-calendar' aria-hidden='true' style='cursor: pointer;' title='Pick a date'></i>
                  </div>`;
        } else if (!value) {
          return `<div style="text-align: center;"></div>`;
        } else {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return `<div style="text-align: center;"></div>`;
          }
          const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
          const editIconId = `edit-icon-${row}`;
    
          if (rowdata.is_address_email === '') {
            setTimeout(() => {
              const editIconElement = document.getElementById(editIconId);
              if (editIconElement) {
                editIconElement.addEventListener('click', () => {
                  this.handleEditClick(rowdata);
                });
              }
            }, 0);
    
            return `<div style="line-height: 30px;display: flex;justify-content: space-around;align-items: center;">
                      ${formattedDate}
                      <i id="${editIconId}" class='fa fa-edit' aria-hidden='true' style='cursor: pointer; margin-left: 15px;margin-top: 3px;' title='Edit date'></i>
                    </div>`;
          } else {
            return `<div style="text-align: left;line-height:30px;margin-left: 11px;">${formattedDate}</div>`;
          }
        }
      }
    },
    {
      text: "AWB Number",
      datafield: "master_awb_no",
      align: "center",
      cellsalign: "center",
      width: 150,
    },
   
    {
      text: "Flight No",
      datafield: "flight_truck_no",
      align: "center",
      cellsalign: "center",
      width: 130,
    },
    // { text: 'Flight Details', datafield: 'flight_details', align: 'center',width: 150 },
    // { text: 'Flight Date', datafield: 'flight_date', align: 'center' },
    { text: "Station", datafield: "station_code",   cellsalign: "center", align: "center", width: 80 },
    // {
    //   text: "Incident Date",
    //   datafield: "incident_date",
    //   cellsalign: "right",
    //   width: 120,
    // },
    {
      text: "Incident Date",
      datafield: "incident_date",
      cellsalign: "center",
      width: 120,
      cellsrenderer: (row, columnfield, value) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
        return `<div style="text-align: left;margin-left: 11px;line-height: 30px;">${formattedDate}</div>`;
      }
    },
    // {
    //   text: "Incident Time",
    //   datafield: "incident_time",
    //   align: "center",
    //   width: 80,
    // },
    {
      text: "Shipper",
      datafield: "shipper",
      cellsalign: "center",
      align: "center",
      width: 200,
    },
    {
      text: "Consignee",
      datafield: "consignee",
      cellsalign: "center",
      align: "center",
      width: 150,
    },
    {
      text: "Incident Type",
      datafield: "type_of_incident",
      cellsalign: "center",
      cellsformat: "c2",
      align: "center",
      textAlign:"center",
      width: 250,
    },
    // {
    //   text: "Damage Discover",
    //   datafield: "damage_discovered",
    //   align: "center",
    // },
  ];
  rendergridrows2 = (params: any): any => {
    params.data.endindex=150
    return params.data;
  }
  

   handleCalendarClick(rowdata) {
    console.log('Calendar icon clicked for row:', rowdata);

     this.cust_report =rowdata.customer_report_id;
    this.report_Id= rowdata.id;

   // jQuery("#datepicker").modal({ backdrop: "static", keyboard: false });
   jQuery("#cnfirmArchive").modal({ backdrop: "static", keyboard: false });
  }
  confirm(){
    jQuery("#cnfirmArchive").modal('hide');
    jQuery("#datepicker").modal({ backdrop: "static", keyboard: false });
  }


  submitDate(){
    const inputElement = (document.getElementById("ddd") as HTMLInputElement).value;
    console.log("Input Element:", inputElement);

    let id=JSON.parse(localStorage.getItem("loginDetails")).userId;
    let orgId = JSON.parse(localStorage.getItem("loginDetails")).orgId;
    let orgtype =JSON.parse(localStorage.getItem("loginDetails")).orgId;
    if(inputElement==""){
      this.toastr.error("please select date");
      return false;
    }
   var payload={
      "reportIds":this.report_Id,
      "customerReportIds": this.cust_report,
      "loginUserId":id,
      "loginOrgid": orgId,
      "loginOrgType": JSON.parse(localStorage.getItem("loginDetails")).orgType,
      "date":inputElement
    }
    console.log("payload==>",payload);
    //return false;
  this.superAdminservice.updateDateMannully(payload).subscribe(
    (data) => {
      console.log("res===>", data);
      this.reloadCurrentRoute();
      if(data.responseCode==1){
        this.toastr.success(data.message);
      }else{
        this.toastr.error(data.message);
      }
      jQuery('#datepicker').modal('hide');
     
     
    },
    (error) => {}
  );
  }

  handleEditClick(rowdata){
    console.log('Calendar icon clicked for row:', rowdata);
    this.cust_report =rowdata.customer_report_id;
    this.report_Id= rowdata.id;

    jQuery("#datepicker").modal({ backdrop: "static", keyboard: false });
  }
  
  // ================================== grid for archived grid in handler |start===================================

  serviceReportSource2: any = {
      
    datatype: "json",
    datafields: [
      { name: "createdon", type: "date" },
      { name: "id", type: "string" },
      { name: "customer_report_id", type: "string" },
      { name: "shipper", type: "string" },
      { name: "consignee", type: "string" },
      { name: "type_of_incident", type: "string" },
      { name: "incident_date", type: "date" },
      { name: "incident_time", type: "time" },
      { name: "station_code", type: "string" },
      { name: "notified_dt", type:"string"},
      { name: "master_awb_no", type: "string" },
      { name: "airline_name", type: "string" },
      { name: "flight_truck_no", type: "string" },
      { name: "flight_date", type: "string" },
      { name: "flight_details", type: "string" },
      { name: "damage_discovered", type: "string" },
      { name: "report", type: "string" },
      { name: "status", type: "string" },
      { name: "notified", type: "string" },
      { name: "is_address_email", type: "string" },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "archivedData",
    totalrecords:100,
    cache: false,
    url:this.config.getConfig().URL +
    "customerService/customerServicePortal.php/getCustomerServiceByHandlerArchivedData?loginUserId="+
    JSON.parse(localStorage.getItem("loginDetails")).userId +
    "&orgId="+JSON.parse(localStorage.getItem("loginDetails")).orgId +
    "&orgType="+JSON.parse(localStorage.getItem("loginDetails")).orgType+
    "&orderType=A"+"&loginUserType="+JSON.parse(localStorage.getItem("loginDetails")).userType,
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata('filter');
    },
  beforeprocessing: (data: any) => {
    CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA = CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA.concat(data.archivedData) ;
   this.serviceReportSource2.totalrecords = data.totalArchivedData;
   data.Rows = data.archivedData;
},
pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
  // callback called when a page or page size is changed.
  console.log(pagenum);
},
    sortcolumn: "createdon",
    sortdirection: "desc",
  };
  

  dataAdapter2: any = new jqx.dataAdapter(this.serviceReportSource2);

  columns2: any[] = [
    {
      text: "Actions",
      datafield: "id",
      cellsalign: "center",
      filterable: false,
      align: "center",
      sortable: false,
      groupable: false,
      width: 60,
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) => {
        return "<div class='jq-actions' style='margin-left: 5px;'><a  href='crpedit:" +
          value +
          ":" +
          columndata.customer_report_id +
          "'><i id = 'crpedit:" +
          value +
          ":" +
          columndata.customer_report_id +
          "' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>"
        ;
      },
    },
    {
      text: "Status",
      datafield: "status",
      align: "center",
      filtertype: "list",
      filteritems: ["New","In Progress","Completed"],
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
        let statusText = '';
        if (value == 1) {
            statusText = 'New';
        } else if (value == 2) {
            statusText = 'In Progress';
        } else if (value == 3) {
            statusText = 'Completed';
        }
        return `<div style="text-align: center; margin-top: 8px;">${statusText}</div>`;
      },
      width: 130,
    },
    {
      text: "Report",
      datafield: "report",
      align: "center",
      width: 50,
      filterable: false,
      sortable: false,
      groupable: false,

      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) => {
        // return("<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href="+this.config.getConfig().URL +"/customerService/customerServicePortalPdf.php/getCustomerServicePdfCreate?id="+columndata.id+"&customer_report_id="+columndata.customer_report_id+"><i id = '' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>");
        return "<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href=" +
          this.config.getConfig().URL +
          "/customerService/customerServicePortal.php/getCustomerServicePdfCreate?id=" +
          columndata.id +
          "&customer_report_id=" +
          columndata.customer_report_id +
          "&org_id="+JSON.parse(localStorage.getItem("loginDetails")).orgId+
          "> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;align-center'></a></div>";
      },
    },
    {
      text: "Email",
      datafield: "notified",
      align: "center",
      width: 50,
      sortable: false,
      filterable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let icon = value == '1' ? 'fa fa-bell' : 'fa fa-bell-slash';
        let color = value == '1' ? '#28a745' : '#ffc107';
        const mailButtonId = `mailButton-${row}-${columnfield}`;
        const bellButtonId = `bellButton-${row}-${columnfield}`;
    
        let mailIconHtml;
        let bellIconHtml;
    
        if (rowdata.is_address_email === '') {
          mailIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
          bellIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
        } else {
          mailIconHtml = `<img id="${mailButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
          bellIconHtml = `<img id="${bellButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
        }
    
        setTimeout(() => {
          const mailIconElement = document.getElementById(mailButtonId);
          const bellIconElement = document.getElementById(bellButtonId);
          if (mailIconElement) {
            mailIconElement.addEventListener('click', function() {
              this.handleMailClick(rowdata);
            }.bind(this));
          }
          if (bellIconElement) {
            bellIconElement.addEventListener('click', function() {
              this.handleMailClick(rowdata);
            }.bind(this));
          }
        });
    
        let text;
        if (rowdata.is_address_email === '' && !rowdata.notified_dt) {
          text = 'Not Notified';
        } else if (rowdata.is_address_email !== '' && !rowdata.notified_dt) {
          text = 'Send Email';
        } else if (rowdata.is_address_email === '' && rowdata.notified_dt) {
          text = 'Notified';
        } else if (rowdata.is_address_email !== '' && rowdata.notified_dt) {
          text = 'Email Sent';
        }
    
        return `<div style="text-align: left; margin-top: 5px; margin-left: 10px;">
                  ${bellIconHtml}
                  
                </div>`;
                // <strong>
                //   ${text}
                //   </strong>
      }
    },
    //===== 21062024=============================================
    {
      text: "Agent Notified",
      datafield: "",
      cellsalign: "center",
      filterable: false,
      align: "center",
      width: 130,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        if(rowdata.notified == '1'){
          return `<div style="text-align: left; line-height:30px;margin-left: 11px;">
                   
                     <span> Notified</span>
                  </div>`;
        }else if(rowdata.notified == '0'){
          return `<div style="text-align: left; line-height:30px;margin-left: 11px;">
                   
                     <span> Not Notified</span>
                  </div>`;
        }
    
    
      }
    },
    //===============21062024 |end==============================

    {
      text: "Notified Date",
      datafield: "notified_dt",
      filterable: false,
      align: "center",
      cellsalign: "center",
      width: 150,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        if (!value && rowdata.is_address_email === '') {
          const calendarIconId = `calendar-icon-${row}`;
    
          setTimeout(() => {
            const calendarIconElement = document.getElementById(calendarIconId);
            if (calendarIconElement) {
              calendarIconElement.addEventListener('click', () => {
                this.handleCalendarClick(rowdata);
              });
            }
          }, 0);
    
          return `<div style="text-align: center;">
                    <i id="${calendarIconId}" class='fa fa-calendar' aria-hidden='true' style='cursor: pointer;' title='Pick a date'></i>
                  </div>`;
        } else if (!value) {
          return `<div style="text-align: center;"></div>`;
        } else {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return `<div style="text-align: center;"></div>`;
          }
          const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
          const editIconId = `edit-icon-${row}`;
    
          if (rowdata.is_address_email === '') {
            setTimeout(() => {
              const editIconElement = document.getElementById(editIconId);
              if (editIconElement) {
                editIconElement.addEventListener('click', () => {
                  this.handleEditClick(rowdata);
                });
              }
            }, 0);
    
            return `<div style="line-height: 30px;display: flex;justify-content: space-around;align-items: center;">
                      ${formattedDate}
                      <i id="${editIconId}" class='fa fa-edit' aria-hidden='true' style='cursor: pointer; margin-left: 15px;margin-top: 3px;' title='Edit date'></i>
                    </div>`;
          } else {
            return `<div style="text-align: left;line-height:30px;margin-left: 11px;">${formattedDate}</div>`;
          }
        }
      }
    },
    {
      text: "AWB Number",
      datafield: "master_awb_no",
      align: "center",
      cellsalign: "center",
      width: 150,
    },
   
    {
      text: "Flight  No",
      datafield: "flight_truck_no",
      align: "center",
      cellsalign: "center",
      width: 130,
    },
    // { text: 'Flight Details', datafield: 'flight_details', align: 'center',width: 150 },
    // { text: 'Flight Date', datafield: 'flight_date', align: 'center' },
    { text: "Station", datafield: "station_code",   cellsalign: "center", align: "center", width: 80 },
    // {
    //   text: "Incident Date",
    //   datafield: "incident_date",
    //   cellsalign: "right",
    //   width: 120,
    // },
    {
      text: "Incident Date",
      datafield: "incident_date",
      cellsalign: "center",
      width: 120,
      cellsrenderer: (row, columnfield, value) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
        return `<div style="text-align: left;margin-left: 11px;line-height: 30px;">${formattedDate}</div>`;
      }
    },
    // {
    //   text: "Incident Time",
    //   datafield: "incident_time",
    //   align: "center",
    //   width: 80,
    // },
    {
      text: "Shipper",
      datafield: "shipper",
      cellsalign: "center",
      align: "center",
      width: 200,
    },
    {
      text: "Consignee",
      datafield: "consignee",
      cellsalign: "center",
      align: "center",
      width: 150,
    },
    {
      text: "Incident Type",
      datafield: "type_of_incident",
      cellsalign: "center",
      cellsformat: "c2",
      align: "center",
      width: 250,
    },
    // {
    //   text: "Damage Discover",
    //   datafield: "damage_discovered",
    //   align: "center",
    // },
   
  ];
  rendergridrows3 = (params: any): any => {
    params.data.endindex=150
    return params.data;
  }

  getWidth2(): any {
    if (document.body.offsetWidth < 850) {
      return "100%";
    } else if (document.body.offsetWidth > 1400) {
      return "100%";
    }
    return "100%";
  }
 // ================================== grid for archived grid in handler |end===================================

 //================================all grid for handler====================================================

 serviceReportSource3: any = {
      
  datatype: "json",
  datafields: [
    { name: "createdon", type: "date" },
    { name: "id", type: "string" },
    { name: "customer_report_id", type: "string" },
    { name: "shipper", type: "string" },
    { name: "consignee", type: "string" },
    { name: "type_of_incident", type: "string" },
    { name: "incident_date", type: "date" },
    { name: "incident_time", type: "time" },
    { name: "station_code", type: "string" },
    { name: "notified_dt", type:"string"},
    { name: "master_awb_no", type: "string" },
    { name: "airline_name", type: "string" },
    { name: "flight_truck_no", type: "string" },
    { name: "flight_date", type: "string" },
    { name: "flight_details", type: "string" },
    { name: "damage_discovered", type: "string" },
    { name: "report", type: "string" },
    { name: "status", type: "string" },
    { name: "notified", type: "string" },
    { name: "is_address_email", type: "string" },
  ],
  pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
  root: "allReportData",
  totalrecords:100,
  cache: false,
  url:this.config.getConfig().URL +
      "customerService/customerServicePortal.php/getCustomerServiceByHandleraAllData?loginUserId="+
      JSON.parse(localStorage.getItem("loginDetails")).userId +
      "&orgId="+JSON.parse(localStorage.getItem("loginDetails")).orgId +
      "&orgType="+JSON.parse(localStorage.getItem("loginDetails")).orgType+
      "&orderType=A"+"&loginUserType="+JSON.parse(localStorage.getItem("loginDetails")).userType,
      filter: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata('filter');
      },
  beforeprocessing: (data: any) => {
      this.serviceReportSource3.totalrecords = data.totalAllReportData;
      data.Rows = data.allReportData;
    },
  pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
      console.log(pagenum);
  },
  sortcolumn: "createdon",
  sortdirection: "desc",
};

getWidth3(): any {
  if (document.body.offsetWidth < 850) {
    return "100%";
  } else if (document.body.offsetWidth > 1400) {
    return "100%";
  }
  return "100%";
}

dataAdapter3: any = new jqx.dataAdapter(this.serviceReportSource3);

columns3: any[] = [
  {
     text: "Actions",
     datafield: "id",
     cellsalign: "center",
     filterable: false,
     align: "center",
     sortable: false,
     groupable: false,
     width: 60,
     cellsrenderer: (
       row,
       columnfield,
       value,
       defaulthtml,
       columnproperties,
       columndata
     ) => {
       return "<div class='jq-actions' style='margin-left: 5px;'><a  href='crpedit:" +
         value +
         ":" +
         columndata.customer_report_id +
         "'><i id = 'crpedit:" +
         value +
         ":" +
         columndata.customer_report_id +
         "' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>"
       
     },
   },
 {
  text: "Status",
  datafield: "status",
  align: "center",
  filtertype: "list",
  filteritems: ["New","In Progress","Completed"],
  cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
    let statusText = '';
    if (value == 1) {
        statusText = 'New';
    } else if (value == 2) {
        statusText = 'In Progress';
    } else if (value == 3) {
        statusText = 'Completed';
    }
    return `<div style="text-align: center; margin-top: 8px;">${statusText}</div>`;
  },
  width: 130,
 },
 {
   text: "Report",
   datafield: "report",
   align: "center",
   width: 50,
   filterable: false,
   sortable: false,
   groupable: false,

   cellsrenderer: (
     row,
     columnfield,
     value,
     defaulthtml,
     columnproperties,
     columndata
   ) => {
    // console.log("grid comulm 310 ==>",columndata);
     // return("<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href="+this.config.getConfig().URL +"/customerService/customerServicePortalPdf.php/getCustomerServicePdfCreate?id="+columndata.id+"&customer_report_id="+columndata.customer_report_id+"><i id = '' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='CRPEdit'></i></a></div>");
     return "<div class='jq-actions' style='margin-left: 5px;'><a target='_blank' href=" +
       this.config.getConfig().URL +
       "/customerService/customerServicePortal.php/getCustomerServicePdfCreate?id=" +
       columndata.id +
       "&customer_report_id=" +
       columndata.customer_report_id +
       "&org_id=" +
       JSON.parse(localStorage.getItem("loginDetails")).orgId+
       "> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;align:center;'></a></div>"
     ;
   },
 },
 {
  text: "Email",
  datafield: "notified",
  align: "center",
  width: 50,
  sortable: false,
  filterable: false,
  cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
    let icon = value == '1' ? 'fa fa-bell' : 'fa fa-bell-slash';
    let color = value == '1' ? '#28a745' : '#ffc107';
    const mailButtonId = `mailButton-${row}-${columnfield}`;
    const bellButtonId = `bellButton-${row}-${columnfield}`;

    let mailIconHtml;
    let bellIconHtml;

    if (rowdata.is_address_email === '') {
      mailIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
      bellIconHtml = `<img src="assets/images/emails-red.png" style="width: 24px; height: 24px; cursor: pointer;" title="Mail not found" />`;
    } else {
      mailIconHtml = `<img id="${mailButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
      bellIconHtml = `<img id="${bellButtonId}" src="assets/images/emails-available2.png" style="width: 24px; height: 24px; cursor: pointer;" title="Click to send mail" />`;
    }

    setTimeout(() => {
      const mailIconElement = document.getElementById(mailButtonId);
      const bellIconElement = document.getElementById(bellButtonId);
      if (mailIconElement) {
        mailIconElement.addEventListener('click', function() {
          this.handleMailClick(rowdata);
        }.bind(this));
      }
      if (bellIconElement) {
        bellIconElement.addEventListener('click', function() {
          this.handleMailClick(rowdata);
        }.bind(this));
      }
    });

    let text;
    if (rowdata.is_address_email === '' && !rowdata.notified_dt) {
      text = 'Not Notified';
    } else if (rowdata.is_address_email !== '' && !rowdata.notified_dt) {
      text = 'Send Email';
    } else if (rowdata.is_address_email === '' && rowdata.notified_dt) {
      text = 'Notified';
    } else if (rowdata.is_address_email !== '' && rowdata.notified_dt) {
      text = 'Email Sent';
    }

    return `<div style="text-align: left; margin-top: 5px; margin-left: 10px;">
              ${bellIconHtml}
              
            </div>`;
            // <strong>
            //   ${text}
            //   </strong>
  }
},
//===== 21062024=============================================
{
  text: "Agent Notified",
  datafield: "",
  cellsalign: "center",
  filterable: false,
  align: "center",
  width: 130,
  cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
    if(rowdata.notified == '1'){
      return `<div style="text-align: left; line-height:30px">
               
                 <span> Notified</span>
              </div>`;
    }else if(rowdata.notified == '0'){
      return `<div style="text-align: left; line-height:30px">
               
                 <span> Not Notified</span>
              </div>`;
    }


  }
},
//===============21062024 |end==============================
{
  text: "Notified Date",
  datafield: "notified_dt",
  filterable: false,
  align: "center",
  cellsalign: "center",
  width: 150,
  cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
    if (!value && rowdata.is_address_email === '') {
      const calendarIconId = `calendar-icon-${row}`;

      setTimeout(() => {
        const calendarIconElement = document.getElementById(calendarIconId);
        if (calendarIconElement) {
          calendarIconElement.addEventListener('click', () => {
            this.handleCalendarClick(rowdata);
          });
        }
      }, 0);

      return `<div style="text-align: center;">
                <i id="${calendarIconId}" class='fa fa-calendar' aria-hidden='true' style='cursor: pointer;' title='Pick a date'></i>
              </div>`;
    } else if (!value) {
      return `<div style="text-align: center;"></div>`;
    } else {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return `<div style="text-align: center;"></div>`;
      }
      const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
      const editIconId = `edit-icon-${row}`;

      if (rowdata.is_address_email === '') {
        setTimeout(() => {
          const editIconElement = document.getElementById(editIconId);
          if (editIconElement) {
            editIconElement.addEventListener('click', () => {
              this.handleEditClick(rowdata);
            });
          }
        }, 0);

        return `<div style="line-height: 30px;display: flex;justify-content: space-around;align-items: center;">
                  ${formattedDate}
                  <i id="${editIconId}" class='fa fa-edit' aria-hidden='true' style='cursor: pointer; margin-left: 15px;margin-top: 3px;' title='Edit date'></i>
                </div>`;
      } else {
        return `<div style="text-align: left;line-height:30px;margin-left: 11px;">${formattedDate}</div>`;
      }
    }
  }
},
 {
   text: "AWB Number",
   datafield: "master_awb_no",
   align: "center",
   cellsalign: "center",
   width: 150,
 },

 {
   text: "Flight No",
   datafield: "flight_truck_no",
   cellsalign: "center",
   align: "center",
   width: 130,
 },
 // { text: 'Flight Details', datafield: 'flight_details', align: 'center',width: 150 },
 // { text: 'Flight Date', datafield: 'flight_date', align: 'center' },
 { text: "Station", datafield: "station_code",   cellsalign: "center", align: "center", width: 80 },
 // {
 //   text: "Incident Date",
 //   datafield: "incident_date",
 //   cellsalign: "right",
 //   width: 120,
 // },
 {
  text: "Incident Date",
  datafield: "incident_date",
  cellsalign: "center",
  width: 120,
  cellsrenderer: (row, columnfield, value) => {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-GB'); // Format date as dd/MM/yyyy
    return `<div style="text-align: left;margin-left: 11px;line-height: 30px;">${formattedDate}</div>`;
  }
},
 // {
 //   text: "Incident Time",
 //   datafield: "incident_time",
 //   align: "center",
 //   width: 80,
 // },
 {
   text: "Shipper",
   datafield: "shipper",
   cellsalign: "center",
   align: "center",
   width: 200,
 },
 {
   text: "Consignee",
   datafield: "consignee",
   cellsalign: "center",
   align: "center",
   width: 150,
 },
 {
   text: "Incident Type",
   datafield: "type_of_incident",
   cellsalign: "center",
   cellsformat: "c2",
   align: "center",
   textAlign:"center",
   width: 250,
 },
 // {
 //   text: "Damage Discover",
 //   datafield: "damage_discovered",
 //   align: "center",
 // },
];
rendergridrows = (params: any): any => {
  ;
  params.data.endindex=150
  return params.data;
}

 //================================end all  grid for handler=================================================


 public getReportIds: any = [];
  public getCustomerReportIds: any = [];

  _onSelectCustomerReportRow(event: any): void {
    if(Array.isArray(event.args.rowindex)){ 
      if(event.args.rowindex.length !== 0){
        for (var i = 0; i < CustomerReportHandlerComponent._TOTAL_GRIDDATA.length; i++) {
          if (CustomerReportHandlerComponent._TOTAL_GRIDDATA[i]) {
            if(CustomerReportHandlerComponent._TOTAL_GRIDDATA[i].id > 0){
            this.getReportIds.push(CustomerReportHandlerComponent._TOTAL_GRIDDATA[i].id);
            }else{
              this.getCustomerReportIds.push(CustomerReportHandlerComponent._TOTAL_GRIDDATA[i].customer_report_id);
            }
          }
        }
      }else{
      this.getReportIds = [];
      }
    }else{
        if( event.type === "rowunselect"){
            if(event.args.row.id > 0){
              let index = this.getReportIds.findIndex(x => x.id === event.args.row.id);
              this.getReportIds.splice(index, 1);
            }else{
              let index = this.getCustomerReportIds.findIndex(x => x === event.args.row.customer_report_id);
              this.getCustomerReportIds.splice(index, 1);
            }
        }else{
          console.log("event.args.rowfhdf",event.args.row.id);
          console.log("getReportIds ",this.getReportIds);
          if(event.args.row.id > 0){
            var ind = CustomerReportHandlerComponent._TOTAL_GRIDDATA.findIndex(x => x.id === event.args.row.id);
            event.args.row = CustomerReportHandlerComponent._TOTAL_GRIDDATA[ind];
            this.getReportIds.push(event.args.row.id) ; 
          }else{
            this.getCustomerReportIds.push(event.args.row.customer_report_id);
          }
        }
    }
  
  }

// =======================================archive model============================

confirm_customerReportManualArchive(){
  if (this.getReportIds.length != 0 || this.getCustomerReportIds.length != 0) {
      jQuery('#customerReportManarchModal').modal({ backdrop: 'static', keyboard: false });
  }else{
      this.spinner.hide();
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
  }
      
  }

  customerReportManualArchive(){
    var payload = {
      reportIds: this.getReportIds,
      customerReportIds: this.getCustomerReportIds,
      status: "1",
      loginUserId: JSON.parse(localStorage.getItem("loginDetails")).userId,
      loginOrgid: JSON.parse(localStorage.getItem("loginDetails")).orgId,
      loginOrgType: JSON.parse(localStorage.getItem("loginDetails")).orgType
    };

    this.superAdminservice.customerReportArchived(payload).subscribe((data) => {
      this.reloadCurrentRoute();
        this.toastr.success(data.message);          
        jQuery('#customerReportManarchModal').modal('hide');
    },(error) => {
        this.toastr.error("Report is not archived successfully.");
        jQuery('#customerReportManarchModal').modal('hide');
        }
    );
  }
  reloadCurrentRoute() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]);
    }); 
  }

  // =======================================end archive model============================
  //====================sent report=============================================
OpenEmailPopup() {
  if (this.getReportIds.length != 0 || this.getCustomerReportIds.length != 0) {
    jQuery('#emailModalsent').modal({ backdrop: 'static', keyboard: false });
}else{
    this.spinner.hide();
    this.toastr.error('Select reports to send', '', { timeOut: 1200 });
}
 
}


confirmSendMail(){
  var payload = {
    reportIds: this.getReportIds,
    customerReportIds: this.getCustomerReportIds,
    status: "1",
    loginUserId: JSON.parse(localStorage.getItem("loginDetails")).userId,
    loginOrgid: JSON.parse(localStorage.getItem("loginDetails")).orgId,
    loginOrgType: JSON.parse(localStorage.getItem("loginDetails")).orgType,
    userMail:this.userMail
  };
  
  console.log("mail payload====>",payload);
 // return false;
  this.superAdminservice.customerSendMail(payload).subscribe((data) => {
    this.reloadCurrentRoute();
    console.log("mail response==>",data);
      this.toastr.success(data.message);          
      jQuery('#emailModalsent').modal('hide');
  },(error) => {
      this.toastr.error("Report is not archived successfully.");
      jQuery('#emailModalsent').modal('hide');
      }
  );
}


notCancel() {
  jQuery("#emailModalsent").modal("hide");
    this.rootcauseshareshow = false;
    // jQuery("#Activation").modal("hide");
}
//====================end sent report=========================================

public getArchivedReportIds: any = [];
public getArchivedCustomerReportIds: any = [];
_onSelectCustomerArchivedReportRestore(event: any): void {
  if(Array.isArray(event.args.rowindex)){ 
    if(event.args.rowindex.length !== 0){
      for (var i = 0; i < CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA.length; i++) {
        if (CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA[i]) {
          if(CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA[i].id > 0){
          this.getArchivedReportIds.push(CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA[i].id);
          }else{
            this.getArchivedCustomerReportIds.push(CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA[i].customer_report_id);
          }
        }
      }
    }else{
    this.getArchivedReportIds = [];
    }
  }else{
      if( event.type === "rowunselect"){
          if(event.args.row.id > 0){
            let index = this.getArchivedReportIds.findIndex(x => x.id === event.args.row.id);
            this.getArchivedReportIds.splice(index, 1);
          }else{
            let index = this.getArchivedCustomerReportIds.findIndex(x => x === event.args.row.customer_report_id);
            this.getArchivedCustomerReportIds.splice(index, 1);
          }
      }else{
        if(event.args.row.id > 0){
          var ind = CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA.findIndex(x => x.id === event.args.row.id);
          event.args.row = CustomerReportHandlerComponent.TOTAL_CUST_GRIDDATA[ind];
          this.getArchivedReportIds.push(event.args.row.id) ; 
        }else{
          this.getArchivedCustomerReportIds.push(event.args.row.customer_report_id);
        }
      }
  }

}



//========================restore ===========================================
confirm_Restore() {
  console.log("742 ",this.getArchivedCustomerReportIds);
  console.log("743 ",this.getArchivedReportIds);
  if (this.getArchivedReportIds.length != 0 || this.getArchivedCustomerReportIds.length != 0) {
    jQuery('#restoreModal').modal({ backdrop: 'static', keyboard: false });
}else{
    this.spinner.hide();
    this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
}
  
}

moveToTrashorRestoreReport() {
  var payload = {
    reportIds: this.getArchivedReportIds,
    customerReportIds: this.getArchivedCustomerReportIds,
    loginUserId: JSON.parse(localStorage.getItem("loginDetails")).userId,
    loginOrgid: JSON.parse(localStorage.getItem("loginDetails")).orgId,
    loginOrgType: JSON.parse(localStorage.getItem("loginDetails")).orgType
  };
  console.log("mail payload====>",payload);
  ////return false;
  this.superAdminservice.ArchiveResore(payload).subscribe((data) => {
    this.reloadCurrentRoute();
    console.log("archive response==>",data);
      this.toastr.success(data.message);          
      jQuery('#restoreModal').modal('hide');
  },(error) => {
      this.toastr.error("Report is not archived successfully.");
      jQuery('#restoreModal').modal('hide');
      }
  );
 
}
//========================end|restore ===========================================

sentMail(){
  console.log("user==>",this.loginUserId);
  let id=JSON.parse(localStorage.getItem("loginDetails")).userId
  
   //return false;
 this.superAdminservice.sendEmailNotification( id,this.cust_reportId,this.MailreportId).subscribe(
   (data) => {
     console.log("res===>", data);
     if(data.responseCode==1){
       this.toastr.success(data.message);
     }else{
       this.toastr.error(data.message);
     }
     jQuery('#emailModalsent').modal('hide');
    
    
   },
   (error) => {}
 );
}

MailIdchecked(event: any){
  this.isChecked = event.checked;
  console.log('Checked====>>>>', this.isChecked);
 // console.log('mail====>>>>', JSON.parse(localStorage.getItem("loginDetails")).emailId);

  if(this.isChecked == true){
   this.userMail =JSON.parse(localStorage.getItem("loginDetails")).emailId;
   console.log('mail====>>>>',  this.userMail);
  }else{
    this.userMail ="";
   console.log('not mail====>>>>', this.userMail);
  }
}


handleMailClick(rowdata) {
  
  if (this.isProcessingClick) {
    return;
  }
 
  this.isProcessingClick = true;
  console.log("Row data:", rowdata);
  this.cust_reportId = rowdata.customer_report_id;
  this.MailreportId = rowdata.id;
  this.getReportIds.push(rowdata.id);
  this.getCustomerReportIds.push(rowdata.customer_report_id);

  jQuery("#emailModalsent").modal({ backdrop: "static", keyboard: false });

  setTimeout(() => {
    this.isProcessingClick = false;
  }, 1000); 
}




}
