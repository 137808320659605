import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";
@Component({
  selector: "app-amount-count",
  templateUrl: "./amount-count.component.html",
  styleUrls: ["./amount-count.component.css"],
})
export class AmountCountComponent implements OnInit {
  loginDetails: any;
  orgId: any;
  totaloriginclaimCount: any;
  paid_amt_orgin: any;
  final_claim_amt_orgin: any;
  paid_amt_orginFormal: any;
  final_claim_amt_orginFormal: any;
  totaldestclaimCount: any;
  paid_amt_destination: any;
  final_claim_amt_destination: any;
  paid_amt_destinationFormal: any;
  final_claim_amt_destinationFormal: any;
  originstation: any;
  Originclaimamount: any;
  Originpaidamount: any;
  deststation: any;
  destclaimamount: any;
  destpaidamount: any;
  inputObj: any;
  originAmount: any = [];
  topOrigin: any = [];
  destamount: any = [];
  topdestamount: any = [];
  totalDestinationPaidClaimAmounts: any;
  totalOriginPaidClaimAmounts: any;
  claimCount: any;
  paidamount: any;
  provisionamount: string;
  scrollable: boolean;
  scrollable1: boolean;
  canvaslineWidth: any;
  graphHeight: number;
  canvaslinebarWidth: number;
  suggestedAmount: string;
  Chargebackamount: string;
  labelLengthpaidClaimOrg: any;
  labelLengthpaidClaimDest: any;

  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {
    this.originAmount = [];
    this.topOrigin = [];
    this.destamount = [];
    this.topdestamount = [];
    //getting input Object
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
    //To get login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
        //amount count Api integration starts here(financial tab) 
      this.spinner.show();
      this.claimcountservice.amount_count(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.totalDestinationPaidClaimAmounts =data.totalDestinationPaidClaimAmounts;
            this.totalOriginPaidClaimAmounts = data.totalOriginPaidClaimAmounts;
           //here calculating paidAmount  and claimAmounts counts
           if (this.totalOriginPaidClaimAmounts != 0) {
            this.claimCount='0.00';
            this.paidamount ='0.00';
            this.provisionamount='0.00';
            this.suggestedAmount='0.00';
            this.Chargebackamount = '0.00';
            for (let i = 0; i < this.totalOriginPaidClaimAmounts.length; i++) {
              for (let j = 0;j < this.totalDestinationPaidClaimAmounts.length; j++) {
                if (this.totalOriginPaidClaimAmounts[i].staus === "Formal") {
                this.claimCount= this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin;
                this.paidamount= this.totalOriginPaidClaimAmounts[i].paid_amt_orgin;
                     if (this.totalOriginPaidClaimAmounts[i].Provision_amount === null ) {
                    this.totalOriginPaidClaimAmounts[i].Provision_amount ='0.00';
                  }else{
                    this.provisionamount= this.totalOriginPaidClaimAmounts[i].Provision_amount;
                  }
                this.suggestedAmount=this.totalOriginPaidClaimAmounts[i].suggested_amount_USD;
                this.Chargebackamount = this.totalOriginPaidClaimAmounts[i].chargeback_amount;
                }
              }
            }
            }
            else{
              this.claimCount='0.00';
              this.paidamount ='0.00';
              this.provisionamount='0.00';
              this.suggestedAmount='0.00';
              this.Chargebackamount = '0.00';
            }
            this.originAmount = data.originPaidClaimAmount;
            this.topOrigin = data.topOriginPaidClaimAmount;
            this.destamount = data.destPaidClaimAmount;
            this.topdestamount = data.topDestPaidClaimAmount;

            //dynamic scroll start here
            if (data.originPaidClaimAmount.stations!=null && data.originPaidClaimAmount.stations !=undefined) {
              this.labelLengthpaidClaimOrg = data.originPaidClaimAmount.stations.length; 
            }
            if ( data.destPaidClaimAmount.stations!=null && data.destPaidClaimAmount.stations!=undefined) {
              this.labelLengthpaidClaimDest = data.destPaidClaimAmount.stations.length; 
            }
           
           
          if (this.labelLengthpaidClaimOrg > 60 ) {
            this.scrollable = true
          }
          else{
            this.scrollable = false
          }
          if (this.labelLengthpaidClaimDest > 60 ) {
            this.scrollable1 = true
          }
          else{
            this.scrollable1 = false
          }
            //Origin Paid Amount line & bar chart in model view
            if (data.originPaidClaimAmount.stations!=null && data.originPaidClaimAmount.stations!= undefined) {
              this.canvaslineWidth = this.getWidth(data.originPaidClaimAmount.stations.length);
            }
            this.originstation = data.originPaidClaimAmount.stations,
              this.Originclaimamount = data.originPaidClaimAmount.claimAmount,
              this.Originpaidamount = data.originPaidClaimAmount.paidAmount,
              new Chart(
                <HTMLCanvasElement>document.getElementById("canvasline"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.originstation,
                    datasets: [
                      {
                        // type: "line",
                        borderColor: "rgba(0,0,255,1)",
                        backgroundColor: "rgba(0,0,255,1)",
                        hoverBorderColor: "rgba(0,0,255,1)",
                        // yAxisID: "y-axis-0",
                        data: this.Originclaimamount,
                        fill: false,
                        label: "Claim amount",
                        stack: "a",
                      },
                      {
                        // type: "bar",
                        borderColor: "rgba(255,165,0,1)",
                        backgroundColor: "rgba(255,165,0,1)",
                        hoverBackgroundColor: "rgba(255,165,0,1)",
                        // yAxisID: "y-axis-1",
                        data: this.Originpaidamount,
                        fill: false,
                        label: "Paid amount",
                        stack: "b",
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItems, data) {
                          return "$" + tooltipItems.yLabel.toString();
                        },
                      },
                    },
                    title: {
                      display: true,
                      //text: "Paid amount vs Claim amount"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        display: true,
                        color: "#000000",
                        anchor: "center",
                        align: "top",
                        rotation: -90,
                        formatter: function (value, context) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Amount ",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                            // reverse:true,
                            callback: function (value:any, _index, array) {
                              if (value == 0) {
                                return "0";
                              }
                              return value < 1000000
                                ? "$" + value / 1000 + "K"
                                : "$" + value / 1000000 + "M";
                            },
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );

            //Origin Paid Amount line & bar chart in top 10 onload view
            (this.originstation = data.topOriginPaidClaimAmount.stations),
              (this.Originclaimamount =
                data.topOriginPaidClaimAmount.claimAmount),
              (this.Originpaidamount =
                data.topOriginPaidClaimAmount.paidAmount),
              new Chart(
                <HTMLCanvasElement>document.getElementById("canvaslineOne"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.originstation,
                    datasets: [
                      {
                        //  type: "line",
                        borderColor: "rgba(0,0,255,1)",
                        backgroundColor: "rgba(0,0,255,1)",
                        hoverBorderColor: "rgba(0,0,255,1)",
                        //yAxisID: "y-axis-0",
                        data: this.Originclaimamount,
                        fill: false,
                        label: "Claim amount",
                        stack: "a",
                      },
                      {
                        //type: "bar",
                        borderColor: "rgba(255,165,0,1)",
                        backgroundColor: "rgba(255,165,0,1)",
                        hoverBackgroundColor: "rgba(255,165,0,1)",
                        //yAxisID: "y-axis-1",
                        data: this.Originpaidamount,
                        fill: false,
                        label: "Paid amount",
                        stack: "b",
                      },
                    ],
                  },
                  options: {
                    //         responsive: true,
                    //  maintainAspectRatio: false,
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItems, data) {
                          return "$" + tooltipItems.yLabel.toString();
                        },
                      },
                    },
                    title: {
                      display: true,
                      // text: "Top 10 Paid amount vs Claim amount"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        display: true,
                        color: "#000000",
                        anchor: "center",
                        align: "top",
                        rotation: -90,
                        formatter: function (value, context) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Amount ",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                            callback: function (value:any, _index, array) {
                              if (value == 0) {
                                return "0";
                              }
                              return value < 1000000
                                ? "$" + value / 1000 + "K"
                                : "$" + value / 1000000 + "M";
                            },
                          },
                          //id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //Destination Paid Amount line & bar chart in modal view
            if (data.destPaidClaimAmount.stations!=null && data.destPaidClaimAmount.stations!=undefined) {
              this.canvaslinebarWidth = this.getWidth(data.destPaidClaimAmount.stations.length);
            }
            (this.deststation = data.destPaidClaimAmount.stations),
              (this.destclaimamount = data.destPaidClaimAmount.claimAmount),
              (this.destpaidamount = data.destPaidClaimAmount.paidAmount),
              new Chart(
                <HTMLCanvasElement>document.getElementById("canvaslinebar"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.deststation,
                    datasets: [
                      {
                        // type: "line",
                        borderColor: "rgba(0,0,255,1)",
                        backgroundColor: "rgba(0,0,255,1)",
                        hoverBorderColor: "rgba(0,0,255,1)",
                        data: this.destclaimamount,
                        //yAxisID: "y-axis-0",
                        fill: false,
                        label: "Claim amount",
                        stack: "a",
                      },
                      {
                        // type: "bar",
                        borderColor: "rgba(255,165,0,1)",
                        backgroundColor: "rgba(255,165,0,1)",
                        hoverBackgroundColor: "rgba(255,165,0,1)",
                        data: this.destpaidamount,
                        // yAxisID: "y-axis-1",
                        fill: false,
                        label: "Paid amount",
                        stack: "b",
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItems, data) {
                          return "$" + tooltipItems.yLabel.toString();
                        },
                      },
                    },
                    title: {
                      display: true,
                      //text: "Destination paid vs Final claim amount"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        display: true,
                        color: "#000000",
                        anchor: "center",
                        align: "top",
                        rotation: -90,
                        formatter: function (value, context) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Amount ",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                            callback: function (value:any, _index, array) {
                              if (value == 0) {
                                return "0";
                              }
                              return value < 1000000
                                ? "$" + value / 1000 + "K"
                                : "$" + value / 1000000 + "M";
                            },
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //Destination Paid Amount line & bar chart onload top 10 view
            (this.deststation = data.topDestPaidClaimAmount.stations),
              (this.destclaimamount = data.topDestPaidClaimAmount.claimAmount),
              (this.destpaidamount = data.topDestPaidClaimAmount.paidAmount),
              new Chart(
                <HTMLCanvasElement>document.getElementById("canvaslinebarOne"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.deststation,
                    datasets: [
                      {
                        // type: "line",
                        borderColor: "rgba(0,0,255,1)",
                        backgroundColor: "rgba(0,0,255,1)",
                        hoverBorderColor: "rgba(0,0,255,1)",
                        data: this.destclaimamount,
                        //yAxisID: "y-axis-0",
                        fill: false,
                        label: "Claim amount",
                        stack: "a",
                      },
                      {
                        // type: "bar",
                        borderColor: "rgba(255,165,0,1)",
                        backgroundColor: "rgba(255,165,0,1)",
                        hoverBackgroundColor: "rgba(255,165,0,1)",
                        data: this.destpaidamount,
                        //yAxisID: "y-axis-1",
                        fill: false,
                        label: "Paid amount",
                        stack: "b",
                      },
                    ],
                  },
                  options: {
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItems, data) {
                          return "$" + tooltipItems.yLabel.toString();
                        },
                      },
                    },
                    title: {
                      display: true,
                      //text: "Top 10 Destination paid vs Final claim amount"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        display: true,
                        color: "#000000",
                        anchor: "center",
                        align: "top",
                        rotation: -90,
                        formatter: function (value, context) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Amount ",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                            callback: function (value:any, _index, array) {
                              if (value == 0) {
                                return "0";
                              }
                              return value < 1000000
                                ? "$" + value / 1000 + "K"
                                : "$" + value / 1000000 + "M";
                            },
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                        // {
                        //   gridLines: {
                        //     drawOnChartArea: false,
                        //   },
                        //   scaleLabel: {
                        //     display: true,
                        //     labelString: "Paid amount ",
                        //     fontSize: 16,
                        //     fontColor: "#000000",
                        //     fontStyle: "bold",
                        //   },
                        //   ticks: {
                        //     beginAtZero: true,
                        //     //lineHeight:20,
                        //     // max:plugin.settings.maxDataValue,
                        //     maxTicksLimit: 10,
                        //     callback: function (value, _index, array) {
                        //       if (value == 0) {
                        //         return "0";
                        //       }
                        //       return value < 1000000
                        //         ? "$" + value / 1000 + "K"
                        //         : "$" + value / 1000000 + "M";
                        //     },
                        //   },
                        //   id: "y-axis-1",
                        //   position: "right",
                        // },
                      ],
                    },
                  },
                }
              );
              this.scrollView();
          } else if (
            data.responseCode === 0 &&
            data.message === "Unauthorized User"
          ) {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          } else {
            this.toaster.error(data.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
        //amount count Api integration ends here(financial tab) 
    } else {
      this.paid_amt_orgin = 0;
      this.final_claim_amt_orgin = 0;
      this.paid_amt_orginFormal = 0;
      this.final_claim_amt_orginFormal = 0;
      this.paid_amt_destination = 0;
      this.final_claim_amt_destination = 0;
      this.paid_amt_destinationFormal = 0;
      this.final_claim_amt_destinationFormal = 0;
    }
  }
  //charts download method
  onClickDownloadTabCharts() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    const canvas = document.getElementById("canvasline") as HTMLCanvasElement;
    const canvas2 = document.getElementById(
      "canvaslinebar"
    ) as HTMLCanvasElement;
    var imgData = canvas.toDataURL("image/png");
    var imgData2 = canvas2.toDataURL("image/png");
    var imgWidth = 285;
    var pageHeight = 200;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.setFontSize(18);
    var position = 80; // give some top padding to first page
    var text = "Cargo Claim Financials Charts";
    var textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, 10, text);
    doc.setFontSize(14);

    doc.text(15, 30, "Origin Financials");
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();

    doc.text(10, 20, "Destination Financials");
    doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position += heightLeft - imgHeight; // top padding for other pages
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save("Financials_Charts.pdf");
  }
  //show and hide methods for model charts
  showModelorg() {
    const element = document.getElementById("Origin");
    element.style.display = "";
    element.style.visibility = "visible";
  }
  hideModelorg() {
    const element = document.getElementById("Origin");
    element.style.display = "block";
    element.style.visibility = "hidden";
  }
  showModeldest() {
    const element = document.getElementById("Destination");
    element.style.display = "";
    element.style.visibility = "visible";
  }
  hideModeldest() {
    const element = document.getElementById("Destination");
    element.style.display = "block";
    element.style.visibility = "hidden";
  }
  //dynamic scroll method
  scrollView(){
    setTimeout(() => {
      const elements =document.getElementsByClassName('chartAreaWrapper_scroll60');
      for(let ele of elements as any){
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
   
  }
   //dynamic width method
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 20) {
        let finalWidth = 150 * number;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}
