import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import * as jsPDF from "jspdf";
@Component({
  selector: "app-irregular-count",
  templateUrl: "./irregular-count.component.html",
  styleUrls: ["./irregular-count.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class IrregularCountComponent implements OnInit {
  loginDetails: any;
  orgId: any;
  iregularityDescription: any;
  irregularityCount: any;
  origincount: any;
  destcount: any;
  Irregularityoriginstation: any = [];
  OriginCount: any;
  Irregularitydeststation: any;
  DestCount: any;
  destcountcard: any;
  origincountcard: any;
  test: string;
  inputObj: any;
  currentyear: any;
  irregularOrigin: any = [];
  topirregualr: any = [];
  irregualrdest: any = [];
  topirregulardest: any = [];
  scrollable: boolean;
  scrollable1: boolean;
  origincountIrregularityWidth: any;
  graphHeight: number;
  destinationcountIrregularityWidth: number;
  labelLengthirregularOrg: any;
  labelLengthirregularDest: number;
  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.irregularOrigin = [];
    this.topirregualr = [];
    this.irregualrdest = [];
    this.topirregulardest = [];
    //to getting input object from dahsboard
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
    //getting login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    this.currentyear = this.datePipe.transform(new Date(), "yyyy");
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
     
      //irregularity api integration
      this.spinner.show();
      this.claimcountservice.irregularuty_count(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.iregularityDescription =
              data.iregularityDescription.description;
            this.irregularityCount = data.irregularityCount;
            for (let i = 0; i < this.irregularityCount.length; i++) {
              if (this.irregularityCount[i].Origin === "Origin") {
                this.origincountcard = this.irregularityCount[i].count;
              } else {
                this.destcountcard = this.irregularityCount[i].count;
              }
            }
            this.irregularOrigin = data.irregularityOrigin;
            this.topirregualr = data.topIrregularityOrigin;
            this.irregualrdest = data.irregularityDestn;
            this.topirregulardest = data.topIrregularityDestn;

            this.Irregularityoriginstation = data.irregularityOrigin.labels;
            this.OriginCount = data.irregularityOrigin.count;

             //dynamic scroll start here
             if (data.irregularityOrigin.labels!=null && data.irregularityOrigin.labels!=undefined) {
              this.labelLengthirregularOrg = data.irregularityOrigin.labels.length; 
             }
             if (data.irregularityDestn.labels!=null && data.irregularityDestn.labels!=undefined) {
              this.labelLengthirregularDest = data.irregularityDestn.labels.length; 
             }
            

          if (this.labelLengthirregularOrg > 60 ) {
            this.scrollable = true
          }
          else{
            this.scrollable = false
          }
          if (this.labelLengthirregularDest > 60 ) {
            this.scrollable1 = true
          }
          else{
            this.scrollable1 = false
          }
           //lrregular Count Origin bar model chart
           if (data.irregularityOrigin.labels!=null && data.irregularityOrigin.labels!=undefined) {
            this.origincountIrregularityWidth = this.getWidth(data.irregularityOrigin.labels.length);
           }
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("origincountIrregularity")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.Irregularityoriginstation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.OriginCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Irregularity per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 10,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //Irregular count origin on load top 10 bar chart
            this.Irregularityoriginstation = data.topIrregularityOrigin.labels;
            this.OriginCount = data.topIrregularityOrigin.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("origincountIrregularityOne")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.Irregularityoriginstation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.OriginCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Irregularity per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 20,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //lrregular Count Destination  bar model chart starts here
            if (data.irregularityDestn.labels!=null && data.irregularityDestn.labels!=undefined) {
              this.destinationcountIrregularityWidth = this.getWidth(data.irregularityDestn.labels.length);
            }
            this.Irregularitydeststation = data.irregularityDestn.labels;
            this.DestCount = data.irregularityDestn.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("destinationcountIrregularity")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.Irregularitydeststation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.DestCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Irregularity per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 10,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //lrregular Count Destination  bar top 10 on load chart starts here
            this.Irregularitydeststation = data.topIrregularityDestn.labels;
            this.DestCount = data.topIrregularityDestn.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("destinationcountIrregularityOne")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.Irregularitydeststation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.DestCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Irregularity per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 10,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            this.scrollView();
          }
          else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.origincountcard = 0;
      this.destcountcard = 0;
    }
  }
  //download charts method
 

  onClickDownloadTabCharts() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    const canvas = document.getElementById("origincountIrregularity") as HTMLCanvasElement;
    const canvas2 = document.getElementById("destinationcountIrregularity")as HTMLCanvasElement;
    const canvas4= document.getElementById("origincountInvestigation") as HTMLCanvasElement;
    const canvas6 = document.getElementById("destinationcountInvestigation")as HTMLCanvasElement;
    var imgData = canvas.toDataURL("image/png");
    var imgData2 = canvas2.toDataURL("image/png");
    var imgData4 = canvas4.toDataURL("image/png");
    var imgData6 = canvas6.toDataURL("image/png");
    var imgWidth = 285;
    var pageHeight = 200;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.setFontSize(18);
    var position = 80; // give some top padding to first page
    var text ="Cargo Claim Root Causes Charts";
    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, 10, text);
    doc.setFontSize(14);
    doc.text(15, 30, 'Claim aspects Origin');
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();
    doc.text(10, 20, 'Claim aspects Destination');
     doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
     doc.addPage();
    doc.text(10, 20, 'Root causes Origin');
    doc.addImage(imgData4, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();
    doc.text(10, 20, ' Root causes Destination');
     doc.addImage(imgData6, "PNG", 0, position, imgWidth, imgHeight);
     heightLeft -= pageHeight;
   
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight; // top padding for other pages
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save("Root_Cause_Charts.pdf");
   
  }
//show and hide model charts method
  showmodelorg(){
    const element = document.getElementById("myModal");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodelorg(){
    const element = document.getElementById("myModal");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  showmodeldest(){
    const element = document.getElementById("Irregulardestination");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodeldest(){
    const element = document.getElementById("Irregulardestination");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  //dynamic scroll method
  scrollView(){
    setTimeout(() => {
      const elements =document.getElementsByClassName('chartAreaWrapper_scroll60');
      for(let ele of elements as any){
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
   
  }
  //dynamic width method
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 30) {
        let finalWidth = 150 * number;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}
