import { Component, OnInit, enableProdMode, ViewChild, Renderer2, ElementRef, Type } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
declare var jQuery: any;
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../../config-service.service';
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-view-reprots',
    templateUrl: './view-reprots.component.html',
    styleUrls: ['./view-reprots.component.css']
})
export class ViewReprotsComponent implements OnInit {
    dummyObj: any;
    dataSourceReportsArray = [];
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    disabledKeys: any[];
    Always;
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    Object: any;
    loginDetails: any
    activateObj: { "organizationId": any; "status": string; };
    obj: any;
    ReportsProductionUrl: string;
    userType: any;
    reportpreparename: string;
    Airlinename: string;
    Emailstatus: any;
    multipleReportList: any[];
    userId: string;
    masterAwbNo: string;
    stationCode: string;
    firstName: string;
    classificationType: string;
    dataGridInstance: any;
    justDeselected: any;
    mergedReportsFinal: any[];
    airlineDetails: any;
    isShowAll: boolean;
    isShowArch: boolean;
    shipper_duplicate: any;

    public static totaldata = [];
    anchors: any;
    editicons: any;
    image: any;
    images: any;
    show_hide_checkbox: string;
    shortReports: any;
    compnyorgid: any;
    orgId: any;
    loginUserId: any;
    orderType: any;
    sideReportsTab: any;
    currentTabName: any;
    selreportsarray: any;
    finalobj: any;
    currentReportId: any;
    finalReportsCount: any = 0;
    archiveReportsCount: any;
    static  webapi :string = "";
    internalReportCount: any;
    carrierId: any;
    
    rootcauseshare: boolean = false;
    rootcauseshareshow: boolean = false;

    constructor(private config : ConfigServiceService,private renderer: Renderer2, private elementRef: ElementRef, private ViewReportsData: ViewReportsData, 
        private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService,
         private superAdminservice: SuperadminService, private spinner: NgxSpinnerService, private locationStr: LocationStrategy) {
          // preventing back button in browser   
          history.pushState(null, null, window.location.href);  
          this.locationStr.onPopState(() => {
            history.pushState(null, null, window.location.href);
          });
          ViewReprotsComponent.webapi = this.config.getConfig().URL

     }

    dataGrid1: DxDataGridComponent;
    ngAfterViewInit() {
        this.myGrid.updatebounddata("refresh");
    }

    ngAfterViewChecked() {
        
        let selectedTab = JSON.parse(localStorage.getItem("sideReportsTab")) ;
    if(selectedTab !== null){
        
      (selectedTab === 'Archived') ? this.myGrid.selectionmode("none") : this.myGrid.selectionmode("checkbox")  ;
    }else{
      this.myGrid.selectionmode("checkbox") ;
    }
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.editicons = this.elementRef.nativeElement.querySelectorAll('i');
        this.images = this.elementRef.nativeElement.querySelectorAll('img');
        
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }

            }
        })

        this.editicons.forEach((editicon: HTMLImageElement) => {
            if (editicon.id != '') {
                let href_Split_Array = [];
                href_Split_Array = editicon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((href_Valid_Check_Var === 'edit')) {
                    editicon.addEventListener('click', this.handleiconClick)
                } else if (href_Valid_Check_Var === 'resend') {
                    editicon.addEventListener('click', this.handleiconClick)

                }

            }
        })
        this.images.forEach((image: HTMLImageElement) => {
            if (image.id != '') {
                let href_Split_Array = [];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((href_Valid_Check_Var === 'resend')) {
                    image.addEventListener('click', this.handleImageClick)
                }

            }
        })
    }

    public handleAnchorClick = (event: Event) => {
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        this.fn_alert(anchor, event);
    }

    public handleiconClick = (event: Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
        this.fn_alerticon(editicon, event);
    }
    public handleImageClick = (event: Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
        this.fn_alertImage(editicon, event);
    }


    fn_alert(anchor, event) {
        if (anchor.innerText === 'Edit') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            this.mergedReportsFinal[href_Valid_Check_Var]
     
            this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }
    }


    fn_alerticon(editicon, event) {
        
        let href_Split_Array = [];
        href_Split_Array = editicon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'edit') {
            console.log(this.myGrid.getselectedcell());
            this.mergedReportsFinal[href_Valid_Check_Var]
            this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }
    }
    fn_alertImage(image, event) {
        let href_Split_Array = [];
        href_Split_Array = image.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'resend') {
            this.mergedReportsFinal[href_Valid_Check_Var]
            this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var], 'S');
        }
    }




fn_OnTab_Changes(tabName : string , type: string){
    ;
    this.spinner.show();

    localStorage.setItem("sideReportsTab", JSON.stringify(tabName));

    if(tabName === 'Internal'){
        this.isShowAll = true ;
        this.source.url = ViewReprotsComponent.webapi + 'getInternalReportList.php/getInternalReportList?organisationId='+ this.logindet.orgId +'&loginUserType='+ this.logindet.userType + '&loginUserId='+ this.logindet.userId;
    }else{ 
    this.source.url = ViewReprotsComponent.webapi + 'reportsDetailsCclpPortal.php/viewReports?userType='+ this.logindet.userType +'&orderType='+type+'&loginUserId='+ this.logindet.userId;
    }
    this.myGrid.updatebounddata("refresh") ;
     this.myGrid.gotopage(0)
     this.selectedRowsData = [];
     this.myGrid.clearselection()
     if(tabName == "Active"){
        this.isShowAll = true ;
        this.myGrid.selectionmode('checkbox');
        this.myGrid.clearselection();
     }else if(tabName == "Archived"){
         this.isShowAll = false ;
        this.myGrid.selectionmode('none');
        this.myGrid.showcolumn("emailStatus");
     }
}




    ngOnInit() {
        this.isShowAll = true;
        this.isShowArch = false;
        this.disabledKeys = [];
        window.scroll(0, 0);
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("orgKey");
            });
        this.webapi = this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        this.sideReportsTab = JSON.parse(localStorage.getItem('sideReportsTab'));
        ;
        if (this.sideReportsTab != null) {          // To highlight same tab when return from detailed report page
            if (this.sideReportsTab === 'Active') {
                this.fn_OnTab_Changes('Active', 'A') ;
            } else if (this.sideReportsTab === 'Archived') {
                this.fn_OnTab_Changes('Archived', 'R') ;
            } else if (this.sideReportsTab === "Internal") {
                this.fn_OnTab_Changes("Internal", "I");
            } 
        }else{
            this.fn_OnTab_Changes('Active', 'A') ;
        }
    }
  public static TOTAL_GRIDDATA = [];
    public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY ;
    logindet = JSON.parse(localStorage.getItem('loginresultobject'));
    url =  ViewReprotsComponent.webapi + 'reportsDetailsCclpPortal.php/viewReports?userType='+ this.logindet.userType +'&orderType=A&loginUserId='+ this.logindet.userId;
    source =
        {
            url: this.url,
            datatype: 'json',
            datafields:
                [
                    { name: 'emailStatus', type: 'string' },
                    { name: 'damageReportType', type: 'string' },
                    { name: 'actions', type: 'string' },
                    { name: 'stationCode', type: 'string' },
                    { name: 'incidentDate', type: 'date', },
                    { name: 'masterAwbNo', type: 'string' },
                    { name: 'reportStatus', type: 'string' },
                    { name: 'safetyLife', type: 'string' },
                    
                    { name: 'authstatus', type: 'string' },
                    { name: 'internalFileName', type: 'string' },
                    { name: 'externalFileName', type: 'string' },
                    { name: 'orgName', type: 'string' },
                    { name: 'airlineName', type: 'string' },
                    { name: 'flightTruckNo', type: 'string' },
                    { name: 'originStation', type: 'string' },
                    { name: 'destStation', type: 'string' },
                    { name: 'truckType', type: 'string' },
                    
                    { name: 'reportPreparedName', type: 'string' },
                    { name: 'shipper', type: 'string' },
                    { name: 'consignee', type: 'string' },
                    { name: 'issuingAgent', type: 'string' },
                    { name: 'jobTitle', type: 'string' },
                    { name: 'flightDate', type: 'date' },
                    { name: 'contentNature', type: 'string' },

                    { name: 'damgagePacking', type: 'string' },
                    { name: 'contentAffected', type: 'string' },
                    
                    { name: 'classificationType', type: 'string' },
                    { name: 'damageDiscovered', type: 'string' },
                    { name: 'discoveresCaused', type: 'string' },
                    { name: 'modifiedDate', type: 'date' },
                    { name: 'modifiedTime', type: 'string' },
                    { name: 'reportCreatedTime', type: 'string' },
                    
                    { name: 'destAirportName', type: 'string' },
                    { name: 'originAirportName', type: 'string' },

                    { name: 'rootcauseExists', type: 'string' },
                    { name: 'rootcausestatus', type: 'string' },
                    { name: "reportOrgRootcauseStatus", type: "string" },
                    { name: 'reportId', type: 'string' },
                    { name: 'userId', type: 'string' },
                    { name: 'firstName', type: 'string' },
                ],
            pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
            root: 'Rows',
            totalrecords: 800,
            cache: false,
            sort: () => {
                // update the grid and send a request to the server.
                this.myGrid.updatebounddata('sort');
              },
              filter: () => {
                // update the grid and send a request to the server.
                this.myGrid.updatebounddata('filter');
              },
              beforeprocessing: (data: any) => {
                  this.mergedReports = [];
                      this.reportsList = [];
                      this.finalReportArray = []
                      this.airlineDetails = []
                      if (data.responseCode === 1) {
                          this.obj = data;
                          if(data.userReports.length !== 0){
                          this.finalReportArray = data.userReports;
                          this.airlineDetails = data.airlineDetails;
                          this.mergedReports = data.userReports;
                          console.log(this.reportsList);
                          for (let i = 0; i < this.finalReportArray.length; i++) {
                              if (this.finalReportArray[i].adminAuth === 'A' || this.finalReportArray[i].superAdminAuth === 'A') {
                                  this.finalReportArray[i].authstatus = 'Authorized user ';
                              } else if (this.finalReportArray[i].adminAuth === 'I' && this.finalReportArray[i].superAdminAuth === 'I') {
                                  this.finalReportArray[i].authstatus = 'Unauthorized user';
                              }
                          }
      
                          for (let index = 0; index < this.finalReportArray.length; index++) {
                            if ((this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime != null) || (this.finalReportArray[index].emailStatus === 'ST')) {
                                this.finalReportArray[index].emailStatus = 'FinalTimer';
                            } else if (this.finalReportArray[index].emailStatus === 'S' && this.finalReportArray[index].reportStatus === 'external') {
                                this.finalReportArray[index].emailStatus = 'Extrenal email sent to Airlines';
                            } else if (this.finalReportArray[index].emailStatus === 'S' && this.finalReportArray[index].reportStatus === 'internal') {
                                this.finalReportArray[index].emailStatus = 'Internal email sent';
                            }else if (this.finalReportArray[index].emailStatus === 'S' && (this.finalReportArray[index].reportStatus === '' || this.finalReportArray[index].reportStatus === null)) {
                                this.finalReportArray[index].emailStatus = 'Email sent';
                            }
                            else if (this.finalReportArray[index].emailStatus === 'E') {
                                this.finalReportArray[index].emailStatus = 'Emails are not found';
                            }
                            else if (this.finalReportArray[index].emailStatus === 'H') {
                                this.finalReportArray[index].emailStatus = 'Hold';
                            }
                            else if (this.finalReportArray[index].emailStatus === 'C') {
                                this.finalReportArray[index].emailStatus = 'Cancelled';
                            } else if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime === null) {
                                this.finalReportArray[index].emailStatus = 'Authorization Failed';
                            }
                        }
      
      
                          console.log('Array', this.finalReportArray);
      
                          for (var i = 0; i < this.finalReportArray.length; i++) {
                              var status = this.containsObject(this.finalReportArray[i], this.mergedReports);
                              if (status) {
                                  var idx = this.mergedReports.findIndex(c => c.masterAwbNo == this.finalReportArray[i].masterAwbNo);
                                  this.mergedReports[idx].type = this.finalReportArray[i].reportType;
                                  this.mergedReports[idx].extrenalpath = this.finalReportArray[i].reportPath;
                              } else {
                                  this.mergedReports.push(this.finalReportArray[i]);
                              }
                          };
      
      
                          for (var i = 0; i < this.mergedReports.length; i++) {
                              
                              if (this.mergedReports[i].extrenalpath) {
                                      let epath1 = this.mergedReports[i].extrenalpath;
                                      let externalPath = epath1.split("/");
                                      this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                                      this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + externalPath[1];
                              }
                              if (this.mergedReports[i].reportPath) {
                                      let ipath1 = this.mergedReports[i].reportPath;
                                      let externalPath = ipath1.split("/");
                                      this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                                      this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + externalPath[1];
                              }
                          }
                          if (this.subscribedParam == 'All') {
                              this.mergedReports = this.mergedReports;
                          } else if (this.subscribedParam == 'AuthrisedUsers') {
                              this.AuthUserReport = [];
                              for (let i = 0; i < this.usersList.length; i++) {
                                  for (let j = 0; j < this.mergedReports.length; j++) {
                                      if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                          if (this.usersList[i].adminAuth == 'A' || this.usersList[i].superAdminAuth == 'A') {
                                              this.AuthUserReport.push(this.mergedReports[j]);
                                          }
                                      }
                                  }
                              }
                              this.mergedReports = this.AuthUserReport;
                          } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                              for (let i = 0; i < this.usersList.length; i++) {
                                  for (let j = 0; j < this.mergedReports.length; j++) {
                                      if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                          if (this.usersList[i].adminAuth == 'I' && this.usersList[i].superAdminAuth == 'I') {
                                              this.UnAuthUserReport.push(this.mergedReports[j]);
                                          }
                                      }
                                  }
                              }
                              this.mergedReports = this.UnAuthUserReport;
                          }
      
      
                          if (this.mergedReports.length != 0) {
                             
                              for (let i = 0; i < this.mergedReports.length; i++) {
                                  let val = this.transformDate(this.mergedReports[i].incidentDate);
                                  this.mergedReports[i].incidentDate = val;
                                  let val2 = this.transformDate(this.mergedReports[i].flightDate);
                                  this.mergedReports[i].flightDate = val2;
                              }
                          }
      
                          ;
                          if (this.airlineDetails !== undefined && this.airlineDetails !== null) {
                              for (let i = 0; i < this.airlineDetails.length; i++) {
                                  for (let j = 0; j < this.mergedReports.length; j++) {
                                      if (this.airlineDetails[i].masterAwbNo === this.mergedReports[j].masterAwbNo) {
                                          if (this.mergedReports[j].stationCodeStatus === undefined) {
                                              if (this.airlineDetails[i].stationCode === this.mergedReports[j].stationCode) {
                                                  this.mergedReports[j].stationCodeStatus = this.airlineDetails[i].status;
                                              }
                                          }
      
                                          if (this.mergedReports[j].originStationStatus === undefined) {
                                              if (this.airlineDetails[i].stationCode === this.mergedReports[j].originStation) {
                                                  this.mergedReports[j].originStationStatus = this.airlineDetails[i].status;
                                              }
                                          }
      
                                          if (this.mergedReports[j].destStationStatus === undefined) {
                                              if (this.airlineDetails[i].stationCode === this.mergedReports[j].destStation) {
                                                  this.mergedReports[j].destStationStatus = this.airlineDetails[i].status;
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                          let selectedreports = JSON.parse(localStorage.getItem('reportstabAllreports'));
                          localStorage.setItem("reportsTabnav", JSON.stringify(selectedreports));
                          this.mergedReportsFinal = this.mergedReports;
                          this.finalReportsCount =    data.finalReportsCount;
                          this.internalReportCount =    (data.internalReportsCount !== null && data.internalReportsCount !== undefined ) ? data.internalReportsCount : 0;
                           

                          let selected_Tab  = JSON.parse(localStorage.getItem("sideReportsTab")) ;
                          if(selected_Tab === 'Active'){
                              document.getElementById('archieved').className = 'nav-link';
                              document.getElementById('active').className = 'nav-link active';
                              document.getElementById('internal_Reports').className = 'nav-link';
                              document.getElementById('dashboard').className = 'nav-link';
                          }else if(selected_Tab === 'Internal'){
                            document.getElementById('archieved').className = 'nav-link';
                            document.getElementById('active').className = 'nav-link';
                            document.getElementById('internal_Reports').className = 'nav-link active' ;
                            document.getElementById('dashboard').className = 'nav-link';
                          }
                          else{
                                document.getElementById('archieved').className = 'nav-link active'  ;
                                document.getElementById('active').className = 'nav-link';
                                document.getElementById('internal_Reports').className = 'nav-link';
                                document.getElementById('dashboard').className = 'nav-link';
                          }
                          data.Rows = this.mergedReports
                          this.source.totalrecords = data.TotalRows;
                          ViewReprotsComponent.totaldata  = this.mergedReports


                          if(ViewReprotsComponent.TOTAL_GRIDDATA.length === 0){
                            ViewReprotsComponent.TOTAL_GRIDDATA =  data.userReports
                          }else{
                            ViewReprotsComponent.TOTAL_GRIDDATA = ViewReprotsComponent.TOTAL_GRIDDATA.concat(data.userReports) ;
                          }


      ;       this.spinner.hide()
                        
                      }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){ 
                        this.toastr.info("Your session is expired, please login again");
                        this.spinner.hide();
                        
                        localStorage.clear();
                        if(this.router.url === "/Home"){
                            this.router.navigateByUrl('/');
                        }else{
                            this.router.navigateByUrl('/Home');
                        }
                    } else{
                        ViewReprotsComponent.totaldata = [];
                          data.Rows = [];
                          this.source.totalrecords = 0;
                          this.finalReportsCount = 0
                          this.spinner.hide()
                      }
                    
              },
            pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
                // callback called when a page or page size is changed.
                console.log(pagenum);
            },
           
        };


    linkrenderer = function (row, column, value, data) {

        var html = "<a  style='margin-left: 11px;' href='" + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
        return html;
    }

    dataAdapter = new jqx.dataAdapter(this.source,
        {
            //Set the http header before calling the api.
            beforeSend: function (jqXHR, settings) {
                this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
                this.token = this.loginresultobject.token;
                jqXHR.setRequestHeader('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0'),
                jqXHR.setRequestHeader('pragma', 'no-cache'),
                jqXHR.setRequestHeader('expires', '0'),
                jqXHR.setRequestHeader('authorization',  this.token);
            }
        });
    columns =
        [
            {
                text: 'Carrier', datafield: 'emailStatus', width: 160,
                cellclassname: function (row, column, value, data) {
                    let result = " ";
                    if (data.emailStatus === "FinalTimer") {
                        return data.emailStatus = "Not sent to airline";
                    }
                    if (data.emailStatus === "Extrenal email sent to Airlines") {
                        return data.emailStatus = "sent to airline";
                    }
                    if (data.emailStatus === "Emails are not found") {
                        return data.emailStatus = "Emails not found";
                    }
                    if (data.emailStatus === "Internal email sent") {
                        return data.emailStatus = "sent to airline";
                    }
                    if (data.emailStatus === "Email sent") {
                        return data.emailStatus = "Sent to airline";
                    }
                    if (data.emailStatus === "Authorization Failed") {
                        return data.emailStatus = "Not sent to airline";
                    }
                    if (data.emailStatus === "Hold") {
                        return data.emailStatus = "Not sent to airline";
                    }
                    if (data.emailStatus === "Cancelled") {
                        return data.emailStatus = "Not sent to airline";
                    }
                },
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));

                    if ((ViewReprotsComponent.totaldata.length != 0) && (columndata.uid < ViewReprotsComponent.totaldata.length)) {
                        if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Sent to airline') {
                         
                            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>"

                        }else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Email sent') {
                         
                            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>"

                        }
                        else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Not sent to airline') {
                          
                            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                        }
                        else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Emails are not found') {
                         
                            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger' aria-hidden='true'></i> Not found</div>"

                        }else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'A' || ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'AS') {
                           if(ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'A'){
                            return "<div class='m-archive' ><i class='fa fa-archive' aria-hidden='true'></i> Manual archived</div>";
                           }else{
                            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>"
                           }
                          
                        }
                        else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'P') {
                            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                         
                        } 
                        else {
                            if ((ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'FinalTimer') && (this.loginDetails.orgType === 'SA' || this.loginDetails.orgType === 'H')) {
                              
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                            }

                            else if ((ViewReprotsComponent.totaldata[columndata.uid].emailStatus == 'Extrenal email sent to Airlines') && (this.loginDetails.orgType === 'SA')) {
                                return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span> <img id = 'resend:" + row + "' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>";

                            }

                            else if ((ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Internal email sent') && (this.loginDetails.orgType === 'SA')) {
                                return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span> <img id = 'resend:" + row + "' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>";

                            }

                            else if ((ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Hold') && (this.loginDetails.orgType === 'SA' || this.loginDetails.orgType === 'H')) {
                                // return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                            }

                            else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Cancelled') {
                                // return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                            }
                            else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Authorization Failed') {
                                // return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
                            }
                            else if (ViewReprotsComponent.totaldata[columndata.uid].emailStatus === 'Emails are not found') {
                                // return "<img src='../../../assets/images/mailnotfound.png' class='reportStatus'>"
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger'  aria-hidden='true'></i> Not found</div>"
                            }
                        }
                    }
                },
            },
            {
                text: 'Report Status', datafield: 'damageReportType', cellsalign: 'center', width: 80,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    if (ViewReprotsComponent.totaldata[columndata.uid].damageReportType === 'FINAL') {
                        return "<div style='margin-top:  8.5px; margin-left: 5px; color:black; font-weight:bold'>FINAL</div>"
                    }else if(ViewReprotsComponent.totaldata[columndata.uid].damageReportType === 'DRAFT'){
                        return "<div style='margin-top:  8.5px; margin-left: 5px;color:black; font-weight:bold'>DRAFT</div>"
                    }
                },
            },
            {
                text: 'Actions', datafield: 'actions', cellsalign: 'center', width: 60,filterable: false,sortable:false, groupable:false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    return "<div style='margin-top:  8.5px; margin-left: 5px;'><a  href='edit:" + columndata.uid + "'><i id = 'edit:" + columndata.uid + "' class='fa fa-pencil-square-o' style='padding:0 15px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a></div>";
                },
            },
            { text: 'Internal', datafield: 'internalFileName', width: 70,filterable: false,sortable:false, groupable:false, cellsrenderer: this.linkrenderer },
            { text: 'External', datafield: 'externalFileName', width: 70, filterable: false,sortable:false, groupable:false,cellsrenderer: this.linkrenderer },
            { text: 'Handler Name', datafield: 'orgName', width: 120, },
            { text: 'Airline', datafield: 'airlineName', width: 120 },
            { text: 'Flight-Truck Number', datafield: 'flightTruckNo', width: 120, },
            {
                text: 'Airport Origin', datafield: 'originStation', width: 90,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    if ((ViewReprotsComponent.totaldata.length != 0) && (columndata.uid < ViewReprotsComponent.totaldata.length)) {
                        if (ViewReprotsComponent.totaldata[columndata.uid].originStationStatus === 'Y') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" + value + "</span><img title='Emails Available'  src='../../../assets/images/emails-available.png' alt='Emails available' style='margin-left:5px' height='15' width='18'></div>"
                        } else if (ViewReprotsComponent.totaldata[columndata.uid].originStationStatus === 'N') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" + value + "</span><img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' alt='Emails not available' style='margin-left:5px' height='18' width='18'></div>"
                        }
                    }
                },
            },
            {
                text: 'Airport Destination', datafield: 'destStation', width: 90,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    if ((ViewReprotsComponent.totaldata.length != 0) && (columndata.uid < ViewReprotsComponent.totaldata.length)) {
                        if (ViewReprotsComponent.totaldata[columndata.uid].destStationStatus === 'Y') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" + value + "</span><img title='Emails Available'  src='../../../assets/images/emails-available.png' alt='Emails available' style='margin-left:5px' height='15' width='18'></div>"
                        } else if (ViewReprotsComponent.totaldata[columndata.uid].destStationStatus === 'N') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" + value + "</span><img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' alt='Emails not available' style='margin-left:5px' height='18' width='18'></div>"
                        }
                    }
                },
            },
            { text: 'status', datafield: 'status', hidden: true, },
            // {
            //     text: 'Station', datafield: 'stationCode', width: 80,
            //     cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
            //         if ((ViewReprotsComponent.totaldata.length != 0) && (columndata.uid < ViewReprotsComponent.totaldata.length)) {
            //             if (ViewReprotsComponent.totaldata[columndata.uid].stationCodeStatus === 'Y') {
            //                 return "<div style='margin-top: 8.5px; margin-left: 5px;'>" + value + "<img title='Emails Available'  src='../../../assets/images/emails-available.png' style='margin-left:5px' alt='Emails available' height='15' width='18'></div>"
            //             } else if (ViewReprotsComponent.totaldata[columndata.uid].stationCodeStatus === 'N') {
            //                 return "<div style='margin-top: 8.5px; margin-left: 5px;'>" + value + "<img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' style='margin-left:5px' alt='Emails not available' height='18' width='18'></div>"
            //             }
            //         }
            //     },
            // },
            { text: 'Reporting Date', datafield: 'incidentDate', width: 100, cellsformat: 'd-MM-yyyy', filtertype: 'date' },
            { text: 'Master Airway Bill', datafield: 'masterAwbNo', width: 130, },
            {
                text: "Responsibility",
                datafield: "reportStatus",
                cellsrenderer: function (
                  row,
                  columnfield,
                  value,
                  defaulthtml,
                  columnproperties,
                  columndata
                ) {
                  if (
                    ViewReprotsComponent.totaldata[columndata.uid]
                      .reportStatus === "internal"
                  ) {
                    if (ViewReprotsComponent.totaldata[columndata.uid]
                      .rootcauseExists === 'Y') {
                      if (
                        ViewReprotsComponent.totaldata[columndata.uid]
                          .rootcausestatus === 'Y'
                      ) {
                        if(value === 'internal') {
                          return (
                            "<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;&nbsp;<img title='Rootcause shared'  src='../../../assets/images/email-sent.jpg' alt='Emails not available' height='20px' width='20px'></div>"
                          );
                        }
                      } else if (
                        ViewReprotsComponent.totaldata[columndata.uid]
                          .rootcausestatus === 'N'
                      ) {
                        if(value === 'internal') {
                          return (
                            "<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;<img title='Rootcause not shared' style='margin-left: 2px;'  src='../../../assets/images/email-not-sent.jpg' alt='Emails not available' height='25px' width='25px'></div>"
                          );
                        }
                      }
                    } else {
                      return (
                        "<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;&nbsp;<img title='Rootcause not available'  src='../../../assets/images/no-rootcause.png' alt='Emails not available' height='20px' width='20px'></div>"
                      );
                    }
                  } else if(ViewReprotsComponent.totaldata[columndata.uid]
                    .reportStatus === 'external') {
                    return (
                      "<div style='margin-top: 8.5px; margin-left: 7px;'>External</div>"
                    );
                  }
                },
              },
            { text: 'Modified Date', datafield: 'modifiedDate', width: 90, cellsformat: 'd-MM-yyyy', filtertype: 'date'},
            { text: 'Modified Time', datafield: 'modifiedTime', width: 90, },
            { text: 'Created Date and Time', datafield: 'reportCreatedTime', width: 150, },
            // { text: 'Responsibility', datafield: 'reportStatus', width: 100, },
            { text: 'Safety or Life Threat?', datafield: 'safetyLife', width: 150 },
            
            { text: 'Auth Status', datafield: 'authstatus', width: 120 },
            
            { text: 'Transport Type', datafield: 'truckType', width: 100, },
           
            { text: 'Report Prepared By', datafield: 'reportPreparedName', width: 150, },
            { text: 'Shipper Name', datafield: 'shipper', width: 120, },
            { text: 'Consignee Name', datafield: 'consignee', width: 120, },
            { text: 'Issuing Agent', datafield: 'issuingAgent', width: 120 },
            { text: 'Job Title', datafield: 'jobTitle', width: 120, },
            { text: 'Date', datafield: 'flightDate', width: 120, cellsformat: 'd-MM-yyyy', filtertype: 'date' },
            { text: 'Content Nature', datafield: 'contentNature', width: 150 },


            { text: 'Damage to Packing', datafield: 'damgagePacking', width: 150, },
            { text: 'Content Affected?', datafield: 'contentAffected', width: 120, },
            
            { text: 'Classification', datafield: 'classificationType', width: 500, },
            { text: 'Discovered', datafield: 'damageDiscovered', width: 120 },
            { text: 'Caused By/Discovered By', datafield: 'discoveresCaused', width: 200 },

            { text: 'Report Id', datafield: 'reportId', width: 200 ,hidden:true },
{ text: 'user Id', datafield: 'userId', width: 200 ,hidden:true },
{ text: 'first Name', datafield: 'firstName', width: 200 ,hidden:true },

        ];
        rendergridrows = (params: any): any => {
            ;
            params.data.endindex=150
            return params.data;
          }
  


    showshortReports(event) {
        localStorage.setItem("sideReportsTab", JSON.stringify(event));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.userType = this.loginDetails.userType;
        this.spinner.show();
        this.superAdminservice.getSuperAdminShortReports(this.loginDetails.userType).subscribe(
            data => {
                if (data.responseCode === 1) {
                    this.spinner.hide();
                    this.shortReports = data.userReports;
                    if (this.shortReports.length != 0) {
                        console.log(event);
                        this.isShowAll = true;
                        this.isShowArch = false;
                        this.mergedReportsFinal = this.shortReports;
                        this.mergedReports = this.shortReports;
                        this.airlineDetails = data.airlineDetails;
                        for (let i = 0; i < this.shortReports.length; i++) {

                            if (this.shortReports[i].adminAuth === 'A' || this.shortReports[i].superAdminAuth === 'A') {
                                this.shortReports[i].authstatus = 'Authorized user ';
                            } else if (this.shortReports[i].adminAuth === 'I' && this.shortReports[i].superAdminAuth === 'I') {
                                this.shortReports[i].authstatus = 'Unauthorized user';
                            }
                        }
                        for (var i = 0; i < this.mergedReports.length; i++) {
                            if (this.mergedReports[i].reportPath) {
                                let epath = this.mergedReports[i].reportPath;
                                let externalPath = epath.split("/");
                                this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                            }
                            if (this.mergedReports[i].reportPath) {
                                let ipath = this.mergedReports[i].reportPath;
                                let internalPath = ipath.split("/");
                                this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + internalPath[1];

                            }
                        }
                        for (let index = 0; index < this.shortReports.length; index++) {
                            if (this.shortReports[index].emailStatus === 'S') {
                                this.shortReports[index].emailStatus = 'Sent to airline';
                            } else if (this.shortReports[index].emailStatus === 'N' || this.shortReports[index].emailStatus === 'C') {
                                this.shortReports[index].emailStatus = 'Not sent to airline';
                            }
                            else if (this.shortReports[index].emailStatus === 'E') {
                                this.shortReports[index].emailStatus = 'Emails are not found';
                            }
                        }


                        if (this.airlineDetails !== undefined && this.airlineDetails !== null) {
                            for (let i = 0; i < this.airlineDetails.length; i++) {
                                for (let j = 0; j < this.mergedReports.length; j++) {
                                    if (this.airlineDetails[i].masterAwbNo === this.mergedReports[j].masterAwbNo) {
                                        if (this.mergedReports[j].stationCodeStatus === undefined) {
                                            if (this.airlineDetails[i].stationCode === this.mergedReports[j].stationCode) {
                                                this.mergedReports[j].stationCodeStatus = this.airlineDetails[i].status;
                                            }
                                        }

                                        if (this.mergedReports[j].originStationStatus === undefined) {
                                            if (this.airlineDetails[i].stationCode === this.mergedReports[j].originStation) {
                                                this.mergedReports[j].originStationStatus = this.airlineDetails[i].status;
                                            }
                                        }

                                        if (this.mergedReports[j].destStationStatus === undefined) {
                                            if (this.airlineDetails[i].stationCode === this.mergedReports[j].destStation) {
                                                this.mergedReports[j].destStationStatus = this.airlineDetails[i].status;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    document.getElementById('users').className = 'nav-link';
                    document.getElementById('reports').className = 'nav-link';
                    // document.getElementById('shortReports').className = 'nav-link active';

                    this.myGrid.clearselection();
                    this.myGrid.selectionmode('checkbox');
                    ViewReprotsComponent.totaldata = this.shortReports;

                    // this.source.localdata = this.shortReports;
                    this.myGrid.source(this.dataAdapter);
                    this.myGrid.showcolumn("emailStatus");
                    this.myGrid.showcolumn("actions");
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }
                error => {
                    this.spinner.hide();
                }
            })
    }
    showArchievedReports(event, type) {
        localStorage.setItem("sideReportsTab", JSON.stringify(event));
        localStorage.setItem('selectedreports', JSON.stringify(event));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.userType = this.loginDetails.userType;
        this.orderType = type
        this.spinner.show();
        this.superAdminservice.viewReports(this.userType, this.orderType).subscribe(
            data => {
                if (data.responseCode == 1) {
                    this.obj = data;
                    this.spinner.hide();
                    this.reportsList = [];
                    this.mergedReports = [];
                    this.finalReportArray = data.userReports;
                    this.mergedReports = data.userReports;
                    this.airlineDetails = data.airlineDetails;
                    console.log(this.reportsList);
                    
                    this.finalReportsCount = data.finalReportsCount;
                  this.archiveReportsCount = data.archiveReportsCount;

                    for (let i = 0; i < this.finalReportArray.length; i++) {

                        if (this.finalReportArray[i].adminAuth === 'A' || this.finalReportArray[i].superAdminAuth === 'A') {
                            this.finalReportArray[i].authstatus = 'Authorized user ';
                        } else if (this.finalReportArray[i].adminAuth === 'I' && this.finalReportArray[i].superAdminAuth === 'I') {
                            this.finalReportArray[i].authstatus = 'Unauthorized user';
                        }
                    }

                    for (let index = 0; index < this.finalReportArray.length; index++) {

                        if ((this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime != null) || (this.finalReportArray[index].emailStatus === 'ST')) {
                            this.finalReportArray[index].emailStatus = 'FinalTimer';
                        } else if (this.finalReportArray[index].emailStatus === 'S' && (this.finalReportArray[index].reportStatus === 'external' || this.finalReportArray[index].reportStatus === '' || this.finalReportArray[index].reportStatus === null)) {
                            this.finalReportArray[index].emailStatus = 'Extrenal email sent to Airlines';
                        } else if (this.finalReportArray[index].emailStatus === 'S' && this.finalReportArray[index].reportStatus === 'internal') {
                            this.finalReportArray[index].emailStatus = 'Internal email sent';
                        }else if (this.finalReportArray[index].emailStatus === 'S' && (this.finalReportArray[index].reportStatus === '' || this.finalReportArray[index].reportStatus === null)) {
                            this.finalReportArray[index].emailStatus = 'Email sent';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'E') {
                            this.finalReportArray[index].emailStatus = 'Emails are not found';
                        }
                       
                        else if (this.finalReportArray[index].emailStatus === 'H') {
                            this.finalReportArray[index].emailStatus = 'Hold';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'C') {
                            this.finalReportArray[index].emailStatus = 'Cancelled';
                        } else if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime === null) {
                            this.finalReportArray[index].emailStatus = 'Authorization Failed';
                        }

                    }


                    console.log('Array', this.finalReportArray);

                    for (var i = 0; i < this.finalReportArray.length; i++) {
                        var status = this.containsObject(this.finalReportArray[i], this.mergedReports);
                        if (status) {
                            var idx = this.mergedReports.findIndex(c => c.masterAwbNo == this.finalReportArray[i].masterAwbNo);
                            this.mergedReports[idx].type = this.finalReportArray[i].reportType;
                            this.mergedReports[idx].extrenalpath = this.finalReportArray[i].reportPath;
                           
                        } else {
                           
                            this.mergedReports.push(this.finalReportArray[i]);
                        }
                    };
                   
                    for (var i = 0; i < this.mergedReports.length; i++) {
                        if (this.mergedReports[i].extrenalpath) {
                            // if (this.mergedReports[i].type === 'E') {
                                let epath1 = this.mergedReports[i].extrenalpath;
                                let externalPath = epath1.split("/");
                                this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                            // } else {
                                let epath = this.mergedReports[i].extrenalpath;
                                let internalPath = epath.split("/");
                                this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + internalPath[1];
                            // }
                        }
                        if (this.mergedReports[i].reportPath) {
                            // if (this.mergedReports[i].reportType === 'E') {
                                let ipath1 = this.mergedReports[i].reportPath;
                                let externalPath = ipath1.split("/");
                                this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                            // } else {
                                let ipath = this.mergedReports[i].reportPath;
                                let internalPath = ipath.split("/");
                                this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + internalPath[1];
                            // }
                        }
                    }
                    if (this.subscribedParam == 'All') {
                        this.mergedReports = this.mergedReports;
                    } else if (this.subscribedParam == 'AuthrisedUsers') {
                        this.AuthUserReport = [];
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'A' || this.usersList[i].superAdminAuth == 'A') {
                                        this.AuthUserReport.push(this.mergedReports[j]);
                                    }
                                }

                            }
                        }
                        this.mergedReports = this.AuthUserReport;
                    } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'I' && this.usersList[i].superAdminAuth == 'I') {
                                        this.UnAuthUserReport.push(this.mergedReports[j]);
                                    }
                                }
                            }
                        }
                        this.mergedReports = this.UnAuthUserReport;
                    }


                    if (this.mergedReports.length != 0) {
                        ;
                        for (let i = 0; i < this.mergedReports.length; i++) {
                            let val = this.transformDate(this.mergedReports[i].incidentDate);
                            this.mergedReports[i].incidentDate = val;
                            let val2 = this.transformDate(this.mergedReports[i].flightDate);
                            this.mergedReports[i].flightDate = val2;
                        }
                    }

                    ;
                    if (this.airlineDetails !== undefined && this.airlineDetails !== null) {
                        for (let i = 0; i < this.airlineDetails.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.airlineDetails[i].masterAwbNo === this.mergedReports[j].masterAwbNo) {
                                    if (this.mergedReports[j].stationCodeStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].stationCode) {
                                            this.mergedReports[j].stationCodeStatus = this.airlineDetails[i].status;
                                        }
                                    }

                                    if (this.mergedReports[j].originStationStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].originStation) {
                                            this.mergedReports[j].originStationStatus = this.airlineDetails[i].status;
                                        }
                                    }

                                    if (this.mergedReports[j].destStationStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].destStation) {
                                            this.mergedReports[j].destStationStatus = this.airlineDetails[i].status;
                                        }
                                    }
                                }
                            }
                        }
                    }

                      
                    document.getElementById('reports').className = 'nav-link active';
                    document.getElementById('users').className = 'nav-link';
                    // document.getElementById('shortReports').className = 'nav-link';
                    
                    this.mergedReportsFinal = this.mergedReports
                    

                    ViewReprotsComponent.totaldata = this.mergedReportsFinal






                    console.log("before", this.mergedReports)
                    console.log("after", this.mergedReports)
                    console.log(this.mergedReports);
                  
                    // this.source.localdata = this.mergedReportsFinal;
                    this.myGrid.source(this.dataAdapter);

                    this.show_hide_checkbox = "none"
                    this.mergedReportsFinal = [];
                    localStorage.setItem("reportstabAllreports", JSON.stringify(event));
                    for (var i = 0; i < this.mergedReports.length; i++) {
                        if ((this.mergedReports[i].reportType === 'E' && this.mergedReports[i].emailStatus === 'Extrenal email sent to Airlines') ||
                            (this.mergedReports[i].reportStatus === 'internal' && this.mergedReports[i].emailStatus === 'Internal email sent') ||
                            ((this.mergedReports[i].reportStatus === '' || this.mergedReports[i].reportStatus === null) && (this.mergedReports[i].emailStatus === 'Internal email sent' || this.mergedReports[i].emailStatus === 'Extrenal email sent to Airlines')) ||
                            (this.mergedReports[i].reportStatus === 'external' && this.mergedReports[i].emailStatus === 'A') ||
                            (this.mergedReports[i].reportStatus === 'internal' && this.mergedReports[i].emailStatus === 'A') ||
                            (this.mergedReports[i].reportStatus === '' && this.mergedReports[i].emailStatus === 'A') ||
                            (this.mergedReports[i].reportStatus === null && this.mergedReports[i].emailStatus === 'A') 

                        ) {
                            

                            this.mergedReportsFinal.push(this.mergedReports[i]);


                        } else {
                           
                        }
                    }

                    this.isShowAll = false;
                    this.isShowArch = true;
                    console.log('showArchievedReports', this.mergedReportsFinal);
                    
                    this.myGrid.selectionmode('none');
                    ViewReprotsComponent.totaldata = this.mergedReportsFinal
                    // this.source.localdata = this.mergedReportsFinal;
                    this.myGrid.source(this.dataAdapter);
                    this.myGrid.selectionmode('none');
                    this.myGrid.showcolumn("actions");
                    this.myGrid.showcolumn("emailStatus");
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }
               
            },

            error => {
                this.spinner.hide();
            }
        )


    }



   





    showAllReports(event, type) {
        localStorage.setItem("sideReportsTab", JSON.stringify(event));
        localStorage.setItem('selectedreports', JSON.stringify(event));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.userType = this.loginDetails.userType;
        this.orderType = type
        this.spinner.show();
        this.superAdminservice.viewReports(this.userType, this.orderType).subscribe(
            data => {
                this.mergedReports = [];
                this.reportsList = [];
                this.finalReportArray = []
                this.airlineDetails = []
                if (data.responseCode == 1) {
                    this.obj = data;
                    this.spinner.hide();
                    this.finalReportsCount = data.finalReportsCount;
                  this.archiveReportsCount = data.archiveReportsCount;
                    if(data.userReports.length !== 0){

                    
                    this.finalReportArray = data.userReports;
                    this.airlineDetails = data.airlineDetails;
                    this.mergedReports = data.userReports;
                    console.log(this.reportsList);
                   
                    

                    for (let i = 0; i < this.finalReportArray.length; i++) {

                        if (this.finalReportArray[i].adminAuth === 'A' || this.finalReportArray[i].superAdminAuth === 'A') {
                            this.finalReportArray[i].authstatus = 'Authorized user ';
                        } else if (this.finalReportArray[i].adminAuth === 'I' && this.finalReportArray[i].superAdminAuth === 'I') {
                            this.finalReportArray[i].authstatus = 'Unauthorized user';
                        }
                    }

                    for (let index = 0; index < this.finalReportArray.length; index++) {

                        if ((this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime != null) || (this.finalReportArray[index].emailStatus === 'ST')) {
                            this.finalReportArray[index].emailStatus = 'FinalTimer';
                        } else if (this.finalReportArray[index].emailStatus === 'S' && this.finalReportArray[index].reportStatus === 'external') {
                            this.finalReportArray[index].emailStatus = 'Extrenal email sent to Airlines';
                        } else if (this.finalReportArray[index].emailStatus === 'S' && this.finalReportArray[index].reportStatus === 'internal') {
                            this.finalReportArray[index].emailStatus = 'Internal email sent';
                        }else if (this.finalReportArray[index].emailStatus === 'S' && (this.finalReportArray[index].reportStatus === '' || this.finalReportArray[index].reportStatus === null)) {
                            this.finalReportArray[index].emailStatus = 'Email sent';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'E') {
                            this.finalReportArray[index].emailStatus = 'Emails are not found';
                        }
                       
                        else if (this.finalReportArray[index].emailStatus === 'H') {
                            this.finalReportArray[index].emailStatus = 'Hold';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'C') {
                            this.finalReportArray[index].emailStatus = 'Cancelled';
                        } else if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime === null) {
                            this.finalReportArray[index].emailStatus = 'Authorization Failed';
                        }

                    }


                    console.log('Array', this.finalReportArray);

                    for (var i = 0; i < this.finalReportArray.length; i++) {
                        var status = this.containsObject(this.finalReportArray[i], this.mergedReports);
                        if (status) {
                            var idx = this.mergedReports.findIndex(c => c.masterAwbNo == this.finalReportArray[i].masterAwbNo);
                            this.mergedReports[idx].type = this.finalReportArray[i].reportType;
                            this.mergedReports[idx].extrenalpath = this.finalReportArray[i].reportPath;
                           

                        } else {
                           
                            this.mergedReports.push(this.finalReportArray[i]);
                        }
                    };


                    for (var i = 0; i < this.mergedReports.length; i++) {
                        
                        if (this.mergedReports[i].extrenalpath) {
             
                                let epath1 = this.mergedReports[i].extrenalpath;
                                let externalPath = epath1.split("/");
                                this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                    
                                let epath = this.mergedReports[i].extrenalpath;
                                let internalPath = epath.split("/");
                                this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + internalPath[1];
                         
                        }
                        if (this.mergedReports[i].reportPath) {
                          
                                let ipath1 = this.mergedReports[i].reportPath;
                                let externalPath = ipath1.split("/");
                                this.mergedReports[i].externalFileName = this.ReportsProductionUrl + "externalUserEmailFiles/" + externalPath[1];
                         
                                let ipath = this.mergedReports[i].reportPath;
                                let internalPath = ipath.split("/");
                                this.mergedReports[i].internalFileName = this.ReportsProductionUrl + "emailFiles/" + internalPath[1];
                          
                        }
                    }
                    if (this.subscribedParam == 'All') {
                        this.mergedReports = this.mergedReports;
                    } else if (this.subscribedParam == 'AuthrisedUsers') {
                        this.AuthUserReport = [];
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'A' || this.usersList[i].superAdminAuth == 'A') {
                                        this.AuthUserReport.push(this.mergedReports[j]);
                                    }
                                }

                            }
                        }
                        this.mergedReports = this.AuthUserReport;
                    } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'I' && this.usersList[i].superAdminAuth == 'I') {
                                        this.UnAuthUserReport.push(this.mergedReports[j]);
                                    }
                                }
                            }
                        }
                        this.mergedReports = this.UnAuthUserReport;
                    }


                    if (this.mergedReports.length != 0) {
                        ;
                        for (let i = 0; i < this.mergedReports.length; i++) {
                            let val = this.transformDate(this.mergedReports[i].incidentDate);
                            this.mergedReports[i].incidentDate = val;
                            let val2 = this.transformDate(this.mergedReports[i].flightDate);
                            this.mergedReports[i].flightDate = val2;
                        }
                    }

                    ;
                    if (this.airlineDetails !== undefined && this.airlineDetails !== null) {
                        for (let i = 0; i < this.airlineDetails.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.airlineDetails[i].masterAwbNo === this.mergedReports[j].masterAwbNo) {
                                    if (this.mergedReports[j].stationCodeStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].stationCode) {
                                            this.mergedReports[j].stationCodeStatus = this.airlineDetails[i].status;
                                        }
                                    }

                                    if (this.mergedReports[j].originStationStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].originStation) {
                                            this.mergedReports[j].originStationStatus = this.airlineDetails[i].status;
                                        }
                                    }

                                    if (this.mergedReports[j].destStationStatus === undefined) {
                                        if (this.airlineDetails[i].stationCode === this.mergedReports[j].destStation) {
                                            this.mergedReports[j].destStationStatus = this.airlineDetails[i].status;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                    let selectedreports = JSON.parse(localStorage.getItem('reportstabAllreports'));
                    localStorage.setItem("reportsTabnav", JSON.stringify(selectedreports));
                    this.mergedReportsFinal = this.mergedReports
                
                    console.log("before", this.mergedReports)
                    console.log("after", this.mergedReports)
                    console.log(this.mergedReports);
                   
       

                    this.show_hide_checkbox = "checkbox"
                 
                  
                    localStorage.setItem("reportstabAllreports", JSON.stringify(event));
             
                    this.isShowAll = true;
                    this.isShowArch = false;
                    console.log('showAllReports', this.mergedReportsFinal);
                    this.myGrid.selectionmode('checkbox');
                    this.myGrid.clearselection();
                    ViewReprotsComponent.totaldata = this.mergedReportsFinal
                    // this.source.localdata = this.mergedReportsFinal;
                    this.myGrid.source(this.dataAdapter);
                    this.myGrid.showcolumn("actions")
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }
                document.getElementById('users').className = 'nav-link active';
                document.getElementById('reports').className = 'nav-link';
                // document.getElementById('shortReports').className = 'nav-link';
            },

            error => {
                this.mergedReports = [];
                this.reportsList = [];
                this.finalReportArray = []
                this.airlineDetails = []
                this.mergedReportsFinal =[]
                ViewReprotsComponent.totaldata =  []
                // this.source.localdata = [];
                this.myGrid.source(this.dataAdapter);
                this.spinner.hide();
            }
        )
    }

    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
   

    Rowdoubleclick(event: any): void {
        let reportsform = {
            sidebarReports: "sidebarReports"
        }
        localStorage.setItem("isFromreports", JSON.stringify('viewreports'));
        localStorage.setItem("isFromreportsSidereports", JSON.stringify(reportsform));
        localStorage.setItem("reportStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportStatus));
        localStorage.setItem("awbNumber", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].masterAwbNo));
        localStorage.setItem('awbNumber_reportId', JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportId));
        localStorage.setItem("emailStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].emailStatus));
        this.router.navigate(["/viewdetails"], { queryParams: { from: 'sideBarReports',orgKey :this.logindet.orgId } });
    }

    getSelectedDataOfReport(val, status) {
        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val.data));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataCancel(val, status) {
        ;
        console.log(val, "deleteData");
        localStorage.setItem("Cancelstatus", JSON.stringify(status));
        localStorage.setItem("reportData", JSON.stringify(val.data));
        jQuery('#cancelsingleModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataOfresendReport(val, status) {
        
        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }
    CancelUser() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let Cancelstatus = JSON.parse(localStorage.getItem('Cancelstatus'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: Cancelstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        jQuery('#cancelsingleModal').modal('hide');
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
          
            if (data.responseCode === 1) {
                this.myGrid.updatebounddata("refresh") ;
                this.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.toastr.success(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                // this.spinner.hide();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#cancelsingleModal').modal('hide');
            })
    }
    sendManualReportEmail() {
        ;
         
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }

        jQuery('#myModal').modal('hide');
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
                this.myGrid.updatebounddata("refresh") ;
                this.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.toastr.success(data.message, '', {});

                jQuery('#myModal').modal('hide');
                // this.spinner.hide();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#myModal').modal('hide');
            })

    }
    resendManualReportEmail() {
         
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }

        jQuery('#resendModal').modal('hide');
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
                this.myGrid.updatebounddata("refresh") ;
     this.myGrid.gotopage(0)
     this.selectedRowsData = [];
                this.toastr.success(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                // this.spinner.hide();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#resendModal').modal('hide');
            });


    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }

    notCancel() {
        this.rootcauseshareshow = false;
        jQuery('#Activation').modal('hide');

    }
    disablechbx(e) {
        ;
        console.log('checkbox customize');
      
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";
        if (e.data.emailStatus === a || e.data.emailStatus === b || e.data.emailStatus === c || e.data.emailStatus === d) {
            const instance = CheckBox.getInstance(e.cellElement.querySelector('.dx-select-checkbox'));
            instance.option('disabled', true);
         
            events.off(e.cellElement);
        }

      
    }

    

    onSelectionChanged(e) {
        console.log("selection changed");
        this.dataGrid.instance.getSelectedRowsData().length;

        this.disabledKeys = [];
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";

        const checkarray = e.currentSelectedRowKeys;
        // const checkarray = this.mergedReports;

        for (let i = 0; i < checkarray.length; i++) {
            if (checkarray[i].emailStatus === a || checkarray[i].emailStatus === b || checkarray[i].emailStatus === c || checkarray[i].emailStatus === d) {
                this.disabledKeys.push(checkarray[i]);
            }

        }

     

        if (this.disabledKeys.length > 0) {

            if (this.justDeselected) {
                this.justDeselected = false;
                e.component.deselectAll();
            }
            else {
                this.justDeselected = true;
                e.component.deselectRows(this.disabledKeys);
            }


        }
    }
    OpenEmailPopup(status, event: any) {
      
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to send');
        }

        else {
            let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            let showCheckbox = false;
            if (loginDetails.reportAcess === 'Y') {
                for(let item of this.selectedRowsData) {
                    if (this.selectedRowsData[0].orgRootcauseStatus === 'Y') {
                        this.rootcauseshare = true;
                    }
                    if (item.rootcauseExists === 'Y') {
                        if ((item.rootcausestatus === 'N' || item.rootcausestatus === 'Y') && item.reportStatus === 'internal') {
                            showCheckbox = true;
                        }
                    }
                }
            }
            if (showCheckbox === true) {
                this.rootcauseshareshow = true;
            }
            if(this.checkUnauthReportsExist()){
                jQuery('#unauthReportsExistPopup').modal({ backdrop: 'static', keyboard: false });
            }else{
                this.currentTabName = JSON.parse(localStorage.getItem('sideReportsTab'))
                localStorage.setItem("Emailstatus", JSON.stringify(status));
                jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
            }
        }
    }
  
    cancelUnauthReportsExistPopup(){
        jQuery('#unauthReportsExistPopup').modal("hide");
    }
    checkUnauthReportsExist() : boolean{
        
      let selectedRowsDAtaArray = []
      for (let i = 0; i < this.selectedRowsData.length; i++) {
          selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
      }
      
      if(selectedRowsDAtaArray.length !== 0){
        let isExist = selectedRowsDAtaArray.some((element) => {
            return (element.adminAuth === "I" && element.superAdminAuth === "I");
          });
          return isExist ;
      }
        
    }
   

    sentMultipleEmailReport() {
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            console.log('testing data' + this.selectedRowsData);
            let rootcauseStatus = 'N';

            for (let index = 0; index < this.selectedRowsData.length; index++) {
               
                    // if (this.selectedRowsData[index].emailStatus === 'Not sent to airline' ||
                    //     this.selectedRowsData[index].emailStatus === 'Emails are not found'
                    //     || this.selectedRowsData[index].emailStatus === 'Sent to airline'  
                    //     || this.selectedRowsData[index].emailStatus === 'Email sent' 
                    //     ) {
                        this.userId = this.selectedRowsData[index].userId,
                            this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                            this.stationCode = this.selectedRowsData[index].stationCode,
                            this.classificationType = this.selectedRowsData[index].classificationType,
                            this.firstName = this.selectedRowsData[index].firstName
                            this.currentReportId = this.selectedRowsData[index].reportId,
                            this.carrierId = this.selectedRowsData[index].carrierId
                        this.multipleReportList.push({
                            userId: this.userId,
                            masterAwbNo: this.masterAwbNo,
                            stationCode: this.stationCode,
                            classificationType: this.classificationType,
                            firstName: this.firstName,
                            reportId: this.currentReportId,
                            carrierId: this.carrierId,
                            destairportId: this.selectedRowsData[index].destAirportName,
                            originAirportId: this.selectedRowsData[index].originAirportName,
                            internalResponsibility: this.selectedRowsData[index].reportStatus,
                            rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                            rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                            reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                        });
                    // }
                // }
                console.log("dedeed", this.multipleReportList)
            }
            if (this.rootcauseshare === true) {
                rootcauseStatus = 'Y'
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                rootcause: rootcauseStatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#emailModal').modal('hide');
            
                this.myGrid.clearselection();
                this.toastr.error('No reports to send');
                return false;
            }
            jQuery('#emailModal').modal('hide');
            this.spinner.show();
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
               
                if (data.responseCode === 1) {
                    this.rootcauseshare = false;
                    this.rootcauseshareshow = false;
                    jQuery('#emailModal').modal('hide');
                   
                    this.myGrid.clearselection();
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    // this.spinner.hide();
                    this.toastr.success(data.message, '', {});

                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#emailModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.selectedRowsData = [];
                    this.toastr.error(data.message, '', {});
                    jQuery('#emailModal').modal('hide');
                    this.spinner.hide();
                    this.myGrid.clearselection();
                }
            },
                error => {
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.spinner.hide();
                    jQuery('#emailModal').modal('hide');
                    this.spinner.hide();
                    this.myGrid.clearselection();
                })
        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
    OpenCancelPopup(status) {
       
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to hold');
        } else {

            let selectedRowsDAtaArray = []
            for (let i = 0; i < this.selectedRowsData.length; i++) {
                selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
            }
            
            if(selectedRowsDAtaArray.length !== 0){
              let isExist = selectedRowsDAtaArray.some((element) => {
                  return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
                });
               
                if(isExist){
                    jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
                    return false;
                }else{
                    localStorage.setItem("Emailstatus", JSON.stringify(status));
                    jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
                }
             
            }
           
        }





    }
    cancelunselectSentReportExistPopup(){
        jQuery('#unselectSentReportExistPopup').modal("hide");
    }





    cancelMultipleEmailReport() {

        // if (this.mergedReports.length === 0) {
        //     this.toastr.error('No reports to sent');
        // }



        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            for (let index = 0; index < this.selectedRowsData.length; index++) {

                    if (this.selectedRowsData[index].emailStatus === 'Not sent to airline' ||
                       this.selectedRowsData[index].emailStatus === 'Emails are not found' ||
                       this.selectedRowsData[index].emailStatus === 'FinalTimer' || this.selectedRowsData[index].emailStatus === 'Hold' 
                       ||this.selectedRowsData[index].emailStatus === 'Authorization Failed' ||this.selectedRowsData[index].emailStatus === 'Cancelled'
                       ||this.selectedRowsData[index].emailStatus === 'Emails are not found'
                    ) {
                        this.userId = this.selectedRowsData[index].userId,
                            this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                            this.stationCode = this.selectedRowsData[index].stationCode,
                            this.classificationType = this.selectedRowsData[index].classificationType,
                            this.firstName = this.selectedRowsData[index].firstName
                            
                        this.currentReportId = this.selectedRowsData[index].reportId,
                        this.multipleReportList.push({
                            userId: this.userId,
                            masterAwbNo: this.masterAwbNo,
                            stationCode: this.stationCode,
                            classificationType: this.classificationType,
                            firstName: this.firstName,
                             reportId: this.currentReportId,
                             destairportId: this.selectedRowsData[index].destAirportName,
                             originAirportId: this.selectedRowsData[index].originAirportName,
                             internalResponsibility: this.selectedRowsData[index].reportStatus,
                             rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                             rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                             reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                        });
                    }
                // }
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#cancelModal').modal('hide');
                this.myGrid.clearselection();
                this.toastr.error('No reports to cancel');
                return false;
            }
            this.spinner.show();
            jQuery('#cancelModal').modal('hide');
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
               
                if (data.responseCode === 1) {

                    this.myGrid.clearselection();
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    // this.spinner.hide();
                    this.toastr.success(data.message, '', {});

                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#cancelModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                    this.toastr.error(data.message, '', {});
                    jQuery('#cancelModal').modal('hide');
                    this.myGrid.updatebounddata("refresh") ;
                    this.selectedRowsData = [];
                    this.myGrid.gotopage(0)
                    this.spinner.hide();
                  
                }
            },
                error => {
                    this.spinner.hide();
                    jQuery('#cancelModal').modal('hide');
                    this.myGrid.updatebounddata("refresh") ;
                    this.selectedRowsData = [];
                     this.myGrid.gotopage(0)
                     this.spinner.hide();
                    
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }

    saveGridInstance(e) {
        console.log("referesh");
        console.log(this.dataGrid.instance.getSelectedRowsData());
        this.dataGridInstance = e.component;
        this.dataGridInstance.refresh();
        this.dataGridInstance.clearSelection();
        this.dataGridInstance.deselectRows();
        this.dataGridInstance.deselectAll();
       
    }

    onEditClick(rowData) {

        localStorage.setItem('awbNumber', JSON.stringify(rowData.masterAwbNo));
         localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
        rowData.isFrom = 'EDIT_REPORT';
        rowData.is_sideReports = 'true';
        this.router.navigate(['save-report'], { queryParams: rowData, skipLocationChange: true })

    }

    confirm_delete(){
        if (this.selectedRowsData.length !== 0) {
            jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
        }else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
        
    }

    confirm_manualarchive(){
        if (this.selectedRowsData.length !== 0) {
            jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
        }else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
    }

    DeleteReport(){
        
        if (this.selectedRowsData.length !== 0) {
            this.selreportsarray = [];
        for(let i=0; i<this.selectedRowsData.length; i++){
            this.userId = this.selectedRowsData[i].userId,
            this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
     this.currentReportId = this.selectedRowsData[i].reportId,
            this.selreportsarray.push({
                userId: this.userId,
                awbNumber: this.masterAwbNo,
               reportId: this.currentReportId
            });

        }

        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.loginUserId = this.Object.userId;

            this.finalobj = {
                "loginUserId": this.loginUserId,
                "reportsList": this.selreportsarray
            }
        this.spinner.show();
        this.superAdminservice.DeleteReport(this.finalobj).subscribe(
            data => {
               
                
                if (data.responseCode == '1') {
                   
                    this.toastr.success(data.message, '', {});
                   
                    jQuery('#delrepModal').modal('hide');
                    this.myGrid.clearselection();
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    // this.spinner.hide();
                    this.selectedRowsData = [];
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#delrepModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                    
                    this.toastr.error(data.message, '', {});
                    jQuery('#delrepModal').modal('hide');
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.spinner.hide();
                    this.selectedRowsData = [];
                }
            },
            error => {
               
                this.spinner.hide();
                jQuery('#delrepModal').modal('hide');
                this.myGrid.updatebounddata("refresh") ;
                this.selectedRowsData = [];
                this.myGrid.gotopage(0)
            }
        )
        }else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
    }

    manualarchive(){
        
        if (this.selectedRowsData.length != 0) {
            this.selreportsarray = [];
        for(let i=0; i<this.selectedRowsData.length; i++){
            this.userId = this.selectedRowsData[i].userId,
            this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
            this.currentReportId = this.selectedRowsData[i].reportId,
            this.selreportsarray.push({
                userId: this.userId,
                awbNumber: this.masterAwbNo,
                 reportId: this.currentReportId
            });
        }

        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.loginUserId = this.Object.userId;

            this.finalobj = {
                "loginUserId": this.loginUserId,
                "reportsList": this.selreportsarray
            }
        this.spinner.show();
        this.superAdminservice.SetReportArchive(this.finalobj).subscribe(
            data => {
                
                
                if (data.responseCode == '1') {
                    this.myGrid.clearselection();
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.toastr.success(data.message, '', {});
                    // this.spinner.hide();
                    jQuery('#manarchModal').modal('hide');
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#manarchModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                    this.myGrid.updatebounddata("refresh") ;
                    this.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.toastr.error(data.message, '', {});
                    jQuery('#manarchModal').modal('hide');
                    this.spinner.hide();
                }
            },
            error => {
                this.myGrid.updatebounddata("refresh") ;
                this.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.spinner.hide();
                jQuery('#manarchModal').modal('hide');
            }
        )
        }
        else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
    }



    getDtotalData(){ // return total selected data from all pages 
        return  this.removeDuplicates(ViewReprotsComponent.TOTAL_GRIDDATA)
        
    }
    removeDuplicates(array) {
        let uniq = {};
        return array.filter(obj => !uniq[obj.reportId] && (uniq[obj.reportId] = true))
      }

    public selectedRowsData = [] ;
    _onSelectRow(event){ // trigger when checkbox checked / unchecked 
        ;
        if(Array.isArray(event.args.rowindex)){ 
           if(event.args.rowindex.length !== 0){
            this.selectedRowsData = this.getDtotalData() ;
           }else{
            this.selectedRowsData = [];
           }
        }else{
            if( event.type === "rowunselect"){
                let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
                this.selectedRowsData.splice(index, 1);
            }else{
                var ind = ViewReprotsComponent.TOTAL_GRIDDATA.findIndex(x => x.reportId === event.args.row.reportId);
                event.args.row.carrierId = ViewReprotsComponent.TOTAL_GRIDDATA[ind].carrierId;
                this.selectedRowsData.push(event.args.row) ; 
            }
        }
            console.log("this.selectedRowsData",this.selectedRowsData)
    }
}
