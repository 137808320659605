export class RootCauseModel {
    public categoryId :string;
    public userId:string;
    public masterAwbNo:string;
    public behaviourId:string;
    public behaviourOther:string;
    public rulesId:string;
    public rulesOther:string;
    public equipmentId:string;
    public equipmentOther:string;
    public organisationId:string;
    public organisationOther:string;
    public rootCauseInvestigation:string;
    public correctiveActions:string;
    public capaTypeId:string;
    public capaTypeOther:string;
    public evaluationDone:string='no';
    public evaluationDate:any;



   
    public organisationDesc: any;
    public capaTypeDesc: any;
    public behaviourDesc: any;
    public rulesDesc: any;
    public equipmentDesc: any;
}