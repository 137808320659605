import { Directive, Renderer } from '@angular/core';

@Directive({
  selector: 'input[trim]',
  host: {
    '(keyup)': 'onKeyup($event)'
  }
})
export class TrimDirective {

  constructor (
    private renderer: Renderer,
  ) {}

  onKeyup(event: KeyboardEvent) {
    let value = (<HTMLInputElement>event.target).value.trim();
    this.renderer.setElementProperty(<HTMLInputElement>event.target, 'value', value);
  }

}
