import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DamageReportsService } from "../services/damageReports/damage-reports.service";
import { DxDataGridComponent } from "devextreme-angular";
import { ConfigServiceService } from "src/app/config-service.service";
@Component({
  selector: "app-viewdetails",
  templateUrl: "./viewdetails.component.html",
  styleUrls: ["./viewdetails.component.css"],
  //   providers: [ViewReports]
})
export class ViewdetailsComponent implements OnInit {
  LoginDetails: any;
  webapi: string;
  eachReport: any;
  STATIONS_WITH_EMAILSARRAY: any[];
  sentDate: any;
  @ViewChild("grid") dataGrid: DxDataGridComponent;
  responseData: any;
  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private reportService: DamageReportsService,
     config : ConfigServiceService
  ) {
    this.webapi =    config.getConfig().CARGO_CLAIM_URL
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.eachReport = JSON.parse(localStorage.getItem("eachReport"));
  
    this.fn_toGetEmails();
  }
  //-----------------service to get External Emails card data-----------//
  fn_toGetEmails() {
    this.spinner.show();
    this.reportService
      .getDetailedReportsEmails(this.eachReport.masterAwbNo)
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.STATIONS_WITH_EMAILSARRAY = [];
            this.STATIONS_WITH_EMAILSARRAY = data.EmailData;
            this.responseData = data;
            // if (this.STATIONS_WITH_EMAILSARRAY.length != 0) {
            //   for (let i = 0; i < this.STATIONS_WITH_EMAILSARRAY.length; i++) {
            //     let val = [];
            //     val = this.STATIONS_WITH_EMAILSARRAY[i].emails.split(",");
            //     this.STATIONS_WITH_EMAILSARRAY[i].email = val;
            //   }
            // }
            let DATE_TIME_ARRAY;
            if (data.modifiedTime === "0000-00-00 00:00:00") {
              DATE_TIME_ARRAY = data.createdTime.split(" ");
              this.sentDate = DATE_TIME_ARRAY[0];
            } else {
              DATE_TIME_ARRAY = data.modifiedTime.split(" ");
              this.sentDate = DATE_TIME_ARRAY[0];
            }
          } else if (data.responseCode == 0) {
            this.STATIONS_WITH_EMAILSARRAY = [];
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  //-----------------End service-----------//
}
