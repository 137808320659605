import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import * as jsPDF from "jspdf";
@Component({
  selector: "app-claim-aspects",
  templateUrl: "./claim-aspects.component.html",
  styleUrls: ["./claim-aspects.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ClaimAspectsComponent implements OnInit {
  loginDetails: any;
  orgId: any;
  iregularityDescription: any;
  irregularityCount: any;
  origincount: any;
  destcount: any;
  Irregularityoriginstation: any = [];
  OriginCount: any = [];
  Irregularitydeststation: any = [];
  DestCount: any = [];
  destcountcard: any;
  origincountcard: any;
  test: string;
  inputObj: any;
  currentyear: any;
  irregularOrigin: any = [];
  topirregualr: any = [];
  irregualrdest: any = [];
  topirregulardest: any = [];
  destclaimaspectlabel: any = [];
  DestclaimaspectCount: any = [];
  topDestclaimaspectCount: any = [];
  topdestclaimaspectlabel: any = [];
  claimOrigin: any;
  topOrigin: any;
  claimDest: any;
  topdest: any;
  toporiginFormal: any;
  topdestformal: any;
  claimOriginOne: any = [];
  topOriginOne: any = [];
  claimDestOne: any = [];
  topdestOne: any = [];
  topdestformalOne: any = [];
  toporiginFormalOne: any = [];
  topIrregularityoriginstation: any = [];
  topOriginCount: any = [];
  topDestnCount: any = [];
  topIrregularitydeststation: any = [];

  totalClaimAspectsLabel: any = [];
  totalClaimAspectsCount: any = [];
  topTtotalClaimAspectsLabel: any = [];
  topTtotalClaimAspectscount: any = [];
  scrollable: boolean = false;
  scrollable1: boolean;
  scrollable2: boolean;
  OriginClaimAspctwidth: number;
  graphHeight: number;
  destinationClaimAspectWidth: number;
  OriginClaimAspctWidth: number;
  totalClaimAspectWidth: number;

  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.claimOriginOne = [];
    this.topOriginOne = [];
    this.claimDestOne = [];
    this.topdestOne = [];
    this.toporiginFormalOne = [];
    this.topdestformalOne = [];
    //getting input Object from dashboard
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
    //getting login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    this.currentyear = this.datePipe.transform(new Date(), "yyyy");
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
      
      //claim aspect api integration
      this.spinner.show();
      this.claimcountservice.Claim_aspscts(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.claimOriginOne = data.totalClaimAspects.count;
            this.topOriginOne = data.topTtotalClaimAspects.count;
            this.claimDestOne = data.totalClaimAspectsOrigin.count;
            this.topdestOne = data.topTotalClaimAspectsOrigin.count;
            this.toporiginFormalOne = data.totalClaimAspectsDestn.count;
            this.topdestformalOne = data.topTotalClaimAspectsDestn.count;
            this.totalClaimAspectsLabel = data.totalClaimAspects.labels;
            this.totalClaimAspectsCount = data.totalClaimAspects.count;
            let labelLengthTotal = data.totalClaimAspects.labels.length;
            let labelLengthdest = data.totalClaimAspectsDestn.labels.length;
            let labelLengthorg = data.totalClaimAspectsOrigin.labels.length;
         //dynamic scroll
            if (labelLengthorg > 60) {
              this.scrollable = true;
            } else {
              this.scrollable = false;
            }
            if (labelLengthdest > 60) {
              this.scrollable1 = true;
            } else {
              this.scrollable1 = false;
            }
            if (labelLengthTotal > 60) {
              this.scrollable2 = true;
            } else {
              this.scrollable2 = false;
            }
            //claim aspect bar model chart
            this.OriginClaimAspctWidth = this.getWidth(data.totalClaimAspects.labels.length);
            new Chart(
              <HTMLCanvasElement>document.getElementById("OriginClaimAspct"),
              {
                type: "line",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.totalClaimAspectsLabel,
                  datasets: [
                    {
                      type: "line",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.totalClaimAspectsCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspect",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:20,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 5,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //claim aspect orign on load top 10 bar chart
            this.destinationClaimAspectWidth = this.getWidth(
              data.totalClaimAspectsOrigin.labels.length
            );
            this.topTtotalClaimAspectsLabel = data.topTtotalClaimAspects.labels;
            this.topTtotalClaimAspectscount = data.topTtotalClaimAspects.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("toporiginClaimAspect")
              ),
              {
                type: "line",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.topTtotalClaimAspectsLabel,
                  datasets: [
                    {
                      type: "line",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.topTtotalClaimAspectscount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspect",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:10,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 5,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //claim aspect Destination  bar model chart starts here
            this.destinationClaimAspectWidth = this.getWidth(
              data.totalClaimAspectsOrigin.labels.length
            );
            this.Irregularitydeststation = data.totalClaimAspectsOrigin.labels;
            this.Irregularitydeststation = data.totalClaimAspectsOrigin.labels;
            this.DestCount = data.totalClaimAspectsOrigin.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("destinationClaimAspect")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.Irregularitydeststation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.DestCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspects per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:20,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 5,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //claim aspect Destination  bar top 10 on load chart starts here
            this.topIrregularitydeststation =
              data.topTotalClaimAspectsOrigin.labels;
            this.topDestnCount = data.topTotalClaimAspectsOrigin.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("destinationClaimAspectOne")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.topIrregularitydeststation,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.topDestnCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspects per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:20,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 10,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //claim aspect Destination  bar model chart starts here
            this.totalClaimAspectWidth = this.getWidth(
              data.totalClaimAspectsDestn.labels.length
            );
            this.destclaimaspectlabel = data.totalClaimAspectsDestn.labels;
            this.DestclaimaspectCount = data.totalClaimAspectsDestn.count;
            new Chart(
              <HTMLCanvasElement>document.getElementById("totalClaimAspect"),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.destclaimaspectlabel,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.DestclaimaspectCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspects per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:20,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 5,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //claim aspect Destination  bar top 10 on load chart starts here
            this.topdestclaimaspectlabel =
              data.topTotalClaimAspectsDestn.labels;
            this.topDestclaimaspectCount = data.topTotalClaimAspectsDestn.count;
            new Chart(
              <HTMLCanvasElement>document.getElementById("totalClaimAspectOne"),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.topdestclaimaspectlabel,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.topDestclaimaspectCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Claim aspects per station",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                          // max rotation: -90,
                          // min rotation: -90
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          //lineHeight:20,
                          // max:plugin.settings.maxDataValue,
                          maxTicksLimit: 5,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            this.scrollView();
          } else if (
            data.responseCode === 0 &&
            data.message === "Unauthorized User"
          ) {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      //this.toaster.error(data.message);
      this.origincountcard = 0;
      this.destcountcard = 0;
    }
  }
  //To  download charts method
  onClickDownloadTabCharts() {
    ;
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    const canvas = document.getElementById(
      "OriginClaimAspct"
    ) as HTMLCanvasElement;
    const canvas2 = document.getElementById(
      "destinationClaimAspect"
    ) as HTMLCanvasElement;
    const canvas4 = document.getElementById(
      "totalClaimAspect"
    ) as HTMLCanvasElement;
    var imgData = canvas.toDataURL("image/png");
    // var imgData1 = canvas1.toDataURL("image/png");
    var imgData2 = canvas2.toDataURL("image/png");
    // var imgData3 = canvas3.toDataURL("image/png");
    var imgData4 = canvas4.toDataURL("image/png");
    var imgWidth = 285;
    var pageHeight = 200;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("landscape", "mm", "a4");
    // new jsPDF("landscape", "mm", "a4");
    doc.setFontSize(18);
    var position = 80; // give some top padding to first page
    var text = "Cargo Claim Aspects Charts";
    var textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, 10, text);
    doc.setFontSize(14);
    doc.text(15, 30, "Claim aspects count");
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();
    doc.text(10, 20, "Claim aspects Origin");
    doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();
    doc.text(10, 20, "Claim aspects Destination");
    doc.addImage(imgData4, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight; // top padding for other pages
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save("Claim_Aspects_Charts.pdf");
  
  }
  //show and hide model charts
  showmodelorg() {
    const element = document.getElementById("myModals");
    element.style.display = "";
    element.style.visibility = "visible";
  }
  hidemodelorg() {
    const element = document.getElementById("myModals");
    element.style.display = "block";
    element.style.visibility = "hidden";
  }
  showmodeldest() {
    const element = document.getElementById("myModal");
    element.style.display = "";
    element.style.visibility = "visible";
  }
  hidemodeldest() {
    const element = document.getElementById("myModal");
    element.style.display = "block";
    element.style.visibility = "hidden";
  }
  showmodeldestOne() {
    const element = document.getElementById("Irregulardestination");
    element.style.display = "";
    element.style.visibility = "visible";
  }
  hidemodeldestOne() {
    const element = document.getElementById("Irregulardestination");
    element.style.display = "block";
    element.style.visibility = "hidden";
  }
  //dynamic scroll
  scrollView() {
    setTimeout(() => {
      const elements = document.getElementsByClassName(
        "chartAreaWrapper_scroll60"
      );
      for (let ele of elements as any) {
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
  }
  //dynamic width
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 20) {
        let finalWidth = 150 * number;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}
