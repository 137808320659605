import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from 'src/app/services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
declare var jQuery: any;
import { CookieService } from 'ngx-cookie-service';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { SwitchdashboardService } from '../../services/switch-dashboard/switchdashboard.service'
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    comapanydetails: any;
    userDetails: any;
    public static totalData;
    CompanyLogoimgurl: string;
    CompanyName: string;
    userLoginDetails: any;
    anchors: any;
    editicons: any;
    click: any;
    cookieValue: string;
    constructor(private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private superAdminservice: SuperadminService,
        private spinner: NgxSpinnerService,
        private elementRef: ElementRef,
        private cookieService: CookieService,
        private ViewReportsData: ViewReportsData,
        private SwitchdashboardService: SwitchdashboardService,private locationStr: LocationStrategy,
        ) {
             // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });

         }


    ngAfterViewInit() {
        this.myGrid.refresh();
        
    }

    ngAfterViewChecked() {
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
       
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                } else if ((anchor.innerText === 'Reset') && (href_Valid_Check_Var === 'reset')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                } else if (anchor.innerText === 'Delete' && href_Valid_Check_Var === 'delete') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
                else if (anchor.innerText === 'Switch' && href_Valid_Check_Var === 'switch') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
            }
        })
    }

    ngOnInit() {
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.userLoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        window.scroll(0, 0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        this.route.paramMap.subscribe(params => {
            
            console.log('params', params)
        });

        this.route.queryParamMap.subscribe(queryParams => {
            ;
            console.log('queryparams', queryParams);
            if (queryParams['params'].type === "Forwarder") {
                this.getUsersList();
            }
        });

        if (this.comapanydetails === null) {
            this.CompanyLogoimgurl = '';
            this.CompanyName = this.userLoginDetails.organisationName;
        } else {
            this.CompanyLogoimgurl = this.comapanydetails.orgLogo;
            this.CompanyName = this.comapanydetails.orgName;
        }
    }


    public handleAnchorClick = (event: Event) => {
        
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;

        this.fn_alert(anchor, event);
    }

    fn_alert(anchor, event) {
        
        if (anchor.innerText === 'Edit') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            // this.Onclickedituld(totalRow);
            this.onClickEditUser(totalRow);
        }
        else if (anchor.innerText === 'Delete') {
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
           

            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var);
            this.deleteAgentPopUp(totalRow)

           

        }
        else if (anchor.innerText === 'Switch') {
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            

            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var);
            this.switchDashboard(totalRow)

          

        }

        else if (anchor.innerText === 'Reset') {
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var);
            this.resetpassword(totalRow)
        }

    }

   
    resetpassword(val) {
        
        localStorage.setItem('resetpassobj', JSON.stringify(val));
        this.router.navigateByUrl('/resetpassword');
    }
    switchDashboard(Obj) {
       ;
        let dashBoardObj = {
            emailId: Obj.loginID,
            OrgId: this.comapanydetails.orgId,
            OrgType: this.comapanydetails.orgType,
           
            airlineStatus: Obj.airlineStatus
        }
        localStorage.setItem('SwitchdashBoardObj', JSON.stringify(dashBoardObj));
        this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {
            
            console.log(data)
            

            if (data.responseCode === 1) {
               
                    localStorage.setItem('loginresultobject', JSON.stringify(data));
                    localStorage.setItem("Token",JSON.stringify(data.token));
                    localStorage.setItem("loginDetails",JSON.stringify(data));
                this.cookieService.delete('cclp', '/', '.cclp.aero');
                var expire = new Date();
                var time = Date.now() + ((3600 * 1000) * 8);
                expire.setTime(time);
                this.cookieService.set('cclp', JSON.stringify(data.token), expire, '/', '.cclp.aero',true,"None");
                this.cookieValue = this.cookieService.get('cclp');
                
                let comapanydetails = {
                    orgId: data.orgId,
                    orgLogo: data.orgLogo,
                    orgName: data.organisationName,
                    orgType: data.orgType,
                    userId: data.userId,
                    reportAcess: data.reportAcess
                }
                localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));

                if (data.userType == 'SA') {
                   
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    this.router.navigate(['HandlerDashboardComponent'], {
                      
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                   
                } else if (data.userType == 'S' && data.orgType == 'H') {
                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                       
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                   
                }
                else if (data.userType == 'S' && data.orgType == 'A') {
                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                    
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                   
                } else if (data.orgType === "E") {

                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                        
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                      
                    
                }
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
             else if (data.responseCode === 0) {
                this.toastr.error(data.message);
            }
        },
            error => {
                this.toastr.error(error.message)
            })

    }

    isdashboardSwitched() {
        
        let dashBoradSwitched = {
            'isSwitched': 'yes'
        }
        localStorage.setItem('dashBoradSwitchedtemp', JSON.stringify(dashBoradSwitched));
       
    }
    deleteAgentPopUp(agentData) {
        
        console.log('agentData--- delete', agentData);
        localStorage.setItem("agentDataforDelete", JSON.stringify(agentData));
        jQuery('#deleteModal').modal({ backdrop: 'static', keyboard: false });

    }

    deleteAgent() {
        
        let data = JSON.parse(localStorage.getItem('agentDataforDelete'));
        let dataObject = {
            userId: data.userId,
        }
        this.spinner.show();
        this.superAdminservice.deleteUser(dataObject).subscribe(data => {
            
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', { timeOut: 1200 });
                jQuery('#deleteModal').modal('hide');
                this.ngOnInit();
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#deleteModal').modal('hide');
            } 
            else {
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#deleteModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#deleteModal').modal('hide');
            })
    }
   
    // Edit User start
    onClickEditUser(totalRow) {
        let edistuserData = {
            userId: totalRow.userId,
            userStatus: totalRow.userStatus,
            userType: totalRow.userType
        }
        localStorage.setItem("editUserData", JSON.stringify(edistuserData));
        console.log('totalRow', totalRow);
        let userId = totalRow.userId;
       // this.router.navigate(["/edituser", userId]) //new
       this.router.navigate(["/edituser"], { queryParams: { "userId":userId }}) //new
    }

    editUserData(val) {
        let edistuserData = {
            userId: val.data.userId,
            userStatus: val.data.userStatus,
            userType: val.data.userType
        }
        localStorage.setItem("editUserData", JSON.stringify(edistuserData));
        console.log(val, "editdata");
        let userObj = val.data;
        let userId = val.data.userId;
        // this.router.navigate(["/edituser", userId])//new 
        this.router.navigate(["/edituser"], { queryParams: { "userId":userId }})//new 

    }
    getUsersList() {
        
        this.spinner.show();
        this.superAdminservice.viewUsers(this.comapanydetails.orgId).subscribe(data => {
            
            if (data['responseCode'] === 1) {
                this.userDetails = data['userDetails'];
                console.log('this.userDetails ', this.userDetails);

               
                this.userDetails.forEach(user => {
                    
                    console.log(user);
                    if (user.adminAuth === 'I' && user.superAdminAuth === 'I') {
                        user.finalAuth = 'I';
                    } else {
                        user.finalAuth = 'A';
                    }
                    console.log('finaluser object', user);
                });
                console.log('final userDetails object', this.userDetails);
                UsersListComponent.totalData = this.userDetails;
                this.source.localdata = this.userDetails;
                this.myGrid.source(this.dataAdapter);
                this.spinner.hide();
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            } 
            else {
                this.spinner.hide();

            }


        })
    }

    public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY
    source = {
        localdata: this.userDetails,
        datatype: 'json',
        datafields: [
            { name: 'activities', type: 'string' },
            { name: 'firstName', type: 'string' },
            { name: 'lastName', type: 'string' },
            { name: 'loginID', type: 'string' },
            { name: 'stationCode', type: 'string' },
            { name: 'finalAuth', type: 'string' },
            { name: 'address', type: 'string' },
            { name: 'userType', type: 'string' },
            { name: 'userStatus', type: 'string' },
            { name: 'contactNumber', type: 'string' },
            { name: 'jobTitle', type: 'string' },
            { name: 'userId', type: 'string' },
            { name: 'truckingAccess', type: 'string' },
            { name: 'cclpAccess', type: 'string' }
        ],
        pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
        //     rendergridrows: function (params) {
        //     return params.data;
        // }
    }

    dataAdapter = new jqx.dataAdapter(this.source);

    columns = [
        {
            text: "Activities",
            datafield: "activities",
            columntype: "text", width: 240,
            cellsrenderer: function (row, index, data) {
                
                // <a  href='edit:" + row + "' ><i id = 'edit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'></i>Edit</a>
                return "<div style='margin-top:  8.5px; margin-left: 5px;' > &nbsp <a  href='reset:" + row + "'><i id = 'reset:" + row + "' class='fa fa-lock' aria-hidden='true'></i>Reset</a>&nbsp<a  href='Edit:" + row + "'><i id = 'Edit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'></i>Edit</a>&nbsp <a  href='delete:" + row + "'><i id = 'delete:" + row + "' class='fa fa-trash-o' aria-hidden='true'></i>Delete</a>&nbsp <a  href='switch:" + row + "'><i id = 'switch:" + row + "' class='fa fa-exchange' aria-hidden='true'></i>Switch</a></div>";
            },
        },
        {
            text: "First Name",
            datafield: "firstName",
            columntype: "text", width: 120,
            cellsrenderer: function (row, index, data) {

                if ((UsersListComponent.totalData.length != 0) && (row <UsersListComponent.totalData.length)) {
                    if ((UsersListComponent.totalData[row].reportAcess === 'Y') ) {
                        return '<span id = "text1:' + row + '" style="background: green; cursor: pointer; color: #fff;padding: 2px;position: relative; margin-top:  8.5px;border-radius: 5px;width: 92px; margin-left: 0px;text-align: center;"> Authenticated</span><br><span>' + data + '</span> '
                    }
                }
            },
        },
        { text: 'Last Name', datafield: 'lastName', width: 150, },
        { text: 'Email Id', datafield: 'loginID', width: 150, },
        { text: 'Station', datafield: 'stationCode', width: 150, },
        {
            text: 'Auth status',
            datafield: 'finalAuth',
            width: 150,
            cellclassname: function (row, column, value, data) {
                let result = " ";
                if (data.finalAuth === "I") {
                    return data.finalAuth = "Unauthorized";
                } else {
                    return data.finalAuth = "Authorized";
                }
            },
            cellsrenderer: function (row, index, data) {
                
                if ((UsersListComponent.totalData[row].finalAuth === 'I')) {
                    return `<p style="margin: 8px;"></i> Unauthorized</p>`;
                } else {
                    return `<a ><img style="margin: 8px;" src="../../../assets/images/tick_icon.png" /></a>`;
                }
                //return "<div><i class='fa fa-pencil-square-o' aria-hidden='true'></i><a *ngIf='row ==1' href='edit:"+ row +"'>  Edit</a> &nbsp <i class='fa fa-pencil-square-o' aria-hidden='true'></i><a  href='setfeedback:"+ row +"'>Set Feedback</a></div>";
            },
        },
        { text: 'Address', datafield: 'address', width: 150, },
        {
            text: 'User Type', datafield: 'userType', width: 150,
            cellclassname: function (row, column, value, data) {
                let result = " ";
                if (data.userType === "SA") {
                    return data.finalAuth = "Super admin";
                } else if (data.userType === "S") {
                    return data.finalAuth = "Company super admin";
                } else if (data.userType === "E") {
                    return data.finalAuth = "User";
                } else if (data.userType === "A") {
                    return data.finalAuth = "Admin";
                }
            },
            cellsrenderer: function (row, index, data) {
                
                if ((UsersListComponent.totalData[row].userType === 'SA')) {
                    return `<p style="margin: 8px;"></i>Super Admin</p>`;
                } else if ((UsersListComponent.totalData[row].userType === 'S')) {
                    return `<p style="margin: 8px;"></i>Company super admin</p>`;
                } else if ((UsersListComponent.totalData[row].userType === 'E')) {
                    return `<p style="margin: 8px;"></i>User</p>`;
                } else if ((UsersListComponent.totalData[row].userType === 'A')) {
                    return `<p style="margin: 8px;"></i>Admin</p>`;
                }
                //return "<div><i class='fa fa-pencil-square-o' aria-hidden='true'></i><a *ngIf='row ==1' href='edit:"+ row +"'>  Edit</a> &nbsp <i class='fa fa-pencil-square-o' aria-hidden='true'></i><a  href='setfeedback:"+ row +"'>Set Feedback</a></div>";
            },
        },
        {
            text: 'Status', datafield: 'userStatus', width: 150,
            cellclassname: function (row, column, value, data) {
                let result = " ";
                if (data.userStatus === "A") {
                    return data.finalAuth = "Active";
                } else {
                    return data.finalAuth = "Inactive";
                }
            },
            cellsrenderer: function (row, index, data) {
                
                if ((UsersListComponent.totalData[row].userStatus === 'A')) {
                    return `<p style="margin: 8px;"></i>Active</p>`;
                } else {
                    return `<p style="margin: 8px;"></i>Inactive</p>`;
                }
                //return "<div><i class='fa fa-pencil-square-o' aria-hidden='true'></i><a *ngIf='row ==1' href='edit:"+ row +"'>  Edit</a> &nbsp <i class='fa fa-pencil-square-o' aria-hidden='true'></i><a  href='setfeedback:"+ row +"'>Set Feedback</a></div>";
            },
        },
        { text: 'CDM', datafield: 'truckingAccess', width: 160 },
        { text: 'CCLP Access', datafield: 'cclpAccess', width: 160 },
        { text: 'Phone Number', datafield: 'contactNumber', width: 150, },
        { text: 'Job Title', datafield: 'jobTitle', width: 150, },
        { text: 'Job Title', datafield: 'userId', width: 150, hidden: true },

    ]

    getUsers() {

    }
    Rowdoubleclick($event) {

    }
    onPageChanged($event) {

    }
    onPageSizeChanged($event) {

    }

}
