import { Component, OnInit, enableProdMode, ViewChild, OnDestroy,Renderer2, ElementRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
declare var jQuery: any;
import { Router, ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { ToastrService } from 'ngx-toastr';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { LocationStrategy } from '@angular/common';
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-allusers',
    templateUrl: './allusers.component.html',
    styleUrls: ['./allusers.component.css']
})
export class AllusersComponent implements OnInit {
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    dataGrid1: DxDataGridComponent;
    allUserData: any;
    Always: any
    selectedUserDetailsTemp: any;
    public static totalData =[];
    anchors: any;
    editicons: any;
    icons: any;
    spantext: any;
    spantexts: any;
    constructor(private renderer: Renderer2, private elementRef: ElementRef,private router: Router, 
        private superadminService: SuperadminService, private toastr: ToastrService, private spinner: NgxSpinnerService,private locationStr: LocationStrategy,
        ) { 
             // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
        }
    AllusersList = [{}]

    ngAfterViewInit() {
        this.myGrid.refresh();
          }


          ngAfterViewChecked(){
   
            this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
            this.icons=this.elementRef.nativeElement.querySelectorAll('i');
            this.spantexts=this.elementRef.nativeElement.querySelectorAll('span');
          
            this.anchors.forEach((anchor: HTMLAnchorElement) => {
              
                if(anchor.href != ''){
                    let href_Split_Array =[];
                    href_Split_Array = anchor.href.split(":");
                    let href_Valid_Check_Var = href_Split_Array[0];
                    if( (anchor.innerText === 'Set user roles') && (href_Valid_Check_Var === 'set' ) ){
                        anchor.addEventListener('click', this.handleAnchorClick)
                    }
                    else if( anchor.innerText === 'EditUser Organization'&& href_Valid_Check_Var === 'edit') {
                        anchor.addEventListener('click', this.handleAnchorClick)
                    }
                }  
            })
            this.icons.forEach((icon: HTMLImageElement) => {
             
              if(icon.id != ''){
                  let href_Split_Array =[];
                  href_Split_Array = icon.id.split(":");
                  let href_Valid_Check_Var = href_Split_Array[0];
                  if( href_Valid_Check_Var === 'edit'  ){
                    
                      icon.addEventListener('click', this.handleIconClick)
                  }
                  else if( href_Valid_Check_Var === 'set') {
                    
                      icon.addEventListener('click', this.handleIconClick)
                  }
              }  
          })
          this.spantexts.forEach((spantext: HTMLSpanElement) => {
             
            if(spantext.id != ''){
                let href_Split_Array =[];
                href_Split_Array = spantext.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if( href_Valid_Check_Var === 'text'  ){
                  
                    spantext.addEventListener('click', this.handletextClick)
                }
               
            }  
        })
        }
    ngOnInit() {
        window.scroll(0, 0);
            this.GetAllUsers();
    }
   
    public handleAnchorClick = (event: Event) => {
        ;
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
       
      this.fn_alert(anchor,event);
      } 
      public handleIconClick = (event: Event) => {
        ;
        // Prevent opening anchors the default way
        event.preventDefault();
        const icon = event.target as HTMLImageElement;
       
      this.fn_alert_icon(icon,event);
      } 
      public handletextClick = (event: Event) => {
        ;
        // Prevent opening anchors the default way
        event.preventDefault();
        const spantext = event.target as HTMLSpanElement;
       
      this.fn_alert_text(spantext,event);
      } 
      
      fn_alert(anchor,event){
        
        if(anchor.innerText==='Set user roles'){
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
          href_Split_Array = anchor.href.split(":");
          let href_Valid_Check_Var = href_Split_Array[1];
          let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
          this.allUserData[href_Valid_Check_Var]
          this.setUserRole(this.allUserData[href_Valid_Check_Var]);
         //this.set()

       }else if(anchor.innerText==='EditUser Organization'){
          let href_Split_Array =[];
              href_Split_Array = anchor.href.split(":");
              let href_Valid_Check_Var = href_Split_Array[1];
              let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
              this.allUserData[href_Valid_Check_Var]
              this.editUserOrg(this.allUserData[href_Valid_Check_Var]);
             //this.edit()
             }
        }
      fn_alert_icon(icon,event){
        
        console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
          href_Split_Array = icon.id.split(":");
          let href_Valid_Check_Var = href_Split_Array[1];
        if(href_Split_Array[0]==='set'){
        let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
        this.allUserData[href_Valid_Check_Var]
          this.setUserRole(this.allUserData[href_Valid_Check_Var]);
        
        // this.setUserRole(totalRow);
        // this.set()
        }
        else if(href_Split_Array[0]==='edit'){
          let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
          this.allUserData[href_Valid_Check_Var]
          this.editUserOrg(this.allUserData[href_Valid_Check_Var]);
             // this.editUserOrg(totalRow);
           // this.edit()
              
        }
      
      }
      fn_alert_text(spantext,event){
        
        console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
          href_Split_Array = spantext.id.split(":");
          let href_Valid_Check_Var = href_Split_Array[1];
        if(href_Split_Array[0]==='text'){
        let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
        this.allUserData[href_Valid_Check_Var]
        //  this.setUserRole(this.allUserData[href_Valid_Check_Var]);
        
        // this.setUserRole(totalRow);
         this.authentication(this.allUserData[href_Valid_Check_Var])
        }
       
      
      }

      
      set(){
          alert("u click set")
      }
      edit(){
        alert("u click edit") 
      }
      
    GetAllUsers() {
        let userType = 'SA'
        this.spinner.show();
        this.superadminService.getAllUsers(userType).subscribe(Response => {
            ;
            console.log(Response);
            if (Response.responseCode === 1) {
                this.allUserData = Response.userDetails;
                console.log('TotalUsersData', this.allUserData);
                console.log('%c%s', 'color: #ff0000', 'userscount', this.allUserData.length);
                this.spinner.hide();
            } else if(Response.responseCode === 0 && Response.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            } 
            else {
                this.spinner.hide();
                this.toastr.error(Response.status);
            }
            AllusersComponent.totalData = this.allUserData;
            this.source.localdata = this.allUserData;
                this.myGrid.source(this.dataAdapter);
               
        },
        );
    }
    source =
    {
        localdata: this.allUserData,
        datatype: 'json',
        datafields:
            [{ name: 'actions', type: 'string' },
            { name: 'firstName', type: 'string' },
            { name: 'lastName', type: 'string' },
            { name: 'loginID', type: 'string' },
            {name: 'orgName',type: 'string'},
            { name: 'authstatus',type: 'string'},
            { name: 'stationCode', type: 'string' },
            {name: 'address',type: 'string'},
            { name: 'address2',type: 'string'},
            { name: 'country', type: 'string' },
            {name: 'postal_code',type: 'string'},
            { name: 'userType',type: 'string'},
            { name: 'status', type: 'string' },
            {name: 'contactNumber',type: 'string'},
            { name: 'jobTitle',type: 'string'},
              ],
       // pagesize: 2,
        beforeprocessing: function (data) {
            // this.source.totalrecords = data[0].TotalRows;
        },
        pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
            // callback called when a page or page size is changed.
            console.log(pagenum);
        },
        rendergridrows: function (params) {
            return params.data;
        }
    };
    dataAdapter = new jqx.dataAdapter(this.source);
    columns =
    [
      { text: 'Actions', datafield: 'actions',width:80, 
      cellsrenderer: function (row,index,data) {
        //  
          return "<div style='margin-top:  8.5px; margin-left: 5px;'><a  href='set:"+ row +"'><i id = 'set:"+ row +"' class='fa fa-users' style='padding:0 15px' aria-hidden='true' data-toggle='tooltip' title='Set user roles'></i></a><a  href='edit:"+ row +"'><i id = 'edit:"+ row +"' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='EditUser Organization'></i></a></div>";
      },
    },
        { text: 'First Name', datafield: 'firstName',width:150, },
        { text: 'Last Name', datafield: 'lastName',width:120,},
        { text: 'Email Id', datafield: 'loginID',width:200,},
        { text: 'Organization Name', datafield: 'orgName',width:220 },
        { text: 'Auth Status', datafield: 'authstatus',width:200,
        cellsrenderer: function (row,index,data) {
            ;
            console.log(this.allUserData + 'gfdgdfgd');
            if (AllusersComponent.totalData.length !=0) {
                ;
                if (AllusersComponent.totalData[row].adminAuth === 'A' || AllusersComponent.totalData[row].superAdminAuth === 'A') {
                    return "<a ><img style='margin: 8px;' src='../../../assets/images/tick_icon.png' /></a>"
                }
                else if (AllusersComponent.totalData[row].adminAuth === 'I' && AllusersComponent.totalData[row].superAdminAuth === 'I') {
                    return '<span style="color:red ; margin-left: 5px;">Unauthorized</span><br><span style=" margin-left: 5px;">Click here to</span> <span id = "text:'+ row +'" style="background: green; cursor: pointer; color: #fff;padding: 1px;position: relative; top:-5px;border-radius: 5px;width: 92px; margin-left: 10px;text-align: center;"> Authorize</span>'
                }
            }
          },
    },
        { text: 'Station', datafield: 'stationCode',width:100, },
        { text: 'Address', datafield: 'address',width:150, },
        { text: 'Address2', datafield: 'address2',width:120,},
        { text: 'Country', datafield: 'country',width:120,},
        { text: 'Postal Code', datafield: 'postal_code',width:100,},
        { text: 'User Type', datafield: 'userType',width:200,

        cellclassname: function(row, column, value, data) {
            if (data.userType === 'S') {
                return data.userType = 'Company Super Admin'
            }else if (data.userType === 'SA') {
                return data.userType ='Super Admin'
            }else if (data.userType === 'E') {
                return data.userType ='User'
            }else if (data.userType === 'A') {
                return data.userType = 'Admin'
            }
                    },
        cellsrenderer: function (row,index,data) {
            ;
            if (data === 'S') {
                return '  Company Super Admin'
            }else if (data === 'SA') {
                return 'Super Admin'
            }else if (data === 'E') {
                return 'User'
            }else if (data === 'A') {
                return 'Admin'
            }
          },
    },
        { text: 'Status', datafield: 'status',width:120,
        cellclassname: function(row, column, value, data) {
            if (data.status === 'A') {
                return data.status = 'Active'
            }else if(data.status === 'I'){
                return data.status ='Inactive'
            }
                    },
        cellsrenderer: function (row,index,data) {
            ;
            if (data === 'A') {
                return 'Active'
            }else if(data === 'I') {
                return 'Inactive'
            }
          },},
        { text: 'Phone Number', datafield: 'contactNumber',width:120,},
        { text: 'Job Title', datafield: 'jobTitle',width:120,},
  ];
    authentication(data) {
        ;
        localStorage.setItem("userauthenticate", JSON.stringify(data));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });

        if (data.userStatus === 'I') {
            // this.popUpUsertype = 'inactive'
        } else if (data.userStatus === 'A') {
            // this.popUpUsertype = ''
        }
        this.selectedUserDetailsTemp = data
    }

    authenticate() {
        ;
        let Object = JSON.parse(localStorage.getItem('loginresultobject'));
        let data = JSON.parse(localStorage.getItem('userauthenticate'));

        let dataObject = {
            userId: this.selectedUserDetailsTemp.userId,
            // orgId: this.subscribedParam
            roleType: Object.userType,
            orgId: this.selectedUserDetailsTemp.orgId

        }
        this.spinner.show();
        this.superadminService.authenticate(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.ngOnInit();
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
            } 
             else {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', {});
            })
    }


    sendActivatonLink() {
        ;
        let ActivationData = JSON.parse(localStorage.getItem('ActivationData'));
        console.log('ActivationData', ActivationData);
        jQuery('#myActivateModal').modal('hide');
        jQuery('#myResendLinkModal').modal('hide');
        this.spinner.show();
        this.superadminService.ActivateResentLink(ActivationData).subscribe(data => {
            ;
            this.spinner.hide();
            if (data.responseCode === 1) {
                ;
                this.toastr.success(data.message, '', { timeOut: 1200 })
                this.ngOnInit();
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
                ;
                this.toastr.error(data.message, '', { timeOut: 1200 })
            }
        }, error => {
            console.log(error)
            this.spinner.hide();
        })

    }

    // Activate(){
    //     alert('Activate')
    // }

    Activate(data, event) {
        ;
        let Object = JSON.parse(localStorage.getItem('loginresultobject'));
        var ActivationData = {
            userId: data.data.userId,
            type: event,
            roleType: Object.userType
        }
        localStorage.setItem("ActivationData", JSON.stringify(ActivationData));
        if (event === 'A') {
            jQuery('#myActivateModal').modal({ backdrop: 'static', keyboard: false });
        } else if (event === "R") {
            jQuery('#myResendLinkModal').modal({ backdrop: 'static', keyboard: false });
        }
    }

    setUserRole(val) {
        ;
        val.isfrom = 'allusers';
        localStorage.setItem('userObj', JSON.stringify(val));
        this.router.navigateByUrl('/set-roles');
    }

    editUserOrg(val) {
        ;
        localStorage.setItem('userOrgObj', JSON.stringify(val));
        this.router.navigateByUrl('/edituser-org');
    }
}
