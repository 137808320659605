import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import * as moment from 'moment';
import 'moment-timezone';
import { MatStepper } from "@angular/material";
import { CargoAcceptanceService } from "src/app/services/cargo-acceptance/cargo-acceptance.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { DateAndTimeFunctions } from 'src/app/shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
import { ConfigServiceService } from "../../config-service.service";
import * as watermark from 'watermarkjs';
@Component({
  selector: 'app-create-import-acceptance',
  templateUrl: './create-import-acceptance.component.html',
  styleUrls: ['./create-import-acceptance.component.css']
})
export class CreateImportAcceptanceComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  @ViewChildren("eachPieceRow") eachPieceRowinputs;
  isFirstTabCompleted = false;
  isSecondTabCompleted = false;
  isThirdTabCompleted = false;
  isFourthTabCompleted = false;
  itemsArray = [];
  hwArray: any;
  show_createawb: boolean= false;
  showlabelfield = true;
  orgDetails: any;
  formSubmissionType: any;
  Data: any;
  TypePackingarray = [];
  Locationarray = [];
  public _imagesArrayTotal = [];
  public _checkListImagesArrayTotal = [];
  urls = [];
  CL_img_urls = [];
  storageLocationValue: string;
  storageLocationImagesEmpty = false;
  images: any = [];
  old_Images = [];
  CL_old_Images = [];
  createImportResp: any;
  updateImportResp: any;
  airportOriginList: any;
  airportOriginList_dup: any;
  showairportOriginList: boolean =false;
  originList_SelectFormList:boolean =false;
  masterAwbNo: any;
  h_aw_data: any[];
  deleted_Old_Images: any = [];
  CL_deleted_Old_Images: any = [];
  mawbId: any;
  editData: any;
  isStorageLocChange: boolean;
  isLinear = true;
  importDetails: any;
  import_TotalData_ToUpdate
  webapi: any;
  actualSpices: number;
  actualWeight: number;
  showDGoodsfield: boolean;
  checkedT_d: boolean;
  actual_pieces: number = 0;
  actual_weight: number = 0;
  airportOriginName: string = "";
  constructor(private cargoAcceptanceService: CargoAcceptanceService,   private toastr: ToastrService, private spinner: NgxSpinnerService,
    private route: Router,private dateTimeFunc: DateAndTimeFunctions, private el: ElementRef, private config: ConfigServiceService,) { 
      this.webapi = this.config.getConfig().ULD_Url;
    }

  createImportAceptanceForm = new FormGroup({
    org_id: new FormControl(""),
    loginUserId: new FormControl(""),
    createdOn:  new FormControl(),
    take_in: new FormControl(new Date()),
    mawb: new FormControl("", [Validators.required]),
    airportOrigin: new FormControl("", [Validators.required]),
    consigneeName: new FormControl(""),
    mawb_total_pieces: new FormControl(0),
    mawb_total_weight: new FormControl(0),
    // actual_pieces: new FormControl(0),
    // actual_weight: new FormControl(0),
    cargoAccLocation: new FormControl(""),
    houseAWBs:new FormControl([]),
    address_labels_available: new FormControl("", [Validators.required]),
    dangerous_goods: new FormControl("", [Validators.required]),
    bonded: new FormControl("", [Validators.required]),
    any_damage_noticed: new FormControl("", [Validators.required]),
    houseAWB: new FormControl(""),
    DG_labels_available: new FormControl("", [Validators.required]),
    security_status: new FormControl("", [Validators.required]),
    UN: new FormControl(""),
    proper_shipping: new FormControl(""),
    packing_group: new FormControl(""),
    class: new FormControl(""),
    net_quantity: new FormControl(""),
    remarks: new FormControl(""),
    created: new FormControl(this.convertUTCDateToLocalDate(new Date())),
    images: new FormControl([]),
    import_type: new FormControl('Y'),
    shipmentInfo: new FormControl({}),
    

    
    // identification: new FormControl("Label", [Validators.required]),
    // NA: new FormControl("NA"),
    // label: new FormControl(""),
    // shipment_number: new FormControl(""),
    // destination: new FormControl("", [Validators.required]),
    // order_pieces: new FormControl(""),
    // shipper_name: new FormControl(""),
    // city: new FormControl(""),
    // pieces_weight_array: new FormControl([]),
  });
  public selectedMoment =(new Date()).toISOString();
  ngOnInit() {
    this.hwArray = []
    // this.createImportAceptanceForm.setValue({import_type: 'Y'})
    // this.morehawscall();
    // this.more_awb_num_call();
    this.add_more_hawbs();
    this.showlabelfield = true;
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.createImportAceptanceForm.patchValue({
      org_id: this.orgDetails.orgId,
      loginUserId: this.orgDetails.userId,
    });
    this.get_airport_origin();
    this.isFirstTabCompleted = false;
    this.formSubmissionType = JSON.parse(localStorage.getItem("CARGO_Import_Acceptance_From"));
    this.cargoAcceptanceService
      .getCargoPackingAndLocation(this.orgDetails.userId)
      .subscribe((resp) => {
        this.Data = resp;        
        this.TypePackingarray = this.Data.packing;
        this.Data.packing.map((t) => {
          if(t.description === "Package"){
            t.checked = true;
          }else{
            t.checked = false;
          }
        });
        this.Locationarray = this.Data.location;
        this.Locationarray.map((l) => {
          l.checked = false;
        });
    });
    if (this.formSubmissionType  === "Edit") { 
      this.mawbId   = JSON.parse(localStorage.getItem("CARGO_Import_Acceptance_STORAGE")).mawbId;
      
      this.fn_ToGet_Awb_Data();
      // for (const item of this.itemsArray) {
      //   // item.type_of_packing_other = item.types_of_packing_other;
      //   item.type_of_packing = item.types_of_packing
      //     .split(",")
      //     .map((e) => parseInt(e));
      // }
    }
  }

  fn_ToGet_Awb_Data(){
    let array1: any[];
    let array2: any[];
    if (
      this.mawbId !== null &&
      this.mawbId !== undefined
    ) {
      const inputObj = {
        orgId: this.orgDetails.orgId,
        loginUserId: this.orgDetails.userId,
        cargoId: this.mawbId,
      };
      this.spinner.show();
      this.cargoAcceptanceService.api_To_GetImport_Details(inputObj).subscribe(
        (responseData) => {
          this.editData = responseData;
          this.spinner.hide();
          if(this.formSubmissionType  === "Edit") {
            this.isStorageLocChange = false ;
          }
          if (this.editData.responseCode === 1) {
            this.isFirstTabCompleted = true;
            this.isLinear = false;
            this.importDetails = this.editData.cargoDetails;
            this.actual_pieces = parseInt(this.importDetails.shipmentInfo.total_pieces);
            this.actual_weight = parseInt(this.importDetails.shipmentInfo.total_weight);
            this.createImportAceptanceForm.patchValue({
              orgId: this.importDetails.orgId,
              loginUserId: this.orgDetails.userId,
              createdOn: this.importDetails.created_date_time,
              // take_in: new Date(this.convertUTCDateToLocalDate(this.importDetails.shipmentInfo.take_in)),
              take_in: new Date(this.importDetails.shipmentInfo.take_in),
              mawb: this.importDetails.shipmentInfo.mawb,
              airportOrigin: this.importDetails.shipmentInfo.airportOrigin,
              consigneeName: this.importDetails.shipmentInfo.consigneeName,
              mawb_total_pieces: this.importDetails.shipmentInfo.mawb_total_pieces,
              mawb_total_weight: this.importDetails.shipmentInfo.mawb_total_weight,
              cargoAccLocation: this.importDetails.shipmentInfo.cargoAccLocation,
              // houseAWBs: this.importDetails.houseAwbs,
              // address_labels_available: this.importDetails.houseAwbs[0].address_labels_available,
              // dangerous_goods: this.importDetails.houseAwbs[0].dangerous_goods,
              // bonded: this.importDetails.houseAwbs[0].bonded,
              // any_damage_noticed: this.importDetails.houseAwbs[0].any_damage_noticed,
            });
            // this.hwArray = this.importDetails.houseAwbs;
            this.hwArray = [];
            if(this.importDetails.houseAwbs.length > 0){
              for(var i=0; i<this.importDetails.houseAwbs.length; i++){
                if (this.importDetails.houseAwbs[i].data === undefined || this.importDetails.houseAwbs[i].data === null) {
                  this.hwArray.push({
                    id: Date.now(),
                    houseAWB: this.importDetails.houseAwbs[i].houseAWB,
                    address_labels_available: this.importDetails.houseAwbs[i].address_labels_available,
                    dangerous_goods: this.importDetails.houseAwbs[i].dangerous_goods,
                    bonded: this.importDetails.houseAwbs[i].bonded,
                    any_damage_noticed: this.importDetails.houseAwbs[i].any_damage_noticed,
                    DG_labels_available: this.importDetails.houseAwbs[i].DG_labels_available,
                    UN: this.importDetails.houseAwbs[i].UN,
                    proper_shipping: this.importDetails.houseAwbs[i].proper_shipping,
                    packing_group: this.importDetails.houseAwbs[i].packing_group,
                    class: this.importDetails.houseAwbs[i].class,
                    net_quantity: this.importDetails.houseAwbs[i].net_quantity,
                    data: [{
                      id: Date.now(),
                      type_of_packing: [7],
                      pieces: "",
                      weight: "",
                      length: "",
                      width: "",
                      height: "",
                      type_of_packing_label: "",
                      location: "",
                      images:[]
                    }]
                  });
                }else{
                  
                  this.hwArray.push({
                    id: Date.now(),
                    houseAWB: this.importDetails.houseAwbs[i].houseAWB,
                    address_labels_available: this.importDetails.houseAwbs[i].address_labels_available,
                    dangerous_goods: this.importDetails.houseAwbs[i].dangerous_goods,
                    bonded: this.importDetails.houseAwbs[i].bonded,
                    any_damage_noticed: this.importDetails.houseAwbs[i].any_damage_noticed,
                    DG_labels_available: this.importDetails.houseAwbs[i].DG_labels_available,
                    UN: this.importDetails.houseAwbs[i].UN,
                    proper_shipping: this.importDetails.houseAwbs[i].proper_shipping,
                    packing_group: this.importDetails.houseAwbs[i].packing_group,
                    class: this.importDetails.houseAwbs[i].class,
                    net_quantity: this.importDetails.houseAwbs[i].net_quantity,
                    data: this.importDetails.houseAwbs[i].data
                  });
                  if(this.importDetails.houseAwbs[i].dangerous_goods === "Yes"){
                    this.hwArray[i].showDGoodsfield = true;
                  }
                  for(var t=0; t<this.importDetails.houseAwbs[i].data.length; t++){
                    this.hwArray[i].data[t].type_of_packing = parseInt(this.importDetails.houseAwbs[i].data[t].type_of_packing);
                  }
                }
              }
            }else{
              this.add_more_hawbs();
            }
            
            this.import_TotalData_ToUpdate = this.importDetails;
            this.old_Images = this.importDetails.cargoImages;
            if (this.old_Images === null || this.old_Images === undefined) {
              this.old_Images = [];
            }
            const stepperArray = [
              "firstTab",
              "secondTab",
              "thirdTab",
              "fourthTab",
            ];
            for (const tab of stepperArray) {
              this.goForward1(tab);
            }
            for (let n = 3; n >= 0; n--) {
              this.myStepper.previous();
            }
          } else {
            this.toastr.error(this.editData.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.route.navigateByUrl("/CargoAcceptance/view-import-acceptance");
    }
  }

  add_more_hawbs(){
    if (this.hwArray.length === 0) {
      this.hwArray.push({
        id: Date.now(),
        houseAWB: "",
        // saData: [{
          address_labels_available: "",
          dangerous_goods: "",
          bonded: "",
          any_damage_noticed: "",
          DG_labels_available: "",
          UN: "",
          proper_shipping: "",
          packing_group: "",
          class: "",
          net_quantity: "",
          
        // }],
        data: [{
          id: Date.now(),
          type_of_packing: [1],
          pieces: "",
          weight: "",
          length: "",
          width: "",
          height: "",
          type_of_packing_label: "",
          location: "",
          images:[]
        }]
      });
    } else{
      const obj = this.hwArray[0];
      if (obj.index === 1) {
        this.hwArray.reverse();
      }
      this.hwArray.push({
        id: Date.now(),
        houseAWB: "",
        // saData: [{
          address_labels_available: "",
          dangerous_goods: "",
          bonded: "",
          any_damage_noticed: "",
          DG_labels_available: "",
          
        // }],
        data: [{
          id: Date.now(),
          type_of_packing: [1],
          pieces: "",
          weight: "",
          length: "",
          width: "",
          height: "",
          type_of_packing_label: "",
          location: "",
          images:[]
        }]
      });
    }
  }
  morehawscall(i) {
    this.hwArray[i].data.push({
      id: Date.now(),
      type_of_packing: [1],
      pieces: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      type_of_packing_label: "",
      location: "",
      images:[]
    })
    // if (this.itemsArray.length === 0) {
    //   this.itemsArray.push({
    //     id: Date.now(),
    //     type_of_packing: [7],
    //     type_of_packing_other: "",
    //     type_of_packing_other_show: false,
    //     pieces: "",
    //     length: "",
    //     width: "",
    //     height: "",
    //     type_of_packing_label: "",
    //   });
    // } else {
    //   const obj = this.itemsArray[0];
    //   if (obj.index === 1) {
    //     this.itemsArray.reverse();
    //   }
    //   this.itemsArray.push({
    //     id: Date.now(),
    //     type_of_packing: [7], 
    //     type_of_packing_other: "",
    //     type_of_packing_other_show: false,
    //     pieces: "",
    //     length: "",
    //     width: "",
    //     height: "",
    //     weight: "",
    //     type_of_packing_label: "",
    //   });
    //   setTimeout(() => {
    //     this.eachPieceRowinputs.last.nativeElement.focus();
    //   }, 0);
    // }
  }

  // more_awb_num_call(){
  //   if (this.hwArray.length === 0) {
  //     this.hwArray.push({
  //       id: Date.now(),
  //       house_awb_number: ""
  //     });
  //   }else{
  //     this.morehawscall();
  //     const obj = this.hwArray[0];
  //     if (obj.index === 1) {
  //       this.hwArray.reverse();
  //     }
  //     this.hwArray.push({
  //       id: Date.now(),
  //       house_awb_number: ""
  //     });
  //     setTimeout(() => {
  //       this.eachPieceRowinputs.last.nativeElement.focus();
  //     }, 0);
  //   }
  // }

  onDangerous(val, i) {
    // this.checkedT_d = false;
    if (val.value === "Yes") {
      this.hwArray[i].showDGoodsfield = true;
      // this.showDGoodsfield = true;
    } else {
      this.hwArray[i].showDGoodsfield = false;
      // this.showDGoodsfield = false;
    }
  }

  onClickT_D(event,i): void {
    if (event.value === "Yes") {
      this.hwArray[i].checkedT_d = true;
      this.hwArray[i].UN = "";
      this.hwArray[i].proper_shipping = "";
      this.hwArray[i].packing_group = "";
      this.hwArray[i].class = "";
      this.hwArray[i].net_quantity = "";
      // this.hwArray[i].saData[indx].push({
      //   UN: "",
      //   proper_shipping: "",
      //   packing_group: "",
      //   class: "",
      //   net_quantity: "",
      // })
      // this.checkedT_d = true;
    } else {
      this.hwArray[i].checkedT_d = false;
      // this.checkedT_d = false;
    }
    this.goForward1("secondTab");
  }

  get_airport_origin(){
    this.spinner.show();
    let inputObj = {
      "orgId": this.orgDetails.orgId,
      "loginUserId": this.orgDetails.userId,
    }
    this.cargoAcceptanceService.get_airport_list(inputObj).subscribe(responseData => {
      this.spinner.hide();
      if (responseData['responsecode'] === 1) {
            this.airportOriginList =  responseData['stations'] ;
            this.airportOriginList_dup =  responseData['stations'] ;
      } else {
        this.toastr.error(responseData['message']);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error("something went wrong , please try later");
    })
  }

  onChangeFieldInput(event,showairportOriginList,listName){
    this[listName+"_SelectFormList"] = false;
    if(event.target.value !== '' && event.target.value){
    this[showairportOriginList] = true ;
    this[listName] = this[listName+"_dup"].filter(item=>{
      return item.port_code.toString().toLowerCase().startsWith(event.target.value.toLowerCase());
    });
    if(this[listName].length !== 0){
      this[showairportOriginList] = true ;
    }else{
      this[showairportOriginList] = false ;
    }
    }else{
      this[showairportOriginList] = false;
    }
  }

  validate_Origin_Dest(type:string,item){
    this.createImportAceptanceForm.get('airportOrigin').setValue(item.port_code);
    this.airportOriginName = item.handler_name;
    // this.createImportAceptanceForm.get('airportOriginName').setValue(item.handler_name);
    this.showairportOriginList=false;
    this.originList_SelectFormList =true ;
    this.goForward1('firstTab')
  }
  
  cancelmorehawscall(id, i) {
    this.hwArray[i].data = this.hwArray[i].data.filter((item) => {
      return item.id !== id;
    });
  }

  cancelMoreHwbNum(id) {
    this.hwArray = this.hwArray.filter((item) => {
      return item.id !== id;
    });
  }

  convertUTCDateToLocalDate(date1){
    let dt = this.utcToLocal(date1, moment.tz.guess());
    return  dt === "Invalid Timezone Operator" ? date1 : dt;
  }

  utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
    var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
    var localDateTime
    var hours = zoneValue.split(":")[0]
    var minutes = zoneValue.split(":")[1]
    if (operator === "-") {
        localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else if (operator) {
        localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else {
        localDateTime = "Invalid Timezone Operator"
    }
    return localDateTime
  }

  goForward1(tab){
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        this.createImportAceptanceForm.get("take_in").value !== "" &&
        this.createImportAceptanceForm.get("mawb").value !== "" &&
        this.createImportAceptanceForm.get("airportOrigin").value !== ""
      ) {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        this._setColoursToEach_Stepper_Header("green", 0);
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
      }
    }
    if (tab === "secondTab") {
      this.isSecondTabCompleted = false;
      if (
        this.hwArray[0].address_labels_available !== "" && 
        this.hwArray[0].dangerous_goods !== "" &&
        this.hwArray[0].bonded !== "" &&
        this.hwArray[0].any_damage_noticed !== ""
      ) {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        this._setColoursToEach_Stepper_Header("green", 1);
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 1);
      }
    }
    if (tab === "thirdTab") {
      this.isThirdTabCompleted = false;
      if(this.createImportAceptanceForm.get('import_type').value === 'N'){
        if (this.hwArray[0].houseAWB !== "" && this.hwArray[0].data[0].type_of_packing !== "") {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this._setColoursToEach_Stepper_Header("green", 2);
        } else {
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 2);
        }
      }else{
        if (
          this.hwArray[0].address_labels_available !== "" && 
          this.hwArray[0].dangerous_goods !== "" &&
          this.hwArray[0].bonded !== "" &&
          this.hwArray[0].any_damage_noticed !== "" &&
          this.hwArray[0].houseAWB !== "" &&
          this.hwArray[0].data[0].type_of_packing !== ""
        ){
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this._setColoursToEach_Stepper_Header("green", 2);
        }else{
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 2);
        }
      }
    }
  }

  goForward(stepper, tab): void {
    const stepperArray = ["firstTab", "secondTab", "thirdTab", "fourthTab"];
    for (const tab of stepperArray) {
      this.goForward1(tab);
    }
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        this.createImportAceptanceForm.get("take_in").value !== "" &&
        this.createImportAceptanceForm.get("mawb").value !== "" &&
        this.createImportAceptanceForm.get("airportOrigin").value !== ""  
      ) {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        this.myStepper.next();
      }else{
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
        for (const key of Object.keys(this.createImportAceptanceForm.controls)) {
          if (this.createImportAceptanceForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (key === "take_in") {
              this.toastr.error(`Please fill the Take In`);
              invalidControl.focus();
              break;
            } else {
              this.toastr.error(`Please fill the ${key} field`);
              invalidControl.focus();
              break;
            }
          }
        }
      }
    }
    if (tab === "secondTab") {
      // this.hwArray= [];
      // this.CL_img_urls = [];
      // this.add_more_hawbs();
      // this.isSecondTabCompleted = true;
      this.isSecondTabCompleted = false;
      if (
        this.hwArray[0].address_labels_available !== "" && 
        this.hwArray[0].dangerous_goods !== "" &&
        this.hwArray[0].bonded !== "" &&
        this.hwArray[0].any_damage_noticed !== ""
      ) {
        if(this.hwArray[0].dangerous_goods === "Yes"){
          if(this.hwArray[0].DG_labels_available !== ""){
            this.isFirstTabCompleted = true;
            this.isSecondTabCompleted = true;
            this.isThirdTabCompleted = true;
            this.isFourthTabCompleted = true;
            stepper.next();
          }else{
            this.toastr.error(`Please select the DG labels available`);
            return;
          }
        }
        else {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          stepper.next();
        }
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 1);
        for (const key of Object.keys(this.hwArray[0])) {
          if (this.hwArray[0][key] === "") {
            if (key === "address_labels_available") {
              this.toastr.error(
                `Please select the Address labels available field`
              );
              break;
            }
            if (key === "dangerous_goods") {
              this.toastr.error(`Please select the Dangerous goods`);
              break;
            }
            if (key === "DG_labels_available") {
              this.toastr.error(`Please select the DG labels Available`);
              break;
            }
            if (key === "bonded") {
              this.toastr.error(`Please select the Bonded`);
              break;
            }
            if (key === "security_status") {
              this.toastr.error(`Please select the Security Status`);
              break;
            }
            if (key === "any_damage_noticed") {
              this.toastr.error(`Please select the Any damage noticed`);
              break;
            }
          }
        }
      }
    }
    if (tab === "thirdTab") {
      this.isThirdTabCompleted = false;
      if(this.createImportAceptanceForm.get('import_type').value === 'N'){
        if (this.hwArray[0].houseAWB !== "" && this.hwArray[0].data[0].type_of_packing !== ""){
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this.myStepper.next();
        }else{
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 2);
          for (const key of Object.keys(this.hwArray[0])) {
            if (this.hwArray[0][key] === "") {
              if (key === "houseAWB") {
                this.toastr.error(
                  `Please enter the Address House AWB Number field`
                );
                break;
              }
            }
          }
        }
      }else{
        for(var i=0; i<this.hwArray.length; i++){
          if (
            this.hwArray[i].address_labels_available !== "" && 
            this.hwArray[i].dangerous_goods !== "" &&
            this.hwArray[i].bonded !== "" &&
            this.hwArray[i].any_damage_noticed !== "" &&
            this.hwArray[i].houseAWB !== ""
            // this.hwArray[i].data[i].type_of_packing !== ""
          ){
            if(this.hwArray[i].dangerous_goods === "Yes"){
              if(this.hwArray[i].DG_labels_available !== ""){
                if(i === this.hwArray.length-1){
                  this.isFirstTabCompleted = true;
                  this.isSecondTabCompleted = true;
                  this.isThirdTabCompleted = true;
                  this.isFourthTabCompleted = true;
                  stepper.next();
                }else{
                  +i
                }
              }else{
                this.toastr.error(`Please select the DG labels available`);
                return;
              }
            }else{
              if(i === this.hwArray.length-1){
                this.isFirstTabCompleted = true;
                this.isSecondTabCompleted = true;
                this.isThirdTabCompleted = true;
                this.isFourthTabCompleted = true;
                stepper.next();
              }else{
                +i
              }
            }
          }else{
            this.isFirstTabCompleted = false;
            this.isSecondTabCompleted = false;
            this.isThirdTabCompleted = false;
            this.isFourthTabCompleted = false;
            this._setColoursToEach_Stepper_Header("white", 2);
            for (const key of Object.keys(this.hwArray[i])) {
              if (this.hwArray[i][key] === "") {
                if (key === "address_labels_available") {
                  this.toastr.error(`Please select the Address labels available field`);
                  break;
                }
                if (key === "dangerous_goods") {
                  this.toastr.error(`Please select the Dangerous goods`);
                  break;
                }
                if (key === "DG_labels_available") {
                  this.toastr.error(`Please select the DG labels Available`);
                  break;
                }
                if (key === "bonded") {
                  this.toastr.error(`Please select the Bonded`);
                  break;
                }
                if (key === "security_status") {
                  this.toastr.error(`Please select the Security Status`);
                  break;
                }
                if (key === "any_damage_noticed") {
                  this.toastr.error(`Please select the Any damage noticed`);
                  break;
                }
                if (key === "houseAWB") {
                  this.toastr.error(`Please enter the Address House AWB Number field`);
                  break;
                }
              }
            }
          }
        }
      }
    }
    if (tab === "fourthTab") {
      this.isFourthTabCompleted = true;
      
    }
  }

  onChng_Comp_Selection(event, formControlName: string, PRESENT_STEP: string): void {
    this.createImportAceptanceForm.get(formControlName).patchValue(event.value);
    this.ngOnInit();
    // this.show_createawb = 
    // this.changeStepper_Access(PRESENT_STEP);
  }
  
  blobToBase64(blob, callback) {
    let reader = new FileReader();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    const watermarkOptions = {
      type: 'image/jpeg',
    };
    reader.onload = function () {
      let dataUrl = reader.result;
      dataUrl = blob;
      watermark([dataUrl],watermarkOptions)
      .dataUrl(watermark.text.lowerLeft('', "0px Arial", '#bfbfbf', 0))
      .then(function (img) {
        // const img2 = img;
        let imgElement = new Image();
        imgElement.src = img;
        imgElement.onload = function() {
          let imageWidth = imgElement.width;
          let imageHeight = imgElement.height;
          let fontSize = Math.min(imageWidth, imageHeight) * 0.07; // Adjust the multiplier as needed
          watermark([img],watermarkOptions)
          .dataUrl(watermark.text.lowerLeft(formattedDate, `${fontSize}px Arial`, '#bfbfbf', 1))
          .then(function (img2) {
            callback(img2); // Call the callback with the watermarked image
          });
        }
      });
      // callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  };

  // checkImages(type) {
  //   // this.storageLocationValue = value;
  //   if(type === 'CL'){
  //     if (this.storageLocationValue !== "" && this.CL_img_urls.length === 0) {
  //       this.storageLocationImagesEmpty = true;
  //     } else if (
  //       this.storageLocationValue === "" ||
  //       (this.storageLocationValue != "" && this.CL_img_urls.length !== 0)
  //     ) {
  //       this.storageLocationImagesEmpty = false;
  //     }
  //   } else{
  //     if (this.storageLocationValue !== "" && this.urls.length === 0) {
  //       this.storageLocationImagesEmpty = true;
  //     } else if (
  //       this.storageLocationValue === "" ||
  //       (this.storageLocationValue != "" && this.urls.length !== 0)
  //     ) {
  //       this.storageLocationImagesEmpty = false;
  //     }
  //   }
  // }

  preview(files) {
    this.urls = [];
    if (files.length !== 0) {
      for (const file of files) {
        const image = new Image();
        image.src = "data:image/png;base64," + file;
        this.urls.push(image);
      }
      this.goForward1("fourthTab");
    }
    if (files.length === 0) {
    }
    // this.checkImages('UP');
    // this._isImagesSelected(); // to change color of stepper
  }

  preview_checklist(files, ind, index) {
    this.hwArray[ind].data[index].CL_img_urls = [];
    if (files.length !== 0) {
      for (const file of files) {
        const image = new Image();
        image.src = "data:image/png;base64," + file;
        // this.CL_img_urls.push(image);
        this.hwArray[ind].data[index].CL_img_urls.push({
          src: "data:image/png;base64," + file
        })
      }
      this.goForward1("fourthTab");
    }
    if (files.length === 0) {
    }
    // this.checkImages('CL');
    // this._isImagesSelected(); // to change color of stepper
  }

  
  selectimages(val, type, ind, index) {
    if(type === 'CL'){
      const self = this;
      const images = val.target.files;
      for (let i = 0; i < images.length; i++) {
        const file: File = images[0];
        if (!file.type.match('image.*|application.*')) {
          this.toastr.error("Please select image and document format only.");
          return;
        }
        this.blobToBase64(images[i], function (base64String) {
          const base64 = base64String.split(",")[1];
          self._checkListImagesArrayTotal.push(base64);
          self.hwArray[ind].data[index].images.push({
            imageURL: base64
          })
          self.preview_checklist(self._checkListImagesArrayTotal, ind, index);
          self._checkListImagesArrayTotal = [];
        });
        // this.hwArray[ind].data[index].images.push({
        //   imageURL: self._checkListImagesArrayTotal[i]
        // })
      }
    }else{
      const self = this;
      const images = val.target.files;
      for (let i = 0; i < images.length; i++) {
        const file: File = images[0];
        if (!file.type.match('image.*|application.*')) {
          this.toastr.error("Please select image and document format only.");
          return;
        }
        this.blobToBase64(images[i], function (base64String) {
          const base64 = base64String.split(",")[1];
          self._imagesArrayTotal.push(base64);
          self.preview(self._imagesArrayTotal);
        });
      }
    }
  }

  // selectimages_cheklist(val) {
  //   const self = this;
  //   const images = val.target.files;
  //   for (let i = 0; i < images.length; i++) {
  //     this.blobToBase64(images[i], function (base64String) {
  //       const base64 = base64String.split(",")[1];
  //       self._checkListImagesArrayTotal.push(base64);
  //       self.preview_checklist(self._checkListImagesArrayTotal);
  //     });
  //   }
  // }

  on_Cancel_Image(i: number, type: string, from: string, indxOfmainaray: any, index:any): void {
    if(from === 'CL'){
      if (type === "NEW") {
        this.hwArray[indxOfmainaray].data[index].CL_img_urls.splice(i, 1)
        // this.CL_img_urls.splice(i, 1);
        this._imagesArrayTotal.splice(i, 1);
      } else {
        this.CL_old_Images[i].status = 0;
        let img_split = [];
        img_split = this.CL_old_Images[i].image_path.split("/");
        this.CL_old_Images[i].deleteImageName = img_split[img_split.length - 1];
        this.CL_old_Images[i].imageURL = "";
        this.CL_deleted_Old_Images.push(this.CL_old_Images[i]);
        this.CL_old_Images.splice(i, 1);
      }
      if (this.CL_img_urls.length > 0 || this.CL_old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
    }else{
      if (type === "NEW") {
        this.urls.splice(i, 1);
        this._imagesArrayTotal.splice(i, 1);
      } else {
        this.old_Images[i].status = 0;
        let img_split = [];
        img_split = this.old_Images[i].image_path.split("/");
        this.old_Images[i].deleteImageName = img_split[img_split.length - 1];
        this.old_Images[i].imageURL = "";
        this.deleted_Old_Images.push(this.old_Images[i]);
        this.old_Images.splice(i, 1);
      }
      if (this.urls.length > 0 || this.old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
    }
    
   
    (document.getElementById("img") as HTMLInputElement).value = "";

    // this.checkImages();
  }

  convertToIso(date: Date){
    let dt  = date.toISOString();
    return   dt.substring(0,10)+" "+ dt.substring(11,19);
  }  

  // onSubmitFormData(): void {
    
  //   // if (
  //   //   ((this.createImportAceptanceForm.get("mawb").value !== "" &&
  //   //     this.createImportAceptanceForm.get("label").value !== "" &&
  //   //     this.createImportAceptanceForm.get("mawb").value !== "N/A") ||
  //   //     (this.createImportAceptanceForm.get("mawb").value !== "" &&
  //   //       this.createImportAceptanceForm.get("NA").value !== "" &&
  //   //       this.createImportAceptanceForm.get("mawb").value !== "Label")) &&
  //   //   this.createImportAceptanceForm.get("destination").value !== ""
  //   // )
  //   //  {
  //     // let obj = {
  //     //   org_id: this.createImportAceptanceForm.get('org_id'),
  //     //   loginUserId: this.createImportAceptanceForm.get('loginUserId'),
  //     //   // createdOn: this.createImportAceptanceForm.get('createdOn'),
  //     //   shipmentInfo: {
  //     //     take_in: this.createImportAceptanceForm.get('take_in'),
  //     //     mawb: this.createImportAceptanceForm.get('mawb'),
  //     //     airportOrigin: this.createImportAceptanceForm.get('airportOrigin'),
  //     //     consigneeName: this.createImportAceptanceForm.get('consigneeName'),
  //     //     cargoAccLocation: this.createImportAceptanceForm.get('cargoAccLocation'),
  //     //     total_pieces: this.createImportAceptanceForm.get('total_pieces'),
  //     //     total_weight: this.createImportAceptanceForm.get('total_weight'),
  //     //   },
  //     //   images: this.images,
  //     //   houseAWBs: {
  //     //     houseAWB: this.createImportAceptanceForm.get('houseAWB'),
  //     //     address_labels_available: this.createImportAceptanceForm.get('address_labels_available'),
  //     //     bonded: this.createImportAceptanceForm.get('bonded'),
  //     //     dangerous_goods: this.createImportAceptanceForm.get('dangerous_goods'),
  //     //     DG_labels_available: this.createImportAceptanceForm.get('DG_labels_available'),
  //     //     any_damage_noticed: this.createImportAceptanceForm.get('any_damage_noticed'),
  //     //     UN: this.createImportAceptanceForm.get('UN'),
  //     //     proper_shipping: this.createImportAceptanceForm.get('proper_shipping'),
  //     //     class: this.createImportAceptanceForm.get('class'),
  //     //     packing_group: this.createImportAceptanceForm.get('packing_group'),
  //     //     net_quantity: this.createImportAceptanceForm.get('net_quantity'),
  //     //     hawbTotalPieces: this.createImportAceptanceForm.get('hawbTotalPieces'),
  //     //     hawbTotalWeight: this.createImportAceptanceForm.get('hawbTotalWeight'),
  //     //     data: {
  //     //       type_of_packing: this.createImportAceptanceForm.get('type_of_packing'),
  //     //       pieces: this.createImportAceptanceForm.get('pieces'),
  //     //       weight: this.createImportAceptanceForm.get('weight'),
  //     //       length: this.createImportAceptanceForm.get('length'),
  //     //       width: this.createImportAceptanceForm.get('width'),
  //     //       height: this.createImportAceptanceForm.get('height'),
  //     //       type_of_packing_label: this.createImportAceptanceForm.get('type_of_packing_label'),
  //     //       location: this.createImportAceptanceForm.get('location'),
  //     //       images: []
          
  //     //     }
  //     //   }
  //     // }
  //     this.images = [];
  //     if (this._imagesArrayTotal.length !== 0) {
  //       for (let i = 0; i < this._imagesArrayTotal.length; i++) {
  //         const obj = { imageURL: this._imagesArrayTotal[i] };
  //         this.images.push(obj);
  //       }
  //     }
  //     if (this.createImportAceptanceForm.value.take_in === "") {
  //       this.toastr.error("Please select the take in");
  //       return;
  //     }
  //     if (this.itemsArray.length === 1) {
  //       let abcd = this.itemsArray.filter((data) => {
        
  //         return !(
  //           data.type_of_packing.length === 0 &&
  //           data.pieces === "" &&
  //           data.length === "" &&
  //           data.width === "" &&
  //           data.height === "" &&
  //           data.weight === ""
  //         );
  //       });
  //       if (abcd.length === 0) {
  //         this.itemsArray = [];
  //       }
  //     }
  //     if (this.itemsArray.length > 0) {
  //       const a = this.itemsArray;
      
  //       if (a.length > 0) {
  //         for (let item of a) {
          
  //           if (item.type_of_packing.length !== 0) {
  //             for (let tlValue of item.type_of_packing) {
  //               if (tlValue === "NaN") {
  //                 item.type_of_packing = "";
  //               }
  //             }
  //           } else {
  //             this.toastr.error("Please select the Type of Packing");
  //             return;
  //           }
            
  //         }
  //         if (
  //           this.createImportAceptanceForm.get("cargoAccLocation").value !== "" &&
  //           this.urls.length === 0 &&
  //           this.old_Images.length === 0
  //         ) {
  //           this.goForward(this.myStepper, "thirdTab");
  //           this.toastr.error("Please upload the Pictures");
  //           return;
  //         }
  //         // for (const val of a) {
          
  //         //   val.type_of_packing = val.type_of_packing.join(",");
  //         // }
  //       }
  //       this.createImportAceptanceForm.patchValue({
  //         images: this.images,
  //         // take_in: this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
  //         houseAWBs: a,
  //       });
  //     } else {
  //       this.createImportAceptanceForm.patchValue({
  //         images: this.images,
  //         // take_in:this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
  //         houseAWBs: [],
  //       });
  //       if (
  //         this.createImportAceptanceForm.get("cargoAccLocation").value !== "" &&
  //         this.urls.length === 0 &&
  //         this.old_Images.length === 0
  //       ) {
  //         this.goForward(this.myStepper, "thirdTab");
  //         this.toastr.error("Please upload the Pictures");
  //         this.morehawscall();
  //         return;
  //       }
  //     }
     
  //     this.createImportAceptanceForm.get("createdOn").setValue(this.dateTimeFunc.convertToIso(new Date()));
  //     this.createImportAceptanceForm.patchValue({
  //         shipmentInfo: {
  //         take_in: this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
  //         mawb: this.createImportAceptanceForm.value.mawb,
  //         airportOrigin: this.createImportAceptanceForm.value.airportOrigin,
  //         consigneeName: this.createImportAceptanceForm.value.consigneeName,
  //         cargoAccLocation: this.createImportAceptanceForm.value.cargoAccLocation,
  //         total_pieces: this.createImportAceptanceForm.value.total_pieces,
  //         total_weight: this.createImportAceptanceForm.value.total_weight,
  //       }
  //     });
  //     this.spinner.show();
  //     this.cargoAcceptanceService.createImport(this.createImportAceptanceForm.value)
  //       .subscribe(
  //         (responseData) => {
  //           this.createImportResp = responseData;
  //           this.spinner.hide();
  //           if (this.createImportResp.responseCode === 1) {
  //             this.toastr.success(this.createImportResp.message);
  //             this.route.navigateByUrl(
  //               "/CargoAcceptance/view-cargo-acceptance"
  //             );
  //           } else {
  //             this.toastr.error(this.createImportResp.message);
  //           }
  //         },
  //         (error) => {
  //           this.spinner.hide();
  //         }
  //       );
  //   // } 
  //   // else {
  //   //   this.toastr.error("Please fill the Shipment Information");
  //   // }
  // }

  onSubmitFormData(): void {
    
      this.images = [];
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) {
          const obj = { imageURL: this._imagesArrayTotal[i] };
          this.images.push(obj);
        }
      }
      if (this.createImportAceptanceForm.value.take_in === "") {
        this.toastr.error("Please select the take in");
        return;
      }
      // if (this.itemsArray.length === 1) {
      //   let abcd = this.itemsArray.filter((data) => {
        
      //     return !(
      //       data.type_of_packing.length === 0 &&
      //       data.pieces === "" &&
      //       data.length === "" &&
      //       data.width === "" &&
      //       data.height === "" &&
      //       data.weight === ""
      //     );
      //   });
      //   if (abcd.length === 0) {
      //     this.itemsArray = [];
      //   }
      // }
      // if (this.itemsArray.length > 0) {
      //   const a = this.itemsArray;
      //   if (a.length > 0) {
      //     for (let item of a) {
      //       if (item.type_of_packing.length !== 0) {
      //         for (let tlValue of item.type_of_packing) {
      //           if (tlValue === "NaN") {
      //             item.type_of_packing = "";
      //           }
      //         }
      //       } else {
      //         this.toastr.error("Please select the Type of Packing");
      //         return;
      //       }
      //     }
      //     if (
      //       this.createImportAceptanceForm.get("cargoAccLocation").value !== "" &&
      //       this.urls.length === 0 &&
      //       this.old_Images.length === 0
      //     ) {
      //       this.goForward(this.myStepper, "thirdTab");
      //       this.toastr.error("Please upload the Pictures");
      //       return;
      //     }
      //   }
      //   this.createImportAceptanceForm.patchValue({
      //     images: this.images,
      //     houseAWBs: a,
      //   });
      // } else {
      //   this.createImportAceptanceForm.patchValue({
      //     images: this.images,
      //     houseAWBs: [],
      //   });
      //   if (
      //     this.createImportAceptanceForm.get("cargoAccLocation").value !== "" &&
      //     this.urls.length === 0 &&
      //     this.old_Images.length === 0
      //   ) {
      //     this.goForward(this.myStepper, "thirdTab");
      //     this.toastr.error("Please upload the Pictures");
      //     // this.morehawscall();
      //     return;
      //   }
      // }
     
      this.createImportAceptanceForm.get("createdOn").setValue(this.dateTimeFunc.convertToIso(new Date()));
      // this.createImportAceptanceForm.patchValue({
      //     shipmentInfo: {
      //     take_in: this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
      //     mawb: this.createImportAceptanceForm.value.mawb,
      //     airportOrigin: this.createImportAceptanceForm.value.airportOrigin,
      //     consigneeName: this.createImportAceptanceForm.value.consigneeName,
      //     cargoAccLocation: this.createImportAceptanceForm.value.cargoAccLocation,
      //     total_pieces: this.createImportAceptanceForm.value.total_pieces,
      //     total_weight: this.createImportAceptanceForm.value.total_weight,
      //   }
      // });
      for(var k=0; k<this.hwArray.length; k++){
        this.hwArray[k].detChecklist = [];
        this.hwArray[k].hawbType = "Y";
        for(var l=0; l<this.hwArray[k].data.length; l++){
          this.hwArray[k].data[l].checklistType = "Y";
          this.hwArray[k].data[l].detImages = [];
          this.hwArray[k].hawbTotalPieces += parseInt(this.hwArray[k].data[l].pieces);
          this.hwArray[k].hawbTotalWeight += parseInt(this.hwArray[k].data[l].weight);
        }
      }

      let inp_data = {
        org_id: this.createImportAceptanceForm.value.org_id,
        loginUserId: this.createImportAceptanceForm.value.loginUserId,
        createdOn: this.createImportAceptanceForm.value.createdOn,
        updatedOn: this.createImportAceptanceForm.value.createdOn,
        shipmentInfo: {
          take_in: this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
          mawb: this.createImportAceptanceForm.value.mawb,
          airportOrigin: this.createImportAceptanceForm.value.airportOrigin,
          airportOriginName: this.airportOriginName,
          consigneeName: this.createImportAceptanceForm.value.consigneeName,
          cargoAccLocation: this.createImportAceptanceForm.value.cargoAccLocation,
          total_pieces: this.actual_pieces,
          total_weight: this.actual_weight,
          mawb_total_pieces: this.createImportAceptanceForm.value.mawb_total_pieces,
          mawb_total_weight: this.createImportAceptanceForm.value.mawb_total_weight,
          
        },
        images: this.images,
        houseAWBs: this.hwArray
      }
      // this.spinner.show();
      console.log(inp_data);
      this.cargoAcceptanceService.createImport(inp_data).subscribe(
        (responseData) => {
          this.createImportResp = responseData;
          this.spinner.hide();
          if (this.createImportResp.responseCode === 1) {
            this.toastr.success(this.createImportResp.message);
            this.route.navigateByUrl(
              "/CargoAcceptance/view-import-acceptance"
            );
          } else {
            this.toastr.error(this.createImportResp.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );

    // } 
    // else {
    //   this.toastr.error("Please fill the Shipment Information");
    // }
  }

  onUpdateFormData(){
    this.images = [];
    if (
      this._imagesArrayTotal.length !== 0 ||
      this.deleted_Old_Images.length !== 0
    ) {
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) {
          const obj = {
            id: null,
            status: 1,
            deleteImageName: "",
            imageURL: this._imagesArrayTotal[i],
          };
          this.images.push(obj);
        }
      }
      if (this.deleted_Old_Images.length !== 0) {
        for (let i = 0; i < this.deleted_Old_Images.length; i++) {
          this.images.push(this.deleted_Old_Images[i]);
        }
      }
    } else {
      const obj = {
        id: "",
        status: 0,
        deleteImageName: "",
        imageURL: "",
      };
      this.images.push(obj);
    }
      if (this.createImportAceptanceForm.value.take_in === "") {
        this.toastr.error("Please select the take in");
        return;
      }
      for(var k=0; k<this.hwArray.length; k++){
        this.hwArray[k].detChecklist = [];
        this.hwArray[k].hawbType = "N";
      }
    //   for(var m=0; m<this.hwArray.length; m++){
    //     this.hwArray[m].address_labels_available= this.createImportAceptanceForm.value.address_labels_available,
    //     this.hwArray[m].bonded= this.createImportAceptanceForm.value.bonded,
    //     this.hwArray[m].dangerous_goods= this.createImportAceptanceForm.value.dangerous_goods,
    //     this.hwArray[m].DG_labels_available= this.createImportAceptanceForm.value.DG_labels_available,
    //     this.hwArray[m].any_damage_noticed= this.createImportAceptanceForm.value.any_damage_noticed,
    //     this.hwArray[m].UN= this.createImportAceptanceForm.value.UN,
    //     this.hwArray[m].proper_shipping= this.createImportAceptanceForm.value.proper_shipping,
    //     this.hwArray[m].class= this.createImportAceptanceForm.value.class,
    //     this.hwArray[m].packing_group= this.createImportAceptanceForm.value.packing_group,
    //     this.hwArray[m].net_quantity= this.createImportAceptanceForm.value.net_quantity
    //   // }
    // }
      this.createImportAceptanceForm.get("createdOn").setValue(this.dateTimeFunc.convertToIso(new Date()));
      let inp_data = {
        cargoId: this.mawbId,
        org_id: this.createImportAceptanceForm.value.org_id,
        loginUserId: this.createImportAceptanceForm.value.loginUserId,
        createdOn: this.createImportAceptanceForm.value.createdOn,
        shipmentInfo: {
          take_in: this.convertToIso(new Date(this.createImportAceptanceForm.value.take_in)),
          mawb: this.createImportAceptanceForm.value.mawb,
          airportOrigin: this.createImportAceptanceForm.value.airportOrigin,
          consigneeName: this.createImportAceptanceForm.value.consigneeName,
          cargoAccLocation: this.createImportAceptanceForm.value.cargoAccLocation,
          mawb_total_pieces: this.createImportAceptanceForm.value.mawb_total_pieces,
          mawb_total_weight: this.createImportAceptanceForm.value.mawb_total_weight,
        },
        images: this.images,
        houseAWBs: this.hwArray
      }
      this.spinner.show();
      this.cargoAcceptanceService.updateImport(inp_data).subscribe(
      (responseData) => {
        this.updateImportResp = responseData;
        this.spinner.hide();
        if (this.updateImportResp.responseCode === 1) {
          this.toastr.success(this.updateImportResp.message);
          this.route.navigateByUrl(
            "/CargoAcceptance/view-import-acceptance"
          );
        } else {
          this.toastr.error(this.updateImportResp.message);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );

  }

  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number): void {
   
    const test = Array.from(document.getElementsByClassName("mat-step-icon"));
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  onCancelPage() {
    this.route.navigateByUrl("/CargoAcceptance/view-import-acceptance");
  }

  goBack(stepper, tab) {
    const stepperArray = ["firstTab", "secondTab", "thirdTab", "fourthTab"];
    for (const tab of stepperArray) {
      this.goForward1(tab);
    }
    
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        this.createImportAceptanceForm.get("identification").value === "Label" &&
        this.createImportAceptanceForm.get("label").value === ""
      ) {
        document.getElementById("lable").focus();
        this.toastr.error(`Please fill the label field`);
        return;
      }
      if (
        ((this.createImportAceptanceForm.get("identification").value !== "" &&
          this.createImportAceptanceForm.get("label").value !== "" &&
          this.createImportAceptanceForm.get("identification").value !== "N/A") ||
          (this.createImportAceptanceForm.get("identification").value !== "" &&
            this.createImportAceptanceForm.get("NA").value !== "" &&
            this.createImportAceptanceForm.get("identification").value !== "Label")) &&
        this.createImportAceptanceForm.get("destination").value !== ""
      ) {
        // this.myStepper.next();
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        // setTimeout(() => {
        //   this.goForward1('firstTab');
        //   this.myStepper.next();
        // }, 0);
        this.myStepper.previous();
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
        for (const key of Object.keys(this.createImportAceptanceForm.controls)) {
          if (this.createImportAceptanceForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (
              this.createImportAceptanceForm.get("identification").value === "Label" &&
              this.createImportAceptanceForm.get("label").value === ""
            ) {
              document.getElementById("lable").focus();
              this.toastr.error(`Please fill the label field`);
              break;
            }
            if (key === "destination") {
              this.toastr.error(`Please fill the Destination`);
              invalidControl.focus();
              break;
            } else {
              this.toastr.error(`Please fill the ${key} field`);
              invalidControl.focus();
              break;
            }
          }
        }
      }
    }
    if (tab === "secondTab") {
      this.isSecondTabCompleted = false;
      if (
        this.createImportAceptanceForm.get("dangerous_goods").value !== "" ||
        this.createImportAceptanceForm.get("bonded").value !== "" ||
        this.createImportAceptanceForm.get("address_labels_available").value !== "" ||
        this.createImportAceptanceForm.get("DG_labels_available").value !== "" ||
        this.createImportAceptanceForm.get("security_status").value !== "" ||
        this.createImportAceptanceForm.get("any_damage_noticed").value !== ""
      ) {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        if (
          this.createImportAceptanceForm.get("dangerous_goods").value !== "" &&
          this.createImportAceptanceForm.get("bonded").value !== "" &&
          this.createImportAceptanceForm.get("DG_labels_available").value !== "" &&
          this.createImportAceptanceForm.get("security_status").value !== "" &&
          this.createImportAceptanceForm.get("any_damage_noticed").value !== "" &&
          this.createImportAceptanceForm.get("address_labels_available").value !== ""
        ) {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this.goForward1("secondTab");
          stepper.previous();
          this._setColoursToEach_Stepper_Header("green", 1);
        } else {
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 1);
          for (const key of Object.keys(this.createImportAceptanceForm.controls)) {
            if (this.createImportAceptanceForm.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector(
                '[formcontrolname="' + key + '"]'
              );
              if (key === "address_labels_available") {
                this.toastr.error(
                  `Please select the Address labels available field`
                );
                invalidControl.focus();
                break;
              }
              if (key === "dangerous_goods") {
                this.toastr.error(`Please select the Dangerous goods`);
                break;
              }
              if (key === "DG_labels_available") {
                this.toastr.error(`Please select the DG labels Available`);
                break;
              }
              if (key === "bonded") {
                this.toastr.error(`Please select the Bonded`);
                break;
              }
              if (key === "security_status") {
                this.toastr.error(`Please select the Security Status`);
                break;
              }
              if (key === "any_damage_noticed") {
                this.toastr.error(`Please select the Any damage noticed`);
                break;
              }
            }
          }
        }
      } else {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        stepper.previous();
      }
    }
    if (tab === "thirdTab") {
      this.isThirdTabCompleted = true;
      if (
        this.itemsArray.length > 0 ||
        this.createImportAceptanceForm.get("cargoAccLocation").value !== ""
      ) {
        if (this.itemsArray.length === 1) {
          let abcd = this.itemsArray.filter((data) => {
         
            return !(
              data.type_of_packing.length === 0 &&
              data.pieces === "" &&
              data.length === "" &&
              data.width === "" &&
              data.height === "" &&
              data.weight === ""
            );
          });
          for (let item of abcd) {
           
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
              
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
           
          }
        } else {
          for (let item of this.itemsArray) {
          
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
           
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
           
          }
        }
        // if (
        //   this.createImportAceptanceForm.get("cargoAccLocation").value !== "" &&
        //   this.urls.length === 0 &&
        //   this.old_Images.length === 0
        // ) {
        //   this.toastr.error("Please upload the Pictures");
        //   return;
        // }
        // if (this.editData !== null && this.editData !== undefined && this.editData !== '') {
        //   if (this.editData.importDetails.cargo_acc_location !== this.createImportAceptanceForm.get('cargoAccLocation').value) {
        //     if (this.urls.length === 0) {
        //       this.toastr.error("Please upload the Pictures");
        //       return;
        //     }
        //   }
        // }
        this._setColoursToEach_Stepper_Header("green", 2);
        stepper.previous();
      } else {
        this._setColoursToEach_Stepper_Header("white", 2);
        stepper.previous();
      }
    }
    if (tab === "fourthTab") {
      this.isFourthTabCompleted = true;
      if (this.urls.length > 0 || this.old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
      stepper.previous();
    }
  }

  format_MAwbNum(event){ 
    ;
     if(event !== '' && event){
        let trimmed =event.replace(/-|\s/g,"");
        let val1="" ;
        let val2="" ;
        let val3="" ;
        if (trimmed.length < 4 && trimmed.length !> 3) {
         val1  = trimmed.substr(0, 3);
         this.masterAwbNo = val1;
        }else
        if (trimmed.length >3 && trimmed.length < 9) {
          val1  = trimmed.substr(0, 3);
          val2  = trimmed.substr(3, 4);
          this.masterAwbNo = val1+"-"+val2;
         }
         else if (trimmed.length >8 && trimmed.length < 13) {
          val1  = trimmed.substr(0, 3);
          val2  = trimmed.substr(3, 4);
          val3  = trimmed.substr(7, 4);
          this.masterAwbNo = val1+"-"+val2+" "+val3;
         }
      if(this.masterAwbNo.length === 9){
        document.getElementById("next_ToAwb_flightnumber").focus()
      }
     } 
  }

  countAmount(index) {
    // this.actual_pieces += parseInt(this.hwArray.data[index].pieces);
    // this.actualSpices = 0;
    // this.actualWeight = 0;
    const a = this.hwArray.filter((data) => {
      return data.data[index].pieces !== "" && data.data[index].weight !== "";
    });
    if (a.length !== 0) {
    a.filter((data) => {
      this.actual_pieces += parseInt(data.data[index].pieces);
      this.actual_weight += parseInt(data.data[index].pieces) * parseInt(data.data[index].weight);
    });
    // this.actual_pieces = this.actualSpices;
    // this.actual_weight = this.actualWeight;
    // this.createImportAceptanceForm.patchValue({
    //   actual_pieces: this.actualSpices,
    //   actual_weight: this.actualWeight,
    // });
    }
  }
  // countAmountWeight(index){
  //   this.actual_pieces += parseInt(this.hwArray.data[index].pieces);
  // }

  onlyNumberKey(evt) {
    if(evt.type === "paste" ) {
      let text = (evt.originalEvent || evt).clipboardData.getData('text/plain');
      if(text.match(/^[0-9]+$/) === null){
       evt.preventDefault();
       this.toastr.error("Alphabets not allowed here");
       return false;
      }
    }else{
     const ASCIICode = evt.which ? evt.which : evt.keyCode;
     if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
       return false;
     }
     return true;
    }
   }

   notAllowSpace(event) {
    if (event.target.value.length === 0 && event.which === 32) {
      event.preventDefault();
    }
    const inputValue = event.charCode;
    
    if (
      !(inputValue >= 65 && inputValue <= 122) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault();
    }
    // if (this.createShipmentForm.get("cargoAccLocation").value === "") {
    //   if (this.formSubmissionType === "Edit") {
    //     this.old_Images = this.editData.cargoDetails.cargoImages;
    //   }
    // }
  }
  notAllowSpace1(event) {
    if (event.target.value.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  }
}
