import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCharactersOnlyDirective]'
})
export class CharactersOnlyDirectiveDirective {

  constructor(private _el: ElementRef) { }

 
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
   ;
    if(this._el.nativeElement.name === 'airportOfOrigin' || this._el.nativeElement.name === 'airportOfDestination' || this._el.nativeElement.name ==='stationCode' || this._el.nativeElement.id ==='stationCode'){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\-\s]*/g,'');
    }else if(this._el.nativeElement.name === 'emailId' || this._el.nativeElement.name === 'confirmEmailId'){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\@\.]*/g, '');
    } else if(this._el.nativeElement.name === 'firstName' || this._el.nativeElement.id === 'firstName'|| this._el.nativeElement.name === "ContentNature" || this._el.nativeElement.name === "otherprocessarea" || this._el.nativeElement.name ===  "department"|| this._el.nativeElement.name === "areaSupervisor"||this._el.nativeElement.name ===  "issuingAgent" ||  this._el.nativeElement.name === "reportPreparedFunction" ||this._el.nativeElement.name ===   "reportPreparedName" || this._el.nativeElement.name === 'airline' || this._el.nativeElement.name === 'ConsigneeName' || this._el.nativeElement.name ===  'ShipperName' || this._el.nativeElement.name === "organisationName" || this._el.nativeElement.name === "other" || this._el.nativeElement.name === "city" || this._el.nativeElement.name === "country" || this._el.nativeElement.name === 'namefeild' ||  this._el.nativeElement.id === 'namefeild'){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\s]*/g, '');
    }
   else if(this._el.nativeElement.name === 'address' || this._el.nativeElement.name === 'address2' ){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\!\#\%\&\'\-\/\@\.\s\,]*/g, '');
  }
   else if( this._el.nativeElement.name === "exactLocation"){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\s]*/g, '');
  }
   else if( this._el.nativeElement.name === "latitude" || this._el.nativeElement.name === "longitude" ){
    this._el.nativeElement.value = initalValue.replace(/[^0-9\.]*/g, '');
  }
  else if(this._el.nativeElement.name === 'awb'){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\-\s]*/g, '');
  }
  else if(this._el.nativeElement.name === 'orgname' || this._el.nativeElement.name === 'Organizationname'){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
  }
  
  else if(this._el.nativeElement.name === 'casueOfEvent'){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\.\,\s]*/g, '');
  }
  else if( this._el.nativeElement.name === 'internalName'){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\.\s]*/g, '');
  }
  else if( this._el.nativeElement.name === 'postalcode'){
    this._el.nativeElement.value = initalValue.replace(/[^0-9a-zA-Z]*/g, '');
  }
  else if( this._el.nativeElement.id === 'SecurityCode'){
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
  }
  else if( this._el.nativeElement.name === 'Agent'){
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-z\s\-]*/g, '');
  }
  
  else{
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\.]*/g, '');
    }    
   

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }


}
