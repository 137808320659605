import { Injectable } from '@angular/core';
import { globalConstants } from 'src/environments/globalConstants';
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  role: any;
  constructor() { }

  isAuthorized(allowedRoles: string[],loginresultobject:any): boolean {
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }


//     HANDLER_ORG_CD:"H",
// FORWARDER_ORG_CD:"F",
// AIRLINE_ORG_CD:"A",
// SUPERADMIN_ORG_CD:"SA",

 
// CSA_USERTYPE_CD:'S',
// ADMIN_USERTYPE_CD:'A',
// USER_USERTYPE_CD :'E',
// SUPERADMIN_USERTYPE_CD :'SA',


// CSA_HANDLER_AUTH_TXT: "CSA_HANDLER",
// ADMIN_HANDLER_AUTH_TXT: "ADMIN_HANDLER",
// USER_HANDLER_AUTH_TXT: "USER_HANDLER",

// CSA_FORWARDER_AUTH_TXT: "CSA_FORWARDER",
// ADMIN_FORWARDER_AUTH_TXT: "ADMIN_FORWARDER",
// USER_FORWARDER_AUTH_TXT: "USER_FORWARDER",

// CSA_AIRLINE_AUTH_TXT: "CSA_AIRLINE",
// ADMIN_AIRLINE_AUTH_TXT: "ADMIN_AIRLINE",
// USER_AIRLINE_AUTH_TXT: "USER_AIRLINE",

// SUPERADMIN_AUTH_TXT: "SUPER_ADMIN",

    //Compare and assign current loggedin user role.
    this.role = 'DEFAULT';
    if(loginresultobject.orgType === globalConstants.SUPERADMIN_USERTYPE_CD){
      this.role = globalConstants.SUPERADMIN_AUTH_TXT ; //tested
    } else if (loginresultobject.orgType === globalConstants.FORWARDER_ORG_CD && loginresultobject.userType === globalConstants.CSA_USERTYPE_CD) {
      this.role = globalConstants.CSA_FORWARDER_AUTH_TXT ; //tested
    } else if (loginresultobject.orgType === globalConstants.FORWARDER_ORG_CD && loginresultobject.userType === globalConstants.ADMIN_USERTYPE_CD) {
      this.role = globalConstants.ADMIN_FORWARDER_AUTH_TXT ;//tested
    }else if (loginresultobject.orgType === globalConstants.FORWARDER_ORG_CD && loginresultobject.userType === globalConstants.USER_USERTYPE_CD) {
      this.role = globalConstants.USER_FORWARDER_AUTH_TXT ; //tested
    }else if (loginresultobject.orgType === globalConstants.HANDLER_ORG_CD && loginresultobject.userType === globalConstants.CSA_USERTYPE_CD) {
      this.role = globalConstants.CSA_HANDLER_AUTH_TXT ;  //tested
    } else if (loginresultobject.orgType === globalConstants.HANDLER_ORG_CD && loginresultobject.userType === globalConstants.ADMIN_USERTYPE_CD) {
      this.role = globalConstants.ADMIN_HANDLER_AUTH_TXT ;  //tested
    }else if (loginresultobject.orgType === globalConstants.HANDLER_ORG_CD && loginresultobject.userType === globalConstants.USER_USERTYPE_CD) {
      this.role = globalConstants.USER_HANDLER_AUTH_TXT ;  //tested
    }else if (loginresultobject.orgType === globalConstants.AIRLINE_ORG_CD && loginresultobject.userType === globalConstants.CSA_USERTYPE_CD) {
      this.role = globalConstants.CSA_AIRLINE_AUTH_TXT ;  //tested
    } else if (loginresultobject.orgType === globalConstants.AIRLINE_ORG_CD && loginresultobject.userType === globalConstants.ADMIN_USERTYPE_CD) {
      this.role = globalConstants.ADMIN_AIRLINE_AUTH_TXT ; //tested
    }else if (loginresultobject.orgType === globalConstants.AIRLINE_ORG_CD && loginresultobject.userType === globalConstants.USER_USERTYPE_CD) {
      this.role = globalConstants.USER_AIRLINE_AUTH_TXT ; //tested
    }
    return allowedRoles.includes(this.role) ;
  }
}