import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import { StationService } from 'src/app/services/station/station.service';
import { AddAirlineEmailsService } from 'src/app/services/addAirlineEmail/add-airline-emails.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
declare var jQuery: any;
@Component({
    selector: 'app-add-airline',
    templateUrl: './add-airline.component.html',
    styleUrls: ['./add-airline.component.css']
})
export class AddAirlineComponent implements OnInit {
    addEmailForm: FormGroup;
    filteredItems: any;
    items: any;
    isListShoW: boolean;
    StationCode: any;
    EmailArrays: any;
    emailDesc: any;
    mailAutoIncrementIds: any;
    handlerId: any;
    isEditEnable: any;
    delete_Email_Index: any;
    emailId_Index: any;
    emailIdsWithoutObj: any;
    public static isEdit;
    public static defaultEmailsLength;
    isSubmitEnable: boolean;
    submitarrays: any;
    selectedStationCode: any;
    notSelectedFrmList: boolean;
    constructor(private activeRoute: ActivatedRoute,
         private router: Router, private fb: FormBuilder,
          private getStationCode_Servc: StationService, public addAirlineEmailServc: AddAirlineEmailsService,
           private spinner: NgxSpinnerService, private toastr: ToastrService ,private location: LocationStrategy) {
       
        this.EmailArrays = [];
        this.items = [];
        this.mailAutoIncrementIds = [];
        this.emailIdsWithoutObj = [];
        this.isListShoW = false;
        this.activeRoute.queryParams.subscribe(params => {
            this.StationCode = params['portCode'];
            this.EmailArrays = params['totalEmailsArray'];
            this.emailDesc = params['emailDesc'];
            this.mailAutoIncrementIds = params['mailAutoIncrementId'];
            this.handlerId = params['handlerId'];
            AddAirlineComponent.isEdit = params['isForEdit'];
            this.isEditEnable = AddAirlineComponent.isEdit;
        });

         // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.location.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.submitarrays = [];
        this.isEditEnable = AddAirlineComponent.isEdit;
        this.addEmailForm = this.create_formGroup();
        let loginInformation = JSON.parse(localStorage.getItem('loginresultobject'));
        this.addEmailForm.controls["airLine"].setValue(loginInformation.organisationName);
        this.addEmailForm.controls["orgId"].setValue(loginInformation.orgId);
        this.addEmailForm.controls["userId"].setValue(loginInformation.userId);
        this.isListShoW = false;
        this.fn_toGetStationCodes(loginInformation.orgId);
        this.onValueChanges();
        if ((this.StationCode != undefined && this.StationCode != null && this.StationCode != '') &&
            (AddAirlineComponent.isEdit === 'YES')) {
            this.edit_email_feild();
            const controlArray = <FormArray>this.addEmailForm.get('emailId');
            // for (let i = 0; i < controlArray.value.length; i++) {
                if (controlArray.controls[0].get('emailid').value !== undefined &&  controlArray.controls[0].get('emailid').value !== null &&  controlArray.controls[0].get('emailid').value !== '') {
                    controlArray.controls[0].disable();
                }else{
                    controlArray.controls[0].enable();
                }

            // }

        } else if ((this.StationCode != undefined && this.StationCode != null && this.StationCode != '') &&
            (AddAirlineComponent.isEdit === 'DELETE')) {
            this.edit_email_feild()
        }
        this.addEmailForm.controls['emailId'].valueChanges.subscribe(value => {
           ;
            console.log(value);
            this.fn_To_Enable_Submit();
            this.addEmailForm.updateValueAndValidity();
          })
    }


    onPaste(event,index){
       
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
        controlArray.controls[index].get('emailid').patchValue(controlArray.controls[index].get('emailid').value.replace(/[^a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\@\.]*/g, ''));
    }
    create_formGroup() {
        return this.fb.group({
            orgId: new FormControl(''),
            userId: new FormControl(''),
            airLine: new FormControl('', [Validators.required]),
            stationCode: new FormControl('', [Validators.required]),
            emailId: this.fb.array([this.fb.group({ emailid: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]) })])
        })
    }



    get emailsData() {
        return this.addEmailForm.get('emailId') as FormArray;
    }


    add_email_Field() {
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
        if(AddAirlineComponent.isEdit === 'YES'){
            for (let i = 0; i < controlArray.value.length; i++) {
                if (i === 0) {
                    controlArray.controls[i].enable();
                }
            }
        }
        this.emailsData.push(this.fb.group({ emailid: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]) }));
        if(AddAirlineComponent.isEdit === 'YES'){
            // for (let i = 0; i < controlArray.value.length; i++) {
                if (controlArray.controls[0].get('emailid').value !== undefined &&  controlArray.controls[0].get('emailid').value !== null &&  controlArray.controls[0].get('emailid').value !== '') {
                    controlArray.controls[0].disable();
                }else{
                    controlArray.controls[0].enable();
                }
            // }
        }else{
            this.fn_To_Enable_Submit();
        }
    }

    delete_email_Field(index, emailId) {
        this.emailId_Index = index;
        if (this.emailsData.length > 1) {
            if (AddAirlineComponent.isEdit === 'YES' || AddAirlineComponent.isEdit === 'DELETE') {
                for (const each of this.EmailArrays) {
                    this.emailIdsWithoutObj.push(each.emailid);
                }
                let val = this.emailIdsWithoutObj.indexOf(emailId);
                if ((val >= 0) && (index < this.EmailArrays.length)) {
                    let email = this.mailAutoIncrementIds[index];
                    this.delete_Email_Index = email;
                    jQuery('#delete-confirmation').modal({ backdrop: 'static', keyboard: false });
                } else {
                    jQuery('#delete-newEmails').modal({ backdrop: 'static', keyboard: false });
                }
            } else {
                jQuery('#delete-newEmails').modal({ backdrop: 'static', keyboard: false });
            }
        }
    }


    delete_newleyEnteredEmail() {
        this.emailsData.removeAt(this.emailId_Index);
        this.fn_To_Enable_Submit()
        jQuery('#delete-newEmails').modal('hide');
    }


    deletePreviousMails() {
        this.spinner.show();
        this.addAirlineEmailServc.deleteIndividualEmail({ "airlineId": this.delete_Email_Index }).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.fn_To_Enable_Submit()
                this.toastr.success(data.message, '', {
                    timeOut: 500
                });
                this.emailsData.removeAt(this.emailId_Index);
                this.mailAutoIncrementIds.splice(this.emailId_Index, 1);
                this.EmailArrays.splice(this.emailId_Index, 1);
                AddAirlineComponent.defaultEmailsLength = this.EmailArrays.length;
                jQuery('#delete-confirmation').modal('hide');
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#delete-confirmation').modal('hide');
            } else {
                this.toastr.success(data.message);
                jQuery('#delete-confirmation').modal('hide');
            }
        }, error => {
            this.spinner.hide();
        })
    }



    edit_email_feild() {
        this.addEmailForm.controls['stationCode'].patchValue(this.StationCode);
        this.addEmailForm.controls['stationCode'].disable();
        for (let i = 0; i < this.EmailArrays.length; i++) {
            this.EmailArrays[i] = { 'emailid': this.EmailArrays[i] }
        }
        AddAirlineComponent.defaultEmailsLength = this.EmailArrays.length;
        for (let i = 1; i < this.EmailArrays.length; i++) {
            this.add_email_Field();
        }
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
        for (let i = 0; i < controlArray.length; i++) {
                if(this.EmailArrays[i] !== undefined && this.EmailArrays[i] !== null){
                    controlArray.controls[i].get('emailid').setValue(this.EmailArrays[i].emailid);
                }
        }
        // for (let i = 0; i < controlArray.value.length; i++) {
            if (controlArray.controls[0].get('emailid').value !== undefined &&  controlArray.controls[0].get('emailid').value !== null &&  controlArray.controls[0].get('emailid').value !== '') {
                controlArray.controls[0].disable();
            }else{
                controlArray.controls[0].enable();
            }
        // }
        
    }


    onSubmitFormData(formData) {
        if (AddAirlineComponent.isEdit === "NO") {
            this.submit_AddEmailForm(formData);
        } else {
            this.update_addEmailForm(formData)
        }
    }


    submit_AddEmailForm(formData) {
        
        this.check_isStnCod_ExistInList(0);
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
        for (let i = 0; i < controlArray.value.length; i++) {
            if (i === 0) {
                controlArray.controls[i].enable();
            }
        }
        let stationCodeVal = this.addEmailForm.controls['stationCode'].value
        if (stationCodeVal != '' && stationCodeVal != undefined && stationCodeVal != '') {
            this.spinner.show();
            this.addAirlineEmailServc.addEmailServc(formData).subscribe(data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    this.addEmailForm.reset();
                    this.router.navigateByUrl("/view-airline-emails")
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                }  else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.spinner.hide();
            })
        }

    }


    update_addEmailForm(formData) {
        
        formData.oldEmailIds = [];
        formData.newEmailIds = [];
        this.addEmailForm.controls['stationCode'].enable();
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
        let firstEmailId;
        for (let i = 0; i < controlArray.value.length; i++) {
            if (i === 0) {
                controlArray.controls[i].enable();
                firstEmailId = controlArray.controls[i].get('emailid');
            }
        }
        
        formData.stationCode = this.addEmailForm.controls['stationCode'].value
        formData.handlerId = this.handlerId;
        formData.mailAutoIncrementIds = this.mailAutoIncrementIds;
       let withOutFirstEmail = [];
       if( formData.emailId != undefined){
        withOutFirstEmail = formData.emailId;
       }

        let withFirstEmail =[];
        withFirstEmail.push( { 'emailid':  firstEmailId.value });
        if(withOutFirstEmail.length != 0){
            withOutFirstEmail.forEach(element => {
                withFirstEmail.push(element);
            });
        }
        let oldArrayValues = withFirstEmail.splice(0, AddAirlineComponent.defaultEmailsLength);
        let newArrayValues = withFirstEmail;
        
        if (oldArrayValues.length != 0) {
            oldArrayValues.forEach(element => {
                formData.oldEmailIds.push(element.emailid);
            });
        }
        if (newArrayValues.length != 0) {
            newArrayValues.forEach(element => {
                formData.newEmailIds.push(element.emailid);
            });
        }
        formData.emailId = this.emailDesc;
        console.log("updateInputfinal", formData);
        this.spinner.show();
        this.addAirlineEmailServc.updateEmailServc(formData).subscribe(data => {
            this.spinner.hide();
            console.log("updateResponse", data);
            if (data.responseCode === 1) {
                this.toastr.success(data.message);
                this.router.navigateByUrl("/view-airline-emails")
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } else {
                const controlArray = <FormArray>this.addEmailForm.get('emailId');
                for (let i = 0; i < controlArray.value.length; i++) {
                    if (i === 0) {
                        controlArray.controls[i].disable();
                    }
                }
                this.addEmailForm.controls['stationCode'].disable();
                this.isListShoW = false;
                this.filteredItems = []
                this.toastr.error(data.message);
            }
        }, error => {
            this.spinner.hide();
        });
    }



    reset_AddEmailForm() {
        this.addEmailForm.reset();
    }


    //--------------Station code search Related code-----------//
    fn_toGetStationCodes(orgId) {
        
        this.addAirlineEmailServc.getAirlineStationCodes(orgId).subscribe(data => {
            if (data.responseCode === 1) {
                this.items = data.stationDetails;
                console.log("StationCodes", this.items);
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            }  else {
                this.items = [];
            }
        },error=>{
            
        });
    }


    onValueChanges(): void {
        
        this.isListShoW = true;
        this.notSelectedFrmList = false;
        this.addEmailForm.get('stationCode').valueChanges.subscribe(val => {
            val ? this.filterItem(val) : this.assignCopy();
        });
    }


    filterItem(value) {
        
        this.isListShoW = true;
        if (!value) {
            this.assignCopy();
        }
        this.filteredItems = this.items.filter(
            item => item.portCode.toLowerCase().startsWith(value.toLowerCase())
        )
    }


    assignCopy() {
        
        this.isListShoW = true;
        this.filteredItems = [];
        this.filteredItems = this.items;
    }


    onSelectStationCode(item) {
        
        this.notSelectedFrmList = false;
        this.selectedStationCode = item.portCode;
        this.addEmailForm.controls['stationCode'].patchValue(item.portCode);
        this.isListShoW = false;
    }



    check_isStnCod_ExistInList(index) {
        
        if (AddAirlineComponent.isEdit === "NO" && index === 0) {
            if (this.addEmailForm.controls['stationCode'].value != '' && this.addEmailForm.controls['stationCode'].value != null && this.addEmailForm.controls['stationCode'].value != undefined) {
                if (!this.selectedStationCode || this.selectedStationCode !== this.addEmailForm.controls['stationCode'].value) {
                    this.addEmailForm.controls['stationCode'].setValue(null);
                    this.addEmailForm.controls['stationCode'].setErrors(null);
                    this.notSelectedFrmList = true;
                    this.selectedStationCode = '';
                } else {
                    this.onLeaveStationCode();
                }
            } else {
                this.notSelectedFrmList = false;
                this.addEmailForm.controls['stationCode'].setErrors({ 'incorrect': true });
                this.addEmailForm.updateValueAndValidity();
            }

        }

    }

    hideList() {
        this.isListShoW = false;
    }

    //--------------End Station code search Related code-----------//

    getEmailIds(form): Array<any> {
        return form.controls.emailId.controls;
    }

    findDuplicate(emailid, p): boolean {
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
       let val =  this.addEmailForm.getRawValue()
        
        if(val.emailId.length > 1){
            let myArray = this.getEmailIds(this.addEmailForm);
            let totalArray = [];
            if (emailid != '' && emailid != null && emailid != undefined) {
                for (let i = 0; i < myArray.length; i++) {
                    totalArray.push(myArray[i].value)
                }
                this.fn_To_Enable_Submit();
                let test = totalArray.filter(data => data.emailid == emailid && emailid != "");
                return (test.length > 1) ? true : false;
            }
        }
    }

fn_To_Enable_Submit() {
       
        let totalArray = [];
        const controlArray = <FormArray>this.addEmailForm.get('emailId');
            let val =  this.addEmailForm.getRawValue()
            if(val.emailId.length > 1 ){
            for (let i = 0; i < val.emailId.length; i++) {
                totalArray.push(val.emailId[i].emailid);
            }
           
            this.isSubmitEnable = this.find_duplicate_in_array(totalArray);
        } 
        console.log(" this.isSubmitEnable", this.isSubmitEnable)
    }

    find_duplicate_in_array(array) {
        return (new Set(array)).size !== array.length;
    }

    onLeaveStationCode() {
        if ((this.addEmailForm.controls['stationCode'].value != '') && (this.addEmailForm.controls['stationCode'].value != null) && (this.addEmailForm.controls['stationCode'].value != undefined)) {
            this.hideList();
        }
    }


  ngOnDestroy(){
    // prevent memory leak when component destroyed
    // this.addEmailForm.unsubscribe()

  }
}

