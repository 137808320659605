// import { AbstractControl } from '@angular/forms';
// export class ConfirmPasswordValidator {
//   static MatchPassword(control: AbstractControl) {
//     let password = control.get('NewPassword').value;
//     let confirmPassword = control.get('ConfirmPassword').value;
//     if (password != confirmPassword ) {
//       control.get('ConfirmPassword').setErrors({ ConfirmPassword: true });
//     }
//     else {
//         // control.get('ConfirmPassword').setErrors({ required: false });
//       return null;
//     }
//     if(confirmPassword === ""){
//         control.get('ConfirmPassword').setErrors({ ConfirmPassword: false });
//         control.get('ConfirmPassword').setErrors({ required: true });
//     }
//   }
// }



import { AbstractControl } from '@angular/forms';
export class ConfirmPasswordValidator {

    static MatchPassword(AC: AbstractControl) {

        let password = AC.get('password').value;
        if (AC.get('cpassword').touched || AC.get('cpassword').dirty) {
            let verifyPassword = AC.get('cpassword').value;

            if (password != verifyPassword) {
                AC.get('cpassword').setErrors({ cpassword: true })
            } else {
                return null
            }
        }
    }
}