import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper } from "@angular/material";
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import * as enLocale from "date-fns/locale/en";
import * as htmlToImage from 'html-to-image';
import * as JsBarcode from 'jsbarcode';
import * as moment from 'moment';
import 'moment-timezone';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { DatepickerOptions } from "ng2-datepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { CargoAcceptanceService } from "src/app/services/cargo-acceptance/cargo-acceptance.service";
import { DateAndTimeFunctions } from 'src/app/shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
import * as watermark from 'watermarkjs';
import { ConfigServiceService } from "../../config-service.service";
import { Cargoacceptance } from "./cargoacceptance";

export interface DialogData {
  alertMessage: string, createOrupdate: string,tabName:string 
}
declare var jQuery: any;
export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: "app-create-cargoacceptance",
  templateUrl: "./create-cargoacceptance.component.html",
  styleUrls: ["./create-cargoacceptance.component.scss"],
  providers: [DatePipe,
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }],
})
export class CreateCargoacceptanceComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  @ViewChildren("eachPieceRow") eachPieceRowinputs;
  createCargoResp: any;
  othermppresent: boolean;
  showDGoodsfield: boolean;
  showDGfield: boolean;
  showothertypePacking = false;
  othertypePacking: any;
  mpa: any;
  myJson: any;
  otherLpresent: boolean;
  showotherLocation = false;
  otherLocation: any;
  Lcn: any;
  TypeOfPackingIDs: string;
  LocationIDs: string;
  Data: any;
  editData: any;
  updateResp: any;
  cargoDetails: any;
  itemsArray = [];
  totalSpices: number;
  totalWeight: number;
  storageLocationImagesEmpty = false;
  storageLocationValue: string;
  shipmentId: any;
  isStorageLocChange: boolean;
  shipmentNumber:any;
  alertMessagebbasedonpcswt:any;
  submitYesorNo:any;
  updateYesorNo:any;
  showlabelfield2:boolean;
  storeLabel:any = '';
  storeTakein:any = '';

 disableLabel:boolean;
  imageLabel = {
    take_in : '',
    generated_label: '',
  }



  constructor(
    private cargoAcceptanceService: CargoAcceptanceService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: Router,
    private toastr: ToastrService,
    private ViewReportsData: ViewReportsData,
    private el: ElementRef,
    private config: ConfigServiceService,
    private dateTimeFunc: DateAndTimeFunctions,
    public dialog: MatDialog
  ) {
    this.webapi = this.config.getConfig().ULD_Url;
  }

  // masterAwbNo1: any;
  // masterAwbNo2: any;
  // createShipmentForm: FormGroup;
  cargoacceptanceobj = new Cargoacceptance();
  orgDetails: any;
  isFirstTabCompleted = false;
  isSecondTabCompleted = false;
  isThirdTabCompleted = false;
  isFourthTabCompleted = false;
  isLinear = true;
  checkedT_d: boolean;
  shipment_TotalData_ToUpdate: any;
  old_Images = [];
  deleted_Old_Images: any = [];
  webapi;
  formSubmissionType: any;
  // mawb_Num_SuffixModel: any = '';
  showlabelfield:boolean;
  // showMABfield = false;
  createShipmentForm = new FormGroup({
    createdOn:  new FormControl(),
    org_id: new FormControl("", [Validators.required]),
    loginUserId: new FormControl("", [Validators.required]),
    identification: new FormControl("Label", [Validators.required]),
    NA: new FormControl("NA"),
    label: new FormControl(""),
    shipment_number: new FormControl(""),
    destination: new FormControl("", [Validators.required]),
    order_pieces: new FormControl(""),
    take_in: new FormControl(new Date()),
    shipper_name: new FormControl(""),
    city: new FormControl(""),
    expected_total_pieces: new FormControl(""),
    expected_total_weight: new FormControl(""),
    generateLabelImage: new FormControl(""),
    generateLabel: new FormControl("No", Validators.required),
    total_pieces: new FormControl(0),
    total_weight: new FormControl(0),
    pieces_weight_array: new FormControl([]),
    cargoAccLocation: new FormControl(""),
    address_labels_available: new FormControl("", [Validators.required]),
    dangerous_goods: new FormControl("", [Validators.required]),
    DG_labels_available: new FormControl("", [Validators.required]),
    bonded: new FormControl("", [Validators.required]),
    security_status: new FormControl("", [Validators.required]),
    any_damage_noticed: new FormControl("", [Validators.required]),
    UN: new FormControl(""),
    class: new FormControl(""),
    proper_shipping: new FormControl(""),
    net_quantity: new FormControl(""),
    packing_group: new FormControl(""),
    remarks: new FormControl(""),
    images: new FormControl([]),
    // created: new FormControl(this.convertUTCDateToLocalDate(new Date()))
    created: new FormControl(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
  });
  // --------Images selection related------------//
  public _imagesArrayTotal = [];
  urls = [];
  images: any = [];
  TypePackingarray = [];
  Locationarray = [];

  public selectedMoment =(new Date()).toISOString();
  public selectedMoment2 = new FormControl((new Date()));
  public selectedMoments = [
    (new Date(2018, 1, 12, 10, 30)).toISOString(),
    (new Date(2018, 3, 21, 20, 30)).toISOString(),
  ];

  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "YYYY-MM-DD",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,

    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  ngOnInit() {
    this.morehawscall();
    this.showlabelfield = true;
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.createShipmentForm.patchValue({
      org_id: this.orgDetails.orgId,
      loginUserId: this.orgDetails.userId,
    });
    this.isFirstTabCompleted = false;
    this.formSubmissionType = JSON.parse(localStorage.getItem("CARGO_Acceptance_From"));
    this.cargoAcceptanceService
      .getCargoPackingAndLocation(this.orgDetails.userId)
      .subscribe((resp) => {
        this.Data = resp;
         
        
        this.TypePackingarray = this.Data.packing;
        this.Data.packing.map((t) => {
          if(t.description === "Package"){
            t.checked = true;
          }else{
            t.checked = false;
          }
          
        });
        this.Locationarray = this.Data.location;
        this.Locationarray.map((l) => {
          l.checked = false;
        });
      });
    if (this.formSubmissionType  === "Edit") { 
     this.generatedShipmentNumber();
      this.shipmentId   = JSON.parse(localStorage.getItem("CARGO_Acceptance_STORAGE")).shipmentId;
      
      this.fn_ToGet_ShipmentData();
      for (const item of this.itemsArray) {
        item.type_of_packing_other = item.types_of_packing_other;
        item.type_of_packing = item.types_of_packing
          .split(",")
          .map((e) => parseInt(e));
      }
    }
    // this.createShipmentForm
    //   .get("cargoAccLocation")
    //   .valueChanges.subscribe((value) => {
    //     this.storageLocationValue = value;
    //     if (value !== "" && this.urls.length === 0) {
    //       this.storageLocationImagesEmpty = true;
    //     } else if (value === "" || (value != "" && this.urls.length !== 0)) {
    //       this.storageLocationImagesEmpty = false;
    //     }
    
    //   });

    let DG_labels_available = this.createShipmentForm.value.DG_labels_available;
    let dangerous_goods = this.createShipmentForm.value.dangerous_goods;
    if (DG_labels_available === "Yes") {
      this.showDGfield = true;
    } else {
      this.showDGfield = false;
    }
    if (dangerous_goods === "Yes") {
      this.showDGoodsfield = true;
    } else {
      this.showDGoodsfield = false;
    }

   this.generatedShipmentNumber();
  }

  generatedShipmentNumber(){
    this.spinner.show()
    this.cargoAcceptanceService.uniqueShipmentNumber().subscribe((res) => {
      this.shipmentNumber = res
      this.spinner.hide();
    })
  }

  onlyNumberKey(evt) {
    
   

   if(evt.type === "paste" ) {
     let text = (evt.originalEvent || evt).clipboardData.getData('text/plain');
     if(text.match(/^[0-9]+$/) === null){
      evt.preventDefault();
      this.toastr.error("Alphabets not allowed here");
      return false;
     }
   }else{
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    }
    return true;
   }
  }

  onlyNumberAndDecimalKey(evt) {
    if (evt.type === "paste") {
      let text = (evt.originalEvent || evt).clipboardData.getData('text/plain');
      if (text.match(/^[0-9]*\.?[0-9]*$/) === null) {
        evt.preventDefault();
        this.toastr.error("Only numbers and decimal values are allowed");
        return false;
      }
    } else {
      const ASCIICode = evt.which ? evt.which : evt.keyCode;
      // Allow only digits (48-57) and the decimal point (46)
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && ASCIICode !== 46) {
        return false;
      }
      // Ensure only one decimal point is allowed
      const inputValue = evt.target.value;
      if (ASCIICode === 46 && inputValue.includes('.')) {
        return false;
      }
      return true;
    }
  }
  

  lettersValidate(key) {
    const keycode = key.which ? key.which : key.keyCode;
    if ((keycode > 64 && keycode < 91) || (keycode > 96 && keycode < 123) ) {
      return true;
    } else {
      return false;
    }
  }

  notAllowSpace(event) {
    if (event.target.value.length === 0 && event.which === 32) {
      event.preventDefault();
    }
    const inputValue = event.charCode;
    
    if (
      !(inputValue >= 65 && inputValue <= 122) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault();
    }
    if (this.createShipmentForm.get("cargoAccLocation").value === "") {
      if (this.formSubmissionType === "Edit") {
        this.old_Images = this.editData.cargoDetails.cargoImages;
      }
    }
  }
  notAllowSpace1(event) {
    if (event.target.value.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  }

  // _AWB_Suffix(event) {
  //   this.masterAwbNo2 = event.target.value;
  //   this.masterAwbNo2.replace(/[^0-9]*/g, '');
  //   if (this.masterAwbNo2.length === 3) {
  //     document.getElementById('masterAirwayBill1').focus();
  //   }
  // }

  // spacecall(event) {
  //   if (event !== undefined && event !== null) {
  //     this.masterAwbNo1 = event.target.value;
  //     let trimmed = event.target.value.replace(/\s+/g, '');
  //     if (trimmed.length > 16) {
  //       trimmed = trimmed.substr(0, 16);
  //     }
  //     const numbers = [];
  //     for (let i = 0; i < trimmed.length; i += 4) {
  //       numbers.push(trimmed.substr(i, 4));
  //     }
  //     this.masterAwbNo1 = numbers.join(' ');
  //   }
  //   this.createShipmentForm.patchValue({ MasterAirwayBill: this.masterAwbNo1 });

  //   if (this.masterAwbNo1.length === 9) {
  //     // tslint:disable-next-line: max-line-length
  //     this.createShipmentForm.patchValue({ AWB: `${this.createShipmentForm.value.masterAirwayBill}-${this.createShipmentForm.value.MasterAirwayBill}` });
  //   }
  // }

  morehawscall() {
    
    if (this.itemsArray.length === 0) {
      this.itemsArray.push({
        id: Date.now(),
        type_of_packing: [7],
        type_of_packing_other: "",
        type_of_packing_other_show: false,
        pieces: "",
        length: "",
        width: "",
        height: "",
        type_of_packing_label: "",
      });
    } else {
      const obj = this.itemsArray[0];
      if (obj.index === 1) {
        this.itemsArray.reverse();
      }
      this.itemsArray.push({
        id: Date.now(),
        type_of_packing: [7],
        type_of_packing_other: "",
        type_of_packing_other_show: false,
        pieces: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        type_of_packing_label: "",
      });
      setTimeout(() => {
        this.eachPieceRowinputs.last.nativeElement.focus();
      }, 0);
    }
  }

  cancelmorehawscall(id) {
    this.itemsArray = this.itemsArray.filter((item) => {
      return item.id !== id;
    });
    this.countAmount('p');
    this.countAmount('w');
  }

  countAmount(type) {
    if(type === 'p'){
      this.totalSpices = 0;
      // this.totalWeight = 0;
      const a = this.itemsArray.filter((data) => {
        // return data.pieces !== "" && data.weight !== "";
        return data.pieces !== "";
      });
      // if (a.length !== 0) {
      a.filter((data) => {
        this.totalSpices += parseInt(data.pieces);
        // this.totalWeight += parseInt(data.pieces) * parseInt(data.weight);
      });
      this.createShipmentForm.patchValue({
        total_pieces: this.totalSpices,
        // total_weight: this.totalWeight,
      });
    }
    if(type === 'w'){
      // this.totalSpices = 0;
      this.totalWeight = 0;
      const a = this.itemsArray.filter((data) => {
        return data.weight !== "";
      });
      // if (a.length !== 0) {
      a.filter((data) => {
        // this.totalSpices += parseInt(data.pieces);
        // this.totalWeight += parseInt(data.pieces) * parseInt(data.weight);
        this.totalWeight += parseFloat(data.weight);
      });
      this.createShipmentForm.patchValue({
        // total_pieces: this.totalSpices,
        total_weight: this.totalWeight,
      });
    }


    if(type === 'v'){
      this.itemsArray.map((data) => {
        if(data.length && data.width && data.height){
          data.volume = this.calculateVolume(data.length, data.width, data.height);
        }else {
          data.volume = '-';
        }
      })
    }
    
    // }
  }

  calculateVolume(length: number, width: number, height: number): number {
    return length * width * height;
  }

  onDangerous(val) {
    this.checkedT_d = false;
    if (val.value === "Yes") {
      this.showDGoodsfield = true;
      this.createShipmentForm.patchValue({
        DG_labels_available: "",
      });
    } else {
      this.showDGoodsfield = false;
      this.createShipmentForm.patchValue({
        DG_labels_available: "No",
        UN: "",
        proper_shipping: "",
        class: "",
        packing_group: "",
        net_quantity: "",
      });
    }
    this.goForward1("secondTab");
  }

  onchangetop1() {
    for (let item of this.itemsArray) {

      // if (!Array.isArray(item.type_of_packing)) {
      //       item.type_of_packing = [item.type_of_packing];
      //     }
      
      if(item.type_of_packing == '6'){
        item.type_of_packing_other_show = true;
      }else {
        item.type_of_packing_other_show = false;
      }

      if (
        item.type_of_packing_other !== "" &&
        item.type_of_packing.indexOf(6) === -1 &&
        item.type_of_packing_other_show === false
      ) {

        item.type_of_packing_other = "";
      }


      // if (item.type_of_packing.length !== 0) {
      //   if (!Array.isArray(item.type_of_packing)) {
      //     item.type_of_packing = [item.type_of_packing];
      //   }
      //   for (let tlValue of item.type_of_packing) {
      //     if (tlValue === 6) {
      //       item.type_of_packing_other_show = true;
      //       return;
      //     } 
      //     else {
      //       item.type_of_packing_other_show = false;
      //     }
      //     if (item.type_of_packing_other !== '' && item.type_of_packing.indexOf(6) === -1 && item.type_of_packing_other_show === false) {
      //       item.type_of_packing_other = '';
      //     }
      //   }
      // } else {
      //   item.type_of_packing_other_show = false;
      //   // if (item.type_of_packing_other !== '' && item.type_of_packing.indexOf(6) === -1 && item.type_of_packing_other_show === false) {
      //   item.type_of_packing_other = '';
      //   // }
      // }
      item.id = this.itemsArray.indexOf(item) + 1;
    }
  }


  onchangetop() {
  

    for (let val of this.itemsArray) {

      if(val.type_of_packing == '6'){
        val.type_of_packing_other_show = true;
      }else {
        val.type_of_packing_other_show = false;
      }

      if (
        val.type_of_packing_other !== "" &&
        val.type_of_packing.indexOf(6) === -1 &&
        val.type_of_packing_other_show === false
      ) {

        val.type_of_packing_other = "";
      }


    //   if(val.type_of_packing.length === 0){
    //     val.type_of_packing_other_show = false;
    //   }
    //   else{
    //   for (let tlValue of val.type_of_packing) {
    //     if (tlValue === 6) {
    //       val.type_of_packing_other_show = true;
    //       return;
    //     } 
    //     else {
    //       val.type_of_packing_other_show = false;
    //     }
    //   }
    // }
      val.id = this.itemsArray.indexOf(val) + 1;
    }
  }

  // onValueChanges(fieldName: string, event) {
  //   let trimmed;
  //   this.isLinear = true;
  //   this.isFirstTabCompleted = false;
  //   if (this.createShipmentForm.valid) {
  //     // if ((((this.createShipmentForm.get('mawb_Num_Prefix').value === '') && (this.createShipmentForm.get('mawb_Num_Suffix').value !== ''))
  //     //   || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== '') && (this.createShipmentForm.get('mawb_Num_Suffix').value === '')))
  //     //   ||
  //     //   (((this.createShipmentForm.get('mawb_Num_Prefix').value === undefined) && (this.createShipmentForm.get('mawb_Num_Suffix').value !== undefined))
  //     //     || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== undefined) && (this.createShipmentForm.get('mawb_Num_Suffix').value === undefined)))
  //     //   ||
  //     //   (((this.createShipmentForm.get('mawb_Num_Prefix').value === null) && (this.createShipmentForm.get('mawb_Num_Suffix').value !== null))
  //     //     || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== null) && (this.createShipmentForm.get('mawb_Num_Suffix').value === null)))) {
  //     //   this.isFirstTabCompleted = false;
  //     //   this.isLinear = true;
  //     // } else {
  //     this.isFirstTabCompleted = true;
  //     this.isLinear = false;
  //     // }
  //     this._setColoursToEach_Stepper_Header('green', 0);

  //   } else {
  //     this.isFirstTabCompleted = false;
  //     this.isLinear = true;
  //   }
  //   if (fieldName === 'mawb_Num_Prefix') {
  //     trimmed = this.createShipmentForm.get('mawb_Num_Prefix').value.replace(/[^0-9]*/g, '');
  //     this.createShipmentForm.get(fieldName).setValue(trimmed);
  //   } else if (fieldName === 'mawb_Num_Suffix') {
  //     if (event !== undefined && event !== null) {
  //       this.mawb_Num_SuffixModel = event.target.value;
  //       let trimmed = this.mawb_Num_SuffixModel.replace(/\s+/g, '');
  //       if (trimmed.length > 16) {
  //         trimmed = trimmed.substr(0, 16);
  //       }
  //       const numbers = [];
  //       for (let i = 0; i < trimmed.length; i += 4) {
  //         numbers.push(trimmed.substr(i, 4));
  //       }
  //       this.mawb_Num_SuffixModel = numbers.join(' ');
  //     }
  //   } else if (fieldName === 'referenceNumber') {
  //     trimmed = this.createShipmentForm.get('referenceNumber').value.replace(/[^a-zA-Z0-9\-]*/g, '');
  //     this.createShipmentForm.get(fieldName).setValue(trimmed);
  //   } else if (fieldName === 'shiperName') {
  //     trimmed = this.createShipmentForm.get('shiperName').value.replace(/[^a-zA-Z\s\-]*/g, '');
  //     this.createShipmentForm.get(fieldName).setValue(trimmed);

  //   }
  // }


  
  nextgoForward(stepper, tab){
    if(tab == 'thirdTab'){
      if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces && this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
        this.alertMessagebbasedonpcswt = 'Received pieces and weight are more than expected. Would you like to proceed?';
      }else if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces ) {
        this.alertMessagebbasedonpcswt = 'Received pieces is more than expected. Would you like to proceed?';
      }else if(this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
        this.alertMessagebbasedonpcswt = 'Received weight is more than expected. Would you like to proceed?';
        // this.openDialog1(this.alertMessagebbasedonpcswt, tabName, 'update')
      }
      else {
        this.alertMessagebbasedonpcswt = '';
        this.goForward(stepper,tab);
      }  
    }else {
      this.goForward(stepper, tab);
    }
  }


  goForward(stepper, tab): void {
    const stepperArray = ["firstTab", "secondTab", "thirdTab", "fourthTab"];
    for (const tab of stepperArray) {
      this.goForward1(tab);
    }
    
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        this.createShipmentForm.get("identification").value === "Label" &&
        this.createShipmentForm.get("label").value === ""
      ) {
        document.getElementById("lable").focus();
        this.toastr.error(`Please fill the label field`);
        return;
      }
      if (
        ((this.createShipmentForm.get("identification").value !== "" &&
          this.createShipmentForm.get("label").value !== "" &&
          this.createShipmentForm.get("identification").value !== "N/A") ||
          (this.createShipmentForm.get("identification").value !== "" &&
            this.createShipmentForm.get("NA").value !== "" &&
            this.createShipmentForm.get("identification").value !== "Label")) &&
        this.createShipmentForm.get("destination").value !== ""
      ) {
        // this.myStepper.next();
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        // setTimeout(() => {
        //   this.goForward1('firstTab');
        //   this.myStepper.next();
        // }, 0);
        this.myStepper.next();
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
        for (const key of Object.keys(this.createShipmentForm.controls)) {
          if (this.createShipmentForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (
              this.createShipmentForm.get("identification").value === "Label" &&
              this.createShipmentForm.get("label").value === ""
            ) {
              document.getElementById("lable").focus();
              this.toastr.error(`Please fill the label field`);
              break;
            }
            if (key === "destination") {
              this.toastr.error(`Please fill the Destination`);
              invalidControl.focus();
              break;
            } else {
              this.toastr.error(`Please fill the ${key} field`);
              invalidControl.focus();
              break;
            }
          }
        }
      }
    }
    if (tab === "secondTab") {
      this.isSecondTabCompleted = false;
      if (
        this.createShipmentForm.get("dangerous_goods").value !== "" ||
        this.createShipmentForm.get("bonded").value !== "" ||
        this.createShipmentForm.get("address_labels_available").value !== "" ||
        this.createShipmentForm.get("DG_labels_available").value !== "" ||
        this.createShipmentForm.get("security_status").value !== "" ||
        this.createShipmentForm.get("any_damage_noticed").value !== ""
      ) {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        if (
          this.createShipmentForm.get("dangerous_goods").value !== "" &&
          this.createShipmentForm.get("bonded").value !== "" &&
          this.createShipmentForm.get("DG_labels_available").value !== "" &&
          this.createShipmentForm.get("security_status").value !== "" &&
          this.createShipmentForm.get("any_damage_noticed").value !== "" &&
          this.createShipmentForm.get("address_labels_available").value !== ""
        ) {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this.goForward1("secondTab");
          stepper.next();
          this._setColoursToEach_Stepper_Header("green", 1);
        } else {
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 1);
          for (const key of Object.keys(this.createShipmentForm.controls)) {
            if (this.createShipmentForm.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector(
                '[formcontrolname="' + key + '"]'
              );
              if (key === "address_labels_available") {
                this.toastr.error(
                  `Please select the Address labels available field`
                );
                invalidControl.focus();
                break;
              }
              if (key === "dangerous_goods") {
                this.toastr.error(`Please select the Dangerous goods`);
                break;
              }
              if (key === "DG_labels_available") {
                this.toastr.error(`Please select the DG labels Available`);
                break;
              }
              if (key === "bonded") {
                this.toastr.error(`Please select the Bonded`);
                break;
              }
              if (key === "security_status") {
                this.toastr.error(`Please select the Security Status`);
                break;
              }
              if (key === "any_damage_noticed") {
                this.toastr.error(`Please select the Any damage noticed`);
                break;
              }
            }
          }
        }
      } else {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        stepper.next();
      }
    }
    if (tab === "thirdTab") {
      this.isThirdTabCompleted = true;
      if (
        this.itemsArray.length > 0 ||
        this.createShipmentForm.get("cargoAccLocation").value !== ""
      ) {
        if (this.itemsArray.length === 1) {
          let abcd = this.itemsArray.filter((data) => {
          
            return !(
              data.type_of_packing.length === 0 &&
              data.pieces === "" &&
              data.length === "" &&
              data.width === "" &&
              data.height === "" &&
              data.weight === ""
            );
          });
          for (let item of abcd) {
          
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
               
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
            
          }
        } else {
          for (let item of this.itemsArray) {
          
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
              
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
          
          }
        }
        if (this.editData !== null && this.editData !== undefined && this.editData !== '') {
          if (this.editData.cargoDetails.cargo_acc_location !== this.createShipmentForm.get('cargoAccLocation').value) {
            if (this.createShipmentForm.get('cargoAccLocation').value !== '') {
              if (this.urls.length === 0) {
                this.toastr.error("Please upload the Pictures");
              }
            }
          }
        }
        this._setColoursToEach_Stepper_Header("green", 2);
        stepper.next();
      } else {
        this._setColoursToEach_Stepper_Header("white", 2);
        stepper.next();
      }
    }
    if (tab === "fourthTab") {
      this.isFourthTabCompleted = true;
      if (this.urls.length > 0 || this.old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
    }
  }

  goForward1(tab): void {
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        ((this.createShipmentForm.get("identification").value !== "" &&
          this.createShipmentForm.get("label").value !== "" &&
          this.createShipmentForm.get("identification").value !== "N/A") ||
          (this.createShipmentForm.get("identification").value !== "" &&
            this.createShipmentForm.get("NA").value !== "" &&
            this.createShipmentForm.get("identification").value !== "Label")) &&
        this.createShipmentForm.get("destination").value !== ""
      ) {
        this.createShipmentForm.patchValue({
          expected_total_pieces: this.createShipmentForm.value.expected_total_pieces,
          expected_total_weight: this.createShipmentForm.value.expected_total_weight
        })
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        this._setColoursToEach_Stepper_Header("green", 0);
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
      }
    }
    if (tab === "secondTab") {
      this.isSecondTabCompleted = false;
      if (
        this.createShipmentForm.get("dangerous_goods").value !== "" ||
        this.createShipmentForm.get("bonded").value !== "" ||
        this.createShipmentForm.get("address_labels_available").value !== "" ||
        this.createShipmentForm.get("DG_labels_available").value !== "" ||
        this.createShipmentForm.get("security_status").value !== "" ||
        this.createShipmentForm.get("any_damage_noticed").value !== ""
      ) {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        if (
          this.createShipmentForm.get("dangerous_goods").value !== "" &&
          this.createShipmentForm.get("bonded").value !== "" &&
          this.createShipmentForm.get("DG_labels_available").value !== "" &&
          this.createShipmentForm.get("security_status").value !== "" &&
          this.createShipmentForm.get("any_damage_noticed").value !== "" &&
          this.createShipmentForm.get("address_labels_available").value !== ""
        ) {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this._setColoursToEach_Stepper_Header("green", 1);
        } else {
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 1);
        }
      } else {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
      }
    }
    if (tab === "thirdTab") {
      this.createShipmentForm.patchValue({
        expected_total_pieces: this.createShipmentForm.value.expected_total_pieces,
        expected_total_weight: this.createShipmentForm.value.expected_total_weight
      })
      this.isThirdTabCompleted = false;
      const a = this.itemsArray.filter((data) => {
       
        return !(
          data.type_of_packing.length === 0 &&
          data.pieces === "" &&
          data.length === "" &&
          data.width === "" &&
          data.height === "" &&
          data.weight === ""
        );
      });
      if (
        a.length > 0 ||
        this.createShipmentForm.get("cargoAccLocation").value !== ""
      ) {
        this._setColoursToEach_Stepper_Header("green", 2);
      } else {
        this._setColoursToEach_Stepper_Header("white", 2);
      }
    }
    if (tab === "fourthTab") {
      this.isFourthTabCompleted = true;
      if (this.urls.length > 0 || this.old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
    }
  }

  // ----- Type of packing  popup functionalities ----//
  openTypeOfPacking() {
    this.othermppresent = false;
    if (this.TypePackingarray != null) {
      this.TypePackingarray.forEach((item) => {
        if (item.checked === true && item.description === "Other") {
          this.othermppresent = true;
        }
      });
    }
    if (this.othermppresent !== false) {
      this.showothertypePacking = true;
    } else {
      this.showothertypePacking = false;
      this.othertypePacking = "";
    }
    jQuery("#TypeOfPacking").modal({ backdrop: "static", keyboard: false });
    this.goForward1("firstTab");
  }

  cancelTypeOfPacking() {
    if (
      this.createShipmentForm.value.type_of_packing === "" ||
      this.createShipmentForm.value.type_of_packing === null ||
      this.createShipmentForm.value.type_of_packing === undefined
    ) {
      this.showothertypePacking = false;
      for (let i = 0; i < this.TypePackingarray.length; i++) {
        this.TypePackingarray[i].checked = false;
      }
    } else {
      this.TypePackingarray = JSON.parse(
        localStorage.getItem("TypePackingarrayL")
      );
      this.othermppresent = false;
      if (this.TypePackingarray != null) {
        if (this.TypePackingarray.length !== 0) {
          this.TypePackingarray.forEach((item) => {
            if (item.checked === true && item.description === "Other") {
              this.othermppresent = true;
            }
          });
        }
      }

      if (this.othermppresent !== false) {
        this.showothertypePacking = true;
      } else {
        this.showothertypePacking = false;
      }
    }

    jQuery(".custom_modal").modal("hide");
  }
  okTypeOfPacking() {
   
    if (
      this.othertypePacking !== undefined &&
      this.othertypePacking !== "" &&
      this.othertypePacking != null
    ) {
      this.othertypePacking = this.othertypePacking.replace(
        /[^a-zA-Z\s]*/g,
        ""
      );
    }

    if (
      this.showothertypePacking === true &&
      (this.othertypePacking === undefined ||
        this.othertypePacking === "" ||
        this.othertypePacking === null)
    ) {
      this.toastr.error("Please enter other field for Type of packing");
    } else {
      localStorage.setItem(
        "TypePackingarrayL",
        JSON.stringify(this.TypePackingarray)
      );
      this.mpa = "";
      this.TypeOfPackingIDs = "";
      for (let i = 0; i < this.TypePackingarray.length; i++) {
      
        if (this.TypePackingarray[i].checked === true) {
          if (this.TypePackingarray[i].description === "Other") {
            this.TypeOfPackingIDs =
              this.TypeOfPackingIDs + this.TypePackingarray[i].id;
            this.mpa = this.mpa + this.othertypePacking;
          } else {
            if (i + 1 === this.TypePackingarray.length) {
              this.TypeOfPackingIDs =
                this.TypeOfPackingIDs + this.TypePackingarray[i].id;
              this.mpa = this.mpa + this.TypePackingarray[i].description;
            } else {
              this.TypeOfPackingIDs =
                this.TypeOfPackingIDs + this.TypePackingarray[i].id + ",";
              this.mpa = this.mpa + this.TypePackingarray[i].description + ",";
            }
          }
        }
      }
      if (this.mpa !== "" && this.mpa != null && this.mpa !== undefined) {
        const val = this.mpa.charAt(this.mpa.length - 1);
        if (val === ",") {
          this.mpa = this.mpa.slice(0, this.mpa.length - 1);
        }
      }
      if (
        this.TypeOfPackingIDs !== "" &&
        this.TypeOfPackingIDs != null &&
        this.TypeOfPackingIDs !== undefined
      ) {
        const val = this.TypeOfPackingIDs.charAt(
          this.TypeOfPackingIDs.length - 1
        );
        if (val === ",") {
          this.TypeOfPackingIDs = this.TypeOfPackingIDs.slice(
            0,
            this.TypeOfPackingIDs.length - 1
          );
        }
      }
      this.createShipmentForm.patchValue({
        type_of_packing: this.mpa,
      });
      jQuery(".custom_modal").modal("hide");
    }
  }

  onChangeTypePacking() {
    this.showothertypePacking = false;
    this.TypePackingarray.map((item) => {
      if (item.checked === true && item.description === "Other") {
        this.othermppresent = true;
      } else if (item.checked === false && item.description === "Other") {
        this.othermppresent = false;
      }
    });

    if (this.othermppresent !== false) {
      this.showothertypePacking = true;
    } else {
      this.showothertypePacking = false;
    }
    this.goForward1("firstTab");
  }

  // ----- Location  popup functionalities ----//
  openLocation() {
    this.otherLpresent = false;
    if (this.Locationarray != null) {
      this.Locationarray.forEach((item) => {
        if (item.checked === true && item.description === "Other") {
          this.otherLpresent = true;
        }
      });
    }
    if (this.otherLpresent !== false) {
      this.showotherLocation = true;
    } else {
      this.showotherLocation = false;
      this.otherLocation = "";
    }
    jQuery("#Location").modal({ backdrop: "static", keyboard: false });
  }

  cancelLocation() {
    if (
      this.createShipmentForm.value.location === "" ||
      this.createShipmentForm.value.location === null ||
      this.createShipmentForm.value.location === undefined
    ) {
      this.showotherLocation = false;
      for (let i = 0; i < this.Locationarray.length; i++) {
        this.Locationarray[i].checked = false;
      }
    } else {
      this.Locationarray = JSON.parse(localStorage.getItem("Locationarray"));
      this.otherLpresent = false;
      if (this.Locationarray != null) {
        if (this.Locationarray.length !== 0) {
          this.Locationarray.forEach((item) => {
            if (item.checked === true && item.description === "Other") {
              this.otherLpresent = true;
            }
          });
        }
      }

      if (this.otherLpresent !== false) {
        this.showotherLocation = true;
      } else {
        this.showotherLocation = false;
      }
    }

    jQuery(".custom_modal").modal("hide");
  }
  okLocation() {
    if (
      this.otherLocation !== undefined &&
      this.otherLocation !== "" &&
      this.otherLocation != null
    ) {
      this.otherLocation = this.otherLocation.replace(/[^a-zA-Z\s]*/g, "");
    }

    if (
      this.showotherLocation === true &&
      (this.otherLocation === undefined ||
        this.otherLocation === "" ||
        this.otherLocation === null)
    ) {
      this.toastr.error("Please enter other field for Location");
    } else {
      localStorage.setItem("Locationarray", JSON.stringify(this.Locationarray));
      this.Lcn = "";
      this.LocationIDs = "";
      for (let i = 0; i < this.Locationarray.length; i++) {
        if (this.Locationarray[i].checked === true) {
          if (this.Locationarray[i].description === "Other") {
            this.Lcn = this.Lcn + this.otherLocation;
          } else {
            if (i + 1 === this.Locationarray.length) {
              this.LocationIDs = this.LocationIDs + this.Locationarray[i].id;
              this.Lcn = this.Lcn + this.Locationarray[i].description;
            } else {
              this.LocationIDs =
                this.LocationIDs + this.Locationarray[i].id + ",";
              this.Lcn = this.Lcn + this.Locationarray[i].description + ",";
            }
          }
        }
      }
      if (this.Lcn !== "" && this.Lcn != null && this.Lcn !== undefined) {
        const val = this.Lcn.charAt(this.Lcn.length - 1);
        if (val === ",") {
          this.Lcn = this.Lcn.slice(0, this.Lcn.length - 1);
        }
      }
      if (
        this.LocationIDs !== "" &&
        this.LocationIDs != null &&
        this.LocationIDs !== undefined
      ) {
        const val = this.LocationIDs.charAt(this.LocationIDs.length - 1);
        if (val === ",") {
          this.LocationIDs = this.LocationIDs.slice(
            0,
            this.LocationIDs.length - 1
          );
        }
      }
      this.createShipmentForm.patchValue({
        location: this.Lcn,
      });
      jQuery(".custom_modal").modal("hide");
    }
  }

  onLocation() {
    this.showotherLocation = false;
    this.Locationarray.map((item) => {
      if (item.checked === true && item.description === "Other") {
        this.otherLpresent = true;
      }
    });

    if (this.otherLpresent !== false) {
      this.showotherLocation = true;
    } else {
      this.showotherLocation = false;
    }
  }

  downloadJson() {
    const sJson = JSON.stringify(this.myJson);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/json;charset=UTF-8," + encodeURIComponent(sJson)
    );
    element.setAttribute("download", "data.json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  onSubmitFormData(): void {
    if (
      ((this.createShipmentForm.get("identification").value !== "" &&
        this.createShipmentForm.get("label").value !== "" &&
        this.createShipmentForm.get("identification").value !== "N/A") ||
        (this.createShipmentForm.get("identification").value !== "" &&
          this.createShipmentForm.get("NA").value !== "" &&
          this.createShipmentForm.get("identification").value !== "Label")) &&
      this.createShipmentForm.get("destination").value !== ""
    ) {
      this.images = [];
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) {
          const obj = { imageURL: this._imagesArrayTotal[i] };
          this.images.push(obj);
        }
      }
      if (this.createShipmentForm.value.take_in === "") {
        this.toastr.error("Please select the take in");
        return;
      }
      if (this.itemsArray.length === 1) {
        let abcd = this.itemsArray.filter((data) => {
        
          return !(
            data.type_of_packing.length === 0 &&
            data.pieces === "" &&
            data.length === "" &&
            data.width === "" &&
            data.height === "" &&
            data.weight === ""
          );
        });
        if (abcd.length === 0) {
          this.itemsArray = [];
        }
      }
      if (this.itemsArray.length > 0) {
        const a = this.itemsArray;
      
        if (a.length > 0) {
          for (let item of a) {
          
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
              
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
            
          }
          if (
            this.createShipmentForm.get("cargoAccLocation").value !== "" &&
            this.urls.length === 0 &&
            this.old_Images.length === 0
          ) {
            this.goForward(this.myStepper, "thirdTab");
            this.toastr.error("Please upload the Pictures");
            return;
          }
          for (const val of a) {
          
            val.type_of_packing = val.type_of_packing.join(",");
          }
        }
        this.createShipmentForm.patchValue({
          images: this.images,
          // take_in: this.convertToIso(new Date(this.createShipmentForm.value.take_in)),
          take_in: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          pieces_weight_array: a,
        });
      } else {
        this.createShipmentForm.patchValue({
          images: this.images,
          // take_in:this.convertToIso(new Date(this.createShipmentForm.value.take_in)),
          take_in: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          pieces_weight_array: [],
        });
        if (
          this.createShipmentForm.get("cargoAccLocation").value !== "" &&
          this.urls.length === 0 &&
          this.old_Images.length === 0
        ) {
          this.goForward(this.myStepper, "thirdTab");
          this.toastr.error("Please upload the Pictures");
          this.morehawscall();
          return;
        }
      }
     
      // this.createShipmentForm.get("createdOn").setValue(this.convertToIso(new Date()));
      // this.createShipmentForm.get("createdOn").setValue(this.dateTimeFunc.convertToIso(new Date()));
      this.createShipmentForm.get("createdOn").setValue(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
      // this.createShipmentForm.get("created").setValue(this.convertUTCDateToLocalDate(new Date()));
      this.spinner.show();
      this.cargoAcceptanceService
        .createCargo(this.createShipmentForm.value)
        .subscribe(
          (responseData) => {
            this.createCargoResp = responseData;
            this.spinner.hide();
            if (this.createCargoResp.responseCode === 1) {
              this.toastr.success(this.createCargoResp.message);
              this.route.navigateByUrl(
                "/CargoAcceptance/view-cargo-acceptance"
              );
            } else {
              this.toastr.error(this.createCargoResp.message);
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    } else {
      this.toastr.error("Please fill the Shipment Information");
    }
  }

  selectimages(val) {
    
    const self = this;
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        const base64 = base64String.split(",")[1];
        self._imagesArrayTotal.push(base64);
        self.preview(self._imagesArrayTotal);
      });
    }
    
  }

  preview(files) {
    this.urls = [];

    if (files.length !== 0) {
      for (const file of files) {
        const image = new Image();
        image.src = "data:image/png;base64," + file;
        this.urls.push(image);
      }
      this.goForward1("fourthTab");
    }

    if (files.length === 0) {
    }

    this.checkImages();
   
   
    // this._isImagesSelected(); // to change color of stepper
  }

  checkImages() {
    // this.storageLocationValue = value;
    if (this.storageLocationValue !== "" && this.urls.length === 0) {
      this.storageLocationImagesEmpty = true;
    } else if (
      this.storageLocationValue === "" ||
      (this.storageLocationValue != "" && this.urls.length !== 0)
    ) {
      this.storageLocationImagesEmpty = false;
    }
  }


  blobToBase64(blob, callback) {
    let reader = new FileReader();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    const watermarkOptions = {
      type: 'image/jpeg',
    };
    reader.onload = function () {
      let dataUrl = reader.result;
      dataUrl = blob;
      watermark([dataUrl],watermarkOptions)
      .dataUrl(watermark.text.lowerLeft('', "0px Arial", '#bfbfbf', 0))
      .then(function (img) {
        // const img2 = img;
        let imgElement = new Image();
        imgElement.src = img;
        imgElement.onload = function() {
          let imageWidth = imgElement.width;
          let imageHeight = imgElement.height;
          let fontSize = Math.min(imageWidth, imageHeight) * 0.07; // Adjust the multiplier as needed
          watermark([img],watermarkOptions)
          .dataUrl(watermark.text.lowerLeft(formattedDate, `${fontSize}px Arial`, '#bfbfbf', 1))
          .then(function (img2) {
            callback(img2); // Call the callback with the watermarked image
          });
        }
      });
      // callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  };
  on_Cancel_Image(i: number, type: string): void {
    // 
    if (type === "NEW") {
      this.urls.splice(i, 1);
      this._imagesArrayTotal.splice(i, 1);
    } else {
      this.old_Images[i].status = 0;
      let img_split = [];
      img_split = this.old_Images[i].image_path.split("/");
      this.old_Images[i].deleteImageName = img_split[img_split.length - 1];
      this.old_Images[i].imageURL = "";
      this.deleted_Old_Images.push(this.old_Images[i]);
      this.old_Images.splice(i, 1);
    }
    if (this.urls.length > 0 || this.old_Images.length > 0) {
      this._setColoursToEach_Stepper_Header("green", 3);
    } else {
      this._setColoursToEach_Stepper_Header("white", 3);
    }
   
    (document.getElementById("img") as HTMLInputElement).value = "";

    this.checkImages();
  }

  // _isImagesSelected(): void {
  //   if ((this.urls.length !== 0) || (this.old_Images.length !== 0)) {
  //     this._setColoursToEach_Stepper_Header('green', 1);
  //   } else {
  //     this._setColoursToEach_Stepper_Header('rgb(242 242 242)', 1);
  //   }
  // }

  // --------End Images selection related------------//

  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number): void {
   
    const test = Array.from(document.getElementsByClassName("mat-step-icon"));
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  onClickT_D(event): void {
  
    if (event.value === "Yes") {
      this.checkedT_d = true;
    } else {
      this.createShipmentForm.patchValue({
        UN: "",
        proper_shipping: "",
        packing_group: "",
        class: "",
        net_quantity: "",
      });
      this.checkedT_d = false;
    }
    this.goForward1("secondTab");
  }

  DG_extra() {
    if (this.formSubmissionType === "Edit") {
      if (this.createShipmentForm.get("DG_labels_available").value === "Yes") {
        this.checkedT_d = true;
      } else {
        this.checkedT_d = false;
      }
    }
  }

  goBack(stepper, tab) {
    const stepperArray = ["firstTab", "secondTab", "thirdTab", "fourthTab"];
    for (const tab of stepperArray) {
      this.goForward1(tab);
    }
    
    if (tab === "firstTab") {
      this.isFirstTabCompleted = false;
      if (
        this.createShipmentForm.get("identification").value === "Label" &&
        this.createShipmentForm.get("label").value === ""
      ) {
        document.getElementById("lable").focus();
        this.toastr.error(`Please fill the label field`);
        return;
      }
      if (
        ((this.createShipmentForm.get("identification").value !== "" &&
          this.createShipmentForm.get("label").value !== "" &&
          this.createShipmentForm.get("identification").value !== "N/A") ||
          (this.createShipmentForm.get("identification").value !== "" &&
            this.createShipmentForm.get("NA").value !== "" &&
            this.createShipmentForm.get("identification").value !== "Label")) &&
        this.createShipmentForm.get("destination").value !== ""
      ) {
        // this.myStepper.next();
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        // setTimeout(() => {
        //   this.goForward1('firstTab');
        //   this.myStepper.next();
        // }, 0);
        this.myStepper.previous();
      } else {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        this._setColoursToEach_Stepper_Header("white", 0);
        for (const key of Object.keys(this.createShipmentForm.controls)) {
          if (this.createShipmentForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (
              this.createShipmentForm.get("identification").value === "Label" &&
              this.createShipmentForm.get("label").value === ""
            ) {
              document.getElementById("lable").focus();
              this.toastr.error(`Please fill the label field`);
              break;
            }
            if (key === "destination") {
              this.toastr.error(`Please fill the Destination`);
              invalidControl.focus();
              break;
            } else {
              this.toastr.error(`Please fill the ${key} field`);
              invalidControl.focus();
              break;
            }
          }
        }
      }
    }
    if (tab === "secondTab") {
      this.isSecondTabCompleted = false;
      if (
        this.createShipmentForm.get("dangerous_goods").value !== "" ||
        this.createShipmentForm.get("bonded").value !== "" ||
        this.createShipmentForm.get("address_labels_available").value !== "" ||
        this.createShipmentForm.get("DG_labels_available").value !== "" ||
        this.createShipmentForm.get("security_status").value !== "" ||
        this.createShipmentForm.get("any_damage_noticed").value !== ""
      ) {
        this.isFirstTabCompleted = false;
        this.isSecondTabCompleted = false;
        this.isThirdTabCompleted = false;
        this.isFourthTabCompleted = false;
        if (
          this.createShipmentForm.get("dangerous_goods").value !== "" &&
          this.createShipmentForm.get("bonded").value !== "" &&
          this.createShipmentForm.get("DG_labels_available").value !== "" &&
          this.createShipmentForm.get("security_status").value !== "" &&
          this.createShipmentForm.get("any_damage_noticed").value !== "" &&
          this.createShipmentForm.get("address_labels_available").value !== ""
        ) {
          this.isFirstTabCompleted = true;
          this.isSecondTabCompleted = true;
          this.isThirdTabCompleted = true;
          this.isFourthTabCompleted = true;
          this.goForward1("secondTab");
          stepper.previous();
          this._setColoursToEach_Stepper_Header("green", 1);
        } else {
          this.isFirstTabCompleted = false;
          this.isSecondTabCompleted = false;
          this.isThirdTabCompleted = false;
          this.isFourthTabCompleted = false;
          this._setColoursToEach_Stepper_Header("white", 1);
          for (const key of Object.keys(this.createShipmentForm.controls)) {
            if (this.createShipmentForm.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector(
                '[formcontrolname="' + key + '"]'
              );
              if (key === "address_labels_available") {
                this.toastr.error(
                  `Please select the Address labels available field`
                );
                invalidControl.focus();
                break;
              }
              if (key === "dangerous_goods") {
                this.toastr.error(`Please select the Dangerous goods`);
                break;
              }
              if (key === "DG_labels_available") {
                this.toastr.error(`Please select the DG labels Available`);
                break;
              }
              if (key === "bonded") {
                this.toastr.error(`Please select the Bonded`);
                break;
              }
              if (key === "security_status") {
                this.toastr.error(`Please select the Security Status`);
                break;
              }
              if (key === "any_damage_noticed") {
                this.toastr.error(`Please select the Any damage noticed`);
                break;
              }
            }
          }
        }
      } else {
        this.isFirstTabCompleted = true;
        this.isSecondTabCompleted = true;
        this.isThirdTabCompleted = true;
        this.isFourthTabCompleted = true;
        stepper.previous();
      }
    }
    if (tab === "thirdTab") {
      this.isThirdTabCompleted = true;
      if (
        this.itemsArray.length > 0 ||
        this.createShipmentForm.get("cargoAccLocation").value !== ""
      ) {
        if (this.itemsArray.length === 1) {
          let abcd = this.itemsArray.filter((data) => {
         
            return !(
              data.type_of_packing.length === 0 &&
              data.pieces === "" &&
              data.length === "" &&
              data.width === "" &&
              data.height === "" &&
              data.weight === ""
            );
          });
          for (let item of abcd) {
           
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
              
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
           
          }
        } else {
          for (let item of this.itemsArray) {
          
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
           
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
           
          }
        }
        if (
          this.createShipmentForm.get("cargoAccLocation").value !== "" &&
          this.urls.length === 0 &&
          this.old_Images.length === 0
        ) {
          this.toastr.error("Please upload the Pictures");
          return;
        }
        if (this.editData !== null && this.editData !== undefined && this.editData !== '') {
          if (this.editData.cargoDetails.cargo_acc_location !== this.createShipmentForm.get('cargoAccLocation').value) {
            if (this.urls.length === 0) {
              this.toastr.error("Please upload the Pictures");
              return;
            }
          }
        }
        this._setColoursToEach_Stepper_Header("green", 2);
        stepper.previous();
      } else {
        this._setColoursToEach_Stepper_Header("white", 2);
        stepper.previous();
      }
    }
    if (tab === "fourthTab") {
      this.isFourthTabCompleted = true;
      if (this.urls.length > 0 || this.old_Images.length > 0) {
        this._setColoursToEach_Stepper_Header("green", 3);
      } else {
        this._setColoursToEach_Stepper_Header("white", 3);
      }
      stepper.previous();
    }
  }
  convertUTCDateToLocalDate(date1){
    let dt = this.utcToLocal(date1, moment.tz.guess());
    return  dt === "Invalid Timezone Operator" ? date1 : dt;
    }
  
    utcToLocal(utcdateTime, tz) {
     var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
     var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
     var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
     var localDateTime
     var hours = zoneValue.split(":")[0]
     var minutes = zoneValue.split(":")[1]
     if (operator === "-") {
         localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
     } else if (operator) {
         localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
     } else {
         localDateTime = "Invalid Timezone Operator"
     }
     return localDateTime
 }

  /// ------- Edit functionalities -------- ///
  fn_ToGet_ShipmentData(): void {
    // get data to view

    let array1: any[];
    let array2: any[];
    if (
      this.shipmentId !== null &&
      this.shipmentId !== undefined
    ) {
      const inputObj = {
        orgId: this.orgDetails.orgId,
        loginUserId: this.orgDetails.userId,
        cargoId: this.shipmentId,
      };
      this.spinner.show();
      this.cargoAcceptanceService.api_To_GetCargo_Details(inputObj).subscribe(
        (responseData) => {
          this.editData = responseData;
          (this.editData.cargoDetails.generateLabel != 'Yes' ? (this.createShipmentForm.controls.identification.enable()) : (this.createShipmentForm.controls.identification.disable()));
          (this.editData.cargoDetails.generateLabel != 'Yes' ? (this.createShipmentForm.controls.label.enable()) : (this.createShipmentForm.controls.label.disable()));
          (this.editData.cargoDetails.generateLabel != 'Yes' ? (this.createShipmentForm.controls.shipment_number.enable()) : (this.createShipmentForm.controls.shipment_number.disable()));
          if(this.formSubmissionType  === "Edit") {
            this.isStorageLocChange = false ;
          }
          if (this.editData.responseCode === 1) {
            this.spinner.hide();
            this.isFirstTabCompleted = true;
            this.isLinear = false;
            this.cargoDetails = this.editData.cargoDetails;
            this.TypeOfPackingIDs = this.editData.cargoDetails.types_of_packing;
            if (this.TypeOfPackingIDs !== undefined) {
              array1 = this.TypeOfPackingIDs.split(",");
            }
            this.mpa = this.editData.cargoDetails.types_of_packing_desc;
            this.Lcn = this.editData.cargoDetails.location_desc;
            this.othertypePacking =
              this.editData.cargoDetails.types_of_packing_other;
            this.createShipmentForm.patchValue({
              identification: this.editData.cargoDetails.identification,
              label: this.editData.cargoDetails.label,
              shipment_number: this.editData.cargoDetails.shipment_number,
              destination: this.editData.cargoDetails.Destination,
              order_pieces: "",
              type_of_packing: this.mpa,
              // take_in: moment(new Date(this.editData.cargoDetails.take_in)),
              // take_in: new Date(this.convertUTCDateToLocalDate(this.editData.cargoDetails.take_in)),
              take_in: new Date(this.editData.cargoDetails.take_in),
              // take_in: this.datePipe.transform(this.editData.cargoDetails.take_in, 'yyyy-MM-dd HH:mm:ss'),
              shipper_name: this.editData.cargoDetails.shipper_name,
              city: this.editData.cargoDetails.city,
              // expected_total_pieces: this.editData.cargoDetails.expected_total_pieces,
              expected_total_pieces: this.editData.cargoDetails.expected_total_pieces ? this.editData.cargoDetails.expected_total_pieces : '',
              expected_total_weight: this.editData.cargoDetails.expected_total_weight ? this.editData.cargoDetails.expected_total_weight : '',
              total_pieces: this.editData.cargoDetails.total_pieces,
              total_weight: this.editData.cargoDetails.total_weight,
              pieces_weight_array:
                this.editData.cargoDetails.pieces_weight_array,
              cargoAccLocation: this.editData.cargoDetails.cargo_acc_location,
              type_of_packing_other:
                this.editData.cargoDetails.types_of_packing_other,
              address_labels_available:
                this.editData.cargoDetails.address_lable,
              dangerous_goods: this.editData.cargoDetails.dangerous_goods,
              DG_labels_available: this.editData.cargoDetails.DG_labels,
              bonded: this.editData.cargoDetails.bonded,
              security_status: this.editData.cargoDetails.security_status,
              any_damage_noticed: this.editData.cargoDetails.is_damaged,
              UN: this.editData.cargoDetails.UN,
              class: this.editData.cargoDetails.class,
              proper_shipping: this.editData.cargoDetails.proper_shipping,
              net_quantity: this.editData.cargoDetails.net_quantity,
              packing_group: this.editData.cargoDetails.packing_group,
              remarks: this.editData.cargoDetails.remarks,
              images: this.editData.cargoDetails.cargoImages,
            });
            if (this.editData.cargoDetails.pieces_weight_array.length > 0) {
              this.itemsArray =
                this.editData.cargoDetails.pieces_weight_array.filter(
                  (data, i) => {
                    return (data.id = 1 + i);
                  }
                );
            }
            this.itemsArray = this.editData.cargoDetails.pieces_weight_array;
            for (let item of this.itemsArray) {
              item.type_of_packing_other = item.types_of_packing_other;
              item.type_of_packing = item.types_of_packing
                .split(",")
                .map((e) => {
                  return parseInt(e);
                });
            }
            this.onchangetop();
            if (this.itemsArray.length === 0) {
              this.morehawscall();
            }
            if (this.editData.cargoDetails.DG_labels === "Yes") {
              this.checkedT_d = true;
            }
            if (array1 !== undefined) {
              array1.forEach((element) => {
                for (const typePacking of this.TypePackingarray) {
                  if (typePacking.id === parseInt(element)) {
                    typePacking.checked = true;
                  }
                }
              });
            }
            if (array2 !== undefined) {
              array2.forEach((element) => {
                for (const location of this.Locationarray) {
                  if (location.id === parseInt(element)) {
                    location.checked = true;
                  }
                }
              });
            }
            this.DG_extra();
            this.onChangeTypePacking();
            this.Editidentificationcall(
              this.editData.cargoDetails.identification
            );
            this.shipment_TotalData_ToUpdate = this.editData.cargoDetails;
            this.old_Images = this.editData.cargoDetails.cargoImages;
            if (this.old_Images === null || this.old_Images === undefined) {
              this.old_Images = [];
            }
            this.checkImages();
            this.countAmount('p');
            this.countAmount('w');
            if (
              this.createShipmentForm.get("dangerous_goods").value === "Yes"
            ) {
              this.showDGoodsfield = true;
            } else {
              this.showDGoodsfield = false;
            }
            const stepperArray = [
              "firstTab",
              "secondTab",
              "thirdTab",
              "fourthTab",
            ];
            for (const tab of stepperArray) {
              this.goForward1(tab);
            }
            for (let n = 3; n >= 0; n--) {
              this.myStepper.previous();
            }
          } else {
            this.toastr.error(this.editData.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance");
    }
  }

  onUpdateFormData(tabName) {
    if(tabName === 'third'){
      if(this.itemsArray && this.itemsArray.length !== 0){
        if(this.itemsArray[0].type_of_packing && this.itemsArray[0].type_of_packing.length === 0){
          this.toastr.error("Please select the Type of Packing");
          return;
        }
      }
      if(this.itemsArray.length === 0 || !this.itemsArray){
        this.toastr.error("Please select the Type of Packing");
        return;
      };
    }
    if (
      ((this.createShipmentForm.get("identification").value !== "" &&
        this.createShipmentForm.get("label").value !== "" &&
        this.createShipmentForm.get("identification").value !== "N/A") ||
        (this.createShipmentForm.get("identification").value !== "" &&
          this.createShipmentForm.get("NA").value !== "" &&
          this.createShipmentForm.get("identification").value !== "Label")) &&
      this.createShipmentForm.get("destination").value !== ""
    ) {
      if (this.itemsArray.length === 1) {
        let abcd = this.itemsArray.filter((data) => {
         
          return !(
            data.type_of_packing.length === 0 &&
            data.pieces === "" &&
            data.length === "" &&
            data.width === "" &&
            data.height === "" &&
            data.weight === ""
          );
        });
        if (abcd.length === 0) {
          this.itemsArray = [];
        }
      }

      if (this.itemsArray.length > 0) {
        const a = this.itemsArray;
        
        if (a.length > 0) {
          for (let item of a) {
           
            if (item.type_of_packing.length !== 0) {
              for (let tlValue of item.type_of_packing) {
              
                if (tlValue === 6) {
                  if (item.type_of_packing_other === "") {
                    this.toastr.error(`Please Fill the Type of packing Other`);
                    return;
                  }
                }
                if (tlValue === "NaN") {
                  item.type_of_packing = "";
                }
              }
            } else {
              this.toastr.error("Please select the Type of Packing");
              return;
            }
           
          }
          if (
            this.createShipmentForm.get("cargoAccLocation").value !== "" &&
            this.urls.length === 0 &&
            this.old_Images.length === 0
          ) {
            this.goForward(this.myStepper, "thirdTab");
            this.toastr.error("Please upload the Pictures");
            return;
          } else {
            if(this.createShipmentForm.get('cargoAccLocation').value!==''){
            if (this.editData !== null && this.editData !== undefined && this.editData !== '') {
              if (this.editData.cargoDetails.cargo_acc_location !== this.createShipmentForm.get('cargoAccLocation').value) {
                if (this.urls.length === 0) {
                  this.goForward(this.myStepper, "thirdTab");
                  this.toastr.error("Please upload the Pictures");
                  return;
                }
              }
            }
            }
          }
          for (const val of a) {
          
            if(typeof val.type_of_packing=="object")
              val.type_of_packing = val.type_of_packing.join(",");
          }
        }
        this.createShipmentForm.patchValue({
          images: this.images,
          take_in: new Date(this.createShipmentForm.value.take_in),
          pieces_weight_array: a,
        });
      } else {
        this.createShipmentForm.patchValue({
          images: this.images,
          take_in: new Date(this.createShipmentForm.value.take_in),
          pieces_weight_array: [],
        });
        if (
          this.createShipmentForm.get("cargoAccLocation").value !== "" &&
          this.urls.length === 0 &&
          this.old_Images.length === 0
        ) {
          this.goForward(this.myStepper, "thirdTab");
          this.toastr.error("Please upload the Pictures");
          this.morehawscall();
          return;
        }
      }
//new change


      if((this.isStorageLocChange) && (this.createShipmentForm.get('cargoAccLocation').value !== '' && this.createShipmentForm.get('cargoAccLocation').value)){
        if (this.urls.length === 0) {
        
          this.goForward(this.myStepper, "thirdTab");
          this.toastr.error("Please upload the Pictures");
          this.morehawscall();
          return;
        }
      }
      const inputObj = {
        cargoId: this.shipmentId,
        org_id: this.createShipmentForm.value.org_id,
        loginUserId: this.createShipmentForm.value.loginUserId,
        // identification: ((this.editData.cargoDetails.generateLabel != 'Yes') ? 'Label' : 'N/A'),
        identification: ((this.editData.cargoDetails.generateLabel != 'Yes') ? this.createShipmentForm.value.identification : this.editData.cargoDetails.identification),
        generateLabel: ((this.editData.cargoDetails.generateLabel != 'Yes') ? this.createShipmentForm.value.generateLabel : this.editData.cargoDetails.generateLabel),
        generateLabelImage: this.createShipmentForm.value.generateLabelImage,
        NA: this.createShipmentForm.value.NA,
        label: ((this.editData.cargoDetails.generateLabel != 'Yes') ? this.createShipmentForm.value.label : this.editData.cargoDetails.label),
        shipment_number: ((this.editData.cargoDetails.generateLabel != 'Yes') ? this.createShipmentForm.value.shipment_number : this.editData.cargoDetails.shipment_number),
        destination: this.createShipmentForm.value.destination,
        take_in: moment(new Date(this.createShipmentForm.value.take_in)).format("YYYY-MM-DD HH:mm:ss"),
        location: this.LocationIDs,
        order_pieces: 0,
        address_labels_available:
          this.createShipmentForm.value.address_labels_available,
        dangerous_goods: this.createShipmentForm.value.dangerous_goods,
        DG_labels_available: this.createShipmentForm.value.DG_labels_available,
        any_damage_noticed: this.createShipmentForm.value.any_damage_noticed,
        remarks: this.createShipmentForm.value.remarks,
        shipper_name: this.createShipmentForm.value.shipper_name,
        city: this.createShipmentForm.value.city,
        weight: this.createShipmentForm.value.weight,
        length: this.createShipmentForm.value.length,
        width: this.createShipmentForm.value.width,
        height: this.createShipmentForm.value.height,
        cargoAccLocation: this.createShipmentForm.value.cargoAccLocation,
        images: [],
        expected_total_pieces: this.createShipmentForm.value.expected_total_pieces,
        expected_total_weight: this.createShipmentForm.value.expected_total_weight,
        total_pieces: this.createShipmentForm.value.total_pieces,
        total_weight: this.createShipmentForm.value.total_weight,
        pieces_weight_array: this.createShipmentForm.value.pieces_weight_array,
        bonded: this.createShipmentForm.value.bonded,
        security_status: this.createShipmentForm.value.security_status,
        UN: this.createShipmentForm.value.UN,
        class: this.createShipmentForm.value.class,
        proper_shipping: this.createShipmentForm.value.proper_shipping,
        net_quantity: this.createShipmentForm.value.net_quantity,
        packing_group: this.createShipmentForm.value.packing_group,
        // updatedOn: this.convertToIso(new Date())
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (
        this._imagesArrayTotal.length !== 0 ||
        this.deleted_Old_Images.length !== 0
      ) {
        if (this._imagesArrayTotal.length !== 0) {
          for (let i = 0; i < this._imagesArrayTotal.length; i++) {
            const obj = {
              id: null,
              status: 1,
              deleteImageName: "",
              imageURL: this._imagesArrayTotal[i],
            };
            inputObj.images.push(obj);
          }
        }
        if (this.deleted_Old_Images.length !== 0) {
          for (let i = 0; i < this.deleted_Old_Images.length; i++) {
            inputObj.images.push(this.deleted_Old_Images[i]);
          }
        }
      } else {
        const obj = {
          id: "",
          status: 0,
          deleteImageName: "",
          imageURL: "",
        };
        inputObj.images.push(obj);
      }
      this.spinner.show();
      this.cargoAcceptanceService.updateCargo(inputObj).subscribe(
        (responseData) => {
          this.updateResp = responseData;
          this.spinner.hide();
          if (this.updateResp.responseCode === 1) {
            let consolidateObj = {
              awbIds: [this.shipmentId],
              org_id: this.createShipmentForm.value.org_id,
              loginUserId: this.createShipmentForm.value.loginUserId,
              todayDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              from: "CW",
              airwaybill: "",
              airwaybillCapt: "",
            };
            this.cargoAcceptanceService.createShipmentConsolidate(consolidateObj).subscribe((resp) => {
            }, err =>{
              this.toastr.error(err.message);
            })
            this.toastr.success(this.updateResp.message);
            this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance");
          } else {
            this.toastr.error(this.updateResp.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.toastr.error("Please fill the mandatory fields!");
    }
  }

  saveAndUpdate() {
    const stepperArray = ["firstTab", "secondTab"];
    for (const tab of stepperArray) {
      this.goForward(this.myStepper, tab);
    }
    if (this.createShipmentForm.valid !== true) {
      this.toastr.error("Please fill the mandatory fields!");
      return;
    }
    this.onUpdateFormData("");
  }

  saveAndExit() {
    const stepperArray = ["firstTab", "secondTab"];
    for (const tab of stepperArray) {
      this.goForward(this.myStepper, tab);
    }
    if (this.createShipmentForm.valid !== true) {
      this.toastr.error("please fill the mandatory fields!");
      return;
    }
    this.onSubmitFormData();
  }

  notCancel() {
  }

  AlertBasedonSubmitFormData(myStepper){
    if(myStepper.selectedIndex == 2 || myStepper.selectedIndex == 3){
      if(this.submitYesorNo != 'yes'){
        if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces && this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
          this.alertMessagebbasedonpcswt = 'Received pieces and weight are more than expected. Would you like to proceed?';
          this.openDialog(this.alertMessagebbasedonpcswt, 'create')
        }else if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces ) {
          this.alertMessagebbasedonpcswt = 'Received pieces is more than expected. Would you like to proceed?';
          this.openDialog(this.alertMessagebbasedonpcswt, 'create')
        }else if(this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
          this.alertMessagebbasedonpcswt = 'Received weight is more than expected. Would you like to proceed?';
          this.openDialog(this.alertMessagebbasedonpcswt, 'create')
        }
        else {
          this.alertMessagebbasedonpcswt = '';
          this.onSubmitFormData();
        }  
      }else {
        this.alertMessagebbasedonpcswt = '';
        this.onSubmitFormData();  
      }
    }else if(myStepper.selectedIndex == 0){
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.alertMessagebbasedonpcswt = '';
        this.onSubmitFormData();
    },2000)
    }
    else {
      this.alertMessagebbasedonpcswt = '';
      this.onSubmitFormData();
    }
  }


  alertBasedonUpddateFormData(tabName){
      if(tabName == 'third' || tabName == 'fourth'){
        if(this.updateYesorNo != 'yes'){
          if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces && this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
            this.alertMessagebbasedonpcswt = 'Received pieces and weight are more than expected. Would you like to proceed?';
            this.openDialog1(this.alertMessagebbasedonpcswt, tabName, 'update')
          }else if(this.createShipmentForm.value.total_pieces > this.createShipmentForm.value.expected_total_pieces ) {
            this.alertMessagebbasedonpcswt = 'Received pieces is more than expected. Would you like to proceed?';
            this.openDialog1(this.alertMessagebbasedonpcswt, tabName, 'update')
          }else if(this.createShipmentForm.value.total_weight > this.createShipmentForm.value.expected_total_weight){
            this.alertMessagebbasedonpcswt = 'Received weight is more than expected. Would you like to proceed?';
            this.openDialog1(this.alertMessagebbasedonpcswt,tabName, 'update')
          }
          else {
            this.alertMessagebbasedonpcswt = '';
            this.onUpdateFormData(tabName);
          }  
        }else {
          this.alertMessagebbasedonpcswt = '';
          this.onUpdateFormData(tabName);  
        }
      }else if(tabName == 'first'){
        this.spinner.show();
        setTimeout(() => {
          this.spinner.hide();
          this.alertMessagebbasedonpcswt = '';
          this.onUpdateFormData(tabName);  
        },2000)
      }
      else {
        this.alertMessagebbasedonpcswt = '';
        this.onUpdateFormData(tabName);  
      }
  }

  

  openDialog(alertMessagebbasedonpcswt, createOrupdate): void {
    const dialogRef = this.dialog.open(AlertBasedOnPiecesAndWeight, {
      width: '350px',
      data: { alertMessage: alertMessagebbasedonpcswt, createOrupdate: createOrupdate, tabName: '' }
    });

    dialogRef.componentInstance.submitForm.subscribe((res) => {
      this.submitYesorNo = res;
    });
  }

  openDialog1(alertMessagebbasedonpcswt, tabName, createOrupdate): void {
    const dialogRef = this.dialog.open(AlertBasedOnPiecesAndWeight, {
      width: '350px',
      data: { alertMessage: alertMessagebbasedonpcswt, createOrupdate: createOrupdate,tabName:tabName }
    });

    dialogRef.componentInstance.updateForm.subscribe((res) => {
      this.updateYesorNo = res.yes;
    });
  }

  onCancelPage() {
    this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance");
  }

  clickToCapture() {
    this.imageLabel;
    let node = document.getElementById('content');
    htmlToImage
      .toPng(node)
      .then((dataUrl) => {
        let base64Data = dataUrl.split(',')[1];

        let image = new Image(); // Create an image object to get the image dimensions
        image.onload = () => {
          image.width= 377.95;
          image.height=340.15;
        }
        image.src = 'data:image/jpeg;base64,' + base64Data;
        this.createShipmentForm.patchValue({
          generateLabelImage: image.src.split(',')[1]
        })
      })
      .catch( (error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  changeidentificationcall(val) {
    if(this.formSubmissionType == 'Edit'){
      if (val.value === "Label") {
        this.createShipmentForm.patchValue({ 
          NA: "" ,
          label: "",
          shipment_number: "",
          generateLabel: 'No',

        });
        this.showlabelfield = true;
      } else {
        let randomNumber = Date.now().toString();
        let randomNumberForLabel = '';
        const now = new Date();
        const year = now.getFullYear().toString();
        const month = (now.getMonth() + 1).toString().padStart(2, '0')
        const randomNumber2 = year[2] + year[3] + month + Date.now().toString().slice(-5);
        randomNumber =
          this.orgDetails.stationCode +
          "-" +
          "EA" +
          "-" +
          this.shipmentNumber.shipment_number;
          randomNumberForLabel = this.orgDetails.stationCode + "-" + "TI" + "-" + randomNumber2;
        this.createShipmentForm.patchValue({
          NA: "NA",
          label: randomNumberForLabel,
          shipment_number: randomNumber,
          generateLabel: 'Yes',
        })
        this.storeLabel = randomNumberForLabel;
        this.storeTakein = this.createShipmentForm.value.take_in;
        this.showlabelfield = true;
        this.showlabelfield2 =true;
        setTimeout(() => {
          JsBarcode("#barcode", randomNumberForLabel, {
            format: "CODE128",
            lineColor: "#000",
            width:1.25,
            height:100,
            displayValue: false
          });
        }, 500);
        setTimeout(() => {
         this.clickToCapture();
          this.showlabelfield2 = true;
        }, 1500);
      }
    }else {
      if (val.value === "Label") {
        this.createShipmentForm.patchValue({ 
          NA: "" ,
          label: "",
          shipment_number: "",
          generateLabel: 'No',
        });
        this.showlabelfield = true;
      } else {
        let randomNumber = Date.now().toString();
        let randomNumberForLabel = '';
        const now = new Date();
        const year = now.getFullYear().toString();
        const month = (now.getMonth() + 1).toString().padStart(2, '0')
        const randomNumber2 = year[2] + year[3] + month + Date.now().toString().slice(-5);
        randomNumber =
          this.orgDetails.stationCode +
          "-" +
          "EA" +
          "-" +
          this.shipmentNumber.shipment_number;
          randomNumberForLabel = this.orgDetails.stationCode + "-" + "TI" + "-" + randomNumber2;
        this.createShipmentForm.patchValue({
          NA: "NA",
          label: randomNumberForLabel,
          shipment_number: randomNumber,
          generateLabel: 'Yes',
        })
        this.storeLabel = randomNumberForLabel;
        this.storeTakein = this.createShipmentForm.value.take_in;
        this.showlabelfield = true;
        this.showlabelfield2 =true;
        setTimeout(() => {
          JsBarcode("#barcode", randomNumberForLabel, {
            format: "CODE128",
            lineColor: "#000",
            width:1.25,
            height:100,
            displayValue: false
          });
        }, 500);
        setTimeout(() => {
         this.clickToCapture();
          this.showlabelfield2 = true;
        }, 1500);
      }
    }
  }

  Editidentificationcall(val) {
    if (val === "Label") {
      // this.showlabelfield = true;
      // this.showMABfield = false;
      // } else if (val === 'MAWB') {
      // this.showMABfield = true;
      // this.showlabelfield = false;
    } else {
      // this.showMABfield = false;
      // this.showlabelfield = false;
    }
  }

  convertDate(date: Date) {
       return date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length === 1 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" + ((date.getDate()).toString().length === 1 ? ("0" + (date.getDate())) : (date.getDate())) + " " + ((date.getHours()).toString().length === 1 ? ("0" + (date.getHours())) : (date.getHours())) + ":" + ((date.getMinutes()).toString().length === 1 ? ("0" + (date.getMinutes())) : (date.getMinutes())) + ":" + ((date.getSeconds()).toString().length === 1 ? ("0" + (date.getSeconds())) : (date.getSeconds()));
  }
  
  convertToIso(date: Date){
    let dt  = date.toISOString();
    return   dt.substring(0,10)+" "+ dt.substring(11,19);
  }

  validateStrLoc(){
    if(this.formSubmissionType  === "Edit") {
      this.isStorageLocChange = true ;
    }
  }
}


@Component({
  selector: 'alert-based-on-pieces-and-weight',
  templateUrl: 'alert-based-on-pieces-and-weight.html',
  styleUrls: ["./alert-based-on-pieces-and-weight.scss"],
})
export class AlertBasedOnPiecesAndWeight {

  @Output() submitForm = new EventEmitter<any>();
  @Output() updateForm = new EventEmitter<any>();
  yes = 'yes'
  constructor(
    public dialogRef: MatDialogRef<AlertBasedOnPiecesAndWeight>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onSubmitFormData():void {
    this.submitForm.emit(this.yes);
    this.dialogRef.close();
  }

  onUpdateFormData(tabName: string) {
    this.updateForm.emit({tabName,yes: this.yes});
    this.dialogRef.close();
  }

  notCancel() {
    this.dialogRef.close();
  }

}
