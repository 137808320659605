import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfigServiceService } from 'src/app/config-service.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ViewRecordsTableComponent } from 'src/app/shared/sharedModule/view-records-table/view-records-table.component';
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { TruckInspectionService } from 'src/app/services/truckInspection/truck-inspection.service';
import { SidebarComponent } from 'src/app/layouts/sidebar/sidebar.component';
declare var jQuery: any;
import * as moment from 'moment';
import 'moment-timezone';
import { DateAndTimeFunctions } from 'src/app/shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
import { MatDialog, MatSnackBar } from '@angular/material';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-view-truckinspection',
  templateUrl: './view-truckinspection.component.html',
  styleUrls: ['./view-truckinspection.component.css']
})
export class ViewTruckinspectionComponent implements OnInit {
  @ViewChild("myGrid") myGrid: jqxGridComponent;
  @ViewChild('reportsDataGrid') reportsDataGrid_Ref: ViewRecordsTableComponent;
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  static WEB_API;
  static totalGridData;
  static artype;
  loginDetails: any;
  finalReportsCount: number = 0;
  concatarray: any = [];
  isArchiveEnable: boolean =true;
  datafields = [
    { name: 'actions', type: 'string' },
    { name: "station_code", type: 'string', },
    { name: "compliance_approved_a_front", type: 'string', },
    { name: "compliance_approved_a_front_desc", type: "string" },
    { name: "compliance_approved_b_left", type: 'string', },
    { name: "compliance_approved_b_left_desc", type: 'string', },
    { name: "compliance_approved_c_right", type: 'string', },
    { name: "compliance_approved_c_right_desc", type: 'string', },
    { name: "compliance_approved_comment", type: 'string', },
    { name: "compliance_approved_d_floor", type: 'string', },
    { name: "compliance_approved_d_floor_desc", type: 'string', },
    { name: "compliance_approved_e_roof", type: 'string', },
    { name: "compliance_approved_e_roof_desc", type: 'string', },
    { name: "compliance_approved_f_outside_doors", type: 'string', },
    { name: "compliance_approved_f_outside_doors_desc", type: 'string', },
    { name: "compliance_approved_g_underside", type: 'string', },
    { name: "compliance_approved_g_underside_desc", type: 'string', },
    { name: "created_by", type: 'string', },
    { name: "created_date_time",  type: 'date', format: 'yyyy-MM-dd' },
    { name: "forwarder_org_id", type: 'string', },
    { name: "handler_org_id", type: 'string', },
    { name: "image_path", type: 'string', }, ,
    { name: "inspectionImages", type: 'string', },
    { name: "org_id", type: 'string', },
    { name: "org_type", type: 'string', },
    { name: "prior_inspec_driver_out_vehicle", type: 'string', },
    { name: "prior_inspec_engine_off", type: 'string', },
    { name: "prior_inspec_handbreak_on", type: 'string', },
    { name: "shipper_org_id", type: 'string', },
    { name: "truck_info_bound", type: 'string', },
    { name: "truck_info_customs_mnr", type: 'string', },
    { name: "truck_info_customs_seal_nr", type: 'string', },
    { name: "truck_info_customs_sealed", type: 'string', },
    { name: "truck_info_customs_sealed_intact", type: 'string', },
    { name: "truck_info_date_time", type: 'date', format: 'yyyy-MM-dd' },
    { name: "truck_info_trucking_company_name", type: 'string', },
    { name: "truck_info_vehicle_id", type: 'string', },
    { name: "truck_inspection_id", type: 'string', },
    { name: "updated_by", type: 'string', },
    { name: "updated_date_time",  type: 'date', format: 'yyyy-MM-dd' },
    { name: "created_by", type: 'string', }
  ]
  anchor_Tags: any;
  editicon_Tags: any;
  TotalRows: any = 0;
  isDeleteEnable: boolean=true;
  
  currentTab: string;
  count: number = 0;
  dashBoradSwitchedtemp: any;
  isSlectALL_records: boolean;
  dialogRef: any;
  // status: any;
  obj: any;
  dt = new Date();
  // dt = new FormControl(new Date()).value;
  // d = this.dt.getDate()+'/'+(this.dt.getMonth() + 1)+'/'+this.dt.getFullYear()+' '+this.dt.getHours()+':'+this.dt.getMinutes()+':'+this.dt.getSeconds();
  // comp_date = new FormControl(new Date())
  comp_date = new Date();
  status = new FormControl('completed');
  tr_ins_id_for_status_update: any;



  constructor(
    private config: ConfigServiceService,
    private location: LocationStrategy,
    private spinner: NgxSpinnerService,
    private logServc: LoginService,
    private ViewReportsData: ViewReportsData,
    private route: Router,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private servc: TruckInspectionService,
    private SidebarComponent: SidebarComponent,
    private dateAndTimeFunc : DateAndTimeFunctions,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    ViewTruckinspectionComponent.artype = this.isDeleteEnable;
    ViewTruckinspectionComponent.WEB_API = this.config.getConfig().ULD_Url;
  }

  OnChangeReports_Tab(tab) {

  }
  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    localStorage.removeItem("TRUCK_INSPECTION_ID");
    localStorage.removeItem("TRUCK_INSPECTION_FROM");
  }

  ngAfterViewInit(){
    if(this.ViewReportsData.TRUCK_INSPECTION_CURRENTTAB !== 'A' ){
      this.getReports("R");
      if (document.getElementById('active_Reports') !== null) {
        document.getElementById('active_Reports').className = 'nav-link';
    }
    if (document.getElementById('archive_Reports') !== null) {
        document.getElementById('archive_Reports').className = 'nav-link active';
    }
 
    }else{
      this.getReports("A");
      

    if (document.getElementById('active_Reports') !== null) {
        document.getElementById('active_Reports').className = 'nav-link active';
    }
    if (document.getElementById('archive_Reports') !== null) {
        document.getElementById('archive_Reports').className = 'nav-link';
    }
  
    }
  }
  getReports(type: string) {
    
    this.currentTab = type;
    this.ViewReportsData.TRUCK_INSPECTION_CURRENTTAB = type;
    this.spinner.show();
   this.reportsDataGrid_Ref.source.url = ViewTruckinspectionComponent.WEB_API+"inspectionApis/getInspectionListWeb.php/inspectionList?loginUserId="+this.loginDetails.userId+"&orgId="+this.loginDetails.orgId+"&type="+type
   this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
   this.reportsDataGrid_Ref.myGrid.gotopage(0)
   this.reportsDataGrid_Ref.myGrid.clearselection();
   this.reportsDataGrid_Ref.myGrid.refresh();
    
   setTimeout(() => {
    this.spinner.hide();
   }, 200);
   this.selectedRowsData = [];
   ViewRecordsTableComponent.TOTAL_GRIDDATA =[];
   document.body.scrollTop = document.documentElement.scrollTop = 0;
   if(this.loginDetails.reportAcess === 'Y'){
    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
   }else{
    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
   }

   if(type === 'A'){
      this.isDeleteEnable = true;
      this.isArchiveEnable = true;
      ViewTruckinspectionComponent.artype = this.isArchiveEnable;
      if(this.columns[4].datafield === 'completed_date'){
        this.columns.splice(4,1);
      }
   }else{
    // this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
      this.isDeleteEnable = true;
      this.isArchiveEnable = false;
      
      ViewTruckinspectionComponent.artype = this.isArchiveEnable;
      this.columns.splice(4, 0, {
        text: "Completed Date",
        datafield: `completed_date`,
        cellsformat: 'yyyy-MM-dd H:mm',
        width: 200,
        filtertype: 'range',
        editable: false,
        cellclassname: function (row, column, value, data) {
          if(data.completed_date !== 'Invalid date'){
            return data.completed_date
          }else{
            return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+'--'+"</div>")
          }
        },
        cellsrenderer: function (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          columnData
        ) {
          // if(DateAndTimeFunctions.convertUTCDateToLocalDate(value) !== 'Invalid date'){
          //   return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+DateAndTimeFunctions.convertUTCDateToLocalDate(value) +"</div>")
          // }else{
          //   return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+'--'+"</div>")
          // }
          
        },
      })
   }
  }
  ngAfterViewChecked() {
    // ViewTruckinspectionComponent.totalGridData = this.reportsDataGrid_Ref.getTotalData();
    if(ViewTruckinspectionComponent.totalGridData){
    if(ViewTruckinspectionComponent.totalGridData.length !== 0){
      this.TotalRows = ViewRecordsTableComponent.ACTIVE_COUNT ;
    //------ assigning click events to edit icon----///
    this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll("a");
    this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll("i");
    this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
      if (anchor.href != "") {
        let href_Valid_Check_Var = anchor.href.split(":")[0];
        if (anchor.innerText === "Edit" && href_Valid_Check_Var === "edit") {
          anchor.addEventListener("click", this.handleAnchorClick);
        }
      }
    });

    this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
      if (editicon.id != "") {
        let href_Valid_Check_Var = editicon.id.split(":")[0];
        if (href_Valid_Check_Var === "edit") {
          editicon.addEventListener("click", this.handleiconClick);
        }
      }
    });
    
  }
}
  }
  public handleiconClick = (event: Event) => {
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.onClick_Icon(editicon);
  };

  public handleAnchorClick = (event: Event) => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    this.onClick_AnchorTag(anchor);
  };

  onClick_AnchorTag(anchor: HTMLAnchorElement) {
    
    if (anchor.innerText === "Edit") {
      let href_Valid_Check_Var = anchor.href.split(":")[1];
      ViewTruckinspectionComponent.totalGridData[href_Valid_Check_Var];
      this.onEditClick(
        ViewTruckinspectionComponent.totalGridData[href_Valid_Check_Var]
      );
    }
  }

  onClick_Icon(editicon: HTMLImageElement) {
    if(editicon.title === "Edit"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      ViewTruckinspectionComponent.totalGridData[href_Valid_Check_Var];
      this.onEditClick(
        ViewTruckinspectionComponent.totalGridData[href_Valid_Check_Var]
      );
    }
    if(editicon.title === "statusEdit"){
      this.editStatus(editicon.id.split(":")[1])
    }

  }

  editStatus(uid: any): void{
    this.obj = ViewTruckinspectionComponent.totalGridData.find(item => item.truck_inspection_id === parseInt(uid))
    this.tr_ins_id_for_status_update = uid;
    // this.status = ViewTruckinspectionComponent.totalGridData.find(item => item.truck_inspection_id === parseInt(uid)).truck_info_vehicle_id; //inspection_status
    this.dialogRef = this.dialog.open(this.callAPIDialog);
    this.dialogRef.afterClosed().subscribe(result => {
            // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
            if (result !== undefined) {
                if (result === 'yes') {
                    // TODO: Replace the following line with your code.
                    console.log('User clicked yes.');
                } else if (result === 'no') {
                    // TODO: Replace the following line with your code.
                    console.log('User clicked no.');
                }
            }
        })
  }
  onCloseClick(){
    this.dialogRef.close()
  }
  onSaveClick(){
    this.spinner.show();
    this.obj.updatedOn = this.dateAndTimeFunc.convertToIso(new Date());
    // this.obj.truck_info_vehicle_id = this.status;
    let inputObj = {
      "loginUserId": this.loginDetails.userId,
      "inspectionId": this.tr_ins_id_for_status_update,
      "inspectionStatus": this.status.value,
      // "completedDate":  this.dateAndTimeFunc.convertToIso(new Date(this.comp_date))
      "completedDate":  moment(this.comp_date).format("YYYY-MM-DD HH:mm:ss")
      
    }
    this.servc.create_Update_TruckInspec_Record(inputObj,'inspectionApis/updateInspectionStatus.php/updateStatus').subscribe(responseData=>{
      this.dialogRef.close();
    if(responseData['responseCode'] === 1){
      this.spinner.hide();
      this.toastr.success("Successfully archived");
      this.ngAfterViewInit();
    }else{
      this.toastr.error(responseData['message']);
      this.spinner.hide();
    }
    },error=>{
      this.toastr.error("Something went wrong , please try later");
      this.spinner.hide();
    })
  }

  onEditClick(obj: any): void {
    
    // this.ViewReportsData.TRUCK_INSPECTION_FROM = "Edit";
    // this.ViewReportsData.TRUCK_INSPECTION_ID = obj.truck_inspection_id;
    localStorage.setItem("TRUCK_INSPECTION_ID",JSON.stringify(obj.truck_inspection_id));
    localStorage.setItem("TRUCK_INSPECTION_FROM",JSON.stringify("Edit"));
    this.route.navigateByUrl("/Truckinspection/create-truck-inspection");
  }
  // self =this;
  linkrenderer = function (row, column, value) {
    return ("<a  href='" + ViewTruckinspectionComponent.WEB_API + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>");
  };

  columnsrenderer = function (value) {
    return ('<div style="text-align: center; margin-top: 5px;">' + value + "</div>");
  };

  columns = [
    {
      text: "Date / Time",
      datafield: `truck_info_date_time`,
      cellsformat: 'yyyy-MM-dd H:mm',
      width: 200,
      filtertype: 'range',
      editable: false,
      cellclassname: function (row, column, value, data) {
        return data.truck_info_date_time
      },
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {
        // return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+DateAndTimeFunctions.convertUTCDateToLocalDate(value) +"</div>")
      },
    },
    // { text: "Station", datafield: "station_code", width: 80, editable: false },
    {
      text: "Actions", datafield: "actions", cellsalign: "center", width: 60, filterable: false, sortable: false, groupable: false, editable: false,
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        ViewTruckinspectionComponent.totalGridData = ViewRecordsTableComponent.totaldata;
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
        let loginData = JSON.parse(localStorage.getItem('loginresultobject'));
        if (loginData.stationCode == ViewTruckinspectionComponent.totalGridData[columnData.uid].station_code) {
          return (
            "<div style='margin-top:  8.5px;margin-left: 20px;'><a  href='edit:" +
            columnData.uid +
            "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
            columnData.uid +
            "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a>"
          );        } else {
          return "<div style='margin-top:  8.5px; margin-left: 20px;'><i class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip'></i>";
        }
        }
      },
      // renderer: this.columnsrenderer,
    },
    {
      text: "Pdf",
      datafield: "inspection_pdf",
      width: 50,
      filterable: false,
      sortable: false,
      groupable: false,
      editable: false,
      cellsrenderer: this.linkrenderer,
    },
    {
      text: "Status", datafield: "inspection_status", cellsalign: "center", width: 100, filterable: false, sortable: false, groupable: false, editable: false,
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData,
      ) {
        
        ViewTruckinspectionComponent.totalGridData = ViewRecordsTableComponent.totaldata;
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
          if(columnData.inspection_status === null){
            columnData.inspection_status = 'Pending';
          }
        // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
        
        // return (
        //   "<div style='margin-top:  8.5px;margin-left: 20px;'><a  href='edit_status:" +
        //   columnData.uid +
        //   "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
        //   columnData.truck_inspection_id +
        //   "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='statusEdit'></i></a>"
        // );
        if(!ViewTruckinspectionComponent.artype){
          return ("<div style='text-align:center;margin-top:  8.5px; '>"+
            columnData.inspection_status+"</div>"
          );
        } else{
          return ("<div style='text-align:center;margin-top:  8.5px; '>"+
            columnData.inspection_status+ "<a href='edit_status:" +
            columnData.truck_info_vehicle_id +
            "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
            columnData.truck_inspection_id +
            "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='statusEdit'></i></a></div>"
          );
        }
        }
      },
    },
    { text: "Vehicle Id", datafield: "truck_info_vehicle_id", width: 150, editable: false },
    // { text: "Vehicle Id", datafield: "truck_info_vehicle_id", width: 150, editable: false,
    // cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
    //   ) {
    //     ViewTruckinspectionComponent.totalGridData = ViewRecordsTableComponent.totaldata;
    //     if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
    //       return ("<div style='text-align:center;margin-top:  8.5px; '>"+
    //       columnData.truck_info_vehicle_id+ "<a href='edit_status:" +
    //       columnData.truck_info_vehicle_id +
    //       "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
    //       columnData.truck_inspection_id +
    //       "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='statusEdit'></i></a></div>"
    //       );
    //     }
    //   }, },
    { text: "Trucking company", datafield: "truck_info_trucking_company_name", width: 150, editable: false },
    {
      text: "Bound", datafield: "truck_info_bound", width: 150, editable: false, cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px;'>Inbound</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px;'>Outbound</div>");
        }
      }
      },
    },
    { text: "Created By", datafield: "created_by", width: 150, editable: false },

    { text: "MRN/CMR/other", datafield: "truck_info_customs_mnr", width: 160, editable: false },
    { text: "truck_info_customs_seal_nr", datafield: "truck_info_customs_seal_nr", width: 150, editable: false, hidden: true },
    {
      text: "Customs Sealed", datafield: "truck_info_customs_sealed", width: 150, editable: false, cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px;'>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px;'>NO</div>");
        }
      }
      },
    },
    {
      text: "Trailer Locked", datafield: "truck_info_customs_sealed_intact", width: 150, editable: false,
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
       
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y")
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        else if (value === "N")
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
      }
    }
    },
   
    {
      text: "Engine turned off", datafield: "prior_inspec_engine_off", width: 150, editable: false,
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y")
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        else if (value === "N")
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
      }
    }
    },
    {
      text: "Hand break on", datafield: "prior_inspec_handbreak_on", width: 150, editable: false, cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y")
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        else if (value === "N")
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
      }
    }
    },
    {
      text: "Driver out of vehicle", datafield: "prior_inspec_driver_out_vehicle", width: 150, editable: false, cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y")
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        else if (value === "N")
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
      }
    }
    },
    {
      text: "A Front Side", datafield: 'compliance_approved_a_front', width: 130, editable: false,
      cellclassname: function (row, column, value, data) { },
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y")
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        else if (value === "N")
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
      },
    },
    {
      text: "A Front Side description", datafield: 'compliance_approved_a_front_desc', width: 130, editable: false,
      cellclassname: function (row, column, value, data) { },
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {

      },
    },


    {
      // cellsrenderer: this.linkrenderer
      text: "B Left Side", datafield: "compliance_approved_b_left", width: 150,  editable: false,
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
        }
      }
    },
    {
      text: "B Left Side Description", datafield: "compliance_approved_b_left_desc", width: 60, editable: false, cellclassname: function (row, column, value, data) { },
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {

      },
    },
    { text: "C Right Side", datafield: "compliance_approved_c_right", width: 60, editable: false ,
    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
        }
      }},
    { text: "C Right Side Description", datafield: "compliance_approved_c_right_desc", width: 150, editable: false },
    { text: "Comments", datafield: "compliance_approved_comment", width: 150, editable: false },
    { text: "D Floor - bottom", datafield: "compliance_approved_d_floor", width: 150, editable: false,
    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
        }
      } },
    { text: "D Floor - bottom Description", datafield: "compliance_approved_d_floor_desc", width: 150, editable: false },
    { text: "E Roof/Ceiling", datafield: "compliance_approved_e_roof", width: 150, editable: false,
    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
        }
      } },
    { text: "E Roof/Ceiling Description", datafield: "compliance_approved_e_roof_desc", width: 150, editable: false },
    { text: "F (In)outside doors", datafield: "compliance_approved_f_outside_doors", width: 150, editable: false,cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
      ) {
        if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
        if (value === "Y") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
        } else if (value === "N") {
          return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
        }
        }
      } },
    { text: "F (In)outside doors Description", datafield: "compliance_approved_f_outside_doors_desc", width: 150, editable: false },
    { text: "G Underside truck", datafield: "compliance_approved_g_underside", width: 150, editable: false 
  ,cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData
    ) {
      if ((ViewTruckinspectionComponent.totalGridData.length != 0) && (columnData.uid < ViewTruckinspectionComponent.totalGridData.length)) {
      if (value === "Y") {
        return ("<div style='text-align:center;margin-top:  8.5px; '>YES</div>");
      } else if (value === "N") {
        return ("<div style='text-align:center;margin-top:  8.5px; '>NO</div>");
      }
      }
    }},
    { text: "G Underside truck description", datafield: "compliance_approved_g_underside_desc", width: 150, editable: false },
    // { text: "Created Date", datafield: "created_date_time", width: 150, editable: false,cellsformat: 'dd-MM-yyyy HH:mm:ss', filtertype: 'date' },
    {
      text: "Created Date",
      datafield: `created_date_time`,
      cellsformat: 'yyyy-MM-dd H:mm:ss',
      width: 150,
      filtertype: 'range',
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    { text: "Updated By", datafield: "updated_by", hidden: true, width: 150, editable: false },
    {
      text: "Updated Date",
      datafield: `updated_date_time`,
      cellsformat: 'yyyy-MM-dd H:mm:ss',
      width: 150,
      filtertype: 'range',
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    // { text: "Updated Date", datafield: "updated_date_time", width: 150, editable: false,cellsformat: 'dd-MM-yyyy HH:mm:ss', filtertype: 'date' },
    { text: "org_type", datafield: "org_type", hidden: true, editable: false },
    { text: "Org Id", datafield: "org_id", hidden: true, editable: false },
    { text: "truck_inspection_id", datafield: "truck_inspection_id", hidden: true, editable: false },
  ]


  onRowDoubleClick(rowData) {
    // this.ViewReportsData.TRUCK_INSPECTION_ID = rowData.args.row.bounddata.truck_inspection_id;
    localStorage.setItem("TRUCK_INSPECTION_ID",JSON.stringify(rowData.args.row.bounddata.truck_inspection_id));
    this.route.navigateByUrl("/Truckinspection/view-truck-inspection-details");
  }

  public selectedRowsData = [];
  _onSelectRow(event) { // trigger when checkbox checked / unchecked 
    this.isSlectALL_records = false;
    if (Array.isArray(event.args.rowindex)) {
      if (event.args.rowindex.length !== 0) {
        this.isSlectALL_records = true;
        this.selectedRowsData = this.reportsDataGrid_Ref.getTotalData();
      } else {
        this.selectedRowsData = [];
      }
    } else {
      if (event.type === "rowunselect") {
        let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
        this.selectedRowsData.splice(index, 1);
      } else {
        this.selectedRowsData.push(event.args.row);
      }
    }

  
    console.log("this.selectedRowsData", this.selectedRowsData)
  }

  OnClickCargoAcceptance() {
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("CargoAcceptance"));
      this.route.navigateByUrl('/CargoAcceptance/view-cargo-acceptance');
    }, 200);
  }

  onClickWorkOrder(){
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"));
      this.route.navigateByUrl('/CargoImageHandler/view-shipment');
  }, 200);
  }
  onClickCargo_Image(){
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule",JSON.stringify("cargoImage"))
    this.route.navigateByUrl('/CargoImage/view-shipment');
  }, 200);
   
  }

  onClickCargoClaim_Reports() {
    this.spinner.show();
setTimeout(() => {
  localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
  this.route.navigateByUrl('/cargoClaim/dashboard');
}, 200);
}
  // delete and manual archive ---------
  confirm_delete() {
    if (this.selectedRowsData.length != 0) {
      jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
    } else {
      this.spinner.hide();
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }

  }

  confirm_manualarchive() {
    if (this.selectedRowsData.length != 0) {
      jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    } else {
      this.spinner.hide();
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
  }

  manualarchive() {
    if (this.selectedRowsData.length != 0) {
      let selreportsIdarray = [];
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        selreportsIdarray.push(this.selectedRowsData[i]['truck_inspection_id'])
      }
      let inputObj = {
        "loginUserId": this.loginDetails.userId,
        "inspectionId": selreportsIdarray
      }
      this.spinner.show();
      this.servc.SetReportArchive(inputObj).subscribe(
        data => {
          if (data.responseCode == '1') {
            this.toastr.success(data.message, '', {});
            jQuery('#manarchModal').modal('hide');
            this.getReports(this.currentTab);
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#manarchModal').modal('hide');
            this.spinner.hide();
          }
          else {
            this.toastr.error(data.message, '', {});
            jQuery('#manarchModal').modal('hide');
            this.getReports(this.currentTab);
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          jQuery('#manarchModal').modal('hide');
          this.getReports(this.currentTab);
          this.reportsDataGrid_Ref.myGrid.clearselection()
          this.selectedRowsData = [];
        }
      )
    }
    else {
      this.spinner.hide();
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
  }

  DeleteReport() {
    if (this.selectedRowsData.length != 0) {
      let selreportsIdarray = [];
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        selreportsIdarray.push(this.selectedRowsData[i]['truck_inspection_id'])
      }
      let inputObj = {
        "loginUserId": this.loginDetails.userId,
        "inspectionIds": selreportsIdarray
      }
      this.spinner.show();
      this.servc.DeleteReport(inputObj).subscribe(
        data => {
          if (data.responseCode === 1) {
            this.toastr.success(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.getReports(this.currentTab);
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#delrepModal').modal('hide');
            this.spinner.hide();
          }
          else {
            this.toastr.error(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.getReports(this.currentTab);
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          jQuery('#delrepModal').modal('hide');
          this.getReports(this.currentTab);
          this.reportsDataGrid_Ref.myGrid.clearselection()
          this.selectedRowsData = [];
        }
      )
    } else {
      this.spinner.hide();
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
  }


  OnClickCargoDamage(){
    this.spinner.show();
    setTimeout(() => {
        localStorage.setItem("currentModule",JSON.stringify("CARGODAMAGE-FORWARDER"))
    this.route.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder'); 
    }, 200);
}


//redirect to Uld damage reports
onClickUldDamage_Reports() {
  localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
  this.route.navigateByUrl('/Uld/viewuld');
}

DamageReports() {

  this.spinner.show();
  setTimeout(() => {
  localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
  this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
  if (this.dashBoradSwitchedtemp !== null) {
      if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
          if (this.loginDetails.userType == 'SA') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/sa-dashboard');
          } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
              this.SidebarComponent.activecall("1")
             
              this.route.navigate(['csa-dashboard'], {
                 
                  queryParams: {
                      DashBorad: 'switched'
                  },
                  queryParamsHandling: 'merge',
              });
          }
          else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
              this.SidebarComponent.activecall("1")
             
              this.route.navigate(['csa-airline-dashboard'], {
                  queryParams: {
                      DashBorad: 'switched'
                  },
                  queryParamsHandling: 'merge',
              });
          }
          else if (this.loginDetails.userType == 'E') {
              this.SidebarComponent.activecall("1")
             
              this.route.navigate(['user-dashboard'], {
                
                  queryParams: {
                      DashBorad: 'switched'
                  },
                  queryParamsHandling: 'merge',
              });
          }
          else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
              this.SidebarComponent.activecall("1")
             
              this.route.navigate(['admin-handler-dashboard'], {
                 
                  queryParams: {
                      DashBorad: 'switched'
                  },
                  queryParamsHandling: 'merge',
              });
          }
          else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
              this.SidebarComponent.activecall("1")
              this.route.navigate(['admin-airline-dashboard'], {
                 
                  queryParams: {
                      DashBorad: 'switched'
                  },
                  queryParamsHandling: 'merge',
              });
          }
      } else {
          if (this.loginDetails.userType == 'SA') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/sa-dashboard');
          } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/csa-dashboard');
          }
          else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/csa-airline-dashboard');
          }
          else if (this.loginDetails.userType == 'E') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/user-dashboard');
          }
          else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/admin-handler-dashboard');
          }
          else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
              this.SidebarComponent.activecall("1")
              this.route.navigateByUrl('/admin-airline-dashboard');
          }
      }
  } else {
      if (this.loginDetails.userType == 'SA') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/sa-dashboard');
      } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/csa-dashboard');
      }
      else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/csa-airline-dashboard');
      }
      else if (this.loginDetails.userType == 'E') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/user-dashboard');
      }
      else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/admin-handler-dashboard');
      }
      else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
          this.SidebarComponent.activecall("1")
          this.route.navigateByUrl('/admin-airline-dashboard');
      }
  }

}, 200);


}
onClick_Truckinspection() {
  localStorage.setItem("TRUCK_INSPECTION_FROM",JSON.stringify("CREATE"));
  this.route.navigateByUrl('/Truckinspection/create-truck-inspection') ;
}

ngOnDestroy() {
  this.spinner.hide();
}

toggleSpinner(isEnable : boolean){
  if(!isEnable){
    if(this.selectedRowsData.length !== 0){
      //need for logic :: -- when we select records and apply filter / search then the list by default coming with selected checkboxes irrespective of selected records present or not.
      setTimeout(() => { //----- uncheck the checkboxes when the record is not present in selected list after filter applied
        let checkboxesRefArray = document.getElementsByClassName("jqx-widget jqx-checkbox");
      if(checkboxesRefArray.length !== 0){
       let selectedRows_copy = [];
      selectedRows_copy = this.selectedRowsData.filter(item=>{
        return item ;
      });
      if(selectedRows_copy.length !== 0){
        this.reportsDataGrid_Ref.myGrid.clearselection();
        for (let i = 0; i < checkboxesRefArray.length; i++) {
         let rowData =  this.reportsDataGrid_Ref.myGrid.getrowdata(i);
        for (let j = 0; j < selectedRows_copy.length; j++) {
          if(selectedRows_copy[j]['truck_inspection_id'] === rowData['truck_inspection_id']){
            this.reportsDataGrid_Ref.myGrid.selectrow(i)
          }
        }
        }
      }
      this.selectedRowsData = selectedRows_copy;
      }
      this.spinner.hide();
      }, 2000);
    }else{
      this.spinner.hide();
    }
  }else{
    this.spinner.show();
  }
}


 //----Need :: -- to push new records to selectedRowsData array when USER selects all records and 
 //                changes the page size / page num.
 onPageNum_Or_size_Changed(isChanged: boolean) { 
  if (isChanged) {
    if (this.isSlectALL_records === true) {
      this.selectedRowsData = [];
      this.selectedRowsData = this.reportsDataGrid_Ref.getTotalData();
      console.log("selected records :::-",this.selectedRowsData);
    }
  }
}
OnClickImportAcceptance(){
  // this.spinner.show();
    // setTimeout(() => {
        // localStorage.setItem("currentModule",JSON.stringify("IMPORT_FORWARDER"))
        this.route.navigateByUrl("/CargoAcceptance/view-import-acceptance");
    // }, 200);
}  

}
