import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, } from '@angular/forms';
import { organizationModal } from './Organization.modal'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { SuperadminService } from '../../services/superadmin/superadmin.service'
import { StationService } from '../../services/station/station.service';
import { ToastrService } from 'ngx-toastr';

import { SidebarComponent } from '../../layouts/sidebar/sidebar.component'

import { RequireMatch as RequireMatch } from './requireMatch';

import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    VERSION,
} from '@angular/material';


import { catchError } from 'rxjs/operators/catchError';
import { Subscription } from 'rxjs/Subscription';


import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Capability } from 'protractor';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform, } from 'ngx-image-cropper';
declare var jQuery: any;
@Component({
    selector: 'app-createorg',
    templateUrl: './createorg.component.html',
    styleUrls: ['./createorg.component.css']
})
export class CreateorgComponent implements OnInit, AfterViewInit, OnDestroy {
    // CreateOrgForm: FormGroup;
    createOrgForm: FormGroup;
    airlineNames = [];
    airlineData: any;
    airlineorg: any;
    filteredStates: Observable<any[]>;
    states: any = [];
    companyName: FormControl;
    stationsListObj: any;
    stationsList: Observable<any[]>;
    stationCode: FormControl;
    showAutocomplete: boolean;
    createFormStatus: string;
    responsedata: any;
    responsedatatemp: any;
    LoginDetails: any;
    carrierNames = [];
    stationDetailsObj: any;
    statesdetails: any;
    showairlinData: boolean;
    stationCodeTemp: any;
    OrgName: any;
    subscription: Subscription;
    // carrierNamesObj: any[];
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    isValidStationCode: boolean;
    stationError: boolean;
    selected_image: any =[];
    selectedIng_BAse64Str: string='';
    isPNG: boolean = false;
    validImage: boolean =true;
    isImageChanged: boolean;
    constructor(private SidebarComponent: SidebarComponent, private formBuilder: FormBuilder, private SuperadminService: SuperadminService, private router: Router, private StationService: StationService,
         private toastr: ToastrService, private spinner: NgxSpinnerService, private locationStr: LocationStrategy,) {
        this.companyName = new FormControl('', [Validators.required]);
        this.stationCode = new FormControl('', [Validators.required]);

        // this.filteredStates = this.companyName.valueChanges
        //     .pipe(
        //         startWith(''),
        //         map(state => state ? this.filterStates(state) : this.statesdetails)
        //     );


        // this.stationsList = this.stationCode.valueChanges
        //     .pipe(
        //         startWith(''),
        //         map(stateobj => stateobj ? this.filterStations(stateobj) : this.stationDetailsObj)
        //     );
         // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });

    }
    // createOrg = new organizationModal('','','','','','',null,'','');
    ngOnInit() {
        ;
        window.scroll(0, 0);
        //    this.companyName.value
        // let EMAILPATTERN = "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}"
        // let pinCodePattern = "^[a-zA-Z0-9]{0,8}$"
        let pinCodePattern = "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
        window.scroll(0, 0);
        this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.createOrgForm = this.formBuilder.group({
            companyName: new FormControl(this.companyName, [Validators.required]),
            orgType: new FormControl(null, [Validators.required]),
            firstName: new FormControl(null),
            lastName: new FormControl(null),
            emailId: new FormControl(null, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            contactNumber: new FormControl(null, [Validators.pattern('[1-9]\\d{9}')]),
            address: new FormControl(null, [Validators.required]),
            stationCode: new FormControl(this.stationCode, [Validators.required]),
            carrierId: new FormControl(null),
            address2: new FormControl(null),
            city: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required]),
            postalCode: new FormControl(null, [Validators.pattern(pinCodePattern)]),
            logo: new FormControl(null),
        });
        // Validators.pattern('[1-9]\\d{5}')
        this.createOrgForm.get('orgType').valueChanges.subscribe(value => {
            ;
            // this.createOrgForm.controls['companyName'].reset();
            this.companyName.reset();
            this.OrgName = undefined;
            console.log(value);
            console.log(this.createOrgForm);
            // alert(value)
            if (value === 'A') {
                this.spinner.show();
                this.showairlinData = true;
                this.SuperadminService.airlineData().subscribe(
                    data => {
                        this.spinner.hide();
                        if (data.responseCode === 1) {
                            ;
                            console.log('data: ', data);
                            // this.airlineData = data;
                            this.states = data;
                            this.statesdetails = data.airline_details;
                            // this.filteredStates = data.airline_details;

                            this.filteredStates = this.companyName.valueChanges
                                .pipe(
                                    startWith(''),
                                    map(state => state ? this.filterStates(state) : null)
                                );

                            // for (let i = 0; i < data.airline_details.length; i++) {
                            //     this.carrierNames.push(data.airline_details[i].carrierName);
                            // }
                            // console.log('carrierNames: ', this.carrierNames);
                            // this.carrierNamesObj = this.carrierNames
                            console.log(data)
                            // this.spinner.hide();
                        } else {
                            this.toastr.error(data.message);
                            this.spinner.hide();
                        }
                    }
                )
                // this.spinner.hide();
            } else if(value === 'H' ||value === 'E') {
                this.showairlinData = false;
                this.companyName.setValue('');
                this.filteredStates = this.companyName.value
            }else{

            }
        });
        this.StationService.GetStationCodes().subscribe(
            data => {
                ;
                this.stationsListObj = data.airport_details
                this.stationDetailsObj = this.stationsListObj;
                this.stationsList = this.stationCode.valueChanges
                    .pipe(
                        startWith(''),
                        map(stateobj => stateobj ? this.filterStations(stateobj) : null)
                    );
                // ;
                // console.log(data)
            },
            error => {
                this.spinner.hide();
            }
        )
        console.log('stationsListObj: ', this.stationsListObj);
        // console.log(this.stationsListObj)

        // this.createOrgForm.controls['companyName'].valueChanges.subscribe(value => {
        //     ;
        //     console.log(value);
        // });



    }
    country(){
        if(this.createOrgForm.value.country != ''&& this.createOrgForm.value.country != null){
            this.createOrgForm.controls['country'].patchValue(this.createOrgForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
      }
    city(){
        if(this.createOrgForm.value.city != ''&& this.createOrgForm.value.city != null){
            this.createOrgForm.controls['city'].patchValue(this.createOrgForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
       
    }
    orgName(){
        ;
       
            if(this.companyName.value != '' && this.companyName.value!= null){
                this.companyName.patchValue(this.companyName.value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
             
        }
       
    }

    fn_createOrgForm(event, formDirective: FormGroupDirective) {
        ;

        // if(this.validImage === false){
        //     this.toastr.error("Height and Width should meet requirements");
        //     return;
        // }
        if(this.createOrgForm.value.firstName != ''&& this.createOrgForm.value.firstName != null){
            this.createOrgForm.controls['firstName'].patchValue(this.createOrgForm.value.firstName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if(this.createOrgForm.value.lastName != ''&& this.createOrgForm.value.lastName != null){
            this.createOrgForm.controls['lastName'].patchValue(this.createOrgForm.value.lastName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if(this.createOrgForm.value.city != ''&& this.createOrgForm.value.city != null){
            this.createOrgForm.controls['city'].patchValue(this.createOrgForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if(this.createOrgForm.value.country != ''&& this.createOrgForm.value.country != null){
            this.createOrgForm.controls['country'].patchValue(this.createOrgForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if(this.createOrgForm.value.address != ''&& this.createOrgForm.value.address != null){
            this.createOrgForm.controls['address'].patchValue(this.createOrgForm.value.address.replace(/[^a-zA-Z0-9\!\#\%\&\'\-\/\@\.\s]*/g, ''));
        }
        if(this.companyName.value != '' && this.companyName.value!= null){
            this.companyName.patchValue(this.companyName.value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
         }
         if (this.createOrgForm.value.address2 != null && this.createOrgForm.value.address2.value != '' && this.createOrgForm.value.address2.value != null) {
            this.createOrgForm.value.address2.patchValue(this.createOrgForm.value.address2.value.replace(/[^a-zA-Z0-9\!\#\%\&\'\+\-\/\@\.\s]*/g, ''));
        }
     
        if (this.stationCodeTemp !== this.stationCode.value) {
            this.stationCodeTemp = undefined;
        }

        // this.createOrgForm.controls['companyName'].setValue(this.companyName.value)
     
        if (this.createOrgForm.controls['orgType'].value === 'A' && this.OrgName === undefined) {
            this.companyName.setErrors({ 'incorrect': true });
        } else if (this.createOrgForm.controls['orgType'].value === 'A' && this.stationCodeTemp === undefined) {
            this.stationCode.patchValue(null)
            //  this.stationError = true;
            this.stationCode.setErrors({ 'incorrect': true });
        }
        else {
            if (this.createOrgForm.controls['orgType'].value === 'H'||this.createOrgForm.controls['orgType'].value === 'E') {
                if (this.stationCodeTemp === undefined) {
                    this.stationCode.patchValue(null)
                    //  this.stationError = true;
                    this.stationCode.setErrors({ 'incorrect': true });
                } else {
                    this.createOrgForm.controls['companyName'].patchValue(this.companyName.value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
                    this.createOrgForm.controls['companyName'].setErrors(null);
                    this.createOrgForm.controls['stationCode'].patchValue(this.stationCodeTemp);
                    this.createOrgForm.value.orgLogo  = this.selectedIng_BAse64Str;
                    ;
                    this.spinner.show()
                    this.SuperadminService.createOrg(this.createOrgForm.value).pipe(finalize(() => {
                        ;
                    })).subscribe(data => {
                        ;
                       
                        this.responsedata = data;
                        if (data.responseCode === 1) {
                            localStorage.setItem('activation', JSON.stringify("1"));
                            this.responsedata = data;
                            this.spinner.hide()
                            this.toastr.success(data.message);
                            this.router.navigate(['/sa-dashboard']);
                        } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                            this.toastr.info("Your session is expired, please login again");
                            this.spinner.hide();
                        } 
                        else {
                            this.spinner.hide();
                            this.toastr.error(data.message);
                        }
                        this.toastr.success(data.message);
                        this.spinner.hide();
                    });
                }
            } else if (this.createOrgForm.controls['orgType'].value === 'A') {
                this.createOrgForm.controls['companyName'].patchValue(this.OrgName);
                this.createOrgForm.controls['companyName'].setErrors(null);
                this.createOrgForm.controls['stationCode'].patchValue(this.stationCodeTemp);
                this.createOrgForm.value.orgLogo  = this.selectedIng_BAse64Str;
                this.spinner.show()
                this.SuperadminService.createOrg(this.createOrgForm.value).pipe(finalize(() => {
                    ;
                    // this.responsedatatemp = this.responsedata;

                })).subscribe(data => {
                    ;
                   
                    this.responsedata = data;

                    if (data.responseCode === 1) {
                        localStorage.setItem('activation', JSON.stringify("1"));
                        this.responsedata = data;
                        this.spinner.hide()
                        this.toastr.success(data.message);
                        this.router.navigate(['/sa-dashboard']);
                    } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                        this.toastr.info("Your session is expired, please login again");
                        this.spinner.hide();
                    } 
                    else {
                        this.spinner.hide();
                        this.toastr.error(data.message);
                    }
                    this.toastr.success(data.message);
                    this.spinner.hide();
                });
            } else {
                return null
            }


        }

    }
    filterStates(carrierName: string) {
        ;
        if (this.createOrgForm.value.orgType === 'A') {
            //   this.OrgName = undefined;

            return this.states.airline_details.filter(state =>
                state.carrierName.toLowerCase().indexOf(carrierName.toLowerCase()) === 0);
        } else {
            this.createOrgForm.controls['companyName'].setValue(carrierName);
            // this.createOrgForm.controls['companyName'].setErrors(null);
        }
    }
    onEnter(state) {
        ;
        console.log(state)
        this.createOrgForm.controls['companyName'].setValue(state.carrierName);
        this.createOrgForm.controls['companyName'].setErrors(null);
        this.createOrgForm.controls['carrierId'].setValue(state.carrierId);
        console.log(this.createOrgForm);
        this.OrgName = state.carrierName;
    }


    filterStations(code: string) {
        ;

        // this.stationsListObj = JSON.parse(localStorage.getItem('stationsList'));
        console.log(this.stationsListObj);
        return this.stationsListObj.filter(state =>
            state.code.toLowerCase().indexOf(code.toLowerCase()) === 0);
    }
    // checkstation(event) {
    //     ;
    //     this.stationCodeTemp = undefined;
    // }


    onSelect(state) {
        ;
        console.log(state)
        this.createOrgForm.controls['stationCode'].setValue(state.code);
        this.createOrgForm.controls['stationCode'].setErrors(null);
        // this.stationCode.setValue(state.code);
        // this.stationCode.setErrors(null);
        console.log(this.createOrgForm);
        this.stationCodeTemp = state.code;
    }

    updatedVal(e) {
        ;
        if (e && e.length === 0) {
            this.showAutocomplete = true;
        } else {
            this.showAutocomplete = false;
        }
    }



    postalCode(event) {
        ;
        if (event.target.value.length > 8) {
            (event.target.value = event.target.value.slice(0, 9));
        } else if (event.target.value.length <= 8) {
            this.createOrgForm.controls['postalCode'].setValue(event.target.value.replace(/[^a-zA-Z0-9]*/g, ''));
            this.createOrgForm.controls['postalCode'].setErrors(null);
            this.createOrgForm.updateValueAndValidity();
        }
    }




    // --------------------

    ngAfterViewInit() {
        this._subscribeToClosingActions();
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    private _subscribeToClosingActions(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.trigger.panelClosingActions
            .subscribe(e => {
                if (!e || !e.source) {
                    if (this.createOrgForm.controls['orgType'].value === "A") {
                        this.companyName.setValue(null);
                        // this.toastr.error('Select Organization name From dropdown')
                        // this.errorMessageShow = true;
                        // console.log(this.createOrgForm.controls['organisationName'].value)
                    } else if (this.createOrgForm.controls['orgType'].value === "H" ||this.createOrgForm.controls['orgType'].value === 'E') {
                      ;
                        this.createOrgForm.controls['companyName'].patchValue(this.companyName.value);

                        this.createOrgForm.controls['companyName'].setErrors(null);
                    }
                }
            },
                err => this._subscribeToClosingActions(),
                () => this._subscribeToClosingActions());
    }


    checkStation(stationCode) {
        //  if (!this.selectedCity.cityName ||
        // this.selectedCity.cityName !== this.form.get('CityGroup').get('cityName').value) {
        //     this.form.get('CityGroup').get('cityName').setValue('');
        //     this.selectedCity = '';
        ;
        console.log(stationCode);
        if (stationCode.value !== this.stationCodeTemp) {
            ;
            console.log(this.stationsList)
            this.stationCode.setValue(null);
        }
    }

    //      displayWith(obj?: any): string | undefined {
    //          ;
    //     return obj ? obj : undefined;
    //   }

    navigateToDashboard() {
        this.SidebarComponent.activecall("1");
        this.router.navigateByUrl('/sa-dashboard');
    }


    loadFile (event) {
        this.selectedIng_BAse64Str ='';
        this.validImage = true ;
        // reader.readAsDataURL(event.target.files[0]);
        this.selected_image = event.target.files[0] ;
        let self = this;
        this.isPNG = event.target.files[0].name.toString().includes(".png");
        this.blobToBase64(event.target.files[0], function (base64String) {
            const base64 = base64String.split(",")[1];
            self.selectedIng_BAse64Str = base64;
            console.log("selectedIng_BAse64StrselectedIng_BAse64StrselectedIng_BAse64Str",self.selectedIng_BAse64Str);
          });
      };

      blobToBase64(blob, callback): void {
        const reader = new FileReader();
        reader.onload = () => {

             //Initiate the JavaScript Image object.
             var image: any = new Image();

             //Set the Base64 string return from FileReader as source.
             image.src = reader.result;
             var self = this;
             //Validate the File Height and Width.
             image.onload = function () {
                 var height = this.height;
                 var width = this.width;
                
                 var output:any = document.getElementById('output');
             output.src = reader.result;
            //  output.style.backgroundColor = (self.isPNG === true) ? "#2c365a" : '';
            const dataUrl = reader.result;
            callback(dataUrl);
            if (height !== 56 && width !== 85) {
                self.validImage = false ;
                 self.toastr.error("Height and Width should meet requirements");
                 return;
             }
             };
          
        };
        reader.readAsDataURL(blob);
      }

      on_Cancel_Image(){
          ;
        // this.selected_image = [];
        // var output:any = document.getElementById('inp_logo');
        // output.value ='';
        // this.validImage = true;
        // this.selectedIng_BAse64Str ='';

        this.selected_image = [];
        this.validImage = true;
        this.selectedIng_BAse64Str ="";
      }

      imageChangedEvent: any = '';
      croppedImage: any = '';
      canvasRotation = 0;
      rotation = 0;
      scale = 1;
      showCropper = false;
      containWithinAspectRatio = false;
      transform: ImageTransform = {};
  
      fileChangeEvent(event: any): void {
          this.imageChangedEvent = event;
          this.isImageChanged = true;
      }
  
      imageCropped(event: ImageCroppedEvent) {
          this.selected_image = ["selected"];
          this.croppedImage = event.base64;
          this.selectedIng_BAse64Str = event.base64.split(",")[1];
      }
  
      imageLoaded() {
          this.imageChangedEvent =  this.imageChangedEvent ;
          this.showCropper = true;
          jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
      }
  
      rotateLeft() {
          this.canvasRotation--;
          this.flipAfterRotate();
      }
  
      rotateRight() {
          this.canvasRotation++;
          this.flipAfterRotate();
      }
  
      private flipAfterRotate() {
          const flippedH = this.transform.flipH;
          const flippedV = this.transform.flipV;
          this.transform = {
              ...this.transform,
              flipH: flippedV,
              flipV: flippedH
          };
      }
  
      resetImage() {
          this.scale = 1;
          this.rotation = 0;
          this.canvasRotation = 0;
          this.transform = {};
      }
  
      zoomOut() {
          this.scale -= .1;
          this.transform = {
              ...this.transform,
              scale: this.scale
          };
      }
  
      zoomIn() {
          this.scale += .1;
          this.transform = {
              ...this.transform,
              scale: this.scale
          };
      }
  
  
     
}
