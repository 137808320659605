import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShipmentsService } from 'src/app/services/shipments/shipments.service';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { ConfigServiceService } from '../../config-service.service';
@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.css']
})
export class CreateShipmentComponent implements OnInit {
  createShipmentForm: FormGroup;
  orgDetails: any;
  isFirstTabCompleted:boolean = false;
  firstFormGroup:any ;
  secondFormGroup:any ;
  thirdFormGroup:any ;
  isLinear = true;
  checkedT_d: boolean;
  shipment_TotalData_ToUpdate: any;
  old_Images: any =[];
  deleted_Old_Images: any = []
  webapi 
  formSubmissionType: any;
  mawb_Num_SuffixModel: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private shipmentServc :ShipmentsService, 
     private spinner: NgxSpinnerService,
     private route: Router,
     private toastr: ToastrService,
     private ViewReportsData: ViewReportsData,
     private el: ElementRef,
     private config : ConfigServiceService
     ) {

      this.webapi =  this.config.getConfig().URL
      }

  ngOnInit() {
    this.createShipmentForm = this.createFormGroup(); // create form group
     this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject")) ;
     this.isFirstTabCompleted =false ;
     this.firstFormGroup; 
     this.secondFormGroup;
     this.formSubmissionType = this.ViewReportsData.shipmentIsFrom ;
     if(this.ViewReportsData.shipmentIsFrom === 'Edit' ){
       this.fn_ToGet_ShipmentData()
     }
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      boundType: new FormControl(""),
      mawb_Num_Prefix: new FormControl(""),
      mawb_Num_Suffix: new FormControl(""),
      referenceNumber: new FormControl("", [Validators.required]),
      shiperName: new FormControl("", [Validators.required]),
    })
  }

  prefixValidate(){
    this.createShipmentForm.get('mawb_Num_Prefix').value.replace(/[^0-9]*/g, '')
    if(this.createShipmentForm.get('mawb_Num_Prefix').value.length === 3){
        document.getElementById("mawb_Num_Suffix").focus()
    }
  }
  suffixValidate(){
    this.createShipmentForm.get('mawb_Num_Suffix').value.replace(/[^0-9]*/g, '')
    if(this.createShipmentForm.get('mawb_Num_Suffix').value.length === 9){
        document.getElementById("referenceNumber").focus()
    }
  }


  onValueChanges(fieldName : string,event ){
      let trimmed;
      this.isLinear = true;
      this.isFirstTabCompleted = false;
      if( this.createShipmentForm .valid){
        if((((this.createShipmentForm.get('mawb_Num_Prefix').value === '') && (this.createShipmentForm.get('mawb_Num_Suffix').value !== '')) 
        || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== '') && (this.createShipmentForm.get('mawb_Num_Suffix').value === '')))
        ||
         (((this.createShipmentForm.get('mawb_Num_Prefix').value === undefined) && (this.createShipmentForm.get('mawb_Num_Suffix').value !== undefined)) 
        || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== undefined) && (this.createShipmentForm.get('mawb_Num_Suffix').value === undefined)))
        || 
        (((this.createShipmentForm.get('mawb_Num_Prefix').value === null) && (this.createShipmentForm.get('mawb_Num_Suffix').value !== null)) 
        || ((this.createShipmentForm.get('mawb_Num_Prefix').value !== null) && (this.createShipmentForm.get('mawb_Num_Suffix').value === null))) ){
          this.isFirstTabCompleted = false;
          this.isLinear = true;
        }else{
          this.isFirstTabCompleted = true ;
          this.isLinear = false;
        }
      this._setColoursToEach_Stepper_Header("green",0) 
     
      }else{
        this.isFirstTabCompleted = false;
        this.isLinear = true;
      }
      if (fieldName === 'mawb_Num_Prefix') { trimmed = this.createShipmentForm.get("mawb_Num_Prefix").value.replace(/[^0-9]*/g, '');
      this.createShipmentForm.get(fieldName).setValue(trimmed);
      } else if (fieldName === 'mawb_Num_Suffix') {
        if (event !== undefined && event !== null) {
          this.mawb_Num_SuffixModel = event.target.value;
          let trimmed = this.mawb_Num_SuffixModel.replace(/\s+/g, '');
          if (trimmed.length > 16) {
            trimmed = trimmed.substr(0, 16);
          }
          const numbers = [];
          for (let i = 0; i < trimmed.length; i += 4) {
            numbers.push(trimmed.substr(i, 4));
          }
          this.mawb_Num_SuffixModel = numbers.join(' ');
        }
      } else if (fieldName === 'referenceNumber') {
         trimmed = this.createShipmentForm.get("referenceNumber").value.replace(/[^a-zA-Z0-9\-]*/g, '');
         this.createShipmentForm.get(fieldName).setValue(trimmed);
      }else if (fieldName === 'shiperName') { trimmed = this.createShipmentForm.get("shiperName").value.replace(/[^a-zA-Z\s\-]*/g, ''); 
      this.createShipmentForm.get(fieldName).setValue(trimmed);
    
    }
     
  }

 
  goForward(stepper, tab) : void {
    
    if (tab === 'firstTab') {
         if (this.createShipmentForm.valid) {
          if(this.isFirstTabCompleted === true ){
            stepper.next() ; this._setColoursToEach_Stepper_Header("green",0) ;
          }
           }else{
            for (const key of Object.keys(this.createShipmentForm.controls)) {
              if (this.createShipmentForm.controls[key].invalid) {
                const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                invalidControl.focus();
                break;
             }
        }
           };
    } else {
      stepper.next()
    }
  }


  onSubmitFormData(formData) : void {
let inputObj ={ 
  "boundType": formData.boundType, 
  "orgId":  this.orgDetails.orgId, 
  "shiperName": formData.shiperName, 
  "awbNumber":(formData.mawb_Num_Prefix+'-'+formData.mawb_Num_Suffix) !== '-' ?(formData.mawb_Num_Prefix+'-'+formData.mawb_Num_Suffix) : "",
  "referenceNumber": formData.referenceNumber, 
  "imagesList": []
  }
if(this._imagesArrayTotal.length !== 0){
  for (let i = 0; i < this._imagesArrayTotal.length; i++) {
   let obj = { 'imageURL':this._imagesArrayTotal[i] }
    inputObj.imagesList.push(obj) ;
  }
}else{
  // let obj = { 'imageURL':"" }
  // inputObj.imagesList.push(obj) ;
}
this.spinner.show() ;
this.shipmentServc.api_To_Create_Shipment(inputObj).subscribe(responseData =>{
  this.spinner.hide() ;
  if(responseData.responseCode === 1){
     this.toastr.success(responseData.message) ;
    this.route.navigateByUrl("/CargoImage/view-shipment") ;
  }else{
    this.toastr.error(responseData.message) ;
  }
},error=>{
this.spinner.hide() ;
}
)
}



//--------Images selection related------------//
public  _imagesArrayTotal=[];
  selectimages(val) {
    const self = this;
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(',')[1];
        self._imagesArrayTotal.push(base64);
        self.preview(self._imagesArrayTotal);
      })
    }
    console.log( self._imagesArrayTotal, 'this.imageslist');
  }


urls :any = [];
preview(files) {
  this.urls = [];
  if (files.length != 0) {
    for (let file of files) {
      var image = new Image();
      image.src = 'data:image/png;base64,' + file;
      this.urls.push(image);
    }
  }
  this._isImagesSelected()// to change color of stepper 
}


blobToBase64(blob, callback) : void {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    callback(dataUrl);
  };
  reader.readAsDataURL(blob);
};

on_Cancel_Image(i : number,type : string) : void {
  ;
  if(type ==='NEW'){
    this.urls.splice(i, 1);
    this._imagesArrayTotal.splice(i, 1);
  }else{
    this.old_Images[i].status = 0
    let img_split = [];
    img_split =  this.old_Images[i].image_path.split("/")
    this.old_Images[i].deleteImageName = img_split[(img_split.length - 1)];
    this.old_Images[i].imageURL = '' ;
    this.deleted_Old_Images.push(this.old_Images[i]) ;
    this.old_Images.splice(i, 1) ;
  }
  this._isImagesSelected(); // to change color of stepper 
}

_isImagesSelected() : void{
    if((this.urls.length !== 0) || (this.old_Images.length !== 0)){
      this._setColoursToEach_Stepper_Header("green",1)
    }else{
      this._setColoursToEach_Stepper_Header("rgb(242 242 242)",1) ;
    }
}

//--------End Images selection related------------//



_setColoursToEach_Stepper_Header(colour:string,setAtIndex:number) : void{
  const test = Array.from(document.getElementsByClassName('mat-step-icon'));
  test.forEach((element : any,index) => {
    if(index === setAtIndex){
      element.style.backgroundColor= colour ;
      element.style.color = "#0a0a0a";
    }
  });
}

onClickT_D(event) : void{
  if (event.target.checked) {
    this.checkedT_d = true;
  } else {
    this.checkedT_d = false;
  }
}



/// ------- Edit functionalities -------- ///
fn_ToGet_ShipmentData() : void {  // get data to view 
  if ((this.ViewReportsData.shipmentId !== null && this.ViewReportsData.shipmentId !== undefined) &&
    (this.ViewReportsData.organizationId !== null && this.ViewReportsData.organizationId !== undefined)) {
    let inputObj = {
      "orgId": this.ViewReportsData.organizationId,
      "shiperId": this.ViewReportsData.shipmentId
    }
    this.spinner.show();
    this.shipmentServc.api_To_GetShipment_Details(inputObj).subscribe(responseData => {
      this.spinner.hide();
      if (responseData.responseCode === 1) {
        this.isFirstTabCompleted = true ;
        this.isLinear = false ;
       this.shipment_TotalData_ToUpdate = responseData.shipmentDetails ;
       this.old_Images = responseData.shipmentDetails.shipmentImages ;
         if(this.old_Images === null || this.old_Images === undefined){
          this.old_Images = []
         }
        this._isImagesSelected();
       if(this.shipment_TotalData_ToUpdate.bond_type !== '' && this.shipment_TotalData_ToUpdate.bond_type !== null && this.shipment_TotalData_ToUpdate.bond_type !== undefined){ 
        this.createShipmentForm.get("boundType").setValue(this.shipment_TotalData_ToUpdate.bond_type  === "Inbound" ? "I" :"O") ;
       }
     if(this.shipment_TotalData_ToUpdate.awb_number !== '' && this.shipment_TotalData_ToUpdate.awb_number !== null && this.shipment_TotalData_ToUpdate.awb_number !== undefined){
       this.createShipmentForm.get("mawb_Num_Prefix").setValue(this.shipment_TotalData_ToUpdate.awb_number.split("-")[0]) ;
       this.createShipmentForm.get("mawb_Num_Suffix").setValue(this.shipment_TotalData_ToUpdate.awb_number.split("-")[1]) ;
     }
       this.createShipmentForm.get("referenceNumber").setValue(this.shipment_TotalData_ToUpdate.reference_number) ;
       this.createShipmentForm.get("shiperName").setValue(this.shipment_TotalData_ToUpdate.shipper_name) ;
     
       this._setColoursToEach_Stepper_Header("green",0) ;
      } else {
        this.toastr.error(responseData.message);
      }
    }, error => {
      this.spinner.hide();
    })
  } else {
    this.route.navigateByUrl("/CargoImage/view-shipment");
  }
}


onUpdateFormData(formData){
  ;
  let inputObj ={ 
    "shiperId":this.ViewReportsData.shipmentId,
    "boundType": formData.boundType, 
    "orgId":  this.orgDetails.orgId, 
    "shiperName": formData.shiperName, 
    "awbNumber":(formData.mawb_Num_Prefix+'-'+formData.mawb_Num_Suffix) !== '-' ?(formData.mawb_Num_Prefix+'-'+formData.mawb_Num_Suffix) : "",
    "referenceNumber": formData.referenceNumber, 
    "imagesList": []
    }
  if(this._imagesArrayTotal.length !== 0 ||   this.deleted_Old_Images.length !== 0){
    if(this._imagesArrayTotal.length !== 0){
      for (let i = 0; i < this._imagesArrayTotal.length; i++) {
        let obj = { 
         "id":null,   
         "status":1, 
         "deleteImageName":"",  
         'imageURL':this._imagesArrayTotal[i]
          }
         inputObj.imagesList.push(obj) ;
       }
    }
    if(this.deleted_Old_Images.length !== 0){
      for (let i = 0; i < this.deleted_Old_Images.length; i++) {
         inputObj.imagesList.push(this.deleted_Old_Images[i]) ;
       }
    }
  }else{
    let obj = { 
      "id":null,   
      "status":0, 
      "deleteImageName":"",  
      'imageURL':""
     }
    inputObj.imagesList.push(obj) ;
  }
  this.spinner.show() ;
this.shipmentServc.api_To_Update_Shipment(inputObj).subscribe(responseData =>{
  this.spinner.hide() ;
  if(responseData.responseCode === 1){
     this.toastr.success(responseData.message) ;
    this.route.navigateByUrl("/CargoImage/view-shipment") ;
  }else{
    this.toastr.error(responseData.message) ;
  }
},error=>{
this.spinner.hide() ;
}
)
}




onCancelPage() : void {
  this.route.navigateByUrl("/CargoImage/view-shipment") ;
}


}
