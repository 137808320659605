import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface LoginObj {
    // [id: string]: any;
    emailId: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    orgAuthStatus: string;
    orgId: number;
    orgPremiumUsr: string;
    orgStatus: string;
    organisationName: string;
    responseCode: number;
    stationCode: string;
    userAdminAuth: string;
    userId: number
    userPremiumUser: string;
    userStatus: string;
    userSuperAdminAuth: string;
    userType: string;
}


@Injectable({
    providedIn: 'root'
})
export class LoginDataService {
    private LoginData = new BehaviorSubject<{} | undefined>(undefined);
    LoginDataObject = this.LoginData.asObservable();

    constructor() { }

    emitLoginData(data) {
        ;
        this.LoginData.next(data)
        console.log(data);
    }
}
