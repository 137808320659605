import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TruckInspectionService } from 'src/app/services/truckInspection/truck-inspection.service';
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from 'src/app/config-service.service';
import * as moment from 'moment';
import 'moment-timezone';
import { DateAndTimeFunctions } from 'src/app/shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';


export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
  };

@Component({
  selector: 'app-create-truckinspection',
  templateUrl: './create-truckinspection.component.html',
  styleUrls: ['./create-truckinspection.component.css'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})

export class CreateTruckinspectionComponent implements OnInit {
  itemsArray: any = [];
  @ViewChild("stepper") private myStepper: MatStepper;
  truckInspectionForm: FormGroup;
  private _imagesArrayTotal: any = [];
  urls: any = [];
  old_Images: any = [];
  deleted_Old_Images: any = [];
  storageLocationImagesEmpty: boolean;
  storageLocationValue: string;

  isFirstTabCompleted: boolean = false;
  isSecondTabCompleted: boolean = false;
  isThirdTabCompleted: boolean = false;
  isFourthTabCompleted: boolean = false;
  LoginDetails: any;
  formSubmissionType: string = "CREATE";
  recordData: any;
  webapi: any;
  TRUCK_INSPECTION_ID: any;
  WARNING_MESSAGE = '';

  constructor(
    private route: Router,
    private toastr: ToastrService,
    private servc: TruckInspectionService,
    private spinner: NgxSpinnerService,
    private sharedVars: ViewReportsData,
    private location: LocationStrategy,
    private formBuilder: FormBuilder,
    private config: ConfigServiceService,
    private dateTimeFunc: DateAndTimeFunctions,
  ) {
    // preventing back button in browser
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.webapi = this.config.getConfig().ULD_Url;
  }

  ngOnInit() {
    // this.formSubmissionType = this.sharedVars.TRUCK_INSPECTION_FROM;
    this.truckInspectionForm = this.fn_CreateFormGroup(); // form group creation.
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
   this.TRUCK_INSPECTION_ID =JSON.parse(localStorage.getItem("TRUCK_INSPECTION_ID"))  ;
    this.formSubmissionType =JSON.parse(localStorage.getItem("TRUCK_INSPECTION_FROM"))  ;
    if ( this.formSubmissionType === 'Edit') {
      if (this.TRUCK_INSPECTION_ID === '' && !this.TRUCK_INSPECTION_ID) {
        this.route.navigateByUrl("/Truckinspection/view-truck-inspection");
        return true;
      } else {
        this.getRecordDetails(this.TRUCK_INSPECTION_ID);
      }
    }
  }

  getRecordDetails(TRUCK_INSPECTION_ID) {
    this.spinner.show();
    let inputObj = {
      "orgId": this.LoginDetails.orgId,
      "loginUserId": this.LoginDetails.userId,
      "inspectionId": TRUCK_INSPECTION_ID
    }
    this.servc.viewDetailedRecord(inputObj).subscribe(responseData => {
      this.spinner.hide();
      if (responseData['responseCode'] === 1) {
        this.recordData = [responseData['inspectionDetails']].filter(item => item)[0];
        // this.selectedMoment = new Date(DateAndTimeFunctions.convertUTCDateToLocalDate(responseData['inspectionDetails']['truck_info_date_time'])); //assign date
        // this.selectedMoment = moment(responseData['inspectionDetails']['truck_info_date_time']).format("YYYY-MM-DD HH:mm:ss")
        this.selectedMoment = new Date(responseData['inspectionDetails']['truck_info_date_time']);
        //--- assign cutoms form array values ------//
        if(this.recordData['truck_info_customs_mnr'] === null){
          this.recordData['truck_info_customs_mnr'] = '';
        }
        if(this.recordData['truck_info_seal_nr'] === null){
          this.recordData['truck_info_seal_nr'] = '';
        }
        let custommrnArray = this.recordData['truck_info_customs_mnr'].split(',');
        let customcussnArray = this.recordData['truck_info_seal_nr'].split(',');
        if (custommrnArray.length !== 0) {
          for (let i = 1; i < custommrnArray.length; i++) {
            this.onAddCustomsMrn();
          }
        }
        if (customcussnArray.length !== 0) {
          for (let i = 1; i < customcussnArray.length; i++) {
            this.onAddCustomSealNum();
          }
        }
        const controlArray = <FormArray>this.truckInspectionForm.get("truck_info_customs_mnr");
        const controlArray2 = <FormArray>this.truckInspectionForm.get("truck_info_seal_nr");
        for (let i = 0; i < custommrnArray.length; i++) {
          controlArray.controls[i].get("mrn").setValue(custommrnArray[i]);
        }
        for (let i = 0; i < customcussnArray.length; i++) {
          controlArray2.controls[i].get("cussn").setValue(customcussnArray[i]);
        }
        this.old_Images = responseData['inspectionDetails']['inspectionImages']; //assign existed images 
        delete this.recordData["truck_info_date_time"]; // deleting key to patch remaining values
        delete this.recordData["truck_info_customs_mnr"];
        delete this.recordData["truck_info_seal_nr"];
        this.truckInspectionForm.patchValue(this.recordData);
        this.changeSteppersColoursWhie_Edit();
      } else {
        this.toastr.error(responseData['message']);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error("something went wrong , please try later");
    })
  }

  changeSteppersColoursWhie_Edit(){
    if (this.isFirstStepValid()) {
      this.enableAndDisable_Tabs(true);
      this._setColoursToEach_Stepper_Header("green", 0);
    } else {
      this.enableAndDisable_Tabs(false);
      this._setColoursToEach_Stepper_Header("white", 0);
    }
    if (this.isSecondStep_AnyFieldFilled()) {
      if (this.isSecondStepValid()) {
        this.enableAndDisable_Tabs(true);
        this._setColoursToEach_Stepper_Header("green", 1);
      }
      else {
        this.enableAndDisable_Tabs(false);
        this._setColoursToEach_Stepper_Header("white", 1);
      }
    } else {
      this.enableAndDisable_Tabs(false);
      this._setColoursToEach_Stepper_Header("white", 1);
    }
    this.isAnyFieldFilled_InThirdTab();

  }
  public selectedMoment = new Date();
  fn_CreateFormGroup(): FormGroup {
    return new FormGroup({
      org_id: new FormControl(""),
      loginUserId: new FormControl(""),
      handler_org_id: new FormControl(""),
      forwarder_org_id: new FormControl(""),
      shipper_org_id: new FormControl(""),
      org_type: new FormControl(""),
      truck_info_date_time: new FormControl(new Date()),
      truck_info_bound: new FormControl(""),
      truck_info_customs_sealed: new FormControl(""),
      truck_info_customs_seal_nr: new FormControl(""),
      truck_info_customs_sealed_intact: new FormControl(""),
      truck_info_warning_field: new FormControl(""),
      t_cord_placed: new FormControl(""),
      truck_info_trucking_bonded_or_secured: new FormControl(""),
      truck_info_seal_nr: new FormArray([new FormGroup({ cussn: new FormControl('') })]),
      truck_info_vehicle_id: new FormControl("", [Validators.required]),
      truck_info_trailer_id: new FormControl(""),
      truck_info_trucking_company_name: new FormControl("", [Validators.required]),
      prior_inspec_engine_off: new FormControl("", [Validators.required]),
      prior_inspec_handbreak_on: new FormControl("", [Validators.required]),
      prior_inspec_driver_out_vehicle: new FormControl("", [Validators.required]),
      compliance_approved_a_front: new FormControl(""),
      compliance_approved_b_left: new FormControl(""),
      compliance_approved_c_right: new FormControl(""),
      compliance_approved_d_floor: new FormControl(""),
      compliance_approved_e_roof: new FormControl(""),
      compliance_approved_f_outside_doors: new FormControl(""),
      compliance_approved_g_underside: new FormControl(""),
      compliance_approved_a_front_desc: new FormControl(""),
      compliance_approved_b_left_desc: new FormControl(""),
      compliance_approved_c_right_desc: new FormControl(""),
      compliance_approved_d_floor_desc: new FormControl(""),
      compliance_approved_e_roof_desc: new FormControl(""),
      compliance_approved_f_outside_doors_desc: new FormControl(""),
      compliance_approved_g_underside_desc: new FormControl(""),
      compliance_approved_comment: new FormControl(""),
      truck_info_customs_mnr: new FormArray([new FormGroup({ mrn: new FormControl('') })]),
      images: new FormControl([]),
    });
  }



  //--- customs mrn multiple ---//
  get customsmrn() {
    return this.truckInspectionForm.get("truck_info_customs_mnr") as FormArray;
  }

  onAddCustomsMrn() {
    this.customsmrn.push(new FormGroup({ mrn: new FormControl('') }));
    //  this.customsmrn.value.map(e => e.mrn).join(",").replace(/,+/g,',');
  }
  get customsealnum() {
    return this.truckInspectionForm.get("truck_info_seal_nr") as FormArray;
  }
  onAddCustomSealNum(){
    this.customsealnum.push(new FormGroup({ cussn: new FormControl('') }));
  }

  onDeleteCustomsSealNum(index) {
    if (this.customsealnum.length > 1) {
      this.customsealnum.removeAt(index);
    }
  }


  onDeleteCustomsMrn(index) {
    if (this.customsmrn.length > 1) {
      this.customsmrn.removeAt(index);
    }
  }

  //------ Input value changes functions---//
  onChng_Comp_Selection(event, formControlName: string, PRESENT_STEP: string): void {
    
    if (this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N') {
      this.customsealnum.controls = [];
      this.customsealnum.push(new FormGroup({ cussn: new FormControl('') }));
    }
    
    if (!(this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N' && this.truckInspectionForm.controls.truck_info_trucking_bonded_or_secured.value === 'Y' && this.truckInspectionForm.controls.truck_info_bound.value === 'N' && (this.truckInspectionForm.controls.truck_info_customs_sealed_intact.value === 'N' || this.truckInspectionForm.controls.t_cord_placed.value === 'N' || this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N'))) {
      if (this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N' && this.truckInspectionForm.controls.truck_info_trucking_bonded_or_secured.value === 'Y') {
        this.truckInspectionForm.patchValue({
          truck_info_warning_field: "Potential customs breach"
        });
      } else if(this.truckInspectionForm.controls.truck_info_bound.value === 'N' && (this.truckInspectionForm.controls.truck_info_customs_sealed_intact.value === 'N' || this.truckInspectionForm.controls.t_cord_placed.value === 'N' || this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N')) {
        this.truckInspectionForm.patchValue({
          truck_info_warning_field: "Potential security breach"
        });
      } else {
        this.truckInspectionForm.patchValue({
          truck_info_warning_field: ""
        });
      }
    } else if (this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N' && this.truckInspectionForm.controls.truck_info_trucking_bonded_or_secured.value === 'Y' && this.truckInspectionForm.controls.truck_info_bound.value === 'N' && (this.truckInspectionForm.controls.truck_info_customs_sealed_intact.value === 'N' || this.truckInspectionForm.controls.t_cord_placed.value === 'N' || this.truckInspectionForm.controls.truck_info_customs_sealed.value === 'N')) {
      this.truckInspectionForm.patchValue({
        truck_info_warning_field: "Potential security and customs breach"
      });
    }

    // if(this.truckInspectionForm.controls.truck_info_trucking_bonded_or_secured.value === 'Y'){
    //   if(this.truckInspectionForm.controls.truck_info_bound.value !== 'N'){
    //     if(formControlName === "truck_info_customs_sealed" && event.value === "N"){
    //       // this.toastr.warning("warning , Potential customs breach");
    //       this.WARNING_MESSAGE = 'Potential customs breach'
    //     }
    //   }
    //   else if(this.truckInspectionForm.controls.truck_info_bound.value === 'N'
    //       && (formControlName === "truck_info_customs_sealed" && event.value === "N")){
    //       // this.toastr.warning("Warning , Potential security and customs breach");
    //       this.WARNING_MESSAGE = 'Potential security and customs breach';
    //   }
    //     else if(formControlName === "truck_info_customs_sealed_intact" && event.value === "N" || 
    //       formControlName === "truck_info_customs_sealed" && event.value === "N" || 
    //       formControlName === "t_cord_placed" && event.value === "N"){
    //     // this.toastr.warning("Warning , Potential security breach");
    //       this.WARNING_MESSAGE = 'Potential security breach';
    //   }
    // } 
    // if(this.truckInspectionForm.controls.truck_info_bound.value === 'N'){
    //     if(this.truckInspectionForm.controls.truck_info_trucking_bonded_or_secured.value === 'N' && (formControlName === "truck_info_customs_sealed_intact" && event.value === "N" || 
    //     formControlName === "truck_info_customs_sealed" && event.value === "N" || 
    //     formControlName === "t_cord_placed" && event.value === "N")){
    //     // this.toastr.warning("Warning , Potential security breach");
    //     this.WARNING_MESSAGE = 'Potential security breach';
    //   }
    // }
    
    
    
    
    // this.truckInspectionForm.get(formControlName).patchValue(event.value);
    // this.truckInspectionForm.get(formControlName + "_desc") && this.truckInspectionForm.get(formControlName + "_desc").patchValue("");
    // this.changeStepper_Access(PRESENT_STEP);
  }

  restrictSpace(event): void { //--- not using now 
    if (event.target.value.length === 0 && event.which === 32)
      event.preventDefault();
    const inputValue = event.charCode;
    if (!(inputValue >= 65 && inputValue <= 122) && inputValue !== 32 && inputValue !== 0)
      event.preventDefault();
  }

  //----- next and back button functions

  changeStepper_Access(PRESENT_STEP: string) {
    if (PRESENT_STEP === "FIRST" && this.isFirstStepValid()) {
      this.enableAndDisable_Tabs(true);
      this._setColoursToEach_Stepper_Header("green", 0);
    } else if (PRESENT_STEP === "FIRST") {
      this.enableAndDisable_Tabs(false);
    }

    if (PRESENT_STEP === "SECOND") {
      if (this.isSecondStep_AnyFieldFilled()) {
        if (this.isSecondStepValid()) {
          this.enableAndDisable_Tabs(true);
          this._setColoursToEach_Stepper_Header("green", 1);
        }else {
          this.enableAndDisable_Tabs(false);
        }
      } else {
        this.enableAndDisable_Tabs(false);
      }
    }

    if (PRESENT_STEP === "THIRD") {
      this.isAnyFieldFilled_InThirdTab();
    }
    this._isImagesSelected();
  }

  enableAndDisable_Tabs(arg: boolean) {
    this.isFirstTabCompleted = arg;
    this.isSecondTabCompleted = arg;
    this.isThirdTabCompleted = arg;
    this.isFourthTabCompleted = arg;
  }

  isFirstStepValid(): boolean {
    return (
      (this.truckInspectionForm.get('truck_info_date_time').value !== '' && this.truckInspectionForm.get('truck_info_date_time').value) &&
      (this.truckInspectionForm.get('truck_info_vehicle_id').value !== '' && this.truckInspectionForm.get('truck_info_vehicle_id').value) &&
      (this.truckInspectionForm.get('truck_info_trucking_company_name').value !== '' && this.truckInspectionForm.get('truck_info_trucking_company_name').value)
    )
  }


  isSecondStepValid(): boolean {
    return (
      (this.truckInspectionForm.get('prior_inspec_engine_off').value !== '' && this.truckInspectionForm.get('prior_inspec_engine_off').value) &&
      (this.truckInspectionForm.get('prior_inspec_handbreak_on').value !== '' && this.truckInspectionForm.get('prior_inspec_handbreak_on').value) &&
      (this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value !== '' && this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value)
    )
  }

  isSecondStep_AnyFieldFilled(): boolean {
    return (
      (this.truckInspectionForm.get('prior_inspec_engine_off').value !== '' && this.truckInspectionForm.get('prior_inspec_engine_off').value) ||
      (this.truckInspectionForm.get('prior_inspec_handbreak_on').value !== '' && this.truckInspectionForm.get('prior_inspec_handbreak_on').value) ||
      (this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value !== '' && this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value)
    )
  }


  isAnyFieldFilled_InThirdTab(): boolean {
    if (
      (this.truckInspectionForm.get('compliance_approved_a_front').value !== '') && (this.truckInspectionForm.get('compliance_approved_a_front').value) ||
      (this.truckInspectionForm.get('compliance_approved_b_left').value !== '') && (this.truckInspectionForm.get('compliance_approved_b_left').value) ||
      (this.truckInspectionForm.get('compliance_approved_c_right').value !== '') && (this.truckInspectionForm.get('compliance_approved_c_right').value) ||
      (this.truckInspectionForm.get('compliance_approved_d_floor').value !== '') && (this.truckInspectionForm.get('compliance_approved_d_floor').value) ||
      (this.truckInspectionForm.get('compliance_approved_e_roof').value !== '') && (this.truckInspectionForm.get('compliance_approved_e_roof').value) ||
      (this.truckInspectionForm.get('compliance_approved_f_outside_doors').value !== '') && (this.truckInspectionForm.get('compliance_approved_f_outside_doors').value) ||
      (this.truckInspectionForm.get('compliance_approved_g_underside').value !== '') && (this.truckInspectionForm.get('compliance_approved_g_underside').value) ||
      (this.truckInspectionForm.get('compliance_approved_a_front_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_a_front_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_b_left_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_b_left_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_c_right_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_c_right_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_d_floor_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_d_floor_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_e_roof_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_e_roof_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_f_outside_doors_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_f_outside_doors_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_g_underside_desc').value !== '') && (this.truckInspectionForm.get('compliance_approved_g_underside_desc').value) ||
      (this.truckInspectionForm.get('compliance_approved_comment').value !== '') && (this.truckInspectionForm.get('compliance_approved_comment').value)) {
      this._setColoursToEach_Stepper_Header("green", 2);
      return true;
    } else {
      this._setColoursToEach_Stepper_Header("white", 2);
      return false;
    }
  }




  validateSteeper_Fwd_And_Back_Navigations(PRESENT_STEP: string, NEXT_STEP: string, TYPE: string): void {
    // if (TYPE === "NEXT") {
      if (PRESENT_STEP === "FIRST") {//--step 1
        // if(this.truckInspectionForm.get('truck_info_trucking_bonded_or_secured').value === 'Y'){
        //   if(this.truckInspectionForm.get('truck_info_customs_sealed').value === 'N' ||
        //       this.truckInspectionForm.get('truck_info_customs_sealed_intact').value === 'N' ||
        //       this.truckInspectionForm.get('t_cord_placed').value === 'N' ){
        //         this.toastr.warning("warning , Potential security breach");
        //         return;
        //   }
        // }
        if (this.isFirstStepValid()) {
          TYPE === "NEXT" ? this.myStepper.next() :this.myStepper.previous();
          this._setColoursToEach_Stepper_Header("green", 0);
        } else {
          this.validate_Input_And_showToast('FIRST');
        }
      } else if (PRESENT_STEP === "SECOND") {     //--- step -2;
        if (this.isSecondStep_AnyFieldFilled()) {
          if (this.isSecondStepValid()) {
            TYPE === "NEXT" ? this.myStepper.next() :this.myStepper.previous();
            this._setColoursToEach_Stepper_Header("green", 1);
          } else {
            this.validate_Input_And_showToast('SECOND');
          }
        } else {
          TYPE === "NEXT" ? this.myStepper.next() :this.myStepper.previous();
          this._setColoursToEach_Stepper_Header("white", 1);
        }
      } else if (PRESENT_STEP === "THIRD") {  //--- step -3;
        TYPE === "NEXT" ? this.myStepper.next() :this.myStepper.previous();
        this.isAnyFieldFilled_InThirdTab();
  }else if(PRESENT_STEP === "FOURTH"){
    TYPE === "NEXT" ? this.myStepper.next() :this.myStepper.previous();
    this._isImagesSelected(); // to change color of stepper
  }
    // } else {
    //   this.myStepper.previous();
    // }
  }



  validate_Input_And_showToast(PRESENT_STEPPER: string) {
    if (PRESENT_STEPPER === 'FIRST') {
      if ((this.truckInspectionForm.get('truck_info_date_time').value === '') || (!this.truckInspectionForm.get('truck_info_date_time').value))
        this.toastr.error("Please enter date / time");
      else if ((this.truckInspectionForm.get('truck_info_vehicle_id').value === '') || (!this.truckInspectionForm.get('truck_info_vehicle_id').value))
        this.toastr.error("Please enter vehicle id");
      else if ((this.truckInspectionForm.get('truck_info_trucking_company_name').value === '') || (!this.truckInspectionForm.get('truck_info_trucking_company_name').value))
        this.toastr.error("Please enter trucking company");
    } else if (PRESENT_STEPPER === 'SECOND') {
      if ((this.truckInspectionForm.get('prior_inspec_engine_off').value === '') || (!this.truckInspectionForm.get('prior_inspec_engine_off').value))
        this.toastr.error("Please choose engine turned off");
      else if ((this.truckInspectionForm.get('prior_inspec_handbreak_on').value === '') || (!this.truckInspectionForm.get('prior_inspec_handbreak_on').value))
        this.toastr.error("Please choose hand break on");
      else if ((this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value === '') || (!this.truckInspectionForm.get('prior_inspec_driver_out_vehicle').value))
        this.toastr.error("Please choose driver out of vehicle");
    }
  }



  onCancelPage(): void {
    this.spinner.show();
    setTimeout(() => {
      this.route.navigateByUrl("/Truckinspection/view-truck-inspection");
    }, 200);
  
  }


  //---- image uploading functions-----------//
  selectimages(val) {
    const self = this;
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        const base64 = base64String.split(",")[1];
        self._imagesArrayTotal.push(base64);
        self.preview(self._imagesArrayTotal);
      });
    }
    console.log(this.urls, this._imagesArrayTotal);
  }

  preview(files) {
    this.urls = [];
    if (files.length !== 0) {
      for (const file of files) {
        const image = new Image();
        image.src = "data:image/png;base64," + file;
        this.urls.push(image);
      }
      // this.goForward1("fourthTab");
    }
    this._isImagesSelected(); // to change color of stepper
  }

  _isImagesSelected(): void {
    if ((this.urls.length !== 0) || (this.old_Images.length !== 0)) {
      this._setColoursToEach_Stepper_Header('green', 3);
    } else {
      this._setColoursToEach_Stepper_Header('rgb(242 242 242)', 3);
    }
  }


  blobToBase64(blob, callback): void {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }

  on_Cancel_Image(i: number, type: string): void {
    if (type === "NEW") {
      this.urls.splice(i, 1);
      this._imagesArrayTotal.splice(i, 1);
    } else {
      this.old_Images[i].status = 0;
      let img_split = [];
      img_split = this.old_Images[i].image_path.split("/");
      this.old_Images[i].deleteImageName = img_split[img_split.length - 1];
      this.old_Images[i].imageURL = "";
      this.old_Images[i].id = this.old_Images[i]['truck_inspection_images_id'];
      this.deleted_Old_Images.push(this.old_Images[i]);
      this.old_Images.splice(i, 1);
    }
    if (this.urls.length > 0 || this.old_Images.length > 0) {
      this._setColoursToEach_Stepper_Header("green", 3);
    } else {
      this._setColoursToEach_Stepper_Header("white", 3);
    }
    console.log((document.getElementById("img") as HTMLInputElement).value);
    (document.getElementById("img") as HTMLInputElement).value = "";
  }

  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number): void {
    const test = Array.from(document.getElementsByClassName("mat-step-icon"));
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  onSubmitForm(formData) {
    if (this.formSubmissionType === 'CREATE') {
      this.create(formData);
    } else {
      this.update(formData);
    }
  }

  update(formData) {
    this.spinner.show();
    try {
      formData.inspectionId = this.TRUCK_INSPECTION_ID;
      // formData.truck_info_date_time = this.dateTimeFunc.convertToIso(formData.truck_info_date_time);
      formData.truck_info_date_time = moment(formData.truck_info_date_time).format("YYYY-MM-DD HH:mm:ss")
      formData.shipper_org_id = "";
      formData.truck_info_customs_mnr = this.customsmrn.value.map(e => e.mrn).join(",").replace(/,+/g, ',');
      formData.truck_info_seal_nr = this.customsealnum.value.map(e => e.cussn).join(",").replace(/,+/g, ',');
      formData.images = [];
      formData.stationCode = this.LoginDetails.stationCode;
      if(this.recordData.inspection_status === null){
        this.recordData.inspection_status = 'Pending';
        }
      formData.inspection_status = this.recordData.inspection_status;
      formData.start_date = this.recordData.created_date_time
      formData.completed_date = this.recordData.completed_date;
      // formData.createdOn = this.dateTimeFunc.convertToIso(new Date());
      formData.createdOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) { //new image
          formData.images.push({
            "id": "",
            "status": 1,
            "deleteImageName": "",
            "imageURL": this._imagesArrayTotal[i]
          })
        }
      }

      if (this.old_Images.length !== 0) {
        for (let i = 0; i < this.old_Images.length; i++) {  // already existed
          let img = {
            "id": this.old_Images[i]['truck_inspection_images_id'],
            "status": 0,
            "deleteImageName": "",
            "imageURL": ""
          }
          formData.images.push(img);
        }
      }

      if (this.deleted_Old_Images.length !== 0) {
        for (let i = 0; i < this.deleted_Old_Images.length; i++) { // deleted images
          formData.images.push(this.deleted_Old_Images[i]);
        }
      }
      ;

      // formData.updatedOn = this.dateTimeFunc.convertToIso(new Date());
      formData.updatedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      this.servc.create_Update_TruckInspec_Record(formData,'inspectionApis/editInspection.php/editInspection').subscribe(responseData=>{
      if(responseData['responseCode'] === 1){
        this.spinner.hide();
        this.toastr.success("Record updated successfully");
        this.onCancelPage();
      }else{
        this.toastr.error(responseData['message']);
        this.spinner.hide();
      }
      },error=>{
        this.toastr.error("Something went wrong , please try later");
        this.spinner.hide();
      })
    } catch (exce) {
      this.spinner.hide();
    }
  }
  create(formData) {
    this.spinner.show();
    try {
      // formData.truck_info_date_time = this.dateTimeFunc.convertToIso(formData.truck_info_date_time);
      // formData.truck_info_date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      formData.truck_info_date_time = moment(formData.truck_info_date_time).format("YYYY-MM-DD HH:mm:ss")
      formData.org_id = this.LoginDetails.orgId;
      formData.loginUserId = this.LoginDetails.userId;
      formData.handler_org_id = this.LoginDetails.orgType === 'H' ? this.LoginDetails.orgId :null ;
      formData.forwarder_org_id = this.LoginDetails.orgType === 'E' ? this.LoginDetails.orgId :null ;
      formData.shipper_org_id = "";
      formData.org_type = this.LoginDetails.orgType;
      formData.truck_info_customs_mnr = this.customsmrn.value.map(e => e.mrn).join(",").replace(/,+/g, ',');
      formData.truck_info_seal_nr = this.customsealnum.value.map(e => e.cussn).join(",").replace(/,+/g, ',');
      formData.images = [];
      // if(columnData.inspection_status === null){
      //   columnData.inspection_status = 'Pending';
      // }
      formData.inspection_status = 'Pending';
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) {
          formData.images.push({ "imageURL": this._imagesArrayTotal[i] })
        }
      }
      formData.stationCode = this.LoginDetails.stationCode;
      // formData.createdOn = this.dateTimeFunc.convertToIso(new Date());
      formData.createdOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      formData.start_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      this.servc.create_Update_TruckInspec_Record(formData, 'inspectionApis/createInspection.php/createInspection').subscribe(responseData => {
        if (responseData['responseCode'] === 1) {
          this.spinner.hide();
          this.toastr.success("Successfully created");
          this.onCancelPage();
        } else {
          this.toastr.error(responseData['message']);
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error("Something went wrong , please try later");
        this.spinner.hide();
      })
    } catch (exce) {
      this.spinner.hide();
    }
  }


  convertDate(date: Date) {
    return date.getFullYear() + "/" + ((date.getMonth() + 1).toString().length === 1 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "/" + ((date.getDate()).toString().length === 1 ? ("0" + (date.getDate())) : (date.getDate())) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  ngOnDestroy(){
    this.spinner.hide();
  }

  // convertToIso(date: Date){
  //   let dt  = date.toISOString();
  //   return   dt.substring(0,10)+" "+ dt.substring(11,19);
  // }

  // convertUTCDateToLocalDate(date1){
  //   let dt = this.utcToLocal(date1, moment.tz.guess());
  //   return  dt === "Invalid Timezone Operator" ? date1 : dt;
  //   }
  
//     utcToLocal(utcdateTime, tz) {
//      var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
//      var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
//      var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
//      var localDateTime
//      var hours = zoneValue.split(":")[0]
//      var minutes = zoneValue.split(":")[1]
//      if (operator === "-") {
//          localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
//      } else if (operator) {
//          localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
//      } else {
//          localDateTime = "Invalid Timezone Operator"
//      }
//      return localDateTime
//  }
}
