import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';
@Injectable({
  providedIn: 'root'
})
export class SwitchdashboardService {
  webAPI:string ; 
  constructor(private http:HttpClient ,config : ConfigServiceService) {
   this.webAPI = config.getConfig().URL
   }

    switchDashBorad(dashBoardObj){
        return this.http.get<any>(this.webAPI + "getLoginCclpPortal.php/getSwithAccount?"+ 'emailId=' + dashBoardObj.emailId+ '&OrgId=' +dashBoardObj.OrgId + '&OrgType='+dashBoardObj.OrgType)
    }

}
