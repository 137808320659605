import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShipmentsService } from 'src/app/services/shipments/shipments.service';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { ConfigServiceService } from '../../config-service.service';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import * as moment from 'moment';
import { DateAndTimeFunctions } from '../../shared/sharedModule/sharedFunctions/DateAndTimeConvertions';

import 'moment-timezone';
@Component({
  selector: 'app-view-cargoacceptance-details',
  templateUrl: './view-cargoacceptance-details.component.html',
  styleUrls: ['./view-cargoacceptance-details.component.css']
})
export class ViewCargoacceptanceDetailsComponent implements OnInit {

  orgDetails: any;
  shipment_COmpleteData:any ;
  modifiedReports = [];
  selectedId: any;
  old_Images: any = [];
  rows = [];
  webapi ;
  concatObject = {width: '', length: '', height: '', weight: '',pieces: ''};
  rowSelected: boolean;
  constructor(

    private shipmentServc: ShipmentsService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private toastr: ToastrService,
    private ViewReportsData: ViewReportsData,
    private config : ConfigServiceService,
    private sanitizer: DomSanitizer
  ) { 
    this.webapi =  this.config.getConfig().ULD_Url
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
}
  ngOnInit() {
    
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.fn_ToGet_ShipmentData();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }


  fn_ToGet_ShipmentData() {
   let CARGO_Acceptance_STORAGE = JSON.parse(localStorage.getItem("CARGO_Acceptance_STORAGE"));
    // if ((this.ViewReportsData.shipmentId !== null && this.ViewReportsData.shipmentId !== undefined) &&
    //   (this.ViewReportsData.organizationId !== null && this.ViewReportsData.organizationId !== undefined)) {
      let inputObj = {
        "loginUserId":this.orgDetails.userId, 
        "orgId": this.orgDetails.orgId, 
        "cargoId": CARGO_Acceptance_STORAGE.shipmentId 
      }
      this.spinner.show();
      this.shipmentServc.api_To_Cargoacceptance_Details(inputObj).subscribe(responseData => {
        this.spinner.hide();
        if (responseData.responseCode === 1 && responseData.message !=="No data found") {
         this.shipment_COmpleteData = responseData.cargoDetails ;
         this.modifiedReports = responseData.modifiedReports;
         this.old_Images = responseData.cargoDetails.cargoImages ;
         this.rows = responseData.cargoDetails.pieces_weight_array;
         this.rows.map(data => {
           if (data.pieces && this.concatObject.pieces) {
             this.concatObject.pieces = this.concatObject.pieces.concat(', ', data.pieces);
           } else {
            this.concatObject.pieces = this.concatObject.pieces.concat(' ', data.pieces);
           }
           if (data.length && this.concatObject.length) {
             this.concatObject.length = this.concatObject.length.concat(', ', data.length);
           } else {
            this.concatObject.length = this.concatObject.length.concat(' ', data.length);
           }
           if (data.width && this.concatObject.width) {
             this.concatObject.width = this.concatObject.width.concat(', ', data.width);
           } else {
            this.concatObject.width = this.concatObject.width.concat(' ', data.width);
           }
           if (data.height && this.concatObject.height) {
             this.concatObject.height = this.concatObject.height.concat(', ', data.height);
           } else {
            this.concatObject.height = this.concatObject.height.concat(' ', data.height);
           }
           if (data.weight && this.concatObject.weight) {
             this.concatObject.weight = this.concatObject.weight.concat(', ', data.weight);
           } else {
            this.concatObject.weight = this.concatObject.weight.concat(' ', data.weight);
           }
         })

         if(this.old_Images === null){
          this.old_Images = [];
         }
        } else {
          this.old_Images = [];
          this.toastr.success(responseData.message);
        }
      }, error => {
        this.spinner.hide();
      }
      )
    // } else {
    //   this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance");
    // }
  }

  fn_To_Extract_Obj_Keys(jsonObject :JSON):Array<string>{  //---> To get object keys
    let obj_Keys = Object.keys(jsonObject);
    return obj_Keys ;
  }
  
  ViewSelectedData(SelectedRowData,index) {
    ;
      this.rowSelected = true;
     (SelectedRowData.id !== null && SelectedRowData.id !== undefined && SelectedRowData.id !== '') ? ( this.selectedId = SelectedRowData.id, this.get_Detailed_ModifiedReport_View( this.selectedId) ): (this.selectedId  = SelectedRowData.id ,this.fn_ToGet_ShipmentData()) ;
    // this.selectedId = SelectedRowData.id;
    // this. get_Detailed_ModifiedReport_View(this.selectedId)
  }

  get_Detailed_ModifiedReport_View(selectedId){
    this.spinner.show();
    this.shipmentServc.api_To_ModifiedReport_View({"id": selectedId}).subscribe(responseData=>{
        this.spinner.hide();
        if(responseData.responseCode==1){
          this.shipment_COmpleteData = responseData.modifiedCargoDetails;
    
          this.old_Images = responseData.modifiedCargoDetails.modifiedCargoImages ;
          this.rows = responseData.modifiedCargoDetails.pieces_weight_array;
          this.rows.map(data => {
            if (data.pieces && this.concatObject.pieces) {
              this.concatObject.pieces = this.concatObject.pieces.concat(', ', data.pieces);
            } else {
             this.concatObject.pieces = this.concatObject.pieces.concat(' ', data.pieces);
            }
            if (data.length && this.concatObject.length) {
              this.concatObject.length = this.concatObject.length.concat(', ', data.length);
            } else {
             this.concatObject.length = this.concatObject.length.concat(' ', data.length);
            }
            if (data.width && this.concatObject.width) {
              this.concatObject.width = this.concatObject.width.concat(', ', data.width);
            } else {
             this.concatObject.width = this.concatObject.width.concat(' ', data.width);
            }
            if (data.height && this.concatObject.height) {
              this.concatObject.height = this.concatObject.height.concat(', ', data.height);
            } else {
             this.concatObject.height = this.concatObject.height.concat(' ', data.height);
            }
            if (data.weight && this.concatObject.weight) {
              this.concatObject.weight = this.concatObject.weight.concat(', ', data.weight);
            } else {
             this.concatObject.weight = this.concatObject.weight.concat(' ', data.weight);
            }
          })
 
          if(this.old_Images === null){
           this.old_Images = [];
          }
        }
        else{
            
        }
    },error=>{
      this.spinner.hide();
    })
  }
  
  navigateToShipments() {
    this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance");
  }

  //  convertDate(date) {
  //   this.convertUTCDateToLocalDate(new Date(date));
  //  }

  //  convertUTCDateToLocalDate(date1){
  //  let dt = this.utcToLocal(date1, moment.tz.guess());
  //  return  dt === "Invalid Timezone Operator" ? date1 : dt;
  //  }
  convertUTCDateToLocalDate(date){
    return  DateAndTimeFunctions.convertUTCDateToLocalDate(date);
   }
 
   utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
    var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
    var localDateTime;
    var hours = zoneValue.split(":")[0];
    var minutes = zoneValue.split(":")[1];
    if (operator === "-") {
        localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else if (operator) {
        localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else {
        localDateTime = "Invalid Timezone Operator"
    }
    return localDateTime
}


}
