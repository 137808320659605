import { Component, OnInit } from '@angular/core';
// import { MyAccountService } from '../../../services/myAccount/my-account.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarCargoClaimComponent } from '../../../cargoClaimModule/layouts/navbar/navbarCargoClaim.component';
import { Location } from '@angular/common';
import { LoginServicesService } from '../../services/login/login-services.service';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    providers:[NavbarCargoClaimComponent]
})
export class ResetPasswordCargoClaimComponent implements OnInit {
    // resetForm: FormGroup;
    // hide: boolean;
    // changePassword: any;
    resetpassobj: any;
    // submitted: boolean;
    // loginresultobject: any;

    // constructor(private location: Location,private navbarComponent:NavbarCargoClaimComponent,private MyAccountService: MyAccountService, private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router) { }

    // ngOnInit() {


    //     //--validations--//

    //     this.resetForm = new FormGroup({
    //         changePassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),

    //     });
    //     //--validations--//


    // }




    // gotoresetpassword() {
    //     ;
    //     this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
    //     this.resetpassobj = JSON.parse(localStorage.getItem('resetpassobj'));
    //     this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));


    //     const obj = {
    //         "changeType": "C",
    //         "userId": this.resetpassobj.userId,
    //         "changePassword": this.changePassword,
    //     }
    //     this.spinner.show();
    //     this.MyAccountService.resetpassword(obj).subscribe(
    //         data => {
    //             ;
    //             if (data.responseCode === 1) {
    //                 this.toastr.success(data.message);
    //                 if( this.loginresultobject.userType === 'S' &&  this.resetpassobj.userType ==='S'){
    //                      this.navbarComponent.Logout();
    //                 }else{
    //                     // this.router.navigateByUrl('/viewusers')
    //                     this.location.back()
                      
    //                 }
                   
    //             } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
    //                 this.spinner.hide();
    //                 this.toastr.info("Your session is expired, please login again");
    //             } 
    //             else {
    //                 this.spinner.hide();
    //                 this.toastr.error(data.message);
    //             }
    //         }, error => {
    //             this.spinner.hide();
    //             console.log(error);
    //             this.toastr.error("Something went wrong");
    //         });
    // }


    // generatepass(length) {
    //     ;
    //     var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    //     var pass = "";
    //     for (var x = 0; x < length; x++) {
    //         var i = Math.floor(Math.random() * chars.length);
    //         pass += chars.charAt(i);
    //     }
    //     this.changePassword = pass;

    // }


    // gotoviewuser() {
    //     ;
    //     this.router.navigateByUrl('/viewusers');
    // }
    // navigateCancel(){
    //     this.location.back()
    // }


    gotoviewuser() {
        ;
        this.router.navigateByUrl('/viewusers');
    }
    navigateCancel(){
        this.location.back()
    }
    
    
  emailId: string;
  passwordForm: FormGroup;
  Logindetails: any;
  orgId: any;
  constructor(
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private loginservice: LoginServicesService
  ) {}
  ngOnInit() {
    this.resetpassobj = JSON.parse(localStorage.getItem('resetpassobj'));
    //password validation
    window.scroll(0, 0);
    this.passwordForm = new FormGroup({
      emailId: new FormControl(this.resetpassobj.loginID, [Validators.required, Validators.email]),
    });
  }
  // forgotpassword Api integration
  sendEmail(forgotObj) {
    this.spinner.show();
    this.loginservice.forgotPassword(forgotObj).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.resposeCode === 1) {
          jQuery("#forgotpasswordpopup").modal("hide");
          jQuery("body").removeClass("modal-open");
          jQuery(".modal-backdrop").remove();
          this.toastr.success(data.message);
          this.router.navigate(["/"]);
        } else {
          this.toastr.error(data.message);
        }
      },
      (error) => {
        alert("Unable to verify email id ");
      }
    );
  }

}   
   