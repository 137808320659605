import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateTruckinspectionComponent } from './create-truckinspection/create-truckinspection.component';
import { ViewTruckinspectionComponent } from './view-truckinspection/view-truckinspection.component';
import { ViewTruckinspectionDetailsComponent } from './view-truckinspection-details/view-truckinspection-details.component';
import { globalConstants } from 'src/environments/globalConstants';
import { AuthenticateGuard } from '../AuthGuard/authenticate.guard';

const routes: Routes = [
  {path:'create-truck-inspection',component:CreateTruckinspectionComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT] }},
  {path:'view-truck-inspection', component:ViewTruckinspectionComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT] }},
  {path:'view-truck-inspection-details',component:ViewTruckinspectionDetailsComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TruckInspectionRoutingModule { }
