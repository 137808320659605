import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ConfigServiceService } from "src/app/config-service.service";
// const webapi = environment.URL;
@Injectable({
  providedIn: "root",
})
export class StationService {
  webapi:any;
  constructor(private http: HttpClient,config : ConfigServiceService) {

    this.webapi  =    config.getConfig().CARGO_CLAIM_URL
  }
  AddStations(obj) {
    return this.http.post<any>(
      this.webapi + "cargoClaim/airlineStations.php/createStation",
      obj
    );
  }
  ViewStations(orgId) {
    return this.http.get<any>(
      this.webapi + "cargoClaim/airlineStations.php/getStations?orgId=" + orgId
    );
  }
  EditStations(obj) {
    return this.http.post<any>(
      this.webapi + "cargoClaim/airlineStations.php/editStations",
      obj
    );
  }
  GetStationCodes() {
    return this.http.post<any>(this.webapi + "tracking.php/getTruckAirport", {});
  }
  deleteIndividual(inputData) {
    return this.http.post<any>(
      this.webapi + "cargoClaim/airlineStations.php/deleteEmail",
      inputData
    );
  }
  getDeatil(inputObj) {
    return this.http.post<any>(
      this.webapi + "cargoClaim/airlineStations.php/getDeatils",
      inputObj
    );
  }
  deletestation(data) {
    return this.http.post<any>(
      this.webapi + "cargoClaim/airlineStations.php/deleteBranch",
      data
    );
  }
}
