import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordChecker } from './custom-validators/password-checker';
import { LoginService } from '../services/login/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

export interface DialogData {
  id: string
}
@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.css"],
})
export class SetPasswordComponent implements OnInit {
  id: any;
  response: any;
  data: any;
  email = "";
  warning = "";
  resetResponse: any;
  showContent = false;

  regex =
    /(?=.*[_!@#$%^&*-+=~`()<>?])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])^.{12,}$/;

  constructor(
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    public spinner: NgxSpinnerService,
    private myAccService: MyAccountService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
    route.params.subscribe((data) => {
      this.id = data.id;
      console.log(this.id);
      
    });
  }

  ngOnInit() {
    // this.openDialog();
    const dialogRef = this.dialog.open(DialogOverviewSetPasswordDialog, {
      width: '250px',
      data: {id: this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.id = result;
      console.log(this.id);
      this.regForm = this.formBuilder;
      this.initForm();
      let obj = {
        key: this.id,
      };
      this.spinner.show();
      this.loginService.getUserDetailsForSetPassword(obj).subscribe(
        (resp) => {
          this.spinner.hide();
          console.log(resp);
          this.response = resp;
          if (this.response.responseCode === 1) {
            this.showContent = true;
            this.email = this.response.email.replace(
              /(\w{3})[\w.-]+@([\w.]+\w)/,
              "$1*****@$2"
            );
            this.regForm.patchValue({
              key: this.id,
              value: this.response.otp,
            });
          } else {
  
          this.spinner.hide();
            // this.toastr.error(this.response.message);
            this.router.navigateByUrl('/invalid')
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message);
        }
      );
    });
  }

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(DialogOverviewSetPasswordDialog, {
  //     width: '250px',
  //     data: {id: this.id}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     this.id = result;
  //   });
  // }

  hide1 = true;
  hide2 = true;
  regForm: any;

  private initForm() {
    this.regForm = this.formBuilder.group(
      {
        value: ["hjk", Validators.required],
        key: ["m,", Validators.required],
        new_password: [
          "",
          [Validators.required, Validators.pattern(this.regex)],
        ],
        confirm_password: [
          "",
          [Validators.required, Validators.pattern(this.regex)],
        ],
      },
      {
        validators: PasswordChecker("new_password", "confirm_password"),
      }
    );
  }

  keyupValidation(event, input) {
    if (input === 'pass') {
    // Create an array and push all possible values that you want in password
    let password = event.target.value;

    // Check for mixed case
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    } else {
      this.warning = "Use both lowercase and uppercase letters.";
      return;
    }

    // Check for numbers
    if (password.match(/\d/)) {
    } else {
      this.warning = "Include at least one number. ";
      return;
    }

    // check spaces
    if (password.indexOf(" ") >= 0) {
      this.warning = "Space is not allowed. ";
      return;
    }

    // Check for special characters
    let special = /([~,!,@,#,$,%,^,&,*,(,),_,+,=,?,>,<])/;
    if (password.match(special) || password == "-") {
    } else {
      this.warning = "Include at least one special character. ";
      return;
    }

    // Check password length
    if (password.length < 12) {
      this.warning = "password length minimum 12 characters.";
      return;
    } else if (password.length > 18) {
      this.warning = "Password length should not greater than 18 characters.";
      return;
    }
    console.log(this.warning);
    
    this.warning = '';
  } else {
    if (event.target.value !== this.regForm.value.new_password) {
      this.warning = "Password does not matched";
      return;
    }
    this.warning = '';
  }
  }

  onSubmit() {
    let flag = true;
    if (this.regForm.value.new_password.indexOf(`'`) != -1) {
      flag = false;
    }
    if (this.regForm.value.new_password.indexOf(`"`) != -1) {
      flag = false;
    }
    if (!flag) {
      this.toastr.error(`Quotes( ', " ) not allowed`);
    }
    if (!this.regForm.valid) {
      return;
    }
    this.spinner.show();
    this.myAccService.changePassword(this.regForm.value).subscribe(
      (resp) => {
        this.spinner.hide();
        this.resetResponse = resp;
        if (this.resetResponse.responseCode === 1) {
          this.toastr.success(this.resetResponse.message);
          this.router.navigateByUrl("/#/Home");
        } else {
          this.toastr.error(this.resetResponse.message);
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    );
  }

  onClickCancel() {
    this.router.navigateByUrl("/#/Home");
  }
}


@Component({
  selector: 'dialog-overview-set-password-dialog',
  templateUrl: 'dialog-overview-set-password-dialog.html',
})
export class DialogOverviewSetPasswordDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewSetPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      console.log(data);
    }

  onOkClick(): void {
    this.dialogRef.close('');
    window.open(`cargohubcclpapp://setPassword/${this.data.id}`)
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.id);
  }

}