import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigServiceService } from 'src/app/config-service.service';
import { UldModuleService } from 'src/app/services/uldmoduleServices/uld-module.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';

@Component({
    selector: 'app-detailed-uld',
    templateUrl: './detailed-uld.component.html',
    styleUrls: ['./detailed-uld.component.css']
})
export class DetailedUldComponent implements OnInit {
    selectedreports:any ;
    uldDamageReport:any;
    statusOfTheReport:any;
    sentDate:any;
    emailStatus:any;
    Images:any;
    modifiedReports=[];
    uldDamageReportImages=[];
    damageImage:any;
    ReportsProductionUrl :any ;
  loginDetails: any;
  EmailData: any;
  externalEmailData: any;
  selectedReportId: number;
    constructor(  public route: Router,private uldServices :UldModuleService,
       private config : ConfigServiceService,
         private spinner: NgxSpinnerService,private sanitizer: DomSanitizer,private location: Location,
         private ViewReportsData: ViewReportsData,) {

          this.ReportsProductionUrl = this.config.getConfig().URL
          }

    ngOnInit() {
       
       let reportId = JSON.parse(localStorage.getItem("uldReportId_To_View")) ;
       this.selectedreports = JSON.parse(localStorage.getItem("uldReportFrom")) ;
       if(this.selectedreports === 'NotIncoming'){
        this.fn_ToGet_ViewDetailedData(reportId)
       }else {
        this.fn_ToGet_IncomingViewDetailedData(reportId)
       }
      
    }
    
    sanitizeImageUrl(imageUrl: string): SafeUrl {
      return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

    navigateToReports(){
      this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
      if(this.loginDetails.userType === 'SA'){
        this.ViewReportsData.isFrmViewDetails = 'yes';
        this.location.back();   // when navigated from view users in SA
      }else{
        this.route.navigateByUrl("/Uld/viewuld") ;
      }
    }

    fn_ToGet_ViewDetailedData(reportId){
        this.spinner.show();
        this.selectedReportId = Number(reportId);
       this.uldServices.get_UldReport_DetailedView({"reportId":reportId}).subscribe(responseData =>{
        this.spinner.hide();
          if(responseData.responseCode === 1){
            ;
           
              this.uldDamageReport = responseData.uldDamageReport ;
              
              if(this.uldDamageReport.rsExternalReportStatus){
                this.fn_ToGetAirlineEmails(reportId);
              }
              this.modifiedReports = responseData.modifiedReports ;
                 if(this.modifiedReports.length !== 0){
                  this.modifiedReports[0].id=Number(responseData.uldDamageReport.reportId)  ; 
                 }
            

              if(responseData.uldDamageReport.picDamageImage !== '' && responseData.uldDamageReport.picDamageImage !==null){
                this.uldDamageReportImages = responseData.uldDamageReport.picDamageImage.split(',') ;
              }else{
                this.uldDamageReportImages = [] ;
              }

              let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.uldDamageReport)   
              for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                  if(obj_Keys[i] !=="picDamageImage"){
                      if(this.uldDamageReport[obj_Keys[i]] === '' || this.uldDamageReport[obj_Keys[i]] === null){
                        this.uldDamageReport[obj_Keys[i]] = '--';
                        }
                  }
                
              }
          }else{
            this.uldDamageReport = {} ;
            this.modifiedReports = [] ;
            this.uldDamageReportImages = [] ;
          }
       },error=>{
        this.spinner.hide();
        this.uldDamageReport = {} ;
        this.modifiedReports = [] ;
        this.uldDamageReportImages = [] ;
       })
    }

    fn_ToGetAirlineEmails(reportId){
      
      this.spinner.show();
      this.uldServices.get_Report_AirlineEmails(reportId).subscribe(responseData =>{
        this.spinner.hide() ;
        if(responseData.responseCode === 1){
            
          this.EmailData =  responseData.EmailData ;
          this.externalEmailData =  responseData;
          
        }else{
         
        }
      },error =>{
        this.spinner.hide() ;
      })
      
    }

    fn_ToGet_IncomingViewDetailedData(reportId){
        this.spinner.show();
       this.uldServices.get_IncomingUldReport_DetailedView({"reportId":reportId}).subscribe(responseData =>{
        this.spinner.hide();
          if(responseData.responseCode === 1){
              this.uldDamageReport = responseData.uldDamageReport ;
              this.modifiedReports = responseData.modifiedReports ;
              if(responseData.uldDamageReport.picDamageImage !== '' && responseData.uldDamageReport.picDamageImage !==null){
                this.uldDamageReportImages = responseData.uldDamageReport.picDamageImage.split(',') ;
              }else{
                this.uldDamageReportImages = [] ;
              }
              if(this.uldDamageReport.rsExternalReportStatus){
                this.fn_ToGetAirlineEmails(reportId);
              }
              let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.uldDamageReport)   
              for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                  if(obj_Keys[i] !=="picDamageImage"){
                      if(this.uldDamageReport[obj_Keys[i]] === '' || this.uldDamageReport[obj_Keys[i]] === null){
                        this.uldDamageReport[obj_Keys[i]] = '--';
                        }
                  }
                
              }
          }else{
            this.uldDamageReport = {} ;
            this.modifiedReports = [] ;
            this.uldDamageReportImages = [] ;
          }
       },error=>{
        this.spinner.hide();
        this.uldDamageReport = {} ;
        this.modifiedReports = [] ;
        this.uldDamageReportImages = [] ;
       })
    }
    fn_To_Extract_Obj_Keys(jsonObject :JSON):Array<string>{  //---> To get object keys
        let obj_Keys = Object.keys(jsonObject);
        return obj_Keys ;
      }

      get_Detailed_View(reportObj , index){
        console.log(reportObj);
        if(index  === 0){
          let reportId = JSON.parse(localStorage.getItem("uldReportId_To_View")) ;
          this.selectedReportId = Number(reportId);
          
             this.fn_ToGet_ViewDetailedData(reportId) 
        }else{
          this.selectedReportId = Number(reportObj.id);
          this.get_Detailed_ModifiedReport_View(reportObj)
        }
      }

      get_Detailed_ModifiedReport_View(reportObj){
        this.spinner.show();
        this.uldServices.get_ModifiedReport_View({"id":reportObj.id}).subscribe(responseData =>{
          this.spinner.hide() ;
          if(responseData.responseCode === 1){
               this.uldDamageReport  = responseData.modifiedReports ;
               this.uldDamageReport.id  =  responseData.modifiedReports.reportId ;
               if(this.EmailData.length !== 0){
                this.EmailData[0].emails =   responseData.modifiedReports.repStationEmails ;
               }
               
               if(responseData.modifiedReports.picDamageImage !== '' && responseData.modifiedReports.picDamageImage !==null){
                this.uldDamageReportImages = responseData.modifiedReports.picDamageImage.split(',') ;
              }else{
                this.uldDamageReportImages = [] ;
              }
              let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.uldDamageReport)   
              for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                  if(obj_Keys[i] !=="picDamageImage"){
                      if(this.uldDamageReport[obj_Keys[i]] === '' || this.uldDamageReport[obj_Keys[i]] === null){
                        this.uldDamageReport[obj_Keys[i]] = '--';
                        }
                  }
                
              }
          }else{
            this.uldDamageReportImages = [] ;
            this.uldDamageReport ={}
          }
        },error =>{
          this.spinner.hide() ;
        })
        
      }
    

}
