import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from '../../config-service.service';




let headers = new HttpHeaders({
    // 'X_VERIFY': 'c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4'
});



@Injectable({
    providedIn: 'root'
})
export class SuperadminService {
    webapi: string;
    uldURL: string;

    constructor(private http: HttpClient, config: ConfigServiceService) {
        this.webapi = config.getConfig().URL
        this.uldURL = config.getConfig().ULD_Url
    }

    viewUsers(OrgId) {
        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/companyUsersInfo?organisationId=' + OrgId);
    }

    viewUserandReports(OrgId) {
        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getUserReportInfo?organisationId=' + OrgId);
    }
    viewReports(userType, orderType) {
        return this.http.get<any>(this.webapi + 'reportsDetailsCclpPortal.php/viewReports?userType=' + userType + '&orderType=' + orderType);
    }

    getActiveOrArchiveReports(orgId, orderType, userType) {
        return this.http.get<any>(this.webapi + 'superAdminUserReports.php/getUserReportInfo?organisationId=' + orgId + "&orderType=" + orderType + "&loginUserType=" + userType);
    }



    viewActivation(activateObj) {
        return this.http.post<any>(this.webapi + 'myAccountCclpPortal.php/setCompanyActiveCclpPortal', activateObj);
    }


    getSuperAdminDashBoard(emailid, usertype) {
        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getSuperAdminDashboard?emailId=' + emailid + '&userType=' + usertype);

    }
    getCompanySuperAdminDashBoard(emailid, usertype, orgType) {
        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getCompanyAdminDashboard?emailId=' + emailid + '&userType=' + usertype + '&orgType=' + orgType);

    }
    getShortReports(orgId, loginUserId) {

        return this.http.get<any>(this.webapi + 'getShortDamageReports.php/getShortDamageReports?orgId=' + orgId + '&loginUserId=' + loginUserId);

    }
    getSuperAdminShortReports(userType: String) {
        return this.http.get<any>(this.webapi + 'reportsDetailsCclpPortal.php/viewReports?userType=' + userType + "&orderType=S");
    }

    getUserDashBoard(emailid, usertype, orgType) {

        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getUserDashboard?emailId=' + emailid + '&userType=' + usertype + '&orgType=' + orgType);

    }
  
    authenticate(obj) {

        return this.http.post<any>(this.webapi + 'myAccountCclpPortal.php/setUserAuthCclpPortal', obj);
    }

    airlineData() {

        return this.http.post<any>(this.webapi + 'tracking.php/getTruckAirlineDetails', {})
    }
  
    airlines(obj) {
        return this.http.get<any>(this.uldURL + 'airlineApis/GetAirlineAwbList.php/airlineAwbList');
    }
  
    createAirlines(obj) {
        return this.http.post<any>(this.uldURL + 'airlineApis/CreateAirlineAwb.php/createAirlineAwb', obj);
    }

    getAirlineDetails(obj)  {
        return this.http.post<any>(this.uldURL + 'airlineApis/GetDetailedAirlineInfo.php/detailedAirlineInfo', obj);
    }

    updateAirlines(obj) {
        return this.http.post<any>(this.uldURL + 'airlineApis/UpdateAirlineAwb.php/updateAirlineAwb', obj);
    }

    DeleteMultipleAirline (obj) {
        return this.http.post<any>(this.uldURL + 'airlineApis/DeleteMultipleAirline.php/deleteMultipleAirline', obj);
    }

    ReActiveAirline (obj) {
        return this.http.post<any>(this.uldURL + 'airlineApis/ReActivateAirline.php/reActivateAirline', obj);
    }

    DeleteSingleAirline (obj) {
        return this.http.post<any>(this.uldURL + 'airlineApis/DeleteAirlineAwb.php/deleteAirlineAwb', obj);
    }
    
    createOrg(obj) {

        console.log(obj)
        return this.http.post<any>(this.webapi + 'companyDetailsCclpPortal.php/createCompanyCclpPortal', obj, { headers: headers })
    }

    editOrg(obj) {

        console.log(obj)
        return this.http.post<any>(this.webapi + 'companyDetailsCclpPortal.php/editCompanyCclpPortal', obj, { headers: headers })
    }

    viewOrg(organizationId) {

        return this.http.post<any>(this.webapi + 'companyDetailsCclpPortal.php/viewCompanyCclpPortal', organizationId, { headers: headers })
    }
    deleteOrg(obj) {

        return this.http.post<any>(this.webapi + 'deleteOrganisation.php/deleteOrganisation', obj)
    }

    OrgAuth(obj) {

        return this.http.post<any>(this.webapi + 'myAccountCclpPortal.php/setAuthActiveCclpPortal', obj)
    }
    getUnauthUsers(OrgId) {

        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getUnauthUsersList?orgId=' + OrgId);

    }
    viewDetailReport(masterAwbNo) {

        return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getReportDetails?masterAwbNo=' + masterAwbNo);

    }
    setUserAuthnticate(obj) {

        return this.http.post<any>(this.webapi + 'getLoginCclpPortal.php/setAuthUsers', obj)
    }

    getAllUsers(userType) {

        return this.http.get<any>(this.webapi + 'reportsDetailsCclpPortal.php/getTotalUsers?userType=' + userType)
    }
    deleteUnauthUser(obj) {

        return this.http.post<any>(this.webapi + 'getLoginCclpPortal.php/deleteUnauthUsers', obj)
    }
    deleteUser(obj) {

        return this.http.post<any>(this.webapi + 'userDetailsCclpPortal.php/deleteUser', obj)
    }

    setholdTime(obj) {

        return this.http.post<any>(this.webapi + 'checkListSave.php/setHoldTime', obj)
    }

    getAccessUserReport(orgId) {

        return this.http.get<any>(this.webapi + 'getAccessReports.php/viewReportAcessUsers?orgId=' + orgId)
    }
    getStationsList(orgId) {

        return this.http.get<any>(this.webapi + 'getAccessReports.php/getCclpStations?orgId=' + orgId)
    }
    getAccessAndunAccessList(orgId, stationCode) {

        return this.http.get<any>(this.webapi + 'getAccessReports.php/getAcessReportsUsers?orgId=' + orgId + '&stationCode=' + stationCode)
    }
    deleteAccessUser(obj) {

        return this.http.post<any>(this.webapi + 'getAccessReports.php/setUserAccessed', obj)
    }
    sendMultipleReportEmail(obj) {

        console.log(obj, 'obj')
        return this.http.post<any>(this.webapi + 'sendEmails.php/sendEmails', obj)
    }

    getDetailedReportsEmails(awbNum, reportId, flightDetails) {
        return this.http.get<any>(this.webapi + 'reportsDetailsCclpPortal.php/getAirlinemails?masterAwbNo=' + awbNum  + '&&flightDetails=' + flightDetails + "&&reportId=" + reportId, { headers: headers });

    }

    ActivateResentLink(ActivationData) {
        return this.http.get<any>(this.webapi + 'resendAndActive.php/activeUser?userId=' + ActivationData.userId + '&type=' + ActivationData.type + '&roleType=' + ActivationData.roleType)
    }

    activeAirlineEmailStatus(obj) {
        return this.http.post<any>(this.webapi + 'airlineEmailReplacement.php/activeAirlineEmailStatus', obj)
    }
    setUserRole(obj) {
        return this.http.post<any>(this.webapi + 'setUserRoleCclpPortal.php/setUserRole', obj)
    }
    editUserOrgn(obj) {
        return this.http.post<any>(this.webapi + 'updateCompanyName.php/changeCompanyName', obj)
    }
    GetOrgList(obj) {
        return this.http.post<any>(this.webapi + 'updateCompanyName.php/getOrganisation', obj)
    }


    viewDetails(obj) {
        let tab = JSON.parse(localStorage.getItem('selectedreports'));
        if (obj.type === 'view') {
            return this.http.post<any>(`${this.webapi}checkAirwaybillNumber.php/checkAirwaybillNumber`, obj);
        } else {
            if (tab === "Incoming") {
                return this.http.post<any>(`${this.webapi}viewIncomingDetailedReport.php/viewIncomingDetailedReport`, obj);
            } else {
                return this.http.post<any>(`${this.webapi}viewDetailedDamageReport.php/viewDetailedDamageReport`, obj);
            }
        }
    }

    modifiedReportsDetails(obj) {
        return this.http.post<any>(`${this.webapi}viewModifiedReport.php/viewModifiedReport`, obj);
    }

    DeleteReport(obj) {
        return this.http.post<any>(this.webapi + 'deleteReport.php/deleteReport', obj)
    }

    MoveToTrashReport(url, obj) {
        return this.http.post<any>(this.webapi + url, obj)
    }

    SetReportArchive(obj) {
        return this.http.post<any>(this.webapi + 'setReportArchive.php/setReportArchive', obj)
    }

    // 111023 To added the new KPT charts code start by iwi
    getCompanyAdminDashboardFilterChart(obj) {
        return this.http.post<any>(`${this.webapi}getLoginCclpPortal.php/getCompanyAdminDashboardFilterChart`, obj);
    }

    getUserDashboardFilterChart(obj) {
        console.log(obj);
        return this.http.post<any>(`${this.webapi}getLoginCclpPortal.php/getUserDashboardFilterChart`, obj);
    }

    getUserReportInfoFilterChart(obj) {
        console.log(obj);
        console.log(JSON.stringify(obj));
        
        return this.http.get<any>(this.webapi + 'superAdminUserReports.php/getUserReportInfoByChart?obj='+JSON.stringify(obj), { headers: headers });
    }
    // 111023 To added the new KPT charts code end by iwi

    //22042024 code by iwi
    getUserDetails(orgId, loginUserId) {
        return this.http.get<any>(
          this.webapi +
            "getLoginCclpPortal.php/companyUsersInfo?organisationId=" +
            orgId +
            "&loginUserId=" +
            loginUserId +
            { headers: headers }
        );
      }
      createAddress(obj) {
        return this.http.post<any>(
          `${this.webapi}customerAddress/customerAddressPortal.php/addUpdateCustomerAddress`,
          obj
        );
      }
      deleteAddress(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerAddress/customerAddressPortal.php/customerAddressRemove`,
          obj
        );
      }

      getCustomerReportInfo(obj) {
        return this.http.post<any>(
          `${this.webapi}customerService/customerServicePortal.php/getCustomerServiceDataById`,
          obj
        );
      }
      updateCustomerReportInfo(obj) {
        return this.http.post<any>(
          `${this.webapi}customerService/customerServicePortal.php/updateCustomerServiceDataById`,
          obj
        );
      }

      createCustomerReportInfo(obj) {
        return this.http.post<any>(
          `${this.webapi}customerService/customerServicePortal.php/createCustomerServiceDataById`,
          obj
        );
      }
      checkAWBNumber(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/getAWBInfoData`,
          obj
        );
      }

      getEmailList(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerAddress/customerAddressPortal.php/customerAllEmailids`,
          obj
        );
      }

      getTruckAirportService() {
        ;
        return this.http.post<any>(this.webapi + 'tracking.php/getTruckAirport', {headers: headers});
      }

      chkMialId(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/checkCustomerAddressIsEmail`,
          obj,
          { headers: headers }
        );
      }

      checkAirport(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/addressBookExistForAirportOrigin`,
          obj,
          { headers: headers }
        );
      }

      customerReportArchived(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/customerReportToArchived`,
          obj,
          { headers: headers }
        );
      }

      customerSendMail(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/sendReportToCustomer`,
          obj,
          { headers: headers }
        );
      }

      ArchiveResore(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/customerArchivedReportMoveToActive`,
          obj,
          { headers: headers }
        );
      }
      sendEmailNotification(user_id,CustTreportId,reportId) {
        return this.http.get<any>(
          this.webapi +
            "customerService/customerServicePortal.php/customerReportSendEmail?loginUserId=" +
            user_id+"&customer_report_id="+CustTreportId+"&report_id="+reportId,
          
        );
      }
    
      getchartList() {
        return this.http.get<any>(
          this.webapi + "kpiChartsEmailSettings.php/getvaluesSettings",
          { headers: headers }
        );
      }

      submitEmailsettingKpi(obj) {
        return this.http.post<any>(
          `${this.webapi}kpiChartsEmailSettings.php/saveKpiChartsEmailSettings`,
          obj
        );
      }

      getemailSettingList(loginuserId, orgType) {
        return this.http.get<any>(
          this.webapi +
            "kpiChartsEmailSettings.php/getKpiChartsEmailSettings?loginUserId=" +
            loginuserId +
            "&orgType=" +
            orgType,
          { headers: headers }
        );
      }

      updateEmailsettingKpi(obj) {
        return this.http.post<any>(
          `${this.webapi}kpiChartsEmailSettings.php/updateKpiChartsEmailSettings`,
          obj
        );
      }
    
      updateDateMannully(obj) {
        return this.http.post<any>(
          `${this.webapi}/customerService/customerServicePortal.php/customerReportIsNotified`,
          obj,
          { headers: headers }
        );
      }
    
      deleteEmailsettingKpi(obj) {
        return this.http.post<any>(
          `${this.webapi}kpiChartsEmailSettings.php/deleteKpiChartsEmailSettings`,
          obj,
          { headers: headers }
        );
      }

      instantMail(obj) {
        return this.http.post<any>(
          `${this.webapi}kpiChartsEmailSettings.php/sandInstantKpiReportByEmail`,
          obj,
          { headers: headers }
        );
      }
      rowMail(obj) {
        return this.http.post<any>(
          `${this.webapi}kpiChartsEmailSettings.php/sendReportByEmail`,
          obj,
          { headers: headers }
        );
      }
}
