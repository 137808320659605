import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigServiceService } from '../../config-service.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
 webapi :string;
 uldURL :string;
    constructor(private http: HttpClient,config : ConfigServiceService) { 
       this.webapi =  config.getConfig().URL
       this.uldURL = config.getConfig().ULD_Url
    }
  

    login(LoginForm) {
        let Logindetails = {
            "emailId": LoginForm.emailId,
            "password": LoginForm.password
        }
        return this.http.post<any>( this.webapi + 'getLoginCclpPortal.php/getLogin', Logindetails)
    }

    switchCCLP(obj) {
        return this.http.post<any>( this.uldURL + 'portalTocclpSwitch.php/switchCclp', obj)
    }

    service_ToCheckLogin_Status(token1) {
        ;   
        let headers = new HttpHeaders({ 'Authorization': token1, 'Content-Type': 'application/json; charset=utf-8', });
        return this.http.post<any>( this.webapi + 'tokenVerification.php/getValidateToken', {}, { headers: headers });
    }


    logOutService(obj) {
        ;
        return this.http.post<any>(this.webapi + 'logout.php/getLogout', obj)
    }



    getSampleData(obj){
        return this.http.post<any>("http://testapi.cclp.aero/inspectionApis/getInspectionList.php/inspectionList" , obj)
        
    }

    getSession(obj){
        return this.http.post<any>(this.uldURL + 'CheckUserSession.php/checkSession', obj);
    }

    getHAWBdetails(data) { 
        return this.http.post<any>(this.uldURL + 'cargoAccept/ImportAcceptance/GetHawbDetails.php/getHawbDetails', data) 
    } 

    submitDeliveryReciept(obj){ 
        return this.http.post<any>(this.uldURL + 'cargoAccept/ImportAcceptance/UploadHawbImages.php/uploadHawbImages', obj); 
    }

    getUserDetailsForSetPassword(data) {
        return this.http.post<any>(this.uldURL + 'PasswordSetUp.php/isPasswordExist', data)
    }
}
