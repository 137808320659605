import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedLayoutsRoutingModule } from './shared-layouts-routing.module';
import { NavbarComponent } from '../navbar/navbar.component';
import { FooterComponent } from '../footer/footer.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatSelectModule, MatListModule, MatToolbarModule, MatSidenavModule, MatStepperModule, MatCardModule, MatExpansionModule, MatAutocompleteModule } from '@angular/material';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DxDataGridModule } from 'devextreme-angular';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputFormatsDirective } from '../../directives/input-formats.directive';
import { ViewUldComponent } from 'src/app/uld/view-uld/view-uld.component';
import { DetailedUldComponent } from 'src/app/uld/detailed-uld/detailed-uld.component';
import { NumberDirective } from 'src/app/save-reports/numbers-only.directive';
import { CharactersOnlyDirectiveDirective } from 'src/app/directives/characters-only-directive.directive';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    InputFormatsDirective,
    DetailedUldComponent ,
    ViewUldComponent,
    NumberDirective,
    CharactersOnlyDirectiveDirective
  ],
  imports: [
    CommonModule,
    SharedLayoutsRoutingModule,
    // SelectAutocompleteModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
  
    
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    DxDataGridModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
  ],
  exports:[
    NavbarComponent,FooterComponent,SidebarComponent,InputFormatsDirective, DetailedUldComponent ,
    ViewUldComponent,NumberDirective,
    CharactersOnlyDirectiveDirective
  ]
})
export class SharedLayoutsModule { }
