import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.css']
})
export class SuperadminComponent implements OnInit {
  userName: any;

  constructor() { }

  ngOnInit() {
    this.userName = JSON.parse(localStorage.getItem('loginresultobject'));

  }

}
