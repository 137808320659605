import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-open-api',
  templateUrl: './open-api.component.html',
  styleUrls: ['./open-api.component.css']
})
export class OpenApiComponent implements OnInit {
  pagename: any;
  resjson:any;
  role: any;

  constructor(private readonly route: ActivatedRoute,) {
    this.resjson = {
      "responseCode": 1,
      "info": {
          "totalOrders": 96,
          "totalPages": 32,
          "resultPerPage": "3",
          "currentPage": "1"
      },
      "ActiveReports": [
          {
              "accepted_id": "ams-098876",
              "pdf": "https://api.cclp.aero/cargoAccept/cargoAccPdf/6295c99f33e44_5751.pdf",
              "take_in": "2022-05-31 01:22:38",
              "created_date": "2022-05-31 13:24:07"
          },
          {
              "accepted_id": "bru-08976",
              "pdf": "https://api.cclp.aero/cargoAccept/cargoAccPdf/6295c9f474cdf_5750.pdf",
              "take_in": "2022-05-31 01:13:46",
              "created_date": "2022-05-31 13:13:56"
          },
          {
              "accepted_id": "XAD 0987",
              "pdf": "https://api.cclp.aero/cargoAccept/cargoAccPdf/6295ca04debf7_5749.pdf",
              "take_in": "2022-05-31 01:12:58",
              "created_date": "2022-05-31 13:13:05"
          }
      ]
  }
  
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
          this.pagename = params['fromWhichPage'];
          this.role = params['role'];
      });
  }

}
