import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, enableProdMode } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfigServiceService } from 'src/app/config-service.service';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { UldModuleService } from 'src/app/services/uldmoduleServices/uld-module.service';
import { globalConstants } from 'src/environments/globalConstants';

declare var jQuery: any;
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: 'app-view-uld',
  templateUrl: './view-uld.component.html',
  styleUrls: ['./view-uld.component.css']
})
export class ViewUldComponent implements OnInit {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  dataGrid1: DxDataGridComponent;
  selectedRowsData: any = []
  isReportActionsEnable: boolean;
  static TOTAL_GRIDDATA = [];
  finalReportsCount: any = 0;
  incomingReportsCount: any = 0;
  static API_URL: string;
  static totaldata: any;
  loginDetails:any
  comapanydetails: any;
  anchor_Tags: any;
  editicon_Tags: any;
  damageActiveReportsCount: any;
  damageArchiveReportsCount: any;
  damageIncomingReportsCount: any;
  images: any;
  resendObj: any;
  internalReportsCount: any;

  constructor(private router: Router, private config: ConfigServiceService, private spinner: NgxSpinnerService,
    private uldServices: UldModuleService, private toastr: ToastrService, private elementRef: ElementRef, 
    private ViewReportsData: ViewReportsData,private location: LocationStrategy , private dialog: MatDialog
  ) {
    ViewUldComponent.API_URL = this.config.getConfig().URL;

       // preventing back button in browser   
       history.pushState(null, null, window.location.href);  
       this.location.onPopState(() => {
         history.pushState(null, null, window.location.href);
       }); 
  }

  ngOnInit() {
    this.spinner.hide();
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
   
  }

  ngAfterViewChecked() {
    let selectedTab = JSON.parse(localStorage.getItem("sideReportsTab"));
    let loginData = JSON.parse(localStorage.getItem('loginresultobject'));
    if( loginData.userType !== 'SA'){
    if (selectedTab !== null) {
      if (selectedTab === 'R') {
        this.isReportActionsEnable = false;
        this.myGrid.selectionmode("none")
        document.getElementById('active_Reports').className = 'nav-link';
        document.getElementById('archive_Reports').className = 'nav-link active';
        if(this.loginDetails.orgType === 'A'){
          document.getElementById('Incoming_Reports').className = 'nav-link';
        }
      }else if(selectedTab === 'I'){
        this.isReportActionsEnable = true;
        this.myGrid.selectionmode("none") ;
        document.getElementById('active_Reports').className = 'nav-link';
        document.getElementById('archive_Reports').className = 'nav-link';
        if(this.loginDetails.orgType === 'A'){
          document.getElementById('Incoming_Reports').className = 'nav-link active';
        }
      }
      else {
        if (loginData.reportAcess === 'Y') {
          this.isReportActionsEnable = true;
          this.myGrid.selectionmode("checkbox");
        } else {
          this.isReportActionsEnable = false;
          this.myGrid.selectionmode("none");
        }
        document.getElementById('active_Reports').className = 'nav-link active';
        document.getElementById('archive_Reports').className = 'nav-link';
        if(this.loginDetails.orgType === 'A'){
          document.getElementById('Incoming_Reports').className = 'nav-link';
        }

      }
    } else {
       if(loginData.orgType === 'A'){
        this.isReportActionsEnable = false;
        this.myGrid.selectionmode("none");
        document.getElementById('active_Reports').className = 'nav-link';
        document.getElementById('archive_Reports').className = 'nav-link';
        if(this.loginDetails.orgType === 'A'){
          document.getElementById('Incoming_Reports').className = 'nav-link active';
        }


       }else{
        if (loginData.reportAcess === 'Y') {
          this.isReportActionsEnable = true;
          this.myGrid.selectionmode("checkbox");
        } else {
          this.isReportActionsEnable = false;
          this.myGrid.selectionmode("none");
        }
       }

     document.getElementById('active_Reports').className = 'nav-link active';
        document.getElementById('archive_Reports').className = 'nav-link';
        if(this.loginDetails.orgType === 'A'){
          document.getElementById('Incoming_Reports').className = 'nav-link';
        }
    }
  }



  //------------- edit icon click assignments ---------//

    this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll('a');
    this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll('i');
    this.images = this.elementRef.nativeElement.querySelectorAll('img');

    this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.href != '') {
            let href_Valid_Check_Var = anchor.href.split(":")[0];
            if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                anchor.addEventListener('click', this.handleAnchorClick)
            }
        }
    })

    this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
        if (editicon.id != '') {
            let href_Valid_Check_Var = editicon.id.split(":")[0];
            if ((href_Valid_Check_Var === 'edit')) {
                editicon.addEventListener('click', this.handleiconClick)
            } 
        }
    })
    this.images.forEach((image: HTMLImageElement) => {
      if (image.id != '') {
          let href_Split_Array = [];
          href_Split_Array = image.id.split(":");
          let href_Valid_Check_Var = href_Split_Array[0];
          if ((href_Valid_Check_Var === 'resend')) {
              image.addEventListener('click', this.handleImageClick)
          }

      }
  })
  }
  
  public handleiconClick = (event: Event) => {
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.onClick_Icon(editicon);
  }
  onClick_Icon(editicon : HTMLImageElement) {
    let href_Valid_Check_Var = editicon.id.split(":")[1];
    this.onEditClick(ViewUldComponent.totaldata[href_Valid_Check_Var]);
  }


  public handleAnchorClick = (event: Event) => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    this.onClick_AnchorTag(anchor);
  }
  
  onClick_AnchorTag(anchor : HTMLAnchorElement) {
    if (anchor.innerText === 'Edit') {
        let href_Valid_Check_Var = anchor.href.split(":")[1];
        this.onEditClick(ViewUldComponent.totaldata[href_Valid_Check_Var]);
    }
  }

  public handleImageClick = (event: Event) => {
    // Prevent opening anchors the default way
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.fn_alertImage(editicon, event);
}
fn_alertImage(image, event) {
  
  let href_Split_Array = [];
  href_Split_Array = image.id.split(":");
  let href_Valid_Check_Var = href_Split_Array[1];
  if (href_Split_Array[0] === 'resend') {
    this.present_ResendModal(ViewUldComponent.totaldata[href_Valid_Check_Var]) ;
  }
}
present_ResendModal(obj){
  this.resendObj = obj
  jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
}



  onEditClick(obj : any) :void{
    this.ViewReportsData.uldISFrom = 'Edit' ;
    this.ViewReportsData.uldId =  obj.id ; 
    this.ViewReportsData.uldOrganizationId = obj.org_id ;
    this.ViewReportsData.uldReportUserId = obj.user_id ;
    this.router.navigateByUrl('/Uld/createuld')
  }


  ngAfterViewInit() {
    let selectedTab = JSON.parse(localStorage.getItem("sideReportsTab"));
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
    
    if( this.loginDetails.userType !== 'SA'){
      if (selectedTab !== null) {
        if (selectedTab === 'A') {
          this.fn_OnChangeReportTab("A");
        } else if(selectedTab === 'R'){
          this.fn_OnChangeReportTab("R");
        }else{
          if(this.loginDetails.orgType === 'A'){
          this.fn_OnChangeReportTab("I");
          }else{
            this.fn_OnChangeReportTab("A");
          }
        }
      } else {
        if(this.loginDetails.orgType === 'A'){
          this.fn_OnChangeReportTab("I");
        }else{
          this.fn_OnChangeReportTab("A");
        }
      }
    }
  }

  fn_OnChangeReportTab(type: string) { //--- call when change reports tab

    this.spinner.show();
    this.source.url = this.config.getConfig().URL + 'ulddamagereports/getUldReportList.php/getUldReportList?organizationId=' + this.logindet.orgId + "&orderType=" + type + "&loginUserType=" + this.logindet.userType + '&loginUserId=' + this.logindet.userId;
    localStorage.setItem("sideReportsTab", JSON.stringify(type));
  
    try{
      this.myGrid.updatebounddata("refresh");
      this.myGrid.gotopage(0)
      this.selectedRowsData = [];
      if (type === "A") {
        this.isReportActionsEnable = true;
        
        this.myGrid.showcolumn("actions");
  
        this.myGrid.hidecolumn("orgName");
        this.myGrid.showcolumn("rs_external_report_status");
        this.myGrid.showcolumn("rs_internal_report_path");
      } else if (type === "R") {
        this.isReportActionsEnable = false;
       
        this.myGrid.showcolumn("actions");
      
        this.myGrid.hidecolumn("orgName");
        this.myGrid.showcolumn("rs_external_report_status");
        this.myGrid.showcolumn("rs_internal_report_path");
      } else if (type === "I") {
        this.isReportActionsEnable = true;
     
        this.myGrid.hidecolumn("actions");
        this.myGrid.hidecolumn("rs_external_report_status");
        this.myGrid.showcolumn("orgName");
        this.myGrid.hidecolumn("rs_internal_report_path");
      }
    }catch(exception){
    console.log("exceptions",exception);
    setTimeout(() => {
      if (type === "A") {
        this.isReportActionsEnable = true;
        this.myGrid.showcolumn("actions");
        this.myGrid.hidecolumn("orgName");
        this.myGrid.showcolumn("rs_external_report_status");
        this.myGrid.showcolumn("rs_internal_report_path");
      } else if (type === "R") {
        this.isReportActionsEnable = false;
        this.myGrid.showcolumn("actions");

        this.myGrid.hidecolumn("orgName");
        this.myGrid.showcolumn("rs_external_report_status");
        this.myGrid.showcolumn("rs_internal_report_path");
      } else if (type === "I") {
        this.isReportActionsEnable = true;
     
        this.myGrid.hidecolumn("actions");
        this.myGrid.hidecolumn("rs_external_report_status");
        this.myGrid.showcolumn("orgName");
        this.myGrid.hidecolumn("rs_internal_report_path");
      }
    }, 1000);
   
    }
    
  }


  logindet = JSON.parse(localStorage.getItem('loginresultobject'));
  // url = this.config.getConfig().URL + 'ulddamagereports/getUldReportList.php/getUldReportList?organizationId=' + this.logindet.orgId + "&orderType=A&loginUserType=" + this.logindet.userType + '&loginUserId=' + this.logindet.userId;
  url = "";
  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY ;
  source =
    {
      url: this.url,
      datatype: 'json',
      datafields:
        [
          { name: 'si_station_code', type: 'string' },
          { name: 'ui_serial_number', type: 'string' },
          { name: 'rs_report_created_time', type: 'string' },
          { name: 'fi_flight_date', type: 'date' },
          { name: 'ina_area_supervisor', type: 'string' },
          { name: 'fi_flight_details', type: 'string' },
          { name: 'ina_casue_of_event', type: 'string' },
          { name: 'ina_department', type: 'string' },
          { name: 'ina_discoveres_caused', type: 'string' },
          { name: 'ina_discoveres_caused', type: 'string' },
          { name: 'ina_exact_location', type: 'string' },
          { name: 'di_serviceable', type: 'string' },
          { name: 'org_Name', type: 'string' },
          { name: 'di_additional_damage_info', type: 'string' },
          { name: 'ui_other_ud_ownername', type: 'string' },
          { name: 'ui_name_of_returning_agent', type: 'string' },
          { name: 'rs_internal_report_path', type: 'string' },

          { name: 'rs_external_report_path', type: 'string' },
          { name: 'inaHandlingStageDesc', type: 'string' },
          { name: 'inaProcessAreaDesc', type: 'string' },
          { name: 'rs_external_report_status', type: 'string' },
          { name: 'orgName', type: 'string' },

          { name: 'id', type: 'string' },
          { name: 'user_id', type: 'string' },
          { name: 'actions', type: 'string' },
        ],
      pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
      root: 'Rows',
      totalrecords: 800,
      cache: false,
      type: 'GET',

      sort: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata('sort');
      },
      filter: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata('filter');
      },
      beforeprocessing: (data: any) => {
       
        if (data.responseCode === 1) {
           
          data.Rows = data.userReports;
          this.source.totalrecords = data.TotalRows;
          this.finalReportsCount = data.finalReportsCount;
          this.incomingReportsCount = data.incomingReportsCount;

         this.damageActiveReportsCount = data.damageActiveReportsCount ;
         this.damageArchiveReportsCount = data.damageArchiveReportsCount ;
         this.damageIncomingReportsCount = data.damageIncomingReportsCount ;
         this.internalReportsCount = data.internalReportsCount ;
          ViewUldComponent.totaldata = data.userReports ;
          if (ViewUldComponent.TOTAL_GRIDDATA.length === 0) {
            ViewUldComponent.TOTAL_GRIDDATA = data.userReports ;
          } else {
            ViewUldComponent.TOTAL_GRIDDATA = ViewUldComponent.TOTAL_GRIDDATA.concat(data.userReports);
          }
          this.spinner.hide()
        } else {
          data.Rows = [];
          this.source.totalrecords = 0 ;
          this.finalReportsCount = 0 ;
          this.incomingReportsCount = 0 ;
          this.spinner.hide()
        }

      },

      pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
        // callback called when a page or page size is changed.
        console.log(pagenum);
      }

    };
  linkrenderer = function (row, column, value) {
    var html = "<a  href='" + ViewUldComponent.API_URL + "ulddamagereports/" + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
    return html;
  }

  columnsrenderer = function (value) {
    return '<div style="text-align: center; margin-top: 5px;">' + value + '</div>';
  }
  dataAdapter = new jqx.dataAdapter(this.source);
  public loginDataDup = JSON.parse(localStorage.getItem('loginresultobject'));
  columns =
    [
      {
        text: "Carrier", datafield: 'rs_external_report_status', cellsalign: 'center', width: 150,
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
          if (ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'N' || ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'C' || ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'P') {
            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"
          } else if (ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'E') {
            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger' aria-hidden='true'></i> Not found</div>"
          } else if (ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'A') {
            return "<div class='m-archive' ><i class='fa fa-archive' aria-hidden='true'></i> Manual Archived</div>";
          } else if (ViewUldComponent.totaldata[columndata.uid].rs_external_report_status === 'S') {
            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span><img id = 'resend:" + columndata.uid + "' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>"
          }
        }
      },
      {
                
              
        text:   'Incoming Reports From', datafield: 'orgName',hidden: true,  cellsalign: 'center',width:  180 ,
    cellclassname: function (row, column, value, data) {
            return data.emailStatus = "Received from " + ViewUldComponent.totaldata[data.uid].orgName + "</span>";
    },
    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
        
        if((ViewUldComponent.totaldata.length !== 0) && (columndata.uid < ViewUldComponent.totaldata.length)){
            return "<span style='padding: 2px;position: relative;top:  10px; border-radius: 5px;width: 141px;; margin-left: 10px;'>" + ViewUldComponent.totaldata[columndata.uid].orgName + "</span>";
     }   
    },
},
      { text: "Serial Number", datafield: 'ui_serial_number', cellsalign: 'center', width: 100 },

      // { text: "Responsibility", cellsalign: 'center', width: 100 },

      {
        text: 'Actions', datafield: 'actions', cellsalign: 'center', width: 60, filterable: false, sortable: false, groupable: false,
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
          return "<div style='margin-top:  8.5px; margin-left: 5px;'><a  href='edit:" + columndata.uid + "'><i id = 'edit:" + columndata.uid + "' class='fa fa-pencil-square-o' style='padding:0 15px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a></div>";
        },
      },
      {
        text: 'Internal', datafield: 'rs_internal_report_path', width: 70, filterable: false, sortable: false, groupable: false, cellsrenderer: this.linkrenderer,
      },
      { text: 'External', datafield: 'rs_external_report_path', width: 70, filterable: false, sortable: false, groupable: false, cellsrenderer: this.linkrenderer },
      { text: "Station", datafield: 'si_station_code', cellsalign: 'center', width: 100 },
      { text: "Created Date and Time", datafield: 'rs_report_created_time', cellsalign: 'center', width: 100 },
      { text: "Flight Date", datafield: 'fi_flight_date', cellsalign: 'center', width: 100, cellsformat: 'dd-MM-yyyy', filtertype: 'date' },
      { text: "Area Supervisor", datafield: 'ina_area_supervisor', cellsalign: 'center', width: 100 },
      { text: "Flight Number", datafield: 'fi_flight_details', cellsalign: 'center', width: 100 },
      { text: "Cause Of Event", datafield: 'ina_casue_of_event', cellsalign: 'center', width: 100 },
      { text: "Handling Stage", datafield: 'inaHandlingStageDesc', cellsalign: 'center', width: 100 },
      { text: "process Area", datafield: 'inaProcessAreaDesc', cellsalign: 'center', width: 100 },
      { text: "Department", datafield: 'ina_department', cellsalign: 'center', width: 100 },
      { text: "Discovered / Caused", datafield: 'ina_discoveres_caused', cellsalign: 'center', width: 100 },
      { text: "Exact Location", datafield: 'ina_exact_location', cellsalign: 'center', width: 100 },
      { text: "Serviceable ?", datafield: 'di_serviceable', cellsalign: 'center', width: 100 },
      { text: "Airline", datafield: 'org_Name', cellsalign: 'center', width: 100 },
      { text: "Additional damage information", datafield: 'di_additional_damage_info', cellsalign: 'center', width: 100 },
      { text: "Owner Name", datafield: 'ui_other_ud_ownername', cellsalign: 'center', width: 100 },
      { text: "Returned By", datafield: 'ui_name_of_returning_agent', cellsalign: 'center', width: 100 },

      { text: "", datafield: 'id', cellsalign: 'center', width: 100, hidden: true },
      { text: "user_id", datafield: 'user_id', cellsalign: 'center', width: 100, hidden: true },
    ];
  rendergridrows = (params: any): any => {
    return params.data;
  }

  Rowdoubleclick(event) {
    
   let currentTab =  JSON.parse(localStorage.getItem("sideReportsTab")) ;
    if(currentTab === 'R' || currentTab === 'A'){
      localStorage.setItem("uldReportFrom", JSON.stringify("NotIncoming")) ;
    }else{
      localStorage.setItem("uldReportFrom", JSON.stringify("Incoming")) ;
    }
    localStorage.setItem("uldReportId_To_View", JSON.stringify(event.args.row.bounddata.id)) ;
    this.router.navigateByUrl('/Uld/DetailedUld') ;
  }

  getDtotalData() { // return total selected data from all pages 
     
    return this.removeDuplicates(ViewUldComponent.TOTAL_GRIDDATA)
  }

  removeDuplicates(array) {
     
    let uniq = {};
    return array.filter(obj => !uniq[obj.reportId] && (uniq[obj.reportId] = true))
  }



  _onSelectRow(event) { // trigger when checkbox checked / unchecked 
    if (Array.isArray(event.args.rowindex)) {
      if (event.args.rowindex.length !== 0) {
        this.selectedRowsData = this.getDtotalData();
      } else {
        this.selectedRowsData = [];
      }
    } else {
      if (event.type === "rowunselect") {
        let index = this.selectedRowsData.findIndex(x => x.id === event.args.row.id);
        this.selectedRowsData.splice(index, 1);
      } else {
        var ind = ViewUldComponent.TOTAL_GRIDDATA.findIndex(x => x.id === event.args.row.id);
        event.args.row.carrierId = ViewUldComponent.TOTAL_GRIDDATA[ind].carrierId;
        this.selectedRowsData.push(event.args.row);
        
      }
    }
    console.log("this.selectedRowsData", this.selectedRowsData)
  }


  send_Or_Cancel_UldReport_To_Airline(type:string) {   //--------> Send report
     
    if (this.selectedRowsData.length !== 0) {
      this.spinner.show()
      let inputObj = { "reportStatus": type, "reportSendBy": this.logindet.userId, "reportsList": [] }
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        let eachReportData = {
          "userId": this.selectedRowsData[i].user_id,
          "stationCode": this.selectedRowsData[i].si_station_code,
          "reportId": this.selectedRowsData[i].id,
          "carrierId": this.selectedRowsData[i].carrierId
        }
        inputObj.reportsList.push(eachReportData);
      }
      this.uldServices.send_UldReport_ToAirlines(inputObj).subscribe(responseData => {
        this.spinner.hide()
        if (responseData.responseCode === 1) {
          jQuery('#emailModal_InUld').modal('hide');
          jQuery('#cancelModal_InUld').modal('hide');
          this.toastr.success(responseData.message);
          this.selectedRowsData = [];
          this.myGrid.clearselection();
          this.myGrid.updatebounddata("refresh");
          this.myGrid.gotopage(0)
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          jQuery('#emailModal_InUld').modal('hide');
          jQuery('#cancelModal_InUld').modal('hide');
          this.spinner.hide();
        } else {
          jQuery('#emailModal_InUld').modal('hide');
          jQuery('#cancelModal_InUld').modal('hide');
          this.toastr.error(responseData.message);
        }
      }, error => {
        jQuery('#emailModal_InUld').modal('hide');
        jQuery('#cancelModal_InUld').modal('hide');
        this.spinner.hide();
      })
    } else {
      this.toastr.error("Please select atleast one report")
    }
  }

  reSend_Report_To_Airline() {   //--------> Send report
    if (this.resendObj !== null && this.resendObj !== undefined) {
      this.spinner.show()
      let inputObj = { "reportStatus": 'S', "reportSendBy": this.logindet.userId, "reportsList": [] }
   
        let eachReportData = {
          "userId": this.resendObj.user_id,
          "stationCode": this.resendObj.si_station_code,
          "reportId": this.resendObj.id,
          "carrierId": this.resendObj.carrierId,
        }
        inputObj.reportsList.push(eachReportData);
      
      this.uldServices.send_UldReport_ToAirlines(inputObj).subscribe(responseData => {
        this.spinner.hide()
        if (responseData.responseCode === 1) {
          jQuery('#resendModal').modal('hide');
          this.toastr.success(responseData.message);
          
          this.myGrid.clearselection();
          this.myGrid.updatebounddata("refresh");
          this.myGrid.gotopage(0)
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          jQuery('#resendModal').modal('hide');
          this.spinner.hide();
        } else {
          jQuery('#resendModal').modal('hide');
          this.toastr.error(responseData.message);
        }
      }, error => {
        jQuery('#resendModal').modal('hide');
        this.spinner.hide();
      })
    } else {
      this.toastr.error("Please select atleast one report")
    }
  }

  fn_OnRefresh() {   //--------> Refresh api call
    
    this.spinner.show();
      this.uldServices.refresh().subscribe(responseData => {
        this.spinner.hide()
        if (responseData.responseCode === 1) {
    
          // this.toastr.success(responseData.message);
          this.selectedRowsData = [];
          this.myGrid.clearselection();
          this.myGrid.updatebounddata("refresh");
          this.myGrid.gotopage(0)
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          this.spinner.hide();
        } else {
  
          this.toastr.error(responseData.message);
        }
      }, error => {
     
        this.spinner.hide();
      })
    
  }

  OpenEmailPopup() {  //---open send report popup
    
    if (this.selectedRowsData.length === 0) {
      this.toastr.error('Please select atleast one report');
    } else {
      jQuery('#emailModal_InUld').modal({ backdrop: 'static', keyboard: false });
    }
  }

  onCancelSendReportPopup() {  //--- Cancel send report popup
    jQuery('#emailModal_InUld').modal('hide');
  }

 onHoldEmailPopup() {  //---open On Hold report popup
    if (this.selectedRowsData.length === 0) {
      this.toastr.error('Please select atleast one report');
    } else {
      jQuery('#cancelModal_InUld').modal({ backdrop: 'static', keyboard: false });
    }
  }

  archive_UldReport() {   //--------> Archive report
    if (this.selectedRowsData.length !== 0) {
      this.spinner.show()
      let inputObj = {"reportsList": []}
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        let eachReportData = {
          "userId": this.selectedRowsData[i].user_id,
          "reportId": this.selectedRowsData[i].id
        }
        inputObj.reportsList.push(eachReportData);
      }
      this.uldServices.manualArchive_Report(inputObj).subscribe(responseData => {
        this.spinner.hide()
        if (responseData.responseCode === 1) {
          jQuery('#manarchModal_InUld').modal('hide');
          this.toastr.success(responseData.message);
          this.selectedRowsData = [];
          this.myGrid.clearselection();
          this.myGrid.updatebounddata("refresh");
          this.myGrid.gotopage(0)
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          jQuery('#manarchModal_InUld').modal('hide');
          this.spinner.hide();
        } else {
          this.toastr.error(responseData.message);
        }
      }, error => {
        jQuery('#manarchModal_InUld').modal('hide');
        this.spinner.hide();
      })
    } else {
      this.toastr.error("Please select atleast one report")
    }
  }


  OpenArchiveEmailPopup() {  //---open Archive report popup
    if (this.selectedRowsData.length === 0) {
      this.toastr.error('Please select atleast one report');
    } else {
      jQuery('#manarchModal_InUld').modal({ backdrop: 'static', keyboard: false });
    }
  }

  delete_UldReport() {   //--------> Archive report
    if (this.selectedRowsData.length !== 0) {
      this.spinner.show()
      let inputObj = {"reportsList": []}
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        let eachReportData = {
          "userId": this.selectedRowsData[i].user_id,
          "reportId": this.selectedRowsData[i].id
        }
        inputObj.reportsList.push(eachReportData);
      }
      this.uldServices.delete_Report(inputObj).subscribe(responseData => {
        this.spinner.hide()
        if (responseData.responseCode === 1) {
          jQuery('#delrepModal_InUld').modal('hide');
          this.toastr.success(responseData.message);
          this.selectedRowsData = [];
          this.myGrid.clearselection();
          this.myGrid.updatebounddata("refresh");
          this.myGrid.gotopage(0)
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          jQuery('#delrepModal_InUld').modal('hide');
          this.spinner.hide();
        } else {
          this.toastr.error(responseData.message);
        }
      }, error => {
        jQuery('#delrepModal_InUld').modal('hide');
        this.spinner.hide();
      })
    } else {
      this.toastr.error("Please select atleast one report")
    }
  }


  OpenDeleteEmailPopup() {  //---open Archive report popup
    if (this.selectedRowsData.length === 0) {
      this.toastr.error('Please select atleast one report');
    } else {
      jQuery('#delrepModal_InUld').modal({ backdrop: 'static', keyboard: false });
    }
  }

  // redirect to handler cargo image
  onClickCargo_Image(){
    localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"))
    this.router.navigateByUrl('/CargoImageHandler/view-shipment');
  }
 
  //redirect to damage reports
  onClickDamage_Reports() {

    this.spinner.show();

    setTimeout(() => {
      
    
    localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
    
   if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
     
      this.router.navigateByUrl('/csa-dashboard');
    }
    else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
     
      this.router.navigateByUrl('/csa-airline-dashboard');
    }
    else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
    
      this.router.navigateByUrl('/admin-airline-dashboard');
    }
    else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
     
      this.router.navigateByUrl('/admin-handler-dashboard');
    }
    else if (this.loginDetails.userType == 'E') {
     
      this.router.navigateByUrl('/user-dashboard');
    }else if (this.loginDetails.orgType == 'E') {
      this.router.navigateByUrl('/HandlerDashboardComponent');
    }else{
      localStorage.clear()
      this.router.navigateByUrl('/Home');
    }

  }, 200);
  
  // this.openDialog()
  
  // let loginDetails = localStorage.getItem('loginDetails');
  // window.location.replace(`http://localhost:4200/cargo-damage?values=${loginDetails}`);
}

onclick_damageCustomerReport(){
  localStorage.setItem("currentModule",JSON.stringify("damageCustomerReport"))
  this.router.navigateByUrl("damageCustomerReport");
}

onclick_CustomerReportHandler(){
  console.log("customerReportHandler");
  localStorage.setItem("currentModule", JSON.stringify("customerReportHandler"));
  this.router.navigateByUrl("/customerReportHandler");
}


onClick_CargoServiceRecovery(){
  localStorage.setItem(
      "currentModule",
      JSON.stringify("csa-service-recovery")
    );
    this.router.navigateByUrl("/csa-service-recovery");
}


openDialog() {
  // const dialogRef = this.dialog.open(DialogContentExampleDialog);  
  // dialogRef.afterClosed().subscribe(result => {
  //   console.log('The dialog was closed', result);
  // });
}


deActivateStations() {
  this.router.navigateByUrl('/viewdeactivatestationemail') ;
}



getCurrentTab() :boolean { // To enable report actions in view users uld reports
 let currentViewUserTab =  JSON.parse(localStorage.getItem("sideReportsTab")) ;
  return (currentViewUserTab  === 'A');

}


AirportClaim() {
 this.spinner.show();
setTimeout(() => {
  localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
this.router.navigateByUrl('/cargoClaim/dashboard');
}, 200);
 

}

OnClickTruckInspection() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
    this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
  }, 200);
}
onClick_Save_Uld() {
  this.ViewReportsData.uldISFrom = 'Create' ;
  this.router.navigateByUrl('/Uld/createuld');
}

}
