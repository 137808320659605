import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Router, ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
// import { ViewUsersComponent }from '../view-users/view-users.component'
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../../config-service.service';
@Component({
    selector: 'app-viewdetails',
    templateUrl: './viewdetails.component.html',
    styleUrls: ['./viewdetails.component.css'],
    //   providers: [ViewReports]
})
export class ViewdetailsComponent implements OnInit {
    eachReport: any;
    flightDetails: any;
    Images: any;
    damageImage: any;
    webapi: string;
    isfrom: string;
    currentUrl: any;
    previousUrl: any;
    subscribedParam: any;
    comapanydetails: any;
    LoginDetails: any;
    ReportsProductionUrl: string;
    eachReports: any;
    viewdetails: any;
    awbnoObj: any;
    reportsList: any;
    viewreports: boolean;
    recentreports: boolean;
    NavigatebackTo: any;
    externalEmailsCardData: any;
    sentDate: any;
    STATIONS_WITH_EMAILSARRAY: any[];
    statusOfTheReport: string;
    typeOfCard: any;
    SelectedawbNumber: string;
    modifiedReports: any;
    reportStatus: any;
    emailStatus: any;
    selectedName: any;
    rowSelected: boolean = false;
    isFromreportsSidereports: any;
    showModifiedReports: boolean;
    SelectedawbNumber_reportId: any;
    selectedreports: any;
    reportsTab;
    responseData: any;
    constructor(private sanitizer: DomSanitizer,private config : ConfigServiceService,
        private router: Router, private activeRoute: ActivatedRoute, private ViewReportsData: ViewReportsData,
         private readonly route: ActivatedRoute, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService, 
         private toastr: ToastrService, private location :Location ,private locationStr: LocationStrategy,) {
 // preventing back button in browser   
//  history.pushState(null, null, window.location.href);  
//  this.locationStr.onPopState(() => {
//    history.pushState(null, null, window.location.href);
//  });
    }
    sanitizeImageUrl(imageUrl: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
    }
    // showGallery(index: number) {
    //     let array = [];
    //     for(let item of this.damageImage){
    //         array.push({path: 'https://preprodtestapi.cclp.aero/cclpApis/uploads/' + item})
    //     };
    //     console.log(array, index);
    //     let prop = {
    //         images: array,
    //         index
    //     };
    //     this.gallery.load(prop);
    //     console.log(prop);
        
    // }
    
    
    ngOnInit() {
        this.reportsTab = JSON.parse(localStorage.getItem('reportsTab'));
        this.selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
        // localStorage.setItem('selectedreports', JSON.stringify("Incoming"));
        this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.reportStatus = JSON.parse(localStorage.getItem('reportStatus'));
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        window.scroll(0, 0);
        this.route.queryParams.filter(params => params.from)
            .subscribe(params => {
                console.log('***********', params);
                this.NavigatebackTo = params.from
            })

        this.route.queryParams.subscribe(
            params => {
                this.subscribedParam = params.type;
                this.typeOfCard = this.subscribedParam;
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });


        // this.activeRoute.queryParams.subscribe(params => {
        //     ;
        //     console.log('reportdetailsParams',params)
        //  });



        this.viewdetails = JSON.parse(localStorage.getItem('isFromreports'));
        this.isFromreportsSidereports = JSON.parse(localStorage.getItem('isFromreportsSidereports'));
        if (this.isFromreportsSidereports !== undefined && this.isFromreportsSidereports !== null) {
            if (this.isFromreportsSidereports.sidebarReports === "sidebarReports") {
                this.showModifiedReports = true;
            }
        }

        if (this.viewdetails === 'viewreports') {
            ;
            this.viewreports = true;
            // this.eachReport = JSON.parse(localStorage.getItem('eachReport'));
            this.SelectedawbNumber = JSON.parse(localStorage.getItem('awbNumber'));
            this.SelectedawbNumber_reportId = JSON.parse(localStorage.getItem('awbNumber_reportId'));
            
            let obj = {
                "loginUserId": this.LoginDetails.userId,
                "awbNumber": this.SelectedawbNumber,
                "orgIdNumber": this.LoginDetails.orgId,
                "reportId" :  this.SelectedawbNumber_reportId,
                "flightDetails": this.flightDetails

            }
            this.spinner.show();
            this.superAdminservice.viewDetails(obj).subscribe(data => {
                ;
                if (data.responseCode == '1') {
                    console.log(data)
                    this.eachReport = data.damageReport;
                    this.flightDetails = this.eachReport.flightDetails;
                    let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.eachReport)   
                    for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                        if(obj_Keys[i] !=="damageImage"){
                            if(this.eachReport[obj_Keys[i]] === '' || this.eachReport[obj_Keys[i]] === null){
                                this.eachReport[obj_Keys[i]] = '--';
                              }
                        }
                      
                    }


                    this.modifiedReports = data.modifiedReports;
                    this.Images = this.eachReport.damageImage;
                    if (this.Images !== null && this.Images !== undefined) {
                        this.damageImage = this.Images.split(",");
                    }
                    this.emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
                    if ((this.selectedreports === 'Incoming')|| (this.emailStatus === 'AS') || (this.emailStatus === 'Extrenal email sent to Airlines') || (this.emailStatus === 'Internal email sent') || (this.emailStatus === 'Sent to airline') || (this.emailStatus === 'Email sent')) {
                        this.fn_toGetEmails();
                    }
                    this.spinner.hide();
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                } 
                 else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });

                    this.spinner.hide();
                }
            }, error => {
                this.spinner.hide();
            });
            localStorage.removeItem("isFromreportsSidereports");
        } else if (this.viewdetails === 'recentreports') {
            ;
            this.recentreports = true;
            this.awbnoObj = JSON.parse(localStorage.getItem('awbnoObj'));
            this.SelectedawbNumber = this.awbnoObj.masterAwbNo;
            this.SelectedawbNumber_reportId = this.awbnoObj.masterAwbNo;

            let obj = {
                "loginUserId": this.LoginDetails.userId,
                "awbNumber": this.awbnoObj.masterAwbNo,
                "orgIdNumber": this.LoginDetails.orgId,
                "reportId": this.awbnoObj.reportId,
                "flightDetails": this.flightDetails
            }
            this.spinner.show();
            this.superAdminservice.viewDetails(obj).subscribe(data => {
                ;
                //     });
                // this.superAdminservice.viewDetailReport(this.awbnoObj.masterAwbNo).subscribe(
                //     data => {
                ;
                console.log(data);
                this.spinner.hide();
                if (data.responseCode == '1') {
                    //  this.reportsList = data.reportsList;
                    this.eachReport = data.damageReport;
                    this.flightDetails = this.eachReport.flightDetails;
                    let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.eachReport)   
                    for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                        if(obj_Keys[i] !=="damageImage"){
                            if(this.eachReport[obj_Keys[i]] === '' || this.eachReport[obj_Keys[i]] === null){
                                this.eachReport[obj_Keys[i]] = '--';
                              }
                        }
                      
                    }

                    this.modifiedReports = data.modifiedReports;
                    this.Images = this.eachReport.damageImage;
                    if (this.Images !== null && this.Images !== undefined) {
                        this.damageImage = this.Images.split(",");
                    }

                    this.reportStatus = data.damageReport.reportStatus;
                    this.flightDetails = this.eachReport.flightDetails;

                    // this.eachReport = data;
                    // this.Images = this.eachReport.damageImage;
                    // this.damageImage = this.Images.split(",");

                    //   for (let index = 0; index < this.eachReport.length; index++) {
                    if (this.eachReport.emailStatus === 'N' && this.eachReport.reportScheduleTime != null) {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'external') {
                        this.eachReport.emailStatus = 'Extrenal email sent to Airlines';
                        this.emailStatus = 'Extrenal email sent to Airlines';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'internal') {
                        this.eachReport.emailStatus = 'Internal email sent';
                    } else if (this.eachReport.emailStatus === 'S') {
                        this.eachReport.emailStatus = 'Sent to airline';
                    }
                    else if (this.eachReport.emailStatus === 'ST') {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'H') {
                        this.eachReport.emailStatus = 'Hold';
                    }
                    else if (this.eachReport.emailStatus === 'C') {
                        this.eachReport.emailStatus = 'Cancelled';
                    } else if (this.eachReport.emailStatus === 'N' && this.eachReport.reportScheduleTime === null) {
                        this.eachReport.emailStatus = 'Authorization Failed';
                    } else if (this.eachReport.emailStatus === 'E') {
                        this.eachReport.emailStatus = 'Emails are not found';
                    }


                    // }
                    this.emailStatus = this.eachReport.emailStatus;
                    localStorage.setItem("emailStatus", JSON.stringify(this.emailStatus));

                    if ((this.selectedreports === 'Incoming')|| (this.emailStatus === 'AS')||(this.eachReport.emailStatus === 'Extrenal email sent to Airlines') || (this.eachReport.emailStatus === 'Internal email sent') || (this.emailStatus === 'Sent to airline') || (this.emailStatus === 'Email sent')) {
                        this.fn_toGetEmails();
                    }
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
            },
                error => {
                    this.spinner.hide();
                }
            )
        }
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.route.queryParams.subscribe(
            params => {
                this.subscribedParam = params.orgKey ;
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.route.queryParams.subscribe(
            params => {
                this.subscribedParam = params.type;
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.webapi =this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        // this.damageImage = [];

        // if (this.viewdetails === 'viewreports') {
        //     ;
        //     let emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
        //     // if (this.eachReport.reportStatus === 'external') {}
        //     if ((emailStatus === 'Extrenal email sent to Airlines') || (emailStatus === 'Internal email sent')) {
        //         this.fn_toGetEmails();
        //     }
        // }


        // console.log('eachReport', 'color: #e50000', this.eachReport);
        // ;

    }



    fn_To_Extract_Obj_Keys(jsonObject :JSON):Array<string>{  //---> To get object keys
        let obj_Keys = Object.keys(jsonObject);
        return obj_Keys ;
      }
    //-----------------service to get External Emails card data-----------//
    fn_toGetEmails() :void {
        this.spinner.show();
        let emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
        if (emailStatus === 'FinalTimer') {
            if (this.comapanydetails != null && this.comapanydetails != undefined) {
                if (this.comapanydetails.reportAcess === 'N' && this.LoginDetails.orgType === 'SA') {
                    this.statusOfTheReport = 'Unauthorised user';
                }

            } else if (this.LoginDetails.orgType === 'SA') {
                this.statusOfTheReport = 'Scheduled'
            }

        } else if (emailStatus === 'FinalTimer' && (this.LoginDetails.orgType === 'H' && this.comapanydetails.reportAcess === 'N')) {
            this.statusOfTheReport = 'Unauthorised user';
        } else if (emailStatus === 'Authorization Failed' && (this.LoginDetails.orgType === 'H' && this.comapanydetails.reportAcess === 'Y')) {
            this.statusOfTheReport = 'Scheduled';
        } else if (emailStatus === 'Extrenal email sent to Airlines') {
            this.statusOfTheReport = 'Sent';
        } else if (emailStatus === 'Internal email sent') {
            this.statusOfTheReport = 'Sent';
        }
        else if (emailStatus === 'Hold') {
            this.statusOfTheReport = 'Scheduled';
        }
        else if (emailStatus === 'Cancelled') {
            this.statusOfTheReport = 'Cancelled';
        } else if (emailStatus === 'Authorization Failed') {
            this.statusOfTheReport = 'Scheduled';
        } else if (emailStatus === 'Emails are not found') {
            this.statusOfTheReport = 'Emails not found';
        }

        
        // if (this.eachReport.emailStatus === 'FinalTimer') {
        //     if (this.comapanydetails != null && this.comapanydetails != undefined) {
        //         if (this.comapanydetails.reportAcess === 'N' && this.LoginDetails.orgType === 'SA') {
        //             this.statusOfTheReport = 'Unauthorised user';
        //         }

        //     } else if (this.LoginDetails.orgType === 'SA') {
        //         this.statusOfTheReport = 'Scheduled'
        //     }

        // } else if (this.eachReport.emailStatus === 'FinalTimer' && (this.LoginDetails.orgType === 'H' && this.comapanydetails.reportAcess === 'N')) {
        //     this.statusOfTheReport = 'Unauthorised user';
        // } else if (this.eachReport.emailStatus === 'Authorization Failed' && (this.LoginDetails.orgType === 'H' && this.comapanydetails.reportAcess === 'Y')) {
        //     this.statusOfTheReport = 'Scheduled';
        // } else if (this.eachReport.emailStatus === 'Extrenal email sent to Airlines') {
        //     this.statusOfTheReport = 'Sent';
        // }
        // else if (this.eachReport.emailStatus === 'Hold') {
        //     this.statusOfTheReport = 'Scheduled';
        // }
        // else if (this.eachReport.emailStatus === 'Cancelled') {
        //     this.statusOfTheReport = 'Cancelled';
        // } else if (this.eachReport.emailStatus === 'Authorization Failed') {
        //     this.statusOfTheReport = 'Scheduled';
        // } else if (this.eachReport.emailStatus === 'Emails are not found') {
        //     this.statusOfTheReport = 'Emails not found';
        // }


        //  if (this.eachReport.emailStatus === 'FinalTimer') {
        //                    this.statusOfTheReport = 'Scheduled';
        //                 } else if (this.eachReport.emailStatus === 'Extrenal email sent to Airlines' ) {
        //                      this.statusOfTheReport = 'Sent';
        //                 }
        //                 else if (this.eachReport.emailStatus === 'FinalTimer') {
        //                     this.statusOfTheReport = 'Scheduled';
        //                 } else if (this.eachReport.emailStatus === 'Hold') {
        //                      this.statusOfTheReport = 'Scheduled';
        //                 }
        //                 else if (this.eachReport.emailStatus === 'Cancelled') {
        //                     this.statusOfTheReport = 'Cancelled';
        //                 } else if (this.eachReport.emailStatus === 'Authorization Failed' ) {
        //                      this.statusOfTheReport = 'Unauthorized User';
        //                 } else if (this.eachReport.emailStatus === 'Emails are not found') {
        //                      this.statusOfTheReport = 'Emails not found';
        //                 }
        this.superAdminservice.getDetailedReportsEmails(this.SelectedawbNumber,this.SelectedawbNumber_reportId, this.flightDetails).subscribe(data => {
            ;
            
            if (data.responseCode === 1) {
                ;
                console.log('....=======> '+JSON.stringify(data));
                this.STATIONS_WITH_EMAILSARRAY = [];
                // if(data.EmailData[0].emails !== ""){
                    
                    this.STATIONS_WITH_EMAILSARRAY = data.EmailData;
                    this.responseData = data;
                    console.log('=========> '+JSON.stringify(this.STATIONS_WITH_EMAILSARRAY));
                    this.spinner.hide();
                // }
                
                
                // if (this.STATIONS_WITH_EMAILSARRAY.length != 0) {
                //     for (let i = 0; i < this.STATIONS_WITH_EMAILSARRAY.length; i++) {
                //         let val = [];
                //         val = this.STATIONS_WITH_EMAILSARRAY[i].emails.split(',');
                //         this.STATIONS_WITH_EMAILSARRAY[i].email = val;
                //         console.log(this.STATIONS_WITH_EMAILSARRAY[i].emails);
                //     }
                // }
                let DATE_TIME_ARRAY;
                if (data.modifiedTime != '' && data.modifiedTime != null && data.modifiedTime != undefined) {
                    if (data.modifiedTime === "0000-00-00 00:00:00") {
                        DATE_TIME_ARRAY = data.createdTime.split(' ');
                        this.sentDate = DATE_TIME_ARRAY[0];
                    } else {
                        DATE_TIME_ARRAY = data.modifiedTime.split(' ');
                        this.sentDate = DATE_TIME_ARRAY[0];
                    }
                }





                // if (this.eachReport.reportStatus === 'C') {
                //     this.statusOfTheReport = 'Cancelled';
                // } else if (this.eachReport.reportStatus === 'E') {
                //     this.statusOfTheReport = 'Emails are not found';
                // }
                // else if (this.eachReport.reportStatus === 'S' && this.eachReport.reportStatus === 'external') {
                //     this.statusOfTheReport = 'Sent';
                // } else if (this.eachReport.reportStatus === 'S' && this.eachReport.isinternal != 'external') {
                //     this.statusOfTheReport = 'Sent';
                // } else if ((this.eachReport.reportStatus === 'N') && (this.eachReport.reportScheduleTime != null) && (this.eachReport.report_Acess === 'Y')) {
                //     this.statusOfTheReport = 'Scheduled';
                // }
                // else if ((this.eachReport.reportStatus === 'H') && (this.eachReport.report_Acess === 'Y')) {
                //     this.statusOfTheReport = 'Scheduled';
                // } else if ((this.eachReport.reportStatus === 'ST') && (this.eachReport.report_Acess === 'Y')) {
                //     this.statusOfTheReport = 'Scheduled';
                // } else if ((this.eachReport.reportStatus === 'N') && (this.eachReport.reportScheduleTime != null) && (this.eachReport.report_Acess === 'N') && (this.eachReport.admin_Auth === 'I') && (this.eachReport.super_Admin_Auth === 'I')) {
                //     this.statusOfTheReport = 'Unauthorized user';
                // } else if ((this.eachReport.reportStatus === 'N') && (this.eachReport.reportScheduleTime != null) && (this.eachReport.report_Acess === 'N') && (this.eachReport.admin_Auth === 'A' || this.eachReport.super_Admin_Auth === 'A')) {
                //     this.statusOfTheReport = 'Submitted';
                // } else if ((this.eachReport.reportStatus === 'N') && (this.eachReport.reportScheduleTime == null) && (this.eachReport.report_Acess === 'Y') && (this.eachReport.admin_Auth === 'A' || this.eachReport.super_Admin_Auth === 'A')) {
                //     this.statusOfTheReport = 'Scheduled';
                // } else if ((this.eachReport.reportStatus === 'N') && (this.eachReport.reportScheduleTime === null) && (this.eachReport.report_Acess === 'N') && (this.eachReport.admin_Auth === 'I' && this.eachReport.super_Admin_Auth === 'I')) {
                //     this.statusOfTheReport = 'Unauthorised User';
                // }
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.spinner.hide();
                this.toastr.info("Your session is expired, please login again");
            } 
             else if (data.responseCode == 0) {
                this.spinner.hide();
                this.STATIONS_WITH_EMAILSARRAY = [];
            }

        }, error => {
            this.spinner.hide();
        });
    }


    //-----------------End service-----------//

    navigateToReports() {
        ;
        
        this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        //   this.isfrom = "viewDetails"
        if(this.comapanydetails === null){
            this.comapanydetails =this.LoginDetails;
        }
        if (this.ViewReportsData.isfromHandlerCsaDashboard === 'yes') {
            ;
            this.router.navigate(["/csa-dashboard"]);
            this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
        } else if (this.ViewReportsData.isfromairlineCsaDashboard === 'yes') {
            this.router.navigate(["/csa-airline-dashboard"]);
            this.ViewReportsData.isfromairlineCsaDashboard = 'no'
        }
        // else if (this.ViewReportsData.isfromHandler_Admin_Dashboard === 'yes') {
        //     this.router.navigate(["/admin-handler-dashboard"]);
        //     this.ViewReportsData.isfromHandler_Admin_Dashboard = 'no'
        // }
        // else if (this.ViewReportsData.isfromAirline_Admin_Dashboard === 'yes') {
        //     this.router.navigate(["/admin-airline-dashboard"]);
        //     this.ViewReportsData.isfromAirline_Admin_Dashboard = 'no'
        // }

        else if (this.ViewReportsData.isfromViewReports === 'yes') {
            ;
            this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            if (this.LoginDetails.userType === 'SA') {
                this.router.navigate(["/viewusers"],{ queryParams: { orgKey: this.comapanydetails.orgId } });
                this.ViewReportsData.isfromViewReports = 'no'
                this.ViewReportsData.isFrmViewDetails = "yes"
            } else {
                if (this.subscribedParam === null) {
                    this.subscribedParam = ''
                }
                this.router.navigate(["/viewusers"], { queryParams: { orgKey: this.comapanydetails.orgId,type : this.subscribedParam}});
                this.ViewReportsData.isfromViewReports = 'no'
                this.ViewReportsData.isFrmViewDetails = "yes"
            }
        } else if (this.ViewReportsData.isfromusersDashboard === 'yes') {
            this.ViewReportsData.isfromusersDashboard = 'no'
            this.router.navigate(["/user-dashboard"]);
        }
        // else if (this.viewdetails === 'viewreports') {
        //     this.ViewReportsData.isfromViewReports = 'yes'
        // }
        else if (this.NavigatebackTo === 'sideBarReports') {
            // this.ViewReportsData.isfromViewReports = 'no'
            this.router.navigate(["/viewreports"],{ queryParams: {orgKey :this.LoginDetails.orgId } } );
        }
        else if (this.viewdetails === 'recentreports') {
            this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            if (this.LoginDetails.userType === "SA") {
                this.router.navigate(["/sa-dashboard"]);
            } else if (this.LoginDetails.userType === "S") {
                this.router.navigate(["/csa-dashboard"]);
            } else if (this.LoginDetails.userType === "E") {
                this.router.navigate(["/user-dashboard"]);
            }
        }else{
              if (this.ViewReportsData.isfromHandler_Admin_Dashboard === 'yes') {
            this.router.navigate(["/admin-handler-dashboard"]);
            this.ViewReportsData.isfromHandler_Admin_Dashboard = 'no'
        }
        else if (this.ViewReportsData.isfromAirline_Admin_Dashboard === 'yes') {
            this.router.navigate(["/admin-airline-dashboard"]);
            this.ViewReportsData.isfromAirline_Admin_Dashboard = 'no'
        }else{
            this.location.back();
        }
           
        }
    }

    public highlightRow(item) {
        this.selectedName = item.updatedObject;
    }
    ViewSelectedData(SelectedRowData) {
        this.rowSelected = true;
        this.selectedName = SelectedRowData.id;
        console.log('SelectedRowData', SelectedRowData);
        if (SelectedRowData.updatedObject != "Y") {
            let reqObj = {
                "loginUserId": this.LoginDetails.userId,
                "id": SelectedRowData.id,
                "awbNumber": SelectedRowData.masterAwbNumber,
                "flightDetails": this.flightDetails
            }
            this.spinner.show();
            this.superAdminservice.modifiedReportsDetails(reqObj).subscribe(ResponseData => {
                console.log(ResponseData);
                if (ResponseData.responseCode === 1 && ResponseData.modifiedReports !== null) {
                    this.eachReport = ResponseData.modifiedReports;
                    console.log(this.STATIONS_WITH_EMAILSARRAY);
                    console.log(this.eachReport.EmailData);
                    this.STATIONS_WITH_EMAILSARRAY = [];
                    this.STATIONS_WITH_EMAILSARRAY = this.eachReport.EmailData;
                    let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.eachReport)   
                    for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                        if(obj_Keys[i] !=="damageImage"){
                            if(this.eachReport[obj_Keys[i]] === '' || this.eachReport[obj_Keys[i]] === null){
                                this.eachReport[obj_Keys[i]] = '--';
                              }
                        }
                      
                    }

                    this.Images = this.eachReport.damageImage;
                    if (this.Images !== null && this.Images !== undefined) {
                        this.damageImage = this.Images.split(",");
                    }

                    // if (this.STATIONS_WITH_EMAILSARRAY.length != 0) {
                    //     for (let i = 0; i < this.STATIONS_WITH_EMAILSARRAY.length; i++) {
                    //         let val = [];
                    //         if(this.STATIONS_WITH_EMAILSARRAY[i].emails !== null && this.STATIONS_WITH_EMAILSARRAY[i].emails !== undefined){
                    //             val = this.STATIONS_WITH_EMAILSARRAY[i].emails.split(',');
                    //             this.STATIONS_WITH_EMAILSARRAY[i].emails = val.join(',  ')
                    //         }
                            
                    //     }
                    // }

                    this.reportStatus = this.eachReport.reportStatus;
                    // if (this.viewdetails === 'viewreports') {
                    ;
                    // this.emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
                    // // if (this.eachReport.reportStatus === 'external') {}
                    // if ((this.emailStatus === 'Extrenal email sent to Airlines') || (this.emailStatus === 'Internal email sent')) {
                    //     this.fn_toGetEmails();
                    // }
                    if (this.eachReport.emailStatus === 'N' && this.eachReport.schedulingTime != null) {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'external') {
                        this.eachReport.emailStatus = 'Extrenal email sent to Airlines';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'internal') {
                        this.eachReport.emailStatus = 'Internal email sent';
                    } else if (this.eachReport.emailStatus === 'S') {
                        this.eachReport.emailStatus = 'Sent to airline';
                    }
                    else if (this.eachReport.emailStatus === 'E') {
                        this.eachReport.emailStatus = 'Emails are not found';
                    }
                    else if (this.eachReport.emailStatus === 'ST') {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'H') {
                        this.eachReport.emailStatus = 'Hold';
                    }
                    else if (this.eachReport.emailStatus === 'C') {
                        this.eachReport.emailStatus = 'Cancelled';
                    } else if (this.eachReport.emailStatus === 'N' && this.eachReport.schedulingTime === null) {
                        this.eachReport.emailStatus = 'Authorization Failed';
                    }
                    this.emailStatus = this.eachReport.emailStatus;
                    // JSON.parse(localStorage.getItem('emailStatus'));

                    localStorage.setItem("emailStatus", JSON.stringify(this.emailStatus));
                    // }
                    this.spinner.hide();
                }else if(ResponseData.responseCode === 0 && ResponseData.message === 'Unauthorized User'){
                    this.spinner.hide();
                    this.toastr.info("Your session is expired, please login again");
                } 
                 else {
                    this.toastr.error(ResponseData.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }

            }, error => {
                console.log(error);
                this.spinner.hide();
            })
        } else {
            let obj = {
                "loginUserId": this.LoginDetails.userId,
                "awbNumber": this.SelectedawbNumber,
                "orgIdNumber": this.LoginDetails.orgId,
                "reportId" : this.SelectedawbNumber_reportId,
                "flightDetails": this.flightDetails
            }
            this.spinner.show();
            this.superAdminservice.viewDetails(obj).subscribe(data => {
                ;
                if (data.responseCode == '1') {
                    console.log(data)
                    this.eachReport = data.damageReport;
                    this.flightDetails = this.eachReport.flightDetails;
                    let obj_Keys  =  this.fn_To_Extract_Obj_Keys(this.eachReport)   
                    for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
                        if(obj_Keys[i] !=="damageImage"){
                            if(this.eachReport[obj_Keys[i]] === '' || this.eachReport[obj_Keys[i]] === null){
                                this.eachReport[obj_Keys[i]] = '--';
                              }
                        }
                      
                    }

                    this.modifiedReports = data.modifiedReports;
                    this.Images = this.eachReport.damageImage;
                    if (this.Images !== null && this.Images !== undefined) {
                        this.damageImage = this.Images.split(",");
                    }

                    // if (this.viewdetails === 'viewreports') {
                    ;
                    // this.emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
                    // if (this.eachReport.reportStatus === 'external') {}
                    if (this.eachReport.emailStatus === 'N' && this.eachReport.schedulingTime != null) {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'external') {
                        this.eachReport.emailStatus = 'Extrenal email sent to Airlines';
                    } else if (this.eachReport.emailStatus === 'S' && this.eachReport.reportStatus === 'internal') {
                        this.eachReport.emailStatus = 'Internal email sent';
                    } else if (this.eachReport.emailStatus === 'S') {
                        this.eachReport.emailStatus = 'Sent to airline';
                    }
                    else if (this.eachReport.emailStatus === 'E') {
                        this.eachReport.emailStatus = 'Emails are not found';
                    }
                    else if (this.eachReport.emailStatus === 'ST') {
                        this.eachReport.emailStatus = 'FinalTimer';
                    } else if (this.eachReport.emailStatus === 'H') {
                        this.eachReport.emailStatus = 'Hold';
                    }
                    else if (this.eachReport.emailStatus === 'C') {
                        this.eachReport.emailStatus = 'Cancelled';
                    } else if (this.eachReport.emailStatus === 'N' && this.eachReport.schedulingTime === null) {
                        this.eachReport.emailStatus = 'Authorization Failed';
                    }
                    this.emailStatus = this.eachReport.emailStatus;
                    // JSON.parse(localStorage.getItem('emailStatus'));
                    ;
                    localStorage.setItem("emailStatus", JSON.stringify(this.emailStatus));
                    if ((this.selectedreports === 'Incoming')||(this.emailStatus === 'AS') ||(this.emailStatus === 'Extrenal email sent to Airlines') || (this.emailStatus === 'Internal email sent') || (this.emailStatus === 'Sent to airline') || (this.emailStatus === 'Email sent')) {
                        this.fn_toGetEmails();
                    }
                    // }
                    this.spinner.hide();
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                } 
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }


            }, error => {
                this.spinner.hide();
            })

        }

    }

    ngOnDestroy() {
        ;
        //   this.ViewReportsData.isFrmViewDetails = 'no';
        this.ViewReportsData.isfromSideBarViewReports = 'no';
        this.ViewReportsData.isfromairlineCsaDashboard = 'no';
        this.ViewReportsData.isfromHandlerCsaDashboard = 'no';
        this.ViewReportsData.isfromViewReports = 'no'
        this.ViewReportsData.isFromViewUsersStations = 'no';
        this.ViewReportsData.isfromusersDashboard = 'no';
        this.ViewReportsData.isfromSidebarStations = 'no';
        this.ViewReportsData.isFromViewReportsStations = 'no';
    }
}