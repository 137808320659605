import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';
import { createUldModel ,uldReportEmailsModel } from '../../uld/create-uld/createUldInputObj';
@Injectable({
  providedIn: 'root'
})
export class UldModuleService {
  webAPI:string ; 
  ULD_Url:string ; 
  constructor(private http:HttpClient,config : ConfigServiceService ) {
    this.webAPI = config.getConfig().URL
    this.ULD_Url = config.getConfig().ULD_Url
   }


   createUldReport(inputObj  : createUldModel){
    return this.http.post<any>(this.webAPI + "ulddamagereports/createUldDamageReport.php/createUldDamageReport", inputObj)
}
   update_UldReport(inputObj  : createUldModel){
    return this.http.post<any>(this.webAPI + "ulddamagereports/editUldReport.php/editUldReport", inputObj)
}
   send_UldReport_Emails(inputObj  : uldReportEmailsModel){
   
    return this.http.post<any>(this.webAPI + "ulddamagereports/sendUldReportEmailPdf.php/sendUldReportEmailPdf", inputObj)
}
   send_Update_UldReport_Emails(inputObj  : uldReportEmailsModel){
   
    return this.http.post<any>(this.webAPI + "ulddamagereports/editUldReportEmailPdf.php/editUldReportEmailPdf", inputObj)
}
   getHandlingstage_ProcessData(){
    return this.http.get<any>(this.webAPI + "ulddamagereports/getHandlingProcessData.php/getHandlingProcessData") ;
}
   get_UldReport_DetailedView(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/viewUldDetailedReport.php/viewUldDetailedReport", inputObj) ;
}
get_IncomingUldReport_DetailedView(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/viewUldIncomingReport.php/viewUldIncomingReport ", inputObj) ;
}
   send_UldReport_ToAirlines(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/sendUldToAirline.php/sendUldToAirline", inputObj) ;
}
   get_TypeCode(){
    return this.http.post<any>(this.ULD_Url + "truckingUldApis/uldTypeCode.php/getTypeCodes", {}) ;
}
   get_ModifiedReport_View(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/viewUldModifiedReport.php/viewUldModifiedReport", inputObj) ;
}
  manualArchive_Report(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/setUldReportArchive.php/setUldReportArchive", inputObj) ;
}
  delete_Report(inputObj){
    return this.http.post<any>(this.webAPI + "ulddamagereports/deleteUldReport.php/deleteUldReport", inputObj) ;
}
  get_Report_AirlineEmails(reportId){
    return this.http.get<any>(this.webAPI + "ulddamagereports/getUldAirlineEmails.php/getUldAirlineEmails?reportId="+reportId) ;
}

refresh(){
  return this.http.get<any>(this.webAPI + "ulddamagereports/airlineUldRefresh.php/airlineUldRefresh") ;
}


  

}
