import { Component, OnInit } from '@angular/core';
import { SettingsServicesService } from '../../services/Settings/settings-services.service'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsModel } from './settingModel';
import { Router, NavigationStart } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import * as moment from 'moment-timezone';
import { globalConstants } from 'src/environments/globalConstants';
declare var jQuery: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settingsModelRefObj = new SettingsModel();
  public static loginUserDetails;
  public static isDefaultSet: boolean;
  public isScheduleReadOnly: boolean;

  scheduleVal: any;
  public reportTypeVal: any;
  isAutosendEmailEnable: any;
  servcScheduleval: any;
  isScheduleReadOnly_Internal: boolean;
  scheduleVal_Internal: any;
  isAutosendEmailEnable_Internal: boolean;
  isAutosendEmailEnable_Rootcause: boolean;
  servcScheduleval_internal: any;

  scheduleTimeArray = [
    {
      time: '15Min',
      value: '00:15:00'
    },{
      time: '30Min',
      value: '00:30:00'
    },{
      time: '1Hr',
      value: '01:00:00'
    },{
      time: '2Hr',
      value: '02:00:00'
    },{
      time: '3Hr',
      value: '03:00:00'
    },{
      time: '4Hr',
      value: '04:00:00'
    },{
      time: '5Hr',
      value: '05:00:00'
    },{
      time: '6Hr',
      value: '06:00:00'
    },{
      time: '7Hr',
      value: '07:00:00'
    },{
      time: '8Hr',
      value: '08:00:00'
    },{
      time: '9Hr',
      value: '09:00:00'
    },{
      time: '10Hr',
      value: '10:00:00'
    },{
      time: '11Hr',
      value: '11:00:00'
    },{
      time: '12Hr',
      value: '12:00:00'
    },{
      time: '13Hr',
      value: '13:00:00'
    },{
      time: '14Hr',
      value: '14:00:00'
    },{
      time: '15Hr',
      value: '15:00:00'
    },{
      time: '16Hr',
      value: '16:00:00'
    },{
      time: '17Hr',
      value: '17:00:00'
    },{
      time: '18Hr',
      value: '18:00:00'
    },{
      time: '19Hr',
      value: '19:00:00'
    },{
      time: '20Hr',
      value: '20:00:00'
    },{
      time: '21Hr',
      value: '21:00:00'
    },{
      time: '22Hr',
      value: '22:00:00'
    },{
      time: '23Hr',
      value: '23:00:00'
    },{
      time: '24Hr',
      value: '24:00:00'
    },
  ]

  constructor(private router: Router, private settingsServicesRefObj: SettingsServicesService,
     private spinner: NgxSpinnerService, public toast: ToastrService,private locationStr: LocationStrategy,
      ) { 
        // preventing back button in browser   
        history.pushState(null, null, window.location.href);  
        this.locationStr.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });

      }
  private routeSub: any;
  ngOnInit() {
    this.scheduleVal = '00:00:00';
    this.reportTypeVal = 'Select Report Type';
    this.scheduleVal_Internal = '00:00:00';
    SettingsComponent.loginUserDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.getCurrent_Status(SettingsComponent.loginUserDetails.orgId);

  }

  // external on change toggle
  onChange_Auto_Send_Email(event, autosendMailVal, type) {
    ;
    localStorage.setItem("autosend_ext_int", JSON.stringify('external'));
    this.settingsModelRefObj.orgId = SettingsComponent.loginUserDetails.orgId;
    if (type === 'AUTO') {
      this.settingsModelRefObj.orgTime = '00';
      event.target.checked === true ?
        (this.isScheduleReadOnly = true, this.settingsModelRefObj.autosendMail = 'Y', this.scheduleVal = '00', this.isAutosendEmailEnable = true, SettingsComponent.isDefaultSet = false, jQuery('#AutoSend_On_confirmation').modal({ backdrop: 'static', keyboard: false })) :
        (this.isScheduleReadOnly = false, this.settingsModelRefObj.autosendMail = 'N', this.scheduleVal = '00', this.isAutosendEmailEnable = false, this.settingsModelRefObj.orgTime = this.scheduleVal, SettingsComponent.isDefaultSet = true, jQuery('#AutoSend_Off_confirmationpopup').modal({ backdrop: 'static', keyboard: false }));
    } else if (type === 'SCHEDULE') {
      this.settingsModelRefObj.orgTime = event.target.value;
      this.settingsModelRefObj.autosendMail = 'N';
      SettingsComponent.isDefaultSet = false;
      jQuery('#scheduleTime_confirmation').modal({ backdrop: 'static', keyboard: false })
    }
  }

  // internal on change toggle
  onChange_Auto_Send_Internal_Email(event, autosendMailVal, type) {
    ;
    localStorage.setItem("autosend_ext_int", JSON.stringify('internal'));
    this.settingsModelRefObj.orgId = SettingsComponent.loginUserDetails.orgId;
    if (type === 'AUTO') {
      this.settingsModelRefObj.orgTimeInternal = '00';
      event.target.checked === true ?
        (this.isScheduleReadOnly_Internal = true, this.settingsModelRefObj.autosendMailInternal = 'Y', this.scheduleVal_Internal = '00', this.isAutosendEmailEnable_Internal = true, SettingsComponent.isDefaultSet = false, jQuery('#AutoSend_On_confirmation').modal({ backdrop: 'static', keyboard: false })) :
        (this.isScheduleReadOnly_Internal = false, this.settingsModelRefObj.autosendMailInternal = 'N', this.scheduleVal_Internal = '00', this.isAutosendEmailEnable_Internal = false, this.settingsModelRefObj.orgTimeInternal = this.scheduleVal_Internal, SettingsComponent.isDefaultSet = true, jQuery('#AutoSend_Off_confirmationpopup').modal({ backdrop: 'static', keyboard: false }));
    } else if (type === 'SCHEDULE') {
      this.settingsModelRefObj.orgTimeInternal = event.target.value;
      this.settingsModelRefObj.autosendMailInternal = 'N';
      SettingsComponent.isDefaultSet = false;
      jQuery('#scheduleTime_confirmation_internal').modal({ backdrop: 'static', keyboard: false });
    }
  }

  open_rootcause_auto_send_email(event) {
    this.isAutosendEmailEnable_Rootcause = event.target.checked;
    jQuery('#rootcause_AutoSend_On_confirmation').modal({ backdrop: 'static', keyboard: false })
  }
  dismiss_rootcause_auto_send_email() {
    this.isAutosendEmailEnable_Rootcause = !this.isAutosendEmailEnable_Rootcause;
    jQuery('#rootcause_AutoSend_On_confirmation').modal('hide');
  }
  rootcause_auto_send_email() {
    let rootcause;
    if (this.isAutosendEmailEnable_Rootcause) {
      rootcause = 'Y'
    } else {
      rootcause = 'N'
    }
    let obj = {
      orgId: SettingsComponent.loginUserDetails.orgId,
      rootcauseStatus: rootcause,
      updatedOn: moment().format("YYYY-MM-DD"),
      loginUserId: SettingsComponent.loginUserDetails.userId
  }
  this.spinner.show();
    this.settingsServicesRefObj.rootcause_auto_send_emails(obj).subscribe(res => {
      console.log(obj);
      this.spinner.hide();
      jQuery('#rootcause_AutoSend_On_confirmation').modal('hide');
      if (res.responseCode === 1) {
        this.toast.success(res.message, '', { timeOut: 1200 });
      } else {
        this.toast.error(res.message, '', { timeOut: 1200 });
      }
    }, err => {
      this.spinner.hide();
      jQuery('#rootcause_AutoSend_On_confirmation').modal('hide');
      this.toast.error(err, '', { timeOut: 1200 });
    })
  }

  fn_toUpdateChanges() {
    ;
    if(  this.settingsModelRefObj.autosendMail === 'N'){
      this.servcScheduleval = this.scheduleVal ;
    }
    if(  this.settingsModelRefObj.autosendMailInternal === 'N'){
      this.servcScheduleval_internal = this.scheduleVal_Internal;
    }
    
    jQuery('#scheduleTime_confirmation').modal('hide');
    jQuery('#scheduleTime_confirmation_internal').modal('hide');
    jQuery('#AutoSend_On_confirmation').modal('hide');
    jQuery('#AutoSend_Off_confirmationpopup').modal('hide');
    let autosend_ext_int = JSON.parse(localStorage.getItem("autosend_ext_int"));
    if(autosend_ext_int === "external"){
      this.settingsModelRefObj.orgTimeInternal = '';
      this.settingsModelRefObj.autosendMailInternal = '';
    }else{
      this.settingsModelRefObj.orgTime = '';
      this.settingsModelRefObj.autosendMail = '';
      
    }
    this.spinner.show();
    this.settingsServicesRefObj.setAuto_Or_Schedule_EmailS(this.settingsModelRefObj).subscribe(data => {
      this.spinner.hide();
      ;
      if (data.responseCode === 1) {
        if (SettingsComponent.isDefaultSet === true) {
          // jQuery('#AutoSendOff_confirmation').modal({ backdrop: 'static', keyboard: false })
        } else {
          this.toast.success(data.message, '', { timeOut: 1500 });
        }
      } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
        this.toast.info("Your session is expired, please login again");
    } 
      else {
        this.toast.error(data.message, '', { timeOut: 1200 });
      }
    }, error => {
      this.spinner.hide();
    })
  }

  getCurrent_Status(orgId) {
    this.spinner.show();
    this.settingsServicesRefObj.getAuto_Or_Schedule_EmailS(orgId).subscribe(data => {
      ;
      this.spinner.hide();
      if (data.responseCode === 1) {
        if (data.autoSendRootcauseStatus === 'Y') {
          this.isAutosendEmailEnable_Rootcause = true;
        } else {
          this.isAutosendEmailEnable_Rootcause = false;
        }

        (data.autoSendEmailStatus === 'Y' && data.scheduleTime === "00:00:00") ? (this.isAutosendEmailEnable = true, this.isScheduleReadOnly = true) : this.isAutosendEmailEnable = false;
        let val ;
        // data.autoSendEmailStatus === 'N' ? (val = data.scheduleTime.split(":"), this.scheduleVal = val[0], this.servcScheduleval = val[0]) : "";
        data.autoSendEmailStatus === 'N' ? (val = data.scheduleTime, this.scheduleVal = val, this.servcScheduleval = val) : "";
      
        // internal status check
        (data.autoSendInternalStatus === 'Y' && data.scheduleTimeInternal === "00:00:00") ? (this.isAutosendEmailEnable_Internal = true, this.isScheduleReadOnly_Internal = true) : this.isAutosendEmailEnable_Internal = false;
        let val1 ;
        data.autoSendInternalStatus === 'N' ? (val1 = data.scheduleTimeInternal, this.scheduleVal_Internal = val1, this.servcScheduleval_internal = val1) : "";
      }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
        this.toast.info("Your session is expired, please login again");
    } 
       else {
        this.toast.error("Failed to get Data", '', { timeOut: 1200 })
      }
    }, error => {
      this.spinner.hide();
    });
  }


  uncheck_Autosend() {
    let autosend_ext_int = JSON.parse(localStorage.getItem("autosend_ext_int"));

    if(autosend_ext_int === "external"){
      this.isAutosendEmailEnable = false;
      this.scheduleVal = this.servcScheduleval;
      this.isScheduleReadOnly = false;
    }else{
      this.scheduleVal_Internal = this.servcScheduleval_internal;
      this.isAutosendEmailEnable_Internal = false
      this.isScheduleReadOnly_Internal = false;
    }
    
    jQuery('#AutoSend_On_confirmation').modal('hide');

    
  }

  check_Autosend_onSelect_NO() {
    let autosend_ext_int = JSON.parse(localStorage.getItem("autosend_ext_int"));
    if(autosend_ext_int === "external"){
      this.isAutosendEmailEnable = true;
      this.isScheduleReadOnly = true;
      this.scheduleVal ='00';
    }else{
      this.isAutosendEmailEnable_Internal = true
      this.scheduleVal_Internal = '00';
      this.isScheduleReadOnly_Internal = true;
    }
   
    jQuery('#AutoSend_Off_confirmationpopup').modal('hide');

   
  }

  unselect_ScheduleTime() {
    let autosend_ext_int = JSON.parse(localStorage.getItem("autosend_ext_int"));
    if(autosend_ext_int === "external"){
      this.scheduleVal = this.servcScheduleval;
    }else{
    this.scheduleVal_Internal = this.servcScheduleval_internal;
    }
    
    jQuery('#scheduleTime_confirmation').modal('hide');
    jQuery('#scheduleTime_confirmation_internal').modal('hide');
     
  }
 
}
