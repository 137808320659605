import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoginServicesService } from "../services/login/login-services.service";

@Component({
  selector: "app-home-dashboard",
  templateUrl: "./home-dashboard.component.html",
  styleUrls: ["./home-dashboard.component.css"]
})
export class HomeDashboardComponent implements OnInit {
  loginDetails: any;
  role: string;
  showCargoDamage: boolean;
  showdamgesTab: boolean;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private loginservice: LoginServicesService
  ) {}

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails.userType === "S") {
      this.role = "Company Super Admin";
    }
  }

  Logout() {
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    const obj = {
      orgId: this.loginDetails.orgId,
      userId: this.loginDetails.userId
    };
    this.loginservice.logOut(obj).subscribe(
      data => {
        this.spinner.hide();
        if (data.responseCode === 1) {
          localStorage.clear();
          this.toastr.success(data.message);
          this.router.navigateByUrl("/");
        }
        else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.warning("your session expired please login again");
          this.router.navigateByUrl("/");
        } else {
          this.toastr.error(data.message);
          // this.router.navigateByUrl("/login");
        }
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  // Gotoclaims(value) {
  //   localStorage.setItem("claimchart", JSON.stringify(value));
  //   localStorage.setItem("activation", JSON.stringify("1"));
  //   this.router.navigateByUrl("/dashboard/claim-count");
  // }
}
