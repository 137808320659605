import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CargoclaimDashboardRoutingModule } from './cargoclaim-dashboard-routing.module';
import { LoginComponent } from '../cargoClaimModule/login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


import { NumbersOnlyDirective } from './awbs/numbers-only.directive';
import { SecurityComponent } from './security/security.component';
import { InactivityTimerComponent } from '../inactivity-timer';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatCheckboxModule, MatRadioModule, MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatListModule, MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatSidenavModule, MatCardModule, MatExpansionModule, MatSelectModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DxSelectBoxModule, DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from '../app-routing.module';

import { AmazingTimePickerService } from 'amazing-time-picker';
import { CookieService } from 'ngx-cookie-service';
import { globalService } from './services/global.service';
import { NavbarCargoClaimComponent } from './layouts/navbar/navbarCargoClaim.component';
import { GDashboardComponent } from './g-dashboard/g-dashboard.component';
import { SidebarCargoClaimComponent } from './layouts/sidebar/sidebarCargoClaim.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { IrregularCountComponent } from './g-dashboard/irregular-count/irregular-count.component';
import { InvestigationCountComponent } from './g-dashboard/investigation-count/investigation-count.component';
import { ClaimCountComponent } from './g-dashboard/claim-count/claim-count.component';
import { AmountCountComponent } from './g-dashboard/amount-count/amount-count.component';
import { ViewstationsComponent } from './stations/viewstations/viewstations.component';
import { AddstationsComponent } from './stations/addstations/addstations.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { DamagereportsComponent } from './damagereports/damagereports.component';
import { EditstationsComponent } from './stations/editstations/editstations.component';
import { DownloaddataComponent } from './downloaddata/downloaddata.component';
import { ViewdetailsComponent } from './viewdetails/viewdetails.component';
import { DamageReportsComponent } from './g-dashboard/damage-reports/damage-reports.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { ClaimAspectsComponent } from './g-dashboard/claim-aspects/claim-aspects.component';
import { ServiceLevelComponent } from './g-dashboard/service-level/service-level.component';
import { ClaimActivityComponent } from './g-dashboard/claim-activity/claim-activity.component';
import { CompareCliamTypeComponent } from './g-dashboard/compare-cliam-type/compare-cliam-type.component';
import { CompareCliamAspectComponent } from './g-dashboard/compare-cliam-aspect/compare-cliam-aspect.component';
import { CompareRootCauseComponent } from './g-dashboard/compare-root-cause/compare-root-cause.component';
import { CompareServiceLevelComponent } from './g-dashboard/compare-service-level/compare-service-level.component';
import { CompareClaimActivityComponent } from './g-dashboard/compare-claim-activity/compare-claim-activity.component';
import { CompareFinancialComponent } from './g-dashboard/compare-financial/compare-financial.component';
import { AwbsComponent } from './awbs/awbs.component';
import { CharactersOnlyDirectiveDirective } from './characters-only-directive.directive';
import { AwbClaimsComponent } from './awb-claims/awb-claims.component';
import { SharedLayoutsModule } from '../layouts/shared-layouts/shared-layouts.module';
import { ViewUsersCargoClaimComponent } from '../cargoClaimModule/allUsers/view-users/view-users.component';
import { SetRolesCargoClaimComponent } from '../cargoClaimModule/allUsers/set-roles/set-roles.component';
import { EdituserCargoClaimComponent } from '../cargoClaimModule/allUsers/edituser/edituser.component';
import { CreateuserCargoClaimComponent } from '../cargoClaimModule/allUsers/createuser/createuser.component';
import { ResetPasswordCargoClaimComponent } from '../cargoClaimModule/allUsers/reset-password/reset-password.component';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    NumbersOnlyDirective,
    SecurityComponent,
    LoginComponent,
  

    ViewUsersCargoClaimComponent,
    GDashboardComponent,
    SidebarCargoClaimComponent,
    NavbarCargoClaimComponent,
    FooterComponent,
    IrregularCountComponent,
    InvestigationCountComponent,
    ClaimCountComponent,
    AmountCountComponent,
    ViewstationsComponent,
    AddstationsComponent,
    MyaccountComponent,
    DamagereportsComponent,
    EditstationsComponent,
    DownloaddataComponent,
    ViewdetailsComponent,
    DamageReportsComponent,
    HomeDashboardComponent,
    ClaimAspectsComponent,
    ServiceLevelComponent,
    ClaimActivityComponent,
    CompareCliamTypeComponent,
    CompareCliamAspectComponent,
    CompareRootCauseComponent,
    CompareServiceLevelComponent,
    CompareClaimActivityComponent,
    CompareFinancialComponent,
    AwbsComponent,
    CharactersOnlyDirectiveDirective,
    AwbClaimsComponent,
    CreateuserCargoClaimComponent,
    EdituserCargoClaimComponent,
    SetRolesCargoClaimComponent,
    ResetPasswordCargoClaimComponent
  ],

  imports: [
    SharedLayoutsModule,
    CommonModule,
    CargoclaimDashboardRoutingModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    ChartsModule,
    jqxGridModule,
    RouterModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    NgxSpinnerModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    ReactiveFormsModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    FormsModule,
    MatSelectModule,
    MatChipsModule
  ],
  providers:[
    MatDatepickerModule,
    DatePipe,
    AmazingTimePickerService,
    CookieService,
    globalService,
    NavbarCargoClaimComponent,
  ]
})
export class CargoclaimDashboardModule { }
