import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UldDamageRoutingModule } from './uld-damage-routing.module';
import { CreateUldComponent } from '../create-uld/create-uld.component';
import { DetailedUldComponent } from '../detailed-uld/detailed-uld.component';
import { ViewUldComponent } from '../view-uld/view-uld.component';
import { SharedLayoutsModule } from 'src/app/layouts/shared-layouts/shared-layouts.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatSelectModule, MatListModule, MatToolbarModule, MatSidenavModule, MatStepperModule, MatCardModule, MatExpansionModule, MatAutocompleteModule } from '@angular/material';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DxDataGridModule } from 'devextreme-angular';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    CreateUldComponent ,
 
  ],
  imports: [
    CommonModule,
    UldDamageRoutingModule,
    SharedLayoutsModule,


    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
  
    
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    DxDataGridModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
  ]
})
export class UldDamageModule { }
