import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as enLocale from "date-fns/locale/en";
import { DatepickerOptions } from "ng2-datepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../../config-service.service";
import { ServiceRecovery } from "../../../services/serviceRecovery/recovery.service";
declare var jQuery: any;
@Component({
  selector: "app-edit-service-recovery",
  templateUrl: "./edit-service-recovery.component.html",
  styleUrls: ["./edit-service-recovery.component.css"],
})
export class EditServiceRecoveryComponent implements OnInit {
  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "DD-MM-YYYY",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private ServiceRecovery: ServiceRecovery,
    public toast: ToastrService,
    public route: Router,
    private sanitizer: DomSanitizer,
    private config: ConfigServiceService
  ) {}

  serviceId: any = 0;
  ServiceRecorverydata: any = [];
  loginuserdetailsTest: any;
  thirdTB_COmp: boolean = false;
  editServiceRecoveryForm: FormGroup;
  recoveryaspctsLable = ["MSCA", "FDCA", "DMA"];

  actionTakenLable = ["Warehouse Check", "Contacted Agent"];
  checkedActionTakenValue: any = [];
  isEditable: boolean = false;

  airlineServiceRecoveryData: any = [];
  preSelectrecoveryaspctsLable: any = [];

  masterawbNo: any;
  orgId: any;
  orgType: any;
  urls: any = [];
  urlsNew: any = [];
  imageslist: any = [];
  urlArrayTotal: any = [];
  damage_Report_Images: any;
  urlArrayTotalurlArrayTotal: any;

  selectedFiles: File[] = [];
  isExtraTabEnable: boolean;
  dd: any = [];
  serviceReportsProductionUrl: string;

  isDisabledForm: boolean = false;
  selectedOption: string;
  ngOnInit() {
    this.loginuserdetailsTest = JSON.parse(
      localStorage.getItem("loginresultobject")
    );
    this.serviceReportsProductionUrl = this.config.getConfig().URL;

    this.orgId = this.loginuserdetailsTest.orgId;
    this.orgType = this.loginuserdetailsTest.orgType;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.serviceId = params["serviceId"];
    });

    this.editServiceRecoveryForm = this.formBuilder.group({
      comment: new FormControl("", []),
      tracingDate: new FormControl("", [Validators.required]),
      piecesTraced: new FormControl("", [Validators.required]),
      actionTakenValue: new FormControl([], [Validators.required]),
      id: new FormControl([], [Validators.required]),
    });

    this.spinner.show();
    this.ServiceRecovery.getServiceRecoveryById(this.orgId,this.serviceId,this.orgType).subscribe((data) => {
      if(data.responsecode == 1){
        this.editServiceRecoveryForm.patchValue({
          id: data.data.id,
          piecesTraced: data.data.piecesTraced,
          tracingDate: data.data.tracingDate != "0000-00-00" ? data.data.tracingDate : "",
        });

        // this.editServiceRecoveryForm.value.id = data.data.id;
        // this.editServiceRecoveryForm.value.piecesTraced = data.data.piecesTraced;
        // this.editServiceRecoveryForm.value.tracingDate !== '0000-00-00' ? data.data.tracingDate : '' ;
        // this.editServiceRecoveryForm.value.comment = "";

        this.airlineServiceRecoveryData.totalMAWBPieces = data.data.totalMAWBPieces;
        this.airlineServiceRecoveryData.piecesInvolved = data.data.piecesInvolved;
        this.airlineServiceRecoveryData.totalMAWBWeight = data.data.totalMAWBWeight;
        this.airlineServiceRecoveryData.files = data.data.files;
        this.airlineServiceRecoveryData.piecesInvolvedWeight = data.data.piecesInvolvedWeight;
        this.airlineServiceRecoveryData.airlineComment = data.data.comment;
        this.airlineServiceRecoveryData.locatedDate = data.data.createdon;
        this.airlineServiceRecoveryData.serviceRecoveryStatus = data.data.status;
        this.airlineServiceRecoveryData.flight = data.data.flight1;
        this.airlineServiceRecoveryData.parent_id = data.data.parent_id;
        this.airlineServiceRecoveryData.org_airline_id = data.data.org_airline_id;
        this.masterawbNo = data.data.masterAwbNo1;
        this.preSelectrecoveryaspctsLable = data.data.recoveryAspcts;
        this.checkedActionTakenValue = data.data.recoveryAspctsByhandler;
        this.airlineServiceRecoveryData.piecesTraced = data.data.piecesTraced;
        this.airlineServiceRecoveryData.chatDeti = data.data.chatDeti;
        if(this.airlineServiceRecoveryData.serviceRecoveryStatus == "closed") {
          this.isDisabledForm = true;
        }

        if (this.airlineServiceRecoveryData.serviceRecoveryStatus == "open") {
          this.serviceReportStatusUpdate();
        }
        this.spinner.hide();
      }else{
        this.toast.error("Data not found");
        setTimeout(() => {
          this.route.navigateByUrl("/csa-service-recovery");
        }, 100);
      }

      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.spinner.hide();
    this.thirdTB_COmp = true;

    jQuery(document).ready(function(){
      jQuery(".toggle-accordion").click(function(){
    
        if (jQuery(this).data("closedAll")) {
          // alert();
          jQuery(".collapse").collapse("show");
          jQuery(this).addClass('active');
        }
        else {
          jQuery(".collapse").collapse("hide");
          jQuery(this).removeClass('active');
        }
        
        // save last state
        jQuery(this).data("closedAll",!jQuery(this).data("closedAll")); 
    });
    jQuery(".toggle-accordion").data("closedAll",true);
    });
  }

  serviceReportStatusUpdate() {
    this.spinner.show();
    var updateData = {
      serviceInfoId: this.serviceId,
      orgId: this.orgId,
      orgType: this.orgType,
    };
    this.ServiceRecovery.updateServiceRecoveryHandlerStatus(
      updateData
    ).subscribe(
      (data) => {
        this.airlineServiceRecoveryData.serviceRecoveryStatus = data.status;
      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

  isSelectedRecoveryAspctsLable(topic) {
    if (this.preSelectrecoveryaspctsLable.length > 0) {
      return this.preSelectrecoveryaspctsLable.indexOf(topic) >= 0;
    }
  }

  getActionTakenValues(ev, data) {
    let obj = data;
    if (ev.target.checked == true) {
      // Pushing the object into array
      this.checkedActionTakenValue.push(obj);
    } else {
      let el = this.checkedActionTakenValue.find((itm) => itm === data);
      if (el)
        this.checkedActionTakenValue.splice(
          this.checkedActionTakenValue.indexOf(el),
          1
        );
    }
  }

  isSelected(topic) {
    return this.checkedActionTakenValue.indexOf(topic) >= 0;
  }

  onCancelPage() {
    console.warn(" onCancelPage ");
    jQuery("#isLeavePageWithouSave").modal("show");
    let isleavePage = JSON.parse(localStorage.getItem("isSaveReportLeave"));
  }

  onClick_YesLeavepagePopup() {
    console.warn(" onClick_YesLeavepagePopup ");
    jQuery("#isLeavePageWithouSave").modal("hide");
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
    this.route.navigateByUrl("/csa-service-recovery");
  }

  onClick_NoleavePage() {
    console.warn(" onClick_NoleavePage ");
    jQuery("#isLeavePageWithouSave").modal("hide");
  }

  // ----------------------------uploadimg-----------------------------------------------

  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }
  public static _imagesArrayTotalNew = [];
  public static _imagesArrayTotalData = [];
  selectimages(val) {
    const self = this;
    const images = val.target.files;

    for (let i = 0; i < images.length; i++){
      const file: File = images[0];
      if (!file.type.match("image.*|application.*")) {
        this.toast.error("Please select image or pdf only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(",")[1];
        var base64Full = base64String;
        EditServiceRecoveryComponent._imagesArrayTotalData.push(base64Full);

        EditServiceRecoveryComponent._imagesArrayTotalNew.push(base64);
        self.preview(EditServiceRecoveryComponent._imagesArrayTotalNew);
      });
    }
  }

  preview(files) {
    this.urlsNew = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = "data:image/png;base64," + file;
        // document.body.appendChild(image);
        this.urlsNew.push(image);
        // this.urls.push(image);
        // this.urlArrayTotal = this.urlsNew;
      }
    }
  }

  on_Cancel_Image(i) {
    EditServiceRecoveryComponent._imagesArrayTotalNew.splice(i, 1);
    EditServiceRecoveryComponent._imagesArrayTotalData.splice(i, 1);
    this.urlsNew.splice(i, 1);
  }

  // ---------------------------End images uploadimg-----------------------------------------------

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  onSubmit() {
   
  if((this.editServiceRecoveryForm.value.piecesTraced > 0) || (this.editServiceRecoveryForm.value.tracingDate != null)){
    if(this.editServiceRecoveryForm.value.piecesTraced == null || (this.editServiceRecoveryForm.value.piecesTraced > this.airlineServiceRecoveryData.piecesInvolved)){
      this.toast.error("Please enter valid pieces traced");
      return false;
    }else if(this.editServiceRecoveryForm.value.tracingDate == "" || this.editServiceRecoveryForm.value.tracingDate == null){
      this.toast.error("Please enter traced date");
      return false;
    }
  }else if( this.editServiceRecoveryForm.value.comment == "" || this.editServiceRecoveryForm.value.comment == null){
    this.toast.error("Please enter comment");
    return false;
  }
      
  this.editServiceRecoveryForm.value.userId = this.loginuserdetailsTest.userId;
  this.editServiceRecoveryForm.value.orgId = this.loginuserdetailsTest.orgId;
  this.editServiceRecoveryForm.value.orgType = this.loginuserdetailsTest.orgType;
  this.editServiceRecoveryForm.value.actionTakenValue = this.checkedActionTakenValue;
  this.editServiceRecoveryForm.value.parent_id = this.airlineServiceRecoveryData.parent_id;
  this.editServiceRecoveryForm.value.org_airline_id = this.airlineServiceRecoveryData.org_airline_id;
  
  if(EditServiceRecoveryComponent._imagesArrayTotalData !== undefined){
    this.dd = "";
    for(let i = 0;i < EditServiceRecoveryComponent._imagesArrayTotalData.length;i++){
      if(i + 1 === EditServiceRecoveryComponent._imagesArrayTotalData.length){
        this.dd = this.dd + EditServiceRecoveryComponent._imagesArrayTotalData[i];
      } else {
        this.dd = this.dd + EditServiceRecoveryComponent._imagesArrayTotalData[i] +"#..";
      }
    }
    this.editServiceRecoveryForm.value.files = this.dd;
  }
  this.ServiceRecovery.updateServiceRecovery(this.editServiceRecoveryForm.value).subscribe((data) => {
    this.spinner.hide();
    if(data.responseCode === 1){
      EditServiceRecoveryComponent._imagesArrayTotalNew = [];
      EditServiceRecoveryComponent._imagesArrayTotalData = [];
      this.urlsNew = [];

      this.toast.success("Services report has been update successfully");
      this.route.navigateByUrl("/", { skipLocationChange: true }).then(() => this.route.navigate(["/csa-service-recovery"]));
    }else if(data.responseCode === 0 && data.message === "Unauthorized User"){
      this.toast.info("Your session is expired, please login again");
      this.spinner.hide();
    }else{
      this.spinner.hide();
      this.toast.error(data.saveDataReport.message);
    }
  },(error) => {
    this.toast.error("Report is not generated due to network issue.");
    this.spinner.hide();
  }
  );
  console.log("316 editServiceRecoveryForm value", this.editServiceRecoveryForm.value);


    // console.log("317 _imagesArrayTotalData => ", EditServiceRecoveryComponent._imagesArrayTotalData);
  /*  
    if(this.editServiceRecoveryForm.value.piecesTraced){
        if(this.editServiceRecoveryForm.value.piecesTraced > this.airlineServiceRecoveryData.piecesInvolved ||
        this.editServiceRecoveryForm.value.piecesTraced === null)
      {
        this.toast.error("Please enter valid pieces traced");
      } else if(this.editServiceRecoveryForm.value.tracingDate == "" || this.editServiceRecoveryForm.value.tracingDate == null){
        this.toast.error("Please enter traced date");
      }else{

        this.editServiceRecoveryForm.value.userId = this.loginuserdetailsTest.userId;
        this.editServiceRecoveryForm.value.orgId =  this.loginuserdetailsTest.orgId;
        this.editServiceRecoveryForm.value.orgType =this.loginuserdetailsTest.orgType;
        this.editServiceRecoveryForm.value.actionTakenValue =  this.checkedActionTakenValue;

        this.editServiceRecoveryForm.value.parent_id =  this.airlineServiceRecoveryData.parent_id;
        this.editServiceRecoveryForm.value.org_airline_id = this.airlineServiceRecoveryData.org_airline_id;

        if(EditServiceRecoveryComponent._imagesArrayTotalData !== undefined) {
          this.dd = "";
          for(let i = 0;i < EditServiceRecoveryComponent._imagesArrayTotalData.length;i++){
            if (i + 1 === EditServiceRecoveryComponent._imagesArrayTotalData.length){
              this.dd = this.dd + EditServiceRecoveryComponent._imagesArrayTotalData[i];
            } else {
              this.dd =this.dd + EditServiceRecoveryComponent._imagesArrayTotalData[i] +"#..";
            }
          }
          this.editServiceRecoveryForm.value.files = this.dd;
        }
        console.log("this editServiceRecovery Formvalue", this.editServiceRecoveryForm.value);
        return false;
        this.spinner.show();
        this.ServiceRecovery.updateServiceRecovery(this.editServiceRecoveryForm.value).subscribe(
          (data) => {
            this.spinner.hide();
            if (data.responseCode === 1) {
              this.toast.success("Services report has been update successfully");
              this.route
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => this.route.navigate(["/csa-service-recovery"]));
            } else if (
              data.responseCode === 0 &&
              data.message === "Unauthorized User"
            ) {
              this.toast.info("Your session is expired, please login again");
              this.spinner.hide();
            } else {
              this.spinner.hide();
              this.toast.error(data.saveDataReport.message);
            }
          },
          (error) => {
            this.toast.error("Report is not generated due to network issue.");
            this.spinner.hide();
          }
        );
        this.spinner.hide();
      }
    }  
    else{
        if( this.editServiceRecoveryForm.value.comment == "" || this.editServiceRecoveryForm.value.comment == null){
        this.toast.error("Please enter comment");
        }else{
        this.editServiceRecoveryForm.value.userId =
        this.loginuserdetailsTest.userId;
      this.editServiceRecoveryForm.value.orgId =
        this.loginuserdetailsTest.orgId;
      this.editServiceRecoveryForm.value.orgType =
        this.loginuserdetailsTest.orgType;
      this.editServiceRecoveryForm.value.actionTakenValue =
        this.checkedActionTakenValue;

      this.editServiceRecoveryForm.value.parent_id =
        this.airlineServiceRecoveryData.parent_id;
      this.editServiceRecoveryForm.value.org_airline_id =
        this.airlineServiceRecoveryData.org_airline_id;

      if (EditServiceRecoveryComponent._imagesArrayTotalData !== undefined) {
        this.dd = "";
        for(let i = 0;i < EditServiceRecoveryComponent._imagesArrayTotalData.length;i++){
          if(i + 1 === EditServiceRecoveryComponent._imagesArrayTotalData.length){
            this.dd =
              this.dd + EditServiceRecoveryComponent._imagesArrayTotalData[i];
          } else {
            this.dd =
              this.dd +
              EditServiceRecoveryComponent._imagesArrayTotalData[i] +
              "#..";
          }
        }
        this.editServiceRecoveryForm.value.files = this.dd;
      }
      this.spinner.show();
      this.ServiceRecovery.updateServiceRecovery(
        this.editServiceRecoveryForm.value
      ).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.toast.success("Services report has been update successfully");
            this.route
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => this.route.navigate(["/csa-service-recovery"]));
          } else if (
            data.responseCode === 0 &&
            data.message === "Unauthorized User"
          ) {
            this.toast.info("Your session is expired, please login again");
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.toast.error(data.saveDataReport.message);
          }
        },
        (error) => {
          this.toast.error("Report is not generated due to network issue.");
          this.spinner.hide();
        }
      );
      this.spinner.hide();
        }    
    }
  */
  }


  openModal(i, image_name) {
    const modal = document.getElementById("myModal" + i + image_name);
    modal.style.display = "block";
  }

  closeModal(i, image_name) {
    const modal = document.getElementById("myModal" + i + image_name);
    modal.style.display = "none";
  }


  openAirlineMsgModal(i) {
    const modal = document.getElementById("airlineMsgModal" + i);
    modal.style.display = "block";
  }

  closeAirlineMsgModal(i) {
    const modal = document.getElementById("airlineMsgModal" + i);
    modal.style.display = "none";
  }
  
  // ---------------toggle eitable----------------------------//
  toggleEditable() {
    this.isEditable = !this.isEditable;
    console.log("toggle", this.isEditable);
  }
  // -------------------end toggle editable ----------------------------//

// function to handle radio button selection
onOptionChange(option: string) {
  this.selectedOption = option;
  console.log("option==>",this.selectedOption);
}

}
