import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreatereportService } from '../services/createreport.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { LoginInput } from '../home/loginModel';
import { ShareLoginDataService } from '../data/share-login-data.service'
import { LoginService } from '../services/login/login.service';
import { LoginDataService } from '../login/login-data.service';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { SaveReportsComponent } from '../save-reports/save-reports.component';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css'],
  providers: [SaveReportsComponent,
    ForgotpasswordComponent]
})
export class UpdatesComponent implements OnInit {
  VERSION_NUMBER = globalConstants.VERSION_NUMBER
  loginForm: FormGroup;
  verifyEmailForm: FormGroup;
  showlogin: boolean;
  showviewreports: boolean;
  //   saveReportObj: SaveReportsComponent;
  forgotEmail: any;
  loginInput = new LoginInput();
  //   forgotPwdComponent: ForgotpasswordComponent;
  isShowDashBoard: boolean;
  loginDetails: any;
  rememberMeStatus: boolean;

  constructor(private DamageReportServicel: CreatereportService, private atp: AmazingTimePickerService,
    private datePipe: DatePipe, public route: Router, public toast: ToastrService,private navbarComponent: NavbarComponent,
    private spinner: NgxSpinnerService, private LoginDataService: LoginDataService, public shareServc: ShareLoginDataService, public loginServc: LoginService, private myAccountService: MyAccountService, private saveReportObj: SaveReportsComponent, private forgotPwdComponent: ForgotpasswordComponent) {
    // this.saveReportObj = new SaveReportsComponent(DamageReportServicel, atp, datePipe, route, toast, spinner, LoginDataService,  shareServc, loginServc,myAccountService);
    // this.forgotPwdComponent  = new ForgotpasswordComponent(route,myAccountService,toast,spinner);
  }


  ngOnInit() {
    
    window.scroll(0, 0);
    // let EMAILPATTERN = /(?!.\.{2})^([a-z\d!#$%&@'+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    // let EMAILPATTERN = "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}" ;
   
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
      passcode: new FormControl('', [Validators.required, Validators.minLength(6),]),
    });
    this.verifyEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
    });
    const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginDetails = loginuserdetails;
    if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
      this.showlogin = true;
      this.showviewreports = false;
      this.isShowDashBoard = false;
    } else {
      this.showlogin = false;
      this.showviewreports = true;
      this.isShowDashBoard = true;
    }


    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
        if(remmeberData !== null && remmeberData !== undefined && remmeberData !== undefined){
            this.loginForm.get("username").patchValue(remmeberData.emailId)
            this.loginForm.get("passcode").patchValue(remmeberData.pwd)
        }
  }

  onDismisssPopUp() {
    this.saveReportObj.loginForm.reset();
  }
  loginClick() {
    this.rememberMeStatus = true;
    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
        if(remmeberData !== null && remmeberData !== undefined && remmeberData !== ''){
            this.loginForm.get("username").patchValue(remmeberData.emailId)
            this.loginForm.get("passcode").patchValue(remmeberData.pwd)
            if((remmeberData.emailId === '' || remmeberData.emailId === undefined || remmeberData.emailId === null)&& (remmeberData.pwd === '' || remmeberData.pwd === null || remmeberData.pwd === undefined)){
                this.loginForm.reset();
                this.rememberMeStatus = false;
            }
        }else{
            this.rememberMeStatus = false;
        }

  }
  fn_OnLogin(loginData) {
    ;
    loginData.rememberMeStatus = this.rememberMeStatus ;
    this.saveReportObj.fn_OnLogin(loginData);
  }

  openforgotcall() {
    this.verifyEmailForm.reset();
    this.saveReportObj.openforgotcall();
  }

  logout() {
    this.navbarComponent.logOut();
  }

  sendpasswordcall() {
    ;
    this.saveReportObj.forgotEmail = this.forgotEmail;
    this.saveReportObj.sendpasswordcall();
  }

  onCnclForgotPwdPopup() {
    this.verifyEmailForm.reset();
  }


  onClickDashBoard() {
    ;
    if (this.loginDetails.userType == 'SA') {
      this.route.navigateByUrl('/sa-dashboard');
    } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
      this.route.navigateByUrl('/csa-dashboard');
    }
    else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
      this.route.navigateByUrl('/csa-airline-dashboard');
    }
    else if (this.loginDetails.userType == 'E') {
      this.route.navigateByUrl('/user-dashboard');
    }
  }

  onClickRememberMe(event){
    ;
   (event.srcElement.checked) ? (this.rememberMeStatus = true): (this.rememberMeStatus = false)
}
}
