import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ShareLoginDataService {


 private LoginSource = new BehaviorSubject(undefined);
  getLoginData = this.LoginSource.asObservable();
    loginObj: any;

  constructor() { }

   updateData(data: any) {
        ;
        this.LoginSource.next(data);
       
        console.log(data);
    }

    getLoginDataObj() {
        ;
         this.getLoginData.subscribe(data => {
            this.loginObj = data;
            console.log(this.loginObj);
        })
        return this.loginObj
    }
}
