import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit, enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/filter';
import { ViewReportsData } from '../../../data/DataShareViewDetails';
import { StationService } from '../../../services/station/station.service';
declare var jQuery: any;


if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}

@Component({
    selector: 'app-viewstations',
    templateUrl: './viewstations.component.html',
    styleUrls: ['./viewstations.component.css']
})
export class ViewstationsComponent implements OnInit, OnDestroy {
    stationDetails: any;
    Logindetails: any;
    comapanydetails: any;
    obj: { orgId: any; };
    deleteObj: any;
    searchItem: any;
    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 5;
    unauthlowValue = 0;
    unauthhighValue = 5;
    CompanyLogoimgurl: string;
    CompanyName: any;
    NavigatebackTo: any;
    stationsFrom: any;


    constructor(private cookieService: CookieService,private readonly route: ActivatedRoute,
         private ViewReportsData: ViewReportsData, private router: Router, private toastr: ToastrService,
          private stationService: StationService, private spinner: NgxSpinnerService,
          private locationStr: LocationStrategy,
          ) {

             // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
      
    }
    dataGrid1: DxDataGridComponent;
    viewData: any;
    stationCode: any;
    public static stationsListFromService;
    ngOnInit() {
        
        window.scroll(0, 0);
        this.route.queryParams.filter(params => params.from)
            .subscribe(params => {
                console.log('***********', params);
                this.NavigatebackTo = params.from
            })
        this.viewstationsdata();
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.stationsFrom = JSON.parse(localStorage.getItem('stationsFrom'));
        console.log('this.sationsfrom', this.stationsFrom)


        if (this.NavigatebackTo !== 'SidebarStations' || this.stationsFrom !== "sidebarStations") {
            if (this.comapanydetails === null) {
                this.CompanyLogoimgurl = '';
               
            } else {
                this.CompanyLogoimgurl = this.comapanydetails.orgLogo
                this.CompanyName = this.comapanydetails.orgName
            }
        } else {
            this.CompanyLogoimgurl = this.Logindetails.orgLogo
            this.CompanyName = this.Logindetails.organisationName
        }

    }

    viewstationsdata() {
        ;
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        if (this.Logindetails.userType === 'SA') {
            this.obj = {
                orgId: this.comapanydetails.orgId
            }
        } else {
            this.obj = {
                orgId: this.Logindetails.orgId
            }
        }
        this.spinner.show();
        this.stationService.ViewStations(this.obj).subscribe(
            data => {
                
              
                    console.log(data)
                    this.spinner.hide();
                  if (data[0].responseCode === undefined) {
                    this.stationDetails = data.reverse();
                    localStorage.setItem('viewData', JSON.stringify(data));
                    if (this.stationDetails.length != 0) {
                        ViewstationsComponent.stationsListFromService = this.stationDetails;
                    } else {
                        ViewstationsComponent.stationsListFromService = [];
                    }

                    
                    for(let i=0; i<this.stationDetails.length; i++){
                        if(this.stationDetails[i].emailId !== '' && this.stationDetails[i].emailId !== null && this.stationDetails[i].emailId !== undefined){
                            this.stationDetails[i].displayemailId = this.stationDetails[i].emailId.replace(/,/g, ', ');
                        }
                       
                    }
                    
                    
                    this.spinner.hide();
                  }
                  else if (data[0].responseCode === 0 && data[0].message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                    this.cookieService.delete('cclp', '/', '.cclp.aero');
                    this.cookieService.delete('uld', '/', '.cclp.aero');
                    this.cookieService.delete('airPortClaims', '/', '.cclp.aero');
                    localStorage.clear();
                    this.router.navigate(["/Home"])
                } 

            },
            error => {
                this.spinner.hide();
                ViewstationsComponent.stationsListFromService = [];
            }
        )
    }
    

    deleteStation() {
        
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.viewData = JSON.parse(localStorage.getItem("viewDataobj"));
        this.stationCode = this.viewData.stationCode
        
        if (this.Logindetails.userType === 'SA') {
           
            this.deleteObj = {
                orgId: this.comapanydetails.orgId,
                orgType: this.comapanydetails.orgType,
                stationCode: this.stationCode,
                handler_Id: this.viewData.handlerId

            }
        } else {
            this.deleteObj = {
                orgId: this.Logindetails.orgId,
                orgType: this.Logindetails.orgType,
                stationCode: this.stationCode,
                handler_Id: this.viewData.handlerId
            }
        }

        this.spinner.show();
        this.stationService.DeleteStations(this.deleteObj).subscribe(
            data => {
                
                console.log(data)
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.toastr.success(data.message, '', { timeOut: 1500 });
                    jQuery('#deletePopUp').modal('hide');
                   
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/viewstations'], { queryParams: { from: 'ViewUsersStations' } }));

                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                    jQuery('#deletePopUp').modal('hide');
                }  else {
                    jQuery('#deletePopUp').modal('hide');
                    this.toastr.error(data.message, '', {});
                }

               
            },
            error => {
                this.spinner.hide();
            }
        )
    }
    editStation(object) {
        
        
        this.router.navigate(["editstations"], { queryParams: object, skipLocationChange: true, queryParamsHandling: "merge" });
    }

    fn_deletestation(event, obj) {
        
        jQuery('#deletePopUp').modal({ backdrop: 'static', keyboard: false });
        console.log(event)
        console.log(obj)
        localStorage.setItem('viewDataobj', JSON.stringify(obj));
    }

    stationsNavigation() {
        
        if (this.ViewReportsData.isfromSidebarStations === 'yes' || this.NavigatebackTo === "SidebarStations") {
            if (this.Logindetails.orgType === 'H') {
                this.router.navigate(["csa-dashboard"]);
                this.ViewReportsData.isfromSidebarStations = 'no'
            } else if (this.Logindetails.orgType === 'A') {
                this.router.navigate(["csa-airline-dashboard"]);
                this.ViewReportsData.isfromSidebarStations = 'no'
            }
        } else if (this.ViewReportsData.isFromViewUsersStations === 'yes' || this.NavigatebackTo === "ViewUsersStations") {
            this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
            if (this.ViewReportsData.isFrmViewDetails === 'yes') {
                this.ViewReportsData.isFrmViewDetails = 'yes';
                this.ViewReportsData.isFromViewUsersStations = 'no';
                // this.router.navigate(["/viewusers", this.comapanydetails.orgId]); //neww
                
                this.router.navigate(["/viewusers"], { queryParams: { orgKey: this.comapanydetails.orgId }});
            } else {
                this.ViewReportsData.isFrmViewDetails = 'no';
                this.ViewReportsData.isFromViewUsersStations = 'no';
                // this.router.navigate(["/viewusers", this.comapanydetails.orgId]);//neww 
                this.router.navigate(["/viewusers"], { queryParams: { orgKey: this.comapanydetails.orgId }});
            }
        } else if (this.NavigatebackTo === "HandlerDashboard") {
            this.router.navigate(['/csa-dashboard'])
        } else if (this.NavigatebackTo === "AirlineDashboard") {
            this.router.navigate(['/csa-airline-dashboard'])
        }
    }


    searchstationcode(searchValue) {
        
        console.log(this.searchItem);
        if (searchValue != '') {
            this.stationDetails = ViewstationsComponent.stationsListFromService;// for every time reassigning service
            this.stationDetails = this.stationDetails.filter((item) => item.stationCode.toLowerCase().startsWith(searchValue.toLowerCase()))
        } else {
            this.stationDetails = ViewstationsComponent.stationsListFromService;
        }
    }


    onClearSearch() {
        this.stationDetails = ViewstationsComponent.stationsListFromService;
        this.searchItem = '';
    }

    ngOnDestroy() {
        localStorage.removeItem('stationsFrom')
    }

}
