import { LocationStrategy } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { globalConstants } from "src/environments/globalConstants";
import { StationService } from "../../../services/station/station.service";
declare var jQuery: any;
@Component({
    selector: "app-editstations",
    templateUrl: "./editstations.component.html",
    styleUrls: ["./editstations.component.css"]
})
export class EditstationsComponent implements OnInit {

    Logindetails: any;
    editStationForm: FormGroup;
    viewData: any;
    handlerId: any;
    handlerName: any;
    emailsObj: any;
    StationCode: any;
    contactName: any;
    address: any;
    secondAddress: any;
    City: any;
    Country: any;
    Postalcode: any;
    longitude: any;
    latitude: any;
    orgId: any;
    emailIdNums: any;
    getDetailsData: any;
    emailIdsWithoutObj: any;
    emailId_Index: any;
    delete_Email_Index: any;
    isSubmitEnable: boolean;
    isEnable: boolean;
    stationCodeCheck: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: any;
    NavigatebackTo: any;
    // CompanyLogoimgurl: any;
    showHandlerItems: boolean;
    handlerItems: any;
    getHandlerData:any;
    handlerIdCheck:any;
    getOrgHandlerId:any=0;
    constructor(
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private stationService: StationService,
        private spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private activeRoute: ActivatedRoute,
        private locationStr: LocationStrategy,
    ) {
       // preventing back button in browser   
       history.pushState(null, null, window.location.href);  
       this.locationStr.onPopState(() => {
         history.pushState(null, null, window.location.href);
       });
    }

    initilizeHandlerData() {
        this.handlerItems = JSON.parse(localStorage.getItem('handlerList'));
        if(this.handlerItems == null){
            this.stationService.GetHandlerName().subscribe(data => {
                if(data.responseCode === 1){
                    localStorage.setItem('handlerList', JSON.stringify(data.handler_details));
                    this.spinner.hide();
                    this.handlerItems = JSON.parse(localStorage.getItem('handlerList'));
                }
                else if(data.responseCode === 0 && data.message === 'Unauthorized User')
                {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                }
            },error => {
                this.spinner.hide();
            });
        }
    }

    getHandlerItems(ev: any) {
        // Reset items back to all of the items
        this.initilizeHandlerData()
        // set val to the value of the searchbar
        const val = ev;
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            this.initilizeHandlerData()
            this.getHandlerData = this.handlerItems.filter((item) =>
                item.orgName.toLowerCase().startsWith(val.toLowerCase())
            );
            if (this.getHandlerData.length === 0) {

                this.getHandlerData = this.getHandlerData.filter((item) =>
                    item.orgName.toLowerCase().startsWith(val.toLowerCase())
                );
            }
            // Show the results
            this.showHandlerItems = true;
        } else {
            // hide the results when the query is empty
            this.showHandlerItems = false;
        }
    }

    selectHandlerId(item) {
        this.handlerIdCheck = item.orgkey;
        this.editStationForm.controls['orgHandlerName'].patchValue(item.orgName);
        this.editStationForm.controls['orgHandlerId'].patchValue(item.orgkey);
        this.showHandlerItems = false;
    }

    ngOnInit() {
        ;
        window.scroll(0, 0);
        this.viewData = JSON.parse(localStorage.getItem("viewData"));
        this.Logindetails = JSON.parse(localStorage.getItem("loginresultobject"));
       
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));

        if (this.comapanydetails === null) {
            this.CompanyLogoimgurl = this.Logindetails.orgLogo;;
            this.CompanyName = this.Logindetails.organisationName
        } else {
            this.CompanyLogoimgurl = this.comapanydetails.orgLogo
            this.CompanyName = this.comapanydetails.orgName
        }

        this.route.queryParams.filter(params => params.from)
            .subscribe(params => {
                ;
                console.log('***********', params);
                this.NavigatebackTo = params.from
            });

        this.activeRoute.queryParams.subscribe(params => {
            params  ;
            this.handlerId = params['handlerId'];
            this.StationCode = params['stationCode'];
            this.contactName = params['handlerName'];
            this.address = params['address'];
            this.secondAddress = params['secondAddress'];
            this.orgId = params['orgId'];
         

        });

      
        this.editStationForm = this.formBuilder.group({
            handlerName: new FormControl(this.viewData.handlerName),
            stationCode: new FormControl(this.viewData.stationCode, [Validators.required]),

            orgHandlerName: new FormControl(this.viewData.orgHandlerName),
            orgHandlerId: new FormControl(this.viewData.orgHandlerId),
          
            address: new FormControl(this.viewData.address, [Validators.required]),
            secondAddress: new FormControl(''),
            city: new FormControl(this.viewData.address, [Validators.required]),
            country: new FormControl(this.viewData.Country, [Validators.required]),
            postalcode: new FormControl(''),
            latitude: new FormControl(this.viewData.lattitude),
            longitude: new FormControl(this.viewData.longitude),
            editemails: this.formBuilder.array([this.formBuilder.group({ emailid: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]) })])
        });
        this.getDeatil(this.handlerId);

        this.editStationForm.controls['editemails'].valueChanges.subscribe(value => {
            ;
            console.log(value);
            this.fn_To_Enable_Submit();
        })


    }
    city_fn(){
        
       
        if(this.editStationForm.value.city != ''&& this.editStationForm.value.city != null){
            this.editStationForm.controls['city'].patchValue(this.editStationForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }
    country_fn(){
        if(this.editStationForm.value.country != ''&& this.editStationForm.value.country != null){
            this.editStationForm.controls['country'].patchValue(this.editStationForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }

    editStation(formData) {
        this.spinner.show();
        if(formData.city != ''&& formData.city != null){
            formData.city= formData.city.replace(/[^a-zA-Z\s]*/g, '');
        }
         
        if(formData.country != ''&& formData.country != null){
            formData.country= formData.country.replace(/[^a-zA-Z\s]*/g, '');
        }
        if(formData.address != ''&& formData.address != null){
            formData.address= formData.address.replace(/[^a-zA-Z0-9\!\#\%\&\'\-\/\@\.\s\,]*/g, '');
        }
        if(formData.handlerName != ''&& formData.handlerName != null){
            formData.handlerName= formData.handlerName.replace(/[^a-zA-Z\s]*/g, '');
        }
        if(formData.secondaddress != ''&& formData.secondaddress != null){
            formData.secondaddress= formData.secondaddress.replace(/[^a-zA-Z0-9\!\#\%\&\'\+\-\/\@\.\s\,]*/g, '');
        }
        if(formData.postalcode != ''&& formData.postalcode != null){
            formData.postalcode= formData.postalcode.replace(/[^a-zA-Z0-9]*/g, '');
        }

        if(formData.latitude != ''&& formData.latitude != null && formData.latitude != undefined){
            formData.latitude= formData.latitude.toString().replace(/[^0-9\.]*/g, '');
        }
        if(formData.longitude != ''&& formData.longitude != null && formData.longitude != undefined ){
            formData.longitude= formData.longitude.toString().replace(/[^0-9\.]*/g, '');
        }
        ;
        if (formData.handlerName === undefined) {
            formData.handlerName = ""
        }
        formData.handlerId = this.handlerId;
        formData.orgId = this.orgId;
        let value = []; 
        let oldEmailsArray = [];
        let newEmailsArray = [];
        value = formData.editemails.splice(0, this.emailsObj.length);
        for (const iterator of value) {
            oldEmailsArray.push(iterator.emailid);
        }
        for (const iterator of formData.editemails) {
            newEmailsArray.push(iterator.emailid);
        }
        formData.newEmails = newEmailsArray;
        formData.editemails = oldEmailsArray;
        formData.emailIdnumber = this.emailIdNums;
        formData.modifiedBy = this.Logindetails.userId;

       
        console.log(formData);
        ;
      
        this.stationService.EditStations(formData).subscribe(
            data => {
                ;
                console.log(data);
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.toastr.success("Successfully updated", "", { timeOut: 1500 });
                   
                    if (this.NavigatebackTo === "SidebarStations") {
                        this.router.navigate(['/viewstations'], { queryParams: { from: 'SidebarStations' } });
                    } else {
                        this.router.navigate(['/viewstations'], { queryParams: { from: 'ViewUsersStations' } });
                    }

                } else if( data.responseCode === 0  && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                else {
                    this.toastr.error(data.message, "", {});
                }
            },
            error => {
                this.spinner.hide();
            }
        );
    }

    gotoback() {
        ;
         if (this.NavigatebackTo === "SidebarStations") {
                        this.router.navigate(['/viewstations'], { queryParams: { from: 'SidebarStations' } });
                    } else {
                        this.router.navigate(['/viewstations'], { queryParams: { from: 'ViewUsersStations' } });
                    }
    
    }
    get emailsData() {
        return this.editStationForm.get('editemails') as FormArray;
    }

    add_email_Field() {
        this.emailsData.push(this.formBuilder.group({ emailid: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]) }));
    }

    delete_email_Field(index, emailId) {
        ;
        this.emailIdsWithoutObj = [];
        this.emailId_Index = index;
        if (this.emailsData.length > 1) {
            for (const each of this.emailsObj) {
                this.emailIdsWithoutObj.push(each.email_id);
            }
            let val = this.emailIdsWithoutObj.indexOf(emailId);
           
                if ((val >= 0) && (index < this.emailsObj.length)) {
                   
                    this.delete_Email_Index = this.emailsObj[index].id;
                    jQuery('#delete-confirmation').modal({ backdrop: 'static', keyboard: false });
                } else {
                    jQuery('#delete-newEmails').modal({ backdrop: 'static', keyboard: false });
                }
            
        }
    }


    delete_newleyEnteredEmail() {
        ;
        this.emailsData.removeAt(this.emailId_Index);
        jQuery('#delete-newEmails').modal('hide');
    }

    deletePreviousMails() {
        ;
        this.spinner.show();
        this.stationService.deleteIndividual({ "airlineId": this.delete_Email_Index }).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', {
                    timeOut: 500
                });
                this.emailsData.removeAt(this.emailId_Index);
                this.emailIdNums.splice(this.emailId_Index, 1);
                this.emailsObj.splice(this.emailId_Index, 1);
                
                jQuery('#delete-confirmation').modal('hide');
            }else if( data.responseCode === 0  && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#delete-confirmation').modal('hide');
            } 
             else {
                this.toastr.error(data.message);
                jQuery('#delete-confirmation').modal('hide');
            }
        }, error => {
            this.spinner.hide();
        })
    }

    getDeatil(handlerId) {
        this.spinner.show();
        this.stationService.getDeatil(handlerId).subscribe(
            data => {
                ;
                if (data.responsecode === 1) {
                    this.emailsObj = [];
                    this.emailsObj = data.emails;
                    ;
                    this.emailIdNums = [];
                    this.getDetailsData = data;
                    for (let i = 1; i < this.emailsObj.length; i++) {
                        this.add_email_Field();
                    }
                    const controlArray = <FormArray>this.editStationForm.get('editemails');
                    for (let i = 0; i < this.emailsObj.length; i++) {
                        this.emailIdNums.push(this.emailsObj[i].id)
                        controlArray.controls[i].get('emailid').setValue(this.emailsObj[i].email_id);
                    }
                    this.editStationForm.controls['stationCode'].patchValue(this.StationCode);
                    this.editStationForm.controls['handlerName'].patchValue(this.contactName);
                    this.editStationForm.controls['address'].patchValue(this.address);
                    this.editStationForm.controls['secondAddress'].patchValue(data.address2);
                    this.editStationForm.controls['city'].patchValue(data.city);
                    this.editStationForm.controls['country'].patchValue(data.country);
                    this.editStationForm.controls['postalcode'].patchValue(data.postal_code);
                    this.editStationForm.controls['latitude'].patchValue(data.lattitude);
                    this.editStationForm.controls['longitude'].patchValue(data.longitude);

                    this.editStationForm.controls['orgHandlerName'].patchValue(data.orgHandlerName);
                    this.editStationForm.controls['orgHandlerId'].patchValue(data.orgHandlerId);

                    this.getOrgHandlerId = data.orgHandlerId;

                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                this.spinner.hide();
            },

        )
    }



    getEmailIds(form): Array<any> {
        return form.controls.editemails.controls;
    }
    findDuplicate(emailid, p): boolean {

        let myArray = this.getEmailIds(this.editStationForm);
        let totalArray = [];

        if (emailid != '' && emailid != null && emailid != undefined) {
            for (let i = 0; i < myArray.length; i++) {
                totalArray.push(myArray[i].value)
            }
            this.fn_To_Enable_Submit();

            let test = totalArray.filter(data => data.emailid == emailid && emailid != "");
            return (test.length > 1) ? true : false;
        }

    }

    fn_To_Enable_Submit() {
        let totalArray = [];
        const controlArray = <FormArray>this.editStationForm.get('editemails');
        for (let i = 0; i < controlArray.value.length; i++) {
            totalArray.push(controlArray.value[i].emailid);
        }
        this.isSubmitEnable = this.find_duplicate_in_array(totalArray);
        this.isEnable = this.isSubmitEnable;
        console.log(" this.isSubmitEnable", this.isSubmitEnable)
    }

    find_duplicate_in_array(array) {
        return (new Set(array)).size !== array.length;
    }


}
