import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StationService } from '../../services/station/station.service';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-access-station',
  templateUrl: './access-station.component.html',
  styleUrls: ['./access-station.component.css']
})
export class AccessStationComponent implements OnInit {
  Logindetails: any;
  comapanydetails: any;
  obj: any;
  stationDetails: any;

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private stationService: StationService,private location: LocationStrategy) { 
      // preventing back button in browser   
      history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      }); 
  }

  ngOnInit() {
    ;
    window.scroll(0, 0);
    this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
    if (this.Logindetails.userType === 'SA') {
      this.obj = {
        orgId: this.comapanydetails.orgId
      }
    } else {
      this.obj = {
        orgId: this.Logindetails.orgId
      }
    }
    this.spinner.show();
    this.stationService.newGetStationsCclpPortal(this.obj).subscribe(
      data => {
        ;
        console.log(data)
        this.spinner.hide();

        if (data.responseCode == '1') {
          this.stationDetails = data.stationDetails;
          localStorage.setItem('viewData', JSON.stringify(data));
          this.spinner.hide();
        }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
          this.toastr.info("Your session is expired, please login again");
      } else {

      }

      
      },
      error => {
        this.spinner.hide();
      }
    )
  }

}
