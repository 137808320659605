import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ConfigServiceService } from "src/app/config-service.service";
// const WEB_API_URL = environment.URL;
@Injectable({
  providedIn: "root",
})
export class DamageReportsService {
  loginDetails: any;
  headers: any;
  WEB_API_URL:any ;
  constructor(private http: HttpClient,config : ConfigServiceService) {
    this.WEB_API_URL =    config.getConfig().CARGO_CLAIM_URL


  }
  getDamageReports(mailId, userTypeVal, orgTypeVal, orgId) {
    return this.http.get<any>(
      this.WEB_API_URL +
        "cargoClaim/airportClaimAdmin.php/airlineReports?emailId=" +
        mailId +
        "&userType=" +
        userTypeVal +
        "&orgType=" +
        orgTypeVal +
        "&orgId=" +
        orgId
    );
  }
  getDetailedReportsEmails(awbNum) {
    return this.http.get<any>(
      this.WEB_API_URL +
        "/reportsDetailsCclpPortal.php/getAirlinemails?masterAwbNo=" +
        awbNum
    );
  }
  downloadreports(obj) {
    return this.http.post(
      this.WEB_API_URL + "cargoClaim/claimCsvExport.php/CargoClaimExportDateWise",
      obj,
      { responseType: "text" }
    );
  }
  ReportsCount(obj) {
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/claimCsvExport.php/exportDataCount",
      obj
    );
  }

  viewIssuedAwbs( userId, year, orgId){
    return this.http.get<any>(
      this.WEB_API_URL +
        "cargoClaim/cargoClaimAwbs.php/viewIssueAWb?orgId=" +
        orgId +
        "&year=" +
        year +
        "&userId=" +
        userId 
    );


  }

  saveAwbsIssued(obj){
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/cargoClaimAwbs.php/saveAWB",
      obj
    );
   }

   editAwbsIssued(obj){
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/cargoClaimAwbs.php/editAWB",
      obj
    );
    }
    
    deleteAwbsIssued(obj){
      return this.http.post<any>(
        this.WEB_API_URL + "cargoClaim/cargoClaimAwbs.php/deleteAWB",
        obj
      );
      }

      getAwbCharts(obj){
        return this.http.post<any>(
          this.WEB_API_URL + "cargoClaim/awbCharts.php/awbCharts",
          obj
        );
        }
    

}
