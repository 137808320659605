import { Component, OnInit } from "@angular/core";

import { Location, LocationStrategy } from "@angular/common";
import { Compiler } from "@angular/core";
import { FormControl } from '@angular/forms';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfigServiceService } from "../../../app/config-service.service";
import { ShareLoginDataService } from "../../data/share-login-data.service";
import { NavbarComponent } from "../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../login/login-data.service";
import { LoginComponent } from "../../login/login.component";
import { LoginService } from "../../services/login/login.service";
import { MyAccountService } from "../../services/myAccount/my-account.service";
import { RegisterService } from "../../services/register/register.service";
import { SuperadminService } from "../../services/superadmin/superadmin.service";
declare var jQuery: any;

@Component({
  selector: 'app-kpi-charts-email-settings',
  templateUrl: './kpi-charts-email-settings.component.html',
  styleUrls: ['./kpi-charts-email-settings.component.css']
})
export class KPIChartsEmailSettingsComponent implements OnInit {

  public reportbyAirlineLabelName: any[] = [];
  public ResposibilityLabelName: any[] = [];
  public contentAffectedLabelName: any[] = [];
  public classificationLabelName: any[] = [];
  public reportsbyStationLabelName: any[] = [];
  public damageReportsperMonthLabelsName: any[] = [];
  public reportbyDiscoveredLabelName: any[] = [];
  public damageBehaviourLabelName: any[] = [];
  public damageRulesLabelName: any[] = [];
  public damageEquipmentLabelName: any[] = [];
  public damageOrganisationLabelName: any[] = [];
  public damageInternalAreaLabelName: any[] = [];
  public damageCapaTypeLabelName: any[] = [];
  public damageConditionContentLabelName: any[] = [];
  public damageMethodPackingLabelName: any[] = [];

  public damageReportbyAirlineChart:any;
  public damageResposibilityChart:any;
  public contentAffectedChart:any;
  public classificationChart:any;
  public reportsbyStationChart:any;
  public damageReportsperMonthChart:any;
  public damageReportbyDiscoveredChart:any;
  public damageBehaviourChart:any;
  public damageRulesChart:any;
  public damageMethodPackingChart:any;
  public damageConditionContentChart:any;
  public damageInternalAreaChart:any;
  public damageOrganisationChart:any;
  public damageEquipmentChart:any;
  public damageCapaTypeChart:any;
  hideSelect: boolean= false;


  public filteredList: any = [];
  status1: boolean = false;
  loginComponent: LoginComponent;
  isUnAuthReportAccess: boolean;
  apiCallCurrentTab: any;
  loginuserdetailsTest: any;
  items3: any;
  showitemssq: boolean;
  aspectsFilterObj:any = {};
  loginDetails: any;
  Object: any;
  selectedEmailFrequency: any;
  // selectedChartNames: string[] = [];
  selectedChartNames: any;
  selectedUser:any;
  userName:{ userId: number; firstName: string; loginID: string }[];
  name:string[] = [];
  editMode: boolean = false;
  emailSettingList:any = {};
  orgType : string;
  loginUserId:string;
  allsettingList:any;
  chartNameList:any;
  emailfrequencyList:any;
  allList:any;
  rowUserId:any;
  filter:any;
 deleteId:any;
  selectedMonth: boolean = false;
    dayValue: String ="";
    selectedDate: any;
    selectedDay:any;
  options3: any = {};
  orgKey:string="";
  showEmail='';
  filterValue: string;

  myControl = new FormControl();
  filteredOptions: Observable<{ userId: number; firstName: string;loginID:string }[]>;
  selectAllChecked: boolean = false; 
  selectedIds: number[] = []; 
  unauthp = 1;
  unauthpageIndex = 0;
  unauthpageSize = 10;
  unauthlowValue = 0;
  unauthhighValue = 10;
 
 
  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private atp: AmazingTimePickerService,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private myAccountService: MyAccountService,
    private SidebarComponent: SidebarComponent,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private config: ConfigServiceService
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner,
      
    );
   // this.selectedDate = new Date();
    
   
  }
  ngOnInit() {

    window.scroll(0, 0);
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginUserId = this.loginDetails.userId;
    var orgId = this.loginDetails.orgId;
    this.orgType = this.loginDetails.orgType;
    this.orgKey = this.loginDetails.orgId;


  this.superAdminservice.getUserDetails(orgId,this.loginUserId).subscribe((data) => {
    this.userName = data.userDetails.filter(user => user.userStatus == 'A');

     console.log("112result===>", this.userName);
   
     this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    
  }, (error) => {

  });
    this.spinner.hide();


    this.getAllSettingList();
    this.getchartList();
    this.getChartFilterData();
  
  }

  private _filter(value: any): { userId: number; firstName: string; loginID: string}[] {
    
  
    if (typeof value === 'string') {
     this.filterValue = value.toLowerCase();
    } else if (value && typeof value === 'object') {
      this.filterValue = value.firstName.toLowerCase();
    } else {
      this.filterValue = '';
    }
  
    return this.userName.filter(opt =>
      opt.firstName.toLowerCase().includes(this.filterValue) || 
      opt.userId.toString().includes(this.filterValue)
    );
  }

  displayUser(user?: { userId: number; firstName: string; loginID: string }): string {
    return user ? user.firstName : '';
  }
  onUserSelected(selectedUser: { userId: number; firstName: string; loginID: string }) {
    console.log('User ID:', selectedUser.userId);
    console.log('Email:', selectedUser.loginID);
    this.showEmail =selectedUser.loginID ;
    this.selectedUser = selectedUser.userId;
  }


  //get filter data  |  sourav  | code start
    getChartFilterData() {
      this.aspectsFilterObj.RS = 'all';
      this.aspectsFilterObj.CA = 'all';
      this.aspectsFilterObj.CF = 'all';
      this.aspectsFilterObj.DRM ='all';
      this.aspectsFilterObj.DRS = [];
      this.aspectsFilterObj.DRA = 'all';
      this.aspectsFilterObj.DRMstartDate = "" ;
      this.aspectsFilterObj.DRMendDate = "" ;

      if(this.loginDetails.orgType =='H' && this.loginDetails.userType =='E'){
        this.spinner.show();
        this.aspectsFilterObj.organisationId = this.loginDetails.orgId;
        this.aspectsFilterObj.orderType = "A"//this.orderType;
        this.aspectsFilterObj.loginUserType = this.loginDetails.userType;
        this.aspectsFilterObj.loginUserId = this.loginDetails.userId;
       
        this.superAdminservice.getUserReportInfoFilterChart(this.aspectsFilterObj).subscribe((data) => {
        if(data.responseCode == 1){
            this.eventHandler(data.data);
        }},(error) => {
          this.toastr.error("Report is not generated due to network issue.");
        });
        this.spinner.hide();
      }else if(this.loginDetails.orgType =='A' && this.loginDetails.userType =='E'){
          this.spinner.show();
          this.aspectsFilterObj.orgId = this.loginDetails.orgId;
          this.aspectsFilterObj.reportAccess = this.loginDetails.reportAcess;
          this.aspectsFilterObj.stationCode = this.loginDetails.stationCode;
          this.aspectsFilterObj.awbPrefix = this.loginDetails.awbPrefix;
          this.aspectsFilterObj.userId = this.loginDetails.userId;
          this.aspectsFilterObj.loginUserId = this.loginDetails.userId;
          console.log("payload 248",this.aspectsFilterObj);
          this.superAdminservice.getUserDashboardFilterChart(this.aspectsFilterObj).subscribe((data) => {
              if(data.responseCode == 1){
                  this.eventHandler(data.data);
              }
          },(error) => {
            this.toastr.error("Report is not generated due to network issue.");
          });
          this.spinner.hide();
      }else if(this.loginDetails.orgType =='H' && this.loginDetails.userType =='S'){
        
        this.aspectsFilterObj.organisationId = this.loginDetails.orgId;
        this.aspectsFilterObj.orderType = "A";//this.orderType;
        this.aspectsFilterObj.loginUserType = this.loginDetails.userType;
        this.aspectsFilterObj.loginUserId = this.loginDetails.userId;

        this.superAdminservice.getUserReportInfoFilterChart(this.aspectsFilterObj).subscribe((data) => {
          if(data.responseCode == 1){
            this.eventHandler(data.data);
          } 
        },(error) => {
          this.toastr.error("Report is not generated due to network issue.");
        });
      
      }else if(this.loginDetails.orgType =='A' && this.loginDetails.userType =='S'){
        this.aspectsFilterObj.orgId = this.loginDetails.orgId;
        this.superAdminservice.getCompanyAdminDashboardFilterChart(this.aspectsFilterObj).subscribe((data) => {
          if(data.responseCode == 1){
              this.eventHandler(data.data);
            
          }
        });
      }
    
    
    }

    eventHandler(event: any): void {
      this.spinner.show();
    
      // damageReportbyAirline chart code start by iwi
          this.damageReportbyAirlineChart = event.damageReportbyAirline;
          
          if (this.damageReportbyAirlineChart != null) {
              for (let i = 0; i < this.damageReportbyAirlineChart.length; i++) {
                this.reportbyAirlineLabelName.push(this.damageReportbyAirlineChart[i].airline_name);
              }
          }
      // damageReportbyAirline chart code end by iwi


      // damageReportResposibility chart code start by iwi
          this.damageResposibilityChart = event.damageResponsibilityStatus;
          if (this.damageResposibilityChart != null) {
              for (let i = 0; i < this.damageResposibilityChart.length; i++) {
                  if( this.damageResposibilityChart[i].reportStatus != 'unknown'){
                     this.ResposibilityLabelName.push(this.damageResposibilityChart[i].reportStatus);
                  }
                 
              }
          }
      // damageReportResposibility chart code end by iwi


      // contentAffectedChart chart code start by iwi
          this.contentAffectedChart = event.damageContentAffected;
          if (this.contentAffectedChart != null) {
              for (let i = 0; i < this.contentAffectedChart.length; i++) {
                  if(this.contentAffectedChart[i].discoveresCaused != 'unknown'){
                      this.contentAffectedLabelName.push(this.contentAffectedChart[i].discoveresCaused);
                  }
              }
          }
      // contentAffectedChart chart code end by iwi

      // classificationChart chart code start by iwi
          this.classificationChart = event.damageClassificationType;
          if (this.classificationChart != null) {
              for (let i = 0; i < this.classificationChart.length; i++) {
                  if(this.classificationChart[i].classification_type != 'unknown'){
                      this.classificationLabelName.push(this.classificationChart[i].classification_type);
                  }
              }
          }
      // classificationChart chart code end by iwi

      // reports per month Chart code start by iwi
          this.damageReportsperMonthChart = event.damageReportEveryMonth;
          if (this.damageReportsperMonthChart != null) {
              for (let i = 0; i < this.damageReportsperMonthChart.length; i++) {
                  
                  this.damageReportsperMonthLabelsName.push(this.damageReportsperMonthChart[i].year);

              }
          }

      // reports per month Chart code end by iwi


      // reportsby Station Chart chart code start by iwi
          this.reportsbyStationChart = event.damageReportbyStationCode;
         
          if (this.reportsbyStationChart != null) {
              for (let i = 0; i < this.reportsbyStationChart.length; i++) {
                this.reportsbyStationLabelName.push(this.reportsbyStationChart[i].station_code);
              }
          }
      // reportsby Station Chart chart code end by iwi

      // damageReportbyDiscovered chart code start by iwi
          this.damageReportbyDiscoveredChart = event.damageReportDiscovered;
          if (this.damageReportbyDiscoveredChart != null) {
              for (let i = 0; i < this.damageReportbyDiscoveredChart.length; i++) {
                this.reportbyDiscoveredLabelName.push(this.damageReportbyDiscoveredChart[i].damage_discovered);
              }
          }
      // damageReportbyDiscovered chart code end by iwi


      // damageBehaviourChart chart code start by iwi
          this.damageBehaviourChart = event.damageBehaviourDataResult;
          if (this.damageBehaviourChart != null) {
              for(let i = 0; i < this.damageBehaviourChart.length; i++) {
                   if(this.damageBehaviourChart[i].behaviour != 'unknown'){
                      this.damageBehaviourLabelName.push(this.damageBehaviourChart[i].behaviour);
                  }
              }
          }
        
      // damageBehaviourChart chart code end by iwi


      // damageRulesChart chart code start by iwi
          this.damageRulesChart = event.damageRulesDataResult;
          if (this.damageRulesChart != null) {
              for(let i = 0; i < this.damageRulesChart.length; i++) {
                  if(this.damageRulesChart[i].rules != 'unknown'){
                      this.damageRulesLabelName.push(this.damageRulesChart[i].rules);
                  }
              }
          }
          
          // this.damageRulesChart.getDatasetMeta(1).hidden = true;
          // this.damageRulesChart.update();
      // damageRulesChart chart code end by iwi


      // damageEquipmentChart chart code start by iwi
          this.damageEquipmentChart = event.damageEquipmentDataResult;
  
          if (this.damageEquipmentChart != null) {
              for(let i = 0; i < this.damageEquipmentChart.length; i++) {
                  if(this.damageEquipmentChart[i].equipment != 'unknown'){
                      this.damageEquipmentLabelName.push(this.damageEquipmentChart[i].equipment);
                  }
                 
              }
          }

      // damageEquipmentChart chart code end by iwi


      // damageOrganisationChart chart code start by iwi
          this.damageOrganisationChart = event.damageOrganisationDataResult;
          if (this.damageOrganisationChart != null) {
              for(let i = 0; i < this.damageOrganisationChart.length; i++) {
                  if(this.damageOrganisationChart[i].organisation != 'unknown'){
                      this.damageOrganisationLabelName.push(this.damageOrganisationChart[i].organisation);
                  }
              }
          }

      // damageOrganisationChart chart code end by iwi


      // damageCapaTypeChart chart code start by iwi
          this.damageCapaTypeChart = event.damageCapaTypeDataResult;
          
          if (this.damageCapaTypeChart != null) {
              for(let i = 0; i < this.damageCapaTypeChart.length; i++) {
                  if(this.damageCapaTypeChart[i].CapaType != 'unknown'){
                      this.damageCapaTypeLabelName.push(this.damageCapaTypeChart[i].CapaType);
                  }
               }
          }
         
      // damageCapaTypeChart chart code end by iwi


      // damageInternalAreaChart chart code end by iwi

       

      this.spinner.hide();
    }

    hideDropdown() {
      if (this.aspectsFilterObj.DRM === 'custom') {
        this.hideSelect = true;
      }
    }
  //get filter data  |  sourav  | code end


  getchartList(){

    this.superAdminservice.getchartList().subscribe((res) => {
  
     this.chartNameList = res.data;
     this.emailfrequencyList = res.emailFrequencya;

      
    }, (error) => {
  
    });

  }

  onClickDamage_Reports() {
    this.spinner.show();
    setTimeout(() => {
        
        localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
        if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
        this.route.navigateByUrl('/csa-dashboard');
        }
        else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
        this.route.navigateByUrl('/csa-airline-dashboard');
        }
        else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
        this.route.navigateByUrl('/admin-airline-dashboard');
        }
        else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
        this.route.navigateByUrl('/admin-handler-dashboard');
        }
        else if (this.loginDetails.userType == 'E') {
        this.route.navigateByUrl('/user-dashboard');
        }else if (this.loginDetails.orgType == 'E') {
        this.route.navigateByUrl('/HandlerDashboardComponent');
        }else{
        localStorage.clear()
        this.route.navigateByUrl('/Home');
        }
    }, 200);
}

onClickUldDamage_Reports() {0
    localStorage.setItem("currentModule", JSON.stringify("ULD_DAMAGE"));
    this.route.navigateByUrl("/Uld/viewuld");
}

onClick_CargoClaim() {
    localStorage.setItem("currentModule", JSON.stringify("cargoClaim"));
    this.route.navigateByUrl("/cargoClaim/dashboard");
}

onClick_CargoServiceRecovery(){
    localStorage.setItem("currentModule",JSON.stringify("csa-service-recovery"))
    this.route.navigateByUrl('/csa-service-recovery');
}
onClick_EmailSettings(){
    localStorage.setItem("currentModule",JSON.stringify("kpiChartsEmailSettings"))
    this.route.navigateByUrl('/kpiChartsEmailSettings');
}

onclick_damageCustomerReport(){
  console.log("damageCustomerReport");
  localStorage.setItem("currentModule", JSON.stringify("damageCustomerReport"));
  this.route.navigateByUrl("/damageCustomerReport");
}

onclick_CustomerReportHandler(){
  console.log("customerReportHandler");
  localStorage.setItem("currentModule", JSON.stringify("customerReportHandler"));
  this.route.navigateByUrl("/customerReportHandler");
}
onEmailFrequencyChange() {
  if(this.selectedEmailFrequency==14){
    this.selectedMonth =true;
  }else{
    this.selectedMonth =false;
  }
}

onDayselected() {
 
}

onDateChange(newDate: Date): void {


}

getSelectedChartNames() {

}

// getSelecteduser() {
//   this.name = this.selectedUser;
 
  
// }

_submit(){

  if(!this.selectedUser){
  this.toast.error("please select user ");
  }
  // else if(this.selectedChartNames.length===0){
  //   this.toast.error("please select chart ");
  // }
  else if(!this.selectedEmailFrequency){
    this.toast.error("please select email frequency ");
  }else{

if(this.selectedDate){
  this.dayValue=''
}

  this.emailSettingList = {
    user: this.selectedUser,
    emailSet: this.selectedEmailFrequency,
    email_set_days:this.dayValue,
    email_set_date:this.selectedDate,
    loginUserId: this.loginUserId,
    orgType: this.orgType,
    orgKey:this.orgKey,
    filter: {
      rs: this.aspectsFilterObj.RS,
      ca: this.aspectsFilterObj.CA,
      cf: this.aspectsFilterObj.CF,
      drm: this.aspectsFilterObj.DRM,
      drs: this.aspectsFilterObj.DRS,
      dra: this.aspectsFilterObj.DRA,
      startDate: this.aspectsFilterObj.DRMstartDate,
      endDate: this.aspectsFilterObj.DRMendDate
    }
  
  };
  // return false;
this.spinner.show();
  this.superAdminservice.submitEmailsettingKpi(this.emailSettingList).subscribe(
    (data) => {
      
        if (data.responseCode === 1) {
          this.spinner.hide();
        this.selectedUser = "",
        this.selectedEmailFrequency= "",
        this.aspectsFilterObj.RS = 'all';
        this.aspectsFilterObj.CA = 'all';
        this.aspectsFilterObj.CF = 'all';
        this.aspectsFilterObj.DRM = 'all';
        this.aspectsFilterObj.DRS = [];
        this.aspectsFilterObj.DRA = 'all';
        this.aspectsFilterObj.DRMstartDate = "" ;
        this.aspectsFilterObj.DRMendDate = "" ;
        this.dayValue="";
        this.selectedDate=null;
        this.hideSelect = false;
        this.selectedMonth=false;
        this.editMode = false;
        this.showEmail="";
        this.myControl.setValue(null, { emitEvent: false });
        this.toast.success("Email Settings have been saved successfully");
        this.getAllSettingList(); 

      } else {
        this.spinner.hide();
        this.toast.error("Something Went Wrong");

      }
    },
  (error) => {
    this.spinner.hide();
  });
  }
}


getAllSettingList(){
  this.spinner.show();
  this.superAdminservice.getemailSettingList(this.loginUserId,this.orgType).subscribe((data) => {
    this.allList=data.data;
    console.log("line 620==>",this.allList);
    this.spinner.hide();
  }, (error) => {

  });
}


editsettings(item: any){
    console.log("edit item 556 ",item);

    this.editMode = true;
    var result = item.charts_id.map(function (x) { 
      return parseInt(x, 10); 
    });
    
    const userId = item.user_id;
    const user = this.userName.find(user => user.userId === userId);
    console.log("line no 641===>",user.firstName);
    this.myControl.setValue(user, { emitEvent: false }); 

    this.selectedEmailFrequency = item.email_frequency_id;
    this.selectedChartNames = result;
    this.selectedUser = item.user_id;
    this.showEmail = item.user_email;
    this.rowUserId = item.id;
    this.dayValue =item.email_set_days;
    if(!item.email_set_days){
      this.selectedMonth = true;
      this.selectedDate =item.email_set_date;
    }else{
      this.selectedMonth = false;
    }
    console.log("  this.aspectsFilterObj.DRM => ",item.chart_filters.drm);
    this.aspectsFilterObj.RS  = item.chart_filters.rs;    //'all';
    this.aspectsFilterObj.CA  = item.chart_filters.ca;    //'all';
    this.aspectsFilterObj.CF  = item.chart_filters.cf;    //'all';
    this.aspectsFilterObj.DRS = item.chart_filters.drs;   // 'all';
    this.aspectsFilterObj.DRA = item.chart_filters.dra;   // 'all';
    const selectedOption:string = item.chart_filters.drm; //['custom', 'all'];
    this.aspectsFilterObj.DRM = typeof selectedOption === 'undefined' ? 'custom' : (selectedOption == 'all' ? item.chart_filters.drm : this.justNumbers(item.chart_filters.drm));//'all';

     

    console.log("  this.aspectsFilterObj.DRM => ",item.chart_filters.drm);
    if(item.chart_filters.endDate != undefined && item.chart_filters.startDate != undefined){
      this.aspectsFilterObj.DRMstartDate = item.chart_filters.startDate ;
      this.aspectsFilterObj.DRMendDate = item.chart_filters.endDate;

      this.aspectsFilterObj.DRM = 'custom';
      this.hideSelect = true;
    }else{
      this.hideSelect = false;
    }

}

justNumbers(string) {
  var numsStr = string.replace(/[^0-9]/g, '');
  return parseInt(numsStr);
}

_updateSetting(){

  if(!this.selectedUser){
    this.toast.error("please select user ");
    }
    // else if(this.selectedChartNames.length===0){
    //   this.toast.error("please select chart ");
    // }
    else if(this.selectedEmailFrequency.length===0  && this.selectedEmailFrequency === null ){
      this.toast.error("please select email frequency ");
    }else{

      if(this.selectedDate){
        this.dayValue=''
      }
      
    
    this.emailSettingList = {
      user: this.selectedUser,
    //  chartList: this.selectedChartNames,
      emailSet: this.selectedEmailFrequency,
      email_set_days:this.dayValue,
      email_set_date:this.selectedDate,
      id:this.rowUserId,
      orgType:this.orgType,
      filter: {
        rs: this.aspectsFilterObj.RS,
        ca: this.aspectsFilterObj.CA,
        cf: this.aspectsFilterObj.CF,
        drm: this.aspectsFilterObj.DRM,
        drs: this.aspectsFilterObj.DRS,
        dra: this.aspectsFilterObj.DRA,
        startDate: this.aspectsFilterObj.DRMstartDate,
        endDate: this.aspectsFilterObj.DRMendDate
      }
    };
    console.log("line 719==>",this.emailSettingList);
    //return false;
    this.spinner.show();
    this.superAdminservice.updateEmailsettingKpi(this.emailSettingList).subscribe((data) => {
             if(data.responseCode == 1){
              this.spinner.hide();
        this.selectedUser = "",
        this.selectedEmailFrequency= "",
        this.aspectsFilterObj.RS = 'all';
        this.aspectsFilterObj.CA = 'all';
        this.aspectsFilterObj.CF = 'all';
        this.aspectsFilterObj.DRM = 'all';
        this.aspectsFilterObj.DRS = [];
        this.aspectsFilterObj.DRA = 'all';
        this.aspectsFilterObj.DRMstartDate = "" ;
        this.aspectsFilterObj.DRMendDate = "" ;
        this.dayValue="";
        this.selectedDate=null;
        this.hideSelect = false;
        this.selectedMonth=false;
        this.editMode = false;

        this.toast.success("Email Settings have been updated successfully");
        this.getAllSettingList(); 
        
      
       }else{
        this.spinner.hide();
        this.toast.error("Something Went Wrong");
       }
      
    }, (error) => {
      this.spinner.hide();
    });
    }

}


reset(){
  this.selectedUser = "",
  this.selectedEmailFrequency= "",
  this.aspectsFilterObj.RS = 'all';
  this.aspectsFilterObj.CA = 'all';
  this.aspectsFilterObj.CF = 'all';
  this.aspectsFilterObj.DRM = 'all';
  this.aspectsFilterObj.DRS = [];
  this.aspectsFilterObj.DRA = 'all';
  this.aspectsFilterObj.DRMstartDate = "" ;
  this.aspectsFilterObj.DRMendDate = "" ;
  this.dayValue="";
  this.selectedDate=null;
  this.hideSelect = false;
  this.selectedMonth=false;
  this.editMode = false;
  this.showEmail="";
  this.myControl.setValue(null, { emitEvent: false });
}


// delete email setings

deletesettings(item: any){

  jQuery('#deletepopup').modal('show');
  this.deleteId = item.id;
  
}

confirmDelete(){
 
  let paylod={
    id:this.deleteId
 }
//return false;
this.spinner.show();
 this.superAdminservice.deleteEmailsettingKpi(paylod).subscribe((data) => {
   jQuery('#deletepopup').modal('hide');
   this.spinner.hide();
   this.getAllSettingList(); 
  
}, (error) => {
  this.spinner.hide();
});
}

sendMail(){

  if(!this.selectedUser){
    this.toast.error("please select user ");
    }
    // else if(this.selectedChartNames.length===0){ 
    //   this.toast.error("please select chart ");
    // }
    // else if(!this.selectedEmailFrequency){
    //   this.toast.error("please select email frequency ");
    // }
    else{

      this.spinner.show();
  
    this.emailSettingList = {
      user: this.selectedUser,
      emailSet: this.selectedEmailFrequency,
      email_set_days:this.dayValue,
      email_set_date:this.selectedDate,
      loginUserId: this.loginUserId,
      orgType: this.orgType,
      orgKey:this.orgKey,
      filter: {
        rs: this.aspectsFilterObj.RS,
        ca: this.aspectsFilterObj.CA,
        cf: this.aspectsFilterObj.CF,
        drm: this.aspectsFilterObj.DRM,
        drs: this.aspectsFilterObj.DRS,
        dra: this.aspectsFilterObj.DRA,
        startDate: this.aspectsFilterObj.DRMstartDate,
        endDate: this.aspectsFilterObj.DRMendDate
      }
    
    };

     this.superAdminservice.instantMail(this.emailSettingList).subscribe((data) => {
     console.log("response====|>",data);
     if (data.responseCode === 1) {
      this.spinner.hide();
      this.toast.success("Email Settings have been saved successfully");
     }else{
      this.spinner.hide();
      this.toast.error("Email not send due to server issue");
     }
  
  
}, (error) => {
  this.spinner.hide();
});
    

  }

}

singleRowMail(object:any){
  console.log("mail send from row--->",object);

  this.spinner.show();
  
  this.emailSettingList = {
    email_settings_id: [object.id],
    loginUserId: object.user_id,
    orgType: this.orgType,
    orgKey:this.orgKey,  
  
  };
  this.superAdminservice.rowMail(this.emailSettingList).subscribe((data) => {
    console.log("response====|>",data);
    if (data.responseCode === 1) {
     this.spinner.hide();
     this.toast.success("Email Settings have been saved successfully");
    }else{
     this.spinner.hide();
     this.toast.error("Email not send due to server issue");
    }
 
 
}, (error) => {
 this.spinner.hide();
});
   


}


multiEmailSend(){ 
  this.spinner.show();
  
  this.emailSettingList = {
    email_settings_id:this.selectedIds,
    loginUserId:this.loginUserId,
    orgType: this.orgType,
    orgKey:this.orgKey,  
  
  };
  
this.superAdminservice.rowMail(this.emailSettingList).subscribe((data) => {
  console.log("response====|>",data);
  if (data.responseCode === 1) {
   this.spinner.hide();
   this.toast.success("Email Settings have been saved successfully");
  }else{
   this.spinner.hide();
   this.toast.error("Email not send due to server issue");
  }


}, (error) => {
this.spinner.hide();
});
}

// table select and un select function

  
  toggleSelection(object: any) {
    if (object.isSelected) {
     
      this.selectedIds.push(object.id);
    } else {
      
      const index = this.selectedIds.indexOf(object.id);
      if (index !== -1) {
        this.selectedIds.splice(index, 1);
      }
    }

    
    this.selectAllChecked = this.allList.every(item => item.isSelected);

    console.log("Selected IDs (single/multi-select):", this.selectedIds);
  }

 
  toggleSelectAll() {
    this.selectedIds = []; 

    this.allList.forEach(item => {
      item.isSelected = this.selectAllChecked;
      if (this.selectAllChecked) {
       
        this.selectedIds.push(item.id);
      }
    });
    console.log("Selected IDs (Select All):", this.selectedIds);
  }

  
}


