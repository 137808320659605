import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { globalConstants } from 'src/environments/globalConstants';
import { AuthenticateGuard } from '../AuthGuard/authenticate.guard';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { ViewShipmentDetailsComponent } from './view-shipment-details/view-shipment-details.component';
import { ViewShipmentsComponent } from './view-shipments/view-shipments.component';

const routes: Routes = [
  { path: 'create-shipment', component: CreateShipmentComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }},
  { path: 'view-shipment', component: ViewShipmentsComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }},
  { path: 'view-shipment-details', component: ViewShipmentDetailsComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] } },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CargoImageRoutingModule { }
