import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TruckInspectionService } from 'src/app/services/truckInspection/truck-inspection.service';
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { ConfigServiceService } from 'src/app/config-service.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DateAndTimeFunctions } from '../../shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
@Component({
  selector: 'app-view-truckinspection-details',
  templateUrl: './view-truckinspection-details.component.html',
  styleUrls: ['./view-truckinspection-details.component.css']
})
export class ViewTruckinspectionDetailsComponent implements OnInit {
  LoginDetails: any;
  detailedRecord: any;
  old_Images = [];
  webapi: any;
  modifiedReports=[];
  selectedId: any;
  TRUCK_INSPECTION_ID: any;
  constructor(private api_servc: TruckInspectionService,
    private route: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private config: ConfigServiceService,
    private sanitizer: DomSanitizer
  ) {
    this.webapi = this.config.getConfig().ULD_Url;
  }

  ngOnInit() {
   this.TRUCK_INSPECTION_ID   =JSON.parse(localStorage.getItem("TRUCK_INSPECTION_ID"))  ;
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.getDetailedReport();
  }

  getDetailedReport(){
    this.spinner.show();
    let inputObj = {
      "orgId": this.LoginDetails.orgId,
      "loginUserId": this.LoginDetails.userId,
      "inspectionId": this.TRUCK_INSPECTION_ID
    }
    this.api_servc.viewDetailedRecord(inputObj).subscribe(responseData => {
      this.spinner.hide();
       
      if (responseData['responseCode'] === 1) {
        this.detailedRecord = responseData['inspectionDetails'];
        this.old_Images = responseData['inspectionDetails']['inspectionImages'];
        this.modifiedReports = (responseData['modifiedReports'] !== null && responseData['modifiedReports'] !== undefined )? responseData['modifiedReports'] :  [];
      } else {
        this.toastr.error(responseData['message']);
      }
    }, error => {
      this.toastr.error("something went wrong , please try later");
      this.spinner.hide();
    })
  }
  
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
}

  navigateToView() {
    this.spinner.show();
    setTimeout(() => {
      this.route.navigateByUrl("/Truckinspection/view-truck-inspection");
    }, 500);
  }

  ngOnDestroy() {
    this.spinner.hide();
  }
  ViewSelectedData(SelectedRowData,index) {
    ;
     (SelectedRowData.id !== null && SelectedRowData.id !== undefined && SelectedRowData.id !== '') ? ( this.selectedId = SelectedRowData.id, this.getModifiedReportData(this.selectedId)): (this.selectedId  = SelectedRowData.id ,this.getDetailedReport()) ;
  }

  getModifiedReportData(id){
    this.spinner.show();
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    let inputObj = {
      "id": id,
      "loginUserId": this.LoginDetails.userId,
    }
    this.api_servc.viewModifiedRecord(inputObj).subscribe(responseData => {
      this.spinner.hide();
      
      if (responseData['responseCode'] === 1) {
        this.detailedRecord = responseData['inspectionDetails'];
        this.old_Images = responseData['inspectionDetails']['inspectionImages'];
        // this.modifiedReports = responseData['modifiedReports'];
      } else {
        this.toastr.error(responseData['message']);
      }
    }, error => {
      this.toastr.error("something went wrong , please try later");
      this.spinner.hide();
    })
    
  }

  convertDate(date){
   return  DateAndTimeFunctions.convertUTCDateToLocalDate(date);
  }
}
