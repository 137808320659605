import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SuperadminService } from "../../services/superadmin/superadmin.service";
import { NgxSpinnerService } from "ngx-spinner";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  VERSION,
} from '@angular/material';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-edituser-org',
  templateUrl: './edituser-org.component.html',
  styleUrls: ['./edituser-org.component.css']
})
export class EdituserOrgComponent implements OnInit {
  subscription: Subscription;
  errorMessageShow: boolean = false;
  editable: boolean;
  userOrgObj: any;
  editUserOrgForm: FormGroup;
  loginDetails: any;
  orgList: any;
  orgName: FormControl;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  filteredStates: Observable<any>;
  constructor(private router: Router, private formBuilder: FormBuilder,
    private SuperadminService: SuperadminService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,private location: LocationStrategy
    ) {
    this.orgName = new FormControl('', [Validators.required]);
    this.filteredStates = this.orgName.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.orgList)
      );
      // preventing back button in browser   
      history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });

  }

  ngOnInit() {
    this.userOrgObj = JSON.parse(localStorage.getItem("userOrgObj"));
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    // let EMAILPATTERN =
    //   "(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}";
    // let EMAILPATTERN = "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}"
    this.editUserOrgForm = this.formBuilder.group({
      orgName: new FormControl("", [Validators.required]),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      loginId: new FormControl("", [
        Validators.required,
        Validators.pattern(globalConstants.EMAIL_PATTERN),
      ]),
      stationCode: new FormControl("", [Validators.required]),
      roleType: new FormControl("", [Validators.required]),
      organisationId: new FormControl("", [Validators.required]),
      userId: new FormControl(this.userOrgObj.userId),
      loginUserId: new FormControl(this.loginDetails.userId),

    });
    this.orgName.setValue(this.userOrgObj.orgName);
    this.editUserOrgForm.get("orgName").setValue(this.orgName.value);
    this.editUserOrgForm.get("firstName").setValue(this.userOrgObj.firstName);
    this.editUserOrgForm.get("lastName").setValue(this.userOrgObj.lastName);
    this.editUserOrgForm.get("loginId").setValue(this.userOrgObj.loginID);
    this.editUserOrgForm.get("stationCode").setValue(this.userOrgObj.stationCode);
    this.editUserOrgForm.get("roleType").setValue(this.userOrgObj.userType);
    this.editUserOrgForm.get("organisationId").setValue(this.userOrgObj.orgId);
    this.editUserOrgForm.get("userId").setValue(this.userOrgObj.userId);
    this.editUserOrgForm.get("loginUserId").setValue(this.loginDetails.userId);

    // userId", "firstName", "lastName", "loginId", "organisationId", "stationCode
    const Obj = {
      loginUserId: this.loginDetails.userId,
      organisationType: this.userOrgObj.orgType
    }
    this.SuperadminService.GetOrgList(Obj).subscribe(
      data => {
        ;
        if (data.responseCode === 1) {
          this.orgList = data.orgDetails;
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
        }


      },
      error => {
        this.spinner.hide();
      }
    )

  }
  filterStates(orgName: string) {
    ;
    if (this.orgList === undefined) {
      return null
    } else {
      this.errorMessageShow = false;
      return this.orgList.filter(state =>
        state.orgName.toLowerCase().indexOf(orgName.toLowerCase()) === 0);
    }
  }
  onEnter(state) {
    ;
    console.log(state)
    this.editUserOrgForm.controls['organisationId'].patchValue(state.orgKey);
  }
  private _subscribeToClosingActions(): void {
    ;
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.trigger.panelClosingActions
      .subscribe(e => {
        ;
        if (!e || !e.source) {
          this.orgName.setValue(null);
          this.errorMessageShow = true;
          console.log(this.editUserOrgForm.controls['orgName'].value)
          // } 
        }
      },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());
  }


  onSelect(state) {
    ;
    console.log(state)
    this.editUserOrgForm.controls['orgName'].setValue(state.orgName);

  }
  fn_editUserOrgSubmit(editUserOrgForm) {
    ;
    if (this.editUserOrgForm.pristine && this.orgName.pristine) {
      this.toastr.error('No fields Updated');
    } else {
      console.log(editUserOrgForm);
      this.spinner.show();
      this.SuperadminService.editUserOrgn(editUserOrgForm).subscribe((data) => {
        ;
        if (data.responseCode === 1) {
          this.toastr.success(data.message);
          this.spinner.hide();
          this.router.navigate(['/allusers']);
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(data.message);
        }
      });
    }

  }
  setOption(event) {
    ;
    this.editUserOrgForm.get("roleType").patchValue(event.value);
  }
  allUserPage() {
    this.router.navigateByUrl("/allusers");
  }
  ngAfterViewInit() {
    this._subscribeToClosingActions();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

}
