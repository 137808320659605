export const globalConstants = {
  JQWIDGET_PAGEsIZESARRAY: ["25", "50", "100", "200", "500"],
  JQWIDGET_DEFAULT_PAGESIZE: 25,
  EMAIL_PATTERN: "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z0-9]{2,}",
  MOBILE_NUM_PATTERN: "",
  VERSION_NUMBER: "9.7.0",

  HANDLER_ORG_CD: "H",
  FORWARDER_ORG_CD: "E",
  AIRLINE_ORG_CD: "A",
  SUPERADMIN_ORG_CD: "SA",

  CSA_USERTYPE_CD: "S",
  ADMIN_USERTYPE_CD: "A",
  USER_USERTYPE_CD: "E",
  SUPERADMIN_USERTYPE_CD: "SA",

  CSA_HANDLER_AUTH_TXT: "CSA_HANDLER",
  ADMIN_HANDLER_AUTH_TXT: "ADMIN_HANDLER",
  USER_HANDLER_AUTH_TXT: "USER_HANDLER",

  CSA_FORWARDER_AUTH_TXT: "CSA_FORWARDER",
  ADMIN_FORWARDER_AUTH_TXT: "ADMIN_FORWARDER",
  USER_FORWARDER_AUTH_TXT: "USER_FORWARDER",

  CSA_AIRLINE_AUTH_TXT: "CSA_AIRLINE",
  ADMIN_AIRLINE_AUTH_TXT: "ADMIN_AIRLINE",
  USER_AIRLINE_AUTH_TXT: "USER_AIRLINE",

  SUPERADMIN_AUTH_TXT: "SUPER_ADMIN",
};
