import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from '../../config-service.service';
@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  API_URL:string ;
  ULD_Url: any;
  
  constructor(public http: HttpClient,config : ConfigServiceService) {
   this.API_URL =    config.getConfig().URL
   this.ULD_Url = config.getConfig().ULD_Url
   }

  api_To_Create_Shipment(inputData){
    return this.http.post<any>(this.API_URL + 'registershipments/createShipementReg.php/shipmentRegister',inputData);
  }
  api_To_Update_Shipment(inputData){
    return this.http.post<any>(this.API_URL + 'registershipments/createShipementReg.php/updateShipment',inputData);
  }
  api_To_Archive_Shipment(inputData){
    return this.http.post<any>(this.API_URL + 'registershipments/createShipementReg.php/archiveShipment',inputData);
  }
  api_To_GetShipment_Details(inputData){
    return this.http.post<any>(this.API_URL + 'registershipments/createShipementReg.php/getShipment',inputData);
  }

  // handler cargo image apis
  api_To_Create_Shipment_handler(inputData){
    return this.http.post<any>(this.API_URL + 'handlerregistershipments/HandlerShipementReg.php/shipmentRegister',inputData);
  }
  api_To_Update_Shipment_handler(inputData){
    return this.http.post<any>(this.API_URL + 'handlerregistershipments/HandlerShipementReg.php/updateShipment',inputData);
  }
  api_To_Archive_Shipment_handler(inputData){
    return this.http.post<any>(this.API_URL + 'handlerregistershipments/HandlerShipementReg.php/archiveShipment',inputData);
  }
  api_To_GetShipment_Details_handler(inputData){
    return this.http.post<any>(this.API_URL + 'handlerregistershipments/HandlerShipementReg.php/getShipment',inputData);
  }

  api_To_Cargoacceptance_Details(inputData){
    return this.http.post<any>(this.ULD_Url + 'cargoAccept/getDetailedCargoAccept.php/detailedCargoAccept',inputData);
  }
  api_To_ModifiedReport_View(inputData){
    return this.http.post<any>(this.ULD_Url + "cargoAccept/getModifiedCargoAccept.php/modifiedCargoAccept", inputData) ;
}
}
