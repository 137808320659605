import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticateGuard } from 'src/app/AuthGuard/authenticate.guard';
import { CreateUldComponent } from '../create-uld/create-uld.component';
import { DetailedUldComponent } from '../detailed-uld/detailed-uld.component';
import { ViewUldComponent } from '../view-uld/view-uld.component';
import { globalConstants } from 'src/environments/globalConstants';
const routes: Routes = [

   { path: 'createuld', component: CreateUldComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT , globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT ,globalConstants.USER_AIRLINE_AUTH_TXT] } },
    { path: 'viewuld', component: ViewUldComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT, globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT ,globalConstants.USER_AIRLINE_AUTH_TXT] } },
    { path: 'DetailedUld', component: DetailedUldComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT, globalConstants.USER_HANDLER_AUTH_TXT, globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT ,globalConstants.USER_AIRLINE_AUTH_TXT ] } },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UldDamageRoutingModule { }
