import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "../services/login/login.service";
import { NgxSpinnerService } from "ngx-spinner";
import { LoginDataService } from "../login/login-data.service";
import { ShareLoginDataService } from "../data/share-login-data.service";
import { SidebarComponent } from "../layouts/sidebar/sidebar.component";
import { CookieService } from "ngx-cookie-service";
declare var jQuery: any;

@Component({
  selector: "app-switch-to-cclp",
  templateUrl: "./switch-to-cclp.component.html",
  styleUrls: ["./switch-to-cclp.component.css"],
})
export class SwitchToCclpComponent implements OnInit {
  obj;
  cookieValue: string;
  errormsg: string;

  constructor(
    private cookieService: CookieService,
    private ShareLoginDataService: ShareLoginDataService,
    private SidebarComponent: SidebarComponent,
    private LoginDataService: LoginDataService,
    private router: Router,
    private toastr: ToastrService,
    private loginservice: LoginService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.obj = route.snapshot.params
    console.log(this.obj);
  }

  ngOnInit() {
    this.cookieService.deleteAll();
    localStorage.clear();
    this.spinner.show();
    setTimeout(() => {
      this.loginservice.switchCCLP(this.obj).subscribe(
        (data) => {
          console.log(data);
          this.ShareLoginDataService.updateData(data);
          this.LoginDataService.emitLoginData(data);
          this.spinner.hide();
          if (data.responseCode == "1") {
            jQuery("#login").modal("hide");

            localStorage.setItem("loginresultobject", JSON.stringify(data));
            localStorage.setItem(
              "logindetailsForSwitching",
              JSON.stringify(data)
            );

            localStorage.setItem("SessionToken", data.token);
            localStorage.setItem("Token", JSON.stringify(data.token));
            localStorage.setItem("loginDetails", JSON.stringify(data));
            let obj = {
              emailId: "",
              pwd: "",
            };
            localStorage.setItem(
              "credentialsForRememberMe",
              JSON.stringify(obj)
            );
            var expire = new Date();
            var time = Date.now() + 3600 * 1000 * 8;
            expire.setTime(time);
            this.cookieService.set(
              "cclp",
              JSON.stringify(data.token),
              expire,
              "/",
              ".cclp.aero",
              true,
              "None"
            );
            this.cookieValue = this.cookieService.get("cclp");
            console.log("set cookie value of cclp", this.cookieValue);
            if (data.userType == "SA") {
              this.toastr.success("Login Successfully", "", { timeOut: 1500 });
              this.SidebarComponent.activecall("1");
              this.router.navigateByUrl("/sa-dashboard");
            } else if (data.orgType === "E") {
              this.toastr.success("Login Successfully", "", { timeOut: 1500 });
              this.router.navigateByUrl("/HandlerDashboardComponent");
            } else {
              this.toastr.success("Login Successfully", "", { timeOut: 1500 });
              this.router.navigateByUrl("/HandlerDashboardComponent");
              // this.router.navigateByUrl('/user-dashboard')
            }
          } else {
            this.toastr.error(data.message, "", { timeOut: 1500 });
            this.router.navigateByUrl("/#/Home");
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error('Server error. Connecting to Server...', "", { timeOut: 1500 });
        }
      );
    }, 5000);
  }
}
