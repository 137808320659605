import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StationService } from 'src/app/services/station/station.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    VERSION,
} from '@angular/material';

import { catchError } from 'rxjs/operators/catchError';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from 'src/app/services/user/user.service';

import { User } from '../../../models/user.model'

import { globalConstants } from 'src/environments/globalConstants';


@Component({
    selector: 'app-createuser',
    templateUrl: './createuser.component.html',
    styleUrls: ['./createuser.component.css']
})
export class CreateuserCargoClaimComponent implements OnInit, AfterViewInit, OnDestroy {
    createUserForm: FormGroup;
    Logindetails: any;
    stationsListObj: any;
    stationsList: Observable<any[]>;
    stationCode: FormControl;
    obj: { orgId: any; };
    subscription: Subscription;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    Obj: any;
    companydetails: any;

    userModel = new User(0, "", "", "", "", "", "", "", "", null, "", "", "", "", null, null, null, "", "", null);
    CompanyLogoimgurl: string;
    Object: any;
    CompanyName: string;
    loginObj: any;
    stationCodeTemp: any;
    constructor( private location: Location, private router: Router, private spinner: NgxSpinnerService, private userService: UserService, private formBuilder: FormBuilder, private toastr: ToastrService, 
        private StationsService: StationService,private locationStr: LocationStrategy) {
        this.stationCode = new FormControl('', [Validators.required]);
        this.location = location;
          // preventing back button in browser   
        //   history.pushState(null, null, window.location.href);  
        //   this.locationStr.onPopState(() => {
        //     history.pushState(null, null, window.location.href);
        //   }); 
    }

    ngOnInit() {
        ;
        window.scroll(0, 0);
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log('%c%s', 'color: #ff6701', this.Logindetails);
        if (this.Logindetails.userType === 'SA') {
            this.obj = {
                orgId: this.companydetails.orgId
            }
        } else if (this.Logindetails.userType === 'S')
            this.obj = {
                orgId: this.Logindetails.orgId
            }

        if (this.companydetails === null) {
            this.CompanyLogoimgurl = '';
            this.CompanyName = this.Object.organisationName
        } else {
            this.CompanyLogoimgurl = this.companydetails.orgLogo
            this.CompanyName = this.companydetails.orgName
        }
        // Create User Form initialisation
        this.createUserForm = this.formBuilder.group({
            'firstName': new FormControl(''),
            'lastName': new FormControl(''),
            'loginId': new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            'stationCode': new FormControl('', [Validators.required]),
            'contactNumber': new FormControl('', [Validators.pattern('[0-9]\\d{9}')]),
            'address': new FormControl(''),
            'organizationType': new FormControl(this.Logindetails.userId),
            'userId': new FormControl(this.Logindetails.userId),
            'iswebormobile': new FormControl('0'),
            'organizationId': new FormControl(this.Logindetails.orgId),
            'dob': new FormControl(''),
            'userType': new FormControl(this.Logindetails.userType),
            'landLine': new FormControl(''),
            'hintQuestion': new FormControl(''),
            'hintAnswer': new FormControl(''),
            'createdBy': new FormControl(this.Logindetails.userId),
            'branchId': new FormControl('1'),
            'regionId': new FormControl('1'),
            'userPrivileges': new FormControl(''),
            'jobTitle': new FormControl(''),
            'roleType': new FormControl(this.Logindetails.userType)
        });

        this.spinner.show();
        // Stations service
        this.StationsService.newGetStationsCclpPortal(this.obj).subscribe(
            data => {
                ;
                if (data.responseCode === 1) {
                    console.log('stations Data', data);
                    this.stationsListObj = data.stationDetails;
                    this.stationsList = this.stationCode.valueChanges
                        .pipe(
                            startWith(''),
                            map(state => state ? this.filterStations(state) : this.stationsListObj)
                        );

                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                }
                this.spinner.hide();
            },
            error => {
                ;
                this.spinner.hide();
                console.log(error)
                this.toastr.error('Unable to fetch stations list')

            });
    }
    createUser() {
        ;

        if (this.createUserForm.value.firstName != '' && this.createUserForm.value.firstName != null) {
            this.createUserForm.controls['firstName'].patchValue(this.createUserForm.value.firstName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if (this.createUserForm.value.lastName != '' && this.createUserForm.value.lastName != null) {
            this.createUserForm.controls['lastName'].patchValue(this.createUserForm.value.lastName.replace(/[^a-zA-Z\s]*/g, ''));
        }

        if (this.stationCodeTemp !== this.stationCode.value) {
            this.stationCodeTemp = undefined;
            return;
        }

        console.log(this.userModel)
        this.spinner.show();
        console.log(this.createUserForm.value);
        this.companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.Obj.userType === 'S') {
            this.createUserForm.controls['organizationType'].setValue(this.Obj.orgType);
            this.createUserForm.controls['userType'].setValue(this.Obj.userType);
            this.createUserForm.controls['organizationId'].setValue(this.Obj.orgId);
        } else if (this.Obj.userType === 'SA') {
            let companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
            this.createUserForm.controls['organizationType'].setValue(companydetails.orgType);
            this.createUserForm.controls['userId'].setValue(companydetails.userId);
            this.createUserForm.controls['organizationId'].setValue(companydetails.orgId);
        }

        // Create User service
        this.userService.createUser(this.createUserForm.value).subscribe(Response => {
            ;
            console.log('Response', Response)
            if (Response.responseCode === 1) {
                this.toastr.success(Response.message, '', { timeOut: 1200 });
                this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
                this.spinner.hide();
                if (this.Obj.orgType === 'SA') {
                    if (this.companydetails !== undefined && this.companydetails.orgType === "E") {
                        this.location.back();
                    } else {
                        this.router.navigate(['cargoClaim/view-users', this.companydetails.orgId]);
                    }
                }
                else if (this.Obj.userType === 'S') {
               const usersFrom = "SidebarAllUsers"
                localStorage.setItem('stationsFrom', JSON.stringify(usersFrom));
        
              this.router.navigate(["cargoClaim/view-users"], { queryParams: { from: 'SidebarAllUsers' } });
        
                }

            } else if (Response.responseCode === 0 && Response.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            }
            else {
                this.toastr.error(Response.message, '', { timeOut: 1200 });
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(error.message, '', { timeOut: 1200 });
            })
    }
    backtodashboard() {
        ;
        this.loginObj = JSON.parse(localStorage.getItem('loginresultobject'));

        if (this.loginObj.orgType === 'SA') {
            this.Obj = JSON.parse(localStorage.getItem('comapanydetails'));
        } else {
            this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
        }

        if (this.Obj.orgType === "E") {
            
            this.location.back();
        } else {
            let isFrom  = JSON.parse(localStorage.getItem('stationsFrom'));
            if((isFrom === "SidebarAllUsers") && (this.loginObj.userType === "S")){
                    this.router.navigate(["cargoClaim/view-users"], { queryParams: { from: 'SidebarAllUsers' } });
            }else{
                this.router.navigate(["cargoClaim/view-users", this.Obj.orgId]);
            }
        }
    }

    //filtering  Stations
    filterStations(code: string) {
        ;
      
        console.log(this.stationsListObj);
        return this.stationsListObj.filter(state =>
            state.portCode.toLowerCase().indexOf(code.toLowerCase()) === 0);
    }

    onstationSelect(state): void {
        ;
        console.log('state', state)
        this.stationCode.setValue(state.portCode);
        this.stationCodeTemp = state.portCode;
        this.stationCode.setErrors(null);
        this.createUserForm.controls['stationCode'].setValue(this.stationCode.value);
        this.createUserForm.controls['stationCode'].setErrors(null);
        this.createUserForm.controls['address'].patchValue(state.address);
        this.createUserForm.updateValueAndValidity();
    }


    ngAfterViewInit() {
        this._subscribeToClosingActions();
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    private _subscribeToClosingActions(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.trigger.panelClosingActions
            .subscribe(e => {
                if (!e || !e.source) {
                    this.stationCode.setValue(null);
                    this.stationCode.setErrors({ 'incorrect': true });
                    this.stationCode.updateValueAndValidity();
                    this.createUserForm.controls['stationCode'].setValue(null);
                    this.createUserForm.controls['stationCode'].setErrors({ 'incorrect': true });
                    this.createUserForm.updateValueAndValidity();
                }
            },
                err => this._subscribeToClosingActions(),
                () => this._subscribeToClosingActions());
    }

}
