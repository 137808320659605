import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {
  private appConfig;
  constructor(public _http:HttpClient) { }


  loadConfig(){
    return this._http.get("assets/apiConfigs.json").toPromise().then(res => {
                 this.appConfig  = res ;
                 console.log( " this.appConfig this.appConfig",this.appConfig)
    })
  }

  getConfig(){
    return this.appConfig;
  }
}
