import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigServiceService } from 'src/app/config-service.service';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-view-records-table',
  templateUrl: './view-records-table.component.html',
  styleUrls: ['./view-records-table.component.css']
})
export class ViewRecordsTableComponent implements OnInit {
  static WEB_API;
  public  static totaldata =[];
  public static TOTAL_GRIDDATA = [];
  public static IS_FILTERING_API_CALL : boolean =false;
  public static IS_PAGE_SIZE_OR_PAGENUM_CHANGED : boolean =false;
  @ViewChild("myGrid") myGrid: jqxGridComponent;
  static totalGridData;
  loginDetails: any;
  finalReportsCount: any;
  @Input() gridColumns =[];
  @Input()  concatarray=[];
  @Input()  datafields=[];
  @Output()  onRowDouble_Click = new EventEmitter<{}>();
  @Output()  onRowSelectionChange = new EventEmitter<{}>();
  @Output()  triggerLoader = new EventEmitter<{}>();
  @Output()  PageNum_Or_size_Changed = new EventEmitter<{}>();
  static ACTIVE_COUNT: any;
  static ARCHIVE_COUNT: any;
  url:any;

  constructor(private config: ConfigServiceService,private location: LocationStrategy,private spinner: NgxSpinnerService,) {
      // preventing back button in browser
     history.pushState(null, null, window.location.href);
     this.location.onPopState(() => {
       history.pushState(null, null, window.location.href);
     });
     ViewRecordsTableComponent.WEB_API = this.config.getConfig().ULD_Url;
  }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.spinner.hide();
  }
  
  ngOnChanges(){
   this.myGrid.source(this.dataAdapter);
  }



 public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
  source = {
    url: "",
    datatype: "json",
    datafields:this.datafields,//dynamic,
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "Rows",
    totalrecords: 800,
    cache: false,
    sort: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("sort");
    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("filter");
      this.triggerLoader.emit(true);
      ViewRecordsTableComponent.IS_FILTERING_API_CALL = true;

    },
    beforeprocessing: (data: any) => {
      try{
         if(data.responseCode === 1 && data['message'] !=="No data found"){
           data.Rows = data.Rows ;
           this.source.totalrecords = data.TotalRows;
           ViewRecordsTableComponent.totaldata  = data.Rows.filter(item=>item) ;
           if(ViewRecordsTableComponent.TOTAL_GRIDDATA){
             if(ViewRecordsTableComponent.TOTAL_GRIDDATA.length === 0){
               ViewRecordsTableComponent.TOTAL_GRIDDATA =  data.Rows ;
             }else{
               ViewRecordsTableComponent.TOTAL_GRIDDATA = ViewRecordsTableComponent.TOTAL_GRIDDATA.concat(data.Rows) ;
             }
           }
           ViewRecordsTableComponent.ACTIVE_COUNT = data.activeCount;
           ViewRecordsTableComponent.ARCHIVE_COUNT = data.archiveCount;
           ViewRecordsTableComponent.TOTAL_GRIDDATA = this.removeDuplicates(ViewRecordsTableComponent.TOTAL_GRIDDATA);
         }else{
           data.Rows = [] ;
           this.source.totalrecords = 0;
          //  ViewRecordsTableComponent.ACTIVE_COUNT =0;
           ViewRecordsTableComponent.TOTAL_GRIDDATA = [];
           ViewRecordsTableComponent.totaldata  = [];
         }

          //--trigger when page size / page changed
         if(ViewRecordsTableComponent.IS_PAGE_SIZE_OR_PAGENUM_CHANGED === true){
          this.PageNum_Or_size_Changed.emit(true);
          ViewRecordsTableComponent.IS_PAGE_SIZE_OR_PAGENUM_CHANGED = false;
         }

           //--trigger when filtering is applied .
         if(ViewRecordsTableComponent.IS_FILTERING_API_CALL === true){
          this.triggerLoader.emit(false);
          ViewRecordsTableComponent.IS_FILTERING_API_CALL = false;
         }
         
         
        }catch(exeception){
          //--trigger when page size / page changed
          if(ViewRecordsTableComponent.IS_PAGE_SIZE_OR_PAGENUM_CHANGED === true){
            this.PageNum_Or_size_Changed.emit(true);
            ViewRecordsTableComponent.IS_PAGE_SIZE_OR_PAGENUM_CHANGED = false;
           }
           
             //--trigger when filtering is applied .
          if(ViewRecordsTableComponent.IS_FILTERING_API_CALL === true){
            this.triggerLoader.emit(false);
            ViewRecordsTableComponent.IS_FILTERING_API_CALL = false;
           }
        }
},
    pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
      ;
      console.log(pagenum);
    },
  };

 
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "90%";
    }
    return 850;
  }
  dataAdapter = new jqx.dataAdapter(this.source, {
    //Set the http header before calling the api.
    beforeSend: function (jqXHR, settings) {
      ;
      this.loginresultobject = JSON.parse(
        localStorage.getItem("loginresultobject")
      );
      this.token = this.loginresultobject.token;
      jqXHR.setRequestHeader(
        "cache-control",
        "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
      ),
        jqXHR.setRequestHeader("pragma", "no-cache"),
        jqXHR.setRequestHeader("expires", "0"),
        jqXHR.setRequestHeader("authorization", this.token);
    },
  });
  getEditorDataAdapter = (datafield: string): any => {
    let dataAdapter = new jqx.dataAdapter(this.source, {
      uniqueDataFields: [datafield],
    });
    return dataAdapter;
  };
  input: any;
  public loginDataDup = JSON.parse(localStorage.getItem("loginresultobject"));
  columns = [];
  rendergridrows = (params: any): any => {
    return params.data;
  };



  onRowDoubleClick(event){
     this.onRowDouble_Click.emit(event);
  }
  _onSelectRow(event):void { // trigger when checkbox checked / unchecked 
    this.onRowSelectionChange.emit(event);
    }

    getTotalData(){
      return this.removeDuplicates(ViewRecordsTableComponent.TOTAL_GRIDDATA);
    }
   
  
  removeDuplicates(array)  {
    let currentModule = JSON.parse(localStorage.getItem('currentModule'));
    if(currentModule === "Truckinspection"){
      let uniq = {};
      return array.filter(obj => !uniq[obj.truck_inspection_id] && (uniq[obj.truck_inspection_id] = true));
    }else if(currentModule === "CARGODAMAGE-FORWARDER"){
      let uniq = {};
      return array.filter(obj => !uniq[obj.uldCargoId] && (uniq[obj.uldCargoId] = true));
    }
      
    }

    onPage_OR_PageSize_Changed(event){
      ViewRecordsTableComponent.IS_PAGE_SIZE_OR_PAGENUM_CHANGED = true;
    }

   
}