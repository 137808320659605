export class shipmentinfo {
 
        public categoryId: any;
        public userId: any;
        public masterAwbNo: any;
        public contentNature: any;
        public comodityNote: any;
        public mawbTotalpcs: any;
        public mawbTotalWeight: any;
        public actualReceivedPcs: any;
        public actualRevicedWeight: any;
        public shipper: any;
        public consignee: any;
        public issuingAgent: any;
 
}
