import { Component, OnInit } from '@angular/core';
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DamageReportsService } from '../services/damageReports/damage-reports.service';
import { DatePipe } from '@angular/common';
import * as jsPDF from "jspdf";
@Component({
  selector: 'app-awb-claims',
  templateUrl: './awb-claims.component.html',
  styleUrls: ['./awb-claims.component.css']
})
export class AwbClaimsComponent implements OnInit {
  claimLabels: string[];
  formalclaims: number[];
  preliminaryclaims: number[];
  selectedYear: any = "Pick Year";
  minDate = new Date();
  maxDate = new Date();
  startmaxDate: Date;
  loginData: any;
  awbObject: any;
  preliminarylabels: any;
  tempSelectedYear: any = "Pick Year";
  formalArray: any=[];
  preliminaryArray: any=[];
  quater='N';
  tempToSelectedYear: any = 'Pick to Year';
  tempFromSelectedYear: any = 'Pick from Year';
  formalrejectedclaims: any=[];
  prerejectedclaims: any=[];
  showCmprDates: boolean;
  myChart: Chart;
  FormalChart: Chart;
  preliminaryChart: Chart;
  prerejectedArray: any;
  forRejectedArray: any;
  CLlables: any;
  CLamount: any;
  greaterLables: any=[];
  greaterFive: any=[];
  greaterTen: any=[];
  greaterTwenty: any=[];
  greaterFifty: any=[];
  greaterAmount: any=[];
  amountchart: Chart;

  constructor(private datePipe: DatePipe,private router: Router,private toaster: ToastrService,private damageReport_Service: DamageReportsService,private spinner: NgxSpinnerService,) { }
  ngOnInit() {
    ;
    this.showCmprDates = false;
    this.formalArray = [];
    this.preliminaryArray = [];
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
     this.selectedYear = this.datePipe.transform(new Date(), "yyyy");
     this.tempSelectedYear = this.selectedYear;
     const currentDate = new Date();
     if (this.tempSelectedYear < currentDate.getFullYear()) {
       this.minDate = new Date(this.tempSelectedYear, 0, 1);
       this.startmaxDate = new Date(this.tempSelectedYear, 11, 31);
     } else {
       this.minDate = new Date(this.tempSelectedYear, 0, 1);
       this.startmaxDate = new Date(
         this.tempSelectedYear,
         currentDate.getMonth(),
         currentDate.getDate()
       );
      
     }

     this.awbChartService(this.tempSelectedYear);
  }
  
  //date picker method
      closeDatePicker(year, elem: any) {
    ;
        this.selectedYear = Number(year.getFullYear());
        const currentDate = new Date();
        if (this.selectedYear < currentDate.getFullYear()) {
          this.minDate = new Date(this.selectedYear, 0, 1);
          this.startmaxDate = new Date(this.selectedYear, 11, 31);
        } else {
          this.minDate = new Date(this.selectedYear, 0, 1);
          this.startmaxDate = new Date(
            this.selectedYear,
            currentDate.getMonth(),
            currentDate.getDate(),
            this.tempSelectedYear = Number(year.getFullYear()),
          );
        }
       
        elem.close();
      }
      onClickSubmit() {
        ;
        this.awbChartService(this.selectedYear);
      }
      checkEvent(event){
        ;
       console.log(event.checked);
     if (event.checked === true) {
       this.quater='Y'
      }else{
       this.quater='N'
}
      }
    
awbChartService(getYear){
  ;
  this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
  let awbObject = {
    orgId: this.loginData.orgId,
    year: getYear,
    loginUserId: this.loginData.userId,
    quarter : this.quater
  };
       this.spinner.show();
       if(awbObject){
       this.damageReport_Service.getAwbCharts(awbObject).subscribe(
         data => {
           this.spinner.hide();
           if (data.responseCode === 1) {
             this.spinner.hide();
       //preliminary and formal claims charts per 1000
       if (data.formalAirportClaims!=null && data.preliminaryAirportClaims!=null && data.formalAirportClaims!=undefined && data.preliminaryAirportClaims!=undefined) {
        this.formalArray = data.formalAirportClaims.formalClaims;
        this.preliminaryArray = data.preliminaryAirportClaims.preliminaryClaims;
       this.prerejectedArray =data.preliminaryRejectedAirportClaims.preliminaryRejectedClaims;
       this.forRejectedArray= data.formalRejectedAirportClaims.formalRejectedClaims;
       this.claimLabels = data.formalAirportClaims.lables;
       this.formalclaims = data.formalAirportClaims.formalClaims;
       this.preliminarylabels = data.preliminaryAirportClaims.preliminaryClaims;
       }
       this.prerejectedclaims=data.preliminaryRejectedAirportClaims.preliminaryRejectedClaims;
       this.formalrejectedclaims=data.formalRejectedAirportClaims.formalRejectedClaims;
       if (data.airportClaimAmount!=null && data.airportClaimAmount!=undefined) {
        this.CLlables=data.airportClaimAmount.lables;
        this.CLamount=data.airportClaimAmount.claimAmount;
       }
      
       if (this.myChart !== undefined) {
        while (this.myChart.data.datasets.length > 0) {
          this.myChart.data.datasets.pop();
        }
      }
 
      this.myChart=new Chart(
         <HTMLCanvasElement>document.getElementById("totalclaims"),
         {
           type: "line",
           
           plugins: [ChartDataLabels],
           data: {
             labels: this.CLlables,
             datasets: [
              {
               // pointStyle: 'rect',
                type: "line",
                borderColor: "rgb(50,205,50)",
              backgroundColor: "rgb(50,205,50)",
              hoverBorderColor: "rgb(50,205,50)",
                data: this.CLamount,
                fill: false,
                label: "Claimed amount in USD per 1000 AWBs",
                datalabels: {
                 align: 'top',
                 display: 'auto',
                 rotation: -360,
                 color: "#000000",
               }
              
              },
             ],
           },
           options: {
            // events:[],
             title: {
               display: true,
               //text: "Count Irregularity"
             },
             plugins: {
               // Change options for ALL labels of THIS CHART
               datalabels: {
                 color: "#000000",
                 anchor: "end",
                 align: "top",
                 rotation: -360,
              
               },
             },
             legend: {
               display: true,
               position: "bottom",
            //    labels: {
            //     usePointStyle: true,
            //     boxWidth: 8
            //  }
             },
            
             scales: {
               xAxes: [
                 {
                   scaleLabel: {
                     display: true,
                     //labelString: "Preliminary claims per 1000",
                     fontSize: 16,
                     fontColor: "#000000",
                     fontStyle: "bold",
                   },
                   ticks: {
                     autoSkip: false,
                     // max rotation: -90,
                     // min rotation: -90
                   },
                   
                 },
               ],
               yAxes: [
                 {
                   scaleLabel: {
                     display: true,
                     // labelString: "Damage reports",
                     fontSize: 16,
                     fontColor: "#000000",
                     fontStyle: "bold",
                   },
                   ticks: {
                     beginAtZero: true,
                     //lineHeight:20,
                     // max:plugin.settings.maxDataValue,
                     maxTicksLimit: 10,
                   },
                   id: "y-axis-0",
                   position: "left",
                 },
               ],
             },
           },
         }
       );
       
   //preliminary claims per 1000
   if (data.preliminaryAirportClaims!=undefined && data.preliminaryAirportClaims!=null && data.preliminaryRejectedAirportClaims!=null && data.preliminaryRejectedAirportClaims!=undefined) {
     this.claimLabels =  data.preliminaryAirportClaims.lables;
     this.preliminaryclaims =data.preliminaryAirportClaims.preliminaryClaims;
   }
   this.prerejectedclaims=data.preliminaryRejectedAirportClaims.preliminaryRejectedClaims;
   if (this.preliminaryChart !== undefined) {
    while (this.preliminaryChart.data.datasets.length > 0) {
      this.preliminaryChart.data.datasets.pop();
    }
  }
  this.preliminaryChart=  new Chart(
       <HTMLCanvasElement>document.getElementById("preclaims"),
       {
         type: "line",
         plugins: [ChartDataLabels],
         data: {
           labels: this.claimLabels,
           datasets: [
             {
               type: "line",
               borderColor: "rgba(0,0,255,1)",
               backgroundColor: "rgba(0,0,255,1)",
               hoverBorderColor: "rgba(0,0,255,1)",
               data:this.preliminaryclaims,
               fill: false,
               label: "Number of Formal Claims per 1000 AWBs",
               datalabels: {
                align: 'top',
                anchor: "end",
                //align: "top",
                display: 'auto',
                 rotation: -360,
                 color: "#000000",
              }
             },
             {
              type: "line",
              borderColor: "rgb(50,205,50)",
              backgroundColor: "rgb(50,205,50)",
              hoverBackgroundColor: "rgb(50,205,50)",
              data:this.prerejectedclaims,
              fill: false,
              label: "Total number of Claims (Preliminary + Formal) and Formal Claims per 1000 AWBs",
              datalabels: {
                align: 'start',
                anchor: "end",
                //align: "top",
                display: 'auto',
                rotation: -360,
                color: "#000000",
              }
            },
            
           ],
         },
         options: {
          // events:[],
           title: {
             display: true,
             //text: "Count Irregularity"
           },
           plugins: {
             // Change options for ALL labels of THIS CHART
             datalabels: {
               color: "#000000",
               anchor: "end",
               align: "top",
               rotation: -360,
            
             },
           },
           legend: {
            display: true,
            position: "bottom",
          },
           scales: {
             xAxes: [
               {
                 scaleLabel: {
                   display: true,
                  //  labelString: "Preliminary claims per 1000",
                   fontSize: 16,
                   fontColor: "#000000",
                   fontStyle: "bold",
                 },
                 ticks: {
                   autoSkip: false,
                   
                 },
              
               },
             ],
             yAxes: [
               {
                 scaleLabel: {
                   display: true,
                   // labelString: "Damage reports",
                   fontSize: 16,
                   fontColor: "#000000",
                   fontStyle: "bold",
                 },
                 ticks: {
                   beginAtZero: true,
                   //lineHeight:20,
                   // max:plugin.settings.maxDataValue,
                   maxTicksLimit: 10,
                 },
                 id: "y-axis-0",
                 position: "left",
               },
             ],
           },
         },
       }
     );
   //formal claims per 1000
   if (data.formalAirportCliams!=undefined && data.formalAirportCliams!=null && data.formalRejectedAirportClaims!=null && data.formalRejectedAirportClaims!=undefined) {
   this.claimLabels =  data.formalAirportCliams.lables;
   this.formalclaims = data.formalAirportCliams.formalClaims;
   }
   this.formalrejectedclaims=data.formalRejectedAirportClaims.formalRejectedClaims;
   if (this.FormalChart !== undefined) {
    while (this.FormalChart.data.datasets.length > 0) {
      this.FormalChart.data.datasets.pop();
    }
  }
     this.FormalChart=new Chart(
       <HTMLCanvasElement>document.getElementById("formclaims"),
       {
         type: "line",
         plugins: [ChartDataLabels],
         data: {
           labels: this.claimLabels,
           datasets: [
             {
               type: "line",
               borderColor: "rgba(0,0,255,1)",
               backgroundColor: "rgba(0,0,255,1)",
               hoverBorderColor: "rgba(0,0,255,1)",
               data: this.formalclaims,
               fill: false,
               label: "Number of Claims per 1000 AWBs",
               stack: "a",
               datalabels: {
                align: 'top',
                anchor: "end",
                //align: "top",
                display: 'auto',
                 rotation: -360,
                 color: "#000000",
              }
             },
             {
              type: "line",
              borderColor: "rgb(255,0,0)",
              backgroundColor: "rgb(255,0,0)",
              hoverBackgroundColor: "rgb(255,0,0)",
              data:this.formalrejectedclaims,
              fill: false,
              label: "Number of Claims less Denied Claims per 1000 AWBs",
              //borderDash: [10,5],
              stack: "b",
              datalabels: {
                align: 'start',
                anchor: "end",
                //align: "top",
                display: 'auto',
                 rotation: -360,
                 color: "#000000",
              }
            },
            
           ],
         },
         options: {
           title: {
             display: true,
             //text: "Count Irregularity"
           },
           plugins: {
             // Change options for ALL labels of THIS CHART
             datalabels: {
              color: "#000000",
              anchor: "end",
              align: "top",
              rotation: -360,
           
            },
           },
           legend: {
            display: true,
            position: "bottom",
          },
           scales: {
             xAxes: [
               {
                 scaleLabel: {
                   display: true,
                  // labelString: "Formal claims per 1000",
                   fontSize: 16,
                   fontColor: "#000000",
                   fontStyle: "bold",
                 },
                 ticks: {
                   autoSkip: false,
                   // max rotation: -90,
                   // min rotation: -90
                 },
               },
             ],
             yAxes: [
               {
                 scaleLabel: {
                   display: true,
                   // labelString: "Damage reports",
                   fontSize: 16,
                   fontColor: "#000000",
                   fontStyle: "bold",
                 },
                 ticks: {
                   beginAtZero: true,
                   //lineHeight:20,
                   // max:plugin.settings.maxDataValue,
                   maxTicksLimit: 10,
                 },
                 id: "y-axis-0",
                 position: "left",
               },
             ],
           },
         },
       }
     );

     //amounts chart
     if (data.greaterThanClaimAmount!=null && data.greaterThanClaimAmount!=undefined) {
       
     
     this.greaterLables = data.greaterThanClaimAmount.lables;
     this.greaterFive =data.greaterThanClaimAmount.claims_gt_5000;
     this.greaterTen =data.greaterThanClaimAmount.claims_gt_10000;
     this.greaterTwenty = data.greaterThanClaimAmount.claims_gt_25000;
     this.greaterFifty =data.greaterThanClaimAmount.claims_gt_50000;
     this.greaterAmount =data.greaterThanClaimAmount.greaterThanAmount;
     }
     if (this.amountchart !== undefined) {
      while (this.amountchart.data.datasets.length > 0) {
        this.amountchart.data.datasets.pop();
      }
    }
     this.amountchart=new Chart(
      <HTMLCanvasElement>document.getElementById("amountClaims"),
      {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels: this.greaterLables,
          datasets: [
            {
              type: "bar",
              borderColor:"rgba(0,128,0)",
              backgroundColor:"rgba(0,128,0)",
              hoverBorderColor:"rgba(0,128,0)",
              data: this.greaterFive,
              fill: false,
              label: "5,000 USD",
              stack: "a",
              datalabels: {
                align: 'top',
                anchor: "end",
                //align: "top",
                display: 'auto',
                 rotation: -360,
                 color: "#000000",
              }
            
            },
            {
             type: "bar",
             borderColor:"rgb(128,0,128)",
             backgroundColor:"rgb(128,0,128)",
             hoverBackgroundColor:"rgb(128,0,128)",
             data:this.greaterTen,
             fill: false,
             label: "10,000 USD",
             //borderDash: [10,5],
             stack: "b",
             datalabels: {
              align: 'top',
              anchor: "end",
              //align: "top",
              display: 'auto',
               rotation: -360,
               color: "#000000",
            }
           
           },
           {
            type: "bar",
            borderColor:"rgb(30,144,255)",
            backgroundColor:"rgb(30,144,255)",
            hoverBackgroundColor:"rgb(30,144,255)",
            data:this.greaterTwenty,
            fill: false,
            label: "25,000 USD",
            //borderDash: [10,5],
            stack: "c",
            datalabels: {
              align: 'top',
              anchor: "end",
              //align: "top",
              display: 'auto',
               rotation: -360,
               color: "#000000",
            }
         
          },
          {
            type: "bar",
            borderColor:"rgb(210,105,30)",
            backgroundColor:"rgb(210,105,30)",
            hoverBackgroundColor:"rgb(210,105,30)",
            data:this.greaterFifty,
            fill: false,
            label: "50,000 USD",
            //borderDash: [10,5],
            stack: "d",
            datalabels: {
              align: 'top',
              anchor: "end",
              //align: "top",
              display: 'auto',
               rotation: -360,
               color: "#000000",
            }
          
          },
           
          ],
        },
        options: {
          title: {
            display: true,
            //text: "Count Irregularity"
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              align: 'top',
              anchor: "end",
              color: "#000000",
              //align: "top",
              display: 'auto',
               rotation: -360,
           
            }
          },
          legend: {
           display: true,
           position: "bottom",
         },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                 // labelString: "Formal claims per 1000",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  autoSkip: false,
                  // max rotation: -90,
                  // min rotation: -90
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  // labelString: "Damage reports",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  beginAtZero: true,
                  //lineHeight:20,
                  // max:plugin.settings.maxDataValue,
                  maxTicksLimit: 10,
                },
                id: "y-axis-0",
                position: "left",
              },
            ],
          },
        },
      }
    );
     
           } else if (
             data.responseCode === 0 &&
             data.message === "Unauthorized User"
           ) {
             this.toaster.warning("your session expired please login again");
             this.router.navigateByUrl("/");
           } else {
             this.toaster.error(data.message);
           }
         },
         (error) => {
           this.spinner.hide();
         }
       );
        }
}
 

  Gotoclaims(){
    this.router.navigateByUrl("/cargoClaim/dashboard/claim-count");
  }
  OpenCompareDates(){
    this.showCmprDates = true;
  }
  closeCompareChartsDates(){
    this.showCmprDates = false;
  }
  //downloading charts method
  onClickDownload() {
    this.spinner.show();
     setTimeout(() => {
      this.spinner.hide();
    }, 3000);
      const canvas = document.getElementById("formclaims") as HTMLCanvasElement;
      const canvas2 = document.getElementById("preclaims")as HTMLCanvasElement;
      const canvas4 = document.getElementById("totalclaims")as HTMLCanvasElement;
      const canvas6 = document.getElementById("amountClaims") as HTMLCanvasElement;
      var imgData = canvas.toDataURL("image/png");
      var imgData2 = canvas2.toDataURL("image/png");
      var imgData4 = canvas4.toDataURL("image/png");
      var imgData6 = canvas6.toDataURL("image/png");
      var imgWidth = 285;
      var pageHeight = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("landscape", "mm", "a4");
      // new jsPDF("landscape", "mm", "a4");
      doc.setFontSize(14);
      var position = 40; // give some top padding to first page
      var text ="Cargo Claim - AWB Charts";
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, 10, text);
      doc.setFontSize(14);
  
      doc.text(15, 30, 'Formal Claims per 1000 AWBs');
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.addPage();

      var text ="Cargo Claim - AWB Charts";
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, 10, text);
      doc.setFontSize(14);

      doc.text(15, 30, 'Total number of Claims (Preliminary + Formal) and Formal Claims per 1000 AWBs');
       doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
       doc.addPage();

       
      var text ="Cargo Claim - AWB Charts";
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, 10, text);
      doc.setFontSize(14);
  
      doc.text(15, 30, 'Total claimed amount in USD per 1000 AWBs');
      doc.addImage(imgData4, "PNG", 0, position, imgWidth, imgHeight);
      doc.addPage();
    
      
      var text ="Cargo Claim - AWB Charts";
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, 10, text);
      doc.setFontSize(14);

      doc.text(15, 30, 'Number of records with claimed amount greater than > 5,000USD; > 10,000USD; 25,000USD and 50,000USD');
      doc.addImage(imgData6, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("AWBs charts.pdf");
   
     
  }
}
