import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";
@Component({
  selector: "app-claim-count",
  templateUrl: "./claim-count.component.html",
  styleUrls: ["./claim-count.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ClaimCountComponent implements OnInit {
  loginDetails: any;
  orgId: any;
  totaloriginclaimCount: any;
  originpreliminary: any;
  originformal: any;
  totaldestclaimCount: any;
  despreliminary: any;
  desformal: any;
  originclaimstation: any;
  originclaimCount: any;
  destclaimstation: any;
  destclaimCount: any;
  inputObj: any;
  claimOrigin: any = [];
  topOrigin: any = [];
  claimDest: any = [];
  topdest: any = [];
  toporiginclaimstation: any;
  toporiginclaimstationformal: any = [];
  toporiginclaimCountformal: any = [];
  originclaimstationmodelformal: any;
  originclaimCountmodelformal: any;
  topdestclaimstationformal: any;
  topdestclaimCountformal: any;
  destclaimstationmodelformal: any;
  destclaimCountmodelformal: any;
  toporiginFormal: any;
  topdestformal: any;
  oriignformla: any;
  destformal: any;
  totalDestinationAirportClaimCount: any;
  totalOriginAirportClaimCount: any;
  primilaryCount: any;
  formalCount: any;
  scrollable: boolean;
  scrollable1: boolean;
  scrollable2: boolean;
  scrollable3: boolean;
  originoilChartWidth: number;
  graphHeight: number;
  destoilChartWidth: number;
  originoilChart_thirdWidth: number;
  destoilChart_fourthWidth: number;
  labelLengthorg: any;
  labelLengthdest: any;
  labelLengthorgFormal: any;
  labelLengthdestFormal: any;
  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {
    ;
    
    this.claimOrigin = [];
    this.topOrigin = [];
    this.claimDest = [];
    this.topdest = [];
    this.toporiginFormal = [];
    this.topdestformal = [];
    this.oriignformla = [];
    this.destformal = [];
    //geting input object from dashboard
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
    
    //getting login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    // this.orgId =887;
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
      ;
      //caim count Api integration
      this.spinner.show();
      this.claimcountservice.claim_count(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            //calculating origin destination counts here
            this.totalDestinationAirportClaimCount =  data.totalDestinationAirportClaimCount;
            if (data.totalOriginAirportClaimCount === null) {
              this.totalOriginAirportClaimCount = 0;
            } else {
              this.totalOriginAirportClaimCount =data.totalOriginAirportClaimCount;
            }
            if (this.totalOriginAirportClaimCount !== 0) {
              this.primilaryCount=0;
              this.formalCount =0;
              for (
                let i = 0;
                i < this.totalOriginAirportClaimCount.length;
                i++
              ) {
                for (
                  let j = 0;
                  j < this.totalDestinationAirportClaimCount.length;
                  j++
                ) {
               
                  if (
                    this.totalOriginAirportClaimCount[i].status ===
                      "Preliminary" &&
                    this.totalDestinationAirportClaimCount[j].status ===
                      "Preliminary"
                  ) {

                    this.primilaryCount =
                      this.totalOriginAirportClaimCount[i].count ;
                  } else if (
                    this.totalOriginAirportClaimCount[i].status === "Formal" &&
                    this.totalDestinationAirportClaimCount[j].status ===
                      "Formal"
                  ) {
                    this.formalCount =
                      this.totalOriginAirportClaimCount[i].count
                  }
                }
              }
            }
            else{
              this.primilaryCount=0;
              this.formalCount =0;
            
            }
            console.log(data.destAirportCliams);
            
            ;
            this.claimOrigin = data.originAirportCliams;
            this.topOrigin = data.topOriginAirportCliams;
            this.claimDest = data.destAirportCliams;
            this.topdest = data.topdestAirportCliams;
            this.toporiginFormal = data.toporiginAirportCliamsFormal;
            this.topdestformal = data.topDestnAirportCliamsFormal;
            this.oriignformla = data.originAirportCliamsFormal;
            this.destformal = data.destnAirportCliamsFormal;
            console.log(this.claimDest.count, this.topOrigin.count);
            

//dymanic scroll start here
if (data.originAirportCliams.stations !=null && data.originAirportCliams.stations !=undefined) {
  this.labelLengthorg = data.originAirportCliams.stations.length;
}
if (data.destAirportCliams.stations !=null && data.destAirportCliams.stations !=undefined) {
  this.labelLengthdest =  data.destAirportCliams.stations.length; 
}
if (data.originAirportCliamsFormal.stations !=null && data.originAirportCliamsFormal.stations !=undefined) {
  this.labelLengthorgFormal = data.originAirportCliamsFormal.stations.length; 
}
if (data.destnAirportCliamsFormal.stations !=null && data.destnAirportCliamsFormal.stations !=undefined) {
  this.labelLengthdestFormal = data.destnAirportCliamsFormal.stations.length; 
}
       
        
          
          
          if (this.labelLengthorg > 60 ) {
            this.scrollable = true
          }
          else{
            this.scrollable = false
          }
          if (this.labelLengthdest > 60 ) {
            this.scrollable1 = true
          }
          else{
            this.scrollable1 = false
          }
          if (this.labelLengthorgFormal > 60 ) {
            this.scrollable2 = true
          }
          else{
            this.scrollable2 = false
          }
          if (this.labelLengthdestFormal > 60 ) {
            this.scrollable3 = true
          }
          else{
            this.scrollable3 = false
          }
            //Origin Claim Count  bar chart model  starts here
            (this.originclaimstation = data.originAirportCliams.stations);
            if (data.originAirportCliams.stations!=null && data.originAirportCliams.stations!=undefined) {
              this.originoilChartWidth = this.getWidth(data.originAirportCliams.stations.length);
            }
              this.originclaimCount = data.originAirportCliams.count,
              new Chart(
                <HTMLCanvasElement>document.getElementById("originoilChart"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.originclaimstation,
                    datasets: [
                      {
                        type: "bar",
                        data: this.originclaimCount,
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Origin Claim Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            // max rotation: -90,
                            // min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Claim count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //preliminary Origin Claim Count  pie chart top 10 on load chart starts here
            (this.originclaimstation = data.topOriginAirportCliams.stations),
              (this.originclaimCount = data.topOriginAirportCliams.count),
              new Chart(
                <HTMLCanvasElement>document.getElementById("originoilChartOne"),
                {
                  type: "pie",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.originclaimstation,
                    datasets: [
                      {
                        type: "pie",
                        data: this.originclaimCount,
                        backgroundColor: [
                          "#FF6384",
                          "#63FF84",
                          "#84FF63",
                          "#8463FF",
                          "#6384FF",
                          "#7EFF33",
                          "#FC33FF",
                          "#FF3361",
                          "#33FFE5",
                          "#FFDD33",
                        ],
                      },
                    ],
                  },
                  options: {
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "start",
                        rotation: -90,
                      },
                    },
                }
                }
              );
            // Destination Claim Count  pie chart model  starts here
            if (data.destAirportCliams.stations!=null && data.destAirportCliams.stations!=undefined) {
              this.destoilChartWidth = this.getWidth(data.destAirportCliams.stations.length);
            }
          
            this.destclaimstation = data.destAirportCliams.stations,
              this.destclaimCount = data.destAirportCliams.count,
              new Chart(
                <HTMLCanvasElement>document.getElementById("destoilChart"),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.destclaimstation,
                    datasets: [
                      {
                        type: "bar",
                        data: this.destclaimCount,
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Destination Claim Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            // max rotation: -90,
                            // min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Claim count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //preliminary Destination Claim Count  pie chart top 10 on load chart starts here
            (this.destclaimstation = data.topdestAirportCliams.stations),
              (this.destclaimCount = data.topdestAirportCliams.count),
              new Chart(
                <HTMLCanvasElement>document.getElementById("destoilChartOne"),
                {
                  type: "pie",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.destclaimstation,
                    datasets: [
                      {
                        type: "pie",
                        data: this.destclaimCount,
                        backgroundColor: [
                          "#FF6384",
                          "#63FF84",
                          "#84FF63",
                          "#8463FF",
                          "#6384FF",
                          "#7EFF33",
                          "#FC33FF",
                          "#FF3361",
                          "#33FFE5",
                          "#FFDD33",
                        ],
                      },
                    ],
                  },
                  options: {
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "start",
                        rotation: -90,
                      },
                    },
                }
              }
              );
            //formal Claim Count origin pie chart top 10 on load chart starts here
            this.toporiginclaimstationformal =
              data.toporiginAirportCliamsFormal.stations;
            this.toporiginclaimCountformal =
              data.toporiginAirportCliamsFormal.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("originoilChartOne_third")
              ),
              {
                type: "pie",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.toporiginclaimstationformal,
                  datasets: [
                    {
                      type: "pie",
                      data: this.toporiginclaimCountformal,
                      backgroundColor: [
                        "#FF6384",
                        "#63FF84",
                        "#84FF63",
                        "#8463FF",
                        "#6384FF",
                        "#7EFF33",
                        "#FC33FF",
                        "#FF3361",
                        "#33FFE5",
                        "#FFDD33",
                      ],
                    },
                  ],
                },
                options: {
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "start",
                      rotation: -90,
                    },
                  },
              }
              }
            );
           //formal Claim Count destination pie chart top 10 on load chart starts here
            (this.topdestclaimstationformal =
              data.topDestnAirportCliamsFormal.stations),
              (this.topdestclaimCountformal =
                data.topDestnAirportCliamsFormal.count),
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("destoilChartOne_fourth")
                ),
                {
                  type: "pie",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.topdestclaimstationformal,
                    datasets: [
                      {
                        type: "pie",
                        data: this.topdestclaimCountformal,
                        backgroundColor: [
                          "#FF6384",
                          "#63FF84",
                          "#84FF63",
                          "#8463FF",
                          "#6384FF",
                          "#7EFF33",
                          "#FC33FF",
                          "#FF3361",
                          "#33FFE5",
                          "#FFDD33",
                        ],
                      },
                    ],
                  },
                  options: {
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "start",
                        rotation: -90,
                      },
                    },
                }
                }
              );
            //Origin Claim Count  bar chart model  starts here model third
            if (data.originAirportCliamsFormal.stations !=null && data.originAirportCliamsFormal.stations !=undefined) {
              this.originoilChart_thirdWidth = this.getWidth(data.originAirportCliamsFormal.stations.length);
            }
       
            this.originclaimstationmodelformal = data.originAirportCliamsFormal.stations,
              this.originclaimCountmodelformal =
                data.originAirportCliamsFormal.count,
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("originoilChart_third")
                ),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.originclaimstationmodelformal,
                    datasets: [
                      {
                        type: "bar",
                        data: this.originclaimCountmodelformal,
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Origin Claim Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            // max rotation: -90,
                            // min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Claim count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //Destination Claim Count  pie chart model  starts here---model forth
            if (data.destnAirportCliamsFormal.stations!=null && data.destnAirportCliamsFormal.stations!=undefined) {
              this.destoilChart_fourthWidth = this.getWidth(data.destnAirportCliamsFormal.stations.length);
            }
            
            this.destclaimstationmodelformal = data.destnAirportCliamsFormal.stations,
              this.destclaimCountmodelformal = data.destnAirportCliamsFormal.count,
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("destoilChart_fourth")
                ),
                {
                  type: "bar",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.destclaimstationmodelformal,
                    datasets: [
                      {
                        type: "bar",
                        data: this.destclaimCountmodelformal,
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Destination Claim Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Stations",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            // max rotation: -90,
                            // min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Claim count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
          } 
          else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          }
          else {
            this.toaster.error(data.message);
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.originpreliminary = 0;
      this.originformal = 0;
      this.despreliminary = 0;
      this.desformal = 0;
    }
  }
  //downloading charts method
  onClickDownloadTabCharts() {
    this.spinner.show();
     setTimeout(() => {
      this.spinner.hide();
    }, 3000);
      const canvas = document.getElementById("originoilChart") as HTMLCanvasElement;
      // const canvas1 = document.getElementById("originoilChartOne") as HTMLCanvasElement;
      const canvas2 = document.getElementById("destoilChart")as HTMLCanvasElement;
      // const canvas3 = document.getElementById("destoilChartOne") as HTMLCanvasElement;
      const canvas4 = document.getElementById("originoilChart_third")as HTMLCanvasElement;
      // const canvas5 = document.getElementById("originoilChartOne_third") as HTMLCanvasElement;
      const canvas6 = document.getElementById("destoilChart_fourth") as HTMLCanvasElement;
      var imgData = canvas.toDataURL("image/png");
      // var imgData1 = canvas1.toDataURL("image/png");
      var imgData2 = canvas2.toDataURL("image/png");
      // var imgData3 = canvas3.toDataURL("image/png");
      var imgData4 = canvas4.toDataURL("image/png");
      // var imgData5 = canvas5.toDataURL("image/png");
      var imgData6 = canvas6.toDataURL("image/png");
      // var imgData7 = canvas7.toDataURL("image/png");
      // console.log("image 2 ", canvas2);
      // console.log("image 1 ", canvas1);
      // console.log("image 3 ", canvas3);
      // console.log("image 2 ", imgData2);

      var imgWidth = 285;
      var pageHeight = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("landscape", "mm", "a4");
      // new jsPDF("landscape", "mm", "a4");
      doc.setFontSize(18);
      var position = 80; // give some top padding to first page
      var text ="Cargo Claim Type Charts";
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, 10, text);
      doc.setFontSize(14);
    
      doc.text(15, 30, 'Preliminary Claim type by Origin');
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.addPage();
  
      doc.text(10, 20, 'Preliminary Claim type by Destination');
       doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
       doc.addPage();
  
      doc.text(10, 20, 'Formal Claim type by Origin');
      doc.addImage(imgData4, "PNG", 0, position, imgWidth, imgHeight);
      doc.addPage();
     
      doc.text(10, 20, 'Formal Claim type by Destination');
      doc.addImage(imgData6, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Claim_Type_Charts.pdf");
   
     
  }
  //show hide model charts methods
  showmodelorg(){
    const element = document.getElementById("Originclaimcount");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodelorg(){
    const element = document.getElementById("Originclaimcount");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  showmodeldest(){
    const element = document.getElementById("Destinationclaimcount");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodeldest(){
    const element = document.getElementById("Destinationclaimcount");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  showmodeldestOne(){
    const element = document.getElementById("Originclaimcount_third");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodeldestOne(){
    const element = document.getElementById("Originclaimcount_third");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
   showmodeldestTwo(){
    const element = document.getElementById("Destinationclaimcount_fourth");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodeldestTwo(){
    const element = document.getElementById("Destinationclaimcount_fourth");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  //dynamic scroll
  scrollView(){
    setTimeout(() => {
      const elements =document.getElementsByClassName('chartAreaWrapper_scroll60');
      for(let ele of elements as any){
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
   
  }
  //dynamic width
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 20) {
        let finalWidth = 150 * number;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}
