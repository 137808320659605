import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";

@Component({
    selector: 'app-compare-financial',
    templateUrl: './compare-financial.component.html',
    styleUrls: ['./compare-financial.component.css']
})

export class CompareFinancialComponent implements OnInit {

    originstation: any = [];
    claimAmountYearOne: any = [];
    claimAmountYearTwo: any = [];
    paidAmountYearOne: any = [];
    paidAmountYearTwo: any = [];
    destoriginstation: any = [];
    inputObj: any;
    loginDetails: any;
    orgId: any;
    scrollable: boolean;
    scrollable1: boolean;
    YearOne: any;
    YearTwo: any;
    totalDestinationPaidClaimAmounts: any;
    totalOriginPaidClaimAmounts: any;
    claimCountYearOne: string;
    claimCountYearTwo: string;
    paidamountYearOne: string;
    paidamountYearTwo: string;
    provisionamountYearOne: string;
    provisionamountYearTwo: string;
    suggestedYearOne: string;
    suggestedYearTwo: string;
    chargedYearOne: string;
    chargedYearTwo: string;
    canvaslinewidth: any;
    canvaslineOnewidth: any;
    height: number;
    constructor(
        private claimcountservice: ClaimCountService,
        private toaster: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }
    ngOnInit() {

        this.inputObj = JSON.parse(localStorage.getItem("inputObj"));

      
        this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        this.orgId = this.loginDetails.orgId;
        if (this.inputObj.yearOne && this.inputObj.yearTwo) {
            //api integration starts here
            this.spinner.show();
            this.claimcountservice.Compare_amount_count(this.inputObj).subscribe(
                (data) => {
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                        this.YearOne = data.yearOne;
                        this.YearTwo = data.yearTwo
                        this.originstation = data.originPaidClaimAmount.stations,
                        this.claimAmountYearOne = data.originPaidClaimAmount.claimAmountYearOne;
                        this.claimAmountYearTwo = data.originPaidClaimAmount.claimAmountYearTwo;
                        this.paidAmountYearOne = data.originPaidClaimAmount.paidAmountYearOne;
                        this.paidAmountYearTwo = data.originPaidClaimAmount.paidAmountYearTwo;
                        //counts display
                        this.totalDestinationPaidClaimAmounts = data.totalDestinationPaidClaimAmounts;
                        this.totalOriginPaidClaimAmounts = data.totalOriginPaidClaimAmounts;
                        if (this.totalOriginPaidClaimAmounts != 0) {
                            this.claimCountYearOne='0.00'
                            this.claimCountYearTwo ='0.00';
                              this.paidamountYearOne= '0.00';
                              this.paidamountYearTwo='0.00';
                              this.provisionamountYearOne='0.00';
                              this.provisionamountYearTwo='0.00';
                              this.suggestedYearOne='0.00';
                              this.suggestedYearTwo='0.00';
                              this.chargedYearOne='0.00';
                              this.chargedYearTwo= '0.00';  
                        for (let i = 0; i < this.totalOriginPaidClaimAmounts.length; i++) {
                            for (let j = 0; j < this.totalDestinationPaidClaimAmounts.length; j++) {
                                if (this.totalOriginPaidClaimAmounts[i].staus === "Formal") {
                                    if (this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yearone === null ) {
                                        this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yearone ='0.00';
                                    }else{
                                        this.claimCountYearOne =this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yearone;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yeartwo === null ) {
                                        this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yeartwo ='0.00';
                                    }else{
                                        this.claimCountYearTwo =this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yeartwo;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yearone === null ) {
                                        this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yearone ='0.00';
                                    }else{
                                        this.paidamountYearOne =this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yearone;
                                    }

                                    if (this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yeartwo === null ) {
                                        this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yeartwo ='0.00';
                                    }else{
                                        this.paidamountYearTwo=this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yeartwo;
                                    }

                                    if (this.totalOriginPaidClaimAmounts[i].Provision_amount_yearone === null ) {
                                        this.totalOriginPaidClaimAmounts[i].Provision_amount_yearone ='0.00';
                                    }else{
                                        this.provisionamountYearOne=this.totalOriginPaidClaimAmounts[i].Provision_amount_yearone;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].Provision_amount_yeartwo === null ) {
                                        this.totalOriginPaidClaimAmounts[i].Provision_amount_yeartwo ='0.00';
                                    }else{
                                        this.provisionamountYearTwo=this.totalOriginPaidClaimAmounts[i].Provision_amount_yeartwo;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yearone === null ) {
                                        this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yearone ='0.00';
                                    }else{
                                        this.suggestedYearOne=this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yearone;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yeartwo === null ) {
                                        this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yeartwo ='0.00';
                                    }else{
                                        this.suggestedYearTwo=this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yeartwo;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].chargeback_amount_yearone === null ) {
                                        this.totalOriginPaidClaimAmounts[i].chargeback_amount_yearone ='0.00';
                                    }else{
                                        this.chargedYearOne=this.totalOriginPaidClaimAmounts[i].chargeback_amount_yearone;
                                    }
                                    if (this.totalOriginPaidClaimAmounts[i].chargeback_amount_yeartwo === null ) {
                                        this.totalOriginPaidClaimAmounts[i].chargeback_amount_yeartwo ='0.00';
                                    }else{
                                        this.chargedYearTwo= this.totalOriginPaidClaimAmounts[i].chargeback_amount_yeartwo;
                                    }
                                    // this.claimCountYearTwo =this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yeartwo;
                                    // this.paidamountYearOne= this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yearone;
                                    // this.paidamountYearTwo=this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yeartwo;
                                    // this.provisionamountYearOne=this.totalOriginPaidClaimAmounts[i].Provision_amount_yearone;
                                    // this.provisionamountYearTwo=this.totalOriginPaidClaimAmounts[i].Provision_amount_yeartwo;
                                    // this.suggestedYearOne=this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yearone;
                                    // this.suggestedYearTwo=this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yeartwo;
                                    // this.chargedYearOne=this.totalOriginPaidClaimAmounts[i].chargeback_amount_yearone;
                                    // this.chargedYearTwo= this.totalOriginPaidClaimAmounts[i].chargeback_amount_yeartwo;
                               // if (this.totalOriginPaidClaimAmounts[i].staus === "Formal" && this.totalDestinationPaidClaimAmounts[j].staus === "Formal") {
                                    // let Claim1: number = parseFloat(this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yearone) + parseFloat(this.totalDestinationPaidClaimAmounts[j].final_claim_amt_orgin_yearone);
                                    // this.claimCountYearOne = Claim1.toFixed(2);
                                    // let Claim2: number = parseFloat(this.totalOriginPaidClaimAmounts[i].final_claim_amt_orgin_yeartwo) + parseFloat(this.totalDestinationPaidClaimAmounts[j].final_claim_amt_orgin_yeartwo);
                                    // this.claimCountYearTwo = Claim2.toFixed(2);
                                    // let paid1: number = parseFloat(this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yearone) + parseFloat(this.totalDestinationPaidClaimAmounts[j].paid_amt_orgin_yearone);
                                    // this.paidamountYearOne = paid1.toFixed(2);
                                    // let paid2: number = parseFloat(this.totalOriginPaidClaimAmounts[i].paid_amt_orgin_yeartwo) + parseFloat(this.totalDestinationPaidClaimAmounts[j].paid_amt_orgin_yeartwo);
                                    // this.paidamountYearTwo = paid2.toFixed(2);
                                    // let provision1: number = parseFloat(this.totalOriginPaidClaimAmounts[i].Provision_amount_yearone) + parseFloat(this.totalDestinationPaidClaimAmounts[j].Provision_amount_yearone);
                                    // this.provisionamountYearOne = provision1.toFixed(2);
                                    // let provision2: number = parseFloat(this.totalOriginPaidClaimAmounts[i].Provision_amount_yeartwo) + parseFloat(this.totalDestinationPaidClaimAmounts[j].Provision_amount_yeartwo);
                                    // this.provisionamountYearTwo = provision2.toFixed(2);
                                    // let suggested1: number = parseFloat(this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yearone) + parseFloat(this.totalDestinationPaidClaimAmounts[j].suggested_amount_USD_yearone);
                                    // this.suggestedYearOne = suggested1.toFixed(2);
                                    // let suggested2: number = parseFloat(this.totalOriginPaidClaimAmounts[i].suggested_amount_USD_yeartwo) + parseFloat(this.totalDestinationPaidClaimAmounts[j].suggested_amount_USD_yeartwo);
                                    // this.suggestedYearTwo = suggested2.toFixed(2);
                                    // let charged1: number = parseFloat(this.totalOriginPaidClaimAmounts[i].chargeback_amount_yearone) + parseFloat(this.totalDestinationPaidClaimAmounts[j].chargeback_amount_yearone);
                                    // this.chargedYearOne = charged1.toFixed(2);
                                    // let charged2: number = parseFloat(this.totalOriginPaidClaimAmounts[i].chargeback_amount_yeartwo) + parseFloat(this.totalDestinationPaidClaimAmounts[j].chargeback_amount_yeartwo);
                                    // this.chargedYearTwo = charged2.toFixed(2);
                               // }
                            }
                        }
                        //dymanic scroll
                        this.canvaslinewidth = this.getWidth(data.originPaidClaimAmount.stations.length);

                        let labelLengthpaidClaimOrg = data.originPaidClaimAmount.stations.length;
                        let labelLengthpaidClaimDest = data.destPaidClaimAmount.stations.length;
                        if (labelLengthpaidClaimOrg > 20) {
                            this.scrollable = true
                        }
                        else {
                            this.scrollable = false
                        }
                        if (labelLengthpaidClaimDest > 20) {
                            this.scrollable1 = true
                        }
                        else {
                            this.scrollable1 = false
                        }
                        setTimeout(() => {
                        new Chart(
                            <HTMLCanvasElement>document.getElementById("canvasline"),
                            {
                                type: "bar",
                                plugins: [ChartDataLabels],
                                data: {
                                    labels: this.originstation,
                                    datasets: [
                                        {
                                            // type: "line",
                                            borderColor: "rgba(0,0,255,1)",
                                            backgroundColor: "rgba(0,0,255,1)",
                                            hoverBorderColor: "rgba(0,0,255,1)",
                                            // yAxisID: "y-axis-0",
                                            data: this.claimAmountYearOne,
                                            fill: false,
                                            label: data.yearOne + "ClaimAmount",
                                            stack: "a",
                                        },
                                        {
                                            // type: "line",
                                            borderColor: "rgba(255,165,0,1)",
                                            backgroundColor: "rgba(255,165,0,1)",
                                            hoverBackgroundColor: "rgba(255,165,0,1)",
                                            // yAxisID: "y-axis-0",
                                            data: this.paidAmountYearOne,
                                            fill: false,
                                            stack: "a",
                                            label: data.yearOne + "PaidAmount",
                                        },
                                        {
                                            // type: "line",
                                            borderColor: "rgba(91, 192, 194)",
                                            backgroundColor: "rgba(91, 192, 194)",
                                            hoverBorderColor: "rgba(91, 192, 194)",
                                            // yAxisID: "y-axis-0",
                                            data: this.claimAmountYearTwo,
                                            fill: false,
                                            stack: "b",
                                            label: data.yearTwo + "ClaimAmount",
                                        },
                                        {
                                            // type: "bar",
                                            borderColor: "rgba(246, 61, 28)",
                                            backgroundColor: "rgba(246, 61, 28)",
                                            hoverBackgroundColor: "rgba(246, 61, 28)",
                                            // yAxisID: "y-axis-1",
                                            data: this.paidAmountYearTwo,
                                            fill: false,
                                            stack: "b",
                                            label: data.yearTwo + "PaidAmount",
                                        },
                                    ],
                                },
                                
                                options: {
                                    maintainAspectRatio: false,
                                    tooltips: {
                                        callbacks: {
                                            title: function(tooltipItems,label) {
                                                if (label ===  data.yearOne + "ClaimAmount") {
                                                    return data.yearOne + "ClaimAmount" + "$" + tooltipItems.toString();
                                                }else if (label ===  data.yearOne + "PaidAmount") {
                                                    return data.yearOne + "PaidAmount" + "$" + tooltipItems.toString();
                                                }else if (label ===  data.yearTwo + "ClaimAmount") {
                                                    return data.yearTwo + "ClaimAmount" + "$" + tooltipItems.toString();
                                                }else if (label ===  data.yearTwo + "PaidAmount") {
                                                    return data.yearTwo + "PaidAmount" + "$" + tooltipItems.toString();
                                                }
                                               
                                          },
                                     
                                        }
                                      },
                                    title: {
                                        display: true,
                                        //text: "Origin Claim Count"
                                    },
                                    plugins: {
                                        // Change options for ALL labels of THIS CHART
                                        datalabels: {
                                            display: false,
                                            color: "#000000",
                                            anchor: "center",
                                            align: "center",
                                            rotation: -90,
                                            formatter: function (value, context) {
                                                return "$" + value;
                                            },
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                    },

                                    scales: {
                                        xAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Stations",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    autoSkip: false,
                                                    // max rotation: -90,
                                                    // min rotation: -90
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Amount ",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    beginAtZero: true,
                                                    //lineHeight:20,
                                                    // max:plugin.settings.maxDataValue,
                                                    maxTicksLimit: 10,
                                                    callback: function (value:any, _index, array) {
                                                        if (value == 0) {
                                                            return "0";
                                                        }
                                                        return value < 1000000
                                                            ? "$" + value / 1000 + "K"
                                                            : "$" + value / 1000000 + "M";
                                                    },
                                                },
                                                id: "y-axis-0",
                                                position: "left",
                                            },
                                        ],
                                    },
                                },
                                
                            }
                        );


                        this.destoriginstation = data.destPaidClaimAmount.stations,
                        this.canvaslineOnewidth = this.getWidth(data.destPaidClaimAmount.stations.length);
                            this.claimAmountYearOne = data.destPaidClaimAmount.claimAmountYearOne,
                            this.claimAmountYearTwo = data.destPaidClaimAmount.claimAmountYearTwo,
                            this.paidAmountYearOne = data.destPaidClaimAmount.paidAmountYearOne,
                            this.paidAmountYearTwo = data.destPaidClaimAmount.paidAmountYearTwo,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineOne"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstation,
                                        datasets: [
                                            {
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.claimAmountYearOne,
                                                fill: false,
                                                label: data.yearOne + "ClaimAmount",
                                                stack: "a",
                                            },
                                            {
                                                // type: "line",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.paidAmountYearOne,
                                                fill: false,
                                                label: data.yearOne + "PaidAmount",
                                                stack: "a",
                                            },
                                            {
                                                // type: "line",
                                                borderColor: "rgba(91, 192, 194)",
                                                backgroundColor: "rgba(91, 192, 194)",
                                                hoverBorderColor: "rgba(91, 192, 194)",
                                                // yAxisID: "y-axis-0",
                                                data: this.claimAmountYearTwo,
                                                fill: false,
                                                label: data.yearTwo + "ClaimAmount",
                                                stack: "b",
                                            },
                                            {
                                                // type: "bar",
                                                borderColor: "rgba(246, 61, 28)",
                                                backgroundColor: "rgba(246, 61, 28)",
                                                hoverBackgroundColor: "rgba(246, 61, 28)",
                                                // yAxisID: "y-axis-1",
                                                data: this.paidAmountYearTwo,
                                                fill: false,
                                                label: data.yearTwo + "PaidAmount",
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        // tooltips: {
                                        //     callbacks: {
                                        //         label: function (tooltipItems, data) {
                                        //             return "$" + tooltipItems.yLabel.toString();
                                        //         },
                                                
                                        //     },
                                        // },
                                        tooltips: {
                                            callbacks: {
                                                title: function(tooltipItems,label) {
                                                    if (label ===  data.yearOne + "ClaimAmount") {
                                                        return data.yearOne + "ClaimAmount" + "$" + tooltipItems.toString();
                                                    }else if (label ===  data.yearOne + "PaidAmount") {
                                                        return data.yearOne + "PaidAmount" + "$" + tooltipItems.toString();
                                                    }else if (label ===  data.yearTwo + "ClaimAmount") {
                                                        return data.yearTwo + "ClaimAmount" + "$" + tooltipItems.toString();
                                                    }else if (label ===  data.yearTwo + "PaidAmount") {
                                                        return data.yearTwo + "PaidAmount" + "$" + tooltipItems.toString();
                                                    }
                                                   
                                              },
                                         
                                            }
                                          },
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim amount"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: false,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "center",
                                                rotation: -90,
                                                formatter: function (value, context) {
                                                    return "$" + value;
                                                },
                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Stations",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Amount ",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,
                                                        callback: function (value:any, _index, array) {
                                                            if (value == 0) {
                                                                return "0";
                                                            }
                                                            return value < 1000000
                                                                ? "$" + value / 1000 + "K"
                                                                : "$" + value / 1000000 + "M";
                                                        },
                                                    },

                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );
                        }, 500);
                        // this.scrollView();
                    }
                }
                else{
                                    this.claimCountYearOne='0.00'
                                  this.claimCountYearTwo ='0.00';
                                    this.paidamountYearOne= '0.00';
                                    this.paidamountYearTwo='0.00';
                                    this.provisionamountYearOne='0.00';
                                    this.provisionamountYearTwo='0.00';
                                    this.suggestedYearOne='0.00';
                                    this.suggestedYearTwo='0.00';
                                    this.chargedYearOne='0.00';
                                    this.chargedYearTwo= '0.00';  
                }
                }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toaster.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            );
        }
    }
    // scrollView() {
    //     setTimeout(() => {
    //         const elements = document.getElementsByClassName('chartAreaWrapper_comparescroll60');
    //         for (let ele of elements as any) {
    //             ele.scrollTop = ele.scrollHeight;
    //         }
    //     }, 2000);

    // }

     getWidth(number) {
        this.height = 420;
        if (number < 10) {
            let finalWidth = 230 * number;
            return finalWidth;
        } else {
            let finalWidth = 60 * number;
            return finalWidth;
        }
    }
}
