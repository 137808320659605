import { Component, OnInit, enableProdMode, ViewChild, ElementRef } from '@angular/core';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment.prod';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { SuperadminService } from 'src/app/services/superadmin/superadmin.service';
import { ConfigServiceService } from '../../config-service.service';
import { Active_Archive_ReportsTableComponent } from '../../shared/dataGrid-tables/active-reports-table/active-archive-reports-table.component';
import { ExcelService } from "src/app/services/excel.service";

if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
declare var jQuery: any;
@Component({
    selector: 'app-admin-airline-dashboard',
    templateUrl: './admin-airline-dashboard.component.html',
    styleUrls: ['./admin-airline-dashboard.component.css']
})
export class AdminAirlineDashboardComponent implements OnInit {
    @ViewChild('reportsDataGrid') reportsDataGrid_Ref: Active_Archive_ReportsTableComponent;
 
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    public static totaldata = [];
    dataGrid1: DxDataGridComponent;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    Obj: any;
    orgType: any;
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    Always;
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    ObjectOne: any;
    ReportsProductionUrl: string;
    userName: any;
    LoginDetails: any;
    shortReports: any[];
    comnyorgid: any;
    orgId: any;
    loginUserId: any;
   
    mergedReportsFinal: any[];
    airlineDetails: any;
    showAll: boolean;
    showArchived: boolean;
    is_Refresh_Enable: boolean;
    reportAcessType: boolean;
    static obj: any;
    reportpreparename: any;
    Airlinename: any;
    static currentSelectedTab: string;
    static objdata: any;
    currentTabName: any;
    Emailstatus: any;
    loginDetails: any;
    multipleReportList: any[];
    userId: any;
    masterAwbNo: any;
    stationCode: any;
    classificationType: any;
    firstName: any;
    anchors: any;
    images: any;
    editicons: any;
    currentReportId: any;
    finalReportsCount: any;
    archiveReportsCount: any;
    incomingReportsCount: any;
    IS_Active_Or_Archive_Enable: boolean;
    internalReportCount: number;
    archveActive: boolean = false;
    selreportsarray: any[];
    carrierId: any;

    constructor(private ViewReportsData: ViewReportsData, private excelService:ExcelService,
         private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService,
          private adminservice: AdminService, 
        private spinner: NgxSpinnerService, private location: LocationStrategy,
        private superAdminservice: SuperadminService, private elementRef: ElementRef,private config : ConfigServiceService) { 
            history.pushState(null, null, window.location.href);  
            this.location.onPopState(() => {
              history.pushState(null, null, window.location.href);
            });  
        }

    ngAfterViewInit() {
        // this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
        let lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
        if (lastSelectedTAb != null && lastSelectedTAb != undefined) {
            if (lastSelectedTAb === "Active") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
            } else if (lastSelectedTAb === "Archived") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
            } else if (lastSelectedTAb === "Internal") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
            }
            else if (lastSelectedTAb === "SHARED_TAB"){
                this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;
            }
        } else {
            this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;
            this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;

            this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;
        }
    }
    ngOnInit() {
        
        window.scroll(0, 0);
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
        this.emailid = this.Object.emailId;
        this.usertype = this.Object.userType;
        this.orgType = this.Object.orgType;
        this.webapi = this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl  
        ;
        let  lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
        if(lastSelectedTAb != null && lastSelectedTAb != undefined){
            if(lastSelectedTAb ==="Active" ){
                 this.fn_OnTab_Changes('Active','A') ;
            }else if( lastSelectedTAb ==="Archived"){
                 this.fn_OnTab_Changes('Archived','R') ; 
            }  else if( lastSelectedTAb ==="Internal"){
                this.fn_OnTab_Changes('Internal','I') ; 
           } 
            else if(lastSelectedTAb ==="SHARED_TAB"){
                this.fn_sharedReports()
            }
        }else{
            this.fn_sharedReports()
        }
       
    }
    ngAfterViewChecked() {
        
        this.Obj = JSON.parse(localStorage.getItem("loginresultobject"))
        if(this.Obj.reportAcess === 'Y'){
            if(this.reportsDataGrid_Ref !== undefined){
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                   
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link'
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link';
                    this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount
                }else if(selectedreports === "Archived"){
                    this.is_Refresh_Enable = false;
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                   
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active'
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link';
                    this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount
                } else  if(selectedreports === "Internal"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                   
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link ';
                    document.getElementById('archive_Reports').className = 'nav-link'
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link active';
                    this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount
                } 
                else if(selectedreports === "SHARED_TAB"){
                    
                    this.showAll = false;
                    this.is_Refresh_Enable = false;
                    // this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
              
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('sharedReports').className = 'nav-link active';
                    document.getElementById('internalReports').className = 'nav-link';
                    this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount
                }
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
            }
        }else{
            if(this.reportsDataGrid_Ref !== undefined){
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
                this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link';
                   
                }else if(selectedreports === "Archived"){
                    this.is_Refresh_Enable = false;
                    this.showAll = false;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link';
                    // this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                }else if(selectedreports === "Internal"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('sharedReports').className = 'nav-link';
                    document.getElementById('internalReports').className = 'nav-link active';
                    // this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                }
                else if(selectedreports === "SHARED_TAB"){
                    this.showAll = false;
                    this.is_Refresh_Enable = false;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('sharedReports').className = 'nav-link active';
                    document.getElementById('internalReports').className = 'nav-link';
                    // this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                  
                }
                // this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
            }
        }



     
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.images = this.elementRef.nativeElement.querySelectorAll('img');
        this.editicons = this.elementRef.nativeElement.querySelectorAll('i');

        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
                else if (anchor.innerText === 'Delete' && href_Valid_Check_Var === 'delete') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
            }
        })

        this.editicons.forEach((editicon: HTMLImageElement) => {
            if (editicon.id != '') {
                let href_Split_Array = [];
                href_Split_Array = editicon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                
                if ((href_Valid_Check_Var === 'edit')) {
                    editicon.addEventListener('click', this.handleiconClick)
                }
            }
        })
        this.images.forEach((image: HTMLImageElement) => {
            if (image.id != '') {
                let href_Split_Array = [];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                
                if ((href_Valid_Check_Var === 'resend')) {
                    image.addEventListener('click', this.handleImageClick)
                }
            }
        })
    }
    public handleImageClick = (event: Event) => {
        event.preventDefault();
        const image = event.target as HTMLImageElement;

        this.fn_alertImage(image, event);
    }
    fn_alertImage(image, event) {
        
        let href_Split_Array = [];
        href_Split_Array = image.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'resend') {
            this.mergedReportsFinal[href_Valid_Check_Var]
            this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var], 'S');
        }
    }

    getSelectedDataOfresendReport(val, status) {
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }

    public handleAnchorClick = (event: Event) => {
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        let href_Split_Array = [];
        href_Split_Array = anchor.href.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        this.mergedReportsFinal[href_Valid_Check_Var];
        this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
    }
    public handleiconClick = (event: Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
        this.fn_ClickIcon(editicon, event);
    }
    fn_ClickIcon(editicon, event) {
        let href_Split_Array = [];
        href_Split_Array = editicon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'edit') {
            this.mergedReportsFinal[href_Valid_Check_Var];
            this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }
    }
    onEditClick(rowData) {
        localStorage.setItem('awbNumber', JSON.stringify(rowData.masterAwbNo));
    localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
        rowData.isFrom = 'EDIT_REPORT';
        rowData.from_csaDashBoard = 'true';

        this.router.navigate(['save-report'], { queryParams: rowData, skipLocationChange: true })
    }
    fn_sharedReports(){
        this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
        this.archveActive = true;
        this.IS_Active_Or_Archive_Enable = false
        let logindet = JSON.parse(localStorage.getItem('loginresultobject'));

        localStorage.setItem("reportsTab", JSON.stringify("SHARED_TAB"));
        localStorage.setItem("selectedreports", JSON.stringify("Incoming"));
     
        this.reportsDataGrid_Ref.source.url = this.webapi +  'adminDashboardCclpPortal.php/getAirlineAdminDashboard?emailId=' + logindet.emailId + '&userType=' + logindet.userType + '&orgType=' + logindet.orgType + '&loginUserId=' + logindet.userId ;
         this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
         this.reportsDataGrid_Ref.myGrid.gotopage(0)

         this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
         this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;
         this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
         this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;

         this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
         this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;


        //  this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
  
         Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];


         this.selectedRowsData = [];


    }


    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }
    getSelectedData(status) {
        
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.dataGrid.instance.getSelectedRowsData().length != 0) {
          
            localStorage.setItem("eachReport", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0]));
            this.ViewReportsData.isfromAirline_Admin_Dashboard = 'yes'
            this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
            // this.router.navigate(["/viewdetails", this.Object.orgId]);//new change
            this.router.navigate(["/viewdetails"],  { queryParams: { orgKey: this.Object.orgId}});

        }
    }
    getUser(obj, type) {
        
        
        let comapanydetails = {
            orgId: obj.organisationId,
            orgLogo: obj.orgLogo,
            orgName: obj.organisationName,
            orgType: obj.orgType
        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        this.router.navigate(["viewusers", obj.organisationId, type]);
        if (type === 'report') {
            this.ViewReportsData.isFrmViewDetails = "yes"
        }
    }
    Rowdoubleclick(event: any): void {
        status = ('viewreports');
        
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.mergedReportsFinal.length != 0) {
            localStorage.setItem("reportStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].masterAwbNo));
            localStorage.setItem('awbNumber_reportId', JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].emailStatus));
            if (this.subscribedParam === undefined) {
                this.ViewReportsData.isfromAirline_Admin_Dashboard = 'yes'
                this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
                // this.router.navigate(["/viewdetails", this.Object.orgId]);//new
                this.router.navigate(["/viewdetails"],  { queryParams: { orgKey: this.Object.orgId}});
               
            } else {
                this.ViewReportsData.isfromAirline_Admin_Dashboard = 'yes'
                this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
                // this.router.navigate(["/viewdetails", this.Object.orgId, this.subscribedParam]);neww 
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId ,type : this.subscribedParam} });
             
            }
        }
    }
    gotoLocations(data) {
        this.router.navigate(["viewstations"],
            { queryParams: { from: 'AirlineDashboard' } }
        );
    }
    AirportClaim() {
        window.open('https://devdashboard.cclp.aero/', '_blank');
    }
  //redirect to Uld damage reports
  onClickUldDamage_Reports() {
    localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
    this.router.navigateByUrl('/Uld/viewuld');
}

    onPageChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }
    onPageSizeChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }


//--- send , hold , functionalities----//
OpenEmailPopup(status) {

    if (this.selectedRowsData.length === 0) {
        this.toastr.error('Select reports to send');
    } else {
        this.currentTabName = JSON.parse(localStorage.getItem('reportsTab'))
        localStorage.setItem("Emailstatus", JSON.stringify(status));
        jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
    }

}
notCancel() {

    jQuery('#Activation').modal('hide');

}


sentMultipleEmailReport() {
    this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    if (this.selectedRowsData.length != 0) {
        this.multipleReportList = [];
        for (let index = 0; index < this.selectedRowsData.length; index++) {
                    this.userId = this.selectedRowsData[index].userId,
                        this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                        this.stationCode = this.selectedRowsData[index].stationCode,
                        this.classificationType = this.selectedRowsData[index].classificationType,
                        this.firstName = this.selectedRowsData[index].firstName
                     this.currentReportId = this.selectedRowsData[index].reportId,
                     this.carrierId = this.selectedRowsData[index].carrierId
                    this.multipleReportList.push({
                        userId: this.userId,
                        masterAwbNo: this.masterAwbNo,
                        stationCode: this.stationCode,
                        classificationType: this.classificationType,
                        firstName: this.firstName,
                      reportId: this.currentReportId,
                      carrierId: this.carrierId,
                      destairportId: this.selectedRowsData[index].destAirportName,
                      originAirportId: this.selectedRowsData[index].originAirportName,
                      internalResponsibility: this.selectedRowsData[index].reportStatus,
                      rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                      rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                      reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                    });
                // }
            // }

        }
        let ORGrootcause = 'N'
        if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
            ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
        }
        let dataObject = {
            reportStatus: this.Emailstatus,
            reportSendBy: this.loginDetails.userId,
            reportsList: this.multipleReportList,
            orgRootcauseStatus: ORGrootcause,
            carrierId: this.carrierId
        }
        if (this.multipleReportList.length === 0) {
            jQuery('#emailModal').modal('hide');
            //this.dataGrid.instance.deselectAll();
            this.myGrid.clearselection();
            this.toastr.error('No emails for this report');
            return false;
        }
        this.spinner.show();
        this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
           
            if (data.responseCode === 1) {
                // this.dataGrid.instance.deselectAll();
             
                this.toastr.success(data.message, '', {});
                jQuery('#emailModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
                // this.spinner.hide();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#emailModal').modal('hide');
                this.spinner.hide();
            } else {
            
                this.toastr.error(data.message, '', {});
                jQuery('#emailModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
                this.spinner.hide();
            }

        },
            error => {
               
                this.spinner.hide();
                jQuery('#emailModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            })

    }
    else {
        this.spinner.hide();
        this.toastr.error('Please select atleast one report');
    }
}


  OpenCancelPopup(status) {

    if (this.selectedRowsData.length === 0) {
        this.toastr.error('Select reports to hold');
    } else {
        let selectedRowsDAtaArray = []
        for (let i = 0; i < this.selectedRowsData.length; i++) {
            selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
        }
        if(selectedRowsDAtaArray.length !== 0){
          let isExist = selectedRowsDAtaArray.some((element) => {
              return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
            });
            if(isExist){
                jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
                return false;
            }else{
                localStorage.setItem("Emailstatus", JSON.stringify(status));
               jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
            }
         
        }
       
    }

}

cancelunselectSentReportExistPopup(){
    jQuery('#unselectSentReportExistPopup').modal("hide");
}



cancelMultipleEmailReport() {
    this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));

    if (this.selectedRowsData.length != 0) {
        this.multipleReportList = [];
        for (let index = 0; index < this.selectedRowsData.length; index++) {
                    this.userId = this.selectedRowsData[index].userId,
                        this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                        this.stationCode = this.selectedRowsData[index].stationCode,
                        this.classificationType =this.selectedRowsData[index].classificationType,
                        this.firstName = this.selectedRowsData[index].firstName
                    this.multipleReportList.push({
                        userId: this.userId,
                        masterAwbNo: this.masterAwbNo,
                        stationCode: this.stationCode,
                        classificationType: this.classificationType,
                        firstName: this.firstName,
                        destairportId: this.selectedRowsData[index].destAirportName,
                        originAirportId: this.selectedRowsData[index].originAirportName,
                        internalResponsibility: this.selectedRowsData[index].reportStatus,
                        rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                        rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                        reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                    });
                // }
            // }
        }
        let ORGrootcause = 'N'
        if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
            ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
        }
        let dataObject = {
            reportStatus: this.Emailstatus,
            reportSendBy: this.loginDetails.userId,
            reportsList: this.multipleReportList,
            orgRootcauseStatus: ORGrootcause,
            carrierId: this.carrierId
        }
        if (this.multipleReportList.length === 0) {
            jQuery('#cancelModal').modal('hide');
            //this.dataGrid.instance.deselectAll();
            this.myGrid.clearselection();
            this.toastr.error('No reports to hold');
            return false;
        }
        this.spinner.show();
        this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
             
                this.toastr.success(data.message, '', {});
                jQuery('#cancelModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#cancelModal').modal('hide');
                this.spinner.hide();
            }
            else {
               
                this.toastr.error(data.message, '', {});
                jQuery('#cancelModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
                this.spinner.hide();
            }
        },
            error => {
            
                this.spinner.hide();
                jQuery('#cancelModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            })

    }
    else {
        this.spinner.hide();
        this.toastr.error('Please select atleast one report');
    }
}

CancelUser() {

    let val = JSON.parse(localStorage.getItem('reportData'));
    let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    let Cancelstatus = JSON.parse(localStorage.getItem('Cancelstatus'));
    let dataObject = {
        reportSendBy: loginDetails.userId,
        userId: val.userId,
        masterAwbNo: val.masterAwbNo,
        stationCode: val.stationCode,
        classificationType: val.classificationType,
        reportStatus: Cancelstatus,
        firstName: val.reportPreparedName,
        reportId:val.reportId,
        carrierId: val.carrierId
    }
    this.spinner.show();
    this.superAdminservice.setholdTime(dataObject).subscribe(data => {
        
        if (data.responseCode === 1) {
         
            this.toastr.success(data.message, '', {});
            jQuery('#cancelsingleModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#cancelsingleModal').modal('hide');
            this.spinner.hide();
        } else if (data.responseCode === 0) {
            this.toastr.error(data.message, '', {});
            jQuery('#cancelsingleModal').modal('hide');
            this.spinner.hide();
        }
    },
        error => {
            this.spinner.hide();
            jQuery('#cancelsingleModal').modal('hide');
        })
}

resendManualReportEmail() {
     
    let val = JSON.parse(localStorage.getItem('reportData'));
    let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    let reportstatus = JSON.parse(localStorage.getItem('status'));
    let dataObject = {
        reportSendBy: loginDetails.userId,
        userId: val.userId,
        masterAwbNo: val.masterAwbNo,
        stationCode: val.stationCode,
        classificationType: val.classificationType,
        reportStatus: reportstatus,
        firstName: val.reportPreparedName,
        reportId:val.reportId,
        carrierId: val.carrierId
    }
    this.spinner.show();
    this.superAdminservice.setholdTime(dataObject).subscribe(data => {
       
        if (data.responseCode === 1) {
          
            this.toastr.success(data.message, '', {});
            jQuery('#resendModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#resendModal').modal('hide');
            this.spinner.hide();
        } else if (data.responseCode === 0) {
            this.toastr.error(data.message, '', {});
            jQuery('#resendModal').modal('hide');
            this.spinner.hide();
        }
    },
        error => {
            this.spinner.hide();
            jQuery('#resendModal').modal('hide');
        })

}




fn_OnTab_Changes(tabName : string , type: string){
    if(type === 'R'){
        this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
        this.archveActive = false;
    }else{
        this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
        this.archveActive = true;
    }
    this.IS_Active_Or_Archive_Enable = true
    localStorage.setItem('reportsTab', JSON.stringify(tabName));
    localStorage.setItem("ordType",JSON.stringify(type));
    localStorage.removeItem("selectedreports");
    this.spinner.show();
    this.selectedRowsData =[]
    try{
        let logindet = JSON.parse(localStorage.getItem('loginresultobject'));
        if(tabName === 'Internal'){
            this.reportsDataGrid_Ref.source.url =  this.webapi + 'getInternalReportList.php/getInternalReportList?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId
        }else{ 
            this.reportsDataGrid_Ref.source.url = this.webapi + 'superAdminUserReports.php/getUserReportInfo?organisationId=' + logindet.orgId + "&orderType="+type +"&loginUserType=" + logindet.userType + '&loginUserId=' + logindet.userId;
        }
         this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
         this.reportsDataGrid_Ref.myGrid.gotopage(0)
         this.reportsDataGrid_Ref.myGrid.clearselection()
       this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
       this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;


       this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
         Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
    }catch(exception){
        this.spinner.hide();
      
    }
 
}



public selectedRowsData = [] ;
_onSelectRow(event){ // trigger when checkbox checked / unchecked 
    
    if(Array.isArray(event.args.rowindex)){ 
       if(event.args.rowindex.length !== 0){
        this.selectedRowsData = this.reportsDataGrid_Ref.getDtotalData() ;
       }else{
        this.selectedRowsData = [];
       }
    }else{
        if( event.type === "rowunselect"){
            let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
            this.selectedRowsData.splice(index, 1);
        }else{
            var ind = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.findIndex(x => x.reportId === event.args.row.reportId);
            event.args.row.carrierId = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA[ind].carrierId;
            this.selectedRowsData.push(event.args.row) ; 
        }
    }
       
}

manualarchive(){
    
    if (this.selectedRowsData.length != 0) {
        this.selreportsarray = [];
    for(let i=0; i<this.selectedRowsData.length; i++){
        this.userId = this.selectedRowsData[i].userId,
        this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
        this.currentReportId = this.selectedRowsData[i].reportId,
        this.selreportsarray.push({
            userId: this.userId,
            awbNumber: this.masterAwbNo,
             reportId: this.currentReportId
        });
    }
    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginUserId = this.Object.userId;

        let finalobj = {
            "loginUserId": this.loginUserId,
            "reportsList": this.selreportsarray
        }
    this.spinner.show();
    this.superAdminservice.SetReportArchive(finalobj).subscribe(
        data => {
           
            
            if (data.responseCode == '1') {
               
                this.toastr.success(data.message, '', {});
               
                jQuery('#manarchModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#manarchModal').modal('hide');
                this.spinner.hide();
            }
            else {
               
                this.toastr.error(data.message, '', {});
                jQuery('#manarchModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
                this.spinner.hide();
            }
        },
        error => {
          
            this.spinner.hide();
            jQuery('#manarchModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
        }
    )
    }
    else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}

confirm_manualarchive(){
    if (this.selectedRowsData.length != 0) {
        jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}


onClick_CargoClaim(){
    localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
    this.router.navigateByUrl('/cargoClaim/dashboard');
}
onClick_Save_Report() {
    const  obj = {
        isFrom : 'CREATE_REPORT'
    };
    this.router.navigate(['save-report'], { queryParams: obj });
  }
  scaleColumnWidth(width:any,scale :number =10){
    return (width / scale)
  }

  onExportClick(): void {
    let deleteColumns = ['actions','uid','modifiedDate','modifiedTime','boundindex','uniqueid','internalFileName','externalFileName','visibleindex','forwarder_org_id','uldCargoId','uldCargoImages','uld_cargo_pdf','userId','firstName','reportId','orgName','status'];
    
    let reportsArray = [];
    // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
    reportsArray = this.reportsDataGrid_Ref.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
    let columnsData = this.reportsDataGrid_Ref.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
            return (!deleteColumns.includes(item.datafield)) ;
    });  //Excel columns Header And width obj array
    // for (let i = 0; i < reportsArray.length; i++) { 
    //   reportsArray[i]['take_in'] = CsaDashboardComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
    //   let imgTempArray = [];
    //     if(reportsArray[i]['cargoImages'].length !== 0){
    //     for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
    //       imgTempArray.push({
    //         image : this.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
        
    //       })
    //     }
    //   }
    //     reportsArray[i]['Images'] = imgTempArray;
    //   //  columnsData.push("Images");
    //   for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
    //       delete  reportsArray[i][deleteColumns[j]];
    //   }
    // }
    this.excelService.exportAsExcelFile(reportsArray,`adminAirlineDashboard_${(new Date()).toString().substring(0,24)}_page_${this.reportsDataGrid_Ref.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
  }
}
