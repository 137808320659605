import { Component, OnInit, ViewChild } from "@angular/core";
import { generalinfo } from "../../../../app/save-reports/generalinfo";
import { serviceRecoveryinfo } from "../serviceRecoveryinfo";

import { DatePipe, Location, LocationStrategy } from "@angular/common";
import { Compiler } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import * as enLocale from "date-fns/locale/en";
import { DatepickerOptions } from "ng2-datepicker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { ConfigServiceService } from "../../../../app/config-service.service";
import { CreatereportService } from "../../../../app/services/createreport.service";
import { ShareLoginDataService } from "../../../data/share-login-data.service";
import { NavbarComponent } from "../../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../../login/login-data.service";
import { LoginComponent } from "../../../login/login.component";
import { LoginService } from "../../../services/login/login.service";
import { MyAccountService } from "../../../services/myAccount/my-account.service";
import { RegisterService } from "../../../services/register/register.service";
import { ServiceRecovery } from "../../../services/serviceRecovery/recovery.service";
import { StationService } from "../../../services/station/station.service";
import { SuperadminService } from "../../../services/superadmin/superadmin.service";
declare var jQuery: any;
@Component({
  selector: "app-add-service-recovery",
  templateUrl: "./add-service-recovery.component.html",
  styleUrls: ["./add-service-recovery.component.css"],
})
export class AddServiceRecoveryComponent implements OnInit {
  @ViewChild("stepper") stepper: MatStepper;
  public filteredList: any = [];
  status1: boolean = false;
  loginComponent: LoginComponent;
  secondTB_COmp: boolean = false;
  firstTB_COmp: boolean = false;
  isFlight_Selected_Frm_List: boolean;
  flight0: any;
  flight1: any;
  flight2: any;
  isUnAuthReportAccess: boolean;
  apiCallCurrentTab: any;
  isFromNextButton: boolean;
  loginuserdetailsTest: any;
  items3: any;
  showitemssq: boolean;
  airwaybillobj = new serviceRecoveryinfo();
  aspectsobj:any = {};// new serviceRecoveryinfo();
  airlineId: any;
  shipmentobj = new generalinfo();
  awb: boolean;
  airlinedata: any;
  items1: any;
  items: any;
  showList1: boolean;
  showList: boolean;
  originAirportId: any = '';
  destairportId: any = '';
  affectedAwbarray: any;
  navigationEnable: boolean = false;
  masterAwbNo1: any;
  flightshipmentdata: any = [];
  recoveryAspcts: any;
  imageslist: any[];
  urlsNew: any = {};
  urlArrayTotal: any;
  damage_Report_Images: any;
  dd: any = [];
  allAirport:any=[];
  findDuplicateVal:any=[];
  // findDuplicateVal:any=[];
  // handler
  thirdTB_COmp: boolean = false;
  recoveryaspctsLable = ["MSCA", "FDCA", "DMA"];
  recoveryAspctsArray: any = [];
  allAirportName: any = [];
  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "DD-MM-YYYY",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0,
    locale: enLocale,
    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private DamageReportServicel: CreatereportService,
    private atp: AmazingTimePickerService,
    private datePipe: DatePipe,
    private ViewReportsData: ViewReportsData,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private myAccountService: MyAccountService,
    private SidebarComponent: SidebarComponent,
    private fb: FormBuilder,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private StationService: StationService,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private ServiceRecovery: ServiceRecovery,
    private config: ConfigServiceService
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));

    this.fetchEvent();
  }

  fetchEvent() {
    this.filteredList = JSON.parse(localStorage.getItem("flightshipmentdata"));
    if(this.filteredList == null || this.filteredList.length < 1){
      this.spinner.show();
      this.DamageReportServicel.getTruckAirportService().subscribe((data) => {
        if (data.responseCode === 1) {
          this.flightshipmentdata = data.airport_details;
          localStorage.setItem("flightshipmentdata",JSON.stringify(data.airport_details));
        } 
      },(error) => {}
      );
      this.spinner.hide();
    }
  }

  ngAfterViewInit() {
    this.fetchEvent();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this._compiler.clearCache();
    this.spinner.show();
    this.loginuserdetailsTest = JSON.parse(
      localStorage.getItem("loginDetails")
    );
    if (this.loginuserdetailsTest.orgType == "A") {
      this.firstTB_COmp = true;
      this.secondTB_COmp = true;
    } else {
      this.thirdTB_COmp = true;
    }

    this.aspectsobj.flightDate = "";
    this.aspectsobj.masterAwbNo = "";
    this.aspectsobj.airlineId = "";

    this.aspectsobj.originAirportAction = true;
    this.aspectsobj.originAirportHandlerId = 0;
    this.aspectsobj.originAirportHandlerName = "";
    this.aspectsobj.destairportAction = true;
    this.aspectsobj.destairportHandlerId = 0;
    this.aspectsobj.destairportHandlerName = "";

    this.aspectsobj.flightDate = new Date().toISOString();
    this.affectedAwbarray = [{
        airportName: "",
        airportAction: true,
        airportHandlerId: 0,
        airportHandlerName: "",
      }];

    this.aspectsobj.totalMAWBPieces = "";
    this.aspectsobj.totalMAWBWeight = "";
    this.aspectsobj.piecesInvolved = "";
    this.aspectsobj.piecesInvolvedWeight = "";
    this.aspectsobj.recoveryAspcts = "";
    this.aspectsobj.comment = "";
    this.aspectsobj.flightDate = new Date().toISOString();
    this.aspectsobj.carrierId = "";

    this.filteredList = JSON.parse(localStorage.getItem("flightshipmentdata"));

    this.DamageReportServicel.getTruckAirlineDetailsService().subscribe((data) => {
        if (data.responseCode === 1) {
          this.airlinedata = data.airline_details;
          localStorage.setItem(
            "airlinedata",
            JSON.stringify(data.airline_details)
          );
        }
      },(error) => {
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

  moreAirportSelectValue(name, code, i) {
    // var checkDuplicateAirport = this.checkDuplicateAirport(name, code);
    // if (checkDuplicateAirport == true) {
    //   this.affectedAwbarray[i].airportName = name + "-" + code;
    //   this.checkDuplicateAirport(name, code);
    // }
    this.affectedAwbarray[i].airportName = name + "-" + code;
    this.handlerExistsByAirport(code,'moreairport', i);
  }

  moreAirportSelectAction(value, i) {
    this.affectedAwbarray[i].airportAction = value;
   
  }

  morehawscall() {
    this.affectedAwbarray.push({ airportName: "", airportAction: "" });
  }

  cancelmorehawscall(i) {
    this.affectedAwbarray.splice(i, 1);
  }

  tabModelVAlidationCheck(tab) {
    if (
      this.loginuserdetailsTest != null &&
      this.loginuserdetailsTest != "" &&
      this.loginuserdetailsTest != undefined
    ) {
      this.apiCallCurrentTab = tab;
      if (tab === "firstTab") {
        this.secondTB_COmp = true;
        this.firstTB_COmp = true;
      }
    }
  }

  checkDuplicateAirportName(){
    var allAirport = [];
    allAirport.push(this.originAirportId); 
    allAirport.push(this.destairportId);
    this.affectedAwbarray.forEach(function(key, val) {
       allAirport.push(key.airportName);
    });
    var findDuplicateAirport =  allAirport.filter((item, index) => allAirport.indexOf(item) != index);
    return findDuplicateAirport;
  }

  checkDuplicate(){
    this.allAirport.splice(0, this.allAirport.length); 
    this.allAirport.push(this.aspectsobj.originAirportId); 
    this.allAirport.push(this.aspectsobj.destairportId);  
    for(let i=0;i<this.affectedAwbarray.length;i++)
    {this.allAirport.push(this.affectedAwbarray[i].airportName);
    }
    this.findDuplicateVal = this.allAirport.filter((item, index) => this.allAirport.indexOf(item) != index);
    return  this.findDuplicateVal;
   }

  
  goForward(stepper: MatStepper, tab, presentTab) {
    
    var checkAirport =this.checkDuplicate();
    
    if (tab === "first") {
      
      // validaction code start
      var duplicateAirportName = this.checkDuplicateAirportName();
     

      
      if (
        this.masterAwbNo1 === undefined || this.masterAwbNo1 === "" || this.masterAwbNo1 === null
      ) {
        this.toast.error("Please enter master airway bill");
        return false;
      } else if (
        this.shipmentobj.incidentDate === "" ||
        this.shipmentobj.incidentDate === null
      ) {
        this.toast.error("Please enter incident date");
        return false;
      } else if (
        this.aspectsobj.originAirportId === "" ||this.aspectsobj.originAirportId === null ||
        this.aspectsobj.originAirportId === undefined
      ) {
        this.toast.error("Please enter origin airport");
        return false;
      } else if (
        this.aspectsobj.destairportId === "" ||
        this.aspectsobj.destairportId === null || this.aspectsobj.destairportId === undefined
      ) {
        this.toast.error("Please enter destination airport");
        return false;
      } else if (checkAirport.length>0) {
        this.toast.error("Please remove duplicate : " + checkAirport[0]);
        this.allAirport.splice(0, this.allAirport.length); 
        return false;
      }
       else if (this.flight1 === "" || this.flight1 === null) {
        this.toast.error("Please enter flight");
        return false;
      } else if (this.flight2 === "" || this.flight2 === null) {
        this.toast.error("Please enter number");
        return false;
      } 
      // else if (this.findDuplicateVal.length) {
      //   this.toast.error("Please remove duplicate airport");
      //   return false;
        
      // }
      // validaction code end

      this.isFromNextButton = true;
      this.status1 = !this.status1;
      stepper.next();
      this.apiCallCurrentTab = presentTab;
      this.firstTB_COmp = true;
      this.secondTB_COmp = true;
    }
  }

  masterAwbNochange() {
    this.awb = false;
    this.airlinedata = JSON.parse(localStorage.getItem("airlinedata"));
    if (
      this.airlinedata != "" &&
      this.airlinedata != null &&
      this.airlinedata != undefined
    ) {
      this.airlinedata.forEach((item) => {
        if (item.AWB_prefix === this.aspectsobj.masterAwbNo) {
          this.aspectsobj.airlineId = item.carrierName;
          this.airlineId = item.carrierName;
          this.aspectsobj.carrierId = item.carrierId;
          // if ((this.flight1 === '') || (this.flight1 === undefined)) {
          if (this.aspectsobj.masterAwbNo === "203") {
            // if(this.airlineId === "Airtstname"){
            this.flight1 = "5J";
            this.flight0 = item.AWB_prefix;
            this.airlineId = "Cebu Pacific Air";
            this.aspectsobj.airlineId = this.airlineId;
            this.aspectsobj.carrierId = 182;
            // }else{
            // this.flight1 = '5J';
            // this.flight0 = item.AWB_prefix;
            // this.airlineId = "Cebu Pacific Air";
            // this.aspectsobj.airlineId = this.airlineId;
            // }
          } else {
            this.flight1 = item.IATA;
            this.flight0 = item.AWB_prefix;
            // console.log(" flight1 310 ", this.flight1);
            // console.log(" flight0 310 ", this.flight0);
            // if(this.aspectsobj.masterAwbNo === '998'){
            // // Show the results
            // this.show998 = true;
            // }
          }
          // this.flight1 = item.IATA;
          // this.flight0 = item.AWB_prefix;
          // }
          this.awb = true;
          this.isFlight_Selected_Frm_List = true;
        }
      });
    }

    if (this.awb === false) {
      this.aspectsobj.airlineId = "";
      this.airlineId = "";
    }

    this.aspectsobj.masterAwbNo.replace(/[^0-9]*/g, "");
    if (this.aspectsobj.masterAwbNo.length === 3) {
      // document.getElementById("_awbSuffix").focus();
      jQuery("#_awbSuffix").focus();
    }
  }

  onCancelPage() {
    jQuery("#isLeavePageWithouSave").modal("show");
    let isleavePage = JSON.parse(localStorage.getItem("isSaveReportLeave"));
  }

  onClick_YesLeavepagePopup() {
    jQuery("#isLeavePageWithouSave").modal("hide");
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
    this.route.navigateByUrl("/csa-service-recovery");
  }

  onClick_NoleavePage() {
    jQuery("#isLeavePageWithouSave").modal("hide");
  }

  // ----------------------airwaybill ,airline , origin ,destination airports search functionalities----------------------------------------
  initializeItems() {
    this.items3 = JSON.parse(localStorage.getItem("airlinedata"));
  }

  getitemssq(ev: any) {
    this.initializeItems();
    const val = ev;
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== "") {
      this.initializeItems();
      this.items3 = this.items3.filter((item) =>
        item.IATA.toLowerCase().startsWith(val.toLowerCase())
      );
      // Show the results
      this.showitemssq = true;
    } else {
      // hide the results when the query is empty
      this.showitemssq = false;
    }
  }

  slectflightcall(item) {
    this.aspectsobj.airlineId = item.carrierName;
    this.airlineId = item.carrierName;
    this.aspectsobj.carrierId = item.carrierId;
    this.isFlight_Selected_Frm_List = true;
    this.flight1 = item.IATA;
    this.flight0 = item.AWB_prefix;
    this.showitemssq = false;
  }

  getItems(ev: any) {
    this.aspectsobj.originAirportId = "";
    this.initializeItems2();
    const val = ev;
    if (val && val.trim() !== "") {
      this.items1 = this.items1.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
      if (this.items1.length === 0) {
        this.initializeItems2();
        this.items1 = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  getItems1(ev: any) {
    this.aspectsobj.destairportId = "";
    this.initializeItems1();
    const val = ev;
    if (val && val.trim() !== "") {
      this.items = this.items.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
      if (this.items.length === 0) {
        this.initializeItems1();
        this.items = this.items.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showList1 = true;
    } else {
      // hide the results when the query is empty
      this.showList1 = false;
    }
  }

  changeairportId() {}

  changeairportIdorigin() {}

  selectresult(item) {
    // var checkDuplicateAirport = this.checkDuplicateAirport(item.name,item.code);
    // if (checkDuplicateAirport == true) {
    //   this.aspectsobj.destairportId = item.name + "-" + item.code;
    //   this.destairportId = item.name + "-" + item.code;
    //   this.showList1 = false;
    // }
    this.aspectsobj.destairportId = item.name + "-" + item.code;
    this.destairportId = item.name + "-" + item.code;
    this.showList1 = false;

    

    this.aspectsobj.destairportId = item.name + "-" + item.code;
    this.destairportId = item.name + "-" + item.code;
    // item.name + "-" + item.code;
    this.showList1 = false;
    this.handlerExistsByAirport(item.code,'destination');
  }

  initializeItems1() {
    this.items = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  initializeItems2() {
    this.items1 = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  initializeMoreAirports() {
    this.filteredList = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  selectresultoriginid(item) {
    // var checkDuplicateAirport = false;
    // var checkDuplicateAirport = this.checkDuplicateAirport(item.name,item.code);
    this.handlerExistsByAirport(item.code,'origin');

    this.aspectsobj.originAirportId = item.name + "-" + item.code;
    this.originAirportId = item.name + "-" + item.code;
    // this.allAirport.push(this.originAirportId);
    this.showList = false;

    // if (checkDuplicateAirport == true) {
    //   this.aspectsobj.originAirportId = item.name + "-" + item.code;
    //   this.originAirportId = item.name + "-" + item.code;
    //   this.showList = false;
    // }
  }

  filterDropdown(e, i) {
    this.initializeMoreAirports();
    const val = e;
    if (val && val.trim() != "") {
      this.filteredList = this.filteredList.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
    }
  }

  spacecall(event) {
    if (event !== undefined && event !== null) {
      this.masterAwbNo1 = event;
      let trimmed = event.replace(/\s+/g, "");
      if (trimmed.length > 16) {
        trimmed = trimmed.substr(0, 16);
      }
      const numbers = [];
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
      this.masterAwbNo1 = numbers.join(" ");
    }
    if (this.masterAwbNo1.length === 9) {
      document.getElementById("next_ToAwb_flightnumber").focus();
    }
  }

  getCheckboxValues(ev, data) {
    let obj = data;
    if (ev.target.checked) {
      // Pushing the object into array
      this.recoveryAspctsArray.push(obj);
    } else {
      let el = this.recoveryAspctsArray.find((itm) => itm.order === data);

      if (el)
        this.recoveryAspctsArray.splice(
          this.recoveryAspctsArray.indexOf(el),
          1
        );
    }
  }
  
  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }
  public static _imagesArrayTotalNew = [];
  public static _imagesArrayTotalData = [];
  
  selectimages(val) {
    const self = this;
    const images = val.target.files;

    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match("image.*|application.*")) {
        this.toast.error("Please select image or pdf only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(",")[1];
        var base64Full = base64String;
        AddServiceRecoveryComponent._imagesArrayTotalData.push(base64Full);

        AddServiceRecoveryComponent._imagesArrayTotalNew.push(base64);
        self.preview(AddServiceRecoveryComponent._imagesArrayTotalNew);
      });
    }
  }

  preview(files) {
    this.urlsNew = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = "data:image/png;base64," + file;
        // document.body.appendChild(image);
        this.urlsNew.push(image);
        // this.urls.push(image);
        this.urlArrayTotal = this.urlsNew;
      }
    }
  }

  on_Cancel_Image(i) {
    AddServiceRecoveryComponent._imagesArrayTotalNew.splice(i, 1);
    AddServiceRecoveryComponent._imagesArrayTotalData.splice(i, 1);
    this.urlsNew.splice(i, 1);
  }

  on_Cancel_PreviousImage(i) {
    localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    this.damage_Report_Images.splice(i, 1);
  }

  //---------------------------End images uploadimg-----------------------------------------------

  _submitShortReport(currentTab, saveType, hitType) {
    var checkAirport =this.checkDuplicate();
    if (
      this.masterAwbNo1 === undefined || this.masterAwbNo1 === "" || this.masterAwbNo1 === null
    ) {
      this.toast.error("Please enter master airway bill");
      return false;
    } else if (
      this.shipmentobj.incidentDate === "" ||
      this.shipmentobj.incidentDate === null
    ) {
      this.toast.error("Please enter incident date");
      return false;
    } else if (
      this.aspectsobj.originAirportId === "" ||this.aspectsobj.originAirportId === null ||
      this.aspectsobj.originAirportId === undefined
    ) {
      this.toast.error("Please enter origin airport");
      return false;
    } else if (
      this.aspectsobj.destairportId === "" ||
      this.aspectsobj.destairportId === null || this.aspectsobj.destairportId === undefined
    ) {
      this.toast.error("Please enter destination airport");
      return false;
    } else if (checkAirport.length>0) {
      this.toast.error("Please remove duplicate : " + checkAirport[0]);
      this.allAirport.splice(0, this.allAirport.length); 
      return false;
    }
     else if (this.flight1 === "" || this.flight1 === null) {
      this.toast.error("Please enter flight");
      return false;
    } else if (this.flight2 === "" || this.flight2 === null) {
      this.toast.error("Please enter number");
      return false;
    } 
    
    else if (
      this.aspectsobj.totalMAWBPieces === "" ||
      this.aspectsobj.totalMAWBPieces === null
    ) {
      this.toast.error("Please enter MAWB Pieces");
    } else if (
      this.aspectsobj.totalMAWBWeight === "" ||
      this.aspectsobj.totalMAWBWeight === null
    ) {
      this.toast.error("Please enter weight");
    } else if (
      this.aspectsobj.piecesInvolved === "" ||
      this.aspectsobj.piecesInvolved === null
    ) {
      this.toast.error("Please enter pieces involved");
    } else {
      this.aspectsobj.masterAwbNo1 = this.masterAwbNo1;
      this.aspectsobj.userId = this.loginuserdetailsTest.userId;
      this.aspectsobj.orgId = this.loginuserdetailsTest.orgId;
      this.aspectsobj.flight1 = this.flight1;
      this.aspectsobj.flight2 = this.flight2;
      this.aspectsobj.comment = this.aspectsobj.comment;
      this.aspectsobj.affectedAwbarray = this.affectedAwbarray;
      this.aspectsobj.recoveryAspcts = this.recoveryAspctsArray;
      if (AddServiceRecoveryComponent._imagesArrayTotalData !== undefined) {
        this.dd = "";
        for (
          let i = 0;
          i < AddServiceRecoveryComponent._imagesArrayTotalData.length;
          i++
        ) {
          if (
            i + 1 ===
            AddServiceRecoveryComponent._imagesArrayTotalData.length
          ) {
            this.dd =
              this.dd + AddServiceRecoveryComponent._imagesArrayTotalData[i];
          } else {
            this.dd =
              this.dd +
              AddServiceRecoveryComponent._imagesArrayTotalData[i] +
              "#..";
          }
        }
        this.aspectsobj.imgUrl = this.dd;
      }
      console.log("payload====>",this.aspectsobj);
      this.ServiceRecovery.saveServiceRecovery(this.aspectsobj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.toast.success("Services Report has been saved successfully");
            localStorage.setItem("currentModule",JSON.stringify("ServiceRecovery"));
            this.route
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => this.route.navigate(["/csa-service-recovery"]));
          } else if (
            data.responseCode === 0 &&
            data.message === "Unauthorized User"
          ) {
            this.toastr.info("Your session is expired, please login again");
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.toast.error(data.saveDataReport.message);
          }
        },
        (error) => {
          this.toast.error("Report is not generated due to network issue.");
        }
      );
    }
  }

  checkDuplicateAirport(airport, airportCode) {
    this.handlerExistsByAirport(airportCode,'');
    // console.log("524", this.allAirportName);
    if (this.allAirportName.indexOf(airport) === -1) {
      this.allAirportName.push(airport);
      return true;
    } else {
      this.toast.error(airport + "  already added");
      return false;
    }
  }

  handlerExistsByAirport(airport,action,index=0) {
    this.spinner.show();
     let handlerExistsPayload: any = {};
    handlerExistsPayload.portCode = airport;
    this.ServiceRecovery.checkHandlerExistsByAirport(handlerExistsPayload).subscribe((data) => {
        // console.log("707 data => ",data);

        if(action == 'origin' && data.responseCode == 1) {
          this.aspectsobj.originAirportHandlerId   = data.handlerId;
          this.aspectsobj.originAirportHandlerName = data.handlerName;
        }else if(action == 'origin' && data.responseCode == 0){
          this.aspectsobj.originAirportHandlerId   = 0;
          this.aspectsobj.originAirportHandlerName = "No handler available";
        }

        if(action == 'destination' && data.responseCode == 1) {
          this.aspectsobj.destairportHandlerId   = data.handlerId;
          this.aspectsobj.destairportHandlerName = data.handlerName;
        }else if(action == 'destination' && data.responseCode == 0){
          this.aspectsobj.destairportHandlerId   = 0;
          this.aspectsobj.destairportHandlerName = "No handler available";
        }

        if(action == 'moreairport' && data.responseCode == 1) {
          this.affectedAwbarray[index].airportHandlerId =  data.handlerId;
          this.affectedAwbarray[index].airportHandlerName      =  data.handlerName;
        }else if(action == 'moreairport' && data.responseCode == 0){
          this.affectedAwbarray[index].airportHandlerId =  0;
          this.affectedAwbarray[index].airportHandlerName      = "No handler available";
        }
        // if(data.responseCode == 0) {
        //   return data; //this.toastr.info("No handler available with this airport");
        // }
        this.spinner.hide();
      },(error) => {
        this.toast.error("Report is not generated due to network issue.");
        // this.spinner.hide();
      }
    );
  }

  ngOnDestroy() {
    this.spinner.hide();
  }
}
