import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";

@Component({
    selector: 'app-compare-cliam-aspect',
    templateUrl: './compare-cliam-aspect.component.html',
    styleUrls: ['./compare-cliam-aspect.component.css']
})

export class CompareCliamAspectComponent implements OnInit {

    originstation: any = [];
    OriginyearOnecount: any = [];
    OriginyearTwocount: any = [];
    destoriginstation: any = [];
    destOriginyearTwocount: any = [];
    destOriginyearOnecount: any = [];
    originstationFormaml: any = [];
    OriginyearOnecountFormaml: any = [];
    OriginyearTwocountFormaml: any = [];
    inputObj: any;
    loginDetails: any;
    orgId: any;
    scrollable: boolean;
    scrollable1: boolean;
    scrollable2: boolean;
    public width: Number;
    public height: Number;
    canvaslineoriginFormalwidth: number;
    canvaslineOnewidth: number;
    canvaslinewidth: any;
    totalAspect: any;
    totalDest: any;
    totalOrgn: any;
    constructor(
        private claimcountservice: ClaimCountService,
        private toaster: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }
    ngOnInit() {
        this.totalAspect=[];
        this.totalDest=[];
        this.totalOrgn=[];
        this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
        this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        this.orgId = this.loginDetails.orgId;
        if (this.inputObj.yearOne && this.inputObj.yearTwo) {
            //api integration starts here
        this.spinner.show();
            this.claimcountservice.Compare_Claim_aspscts(this.inputObj).subscribe(
                (data) => {
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                        this.totalAspect = data.totalClaimAspects.labels;
                       this.totalDest = data.totalClaimAspectsDestn.labels;
                        this.totalOrgn = data.totalClaimAspectsOrigin.labels;


                        let labelLengthTotal = data.totalClaimAspects.labels.length;
                        let labelLengthdest = data.totalClaimAspectsDestn.labels.length;
                        let labelLengthorg = data.totalClaimAspectsOrigin.labels.length;
                        if (labelLengthorg > 60) {
                            this.scrollable = true
                        }
                        else {
                            this.scrollable = false
                        }
                        if (labelLengthdest > 60) {
                            this.scrollable1 = true
                        }
                        else {
                            this.scrollable1 = false
                        }
                        if (labelLengthTotal > 60) {
                            this.scrollable2 = true
                        }
                        else {
                            this.scrollable2 = false
                        }
                        setTimeout(() => {
                        //Claim aspects Count
                        this.originstation = data.totalClaimAspects.labels,
                        this.canvaslinewidth = this.getWidth(data.totalClaimAspects.labels.length)
                            this.OriginyearOnecount = data.totalClaimAspects.yearOneCount,
                            this.OriginyearTwocount = data.totalClaimAspects.yearTwoCount,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvasline"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.originstation,
                                        datasets: [
                                            {

                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.OriginyearOnecount,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {

                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.OriginyearTwocount,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                         maintainAspectRatio: false,
                                        title: {
                                            display: true,
                                            //text: "Origin Claim Count"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                color: "#000000",
                                                anchor: "end",
                                                align: "top",
                                                rotation: -90,
                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },

                                        scales: {
                                            xAxes: [
                                                {
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim aspect",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                        // max rotation: -90,
                                                        // min rotation: -90
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },
                                            ],
                                        },
                                    },
                                }
                            );
                        //Claim aspects Origin
                        this.canvaslineOnewidth = this.getWidth(data.totalClaimAspectsOrigin.labels.length);
                        console.log(this.canvaslineOnewidth)
                        this.destoriginstation = data.totalClaimAspectsOrigin.labels,
                            this.destOriginyearOnecount = data.totalClaimAspectsOrigin.yearOneCount,
                            this.destOriginyearTwocount = data.totalClaimAspectsOrigin.yearTwoCount,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineOne"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstation,
                                        datasets: [
                                            {

                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.destOriginyearOnecount,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {

                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.destOriginyearTwocount,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim amount"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim aspects per station",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count ",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,
                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );

                        //Claim aspects Destination
                        this.canvaslineoriginFormalwidth = this.getWidth(data.totalClaimAspectsDestn.labels.length);
                        this.originstationFormaml = data.totalClaimAspectsDestn.labels,
                            this.OriginyearOnecountFormaml = data.totalClaimAspectsDestn.yearOneCount,
                            this.OriginyearTwocountFormaml = data.totalClaimAspectsDestn.yearTwoCount,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineoriginFormal"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.originstationFormaml,
                                        datasets: [
                                            {
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.OriginyearOnecountFormaml,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {

                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.OriginyearTwocountFormaml,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        //   responsive: true,
                                        maintainAspectRatio: false,
                                        tooltips: {

                                        },
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim amount"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim aspects per station",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,

                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );
                        }, 500);
                        // this.scrollView();
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toaster.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            );

        }
        //api integration ends here

    }
    scrollView() {
        setTimeout(() => {
            const elements = document.getElementsByClassName('chartAreaWrapper_comparescroll60');
            for (let ele of elements as any) {
                ele.scrollTop = ele.scrollHeight;
            }
        }, 2000);

    }

    getWidth(number) {
       this.height = 420;
        if (number < 10) {
            let finalWidth = 230 * number;
            return finalWidth;
        } else {
            let finalWidth = 60 * number;
            return finalWidth;
        }
    }
}
