import { Component, OnInit, enableProdMode, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { DamageReportsService } from "../services/damageReports/damage-reports.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ConfigServiceService } from "src/app/config-service.service";
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: "app-damagereports",
  templateUrl: "./damagereports.component.html",
  styleUrls: ["./damagereports.component.css"],
})
export class DamagereportsComponent implements OnInit {
  @ViewChild("grid") dataGrid: DxDataGridComponent;
  dataGrid1: DxDataGridComponent;
  mergedReports: any;
  public static loginDetails: any = JSON.parse(
    localStorage.getItem("loginDetails")
  );
  reportsData_From_Servc: any;
  finalReports: any;
  WEBAPI_URL ;
  loginData: any;
  claimchart: any;
  showdamgesTab: boolean;
  Always: boolean;
  constructor(
    private router: Router,
    private damageReport_Service: DamageReportsService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    config : ConfigServiceService
  ) {

    this.WEBAPI_URL =    config.getConfig().CARGO_CLAIM_URL
  }
  ngOnInit() {
    //login data
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
    DamagereportsComponent.loginDetails = this.loginData;
    this.fn_toGet_damageReportsData();
  }
  //damage reports api
  fn_toGet_damageReportsData() {
    this.spinner.show();
    this.damageReport_Service
      .getDamageReports(
        DamagereportsComponent.loginDetails.emailId,
        DamagereportsComponent.loginDetails.userType,
        DamagereportsComponent.loginDetails.orgType,
        DamagereportsComponent.loginDetails.orgId
      )
      .subscribe(
        (responseData) => {
          if (responseData.responseCode === 1) {
            this.reportsData_From_Servc = responseData;
            this.finalReports = this.reportsData_From_Servc.userReports;
          } else {
            this.toaster.error(responseData.message);
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toaster.error("service error occured");
        }
      );
  }
  //To get row data
  getSelectedData(data) {
    localStorage.setItem("eachReport", JSON.stringify(data.data));
    this.router.navigate(["/cargoClaim/view-Details"]);
  }
}
