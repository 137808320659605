
export class investigation {
      
        public categoryId: any;
        public userId: any;
        public masterAwbNo: any;
        public damageDiscovered: any;
        public discoveresCaused: any;
        public internalName: any;
        public internalGroup: any;;
        public internalcode: any;
        public internalResponsibility: any;
        public internalArea: any;
        public areaSupervisor: any;
        public exactLocation: any;
        public locatonId: any;
        public casueOfEvent: any;
        public rootcause: any;

       
       


}
