import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ConfigServiceService } from "src/app/config-service.service";
// const webAPI = environment.URL;
@Injectable({
  providedIn: "root",
})
export class MyaccountService {
  webAPI:any ;
  constructor(private http: HttpClient ,config : ConfigServiceService) {
    this.webAPI  =    config.getConfig().CARGO_CLAIM_URL
  }
  myAccountDetails(userId) {
    userId;
    return this.http.post<any>(
      this.webAPI + "cargoClaim/myAccountAirportClaim.php/userDetails",
      userId
    );
  }
  passwordChange(obj) {
    return this.http.post<any>(
      this.webAPI + "cargoClaim/myAccountAirportClaim.php/changePassword",
      {
        orgId: obj.orgId,
        userId: obj.userId,
        oldPassword: obj.oldPassword,
        newPassword: obj.newPassword,
      }
    );
  }
  updateUserDetails(obj) {
    return this.http.post<any>(
      this.webAPI + "cargoClaim/myAccountAirportClaim.php/editProfile",
      obj
    );
  }
  getOrgStations(obj) {
    return this.http.post<any>(
      this.webAPI + "cargoClaim/myAccountAirportClaim.php/getStationsCclpPortal",
      obj
    );
  }
  OpenApi(obj) {
    return this.http.post<any>(
      this.webAPI + "cargoClaimOpenApi/generateTokens.php/generateApiTokens",
      obj
    );
  }
}
