import { Location, LocationStrategy } from "@angular/common";
import { Compiler, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import * as enLocale from "date-fns/locale/en";
import { DatepickerOptions } from "ng2-datepicker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../../../app/config-service.service";
import { ShareLoginDataService } from "../../../data/share-login-data.service";
import { NavbarComponent } from "../../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../../login/login-data.service";
import { LoginComponent } from "../../../login/login.component";
import { CreatereportService } from '../../../services/createreport.service';
import { LoginService } from "../../../services/login/login.service";
import { RegisterService } from "../../../services/register/register.service";
import { SuperadminService } from "../../../services/superadmin/superadmin.service";

declare var jQuery: any;
@Component({
  selector: 'app-grid-report-details',
  templateUrl: './grid-report-details.component.html',
  styleUrls: ['./grid-report-details.component.css']
})
export class GridReportDetailsComponent implements OnInit {

  loginComponent: LoginComponent;
  loginDetails: any;
  userName: [];
  orgType: string;
  loginUserId: string;
  incidentDate: any;
  time: any;
  station: string;
  flightNumber: string;
  airlineId: string;
  airlinecarrierId: string;
  airlinedata: any;
  awbNumber: string='';
  shipperName: string;
  addressShipper:string;
  shipperCode: string;
  consigneeName: string;
  incidentType="";
  orderTyp="";
  damageDiscovered: string = "";
  damageCheck:boolean=false;
  id: any;
  reportId: any;
  custome_own_Id: any;
  id2: any;
  reportId2: any;
  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "DD-MM-YYYY",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  createReport: boolean = false;
  awbNumberPart1: string = ""; // Initialize part 1 property
  awbNumberPart2: string = ""; // Initialize part 2 property
  resMessage: any;
  restStatus:any;
  emailList:any;
  selectedEmail:any;
  userEmail:string;
  formEdit:'1'
  selectednotified:any;
  ismail:any;
  notified:any;
  reportOrgId ="";
  reportOrgkey ="";
  isCheckbox:boolean = false;
  mannualCheck:boolean = false;
  mailsendStatus:any='0';
  isChecked:boolean=true;
  airlineItem:any;
  items1:any;
  showList: boolean;
  originAirportId:any;
  destairportId:any;
  items:any;
  showList1:any;
  items2:any;
  showList2:any;
  showList3:any;
  shipperId:any;
  filteredItems: any[] = [];
  flightshipmentdata: any = [];
  public filteredList: any = [];
  agentCode:any;
  orgAiroportId:any;
  destiAirportId:any;
  shipperCodeId:any;
  auditData:any;
  mailSend:any;
  isReadOnly: boolean = true;
  weight="";
  qty="";
  port_Id:any;
  selectedDamageTypes: string[] = [];
  chkAirMessage="";
  chkAircode="";
  only_scratches_tears_dents:any;
  inputValue:any;
  
  isVerifyingawb:any;
  searchInput: string = '';
  isUnAuthReportAccess:any;
  selectedAirports: string[] = [];
  tabModelVAlidationCheck:any;
  selectedAirportCodes: string[] = []; 
  selectedAirportId: string[] = []; 
  // name:string='';
  // agent_code:'';
  empEmail: '';
  mobNumber: '';
 ShiperlistRes:any;
  // addressBook modal | start
  addressBookShipperName = '';
  addressBookAgentCode = '';
  shipperid: string = "0";
  agent_code_id:any =[];
  damageOptions = [
    { id: 'broken', name: 'damageType', value: 'Broken', label: 'Broken' },
    { id: 'collapsed', name: 'damageType', value: 'Collapsed', label: 'Collapsed' },
    { id: 'crushed', name: 'damageType', value: 'Crushed', label: 'Crushed' },
    { id: 'damp', name: 'damageType', value: 'Damp', label: 'Damp' },
    { id: 'dented', name: 'damageType', value: 'Dented', label: 'Dented' },
    { id: 'hole', name: 'damageType', value: 'Hole', label: 'Hole in' },
    { id: 'open', name: 'damageType', value: 'Open', label: 'Open' },
    { id: 'missing', name: 'damageType', value: 'Missing', label: 'item missing' },
    { id: 'seal', name: 'damageType', value: 'Seal', label: 'Seal open/broken' },
    { id: 'shock', name: 'damageType', value: 'Shock', label: 'Shock watch' },
    { id: 'tape', name: 'damageType', value: 'Tape', label: 'Tape loose and/or torn' },
    { id: 'torn', name: 'damageType', value: 'Torn', label: 'Torn' },
    { id: 'wet', name: 'damageType', value: 'Wet', label: 'Wet' },
    { id: 'other', name: 'damageType', value: 'Other', label: 'Other' }
  ];
  editMode: boolean = false;

  constructor(
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private atp: AmazingTimePickerService,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private SidebarComponent: SidebarComponent,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private config: ConfigServiceService,
    private DamageReportServicel: CreatereportService,
  ) { 
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.loginUserId = this.loginDetails.userId;
    var orgId = this.loginDetails.orgId;
    this.orgType = this.loginDetails.orgType;

    this.superAdminservice.getUserDetails(orgId, this.loginUserId).subscribe(
      (data) => {
        this.userName = data.userDetails.filter(
          (user) => user.userStatus == "A"
        );
      },
      (error) => {}
    );
    this.spinner.hide();
    console.log("data 99 ==>", this.loginDetails);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params["id"];
     

      if (!this.id) {
        this.createReport = true;
        //this.formEdit='0'
      }

      if (this.reportId == "undefined") {
        this.reportId = "0";
      }
      console.log("Received data:", this.id);
      console.log("Received data2:", this.reportId);
    });

   this.getEmail();
   this.fetchAirlines();
    let obj = {
      id: this.id,
      customer_report_id: this.reportId,
    };
    console.log("payload", obj);
    this.spinner.show();
    setTimeout(() => {
      this.superAdminservice.getCustomerReportInfo(obj).subscribe(
        (data) => {
          console.log("data info===>", data.data);
          console.log("data tracking===>", data.trackData);
          this.auditData =data.trackData;
          this.incidentDate = data.data[0].incident_date;
          this.time = data.data[0].incident_time;
          this.station = data.data[0].station_code;
          this.flightNumber = data.data[0].flight_truck_no;
           this.awbNumber = data.data[0].master_awb_no;
          // 
         var firstThree = this.awbNumber.substring(0, 3);
         console.log("218",firstThree);
         console.log("219",this.airlinedata);
  
         var fetchAirline = this.airlinedata.filter((item) => {
          if (item.AWB_prefix !== null && item.AWB_prefix !== undefined) {
           return item.AWB_prefix.toLowerCase().startsWith(firstThree.toLowerCase())
          }
        });
        this.airlineId = fetchAirline && fetchAirline.length > 0 ? fetchAirline[0].carrierName : '';
          // 
         this.userEmail = data.data[0].is_email;
          this.shipperName = data.data[0].shipper;
          this.consigneeName = data.data[0].consignee;
          this.incidentType = data.data[0].type_of_incident;
          this.damageDiscovered = data.data[0].damage_discovered;
          this.custome_own_Id = data.data[0].customer_own_report_id;
          this.reportId2 = data.data[0].customer_report_id;
          this.id2 = data.data[0].id;
          this.selectedEmail=data.data[0].address_book_id;
          this.selectednotified=data.data[0].is_notified;
          this.reportOrgId = data.data[0].org_Id;
          this.reportOrgkey = data.data[0].org_Type;
          this.mailsendStatus = data.data[0].is_notified;
          this.shipperCode = data.data[0].agent_code;
          this.destairportId =data.data[0].destination_code;
          this.originAirportId =data.data[0].origin_code;
          this.ismail = data.data[0].is_email;
          this.destiAirportId=data.data[0].destination_code;
          this.orgAiroportId=data.data[0].origin_code;
          this.shipperCode=data.data[0].agent_code;
          this.shipperCodeId=data.data[0].address_book_id;
          this.qty = data.data[0].quantity;
          this.weight =data.data[0].weight;
          this.only_scratches_tears_dents = data.data[0].dents_or_tears;
          this.selectedDamageTypes = data.data[0].irregularity_for_damaged;
          console.log("damage==>",this.incidentType);
          console.log("tears==>",this.only_scratches_tears_dents);

          if(this.incidentType=='damaged_cargo' && this.only_scratches_tears_dents=='no'){
              this.damageCheck =true;
          }
  
          if(this.shipperCode==''){
             this.addressShipper='' 
          }else{
            this.addressShipper = this.shipperName;
          }
        
          if (this.selectednotified == 1 && this.ismail=="") {
           // this.isCheckbox = true; 
           this.mannualCheck = true;
          }else if(this.selectednotified == 1 && this.ismail!==""){
            this.isCheckbox = true;
          }
          else{
            this.isCheckbox = false; 
          } 
          if(this.mailsendStatus == 1){
            this.isChecked==true
          }else{
            this.isChecked==false
          }

          this.ShiperlistRes=localStorage.getItem('Shiperlist');
         if(this.ShiperlistRes){
            this.ShiperlistRes=JSON.parse(this.ShiperlistRes);
  
            var addressBookData = this.ShiperlistRes.find(t => t.shipping_agent_code === this.shipperCode);
      console.log("addressBookData==> 973 ", addressBookData);
      this.userEmail =addressBookData.email;
  
      this.addressBookShipperName = addressBookData.name;
      this.addressBookAgentCode =  addressBookData.shipping_agent_code;
      this.empEmail =addressBookData.email;
      this.mobNumber = addressBookData.number;
      this.shipperid=addressBookData.id
      this.selectedAirports =addressBookData.airport_code.split(',');
         }

  
        },
        (error) => {}
      );
   
    }, 1000);

    this.spinner.hide();
   
  }
  getColumnClass() {
    if (this.reportId == undefined) {
      return 'col-md-7 pr-0';
    } else {
      return 'col-md-12 ';
    }
  }

  getdivClass() {
    if (this.reportId == undefined) {
      return 'd-none';
    } else {
      return 'd-flex row';
    }
  }

  // getdivClassAir() {
  //   if (this.loginDetails.orgType == "H") {
  //     return 'col-md-5';
  //   } else {
  //     return 'col-md-6';
  //   }
  // }

  initializeItems3() {
    this.items2 = JSON.parse(localStorage.getItem("Shiperlist"));
    console.log("item 3==>", this.items2);
  }

  initializeItems2() {
    this.items1 = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }
  initializeItems1(){
    this.items = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  

  getItems(ev: any) {
    this.originAirportId = "";
    this.initializeItems2();
    const val = ev;
    if (val && val.trim() !== "") {
      this.items1 = this.items1.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
      if (this.items1.length === 0) {
        this.initializeItems2();
        this.items1 = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  getItems1(ev: any) {
    this.destairportId = "";

    this.initializeItems1();
    const val = ev;
    if (val && val.trim() !== "") {
      this.items = this.items.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );

      if (this.items.length === 0) {
        this.initializeItems1();
        this.items = this.items.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList1 = true;
    } else {
      this.showList1 = false;
    }
  }

  getItems2(val: string) {
    this.addressShipper = val;
    this.initializeItems3();
    if (val && val.trim() !== "") {
      this.filteredItems = this.items2.filter((item) =>
        item.name.toLowerCase().startsWith(val.toLowerCase())
      );
  
      if (this.filteredItems.length === 0) {
        this.initializeItems3();
        this.filteredItems = this.items2.filter((item) =>
          item.name.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList2 = true;
    } else {

      this.showList2 = false;
    }
  }

  getItems3(val: string) {
    this.addressShipper = val;
    this.initializeItems3();
    if (val && val.trim() !== "") {
      this.filteredItems = this.items2.filter((item) =>
        item.shipping_agent_code.toLowerCase().startsWith(val.toLowerCase())
      );
  
      if (this.filteredItems.length === 0) {
        this.initializeItems3();
        this.filteredItems = this.items2.filter((item) =>
          item.shipping_agent_code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList3 = true;
    } else {

      this.showList3 = false;
    }
  }


  selectresultoriginid(item) {
    this.originAirportId = item.name + "-" + item.code;
    this.orgAiroportId = item.code;
    this.port_Id = item.portId;
   // this.originAirportId = item.name + "-" + item.code;
    this.showList = false;
    this.checkOriginAirport();

  }
  selectresult(item) {
    this.destairportId = item.name + "-" + item.code;
    this.destiAirportId =item.code;
    //this.destairportId = item.name + "-" + item.code;
    this.showList1 = false;
  }
  selectResultShipper(item: any) {
    this.shipperCode = item.shipping_agent_code;
    this.addressShipper = item.name;
    this.shipperName = item.name;
    this.shipperCodeId =item.id;
    this.userEmail =item.email;
    this.showList2 = false;
  }
  selectResultShippercode(item: any) {
    this.addressShipper = item.name;
    this.shipperName = item.name;
    this.shipperCode = item.shipping_agent_code;
    this.shipperCodeId =item.id;
    this.userEmail =item.email;
    this.showList3 = false;
   
    
  }
  changeairportIdorigin() {}
  changeairportId() {}
  changeshipper(){}
  //changeshippercode(){}

  //======= for model airpot selection | start=======================



 

  getItems4(ev: any) {
    
      this.items = JSON.parse(localStorage.getItem("flightshipmentdata"));
      this.filteredItems = this.items; 
      console.log("filter===>", this.filteredItems);

    this.searchInput = ev.target.value;
    if (this.searchInput && this.searchInput.trim() !== "") {
      this.filteredItems = this.items.filter(item =>
        item.code.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        item.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
      this.showList = this.filteredItems.length > 0; 
    } else {
      this.showList = false;
    }
  }
  


  
  selectresultoriginid2(item) {
    const selectedAirport = item.name + " - " + item.code;
    this.selectedAirports.push(selectedAirport); 
    this.selectedAirportCodes.push(item.code); 
    this.selectedAirportId.push(item.portId);
    this.showList = false;
  
  }


  // selectresultoriginid4(item) {
  //   const selectedAirport = item.name + " - " + item.code;
  //   this.selectedAirports.push(selectedAirport); 
  //   this.selectedAirportCodes.push(item.code); 
  //   this.selectedAirportId.push(item.portId);
  //   this.showList = false;
  // }

   //======= for model airpot selection | end=======================

  _update() {

    if(this.loginDetails.orgType=="H"){

      if (!this.awbNumber) {
        this.toast.error("Please enter awbNumber ");
      }
      else if (!this.airlineId || this.airlineId.trim() === "") {
        this.toast.error("Airline not found for this awbNumber prefix ");
      }
      else if (!this.incidentDate) {
        this.toast.error("Please enter incident Date");
      } else if (!this.time || this.time.trim() === "") {
        this.toast.error("Please enter time ");
      } else if (!this.station || this.station.trim() === "") {
        this.toast.error("Please enter station ");
      } 
      else if(this.incidentType === '' || this.incidentType === undefined || this.incidentType == null){
        this.toast.error("Please select Type of Incident ");
      }
       else if(this.incidentType == 'damaged_cargo' && 
       (this.only_scratches_tears_dents == null || this.only_scratches_tears_dents == "no") && 
       (this.selectedDamageTypes == null || this.selectedDamageTypes.length == 0)) {
     this.toast.error("Please select irregularity for damaged cargo");
      }
      else {
        this.awbNumber = this.awbNumber.replace(' ', '');

        let formatRegex = /^\d{3}-\d{4} \d{4}$/;
    let formattedString = this.awbNumber;
    if (!formatRegex.test(this.awbNumber)) {
      formattedString = this.awbNumber.slice(0, 3) + '-';
      formattedString += this.awbNumber.slice(3, 7) + ' ';
      formattedString += this.awbNumber.slice(7);
  }

      let payload = {
        report_org_key: this.reportOrgkey,
        report_org_Id: this.reportOrgId,
        login_org_Key: this.loginDetails.orgType,
        login_org_id: this.loginDetails.orgId,
        loginUserId:this.loginDetails.userId,
        cargo_damage_report_id: this.reportId2,
        id: this.id2,
        incident_date: this.incidentDate,
        incident_time: this.time,
        station_code: this.station,
        flight_truck_no: this.flightNumber,
        master_awb_no: formattedString,
        shipper: this.shipperName,
        consignee: this.consigneeName,
        email: this.addressShipper,
        notified:this.selectednotified,
        type_of_incident: this.incidentType,
        damage_discovered: this.damageDiscovered,
        customer_own_report_id: this.custome_own_Id,
        isEmailSend : this.mailsendStatus,
        destAirport: this.destiAirportId,
        originAirport:this.orgAiroportId,
        agentCode:this.shipperCodeId,
        weight :this.weight,
        quantity:this.qty,
        damageId:this.selectedDamageTypes,
        scratches_tears:this.only_scratches_tears_dents
  
      };
  
      console.log("payload==>",payload);
  
     // return false
      this.superAdminservice.updateCustomerReportInfo(payload).subscribe(
        (data) => {
          this.toast.success(data.message);
          if(this.loginDetails.orgType=="A"){
            this.router.navigate(["/damageCustomerReport"]);
          }
          if(this.loginDetails.orgType=="H"){
            this.router.navigate(["/customerReportHandler"]);
          }
          //this.router.navigateByUrl("/damageCustomerReport");
        },
        (error) => {}
      );
    }


    }else{
      if (!this.awbNumber) {
        this.toast.error("Please enter awbNumber ");
      }
      else if (!this.incidentDate) {
        this.toast.error("Please enter incident Date");
      } else if (!this.time || this.time.trim() === "") {
        this.toast.error("Please enter time ");
      } else if (!this.station || this.station.trim() === "") {
        this.toast.error("Please enter station ");
      } 
      else if(this.incidentType === '' || this.incidentType === undefined || this.incidentType == null){
        this.toast.error("Please select Type of Incident ");
      }
       else if(this.incidentType == 'damaged_cargo' && 
       (this.only_scratches_tears_dents == null || this.only_scratches_tears_dents == "no") && 
       (this.selectedDamageTypes == null || this.selectedDamageTypes.length == 0)) {
     this.toast.error("Please select irregularity for damaged cargo");
      }
      else {
      //  this.awbNumber = this.awbNumber.replace(' ', '');

        
      let formatRegex = /^\d{3}-\d{4} \d{4}$/;
    let formattedString = this.awbNumber;
    if (!formatRegex.test(this.awbNumber)) {
      formattedString = this.awbNumber.slice(0, 3) + '-';
      formattedString += this.awbNumber.slice(3, 7) + ' ';
      formattedString += this.awbNumber.slice(7);
  }
        
      let payload = {
        report_org_key: this.reportOrgkey,
        report_org_Id: this.reportOrgId,
        login_org_Key: this.loginDetails.orgType,
        login_org_id: this.loginDetails.orgId,
        loginUserId:this.loginDetails.userId,
        cargo_damage_report_id: this.reportId2,
        id: this.id2,
        incident_date: this.incidentDate,
        incident_time: this.time,
        station_code: this.station,
        flight_truck_no: this.flightNumber,
        master_awb_no: formattedString,
        shipper: this.shipperName,
        consignee: this.consigneeName,
        email: this.addressShipper,
        notified:this.selectednotified,
        type_of_incident: this.incidentType,
        damage_discovered: this.damageDiscovered,
        customer_own_report_id: this.custome_own_Id,
        isEmailSend : this.mailsendStatus,
        destAirport: this.destiAirportId,
        originAirport:this.orgAiroportId,
        agentCode:this.shipperCodeId,
        weight :this.weight,
        quantity:this.qty,
        damageId:this.selectedDamageTypes,
        scratches_tears:this.only_scratches_tears_dents
  
      };
  
      console.log("payload==>",payload);
  
      //return false
      this.superAdminservice.updateCustomerReportInfo(payload).subscribe(
        (data) => {
          this.toast.success(data.message);
          if(this.loginDetails.orgType=="A"){
            this.router.navigate(["/damageCustomerReport"]);
          }
          if(this.loginDetails.orgType=="H"){
            this.router.navigate(["/customerReportHandler"]);
          }
          //this.router.navigateByUrl("/damageCustomerReport");
        },
        (error) => {}
      );
    }

    }
  }

  _submit() {

if(this.loginDetails.orgType=="H"){

  if (!this.awbNumber) {
    this.toast.error("Please enter awbNumber ");
  }
  else if (!this.airlineId || this.airlineId.trim() === "") {
    this.toast.error("Airline not found for this awbNumber prefix ");
  }
  else if (!this.incidentDate) {
    this.toast.error("Please enter incident Date");
  } else if (!this.time || this.time.trim() === "") {
    this.toast.error("Please enter time ");
  } else if (!this.station || this.station.trim() === "") {
    this.toast.error("Please enter station ");
  }
  else if(this.incidentType === '' || this.incidentType === undefined || this.incidentType == null){
    this.toast.error("Please select Type of Incident ");
  }
   else if(this.incidentType == 'damaged_cargo' && this.only_scratches_tears_dents == undefined || this.only_scratches_tears_dents == "no" && this.selectedDamageTypes.length == 0 ){
    this.toast.error("Please select Type of irregularity for damaged cargo ");
  }
  else {
    //  this.awbNumber = this.awbNumber.replace(' ', '');

    let formatRegex = /^\d{3}-\d{4} \d{4}$/;
    let formattedString = this.awbNumber;
    if (!formatRegex.test(this.awbNumber)) {
      formattedString = this.awbNumber.slice(0, 3) + '-';
      formattedString += this.awbNumber.slice(3, 7) + ' ';
      formattedString += this.awbNumber.slice(7);
  }
  
  console.log("awb format==>",formattedString);
  
    let payload = {
      cr_org_Key: this.loginDetails.orgType,
      cr_org_id: this.loginDetails.orgId,
      loginUserId:this.loginDetails.userId,
      incident_date: this.incidentDate,
      incident_time: this.time,
      station_code: this.station,
      shipper: this.shipperName,
      flight_truck_no: this.flightNumber,
      master_awb_no: formattedString,
      consignee: this.consigneeName,
      type_of_incident: this.incidentType,
      email: this.addressShipper,
      destAirport: this.destiAirportId,
      originAirport:this.orgAiroportId,
      agentCode:this.shipperCodeId,
      damage_discovered: this.damageDiscovered,
      weight :this.weight,
      quantity:this.qty,
      damageId:this.selectedDamageTypes,
      scratches_tears:this.only_scratches_tears_dents,
      carrierId:this.airlinecarrierId

    };
    console.log("HANDLER   payload 380 ==>",payload);
    //return false;
    this.superAdminservice.createCustomerReportInfo(payload).subscribe(
      (data) => {
       console.log("res==>",data);
        
        if(data.status=="1"){
          this.toast.success(data.message);
          if(this.loginDetails.orgType=="A"){
            this.router.navigate(["/damageCustomerReport"]);
          }
          if(this.loginDetails.orgType=="H"){
            this.router.navigate(["/customerReportHandler"]);
          }
        }else{
          this.toast.error(data.message);
        }
       
        //this.router.navigateByUrl("/damageCustomerReport");
      },
      (error) => {}
    );
  }

}else{

  if (!this.awbNumber) {
    this.toast.error("Please enter awbNumber ");
  }
  // else if (!this.airlineId || this.airlineId.trim() === "") {
  //   this.toast.error("Airline not found for this awbNumber prefix ");
  // }
  else if (!this.incidentDate) {
    this.toast.error("Please enter incident Date");
  } else if (!this.time || this.time.trim() === "") {
    this.toast.error("Please enter time ");
  } else if (!this.station || this.station.trim() === "") {
    this.toast.error("Please enter station ");
  }
  else if(this.incidentType === '' || this.incidentType === undefined || this.incidentType == null){
    this.toast.error("Please select Type of Incident ");
  }
   else if(this.incidentType == 'damaged_cargo' && this.only_scratches_tears_dents == undefined || this.only_scratches_tears_dents == "no" && this.selectedDamageTypes.length == 0 ){
    this.toast.error("Please select Type of irregularity for damaged cargo ");
  }
  else {
    // this.awbNumber = this.awbNumber.replace(' ', '');
    let formatRegex = /^\d{3}-\d{4} \d{4}$/;
    let formattedString = this.awbNumber;
    if (!formatRegex.test(this.awbNumber)) {
      formattedString = this.awbNumber.slice(0, 3) + '-';
      formattedString += this.awbNumber.slice(3, 7) + ' ';
      formattedString += this.awbNumber.slice(7);
  }
   
   console.log("awb format==>",formattedString);
    let payload = {
      cr_org_Key: this.loginDetails.orgType,
      cr_org_id: this.loginDetails.orgId,
      loginUserId:this.loginDetails.userId,
      incident_date: this.incidentDate,
      incident_time: this.time,
      station_code: this.station,
      shipper: this.shipperName,
      flight_truck_no: this.flightNumber,
      master_awb_no: formattedString,
      consignee: this.consigneeName,
      type_of_incident: this.incidentType,
      email: this.addressShipper,
      destAirport: this.destiAirportId,
      originAirport:this.orgAiroportId,
      agentCode:this.shipperCodeId,
      damage_discovered: this.damageDiscovered,
      weight :this.weight,
      quantity:this.qty,
      damageId:this.selectedDamageTypes,
      scratches_tears:this.only_scratches_tears_dents

    };
    console.log("payload 380 ==>",payload);
 // return false;
    this.superAdminservice.createCustomerReportInfo(payload).subscribe(
      (data) => {
       console.log("res==>",data);
        this.toast.success(data.message);
        if(this.loginDetails.orgType=="A"){
          this.router.navigate(["/damageCustomerReport"]);
        }
        if(this.loginDetails.orgType=="H"){
          this.router.navigate(["/customerReportHandler"]);
        }
        //this.router.navigateByUrl("/damageCustomerReport");
      },
      (error) => {}
    );
  }

}

    
  }
  _cancel() {
    if(this.loginDetails.orgType=="A"){
      this.router.navigate(["/damageCustomerReport"]);
    }
    if(this.loginDetails.orgType=="H"){
      this.router.navigate(["/customerReportHandler"]);
    }
  }

  onKeyPress1(event: KeyboardEvent) {
    this.inputValue = (event.target as HTMLInputElement).value;
    const key = event.key;
    if (key === "Backspace") {
      return;
    }
    if (this.inputValue.length >= 15) {
      event.preventDefault();
    }
    if(this.inputValue.length == 3){
      var firstThree = this.inputValue.substring(0, 3);
       var fetchAirline = this.airlinedata.filter((item) => {
        if (item.AWB_prefix !== null && item.AWB_prefix !== undefined) {
         return item.AWB_prefix.toLowerCase().startsWith(firstThree.toLowerCase())
        }
      });
      this.airlineId = fetchAirline && fetchAirline.length > 0 ? fetchAirline[0].carrierName : '';
    }

   
    setTimeout(() => {
      let value = this.awbNumber.replace(/\s+/g, ''); 

      if (value.length > 3) {
        value = value.substring(0, 3) + ' ' + value.substring(3);
      }

      this.awbNumber = value;
    }, 0);

  }
  getCleanAwbNumber(): string {
    return this.awbNumber.replace(/\s+/g, ''); // Remove all spaces for the clean value
  }

  onAWBNumberChange(value: string): void {
    this.awbNumber = value;
    if (this.awbNumber.length >= 3) {
      const firstThree = this.awbNumber.substring(0, 3);
      const fetchAirline = this.airlinedata.filter((item) => {
        return item.AWB_prefix && item.AWB_prefix.toLowerCase().startsWith(firstThree.toLowerCase());
      });
      this.airlineId = fetchAirline && fetchAirline.length > 0 ? fetchAirline[0].carrierName : ' ';
      this.airlinecarrierId = fetchAirline && fetchAirline.length > 0 ? fetchAirline[0].carrierId : '';
    } else {
      this.airlineId = ' ';
      this.airlinecarrierId ="";
    }
  }

  
  fetch() {
    this.spinner.show();
    let payload = {
      awb_no: this.awbNumber,
      loginUserId: this.loginUserId,
      orgId:this.loginDetails.orgId,
      orderType:JSON.parse(localStorage.getItem("ordType")),
      loginUserType:this.loginDetails.userType,
      orgType:this.orgType,
      master_awb_no:this.loginDetails.awbPrefix
    };
    console.log("payload 976==>",payload);
//return false
    this.superAdminservice.checkAWBNumber(payload).subscribe(
      (data) => {
        this.spinner.hide();
        this.restStatus = data.responsecode;
        this.resMessage = data.message;
        if (data.responsecode == 1) {
         // console.log("Data found");
         console.log("awb fetch data==>",data);
          // this.restStatus = data.responsecode;
          // this.resMessage = data.message; 
          this.incidentDate = data.data.date;
          this.time = data.data.time;
          this.station = data.data.station;
          this.flightNumber = data.data.FlightNo;
          this.shipperName = data.data.ShipperName;
          this.consigneeName = data.data.ConsigneeName;
          this.incidentType = data.data.incident;
          this.damageDiscovered = data.data.discovered;
          this.shipperCode = data.data.ShippingAgentCode;
          this.originAirportId = data.data.originAirportName;
          this.orgAiroportId=data.data.OriginCode;
          this.destairportId =data.data.destAirportName;
          this.destiAirportId=data.data.DestinationCode;
          this.shipperName =data.data.AgentName;
          this.shipperCodeId =data.data.address_book_id;
          this.weight =data.data.GrossWeight;
          this.qty = data.data.PiecesCount;
          this.incidentType = data.data.incident;
          this.selectedDamageTypes = data.data.irregularity_for_damaged;
          this.only_scratches_tears_dents = data.data.dents_or_tears;

          if(this.shipperCode==''){
            this.addressShipper='' 
         }else{
           this.addressShipper = this.shipperName;
         }
         if(this.incidentType=='damaged_cargo' && this.only_scratches_tears_dents=='no'){
          this.damageCheck =true;
      }else{
        this.damageCheck =false;
      }

         
         this.ShiperlistRes=localStorage.getItem('Shiperlist');
         if(this.ShiperlistRes){
            this.ShiperlistRes=JSON.parse(this.ShiperlistRes);
  
            var addressBookData = this.ShiperlistRes.find(t => t.shipping_agent_code === this.shipperCode);
      console.log("addressBookData==> 973 ", addressBookData);
      this.userEmail =addressBookData.email;
  
      this.addressBookShipperName = addressBookData.name;
      this.addressBookAgentCode =  addressBookData.shipping_agent_code;
      this.empEmail =addressBookData.email;
      this.mobNumber = addressBookData.number;
      this.shipperid=addressBookData.id
      this.selectedAirports =addressBookData.airport_code.split(',');
         }
      
  
         var firstThree = this.awbNumber.substring(0, 3);
         console.log("218",firstThree);
         console.log("219",this.airlinedata);
  
         var fetchAirline = this.airlinedata.filter((item) => {
          if (item.AWB_prefix !== null && item.AWB_prefix !== undefined) {
           return item.AWB_prefix.toLowerCase().startsWith(firstThree.toLowerCase())
          }
        });
        this.airlineId = fetchAirline && fetchAirline.length > 0 ? fetchAirline[0].carrierName : '';
         
      } else {
         this.damageCheck=false;
      
        this.incidentDate = "";
        this.time = "";
        this.station = "";
        this.flightNumber = "";
        this.shipperName = "";
        this.consigneeName = "";
        this.incidentType = "";
        this.damageDiscovered = "";
        this.shipperCode = "";
        this.originAirportId = "";
        this.orgAiroportId="";
        this.destairportId ="";
        this.destiAirportId="";
        this.shipperName ="";
        this.shipperCodeId ="";
        this.weight ="";
        this.qty = "";
        this.incidentType = "";
        this.addressShipper="";
        this.selectedDamageTypes = [];   
        this.userEmail ="";
 
      }
      
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
 
  getEmail() {
  
    let payload = {
      loginUserId: this.loginUserId,
      orgId: this.loginDetails.orgId
    };
  
    this.superAdminservice.getEmailList(payload).subscribe(
      (data) => {
        this.emailList =data.data;
        console.log("liust====>",this.emailList);
        localStorage.setItem("Shiperlist",JSON.stringify(this.emailList) );
      
      },
      (error) => {}
    );
  }

  fetchAirlines(){
    
      this.spinner.show();

      // 
      this.DamageReportServicel.getTruckAirlineDetailsService().subscribe(
        data => {
            if (data.responseCode === 1) {
              this.airlinedata = data.airline_details;
              localStorage.setItem('airlinedata', JSON.stringify(data.airline_details));
            }
          },
        error => {
          this.spinner.hide();
      });
      //

      this.superAdminservice.getTruckAirportService().subscribe((data) => {
       // console.log("air===>",data);
        if (data.responseCode === 1) {
          this.flightshipmentdata = data.airport_details;
         // console.log("air linew===>", this.flightshipmentdata);
          localStorage.setItem("flightshipmentdata",JSON.stringify(data.airport_details));
        } 
      },(error) => {}
      );
      this.spinner.hide();
    
  }
  
  
// getSelectedemail() {

//   this.userEmail = this.selectedEmail;
 
// }

  
getSelectednotified() {

 this.notified = this.selectednotified;
  console.log('Selected notified ===>:',  this.selectednotified);
  if (this.selectednotified == 1) {
    this.chklMail();
   // this.isCheckbox = true; 
  }else{
    this.isCheckbox = false;
    this.mannualCheck =false;
  } 
  
}

checkboxChanged(event: any) {
  this.isChecked = event.checked;
  console.log('Checkbox checked:', this.isChecked);
  if(this.isChecked==true){
   this.mailsendStatus='1'
  
  }else{
    this.mailsendStatus='0'
  }
 
}
checkboxmannualChanged(event: any) {
  this.isChecked = event.checked;
  console.log('Checkbox checked:', this.isChecked);
  if(this.isChecked==true){
   this.mailsendStatus='1'
  
  }else{
    this.mailsendStatus='0'
  }
 
}

chklMail(){
  let payload = {
    address_book_id:this.shipperCodeId
  };
  this.spinner.show();
  this.superAdminservice.chkMialId(payload).subscribe(
    (data) => {
     console.log("mail chking==>",data);
     if(data.responsecode==0){
      this.mannualCheck =true
     }else{
      this.mannualCheck =false
      this.isCheckbox = true
     }
    },
    (error) => {}
  );
  this.spinner.hide();

}

getSelectedIncident(){
console.log("incident Type==>",this.incidentType);
if(this.incidentType =='missing_cargo'){
  this.damageCheck = false;
  this.damageDiscovered ="A missing and/or lost cargo was noted and is pending investigation , this form is issued to document the incident "
}else if(this.incidentType == 'damaged_cargo'){
  //jQuery('#myModal2').modal('show');
 // this.damageCheck =true;
  console.log("option value==>",this.only_scratches_tears_dents);
  this.damageDiscovered ="The damaged was noted with irregularity upon arrival pending the investigation ,this form is issued to document the incident."
  
}else if(this.incidentType == 'lost_cargo'){
  this.damageCheck = false;
  this.damageDiscovered ="A missing and/or lost cargo was noted and is pending investigation , this form is issued to document the incident ."
}
}

getAddress(){
 // this.router.navigate(["address-book"]); 
 this.editMode =false;
 jQuery('#myModal').modal('show');
}

onCancel() {

  this.addressBookShipperName = '';
  this.addressBookAgentCode =  '';
  this.empEmail = '';
  this.mobNumber = '';
  this.shipperid= '';   
  this.editMode =false;
  this.selectedAirports =[];
 
           }

checkOriginAirport(){

  let payload = {
    address_book_id:this.shipperCodeId,
    airport_id:this.port_Id
  };
  console.log("airport chk==>",payload);
  //return false;
  this.spinner.show();
  this.superAdminservice.checkAirport(payload).subscribe(
    (data) => {
     console.log("airport chking==>",data);
     this.chkAircode =data.responsecode;
      this.chkAirMessage = data.message;
    
    },
    (error) => {}
  );
  this.spinner.hide();


}

updateSelectedDamageTypes(event: any, value: string) {
  if (!this.selectedDamageTypes) {
    this.selectedDamageTypes = [];
  }

  if (event.target.checked) {
    this.selectedDamageTypes.push(value);
  } else {
    const index = this.selectedDamageTypes.indexOf(value);
    if (index !== -1) {
      this.selectedDamageTypes.splice(index, 1);
    }
  }
  console.log("damage id==>",this.selectedDamageTypes); 
}

// damageDetails(){
//   this.damageCheck = true;
//   jQuery('#myModal2').modal('hide');
// }
handleOptionChange(){
  console.log('Selected value:', this.only_scratches_tears_dents);
   if(this.only_scratches_tears_dents =='no'){
    this.damageCheck = true;
   }else{
    this.damageCheck = false;
   }
}


isDamageTypeSelected(value: string): boolean {
  return this.selectedDamageTypes && this.selectedDamageTypes.includes(value);
}

getAddressBookData(){
  this.editMode =true;

  var addressBookData = this.filteredItems.filter(t=>t.shipping_agent_code ===this.shipperCode)[0];
  console.log("addressBookData==> 1174 ",addressBookData);

  this.addressBookShipperName = addressBookData.name;
  this.addressBookAgentCode =  addressBookData.shipping_agent_code;
  this.empEmail =addressBookData.email;
  this.mobNumber = addressBookData.number;
  this.shipperid=addressBookData.id
 // this.agent_code_id =addressBookData.airport_code_id;
  this.selectedAirports =addressBookData.airport_code.split(',');
}

arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
}

onSubmit() {
  if (!this.addressBookShipperName) {
    this.toast.error("Please enter shipper name ");
  } else if(this.addressBookShipperName.length < 3){
    this.toast.error("Shipper name must be at least 3 characters long");
  } else if(this.addressBookAgentCode == ""){
    this.toast.error("Please add agent code");
  }
  else{
    let payload = {
      name: this.addressBookShipperName,
      email: this.empEmail,
      user_id: this.loginUserId,
      id: this.shipperid,
      number: this.mobNumber,
      agent_code:this.addressBookAgentCode,
      // airport_code:this.selectedAirportId
      
      airport_code: this.arrayUnique(this.selectedAirportId.concat(this.agent_code_id))
    };
    console.log("payload:", payload);
  // return false;
 // this.shipperCode = this.addressBookAgentCode;
    this.superAdminservice.createAddress(payload).subscribe(
      (data) => {
        if(data.responsecode== 1){
          this.userEmail=this.empEmail;
          this.toast.success(data.message);
          this.getEmail();
          
          jQuery('#myModal').modal('hide');

          
        }else{
          this.toast.error(data.message)
        }
    
      },
      (error) => {}
    );
  }
  
}

}
