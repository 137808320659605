import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
@Component({
  selector: "app-damage-reports",
  templateUrl: "./damage-reports.component.html",
  styleUrls: ["./damage-reports.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DamageReportsComponent implements OnInit {
  damagereports: {
    originReports: { stations: string[]; count: number[] };
    topOriginReports: { stations: string[]; count: number[] };
    destinationReports: { stations: string[]; count: number[] };
    topDestnReports: { stations: string[]; count: number[] };
  };
  originstations: string[];
  origincount: number[];
  deststations: string[];
  destcount: number[];
  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {
    //response data
    this.damagereports = {
      originReports: {
        stations: [
          "BRU - World Flight Services",
          "HYD - Test Amsterdam",
          "KAB - Mindwave Handlers",
          "UAM - Mindwave Handlers",
          "AMS - World Flight Services",
          "FGI - Mindwave Handlers",
          "GNA - Cclptest",
          "HYD - castustestdev",
          "HYD - Mindwave Handlers",
          "KDK - Mindwave Handlers",
        ],
        count: [106, 1, 48, 9, 5, 35, 9, 5, 26, 6],
      },
      topOriginReports: {
        stations: [
          "AMS - Mindwave Handlers",
          "HYD - Mindwave Handlers",
          "AMS - sparx",
          "AMS - testcclp",
          "AMS - airpods",
          "HYD - castustestdev",
          "AMS - Cclptest",
          "AMS - Castus Info Handler",
          "AMS - World Flight Services",
          "AMS - Test Amsterdam ",
        ],
        count: [85, 26, 11, 7, 6, 3, 2, 2, 1, 1],
      },
      destinationReports: {
        stations: [
          "AMS - airpods",
          "AMS - Castus Info Handler",
          "AMS - Cclptest",
          "AMS - Mindwave Handlers",
          "AMS - Nageswar Handlers limited",
          "AMS - sparx",
          "AMS - Test Amsterdam",
          "AMS - testaero",
          "AMS - testcclp",
          "AMS - World Flight Services",
          "FGI - Mindwave Handlers",
          "GNA - Cclptest",
          "HYD - castustestdev",
          "HYD - Mindwave Handlers",
          "KDK - Mindwave Handlers",
        ],
        count: [6, 18, 2, 85, 10, 11, 1, 56, 7, 12, 1, 2, 3, 26, 10],
      },
      topDestnReports: {
        stations: [
          "BRU - Mindwave Handlers",
          "BRU - sparx",
          "BRU - testcclp",
          "BRU - airpods",
          "BRU - Cclptest",
          "BRU - castustestdev",
          "BRU - Castus Info Handler",
          "UAM - Mindwave Handlers",
          "KAB - Mindwave Handlers",
          "BRU - testaero",
        ],
        count: [111, 11, 7, 6, 3, 3, 2, 1, 1, 1],
      },
    };
    //damagereport  Origin bar model chart
    this.originstations = this.damagereports.originReports.stations;
    this.origincount = this.damagereports.originReports.count;
    new Chart(
      <HTMLCanvasElement>document.getElementById("origindamagereport"),
      {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels: this.originstations,
          datasets: [
            {
              type: "bar",
              borderColor: "#ED7D31",
              backgroundColor: "#ED7D31",
              hoverBackgroundColor: "#ED7D31",
              data: this.origincount,
              fill: false,
            },
          ],
        },
        options: {
          title: {
            display: true,
            //text: "Count Irregularity"
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              color: "#000000",
              anchor: "end",
              align: "top",
              rotation: -90,
            },
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Stations",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  autoSkip: false,
                  // max rotation: -90,
                  // min rotation: -90
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Damage reports",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  beginAtZero: true,
                  //lineHeight:20,
                  // max:plugin.settings.maxDataValue,
                  maxTicksLimit: 10,
                },
                id: "y-axis-0",
                position: "left",
              },
            ],
          },
        },
      }
    );
    //Damage reports origin on load top 10 bar chart
    this.originstations = this.damagereports.topOriginReports.stations;
    this.origincount = this.damagereports.topOriginReports.count;
    new Chart(
      <HTMLCanvasElement>document.getElementById("origindamagereportOne"),
      {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels: this.originstations,
          datasets: [
            {
              type: "bar",
              borderColor: "#ED7D31",
              backgroundColor: "#ED7D31",
              hoverBackgroundColor: "#ED7D31",
              data: this.origincount,
              fill: false,
            },
          ],
        },
        options: {
          title: {
            display: true,
            //text: "Top 10 Irregularity Count"
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              color: "#000000",
              anchor: "end",
              align: "top",
              rotation: -90,
            },
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Stations",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  autoSkip: false,
                  // max rotation: -90,
                  // min rotation: -90
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Damage reports",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  beginAtZero: true,
                  //lineHeight:20,
                  // max:plugin.settings.maxDataValue,
                  maxTicksLimit: 10,
                },
                id: "y-axis-0",
                position: "left",
              },
            ],
          },
        },
      }
    );
    //Damage reports  Destination  bar model chart starts here
    this.deststations = this.damagereports.destinationReports.stations;
    this.destcount = this.damagereports.destinationReports.count;
    new Chart(<HTMLCanvasElement>document.getElementById("destdamagereport"), {
      type: "bar",
      plugins: [ChartDataLabels],
      data: {
        labels: this.deststations,
        datasets: [
          {
            type: "bar",
            borderColor: "#ED7D31",
            backgroundColor: "#ED7D31",
            hoverBackgroundColor: "#ED7D31",
            data: this.destcount,
            fill: false,
          },
        ],
      },
      options: {
        title: {
          display: true,
          //text: "Count Irregularity"
        },
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: "#000000",
            anchor: "end",
            align: "top",
            rotation: -90,
          },
        },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Stations",
                fontSize: 16,
                fontColor: "#000000",
                fontStyle: "bold",
              },
              ticks: {
                autoSkip: false,
                // max rotation: -90,
                // min rotation: -90
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Damage reports",
                fontSize: 16,
                fontColor: "#000000",
                fontStyle: "bold",
              },
              ticks: {
                beginAtZero: true,
                //lineHeight:20,
                // max:plugin.settings.maxDataValue,
                maxTicksLimit: 10,
              },
              id: "y-axis-0",
              position: "left",
            },
          ],
        },
      },
    });
    //Damage reports Destination  bar top 10 on load chart starts here
    this.deststations = this.damagereports.topDestnReports.stations;
    this.destcount = this.damagereports.topDestnReports.count;
    new Chart(
      <HTMLCanvasElement>document.getElementById("destdamagereportOne"),
      {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels: this.deststations,
          datasets: [
            {
              type: "bar",
              borderColor: "#ED7D31",
              backgroundColor: "#ED7D31",
              hoverBackgroundColor: "#ED7D31",
              data: this.destcount,
              fill: false,
            },
          ],
        },
        options: {
          title: {
            display: true,
            //text: "Top 10 Irregularity Count"
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              color: "#000000",
              anchor: "end",
              align: "top",
              rotation: -90,
            },
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Stations",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  autoSkip: false,
                  // max rotation: -90,
                  // min rotation: -90
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Damage reports",
                  fontSize: 16,
                  fontColor: "#000000",
                  fontStyle: "bold",
                },
                ticks: {
                  beginAtZero: true,
                  //lineHeight:20,
                  // max:plugin.settings.maxDataValue,
                  maxTicksLimit: 10,
                },
                id: "y-axis-0",
                position: "left",
              },
            ],
          },
        },
      }
    );
  }
}
