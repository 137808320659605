import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.css']
})
export class SampleComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  date = new Date();
  private _imagesArrayTotal: any = [];
  urls = [];
  id: any;
  response: any;
  submittedResponse: any;
  data: any;
  signature: string;

  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'canvasWidth': 300,
    'canvasHeight': 100
  };

  constructor(private loginService: LoginService,
    public route: ActivatedRoute, private router: Router,
    public spinner: NgxSpinnerService, private toastr: ToastrService ) {
    route.params.subscribe(data => {
      this.id = data.id;
    })
  }

  ngOnInit() {
    localStorage.clear();
    sessionStorage.clear();
    let obj = {
      key: this.id
    }
    this.spinner.show();
    this.loginService.getHAWBdetails(obj).subscribe(resp => {
      this.spinner.hide();
      console.log(resp);
      this.response = resp;
      if (this.response.responseCode === 1 && this.response.data.length !== 0) {
        this.data = this.response.data[0];
      } else {
        this.router.navigateByUrl('/#/Home');
      }
    }, err => {
      this.spinner.hide();
    })
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  clear() {
    this.signaturePad.clear();
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    this.signature = this.signaturePad.toDataURL()
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }


  blobToBase64(blob, callback): void {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }
  
  selectimages(val) {
    const self = this;
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        const base64 = base64String.split(",")[1];
        self._imagesArrayTotal.push(base64);
        self.preview(self._imagesArrayTotal);
      });
    }
    val.target.value = null;
  }

  preview(files) {
    this.urls = [];
    if (files.length !== 0) {
      for (const file of files) {
        const image = new Image();
        image.src = "data:image/png;base64," + file;
        this.urls.push(image);
      }
    }
  }

  on_Cancel_Image(index) {
    this.urls.splice(index, 1);
    this._imagesArrayTotal.splice(index, 1);
  }

  cancel() {
    this.router.navigateByUrl('/#/Home');
  }

  submit() {
    let obj = {
      hawb_id: this.data.hawb_id,
      created_on: moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
      signature: { imageURL: this.signature },
      images: []
    };
    for(let item of this._imagesArrayTotal) {
      obj.images.push({
        imageURL: item
      });
    }
    this.spinner.show();
    this.loginService.submitDeliveryReciept(obj).subscribe(resp => {
      this.spinner.hide();
      this.submittedResponse = resp;
      if (this.submittedResponse.responseCode === 1) {
          this.toastr.success(this.submittedResponse.message, '', { timeOut: 1500 });
          this.router.navigateByUrl('/#/Home');
      } else {
        this.toastr.warning(this.submittedResponse.message, '', { timeOut: 1500 });
      }
    }, error => {
      this.spinner.hide();
      this.toastr.warning(error.message, '', { timeOut: 1500 });
    })
  }


}
