import number_box from "devextreme/ui/number_box";

  export class createUldModel{ 
   
   public userId: number;
   public siIncidentDate: string; 
   public siIncidentTime:string;  
   public organizationId: number; 
   public siStationCode: string;  
   public siReportPreparedName: string;  
   public siReportPreparedFunction: string; 
   public iswebormobile: number; 
   public uiReturnFromAgent: string; 
   public uiType: string; 
   public uiSerialNumber: string; 
   public fiAirlineId: number; 
   public uiOtherUdOwnername: string; 
   public fiFlightDetails:string; 
   public fiFlightDetailsData: string; 
   public fiFlightDate: string; 
   public fiOriginAirportName: string;  
   public uiNameOfReturningAgent: string; 
   public inaHandlingStageId:string; 
   public inaHandlingStageOther: string; 
   public inaProcessAreaId: string; 
   public inaProcessAreaOther: string; 
   public inaDepartment:string;  
   public inaDiscoveresCaused: string; 
   public inaInternalName: string; 
   public inaInternalResponsibility: string; 
   public inaAreaSupervisor: string;  
   public inaExactLocation: string; 
   public inaLocationId: string; 
   public inaCasueOfEvent: string; 
   public diRefrigerationActive: string; 
   public diServiceable: string; 
   public diAdditionalDamageInfo: string; 
   public picDamageImage: string; 
   public carrierId: any;
 
} 


  export class updateUldModel{ 
    public reportId :number ; //new
    public inaProcessAreaId: string; 
    public fiAirlineId: number; 
    public fiFlightDetailsData: string; 
    public inaHandlingStageId:string; 
    public userId: number;
    public siIncidentDate: string; 
    public siIncidentTime:string;  
    public organizationId: number;
    public siStationCode: string;  
    public siReportPreparedName: string;  
    public siReportPreparedFunction: string;
    public iswebormobile: number; 
    public uiReturnFromAgent: string; 
    public uiType: string;
    public uiSerialNumber: string; 
    public uiOtherUdOwnername: string;
    public fiFlightDetails:string; 
    public fiFlightDate: string; 
    public fiOriginAirportName: string;
    public uiNameOfReturningAgent: string; 
    public inaHandlingStageOther: string;
    public inaProcessAreaOther: string; 
    public inaDepartment:string;  
    public inaDiscoveresCaused: string;
    public inaInternalName: string; 
    public inaInternalResponsibility: string; 
    public inaAreaSupervisor: string;  
    public inaExactLocation: string; 
    public inaLocationId: string; 
    public inaCasueOfEvent: string; 
    public diRefrigerationActive: string; 
    public diServiceable: string; 
    public diAdditionalDamageInfo: string;
    public oldDamageImages: string;
    public picDamageImage: string; // new 
    public carrierId: any;

} 

export class uldReportEmailsModel{
 public awbNumber : string; 
 public organizationId :number
 public userId :number
 public reportId :number
 public carrierId: any;
}