import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputFormats]'
})
export class InputFormatsDirective {

 
  constructor(private _el: ElementRef) { }
 @HostListener('input', ['$event']) onInputChange(event) {
  ;
    const initalValue = this._el.nativeElement.value;
     if(this._el.nativeElement.id === 'mawb_Num_Prefix'){
      this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    }
    else if(this._el.nativeElement.id === "mawb_Num_Suffix" || this._el.nativeElement.id === "uldSerialNum" ||this._el.nativeElement.id === "uldFlightNumFeild"){
      this._el.nativeElement.value = initalValue.replace(/[^0-9\s]*/g, '');
    }
    else if(this._el.nativeElement.id === "referenceNumber"){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-z0-9\-]*/g, '');
    }else if(this._el.nativeElement.id === "shiperName" || this._el.nativeElement.id === "uldReturnedByFeild" || this._el.nativeElement.id === "uldOwnerNameFeild"){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-z\s\-]*/g, '');
    }else if(this._el.nativeElement.id === "cdMwab" ){
      this._el.nativeElement.value = initalValue.replace(/[^0-9\s\-]*/g, '');
    }
      if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
