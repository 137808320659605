import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';



@Injectable({
  providedIn: 'root'
})
export class UserService {
    webAPI:string ;
  constructor(private http: HttpClient ,config : ConfigServiceService ) {
    this.webAPI = config.getConfig().URL
   }

  createUser(userObj) {
        return this.http.post<any>(this.webAPI + "userDetailsCclpPortal.php/createUser", userObj)
    }

    viewUser(userId) {
        return this.http.post<any>(this.webAPI + "tracking.php/viewUserDetails", {userId})
    }

    updateUser(userObj){
        return this.http.post<any>(this.webAPI + 'userDetailsCclpPortal.php/editUser', userObj)

    }

    userAccess(Obj){
         return this.http.post<any>(this.webAPI + 'getAccessReports.php/setUserAccessed', Obj)
    }
}
