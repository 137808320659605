import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  template: '<ngx-spinner></ngx-spinner>',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private navbarComponent: NavbarComponent,public route: Router) { }

  ngOnInit() {
    const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
    if(loginuserdetails != null && loginuserdetails != '' && loginuserdetails != undefined){
      this.navbarComponent.logOut();
    }else{
      this.route.navigateByUrl("/")
    }
  }

}
