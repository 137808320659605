import { Component, OnInit, enableProdMode, ViewChild, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
declare var jQuery: any;
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { ShareLoginDataService } from '../../../data/share-login-data.service';
import { SwitchdashboardService } from '../../../services/switch-dashboard/switchdashboard.service';
import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';
import { error } from 'util';
import { CookieService } from 'ngx-cookie-service';
import { RefreshService } from 'src/app/services/Refresh/refresh.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { Location } from '@angular/common';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../../../config-service.service';
// import { Active_Archive_ReportsTableComponent } from '../../';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-view-users',
    templateUrl: './view-users.component.html',
    styleUrls: ['./view-users.component.css']
})
export class ViewUsersCargoClaimComponent implements OnInit, OnDestroy {
    // @ViewChild('reportsDataGrid') reportsDataGrid_Ref: Active_Archive_ReportsTableComponent;

    dummyObj: any;
    dataSourceReportsArray = [];
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    Always;
    finalReportArray = [];
    mergedReports = [];
    public static totaldata = [];
    public static viewtotaldata = [];
    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    Object: any;
    loginDetails: any
    activateObj: { "organizationId": any; "status": string; };
    obj: any;
    ReportsProductionUrl: string;
    reportpreparename: string;
    Airlinename: string;
    submitButtonShow: boolean = false;
    Emailstatus: any;
    disabledValues: number[];
    multipleReportList: any;
    userId: string;
    masterAwbNo: string;
    stationCode: string;
    firstName: string;
    classificationType: string;
    disabledKeys: any[];
    dataGridInstance: any;
    justDeselected: any;
    popUpUsertype: any;
    createUserButton: boolean;
    typeOfCard: any;
    showUsersAndReports: boolean;
    isFrom: any;
    mergedReportsFinal: any[];
    airlineDetails: any;
    showAll: boolean;
    showArcived: boolean;
    orgKey: any;
    Acticpam: any;
    comnyorgid: any;
    cookieValue: string;
    isRefreshEnable: boolean;
    is_From_Edit_Damage_Report: any;
    Active_Reports_nav_Class: any;
    view_users_nav_Class: string;
    Archived_Reports_nav_Class: string;
    anchors: any;
    icons: any;
    images: any;
    public static obj_data;
    public static view_object;
    spantexts: any;
    orgId: any;
    loginUserId: any;
    shortReports: any;
    userTypeValue: any;
    currentTabName: any;
    finalobj: any;
    selreportsarray: any;
    currentReportId: any;
    archiveReportsCount: any;
    finalReportsCount: any = 0;
    incomingReportsCount: any;
    showIncoming_Reports: any;
    constructor(
        private config: ConfigServiceService,
        private location: Location,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private ViewReportsData: ViewReportsData,
        private refreshServiceRef: RefreshService,
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private superAdminservice: SuperadminService,
        private spinner: NgxSpinnerService,
        private SwitchdashboardService: SwitchdashboardService, private cookieService: CookieService,
        private locationStr: LocationStrategy,) {
        this.disabledValues = [1];
        this.disablechbx = this.disablechbx.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        // preventing back button in browser   
        history.pushState(null, null, window.location.href);
        this.locationStr.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });

    }


    ngAfterViewInit() {
        
        if (this.myGrid !== undefined && this.myGrid !== null) {
            this.myGrid.refresh();
        }
    }
    ngAfterViewChecked() {
        if (this.comapanydetails === null) {
            this.comapanydetails = JSON.parse(localStorage.getItem('loginresultobject'));
        }
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.icons = this.elementRef.nativeElement.querySelectorAll('i');
        this.images = this.elementRef.nativeElement.querySelectorAll('img');
        this.spantexts = this.elementRef.nativeElement.querySelectorAll('span');
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                } else if ((anchor.innerText === 'Reset') && (href_Valid_Check_Var === 'reset')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                } else if ((anchor.innerText === ' Edit') && (href_Valid_Check_Var === 'viewedit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                } else if ((anchor.innerText === 'Delete') && (href_Valid_Check_Var === 'delete')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                } else if ((anchor.innerText === 'Switch') && (href_Valid_Check_Var === 'switch')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                } else if ((anchor.innerText === 'Set user roles') && (href_Valid_Check_Var === 'set')) {
                    anchor.addEventListener('click', this.handleAnchorClick)

                }
            }
        })

        this.icons.forEach((icon: HTMLImageElement) => {
            //
            //console.log("anchors1innerText",anchor.innerText);
            if (icon.id != '') {
                let href_Split_Array = [];
                href_Split_Array = icon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                //
                if ((href_Valid_Check_Var === 'edit')) {
                    icon.addEventListener('click', this.handleiconClick)
                } else if (href_Valid_Check_Var === 'resend') {
                    icon.addEventListener('click', this.handleiconClick)

                } else if (href_Valid_Check_Var === 'reset') {
                    icon.addEventListener('click', this.handleiconClick)
                } else if (href_Valid_Check_Var === 'viewedit') {
                    icon.addEventListener('click', this.handleiconClick)
                }
                else if (href_Valid_Check_Var === 'delete') {
                    icon.addEventListener('click', this.handleiconClick)
                }
                else if (href_Valid_Check_Var === 'switch') {
                    icon.addEventListener('click', this.handleiconClick)
                }
                else if (href_Valid_Check_Var === 'set') {
                    icon.addEventListener('click', this.handleiconClick)
                }
            }
        })
        this.images.forEach((image: HTMLImageElement) => {
            //
            //console.log("anchors1innerText",anchor.innerText);
            if (image.id != '') {
                let href_Split_Array = [];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                //
                if ((href_Valid_Check_Var === 'resend')) {
                    image.addEventListener('click', this.handleImageClick)
                }

            }
        })
        this.spantexts.forEach((spantext: HTMLSpanElement) => {

            if (spantext.id != '') {
                let href_Split_Array = [];
                href_Split_Array = spantext.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if (href_Valid_Check_Var === 'text') {

                    spantext.addEventListener('click', this.handletextClick)
                }

            }
        })
    }

    public handleAnchorClick = (event: Event) => {
        // 
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;

        this.fn_alert(anchor, event);
    }

    public handleiconClick = (event: Event) => {
        //
        // Prevent opening anchors the default way
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;

        this.fn_alerticon(editicon, event);
    }
    public handleImageClick = (event: Event) => {
        //
        // Prevent opening anchors the default way
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;

        this.fn_alertImage(editicon, event);
    }
    public handletextClick = (event: Event) => {

        // Prevent opening anchors the default way
        event.preventDefault();
        const spantext = event.target as HTMLSpanElement;

        this.fn_alert_text(spantext, event);
    }


    fn_alert(anchor, event) {
        if (anchor.innerText === 'Edit') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
      
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            this.mergedReportsFinal[href_Valid_Check_Var]
       
        } else if (anchor.innerText === 'Reset') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
 
            this.resetpassword(this.usersList[href_Valid_Check_Var])
        } else if (anchor.innerText === ' Edit') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            this.usersList[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.getSelectedDataOfEdit(this.usersList[href_Valid_Check_Var])
        } else if (anchor.innerText === 'Delete') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            this.usersList[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.getSelectedDataOfDelete(this.usersList[href_Valid_Check_Var])
        } else if (anchor.innerText === 'Switch') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            //   this.mergedReportsFinal[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.switchDashboard(this.usersList[href_Valid_Check_Var])
        } else if (anchor.innerText === 'Set user roles') {
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[1];
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            this.usersList[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.setUserRole(this.usersList[href_Valid_Check_Var])
        }


    }


    fn_alerticon(icon, event) {
        //  
        let href_Split_Array = [];
        href_Split_Array = icon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'edit') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)

            this.mergedReportsFinal[href_Valid_Check_Var]
            // this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        } else if (href_Split_Array[0] === 'reset') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)

            //  this.mergedReportsFinal[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            // this.resetpassword(totalRow)
            this.resetpassword(this.usersList[href_Valid_Check_Var])
        } else if (href_Split_Array[0] === 'viewedit') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)

            this.usersList[href_Valid_Check_Var]
            this.getSelectedDataOfEdit(this.usersList[href_Valid_Check_Var])
        } else if (href_Split_Array[0] === 'delete') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)

            this.usersList[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.getSelectedDataOfDelete(this.usersList[href_Valid_Check_Var])
        } else if (href_Split_Array[0] === 'switch') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            // this.Onclickedituld(totalRow);
            this.switchDashboard(this.usersList[href_Valid_Check_Var])
        } else if (href_Split_Array[0] === 'set') {
            console.log(this.myGrid.getselectedcell());
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            // this.Onclickedituld(totalRow);
            this.setUserRole(this.usersList[href_Valid_Check_Var])
        }


    }
    fn_alertImage(image, event) {

        let href_Split_Array = [];
        href_Split_Array = image.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'resend') {
            console.log(this.myGrid.getselectedcell());

            // let data=this.myGrid.getrowdata(href_Valid_Check_Var);
            // console.log(data);

            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            // this.Onclickedituld(totalRow);
            this.mergedReportsFinal[href_Valid_Check_Var]
            //console.log(this.mergedReportsFinal,"popupdata")
            this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var], 'S');
            // this.resend()
        }


    }
    fn_alert_text(spantext, event) {

        console.log(this.myGrid.getselectedcell());
        let href_Split_Array = [];
        href_Split_Array = spantext.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'text') {
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
            this.usersList[href_Valid_Check_Var]
            //   this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
            this.authentication(this.usersList[href_Valid_Check_Var])
        }


    }


    ngOnInit() {

        this.view_users_nav_Class = 'nav-link active'
        this.Archived_Reports_nav_Class = 'nav-link'
        this.Active_Reports_nav_Class = 'nav-link';
        this.showAll = true;
        this.showArcived = false;
        this.createUserButton = true;
        this.disabledKeys = [];
        window.scroll(0, 0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.spinner.show();
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        console.log('-------- comapanydetails local storage-----', this.comapanydetails)
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        ViewUsersCargoClaimComponent.view_object = this.Object;
        this.webapi = this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl

        if (this.comapanydetails === null) {
            this.CompanyLogoimgurl = '';
            this.CompanyName = this.Object.organisationName
        } else {
            this.CompanyLogoimgurl = this.comapanydetails.orgLogo
            this.CompanyName = this.comapanydetails.orgName
        }

        this.route.queryParams.subscribe(
            params => {
                this.subscribedParam = params.orgKey;
                this.orgKey = this.subscribedParam
            });
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("type");
                this.typeOfCard = this.subscribedParam;
            });

        this.route.queryParams
            .subscribe(params => {
                this.isFrom = params.from;
                this.is_From_Edit_Damage_Report = params.is_From_Edit_DamageReport;
                console.log(this.isFrom, 'this.isFrom');
                if (this.is_From_Edit_Damage_Report === "true") {
                    this.router.navigate(
                        ['.'],
                        { relativeTo: this.route, queryParams: {} }
                    );
                }

            });

        if (this.isFrom !== null || this.isFrom !== undefined) {
            if (this.isFrom === "SidebarAllUsers") {
                this.CompanyLogoimgurl = this.loginDetails.orgLogo
                this.CompanyName = this.loginDetails.organisationName
            }
        }

        if (this.typeOfCard === 'UnAuthrisedUsers') {
            this.showUsersAndReports = false;
        } else {
            this.showUsersAndReports = true;
        }
        ;
        if (this.ViewReportsData.isfromSideBarViewReports === 'yes') {
            this.showUser = false;
            this.showReports = true;
            this.showIncoming_Reports = false
            // this.fn_OnTab_Changes("Active", "A");
        } else if (this.ViewReportsData.isFrmViewDetails === "yes") {
            this.createUserButton = false;
            this.showUser = false;
            this.showReports = true;
            this.submitButtonShow = true;
            this.showIncoming_Reports = false
            this.ViewReportsData.isFrmViewDetails = "no"
            if ((this.comapanydetails.orgType === 'H') && (this.comapanydetails.userType !== 'S') && (this.comapanydetails.userType !== 'SA')) {
                let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
                if (selectedreports === 'Active') {
                    // this.fn_OnTab_Changes("Active", "A");
                } else if (selectedreports === "Archived") {
                    // this.fn_OnTab_Changes("Archived", "R");
                }
            } else {
                let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
                if (selectedreports === 'Active') {
                    // this.fn_OnTab_Changes("Active", "A");
                } else if (selectedreports === "Archived") {
                    // this.fn_OnTab_Changes("Archived", "R");
                } else {
                    // this.getIncomingReports()
                }

                // this.Service("A");
            }
        } else if (this.is_From_Edit_Damage_Report != undefined) {
            if (this.is_From_Edit_Damage_Report === "true") {
                this.createUserButton = false;
                this.showUser = false;
                this.showReports = true;
                this.showIncoming_Reports = false
                this.submitButtonShow = true;
                let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
                if (selectedreports === 'Active' || selectedreports === null || selectedreports === undefined) {
                    // this.fn_OnTab_Changes("Active", "A");
                } else if (selectedreports === "Archived") {
                    // this.fn_OnTab_Changes("Archived", "R");
                } else if (selectedreports === "short") {
                    // this.showshortReports('short')
                }
                // this.is_From_Edit_Damage_Report = "false"
            }
        } else {
            this.showUser = true;
            this.ViewReportsData.isFrmViewDetails = "no"
            if (this.comapanydetails !== null) {
                if (this.comapanydetails.isfrom === 'airlines') {
                    this.getUsers();
                } else {
                    this.getUsers();
                }
            } else {
                this.getUsers();
            }
        }



    }


    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }

 
    static currentReportTab
    

  
  
    getSelectedDataOfEdit(val) {

        let edistuserData = {
            userId: val.userId,
            userStatus: val.userStatus,
            userType: val.userType
        }
        localStorage.setItem("editUserData", JSON.stringify(edistuserData));
        console.log(val, "editdata");
        let userObj = val;
        let userId = val.userId;
        this.router.navigate(["/cargoClaim/edit-user"], { queryParams: { "userId": userId } });
    }

    //done
    getSelectedDataOfDelete(val) {

        console.log(val, "deleteData");
        localStorage.setItem("userdeleteData", JSON.stringify(val));
        jQuery('#Modal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataOfReport(val, status) {

        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val.data));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
    }
    //done
    getSelectedDataOfresendReport(val, status) {

        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataCancel(val, status) {

        console.log(val, "deleteData");
        localStorage.setItem("Cancelstatus", JSON.stringify(status));
        localStorage.setItem("reportData", JSON.stringify(val.data));
        jQuery('#cancelsingleModal').modal({ backdrop: 'static', keyboard: false });
    }
   
    DeleteUser() {

        let data = JSON.parse(localStorage.getItem('userdeleteData'));
        console.log(this.subscribedParam);
        console.log(data.userId)
        let dataObject = {
            userId: data.userId,
        }
        this.spinner.show();
        this.superAdminservice.deleteUser(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', { timeOut: 1200 });
                jQuery('#Modal').modal('hide');
             this.ngOnInit();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#Modal').modal('hide');
            }
            else {
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#Modal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#Modal').modal('hide');
            })
    }

    authentication(data) {

        localStorage.setItem("userauthenticate", JSON.stringify(data));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
        if (data.userStatus === 'I') {
            this.popUpUsertype = 'inactive'
        } else if (data.userStatus === 'A') {
            this.popUpUsertype = ''
        }
    }

    authenticate() {

        let Object = JSON.parse(localStorage.getItem('loginresultobject'));
        let data = JSON.parse(localStorage.getItem('userauthenticate'));
        console.log(this.subscribedParam);
        console.log(data.userId)
        let dataObject = {
            userId: data.userId,
            // orgId: this.subscribedParam
            roleType: Object.userType,
            orgId: this.comapanydetails.orgId
        }
        this.spinner.show();
        this.superAdminservice.authenticate(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', { timeOut: 1200 });
                jQuery('#myModal').modal('hide');
                this.ngOnInit();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
            }
            else {
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#myModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', { timeOut: 1200 });
            })
    }
    getUsers() {

        this.view_users_nav_Class = 'nav-link active'
        this.Archived_Reports_nav_Class = 'nav-link'
        this.Active_Reports_nav_Class = 'nav-link';
        this.createUserButton = true;
        this.submitButtonShow = false;
        this.showUser = true;
        this.showReports = false;
        this.showIncoming_Reports = false
        this.isRefreshEnable = false
        this.ViewReportsData.isFrmViewDetails = "no"
        localStorage.removeItem('selectedreports');
        this.spinner.show();
        if (this.comapanydetails === null) {

            this.comapanydetails = JSON.parse(localStorage.getItem('loginresultobject'));
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
            if (this.isFrom === "SidebarAllUsers") {
                this.comapanydetails = JSON.parse(localStorage.getItem('loginresultobject'));
            }
        }
        this.superAdminservice.viewUsers(this.comapanydetails.orgId).subscribe(
            data => {

                if (data.responseCode === 1) {
                    this.spinner.hide();
                    this.usersList = [];
                    this.AuthrisedUsers = [];
                    this.UNAuthrisedUsers = [];
                    this.usersList = data.userDetails;
                    this.finalReportsCount = data.activeReportsCount;
                    this.archiveReportsCount = data.archiveReportsCount;
                    this.incomingReportsCount = data.incomingReportsCount;
                    this.obj = data
                    ViewUsersCargoClaimComponent.obj_data = data
                    // this.usersList1 = data.userDetails;

                    for (let i = 0; i < this.usersList.length; i++) {
                        if (this.usersList[i].adminAuth === 'I' && this.usersList[i].superAdminAuth === 'I') {
                            this.usersList[i].finalAuth = 'I';
                        } else {
                            this.usersList[i].finalAuth = 'A';
                        }
                    }
                    ViewUsersCargoClaimComponent.viewtotaldata = this.usersList

                    this.source.localdata = this.usersList;

                    this.myGrid.source(this.dataAdapter);

                    document.getElementById('users').className = 'nav-link active';
                    if( document.getElementById('reports') !== null){
                        document.getElementById('reports').className = 'nav-link';
                    }
                    if(document.getElementById('archievedreports') !== null){
                        document.getElementById('archievedreports').className = 'nav-link';
                    }
                   
                   
                    // document.getElementById('short_Reports').className = 'nav-link';
                    if (this.comapanydetails.orgType === 'A') {
                        if(document.getElementById('IncomingReports') !== null){
                            document.getElementById('IncomingReports').className = 'nav-link';
                        }
                      
                    }

                    if (this.comapanydetails.orgType === 'H') {
                        // document.getElementById('short_Reports').className = 'nav-link';
                        if(document.getElementById('archievedreports') !== null){
                            document.getElementById('archievedreports').className = 'nav-link';
                        }
                     

                    }
                    if (this.subscribedParam == 'All') {
                        this.usersList = data.userDetails;
                    } else if (this.subscribedParam == 'AuthrisedUsers') {
                        console.log(this.usersList[0].adminAuth);
                        for (let index = 0; index < this.usersList.length; index++) {
                            console.log(this.usersList[index].adminAuth);
                            if (this.usersList[index].adminAuth === 'A' || this.usersList[index].superAdminAuth === 'A') {
                                this.AuthrisedUsers.push(this.usersList[index]);
                            }
                        }
                        this.usersList = this.AuthrisedUsers;
                        ViewUsersCargoClaimComponent.viewtotaldata = this.usersList;
                        this.source.localdata = this.usersList;

                        this.myGrid.source(this.dataAdapter);
                    } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                        for (let index = 0; index < this.usersList.length; index++) {
                            if (this.usersList[index].adminAuth === 'I' && this.usersList[index].superAdminAuth === 'I') {
                                this.UNAuthrisedUsers.push(this.usersList[index]);

                            }
                        }
                        this.usersList = this.UNAuthrisedUsers;
                        ViewUsersCargoClaimComponent.viewtotaldata = this.usersList
                        this.source.localdata = this.usersList;

                        this.myGrid.source(this.dataAdapter);
                    }

                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                } else {
                    if(document.getElementById('reports') !== null){
                        document.getElementById('reports').className = 'nav-link';
                    }

                    if(document.getElementById('archievedreports') !== null){
                        document.getElementById('archievedreports').className = 'nav-link';
                    }
                  
                    // document.getElementById('short_Reports').className = 'nav-link';
                   
                    document.getElementById('users').className = 'nav-link active';
                    if (this.comapanydetails.orgType === 'A') {
                        if(document.getElementById('IncomingReports') !== null){
                            document.getElementById('IncomingReports').className = 'nav-link';
                        }
                        
                    }

                    this.spinner.hide();
                    this.toastr.error(data.message);
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }
    public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY
    source =
        {

            localdata: this.usersList,
            datatype: 'json',
            datafields:
                [
                    { name: 'activities', type: 'string' },
                    { name: 'createdonUser', type: 'date' },
                    { name: 'firstName', type: 'string' },
                    { name: 'lastName', type: 'string' },
                    { name: 'loginID', type: 'string' },

                    { name: 'stationCode', type: 'string' },
                    { name: 'finalAuth', type: 'string' },
                    // { name: 'address',type: 'string'},
                    { name: 'userType', type: 'string' },
                    { name: 'userStatus', type: 'string' },
                    // { name: 'contactNumber',type: 'string'},
                    { name: 'jobTitle', type: 'string' },

                ],
            pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
            beforeprocessing: function (data) {
                // this.source.totalrecords = data[0].TotalRows;
            },
            pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
                // callback called when a page or page size is changed.
                console.log(pagenum);
            },



            // rendergridrows: function (params) {
            //     return params.data;
            // }
        };

    dataAdapter = new jqx.dataAdapter(this.source);

    columns =
        [
            {
                text: 'Activities', datafield: 'activities', cellsalign: 'center', width: 140,
                cellsrenderer: function (row, index, data) {
                    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
                    if (this.Object.userType === 'SA') {
                        return "<div class='jq-actions' style='margin-top:  8.5px; margin-left: 5px;'><a  href='reset:" + row + "'><i id = 'reset:" + row + "' class='fa fa-lock' aria-hidden='true'  data-toggle='tooltip' title='Reset'></i></a><a  href='viewedit:" + row + "'><i id = 'viewedit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'  data-toggle='tooltip' title='Edit'></i></a><a  href='delete:" + row + "'><i id = 'delete:" + row + "' class='fa fa-trash-o text-danger' aria-hidden='true' data-toggle='tooltip' title='Delete'></i></a><a  href='switch:" + row + "'><i id = 'switch:" + row + "' class='fa fa-exchange' aria-hidden='true' data-toggle='tooltip' title='Switch'></i></a><a  href='set:" + row + "'><i id = 'set:" + row + "' class='fa fa-users' aria-hidden='true' data-toggle='tooltip' title='Set user roles'></i></a></div>";
                    } else {
                        return "<div class='jq-actions' style='margin-top:  8.5px; margin-left: 5px;'><a  href='reset:" + row + "'><i id = 'reset:" + row + "' class='fa fa-lock' aria-hidden='true' data-toggle='tooltip' title='Reset'></i></a><a  href='viewedit:" + row + "'><i id = 'viewedit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'   data-toggle='tooltip' title='Edit'></i></a><a  href='delete:" + row + "'><i id = 'delete:" + row + "' class='fa fa-trash-o text-danger' aria-hidden='true'   data-toggle='tooltip' title='Delete'></i></a><a  href='set:" + row + "'><i id = 'set:" + row + "' class='fa fa-users' aria-hidden='true' data-toggle='tooltip' title='Set user roles'></i></a></div>";
                    }
                },
            },

            {
                text: 'First Name', datafield: 'firstName', width: 150,
                cellsrenderer: function (row, index, data) {

                    if ((ViewUsersCargoClaimComponent.viewtotaldata.length != 0) && (row < ViewUsersCargoClaimComponent.viewtotaldata.length)) {
                        if ((ViewUsersCargoClaimComponent.viewtotaldata[row].reportAcess === 'Y') && ((ViewUsersCargoClaimComponent.obj_data.orgType === 'H') || (ViewUsersCargoClaimComponent.obj_data.orgType === 'E'))) {
                            return '<span id = "text1:' + row + '" style="background: green; cursor: pointer; color: #fff;padding: 2px;position: relative; margin-top:  8.5px;border-radius: 5px;width: 92px; margin-left: 0px;text-align: center;"> Authenticated</span><br><span>' + data + '</span> '
                        }
                    }
                },
            },
            { text: 'Last Name', datafield: 'lastName', width: 150, },
            { text: 'Email Id', datafield: 'loginID', width: 200, },

            //{ text: 'Handler Company Name', datafield: 'stationCode',width:250 },
            { text: 'Station', datafield: 'stationCode', width: 90, },

            {
                text: 'Auth Status', datafield: 'finalAuth', width: 180,
                cellsrenderer: function (row, index, data) {
                    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
                    if ((ViewUsersCargoClaimComponent.viewtotaldata.length != 0) && (row < ViewUsersCargoClaimComponent.viewtotaldata.length)) {
                        if ((ViewUsersCargoClaimComponent.viewtotaldata[row].finalAuth === 'A') && (ViewUsersCargoClaimComponent.view_object.userType === 'SA')) {
                            return "<a ><img style='margin: 8px;' src='../../../assets/images/tick_icon.png' /></a>"
                        }
                        else if ((ViewUsersCargoClaimComponent.viewtotaldata[row].finalAuth === 'I') && (ViewUsersCargoClaimComponent.view_object.userType === 'SA')) {
                            return '<span style="color:red ; margin-left: 5px;">Unauthorized</span><br><span style=" margin-left: 5px;">Click here to</span> <span id = "text:' + row + '" style="background: green; cursor: pointer; color: #fff;padding: 1px;position: relative; top:-5px;border-radius: 5px;width: 92px; margin-left: 10px;text-align: center;"> Authorize</span>'
                        }
                        else if ((ViewUsersCargoClaimComponent.viewtotaldata[row].finalAuth === 'I') && (ViewUsersCargoClaimComponent.view_object.userType === 'S')) {
                            return '<span style="color:red ; margin-left: 5px;">Unauthorized</span><br><span style=" margin-left: 5px;">Click here to</span> <span id = "text:' + row + '" style="background: green; cursor: pointer; color: #fff;padding: 1px;position: relative; top:-5px;border-radius: 5px;width: 92px; margin-left: 10px;text-align: center;"> Authorize</span>'
                        }

                        else if ((ViewUsersCargoClaimComponent.viewtotaldata[row].finalAuth === 'A') && (ViewUsersCargoClaimComponent.view_object.userType === 'S')) {
                            return "<a ><img style='margin: 8px;' src='../../../assets/images/tick_icon.png' /></a>"
                        }
                    }
                },
            },
            // { text: 'Address', datafield: 'address',width:150, },
            {
                text: 'User Type', datafield: 'userType', width: 150,
                cellclassname: function (row, column, value, data) {
                    if (data.userType === 'S') {
                        return data.userType = 'Company Super admin'
                    } else if (data.userType === 'SA') {
                        return data.userType = 'Super Admin'
                    } else if (data.userType === 'E') {
                        return data.userType = 'User'
                    } else if (data.userType === 'A') {
                        return data.userType = 'Admin'
                    }
                },
                cellsrenderer: function (row, index, data) {

                    if (data === 'SA') {
                        return 'Super Admin'
                    } else if (data === 'S') {
                        return 'Company super admin'
                    } else if (data === 'E') {
                        return 'User'
                    } else if (data === 'A') {
                        return 'Admin'
                    }
                },
            },
            {
                text: 'Status', datafield: 'userStatus', width: 80,
                cellclassname: function (row, column, value, data) {
                    if (data.userStatus === 'A') {
                        return data.userStatus = 'Active'
                    } else if (data.userStatus === 'I') {
                        return data.userStatus = 'Inactive'
                    }
                },
                cellsrenderer: function (row, index, data) {
                    if (data === 'A') {
                        return "<p style='color:  black; margin-left: 5px';>Active</p>"
                    } else if (data === 'I') {
                        return "<p style='color:  black; margin-left: 5px';>Inactive</p>"
                    }

                }
            },
            { text: 'Created On', datafield: 'createdonUser', width: 100, cellsformat: 'd-MM-yyyy', filtertype: 'date' },

            // { text: 'Phone Number', datafield: 'contactNumber',width:150 },
            { text: 'Job Title', datafield: 'jobTitle', width: 160 },

        ];



    calculateCellValue(data) {
        return {
            stationcode: data.stationCode,
            status: data.stationCodeStatus
        };
    }
    calculateCellValueorigin(data) {
        return {
            origin: data.originAirportName,
            status: data.originStationStatus
        };
    }
    calculateCellValuedest(data) {
        return {
            dest: data.destAirportName,
            status: data.destStationStatus
        };
    }

    onCellPrepared(e) {
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        if (this.comapanydetails.reportAcess === 'Y') {
            if (e.rowType == 'data') {
                if (e.column.dataField == 'stationCode') {
                    if (e.data.stationCodeStatus === 'Y') {
                        e.cellElement.style.color = 'green';
                        e.cellElement.style.fontWeight = 'bold';
                    } else if (e.data.stationCodeStatus === 'N') {
                        e.cellElement.style.color = 'red';
                        e.cellElement.style.fontWeight = 'bold';
                    }

                }

                if (e.column.dataField == 'originAirportName') {
                    if (e.data.originStationStatus === 'Y') {
                        e.cellElement.style.color = 'green';
                        e.cellElement.style.fontWeight = 'bold';
                    } else if (e.data.originStationStatus === 'N') {
                        e.cellElement.style.color = 'red';
                        e.cellElement.style.fontWeight = 'bold';
                    }

                }

                if (e.column.dataField == 'destAirportName') {
                    if (e.data.destStationStatus === 'Y') {
                        e.cellElement.style.color = 'green';
                        e.cellElement.style.fontWeight = 'bold';
                    } else if (e.data.destStationStatus === 'N') {
                        e.cellElement.style.color = 'red';
                        e.cellElement.style.fontWeight = 'bold';
                    }

                }
            }
        }
    }

 

    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
   


    handleActivation() {

        jQuery('#Activation').modal({ backdrop: 'static', keyboard: false, ignoreBackdropClick: true });
        // this.dummyObj = obj;
        if (this.obj.status === 'I') {

            this.activateObj = {
                "organizationId": this.obj.orgKey,
                "status": "A"
            }
            // this.obj.status = 'A'
        }
        else {

            this.activateObj = {
                "organizationId": this.obj.orgKey,
                "status": "I"
            }
            // this.obj.status = 'I'
        }
    }

    authoriseYes() {

        this.spinner.show();
        this.superAdminservice.viewActivation(this.activateObj).subscribe(
            data => {
                this.spinner.hide();
                if (data.responseCode == '1') {
                    this.toastr.success('Successfully updated', '', { timeOut: 1300 });
                    if (this.ViewReportsData.isFrmViewDetails === 'no') {
                        this.getUsers();
                    }
                    if (this.obj.status === 'I') {
                        this.obj.status = 'A'
                    } else if (this.obj.status === 'A') {
                        this.obj.status = 'I'
                    } else {

                    }
                    // this.ngOnInit();
                    // this.cd.detectChanges();
                    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                    // this.router.navigate(['/viewusers/351']));
                    jQuery('#Activation').modal('hide');
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#Activation').modal('hide');
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1300 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }

    authoriseNo() {

        // if(this.dummyObj.status == 'I'){
        // this.dummyObj.status = 'I'
        // }else if(this.dummyObj.status == 'A'){
        // this.dummyObj.status = 'A'
        // }
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['cargoClaim/view-users/:orgKey/:type']));
        // this.cd.detectChanges();
        jQuery('#Activation').modal('hide');
    }
    deleteNo() {

        //this.location.back()
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //     this.router.navigate(['viewusers']));
        // this.cd.detectChanges();
        jQuery('#Activation').modal('hide');
    }
    notSent() {

        jQuery('#Activation').modal('hide');

    }
    notCancel() {

        jQuery('#Activation').modal('hide');

    }

    // onCellPrepared(e) {
    //     
    //     console.log('checkbox customize');
    //     if (e.rowType === "data" && e.column.dataField === 'reportStatus') {
    //         if(e.data.emailStatus === "Internal email sent"){
    //             e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance").option("disabled", true);
    //             e.cellElement.off();
    //         }

    //     }
    // }

    isdashboardSwitched() {

        let dashBoradSwitched = {
            'isSwitched': 'yes'
        }
        localStorage.setItem('dashBoradSwitchedtemp', JSON.stringify(dashBoradSwitched));
        // console.log(dashBoradSwitchedtemp)
    }


    switchDashboard(Obj) {
        this.spinner.show();
        console.log('user switchdashboardData', Obj);
        let dashBoardObj = {
            emailId: Obj.loginID,
            OrgId: this.comapanydetails.orgId,
            OrgType: this.comapanydetails.orgType,
            airlineStatus: Obj.airlineStatus
        }
        localStorage.setItem('SwitchdashBoardObj', JSON.stringify(dashBoardObj));
        console.log('final switchdashboardData', dashBoardObj);
        this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {
            console.log(data)
            if (data.responseCode === 1) {
                localStorage.removeItem("selectedreports");
                localStorage.removeItem("reportsTab");
                this.spinner.hide();
                localStorage.setItem('loginresultobject', JSON.stringify(data));
                this.cookieService.delete('cclp', '/', '.cclp.aero');
                var expire = new Date();
                var time = Date.now() + ((3600 * 1000) * 8);
                expire.setTime(time);
                this.cookieService.set('cclp', JSON.stringify(data.token), expire, '/', '.cclp.aero', true, "None");
                this.cookieValue = this.cookieService.get('cclp');
                console.log("set cookie value of cclp", this.cookieValue);
                if (data.userType == 'SA') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    // let switched = true;
                    // localStorage.setItem('isSwitched', JSON.stringify(switched));
                    this.router.navigate(['sa-dashboard'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/sa-dashboard');
                } else if (data.userType == 'S' && data.orgType == 'H') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    // this.router.navigateByUrl('/HandlerDashboardComponent');
                    // let switched = true;
                    // localStorage.setItem('isSwitched', JSON.stringify(switched));
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/csa-dashboard');
                }
                else if (data.userType == 'S' && data.orgType == 'A') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    // let switched = true;
                    // localStorage.setItem('isSwitched', JSON.stringify(switched));
                    this.router.navigate(['HandlerDashboardComponent'], {
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/csa-airline-dashboard');
                }
                else if (data.userType == 'E') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    // let switched = true;
                    // localStorage.setItem('isSwitched', JSON.stringify(switched));
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/user-dashboard');
                } else if (data.userType == 'A' && data.orgType == 'A') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    this.router.navigate(['HandlerDashboardComponent'], {
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });

                } else if (data.userType == 'A' && data.orgType == 'H') {
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    this.router.navigate(['HandlerDashboardComponent'], {
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', { timeOut: 1200 });
            }
        },
            error => {
                this.toastr.error(error.message, '', { timeOut: 1200 })
            })
    }


    ngOnDestroy() {

        // this.ViewReportsData.isfromViewReports = 'no';
        this.ViewReportsData.isFrmViewDetails = 'no';
        //   localStorage.removeItem('selectedreports');
    }
    disablechbx(e) {
        // 
        console.log('checkbox customize');
        // if (e.rowType === "data" && e.column.dataField === 'reportStatus') {
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";
        if (e.data.emailStatus === a || e.data.emailStatus === b || e.data.emailStatus === c || e.data.emailStatus === d) {
            const instance = CheckBox.getInstance(e.cellElement.querySelector('.dx-select-checkbox'));
            instance.option('disabled', true);
            // instance.option("visible", false);
            events.off(e.cellElement, "onRowClick");
        }

        // }
    }
    onContentReady(e: any) {
        console.log("ready cntnt");
        let elements = e.element.querySelectorAll(".dx-command-select .dx-state-disabled");
        elements.forEach((el) => {
            events.off(el.parentElement, "dxclick");
        });
    }



    onSelectionChanged(e) {

        console.log("selection changed");
        this.dataGrid.instance.getSelectedRowsData().length;

        this.disabledKeys = [];
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";

        const checkarray = e.currentSelectedRowKeys;
        // const checkarray = this.mergedReports;

        for (let i = 0; i < checkarray.length; i++) {
            if (checkarray[i].emailStatus === a || checkarray[i].emailStatus === b || checkarray[i].emailStatus === c || checkarray[i].emailStatus === d) {
                this.disabledKeys.push(checkarray[i]);
            }

        }

        // var jjkk = e.currentSelectedRowKeys.filter(i => (!i.editingAllowed));

        if (this.disabledKeys.length > 0) {

            if (this.justDeselected) {
                this.justDeselected = false;
                e.component.deselectAll();
            }
            else {
                this.justDeselected = true;
                e.component.deselectRows(this.disabledKeys);
            }


        }
    }
    // OpenEmailPopup(status) {

    //     if (this.selectedRowsData.length === 0) {
    //         this.toastr.error('Select reports to send', '', { timeOut: 1200 });
    //     } else {
    //         if (this.checkUnauthReportsExist()) {
    //             jQuery('#unauthReportsExistPopup').modal({ backdrop: 'static', keyboard: false });
    //         } else {
    //             this.currentTabName = JSON.parse(localStorage.getItem('selectedreports'))
    //             localStorage.setItem("Emailstatus", JSON.stringify(status));
    //             jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
    //         }
    //     }
    // }
    // cancelUnauthReportsExistPopup() {
    //     jQuery('#unauthReportsExistPopup').modal("hide");
    // }
    // checkUnauthReportsExist(): boolean {
    //     ;
    //     let selectedRowsDAtaArray = []
    //     for (let i = 0; i < this.selectedRowsData.length; i++) {
    //         selectedRowsDAtaArray.push(this.selectedRowsData[i]);
    //     }

    //     if (selectedRowsDAtaArray.length !== 0) {
    //         let isExist = selectedRowsDAtaArray.some((element) => {
    //             return (element.adminAuth === "I" && element.superAdminAuth === "I");
    //         });
    //         return isExist;
    //     }

    // }
    // sentMultipleEmailReport() {
    //     // if ( this.selectedRowsData.length === 0) {
    //     //     this.toastr.error('No reports to sent', '', { timeOut: 1200 });
    //     // }
    //     this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
    //     this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    //     if (this.selectedRowsData.length != 0) {
    //         this.multipleReportList = [];
    //         for (let index = 0; index < this.selectedRowsData.length; index++) {


    //             if (
    //                 this.selectedRowsData[index].emailStatus === 'Not sent to airline' ||
    //                 this.selectedRowsData[index].emailStatus === 'Sent to airline' ||
    //                 this.selectedRowsData[index].emailStatus === 'Emails are not found'
    //             ) {
    //                 this.userId = this.selectedRowsData[index].userId,
    //                     this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
    //                     this.stationCode = this.selectedRowsData[index].stationCode,
    //                     this.classificationType = this.selectedRowsData[index].classificationType,
    //                     this.firstName = this.selectedRowsData[index].firstName
    //                 this.currentReportId = this.selectedRowsData[index].reportId,
    //                     this.multipleReportList.push({
    //                         userId: this.userId,
    //                         masterAwbNo: this.masterAwbNo,
    //                         stationCode: this.stationCode,
    //                         classificationType: this.classificationType,
    //                         firstName: this.firstName,
    //                         reportId: this.currentReportId
    //                     });
    //             }
    //             // }
    //         }

    //         let dataObject = {
    //             reportStatus: this.Emailstatus,
    //             reportSendBy: this.loginDetails.userId,
    //             reportsList: this.multipleReportList,
    //         }
    //         if (this.multipleReportList.length === 0) {
    //             jQuery('#emailModal').modal('hide');
    //             // this.dataGrid.instance.deselectAll();
    //             this.myGrid.clearselection();
    //             this.toastr.error('No emails for this report', '', { timeOut: 1200 });
    //             return false;
    //         }
    //         this.spinner.show();
    //         this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
                
    //             if (data.responseCode === 1) {
    //                 this.myGrid.clearselection();
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.toastr.success(data.message, '', {});
    //                 jQuery('#emailModal').modal('hide');
    //                 // this.spinner.hide();
    //             } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
    //                 this.toastr.info("Your session is expired, please login again");
    //                 jQuery('#emailModal').modal('hide');
    //                 this.spinner.hide();
    //             }
    //             else {

    //                 let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.toastr.error(data.message, '', {});
    //                 jQuery('#emailModal').modal('hide');
    //                 this.spinner.hide();
    //             }
    //         },
    //             error => {
    //                 let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 // this.Service(selectedreports === "allreports" ? "A" : (selectedreports === 'short') ? this.showshortReports('short') : "R");
    //                 this.spinner.hide();
    //                 jQuery('#emailModal').modal('hide');
    //             })

    //     }
    //     else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }
    // }

    // OpenCancelPopup(status) {
    //     if (this.selectedRowsData.length === 0) {
    //         this.toastr.error('Select reports to hold');
    //     } else {
    //         let selectedRowsDAtaArray = []
    //         for (let i = 0; i < this.selectedRowsData.length; i++) {
    //             selectedRowsDAtaArray.push(this.selectedRowsData[i]);
    //         }

    //         if (selectedRowsDAtaArray.length !== 0) {
    //             let isExist = selectedRowsDAtaArray.some((element) => {
    //                 return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
    //             });

    //             if (isExist) {
    //                 jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
    //                 return false;
    //             } else {
    //                 localStorage.setItem("Emailstatus", JSON.stringify(status));
    //                 jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
    //             }

    //         }

    //     }
    // }
    // cancelunselectSentReportExistPopup() {
    //     jQuery('#unselectSentReportExistPopup').modal("hide");
    // }

    // cancelMultipleEmailReport() {
    //     // if (this.mergedReports.length === 0) {
    //     //     this.toastr.error('No reports to sent');
    //     // }
    //     this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
    //     this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    //     if (this.selectedRowsData.length >= 0) {
    //         this.multipleReportList = [];
    //         for (let index = 0; index < this.selectedRowsData.length; index++) {
    //             //                
    //             if (
    //                 this.selectedRowsData[index].emailStatus === 'Not sent to airline' ||
    //                 this.selectedRowsData[index].emailStatus === 'Emails are not found'
    //             ) {
    //                 this.userId = this.selectedRowsData[index].userId,
    //                     this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
    //                     this.stationCode = this.selectedRowsData[index].stationCode,
    //                     this.classificationType = this.selectedRowsData[index].classificationType,
    //                     this.firstName = this.selectedRowsData[index].firstName
    //                 this.currentReportId = this.selectedRowsData[index].reportId,
    //                     this.multipleReportList.push({
    //                         userId: this.userId,
    //                         masterAwbNo: this.masterAwbNo,
    //                         stationCode: this.stationCode,
    //                         classificationType: this.classificationType,
    //                         firstName: (this.firstName !== undefined) ? this.firstName : "",
    //                         reportId: this.currentReportId
    //                     });
    //             }
    //             // }
    //         }

    //         let dataObject = {
    //             reportStatus: this.Emailstatus,
    //             reportSendBy: this.loginDetails.userId,
    //             reportsList: this.multipleReportList,
    //         }
    //         if (this.multipleReportList.length === 0) {
    //             jQuery('#cancelModal').modal('hide');
    //             this.myGrid.clearselection();
    //             this.toastr.error('No reports to cancel', '', { timeOut: 1200 });
    //             return false;
    //         }
    //         this.spinner.show();
    //         this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
                
    //             if (data.responseCode === 1) {
    //                 this.myGrid.clearselection();
                   
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.toastr.success(data.message, '', {});
    //                 jQuery('#cancelModal').modal('hide');
    //                 // this.spinner.hide();
    //             } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
    //                 this.toastr.info("Your session is expired, please login again");
    //                 jQuery('#cancelModal').modal('hide');
    //                 this.spinner.hide();
    //             }
    //             else {
                    
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.toastr.error(data.message, '', {});
    //                 jQuery('#cancelModal').modal('hide');
    //                 this.spinner.hide();
    //             }
    //         },
    //             error => {
    //                 let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.spinner.hide();
    //                 jQuery('#cancelModal').modal('hide');
    //             })
    //     }
    //     else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }
    // }

    saveGridInstance(e) {

        console.log("referesh");
        console.log(this.dataGrid.instance.getSelectedRowsData());
        this.dataGridInstance = e.component;
        this.dataGridInstance.refresh();
        this.dataGridInstance.clearSelection();
        this.dataGridInstance.deselectRows();
        this.dataGridInstance.deselectAll();
        // this.dataGrid.instance.refresh();
    }
    //done
    resetpassword(val) {
        localStorage.setItem('resetpassobj', JSON.stringify(val));
        this.router.navigateByUrl('/cargoClaim/reset-password');
    }

 

    sendActivatonLink() {

        let ActivationData = JSON.parse(localStorage.getItem('ActivationData'));
        console.log('ActivationData', ActivationData);
        jQuery('#myActivateModal').modal('hide');
        jQuery('#myResendLinkModal').modal('hide');
        this.spinner.show();
        this.superAdminservice.ActivateResentLink(ActivationData).subscribe(data => {

            this.spinner.hide();
            if (data.responseCode === 1) {

                this.toastr.success(data.message, '', { timeOut: 1200 })
                this.ngOnInit();
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
            }
            else {

                this.toastr.error(data.message, '', { timeOut: 1200 })
            }
        }, error => {
            console.log(error)
            this.spinner.hide();
        })

    }

    // Activate(){
    //     alert('Activate')
    // }

    Activate(data, event) {

        var ActivationData = {
            userId: data.data.userId,
            type: event,
            roleType: this.Object.userType
        }
        localStorage.setItem("ActivationData", JSON.stringify(ActivationData));
        if (event === 'A') {
            jQuery('#myActivateModal').modal({ backdrop: 'static', keyboard: false });
        } else if (event === "R") {
            jQuery('#myResendLinkModal').modal({ backdrop: 'static', keyboard: false });
        }
    }


    deActivateStations() {

        // jQuery('#deActivateStations').modal({ backdrop: 'static', keyboard: false });
        this.router.navigateByUrl('/viewdeactivatestationemail')
    }



    setUserRole(val) {

        val.isfrom = 'viewusers';
        this.comnyorgid = JSON.parse(localStorage.getItem('comapanydetails'));
        if (this.comnyorgid === null) {

            this.comnyorgid = JSON.parse(localStorage.getItem('loginresultobject'));
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
            if (this.isFrom === "SidebarAllUsers") {
                this.comnyorgid = JSON.parse(localStorage.getItem('loginresultobject'));
            }
        }
        val.orgId = this.comnyorgid.orgId;
        localStorage.setItem('userObj', JSON.stringify(val));
        this.router.navigateByUrl('/cargoClaim/set-user-roles');
    }

    // onEditClick(rowData) {

    //     localStorage.setItem('awbNumber', JSON.stringify(rowData.masterAwbNo));
    //     localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
    //     rowData.isFrom = 'EDIT_REPORT';
    //     rowData.from_view_Users = 'true';
    //     this.router.navigate(['save-report'], { queryParams: rowData, skipLocationChange: true })
    // }



    // confirm_delete() {
    //     if (this.selectedRowsData.length != 0) {
    //         jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
    //     } else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }

    // }

    // confirm_manualarchive() {
    //     if (this.selectedRowsData.length != 0) {
    //         jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    //     } else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }
    // }


    // DeleteReport() {
    //     ;
    //     if (this.selectedRowsData.length != 0) {
    //         this.selreportsarray = [];
    //         for (let i = 0; i < this.selectedRowsData.length; i++) {
    //             this.userId = this.selectedRowsData[i].userId,
    //                 this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
    //                 this.currentReportId = this.selectedRowsData[i].reportId,
    //                 this.selreportsarray.push({
    //                     userId: this.userId,
    //                     awbNumber: this.masterAwbNo,
    //                     reportId: this.currentReportId
    //                 });

    //         }

    //         this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    //         this.loginUserId = this.Object.userId;

    //         this.finalobj = {
    //             "loginUserId": this.loginUserId,
    //             "reportsList": this.selreportsarray
    //         }

    //         this.spinner.show();
    //         this.superAdminservice.DeleteReport(this.finalobj).subscribe(
    //             data => {
                   

    //                 if (data.responseCode === 1) {
    //                     this.myGrid.clearselection();
    //                     let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                     this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                     this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                     this.selectedRowsData = [];
    //                     this.toastr.success(data.message, '', {});
    //                     jQuery('#delrepModal').modal('hide');
    //                     // this.spinner.hide();
    //                 } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
    //                     this.toastr.info("Your session is expired, please login again");
    //                     jQuery('#delrepModal').modal('hide');
    //                     this.spinner.hide();
    //                 }
    //                 else {
    //                     let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                     this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                     this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                     this.selectedRowsData = [];
    //                     this.toastr.error(data.message, '', {});
    //                     jQuery('#delrepModal').modal('hide');
    //                     this.spinner.hide();
    //                 }
    //             },
    //             error => {
    //                 let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.spinner.hide();
    //                 jQuery('#delrepModal').modal('hide');
    //             }
    //         )
    //     }
    //     else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }

    // }

    // manualarchive() {
    //     ;
    //     if (this.selectedRowsData.length != 0) {
    //         this.selreportsarray = [];
    //         for (let i = 0; i < this.selectedRowsData.length; i++) {
    //             this.userId = this.selectedRowsData[i].userId,
    //                 this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
    //                 this.currentReportId = this.selectedRowsData[i].reportId,
    //                 this.selreportsarray.push({
    //                     userId: this.userId,
    //                     awbNumber: this.masterAwbNo,
    //                     reportId: this.currentReportId
    //                 });

    //         }

    //         this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    //         this.loginUserId = this.Object.userId;

    //         this.finalobj = {
    //             "loginUserId": this.loginUserId,
    //             "reportsList": this.selreportsarray
    //         }
    //         this.spinner.show();
    //         this.superAdminservice.SetReportArchive(this.finalobj).subscribe(
    //             data => {
                 

    //                 if (data.responseCode === 1) {
    //                     this.myGrid.clearselection();
    //                     let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                     this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                     this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                     this.selectedRowsData = [];
    //                     this.toastr.success(data.message, '', {});
    //                     jQuery('#manarchModal').modal('hide');
    //                     // this.spinner.hide();
    //                 } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
    //                     this.toastr.info("Your session is expired, please login again");
    //                     jQuery('#manarchModal').modal('hide');
    //                     this.spinner.hide();
    //                 }
    //                 else {
    //                     let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                     this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                     this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                     this.selectedRowsData = [];
    //                     this.toastr.error(data.message, '', {});
    //                     jQuery('#manarchModal').modal('hide');
    //                     this.spinner.hide();
    //                 }
    //             },
    //             error => {
    //                 let selectedreports = JSON.parse(localStorage.getItem('selectedreports'));
    //                 this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh");
    //                 this.reportsDataGrid_Ref.myGrid.gotopage(0)
    //                 this.selectedRowsData = [];
    //                 this.spinner.hide();
    //                 jQuery('#manarchModal').modal('hide');
    //             }
    //         )
    //     }
    //     else {
    //         this.spinner.hide();
    //         this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    //     }
    // }


    // public selectedRowsData = [];
    // _onSelectRow(event) { // trigger when checkbox checked / unchecked 
    //     ;
    //     if (Array.isArray(event.args.rowindex)) {
    //         if (event.args.rowindex.length !== 0) {
    //             this.selectedRowsData = this.reportsDataGrid_Ref.getDtotalData();
    //         } else {
    //             this.selectedRowsData = [];
    //         }
    //     } else {
    //         if (event.type === "rowunselect") {
    //             let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
    //             this.selectedRowsData.splice(index, 1);
    //         } else {
    //             this.selectedRowsData.push(event.args.row);
    //         }
    //     }
    //     console.log("this.selectedRowsData", this.selectedRowsData)
    // }








}

