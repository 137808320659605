import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class globalService {
  public loaderState$ = new Subject();
  public HomeState$ = new Subject();
  constructor(
  ) {
  }

  /**
   *	Method used to set the loader state of loader.
   */
  setDashBoardState(data) {
    this.loaderState$.next(data);
  }

  /**
   *	Method used to get the state of loader.
   */
  getDashBoardState() {
    return this.loaderState$.asObservable();
  }

  
  /**
   *	Method used to set the loader state of loader.
   */
  setHomeState(data) {
    this.HomeState$.next(data);
  }

  /**
   *	Method used to get the state of loader.
   */
  getHomeState() {
    return this.HomeState$.asObservable();
  }

}
