import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SuperadminService } from "../../services/superadmin/superadmin.service";
import { NgxSpinnerService } from "ngx-spinner";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { NavbarComponent } from 'src/app/layouts/navbar/navbar.component';
declare var jQuery: any;
import { Location } from '@angular/common';
import { globalConstants } from "src/environments/globalConstants";
import { LocationStrategy } from "@angular/common";
@Component({
  selector: "app-set-roles",
  templateUrl: "./set-roles.component.html",
  styleUrls: ["./set-roles.component.css"],
  providers: [NavbarComponent]
})
export class SetRolesComponent implements OnInit {
  allUserObj: any;
  userDetailForm: FormGroup;
  loginDetails: any;
  companyObj: any;
  editReports
  viewReports

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private SuperadminService: SuperadminService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private navbarComponent: NavbarComponent,
    private location: Location,
    private locationStr: LocationStrategy,

  ) { 
    // preventing back button in browser   
    // history.pushState(null, null, window.location.href);  
    // this.locationStr.onPopState(() => {
    //   history.pushState(null, null, window.location.href);
    // });
  }

  ngOnInit() {
    // this.companyObj = JSON.parse(localStorage.getItem("comapanydetails"));
    this.allUserObj = JSON.parse(localStorage.getItem("userObj"));
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    // let EMAILPATTERN =
    //   "(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}";
    // let EMAILPATTERN = "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}"
    this.userDetailForm = this.formBuilder.group({
      orgName: new FormControl("", [Validators.required]),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      loginID: new FormControl("", [
        Validators.required,
        Validators.pattern(globalConstants.EMAIL_PATTERN),
      ]),
      stationCode: new FormControl("", [Validators.required]),
      roleType: new FormControl("", [Validators.required]),
      orgId: new FormControl(this.allUserObj.orgId),
      userId: new FormControl(this.allUserObj.userId),
      loginUserId: new FormControl(this.loginDetails.userId),
    });
    this.userDetailForm.get("orgName").setValue(this.allUserObj.orgName);
    this.userDetailForm.get("firstName").setValue(this.allUserObj.firstName);
    this.userDetailForm.get("lastName").setValue(this.allUserObj.lastName);
    this.userDetailForm.get("loginID").setValue(this.allUserObj.loginID);
    this.userDetailForm.get("stationCode").setValue(this.allUserObj.stationCode);
    this.userDetailForm.get("roleType").setValue(this.allUserObj.userType);
    this.userDetailForm.get("orgId").setValue(this.allUserObj.orgId);
    this.userDetailForm.get("userId").setValue(this.allUserObj.userId);
    this.userDetailForm.get("loginUserId").setValue(this.loginDetails.userId);
    console.log(this.editReports);
    console.log(this.viewReports);
  }

  setOption(event) {
    this.userDetailForm.get("roleType").patchValue(event.value);
  }

  fn_userSubmit(userDetailForm) {
    this.spinner.show();
    this.SuperadminService.setUserRole(userDetailForm).subscribe((data) => {
      if (data.responseCode === 1) {
        if (data.logout == 'N') {
          this.toastr.success(data.message);
        
         // if (this.allUserObj.isfrom === 'viewusers') {
           // this.router.navigate(['/viewusers']);
         // } else if (this.allUserObj.isfrom === 'allusers') {
           // this.router.navigate(['/allusers']);

         // }
         this.location.back();
         
        } else if (data.logout == 'Y') {
          jQuery('#information_Modal').modal({ backdrop: 'static', keyboard: false });
          this.spinner.hide();
        }
      } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
        this.toastr.info("Your session is expired, please login again");
        this.spinner.hide();
    } 
      
      else {
        this.spinner.hide();
        this.toastr.error(data.message);
      }
    });
  }

  onlogut() {
    jQuery('#information_Modal').modal('hide');
    this.navbarComponent.logOut();
  }

  onclickcancel() {
    // if (this.allUserObj.isfrom === 'viewusers') {
    //   this.router.navigate(['/viewusers']);
    // } else if (this.allUserObj.isfrom === 'allusers') {
    //   this.router.navigate(['/allusers']);

    // }
    this.location.back()
  }

  ngOnDestroy(){
    this.spinner.hide();
  }
}
