import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, RouterEvent } from '@angular/router';
// import {MyAccountService } from '../../services/myAccount/my-account.service'
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { SidebarComponent } from '../../layouts/sidebar/sidebar.component';
import { Subscription } from 'rxjs';
import { LoginComponent } from '../../login/login.component';
import { SwitchdashboardService } from '../../services/switch-dashboard/switchdashboard.service'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/services/login/login.service';
import { ConfigServiceService } from 'src/app/config-service.service';
export let browserRefresh = false;
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    userName: any;
    truckingAPI: string;
    myAccount;
    loginDetails: any;
    email: string;
    password: string;
    userID: number;
    switchFrom: string;
    
    baseURL = this.config.getConfig().switchTruckingURL;

    public role: string;
    Logindetails: any;
    userId: string;
    saDashboard: any;
    DashBoardDetails: any;
    LoginDetails: any;
    comapanydetails: any;
    switchEnable: string;
    isSwiched: string;
    subscription: Subscription;
    dashBoradSwitchedtemp: any;
    AdminType: string;
    cookieValue: string;
    disableSave: boolean;
    disableCreateShipment: boolean;
    disableOther_Buttons: boolean;
    currentTab: any ;
    disablecreateuld_Buttons: boolean;
    tab: string;
    // browserRefresh : any;
    constructor(public router: Router,
                private ViewReportsData: ViewReportsData,
                private SidebarComponent: SidebarComponent,
                private route: ActivatedRoute,
                private LoginComponent: LoginComponent,
                private SwitchdashboardService: SwitchdashboardService,
                private toastr: ToastrService,
                public loginservice: LoginService,
                private spinner: NgxSpinnerService,
                private cookieService: CookieService,
                private config : ConfigServiceService
                ) {
            console.log('this.router.url', this.router.url);
            if (this.router.url.toString().includes('/save-report')) {
                    this.disableSave = true
            }
            if (this.router.url.toString().includes('/create-shipment')) {
                    this.disableCreateShipment = true
            }
            if (this.router.url.toString().includes('/createuld')) {
                    this.disablecreateuld_Buttons = true
            }
           
            this.currentTab = JSON.parse(localStorage.getItem('currentModule')) ;
            console.log(this.currentTab, this.router.url);
            this.tab  = this.router.url;
    }

    ngOnInit() {
        this.truckingAPI = this.config.getConfig().truckingURL
        this.loginDetails = JSON.parse(localStorage.getItem('loginDetails')) ;
        if (this.loginDetails != null || this.loginDetails != undefined) {
            this.email = this.loginDetails.emailId;
            this.password = '';
            this.userID = this.loginDetails.userId;
            this.switchFrom = 'cclp';
        }

        this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log('console.log(this.LoginDetails?.userType)', this.LoginDetails.userType);

        console.log(this.LoginDetails);
        if (this.LoginDetails.userType === 'SA') {
            this.role = 'Super Admin';
        } else if (this.LoginDetails.userType === 'S') {
            this.role = 'Company Super Admin';
        } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'Y' && this.LoginDetails.orgType === 'H') {
            this.role = 'Authenticated User';
        } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'I' && this.LoginDetails.userSuperAdminAuth === 'I') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === 'H') {
            this.role = 'UnAuthenticated User';
        } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === 'H') {
            this.role = 'UnAuthenticated User';
        } else if (this.LoginDetails.userType == 'E' && this.LoginDetails.orgType === 'A') {
            this.role = 'User';
        } else if ((this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'A' ) || (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'H')) {
            this.role = 'Admin';
        }


        if (this.LoginDetails.userType == 'E' && this.LoginDetails.orgType === 'E') {
    this.role = 'User';
  } else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType === 'E') {
    this.role = 'Admin';
  }
        // } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A')) {
        //     this.role = 'Authorized User';
        // } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'I' && this.LoginDetails.userSuperAdminAuth === 'I')) {
        //     this.role = 'UnAuthorized User';
        // }

        // else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'Y' && this.LoginDetails.orgType === "H") {
        //     this.role = 'Authenticated User';
        // } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'I' && this.LoginDetails.userSuperAdminAuth === 'I') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === "H") {
        //     this.role = 'UnAuthenticated User';
        // }else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === "H") {
        //     this.role = 'UnAuthenticated User';
        // }

        if (this.LoginDetails.userType === 'A' && this.LoginDetails.reportAcess === 'Y' && this.LoginDetails.orgType === 'H') {
            this.AdminType = 'Authenticated';

        } else if (this.LoginDetails.userType === 'A' && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === 'H') {
            this.AdminType = 'UnAuthenticated';

        }
        if (this.LoginDetails.userType === 'S' && this.LoginDetails.reportAcess === 'Y' && this.LoginDetails.orgType === 'H') {
            this.AdminType = 'Authenticated';

        } else if (this.LoginDetails.userType === 'S' && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === 'H') {
            this.AdminType = 'UnAuthenticated';

        }
        // if (this.LoginDetails.userType === 'S' && this.LoginDetails.orgAuthStatus === "A" && this.LoginDetails.orgType === "A") {
        //     this.AdminType = 'Authorized'

        // } else if (this.LoginDetails.userType === 'S' && this.LoginDetails.orgAuthStatus !== "A" && this.LoginDetails.orgType === "A") {
        //     this.AdminType = 'UnAuthorized'

        // }

        if (this.LoginDetails.userType === 'S' && (this.LoginDetails.userSuperAdminAuth === 'A' || this.LoginDetails.userAdminAuth === 'A') && this.LoginDetails.orgType === 'A') {
            this.AdminType = 'Authorized';

        } else if (this.LoginDetails.userType === 'S' && (this.LoginDetails.userSuperAdminAuth === 'I' && this.LoginDetails.userAdminAuth === 'I') && this.LoginDetails.orgType === 'A') {
            this.AdminType = 'UnAuthorized';

        }
        // this.switchEnable = this.ViewReportsData.isFromDashboard
        this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
        console.log('dashBoradSwitched---------------', this.dashBoradSwitchedtemp);
        if (this.dashBoradSwitchedtemp !== null) {
            if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
                this.switchEnable = 'yes';

                this.ViewReportsData.isFromDashboard = 'yes';
            }
        }
    }

    logOut() {

        const loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (loginDetails != null && loginDetails != undefined) {
            this.spinner.show();
            const obj = {
            loginUserId: loginDetails.userId
        };
            this.loginservice.logOutService(obj).subscribe(
            data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.cookieService.delete('cclp', '/', '.cclp.aero');
                    this.cookieService.delete('uld', '/', '.cclp.aero');
                    this.cookieService.delete('airPortClaims', '/', '.cclp.aero');
                    const remmeberData = JSON.parse(localStorage.getItem('credentialsForRememberMe'));
                    localStorage.clear();
                    localStorage.setItem('credentialsForRememberMe', JSON.stringify(remmeberData));
                    this.router.navigate(['/Home']);
                } else {
                    if (data.message === 'Unauthorized User') {

                    } else {
                        this.toastr.error(data.message);
                    }
                    const obj = {
                        emailId: '',
                         pwd: ''
                     };
                    localStorage.setItem('credentialsForRememberMe', JSON.stringify(obj));
                }
            },
            error => {
                this.spinner.hide();
            }
        );
        } else {
            this.router.navigate(['/Home']);
        }
        //  this.router.navigate(["/sa-dashboard"])
    }

    // switchToTrucking () {
    //     const user = JSON.parse(localStorage.getItem('loginDetails'));
    //     var test_parameter = {email: user.emailId, password: 'Preprod1@3'};
    //     window.open("https://preprod.trucking.aero").postMessage(JSON.stringify(test_parameter), "https://preprod.trucking.aero");
    // }

    switchToSaDashbord() {
        localStorage.removeItem('currentModule') ;
        localStorage.removeItem('uldReportFrom') ;
        localStorage.removeItem('uldReportId_To_View') ;
        localStorage.removeItem('sideReportsTab') ;




        const logindetailsForSwitching = JSON.parse(localStorage.getItem('logindetailsForSwitching'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));

        let loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));

    //    loginresultobject["token"] = logindetailsForSwitching["token"]
    //    localStorage.setItem('loginresultobject', JSON.stringify(loginresultobject));
    //     console.log('logindetailsForSwitching', logindetailsForSwitching);
    //     console.log('this.comapanydetails ', this.comapanydetails);
        // if(this.comapanydetails === undefined ||this.comapanydetails === null){
        //     let SwitchdashBoardObj = JSON.parse(localStorage.getItem('SwitchdashBoardObj'));
        //     this.comapanydetails.orgId = SwitchdashBoardObj.OrgId
        // }
        localStorage.setItem('tokenTO_SaveReportApi_WhenSwitching', JSON.stringify(loginresultobject));
        const dashBoardObj = {
            emailId: logindetailsForSwitching.emailId,
            OrgId: logindetailsForSwitching.orgId,
            OrgType: logindetailsForSwitching.orgType
        };
        console.log('switching to SA dashboard', dashBoardObj);
        this.spinner.show();
        this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {

            console.log(data);
            this.spinner.hide();
            if (data.responseCode === 1) {

                this.cookieService.delete('cclp', '/', '.cclp.aero');
                let expire = new Date();
                let time = Date.now() + ((3600 * 1000) * 8);
                expire.setTime(time);
                this.cookieService.set('cclp', JSON.stringify(data.token), expire, '/', '.cclp.aero', true,'None');
                this.cookieValue = this.cookieService.get('cclp');
                console.log('set cookie value of cclp', this.cookieValue);

                if (data.userType === 'SA') {
                    localStorage.setItem('loginresultobject', JSON.stringify(data));

                    localStorage.setItem("Token",JSON.stringify(data.token));
                    localStorage.setItem("loginDetails",JSON.stringify(data));
                    if (this.ViewReportsData.isFromDashboard === 'yes') {
                        this.ViewReportsData.isFromDashboard = 'no';

                        localStorage.removeItem('dashBoradSwitchedtemp');

                        const val = '1';
                        localStorage.setItem('activation', JSON.stringify(val));
                        this.router.navigate(['/sa-dashboard']);
                    } else {
                        this.ViewReportsData.isFromDashboard = 'no';

                        this.SidebarComponent.activecall('');
                        this.router.navigate(['viewusers', this.comapanydetails.orgId]);
                    }
                } else if (data.responseCode === 0) {
                    this.toastr.error(data.message);
                }
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info('Your session is expired, please login again');
            } else {
                this.toastr.error(data.message);
            }
        }, error => {

            this.spinner.hide();
            console.log(error);
            this.toastr.error(error.statusText);
        });
    }

    apiDocumentation(){
        this.router.navigate(['/open-api'], {queryParams:{fromWhichPage: 'myaccount', role: this.role}});
    }

	onClick_Save_Report() {
      const  obj = {
          isFrom : 'CREATE_REPORT'
      };
      this.router.navigate(['save-report'], { queryParams: obj });
    }

    onClick_Save_Uld() {
        this.ViewReportsData.uldISFrom = 'Create' ;
        this.router.navigateByUrl('/Uld/createuld');
      }
      onClick_CreateShipment() {
        this.ViewReportsData.shipmentIsFrom = 'Create';
        this.router.navigateByUrl('/CargoImage/create-shipment') ;
      }
      onClick_CreateShipment_handler() {
        this.ViewReportsData.shipmentIsFrom = 'Create';
        this.router.navigateByUrl('/CargoImageHandler/create-shipment') ;
      }
      onClick_Create_CargoAcceptance() {
        // this.ViewReportsData.shipmentIsFrom = 'Create';
        localStorage.setItem("CARGO_Acceptance_From",JSON.stringify("Create"));
        this.router.navigateByUrl('/CargoAcceptance/create-cargo-acceptance') ;
      }
      onClick_Truckinspection() {
        localStorage.setItem("TRUCK_INSPECTION_FROM",JSON.stringify("CREATE"));
        this.router.navigateByUrl('/Truckinspection/create-truck-inspection') ;
      }
      onClick_cargoDamageForwarder() {
        this.ViewReportsData.CARGODAMGE_FROM ="CREATE";
        localStorage.setItem("CARGODAMGE_FROM",JSON.stringify("CREATE"));
        this.router.navigateByUrl("/cargoDamageForwarder/create-cargoDamageForwarder");
      }
}
