import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { environment } from 'src/environments/environment';
import { globalConstants } from 'src/environments/globalConstants';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { LocationStrategy } from '@angular/common';
import { ShipmentsService } from 'src/app/services/shipments/shipments.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfigServiceService } from '../../config-service.service';

@Component({
  selector: 'app-view-shipments',
  templateUrl: './view-shipments.component.html',
  styleUrls: ['./view-shipments.component.css']
})
export class ViewShipmentsComponent implements OnInit {

  @ViewChild('myGrid') myGrid: jqxGridComponent;
 static  webapi :string;
 static totalGridData :any ;
  reportsData: any;
  anchor_Tags: any;
  editicon_Tags: any;
  isArchiveEnable: boolean = true;
  orgDetails: any;
  finalReportsCount = 0 ;
  loginDetails: any;
  constructor( 
    private route: Router,
    private ViewReportsData: ViewReportsData,
    private elementRef: ElementRef,
    private location: LocationStrategy,
    private shipmentServc :ShipmentsService,
    private spinner: NgxSpinnerService, 
    private toastr: ToastrService,
    private config : ConfigServiceService,
    private router: Router
   ) { 
      // preventing back button in browser   
      history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
      ViewShipmentsComponent.webapi = this.config.getConfig().URL

   }

  ngOnInit() {
    let selectedTab = JSON.parse(localStorage.getItem("selected_Shipments_Tab")) ;
    if(selectedTab !== null){
      this.OnChangeReports_Tab(selectedTab);
    }else{
      this.OnChangeReports_Tab("A");
    }
  }


  ngAfterViewChecked() {
    let selectedTab = JSON.parse(localStorage.getItem("selected_Shipments_Tab")) ;
    let  loginData = JSON.parse(localStorage.getItem('loginresultobject'));
    if(selectedTab !== null){
      if(selectedTab === 'I') {
        this.isArchiveEnable =false ;
        this.myGrid.selectionmode("none") 
      } 
      else{
        if(loginData.reportAcess ==='Y'){ 
          this.isArchiveEnable =true ;
          this.myGrid.selectionmode("checkbox") ;
        }else{
          this.isArchiveEnable =false ;
          this.myGrid.selectionmode("none") ;
        }
      }
    }else{
      if(loginData.reportAcess ==='Y'){ 
        this.isArchiveEnable =true ;
        this.myGrid.selectionmode("checkbox") ;
      }else{
        this.isArchiveEnable =false ;
        this.myGrid.selectionmode("none") ;
      }
    }

    this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll('a');
    this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll('i');
    this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.href != '') {
            let href_Valid_Check_Var = anchor.href.split(":")[0];
            if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                anchor.addEventListener('click', this.handleAnchorClick)
            }
        }
    })

    this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
        if (editicon.id != '') {
            let href_Valid_Check_Var = editicon.id.split(":")[0];
            if ((href_Valid_Check_Var === 'edit')) {
                editicon.addEventListener('click', this.handleiconClick)
            } 
        }
    })
}

ngAfterViewInit(){
  // this.myGrid.updatebounddata("refresh") ;
  let selectedTab = JSON.parse(localStorage.getItem("selected_Shipments_Tab")) ;
    let  loginData = JSON.parse(localStorage.getItem('loginresultobject'));
    if(selectedTab !== null){
      if(selectedTab === 'I') {
        this.myGrid.selectionmode("none") ;
        this.myGrid.hidecolumn("actions_e") ;
      } 
      else{
        if(loginData.reportAcess ==='Y'){ 
          this.isArchiveEnable =true ;
          this.myGrid.selectionmode("checkbox") ;
        }else{
          this.isArchiveEnable =false ;
          this.myGrid.selectionmode("none") ;
        }
      }
    }else{
      if(loginData.reportAcess ==='Y'){ 
        this.isArchiveEnable =true ;
        this.myGrid.selectionmode("checkbox") ;
      }else{
        this.isArchiveEnable =false ;
        this.myGrid.selectionmode("none") ;
      }
    }






}

OnChangeReports_Tab(type : string){
  this.spinner.show()
  localStorage.setItem("selected_Shipments_Tab",JSON.stringify(type)) ;
  this.source.url = ViewShipmentsComponent.webapi + 'handlerregistershipments/HandlerShipementReg.php/shipmentList' + '&orgId=' + this.logindet.orgId +'&type='+ type+ '&loginUserId=' + this.logindet.userId
 
  this.myGrid.gotopage(0) ;
  this.myGrid.updatebounddata("refresh") ;
  if(type === 'A'){
    this.isArchiveEnable = true ;
    this.myGrid.clearselection() ;
     this.myGrid.selectionmode("checkbox") ;
     this.myGrid.showcolumn("actions_e") ;
     document.getElementById("archive_Reports").className="nav-link" ;
     document.getElementById("active_Reports").className="nav-link active" ;
  }else{
    this.isArchiveEnable = false ;
    this.myGrid.selectionmode("none") ;
    this.myGrid.hidecolumn("actions_e") ;
   document.getElementById("archive_Reports").className="nav-link active" ;
   document.getElementById("active_Reports").className="nav-link" ;
  }
}



public handleiconClick = (event: Event) => {
  event.preventDefault();
  const editicon = event.target as HTMLImageElement;
  this.onClick_Icon(editicon);
}

public handleAnchorClick = (event: Event) => {
  event.preventDefault();
  const anchor = event.target as HTMLAnchorElement;
  this.onClick_AnchorTag(anchor);
}

onClick_AnchorTag(anchor : HTMLAnchorElement) {
  if (anchor.innerText === 'Edit') {
      let href_Valid_Check_Var = anchor.href.split(":")[1];
      ViewShipmentsComponent.totalGridData[href_Valid_Check_Var]
      this.onEditClick(ViewShipmentsComponent.totalGridData[href_Valid_Check_Var]);
  }
}


onClick_Icon(editicon : HTMLImageElement) {
  let href_Valid_Check_Var = editicon.id.split(":")[1];
  ViewShipmentsComponent.totalGridData[href_Valid_Check_Var]
  this.onEditClick(ViewShipmentsComponent.totalGridData[href_Valid_Check_Var]);
}

onEditClick(obj : any) :void{
  ;
  this.ViewReportsData.shipmentIsFrom = 'Edit' ;
  this.ViewReportsData.shipmentId =  obj.id ; 
  this.ViewReportsData.organizationId = obj.org_id ;
  this.route.navigateByUrl('/CargoImageHandler/create-shipment') ;
}

  logindet = JSON.parse(localStorage.getItem('loginresultobject'));
  url = ViewShipmentsComponent.webapi + 'registershipments/createShipementReg.php/shipmentList' + '&orgId=' + this.logindet.orgId +'&type='+ 'A'+ '&loginUserId=' + this.logindet.userId
  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY
  source =
    {
      url: this.url,
      datatype: 'json',
      datafields:
        [
          { name: 'awb_numbers', type: 'string' },
          { name: 'bond_type', type: 'string' },
          { name: 'actions_e', type: 'string' },
          { name: 'pdf_path', type: 'string' },
          { name: 'station_code', type: 'string' },
          { name: 'created_date_time', type: 'string' },
          { name: 'flight_no', type: 'string' },
          { name: 'flight_date', type: 'string' }, 
          { name: 'shiper_name', type: 'string' },
          { name: 'actions', type: 'string' },
          { name: 'first_name', type: 'string' },
          // { name: 'station_code', type: 'string' },
          { name: 'description', type: 'string' },
          
          
          { name: 'id', type: 'string' },
          { name: 'org_id', type: 'string' },
        ],
      pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
      root: 'Rows',
      totalrecords: 800,
      cache: false,
      type: 'GET',

      sort: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata('sort');
      },
      filter: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata('filter');
      },
      beforeprocessing: (data: any) => {
        this.spinner.hide();
        if (data.responseCode === 1) {
          if (data.Rows !== undefined) {
            if (data.Rows.length !== 0) {
              data.Rows = data.Rows;
              this.source.totalrecords = data.TotalRows;
              ViewShipmentsComponent.totalGridData =  data.Rows ;
              if(data.activeCount != undefined && data.activeCount != null){
                this.finalReportsCount = data.activeCount ;
              }else{
                this.finalReportsCount = 0;
              }
                 
              
              
            }
          } else {
            data.Rows = [];
            this.source.totalrecords = 0;
           
            if(data.activeCount != undefined && data.activeCount != null){
              this.finalReportsCount = data.activeCount ;
            }else{
              this.finalReportsCount = 0;
            }
            
          }

        } else {
          data.Rows = [];
          this.source.totalrecords = 0;
         
            this.finalReportsCount = 0 ; 
          
        }




      },

      pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
        // callback called when a page or page size is changed.
        console.log(pagenum);
      }

    };
  linkrenderer = function (row, column, value) {
;
    var html = "<a  href='"+ ViewShipmentsComponent.webapi + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
    return html;
  }

  columnsrenderer = function (value) {
    ;
    return '<div style="text-align: center; margin-top: 5px;">' + value + '</div>';
  }
  dataAdapter = new jqx.dataAdapter(this.source, {

    //Set the http header before calling the api.
    beforeSend: function (jqXHR, settings) {
      let urlSplit1 = [] ;
      let urlSplit2 = [] ;
      urlSplit1 = settings.url.toString().split("shipmentList");
      urlSplit2 = urlSplit1[1].toString().split("?");
      settings.url = urlSplit1[0] + "shipmentList?" + urlSplit2[0] + "&" + urlSplit2[1] ;
      this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
      this.token = this.loginresultobject.token;
      jqXHR.setRequestHeader('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0'),
        jqXHR.setRequestHeader('pragma', 'no-cache'),
        jqXHR.setRequestHeader('expires', '0'),
        jqXHR.setRequestHeader('authorization', this.token);
    }
  });
  public loginDataDup = JSON.parse(localStorage.getItem('loginresultobject'));

  columns =
    [

      {
        text: "Master Airway Bill", datafield: "awb_numbers",width: 200,filterable: false,sortable:false,groupable:false,
        cellclassname: function (row, column, value, data) {
          


        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },

      },
      {
        text: 'Actions', datafield: 'actions_e', cellsalign: 'center',width: 80,filterable: false,sortable:false,groupable:false,
         cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
          let loginData = JSON.parse(localStorage.getItem('loginresultobject'));
          if (loginData.stationCode == ViewShipmentsComponent.totalGridData[columnData.uid].station_code) {
            return "<div style='margin-top:  8.5px; margin-left: 20px;'><a  href='edit:" + columnData.uid + "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" + columnData.uid + "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a>";
          } else {
            return "<div style='margin-top:  8.5px; margin-left: 20px;'><i class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip'></i>";
          }
          // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
          // return "<div style='margin-top:  8.5px; margin-left: 20px;'><a  href='edit:" + columnData.uid + "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" + columnData.uid + "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a>";
        }, renderer: this.columnsrenderer
      },

      { text: 'Pdf', datafield: 'pdf_path', width: 80, filterable: false,sortable:false,groupable:false,
      cellsrenderer: this.linkrenderer },
      // { text: 'Station', datafield: 'station_code', width: 80 },
      { text: 'Created On', datafield: 'created_date_time', width: 200 },
      {
        text: "Requested By", datafield: "bond_type",width: 100,
        cellclassname: function (row, column, value, data) {
          


        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },

      },
      {
        text: "Flight", datafield: "flight_no", width: 100,
        cellclassname: function (row, column, value, data) {
          


        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },

      },
      {
        text: "Flight Date", datafield: "flight_date",width: 110,
        cellclassname: function (row, column, value, data) {
          


        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },

      },
      
      {
        text: "Airline/Agent", datafield: "shiper_name",width: 150,
        cellclassname: function (row, column, value, data) {
          
        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },
      },
      {
        text: "Activity", datafield: "actions",width: 200,filterable: false,sortable:false,groupable:false,
        cellclassname: function (row, column, value, data) {
          
        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },
      },
      
      // {
      //   text: "Station", datafield: "station_code",
      //   cellclassname: function (row, column, value, data) {
          
      //   },
      //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
      //   },
      // },
      {
        text: "Prepared By", datafield: "first_name",width: 150,
        cellclassname: function (row, column, value, data) {
          
        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },
      },
      {
        text: "Activity Description", datafield: "description",width: 200,
        cellclassname: function (row, column, value, data) {
          
        },
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
        },
      },
      
      { text: 'Id', datafield: 'id',  hidden: true, },
      { text: 'Org Id', datafield: 'org_id',  hidden: true, }
    ];
  rendergridrows = (params: any): any => {
    return params.data;
  }



  onRowDoubleClick(rowData){
    this.ViewReportsData.shipmentId = rowData.args.row.bounddata.id
    this.ViewReportsData.organizationId = rowData.args.row.bounddata.org_id
   this.route.navigateByUrl("/CargoImageHandler/view-shipment-details")  ;
  }




  fn_To_Archive(){
    
    if(this.selectedRowsData.length !==0 ){
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    let inputObj ={ 
      "orgId": this.orgDetails.orgId,
      "shiperId":  []
      }
for (let i = 0; i < this.selectedRowsData.length; i++) {
  inputObj.shiperId.push( this.selectedRowsData[i].id)
}
  this.spinner.show() ;
  this.shipmentServc.api_To_Archive_Shipment_handler(inputObj).subscribe(responseData =>{
    this.spinner.hide() ;
    if(responseData.responseCode === 1){
       this.toastr.success(responseData.message) ;
       this.myGrid.gotopage(0) ;
       this.myGrid.updatebounddata("refresh") ;
       this.myGrid.clearselection() ;
    }else{
      this.toastr.error(responseData.message) ;
    }
  },error=>{
  this.spinner.hide() ;
  }
  )
}else{
  this.toastr.error("Please select atleast one shipment") ;
}
  }



    public selectedRowsData = [] ;
    _onChangeRowSelection(event){// trigger when checkbox checked / unchecked
    
    if(Array.isArray(event.args.rowindex)){ 
       if(event.args.rowindex.length !== 0){
        this.selectedRowsData = ViewShipmentsComponent.totalGridData ;
       }else{
        this.selectedRowsData = [];
       }
    }else{
        if( event.type === "rowunselect"){
            let index = this.selectedRowsData.findIndex(x => x.id === event.args.row.id);
            this.selectedRowsData.splice(index, 1);
        }else{
            this.selectedRowsData.push(event.args.row) ; 
        }
    }
        console.log("this.selectedRowsData",this.selectedRowsData)
}


//redirect to Uld damage reports
onClickUldDamage_Reports() {
  localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
  this.router.navigateByUrl('/Uld/viewuld');
}

//redirect to damage reports
onClickDamage_Reports() {

  this.spinner.show();

  setTimeout(() => {
    
  
  localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"));
  this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
  
 if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
   
    this.router.navigateByUrl('/csa-dashboard');
  }
  else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
   
    this.router.navigateByUrl('/csa-airline-dashboard');
  }
  else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
  
    this.router.navigateByUrl('/admin-airline-dashboard');
  }
  else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
   
    this.router.navigateByUrl('/admin-handler-dashboard');
  }
  else if (this.loginDetails.userType == 'E') {
   
    this.router.navigateByUrl('/user-dashboard');
  }else if (this.loginDetails.orgType == 'E') {
    this.router.navigateByUrl('/HandlerDashboardComponent');
  }else{
    localStorage.clear()
    this.router.navigateByUrl('/Home');
  }

}, 200);
}

OnClickTruckInspection() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
    this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
  }, 200);
}
onClick_CreateShipment_handler() {
  this.ViewReportsData.shipmentIsFrom = 'Create';
  this.router.navigateByUrl('/CargoImageHandler/create-shipment') ;
}
}
