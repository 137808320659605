export class damageinfo {
   
        public categoryId: any;
        public userId: any;
        public masterAwbNo: any;
      
        public methodPacking: any;
        public identificationNo: any;
        public damagePacking: any;
        public contentAffected: any;
        public contentPieces: any;
        public contentWeight: any;
        public itemMissing: any;
        public itemPcs: any;
        public itemWeight: any;
        public suspectPilf: any;
        public safetyLife: any;
        public conditioncontent: any;
        public recuperationReq: any;
        public recuperatoinStatus: any;
        public affectedAwb: any;
        public classificationType: any;
        public dentsOrTears: any;
        
}
