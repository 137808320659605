import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer, Subscription, Observable, of, interval } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { InactivityService } from './inactivity-service';
import { LoginService } from './services/login/login.service';
import { NavbarComponent } from './layouts/navbar/navbar.component';

@Component({
  selector: 'inactivity-timer',
  template: `

  `,
  styles: [],
})
export class InactivityTimerComponent implements OnDestroy, OnInit {
  // <h1>User logout in:  {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}
  // </h1>
  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime = 60;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  subscription: Subscription;
  fetchData$: Observable<any> = of([]);

  constructor(private inactiveService: InactivityService, private loginService: LoginService, private navbarComponent: NavbarComponent) {

  }

  ngOnInit() {
    this.resetTimer();
    this.inactiveService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });

    // let intvl = 300000 ;
    // this.subscription = timer(0, intvl ).subscribe((res) => {
    //   console.log('iam');
    //   if (res) {
    //     this.checksession();
    //   }
    // });
  }

  // checksession() {
  //   if(this.logindet.userId !== null && localStorage.getItem("Token") !== null ){
  //     let obj = {
  //       'userId': this.logindet.userId,
  //       'sessionId': localStorage.getItem("SessionToken")
  //     };
  //     this.loginService.getSession(obj).subscribe(res => {
  //       if (res.responseCode !== 1) {
  //         console.log('session expired api called and session was expired.')
  //         this.navbarComponent.logOut();
  //       }else{
  //         console.log('session expired api called but session is active.')
  //       }
  //     }, (err) => {
  //       console.log(err);
  //     })
  //   } 
  // }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logindet = JSON.parse(localStorage.getItem("loginresultobject"));
  resetTimer(endTime: number = this.endTime) {
    const interval = 5000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => { },
      () => {
        this.inactiveService.logOutUser();
      }
    )
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

}