
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[appCharactersOnlyDirective]'
})
export class CharactersOnlyDirectiveDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
   // ;
    if(this._el.nativeElement.name === 'firstname' || this._el.nativeElement.name === 'lastname'){
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\s]*/g,'');
    }
  else{
      this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z]*/g,'');
    }    

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }


}
