import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';
import { Login } from './login';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';

import { LoginDataService } from './login-data.service';
import { ShareLoginDataService } from '../data/share-login-data.service';
import { SidebarComponent } from '../layouts/sidebar/sidebar.component'
import { CookieService } from 'ngx-cookie-service';
import { globalConstants } from 'src/environments/globalConstants';
declare var jQuery: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    User = new Login('', '');
    errormsg: string;
    LoginForm: FormGroup;
    cookieValue: string;


    constructor(private cookieService: CookieService,private ShareLoginDataService: ShareLoginDataService, private SidebarComponent: SidebarComponent, private LoginDataService: LoginDataService, private router: Router, private toastr: ToastrService, private loginservice: LoginService, private spinner: NgxSpinnerService, ) { }

    ngOnInit() {
        window.scroll(0, 0);
        this.LoginForm = new FormGroup({
            'emailId': new FormControl(null, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            'password': new FormControl(null, [Validators.required, this.removeSpaces])
        })
    }


    gotofpassword() {
        this.router.navigateByUrl('/forgotpassword');
    }

    Login(LoginForm) {
        ;
        console.log(this.LoginForm)
        this.spinner.show();
        this.loginservice.login(LoginForm).subscribe(
            data => {
                ;
                console.log(data)
                this.ShareLoginDataService.updateData(data);
                this.LoginDataService.emitLoginData(data)
                this.spinner.hide();
                if (data.responseCode == '1') {
                     
                    jQuery('#login').modal('hide');

                    localStorage.setItem('loginresultobject', JSON.stringify(data));
                    localStorage.setItem('logindetailsForSwitching', JSON.stringify(data));

                    localStorage.setItem("SessionToken",data.token);
                    localStorage.setItem("Token",JSON.stringify(data.token));
                    localStorage.setItem("loginDetails",JSON.stringify(data));
                    if(LoginForm.rememberMeStatus) {
                        let obj={
                           emailId: LoginForm.emailId,
                            pwd:LoginForm.password
                        }
                        localStorage.setItem('credentialsForRememberMe', JSON.stringify(obj));
                      }else{
                        let obj={
                            emailId: '',
                             pwd:''
                         }
                         localStorage.setItem('credentialsForRememberMe', JSON.stringify(obj));
                      }
                    var expire = new Date();
                    var time = Date.now() + ((3600 * 1000) * 8);
                    expire.setTime(time);
                    this.cookieService.set('cclp', JSON.stringify(data.token),expire,'/','.cclp.aero',true,"None");
                    this.cookieValue = this.cookieService.get('cclp');
                    console.log("set cookie value of cclp",this.cookieValue);
                    if (data.userType == 'SA') {
                        this.toastr.success('Login Successfully', '', { timeOut: 1500 });
                        this.SidebarComponent.activecall("1")
                        this.router.navigateByUrl('/sa-dashboard');
                    } else if(data.orgType === 'E'){
                       
                        this.toastr.success('Login Successfully', '', { timeOut: 1500 });
                        this.router.navigateByUrl('/HandlerDashboardComponent');
                       
                    }else {
                        this.toastr.success('Login Successfully', '', { timeOut: 1500 });
                        this.router.navigateByUrl('/HandlerDashboardComponent');
                        // this.router.navigateByUrl('/user-dashboard')
                    }

                   
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }

    removeSpaces(control: AbstractControl) {
        if (control && control.value && !control.value.replace(/\s/g, '').length) {
            control.setValue('');
        }
        return null;
    }


}
