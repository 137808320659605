import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slice',
  pure: false
})
export class SlicePipe implements PipeTransform {

//   transform(value: any, ...args: any[]): any {
//     return null;
//   }


  transform(value: any, limit: number) {
        if(value.length > limit){
            return value.substr(0,limit) + '...';
        }else{
            return value;
        }
    }

}
