import { Component, OnInit, enableProdMode, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';

import { DeactivatestationemailsService } from 'src/app/services/dsc/deactivatestationemails.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

declare var jQuery: any;
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-view-dse',
    templateUrl: './view-dse.component.html',
    styleUrls: ['./view-dse.component.css']
})
export class ViewDSEComponent implements OnInit {

    @ViewChild('grid') dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
    viewulds: {}[];
    orgDetails: any;

    constructor(private router: Router,private spinner: NgxSpinnerService, private DeactivatestationemailsService: DeactivatestationemailsService, private toastr: ToastrService) { }

    ngOnInit() {
        
        this.orgDetails = JSON.parse(localStorage.getItem('comapanydetails'));
        console.log('comapanydetails', this.orgDetails);
        let obj = {
            "loginUserId": this.orgDetails.userId,
            "orgId": this.orgDetails.orgId,
        }
        this.spinner.show()
        this.DeactivatestationemailsService.viewDeactivateStationEmail(obj).subscribe(data => {
            ;
            console.log('this.DeactivatestationemailsService', data);
            if (data.responseCode === 1) {
                ;
                this.viewulds = data.stationDetails;
                this.spinner.hide();
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.spinner.hide();
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
                this.toastr.error(data.message, '', { timeOut: 1400 });
                this.spinner.hide();
            }
        }, error => {
            this.spinner.hide();
            this.toastr.error(error.message, '', { timeOut: 1400 });

        });


    }
    editActivationEmails(data) {
        ;
        console.log('editActivationEmails', data)
        let obj = {
            "repStationId": data.data.id,
            "loginUserId": this.orgDetails.userId,
            "emailId": data.data.emailId,
            "stationCode":data.data.stationCode
        }
        console.log('editActivationEmails', obj);
        localStorage.setItem('editActivationEmails', JSON.stringify(obj));
         this.router.navigateByUrl('/editdeactivatestationemail');
    }

}
