import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ConfigServiceService } from "src/app/config-service.service";
// const WEB_API_URL = environment.URL;
@Injectable({
  providedIn: "root",
})
export class LoginServicesService {
  WEB_API_URL:any ;
  constructor(private http: HttpClient,config : ConfigServiceService) {
    this.WEB_API_URL =    config.getConfig().CARGO_CLAIM_URL
  }
  loginServc(data) {
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/airportClaimAdmin.php/airClaimLogin",
      data
    );
  }
  forgotPassword(emailid) {
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/myAccountAirportClaim.php/forgotPassword",
      emailid
    );
  }
  logOut(data) {
    return this.http.post<any>(
      this.WEB_API_URL + "cargoClaim/airportClaimLogOut.php/logOutairClaim",
      data
    );
  }

  service_ToCheckLogin_Status(token1){
   let  headers = new HttpHeaders({ 'Authorization': token1 , 'Content-Type': 'application/json; charset=utf-8',});
    return this.http.post<any>(this.WEB_API_URL + 'tokenVerification.php/getValidateToken',{},{headers:headers});
  }
}
