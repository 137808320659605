import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ShareLoginDataService } from '../data/share-login-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../services/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from '../login/login-data.service';
import { SidebarComponent } from '../layouts/sidebar/sidebar.component';

@Component({
  selector: 'app-react-web',
  templateUrl: './react-web.component.html',
  styleUrls: ['./react-web.component.css']
})
export class ReactWebComponent implements OnInit {
  obj;
  cookieValue: string;
  errormsg: string;

  constructor(
    private cookieService: CookieService,
    private ShareLoginDataService: ShareLoginDataService,
    private SidebarComponent: SidebarComponent,
    private LoginDataService: LoginDataService,
    private router: Router,
    private toastr: ToastrService,
    private loginservice: LoginService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
    ) {
      this.cookieService.deleteAll();
      localStorage.clear();
      this.spinner.show();
      localStorage.setItem("currentModule",JSON.stringify(route.snapshot.params.id))
      this.route.queryParams.subscribe(params => {
        this.obj = JSON.parse(params['values'])
        console.log(this.obj); // Print the parameter to the console. 
        console.log(this.obj);
        this.ShareLoginDataService.updateData(this.obj);
        this.LoginDataService.emitLoginData(this.obj);
        this.spinner.hide();
        if (this.obj.responseCode == "1") {
          localStorage.setItem("loginresultobject", JSON.stringify(this.obj));
          localStorage.setItem(
            "logindetailsForSwitching",
            JSON.stringify(this.obj)
          );
    
          localStorage.setItem("SessionToken", this.obj.token);
          localStorage.setItem("Token", JSON.stringify(this.obj.token));
          localStorage.setItem("loginDetails", JSON.stringify(this.obj));
          let obj1 = {
            emailId: "",
            pwd: "",
          };
          localStorage.setItem(
            "credentialsForRememberMe",
            JSON.stringify(obj1)
          );
          var expire = new Date();
          var time = Date.now() + 3600 * 1000 * 8;
          expire.setTime(time);
          this.cookieService.set(
            "cclp",
            JSON.stringify(this.obj.token),
            expire,
            "/",
            ".cclp.aero",
            true,
            "None"
          );
          this.cookieValue = this.cookieService.get("cclp");
          console.log("set cookie value of cclp", this.cookieValue);
          if (this.obj.userType == "SA") {
            this.toastr.success("Login Successfully", "", { timeOut: 1500 });
            this.SidebarComponent.activecall("1");
            this.router.navigateByUrl("/sa-dashboard");
          } else if (this.obj.orgType === "E") {
            this.toastr.success("Login Successfully", "", { timeOut: 1500 });
            this.router.navigateByUrl("/HandlerDashboardComponent");
          } else {
            this.toastr.success("Login Successfully", "", { timeOut: 1500 });
            this.router.navigateByUrl("/HandlerDashboardComponent");
            // this.router.navigateByUrl('/user-dashboard')
          }
        } else {
          // this.toastr.error(this.obj.message, "", { timeOut: 1500 });
          this.router.navigateByUrl("/#/Home");
        }
    
    });
      
      // this.obj = JSON.parse(data.obj);
      // console.log(this.obj)
    }

  ngOnInit() {  }

}
