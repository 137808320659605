import { Injectable } from '@angular/core';


export class User {
constructor(
public organizationId:number,
public organizationType:string,
public firstName:string,
public lastName:string,
public loginId:string,
public stationCode:string,
public dob:string,
public userType:string,
public address:string,
public contactNumber:number,
public  roleType: string,
public landLine?:string,
public hintQuestion?:string,
public hintAnswer?:string,
public createdBy?:number,
public branchId?:number,
public regionId?:number,
public userPrivileges?:string,
public jobTitle?:string,
public iswebormobile?:number,
public userId?:string,
public organisationName?:string,

){}
}
