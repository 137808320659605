import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TruckInspectionRoutingModule } from './truck-inspection-routing.module';
import { CreateTruckinspectionComponent } from './create-truckinspection/create-truckinspection.component';
import { ViewTruckinspectionComponent } from './view-truckinspection/view-truckinspection.component';
import { ViewTruckinspectionDetailsComponent } from './view-truckinspection-details/view-truckinspection-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedLayoutsModule } from '../layouts/shared-layouts/shared-layouts.module';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { FormsModule } from '@angular/forms';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { SharedModule } from '../shared/sharedModule/shared.module';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatDatetimepickerModule, MAT_DATETIME_FORMATS } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule} from '@mat-datetimepicker/moment';


@NgModule({
  declarations: [CreateTruckinspectionComponent, ViewTruckinspectionComponent, ViewTruckinspectionDetailsComponent],
  imports: [
    CommonModule,
    TruckInspectionRoutingModule,
    NgxSpinnerModule,
    SharedLayoutsModule,
    SharedModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule
  ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L',
          monthInput: 'MMMM',
          timeInput: 'HH:mm:ss',
          datetimeInput: 'L HH:mm:ss',
        },
        display: {
          dateInput: "L",
          monthInput: "MMMM",
          datetimeInput: "L HH:mm:ss",
          timeInput: "HH:mm:ss",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
          popupHeaderDateLabel: "ddd, DD MMM"
        }
      }
    }
  ],
  bootstrap :[CreateTruckinspectionComponent]
})
export class TruckInspectionModule { }
