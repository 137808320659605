import { Component, OnInit } from "@angular/core";
import { DamageReportsService } from "../services/damageReports/damage-reports.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AmazingTimePickerService } from "amazing-time-picker";
@Component({
  selector: "app-downloaddata",
  templateUrl: "./downloaddata.component.html",
  styleUrls: ["./downloaddata.component.css"],
})
export class DownloaddataComponent implements OnInit {
  totalCount: any;
  loginData: any;
  fromDate: any;
  toDate: any;
  picker: any;
  picker1: any;
  showquerterly: boolean;
  showYearInput: boolean = false;
  showdate: boolean;
  selectedYear: any = "Pick Year";
  fromDatePicker: any;
  matSelectedTypes: any;
  selectedMonth: any;
  QuarterlyValuesArray: any[];
  currentYear: number;
  minDate = new Date();
  maxDate = new Date();
  startmaxDate: Date;
  CountObj: Object;
  file_Name: any;
  selectedYear_ForStartDatevalidation: any;
  constructor(
    private atp: AmazingTimePickerService,
    private datePipe: DatePipe,
    private router: Router,
    private damageReport_Service: DamageReportsService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  //time picker method
  open() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {});
  }
  ngOnInit() {
    this.totalCount = 0;
    this.fromDate = "";
    this.toDate = "";
  }
  //To clear date method
  startYearClosed() {
    this.toDate = "";
  }
  //To download all charts
  onClickAllDownload() {
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
    let Object = {
      org_id: this.loginData.orgId,
      year: null,
      fromDate: null,
      toDate: null,
    };
    this.spinner.show();
    this.damageReport_Service.downloadreports(Object).subscribe(
      (result) => {
        this.downloadFile(result);
        this.spinner.hide();
      },
      (error) => {
        this.toaster.error("service error occured");
        this.spinner.hide();
      }
    );
  }
  //date picker method
  closeDatePicker(year, elem: any) {
    
    this.fromDate = "";
    this.toDate = "";
    this.selectedYear = Number(year.getFullYear());
    this.selectedYear_ForStartDatevalidation = year ;
    const currentDate = new Date();
    if (this.selectedYear < currentDate.getFullYear()) {
      this.minDate = new Date(this.selectedYear, 0, 1);
      this.startmaxDate = new Date(this.selectedYear, 11, 31);
    } else {
      this.minDate = new Date(this.selectedYear, 0, 1);
      this.startmaxDate = new Date(
        this.selectedYear,
        currentDate.getMonth(),
        currentDate.getDate()
      );
    }
    this.showdate = false;
    this.QuarterlyValuesArray = [];
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.selectedMonth = date.getMonth() + 1;
    if (this.selectedYear === this.currentYear) {
      if (this.selectedMonth > "00" && this.selectedMonth <= "03") {
        this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: true });
        this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
        this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
      } else if (this.selectedMonth > "03" && this.selectedMonth <= "06") {
        this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
        this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
      } else if (this.selectedMonth > "06" && this.selectedMonth <= "09") {
        this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
      } else if (this.selectedMonth > "09" && this.selectedMonth <= "12") {
        this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
        this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: false });
      }
    } else if (this.selectedYear < this.currentYear) {
      this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
      this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
      this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
      this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: false });
    }



    elem.close();
    this.HandleCount();
  }
  //To download chart single chart
  onClickDownload() {
    if (this.SELECTED_QuarterValuesArray.length != 0) {
      if (this.SELECTED_QuarterValuesArray[0] === "Q1") {
      }
    }
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
    this.fromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    let Object = {
      org_id: this.loginData.orgId,
      year: this.selectedYear,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    // if (
    //   this.selectedYear != null &&
    //   this.selectedYear != undefined &&
    //   this.selectedYear != "" &&
    //   this.selectedYear != "Pick Year"
    // ) {
      // alert(this.matSelectedTypes);
      if (this.matSelectedTypes != null && this.matSelectedTypes === "Dates") {
        if (
          this.fromDate === null ||
          this.fromDate === "" ||
          this.fromDate === undefined
        ) {
          this.toaster.error("please select start date");
          return;
        } else if (
          this.toDate === null ||
          this.toDate === "" ||
          this.toDate === undefined
        ) {
          this.toaster.error("please select end date");
          return;
        } else {
          this.spinner.show();
          this.damageReport_Service.downloadreports(Object).subscribe(
            (result) => {
              this.downloadFile(result);
              this.selectedYear = "";
              this.matSelectedTypes = "";
              this.QuarterlyValuesArray = [];
              this.showquerterly = false;
              this.showdate = false;
              this.fromDate = "";
              this.toDate = "";
              this.totalCount = 0;
              this.spinner.hide();
            },
            (error) => {
              this.toaster.error("service error occured");
              this.spinner.hide();
            }
          );
        }
      } else {
        this.spinner.show();
        this.damageReport_Service.downloadreports(Object).subscribe(
          (result) => {
            this.downloadFile(result);
            this.selectedYear = "";
            this.matSelectedTypes = "";
            this.QuarterlyValuesArray = [];
            this.showquerterly = false;
            this.showdate = false;
            this.fromDate = "";
            this.toDate = "";
            this.totalCount = 0;
            this.spinner.hide();
          },
          (error) => {
            this.toaster.error("service error occured");
            this.spinner.hide();
          }
        );
      }
    // } else {
    //   this.toaster.error("please select year");
    // }
  }
  //download method
  downloadFile(val) {
    if (val.responseCode === 0) {
      this.toaster.error(val.message);
    } else {
      var today = new Date();
      var date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      var time = today.getHours() + " " + today.getMinutes();
      var blob = new Blob([val], { type: "text/csv" });
      let fileName = '' ;
      if(this.file_Name !== undefined && this.file_Name !== '' && this.file_Name !== null ){
         fileName = this.file_Name ;
      }else{
        fileName = "airportClaims" + "_" + date + " " + time + ".csv";
      }
      // const fileName = "airportClaims" + "_" + date + " " + time + ".csv";
     
      if (window.navigator.msSaveOrOpenBlob) {
        //IE & Edge
        //msSaveBlob only available for IE & Edge
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        var url = window.URL.createObjectURL(blob);
        // window.open(url);
        var a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }
  }
  //To select quaterly fucntionality
  onSelectionChange(val) {
    
    this.fromDate = "";
    this.toDate = "";
    this.totalCount = 0 ;
    this.selectedYear = null
    // if (
    //   this.selectedYear != null &&
    //   this.selectedYear != "" &&
    //   this.selectedYear != undefined &&
    //   this.selectedYear != "Pick Year"
    // ) {
      ;
      if (val === "Quarterly") {
        this.showquerterly = true;
        this.showYearInput = true;
        this.showdate = false;
      
        this.QuarterlyValuesArray = [];
        // let date = new Date();
        // this.currentYear = date.getFullYear();
        // this.selectedMonth = date.getMonth() + 1;
        // if (this.selectedYear === this.currentYear) {
        //   if (this.selectedMonth > "00" && this.selectedMonth <= "03") {
        //     this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: true });
        //     this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
        //     this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
        //   } else if (this.selectedMonth > "03" && this.selectedMonth <= "06") {
        //     this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
        //     this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
        //   } else if (this.selectedMonth > "06" && this.selectedMonth <= "09") {
        //     this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
        //   } else if (this.selectedMonth > "09" && this.selectedMonth <= "12") {
        //     this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
        //     this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: false });
        //   }
        // } else if (this.selectedYear < this.currentYear) {
          this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: true });
          this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: true });
          this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
          this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
        // }
      } else if (val === "Dates") {
        this.showquerterly = false;
        this.showdate = true;
        this.showYearInput = false;
      }
    // } else {
    //   this.matSelectedTypes = null;
    //   this.toaster.error("please select Year");
    // }
  }
  SELECTED_QuarterValuesArray = [];
  onSelectCheckBoxes(value) {
    if (value === "Q1") {
      this.toDate = new Date("03-31-" + this.selectedYear);
      this.fromDate = new Date("01-01-" + this.selectedYear);
    } else if (value === "Q2") {
      this.fromDate = new Date("04-01-" + this.selectedYear);
      this.toDate = new Date("06-30-" + this.selectedYear);
    } else if (value === "Q3") {
      this.fromDate = new Date("07-01-" + this.selectedYear);
      this.toDate = new Date("09-30-" + this.selectedYear);
    } else if (value === "Q4") {
      this.fromDate = new Date("10-01-" + this.selectedYear);
      this.toDate = new Date("12-31-" + this.selectedYear);
    }
    this.HandleCount();
  }
  //To get reports count api 
  HandleCount() {
    
    this.spinner.show();
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
    this.fromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    let Object = {
      org_id: this.loginData.orgId,
      year: this.selectedYear,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    this.damageReport_Service.ReportsCount(Object).subscribe(
      (data) => {
        this.totalCount = data["count"];
        this.file_Name = data["filename"];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  addEvent(event) {
    this.HandleCount();
  }
}
