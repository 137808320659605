import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";

@Component({
    selector: 'app-compare-service-level',
    templateUrl: './compare-service-level.component.html',
    styleUrls: ['./compare-service-level.component.css']
})

export class CompareServiceLevelComponent implements OnInit {

    originstation: any = [];
    OriginyearOnecount: any = [];
    OriginyearTwocount: any = [];
    inputObj: any;
    loginDetails: any;
    orgId: any;
    scrollable: boolean;
    canvaslinewidth: number;
    height: number;
    constructor(
        private claimcountservice: ClaimCountService,
        private toaster: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }
    ngOnInit() {
        this.originstation =[];
        this.inputObj = JSON.parse(localStorage.getItem("inputObj"));

        this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        this.orgId = this.loginDetails.orgId;

        if (this.inputObj.yearOne && this.inputObj.yearTwo) {
            //service level api starts here
            this.spinner.show();
            this.claimcountservice.compare_serviceLevel_count(this.inputObj).subscribe(
                (data) => {
                    this.spinner.hide();

                    if (data.responseCode === 1) {
                        this.originstation = data.slaLevel.labels,
                        this.canvaslinewidth = this.getWidth(data.slaLevel.labels.length);
                            this.OriginyearOnecount = data.slaLevel.yearOneCount;
                        this.OriginyearTwocount = data.slaLevel.yearTwoCount;
                        //dymanic scroll start here
                        let labelLengthsalLevel = data.slaLevel.labels.length;

                        if (labelLengthsalLevel > 60) {
                            this.scrollable = true
                        }
                        else {
                            this.scrollable = false
                        }
                        setTimeout(() => {
                        new Chart(
                            <HTMLCanvasElement>document.getElementById("canvasline"),
                            {
                                type: "bar",
                                plugins: [ChartDataLabels],
                                data: {
                                    labels: this.originstation,
                                    datasets: [
                                        {
                                            // type: "line",
                                            borderColor: "rgba(0,0,255,1)",
                                            backgroundColor: "rgba(0,0,255,1)",
                                            hoverBorderColor: "rgba(0,0,255,1)",
                                            // yAxisID: "y-axis-0",
                                            data: this.OriginyearOnecount,
                                            fill: false,
                                            label: data.yearOne,
                                            stack: "a",
                                        },
                                        {
                                            // type: "bar",
                                            borderColor: "rgba(255,165,0,1)",
                                            backgroundColor: "rgba(255,165,0,1)",
                                            hoverBackgroundColor: "rgba(255,165,0,1)",
                                            // yAxisID: "y-axis-1",
                                            data: this.OriginyearTwocount,
                                            fill: false,
                                            label: data.yearTwo,
                                            stack: "b",
                                        },
                                    ],
                                },
                                options: {
                                    maintainAspectRatio: false,
                                    title: {
                                        display: true,
                                        //text: "Origin Claim Count"
                                    },
                                    plugins: {
                                        // Change options for ALL labels of THIS CHART
                                        datalabels: {
                                            color: "#000000",
                                            anchor: "end",
                                            align: "top",
                                            rotation: -90,
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                    },

                                    scales: {
                                        xAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Service Level",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    autoSkip: false,
                                                    // max rotation: -90,
                                                    // min rotation: -90
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Count",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    beginAtZero: true,
                                                    //lineHeight:20,
                                                    // max:plugin.settings.maxDataValue,
                                                    maxTicksLimit: 10,
                                                },
                                                id: "y-axis-0",
                                                position: "left",
                                            },
                                        ],
                                    },
                                },
                            }
                        );
                    }, 500);
                        // this.scrollView()
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toaster.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            )
            //service level api ends here

        }
    }
    scrollView() {
        setTimeout(() => {
            const elements = document.getElementsByClassName('chartAreaWrapper_comparescroll60');
            for (let ele of elements as any) {
                ele.scrollTop = ele.scrollHeight;
            }
        }, 2000);

    }

    getWidth(number) {
        this.height = 420;
        if (number < 10) {
            let finalWidth = 230 * number;
            return finalWidth;
        } else {
            let finalWidth = 60 * number;
            return finalWidth;
        }
    }
}