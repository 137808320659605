import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { environment } from "src/environments/environment";
import { globalConstants } from "src/environments/globalConstants";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { LocationStrategy } from "@angular/common";
import { ShipmentsService } from "src/app/services/shipments/shipments.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../config-service.service";
import { CargoAcceptanceService } from "src/app/services/cargo-acceptance/cargo-acceptance.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from 'moment';
import 'moment-timezone';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-import-acceptance',
  templateUrl: './view-import-acceptance.component.html',
  styleUrls: ['./view-import-acceptance.component.css']
})
export class ViewImportAcceptanceComponent implements OnInit {
  @ViewChild("myGrid") myGrid: jqxGridComponent;
  // @ViewChild("nestedGrid") nestedGrid: jqxGridComponent;
  newRowIndex: number;
  static webapi: string;
  static totalGridData: any;
  reportsData: any;
  anchor_Tags: any;
  editicon_Tags: any;
  isArchiveEnable: boolean = true;
  orgDetails: any;
  finalReportsCount = 0;
  loginDetails: any;
  CargoACCResp: any;
  slResp: any;
  static TOTAL_GRIDDATA :any =[];
  static IS_PAGE_SIZE_OR_PAGENUM_CHANGED :boolean = false;
  isSlectALL_records: boolean;
 

  constructor(
    private route: Router,
    private ViewReportsData: ViewReportsData,
    private elementRef: ElementRef,
    private location: LocationStrategy,
    private shipmentServc: ShipmentsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private config: ConfigServiceService,
    private router: Router,
    private cargoAcceptanceService: CargoAcceptanceService,
    private excelService:ExcelService) {
      // preventing back button in browser
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
      ViewImportAcceptanceComponent.webapi = this.config.getConfig().ULD_Url;
    }

    ngOnInit() {
      this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
      localStorage.removeItem("CARGO_Acceptance_STORAGE");
      localStorage.removeItem("CARGO_Import_Acceptance_From");
    }
    
    ngAfterViewChecked() {
      let selectedTab = JSON.parse(
        localStorage.getItem("selected_Shipments_Tab")
      );
      let loginData = JSON.parse(localStorage.getItem("loginresultobject"));
      this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll("a");
      this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll("i");
      this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.href != "") {
          let href_Valid_Check_Var = anchor.href.split(":")[0];
          if (anchor.innerText === "Edit" && href_Valid_Check_Var === "edit") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
        }
      });
      this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
        if (editicon.id != "") {
          let href_Valid_Check_Var = editicon.id.split(":")[0];
          if (href_Valid_Check_Var === "edit") {
            editicon.addEventListener("click", this.handleiconClick);
          }
        }
      });
    }
  
    ngAfterViewInit() {
      // this.myGrid.updatebounddata("refresh") ;
      let selectedTab = JSON.parse(
        localStorage.getItem("selected_Shipments_Tab")
      );
      let loginData = JSON.parse(localStorage.getItem("loginresultobject"));
        this.myGrid.showcolumn("cargo_acc_location");
        this.myGrid.hidecolumn("cargoAccLocationCopy");
        this.myGrid.showcolumn("shipment_number");
        this.myGrid.hidecolumn("shipment_numberCopy");
      if (selectedTab !== null) {
        this.OnChangeReports_Tab(selectedTab);
      } else {
        this.OnChangeReports_Tab("A");
      }
    }
  
    OnChangeReports_Tab(type: string) {
      this.selectedRowsData = [];
      ViewImportAcceptanceComponent.TOTAL_GRIDDATA =[];
      this.spinner.show();
      localStorage.setItem("selected_Shipments_Tab", JSON.stringify(type));
      this.source.url =
        ViewImportAcceptanceComponent.webapi +
        "cargoAccept/ImportAcceptance/GetImportAcceptList.php/importAcceptList?" +
        "orgId=" +
        this.logindet.orgId +
        "&orderType=" +
        type +
        "&type=" +
        type +
        "&loginUserId=" +
        this.logindet.userId;
      this.myGrid.gotopage(0);
      this.myGrid.updatebounddata("refresh");
      if (type === "A") {
        if(this.logindet.reportAcess === 'Y'){
        this.isArchiveEnable = true;
        this.myGrid.selectionmode("checkbox");
        this.myGrid.clearselection();
        }else{
          this.isArchiveEnable = false;
          this.myGrid.selectionmode("none");
        }
       
        this.myGrid.showcolumn("actions_e");
        document.getElementById("archive_Reports").className = "nav-link";
        document.getElementById("active_Reports").className = "nav-link active";
      } else {
        this.isArchiveEnable = false;
        this.myGrid.selectionmode("none");
        this.myGrid.showcolumn("actions_e");
        document.getElementById("archive_Reports").className = "nav-link active";
        document.getElementById("active_Reports").className = "nav-link";
      }
      this.myGrid.showcolumn("cargo_acc_location");
      this.myGrid.hidecolumn("cargoAccLocationCopy");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  
    public handleiconClick = (event: Event) => {
      event.preventDefault();
      const editicon = event.target as HTMLImageElement;
      this.onClick_Icon(editicon);
    };
  
    public handleAnchorClick = (event: Event) => {
      event.preventDefault();
      const anchor = event.target as HTMLAnchorElement;
      this.onClick_AnchorTag(anchor);
    };
  
    onClick_AnchorTag(anchor: HTMLAnchorElement) {
      if (anchor.innerText === "Edit") {
        let href_Valid_Check_Var = anchor.href.split(":")[1];
        ViewImportAcceptanceComponent.totalGridData[href_Valid_Check_Var];
        this.onEditClick(
          ViewImportAcceptanceComponent.totalGridData[href_Valid_Check_Var]
        );
      }
    }
  
    onClick_Icon(editicon: HTMLImageElement) {
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      ViewImportAcceptanceComponent.totalGridData[href_Valid_Check_Var];
      this.onEditClick(
        ViewImportAcceptanceComponent.totalGridData[href_Valid_Check_Var]
      );
    }
  
    onEditClick(obj: any): void {
       
      let storeObj = {
        mawbId : obj.id,
        // organizationId : obj.org_id,
      }
      localStorage.setItem("CARGO_Import_Acceptance_STORAGE",JSON.stringify(storeObj));
      localStorage.setItem("CARGO_Import_Acceptance_From",JSON.stringify("Edit"));
      this.route.navigateByUrl("/CargoAcceptance/create-import-acceptance");
    }
    

  
    logindet = JSON.parse(localStorage.getItem("loginresultobject"));
    url ="";
    public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
    source = {
      datafields: [
        { name: "mawb", type: "string" },
        { name: "actions_e", type: "string" },
        { name: "cargo_pdf", type: "string" },
        { name: "take_in", type: 'date', format: 'yyyy-MM-dd' },
        { name: "airport_origin", type: "string" },
        { name: "consigneeName", type: "string" },
        { name: "cargo_acc_location", type: "string" },
        { name: "total_weight", type: "string" },
        { name: "total_pieces", type: "string" },
        { name: "total_hawbs", type: "string" }
        // { name: "address_lable", type: "string" },
      ],
      pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
      root: "Rows",
      record: '',
      totalrecords: 800,
      cache: false,
      type: "GET",
      url: this.url,
      datatype: "json",
  
      sort: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata("sort");
      },
      filter: () => {
        // update the grid and send a request to the server.
        this.myGrid.updatebounddata("filter");
        this.spinner.show();
      },
      
      beforeprocessing: (data: any) => {
        this.spinner.hide();
         
        if (data.responseCode === 1) {
          if (data.Rows !== undefined) {
            if (data.Rows.length !== 0) {
              data.Rows = data.Rows;
              for(var i=0; i<data.Rows.length; i++){
                if(data.Rows[i].houseAwbs !== null){
                  data.Rows[i].total_hawbs = data.Rows[i].houseAwbs.length;
                }
              }
              this.source.totalrecords = data.TotalRows;
              ViewImportAcceptanceComponent.totalGridData = data.Rows;
              if (data.activeCount != undefined && data.activeCount != null) {
                this.finalReportsCount = data.activeCount;
              } else {
                this.finalReportsCount = 0;
              }
  
              if(ViewImportAcceptanceComponent.TOTAL_GRIDDATA){
                if(ViewImportAcceptanceComponent.TOTAL_GRIDDATA.length === 0){
                  ViewImportAcceptanceComponent.TOTAL_GRIDDATA =  data.Rows ;
                }else{
                  ViewImportAcceptanceComponent.TOTAL_GRIDDATA = ViewImportAcceptanceComponent.TOTAL_GRIDDATA.concat(data.Rows) ;
                }
              }
             
              ViewImportAcceptanceComponent.TOTAL_GRIDDATA = this.removeDuplicates(ViewImportAcceptanceComponent.TOTAL_GRIDDATA);
  
              if (this.isSlectALL_records === true) {
                this.selectedRowsData = [];
                this.selectedRowsData = ViewImportAcceptanceComponent.TOTAL_GRIDDATA;
            
              }
            
                this.fn_Check_OrUncheckslectedrows_OnSearch();
            
              
            }
          } else {
            data.Rows = [];
            this.source.totalrecords = 0;
            if (data.activeCount != undefined && data.activeCount != null) {
              this.finalReportsCount = data.activeCount;
            } else {
              this.finalReportsCount = 0;
            }
          }
        } else {
          data.Rows = [];
          this.source.totalrecords = 0;
          this.finalReportsCount = 0;
  
          
        }
       
      },
  
      pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
        // callback called when a page or page size is changed.
       
      },
    };

    getWidth(): any {
      if (document.body.offsetWidth < 850) {
        return "90%";
      }
      return 850;
    }
    linkrenderer = function (row, column, value) {
      var html =
        "<a  href='" +
        ViewImportAcceptanceComponent.webapi +
        value +
        "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
      return html;
    };
  
    columnsrenderer = function (value) {
      return (
        '<div style="text-align: center; margin-top: 5px;">' + value + "</div>"
      );
    };

    dataAdapter = new jqx.dataAdapter(this.source, {
      //Set the http header before calling the api.
      beforeSend: function (jqXHR, settings) {
        let urlSplit1 = [];
        let urlSplit2 = [];
        this.loginresultobject = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        this.token = this.loginresultobject.token;
        jqXHR.setRequestHeader(
          "cache-control",
          "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
        ),
          jqXHR.setRequestHeader("pragma", "no-cache"),
          jqXHR.setRequestHeader("expires", "0"),
          jqXHR.setRequestHeader("authorization", this.token);
      },
    });

    // nested_url ="";
    // nestegridSource: any =
    // {
    //   datafields: [
    //       // { name: 'awb_id' },
    //       // { name: 'hawb_total_pieces' },
    //       // { name: 'hawb_total_weight' },
    //       // { name: 'type_of_packing_label' },
    //       // { name: 'types_of_packing' },
    //       // { name: 'address_labels_available' },
    //       // { name: 'bonded' },
    //       // { name: 'dangerous_goods' },
    //       // { name: 'DG_labels_available' },
    //       // { name: 'any_damage_noticed' },
    //       // { name: 'UN' },
    //       // { name: 'proper_shipping' },
    //       // { name: 'class' },
    //       // { name: 'packing_group' },
    //       // { name: 'net_quantity' },
    //       { name: 'awb_id' },
    //       { name: 'address_labels_available' },
    //       { name: 'bonded' },
    //       { name: 'dangerous_goods' },
    //       { name: 'DG_labels_available' },
    //       { name: 'UN' },
    //       { name: 'proper_shipping' },
    //       { name: 'class' },
    //       { name: 'packing_group' },

    //       // { name: 'hawb_total_pieces' },
    //       // { name: 'hawb_total_weight' },
    //       // { name: 'type_of_packing_label' },
    //       // { name: 'types_of_packing' },
    //       // { name: 'any_damage_noticed' },
    //       // { name: 'net_quantity' },
    //     ],
    //     root: 'howse_awbs',
    //     record: 'howse_awbs',
    //     datatype: 'json',
    //     url: this.nested_url
    // };
    // nested_dataAdapter = new jqx.dataAdapter(this.nestegridSource, {autoBind: true },{
    //   beforeSend: function (jqXHR, settings) {
    //     let urlSplit1 = [];
    //     let urlSplit2 = [];
    //     this.loginresultobject = JSON.parse(
    //       localStorage.getItem("loginresultobject")
    //     );
    //     this.token = this.loginresultobject.token;
    //     jqXHR.setRequestHeader(
    //       "cache-control",
    //       "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
    //     ),
    //       jqXHR.setRequestHeader("pragma", "no-cache"),
    //       jqXHR.setRequestHeader("expires", "0"),
    //       jqXHR.setRequestHeader("authorization", this.token);
    //   },
    // });
    howse_awbs = [];
    nestedGrids: any[] = new Array();
    initrowdetails= (index: any, parentElement: any, gridElement: any, record: any): void => {
      let id = record.uid.toString();
      let nestedGridContainer = parentElement.children[0];
      this.nestedGrids[index] = nestedGridContainer;

      var dta = ViewImportAcceptanceComponent.TOTAL_GRIDDATA;
      let obj={
        cargoId: dta[index].id,
        loginUserId: this.logindet.userId,
      }
      this.cargoAcceptanceService.getNestedHouseAwbs(obj).subscribe((resp: any) => {
        if(resp){
          this.howse_awbs = resp.Rows[0];
          let nestegridSource = {
            datafields: [
              { name: 'awb_id', type: "string"  },
              { name: 'address_labels_available', type: "string"  },
              { name: 'bonded', type: "string"  },
              { name: 'dangerous_goods', type: "string"  },
              { name: 'DG_labels_available', type: "string"  },
              { name: 'UN', type: "string"  },
              { name: 'proper_shipping', type: "string"  },
              { name: 'class', type: "string"  },
              { name: 'packing_group', type: "string"  },
            ],
              localdata: this.howse_awbs,
              cache: false,
              datatype: "json",
          }
          let nestedGridAdapter = new jqx.dataAdapter(nestegridSource);
          let nestedGrid: jqxGridComponent;

        
          if (nestedGridContainer != null) {
              let settings = {
                  theme: 'material', 
                  width: 1080,
                  height: 200,
                  source: nestedGridAdapter, 
                  enablebrowserselection: true,
                  selectionmode: 'none',
                  rowdetails: true,
                  initrowdetails: this.initnestedrowdetails,
                  rowdetailstemplate: this.subnestedrowdetailstemplate,
    
                  columns: [
                    { text: 'House AWB', datafield: 'awb_id', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Address Labels Available', datafield: 'address_labels_available', width: 200, cellclassname: 'awf-nested-grid-cell-copy'},
                    // { text: 'Bonded Goods', datafield: 'bonded', width: 150, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Dangerous Goods', datafield: 'dangerous_goods', width: 150, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'DG Labels Available', datafield: 'DG_labels_available', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'UN', datafield: 'UN', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Proper Shipping Name', datafield: 'proper_shipping', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Class', datafield: 'class', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Packing Group', datafield: 'packing_group', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },

                    { text: 'Package', width: 160, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Pieces',  width: 60, cellclassname: 'awf-nested-grid-cell-copy'},
                    { text: 'Length',  width: 60, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Width',  width: 60, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Height',  width: 60, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Weight',  width: 60, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Packing Label', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Location', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: "Pieces Out", width: 100, editable: false },
                    { text: "Pieces Stock", width: 100, editable: false },
                ]
              };
    
            nestedGrid = jqwidgets.createInstance(`#${nestedGridContainer.id}`, 'jqxGrid', settings );
          }
        }
      });
    }

    sud_howse_awbs: [];
    initnestedrowdetails= (index: any, parentElement: any, gridElement: any, record: any): void => {
      let id = record.uid.toString();
      let nestedGridContainer = parentElement.children[0];
      this.nestedGrids[index] = nestedGridContainer;

      let obj={
        hawbId: this.howse_awbs['id'],
        loginUserId: this.logindet.userId,
      }
      this.cargoAcceptanceService.getSubNestedHouseAwbs(obj).subscribe((resp: any) => {
        if(resp){
          this.sud_howse_awbs = resp.Rows[0].pieces_weight_array;
          let nestegridSource = {
            datafields: [
              { name: 'types_of_packing' },
              { name: 'pieces' },
              { name: 'length' },
              { name: 'width' },
              { name: 'height' },
              { name: 'weight' },
              { name: 'type_of_packing_label' },
              { name: 'location' },
            ],
              localdata: this.sud_howse_awbs,
              cache: false,
              datatype: "json",
          }
          let nestedGridAdapter = new jqx.dataAdapter(nestegridSource);
          let subnestedGrid: jqxGridComponent;

          if (nestedGridContainer != null) {
              let settings = {
                  theme: 'material', 
                  width: 980,
                  height: 200,
                  source: nestedGridAdapter, 
                  enablebrowserselection: true,
                  selectionmode: 'none',
    
                  columns: [
                    // { text: 'Package', datafield: 'awb_id', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Pieces', datafield: 'pieces', width: 200, cellclassname: 'awf-nested-grid-cell-copy'},
                    // { text: 'Length', datafield: 'length', width: 150, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Width', datafield: 'width', width: 150, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Height', datafield: 'height', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Weight', datafield: 'weight', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Packing Label', datafield: 'type_of_packing_label', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },
                    // { text: 'Location', datafield: 'location', width: 200, cellclassname: 'awf-nested-grid-cell-copy' },

                    { text: 'Address Labels Available', width: 180, cellclassname: 'awf-nested-grid-cell-copy'},
                    { text: 'Bonded Goods', width: 120, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Dangerous Goods', width: 130, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'DG Labels Available', width: 170, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'UN', width: 60, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Proper Shipping Name', width: 180, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Class', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Packing Group', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Net Quantity', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                    { text: 'Any Damage', width: 100, cellclassname: 'awf-nested-grid-cell-copy' },
                ]
              };
    
              subnestedGrid = jqwidgets.createInstance(`#${nestedGridContainer.id}`, 'jqxGrid', settings );
          }
        }
      });
    }

    subnestedrowdetailstemplate: any = {
      rowdetails: '<div id="subnestedGrid" style="margin: 10px;"></jqxGrid>', rowdetailsheight: 220, rowdetailshidden: true
    };

    getEditorDataAdapter = (datafield: string): any => {
      let dataAdapter = new jqx.dataAdapter(this.source, {
        uniqueDataFields: [datafield],
      });
      return dataAdapter;
    };
    input: any;
    public loginDataDup = JSON.parse(localStorage.getItem("loginresultobject"));
    

    rowdetailstemplate: any = {
      rowdetails: '<jqxGrid id="nestedGrid" style="margin: 10px;" ></jqxGrid>', rowdetailsheight: 220, rowdetailshidden: true
    };
    
    columns = [
      { text: "Master Airway Bill", datafield: "mawb", width: 130, editable: false },
      // {
      //   text: "Actions",
      //   datafield: "actions_e",
      //   width: 60,
      //   filterable: false,
      //   sortable: false,
      //   groupable: false,
      //   editable: false,
      //   cellclassname: function (row, column, value, data) {
      //     // return data.take_in  = ViewImportAcceptanceComponent.convertUTCDateToLocalDate(value);
      //   },
      //   cellsrenderer: function (
      //     row,
      //     columnfield,
      //     value,
      //     defaulthtml,
      //     columnproperties,
      //     columnData
      //   ) {
      //     // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
      //     return (
      //       "<div style='margin-top:  8.5px; margin-left: 20px;'><a  href='edit:" +
      //       columnData.uid +
      //       "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
      //       columnData.uid +
      //       "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a><div>"
      //     );
      //   },
      //   // renderer: this.columnsrenderer,
      // },
  
      {
        text: "Pdf",
        datafield: "cargo_pdf",
        width: 50,
        filterable: false,
        sortable: false,
        groupable: false,
        editable: false,
        cellsrenderer: this.linkrenderer,
      },
      {
        text: "Take In",
        datafield: `take_in`,
        cellsformat: 'yyyy-MM-dd H:mm',
        width:180,
        filtertype: 'range',
        editable: false,
        cellclassname: function (row, column, value, data) {
          // return data.take_in  = ViewImportAcceptanceComponent.convertUTCDateToLocalDate(value);
        },
        cellsrenderer: function (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          columnData
        ) {
       return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+ViewImportAcceptanceComponent.convertUTCDateToLocalDate(value) +"</div>")
        },
      },
      { text: "Airport Origin", datafield: "airport_origin", width: 100, editable: false },
      {
        text: "Consignee Name",
        datafield: "consigneeName",
        width: 130,
        editable: false,
        cellclassname: function (row, column, value, data) {},
        cellsrenderer: function (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          columnData
        ) {},
      },
      { text: "Storage Location", datafield: "cargo_acc_location", width: 120, editable: false },
      { text: "Total Pieces", datafield: "total_pieces", width: 100, editable: false },
      { text: "Total Weight", datafield: "total_weight", width: 100, editable: false },
      // { text: "Adress Label", datafield: "address_lable", width: 100, editable: false },
      { text: "Actual Pieces", width: 100, editable: false },
      { text: "Actual Weight", width: 100, editable: false },
      { text: "Pieces Out", width: 100, editable: false },
      { text: "Pieces Stock", width: 100, editable: false },
      { text: "Total HAWB's",datafield: "total_hawbs", width: 100, editable: false },
      
    ];

    rendergridrows = (params: any): any => {
      return params.data;
    };
  
    onCellBeginEdit(event: any): void {
      let args = event.args;
      let columnDataField = args.datafield;
      let row = args.row;
      let cellValue = args.value;
    }
    onCellEndEdit(event: any): void {
      let args = event.args;
      let columnDataField = args.datafield;
      let row = args.row;
      let cellValue = args.value;
      let oldValue = args.oldvalue;
      if (!oldValue && !cellValue) {
      } else if (cellValue && oldValue) {
        if (cellValue === oldValue) {
        } else {
          let obj;
          let OrgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
          if(columnDataField === 'shipment_number'){
            obj = {
              loginUserId: OrgDetails.userId,
              cargoId: row.id,
              shipment_number: cellValue,
              updatedOn : this.convertToIso(new Date()),
              flag: 'sn'
            };
          } else{
            obj = {
              loginUserId: OrgDetails.userId,
              cargoId: row.id,
              storageLocation: cellValue,
              updatedOn : this.convertToIso(new Date()),
              flag: 'sl'
            };
          }
         
         
          this.cargoAcceptanceService
            .updateStorageLocation(obj)
            .subscribe((resp) => {
              this.slResp = resp;
              if (this.slResp.responseCode === 1) {
                if(columnDataField === 'shipment_number'){
                  this.toastr.success(
                    `Shipment Number updated successfully`
                  );
                }else{
                  this.toastr.success(
                    `Storage Location updated successfully`
                  );
                }
                // this.toastr.success(
                //   `${oldValue} to ${cellValue} ${this.slResp.message}`
                // );
                let selectedTab = JSON.parse(
                  localStorage.getItem("selected_Shipments_Tab")
                );
                if (selectedTab !== null) {
                  this.OnChangeReports_Tab(selectedTab);
                } else {
                  this.OnChangeReports_Tab("A");
                }
              }
            });
        }
      }
    }
  
    onRowDoubleClick(rowData) {
      // this.ViewReportsData.shipmentId = rowData.args.row.bounddata.id;
      // this.ViewReportsData.organizationId = rowData.args.row.bounddata.org_id;
      
      let obj = {
        shipmentId : rowData.args.row.bounddata.id,
        organizationId : rowData.args.row.bounddata.org_id
      }
      localStorage.setItem("CARGO_Acceptance_STORAGE",JSON.stringify(obj));
      // this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance-details");
    }
  
    fn_To_Archive() {
      if (this.selectedRowsData.length !== 0) {
        this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
        let inputObj = {
          loginUserId: this.logindet.userId,
          orgId: this.orgDetails.orgId,
          cargoId: [],
          updatedOn: this.convertToIso(new Date())
        };
        for (let i = 0; i < this.selectedRowsData.length; i++) {
          inputObj.cargoId.push(this.selectedRowsData[i].id);
        }
        this.spinner.show();
        this.cargoAcceptanceService
          .api_To_Archive_cargoacceptance(inputObj)
          .subscribe(
            (responseData) => {
              this.spinner.hide();
              this.CargoACCResp = responseData;
              if (this.CargoACCResp.responseCode === 1) {
                this.toastr.success(this.CargoACCResp.message);
                this.myGrid.gotopage(0);
                this.myGrid.updatebounddata("refresh");
                this.myGrid.clearselection();
              } else {
                this.toastr.error(this.CargoACCResp.message);
              }
            },
            (error) => {
              this.spinner.hide();
            }
          );
      } else {
        this.toastr.error("Please select atleast one record");
      }
    }
  
    public selectedRowsData = [];
    _onChangeRowSelection(event) {
      // trigger when checkbox checked / unchecked
      this.isSlectALL_records = false;
      if (Array.isArray(event.args.rowindex)) {
        if (event.args.rowindex.length !== 0) {
          this.selectedRowsData = ViewImportAcceptanceComponent.TOTAL_GRIDDATA.filter(item=>item);
          this.isSlectALL_records = true;
        } else {
          this.selectedRowsData = [];
        }
      } else {
        if (event.type === "rowunselect") {
          let index = this.selectedRowsData.findIndex(
            (x) => x.id === event.args.row.id
          );
          this.selectedRowsData.splice(index, 1);
        } else {
          this.selectedRowsData.push(event.args.row);
        }
      }
     
    }

  OnClickCargoDamage(){
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule",JSON.stringify("CARGODAMAGE-FORWARDER"))
      this.route.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder'); 
    }, 200);
  }
   //redirect to Uld damage reports
   onClickCargoClaim_Reports(){
    localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
    this.route.navigateByUrl('/cargoClaim/dashboard/claim-count');
  }
  // redirect to handler cargo image
  onClickCargo_Image(){
    localStorage.setItem("currentModule",JSON.stringify("cargoImage"))
    this.route.navigateByUrl('/CargoImage/view-shipment');
  }
  OnClickTruckInspection() {
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
      this.route.navigateByUrl('/Truckinspection/view-truck-inspection');
    }, 200);
  }

  onExportClick(): void {
    let deleteColumns = ['actions_e','cargo_pdf','org_id','uid','boundindex','uniqueid','visibleindex','id',"cargo_acc_location","cargoImages"];
    let reportsArray = [];
    // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
    reportsArray = this.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
   let columnsData = this.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
           return (!deleteColumns.includes(item.datafield)) ;
   });  //Excel columns Header And width obj array
    for (let i = 0; i < reportsArray.length; i++) { 
      reportsArray[i]['take_in'] = ViewImportAcceptanceComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
      let imgTempArray = [];
      if(reportsArray[i]['cargoImages'] !== undefined){
        if(reportsArray[i]['cargoImages'].length !== 0){
          for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
            imgTempArray.push({
              image : ViewImportAcceptanceComponent.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
             
            })
          }
        }
      }
      
        reportsArray[i]['Images'] = imgTempArray;
      //  columnsData.push("Images");
      for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
          delete  reportsArray[i][deleteColumns[j]];
      }
    }
    this.excelService.exportAsExcelFile(reportsArray,`cargoAcceptance_${(new Date()).toString().substring(0,24)}_page_${this.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
  }
  
  scaleColumnWidth(width:any,scale :number =10){
    return (width / scale)
  }
  
  convertDate(dateTxt: string) {
    let date = new Date(dateTxt);
    return date.getFullYear() + "/" + ((date.getMonth() + 1).toString().length === 1 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "/" + ((date.getDate()).toString().length === 1 ? ("0" + (date.getDate())) : (date.getDate())) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  
  static convertUTCDateToLocalDate(date1){
    let dt = this.utcToLocal(date1, moment.tz.guess());
    return  dt === "Invalid Timezone Operator" ? date1 : dt;
  }
  static utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
    var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
    var localDateTime
    var hours = zoneValue.split(":")[0]
    var minutes = zoneValue.split(":")[1]
    if (operator === "-") {
        localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else if (operator) {
        localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else {
        localDateTime = "Invalid Timezone Operator"
    }
    return localDateTime
  }
  
  convertToIso(date: Date){
    let dt  = date.toISOString();
    return   dt.substring(0,10)+" "+ dt.substring(11,19);
  }
  onClick_Create_CargoAcceptance() {
    localStorage.setItem("CARGO_Import_Acceptance_From",JSON.stringify("Create"));
    this.router.navigateByUrl('/CargoAcceptance/create-import-acceptance') ;
  }
  
  removeDuplicates(array)  {
      let uniq = {};
      return array.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
  }
  
  fn_Check_OrUncheckslectedrows_OnSearch(){
    if (this.selectedRowsData.length !== 0) {
      //need for logic :: -- when we select records and apply filter / search then the list by default coming with selected checkboxes irrespective of selected records present or not.
      setTimeout(() => { //----- uncheck the checkboxes when the record is not present in selected list after filter applied
        let checkboxesRefArray = document.getElementsByClassName("jqx-widget jqx-checkbox");
        if (checkboxesRefArray.length !== 0) {
          let selectedRows_copy = [];
          selectedRows_copy = this.selectedRowsData.filter(item => {
            return item;
          });
          if (selectedRows_copy.length !== 0) {
            this.myGrid.clearselection();
            for (let i = 0; i < checkboxesRefArray.length; i++) {
              let rowData = this.myGrid.getrowdata(i);
              if(rowData){
                for (let j = 0; j < selectedRows_copy.length; j++) {
                  if (selectedRows_copy[j]['id'] === rowData['id']) {
                    this.myGrid.selectrow(i)
                  }
                }
              }
            }
          }
          this.selectedRowsData = selectedRows_copy;
        
        }
        
      }, 2000);
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
    } else {
      this.spinner.hide();
    }
  };

  onExportAsXmlClick():void {
    let deleteColumns = ['actions_e','cargo_pdf','org_id','uid','boundindex','uniqueid','visibleindex','id',"cargo_acc_location","cargoImages"];
    let reportsArray = [];
    reportsArray = this.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
    
    for (let i = 0; i < reportsArray.length; i++) { 
      reportsArray[i]['take_in'] = ViewImportAcceptanceComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
      let imgTempArray = [];
      if(reportsArray[i]['cargoImages'] !== undefined){
        if(reportsArray[i]['cargoImages'].length !== 0){
          for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
            imgTempArray.push({
              image : ViewImportAcceptanceComponent.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
              
            })
          }
        }
      }
      
      reportsArray[i]['Images'] = imgTempArray;
      for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
          delete  reportsArray[i][deleteColumns[j]];
      }
    }
    let strXMl = this.OBJtoXML({ reportsData : {report : reportsArray}});
    let formattedXML = this.formatXml('<?xml version="1.0" encoding="UTF-8"?>'+strXMl,'\t');
    var file = new File([formattedXML], `cargoAcceptance_${(new Date()).toString().substring(0,24)}_page_${this.myGrid.getpaginginformation().pagenum+1}`, {type: "text/xml"});
    FileSaver.saveAs(file);
  }

  formatXml(xml : string, tab : string) { // tab = optional indent value, default is tab (\t)
    var formatted = '', indent= '';
    tab = tab || '\t';
    xml.split(/>\s*</).forEach(function(node) {
        if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^\/]$/ )) indent += tab;              // increase indent
    });
    return formatted.substring(1, formatted.length-3);
  };

  OBJtoXML(obj:Object) :string {
    var xml = '';
    for (var prop in obj) {  // loop through the object properties
        if (obj[prop] instanceof Array) {    // If  object prop is array
            for (var array in obj[prop]) {
                xml += '<' + prop + '>';
                xml += this.OBJtoXML(new Object(obj[prop][array]));
                xml += '</' + prop + '>';
            }
        } else { // If  object prop is Not array
            xml += '<' + prop + '>';
            typeof obj[prop] == 'object' ? xml += this.OBJtoXML(new Object(obj[prop])) : xml += obj[prop]; // If  object prop is object then recalling the xml fun else concating the string 
            xml += '</' + prop + '>';
        }
    }
    var xml =  xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml;
  };

  onClickCargoAcceptance_Reports(){
    localStorage.setItem("currentModule",JSON.stringify("CargoAcceptance"))
    this.route.navigateByUrl('/CargoAcceptance/view-cargo-acceptance');
  }

}
