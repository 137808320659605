import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from "../../services/user/user.service"
declare var jQuery: any;
@Component({
    selector: 'app-useraccess',
    templateUrl: './useraccess.component.html',
    styleUrls: ['./useraccess.component.css']
})
export class UseraccessComponent implements OnInit {
    LoginObj: any;
    searchItem: any;
    stationsList = [];
    AccessList = [];
    UnAccessList = [];
    selectedIndex: number;
    stationCode: any;
    orgId: any;
    usersListTemp: any = [];
    removeAccessObj: any = [];
    finalObj: any;
    giveAccessObj: any = [];
    giveAccessObjTemp: any = [];
    finalremoveAccessObj: { "accessType": string; "usersList": []; };
    index: any;
    giveAccesstoUserObj: { "accessType": string; "usersList": any; };


    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 15;
    unauthlowValue = 0;
    unauthhighValue = 10;


    addaccess = 1;
    unauthpagesIndex = 0;
    addaccesspageSize = 15;
    addlowValue = 0;
    addhighValue = 10;
    public static stationsListFromService;
    public static UnAccessListFromService;
    public static AccessListFromService;
    searchfirstname: any;
    searchdeletename: string;
    constructor(private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private superAdminservice: SuperadminService,
        private spinner: NgxSpinnerService,
        private UserService: UserService,
    ) { }

    ngOnInit() {
        
        this.selectedIndex = 2;
        this.spinner.show();
        this.LoginObj = JSON.parse(localStorage.getItem('loginresultobject'));
        this.superAdminservice.getStationsList(this.LoginObj.orgId).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
               
                this.spinner.hide();
                this.stationsList = data.stationsList;
                UseraccessComponent.stationsListFromService = data.stationsList;
                this.setRow(0, this.stationsList[0]);

                if (JSON.parse(localStorage.getItem('tabselect')) === '2') {
                    jQuery('.nav-tabs a[href="#delete_access"]').tab('show');
                }

            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
                UseraccessComponent.stationsListFromService = [];
                

            }
        },
            error => {
                this.spinner.hide();
                UseraccessComponent.stationsListFromService = [];
               

            })
    }
    setRow(_index: number, item) {
        
         this.removeAccessObj = [];
         this.giveAccessObjTemp = [];
        this.selectedIndex = _index;
        this.LoginObj = JSON.parse(localStorage.getItem('loginresultobject'));
        this.stationCode = item.portCode,
            this.orgId = this.LoginObj.orgId
        this.spinner.show();
        this.superAdminservice.getAccessAndunAccessList(this.orgId, this.stationCode).subscribe(data => {
            console.log(data);
            this.spinner.hide();
            this.AccessList = [];
            this.UnAccessList = [];
            if (data.responseCode === 1) {
               
                this.spinner.hide();
                this.AccessList = data.accessedusersList;
                UseraccessComponent.AccessListFromService = data.accessedusersList;
                this.UnAccessList = data.unAccessedUsersList;
                UseraccessComponent.UnAccessListFromService = data.unAccessedUsersList;
                console.log(this.AccessList, this.UnAccessList);
                
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
              

                UseraccessComponent.UnAccessListFromService = [];
                UseraccessComponent.AccessListFromService = [];
            }
        },
            error => {
                this.spinner.hide();
              
                UseraccessComponent.UnAccessListFromService = [];
                UseraccessComponent.AccessListFromService = [];

            })
    }

    giveAccess(event, item) {
        
        if (event.target.checked === true) {
            
            let accesObj = {
                "userId": item.user_key,
            }
            this.giveAccessObjTemp.push(accesObj);
        } else if (event.target.checked === false) {
            
            console.log('before uncheck', this.giveAccessObjTemp);
            for (let i = 0; i < this.giveAccessObjTemp.length; i++) {
                
                if (this.giveAccessObjTemp[i].userId === item.user_key) {
                    
                    this.giveAccessObjTemp.splice(i, 1);
                    console.log('after uncheck', this.giveAccessObjTemp);
                }
            }
        }
        console.log('%c%s', 'color: #2fff06', 'giveAccessObj', this.giveAccessObjTemp);

    }
    removeAccess(event, item) {
        
        if (event.target.checked === true) {
            let accesObj = {
                "userId": item.user_key,
            }
            this.removeAccessObj.push(accesObj)
            console.log('checked final', this.removeAccessObj);
        } else if (event.target.checked === false) {
            for (let i = 0; i < this.removeAccessObj.length; i++) {
                
                if (this.removeAccessObj[i].userId === item.user_key) {
                    
                    this.removeAccessObj.splice(i, 1);
                    console.log('after uncheck', this.removeAccessObj);
                }
            }
        }
    }

    userRemoveAccess() {
        
        jQuery('#removeAccessPopUp').modal('hide');
        if (this.removeAccessObj.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 });
        } else {
            this.finalremoveAccessObj = {
                "accessType": "N",
                "usersList": this.removeAccessObj
            }
            this.spinner.show();
            this.UserService.userAccess(this.finalremoveAccessObj).subscribe(data => {
                
                this.spinner.hide();

                if (data.responseCode === 1) {
                    jQuery('#removeAccessPopUp').modal('hide');
                    this.toastr.success(data.message, '', { timeOut: 1000 })
                    this.pageReload();
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#removeAccessPopUp').modal('hide');
                } 
                 else {
                    jQuery('#removeAccessPopUp').modal('hide');
                    this.toastr.error(data.message, '', { timeOut: 1000 })
                }
                error => {
                    jQuery('#removeAccessPopUp').modal('hide');
                    console.log(error)
                    this.toastr.error(error.message, '', { timeOut: 1000 })
                    this.spinner.hide();
                  
                }

            });
        }
    }

    giveAccessToUser() {
        
        jQuery('#giveAccessPopUp').modal('hide');
        if (this.giveAccessObjTemp.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 });
        } else {
            this.giveAccesstoUserObj = {
                "accessType": "Y",
                "usersList": this.giveAccessObjTemp
            }
            this.spinner.show();
            this.UserService.userAccess(this.giveAccesstoUserObj).subscribe(data => {
                
                this.spinner.hide();
                if (data.responseCode === 1) {
                    jQuery('#giveAccessPopUp').modal('hide');
                    this.toastr.success(data.message, '', { timeOut: 1000 })
                    this.pageReload();
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#giveAccessPopUp').modal('hide');
                } 
                else {
                    jQuery('#giveAccessPopUp').modal('hide');
                    this.toastr.error(data.message, '', { timeOut: 1000 })
                } error => {
                    jQuery('#giveAccessPopUp').modal('hide');
                    this.toastr.error(error.message, '', { timeOut: 1000 })
                    this.spinner.hide();
                  

                }
            });
        }
    }
    userRemoveAccessDeselect() {
        
        if (this.removeAccessObj.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 })
        } else {
            this.removeAccessObj = [];
            this.pageReload();
        }
        jQuery('.nav-tabs a[href="#delete_access"]').tab('show');

    }

    tabcall(dataval) {
        
        localStorage.setItem('tabselect', JSON.stringify(dataval));
    }

    giveAccessToUserDeselect() {
        
        if (this.giveAccessObjTemp.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 })
        } else {
            this.giveAccessObjTemp = [];
            this.pageReload();
        }
        jQuery('.nav-tabs a[href="#delete_access"]').tab('show');

    }

    pageReload() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/useraccess"]));
    }

    userAccessNavigation() {
        
        this.router.navigate(["/reportaccess"])
    }


    giveAccessPop() {
        
        if (this.giveAccessObjTemp.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 });
        } else {
            jQuery('#giveAccessPopUp').modal({ backdrop: 'static', keyboard: false });
        }

    }

    removeAccessPop() {
        
        if (this.removeAccessObj.length === 0) {
            this.toastr.error('Select any one user', '', { timeOut: 1000 });
        } else {
            jQuery('#removeAccessPopUp').modal({ backdrop: 'static', keyboard: false });
        }

    }



    searchstationcode(searchValue) {
        
        console.log(this.searchItem);
        if (searchValue != '') {
            this.stationsList = UseraccessComponent.stationsListFromService;// for every time reassigning service
            this.stationsList = this.stationsList.filter((item) => item.portCode.toLowerCase().startsWith(searchValue))
        } else {
            this.stationsList = UseraccessComponent.stationsListFromService;
        }
    }


    onClearSearch() {
        this.stationsList = UseraccessComponent.stationsListFromService;
        this.searchItem = '';
    }



    searchusername(searchusername) {
        
        console.log(this.searchfirstname);
        if (searchusername != '') {
            this.UnAccessList = UseraccessComponent.UnAccessListFromService;// for every time reassigning service
            this.UnAccessList = this.UnAccessList.filter((item) => item.first_Name.toLowerCase().startsWith(searchusername))
        } else {
            this.UnAccessList = UseraccessComponent.UnAccessListFromService;
        }
    }


    onClearfirstname() {
        this.UnAccessList = UseraccessComponent.UnAccessListFromService;
        this.searchfirstname = '';
    }





    searchdeleteusername(searchdeletenames) {
        
        console.log(this.searchdeletename);
        if (searchdeletenames != '') {
            this.AccessList = UseraccessComponent.AccessListFromService;// for every time reassigning service
            this.AccessList = this.AccessList.filter((item) => item.first_Name.toLowerCase().startsWith(searchdeletenames))
        } else {
            this.AccessList = UseraccessComponent.AccessListFromService;
        }
    }


    onCleardeletename() {
        this.AccessList = UseraccessComponent.AccessListFromService;
        this.searchdeletename = '';
    }



}
