import { Component, OnInit } from "@angular/core";
import { StationService } from "../../services/stations/stations.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormGroupDirective,
  AbstractControl,
  FormArray,
} from "@angular/forms";
declare var jQuery: any;
@Component({
  selector: "app-editstations",
  templateUrl: "./editstations.component.html",
  styleUrls: ["./editstations.component.css"],
})
export class EditstationsComponent implements OnInit {
  Logindetails: any;
  editStationForm: FormGroup;
  viewData: any;
  handlerId: any;
  handlerName: any;
  emailsObj: any;
  StationCode: any;
  contactName: any;
  address: any;
  secondAddress: any;
  City: any;
  Country: any;
  Postalcode: any;
  longitude: any;
  latitude: any;
  orgId: any;
  emailIdNums: any;
  getDetailsData: any;
  emailIdsWithoutObj: any;
  emailId_Index: any;
  delete_Email_Index: any;
  isSubmitEnable: boolean;
  isEnable: boolean;
  stationCodeCheck: string;
  CompanyName: any;
  emails_As_Sytring: any;
  Splitted_Emails: any[];
  // CompanyLogoimgurl: any;
  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private stationService: StationService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute
  ) {
    // this.stationCodeCheck = '';
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.fn_toCreateForm();
    this.Logindetails = JSON.parse(localStorage.getItem("loginDetails"));
    this.activeRoute.queryParams.subscribe((params) => {
      params;
      this.handlerId = params["handlerId"];
      this.StationCode = params["stationCode"];
      this.contactName = params["handlerName"];
      this.address = params["address"];
      this.secondAddress = params["secondAddress"];
      this.orgId = params["orgId"];
      this.emails_As_Sytring = params["emailId"];
      this.editStationForm.controls["stationCode"].patchValue(this.StationCode);
      this.editStationForm.controls["handlerName"].patchValue(this.contactName);
      this.editStationForm.controls["address"].patchValue(this.address);
      this.editStationForm.controls["secondAddress"].patchValue(
        params["address2"]
      );
      this.editStationForm.controls["city"].patchValue(params["city"]);
      this.editStationForm.controls["country"].patchValue(params["country"]);
      this.editStationForm.controls["postalcode"].patchValue(
        params["postalCode"]
      );
      this.editStationForm.controls["latitude"].patchValue(params["lattitude"]);
      this.editStationForm.controls["longitude"].patchValue(
        params["longitude"]
      );
    });
    this.Splitted_Emails = [];
    if (this.emails_As_Sytring != "") {
      this.Splitted_Emails = this.fn_toSplit_Emails(this.emails_As_Sytring);
    }
    for (let i = 1; i < this.Splitted_Emails.length; i++) {
      this.add_email_Field();
    }
    const controlArray = <FormArray>this.editStationForm.get("editemails");
    for (let i = 0; i < this.Splitted_Emails.length; i++) {
      // this.emailIdNums.push(this.Splitted_Emails[i].id)
      controlArray.controls[i].get("emailid").setValue(this.Splitted_Emails[i]);
    }
    let val = {
      handlerId: this.handlerId,
      orgId: this.orgId,
    };
    this.getDeatil(val);
    this.editStationForm.controls["editemails"].valueChanges.subscribe(
      (value) => {
        value;
        this.fn_To_Enable_Submit();
      }
    );
  }
  fn_toCreateForm() {
    let EMAILPATTERN = /(?!.\.{2})^([a-z\d!#$%&@'+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    this.editStationForm = this.formBuilder.group({
      handlerName: new FormControl(),
      stationCode: new FormControl([Validators.required]),
      address: new FormControl(),
      secondAddress: new FormControl(""),
      city: new FormControl(),
      country: new FormControl(),
      postalcode: new FormControl(""),
      latitude: new FormControl(),
      longitude: new FormControl(),
      editemails: this.formBuilder.array([
        this.formBuilder.group({
          emailid: new FormControl("", [
            Validators.pattern(EMAILPATTERN),
          ]),
        }),
      ]),
    });
  }
  fn_toSplit_Emails(inputString) {
    let val = [];
    val = inputString.split(",");
    return val;
  }
  editStation(formData) {
    formData.handlerId = this.handlerId;
    formData.orgId = this.orgId;
    let value = [];
    let oldEmailsArray = [];
    let newEmailsArray = [];
    value = formData.editemails.splice(0, this.emailsObj.length);
    for (const iterator of value) {
      oldEmailsArray.push(iterator.emailid);
    }
    for (const iterator of formData.editemails) {
      newEmailsArray.push(iterator.emailid);
    }
    formData.newEmails = newEmailsArray;
    formData.editemails = oldEmailsArray;
    formData.emailIdnumber = this.emailIdNums;
    formData.modifiedBy = this.Logindetails.userId;
    // formData.modifiedBy = this.getDetailsData.modifiedBY;
    formData;
    this.spinner.show();
    this.stationService.EditStations(formData).subscribe(
      (data) => {
        data;
        this.spinner.hide();
        if (data.status == "1") {
          this.toastr.success("Successfully updated", "", { timeOut: 1500 });
          this.router.navigateByUrl("/cargoClaim/viewstation");
          // this.router.navigate(['/viewstations'], { queryParams: { from: 'ViewUsersStations' } });
        } else {
          this.toastr.error(data.message, "", {});
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  gotoback() {
    this.router.navigateByUrl("/cargoClaim/viewstation");
    // this.router.navigate(['/viewstations'], { queryParams: { from: 'ViewUsersStations' } });
  }
  get emailsData() {
    return this.editStationForm.get("editemails") as FormArray;
  }
  add_email_Field() {
    let EMAILPATTERN = /(?!.\.{2})^([a-z\d!#$%&@'+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    this.emailsData.push(
      this.formBuilder.group({
        emailid: new FormControl("", [
          Validators.required,
          Validators.pattern(EMAILPATTERN),
        ]),
      })
    );
  }
  delete_email_Field(index, emailId) {
    this.emailIdsWithoutObj = [];
    this.emailId_Index = index;
    if (this.emailsData.length > 1) {
      for (const each of this.Splitted_Emails) {
        this.emailIdsWithoutObj.push(each);
      }
      let val = this.emailIdsWithoutObj.indexOf(emailId);
      if (val >= 0 && index < this.emailsObj.length) {
        // let email = ;
        this.delete_Email_Index = this.emailsObj[index].id;
        jQuery("#delete-confirmation").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        jQuery("#delete-newEmails").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    }
  }
  delete_newleyEnteredEmail() {
    this.emailsData.removeAt(this.emailId_Index);
    jQuery("#delete-newEmails").modal("hide");
  }
  deletePreviousMails() {
    this.spinner.show();
    this.stationService
      .deleteIndividual({
        airlineId: this.delete_Email_Index,
        orgId: this.orgId,
      })
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.toastr.success(data.message, "", {
              timeOut: 500,
            });
            this.emailsData.removeAt(this.emailId_Index);
            this.emailIdNums.splice(this.emailId_Index, 1);
            this.emailsObj.splice(this.emailId_Index, 1);
            // AddAirlineComponent.defaultEmailsLength =  this.emailsObj.length;
            jQuery("#delete-confirmation").modal("hide");
          } else {
            this.toastr.success(data.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  getDeatil(handlerId) {
    this.stationService.getDeatil(handlerId).subscribe((data) => {
      if (data.responsecode == "1") {
        this.emailsObj = [];
        this.emailsObj = data.emails;
        this.emailIdNums = [];
        this.getDetailsData = data;
        for (let i = 0; i < this.emailsObj.length; i++) {
          this.emailIdNums.push(this.emailsObj[i].id);
        }
      }
    });
  }
  getEmailIds(form): Array<any> {
    return form.controls.editemails.controls;
  }
  findDuplicate(emailid, p): boolean {
    let myArray = this.getEmailIds(this.editStationForm);
    let totalArray = [];
    if (emailid != "" && emailid != null && emailid != undefined) {
      for (let i = 0; i < myArray.length; i++) {
        totalArray.push(myArray[i].value);
      }
      this.fn_To_Enable_Submit();
      let test = totalArray.filter(
        (data) => data.emailid == emailid && emailid != ""
      );
      return test.length > 1 ? true : false;
    }
  }
  fn_To_Enable_Submit() {
    let totalArray = [];
    const controlArray = <FormArray>this.editStationForm.get("editemails");
    for (let i = 0; i < controlArray.value.length; i++) {
      totalArray.push(controlArray.value[i].emailid);
    }
    this.isSubmitEnable = this.find_duplicate_in_array(totalArray);
    this.isEnable = this.isSubmitEnable;
  }
  find_duplicate_in_array(array) {
    return new Set(array).size !== array.length;
  }
}
