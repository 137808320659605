import { Injectable } from '@angular/core';

@Injectable()
export class  ViewReportsData {
    isFrmViewDetails: string = 'no';
    isfromSideBarViewReports = 'no';
    isfromairlineCsaDashboard = 'no';
    isfromHandlerCsaDashboard = 'no';
    isfromViewReports = 'no';
    isFromViewUsersStations: string;
    isfromusersDashboard = 'no';
    isfromSidebarStations = 'no';
    isFromViewReportsStations = 'no';
    // isDashBoradSwitched = 'no';
    isFromDashboard = 'no';
    // isFrmArchievedReports:string ='yes'
    isfromHandler_Admin_Dashboard:string = 'no';
    isfromAirline_Admin_Dashboard:string = 'no';
    shipmentId:number ;
    organizationId:number ;
    shipmentIsFrom:string ;

    uldISFrom :string ;
    uldId:number ;
    uldOrganizationId:number ;
    uldReportUserId:number;

 //--truck inspection shared variables--
   //  TRUCK_INSPECTION_FROM="CREATE";
   //  TRUCK_INSPECTION_ID="";
    TRUCK_INSPECTION_CURRENTTAB="A";

 //--Cargo damage forwarder shared variables--
    CARGODAMGE_FROM="CREATE";
    CARGODAMGE__ID="";
    CARGODAMGE_CURRENTTAB="A";


}