import { Component, OnInit } from "@angular/core";
import { LoginServicesService } from "../services/login/login-services.service";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
declare var jQuery: any;
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  emailId: string;
  passwordForm: FormGroup;
  Logindetails: any;
  orgId: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private loginservice: LoginServicesService
  ) {}
  ngOnInit() {
    //password validation
    window.scroll(0, 0);
    this.passwordForm = new FormGroup({
      emailId: new FormControl(null, [Validators.required, Validators.email]),
    });
  }
  // forgotpassword Api integration
  sendEmail(forgotObj) {
    this.spinner.show();
    this.loginservice.forgotPassword(forgotObj).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.resposeCode === 1) {
          jQuery("#forgotpasswordpopup").modal("hide");
          jQuery("body").removeClass("modal-open");
          jQuery(".modal-backdrop").remove();
          this.toastr.success(data.message);
          this.router.navigate(["/"]);
        } else {
          this.toastr.error(data.message);
        }
      },
      (error) => {
        alert("Unable to verify email id ");
      }
    );
  }
}
