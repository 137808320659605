import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CargoAcceptanceRoutingModule } from './cargo-acceptance-routing.module';
import { CreateCargoacceptanceComponent } from './create-cargoacceptance/create-cargoacceptance.component';
import { ViewCargoacceptanceComponent } from './view-cargoacceptance/view-cargoacceptance.component';
import { ViewCargoacceptanceDetailsComponent } from './view-cargoacceptance-details/view-cargoacceptance-details.component';
import { SharedLayoutsModule } from '../layouts/shared-layouts/shared-layouts.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatSelectModule, MatListModule, MatToolbarModule, MatSidenavModule, MatStepperModule, MatCardModule, MatExpansionModule, MatAutocompleteModule } from '@angular/material';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DxDataGridModule } from 'devextreme-angular';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule } from '@angular/forms';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { ViewImportAcceptanceComponent } from './view-import-acceptance/view-import-acceptance.component';
import { CreateImportAcceptanceComponent } from './create-import-acceptance/create-import-acceptance.component';


@NgModule({
  declarations: [CreateCargoacceptanceComponent, ViewCargoacceptanceComponent, ViewCargoacceptanceDetailsComponent, ViewImportAcceptanceComponent, CreateImportAcceptanceComponent],
  imports: [
    CommonModule,
    CargoAcceptanceRoutingModule,
    SharedLayoutsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    DxDataGridModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
    MatRadioModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    jqxButtonModule,
    jqxNumberInputModule,
    jqxInputModule,
    jqxWindowModule

  ],
  bootstrap: [CreateCargoacceptanceComponent]
})
export class CargoAcceptanceModule { }
