import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateGuard implements CanActivate {
 constructor( private spinner: NgxSpinnerService,private Router: Router,private authorizationService: AuthorizationService, private toastr: ToastrService,) { }

     canActivate( route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log(loginresultobject)
        console.log("auth gurd==>",loginresultobject)
        if (loginresultobject != null) {
            const allowedRoles = route.data.allowedRoles;
            const isAuthorized = this.authorizationService.isAuthorized(allowedRoles, loginresultobject);
            if (!isAuthorized) {
                localStorage.clear();
                this.spinner.hide();
                this.toastr.error("You are not authorized to view .")
              this.Router.navigate(['/Home']);
            }
            return isAuthorized;
        } else {
            this.Router.navigate(['/Home'])
        }
    }

}


