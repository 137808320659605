import { Component, OnInit } from '@angular/core';
import { MyAccountService } from '../services/myAccount/my-account.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { Location } from '@angular/common';
import { LoginServicesService } from '../cargoClaimModule/services/login/login-services.service';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    providers:[NavbarComponent]
})
export class ResetPasswordComponent implements OnInit {
    resetForm: FormGroup;
    hide: boolean;
    changePassword: any;
    resetpassobj: any;
    submitted: boolean;
    loginresultobject: any;

    gotoresetpassword() {
        this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
        this.resetpassobj = JSON.parse(localStorage.getItem('resetpassobj'));
        this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));


        const obj = {
            "changeType": "C",
            "userId": this.resetpassobj.userId,
            "changePassword": this.changePassword,

        }
        this.spinner.show();
        this.MyAccountService.resetpassword(obj).subscribe(
            data => {
                ;
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    if( this.loginresultobject.userType === 'S' &&  this.resetpassobj.userType ==='S'){
                         this.navbarComponent.logOut();
                    }else{
                        // this.router.navigateByUrl('/viewusers')
                        this.location.back()
                      
                    }
                   
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.spinner.hide();
                    this.toastr.info("Your session is expired, please login again");
                } 
                else {
                    this.spinner.hide();
                    this.toastr.error(data.message);
                }
            }, error => {
                this.spinner.hide();
                console.log(error);
                this.toastr.error("Something went wrong");
            });
    }


    generatepass(length) {
        var pass = "";
        var smallChars = "abcdefghijklmnopqrstuvwxyz";
        var specialChar = "!@#$%^&*`~()";
        var chars = "ABCDEFGHIJKLMNOP";
        var numbers = "1234567890";
        for (var x = 0; x < 3; x++) {
            var i1 = Math.floor(Math.random() * smallChars.length);
            pass += smallChars.charAt(i1);
            var i2 = Math.floor(Math.random() * specialChar.length);
            pass += specialChar.charAt(i2);
            var i3 = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i3);
            var i4 = Math.floor(Math.random() * numbers.length);
            pass += numbers.charAt(i4);
        }
        this.changePassword = pass;

    }


    gotoviewuser() {
        ;
        this.router.navigateByUrl('/viewusers');
    }
    navigateCancel(){
        this.location.back()
    }
    
    
  emailId: string;
  passwordForm: FormGroup;
  Logindetails: any;
  orgId: any;
  constructor(
    private loginservice: LoginServicesService,
    private location: Location,
    private navbarComponent:NavbarComponent,
    private MyAccountService: MyAccountService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router) { }

    regex = /(?=.*[_!@#$%^&*-+=~`()<>])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])^.{12,}$/;


  ngOnInit() {
    this.resetpassobj = JSON.parse(localStorage.getItem('resetpassobj'));
    //password validation
    window.scroll(0, 0);
    this.passwordForm = new FormGroup({
      emailId: new FormControl(this.resetpassobj.loginID, [Validators.required, Validators.email]),
    });
    
        //--validations--//

        this.resetForm = new FormGroup({
          changePassword: new FormControl('', 
          [Validators.required, Validators.pattern(this.regex)]),

      });
  }
  // forgotpassword Api integration
  sendEmail(forgotObj) {
    this.spinner.show();
    this.loginservice.forgotPassword(forgotObj).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.resposeCode === 1) {
          jQuery("#forgotpasswordpopup").modal("hide");
          jQuery("body").removeClass("modal-open");
          jQuery(".modal-backdrop").remove();
          this.toastr.success(data.message);
          this.router.navigate(["/"]);
        } else {
          this.toastr.error(data.message);
        }
      },
      (error) => {
        alert("Unable to verify email id ");
      }
    );
  }

}   
   