import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigServiceService } from 'src/app/config-service.service';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-view-detailed-record',
  templateUrl: './view-detailed-record.component.html',
  styleUrls: ['./view-detailed-record.component.css']
})
export class ViewDetailedRecordComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
