import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
   webAPI :string;
  constructor(private http: HttpClient,config : ConfigServiceService) { 
    this.webAPI =    config.getConfig().URL
  }


   sendRegisterationOtp(emailId) {
        
        console.log(emailId);
        return this.http.post<any>(this.webAPI + "cclpRegistration.php/sendOtpRegister", { emailId });
    }

    checkEmail(obj) {
        
        return this.http.post<any>(this.webAPI + 'cclpRegistration.php/checkEmail', obj);
    }

    companyTypecheck(obj){
        
        return this.http.post<any>(this.webAPI + 'cclpRegistration.php/companyTypecheck', obj);
    }

    registration(obj){
        
        return this.http.post<any>(this.webAPI + 'cclpRegistration.php/registrationCclp', obj);
    }
}
