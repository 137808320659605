import { Component, OnInit } from '@angular/core';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { globalConstants } from 'src/environments/globalConstants';

declare var jQuery: any;
@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
    emailId: string;
    passwordForm: FormGroup;
    constructor(private router: Router,private MyAccountService: MyAccountService, private toastr: ToastrService,private spinner: NgxSpinnerService) { }

    ngOnInit() {
       
        window.scroll(0, 0);
        this.passwordForm = new FormGroup({
            'emailId': new FormControl(null, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)])
        })
    }


    sendEmail(forgotObj) {
        ;
        this.spinner.show();
        this.MyAccountService.forgotPassword(forgotObj.emailId).subscribe(
            data => {
                ;
                console.log(data)
                this.spinner.hide();
                if(data.resposeCode === 1){
                 jQuery('#forgotpasswordpopup').modal('hide');
                jQuery('body').removeClass('modal-open')
               jQuery('.modal-backdrop').remove();
                this.toastr.success(data.message)
                   this.router.navigate(["/"]);
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    jQuery('#forgotpasswordpopup').modal('hide');
                    this.toastr.info("Your session is expired, please login again");
                } 
                else{
                    this.toastr.error(data.message)
                }
                
            },
            error => {
                this.toastr.error("service error occured");
               
            }
        )
    }

}
