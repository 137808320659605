import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as Excel from "exceljs/dist/exceljs.min.js";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  dateColumns: any;
  dataservice: any;
  headings: ExcelHeader[];

  constructor() { }

  exportAsExcelFile(Data: Array<any>,SheetName: String,dateColumns :Array<string> = [],columnsData:ExcelHeader[]): void {
    this.dateColumns = dateColumns;
    let exportingData: any = [];
    exportingData = Data;
    var options = {
      filename: "./workbook.xlsx",
      useStyles: true,
      useSharedStrings: true,
    };
    let workbook = new Excel.Workbook(options);
    var worksheet = workbook.addWorksheet("Sheet1", {
      properties: { tabColor: { argb: "FFFFFF" } },
    });
    // let data = Object.values(exportingData);
    // this.headings = Object.keys(Data[0]);
    this.headings = columnsData;
    for (let eachobject of exportingData) {
      let temp = [];
      for (let eachvalue of this.headings) {
        temp.push(eachobject[eachvalue['datafield']]);
      }
      worksheet.getRow(1).values = this.headings;
      worksheet.addRow(temp);
    }
    worksheet.columns = this.setColumnData();
    let datecolumns = [];
    worksheet._columns.forEach((item) => {
      if (item["_key"] && this.dateColumns.includes(item["_key"])) {
        datecolumns.push(item._number);
      }
    });
    datecolumns.forEach((item, index) => {
      const dobCol = worksheet.getColumn(item);
      dobCol.eachCell((cell) => {
        cell.numFmt = "mm/dd/yyyy\\ hh:mm:ss";       
      });
    });
    // styling start
    worksheet.views = [{ state: "frozen", ySplit: 1 }];
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.font = {
          name: "Calibri",
          family: 2,
          bold: false,
          size: 11,
        };
        cell.alignment = {
          vertical: "top",
          horizontal: "left",
        };
        // cell.border =
        row.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        if (rowNumber == 1) {
          cell.font = {
            bold: true,
            size: 11.5,
          };
        }
      });
    });
    var worksheet = workbook.getWorksheet();
    const excelBuffer: any = workbook.xlsx.writeBuffer();
    excelBuffer.then(function (buffer) {
      const data: Blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(data, SheetName);
    });
  }

  setColumnData() {
    let coulumns = [];
    this.headings.forEach((item) => {
      coulumns.push({
        key: item['datafield'],
        header: item['text'] ,
        width: item['width'],
        style: this.getColumnStyle(item),
      });
    });
    return coulumns;
  }

  getColumnStyle(item) {
    if (this.dateColumns.includes(item['datafield'])) {
      return { numFmt: "mm/dd/yyyy\\ hh:mm:ss" };
    } else {
    }
  }

  

}

export class ExcelHeader{
  public datafield:string;
  public text:string ;
  public width:number ;
}