import { Component, OnInit, enableProdMode, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../../config-service.service';
declare var jQuery: any;
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-unauthroized-users',
    templateUrl: './unauthroized-users.component.html',
    styleUrls: ['./unauthroized-users.component.css']
})
export class UnauthroizedUsersComponent implements OnInit {
    prefix: string;
    selectionChangedBySelectbox: boolean;
    selectedRows: number[];
    Obj: any;
    orgId: any;
    reportsList: any;
    usersList: any;
    finalReportArray = [];
    finalArray = [];
    ReportsProductionUrl: any;
    webapi: string;
    Object: any;
    userType: any;
    userId: any;
    isSelected: boolean;
    userIdList = [];
    dataGridInstance: any;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    constructor(
        private config : ConfigServiceService,
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService,private locationStr: LocationStrategy,
        ) {

             // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
        
    }
    ngAfterViewInit() {
        this.myGrid.refresh();
          }
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
    ngOnInit() {
        
        window.scroll(0, 0);
       
        this.webapi = this.config.getConfig().URL
        this.ReportsProductionUrl =this.config.getConfig().ReportsUrl
        this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
        this.orgId = this.Obj.orgId;
        this.spinner.show();
        this.superAdminservice.getUnauthUsers(this.orgId).subscribe(
            data => {
                
                console.log(data)
                this.spinner.hide();
                this.finalReportArray = [];
                this.reportsList = data.reportsList;
                this.usersList = data.usersList;
              
                this.finalReportArray = data.usersList;
                if (this.finalReportArray !== null && this.finalReportArray !== undefined) {
                    for (let index = 0; index < this.finalReportArray.length; index++) {
                        if (this.finalReportArray[index].reportPath) {
                            let epath = this.finalReportArray[index].reportPath;
                            let externalPath = epath.split("/");
                            this.finalReportArray[index].externalFileName = externalPath[1];
                        }
                    }
                }else{

                }
                this.source.localdata = this.finalReportArray;
                this.myGrid.source(this.dataAdapter);
                 if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
               
            },
            error => {
                this.spinner.hide();
            }
        )
    }

    source =
    {

        localdata: this.finalReportArray,
        datatype: 'json',
        datafields:
            [{ name: 'firstName', type: 'string' },
            { name: 'lastName', type: 'string' },
            { name: 'loginId', type: 'string' },
            { name: 'createdOn', type: 'date' },
            {name: 'orgName',type: 'string'},
            { name: 'stationCode',type: 'string'},
           
              ],
       // pagesize: 2,
        beforeprocessing: function (data) {
            // this.source.totalrecords = data[0].TotalRows;
        },
        pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
            // callback called when a page or page size is changed.
            console.log(pagenum);
        },
        

      
        rendergridrows: function (params) {
            return params.data;
        }
    };

    dataAdapter = new jqx.dataAdapter(this.source);

    columns =
    [
      { text: 'First Name', datafield: 'firstName',width:250, },
        { text: 'Last Name', datafield: 'lastName',width:250, },
        { text: 'Email Id', datafield: 'loginId',width:250,},
        { text: 'Created On', datafield: 'createdOn',width:250, cellsformat: 'd-MM-yyyy',filtertype: 'date'},
        { text: 'Handler Company Name', datafield: 'orgName',width:250 },
        { text: 'Station', datafield: 'stationCode',width:250,},
       
        
       
  ];

    OpenAuthPopup() {
        
        
        if (this.myGrid.getselectedrowindexes().length === 0) {
            this.toastr.error('No users to authorize');
        } else {
            jQuery('#myModalauth').modal({ backdrop: 'static', keyboard: false });
        }


    }
   

    getSelectedData() {
        
        if (this.finalReportArray.length === 0) {
            this.toastr.error('No users to authorize');
        }
        else if (this.myGrid.getselectedrowindexes().length != 0) {
           
            localStorage.setItem("eachReport", JSON.stringify(this.myGrid.getselectedrowindexes()));
            this.userIdList = [];
            for (let index = 0; index < this.myGrid.getselectedrowindexes().length; index++) {
                this.userId = this.finalReportArray[this.myGrid.getselectedrowindexes()[index]].userId;
                this.userIdList.push(this.userId);
            }
            this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
            this.userType = this.Object.userType;
            let Object = {
                userType: this.userType,
                usersList: this.userIdList
            }
            this.spinner.show();
            this.superAdminservice.setUserAuthnticate(Object).subscribe(data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.ngOnInit();
                    this.toastr.success(data.message, '', {});
                    jQuery('#myModalauth').modal('hide');
                    this.myGrid.clearselection();
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#myModalauth').modal('hide');
                    this.myGrid.clearselection();
                } 
                 else {
                    this.ngOnInit();
                    this.toastr.error(data.message, '', {});
                    jQuery('#myModalauth').modal('hide');
                    this.myGrid.clearselection();
                }
            },
                error => {
                    this.ngOnInit();
                    this.spinner.hide();
                    jQuery('#myModalauth').modal('hide');
                })
        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one user');
        }
    }
    OpenDeletePopup() {
        
        if (this.myGrid.getselectedrowindexes().length === 0) {
            this.toastr.error('No users to delete');
        } else {
            jQuery('#myModalDelete').modal({ backdrop: 'static', keyboard: false });
        }


    }
   

    getSelectedDataDelete() {
        
        if (this.finalReportArray.length === 0) {
            this.toastr.error('No users to delete');
        }
        else if (this.myGrid.getselectedrowindexes().length != 0) {
         
            localStorage.setItem("eachReport", JSON.stringify(this.myGrid.getselectedrowindexes()));
            this.userIdList = [];
            for (let index = 0; index < this.myGrid.getselectedrowindexes().length; index++) {
                this.userId = this.finalReportArray[this.myGrid.getselectedrowindexes()[index]].userId;
                this.userIdList.push(this.userId);
            }
            let Object = {
                usersList: this.userIdList
            }
            this.spinner.show();
            this.superAdminservice.deleteUnauthUser(Object).subscribe(data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.ngOnInit();
                    this.toastr.success(data.message, '', {});
                    jQuery('#myModalDelete').modal('hide');
                    this.myGrid.clearselection();
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#myModalDelete').modal('hide');
                } 
                 else {
                    this.toastr.error(data.message, '', {});
                    this.ngOnInit();
                    jQuery('#myModalDelete').modal('hide');
                }
            },
                error => {
                    this.spinner.hide();
                    this.ngOnInit();
                    jQuery('#myModalDelete').modal('hide');
                })
        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one user to delete');
        }
    }



    saveGridInstance(e) {
        
        console.log("referesh");
        console.log(this.dataGrid.instance.getSelectedRowsData());
        this.dataGridInstance = e.component;
        this.dataGridInstance.refresh();
        this.dataGridInstance.clearSelection();
        this.dataGridInstance.deselectRows();
        this.dataGridInstance.deselectAll();
        
    }
}
