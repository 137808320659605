import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { StationService } from 'src/app/services/station/station.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    VERSION,
} from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DeactivatestationemailsService } from 'src/app/services/dsc/deactivatestationemails.service';
import { Router } from '@angular/router';
import { globalConstants } from 'src/environments/globalConstants';
declare var jQuery: any;
@Component({
    selector: 'app-add-dse',
    templateUrl: './add-dse.component.html',
    styleUrls: ['./add-dse.component.css']
})
export class AddDSEComponent implements OnInit {
    createAddDSEFrom: FormGroup
    FormBuilder: any;
    stationCode: FormControl;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    stationsListObj: any;
    stationsList: any;
    Logindetails: any;
    orgDetails: any;
    constructor(private router: Router, private DeactivatestationemailsService: DeactivatestationemailsService, private formBuilder: FormBuilder, private toastr: ToastrService, private StationService: StationService, private spinner: NgxSpinnerService) {
        this.stationCode = new FormControl('', [Validators.required]);
    }

    ngOnInit() {
       
        this.createAddDSEFrom = this.formBuilder.group({
            'stationCode': new FormControl(null, [Validators.required]),
            'emailId': new FormControl(null, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)])

        });
        this.spinner.show();
        this.StationService.GetStationCodes().subscribe(
            data => {
                ;
                console.log('stations Data', data);
                this.stationsListObj = data.airport_details;
                this.stationsList = this.stationCode.valueChanges
                    .pipe(
                        startWith(''),
                        map(state => state ? this.filterStations(state) : null)
                    );
                this.spinner.hide();
            },
            error => {
                ;
                this.spinner.hide();
                console.log(error)
                this.toastr.error('Unable to fetch stations list')

            });


    }

    filterStations(code: string) {
        ;
      
        console.log('this.stationsListObj', this.stationsListObj);
        return this.stationsListObj.filter(state =>
            state.code.toLowerCase().indexOf(code.toLowerCase()) === 0);
    }

    onstationSelect(state): void {
        ;
        console.log('state', state)
        this.stationCode.setValue(state.code);
        this.stationCode.setErrors(null);
        this.createAddDSEFrom.controls['stationCode'].setValue(this.stationCode.value);
        this.createAddDSEFrom.controls['stationCode'].setErrors(null);
        this.createAddDSEFrom.updateValueAndValidity();
    }


    addDeactStationsPopUp() {
        jQuery('#Adddeactivationemail').modal({ backdrop: 'static', keyboard: false });
    }

    addDeactStations() {
      
        this.orgDetails = JSON.parse(localStorage.getItem('comapanydetails'));
        let obj = {
            "loginUserId": this.orgDetails.userId,
            "orgId": this.orgDetails.orgId,
            "stationCode": this.createAddDSEFrom.controls['stationCode'].value,
            "emailId": this.createAddDSEFrom.controls['emailId'].value
        }
        this.spinner.show();
        this.DeactivatestationemailsService.addDeactivateStationEmail(obj).subscribe(data => {
            ;
            jQuery('#Adddeactivationemail').modal('hide');
            console.log('this.DeactivatestationemailsService', data);
            if (data.responseCode === 1) {
                this.spinner.hide();
                this.toastr.success(data.message, '', { timeOut: 1200 });
                this.router.navigateByUrl('/viewdeactivatestationemail');
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            } 
            else {
                this.spinner.hide();
                this.toastr.error(data.message, '', { timeOut: 1200 });
            }
        }, error => {
             this.spinner.hide();
            this.toastr.error(error.message, '', { timeOut: 1200 })
        })

    }

}
