import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
// import { environment } from "src/environments/environment";
import { globalConstants } from "src/environments/globalConstants";
// import { ViewReportsData } from "src/app/data/DataShareViewDetails";
// import { LocationStrategy } from "@angular/common";
// import { ShipmentsService } from "src/app/services/shipments/shipments.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../config-service.service";
import { CargoAcceptanceService } from "src/app/services/cargo-acceptance/cargo-acceptance.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from 'moment';
import 'moment-timezone';
import * as FileSaver from 'file-saver';
import { SuperadminService } from "../services/superadmin/superadmin.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject } from "@angular/core";
import { FormControl, FormGroup, MaxLengthValidator, Validators } from "@angular/forms";
import { DateAndTimeFunctions } from 'src/app/shared/sharedModule/sharedFunctions/DateAndTimeConvertions';
declare var jQuery: any;

export interface DialogData {
  id: string;
  country: string;
}

@Component({
  selector: "app-airlines",
  templateUrl: "./airlines.component.html",
  styleUrls: ["./airlines.component.css"],
})
export class AirlinesComponent implements OnInit {
  @ViewChild("myGrid") myGrid: jqxGridComponent;

  response: any;
  singleDelete: string;
  newRowIndex: number;
  static webapi: string;
  static totalGridData: any;
  static aitype;
  reportsData: any;
  anchor_Tags: any;
  button_tags: any;
  editicon_Tags: any;
  isArchiveEnable: boolean = true;
  orgDetails: any;
  finalReportsCount = 0;
  loginDetails: any;
  CargoACCResp: any;
  slResp: any;
  static TOTAL_GRIDDATA: any = [];
  static IS_PAGE_SIZE_OR_PAGENUM_CHANGED: boolean = false;
  isSlectALL_records: boolean;
  isDeleteEnable: boolean=true;
  selAirlineIdarray = []
  tab_type: any;
  active_total_count: any;
  inactive_total_count: any;
  

  constructor(
    private route: Router,
    private elementRef: ElementRef,
    private spinner: NgxSpinnerService,
    private config: ConfigServiceService,
    private excelService: ExcelService,
    private superAdminService: SuperadminService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private superAdminServices: SuperadminService,
    private dateAndTimeFunc : DateAndTimeFunctions,
  ) {
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    AirlinesComponent.webapi = this.config.getConfig().ULD_Url;
    AirlinesComponent.aitype = 'A';
  }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.getData();
  }

  getData() {
    AirlinesComponent.TOTAL_GRIDDATA = [];
    this.spinner.show();
    this.superAdminService.airlines({}).subscribe(res => {
      this.CargoACCResp = res;
      this.spinner.hide();
    })
  }

  ngAfterViewChecked() {
    let selectedTab = JSON.parse(
      localStorage.getItem("selected_Shipments_Tab")
    );
    let loginData = JSON.parse(localStorage.getItem("loginresultobject"));
    if (selectedTab !== null) {
      if (selectedTab === "I") {
        this.isArchiveEnable = false;
        this.myGrid.selectionmode("none");
      } else {
        if (loginData.reportAcess === "Y") {
          this.isArchiveEnable = true;
          this.myGrid.selectionmode("checkbox");
        } else {
          this.isArchiveEnable = false;
          this.myGrid.selectionmode("none");
        }
      }
    } else {
      if (loginData.reportAcess === "Y") {
        this.isArchiveEnable = true;
        this.myGrid.selectionmode("checkbox");
      } else {
        this.isArchiveEnable = false;
        this.myGrid.selectionmode("none");
      }
    }
    
    this.button_tags = this.elementRef.nativeElement.querySelectorAll("button");
    this.button_tags.forEach((anchor: HTMLAnchorElement) => {
      if (anchor.id != "") {
          anchor.addEventListener("click", this.handleAnchorClick);
      }
    });

      //------ assigning click events to edit icon----///
      this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll("a");
      this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll("i");
      this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.href != "") {
          let href_Valid_Check_Var = anchor.href.split(":")[0];
          if (anchor.innerText === "Edit" && href_Valid_Check_Var === "edit") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
          if (anchor.innerText === "Delete" && href_Valid_Check_Var === "delete") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
          if (anchor.innerText === "Active" && href_Valid_Check_Var === "active") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
          if (anchor.innerText === "On" && href_Valid_Check_Var === "on") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
          if (anchor.innerText === "Off" && href_Valid_Check_Var === "off") {
            anchor.addEventListener("click", this.handleAnchorClick);
          }
          
        }
      });
  
      this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
        if (editicon.id != "") {
          let href_Valid_Check_Var = editicon.id.split(":")[0];
          if (href_Valid_Check_Var === "edit" || href_Valid_Check_Var === "delete" || href_Valid_Check_Var === "active" || href_Valid_Check_Var === "on" || href_Valid_Check_Var === "off" ) {
            editicon.addEventListener("click", this.handleiconClick);
          }
          if(editicon.className === "fa fa-toggle-on fa-2x"){
            editicon.style.display = "none";
            editicon.style.padding = "0 3px";
            editicon.style.fontSize = "24px";
            editicon.style.color = "blue";
          }
          if(editicon.className === "fa fa-toggle-on fa-2x fa-rotate-180"){
            editicon.style.padding = "0 3px";
            editicon.style.fontSize = "24px";
            editicon.style.color = "green";
          }
        }
      });
  }

  ngAfterViewInit() {
      this.OnChangeAirline_Tab('Active');
  }

  OnChangeAirline_Tab(type) {
    this.tab_type = type;
    AirlinesComponent.TOTAL_GRIDDATA =[];
    this.spinner.show();
    if(type === 'Active'){
      this.source.url =
      AirlinesComponent.webapi +
      "airlineApis/GetAirlineAwbList.php/airlineAwbList?" +
      "loginUserId=" + this.logindet.userId +  "&tab=A";
      AirlinesComponent.aitype = 'A';
      document.getElementById('active_airline').className = 'nav-link active';
      document.getElementById('inactive_airline').className = 'nav-link';
    }
    if(type === 'Inactive'){
      this.source.url =
      AirlinesComponent.webapi +
      "airlineApis/GetAirlineAwbList.php/airlineAwbList?" +
      "loginUserId=" + this.logindet.userId +  "&tab=I";
      AirlinesComponent.aitype = 'I';
      document.getElementById('active_airline').className = 'nav-link';
      document.getElementById('inactive_airline').className = 'nav-link active';
    }
    this.myGrid.gotopage(0);
    this.myGrid.updatebounddata("refresh");
    this.myGrid.showcolumn("actions_e");
    
  }
  
  public handleiconClick = (event: Event) => {
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.onClick_Icon(editicon);
  };
  public handleAnchorClick = (event: Event) => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    console.log(anchor);
    // this.onClick_AnchorTag(anchor);
  };

  onClick_Icon(editicon: HTMLImageElement) {
    if(editicon.title === "Edit"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      AirlinesComponent.totalGridData[href_Valid_Check_Var];
      this.editAirline(href_Valid_Check_Var);
    }
    if(editicon.title === "Delete"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      AirlinesComponent.totalGridData[href_Valid_Check_Var];
      this.deleteAirline(href_Valid_Check_Var);
    }
    if(editicon.title === "Active"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      AirlinesComponent.totalGridData[href_Valid_Check_Var];
      this.reActiveAirlineConfirm(href_Valid_Check_Var);
    }
    if(editicon.title === "On"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      AirlinesComponent.totalGridData[href_Valid_Check_Var];
      editicon.style.display = "none"
      // editicon.classList.toggle('active')
      // editicon.classList.toggle('inactive');
      // editicon.classList.remove('inactive');
      // editicon.classList.add('active');
      
      
      // $('.inactive, .active').toggle();
      // this.classList.toggle('active');
      // this.reActiveAirlineConfirm(href_Valid_Check_Var);
    }
    if(editicon.title === "Off"){
      let href_Valid_Check_Var = editicon.id.split(":")[1];
      AirlinesComponent.totalGridData[href_Valid_Check_Var];
      editicon.style.display = "initial"
      
    }
  }

  // onClick_AnchorTag(anchor: HTMLAnchorElement) {
  //   if (anchor.innerText === "Edit") {
  //     let href_Valid_Check_Var = anchor.id.split(":")[1];
  //     console.log(href_Valid_Check_Var);
  //     this.editAirline(href_Valid_Check_Var);
  //   } else if (anchor.innerText === "Delete") {
  //     let href_Valid_Check_Var = anchor.id.split(":")[1];
  //     this.deleteAirline(href_Valid_Check_Var);
  //   }
  // }

  logindet = JSON.parse(localStorage.getItem("loginresultobject"));
  url = "";
  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
  source = {
    url: this.url,
    datatype: "json",
    datafields: [
      {name: "id", type: "string"},
      { name: "airline_name", type: "string" },
      { name: "actions_e", type: "string" },
      { name: "AWB_prefix", type: "string" },
      { name: "ICAO", type: "string" },
      { name: "IATA", type: "string" },
      { name: "country", type: "string" },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "Rows",
    totalrecords: 800,
    cache: false,
    type: "GET",
    sort: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("sort");
    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("filter");
      this.spinner.show();
    },
    beforeprocessing: (data: any) => {
      this.spinner.hide();
      if (data.responseCode === 1) {
        if (data.Rows !== undefined) {
          if (data.Rows.length !== 0) {
            data.Rows = data.Rows;
            // if(this.tab_type === 'Active'){
              this.active_total_count = data.activeCount;
            // }
            // if(this.tab_type === 'Inactive'){
              this.inactive_total_count = data.inActiveCount;
            // }
            this.source.totalrecords = data.TotalRows;
            AirlinesComponent.totalGridData = data.Rows;
            if (data.activeCount != undefined && data.activeCount != null) {
              this.finalReportsCount = data.activeCount;
            } else {
              this.finalReportsCount = 0;
            }

            if (AirlinesComponent.TOTAL_GRIDDATA) {
              if (AirlinesComponent.TOTAL_GRIDDATA.length === 0) {
                AirlinesComponent.TOTAL_GRIDDATA = data.Rows;
              } else {
                AirlinesComponent.TOTAL_GRIDDATA =
                  AirlinesComponent.TOTAL_GRIDDATA.concat(data.Rows);
              }
            }

            AirlinesComponent.TOTAL_GRIDDATA = this.removeDuplicates(
              AirlinesComponent.TOTAL_GRIDDATA
            );
          }
        } else {
          data.Rows = [];
          this.source.totalrecords = 0;
          if (data.activeCount != undefined && data.activeCount != null) {
            this.finalReportsCount = data.activeCount;
          } else {
            this.finalReportsCount = 0;
          }
        }
      } else {
        data.Rows = [];
        this.source.totalrecords = 0;
        this.finalReportsCount = 0;
      }
    },

    pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
    },
  };
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "90%";
    }

    return 850;
  }
  linkrenderer = function (row, column, value) {
    var html =
      "<a  href='" +
      AirlinesComponent.webapi +
      value +
      "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
    return html;
  };

  columnsrenderer = function (value) {
    return (
      '<div style="text-align: center; margin-top: 5px;">' + value + "</div>"
    );
  };
  dataAdapter = new jqx.dataAdapter(this.source, {
    
    beforeSend: function (jqXHR, settings) {
      this.loginresultobject = JSON.parse(
        localStorage.getItem("loginresultobject")
      );
      this.token = this.loginresultobject.token;
      jqXHR.setRequestHeader(
        "cache-control",
        "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
      ),
        jqXHR.setRequestHeader("pragma", "no-cache"),
        jqXHR.setRequestHeader("expires", "0"),
        jqXHR.setRequestHeader("authorization", this.token);
    },
  });
  getEditorDataAdapter = (datafield: string): any => {
    let dataAdapter = new jqx.dataAdapter(this.source, {
      uniqueDataFields: [datafield],
    });
    return dataAdapter;
  };
  input: any; 
  public loginDataDup = JSON.parse(localStorage.getItem("loginresultobject"));

  columns = [
    { text: "Airline", datafield: "airline_name", width: 200, editable: false },
    {
      text: "Actions",
      datafield: "actions_e",
      width: 200,
      filterable: false,
      sortable: false,
      groupable: false,
      editable: false,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {
        // return '<button type="button" style="width: 43%; margin-left: 5%" class="btn btn-outline-warning btn-sm" id="edit: '+columnData.id+'">Edit</button><button type="button" style="width: 43%; margin-left: 5%" class="btn btn-outline-danger btn-sm" id="delete: '+columnData.id+'">Delete</button>';
        if(AirlinesComponent.aitype === 'A'){
          return (
            "<div style='margin-top:  8.5px;margin-left: 20px;'><a  href='edit:" +
            columnData.id +
            "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
            columnData.id +
            "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a>"+
  
            "<a  href='delete:" +
            columnData.id +
            "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'delete:" +
            columnData.id +
            "' class='fa fa-trash-o' style='padding:0 3px;font-size:18px;color:red;' aria-hidden='true' data-toggle='tooltip' title='Delete'></i></a>"+

            // "<div id='jqxButton'></div>"+
            // "<a  href='on:" +
            // columnData.id +
            // "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'on:" +
            // columnData.id +
            // "' class='fa fa-toggle-on fa-2x' title='On'></i>"+
            // "<i id = 'off:" +
            // columnData.id +
            // "' class='fa fa-toggle-on fa-2x fa-rotate-180' title='Off'></i></a>"+

            // "<a  href='delete:" +
            // columnData.id +
            // "<p><span class='custom-switch'><input type='checkbox' [checked]='isAutosendEmailEnable_Internal' class='custom-control-input' id='customSwitch2'>"+
            //   "<label class='custom-control-label' for='customSwitch2'></label></span></p>/a>"+
            
            "</div>"
            
          );
        }else{
          return (
            "<div style='margin-top:  8.5px;margin-left: 20px;'><a  href='active:" +
            columnData.id +
            "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'active:" +
            columnData.id +
            "' class='fa fa-undo' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Active'></i></a></div>"
          );
        }
      }
    },
    { text: "Prefix", datafield: "AWB_prefix", width: 200, editable: false },
    { text: "ICAO", datafield: "ICAO", width: 150, editable: false },
    { text: "IATA", datafield: "IATA", width: 150, editable: false },
    { text: "Country", datafield: "country", width: 200, editable: false },
  ];


  rendergridrows = (params: any): any => {
    return params.data;
  };

  onRowDoubleClick(rowData) {
    console.log(rowData);
  }

  editAirline(id) {
    let obj = {
      'loginUserId': this.loginDetails.userId,
      'id': id
    };
    this.superAdminServices.getAirlineDetails(obj).subscribe(res => {
      this.response = res;
      if (this.response.responseCode === 1) {
        this.response.loginUserId = this.loginDetails.userId;
        this.response.id = id;
        this.openDialog(this.response);
      } else if (this.response.responseCode === 0) {
        this.toastr.error(this.response.message);
      }
    }, (err) => {
      this.toastr.error(this.response.message);
    })
    console.log(id, JSON.parse(id), "edit working");
  }
  
  deleteAirline(data) {
    this.singleDelete = data;
    console.log(data, "edit working");
    jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
  }

  reActiveAirlineConfirm(data){
    if(data === 'multiple'){
      if (this.selectedRowsData.length != 0) {
        jQuery('#reActModal').modal({ backdrop: 'static', keyboard: false });
      } else {
        this.spinner.hide();
        this.toastr.error('Please select atleast one airline', '', { timeOut: 1200 });
      }
    }else{
      this.selectedRowsData.push({'id': data});
      jQuery('#reActModal').modal({ backdrop: 'static', keyboard: false });
    }
  }

  reActiveAirline(){
    if (this.selectedRowsData.length != 0) {
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        this.selAirlineIdarray.push(this.selectedRowsData[i]['id'])
      }
      let inputObj = {
        "loginUserId": this.loginDetails.userId,
        "id": this.selAirlineIdarray,
        "updatedOn": this.dateAndTimeFunc.convertToIso(new Date())
      }
      this.spinner.show();
      this.superAdminServices.ReActiveAirline(inputObj).subscribe(
        data => {
          if (data.responseCode === 1) {
            this.toastr.success(data.message, '', {});
            jQuery('#reActModal').modal('hide');
            this.ngAfterViewInit();
            this.myGrid.clearselection()
            this.selectedRowsData = [];
            this.selAirlineIdarray = [];
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#reActModal').modal('hide');
            this.spinner.hide();
          }
          else {
            this.toastr.error(data.message, '', {});
            jQuery('#reActModal').modal('hide');
            this.ngAfterViewInit();
            this.selectedRowsData = [];
            this.selAirlineIdarray = [];
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          jQuery('#reActModal').modal('hide');
          this.ngAfterViewInit();
          this.selectedRowsData = [];
          this.selAirlineIdarray = [];
        }
      )
    } 
  }

  onClickCargoClaim_Reports() {
    localStorage.setItem("currentModule", JSON.stringify("cargoClaim"));
    this.route.navigateByUrl("/cargoClaim/dashboard/claim-count");
  }
  

  onExportClick(): void {
    let deleteColumns = ["actions_e","id"];
    let reportsArray = [];
    reportsArray = this.myGrid.getrows().map((x) => Object.assign({}, x)); //deep cloned array
    let columnsData = this.myGrid.attrColumns
      .map((item) => {
        return {
          datafield: item.datafield,
          text: item.text,
          width: item.width && this.scaleColumnWidth(item.width, 6),
        };
      })
      .filter((item) => {
        return !deleteColumns.includes(item.datafield);
      }); //Excel columns Header And width obj array
    for (let i = 0; i < reportsArray.length; i++) {
      for (let j = 0; j < deleteColumns.length; j++) {
        // deleting extra columns from datagrid cloned object
        delete reportsArray[i][deleteColumns[j]];
      }
    }
    this.excelService.exportAsExcelFile( reportsArray,
      `airline_${new Date().toString().substring(0, 24)}_page_${
        this.myGrid.getpaginginformation().pagenum + 1
      }`,
      ["airline_name"],
      columnsData
    );
  }

  scaleColumnWidth(width: any, scale: number = 10) {
    return width / scale;
  }

  convertDate(dateTxt: string) {
    let date = new Date(dateTxt);
    return (
      date.getFullYear() +
      "/" +
      ((date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      (date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate()) +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  }

  static convertUTCDateToLocalDate(date1) {
    let dt = this.utcToLocal(date1, moment.tz.guess());
    return dt === "Invalid Timezone Operator" ? date1 : dt;
  }

  static utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z"); // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, ""); // Zone value without + - chars
    var operator =
      zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+"; // operator for addition subtraction
    var localDateTime;
    var hours = zoneValue.split(":")[0];
    var minutes = zoneValue.split(":")[1];
    if (operator === "-") {
      localDateTime = moment(utcdateTime)
        .subtract(hours, "hours")
        .subtract(minutes, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (operator) {
      localDateTime = moment(utcdateTime)
        .add(hours, "hours")
        .add(minutes, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      localDateTime = "Invalid Timezone Operator";
    }
    return localDateTime;
  }

  convertToIso(date: Date) {
    let dt = date.toISOString();
    return dt.substring(0, 10) + " " + dt.substring(11, 19);
  }
  onClick_Create_CargoAcceptance() {
    localStorage.setItem("CARGO_Acceptance_From", JSON.stringify("Create"));
    this.route.navigateByUrl("/CargoAcceptance/create-cargo-acceptance");
  }

  removeDuplicates(array) {
    let uniq = {};
    return array.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));
  }

  onExportAsXmlClick(): void {
    let deleteColumns = [
      "actions_e"
    ];
    let reportsArray = [];
    reportsArray = this.myGrid.getrows().map((x) => Object.assign({}, x));
    
    let strXMl = this.OBJtoXML({ reportsData: { report: reportsArray } });
    let formattedXML = this.formatXml(
      '<?xml version="1.0" encoding="UTF-8"?>' + strXMl,
      "\t"
    );
    var file = new File(
      [formattedXML],
      `cargoAcceptance_${new Date().toString().substring(0, 24)}_page_${
        this.myGrid.getpaginginformation().pagenum + 1
      }`,
      { type: "text/xml" }
    );
    FileSaver.saveAs(file);
  }

  formatXml(xml: string, tab: string) {
    // tab = optional indent value, default is tab (\t)
    var formatted = "",
      indent = "";
    tab = tab || "\t";
    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
      formatted += indent + "<" + node + ">\r\n";
      if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab; // increase indent
    });
    return formatted.substring(1, formatted.length - 3);
  }

  OBJtoXML(obj: Object): string {
    var xml = "";
    for (var prop in obj) {
      // loop through the object properties
      if (obj[prop] instanceof Array) {
        // If  object prop is array
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += this.OBJtoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else {
        // If  object prop is Not array
        xml += "<" + prop + ">";
        typeof obj[prop] == "object"
          ? (xml += this.OBJtoXML(new Object(obj[prop])))
          : (xml += obj[prop]); // If  object prop is object then recalling the xml fun else concating the string
        xml += "</" + prop + ">";
      }
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
    return xml;
  }
  
  // id: string;

  openDialog(details): void {

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      disableClose: false,
      hasBackdrop: true,
      backdropClass: '',
      width: '37%',
      panelClass:'makeItMiddle',
      data: details
    });

    dialogRef.afterClosed().subscribe(result => {
      // alert(JSON.stringify(result))
      this.ngAfterViewInit();
    });
  }

  public selectedRowsData = [];
  _onChangeRowSelection(event) {
    // trigger when checkbox checked / unchecked
    this.isSlectALL_records = false;
    if (Array.isArray(event.args.rowindex)) {
      if (event.args.rowindex.length !== 0) {
        this.selectedRowsData = AirlinesComponent.TOTAL_GRIDDATA.filter(item=>item);
        this.isSlectALL_records = true;
      } else {
        this.selectedRowsData = [];
      }
    } else {
      if (event.type === "rowunselect") {
        let index = this.selectedRowsData.findIndex(
          (x) => x.id === event.args.row.id
        );
        this.selectedRowsData.splice(index, 1);
      } else {
        this.selectedRowsData.push(event.args.row);
      }
    }
  }
   // delete and manual archive ---------
   confirm_delete() {
    if (this.selectedRowsData.length != 0) {
      jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
    } else {
      this.spinner.hide();
      this.toastr.error('Please select atleast one airline', '', { timeOut: 1200 });
    }
  }
  DeleteReport() {
    if (this.selectedRowsData.length != 0) {
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        this.selAirlineIdarray.push(this.selectedRowsData[i]['id'])
      }
      let inputObj = {
        "loginUserId": this.loginDetails.userId,
        "id": this.selAirlineIdarray,
        "updatedOn": this.dateAndTimeFunc.convertToIso(new Date())
      }
      this.spinner.show();
      this.superAdminServices.DeleteMultipleAirline(inputObj).subscribe(
        data => {
          if (data.responseCode === 1) {
            this.toastr.success(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.ngAfterViewInit();
            this.myGrid.clearselection()
            this.selectedRowsData = [];
            this.selAirlineIdarray = [];
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#delrepModal').modal('hide');
            this.spinner.hide();
          }
          else {
            this.toastr.error(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.ngAfterViewInit();
            this.selectedRowsData = [];
            this.selAirlineIdarray = [];
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          jQuery('#delrepModal').modal('hide');
          this.ngAfterViewInit();
          this.selectedRowsData = [];
          this.selAirlineIdarray = [];
        }
      )
    } else if(this.singleDelete){
      let inputObj = {
        "loginUserId": this.loginDetails.userId,
        "id": this.singleDelete,
        "updatedOn": this.dateAndTimeFunc.convertToIso(new Date())
      }
      this.spinner.show();
      this.superAdminServices.DeleteSingleAirline(inputObj).subscribe(
        data => {
          if (data.responseCode === 1) {
            this.toastr.success(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.ngAfterViewInit();
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            jQuery('#delrepModal').modal('hide');
            this.spinner.hide();
          }
          else {
            this.toastr.error(data.message, '', {});
            jQuery('#delrepModal').modal('hide');
            this.ngAfterViewInit();
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          jQuery('#delrepModal').modal('hide');
          this.ngAfterViewInit();
          this.selectedRowsData = [];
        }
      )
    }
     else {
      this.spinner.hide();
      jQuery('#delrepModal').modal('hide');
      this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
  }

  
}

@Component({
  selector: 'dialog-overview-dailog',
  templateUrl: 'dialog-overview-dailog.html',
  styleUrls: ['airlines.component.css']
})
export class DialogOverviewExampleDialog {
  
  loginDetails: any;
  response: any;
  data_already_exists: any = 'hide';
  airlineForm = new FormGroup({
    id: new FormControl(),
    loginUserId: new FormControl(null),
    awbPrefix: new FormControl('', [Validators.required]),
    airlineName: new FormControl('', [Validators.required]),
    icao: new FormControl(''),
    iata: new FormControl(''),
    country: new FormControl(''),
    createdOn: new FormControl(this.dateAndTimeFunc.convertToIso(new Date()))
  });

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    private superAdminServices: SuperadminService,
    private toastr: ToastrService,
    private dateAndTimeFunc : DateAndTimeFunctions,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    if(this.data){
      this.airlineForm = new FormGroup({
        id: new FormControl(this.data.id),
        loginUserId: new FormControl(this.data['loginUserId']),
        awbPrefix: new FormControl(this.data['AWB_prefix'], [Validators.required]),
        airlineName: new FormControl(this.data['airline_name'], [Validators.required]),
        icao: new FormControl(this.data['ICAO']),
        iata: new FormControl(this.data['IATA']),
        country: new FormControl(this.data['country']),
        createdOn: new FormControl(this.dateAndTimeFunc.convertToIso(new Date()))
      });
    }
  }

  onSubmit() {
    this.spinner.show();
    // let country = this.airlineForm.value.country;
    this.airlineForm.patchValue({
      // country: country.alpha3Code,
      loginUserId: this.loginDetails.userId
    });
    console.log(this.airlineForm.value);
    if(this.airlineForm.value.airlineName || this.airlineForm.value.icao || this.airlineForm.value.iata || this.airlineForm.value.awbPrefix ||
      this.airlineForm.value.country){
        if (this.airlineForm.value.id === null) {
          this.superAdminServices.createAirlines(this.airlineForm.value).subscribe(res => {
            this.response = res;
            if (this.response.responseCode === 1) {
              this.spinner.hide();
              this.toastr.success(this.response.message);
            } else if (this.response.responseCode === 0) {
              this.spinner.hide();
              this.toastr.error(this.response.message);
            }
          }, (err) => {
            this.spinner.hide();
            this.toastr.error(this.response.message);
          })
        } else {
          // alert('update not implemented')
          this.spinner.hide();
          this.dialogRef.close();
        }
      }
    else{
      this.spinner.hide();
      this.dialogRef.close();
    }  
    this.dialogRef.close(this.airlineForm.value);
  }

  onUpdate(){
    this.spinner.show();
    this.superAdminServices.updateAirlines(this.airlineForm.value).subscribe(res => {
      this.response = res;
      if (this.response.responseCode === 1) {
        this.spinner.hide();
        this.toastr.success(this.response.message);
      } else if (this.response.responseCode === 0) {
        this.spinner.hide();
        this.toastr.error(this.response.message);
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(this.response.message);
    })
    this.dialogRef.close(this.airlineForm.value);
  }

  cancel(){
    this.dialogRef.close();
  }

  onCountrySelected(event) {
    console.log(event);
  }

  existing_check(type){
     
    // if(type === 'prefix'){
    //   if(this.airlineForm.value.awbPrefix.length === 3){
    //     AirlinesComponent.TOTAL_GRIDDATA.forEach((item) => {
    //       if(item.AWB_prefix !== null && item.AWB_prefix === this.airlineForm.value.awbPrefix){
    //         this.airlineForm.get('awbPrefix').patchValue('');
    //         this.toastr.warning('This AWB Prefix already exists.', 'Warning');
    //       }
    //     });
    //   }
    // }
    // if(type === 'iata'){
      if(this.airlineForm.value.iata.length > 0){
        AirlinesComponent.TOTAL_GRIDDATA.forEach((item) => {
          if(item.IATA !== null && item.IATA.toUpperCase() === this.airlineForm.value.iata.toUpperCase()){
            this.airlineForm.get('iata').patchValue('');
            this.toastr.warning('This IATA already exists.', 'Warning');
          }
        });
      }
    // }
  }
}
