import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import {MyAccountService } from '../../services/myAccount/my-account.service'
import { LocationStrategy } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ViewReportsData } from '../../app/data/DataShareViewDetails';
import { SidebarComponent } from '../../app/layouts/sidebar/sidebar.component';
import { ConfigServiceService } from '../config-service.service';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { SwitchdashboardService } from "../services/switch-dashboard/switchdashboard.service";

export interface reactDialogData {
    resp: string;
  }

export let browserRefresh = false;
@Component({
    selector: 'app-handler-dashboard',
    templateUrl: './handler-dashboard.component.html',
    styleUrls: ['./handler-dashboard.component.css']
})
export class HandlerDashboardComponent implements OnInit {
    userName: any;
    truckingAPI: string;
    myAccount;
    loginDetails: any;
    email: string;
    password: string;
    userID: number;
    switchFrom: string;

    baseURL = this.config.getConfig().switchTruckingURL;
    public role: string;
    Logindetails: any;
    userId: string;
    saDashboard: any;
    DashBoardDetails: any;
    LoginDetails: any;
    comapanydetails: any;
    switchEnable: string;
    isSwiched: string;
    subscription: Subscription;
    dashBoradSwitchedtemp: any;
    AdminType: string;
    // browserRefresh : any;
    constructor(private router: Router,
        private ViewReportsData: ViewReportsData,
        private SidebarComponent: SidebarComponent,
        private navbarComponent: NavbarComponent,
        private SwitchdashboardService: SwitchdashboardService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService, private location: LocationStrategy,
        private config : ConfigServiceService,
        public dialog: MatDialog
        ) {
    this.truckingAPI = config.getConfig().truckingURL

            history.pushState(null, null, window.location.href);  
            this.location.onPopState(() => {
              history.pushState(null, null, window.location.href);
            });  
        
    }

    ngOnInit() {
        this.loginDetails = JSON.parse(localStorage.getItem('loginDetails')) ;
        if (this.loginDetails != null || this.loginDetails != undefined) {
            this.email = this.loginDetails.emailId;
            this.password = '';
            this.userID = this.loginDetails.userId;
            this.switchFrom = 'cclp';
        }
        
        this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log('console.log(this.LoginDetails?.userType)', this.LoginDetails.userType)
        // this.isSwiched = JSON.parse(localStorage.getItem('isSwitched'));
        console.log(this.LoginDetails)
        if (this.LoginDetails.userType === 'SA') {
            this.role = 'Super Admin';
        } else if (this.LoginDetails.userType === 'S') {
            this.role = 'Company Super Admin';
        }
        else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'Y' && this.LoginDetails.orgType === "H") {
            this.role = 'Authenticated User';
        } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'I' && this.LoginDetails.userSuperAdminAuth === 'I') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === "H") {
            this.role = 'UnAuthenticated User';
        } else if (this.LoginDetails.userType == 'E' && (this.LoginDetails.userAdminAuth === 'A' || this.LoginDetails.userSuperAdminAuth === 'A') && this.LoginDetails.reportAcess === 'N' && this.LoginDetails.orgType === "H") {
            this.role = 'UnAuthenticated User';
        }
        else if (this.LoginDetails.userType == 'E' && this.LoginDetails.orgType === "A") {
            this.role = 'User';
        }else if ((this.LoginDetails.userType == 'A' && this.LoginDetails.orgType === "A" ) || (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType === "H" )) {
            this.role = 'Admin';
        }
        else if (this.LoginDetails.userType === 'E' && this.LoginDetails.orgType === "E") {
            this.role = 'User';
        }

        if (this.LoginDetails.userType === 'S' && this.LoginDetails.reportAcess === "Y" && this.LoginDetails.orgType === "H") {
            this.AdminType = 'Authenticated'

        } else if (this.LoginDetails.userType === 'S' && this.LoginDetails.reportAcess === "N" && this.LoginDetails.orgType === "H") {
            this.AdminType = 'UnAuthenticated'

        }


        if (this.LoginDetails.userType === 'S' && (this.LoginDetails.userSuperAdminAuth === "A" || this.LoginDetails.userAdminAuth === "A") && this.LoginDetails.orgType === "A") {
            this.AdminType = 'Authorized'

        } else if (this.LoginDetails.userType === 'S' && (this.LoginDetails.userSuperAdminAuth === "I" && this.LoginDetails.userAdminAuth === "I") && this.LoginDetails.orgType === "A") {
            this.AdminType = 'UnAuthorized'

        }
        // this.switchEnable = this.ViewReportsData.isFromDashboard
        this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
        console.log('dashBoradSwitched---------------', this.dashBoradSwitchedtemp);
        if (this.dashBoradSwitchedtemp !== null) {
            if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
                this.switchEnable = 'yes'
                // console.log(this.LoginDetails.userType)
                this.ViewReportsData.isFromDashboard = 'yes'
            }
        }
        // if(this.LoginDetails.orgType === 'A' || ( (this.LoginDetails.userType ==='S' || this.LoginDetails.userType ==='A' || (this.LoginDetails.userType === 'E' && this.LoginDetails.reportAcess === 'Y') )&& this.LoginDetails.orgType === 'E')){
        //     this.OnClickCargoDamage();
        // } else if (this.LoginDetails.orgType === 'E') {
        //     this.OnClickCargoDamage();
        // }
        let moduleName = JSON.parse(localStorage.getItem('currentModule'))
        if (moduleName == 'cargoClaim') {
            this.AirportClaim();
        } else if (moduleName == 'cargoImage') {
            this.OnClickShipments('E')
        } else if (moduleName == 'cargoImage_Handler') {
            this.OnClickShipments('H')
        } else if (moduleName == 'CargoAcceptance') {
            this.OnClickCargoAcceptance();
        } else if (moduleName == 'Truckinspection') {
            this.OnClickTruckInspection();
        } else if (moduleName == 'ULD_DAMAGE') {
            this.OnClickUldDamage();
        } else if (moduleName == 'CARGODAMAGE-FORWARDER') {
            this.OnClickCargoDamage()
        }
    }



    logOut() {
       
        this.navbarComponent.logOut();
    }

    switchToSaDashbord() {
        
        let logindetailsForSwitching = JSON.parse(localStorage.getItem('logindetailsForSwitching'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        // console.log('logindetailsForSwitching', logindetailsForSwitching);
        // console.log('this.comapanydetails ', this.comapanydetails);
        // let loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
        // loginresultobject["token"] = logindetailsForSwitching["token"]
        // localStorage.setItem('loginresultobject', JSON.stringify(loginresultobject));
        let dashBoardObj = {
            emailId: logindetailsForSwitching.emailId,
            OrgId: logindetailsForSwitching.orgId,
            OrgType: logindetailsForSwitching.orgType

        }
        console.log('switching to SA dashboard', dashBoardObj);
        this.spinner.show();
        this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {
            
            console.log(data);
            this.spinner.hide();
            if (data.responseCode === 1) {
                if (data.userType === 'SA') {
                    localStorage.setItem('loginresultobject', JSON.stringify(data));
                    if (this.ViewReportsData.isFromDashboard === 'yes') {
                        this.ViewReportsData.isFromDashboard = 'no';
                       
                        localStorage.removeItem("dashBoradSwitchedtemp");
                      
                        let val = '1'
                        localStorage.setItem('activation', JSON.stringify(val));
                        this.router.navigate(["/sa-dashboard"]);
                    } else {
                        this.ViewReportsData.isFromDashboard = 'no';
                       
                        this.SidebarComponent.activecall("")
                        this.router.navigate(["viewusers", this.comapanydetails.orgId]);
                    }
                } else if (data.responseCode === 0) {
                    this.toastr.error(data.message);
                }
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } else{
                this.toastr.error(data.message);
            }

        }, error => {
            
            this.spinner.hide();
            console.log(error);
            this.toastr.error(error.statusText)
        });



    }

    DamageReports() {

        this.spinner.show();
        setTimeout(() => {
        localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
       

        this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
        if (this.dashBoradSwitchedtemp !== null) {
            
            if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
                if (this.LoginDetails.userType == 'SA') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/sa-dashboard');
                } else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'H') {
                    this.SidebarComponent.activecall("1")
                   
                    this.router.navigate(['csa-dashboard'], {
                       
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
                else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'A') {
                    this.SidebarComponent.activecall("1")
                   
                    this.router.navigate(['csa-airline-dashboard'], {
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
                else if (this.LoginDetails.userType == 'E') {
                    this.SidebarComponent.activecall("1")
                   
                    this.router.navigate(['user-dashboard'], {
                      
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
                else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'H') {
                    this.SidebarComponent.activecall("1")
                   
                    this.router.navigate(['admin-handler-dashboard'], {
                       
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
                else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'A') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigate(['admin-airline-dashboard'], {
                       
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                }
            } else {
                if (this.LoginDetails.userType == 'SA') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/sa-dashboard');
                } else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'H') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/csa-dashboard');
                }
                else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'A') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/csa-airline-dashboard');
                }
                else if (this.LoginDetails.userType == 'E') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/user-dashboard');
                }
                else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'H') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/admin-handler-dashboard');
                }
                else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'A') {
                    this.SidebarComponent.activecall("1")
                    this.router.navigateByUrl('/admin-airline-dashboard');
                }
            }
        } else {
            if (this.LoginDetails.userType == 'SA') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/sa-dashboard');
            } else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'H') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/csa-dashboard');
            }
            else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'A') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/csa-airline-dashboard');
            }
            else if (this.LoginDetails.userType == 'E') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/user-dashboard');
            }
            else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'H') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/admin-handler-dashboard');
            }
            else if (this.LoginDetails.userType == 'A' && this.LoginDetails.orgType == 'A') {
                this.SidebarComponent.activecall("1")
                this.router.navigateByUrl('/admin-airline-dashboard');
            }
        }

    }, 200);
    // this.openDialogforReact()
    
    // let loginDetails = localStorage.getItem('loginDetails');
    // window.location.replace(`http://localhost:4200/cargo-damage?values=${loginDetails}`);
  }

  ServiceReports(){
    this.spinner.show();
    if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'H') {
        this.SidebarComponent.activecall("1")
        localStorage.setItem("currentModule",JSON.stringify("ServiceRecovery"))
        this.router.navigateByUrl('/csa-service-recovery');
        this.spinner.hide();
    }
    else if (this.LoginDetails.userType == 'S' && this.LoginDetails.orgType == 'A') {
        this.SidebarComponent.activecall("1")
        localStorage.setItem("currentModule",JSON.stringify("ServiceRecovery"))
        this.router.navigateByUrl('/csa-service-recovery');
    }
    this.spinner.hide();
} 
  
  openDialogforReact() {
    // const dialogRef = this.dialog.open(DialogContentExampleDialog);  
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed', result);
    // });
  }


    openNewTab() {
        
        window.open("https://uld.cclp.aero/ ", "_blank");
    }
    
    AirportClaim() {
              this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
            this.router.navigateByUrl('/cargoClaim/dashboard');
        }, 200);
    }


    OnClickShipments(type){
        this.spinner.show();
        if(type === "E"){
            setTimeout(() => {
                localStorage.setItem("currentModule",JSON.stringify("cargoImage"));
                this.router.navigateByUrl('/CargoImage/view-shipment');
            }, 200);
        }else{
            setTimeout(() => {
                localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"));
                this.router.navigateByUrl('/CargoImageHandler/view-shipment');
            }, 200);
        }
        
    }

    OnClickCargoAcceptance(){
        this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("CargoAcceptance"));
        this.router.navigateByUrl('/CargoAcceptance/view-cargo-acceptance'); 
        }, 200);
    }

    OnClickTruckInspection(){
        this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("Truckinspection"));
        this.router.navigateByUrl('Truckinspection/view-truck-inspection'); 
        }, 200);
    }

    OnClickImportAcceptance(){
        this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("CargoAcceptance"));
        this.router.navigateByUrl('CargoAcceptance/view-import-acceptance'); 
        }, 200);
    }

    OnClickUldDamage(){
        this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
        this.router.navigateByUrl('/Uld/viewuld'); 
        }, 200);
    }
    OnClickCargoDamage(){
        this.spinner.show();
        setTimeout(() => {
            localStorage.setItem("currentModule",JSON.stringify("CARGODAMAGE-FORWARDER"))
        this.router.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder'); 
        }, 200);
    }



    // constructor(public dialog: MatDialog) {}

    openDialog() {

        // const dialogRef = this.dialog.open(DialogContentExampleDialog);  
        //   dialogRef.afterClosed().subscribe(result => {
        //     console.log('The dialog was closed', result);
        //   });
    }
  }
  
  @Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: './dialog-content-example-dialog.html'
  })
  export class DialogContentExampleDialog {
    constructor(public dialogRef: MatDialogRef<DialogContentExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    closeDialog() {
      this.dialogRef.close('Pizza!');
    }

    onNoClick(): void {
    //   this.data.resp = 'No'
      this.dialogRef.close('No');
    }
  
    onYesClick(): void {
    //   this.data.resp = 'Yes'
    console.log(this.data);
        this.dialogRef.close('Yes');
        let tab = localStorage.getItem('currentModule');
        let loginDetails = localStorage.getItem('loginDetails');
        window.location.replace(`http://cclp.mindwavetech.com/${tab}?values=${loginDetails}`);
    }
  }