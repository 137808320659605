export class serviceRecoveryinfo {
  public categoryId: any;
  public userId: any;
  public masterAwbNo: any;
  public masterAwbNo1: any;
  public airlineId: any;
  public airlineOrgId: any;
  public flightTruckNo: any;
  public flightDate: any;
  public originAirportId: any;
  public originAirportAction: any;
  public destairportId: any;
  public destairportAction: any;
  public flight1: any;
  public flight2: any;
  public flightnumber: any;
  public affectedAwbarray: any;
  public carrierId: any;
  public orgId: any;
  public totalMAWBPieces: any;
  public totalMAWBWeight: any;
  public piecesInvolved: any;
  public piecesInvolvedWeight: any;
  public recoveryAspcts: any;
  public comment: any;
  public createdon: any;
  public progressStatus: any;
  public viewHandlerDetails: any;
  public handler_air_Id:any;
  public id: any;
  public status: any;
  public imgUrl: any;
  public actype: any;
}
