import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShipmentsService } from 'src/app/services/shipments/shipments.service';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { ConfigServiceService } from '../../config-service.service';
@Component({
  selector: 'app-view-shipment-details',
  templateUrl: './view-shipment-details.component.html',
  styleUrls: ['./view-shipment-details.component.css']
})
export class ViewShipmentDetailsComponent implements OnInit {
  orgDetails: any;
  shipment_COmpleteData:any ;
  old_Images: any = [];
  webapi ;
  constructor(

    private shipmentServc: ShipmentsService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private toastr: ToastrService,
    private ViewReportsData: ViewReportsData,
    private config : ConfigServiceService
  ) { 
    this.webapi =  this.config.getConfig().URL
  }

  ngOnInit() {
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.fn_ToGet_ShipmentData();
  }


  fn_ToGet_ShipmentData() {
    if ((this.ViewReportsData.shipmentId !== null && this.ViewReportsData.shipmentId !== undefined) &&
      (this.ViewReportsData.organizationId !== null && this.ViewReportsData.organizationId !== undefined)) {
      let inputObj = {
        "orgId": this.ViewReportsData.organizationId,
        "shiperId": this.ViewReportsData.shipmentId
      }
      this.spinner.show();
      this.shipmentServc.api_To_GetShipment_Details(inputObj).subscribe(responseData => {
        this.spinner.hide();
        if (responseData.responseCode === 1 && responseData.message !=="No data found") {
         this.shipment_COmpleteData = responseData.shipmentDetails ;
         this.old_Images = responseData.shipmentDetails.shipmentImages ;
         if(this.old_Images === null){
          this.old_Images = [];
         }
         let obj_Keys  =  this.fn_To_Extract_Obj_Keys( this.shipment_COmpleteData)   
         for(let i=0;i<obj_Keys.length;i++){ //---> To assign double hiephens(--) for empty / null values 
          if(this.shipment_COmpleteData[obj_Keys[i]] !== "shipmentImages"){
            if( this.shipment_COmpleteData[obj_Keys[i]] === '' ||  this.shipment_COmpleteData[obj_Keys[i]] === null){
              this.shipment_COmpleteData[obj_Keys[i]] = '--';
               }
          }
                 
         }
        } else {
          this.old_Images = [];
          this.toastr.success(responseData.message);
        }
      }, error => {
        this.spinner.hide();
      }
      )
    } else {
      this.route.navigateByUrl("/CargoImage/view-shipment");
    }
  }
  fn_To_Extract_Obj_Keys(jsonObject :JSON):Array<string>{  //---> To get object keys
    let obj_Keys = Object.keys(jsonObject);
    return obj_Keys ;
  }
  navigateToShipments() {
    this.route.navigateByUrl("/CargoImage/view-shipment");
  }

}
