import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ConfigServiceService } from "src/app/config-service.service";
// const webapi = environment.URL;
@Injectable({
  providedIn: "root",
})
export class ClaimCountService {
  webapi:any ;
  constructor(private http: HttpClient,config : ConfigServiceService) {
    this.webapi  =    config.getConfig().CARGO_CLAIM_URL
  }
   //claim  api
  claim_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/airportClaimCount.php/claimCount",
      obj,
      { headers: headers }
    );
  }
   //Compare_claim  api
  Compare_claim_count(obj){
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareClaimCountChart.php/compareClaimCount",
      obj,
      { headers: headers }
    );
  }
  
 //amount  api
  amount_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/paidClaimAmount.php/getPaidClaimAmountChart",
      obj,
      { headers: headers }
    );
  }
  //cpmpare amount  api
  Compare_amount_count(obj){
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/comparePaidClaimAmount.php/comparePaidClaimAmount",
      obj,
      { headers: headers }
    );
  }
 
  //irregularuty  api
  irregularuty_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/airportIrregularityClaims.php/getIrrigularityCount",
      obj,
      { headers: headers }
    );
  }
  // compare_irregularuty  api
  compare_irregularuty_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareIrregularityCount.php/compareIrregularityCount",
      obj,
      { headers: headers }
    );
  }
  //Claim_aspscts  api
  Claim_aspscts(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/airportClaimAspects.php/claimAspectsChart",
      obj,
      { headers: headers }
    );
  } 
   // Compare_Claim_aspscts  api
  Compare_Claim_aspscts(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareClaimAspectsChart.php/CompareClaimAspects",
      obj,
      { headers: headers }
    );
  }
  //investigation api
  investigation_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/investigationChart.php/investigationCountChart",
      obj,
      { headers: headers }
    );
  }
   //compare investigation api
  compare_investigation_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareInvestigationCount.php/compareInvestigationCount",
      obj,
      { headers: headers }
    );
  }
  //serviceLevel api
  serviceLevel_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/serviceLevelChart.php/serviceLevelChart",
      obj,
      { headers: headers }
    );
  }
  // compare_serviceLevel api
  compare_serviceLevel_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareServiceLevel.php/compareServiceLevel",
      obj,
      { headers: headers }
    );
  }
 
  //claim activity api
  claimActivity_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/claimActivityChart.php/claimActivityChart",
      obj,
      { headers: headers }
    );
  }
  //compare claim activity api
  compare_claimActivity_count(obj) {
    let val = JSON.parse(localStorage.getItem("Token"));
    const headers = new HttpHeaders({ Authorization: val });
    return this.http.post<any>(
      this.webapi + "cargoClaim/compareClaimActivity.php/compareClaimActivity",
      obj,
      { headers: headers }
    );
  }
  
}
