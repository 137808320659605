import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { createUldModel, uldReportEmailsModel, updateUldModel } from './createUldInputObj';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { UldModuleService } from '../../services/uldmoduleServices/uld-module.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CreatereportService } from 'src/app/services/createreport.service';
import { Subscription } from 'rxjs';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfigServiceService } from 'src/app/config-service.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-create-uld',
  templateUrl: './create-uld.component.html',
  styleUrls: ['./create-uld.component.css']
})
export class CreateUldComponent implements OnInit {
  uldInformationGroup: FormGroup;
  damageInformationGroup: FormGroup;
  investigation_And_ResponsibilityGroup: FormGroup;

  private createUldInput = new createUldModel();
  private sendEmailInput = new uldReportEmailsModel();
  private updateUldInput = new updateUldModel();


  handlingStageData: any = []; processAreaData: any = [];
  airlineData: any = []; airlineDataDuplicate: any = [];
  typeCodes: any = []; typeCodesReplica: any = [];
  ownerNameData: any = []; ownerNameDataDuplicate: any = [];
  imageslist: any = [];
  uld_damage_Report_Images: any = []

  showAirLineList: boolean = false;
  showTypeCodeList: boolean = false;
  showOwnerNameList: boolean = false;
  isHandlingStage_Other_enable: boolean = false;
  isProcess_Other_enable: boolean = false;
  isreturnedBy_enable: boolean = true;
  isOtherFeilds_enable: boolean = true;
  isEditable: boolean = true;
  isLinear: boolean = true;

  loginDetails: any;
  urls: any;
  urlArrayTotal: any;
  is_From: any

  investigationGroupSubscription1: Subscription; uldresponsibility: Subscription;
  investigationGroupSubscription2: Subscription; uldinvestigationSubscription: Subscription;
  uldIncidentSubscription: Subscription;
  uldRefrigerationActiveSubscription: Subscription;
  uldServiceableSubscription: Subscription;
  datePickerOptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'DD-MM-YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: { "overflow": "visible !important" }, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker1', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  ReportsProductionUrl: any;
  jobTitleFromView: any;
  firstNameFromView: any;
  stationCodeFromView: any;
  orgIdFromView: any;

  constructor(private formBuilder: FormBuilder,
    private uldServices: UldModuleService,
    private spinner: NgxSpinnerService,
    public route: Router,
    private toastr: ToastrService,
    private damageReportService: CreatereportService,
    private el: ElementRef,
    private viewReportsData: ViewReportsData,
    private sanitizer: DomSanitizer,
    private config : ConfigServiceService,
    private location: Location,
  ) {
    this.ReportsProductionUrl = this.config.getConfig().URL
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
}

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    CreateUldComponent._imagesArrayTotal = [];
    this.urls = [];
    this.uldInformationGroup = this.fn_ToCreateUldInformationForm();
    this.uldInformationGroup.get("flightDate").setValue(new Date()) ;
    
    this.damageInformationGroup = this.fn_ToCreatedamageInformationForm();
    this.investigation_And_ResponsibilityGroup = this.fn_ToCreateinvestigation_And_Respon_Form();
    if(this.viewReportsData.uldISFrom === undefined || this.viewReportsData.uldISFrom === null ||this.viewReportsData.uldISFrom === ""){
      this.location.back();
    }else{
      if(this.viewReportsData.uldISFrom === 'Create'){
        this.is_From = "CREATE_REPORT";
      }else if(this.viewReportsData.uldISFrom === 'Edit'){
        this.is_From = "EDIT_REPORT";
        this.updateUldInput.reportId = this.viewReportsData.uldId ;
      }
    }
   
    this.fn_ToGet_AirlineData();
    
    this.createUldInput.inaProcessAreaId = null ;
   


    //------- Field value changes functinality   ----------//
    this.investigationGroupSubscription1 = this.investigation_And_ResponsibilityGroup.get("handlingStage").valueChanges.subscribe(value => {
     
      let selectedObj = this.handlingStageData.find(obj => obj.description === value);
     
      if(this.viewReportsData.uldISFrom === 'Edit'){
        this.updateUldInput.inaHandlingStageId = selectedObj.id;
      }else{
        this.createUldInput.inaHandlingStageId = selectedObj.id;
      }
      if (value === "Other") {
        this.investigation_And_ResponsibilityGroup.addControl('handlingStageOther', this.formBuilder.control('', [Validators.required]));
        this.isHandlingStage_Other_enable = true;
      } else {
        this.investigation_And_ResponsibilityGroup.removeControl('handlingStageOther');
        this.isHandlingStage_Other_enable = false;
      }
      this.isFormValid('THIRD', 'INPUT')
    }, error => {
    })

    this.investigationGroupSubscription2 = this.investigation_And_ResponsibilityGroup.get("processArea").valueChanges.subscribe(value => {
      let selectedObj = this.processAreaData.find(obj => obj.description === value);
      if(selectedObj !== '' && selectedObj !== null && selectedObj !== undefined){
        if(this.viewReportsData.uldISFrom === 'Edit'){
          this.updateUldInput.inaProcessAreaId = selectedObj.id;
        }else{
          this.createUldInput.inaProcessAreaId = selectedObj.id;
        }
      }
      if (value === "Other") {
        this.investigation_And_ResponsibilityGroup.addControl('processAreaOther', this.formBuilder.control('', [Validators.required]));
        this.isProcess_Other_enable = true;
      } else {
        this.investigation_And_ResponsibilityGroup.removeControl('processAreaOther');
        this.isProcess_Other_enable = false;
      }
      this.isFormValid('THIRD', 'INPUT')
    }, error => {

    })

    this.uldIncidentSubscription = this.uldInformationGroup.get("uldIncident").valueChanges.subscribe(value => {
      if (value === "L") {
        this.uldInformationGroup.addControl('returnedBy', this.formBuilder.control('', [Validators.required]));
        this.uldInformationGroup.removeControl('flightNumber');
        this.uldInformationGroup.removeControl('flightDate');
        this.isreturnedBy_enable = true;
      } else {
        this.uldInformationGroup.removeControl('returnedBy');
        this.uldInformationGroup.addControl('flightNumber', this.formBuilder.control('', [Validators.required]));
        this.uldInformationGroup.addControl('flightDate', this.formBuilder.control(new Date(), [Validators.required]));
        this.isreturnedBy_enable = false;
      }
      this.isFormValid('FIRST', 'INPUT')
    }, error => {

    })


    this.uldinvestigationSubscription = this.investigation_And_ResponsibilityGroup.get("investigation").valueChanges.subscribe(value => {
      if (value === "discoveredby") {
        this.investigation_And_ResponsibilityGroup.get("responsibility").setValue("external");
      } else {
        this.investigation_And_ResponsibilityGroup.get("responsibility").setValue("internal");
      }
      this.isFormValid('THIRD', 'INPUT')
    }, error => {

    })


    this.uldresponsibility = this.investigation_And_ResponsibilityGroup.get("responsibility").valueChanges.subscribe(value => {
      if (value === "internal") {
        this.isOtherFeilds_enable = true;
      } else {
        this.isOtherFeilds_enable = false;
      }
      this.isFormValid('THIRD', 'INPUT')
    }, error => {

    })
    this.uldServiceableSubscription = this.damageInformationGroup.get("serviceable").valueChanges.subscribe(value => {
      this.isFormValid('SECOND', 'INPUT')
    }, error => {

    })
    this.uldRefrigerationActiveSubscription = this.damageInformationGroup.get("refrigerationActive").valueChanges.subscribe(value => {
      this.isFormValid('SECOND', 'INPUT')
    }, error => {

    })



  }


  //------- Form gropus creation ---------//
  fn_ToCreateUldInformationForm(): FormGroup {
    return this.formBuilder.group({
      uldIncident: new FormControl("", [Validators.required]),
      returnedBy: new FormControl("", [Validators.required]),

      type: new FormControl("", [Validators.required]),
      typeReplica: new FormControl("", [Validators.required]),

      serialNumber: new FormControl("", [Validators.required]),

      uldOwnerName: new FormControl("", [Validators.required]),
      uldOwnerNameReplica: new FormControl("", [Validators.required]),

      airline: new FormControl("", [Validators.required]),
      airlineReplica: new FormControl("", [Validators.required]),

      flightNumber: new FormControl("", [Validators.required]),
      flightDate: new FormControl("", [Validators.required]),
    })
  }

  fn_ToCreatedamageInformationForm(): FormGroup {
    return this.formBuilder.group({
      refrigerationActive: new FormControl(""),
      serviceable: new FormControl(""),
      additionalDamageInformation: new FormControl(""),
    })
  }

  fn_ToCreateinvestigation_And_Respon_Form(): FormGroup {
    return this.formBuilder.group({
      investigation: new FormControl("", [Validators.required]),
      nameOfPerson: new FormControl("", [Validators.required]),
      department: new FormControl(""),
      handlingStage: new FormControl("", [Validators.required]),
      causeOfEvent: new FormControl(""),
      responsibility: new FormControl("", [Validators.required]),
      processArea: new FormControl(""),
      areaSupervisor: new FormControl(""),
      exactLocation: new FormControl(""),
    })
  }

  termsAndConditionGroup = this.formBuilder.group({
    terms_N_Condition: new FormControl(false, [Validators.requiredTrue]),
  })


 

  fn_ToGet_AirlineData() { ///---api call used for airline and owner code  list 
    this.spinner.show();
    this.damageReportService.getTruckAirlineDetailsService().subscribe(
      responseData => {
        this.fn_ToGet_Handling_ProcessData()
        this.fn_ToGet_Typecodes()
        if (responseData.responseCode === 1) {
          this.airlineData = responseData.airline_details;
          this.airlineDataDuplicate = responseData.airline_details;
          this.ownerNameData = responseData.airline_details;
          this.ownerNameDataDuplicate = responseData.airline_details;
       
        } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
          this.toastr.info("Your session expired, please login again");
        } else {
          this.toastr.error(responseData.message, '', { timeOut: 1500 });
        }
      },
      error => {
        this.fn_ToGet_Handling_ProcessData()
        this.fn_ToGet_Typecodes()
        this.spinner.hide();
      });
  }


  fn_ToGet_Handling_ProcessData() { ///---api call used to get  Handling stage And process Area  Data
    this.uldServices.getHandlingstage_ProcessData().subscribe(responseData => {
      this.spinner.hide();
      if (responseData.responseCode === 1) {
        this.handlingStageData = responseData.handlingStage
        this.processAreaData = responseData.processData

        if(this.viewReportsData.uldISFrom === 'Edit'){
          this.is_From = "EDIT_REPORT";
         this.fn_ToGet_ViewOfReport(this.viewReportsData.uldId);
        }
      } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
        this.toastr.info("Your session expired, please login again");
      } else {
        this.toastr.error(responseData.message, '', { timeOut: 1500 });
      }
    }, error => {
      this.spinner.hide();
      if(this.viewReportsData.uldISFrom === 'Edit'){
        this.is_From = "EDIT_REPORT";
       this.fn_ToGet_ViewOfReport(this.viewReportsData.uldId);
      }
    })
  }
  fn_ToGet_Typecodes() {  ///---api call used to get Type codes Data
    this.uldServices.get_TypeCode().subscribe(responseData => {
      this.spinner.hide();
      if (responseData.responseCode === 1) {

        this.typeCodes = responseData.typecodeDetails
        this.typeCodesReplica = responseData.typecodeDetails
      } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
        this.toastr.info("Your session expired, please login again");
      } else {
        this.toastr.error(responseData.message, '', { timeOut: 1500 });
      }
    }, error => {
      this.spinner.hide();
    })
  }

  //------Airline Field related functions-------//
  onAirlineFocus() {
    this.showAirLineList = true;
  }

  onAirlineFocusOut() {
    this.showAirLineList = false;
  }

  fn_To_filterAirlines(ev: any) {
    if(this.viewReportsData.uldISFrom === 'Edit'){ 
      this.updateUldInput.fiAirlineId = null;
      this.updateUldInput.fiFlightDetailsData = '' ;
    }else{
      this.createUldInput.fiAirlineId = null;
      this.createUldInput.fiFlightDetailsData = '' ;
    }

    const val = ev.target.value;
    this.uldInformationGroup.get("airlineReplica").setValue("");
    if (val && val.trim() !== '') {
      this.airlineData = this.airlineDataDuplicate.filter((item) => item.carrierName.toLowerCase().startsWith(val.toLowerCase()));
      this.showAirLineList = true;
    }
    else {
      this.airlineData = this.airlineDataDuplicate
    }
  }

  on_SelectAirline(item) {
     
    this.uldInformationGroup.get("airline").setValue(item.carrierName);
    this.uldInformationGroup.get("airlineReplica").setValue(item.carrierName);

    if(this.viewReportsData.uldISFrom === 'Edit'){ 
      this.updateUldInput.fiAirlineId = item.carrierId ;
      this.updateUldInput.carrierId = item.carrierId ;
      this.updateUldInput.fiFlightDetailsData = item.AWB_prefix + "-" ;
    }else{
      this.createUldInput.fiAirlineId = item.carrierId ;
      this.createUldInput.carrierId = item.carrierId ;
      this.createUldInput.fiFlightDetailsData = item.AWB_prefix + "-" ;
  
    }

    this.showAirLineList = false;
  }


  //------Owner Name Field related functions-------//
  onOwnerNameFocus() {
    this.showOwnerNameList = true;
  }
  onOwnerNameFocusOut() {
    this.showOwnerNameList = false;
  }

  fn_To_filteronOwnerName(ev: any) {
    const val = ev.target.value;
    this.uldInformationGroup.get("uldOwnerNameReplica").setValue("");
    if (val && val.trim() !== '') {
      this.ownerNameData = this.ownerNameDataDuplicate.filter((item) => item.IATA.toLowerCase().startsWith(val.toLowerCase()));
      this.showOwnerNameList = true;
    }
    else {
      this.ownerNameData = this.ownerNameDataDuplicate
    }
  }

  on_SelectOwnerName(item) {
    this.uldInformationGroup.get("uldOwnerName").setValue(item.IATA + "-" + item.carrierName);
    this.uldInformationGroup.get("uldOwnerNameReplica").setValue(item.IATA + "-" + item.carrierName);
    this.showOwnerNameList = false;
  }


  //------Type Field related functions-------//
  onTypeCodeFocus() {
    this.showTypeCodeList = true;
  }

  onTypeCodeFocusOut() {
    this.showTypeCodeList = false;
    //  if(this.uldInformationGroup.get("typeReplica").value === '') 
  }

  fn_To_filterTypeCodes(ev: any) {
    const val = ev.target.value;
    this.uldInformationGroup.get("typeReplica").setValue("");
    if (val && val.trim() !== '') {
      this.typeCodes = this.typeCodesReplica.filter((item) => item.uldTypecode.toLowerCase().startsWith(val.toLowerCase()));
      this.showTypeCodeList = true;
    }
    else {
      this.typeCodes = this.typeCodesReplica
    }
  }

  on_SelectypeCode(item) {
    this.uldInformationGroup.get("type").setValue(item.uldTypecode);
    this.uldInformationGroup.get("typeReplica").setValue(item.uldTypecode);
    this.showTypeCodeList = false;
  }

  //----------------------------Images uploadimg-----------------------------------------------///

  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  };
  public static _imagesArrayTotal = [];
  selectimages(val) {
    const self = this;
    this.imageslist = [];
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(',')[1];
        CreateUldComponent._imagesArrayTotal.push(base64);
        self.imageslist = CreateUldComponent._imagesArrayTotal; 
        ;
        self.preview(CreateUldComponent._imagesArrayTotal);
        if (self.imageslist.length !== 0) {
          self._setColoursToEach_Stepper_Header("green", 3);
        } else {
          self._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
        }
      })
    }
  }
  //--------To preview images------------//
  preview(files) {
    this.urls = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = 'data:image/png;base64,' + file;
        this.urls.push(image);
        this.urlArrayTotal = this.urls
      }
    }
  }

  on_Cancel_Image(i) {
    this.imageslist.splice(i, 1);
    this.urls.splice(i, 1);
    if (this.imageslist.length !== 0) {
      this._setColoursToEach_Stepper_Header("green", 3);
    } else {
      this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
    }
  }


  on_Cancel_PreviousImage(i) {
  this.uld_damage_Report_Images.splice(i, 1);
  if (this.imageslist.length !== 0 || this.uld_damage_Report_Images.length !== 0) {
    this._setColoursToEach_Stepper_Header("green", 3);
  } else {
    this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
  }
  }




  onSubmitForm(uldInformationGroup, damageInformationGroup, investigation_And_ResponsibilityGroup) { // api call to create uld report
    let date = new Date();
    this.createUldInput.userId = this.loginDetails.userId;
    this.createUldInput.siIncidentDate = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length === 1 ? ("0"+(date.getMonth() + 1)) :   (date.getMonth() + 1) ) + "-" + (date.getDate().toString().length === 1 ? "0"+date.getDate() : date.getDate());
    this.createUldInput.siIncidentTime =    (( date.getHours()).toString().length === 1 ? ("0"+(date.getHours())) : ( date.getHours()) ) + ":" + ((date.getMinutes()).toString().length === 1 ? ("0"+(date.getMinutes())) : (date.getMinutes()) );
    this.createUldInput.organizationId = this.loginDetails.orgId;
    this.createUldInput.siStationCode = this.loginDetails.stationCode;
    this.createUldInput.siReportPreparedName = this.loginDetails.firstName;
    this.createUldInput.siReportPreparedFunction = this.loginDetails.jobTitle;
    this.createUldInput.iswebormobile = 0;
    this.createUldInput.uiReturnFromAgent = uldInformationGroup.uldIncident;
    this.createUldInput.uiType = uldInformationGroup.type;
    this.createUldInput.uiSerialNumber = uldInformationGroup.serialNumber;
    let ownerNaemSplitted = uldInformationGroup.uldOwnerName.split("-");
    this.createUldInput.uiOtherUdOwnername = ownerNaemSplitted[0];
    if (uldInformationGroup.flightNumber !== undefined) {
      this.createUldInput.fiFlightDetails = uldInformationGroup.flightNumber;
      this.createUldInput.fiFlightDetailsData = this.createUldInput.fiFlightDetailsData + uldInformationGroup.flightNumber;
    } else {
      this.createUldInput.fiFlightDetails = ""
      // this.createUldInput.fiFlightDetailsData = ""
    }
    if (uldInformationGroup.flightDate !== undefined) {
      let flightDateReplica = new Date(uldInformationGroup.flightDate)
      this.createUldInput.fiFlightDate = flightDateReplica.getFullYear() + '-' +( (flightDateReplica.getMonth() +1).toString().length === 1 ? "0"+(flightDateReplica.getMonth() +1) : (flightDateReplica.getMonth() +1))+ '-' + (flightDateReplica.getDate().toString().length === 1 ? "0"+flightDateReplica.getDate() : flightDateReplica.getDate());
    } else {
      this.createUldInput.fiFlightDate = null
    }
    this.createUldInput.fiOriginAirportName = null;
    this.createUldInput.uiNameOfReturningAgent = (uldInformationGroup.returnedBy !== undefined ? uldInformationGroup.returnedBy : null);

    this.createUldInput.inaHandlingStageOther = ((investigation_And_ResponsibilityGroup.handlingStage === 'Other') ? investigation_And_ResponsibilityGroup.handlingStageOther : null);

    this.createUldInput.inaProcessAreaOther = ((investigation_And_ResponsibilityGroup.processArea === 'Other') ? investigation_And_ResponsibilityGroup.processAreaOther : null);;
    this.createUldInput.inaDepartment = investigation_And_ResponsibilityGroup.department;
    this.createUldInput.inaDiscoveresCaused = investigation_And_ResponsibilityGroup.investigation;
    this.createUldInput.inaInternalName = investigation_And_ResponsibilityGroup.nameOfPerson; 
    this.createUldInput.inaInternalResponsibility = investigation_And_ResponsibilityGroup.responsibility;
    this.createUldInput.inaAreaSupervisor = investigation_And_ResponsibilityGroup.areaSupervisor;
    this.createUldInput.inaExactLocation = investigation_And_ResponsibilityGroup.exactLocation;
    this.createUldInput.inaLocationId = null;
    this.createUldInput.inaCasueOfEvent = investigation_And_ResponsibilityGroup.causeOfEvent;
    this.createUldInput.diRefrigerationActive = damageInformationGroup.refrigerationActive;
    this.createUldInput.diServiceable = damageInformationGroup.serviceable;
    this.createUldInput.diAdditionalDamageInfo = damageInformationGroup.additionalDamageInformation;


    if (this.imageslist !== undefined) {
      let imageBase64String = '';
      if (this.imageslist.length !== 0) {
        for (let i = 0; i < this.imageslist.length; i++) {
          if (i + 1 === this.imageslist.length) {
            imageBase64String = imageBase64String + this.imageslist[i];
          } else {
            imageBase64String = imageBase64String + this.imageslist[i] + '#..';
          }
        }
        this.createUldInput.picDamageImage = imageBase64String;
      } else {
        this.createUldInput.picDamageImage = ''
      }
    } else {
      this.createUldInput.picDamageImage = ''
    }
    this.spinner.show();
    this.uldServices.createUldReport(this.createUldInput).subscribe(responseData => {
      if (responseData.responseCode === 1) {
        CreateUldComponent._imagesArrayTotal = [];
        this.sendEmailInput.organizationId = responseData.userDetails.org_Id;
        this.sendEmailInput.reportId = responseData.reportId;
        this.sendEmailInput.userId = responseData.user_Id;
        this.sendEmailInput.carrierId = responseData.user_Id;
        this.sendEmailInput.carrierId = this.createUldInput.carrierId ;
        this.apiCallTo_Send_Emails(this.sendEmailInput)
        this.route.navigateByUrl("/Uld/viewuld");
      } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
        this.toastr.info("Your session expired, please login again");
        this.spinner.hide();
      } else {
        this.toastr.error(responseData.message, '', { timeOut: 1500 });
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    })
  }



  apiCallTo_Send_Emails(inputObj: uldReportEmailsModel) {
    this.uldServices.send_UldReport_Emails(inputObj).subscribe(responseData => {
      this.spinner.hide();
      if (responseData.responseCode === 1) {
      } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
        this.toastr.info("Your session expired, please login again");
      }
    }, error => {
      this.spinner.hide();
    })
  }

  isFormValid(currentTab, from): void { //---- Func to focus invalid field 
    if (currentTab === "FIRST") {
      if (this.uldInformationGroup.valid) {
        this._setColoursToEach_Stepper_Header("green", 0);
      } else {

        if (from === 'INPUT') {
          this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 0);
        } else {

          if (this.uldInformationGroup.get("uldIncident").value === '' || this.uldInformationGroup.get("uldIncident").value === null) {
            this.toastr.error("Please choose uldIncident field");
          }

          for (const key of Object.keys(this.uldInformationGroup.controls)) {
            if (this.uldInformationGroup.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
              invalidControl.focus();
              if (this.uldInformationGroup.get("flightDate").value === '' || this.uldInformationGroup.get("flightDate").value === null) {
                this.toastr.error("Please choose flightDate field");
              }
              break;
            }
          }
         
        }

      }
    } else if (currentTab === "SECOND") {
      if ((this.damageInformationGroup.get("refrigerationActive").value !== '' && this.damageInformationGroup.get("refrigerationActive").value !== null) ||
        (this.damageInformationGroup.get("serviceable").value !== '' && this.damageInformationGroup.get("serviceable").value !== null) ||
        (this.damageInformationGroup.get("additionalDamageInformation").value !== '' && this.damageInformationGroup.get("additionalDamageInformation").value !== null)) {
        this._setColoursToEach_Stepper_Header("green", 1);
      } else {
        this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 1);
      }
    } else if (currentTab === "THIRD") {
      if (this.investigation_And_ResponsibilityGroup.valid) {
        this._setColoursToEach_Stepper_Header("green", 2);
      } else {
        if (from === 'INPUT') {
          this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 2);
        } else {
          if (this.investigation_And_ResponsibilityGroup.get("investigation").value === '' || this.investigation_And_ResponsibilityGroup.get("investigation").value === null) {
            this.toastr.error("Please choose discoveredby or causedby field");
          } else if (this.investigation_And_ResponsibilityGroup.get("responsibility").value === '' || this.investigation_And_ResponsibilityGroup.get("responsibility").value === null) {
            this.toastr.error("Please choose responsibility")
          }
          for (const key of Object.keys(this.investigation_And_ResponsibilityGroup.controls)) {
            if (this.investigation_And_ResponsibilityGroup.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
              invalidControl.focus();
              break;
            }
          }
        }
      }
    }

  }


  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number): void { // ---func to change step header colour based on form fill
    const test = Array.from(document.getElementsByClassName('mat-step-icon'));
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  navigateToReports() {
    if(this.loginDetails.userType === 'SA'){
      this.viewReportsData.isFrmViewDetails = 'yes';
      this.location.back();   // when navigated from view users in SA
    }else{
      this.route.navigateByUrl("/Uld/viewuld") ;
    }
  }

//------------------- View And update Functions---------------- //
fn_ToGet_ViewOfReport(reportId){
  this.spinner.show();
 this.uldServices.get_UldReport_DetailedView({"reportId":reportId}).subscribe(responseData =>{
    if(responseData.responseCode === 1){
      ;
      this.jobTitleFromView     = responseData.uldDamageReport.siReportPreparedFunction ;
      this.firstNameFromView    = responseData.uldDamageReport.siReportPreparedName ;
       this.stationCodeFromView = responseData.uldDamageReport.siStationCode ;
      this.orgIdFromView        = responseData.uldDamageReport.orgKey ;

        this.uldInformationGroup.get("uldIncident").setValue(responseData.uldDamageReport.uiReturnFromAgent) ;
        if(this.uldInformationGroup.get("returnedBy")){
          this.uldInformationGroup.get("returnedBy").setValue(responseData.uldDamageReport.uiNameOfReturningAgent) ;
        }
        this.uldInformationGroup.get("type").setValue(responseData.uldDamageReport.uiType) ; 
        this.uldInformationGroup.get("typeReplica").setValue(responseData.uldDamageReport.uiType) ;
        this.uldInformationGroup.get("serialNumber").setValue(responseData.uldDamageReport.uiSerialNumber) ;
        if(this.ownerNameDataDuplicate !== null ){
       if(this.ownerNameDataDuplicate.length !== 0){
        let index = this.ownerNameDataDuplicate.findIndex(obj => obj.IATA === responseData.uldDamageReport.uiOtherUdOwnername);
        this.uldInformationGroup.get("uldOwnerName").setValue(responseData.uldDamageReport.uiOtherUdOwnername+'-'+this.ownerNameDataDuplicate[index].carrierName)//need concat 
        this.uldInformationGroup.get("uldOwnerNameReplica").setValue(responseData.uldDamageReport.uiOtherUdOwnername+'-'+this.ownerNameDataDuplicate[index].carrierName)//need concat 
       }
        }
    
        this.updateUldInput.fiAirlineId = responseData.uldDamageReport.fiAirlineId ;
        this.updateUldInput.fiFlightDetailsData = responseData.uldDamageReport.fiFlightDetailsData ;
        this.uldInformationGroup.get("airline").setValue( responseData.uldDamageReport.fiAirlineName) ;
        this.uldInformationGroup.get("airlineReplica").setValue(responseData.uldDamageReport.fiAirlineName) ;
        if(this.uldInformationGroup.get("flightNumber")){
          this.uldInformationGroup.get("flightNumber").setValue(responseData.uldDamageReport.fiFlightDetails) ;
          this.uldInformationGroup.get("flightDate").setValue(new Date(responseData.uldDamageReport.fiFlightDate)) ;
        }

        this.damageInformationGroup.get("refrigerationActive").setValue(responseData.uldDamageReport.diRefrigerationActive) ;
        this.damageInformationGroup.get("serviceable").setValue(responseData.uldDamageReport.diServiceable) ;
        this.damageInformationGroup.get("additionalDamageInformation").setValue(responseData.uldDamageReport.diAdditionalDamageInfo) ;
        
        this.investigation_And_ResponsibilityGroup.get("investigation").setValue(responseData.uldDamageReport.inaDiscoveresCaused)
        this.investigation_And_ResponsibilityGroup.get("nameOfPerson").setValue(responseData.uldDamageReport.inaInternalName)
        this.investigation_And_ResponsibilityGroup.get("department").setValue(responseData.uldDamageReport.inaDepartment)
        this.investigation_And_ResponsibilityGroup.get("handlingStage").setValue(responseData.uldDamageReport.inaHandlingStageDesc)
        this.investigation_And_ResponsibilityGroup.get("causeOfEvent").setValue(responseData.uldDamageReport.inaCasueOfEvent)
        this.investigation_And_ResponsibilityGroup.get("responsibility").setValue(responseData.uldDamageReport.inaInternalResponsibility)
        this.investigation_And_ResponsibilityGroup.get("processArea").setValue(responseData.uldDamageReport.inaProcessAreaDesc)
        this.investigation_And_ResponsibilityGroup.get("areaSupervisor").setValue(responseData.uldDamageReport.inaAreaSupervisor)
        this.investigation_And_ResponsibilityGroup.get("exactLocation").setValue(responseData.uldDamageReport.inaExactLocation)
        
        if(this.investigation_And_ResponsibilityGroup.get("processArea").value === 'Other'){
          this.investigation_And_ResponsibilityGroup.get("processAreaOther").setValue(responseData.uldDamageReport.inaProcessAreaOther);
        }
        if(this.investigation_And_ResponsibilityGroup.get("handlingStage").value === 'Other'){
          this.investigation_And_ResponsibilityGroup.get("handlingStageOther").setValue(responseData.uldDamageReport.inaHandlingStageOther);
        }

         if(responseData.uldDamageReport.picDamageImage === ''){
          this.uld_damage_Report_Images = [] ;
          this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
         }else{
          this.uld_damage_Report_Images = responseData.uldDamageReport.picDamageImage.split(",") ;
            this._setColoursToEach_Stepper_Header("green", 3);
         }
       
         this.isFormValid("FIRST", "INPUT") ;
         this.isFormValid("SECOND", "INPUT") ;
         this.isFormValid("THIRD", "INPUT") ;
    }else{
     
    }
    this.spinner.hide();
 },error=>{
  this.spinner.hide();
 })
}


onUpdateForm(uldInformationGroup, damageInformationGroup, investigation_And_ResponsibilityGroup) { // api call to Update uld report
  let date = new Date();
  this.updateUldInput.userId =  this.viewReportsData.uldReportUserId ;
  this.updateUldInput.siIncidentDate = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length === 1 ? ("0"+(date.getMonth() + 1)) :   (date.getMonth() + 1) )+ "-" + (date.getDate().toString().length === 1 ? "0"+date.getDate() : date.getDate());
  this.updateUldInput.siIncidentTime =    (( date.getHours()).toString().length === 1 ? ("0"+(date.getHours())) : ( date.getHours()) ) + ":" + ((date.getMinutes()).toString().length === 1 ? ("0"+(date.getMinutes())) : (date.getMinutes()) );
  this.updateUldInput.organizationId = this.orgIdFromView;
  this.updateUldInput.siStationCode = this.stationCodeFromView;
  this.updateUldInput.siReportPreparedName = this.firstNameFromView;
  this.updateUldInput.siReportPreparedFunction = this.jobTitleFromView;
  this.updateUldInput.iswebormobile = 0;
  this.updateUldInput.uiReturnFromAgent = uldInformationGroup.uldIncident;
  this.updateUldInput.uiType = uldInformationGroup.type;
  this.updateUldInput.uiSerialNumber = uldInformationGroup.serialNumber;
  let ownerNaemSplitted = uldInformationGroup.uldOwnerName.split("-");
  this.updateUldInput.uiOtherUdOwnername = ownerNaemSplitted[0];
  if (uldInformationGroup.flightNumber !== undefined) {
    this.updateUldInput.fiFlightDetails = uldInformationGroup.flightNumber;
    this.updateUldInput.fiFlightDetailsData = this.updateUldInput.fiFlightDetailsData.split("-")[0]+"-"+ uldInformationGroup.flightNumber;
  } else {
    this.updateUldInput.fiFlightDetails = "" ;
  }
  if (uldInformationGroup.flightDate !== undefined) {
    let flightDateReplica = new Date(uldInformationGroup.flightDate)
    this.updateUldInput.fiFlightDate = flightDateReplica.getFullYear() + '-' +( (flightDateReplica.getMonth() +1).toString().length === 1 ? "0"+(flightDateReplica.getMonth() +1) : (flightDateReplica.getMonth() +1))+ '-' + (flightDateReplica.getDate().toString().length === 1 ? "0"+flightDateReplica.getDate() : flightDateReplica.getDate());
  } else {
    this.updateUldInput.fiFlightDate = null
  }
  this.updateUldInput.fiOriginAirportName = null;
  this.updateUldInput.uiNameOfReturningAgent = (uldInformationGroup.returnedBy !== undefined ? uldInformationGroup.returnedBy : null);

  this.updateUldInput.inaHandlingStageOther = ((investigation_And_ResponsibilityGroup.handlingStage === 'Other') ? investigation_And_ResponsibilityGroup.handlingStageOther : null);

  this.updateUldInput.inaProcessAreaOther = ((investigation_And_ResponsibilityGroup.processArea === 'Other') ? investigation_And_ResponsibilityGroup.processAreaOther : null);;
  this.updateUldInput.inaDepartment = investigation_And_ResponsibilityGroup.department;
  this.updateUldInput.inaDiscoveresCaused = investigation_And_ResponsibilityGroup.investigation;
  this.updateUldInput.inaInternalName = investigation_And_ResponsibilityGroup.nameOfPerson;
  this.updateUldInput.inaInternalResponsibility = investigation_And_ResponsibilityGroup.responsibility;
  this.updateUldInput.inaAreaSupervisor = investigation_And_ResponsibilityGroup.areaSupervisor;
  this.updateUldInput.inaExactLocation = investigation_And_ResponsibilityGroup.exactLocation;
  this.updateUldInput.inaLocationId = null;
  this.updateUldInput.inaCasueOfEvent = investigation_And_ResponsibilityGroup.causeOfEvent;
  this.updateUldInput.diRefrigerationActive = damageInformationGroup.refrigerationActive;
  this.updateUldInput.diServiceable = damageInformationGroup.serviceable;
  this.updateUldInput.diAdditionalDamageInfo = damageInformationGroup.additionalDamageInformation;
  

  if (this.imageslist !== undefined) {
    let imageBase64String = '';
    if (this.imageslist.length !== 0) {
      for (let i = 0; i < this.imageslist.length; i++) {
        if (i + 1 === this.imageslist.length) {
          imageBase64String = imageBase64String + this.imageslist[i];
        } else {
          imageBase64String = imageBase64String + this.imageslist[i] + '#..';
        }
      }
      this.updateUldInput.picDamageImage = imageBase64String;
    } else {
      this.updateUldInput.picDamageImage = ''
    }
  } else {
    this.updateUldInput.picDamageImage = ''
  }
  if(this.uld_damage_Report_Images.length !== 0){
    this.updateUldInput.oldDamageImages = this.uld_damage_Report_Images.toString();
  }else{
    this.updateUldInput.oldDamageImages = '' ;
  }
  this.spinner.show();
  this.uldServices.update_UldReport(this.updateUldInput).subscribe(responseData => {
    if (responseData.responseCode === 1) {
      this.toastr.success(responseData.message, '', { timeOut: 1500 });
      CreateUldComponent._imagesArrayTotal = [];
      this.sendEmailInput.organizationId = this.viewReportsData.uldOrganizationId;
      this.sendEmailInput.reportId = this.viewReportsData.uldId;
      this.sendEmailInput.userId = this.viewReportsData.uldReportUserId ;
      let cid: any;
      cid = this.updateUldInput.fiAirlineId;
      this.sendEmailInput.carrierId =  parseInt(cid);
      this.apiCallTo_Update_Send_Emails(this.sendEmailInput)
      this.spinner.hide();
      this.navigateToReports();
    } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
      this.toastr.info("Your session expired, please login again");
      this.spinner.hide();
    } else {
      this.toastr.error(responseData.message, '', { timeOut: 1500 });
      this.spinner.hide();
    }
  }, error => {
    this.spinner.hide();
  })
}


apiCallTo_Update_Send_Emails(inputObj: uldReportEmailsModel) {
  this.uldServices.send_Update_UldReport_Emails(inputObj).subscribe(responseData => {
    this.spinner.hide();
    if (responseData.responseCode === 1) {
    } else if (responseData.responseCode === 0 && responseData.message === 'Unauthorized User') {
      this.toastr.info("Your session expired, please login again");
    }
  }, error => {
    this.spinner.hide();
  })
}


  ngOnDestroy() {
    if (this.investigationGroupSubscription1 !== undefined && this.investigationGroupSubscription1 !== null) {
      this.investigationGroupSubscription1.unsubscribe();
    }
    if (this.investigationGroupSubscription2 !== undefined && this.investigationGroupSubscription2 !== null) {
      this.investigationGroupSubscription2.unsubscribe();
    }
    if (this.uldinvestigationSubscription !== undefined && this.uldinvestigationSubscription !== null) {
      this.uldinvestigationSubscription.unsubscribe()
    }
    if (this.uldIncidentSubscription !== undefined && this.uldIncidentSubscription !== null) {
      this.uldIncidentSubscription.unsubscribe()
    }
    if (this.uldresponsibility !== undefined && this.uldresponsibility !== null) {
      this.uldresponsibility.unsubscribe()
    }
    this.spinner.hide();
  }

}
