import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { globalConstants } from 'src/environments/globalConstants';
import { StationService } from '../../../services/station/station.service';
declare var jQuery: any;
@Component({
    selector: 'app-addstations',
    templateUrl: './addstations.component.html',
    styleUrls: ['./addstations.component.css']
})
export class AddstationsComponent implements OnInit {
    Logindetails: any;
    addStationForm: FormGroup;
    airportDetails = [];
    stationcodeData: any;
    showListstation: boolean;
    items: any;
    stationCode: any;
    comapanydetails: any;
    orgId: any;
    minus: boolean;
    secondaryEmailIds: any[];
    isSubmitEnable: boolean;
    val: any;
    index: any;
    stationCodeCheck: any;
    CompanyLogoimgurl: string;
    CompanyName: any;
    loginInformation: any;
    handlerItems: any;
    getHandlerData:any;
    handlerIdCheck:any;
     showHandlerItems: boolean;
    // emailsData: any;
    constructor(private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService,
         private stationService: StationService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,private locationStr: LocationStrategy,
         ) {
        this.stationCodeCheck = '';
         // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.locationStr.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
    }
    initilizeHandlerData() {
        this.handlerItems = JSON.parse(localStorage.getItem('handlerList'));
    }

    getHandlerItems(ev: any) {
        // Reset items back to all of the items
        this.initilizeHandlerData()
        // set val to the value of the searchbar
        const val = ev;
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            this.initilizeHandlerData()
            this.getHandlerData = this.handlerItems.filter((item) =>
                item.orgName.toLowerCase().startsWith(val.toLowerCase())
            );
            if (this.getHandlerData.length === 0) {

                this.getHandlerData = this.getHandlerData.filter((item) =>
                    item.orgName.toLowerCase().startsWith(val.toLowerCase())
                );
            }
            // Show the results
            this.showHandlerItems = true;
        } else {
            // hide the results when the query is empty
            this.showHandlerItems = false;
        }
    }

    selectHandlerId(item) {
        this.handlerIdCheck = item.orgkey;
        this.addStationForm.get('handlerName').patchValue(item.orgName);
        this.addStationForm.get('handlerId').patchValue(item.orgkey);
        this.showHandlerItems = false;
    }
    initilizeStationCodeData() {
        this.items = JSON.parse(localStorage.getItem('stationsList'));

        //this.droplist=true;
    }
    getstationItems(ev: any) {
        

        // Reset items back to all of the items
        this.initilizeStationCodeData()
        // set val to the value of the searchbar
        const val = ev;
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            this.initilizeStationCodeData()
            this.stationcodeData = this.items.filter((item) =>
                item.code.toLowerCase().startsWith(val.toLowerCase())
            );
            if (this.stationcodeData.length === 0) {

                this.stationcodeData = this.stationcodeData.filter((item) =>
                    item.code.toLowerCase().startsWith(val.toLowerCase())
                );
            }
            // Show the results
            this.showListstation = true;
        } else {
            // hide the results when the query is empty
            this.showListstation = false;
        }
    }

    stationcodekeyup() {
        this.stationCodeCheck = '';
    }

    leavestationcode() {
        
        this.addStationForm.controls['stationCode'].value;
        if (this.stationCodeCheck === '' && this.addStationForm.controls['stationCode'].value !== '') {
            this.addStationForm.controls['stationCode'].setValue('');
            this.toastr.error('Please select stationCode from list', '', {});
            // this.addStationForm.controls['stationCode'].setErrors(null);
        }
    }

    selectstationcode(item) {
        
        this.stationCodeCheck = item.code;
        let val = item.code + '-' + item.name;
        // this.addStationForm.setItem('stationCode') =item.code;
        this.addStationForm.get('stationCode').patchValue(item.code);
        // this.addStationForm.get('stationCode2').setValue(val);
        this.showListstation = false;
    }
    ngOnInit() {
        
        this.isSubmitEnable = false;
        window.scroll(0, 0);
       
        this.loginInformation = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));

        if (this.comapanydetails === null) {
            this.CompanyLogoimgurl = this.loginInformation.orgLogo;
            this.CompanyName = this.loginInformation.organisationName
        } else {
            this.CompanyLogoimgurl = this.comapanydetails.orgLogo
            this.CompanyName = this.comapanydetails.orgName
        }
        this.spinner.show();

        this.stationService.GetHandlerName().subscribe(data => {
            if(data.responseCode === 1){
                localStorage.setItem('handlerList', JSON.stringify(data.handler_details));
                this.spinner.hide();
            }
            else if(data.responseCode === 0 && data.message === 'Unauthorized User')
            {
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            }
         
        },
        error => {
            
            this.spinner.hide();
        }
    );

         this.stationService.GetStationCodes().subscribe(
            data => {
             
                if(data.responseCode === 1){
                    this.airportDetails = data.airport_details
                    localStorage.setItem('stationsList', JSON.stringify(this.airportDetails));
                    this.spinner.hide();
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                this.spinner.hide();
            }
             
            },
            error => {
                this.spinner.hide();
            }
        )

       
      
        this.addStationForm = this.formBuilder.group({
            'contactName': new FormControl(''),
            'stationCode': new FormControl('', [Validators.required]),
            'handlerId': new FormControl(''),
            'handlerName': new FormControl('', [Validators.required]),
            'orgId': new FormControl(''),
            'emailId': new FormControl(''),
         
            'secondarymail1': this.formBuilder.array([this.formBuilder.group({ email: new FormControl('', [Validators.pattern(globalConstants.EMAIL_PATTERN)]) })]),
            'address': new FormControl(''),
            'city': new FormControl(''),
            'country': new FormControl(''),
            'secondaddress': new FormControl(''),
            'postalcode': new FormControl(''),
            'latitude': new FormControl(''),
            'longitude': new FormControl(''),
        })
        this.addStationForm.controls['orgId'].setValue(this.loginInformation.orgId);

        this.addStationForm.controls['secondarymail1'].valueChanges.subscribe(value => {
            
            console.log(value);
            this.fn_To_Enable_Submit();
            console.log()
        });
    }


    city_fn(){
        
       
        if(this.addStationForm.value.city != ''&& this.addStationForm.value.city != null){
            this.addStationForm.controls['city'].patchValue(this.addStationForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }
    country_fn(){
        if(this.addStationForm.value.country != ''&& this.addStationForm.value.country != null){
            this.addStationForm.controls['country'].patchValue(this.addStationForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }
    addStation(formData) {
       
        if(formData.city != ''&& formData.city != null){
            formData.city= formData.city.replace(/[^a-zA-Z\s]*/g, '');
        }
        if(formData.address != ''&& formData.address != null){
            formData.address= formData.address.replace(/[^a-zA-Z0-9\!\#\%\&\'\-\/\@\.\s\,]*/g, '');
        }
        if(formData.country != ''&& formData.country != null){
            formData.country= formData.country.replace(/[^a-zA-Z\s]*/g, '');
        }
        if(formData.contactName != ''&& formData.contactName != null){
            formData.contactName= formData.contactName.replace(/[^a-zA-Z\s]*/g, '');
        }
        if(formData.secondaddress != ''&& formData.secondaddress != null){
            formData.secondaddress= formData.secondaddress.replace(/[^a-zA-Z0-9\!\#\%\&\'\+\-\/\@\.\s\,]*/g, '');
        }

        if(formData.postalcode != ''&& formData.postalcode != null){
            formData.postalcode= formData.postalcode.replace(/[^a-zA-Z0-9]*/g, '');
        }
        if(formData.latitude != ''&& formData.latitude != null){
            formData.latitude= formData.latitude.replace(/[^0-9\.]*/g, '');
        }
        if(formData.longitude != ''&& formData.longitude != null){
            formData.longitude= formData.longitude.replace(/[^0-9\.]*/g, '');
        }
        
        if (this.stationCodeCheck === '') {
           
           
            this.addStationForm.controls['stationCode'].setValue(null);
           
        } else {
            this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
            this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
           
            formData.emailId = formData.secondarymail1[0].email;
            formData.secondarymail = [];
            for (let i = 0; i < formData.secondarymail1.length; i++) {
                if (i > 0) {
                    formData.secondarymail.push(formData.secondarymail1[i].email);
                }
            }
            formData.createdBy = this.Logindetails.userId;
            console.log(formData, "formDataformData")
            if (this.Logindetails.userType === 'SA') {
                formData.orgId = this.comapanydetails.orgId;
            } else {
                formData.orgId = this.Logindetails.orgId;
            }
            console.log(formData, "finalservcData")
            this.spinner.show();
            this.stationService.AddStations(formData).subscribe(
                data => {
                    
                    console.log(data)
                    this.spinner.hide();
                    if (data.status == '1') {
                        this.toastr.success('Successfully created', '', { timeOut: 1500 });
                      
                        this.router.navigate(["viewstations"], { queryParams: { from: 'ViewUsersStations' } });
                    }else if((data.status == '0' || data.responseCode === 0 ) && data.message === 'Unauthorized User'){
                        this.toastr.info("Your session is expired, please login again");
                    } 
                    else {
                        this.toastr.error(data.message, '', {});

                    }
                },
                error => {
                    this.spinner.hide();
                }
            )
        }

    }
    gotoback() {
       
        this.router.navigate(['/viewstations'],{ queryParams: { from: 'ViewUsersStations' } });
    }

    emailbun() {
        
        
    }
    emailbun2(i) {
        
       
    }


    get emailsData() {
        return this.addStationForm.get('secondarymail1') as FormArray;
    }


    add_email_Field() {
        this.emailsData.push(this.formBuilder.group({ email: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]) }));
    }

    delete_email_Field(index) {
        
        this.index = index;
        jQuery('#delete-confirmation').modal({ backdrop: 'static', keyboard: false });

    }

    confirm_delete_email_Field() {
        if (this.emailsData.length > 1) {
            this.emailsData.removeAt(this.index);
        }
        jQuery('#delete-confirmation').modal('hide');
    }

    getEmailIds(form): Array<any> {
      
        return form.controls.secondarymail1.controls;
    }
    findDuplicate(email, p): boolean {
       
        let myArray = this.getEmailIds(this.addStationForm);
        let totalArray = [];
        if (email != '' && email != null && email != undefined) {
            for (let i = 0; i < myArray.length; i++) {
                totalArray.push(myArray[i].value)
            }
            this.fn_To_Enable_Submit();
            let test = totalArray.filter(
                data =>
                    data.email == email && email != "");
            return (test.length > 1) ? true : false;
        }

    }


    fn_To_Enable_Submit() {
      
        let totalArray = [];
        const controlArray = <FormArray>this.addStationForm.get('secondarymail1');
        for (let i = 0; i < controlArray.value.length; i++) {
            totalArray.push(controlArray.value[i].email);
        }
        console.log("working");
        this.isSubmitEnable = this.find_duplicate_in_array(totalArray);
        console.log(" this.isSubmitEnable", this.isSubmitEnable)
    }
    find_duplicate_in_array(array) {
       
        return (new Set(array)).size !== array.length;

    }



}
