import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateCargoacceptanceComponent } from './create-cargoacceptance/create-cargoacceptance.component';
import { ViewCargoacceptanceComponent } from './view-cargoacceptance/view-cargoacceptance.component';
import { ViewCargoacceptanceDetailsComponent } from './view-cargoacceptance-details/view-cargoacceptance-details.component';
import { globalConstants } from 'src/environments/globalConstants';
import { AuthenticateGuard } from '../AuthGuard/authenticate.guard';
import { ViewImportAcceptanceComponent } from './view-import-acceptance/view-import-acceptance.component';
import { CreateImportAcceptanceComponent } from './create-import-acceptance/create-import-acceptance.component';


const routes: Routes = [
  { path: 'create-cargo-acceptance', component: CreateCargoacceptanceComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }  },
  { path: 'view-cargo-acceptance', component: ViewCargoacceptanceComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }  },
  { path: 'view-cargo-acceptance-details', component: ViewCargoacceptanceDetailsComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] } },
  { path: 'view-import-acceptance', component: ViewImportAcceptanceComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }  },
  { path: 'create-import-acceptance', component: CreateImportAcceptanceComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ] }  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CargoAcceptanceRoutingModule { }
