import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';
const headers = new HttpHeaders(
    {'Content-Type': 'application/json; charset=utf-8',
    'X_VERIFY'  :  'c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4'});



@Injectable({
    providedIn: 'root'
})
export class MyAccountService {
     webAPI:string;
     webAPI2: string;
    constructor(private http: HttpClient , config : ConfigServiceService) {
        this.webAPI = config.getConfig().URL ; 
        this.webAPI2 = config.getConfig().ULD_Url ; 
     }

    myAccountDetails(userId) {
        return this.http.post<any>(this.webAPI + "myAccount.php/viewUserDetails", { userId })
    }

    forgotPassword(emailid) {
        return this.http.post<any>(this.webAPI + 'myAccountCclpPortal.php/forgotPasswordCclpPortal', { emailid })
    }

    passwordChange(obj) {
        return this.http.post<any>(this.webAPI + 'checkListSave.php/changePassword', {
            "userId": obj.userId,
            "oldPassword": obj.oldPassword,
            "newPassword": obj.newPassword
        })
    }

    updateUserDetails(obj) {
        return this.http.post<any>(this.webAPI + 'myAccountCclpPortal.php/editProfile', obj)
    }

    getOrgStations(obj) {
        return this.http.post<{ obj: { orgId: string } }>(this.webAPI + 'myAccountCclpPortal.php/getStationsCclpPortal', obj)
    }


    resetpassword(obj) {
        return this.http.post<any>(this.webAPI2 + 'resetPassword.php/changeUsersPassword', obj)
    }

    generateToken(obj){
        console.log(obj);
        return this.http.post<any>(this.webAPI2 + 'cargoClaimOpenApi/generateTokenTruckingPortal.php/generateApiTokens', obj)
    }

    changePassword(obj) {
        return this.http.post<any>(this.webAPI2 + 'PasswordSetUp.php/changePassword', obj)
    }
}
