import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {globalService} from "../../services/global.service"
import { from } from 'rxjs';
@Component({
  selector: "app-sidebarCargoClaim",
  templateUrl: "./sidebarCargoClaim.component.html",
  styleUrls: ["./sidebarCargoClaim.component.css"],
 
})
export class SidebarCargoClaimComponent implements OnInit {
  sidenavhome: string;
  sidenavstations: string;
  val: any;
  sidenavreports: string;
  sidenavdownloads: string;
  loginDetails: any;
  claimchart: any;
  showCargoDamage: boolean;
  sidenavmodule: any;
  showDownload: boolean;
  dashbaordsubscribe: any;
  showclaim: boolean;
  tabIndex: any;
  selectedTab: any;
  sideNavAlluser:any ;
  companydetails: any;
  CompanyLogoimgurl: string;
  CompanyName: any;
  constructor(public router: Router,private globalService: globalService) {}

  ngOnInit() {
    this.getDashboardIconsubscriber();
    // this.activecall('1');
    this.showDownload = true;
    this.showCargoDamage = false;
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    this.val = JSON.parse(localStorage.getItem("activation"));
    if (this.val !== null && this.val !== undefined && this.val !== "") {
      this.activecall(this.val);
    }
    this.companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
    if (this.companydetails === null) {
        this.CompanyLogoimgurl = this.loginDetails.orgLogo;
        this.CompanyName =  this.loginDetails.organisationName
    } else {
        this.CompanyLogoimgurl = this.companydetails.orgLogo
        this.CompanyName = this.companydetails.orgName
    }
  }
  getDashboardIconsubscriber() {
    this.dashbaordsubscribe = this.globalService.getDashBoardState().subscribe(res => {
     if (res === "claims") {
      this.showCargoDamage = false;
      this.showDownload = true;
    } else if (res === "damagereports") {
      this.showCargoDamage = true;
      this.showDownload = false;
    }
    });
  }

  ngOnDestroy() {
    this.dashbaordsubscribe.unsubscribe();
 }
  navigatetodashboard() {
    ;
     let value = "yes"
    localStorage.setItem("homeButtonCliked", JSON.stringify(value));

  this.globalService.setHomeState(true);
    this.router.navigateByUrl("/cargoClaim/dashboard/claim-count");
   
  }
  activecall(val) {
    localStorage.setItem("activation", JSON.stringify(val));
    if (val === "1") {
      this.sidenavhome = "navactive";
      this.sidenavstations = "navdefault";
      this.sidenavreports = "navdefault";
      this.sidenavdownloads = "navdefault";
      this.sidenavmodule = "navdefault";
      this.sideNavAlluser = "navdefault";
    }
    if (val === "2") {
      this.sidenavhome = "navdefault";
      this.sidenavstations = "navactive";
      this.sidenavreports = "navdefault";
      this.sidenavdownloads = "navdefault";
      this.sidenavmodule = "navdefault";
      this.sideNavAlluser = "navdefault";
    }
    if (val === "3") {
      this.sidenavhome = "navdefault";
      this.sidenavstations = "navdefault";
      this.sidenavreports = "navactive";
      this.sidenavdownloads = "navdefault";
      this.sidenavmodule = "navdefault";
      this.sideNavAlluser = "navdefault";
    }
    if (val === "4") {
      this.sidenavhome = "navdefault";
      this.sidenavstations = "navdefault";
      this.sidenavreports = "navdefault";
      this.sidenavdownloads = "navactive";
      this.sidenavmodule = "navdefault";
      this.sideNavAlluser = "navdefault";
    }
    if (val === "5") {
      this.sidenavhome = "navdefault";
      this.sidenavstations = "navdefault";
      this.sidenavreports = "navdefault";
      this.sidenavdownloads = "navdefault";
      this.sidenavmodule = "navactive";
      this.sideNavAlluser = "navdefault";
    }
    if (val === "6") {
      this.sidenavhome = "navdefault";
      this.sidenavstations = "navdefault";
      this.sidenavreports = "navdefault";
      this.sidenavdownloads = "navdefault";
      this.sidenavmodule = "navdefault";
      this.sideNavAlluser = "navactive";
    }
    
  }


  navigateToAllusers() {
    ;
    const usersFrom = "SidebarAllUsers"
    localStorage.setItem('stationsFrom', JSON.stringify(usersFrom));
    localStorage.removeItem("selected_Shipments_Tab") ;
    if (this.loginDetails.userType === "S") {
        this.router.navigate(["cargoClaim/view-users"], { queryParams: { from: 'SidebarAllUsers' } });
    }
}
}
