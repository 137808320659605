import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { environment } from "src/environments/environment";
import { globalConstants } from "src/environments/globalConstants";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { LocationStrategy } from "@angular/common";
import { ShipmentsService } from "src/app/services/shipments/shipments.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../config-service.service";
import { CargoAcceptanceService } from "src/app/services/cargo-acceptance/cargo-acceptance.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from 'moment';
import 'moment-timezone';
import * as FileSaver from 'file-saver';
@Component({
  selector: "app-view-cargoacceptance",
  templateUrl: "./view-cargoacceptance.component.html",
  styleUrls: ["./view-cargoacceptance.component.css"],
})
export class ViewCargoacceptanceComponent implements OnInit {
  @ViewChild("myGrid") myGrid: jqxGridComponent;
  newRowIndex: number;
  static webapi: string;
  static totalGridData: any;
  reportsData: any;
  anchor_Tags: any;
  editicon_Tags: any;
  isArchiveEnable: boolean = true;
  orgDetails: any;
  finalReportsCount = 0;
  loginDetails: any;
  CargoACCResp: any;
  slResp: any;
  static TOTAL_GRIDDATA :any =[];
  static IS_PAGE_SIZE_OR_PAGENUM_CHANGED :boolean = false;
  isSlectALL_records: boolean;
  
  constructor(
    private route: Router,
    private ViewReportsData: ViewReportsData,
    private elementRef: ElementRef,
    private location: LocationStrategy,
    private shipmentServc: ShipmentsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private config: ConfigServiceService,
    private router: Router,
    private cargoAcceptanceService: CargoAcceptanceService,
    private excelService:ExcelService
  ) {
    // preventing back button in browser
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    ViewCargoacceptanceComponent.webapi = this.config.getConfig().ULD_Url;
  }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    localStorage.removeItem("CARGO_Acceptance_STORAGE");
    localStorage.removeItem("CARGO_Acceptance_From");
  }
  

  ngAfterViewChecked() {
    let selectedTab = JSON.parse(
      localStorage.getItem("selected_Shipments_Tab")
    );
    let loginData = JSON.parse(localStorage.getItem("loginresultobject"));
    // if (selectedTab !== null) {
    //   if (selectedTab === "I") {
    //     this.isArchiveEnable = false;
    //     this.myGrid.selectionmode("none");
    //   } else {
    //     if (loginData.reportAcess === "Y") {
    //       this.isArchiveEnable = true;
    //       this.myGrid.selectionmode("checkbox");
    //     } else {
    //       this.isArchiveEnable = false;
    //       this.myGrid.selectionmode("none");
    //     }
    //   }
    // } else {
    //   if (loginData.reportAcess === "Y") {
    //     this.isArchiveEnable = true;
    //     this.myGrid.selectionmode("checkbox");
    //   } else {
    //     this.isArchiveEnable = false;
    //     this.myGrid.selectionmode("none");
    //   }
    // }

    this.anchor_Tags = this.elementRef.nativeElement.querySelectorAll("a");
    this.editicon_Tags = this.elementRef.nativeElement.querySelectorAll("i");
    this.anchor_Tags.forEach((anchor: HTMLAnchorElement) => {
      if (anchor.href != "") {
        let href_Valid_Check_Var = anchor.href.split(":")[0];
        if (anchor.innerText === "Edit" && href_Valid_Check_Var === "edit") {
          anchor.addEventListener("click", this.handleAnchorClick);
        }
      }
    });

    this.editicon_Tags.forEach((editicon: HTMLImageElement) => {
      if (editicon.id != "") {
        let href_Valid_Check_Var = editicon.id.split(":")[0];
        if (href_Valid_Check_Var === "edit") {
          editicon.addEventListener("click", this.handleiconClick);
        }
      }
    });
  }

  ngAfterViewInit() {
    // this.myGrid.updatebounddata("refresh") ;
    
    let selectedTab = JSON.parse(
      localStorage.getItem("selected_Shipments_Tab")
    );
    let loginData = JSON.parse(localStorage.getItem("loginresultobject"));
      this.myGrid.showcolumn("cargo_acc_location");
      this.myGrid.hidecolumn("cargoAccLocationCopy");
      this.myGrid.showcolumn("shipment_number");
      this.myGrid.hidecolumn("shipment_numberCopy");
    // if (selectedTab !== null) {
    //   if (selectedTab === "I") {
    //     this.myGrid.selectionmode("none");
    //     this.myGrid.showcolumn("actions_e");
    //   } else {
    //     if (loginData.reportAcess === "Y") {
    //       this.isArchiveEnable = true;
    //       this.myGrid.selectionmode("checkbox");
    //     } else {
    //       this.isArchiveEnable = false;
    //       this.myGrid.selectionmode("none");
    //     }
    //   }
    // } else {
    //   if (loginData.reportAcess === "Y") {
    //     this.isArchiveEnable = true;
    //     this.myGrid.selectionmode("checkbox");
    //   } else {
    //     this.isArchiveEnable = false;
    //     this.myGrid.selectionmode("none");
    //   }
    // }

    // let selectedTab = JSON.parse(
    //   localStorage.getItem("selected_Shipments_Tab")
    // );
    if (selectedTab !== null) {
      this.OnChangeReports_Tab(selectedTab);
    } else {
      this.OnChangeReports_Tab("A");
    }
   
  }

  OnChangeReports_Tab(type: string) {
    
    this.selectedRowsData = [];
    ViewCargoacceptanceComponent.TOTAL_GRIDDATA =[];
    this.spinner.show();
    localStorage.setItem("selected_Shipments_Tab", JSON.stringify(type));
    this.source.url =
      ViewCargoacceptanceComponent.webapi +
      "cargoAccept/getAcceptList.php/cargoAcceptList?" +
      "orgId=" +
      this.logindet.orgId +
      "&orderType=" +
      type +
      "&type=" +
      type +
      "&loginUserId=" +
      this.logindet.userId;
      this.myGrid.clearfilters();
    this.myGrid.gotopage(0);
    this.myGrid.updatebounddata("refresh");
    if (type === "A") {
      if(this.logindet.reportAcess === 'Y'){
      this.isArchiveEnable = true;
      this.myGrid.selectionmode("checkbox");
      this.myGrid.clearselection();
      }else{
        this.isArchiveEnable = false;
        this.myGrid.selectionmode("none");
      }
     
      this.myGrid.showcolumn("actions_e");
      document.getElementById("archive_Reports").className = "nav-link";
      document.getElementById("active_Reports").className = "nav-link active";
    } else {
      this.isArchiveEnable = false;
      this.myGrid.selectionmode("none");
      this.myGrid.showcolumn("actions_e");
      document.getElementById("archive_Reports").className = "nav-link active";
      document.getElementById("active_Reports").className = "nav-link";
    }
// if( this.logindet.reportAcess === 'Y'){
  this.myGrid.showcolumn("cargo_acc_location");
  this.myGrid.hidecolumn("cargoAccLocationCopy");
// }else{
//   this.myGrid.hidecolumn("cargo_acc_location");
//   this.myGrid.showcolumn("cargoAccLocationCopy");
// }
document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  public handleiconClick = (event: Event) => {
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.onClick_Icon(editicon);
  };

  public handleAnchorClick = (event: Event) => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    this.onClick_AnchorTag(anchor);
  };

  onClick_AnchorTag(anchor: HTMLAnchorElement) {
    if (anchor.innerText === "Edit") {
      let href_Valid_Check_Var = anchor.href.split(":")[1];
      ViewCargoacceptanceComponent.totalGridData[href_Valid_Check_Var];
      this.onEditClick(
        ViewCargoacceptanceComponent.totalGridData[href_Valid_Check_Var]
      );
    }
  }

  onClick_Icon(editicon: HTMLImageElement) {
    let href_Valid_Check_Var = editicon.id.split(":")[1];
    ViewCargoacceptanceComponent.totalGridData[href_Valid_Check_Var];
    this.onEditClick(
      ViewCargoacceptanceComponent.totalGridData[href_Valid_Check_Var]
    );
  }

  onEditClick(obj: any): void {
    
    // this.ViewReportsData.shipmentIsFrom = "Edit";
    // this.ViewReportsData.shipmentId = obj.id;
    // this.ViewReportsData.organizationId = obj.org_id;
    let storeObj = {
      shipmentId : obj.id,
      organizationId : obj.org_id,
    }
    localStorage.setItem("CARGO_Acceptance_STORAGE",JSON.stringify(storeObj));
    localStorage.setItem("CARGO_Acceptance_From",JSON.stringify("Edit"));
    this.route.navigateByUrl("/CargoAcceptance/create-cargo-acceptance");
  }

  logindet = JSON.parse(localStorage.getItem("loginresultobject"));
  url ="";
  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
  source = {
    url: this.url,
    datatype: "json",
    datafields: [
      { name: "take_in", type: 'date', format: 'yyyy-MM-dd' },
      { name: "identification", type: "string" },
      { name: "total_weight", type: "string" },
      { name: "total_pieces", type: "string" },
      { name: "expected_total_pieces", type: "string" },
      {name: "expected_total_weight", type: "string"},
      { name: "actions_e", type: "string" },
      { name: "cargo_pdf", type: "string" },
      { name: "label", type: "string" },
      {name: "shipment_number", type: "string"},
      { name: "bonded", type: "string" },
      { name: "Destination", type: "string" },
      { name: "cargo_acc_location", type: "string" },
      { name: "cargoAccLocationCopy", type: "string" },
      { name: "shipper_name", type: "string" },
      { name: "address_lable", type: "string" },
     
  
      { name: "DG_labels", type: "string" },
      { name: "dangerous_goods", type: "string" },
      { name: "is_damaged", type: "string" },
      { name: "security_status", type: "string" },
      { name: "remarks", type: "string" },
      { name: "first_name", type: "string" },

      { name: "city", type: "string" },

      { name: "id", type: "string" },
      { name: "org_id", type: "string" },
      { name: "station_code", type: "string" },
      { name: "cargoImages", type: "string" },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "Rows",
    totalrecords: 800,
    cache: false,
    type: "GET",

    sort: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("sort");
    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("filter");
      this.spinner.show();
    },
    beforeprocessing: (data: any) => {
      this.spinner.hide();
      if (data.responseCode === 1) {
        if (data.Rows !== undefined) {
          if (data.Rows.length !== 0) {
            data.Rows = data.Rows;
            this.source.totalrecords = data.TotalRows;
            ViewCargoacceptanceComponent.totalGridData = data.Rows;
            if (data.activeCount != undefined && data.activeCount != null) {
              this.finalReportsCount = data.activeCount;
            } else {
              this.finalReportsCount = 0;
            }

            if(ViewCargoacceptanceComponent.TOTAL_GRIDDATA){
              if(ViewCargoacceptanceComponent.TOTAL_GRIDDATA.length === 0){
                ViewCargoacceptanceComponent.TOTAL_GRIDDATA =  data.Rows ;
              }else{
                ViewCargoacceptanceComponent.TOTAL_GRIDDATA = ViewCargoacceptanceComponent.TOTAL_GRIDDATA.concat(data.Rows) ;
              }
            }
           
            ViewCargoacceptanceComponent.TOTAL_GRIDDATA = this.removeDuplicates(ViewCargoacceptanceComponent.TOTAL_GRIDDATA);

            if (this.isSlectALL_records === true) {
              this.selectedRowsData = [];
              this.selectedRowsData = ViewCargoacceptanceComponent.TOTAL_GRIDDATA;
          
            }
          
              this.fn_Check_OrUncheckslectedrows_OnSearch();
          
            
          }
        } else {
          data.Rows = [];
          this.source.totalrecords = 0;
          if (data.activeCount != undefined && data.activeCount != null) {
            this.finalReportsCount = data.activeCount;
          } else {
            this.finalReportsCount = 0;
          }
        }
      } else {
        data.Rows = [];
        this.source.totalrecords = 0;
        this.finalReportsCount = 0;

        
      }
     
    },

    pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
     
    },
  };
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "90%";
    }

    return 850;
  }
  linkrenderer = function (row, column, value) {
    var html =
      "<a  href='" +
      ViewCargoacceptanceComponent.webapi +
      value +
      "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
    return html;
  };

  columnsrenderer = function (value) {
    return (
      '<div style="text-align: center; margin-top: 5px;">' + value + "</div>"
    );
  };
  dataAdapter = new jqx.dataAdapter(this.source, {
    //Set the http header before calling the api.
    beforeSend: function (jqXHR, settings) {
      let urlSplit1 = [];
      let urlSplit2 = [];
      // urlSplit1 = settings.url.toString().split("shipmentList");
      // urlSplit2 = urlSplit1[1].toString().split("?");
      // settings.url = urlSplit1[0] + "shipmentList?" + urlSplit2[0] + "&" + urlSplit2[1] ;
      this.loginresultobject = JSON.parse(
        localStorage.getItem("loginresultobject")
      );
      this.token = this.loginresultobject.token;
      jqXHR.setRequestHeader(
        "cache-control",
        "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
      ),
        jqXHR.setRequestHeader("pragma", "no-cache"),
        jqXHR.setRequestHeader("expires", "0"),
        jqXHR.setRequestHeader("authorization", this.token);
    },
  });
  getEditorDataAdapter = (datafield: string): any => {
    let dataAdapter = new jqx.dataAdapter(this.source, {
      uniqueDataFields: [datafield],
    });
    return dataAdapter;
  };
  input: any;
  public loginDataDup = JSON.parse(localStorage.getItem("loginresultobject"));

  columns = [
    {
      text: "Take In",
      datafield: `take_in`,
      cellsformat: 'yyyy-MM-dd H:mm',
      width:200,
      filtertype: 'range',
      editable: false,
      cellclassname: function (row, column, value, data) {
        // return value;
        // return data.take_in  = ViewCargoacceptanceComponent.convertUTCDateToLocalDate(value);
        return data.take_in;
      },
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {
    //  return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+ViewCargoacceptanceComponent.convertUTCDateToLocalDate(value) +"</div>")
    // return("<div style='margin-top:  8.5px; margin-left: 20px;'>"+data.take_in +"</div>")
      },
    },
    {
      text: "Actions",
      datafield: "actions_e",
      width: 90,
      filterable: false,
      sortable: false,
      groupable: false,
      editable: false,
      cellclassname: function (row, column, value, data) {
        // return data.take_in  = ViewCargoacceptanceComponent.convertUTCDateToLocalDate(value);
      },
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {
        // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
        return (
          "<div style='margin-top:  8.5px; margin-left: 20px;'><a  href='edit:" +
          columnData.uid +
          "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:" +
          columnData.uid +
          "' class='fa fa-pencil-square-o' style='padding:0 3px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a><div>"
        );
      },
      // renderer: this.columnsrenderer,
    },

    {
      text: "Pdf",
      datafield: "cargo_pdf",
      width: 50,
      filterable: false,
      sortable: false,
      groupable: false,
      editable: false,
      cellsrenderer: this.linkrenderer,
    },
    {
      text: "ID",
      datafield: "identification",
      width: 60,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    // { text: "Shipment Number", datafield: "shipment_number", width: 90, editable: false},
    { text: "Bonded", datafield: "bonded", width: 60, editable: false },
    { text: "Label", datafield: "label", width: 150, editable: false },
    // {
    //   text: "Shipment Number",
    //   datafield: "shipment_number",
    //   width: 60,
    //   editable: false,
    //   cellclassname: function (row, column, value, data) {},
    //   cellsrenderer: function (
    //     row,
    //     columnfield,
    //     value,
    //     defaulthtml,
    //     columnproperties,
    //     columnData
    //   ) {},
    // },
    {
      text: `<i class='fa fa-pencil'></i> Shipment Number`,
      datafield: "shipment_number",
      width: 150,
      cellbeginedit: function (row, column, value, data) {
        if (data !== '') {
          return true;
        } else {
          return false
        }
    },
      editable: true,
      columntype: "input",
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: 'Shipment Number',
      datafield: "shipment_numberCopy",
      editable: false,
      showfilterrow: true,
      width: 150,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    
    {
      text: `<i class='fa fa-pencil'></i> Storage Location`,
      datafield: "cargo_acc_location",
      width: 150,
      cellbeginedit: function (row, column, value, data) {
        if (data !== '') {
          return true;
        } else {
          return false
        }
    },
      editable: true,
      columntype: "input",
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: 'Storage Location',
      datafield: "cargoAccLocationCopy",
      editable: false,
      showfilterrow: true,
      width: 150,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    //   {
    //     text: `Storage Location`, columntype: 'template', datafield: 'cargo_acc_location', width: 80,
    //     createeditor: (row: number, cellvalue: any, editor: any, cellText: any, width: any, height: any): void => {
    //         // construct the editor.
    //         let container = document.createElement('input');
    //         container.className = 'Cargo';
    //         editor[0].appendChild(container);
    //         let options = {
    //             width: width, height: height,
    //             displayMember: 'cargo_acc_location',
    //             source: this.getEditorDataAdapter('cargo_acc_location')
    //         };
    //         this.input = jqwidgets.createInstance('.Cargo', 'jqxInput', options);
    //     },
    //     initeditor: (row: number, cellvalue: any, editor: any, celltext: any, pressedkey: any): void => {
    //         // set the editor's current value. The callback is called each time the editor is displayed.
    //         if (pressedkey) {
    //             this.input.val(pressedkey);
    //             this.input.selectLast();
    //         }
    //         else {
    //             this.input.val(cellvalue);
    //             this.input.selectAll();
    //         }
    //     },
    //     geteditorvalue: (row: number, cellvalue: any, editor: any): void => {
    //         // return the editor's value
    //         return this.input.val();
    //     }
    // },
    {
      text: "Destination",
      datafield: "Destination",
      width: 100,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    // {
    //   text: "TTL PCS",
    //   datafield: "total_pieces",
    //   width: 60,
    //   editable: false,
    //   cellclassname: function (row, column, value, data) {},
    //   cellsrenderer: function (
    //     row,
    //     columnfield,
    //     value,
    //     defaulthtml,
    //     columnproperties,
    //     columnData
    //   ) {},
    // },
    {
      text: "Expected pcs",
      datafield: "expected_total_pieces",
      width: 100,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    // {
    //   text: "TTL Weight",
    //   datafield: "total_weight",
    //   width: 60,
    //   editable: false,
    //   cellclassname: function (row, column, value, data) {},
    //   cellsrenderer: function (
    //     row,
    //     columnfield,
    //     value,
    //     defaulthtml,
    //     columnproperties,
    //     columnData
    //   ) {},
    // },
    {
      text: "Expected Weight",
      datafield: "expected_total_weight",
      width: 120,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Received pcs",
      datafield: "total_pieces",
      width: 100,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Received Weight",
      datafield: "total_weight",
      width: 120,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Shipper Name",
      datafield: "shipper_name",
      width: 90,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Shipper City",
      datafield: "city",
      width: 90,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Address labels available",
      datafield: "address_lable",
      width: 80,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Dangerous Goods",
      datafield: "dangerous_goods",
      width: 80,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "DG labels available",
      datafield: "DG_labels",
      width: 80,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Security status",
      datafield: "security_status",
      width: 80,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },

    // {
    //   text: "Station", datafield: "station_code",
    //   cellclassname: function (row, column, value, data) {

    //   },
    //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
    //   },
    // },
    {
      text: "Any Damage Noticed",
      datafield: "is_damaged",
      width: 80,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Remarks",
      datafield: "remarks",
      width: 80,
      // cellbeginedit: ,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Accepted by",
      datafield: "first_name",
      width: 90,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    {
      text: "Station",
      datafield: "station_code",
      width: 90,
      editable: false,
      cellclassname: function (row, column, value, data) {},
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columnData
      ) {},
    },
    // {
    //   text: "Weight", datafield: "weight", width: 100,
    //   cellclassname: function (row, column, value, data) {

    //   },
    //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
    //   },
    // },
    // {
    //   text: "length", datafield: "length",width: 100,
    //   cellclassname: function (row, column, value, data) {

    //   },
    //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
    //   },
    // },
    // {
    //   text: "Width", datafield: "width", width: 100,
    //   cellclassname: function (row, column, value, data) {

    //   },
    //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
    //   },
    // },
    // {
    //   text: "Height", datafield: "height", width: 100,
    //   cellclassname: function (row, column, value, data) {

    //   },
    //   cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columnData) {
    //   },
    // },
    { text: "Id", datafield: "id", hidden: true, editable: false },
    { text: "Org Id", datafield: "org_id", hidden: true, editable: false },
    { text: "cargoImages", datafield: "cargoImages", hidden: true, editable: false },
  ];

//   cellbeginedit(row, column, value, data){

//         if (value == undefined || value !== '' || value !== null) {
//           return true;
//         } else {
//           return false
//         }
// };
  rendergridrows = (params: any): any => {
    return params.data;
  };

  onCellBeginEdit(event: any): void {
    
    let args = event.args;
    let columnDataField = args.datafield;
    let row = args.row;
    let cellValue = args.value;
  }
  onCellEndEdit(event: any): void {
    
    let args = event.args;
    let columnDataField = args.datafield;
    let row = args.row;
    let cellValue = args.value;
    let oldValue = args.oldvalue;
    if (!oldValue && !cellValue) {
    } else if (cellValue && oldValue) {
      if (cellValue === oldValue) {
      } else {
        let obj;
        let OrgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
        if(columnDataField === 'shipment_number'){
          obj = {
            loginUserId: OrgDetails.userId,
            cargoId: row.id,
            shipment_number: cellValue,
            // updatedOn : this.convertToIso(new Date()),
            updatedOn : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            flag: 'sn'
          };
        } else{
          obj = {
            loginUserId: OrgDetails.userId,
            cargoId: row.id,
            storageLocation: cellValue,
            // updatedOn : this.convertToIso(new Date()),
            updatedOn : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            flag: 'sl'
          };
        }
       
       
        this.cargoAcceptanceService
          .updateStorageLocation(obj)
          .subscribe((resp) => {
            this.slResp = resp;
            if (this.slResp.responseCode === 1) {
              if(columnDataField === 'shipment_number'){
                this.toastr.success(
                  `Shipment Number updated successfully`
                );
              }else{
                this.toastr.success(
                  `Storage Location updated successfully`
                );
              }
              // this.toastr.success(
              //   `${oldValue} to ${cellValue} ${this.slResp.message}`
              // );
              let selectedTab = JSON.parse(
                localStorage.getItem("selected_Shipments_Tab")
              );
              if (selectedTab !== null) {
                this.OnChangeReports_Tab(selectedTab);
              } else {
                this.OnChangeReports_Tab("A");
              }
            }
          });
      }
    }
  }

  onRowDoubleClick(rowData) {
    // this.ViewReportsData.shipmentId = rowData.args.row.bounddata.id;
    // this.ViewReportsData.organizationId = rowData.args.row.bounddata.org_id;
    
    let obj = {
      shipmentId : rowData.args.row.bounddata.id,
      organizationId : rowData.args.row.bounddata.org_id
    }
    localStorage.setItem("CARGO_Acceptance_STORAGE",JSON.stringify(obj));
    this.route.navigateByUrl("/CargoAcceptance/view-cargo-acceptance-details");
  }

  fn_To_Archive() {
    if (this.selectedRowsData.length !== 0) {
      this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
      let inputObj = {
        loginUserId: this.logindet.userId,
        orgId: this.orgDetails.orgId,
        cargoId: [],
        // updatedOn: this.convertToIso(new Date())
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      };
      for (let i = 0; i < this.selectedRowsData.length; i++) {
        inputObj.cargoId.push(this.selectedRowsData[i].id);
      }
      this.spinner.show();
      this.cargoAcceptanceService
        .api_To_Archive_cargoacceptance(inputObj)
        .subscribe(
          (responseData) => {
            this.spinner.hide();
            this.CargoACCResp = responseData;
            if (this.CargoACCResp.responseCode === 1) {
              this.toastr.success(this.CargoACCResp.message);
              this.myGrid.gotopage(0);
              this.myGrid.updatebounddata("refresh");
              this.myGrid.clearselection();
            } else {
              this.toastr.error(this.CargoACCResp.message);
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    } else {
      this.toastr.error("Please select atleast one record");
    }
  }

  public selectedRowsData = [];
  _onChangeRowSelection(event) {
    // trigger when checkbox checked / unchecked
    this.isSlectALL_records = false;
    if (Array.isArray(event.args.rowindex)) {
      if (event.args.rowindex.length !== 0) {
        this.selectedRowsData = ViewCargoacceptanceComponent.TOTAL_GRIDDATA.filter(item=>item);
        this.isSlectALL_records = true;
      } else {
        this.selectedRowsData = [];
      }
    } else {
      if (event.type === "rowunselect") {
        let index = this.selectedRowsData.findIndex(
          (x) => x.id === event.args.row.id
        );
        this.selectedRowsData.splice(index, 1);
      } else {
        this.selectedRowsData.push(event.args.row);
      }
    }
   
  }

  //redirect to Uld damage reports
  onClickCargoClaim_Reports(){
    localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
    this.router.navigateByUrl('/cargoClaim/dashboard/claim-count');
  }

  // redirect to handler cargo image
  onClickCargo_Image(){
    localStorage.setItem("currentModule",JSON.stringify("cargoImage"))
    this.router.navigateByUrl('/CargoImage/view-shipment');
  }
  //redirect to damage reports
  onClickDamage_Reports() {
    this.spinner.show();

    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("CARGO_DAMAGE"));
      this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));

      if (
        this.loginDetails.userType == "S" &&
        this.loginDetails.orgType == "H"
      ) {
        this.router.navigateByUrl("/csa-dashboard");
      } else if (
        this.loginDetails.userType == "S" &&
        this.loginDetails.orgType == "A"
      ) {
        this.router.navigateByUrl("/csa-airline-dashboard");
      } else if (
        this.loginDetails.userType == "A" &&
        this.loginDetails.orgType == "A"
      ) {
        this.router.navigateByUrl("/admin-airline-dashboard");
      } else if (
        this.loginDetails.userType == "A" &&
        this.loginDetails.orgType == "H"
      ) {
        this.router.navigateByUrl("/admin-handler-dashboard");
      } else if (this.loginDetails.userType == "E") {
        this.router.navigateByUrl("/user-dashboard");
      } else if (this.loginDetails.orgType == "E") {
        this.router.navigateByUrl("/HandlerDashboardComponent");
      } else {
        localStorage.clear();
        this.router.navigateByUrl("/Home");
      }
    }, 200);
  }

  OnClickCargoDamage(){
    this.spinner.show();
    setTimeout(() => {
        localStorage.setItem("currentModule",JSON.stringify("CARGODAMAGE-FORWARDER"))
    this.route.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder'); 
    }, 200);
}

OnClickImportAcceptance(){
  // this.spinner.show();
    // setTimeout(() => {
        // localStorage.setItem("currentModule",JSON.stringify("IMPORT_FORWARDER"))
        this.route.navigateByUrl("/CargoAcceptance/view-import-acceptance");
    // }, 200);
}

OnClickTruckInspection() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
    this.route.navigateByUrl('/Truckinspection/view-truck-inspection');
  }, 200);
}


onExportClick(): void {
   
  let deleteColumns = ['actions_e','cargo_pdf','org_id','uid','boundindex','uniqueid','visibleindex','id',"cargo_acc_location","cargoImages"];
  let reportsArray = [];
  // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
  reportsArray = this.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
 let columnsData = this.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
         return (!deleteColumns.includes(item.datafield)) ;
 });  //Excel columns Header And width obj array
  for (let i = 0; i < reportsArray.length; i++) { 
    reportsArray[i]['take_in'] = ViewCargoacceptanceComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
    let imgTempArray = [];
    if(reportsArray[i]['cargoImages'] !== undefined){
      if(reportsArray[i]['cargoImages'].length !== 0){
        for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
          imgTempArray.push({
            image : ViewCargoacceptanceComponent.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
           
          })
        }
      }
    }
    
      reportsArray[i]['Images'] = imgTempArray;
    //  columnsData.push("Images");
    for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
        delete  reportsArray[i][deleteColumns[j]];
    }
  }
  this.excelService.exportAsExcelFile(reportsArray,`cargoAcceptance_${(new Date()).toString().substring(0,24)}_page_${this.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
}


scaleColumnWidth(width:any,scale :number =10){
   return (width / scale)
}

convertDate(dateTxt: string) {
  let date = new Date(dateTxt);
  return date.getFullYear() + "/" + ((date.getMonth() + 1).toString().length === 1 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "/" + ((date.getDate()).toString().length === 1 ? ("0" + (date.getDate())) : (date.getDate())) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}

static convertUTCDateToLocalDate(date1){
  let dt = this.utcToLocal(date1, moment.tz.guess());
  return  dt === "Invalid Timezone Operator" ? date1 : dt;
  }
  

 static utcToLocal(utcdateTime, tz) {
   var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
   var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
   var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
   var localDateTime
   var hours = zoneValue.split(":")[0]
   var minutes = zoneValue.split(":")[1]
   if (operator === "-") {
       localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
   } else if (operator) {
       localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
   } else {
       localDateTime = "Invalid Timezone Operator"
   }
   return localDateTime
}

convertToIso(date: Date){
  let dt  = date.toISOString();
  return   dt.substring(0,10)+" "+ dt.substring(11,19);
}
onClick_Create_CargoAcceptance() {
  localStorage.setItem("CARGO_Acceptance_From",JSON.stringify("Create"));
  this.router.navigateByUrl('/CargoAcceptance/create-cargo-acceptance') ;
}

removeDuplicates(array)  {
    let uniq = {};
    return array.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
  }


  fn_Check_OrUncheckslectedrows_OnSearch(){
    if (this.selectedRowsData.length !== 0) {
      //need for logic :: -- when we select records and apply filter / search then the list by default coming with selected checkboxes irrespective of selected records present or not.
      setTimeout(() => { //----- uncheck the checkboxes when the record is not present in selected list after filter applied
        let checkboxesRefArray = document.getElementsByClassName("jqx-widget jqx-checkbox");
        if (checkboxesRefArray.length !== 0) {
          let selectedRows_copy = [];
          selectedRows_copy = this.selectedRowsData.filter(item => {
            return item;
          });
          if (selectedRows_copy.length !== 0) {
            this.myGrid.clearselection();
            for (let i = 0; i < checkboxesRefArray.length; i++) {
              let rowData = this.myGrid.getrowdata(i);
              if(rowData){
                for (let j = 0; j < selectedRows_copy.length; j++) {
                  if (selectedRows_copy[j]['id'] === rowData['id']) {
                    this.myGrid.selectrow(i)
                  }
                }
              }
            }
          }
          this.selectedRowsData = selectedRows_copy;
       
        }
        
      }, 2000);
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
    } else {
      this.spinner.hide();
    }
  };

  onExportAsXmlClick():void {
    let deleteColumns = ['actions_e','cargo_pdf','org_id','uid','boundindex','uniqueid','visibleindex','id',"cargo_acc_location","cargoImages"];
    let reportsArray = [];
    reportsArray = this.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
    
    for (let i = 0; i < reportsArray.length; i++) { 
      reportsArray[i]['take_in'] = ViewCargoacceptanceComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
      let imgTempArray = [];
      if(reportsArray[i]['cargoImages'] !== undefined){
        if(reportsArray[i]['cargoImages'].length !== 0){
          for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
            imgTempArray.push({
              image : ViewCargoacceptanceComponent.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
             
            })
          }
        }
      }
      
      reportsArray[i]['Images'] = imgTempArray;
      for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
          delete  reportsArray[i][deleteColumns[j]];
      }
    }
    let strXMl = this.OBJtoXML({ reportsData : {report : reportsArray}});
    let formattedXML = this.formatXml('<?xml version="1.0" encoding="UTF-8"?>'+strXMl,'\t');
    var file = new File([formattedXML], `cargoAcceptance_${(new Date()).toString().substring(0,24)}_page_${this.myGrid.getpaginginformation().pagenum+1}`, {type: "text/xml"});
    FileSaver.saveAs(file);
  }

  formatXml(xml : string, tab : string) { // tab = optional indent value, default is tab (\t)
    var formatted = '', indent= '';
    tab = tab || '\t';
    xml.split(/>\s*</).forEach(function(node) {
        if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^\/]$/ )) indent += tab;              // increase indent
    });
    return formatted.substring(1, formatted.length-3);
  };

   OBJtoXML(obj:Object) :string {
    var xml = '';
    for (var prop in obj) {  // loop through the object properties
        if (obj[prop] instanceof Array) {    // If  object prop is array
            for (var array in obj[prop]) {
                xml += '<' + prop + '>';
                xml += this.OBJtoXML(new Object(obj[prop][array]));
                xml += '</' + prop + '>';
            }
        } else { // If  object prop is Not array
            xml += '<' + prop + '>';
            typeof obj[prop] == 'object' ? xml += this.OBJtoXML(new Object(obj[prop])) : xml += obj[prop]; // If  object prop is object then recalling the xml fun else concating the string 
            xml += '</' + prop + '>';
        }
    }
    var xml =  xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml;
  };

}


