import { copyObj } from '@angular/animations/browser/src/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from 'src/app/config-service.service';

@Injectable({
  providedIn: 'root'
})
export class TruckInspectionService {

  webAPI:string ; 
  constructor(private http:HttpClient ,config : ConfigServiceService) {
   this.webAPI = config.getConfig().ULD_Url
   }
   
  viewDetailedRecord(obj){
    return this.http.post<any>(this.webAPI + "inspectionApis/getDetailedInspection.php/detailedInspection",obj)
  }
  viewModifiedRecord(obj){
    return this.http.post<any>(this.webAPI + "inspectionApis/getModifiedInspection.php/modifiedInspection",obj)
  }
  create_Update_TruckInspec_Record(obj,api){
    // return this.http.post<any>(this.webAPI + "inspectionApis/createInspection.php/createInspection",obj);
    return this.http.post<any>(this.webAPI + api,obj);
  }
  create_TruckInspec_Record(obj){
    return this.http.post<any>(this.webAPI + "inspectionApis/createInspection.php/createInspection",obj);
    // return this.http.post<any>(this.webAPI + api,obj);
  }
  SetReportArchive(obj){
    return this.http.post<any>(this.webAPI + "inspectionApis/archiveInspection.php/archiveInspection",obj);
    // return this.http.post<any>(this.webAPI + api,obj);
  }
  DeleteReport(obj){
    return this.http.post<any>(this.webAPI + "inspectionApis/deleteInspection.php/deleteInspection",obj);
    // return this.http.post<any>(this.webAPI + api,obj);
  }
 

  // cargo damage services  
  SetCargoDamage_ReportArchive(obj){
    return this.http.post<any>(this.webAPI + "uldCargoApis/archiveUldCargo.php/archiveCargo",obj);
  }


  create_Update_CargoDamage_Record(obj,api){
    return this.http.post<any>(this.webAPI + api,obj);
  }
  viewDetailedCargoDamage(obj){
    return this.http.post<any>(this.webAPI + "uldCargoApis/getDetailedUldCargo.php/detailedUldCargo",obj)
  }
  get_orign_destList(obj){
    return this.http.post<any>(this.webAPI + "cclpApis/tracking.php/getTruckAirport",obj)
  }
  get_ModifiedReport_View(inputData){
    return this.http.post<any>(this.webAPI + "uldCargoApis/getModifiedUldCargo.php/modifiedUldCargo ", inputData) ;
}

}
