import { Component, OnInit, enableProdMode ,ViewChild,Renderer2, ElementRef} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

declare var jQuery: any;


if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-report-access',
    templateUrl: './report-access.component.html',
    styleUrls: ['./report-access.component.css']
})
export class ReportAccessComponent implements OnInit {
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    public pluginInputData: any
    LoginObj: any;
    public static totalData =[];
    usersAccessReportList = [];
    usersList: any[];
    anchors: any;
    editicons: any;
    constructor(
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private superAdminservice: SuperadminService,
        private spinner: NgxSpinnerService,
        private renderer: Renderer2, private elementRef: ElementRef
    ) { }
    ngAfterViewInit() {
        this.myGrid.refresh();
          }
          ngAfterViewChecked(){
           
            this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
            this.editicons = this.elementRef.nativeElement.querySelectorAll('i');
            this.anchors.forEach((anchor: HTMLAnchorElement) => {
                if(anchor.href != ''){
                    let href_Split_Array =[];
                    href_Split_Array = anchor.href.split(":");
                    let href_Valid_Check_Var = href_Split_Array[0];
                    if( (anchor.innerText === 'Delete report access') && (href_Valid_Check_Var === 'delete' ) ){
                        anchor.addEventListener('click', this.handleAnchorClick)
                    }
                }  
            })
            this.editicons.forEach((editicon: HTMLImageElement) => {
                if(editicon.id != ''){
                    let href_Split_Array =[];
                    href_Split_Array = editicon.id.split(":");
                    let href_Valid_Check_Var = href_Split_Array[0];
                    if(href_Valid_Check_Var === 'delete' ){
                        editicon.addEventListener('click', this.handleiconClick)
                    }
                }  
            })
        }
    
    ngOnInit() {
        
        this.spinner.show();
        this.LoginObj = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log('this.LoginObj', this.LoginObj);
       
        this.superAdminservice.getAccessUserReport(this.LoginObj.orgId).subscribe(data => {
            this.spinner.hide();
            console.log("------------getAccessUserReportdata----", data);
            if (data.responseCode === 1) {
                
               
                this.spinner.hide();
                this.usersAccessReportList = data.viewReportUsersList;
                console.log("------------", this.usersAccessReportList);


            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
             else if (data.responseCode === 0) {
               
                if (data.message === "No data Found") {
                    this.usersAccessReportList = [];
                }

            }
            this.source.localdata = this.usersAccessReportList;
            this.myGrid.source(this.dataAdapter);
            ReportAccessComponent.totalData = this.usersAccessReportList;
        },
            error => {
                this.spinner.hide();
                //this.toastr.error(data.message, '', { timeOut: 1200 });

            })
    }
    public handleAnchorClick = (event: Event) => {
        // 
         // Prevent opening anchors the default way
         event.preventDefault();
         const anchor = event.target as HTMLAnchorElement;
       this.fn_alert(anchor,event);
       }
       public handleiconClick = (event: Event) => {
        // 
         // Prevent opening anchors the default way
         event.preventDefault();
         const editicon = event.target as HTMLImageElement;
       this.fn_editIcon(editicon,event);
       }
       fn_alert(anchor,event){
        if(anchor.innerText==='Delete report access'){
            console.log(this.myGrid.getselectedcell());
            let href_Split_Array = [];
          href_Split_Array = anchor.href.split(":");
          let href_Valid_Check_Var = href_Split_Array[1];
          let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var);
          this.usersAccessReportList[href_Valid_Check_Var];
          this.getSelectedDataOfDelete(this.usersAccessReportList[href_Valid_Check_Var],'N');
        }
    }
    fn_editIcon(editicon,event){
        let href_Split_Array = [];
               href_Split_Array = editicon.id.split(":");
               let href_Valid_Check_Var = href_Split_Array[1];
             if(href_Split_Array[0]==='delete'){
                 console.log(this.myGrid.getselectedcell());
               let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
              this.usersAccessReportList[href_Valid_Check_Var];
              this.getSelectedDataOfDelete(this.usersAccessReportList[href_Valid_Check_Var],'N');
             }
    }
    source =
    {
        localdata: this.usersAccessReportList,
        datatype: 'json',
        datafields:
            [{ name: 'activities', type: 'string'},
            { name: 'firstName', type: 'string'},
            { name: 'lastName', type: 'string'},
            { name: 'EmialID', type: 'string'},
            {name: 'stationCode',type: 'string'},
              ],
       // pagesize: 2,
        beforeprocessing: function (data) {
            // this.source.totalrecords = data[0].TotalRows;
        },
        pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
            // callback called when a page or page size is changed.
            console.log(pagenum);
        },
        rendergridrows: function (params) {
            return params.data;
        }
    };
    dataAdapter = new jqx.dataAdapter(this.source);
    columns =
    [
      { text: 'Activities', datafield: 'activities',width:90, 
      cellsrenderer: function (row,index,data) {
        
        
        //    
          return "<div style='margin-top:  8.5px; margin-left: 5px;'><a data-toggle='tooltip' title='Delete report access' style='color:red; font-size: 18px;margin-left: 15px;' href='delete:"+ row +"'><i id = 'delete:"+ row +"' class='fa fa-trash-o' aria-hidden='true'></i></a></div>";
      },
    },
        { text: 'First name', datafield: 'firstName',width:300, },
        { text: 'Last Name', datafield: 'lastName',width:320,},
        { text: 'Email Id', datafield: 'EmialID',width:300,},
        { text: 'Station', datafield: 'stationCode',width:320 },
  ];
    getSelectedDataOfDelete(val, status) {
        
        console.log(val, "deleteData");
        if (val.userType === "S") {
            this.toastr.error("Unable to delete access for Admin" ,'', { timeOut: 1500 })
        } else {
            localStorage.setItem("AccessType", JSON.stringify(status));
            localStorage.setItem("userdeleteData", JSON.stringify(val));
            jQuery('#Modal').modal({ backdrop: 'static', keyboard: false });
        }


    }
    DeleteUser() {
        
        let AccessType = JSON.parse(localStorage.getItem('AccessType'));
        let data = JSON.parse(localStorage.getItem('userdeleteData'));
        this.usersList = [];
        this.usersList.push({ userId: data.userId });
        let dataObject = {
            accessType: AccessType,
            usersList: this.usersList,
        }
        this.spinner.show();
        this.superAdminservice.deleteAccessUser(dataObject).subscribe(
            data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.ngOnInit();
                    
                    this.toastr.success(data.message, '', { timeOut: 1200 });
                    jQuery('#Modal').modal('hide');

                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#Modal').modal('hide');
                } 
                 else {
                    this.toastr.error(data.message, '', { timeOut: 1200 });
                    jQuery('#Modal').modal('hide');
                }
            },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', { timeOut: 1200 });
                jQuery('#Modal').modal('hide');
            })
    }


}
