import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
import { LoginDataService } from '../../login/login-data.service';
import { SwitchdashboardService } from '../../services/switch-dashboard/switchdashboard.service'
import { ViewReportsData } from '../../data/DataShareViewDetails'
import { SidebarComponent } from '../../layouts/sidebar/sidebar.component'
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { globalConstants } from 'src/environments/globalConstants';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

// import { ConsoleReporter } from 'jasmine';

declare var jQuery: any;
@Component({
    selector: 'app-sa-dashboard',
    templateUrl: './sa-dashboard.component.html',
    styleUrls: ['./sa-dashboard.component.css']
})
export class SaDashboardComponent implements OnInit {

    @ViewChild('myGrid') myGrid: jqxGridComponent;
    public static totaldata = [];

    dummyObj: any;
    searchItem: any;
    Allp = 1;
    AllpageIndex = 0;
    AllpageSize = 4;
    AlllowValue = 0;
    AllhighValue = 4;

    authp = 1;
    authpageIndex = 0;
    authpageSize = 4;
    authlowValue = 0;
    authhighValue = 4;

    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 4;
    unauthlowValue = 0;
    unauthhighValue = 4;

    premiump = 1;
    premiumpageIndex = 0;
    premiumpageSize = 4;
    premiumlowValue = 0;
    premiumhighValue = 4;

    nonpremiump = 1;
    nonpremiumpageIndex = 0;
    nonpremiumpageSize = 4;
    nonpremiumlowValue = 0;
    nonpremiumhighValue = 4;



    // <----airlines---->
    Allair = 1;
    AllairpageIndex = 0;
    AllairpageSizes = 6;
    AllairlowValues = 0;
    AllairhighValues = 6;


    authair = 1;
    authpageIndexs = 0;
    authpageSizes = 6;
    authlowValues = 0;
    authhighValues = 6;

    unauthpair = 1;
    unauthpageIndexs = 0;
    unauthpageSizes = 6;
    unauthlowValues = 0;
    unauthhighValues = 6;

    premiumpair = 1;
    premiumpageIndexs = 0;
    premiumpageSizes = 6;
    premiumlowValues = 0;
    premiumhighValues = 6;

    nonpremiumpair = 1;
    nonpremiumpageIndexs = 0;
    nonpremiumpageSizes = 6;
    nonpremiumlowValues = 0;
    nonpremiumhighValues = 6;

    // <----farworders---->
    Allfarworders = 1;
    AllfarworderspageIndex = 0;
    AllfarworderspageSizes = 6;
    AllfarworderslowValues = 0;
    AllfarwordershighValues = 6;


    authfarworders = 1;
    authfarworderspageIndexs = 0;
    authfarworderspageSizes = 6;
    authfarworderslowValues = 0;
    authfarwordershighValues = 6;

    unauthpfarworders = 1;
    unauthfarworderspageIndexs = 0;
    unauthfarworderspageSizes = 6;
    unauthfarworderslowValues = 0;
    unauthfarwordershighValues = 6;

    premiumpfarworders = 1;
    premiumfarworderspageIndexs = 0;
    premiumfarworderspageSizes = 6;
    premiumfarworderslowValues = 0;
    premiumfarwordershighValues = 6;

    nonpremiumpfarworders = 1;
    nonpremiumpagefarwordersIndexs = 0;
    nonpremiumpagefarwordersSizes = 6;
    nonpremiumlowfarwordersValues = 0;
    nonpremiumhighfarwordersValues = 6;





    Obj: any;
    role: string;
    authorizedOrgs = [];
    unAuthorizedOrgs = [];
    premiumOrgs = [];
    nonPremiumOrgs = [];
    allAUOrgs = [];
    allPNOrgs = [];

    search_Allorgs = [];
    serach_authorgs = [];
    serach_unauthorgs = [];
    serach_premium = [];
    serach_nonpremium = [];


    search_Allairorgs = [];
    serach_authairorgs = [];
    serach_unauthairorgs = [];
    serach_premiumair = [];
    serach_nonpremiumair = [];


    search_allFarwordersorgs = [];
    serach_authFarwordersorgs = [];
    serach_unauthFarwordersorgs = [];
    serach_premiumFarworders = [];
    serach_nonpremiumFarworders = [];

    allStatus: boolean;
    authStatus: boolean;
    nonauthStatus: boolean;
    premiumStatus: boolean;
    nonpremiumStatus: boolean;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    activateObj: { "organizationId": any; "status": string; };
    loginDetails: any;
    delObj: { "orgId": any; "orgType": any; };
    Organizationsairlines: any;
    allairlinesOrgs: any;
    allPNairlinesOrgs: any;
    authorizedairlineOrgs: any;
    unAuthorizedairlineOrgs: any;
    premiumairlineOrgs: any;
    nonPremiumairlineOrgs: any;
    allStatusair: boolean;
    authStatusair: boolean;
    nonauthStatusair: boolean;
    premiumStatusair: boolean;
    nonpremiumStatusair: boolean;
    searchItems: string;
    authObj: {};
    reportsList: any;
    comapanydetails: any;
    subscribedParam: any;
    cookieValue: string;
    agentOrganizationDetails: any;
    allFarwordersOrg: any = [];
    allForwareders: boolean;
    authorizedForwareders: boolean;
    unAuthorizedForwareders: boolean;
    nonPremiumForwareders: boolean;
    premiumForwareders: boolean;
    Farworders_allPNOrgs: any = [];
    Farworders_authorizedOrgs: any = [];
    Farworders_unAuthorizedOrgs: any = [];
    Farworders_premiumOrgs: any = [];
    Farworders_nonPremiumOrgs: any = [];
    allFarwordersOrgs: any[];
    authorizedFarwordersOrgs: any[];
    unAuthorizedFarwordersOrgs: any[];
    premiumFarwordersOrgs: any[];
    nonPremiumFarwordersOrgs: any[];
    ForwarderssearchItems: string;
    concatarray: any;
    static viewtotaldata: any[];
    anchors: any;
    icons: any;
    images: any;
    spantexts: any;
    filterorganisationlist: any[];
    selected: any;
    constructor(private cd: ChangeDetectorRef,
        private LoginDataService: LoginDataService,
        private readonly route: ActivatedRoute,
        private router: Router,
        private elementRef: ElementRef,
        private SwitchdashboardService: SwitchdashboardService,
        private toastr: ToastrService,
        private superAdminservice: SuperadminService,
        private spinner: NgxSpinnerService,
        private ViewReportsData: ViewReportsData,
        private SidebarComponent: SidebarComponent, private cookieService: CookieService,private location: LocationStrategy
    ) {
        history.pushState(null, null, window.location.href);  
        this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });  
     }

    ngOnInit() {
        
        window.scroll(0, 0);
        this.selected = 'all';
        this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
        this.emailid = this.Obj.emailId;
        this.Password = this.loginDetails;
        this.usertype = this.Obj.userType;
        this.allairlinesOrgs = [];
        this.allPNairlinesOrgs = [];
        this.authorizedairlineOrgs = [];
        this.unAuthorizedairlineOrgs = [];
        this.premiumairlineOrgs = [];
        this.nonPremiumairlineOrgs = [];
        this.allStatusair = true;
        this.authStatusair = false;
        this.nonauthStatusair = false;
        this.premiumStatusair = false;
        this.nonpremiumStatusair = false;
        // this.SidebarComponent.activecall("1")
        this.spinner.show();
        this.superAdminservice.getSuperAdminDashBoard(this.emailid, this.usertype).subscribe(
            data => {
                
                console.log(data)
                this.spinner.hide();
                if (data.responseCode == '1') {
                    
                    // this.toastr.success('Login Successfully', '', {});
                    localStorage.setItem('saDashBoardData', JSON.stringify(data));
                    localStorage.setItem('DashBoardData', JSON.stringify(data));
                    this.Object = data;
                    this.Organizations = data.organizationDetails;
                    this.agentOrganizationDetails = data.agentOrganizationDetails;
                    this.Organizationsairlines = data.airlinesOrganizationDetails;
                    this.concatarray = [];
                    this.concatarray= this.concatarray.concat(this.Organizations);
                    this.concatarray= this.concatarray.concat(this.agentOrganizationDetails);
                    this.concatarray= this.concatarray.concat(this.Organizationsairlines);
                    //  console.log('concat', this.concatarray);

                     for(let i = 0; i < this.concatarray.length; i++){
                            if(this.concatarray[i].orgType == 'H'){
                                this.concatarray[i].orgFullType = 'Handler';
                            }else if(this.concatarray[i].orgType == 'E'){
                                this.concatarray[i].orgFullType = 'Forwarders';
                            }else if(this.concatarray[i].orgType == 'A'){
                                this.concatarray[i].orgFullType = 'Airline';
                            }

                            if(this.concatarray[i].status == 'A'){
                                this.concatarray[i].statusFull = 'Active';
                            }else if(this.concatarray[i].status == 'I'){
                                this.concatarray[i].statusFull = 'Inactive';
                            }

                         }
                         localStorage.setItem('Lconcatarray', JSON.stringify(this.concatarray));
                         SaDashboardComponent.viewtotaldata = this.concatarray;
                    this.source.localdata = this.concatarray;
                    this.myGrid.source(this.dataAdapter);

                   

                    for (let index = 0; index < this.Organizations.length; index++) {
                        if (this.Organizations[index].authStatus == 'A') {
                            this.allAUOrgs.push(this.Organizations[index]);

                        } else if (this.Organizations[index].authStatus == 'I') {
                            this.allAUOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {
                        if (this.Organizations[index].premiumUser == 'A') {
                            this.allPNOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].premiumUser == 'I') {
                            this.allPNOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {

                        if (this.Organizations[index].authStatus == 'A') {
                            this.authorizedOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].authStatus == 'I') {
                            this.unAuthorizedOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {

                        if (this.Organizations[index].premiumUser == 'A') {
                            this.premiumOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].premiumUser == 'I' || this.Organizations[index].premiumUser == '' || this.Organizations[index].premiumUser == 'N') {
                            this.nonPremiumOrgs.push(this.Organizations[index]);
                        }
                    }

                    // -------airlines----//

                    this.Organizationsairlines = data.airlinesOrganizationDetails;
                    for (let index = 0; index < this.Organizationsairlines.length; index++) {
                        if (this.Organizationsairlines[index].authStatus == 'A') {
                            this.allairlinesOrgs.push(this.Organizationsairlines[index]);

                        } else if (this.Organizationsairlines[index].authStatus == 'I') {
                            this.allairlinesOrgs.push(this.Organizationsairlines[index]);
                        }
                    }

                    for (let index = 0; index < this.Organizationsairlines.length; index++) {
                        if (this.Organizationsairlines[index].premiumUser == 'A') {
                            this.allPNairlinesOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].premiumUser == 'I') {
                            this.allPNairlinesOrgs.push(this.Organizationsairlines[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizationsairlines.length; index++) {

                        if (this.Organizationsairlines[index].authStatus == 'A') {
                            this.authorizedairlineOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].authStatus == 'I') {
                            this.unAuthorizedairlineOrgs.push(this.Organizationsairlines[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizationsairlines.length; index++) {
                        if (this.Organizationsairlines[index].premiumUser == 'A') {
                            this.premiumairlineOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].premiumUser == 'I' || this.Organizationsairlines[index].premiumUser == '' || this.Organizationsairlines[index].premiumUser == 'N') {
                            this.nonPremiumairlineOrgs.push(this.Organizationsairlines[index]);
                        }
                    }


                    // -------airlines end----//

                    //---------agentOrganizationDetails -----//
                    this.agentOrganizationDetails = data.agentOrganizationDetails;

                    this.agentOrganizationDetails.forEach(data => {
                        
                        // console.log(data);
                        this.allFarwordersOrg.push(data);
                        this.Farworders_allPNOrgs.push(data);
                        if (data.authStatus == 'A') {
                            this.Farworders_authorizedOrgs.push(data);
                        } else if (data.authStatus == 'I') {
                            this.Farworders_unAuthorizedOrgs.push(data);
                        }
                        if (data.premiumUser == 'A') {
                            this.Farworders_premiumOrgs.push(data);
                        } else if (data.premiumUser == 'I') {
                            this.Farworders_nonPremiumOrgs.push(data);
                        }
                    })
                    //---------agentOrganizationDetails -----//


                    this.search_Allorgs = this.allAUOrgs;
                    this.serach_authorgs = this.authorizedOrgs;
                    this.serach_unauthorgs = this.unAuthorizedOrgs;
                    this.serach_premium = this.premiumOrgs;
                    this.serach_nonpremium = this.nonPremiumOrgs;
                    this.tabChange('All');

                    // -------airlines search----//
                    this.search_Allairorgs = this.allairlinesOrgs;
                    this.serach_authairorgs = this.authorizedairlineOrgs;
                    this.serach_unauthairorgs = this.unAuthorizedairlineOrgs;
                    this.serach_premiumair = this.premiumairlineOrgs;
                    this.serach_nonpremiumair = this.nonPremiumairlineOrgs;
                    this.tabChanges('Allairline');
                    // -------airlines end----//


                    // -------Farworders search----//
                    this.search_allFarwordersorgs = this.allFarwordersOrg;
                    this.serach_authFarwordersorgs = this.Farworders_authorizedOrgs;
                    this.serach_unauthFarwordersorgs = this.Farworders_unAuthorizedOrgs;
                    this.serach_premiumFarworders = this.Farworders_premiumOrgs;
                    this.serach_nonpremiumFarworders = this.Farworders_nonPremiumOrgs;
                    this.forwardertabChanges('allForwareders');
                    // -------Farworders end----//
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session expired, please login again");
                }else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
                
            },
            error => {
                this.spinner.hide();
            }
        )
        let storedActiveTab = localStorage.getItem('ActiveTab');
        if (storedActiveTab) {
            this.tabChange(storedActiveTab);
        } else {
            this.tabChange('All');
        }

        let storedActiveTab1 = localStorage.getItem('ActiveTabs');
        if (storedActiveTab1) {
            this.tabChanges(storedActiveTab1);
        } else {
            this.tabChanges('Allairline');
        }
        let storedForwardersActiveTab = localStorage.getItem('forwaredersActiveTabs');
        if (storedForwardersActiveTab) {
            this.tabChanges(storedForwardersActiveTab);
        } else {
            this.tabChanges('allForwareders');
        }

        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("orgKey");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("type");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
    }


// -------------- grid display --------------------

ngAfterViewChecked() {
    this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
    this.icons = this.elementRef.nativeElement.querySelectorAll('i');
    this.images = this.elementRef.nativeElement.querySelectorAll('img');
    this.spantexts = this.elementRef.nativeElement.querySelectorAll('span');
    this.anchors.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.href != '') {
            let href_Split_Array = [];
            href_Split_Array = anchor.href.split(":");
            let href_Valid_Check_Var = href_Split_Array[0];
            if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                anchor.addEventListener('click', this.handleAnchorClick)
            } else if ((anchor.innerText === 'Reset') && (href_Valid_Check_Var === 'reset')) {
                anchor.addEventListener('click', this.handleAnchorClick)

            } else if ((anchor.innerText === ' Edit') && (href_Valid_Check_Var === 'viewedit')) {
                anchor.addEventListener('click', this.handleAnchorClick)

            } else if ((anchor.innerText === 'Delete') && (href_Valid_Check_Var === 'delete')) {
                anchor.addEventListener('click', this.handleAnchorClick)

            } else if ((anchor.innerText === 'Switch') && (href_Valid_Check_Var === 'switch')) {
                anchor.addEventListener('click', this.handleAnchorClick)

            } else if ((anchor.innerText === 'Set user roles') && (href_Valid_Check_Var === 'set')) {
                anchor.addEventListener('click', this.handleAnchorClick)

            }
        }
    })

    this.icons.forEach((icon: HTMLImageElement) => {
        //
        //console.log("anchors1innerText",anchor.innerText);
        if (icon.id != '') {
            let href_Split_Array = [];
            href_Split_Array = icon.id.split(":");
            let href_Valid_Check_Var = href_Split_Array[0];
            //
            if ((href_Valid_Check_Var === 'edit')) {
                icon.addEventListener('click', this.handleiconClick)
            } else if (href_Valid_Check_Var === 'resend') {
                icon.addEventListener('click', this.handleiconClick)

            } else if (href_Valid_Check_Var === 'reset') {
                icon.addEventListener('click', this.handleiconClick)
            } else if (href_Valid_Check_Var === 'viewedit') {
                icon.addEventListener('click', this.handleiconClick)
            }
            else if (href_Valid_Check_Var === 'delete') {
                icon.addEventListener('click', this.handleiconClick)
            }
            else if (href_Valid_Check_Var === 'switch') {
                icon.addEventListener('click', this.handleiconClick)
            }
            else if (href_Valid_Check_Var === 'set') {
                icon.addEventListener('click', this.handleiconClick)
            }
        }
    })
    this.images.forEach((image: HTMLImageElement) => {
        //
        //console.log("anchors1innerText",anchor.innerText);
        if (image.id != '') {
            let href_Split_Array = [];
            href_Split_Array = image.id.split(":");
            let href_Valid_Check_Var = href_Split_Array[0];
            //
            if ((href_Valid_Check_Var === 'resend')) {
                image.addEventListener('click', this.handleImageClick)
            }

        }
    })
    this.spantexts.forEach((spantext: HTMLSpanElement) => {

        if (spantext.id != '') {
            let href_Split_Array = [];
            href_Split_Array = spantext.id.split(":");
            let href_Valid_Check_Var = href_Split_Array[0];
            if (href_Valid_Check_Var === 'text') {

                spantext.addEventListener('click', this.handletextClick)
            }

        }
    })
}

public handleAnchorClick = (event: Event) => {
    
    // 
    // Prevent opening anchors the default way
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;


    // this.fn_alert(anchor, event);
}

public handleiconClick = (event: Event) => {
    ;
    //
    // Prevent opening anchors the default way
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;

    let href_Split_Array = [];
    href_Split_Array = editicon.id.split(":");
    let href_Valid_Check_Var = href_Split_Array[1];
    if (href_Split_Array[0] === 'switch') {
        this.switchDashboard(this.concatarray[href_Valid_Check_Var]);
      
    }
    if (href_Split_Array[0] === 'set') {
        if(this.concatarray[href_Valid_Check_Var].orgType == 'E'){
            this.getUserDetails(this.concatarray[href_Valid_Check_Var]);
        }else{
            this.getUser(this.concatarray[href_Valid_Check_Var]);

        }
      
    }
    if (href_Split_Array[0] === 'viewedit') {
        this.EditOganization(this.concatarray[href_Valid_Check_Var]);
      
    }
    if (href_Split_Array[0] === 'delete') {
        this.fn_deleteOrg(this.concatarray[href_Valid_Check_Var]);
      
    }
    // this.fn_alerticon(editicon, event);
}
public handleImageClick = (event: Event) => {
    //
    // Prevent opening anchors the default way
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;

    // this.fn_alertImage(editicon, event);
}
public handletextClick = (event: Event) => {

    // Prevent opening anchors the default way
    event.preventDefault();
    const spantext = event.target as HTMLSpanElement;

    console.log(this.myGrid.getselectedcell());
        let href_Split_Array = [];
        href_Split_Array = spantext.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'text') {
            let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var);
            this.handleAuth(event, this.concatarray[href_Valid_Check_Var]);
          
        }
   
}

public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY
   source =
      {

          localdata: this.concatarray,
        //   url: this.url,
          datatype: 'json',
          datafields:
              [
                { name: 'orgName', type: 'string' },
                { name: 'orgFullType', type: 'string' },
                { name: 'firstName', type: 'string' },
                { name: 'lastName', type: 'string' },
                { name: 'totalNoOfUsers', type: 'string' }, 
                { name: 'statusFull', type: 'string' },
                { name: 'actions', type: 'string' },
                { name: 'authStatus', type: 'string' },
                { name: 'activities', type: 'string' },
                
                
               

              ], 
          pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    //       root: 'Rows',
    //       totalrecords: 800,
    // cache: false,
    // sort: () => {
    //   // update the grid and send a request to the server.
    //   this.myGrid.updatebounddata('sort');
    // },
    // filter: () => {
    //   // update the grid and send a request to the server.
    //   this.myGrid.updatebounddata('filter');
    // },
    beforeprocessing: (data: any) => {
        
        
    //    data.Rows = data.userReports;
    //   this.source.totalrecords = data.TotalRows;
    },
          // beforeprocessing: function (data) {
          //     // this.source.totalrecords = data[0].TotalRows;
          // },
          pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
              // callback called when a page or page size is changed.
              console.log(pagenum);
          },
          rendergridrows: function (params) {
            return params.data;
        }
        
      };


  linkrenderer = function (row, column, value, data) {

      var html = "<a  style='margin-left: 11px;' href='" + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
      return html;
  }

  dataAdapter = new jqx.dataAdapter(this.source,
      {
  
          //Set the http header before calling the api.
        //   beforeSend: function (jqXHR, settings) {
        //       
        //       this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
        //       this.token = this.loginresultobject.token;
        //       jqXHR.setRequestHeader('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0'),
        //       jqXHR.setRequestHeader('pragma', 'no-cache'),
        //       jqXHR.setRequestHeader('expires', '0'),
        //       jqXHR.setRequestHeader('authorization',  this.token);
        //   }
      });
  columns =
      [
        { text: 'Organization Name', datafield: 'orgName', width: 320, },
        { text: 'Organization Type', datafield: 'orgFullType', width: 150, },
        { text: 'First Name', datafield: 'firstName', width: 250 },
        { text: 'Last Name', datafield: 'lastName', width: 250 },
        { text: 'Users', datafield: 'totalNoOfUsers', width: 100 }, 
        { text: 'Status', datafield: 'statusFull', width: 100,},
          {
              text: 'Actions', datafield: 'actions', cellsalign: 'center', width: 150,
              cellsrenderer: function (row, index, data) {
                return "<div class='jq-actions' style='margin-top:  8.5px; margin-left: 5px;'><a  href='switch:" + row + "'><i id = 'switch:" + row + "' class='fa fa-exchange' aria-hidden='true' data-toggle='tooltip' title='Switch'></i></a><a  href='set:" + row + "'><i id = 'set:" + row + "' class='fa fa-users' aria-hidden='true' data-toggle='tooltip' title='View User'></i></a></div>";
              },

          },
          { text: 'Authorization', datafield: 'authStatus', width: 150, 
          cellsrenderer: function (row, index, data) {
            this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
            if ((SaDashboardComponent.viewtotaldata.length != 0) && (row < SaDashboardComponent.viewtotaldata.length)) {
                
                if (SaDashboardComponent.viewtotaldata[row].orgType === 'E') {
                    if (SaDashboardComponent.viewtotaldata[row].authStatus === 'A') {
                        return "<a ><img style='margin: 8px;' src='../../../assets/images/tick_icon.png' /></a>"
                    }
                    else if (SaDashboardComponent.viewtotaldata[row].authStatus === 'I')  {
                        return '<span style="color:red ; margin-left: 5px;"></span><br><span style=" margin-left: 5px;"></span> <span  style="background: red; cursor: pointer; color: #fff;padding: 1px;position: relative; top:-5px;border-radius: 5px;width: 92px; margin-left: 10px;text-align: center;">Un Authorized</span>'
                    }
                }else{
                    if (SaDashboardComponent.viewtotaldata[row].authStatus === 'A') {
                        return "<a ><img style='margin: 8px;' src='../../../assets/images/tick_icon.png' /></a>"
                    }
                    else if (SaDashboardComponent.viewtotaldata[row].authStatus === 'I')  {
                        return '<span style="color:red ; margin-left: 5px;"></span><br><span style=" margin-left: 5px;"></span> <span id = "text:' + row + '" style="background: green; cursor: pointer; color: #fff;padding: 1px;position: relative; top:-5px;border-radius: 5px;width: 92px; margin-left: 10px;text-align: center;"> Authorize</span>'
                    }
                }
               
               
            }
        },},
          {
            text: 'Activities', datafield: 'activities', cellsalign: 'center', width: 120,
            cellsrenderer: function (row, index, data) {
                //  
                if ((SaDashboardComponent.viewtotaldata.length != 0) && (row < SaDashboardComponent.viewtotaldata.length)) {
                    if (SaDashboardComponent.viewtotaldata[row].orgType === 'E') {
                        // return "<div class='jq-actions' style='margin-top:  8.5px; margin-left: 5px;'><a  href='viewedit:" + row + "'><i id = 'viewedit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'  data-toggle='tooltip' title='Edit'></i></a><a  href='delete:" + row + "'><i id = 'delete:" + row + "' class='fa fa-trash-o text-danger' aria-hidden='true' data-toggle='tooltip' title='Delete'></i></a></div>";

                    }
                    else{
                        return "<div class='jq-actions' style='margin-top:  8.5px; margin-left: 5px;'><a  href='viewedit:" + row + "'><i id = 'viewedit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'  data-toggle='tooltip' title='Edit'></i></a><a  href='delete:" + row + "'><i id = 'delete:" + row + "' class='fa fa-trash-o text-danger' aria-hidden='true' data-toggle='tooltip' title='Delete'></i></a></div>";

                    }
                   
                }
            },

        },
     

      ];

     

        // --------------------- grid display end ------------------------------


// on change organisation type --------------------------
        changeorgtype(event){
            
            if(event !== null && event !== undefined && event !== ''){

     
            this.filterorganisationlist = [];
            this.concatarray = JSON.parse(localStorage.getItem('Lconcatarray')); 
            if(event === 'all'){
                this.filterorganisationlist = JSON.parse(localStorage.getItem('Lconcatarray')); 
                
            }
            if(event === 'H'){
                for(let i = 0; i < this.concatarray.length; i++){
                     if(this.concatarray[i].orgType === 'H'){
                        this.filterorganisationlist.push(this.concatarray[i]);
                    }
                }
            }
            if(event === 'A'){
                for(let i = 0; i < this.concatarray.length; i++){
                    if(this.concatarray[i].orgType === 'A'){
                       this.filterorganisationlist.push(this.concatarray[i]);
                   }
               }
            }
            if(event === 'F'){
                for(let i = 0; i < this.concatarray.length; i++){
                    if(this.concatarray[i].orgType === 'E'){
                       this.filterorganisationlist.push(this.concatarray[i]);
                   }
               }
            }
            // if(this.filterorganisationlist.length !== 0){

            // }
            SaDashboardComponent.viewtotaldata = this.filterorganisationlist
            this.source.localdata = this.filterorganisationlist;
            this.concatarray  =  this.filterorganisationlist;
                    this.myGrid.source(this.dataAdapter);
        }
        }
// on change organisation type --------------------------












    handleActivation(event, obj) {
        
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
        this.dummyObj = obj;

        if (obj.status == 'I') {
            this.activateObj = {
                "organizationId": obj.orgId,
                "status": "A"
            }
            this.dummyObj.status = 'A'
        }
        else {
            this.activateObj = {
                "organizationId": obj.orgId,
                "status": "I"
            }
            this.dummyObj.status = 'I'
        }

        // if (event.target.checked) {
        //     this.activateObj = {
        //         "organizationId": obj.orgId,
        //         "status": "A"
        //     }
        //     this.dummyObj.status = 'A'
        // } else {
        //     this.activateObj = {
        //         "organizationId": obj.orgId,
        //         "status": "I"
        //     }
        //     this.dummyObj.status = 'I'
        // }

    }


    authoriseYes() {
        
        // this.spinner.show();
        this.superAdminservice.viewActivation(this.activateObj).subscribe(
            data => {
                // this.spinner.hide();
                if (data.responseCode == '1') {
                    this.toastr.success('Successfully updated', '', { timeOut: 1500 });
                    // this.ngOnInit();
                    // this.cd.detectChanges();
                    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                    // this.router.navigate(['/sa-dashboard']));
                    jQuery('#myModal').modal('hide');
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }



    authoriseNo() {
        
        // if(this.dummyObj.status == 'I'){
        // this.dummyObj.status = 'I'
        // }else if(this.dummyObj.status == 'A'){
        // this.dummyObj.status = 'A'
        // }
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/sa-dashboard']));
        // this.cd.detectChanges();
        jQuery('#myModal').modal('hide');
    }

    tabChange(data) {
        this.searchItem = '';
        if (data == 'All') {
            localStorage.setItem('ActiveTab', 'All');
            // localStorage.setItem('ActiveTab', JSON.stringify('All'));
            this.allStatus = true;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.allAUOrgs = this.search_Allorgs;
        } else if (data == 'Auth') {
            localStorage.setItem('ActiveTab', 'Auth');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Auth'));
            this.allStatus = false;
            this.authStatus = true;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.authorizedOrgs = this.serach_authorgs;
        } else if (data == 'Unauth') {
            localStorage.setItem('ActiveTab', 'Unauth');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Unauth'));
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = true;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.unAuthorizedOrgs = this.serach_unauthorgs;
        } else if (data == 'premium') {
            localStorage.setItem('ActiveTab', 'premium');
            //  localStorage.setItem('ActiveTab', JSON.stringify('premium'));
            console.log(this.premiumOrgs);
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = true;
            this.nonpremiumStatus = false;
            this.premiumOrgs = this.serach_premium;
        } else if (data == 'Nonpremium') {
            localStorage.setItem('ActiveTab', 'Nonpremium');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Nonpremium'));
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = true;
            this.nonPremiumOrgs = this.serach_nonpremium;
        }
    }

    // <----airlines tabs--------->

    tabChanges(data) {
        this.searchItems = '';
        if (data == 'Allairline') {
            localStorage.setItem('ActiveTabs', 'Allairline');
            this.allStatusair = true;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.allairlinesOrgs = this.search_Allairorgs;
        } else if (data == 'Authairline') {
            localStorage.setItem('ActiveTabs', 'Authairline');
            this.allStatusair = false;
            this.authStatusair = true;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.authorizedairlineOrgs = this.serach_authairorgs;
        } else if (data == 'Unauthairline') {
            localStorage.setItem('ActiveTabs', 'Unauthairline');
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = true;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
        } else if (data == 'premiumairline') {
            localStorage.setItem('ActiveTabs', 'premiumairline');
            console.log(this.premiumOrgs);
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = true;
            this.nonpremiumStatusair = false;
            this.premiumairlineOrgs = this.serach_premiumair;
        } else if (data == 'Nonpremiumairline') {
            localStorage.setItem('ActiveTabs', 'Nonpremiumairline');
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = true;
            this.nonPremiumairlineOrgs = this.serach_nonpremiumair;
        }
    }

    //Forworders tabs change
    forwardertabChanges(data) {
        this.ForwarderssearchItems = '';
        if (data == 'allForwareders') {
            localStorage.setItem('forwaredersActiveTabs', 'allForwareders');
            this.allForwareders = true;
            this.authorizedForwareders = false;
            this.unAuthorizedForwareders = false;
            this.premiumForwareders = false;
            this.nonPremiumForwareders = false;
            this.allFarwordersOrgs = this.search_allFarwordersorgs;
        } else if (data == 'authorizedForwareders') {
            localStorage.setItem('forwaredersActiveTabs', 'authorizedForwareders');
            this.allForwareders = false;
            this.authorizedForwareders = true;
            this.unAuthorizedForwareders = false;
            this.premiumForwareders = false;
            this.nonPremiumForwareders = false;
            this.authorizedFarwordersOrgs = this.Farworders_authorizedOrgs;
        } else if (data == 'unAuthorizedForwareders') {
            localStorage.setItem('forwaredersActiveTabs', 'unAuthorizedForwareders');
            this.allForwareders = false;
            this.authorizedForwareders = false;
            this.unAuthorizedForwareders = true;
            this.premiumForwareders = false;
            this.nonPremiumForwareders = false;
            this.unAuthorizedFarwordersOrgs = this.Farworders_unAuthorizedOrgs;
        } else if (data == 'premiumForwareders') {
            localStorage.setItem('forwaredersActiveTabs', 'authorizedForwareders');
            console.log(this.premiumOrgs);
            this.allForwareders = false;
            this.authorizedForwareders = false;
            this.unAuthorizedForwareders = false;
            this.premiumForwareders = true;
            this.nonPremiumForwareders = false;
            this.premiumFarwordersOrgs = this.Farworders_premiumOrgs;
        } else if (data == 'nonPremiumForwareders') {
            localStorage.setItem('forwaredersActiveTabs', 'nonPremiumForwareders');
            this.allForwareders = false;
            this.authorizedForwareders = false;
            this.unAuthorizedForwareders = false;
            this.premiumForwareders = false;
            this.nonPremiumForwareders = true;
            this.nonPremiumFarwordersOrgs = this.Farworders_nonPremiumOrgs;
        }
    }

    // <-------airlines tabs--------->



    // ----------Farworders search----->
    searchForwardersOrg() {
        
        console.log(this.ForwarderssearchItems);
        if (this.allForwareders) {
            if (this.ForwarderssearchItems != '') {
                const val = this.ForwarderssearchItems;
                this.allFarwordersOrg = [];
                this.allFarwordersOrg = this.search_allFarwordersorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.Allfarworders = 1;
            } else {
                this.allFarwordersOrg = this.search_allFarwordersorgs;
            }
        } else if (this.authorizedForwareders) {
            if (this.ForwarderssearchItems != '') {
                const val = this.ForwarderssearchItems;
                this.Farworders_authorizedOrgs = [];
                this.Farworders_authorizedOrgs = this.serach_authFarwordersorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.authfarworders = 1;
            } else {
                this.Farworders_authorizedOrgs = this.serach_authFarwordersorgs;
            }
        } else if (this.unAuthorizedForwareders) {
            if (this.ForwarderssearchItems != '') {
                const val = this.ForwarderssearchItems;
                this.Farworders_unAuthorizedOrgs = [];
                this.Farworders_unAuthorizedOrgs = this.serach_unauthFarwordersorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.unauthpfarworders = 1;
            } else {
                this.Farworders_unAuthorizedOrgs = this.serach_unauthFarwordersorgs;
            }
        }
        else if (this.premiumForwareders) {
            if (this.ForwarderssearchItems != '') {
                const val = this.ForwarderssearchItems;
                this.Farworders_premiumOrgs = [];
                this.Farworders_premiumOrgs = this.serach_premiumFarworders.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.premiumpfarworders = 1;
            } else {
                this.Farworders_premiumOrgs = this.serach_premiumFarworders;
            }
        }
        else if (this.nonPremiumForwareders) {
            if (this.ForwarderssearchItems != '') {
                const val = this.ForwarderssearchItems;
                this.Farworders_nonPremiumOrgs = [];
                this.Farworders_nonPremiumOrgs = this.serach_nonpremiumFarworders.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.nonpremiumpfarworders = 1;
            } else {
                this.Farworders_nonPremiumOrgs = this.serach_nonpremiumFarworders;

            }
        }
    }


    clearForwardersSearchs() {
        
        this.ForwarderssearchItems = '';
        if (this.allForwareders) {
            this.allFarwordersOrg = this.search_allFarwordersorgs;
        } else if (this.authorizedForwareders) {
            this.Farworders_authorizedOrgs = this.serach_authFarwordersorgs;
        } else if (this.unAuthorizedForwareders) {
            this.Farworders_unAuthorizedOrgs = this.serach_unauthFarwordersorgs;
        }
        else if (this.premiumForwareders) {
            this.Farworders_premiumOrgs = this.serach_premiumFarworders;
        }
        else if (this.nonPremiumForwareders) {
            this.Farworders_nonPremiumOrgs = this.serach_nonpremiumFarworders;
        }
    }
    // ----------airlines search----->
    searchairlines() {
        
        console.log(this.searchItems);
        if (this.allStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.allairlinesOrgs = [];
                this.allairlinesOrgs = this.search_Allairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.Allair = 1;
            } else {
                this.allairlinesOrgs = this.search_Allairorgs;
            }
        } else if (this.authStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.authorizedairlineOrgs = [];
                this.authorizedairlineOrgs = this.serach_authairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.authair = 1;
            } else {
                this.authorizedairlineOrgs = this.serach_authairorgs;
            }
        } else if (this.nonauthStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.unAuthorizedairlineOrgs = [];
                this.unAuthorizedairlineOrgs = this.serach_unauthairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.unauthpair = 1;
            } else {
                this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
            }
        }
        else if (this.premiumStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.premiumairlineOrgs = [];
                this.premiumairlineOrgs = this.serach_premiumair.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.premiumpair = 1;
            } else {
                this.premiumairlineOrgs = this.serach_premiumair;
            }
        }
        else if (this.nonpremiumStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.nonPremiumairlineOrgs = [];
                this.nonPremiumairlineOrgs = this.serach_nonpremiumair.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.nonpremiumpair = 1;
            } else {
                this.nonPremiumairlineOrgs = this.serach_nonpremiumair;

            }
        }
    }




    clearSearchs() {
        this.searchItems = '';
        if (this.allStatusair) {
            this.allairlinesOrgs = this.search_Allairorgs;
        } else if (this.authStatus) {
            this.authorizedairlineOrgs = this.serach_authairorgs;
        } else if (this.nonauthStatus) {
            this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
        }
        else if (this.premiumStatus) {
            this.premiumairlineOrgs = this.serach_premiumair;
        }
        else if (this.nonpremiumStatus) {
            this.nonPremiumOrgs = this.serach_nonpremiumair;
        }
    }

    // ---------airlines search---------->


    searchOrgs() {
        
        console.log(this.searchItem);
        if (this.allStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.allAUOrgs = [];
                this.allAUOrgs = this.search_Allorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.Allp = 1;
            } else {
                this.allAUOrgs = this.search_Allorgs;
            }
        } else if (this.authStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.authorizedOrgs = [];
                this.authorizedOrgs = this.serach_authorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.authp = 1;
            } else {
                this.authorizedOrgs = this.serach_authorgs;
            }
        } else if (this.nonauthStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.unAuthorizedOrgs = [];
                this.unAuthorizedOrgs = this.serach_unauthorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.unauthp = 1;
            } else {
                this.unAuthorizedOrgs = this.serach_unauthorgs;
            }
        }
        else if (this.premiumStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.premiumOrgs = [];
                this.premiumOrgs = this.serach_premium.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.premiump = 1;
            } else {
                this.premiumOrgs = this.serach_premium;
            }
        }
        else if (this.nonpremiumStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.nonPremiumOrgs = [];
                this.nonPremiumOrgs = this.serach_nonpremium.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
                this.nonpremiump = 1;
            } else {
                this.nonPremiumOrgs = this.serach_nonpremium;

            }
        }
    }


    clearSearch() {
        
        this.searchItem = '';
        if (this.allStatus) {
            this.allAUOrgs = this.search_Allorgs;
        } else if (this.authStatus) {
            this.authorizedOrgs = this.serach_authorgs;
        } else if (this.nonauthStatus) {
            this.unAuthorizedOrgs = this.serach_unauthorgs;
        }
        else if (this.premiumStatus) {
            this.premiumOrgs = this.serach_premium;
        }
        else if (this.nonpremiumStatus) {
            this.nonPremiumOrgs = this.serach_nonpremium;
        }
    }

    // getUser(obj){
    //   
    //   localStorage.setItem('organizationId', JSON.stringify(obj.orgKey));
    //   this.router.navigateByUrl('/viewusers');
    // }
    getUser(obj) {
        
        console.log(obj.orgId);
        let comapanydetails = {
            orgId: obj.orgId,
            orgLogo: obj.orgLogo,
            orgName: obj.orgName,
            orgType: obj.orgType,
            userId: obj.userId,
            reportAcess: obj.reportAcess,
            airlineStatus: obj.airlineStatus,

        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        // this.router.navigate(["viewusers", obj.orgId]);
        // this.router.navigate(["viewusers"],{ queryParams: { orgKey: obj.orgId } });
        this.router.navigate(["viewusers"],{ queryParams: { orgKey: obj.orgId } });

    }
    getUserDetails(orgDetails) {
        
        console.log(orgDetails.orgId);
        let comapanydetails = {
            orgId: orgDetails.orgId,
            orgLogo: orgDetails.orgLogo,
            orgName: orgDetails.orgName,
            orgType: orgDetails.orgType,
            userId: orgDetails.userId,
            reportAcess: orgDetails.reportAcess,
            // airlineStatus: orgDetails.airlineStatus,

        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        // this.router.navigate(["listofusers", orgDetails.orgId], { queryParams: { type: 'Forwarder', 'navigatedFrom': 'SAdashboard' } });
        this.router.navigate(["listofusers"], { queryParams: { type: 'Forwarder', 'navigatedFrom': 'SAdashboard' } });
    }

    //----airlines view user----//

    gotoviewUser(obj) {
        
        this.spinner.show();
        let comapanydetails = {
            orgId: obj.orgId,
            orgLogo: obj.orgLogo,
            orgName: obj.orgName,
            orgType: obj.orgType,
            isfrom: "airlines",
        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        
        // this.router.navigate(["viewusers", obj.orgId]);
        this.router.navigate(["viewusers"],{ queryParams: { orgKey: obj.orgId } });
    }

    EditOganization(obj) {
        
        console.log(obj);
        localStorage.setItem('editOrgObj', JSON.stringify(obj));
        // this.router.navigateByUrl("['/editorg',obj.orgId,obj.orgType]");
        // this.router.navigate(['/editorg', obj.orgId, obj.orgType]);
        this.router.navigate(["/editorg"], { queryParams: { orgId: obj.orgId,orgType : obj.orgType} });
    }

    fn_deleteOrg(event) {
        
        jQuery('#deletePopUp').modal({ backdrop: 'static', keyboard: false });
        this.delObj = {
            "orgId": event.orgId,
            "orgType": event.orgType
        }

    }

    deleteOrg() {
        
        console.log(event)
        console.log(this.delObj);
        this.spinner.show();
        this.superAdminservice.deleteOrg(this.delObj).subscribe(
            data => {
                
                this.spinner.hide();
                console.log(data);
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    jQuery('#deletePopUp').modal('hide');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/sa-dashboard']));
                    this.spinner.hide();
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide();
                    jQuery('#deletePopUp').modal('hide');
                } 
                else {
                    this.toastr.error(data.message);
                    this.spinner.hide();
                    jQuery('#deletePopUp').modal('hide');
                }
            }
        )
    }

    handleAuth(event, obj) {
        
        console.log(event);
        console.log(obj);
        this.authObj = {
            organizationId: obj.orgId
        }
        jQuery('#myModalauth').modal({ backdrop: 'static', keyboard: false });

    }

    authenticateYes() {
        
        this.superAdminservice.OrgAuth(this.authObj).subscribe(data => {
            console.log(data)
            if (data.responseCode === 1) {
                this.toastr.success(data.message);
                jQuery('#myModalauth').modal('hide');
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/sa-dashboard']));
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModalauth').modal('hide');
            } 
             else {
                this.toastr.error(data.message);
                jQuery('#myModalauth').modal('hide');
            }
        })
    }
    navigateToViewReports(obj, status) {
        
        localStorage.setItem("isFromreports", JSON.stringify(status));
        localStorage.setItem("awbnoObj", JSON.stringify(obj));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.router.navigate(["/viewdetails"]);
    }


    switchDashboard(Obj) {
        
        this.spinner.show()
        let dashBoardObj = {
            emailId: Obj.loginID,
            OrgId: Obj.orgId,
            OrgType: Obj.orgType,
            airlineStatus: Obj.airlineStatus
        }
        localStorage.setItem('SwitchdashBoardObj', JSON.stringify(dashBoardObj));
        this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {
            
            console.log(data)
           
            this.spinner.hide()
            if (data.responseCode === 1) {
                localStorage.removeItem("selectedreports") ;
                localStorage.removeItem("reportsTab") ;
                    localStorage.setItem('loginresultobject', JSON.stringify(data));
               
                    localStorage.setItem("Token",JSON.stringify(data.token));
                    localStorage.setItem("loginDetails",JSON.stringify(data));


                this.cookieService.delete('cclp', '/', '.cclp.aero');
                var expire = new Date();
                var time = Date.now() + ((3600 * 1000) * 8);
                expire.setTime(time);
                this.cookieService.set('cclp', JSON.stringify(data.token), expire, '/', '.cclp.aero',true,"None");
                this.cookieValue = this.cookieService.get('cclp');
                
                let comapanydetails = {
                    orgId: data.orgId,
                    orgLogo: data.orgLogo,
                    orgName: data.organisationName,
                    orgType: data.orgType,
                    userId: data.userId,
                    reportAcess: data.reportAcess
                }
                localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));

                if (data.userType == 'SA') {
                    // localStorage.setItem('dashBoradSwitched', JSON.stringify(dashBoradSwitched));
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.isdashboardSwitched();
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/sa-dashboard');
                } else if (data.userType == 'S' && data.orgType == 'H') {
                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    localStorage.removeItem('csaReportsTab');

                    // this.router.navigateByUrl('/csa-dashboard');
                }
                else if (data.userType == 'S' && data.orgType == 'A') {
                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/csa-airline-dashboard');
                }
                else if (data.userType == 'E') {
                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // this.router.navigateByUrl('/user-dashboard');
                } else if (data.orgType === "E") {

                    this.isdashboardSwitched();
                    this.ViewReportsData.isFromDashboard = 'yes';
                    this.router.navigate(['HandlerDashboardComponent'], {
                        // relativeTo: this.route,
                        queryParams: {
                            DashBorad: 'switched'
                        },
                        queryParamsHandling: 'merge',
                    });
                    // window.open("https://uld.cclp.aero/ ", "_blank");
                    // window.open("https://uld.cclp.aero/ ", "_blank");
                }
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session expired, please login again");
            } else if (data.responseCode === 0) {
                this.toastr.error(data.message);
            }
        },
            error => {
                this.toastr.error(error.message)
            })

    }

    isdashboardSwitched() {
        
        let dashBoradSwitched = {
            'isSwitched': 'yes'
        }
        localStorage.setItem('dashBoradSwitchedtemp', JSON.stringify(dashBoradSwitched));
        // console.log(dashBoradSwitchedtemp)
        // localStorage.removeItem('comapanydetails');
    }
}
