import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import * as moment from 'moment';
import 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RefreshService } from 'src/app/services/Refresh/refresh.service';
import { ExcelService } from "src/app/services/excel.service";
import { ConfigServiceService } from '../../config-service.service';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { Active_Archive_ReportsTableComponent } from '../../shared/dataGrid-tables/active-reports-table/active-archive-reports-table.component';
import { ViewUsersComponent } from '../../shared/view-users/view-users.component';
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
declare var jQuery: any;

@Component({
    selector: 'app-csa-dashboard',
    templateUrl: './csa-dashboard.component.html',
    styleUrls: ['./csa-dashboard.component.css'],
    providers: [ViewUsersComponent]
})
export class CsaDashboardComponent implements OnInit {
    @ViewChild('reportsDataGrid') reportsDataGrid_Ref: Active_Archive_ReportsTableComponent;
 
    
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    emailid: any;
    public static totaldata = [];
    public static objdata: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    Obj: any;
    orgType: any;
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    Always;
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    
    rootcauseshare: boolean = false;
    rootcauseshareshow: boolean = false;

    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    ObjectOne: any;
    ReportsProductionUrl: string;
    loginDetails: any;
    reportpreparename: string;
    Airlinename: string;
    Emailstatus: any;
    disabledValues: number[];
    multipleReportList: any;
    userId: string;
    masterAwbNo: string;
    stationCode: string;
    firstName: string;
    classificationType: string;
    disabledKeys: any[];
    dataGridInstance: any;
    justDeselected: any;
    mergedReportsFinal: any[];
    airlineDetails: any;
    showAll: boolean;
    showArchived: boolean;
    isEnable_Refresh: boolean;
    comnyorgid: any;
    SwitchdashBoardObj: any;
    anchors: any;
    images: any;
    editicons: any;
    orgId: any;
    loginUserId: any;
    shortReports: any;
    currentTab: any;
    csaReportsTab: any;
    currentTabName: any;
    currentReportId: any;
    selreportsarray: any[];
    finalobj: any;
    finalReportsCount: any;
    archiveReportsCount: any;
    internalReportCount: any;
    carrierId: any;
    send_report: string = 'Y';
    resend_report: string;
    /* 111023 To remove the old KPT charts code start

        public damageReportbyAirlineChart:any;
        public reportbyAirlineLabeldata: any[] = [];
        public reportbyAirlineRealdata: any[] = [];

        public ResposibilityLabeldata: any[] = [];
        public ResposibilityRealdata: any[] = [];
        public damageResposibilityChart:any;

        public contentAffectedLabeldata: any[] = [];
        public contentAffectedRealdata: any[] = [];
        public contentAffectedChart:any;

        public classificationLabeldata: any[] = [];
        public classificationRealdata: any[] = [];
        public classificationChart:any;

        public reportsbyStationLabeldata: any[] = [];
        public reportsbyStationRealdata: any[] = [];
        public reportsbyStationChart:any;

        public damageReportsperMonthLabelsData: any[] = [];
        public damageReportsperMonthRealData: any[] = [];
        public damageReportsperMonthChart:any;

        public totalDamageReport:any;

        111023 To remove the old KPT charts code end
    */

    constructor(private config : ConfigServiceService, private location: LocationStrategy,private renderer: Renderer2, private elementRef: ElementRef, private refreshServiceRef: RefreshService, private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService,
        private excelService:ExcelService) {
        this.disabledValues = [1];
     
      

        history.pushState(null, null, window.location.href);  
        this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });  
        this.webapi = this.config.getConfig().ULD_Url;
    }
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
  
    ngAfterViewInit() {
        this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
    }
    ngAfterViewChecked() {
        this.Obj = JSON.parse(localStorage.getItem("loginresultobject"))
        this.isEnable_Refresh = false;
        if(this.Obj.reportAcess === 'Y'){
            if(this.reportsDataGrid_Ref !== undefined){
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "All"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    // document.getElementById('all_Reports').className = 'nav-link active';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link'
                    document.getElementById('internal_Reports').className = 'nav-link';
                } else if(selectedreports === "Active"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link'
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Archived"){
                     
                    this.isEnable_Refresh = true;
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Trash"){
                     
                    this.showAll = false;
                    this.isEnable_Refresh = false;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Internal"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                }
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
            }
        }else{
            if(this.reportsDataGrid_Ref !== undefined){
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "All"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    document.getElementById('all_Reports').className = 'nav-link active';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                } else if(selectedreports === "Active"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Archived"){
                    this.isEnable_Refresh = true;  
                    this.showAll = false;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Trash"){
                     
                    this.isEnable_Refresh = false;
                    this.showAll = false;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Internal"){
                    this.isEnable_Refresh = true;
                    this.showAll = true;
                    // document.getElementById('all_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                }
                this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
            }
        }
        
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.images = this.elementRef.nativeElement.querySelectorAll('img');
        this.editicons = this.elementRef.nativeElement.querySelectorAll('i');
      
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
                else if (anchor.innerText === 'Delete' && href_Valid_Check_Var === 'delete') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
            }
        })

        this.editicons.forEach((editicon: HTMLImageElement) => {
            if (editicon.id != '') {
                let href_Split_Array = [];
                href_Split_Array = editicon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((href_Valid_Check_Var === 'edit')) {
                    editicon.addEventListener('click', this.handleiconClick)
                }
            }
        })
        this.images.forEach((image: HTMLImageElement) => {
            if (image.id != '') {
                let href_Split_Array = [];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                
                if ((href_Valid_Check_Var === 'resend')) {
                    image.addEventListener('click', this.handleImageClick)
                }

            }
        })
    }
    static convertUTCDateToLocalDate(date1){
        let dt = this.utcToLocal(date1, moment.tz.guess());
        return  dt === "Invalid Timezone Operator" ? date1 : dt;
    }
    static utcToLocal(utcdateTime, tz) {
        var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
        var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
        var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
        var localDateTime
        var hours = zoneValue.split(":")[0]
        var minutes = zoneValue.split(":")[1]
        if (operator === "-") {
            localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
        } else if (operator) {
            localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
        } else {
            localDateTime = "Invalid Timezone Operator"
        }
        return localDateTime
     }

    onExportClick(): void {
        let deleteColumns = ['actions','externalFileName','internalFileName','uid','boundindex','uniqueid','visibleindex','userId','firstName','reportId','orgName','status'];
        let reportsArray = [];
        // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
        reportsArray = this.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
       let columnsData = this.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
               return (!deleteColumns.includes(item.datafield)) ;
       });  //Excel columns Header And width obj array
        // for (let i = 0; i < reportsArray.length; i++) { 
        //   reportsArray[i]['take_in'] = CsaDashboardComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
        //   let imgTempArray = [];
        //     if(reportsArray[i]['cargoImages'].length !== 0){
        //     for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
        //       imgTempArray.push({
        //         image : this.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
               
        //       })
        //     }
        //   }
        //     reportsArray[i]['Images'] = imgTempArray;
        //   //  columnsData.push("Images");
        //   for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
        //       delete  reportsArray[i][deleteColumns[j]];
        //   }
        // }
        this.excelService.exportAsExcelFile(reportsArray,`cargoHandlerReport_${(new Date()).toString().substring(0,24)}_page_${this.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
    }

    scaleColumnWidth(width:any,scale :number =10){
        return (width / scale)
    }

    public handleImageClick = (event: Event) => {
        event.preventDefault();
        const image = event.target as HTMLImageElement;
        this.fn_alertImage(image, event);
    }

    fn_alertImage(image, event) {
        let href_Split_Array = [];
        href_Split_Array = image.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'resend') {
        
           
            this.mergedReportsFinal[href_Valid_Check_Var]
            this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var], 'S');
           
        }
    }


    public handleAnchorClick = (event: Event) => {
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        let href_Split_Array = [];
        href_Split_Array = anchor.href.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        this.mergedReportsFinal[href_Valid_Check_Var];
        this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
    }
    public handleiconClick = (event: Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
        this.fn_alerticon(editicon, event);
    }
    fn_alerticon(editicon, event) {
        let href_Split_Array = [];
        href_Split_Array = editicon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'edit') {
         
            this.mergedReportsFinal[href_Valid_Check_Var];
            this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }
    }

    fn_OnTab_Changes(tabName : string , type: string){
        // if(type === 'A'){
        //     this.send_report = 'Y';
        //     this.resend_report = 'N';
        // }
        if(type === 'R'){
            this.send_report = 'N';
            this.resend_report = 'Y';
        }else if (type === 'T') {
            this.send_report = 'N';
            this.resend_report = 'N';
            this.showAll = false;
        } else{
            this.send_report = 'Y';
            this.resend_report = 'N';
        }
        localStorage.setItem('reportsTab', JSON.stringify(tabName));
        localStorage.setItem("ordType",JSON.stringify(type));
        this.spinner.show();
        this.selectedRowsData =[]
        try{
            let logindet = JSON.parse(localStorage.getItem('loginresultobject'));
            if(tabName === 'Internal'){
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;
                this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                this.reportsDataGrid_Ref.source.url =  this.webapi + 'getInternalReportList.php/getInternalReportList?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId;
            } else if(tabName === 'Trash'){
                this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox");
                this.reportsDataGrid_Ref.source.url =  this.webapi + 'getTrashReports.php/getTrashReports?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId;
                setTimeout(() => {
                    this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
                }, 0);
            } else{
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;
                this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                this.reportsDataGrid_Ref.source.url = this.webapi + 'superAdminUserReports.php/getUserReportInfo?organisationId=' + logindet.orgId + "&orderType="+type +"&loginUserType=" + logindet.userType + '&loginUserId=' + logindet.userId;
            }
             this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
             this.reportsDataGrid_Ref.myGrid.gotopage(0)
             this.reportsDataGrid_Ref.myGrid.clearselection()
             Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
             this.selectedRowsData = [];
             this.spinner.hide();

        }catch(exception){
            this.spinner.hide();
           
        }
     
}




    ngOnInit() {
        this.showAll = true;
        this.showArchived = false;
        this.disabledKeys = [];
        window.scroll(0, 0);
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        // localStorage.setItem('orgId', JSON.stringify(this.Object.orgId));
        // localStorage.setItem('orgType', JSON.stringify(this.Object.orgType));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.SwitchdashBoardObj = JSON.parse(localStorage.getItem('SwitchdashBoardObj'));
        this.emailid = this.Object.emailId;
        this.Password = this.Object.password;
        this.usertype = this.Object.userType;
        this.orgType = this.Object.orgType;
        this.webapi = this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        let lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
        if (lastSelectedTAb != null && lastSelectedTAb != undefined) {
            if (lastSelectedTAb === "All") {
                this.fn_OnTab_Changes('All','AL') ;
            } else if (lastSelectedTAb === "Active") {
                this.fn_OnTab_Changes('Active','A') ;
            } else if (lastSelectedTAb === "Archived") {
                this.fn_OnTab_Changes('Archived',"R") ;
            }else if (lastSelectedTAb === "Internal") {
                this.fn_OnTab_Changes('Internal',"I") ;
            }else if (lastSelectedTAb === "Trash") {
                this.fn_OnTab_Changes('Trash',"T") ;
            }
        } else {
            this.fn_OnTab_Changes('Active','A') ;
        }
    }


    fn_ToRefreshReports() {
        this.comnyorgid = JSON.parse(localStorage.getItem('loginresultobject'));
        this.spinner.show();
        this.refreshServiceRef.servc_To_Refresh_Reports(this.comnyorgid.userId).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
            }
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
        })
    }

    static currentReportTab :any
    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
    containsObject(obj, list) {
        
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }


    getUser(obj, type) {

       
        let comapanydetails = {
            orgId: obj.organisationId,
            orgLogo: obj.orgLogo,
            orgName: obj.organisationName,
            orgType: obj.orgType,
            userId: obj.userId,
            reportAcess: obj.reportAcess,
            airlineStatus: obj.airlineStatus,
        }
       
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        this.router.navigate(["viewusers", obj.organisationId, type]);
        if (type === 'report') {
            this.ViewReportsData.isFrmViewDetails = "yes"
        }
    }
 
   
    getSelectedDataOfresendReport(val, status) {
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }
    Rowdoubleclick(event: any): void {
        
        status = ('viewreports');
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.mergedReportsFinal[event.args.row.bounddata.uid].length != 0) {
         
            localStorage.setItem("reportStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].masterAwbNo));
             localStorage.setItem('awbNumber_reportId', JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].emailStatus));
            if (this.subscribedParam === undefined) {
              
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId }});
                this.ViewReportsData.isfromHandlerCsaDashboard = 'yes'
            } else {
              
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId ,type : this.subscribedParam} });
                this.ViewReportsData.isfromHandlerCsaDashboard = 'yes'
            }
        }

    }
    CancelUser() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let Cancelstatus = JSON.parse(localStorage.getItem('Cancelstatus'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: Cancelstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
           
            if (data.responseCode === 1) {
               
                this.toastr.success(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
               
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            } else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#cancelsingleModal').modal('hide');
            })
    }
    sendManualReportEmail() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
              
                this.toastr.success(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
               
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#myModal').modal('hide');
            })

    }
    resendManualReportEmail() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            } else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#resendModal').modal('hide');
            })
    }

    navigateToViewReports(obj, status) {
        localStorage.setItem("isFromreports", JSON.stringify(status));
        localStorage.setItem("awbnoObj", JSON.stringify(obj));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.router.navigate(["/viewdetails"]);
    }

    notCancel() {
        this.rootcauseshareshow = false;
        jQuery('#Activation').modal('hide');
    }
   
    OpenEmailPopup(status) {
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to send');
        } else {
            this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            this.currentTabName = JSON.parse(localStorage.getItem('reportsTab'))
            localStorage.setItem("Emailstatus", JSON.stringify(status));
            let showCheckbox = false;
            if (this.loginDetails.reportAcess === 'Y') {
                for(let item of this.selectedRowsData) {
                    if (this.selectedRowsData[0].orgRootcauseStatus === 'Y') {
                        this.rootcauseshare = true;
                    }
                    if (item.rootcauseExists === 'Y') {
                        if ((item.rootcausestatus === 'N' || item.rootcausestatus === 'Y') && item.reportStatus === 'internal') {
                            showCheckbox = true;
                        }
                    }
                    if (item.reportOrgId !== this.loginDetails.orgId) {
                        this.toastr.error('Other organisations reports Not Allowed');
                        this.rootcauseshareshow = false;
                        return;
                    }
                }
            }
            if (showCheckbox === true) {
                this.rootcauseshareshow = true;
            }
            jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
        }
    }

    sentMultipleEmailReport() {
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            let rootcauseStatus = 'N';
            for (let index = 0; index < this.selectedRowsData.length; index++) {
                this.userId = this.selectedRowsData[index].userId;
                this.masterAwbNo = this.selectedRowsData[index].masterAwbNo;
                this.stationCode = this.selectedRowsData[index].stationCode;
                this.classificationType = this.selectedRowsData[index].classificationType;
                this.firstName = this.selectedRowsData[index].firstName;
                this.currentReportId = this.selectedRowsData[index].reportId;
                this.carrierId = this.selectedRowsData[index].carrierId;
                this.multipleReportList.push({
                    userId: this.userId,
                    masterAwbNo: this.masterAwbNo,
                    stationCode: this.stationCode,
                    classificationType: this.classificationType,
                    firstName: this.firstName,
                    reportId:this.currentReportId,
                    carrierId: this.carrierId,
                    reportStatus: this.selectedRowsData[index].emailStatus,
                    destairportId: this.selectedRowsData[index].destAirportName,
                    originAirportId: this.selectedRowsData[index].originAirportName,
                    internalResponsibility: this.selectedRowsData[index].reportStatus,
                    rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                    rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                    reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                });
                // if (this.selectedRowsData[index].rootcausestatus) {
                //     rootcauseStatus = 'Y';
                // } else {
                //     rootcauseStatus = 'N';
                // }
            }
            if (this.rootcauseshare === true) {
                rootcauseStatus = 'Y'
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                rootcause: rootcauseStatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
             
            if (this.multipleReportList.length === 0) {
                jQuery('#emailModal').modal('hide');
                this.myGrid.clearselection();
                this.toastr.error('Please slect any report');
                return false;
            }
            this.spinner.show();
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
              
                if (data.responseCode === 1) {
                    this.rootcauseshare = false;
                    this.rootcauseshareshow = false;
                    this.myGrid.clearselection();
                    this.toastr.success(data.message, '', {});
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.spinner.hide();
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#emailModal').modal('hide');
                    this.spinner.hide();
                } else {
                    this.toastr.error(data.message, '', {});
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.spinner.hide();
                }

            },
                error => {
                    this.spinner.hide();
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                    this.spinner.hide();
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
    OpenCancelPopup(status) {
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to hold');
        } else {
            let selectedRowsDAtaArray = []
            for (let i = 0; i < this.selectedRowsData.length; i++) {
                selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
            }
            
            if(selectedRowsDAtaArray.length !== 0){
              let isExist = selectedRowsDAtaArray.some((element) => {
                  return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
                });
               
                if(isExist){
                    jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
                    return false;
                }else{
                    localStorage.setItem("Emailstatus", JSON.stringify(status));
                   jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
                }
             
            }
           
        }

    }

    cancelunselectSentReportExistPopup(){
        jQuery('#unselectSentReportExistPopup').modal("hide");
    }



    cancelMultipleEmailReport() {
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));

        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            for (let index = 0; index < this.selectedRowsData.length; index++) {
                        this.userId = this.selectedRowsData[index].userId,
                            this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                            this.stationCode = this.selectedRowsData[index].stationCode,
                            this.classificationType = this.selectedRowsData[index].classificationType,
                            this.firstName = this.selectedRowsData[index].firstName;
                            this.currentReportId = this.selectedRowsData[index].reportId,
                        this.multipleReportList.push({
                            userId: this.userId,
                            masterAwbNo: this.masterAwbNo,
                            stationCode: this.stationCode,
                            classificationType: this.classificationType,
                            firstName: this.firstName,
                            reportId:this.currentReportId,
                            destairportId: this.selectedRowsData[index].destAirportName,
                            originAirportId: this.selectedRowsData[index].originAirportName,
                            internalResponsibility: this.selectedRowsData[index].reportStatus,
                            rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                            rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                            reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                        });
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#cancelModal').modal('hide');
                
                this.myGrid.clearselection();
                this.toastr.error('No reports to hold');
                return false;
            }
            this.spinner.show();
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
              
                if (data.responseCode === 1) {
                   
                    this.myGrid.clearselection();
                   
                    this.toastr.success(data.message, '', {});
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                 
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#cancelModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                    
                    this.toastr.error(data.message, '', {});
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.spinner.hide();
                }
            },
                error => {
                  
                    this.spinner.hide();
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.clearselection();
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.selectedRowsData = [];
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
  

    gotoLocations(data) {
        this.router.navigate(["viewstations"],
            { queryParams: { from: 'HandlerDashboard' } }
        );
     
    }

    

    

    onEditClick(rowData) {
        localStorage.setItem('awbNumber', JSON.stringify(rowData.masterAwbNo));
    localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
        rowData.isFrom = 'EDIT_REPORT';
        rowData.from_csaDashBoard = 'true';
        this.router.navigate(['save-report'], { queryParams: rowData, skipLocationChange: true })
    }


  


//  delete, Move to Trash, Restore and manual archive --------

confirm_delete(){
    if (this.selectedRowsData.length != 0) {
        jQuery('#delrepModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
    
}

confirm_manualarchive(){
    if (this.selectedRowsData.length != 0) {
        jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}


confirm_Restore(){
    if (this.selectedRowsData.length != 0) {
        jQuery('#restoreModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
    
}

confirm_Move_To_Trash(){
    if (this.selectedRowsData.length != 0) {
        jQuery('#moveToTrashModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
    
}

DeleteReport(){
    
    if (this.selectedRowsData.length != 0) {
        this.selreportsarray = [];
    for(let i=0; i<this.selectedRowsData.length; i++){
        this.userId = this.selectedRowsData[i].userId,
        this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
        this.currentReportId = this.selectedRowsData[i].reportId,
        this.selreportsarray.push({
            userId: this.userId,
            awbNumber: this.masterAwbNo,
            reportId: this.currentReportId
        });

    }

    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginUserId = this.Object.userId;

        this.finalobj = {
            "loginUserId": this.loginUserId,
            "reportsList": this.selreportsarray
        }
    this.spinner.show();
    this.superAdminservice.DeleteReport(this.finalobj).subscribe(
        data => {
          
            
            if (data.responseCode == '1') {
                this.myGrid.clearselection();
                this.toastr.success(data.message, '', {});
               
                jQuery('#delrepModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
                
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#delrepModal').modal('hide');
                this.spinner.hide();
            }
            else {
               
                this.toastr.error(data.message, '', {});
                jQuery('#delrepModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.clearselection();
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.spinner.hide();
            }
        },
        error => {
           
            this.spinner.hide();
            jQuery('#delrepModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.clearselection();
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.selectedRowsData = [];
        }
    )
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}

manualarchive(){
    
    if (this.selectedRowsData.length != 0) {
        this.selreportsarray = [];
    for(let i=0; i<this.selectedRowsData.length; i++){
        this.userId = this.selectedRowsData[i].userId,
        this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
        this.currentReportId = this.selectedRowsData[i].reportId,
        this.selreportsarray.push({
            userId: this.userId,
            awbNumber: this.masterAwbNo,
             reportId: this.currentReportId
        });
    }

    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginUserId = this.Object.userId;

        this.finalobj = {
            "loginUserId": this.loginUserId,
            "updatedOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "reportsList": this.selreportsarray
        }
    this.spinner.show();
    this.superAdminservice.SetReportArchive(this.finalobj).subscribe(
        data => {
            if (data.responseCode == '1') {
                this.myGrid.clearselection();
                this.toastr.success(data.message, '', {});
                jQuery('#manarchModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
                
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#manarchModal').modal('hide');
                this.spinner.hide();
            }
            else {
                this.toastr.error(data.message, '', {});
                jQuery('#manarchModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.selectedRowsData = [];
                this.spinner.hide();
            }
        },
        error => {
            this.spinner.hide();
            jQuery('#manarchModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.selectedRowsData = [];
        }
    )
    }
    else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}

moveToTrashorRestoreReport(status){
    if (this.selectedRowsData.length != 0) {
        this.selreportsarray = [];
    for(let i=0; i<this.selectedRowsData.length; i++){
        this.userId = this.selectedRowsData[i].userId,
        this.currentReportId = this.selectedRowsData[i].reportId,
        this.selreportsarray.push({
            userId: this.userId,
            awbNumber: this.masterAwbNo,
            reportId: this.currentReportId,
            reportOrgId: this.selectedRowsData[i].reportOrgId,
            reportOrgType: this.selectedRowsData[i].reportOrgType
        });
    }

    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginUserId = this.Object.userId;
        this.finalobj = {
            "loginUserId": this.loginUserId,
            "trashMovedStatus": status,
            "organisationId": this.Object.orgId,
            "updatedOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "reportsList": this.selreportsarray,
        }
        let url;
        if (status === 'Y') {
            url = 'setReportTrash.php/setReportTrash';
        } else {
            url = 'setReportTrash.php/reactivateReportTrash';
        }
        this.spinner.show();
        this.superAdminservice.MoveToTrashReport(url, this.finalobj).subscribe(
        data => {
            if (data.responseCode == '1') {
                this.toastr.success(data.message, '', {});
                jQuery('#moveToTrashModal').modal('hide');
                jQuery('#restoreModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#moveToTrashModal').modal('hide');
                jQuery('#restoreModal').modal('hide');
                this.spinner.hide();
            }
            else {
                this.toastr.error(data.message, '', {});
                jQuery('#moveToTrashModal').modal('hide');
                jQuery('#restoreModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
                this.spinner.hide();
            }
        },
        error => {
            this.spinner.hide();
            jQuery('#moveToTrashModal').modal('hide');
            jQuery('#restoreModal').modal('hide');
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
            this.reportsDataGrid_Ref.myGrid.gotopage(0)
            this.reportsDataGrid_Ref.myGrid.clearselection()
            this.selectedRowsData = [];
        }
)
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
}
//  delete, Move to Trash, Restore and manual archive --------




    onPageChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }
    onPageSizeChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }

    public selectedRowsData = [] ;
    _onSelectRow(event){ // trigger when checkbox checked / unchecked 
        
        if(Array.isArray(event.args.rowindex)){ 
           if(event.args.rowindex.length !== 0){
            this.selectedRowsData = this.reportsDataGrid_Ref.getDtotalData() ;
           }else{
            this.selectedRowsData = [];
           }
        }else{
            if( event.type === "rowunselect"){
                let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
                this.selectedRowsData.splice(index, 1);
            }else{
                var ind = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.findIndex(x => x.reportId === event.args.row.reportId);
                event.args.row.carrierId = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA[ind].carrierId;
                this.selectedRowsData.push(event.args.row) ; 
            }
        }
         
    }


  //redirect to Uld damage reports
  onClickUldDamage_Reports() {
    localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
    this.router.navigateByUrl('/Uld/viewuld');
}

// redirect to handler cargo image
onClickCargo_Image(){
    localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"))
    this.router.navigateByUrl('/CargoImageHandler/view-shipment');
  }


  OnClickTruckInspection() {
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
      this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
    }, 200);
  }


  OnClickServiceRecovery() {
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("ServiceRecovery"));
      this.router.navigateByUrl('/csa-service-recovery');
    }, 200);
  }

  onclick_CustomerReportHandler(){
    console.log("customerReportHandler");
    localStorage.setItem("currentModule", JSON.stringify("customerReportHandler"));
    this.router.navigateByUrl("/customerReportHandler");
}

  onClick_Save_Report() {
    const  obj = {
        isFrom : 'CREATE_REPORT'
    };
    this.router.navigate(['save-report'], { queryParams: obj });
  }
    
    /*  
        111023 To remove the old KPT charts code start
        eventHandler(event: any): void {
            this.spinner.show();
            this.totalDamageReport = event.TotalRows;
            let $this = this;
            // damageReportbyAirline chart code start by iwi
                this.damageReportbyAirlineChart = event.damageReportbyAirline;
                this.reportbyAirlineLabeldata= [];
                this.reportbyAirlineRealdata= [];
                if (this.damageReportbyAirlineChart != null) {
                    for (let i = 0; i < this.damageReportbyAirlineChart.length; i++) {
                        this.reportbyAirlineLabeldata.push(this.damageReportbyAirlineChart[i].airline_name);
                        this.reportbyAirlineRealdata.push(this.damageReportbyAirlineChart[i].Count);
                    }
                }
                var ReportbyAirline_New = {
                    labels: this.reportbyAirlineLabeldata,
                    datasets: [
                        {
                            label: "Total Damage Report",
                            data: this.reportbyAirlineRealdata,
                            backgroundColor:  "#ff7a15",
                            barThickness: 15,
                            hoverOffset: 4,
                            borderRadius: Number.MAX_VALUE
                            //hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.damageReportbyAirlineChart = new Chart("damageReportbyAirlineChart", {
                    type: 'horizontalBar',
                    data: ReportbyAirline_New,
                    options:{
                        maintainAspectRatio: false,
                        scales:{
                            xAxes:[{
                                //barThickness: 6
                                display: true,
                                ticks: {
                                    display: true, //this will remove only the label
                                    min: 0
                                 },
                                // angleLines: {
                                //     display: false
                                // }
                            }],
                             yAxes: [{
                                display: true,
                                ticks:{
                                    display:true
                                }
                            }]
                        },
                        legend:{
                            display:false
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                    
                });
            // damageReportbyAirline chart code end by iwi


            // damageReportResposibility chart code start by iwi
                this.damageResposibilityChart = event.damageResponsibilityStatus;
                this.ResposibilityLabeldata= [];      
                this.ResposibilityRealdata= [];
                if (this.damageResposibilityChart != null) {
                    for (let i = 0; i < this.damageResposibilityChart.length; i++) {
                        this.ResposibilityLabeldata.push(this.damageResposibilityChart[i].reportStatus);
                        this.ResposibilityRealdata.push(this.damageResposibilityChart[i].Count);
                    }
                }
                var damageResposibility_New = {
                    labels: this.ResposibilityLabeldata,
                    datasets: [
                        {
                            label: "Total Damage Report",
                            data: this.ResposibilityRealdata,
                            backgroundColor:  [
                              'rgb(255, 99, 132)',
                              'rgb(54, 162, 235)',
                              'rgb(255, 205, 86)'
                            ] ,
                            hoverOffset: 4,
                            borderWidth:1,
                            borderRadius: Number.MAX_VALUE
                            //hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.damageResposibilityChart = new Chart("damageResposibilityChart", {
                    type: 'doughnut',
                    data: damageResposibility_New,
                    options:{
                        scales:{
                            xAxes:[{
                                //barThickness: 6
                                display: false,
                                ticks: {
                                    display: false //this will remove only the label
                                 },
                                // angleLines: {
                                //     display: false
                                // }
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                    
                });
            // damageReportResposibility chart code end by iwi


            // contentAffectedChart chart code start by iwi
                this.contentAffectedChart = event.damageContentAffected;
                this.contentAffectedLabeldata= [];
                this.contentAffectedRealdata= [];
                if (this.contentAffectedChart != null) {
                    for (let i = 0; i < this.contentAffectedChart.length; i++) {
                        this.contentAffectedLabeldata.push(this.contentAffectedChart[i].discoveresCaused);
                        this.contentAffectedRealdata.push(this.contentAffectedChart[i].Count);
                    }
                }
                var contentAffectedData = {
                    labels: this.contentAffectedLabeldata,
                    datasets: [
                        {
                            label: "Content Affected",
                            data: this.contentAffectedRealdata,
                            borderRadius: Number.MAX_VALUE, 
                            barThickness: 12,
                            grouped: true,
                            backgroundColor:  [
                                '#009EF7',
                                '#ff6384',
                                '#7239EA',
                                '#EDC539',
                                '#50cd89'
                                ],
                            hoverBackgroundColor: "#8bc7f7"
                        }]
                };
                this.contentAffectedChart = new Chart("contentAffectedChart", {
                    type: 'pie',
                    data: contentAffectedData,
                    options: {
                        // indexAxis: 'y',
                        scales: {
                            xAxes: [{
                                ticks: {
                                    display: false // Edit the value according to what you need
                                },
                                gridLines: {
                                    display:false
                                }
                            }],
                            yAxes: [{
                                stacked: false,
                                ticks:{display:false},
                                gridLines: {
                                    display:false
                                }
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });
            // contentAffectedChart chart code end by iwi

            // classificationChart chart code start by iwi
                this.classificationChart = event.damageClassificationType;
                this.classificationLabeldata= [];
                this.classificationRealdata= [];
                if (this.classificationChart != null) {
                    for (let i = 0; i < this.classificationChart.length; i++) {
                        this.classificationLabeldata.push(this.classificationChart[i].classification_type);
                        this.classificationRealdata.push(this.classificationChart[i].Count);
                    }
                }
                var classificationData_New = {
                    labels: this.classificationLabeldata,
                    datasets: [
                        {
                            label: "Classification",
                            data: this.classificationRealdata,
                            grouped: true,
                            backgroundColor:  [
                                '#009EF7',
                                '#ff6384',
                                '#7239EA',
                                '#EDC539',
                                '#50cd89',
                                '#c0c7eb'
                                ],
                            hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.classificationChart = new Chart("classificationChart", {
                    type: 'pie',
                    data: classificationData_New,
                    options: {
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    display: false // Edit the value according to what you need
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    display: false // Edit the value according to what you need
                                },
                                stacked: true
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });

            // classificationChart chart code end by iwi

            // reports per month Chart code start by iwi
                this.damageReportsperMonthChart = event.damageReportEveryMonth;
                this.damageReportsperMonthLabelsData= [];
                this.damageReportsperMonthRealData= [];
                if (this.damageReportsperMonthChart != null) {
                    for (let i = 0; i < this.damageReportsperMonthChart.length; i++) {
                        this.damageReportsperMonthLabelsData.push(this.damageReportsperMonthChart[i].year);
                        this.damageReportsperMonthRealData.push(this.damageReportsperMonthChart[i].Count);
                    }
                }
                var monthGroupData_fifth = {
                    labels: this.damageReportsperMonthLabelsData,
                    datasets: [
                        {
                            label: "Reports Per Month",
                            data: this.damageReportsperMonthRealData,
                            backgroundColor:  "#009EF7" ,
                            barThickness:15,
                            hoverBackgroundColor: "#8bc7f7",
                            fill: true
                        }]
                };
                this.damageReportsperMonthChart = new Chart("damageReportsperMonthChart", {
                    type: 'bar',
                    data: {
                        labels: this.damageReportsperMonthLabelsData,
                        datasets: [{
                            label: '',
                            data: this.damageReportsperMonthRealData,
                            barThickness:15,
                            backgroundColor: "#7239EA"
                        }, {
                            label: '',
                            data: this.damageReportsperMonthRealData,
                            backgroundColor: "#F5F0FF",
                            borderColor: "#F5F0FF",

                            // Changes this dataset to become a line
                            type: 'line'
                        }],
                        // labels: ['January', 'February', 'March', 'April']
                    },
                    // data: monthGroupData_fifth,
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    min: 0 
                                }
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        legend: {
                          display: false

                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                        },
                        onClick: this.chartClick.bind(this)
                        // onClick: function (e) {
                        //     var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                        //     $this.handleChartClick("reportsByStation",activePointLabel);
                        // }
                    }
                });
            // reports per month Chart code end by iwi


            // reportsby Station Chart chart code start by iwi
                this.reportsbyStationChart = event.damageReportbyStationCode;
                this.reportsbyStationLabeldata= [];
                this.reportsbyStationRealdata= [];
                if (this.reportsbyStationChart != null) {
                    for (let i = 0; i < this.reportsbyStationChart.length; i++) {
                        this.reportsbyStationLabeldata.push(this.reportsbyStationChart[i].station_code);
                        this.reportsbyStationRealdata.push(this.reportsbyStationChart[i].Count);
                    }
                }
                var reportsbyStationData_fourth = {
                    labels: this.reportsbyStationLabeldata,
                    datasets: [
                        {
                            label: "Reports by Station",
                            data: this.reportsbyStationRealdata,
                            backgroundColor:  "#009EF7" ,
                            barThickness:15,
                            hoverBackgroundColor: "#8bc7f7",
                            fill: true
                        }]
                };
                this.reportsbyStationChart = new Chart("reportsbyStationChart", {
                    type: 'bar',
                    data: reportsbyStationData_fourth,
                    options: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    min: 0 // Edit the value according to what you need
                                }
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });
            // reportsby Station Chart chart code end by iwi
            this.spinner.hide();
        }

        chartClick(e): void {
            console.log(e);
        }

        handleChartClick(activePoint, activePointLabel): void {
            console.log("1828 activePoint ", activePoint);
            console.log("1829 activePointLabel ", activePointLabel);
        }
        111023 To remove the old KPT charts code end
    */
    
}
