import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    @Input() currentModuleName: string;
    searchItem: any;
    Allp = 1;
    AllpageIndex = 0;
    AllpageSize = 4;
    AlllowValue = 0;
    AllhighValue = 4;

    authp = 1;
    authpageIndex = 0;
    authpageSize = 4;
    authlowValue = 0;
    authhighValue = 4;

    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 4;
    unauthlowValue = 0;
    unauthhighValue = 4;

    premiump = 1;
    premiumpageIndex = 0;
    premiumpageSize = 4;
    premiumlowValue = 0;
    premiumhighValue = 4;

    nonpremiump = 1;
    nonpremiumpageIndex = 0;
    nonpremiumpageSize = 4;
    nonpremiumlowValue = 0;
    nonpremiumhighValue = 4;

    Obj: any;
    role: string;
    authorizedOrgs = [];
    unAuthorizedOrgs = [];
    premiumOrgs = [];
    nonPremiumOrgs = [];
    allAUOrgs = [];
    allPNOrgs = [];

    search_Allorgs = [];
    serach_authorgs = [];
    serach_unauthorgs = [];
    serach_premium = [];
    serach_nonpremium = [];

    allStatus: boolean;
    authStatus: boolean;
    nonauthStatus: boolean;
    premiumStatus: boolean;
    nonpremiumStatus: boolean;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    userName: any;
    organizationDetails: any;
    recentActivites: any;
    loginDetails: any;
    LoginDetails: any;
    comapanydetails: any;
    orgType: any;
    sidenavhome: string;
    sidenavsettings: string;
    sidenavaddorg: string;
    sidenavstations: string;
    sidenavairline: string;
    sidenavreport: string;
    sidenavunauth: string;
    sidenavaccessstation: string;
    sideNavAllUsers: string;
    sidenavairlines: string;
    sidenavspecialstation: string;
    addressBook:string;
    val: any;
    emailSettings:string;
    kpicharts: any;
    sideNavCreatuser: string;
    sidenavaccessreport: string;
    disableOther_Buttons: boolean = false;
    currentModule: any;
    companydetails: any;
    CompanyLogoimgurl: '../../../assets/images/no_logo.png';
    CompanyName: any;
    constructor(private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, public router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log('sidebar logindetails', this.userName);

        // this.sidenavhome = 'sidenavhighlight';
        this.val = JSON.parse(localStorage.getItem('activation'));

        if (this.val !== null && this.val !== undefined && this.val !== '') {
            this.activecall(this.val);
        }

        this.currentModule = JSON.parse(localStorage.getItem('currentModule'));
        if (this.currentModule === "ULD_DAMAGE") {
            this.disableOther_Buttons = true
        }
        this.companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        // this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.companydetails === null) {
            this.CompanyLogoimgurl = this.userName.orgLogo;
            this.CompanyName =  this.userName.organisationName;
        } else {
            this.CompanyLogoimgurl = this.companydetails.orgLogo;
            this.CompanyName = this.companydetails.orgName;
        }
        if(this.CompanyLogoimgurl === null || this.CompanyLogoimgurl === undefined){
            this.CompanyLogoimgurl = '../../../assets/images/no_logo.png'
        }

    }

    activecall(val) {
        localStorage.setItem('activation', JSON.stringify(val));
        if (val === '1') {
            this.sidenavhome = 'sidenavhighlight';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '2') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavhighlight';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '3') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavhighlight';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '4') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavhighlight';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '5') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavhighlight';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '6') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavhighlight';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '7') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavhighlight';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '8') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavhighlight';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '9') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavhighlight';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '10') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavhighlight';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '11') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavhighlight';
            this.sidenavairlines =  'sidenavdefault'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '12') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavairlines =  'sidenavhighlight'
            this.sidenavspecialstation = 'sidenavdefault';
        }
        if (val === '13') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavspecialstation = 'sidenavhighlight';
        }
        // 111023 To added the new KPT charts code start by iwi
        if (val === '14') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavspecialstation = 'sidenavdefault';
            this.kpicharts = 'sidenavhighlight'
        }
        // 111023 To added the new KPT charts code end by iwi

        //22.04.2024 ny iwi============
        if (val === '15') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavspecialstation = 'sidenavdefault';
            this.addressBook = 'sidenavhighlight'
            
        }

        if (val === '16') {
            this.sidenavhome = 'sidenavdefault';
            this.sidenavsettings = 'sidenavdefault';
            this.sidenavaddorg = 'sidenavdefault';
            this.sidenavstations = 'sidenavdefault';
            this.sidenavairline = 'sidenavdefault';
            this.sidenavreport = 'sidenavdefault';
            this.sidenavunauth = 'sidenavdefault';
            this.sidenavaccessstation = 'sidenavdefault';
            this.sideNavAllUsers = 'sidenavdefault';
            this.sideNavCreatuser = 'sidenavdefault';
            this.sidenavaccessreport = 'sidenavdefault';
            this.sidenavspecialstation = 'sidenavdefault';
            this.emailSettings = 'sidenavhighlight'
            
        }
    }

    gotosetting() {
        this.router.navigateByUrl('/settings');
    }
    gotoviewReports(type) {
        ;
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        if (type === 'report') {
            this.ViewReportsData.isfromSideBarViewReports = "yes"
        }

        this.router.navigateByUrl('/viewusers');
    }
    gotoDashBoard() {
        ;
        this.currentModuleName
        let currentModule = JSON.parse(localStorage.getItem('currentModule'));
        if (currentModule === "cargoImage_Handler") {
            this.router.navigateByUrl('/CargoImageHandler/view-shipment');
        } else if (currentModule === "CargoAcceptance") {
                this.router.navigateByUrl('/CargoAcceptance/view-cargo-acceptance');
        } else if (currentModule === "Truckinspection") {
            this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
        } 
        else if (currentModule === "CARGODAMAGE-FORWARDER") {
            this.router.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder');
        } 
        else {
            if (!this.disableOther_Buttons) {
                this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
                if (this.userName.userType === 'SA') {
                    this.router.navigateByUrl('/sa-dashboard');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/sa-dashboard']));
                } else if (this.userName.userType === 'S' && this.userName.orgType == 'H') {
                    this.router.navigateByUrl('/csa-dashboard');
                }
                else if (this.userName.userType == 'E') {
                        if (this.userName.orgType == 'E') {
                            this.router.navigateByUrl('/CargoImage/view-shipment');
                        } else {
                            this.router.navigateByUrl('/user-dashboard');
                        }
                }
                else if (this.userName.userType == 'S' && this.userName.orgType == 'A') {
                    this.router.navigateByUrl('/csa-airline-dashboard');
                }
                else if (this.userName.userType == 'A' && this.userName.orgType == 'A') {
                    this.router.navigateByUrl('/admin-airline-dashboard');
                }
                else if (this.userName.userType == 'A' && this.userName.orgType == 'H') {
                    this.router.navigateByUrl('/admin-handler-dashboard');
                } else {
                    this.router.navigateByUrl('/CargoImage/view-shipment');
                }
            } else {
                this.router.navigateByUrl('/Uld/viewuld');
            }

        }
    }





    navigateToReports() {
        ;
        this.router.navigate(["viewusers"]);
        this.ViewReportsData.isFrmViewDetails = "yes"
    }
    navigateToStations() {
        ;
        const stationsFrom = "sidebarStations"
        localStorage.setItem('stationsFrom', JSON.stringify(stationsFrom));
        this.router.navigate(["viewstations"], { queryParams: { from: 'SidebarStations' } });
        this.ViewReportsData.isfromSidebarStations = 'yes'
    }
    navigateToAllReports() {
        ;
        localStorage.removeItem('sideReportsTab');
        this.router.navigate(["viewreports"]);

    }

    navigateToAllusers() {
        ;
        const usersFrom = "SidebarAllUsers"
        localStorage.setItem('stationsFrom', JSON.stringify(usersFrom));
        localStorage.removeItem("selected_Shipments_Tab");
        if (this.userName.userType === "S") {
            this.router.navigate(["viewusers"], { queryParams: { from: 'SidebarAllUsers' } });
        }
    }

    gotoModules() {
        this.router.navigate(["HandlerDashboardComponent"]);
    }

}
