export class generalinfo {
      
        public categoryId: any;
        public iswebormobile: any;
        public userId: any;
        public organizationId: any;
        public incidentDate: any;
        public incidentTime: any;
        public masterAwbNo: any;
        public emailId: any;
        public address: any;
        public stationCode: any;
        public reportPreparedName: any;
        public reportPreparedFunction: any;
        public privacyStatus:any;
        public orgName :any;
        public carrierId :any;
   
}
