import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay, startWith } from "rxjs/operators";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormGroupDirective,
    AbstractControl,
} from "@angular/forms";
import { ConfirmPasswordValidator } from "./password.validator";
import { MyaccountService } from "../services/myaccount.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { debug } from "util";
import { NgxSpinnerService } from "ngx-spinner";

import { NavbarCargoClaimComponent } from "../layouts/navbar/navbarCargoClaim.component"
declare var jQuery: any;
@Component({
    selector: "app-myaccount",
    templateUrl: "./myaccount.component.html",
    styleUrls: ["./myaccount.component.css"],
})
export class MyaccountComponent implements OnInit {
    myAccount;
    Logindetails: any;
    myAccountDetails: any;
    formDirective;
    colorChangeone: string;
    colorChangetwo: string;
    colorChangethree: string;
    ChangePasswordForm: FormGroup;
    EditProfileForm: FormGroup;
    show: boolean = true;
    show1: any = true;
    viewreport: boolean;
    editeport: boolean;
    changerpass: boolean;
    LoginData: string;
    isReadOnly: boolean;
    isStationCodeReadOnly: boolean;
    isAddressReadOnly: boolean;
    OldEmail: any;
    isUpdatable: boolean;
    LogindetailsOne: any;
    // stationCode: FormControl;
    stationsList: Observable<any[]>;
    states: any;
    stationsListObj: any;
    showAutocomplete: boolean;
    buttonDisabled: boolean;
    stationDetailsObj: any;
    isEmailReadOnly: boolean;
    loginresultobject: any;
    dashBoradSwitchedtemp: any;
    colorChangefour: string;
    apitoken: boolean;
    loginData: any;
    tokenApiObj: any;
    AuthToken: any;
    ApiKey: any;
    detailsbm2d() {
        this.show = !this.show;
    }
    detailskod2d() {
        this.show1 = !this.show1;
    }
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        );
    constructor(
        private NavbarComponent: NavbarCargoClaimComponent,
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private breakpointObserver: BreakpointObserver,
        private formBuilder: FormBuilder,
        private MyAccountService: MyaccountService
    ) { }
    ngOnInit() {
        window.scroll(0, 0);
        let EMAILPATTERN =
            "(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}";
        this.LogindetailsOne = JSON.parse(localStorage.getItem("loginDetails"));
        let getstationobj = {
            orgId: this.LogindetailsOne.orgId,
        };
        this.MyAccountService.getOrgStations(getstationobj).subscribe(
            (data) => {
                if (data.responseCode === 1) {
                    this.stationsListObj = data;
                    this.stationDetailsObj = this.stationsListObj.stationDetails;
                }
                else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.warning("your session expired please login again");
                    this.router.navigateByUrl("/");
                }
            },
            (error) => {
                this.spinner.hide();
            }
        );
        this.LogindetailsOne = JSON.parse(localStorage.getItem("loginDetails"));
        const inputobj = {
            userId: this.LogindetailsOne.userId,
            orgId: this.LogindetailsOne.orgId,
        };
        this.MyAccountService.myAccountDetails(inputobj).subscribe((data) => {
            this.spinner.hide();
            if (data != null) {
                this.myAccountDetails = data.userDetails;
                this.EditProfileForm.setValue({
                    firstname: this.myAccountDetails.firstName,
                    lastname: this.myAccountDetails.lastName,
                    emailid: this.myAccountDetails.loginId,
                    stationcode: this.myAccountDetails.stationCode,
                    contactnumber: this.myAccountDetails.contactNumber,
                    address: this.myAccountDetails.address,
                });
                if (this.myAccountDetails.userType === "S") {
                    this.isAddressReadOnly = true;
                }
            }
            else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.warning("your session expired please login again");
                this.router.navigateByUrl("/");
            } else {
                this.toastr.success("Data Unavailable", "", {});
            }
        });
        this.colorChangeone = "load-delay";
        this.viewreport = true;
        this.editeport = false;
        this.changerpass = false;
        //  -----------------validations--------------------//
        let regex = /(?=.*[_!@#$%^&*-+=~`()<>])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])^.{12,}$/;

        this.ChangePasswordForm = this.formBuilder.group(
            {
                OldPassword: new FormControl(null, [Validators.required]),
                NewPassword: new FormControl(null, [
                    Validators.required,
                    Validators.pattern(regex),
                ]),
                ConfirmPassword: new FormControl(null, [Validators.required]),
            },
            {
                validator: ConfirmPasswordValidator.MatchPassword,
            }
        );
        this.EditProfileForm = this.formBuilder.group({
            firstname: new FormControl(""),
            lastname: new FormControl(""),
            emailid: new FormControl("", [
                Validators.required,
                Validators.pattern(EMAILPATTERN),
            ]),
            stationcode: new FormControl("", [Validators.required]),
            contactnumber: new FormControl("", [
                Validators.required,
                Validators.pattern("[1-9]\\d{9}"),
            ]),
            address: new FormControl(""),
        });
        //  -----------------validations--------------------//
        this.stationsList = this.EditProfileForm.controls[
            "stationcode"
        ].valueChanges.pipe(
            startWith(""),
            map((stateobj) =>
                stateobj ? this.filterStations(stateobj) : this.stationDetailsObj
            )
        );
        this.EditProfileForm.valueChanges.subscribe((data) => {
            // this.EditProfileForm.setErrors(null);
        });
    }
    // ------------------Edit details-----------------//
    Update() {
        this.LogindetailsOne = JSON.parse(localStorage.getItem("loginDetails"));
        if (this.EditProfileForm.dirty) {
            const UpdatedDetails = {
                orgId: this.LogindetailsOne.orgId,
                userId: this.LogindetailsOne.userId,
                userType: this.LogindetailsOne.userType,
                firstName: this.EditProfileForm.value.firstname.replace(/[^a-zA-Z\s]*/g, ''),
                lastName: this.EditProfileForm.value.lastname.replace(/[^a-zA-Z\s]*/g, ''),
                emailId: this.EditProfileForm.value.emailid,
                contactNumber: this.EditProfileForm.value.contactnumber,
                stationCode: this.EditProfileForm.value.stationcode,
                address: this.EditProfileForm.value.address,
            };
            this.spinner.show();
            jQuery("#myModal").modal("hide");
            this.MyAccountService.updateUserDetails(UpdatedDetails).subscribe(
                (data) => {
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                        console.log(data);
                        this.toastr.success(data.message, "", {});
                        // jQuery("#myModal").modal("hide");
                        this.EditProfileForm.setErrors({ invalid: true });
                        this.myAccountDetails = "";
                        this.myAccountDetails = data;
                        this.Logindetails = JSON.parse(localStorage.getItem(""));
                        this.loginresultobject = JSON.parse(
                            localStorage.getItem("loginDetails")
                        );
                        this.loginresultobject.stationCode = data.stationCode;
                        this.loginresultobject.firstName = data.firstName;
                        this.loginresultobject.lastName = data.lastName;
                        this.loginresultobject.loginID = data.loginId;
                        this.loginresultobject.emailId = data.loginId;
                        localStorage.setItem(
                            "loginDetails",
                            JSON.stringify(this.loginresultobject)
                        );
                        const oldEmail = this.LogindetailsOne.emailId;
                        const NewEmail = this.EditProfileForm.value.emailid;
                        this.EditProfileForm.setErrors(null);
                        if (oldEmail != NewEmail) {
                            this.dashBoradSwitchedtemp = JSON.parse(
                                localStorage.getItem("dashBoradSwitchedtemp")
                            );
                            if (this.dashBoradSwitchedtemp !== null) {
                                ;
                                if (this.dashBoradSwitchedtemp.isSwitched === "yes") {
                                    this.NavbarComponent.Logout();
                                    // localStorage.clear();
                                    // this.router.navigateByUrl("/Home");
                                }
                            } else {
                                ;
                                this.NavbarComponent.Logout();
                                // localStorage.clear();
                                // this.router.navigateByUrl("/Home");
                            }
                        } else {
                            this.viewpro("view");
                        }
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toastr.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toastr.error(data.message, "", {});

                    }
                }
            );
        }
    }
    updateUserDetails() {
        if (this.EditProfileForm.dirty) {
            jQuery("#myModal").modal({ backdrop: "static", keyboard: false });
        } else {
            this.toastr.error("No values changed");
        }
    }
    // ------------------side bar colorchange---------------------//
    viewpro(val) {
        if (val === "view") {
            this.colorChangeone = "load-delay";
            this.colorChangetwo = "load-delayone ";
            this.colorChangethree = "load-delayone ";
            this.colorChangefour = "load-delayone";
            this.viewreport = true;
            this.editeport = false;
            this.changerpass = false;
            this.apitoken = false;
        } else if (val === "edit") {
            this.colorChangeone = "load-delayone";
            this.colorChangetwo = "load-delay ";
            this.colorChangethree = "load-delayone ";
            this.colorChangefour = "load-delayone";
            this.viewreport = false;
            this.editeport = true;
            this.changerpass = false;
            this.apitoken = false;
            this.EditProfileForm.setValue({
                firstname: this.myAccountDetails.firstName,
                lastname: this.myAccountDetails.lastName,
                emailid: this.myAccountDetails.loginId,
                stationcode: this.myAccountDetails.stationCode,
                contactnumber: this.myAccountDetails.contactNumber,
                address: this.myAccountDetails.address,
            });
            this.EditProfileForm.markAsPristine();
        } else if (val === "change") {
            this.colorChangeone = "load-delayone";
            this.colorChangetwo = "load-delayone ";
            this.colorChangethree = "load-delay";
            this.colorChangefour = "load-delayone";
            this.viewreport = false;
            this.editeport = false;
            this.changerpass = true;
            this.apitoken = false;
            this.ChangePasswordForm.reset();
        } else if (val === "token") {
            this.colorChangeone = "load-delayone";
            this.colorChangetwo = "load-delayone ";
            this.colorChangethree = "load-delayone";
            this.colorChangefour = "load-delay";
            this.viewreport = false;
            this.editeport = false;
            this.changerpass = false;
            this.apitoken = true;
        }
    }
    // ------------------side bar colorchange---------------------//
    //-----------------ChangePasword---------------------------------//
    passwordChange(ChangePasswordForm: any, formDirective: FormGroupDirective) {
        this.LogindetailsOne = JSON.parse(localStorage.getItem("loginDetails"));
        const obj: {} = {
            orgId: this.LogindetailsOne.orgId,
            userId: this.LogindetailsOne.userId,
            oldPassword: this.ChangePasswordForm.value.OldPassword,
            newPassword: this.ChangePasswordForm.value.NewPassword,
        };
        this.spinner.show();
        this.ChangePasswordForm.invalid;
        this.MyAccountService.passwordChange(obj).subscribe(
            (data) => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    this.NavbarComponent.Logout();
                    //   localStorage.clear();
                    //   this.router.navigateByUrl("/login");
                }
                else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.warning("your session expired please login again");
                    this.router.navigateByUrl("/");
                }
                else {
                    this.toastr.error(data.message, "", { timeOut: 1200 });
                    this.ChangePasswordForm.valid;
                }
            },
            (error) => {
                this.toastr.error("unable Updated Password", "", {});
            }
        );
    }
    //-----------------ChangePasword---------------------------------//
    resetConfirmPassword() {
        this.ChangePasswordForm.controls["ConfirmPassword"].reset();
    }
    filterStations(stateobj: string) {
        return this.stationsListObj.stationDetails.filter(
            (state) =>
                state.portCode.toLowerCase().indexOf(stateobj.toLowerCase()) === 0
        );
    }
    onSelect(state) {
        this.EditProfileForm.controls["address"].patchValue(state.address);
        this.EditProfileForm.setErrors(null);
    }
    updatedVal(e) {
        if (e && e.length === 0) {
            this.showAutocomplete = true;
        } else {
            this.showAutocomplete = false;
        }
    }
    handleOpenApi() {
        this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
        let tokenObj = {
            org_id: this.loginData.orgId,
            user_id: this.loginData.userId,
        };
        this.spinner.show();
        this.MyAccountService.OpenApi(tokenObj).subscribe(
            (data) => {
                if (data.responseCode === 1) {
                    this.ApiKey = data["X-Api-Key"];
                    this.AuthToken = data["X-Auth-Token"];
                    this.spinner.hide();
                }
                else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.warning("your session expired please login again");
                    this.router.navigateByUrl("/");
                }
            },
            (error) => {
                this.spinner.hide();
            }
        );
    }
    openPopUp() {
        jQuery("#generate-token").modal({ backdrop: "static", keyboard: false });
    }
    handleNewTokens() {
        jQuery("#generate-token").modal("hide");
        this.handleOpenApi();

    }
    copyText(text) {
        const link = text; //Save the text to be copied
        const textarea = document.createElement("textarea");
        textarea.style.opacity = "0";
        textarea.value = link;
        document.body.appendChild(textarea);
        textarea.select();
        const successful = document.execCommand("copy");
        if (successful) {
            this.toastr.success("Copied");
        } else {
            this.toastr.error("Unable to copy");
        }
        if (textarea && textarea.parentNode) {
            textarea.parentNode.removeChild(textarea);
        }
    }
}
