import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NullAstVisitor } from '@angular/compiler';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    token: any;
    loginresultobject: any;
    logedInUserDetails: any;

    constructor(public toast: ToastrService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        ////-------------cclp module --------------./////
        let currentModule = JSON.parse(localStorage.getItem('currentModule'));
        if (currentModule !== "cargoClaim") {
            if(request.url.toString().includes("cargoClaimOpenApi/generateTokenTruckingPortal.php/generateApiTokens")){
                this.logedInUserDetails = JSON.parse(localStorage.getItem('loginresultobject'));
                this.token = this.logedInUserDetails.token
                let md5Token = Md5.hashStr(this.logedInUserDetails.emailId);
                let finalToken = `${md5Token}#${this.token}`
                request = request.clone({
                    headers: request.headers
                        .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                        .set('pragma', 'no-cache')
                        .set('expires', '0')
                        .set('authorization', finalToken)
                }); 
            }else{
                this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
                if (this.loginresultobject != null && this.loginresultobject != undefined && this.loginresultobject != '') {
                    this.token = this.loginresultobject.token
                    request = request.clone({
                        headers: request.headers
                            .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                            .set('pragma', 'no-cache')
                            .set('expires', '0')
                            .set('authorization', this.token)
                    });
                    if (request.method === 'POST') {
                        request = request.clone();
                        request.body.loginUserId = this.loginresultobject.userId
                    } else if (request.method === 'GET') {
                        request = request.clone({
                            setParams: {
                                loginUserId: this.loginresultobject.userId
                            }
                        });
                    }
                } else {
                    request = request.clone({
                        headers: request.headers
                            .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                            .set('pragma', 'no-cache')
                            .set('expires', '0')
                    });
                }
            }
            
        } else {
            ///----------Cargoclaim Module------------------///
            this.token = JSON.parse(localStorage.getItem('Token'));
            if (this.token != null && this.token != undefined && this.token != '') {
                this.logedInUserDetails = JSON.parse(localStorage.getItem('loginresultobject'));
                let md5Token = Md5.hashStr(this.logedInUserDetails.emailId);
                let finalToken = `${md5Token}#${this.token}`
               if(request.url.toString().includes("cclpApis/getLoginCclpPortal.php/companyUsersInfo")   ||  
               request.url.toString().includes("cclpApis/resetPassword.php/changeUsersPassword")||
               request.url.toString().includes("cclpApis/userDetailsCclpPortal.php/editUser")||
               request.url.toString().includes("cclpApis/userDetailsCclpPortal.php/createUser")||
               request.url.toString().includes("cclpApis/tracking.php/viewUserDetails")||
               request.url.toString().includes("clpApis/getLoginCclpPortal.php/getStationsCclpPortal")||
               request.url.toString().includes("cclpApis/setUserRoleCclpPortal.php/setUserRole") ||
               request.url.toString().includes("cclpApis/userDetailsCclpPortal.php/deleteUser") ||
               request.url.toString().includes("cclpApis/resendAndActive.php/activeUser") 
               ) {
                request = request.clone({
                    headers: request.headers
                        .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                        .set('pragma', 'no-cache')
                        .set('expires', '0')
                        .set('authorization', this.logedInUserDetails.token)
                });
               }else{
                request = request.clone({
                    headers: request.headers
                        .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                        .set('pragma', 'no-cache')
                        .set('expires', '0')
                        .set('authorization', finalToken)
                }); 
               }

                ;
                // request = request.clone({
                //     headers: request.headers
                //         .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                //         .set('pragma', 'no-cache')
                //         .set('expires', '0')
                //         .set('authorization', finalToken)
                // });
                if (request.method === 'POST') {
                    ;
                    request = request.clone();
                    request.body.loginUserId = this.logedInUserDetails.userId;
                } else if (request.method === 'GET') {
                    request = request.clone({
                        setParams: {
                            loginUserId: this.logedInUserDetails.userId
                        }
                    });
                }
            } else {
                request = request.clone({
                    headers: request.headers
                        .set('cache-control', 'no-cache, no-store, must-revalidate,post-check=0, pre-check=0',)
                        .set('pragma', 'no-cache')
                        .set('expires', '0')
                });
            }
        }
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body) {
                        if (evt.body.responseCode === 0 && evt.body.message === 'Unauthorized User') {
                            console.log(evt.body.responseCode, evt.body.message, "un User");
                            localStorage.clear();
                            if (this.router.url === "/Home") {
                                this.router.navigateByUrl('/');
                            } else {
                                this.router.navigateByUrl('/Home');
                            }
                        }
                    }
                }
            })
        );
    }
}