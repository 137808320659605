import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { ConfigServiceService } from '../../config-service.service';
const headers = new HttpHeaders(
  {"X_VERIFY"  :  "c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4" }
  );

@Injectable({
  providedIn: 'root'
})
export class AddAirlineEmailsService {
  WEB_API:string ; 
  constructor(private http: HttpClient,config : ConfigServiceService) {
    this.WEB_API =    config.getConfig().URL
   }

  addEmailServc(inputData){
    
    return this.http.post<any>(this.WEB_API+"airlineEmailsCCLPPortal.php/addAirlineEmail",inputData,{headers : headers})

  }


  getAirlineEmails(orgId){
    return this.http.get<any>(this.WEB_API+"airlineEmailsCCLPPortal.php/getAirlineEmails?orgId="+orgId,{headers : headers})
  }

  updateEmailServc(inputData){
    return this.http.post<any>(this.WEB_API+"airlineEmailsCCLPPortal.php/editAirlineEmails",inputData,{headers : headers})
  }

  deleteAirlineEmailsServc(inputData){
    return this.http.post<any>(this.WEB_API+"/airlineEmailsCCLPPortal.php/deleteAirlineEmails",inputData,{headers : headers})
  }


  deleteIndividualEmail(inputData){
    //
    return this.http.post<any>(this.WEB_API+"/airlineEmailsCCLPPortal.php/deleteEmail",inputData)
  }

  getAirlineStationCodes(orgId){
    return this.http.get<any>(this.WEB_API+"getLoginCclpPortal.php/getStationsCclpPortal?orgId=" +orgId);
  }
}
