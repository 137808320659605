import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CargoImageHandlerModuleRoutingModule } from './cargo-image-handler-module-routing.module';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { ViewShipmentDetailsComponent } from './view-shipment-details/view-shipment-details.component';
import { ViewShipmentsComponent } from './view-shipments/view-shipments.component';
import { SharedLayoutsModule } from '../layouts/shared-layouts/shared-layouts.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatSelectModule, MatListModule, MatToolbarModule, MatSidenavModule, MatStepperModule, MatCardModule, MatExpansionModule, MatAutocompleteModule } from '@angular/material';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DxDataGridModule } from 'devextreme-angular';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NumberDirective } from '../save-reports/numbers-only.directive';

@NgModule({
  declarations: [
    CreateShipmentComponent, 
    ViewShipmentDetailsComponent, 
    ViewShipmentsComponent,
    ],
  imports: [
    CommonModule,
    CargoImageHandlerModuleRoutingModule,
    CommonModule,
    SharedLayoutsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    DxDataGridModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
  ]
})
export class CargoImageHandlerModuleModule { }
