import { Component, OnInit, enableProdMode, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { StationService } from "../../services/stations/stations.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var jQuery: any;
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: "app-viewstations",
  templateUrl: "./viewstations.component.html",
  styleUrls: ["./viewstations.component.css"],
})
export class ViewstationsComponent implements OnInit {
  @ViewChild("grid") dataGrid: DxDataGridComponent;
  dataGrid1: DxDataGridComponent;
  mergedReports: any;
  Logindetails: any;
  stationDetails: any;
  static stationsListFromService: any;
  orgId: any;
  deleteObj: { orgId: any; orgType: any; stationCode: any; handler_Id: any };
  getdetails: any;
  userObj: any;
  userId: any;
  data: any;
  Always: boolean;
  constructor(
    private spinner: NgxSpinnerService,
    private stationService: StationService,
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.Logindetails = JSON.parse(localStorage.getItem("loginDetails"));
    this.orgId = this.Logindetails.orgId;
    this.spinner.show();
    this.stationService.ViewStations(this.orgId).subscribe(
      (data) => {
        this.spinner.hide();
        this.stationDetails = data.reverse();
        // localStorage.setItem('getstationsdaetails', JSON.stringify(data));
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  gotoeditstation(val) {
    // this.userObj = val.data;
    // this.userId = val.data.userId;
    // this.router.navigateByUrl('/editstation');
    this.router.navigate(["/cargoClaim/editstation"], {
      queryParams: val.data,
      skipLocationChange: true,
    });
  }
  deletestations() {
    this.data = JSON.parse(localStorage.getItem("stationdeleteData"));
    this.Logindetails = JSON.parse(localStorage.getItem("loginDetails"));
    this.deleteObj = {
      orgId: this.data.orgId,
      orgType: this.Logindetails.orgType,
      stationCode: this.data.stationCode,
      handler_Id: this.data.handlerId,
    };
    this.spinner.show();
    this.stationService.deletestation(this.deleteObj).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status === 1) {
          this.ngOnInit();
          this.toastr.success(data.message, "", { timeOut: 1500 });
          jQuery("#deletePopUp").modal("hide");
        } else {
          jQuery("#deletePopUp").modal("hide");
          this.toastr.error(data.message, "", {});
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  deletedata(val) {
    localStorage.setItem("stationdeleteData", JSON.stringify(val.data));
    jQuery("#deletePopUp").modal({ backdrop: "static", keyboard: false });
  }
}
