import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { MatInputModule } from '@angular/material/input';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DxCheckBoxModule, DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { AuthenticateGuard } from './AuthGuard/authenticate.guard';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddAirlineComponent } from './csa-admin/add-airline/add-airline.component';
import { AddressBookComponent } from './csa-admin/address-book/address-book.component';
import { CreateuserComponent } from './csa-admin/createuser/createuser.component';
import { CSAAdminComponent } from './csa-admin/csa-admin.component';
import { CsaAirlineDashboardComponent } from './csa-admin/csa-airline-dashboard/csa-airline-dashboard.component';
import { CsaDashboardComponent } from './csa-admin/csa-dashboard/csa-dashboard.component';
import { CustomerReportComponent } from './csa-admin/customer-report/customer-report.component';
import { DamageCustomerReportComponent } from './csa-admin/damage-customer-report/damage-customer-report.component';
import { ViewReportsData } from './data/DataShareViewDetails';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './services/login/login.service';
import { SuperadminService } from './services/superadmin/superadmin.service';
import { MyaccountComponent } from './shared/myaccount/myaccount.component';
import { SettingsComponent } from './shared/settings/settings.component';
import { AddstationsComponent } from './shared/stations/addstations/addstations.component';
import { EditstationsComponent } from './shared/stations/editstations/editstations.component';
import { ViewstationsComponent } from './shared/stations/viewstations/viewstations.component';
import { ViewReprotsComponent } from './shared/view-reprots/view-reprots.component';
import { ViewUsersComponent } from './shared/view-users/view-users.component';
import { ViewdetailsComponent } from './shared/viewdetails/viewdetails.component';
import { CreateorgComponent } from './superadmin/createorg/createorg.component';
import { EditorgComponent } from './superadmin/editorg/editorg.component';
import { SaDashboardComponent } from './superadmin/sa-dashboard/sa-dashboard.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { UsersComponent } from './users/users.component';

// import { SelectAutocompleteModule } from 'mat-select-autocomplete';
// import { SidebarComponent } from './layouts/sidebar/sidebar.component'

import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatInputModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTabsModule
} from '@angular/material';
import { MAT_DATETIME_FORMATS, MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { ViewAirlineEmailsComponent } from './csa-admin/view-airline-emails/view-airline-emails.component';

import { AccessStationComponent } from './csa-admin/access-station/access-station.component';
import { EdituserComponent } from './csa-admin/edituser/edituser.component';
import { SlicePipe } from './pipes/slice.pipe';
import { UnauthroizedUsersComponent } from './shared/unauthroized-users/unauthroized-users.component';

import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgDatepickerModule } from 'ng2-datepicker';
import { ReportAccessComponent } from './csa-admin/report-access/report-access.component';
import { UseraccessComponent } from './csa-admin/useraccess/useraccess.component';
import { DownloadComponent } from './download/download.component';
import { AddDSEComponent } from './dse/add-dse/add-dse.component';
import { EditDSEComponent } from './dse/edit-dse/edit-dse.component';
import { ViewDSEComponent } from './dse/view-dse/view-dse.component';
import { DialogContentExampleDialog, HandlerDashboardComponent } from './handler-dashboard/handler-dashboard.component';
import { HomeComponent } from './home/home.component';
import { PrivacypoliciesComponent } from './privacypolicies/privacypolicies.component';
import { RegisterflowComponent } from './registerflow/registerflow.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AutofocusDirective } from './save-reports/autoFocusDirective';
import { PreventCopyPasteDirective } from './save-reports/prevent-copy-paste.directive';
import { SaveReportsComponent } from './save-reports/save-reports.component';
import { AllusersComponent } from './superadmin/allusers/allusers.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { UpdatesComponent } from './updates/updates.component';
// import { DetailedUldComponent } from './uld/detailed-uld/detailed-uld.component';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { CookieService } from 'ngx-cookie-service';
import { CanDeactivateSaveReport } from './AuthGuard/deactivateguard';
import { TokenInterceptor } from './AuthGuard/token.interceptor';
import { AdminAirlineDashboardComponent } from './admin/admin-airline-dashboard/admin-airline-dashboard.component';
import { AdminHandlerDashboardComponent } from './admin/admin-handler-dashboard/admin-handler-dashboard.component';
import { InactivityTimerComponent } from './inactivity-timer';
import { OpenApiComponent } from './open-api/open-api.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UsersListComponent } from './shared/users-list/users-list.component';
import { EdituserOrgComponent } from './superadmin/edituser-org/edituser-org.component';
import { SetRolesComponent } from './superadmin/set-roles/set-roles.component';

import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { AngularResizedEventModule } from 'angular-resize-event';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CargoAcceptanceModule } from './cargo-acceptance/cargo-acceptance.module';
import { CargoImageHandlerModuleModule } from './cargo-image-handler-module/cargo-image-handler-module.module';
import { CargoclaimDashboardModule } from './cargoClaimModule/cargoclaim-dashboard.module';
import { CargoImageModule } from './cargoImageModule/cargo-image.module';
import { ConfigServiceService } from './config-service.service';
import { SharedLayoutsModule } from './layouts/shared-layouts/shared-layouts.module';
import { ExcelService } from './services/excel.service';
import { Active_Archive_ReportsTableComponent } from './shared/dataGrid-tables/active-reports-table/active-archive-reports-table.component';
import { SharedModule } from './shared/sharedModule/shared.module';
import { TruckInspectionModule } from './truck-inspection/truck-inspection.module';
import { UldDamageModule } from './uld/uld-damage/uld-damage.module';

import { NgKnifeModule } from 'ng-knife';
import { AirlinesComponent, DialogOverviewExampleDialog } from './airlines/airlines.component';
import { SampleComponent } from './sample/sample.component';
import { DialogOverviewSetPasswordDialog, SetPasswordComponent } from './set-password/set-password.component';
import { StationComponent } from './station/station.component';

import { SignaturePadModule } from 'angular2-signaturepad';
import { TrimDirective } from './directives/trim.directive';
import { InvalidRequestComponent } from './invalid-request/invalid-request.component';
import { SwitchToCclpComponent } from './switch-to-cclp/switch-to-cclp.component';
// 111023 To added the new KPT charts code start
import { KPIChartsEmailSettingsComponent } from './csa-admin/kpi-charts-email-settings/kpi-charts-email-settings.component';
import { KpichartsComponent } from './csa-admin/kpicharts/kpicharts.component';
import { ReactWebComponent } from './react-web/react-web.component';
// 111023 To added the new KPT charts code end

// 26042024 to service rewcovery
import { AlertBasedOnPiecesAndWeight } from './cargo-acceptance/create-cargoacceptance/create-cargoacceptance.component';
import { CustomerReportHandlerComponent } from './csa-admin/damage-customer-report/customer-report-handler/customer-report-handler.component';
import { AddHandlerServicesComponent } from "./csa-admin/service-recovery/add-handler-services/add-handler-services.component";
import { AddServiceRecoveryComponent } from "./csa-admin/service-recovery/add-service-recovery/add-service-recovery.component";
import { EditServiceRecoveryAirlineComponent } from "./csa-admin/service-recovery/edit-service-recovery-airline/edit-service-recovery-airline.component";
import { EditServiceRecoveryComponent } from "./csa-admin/service-recovery/edit-service-recovery/edit-service-recovery.component";
import { ViewServiceRecoveryComponent } from "./csa-admin/service-recovery/view-service-recovery/view-service-recovery.component";
import { GridReportDetailsComponent } from './csa-admin/damage-customer-report/grid-report-details/grid-report-details.component';

const appConfig = (config : ConfigServiceService) =>{
    return ()=>{
   return config.loadConfig() ;
    }
}

@NgModule({
    declarations: [
       
        InactivityTimerComponent,
        AppComponent,
        LoginComponent,
        AdminComponent,
        DashboardComponent,
        // SidebarComponent,
        // NavbarComponent,
        // FooterComponent,
        SuperadminComponent,
        SaDashboardComponent,
        CreateuserComponent,
        CSAAdminComponent,
        CsaDashboardComponent,
        UsersComponent,
        MyaccountComponent,
        SettingsComponent,
        ViewReprotsComponent,
        ViewUsersComponent,
        ForgotpasswordComponent,
        ViewdetailsComponent,
        AddstationsComponent,
        EditstationsComponent,
        ViewstationsComponent,
        CreateorgComponent,
        EditorgComponent,
        AddAirlineComponent,
        CsaAirlineDashboardComponent,
        ViewAirlineEmailsComponent,
        EdituserComponent,
        UnauthroizedUsersComponent,
        AccessStationComponent,
        SlicePipe,
        // NumberDirective,
        SaveReportsComponent,
        HomeComponent,
        DownloadComponent,
        RegisterflowComponent,
        PrivacypoliciesComponent,
        PreventCopyPasteDirective,
        AutofocusDirective,
        UnAuthorizedComponent,
        UpdatesComponent,
        AllusersComponent,
        ReportAccessComponent,
        UseraccessComponent,
        ResetPasswordComponent,
        AddDSEComponent,
        EditDSEComponent,
        ViewDSEComponent,
        // CreateUldComponent,
        // ViewUldComponent,
        HandlerDashboardComponent,
        // DetailedUldComponent,
        OpenApiComponent,
        SetRolesComponent,
        EdituserOrgComponent,
        AdminHandlerDashboardComponent,
        AdminAirlineDashboardComponent,
        DialogContentExampleDialog,
        UsersListComponent,
        // CharactersOnlyDirectiveDirective,
        PageNotFoundComponent,
        DialogOverviewExampleDialog,
        
        
        
        // ViewShipmentDetailsComponent,
        // CreateShipmentComponent,
        // ViewShipmentsComponent,
        Active_Archive_ReportsTableComponent,
        AirlinesComponent,
        StationComponent,
        SampleComponent,
        SetPasswordComponent,
        InvalidRequestComponent,
        TrimDirective,
        SwitchToCclpComponent,
        // 111023 To added the new KPT charts code start
        KpichartsComponent,
        KPIChartsEmailSettingsComponent,
        ReactWebComponent,
        DialogOverviewSetPasswordDialog,
        AlertBasedOnPiecesAndWeight,
        // 111023 To added the new KPT charts code end
        //220424 to address book and customer form
        CustomerReportComponent,
        AddressBookComponent,
        DamageCustomerReportComponent,
        ViewServiceRecoveryComponent,
        AddServiceRecoveryComponent,
        EditServiceRecoveryComponent,
        EditServiceRecoveryAirlineComponent,
        AddHandlerServicesComponent,
        CustomerReportHandlerComponent,
        GridReportDetailsComponent,
       
    ],
    imports: [
        DxSelectBoxModule,
        DxCheckBoxModule,
        BrowserModule,
        // SelectAutocompleteModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatListModule,
        MatToolbarModule,
        MatSidenavModule,
        DxDataGridModule,
        MatStepperModule,
        MatDialogModule,
        MatCardModule,
        MatExpansionModule,
        HttpClientModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        MatAutocompleteModule,
        NgDatepickerModule,
        AmazingTimePickerModule,
        jqxGridModule,
        SharedLayoutsModule,
        SharedModule,
        UldDamageModule,
        CargoImageModule,
        CargoImageHandlerModuleModule,
        CargoAcceptanceModule,
        CargoclaimDashboardModule,
        TruckInspectionModule,
        ClipboardModule,
        FormsModule, ToastrModule.forRoot({
            preventDuplicates: true,
            maxOpened: 1,
        }),
        MatChipsModule,
        AngularResizedEventModule,
        ImageCropperModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
        MatSelectCountryModule.forRoot('nl'),
        NgKnifeModule,
        SignaturePadModule,
        MatTabsModule
    ],
      exports: [
        // MatAutocompleteModule,
        MatSnackBarModule,
        MatDialogModule,
      ],
      entryComponents: [DialogOverviewExampleDialog, DialogContentExampleDialog, DialogOverviewSetPasswordDialog, AlertBasedOnPiecesAndWeight],

    providers: [
        ExcelService,LoginService,
        ConfigServiceService,
        {
            provide: APP_INITIALIZER,
           useFactory:appConfig,
           multi:true,
           deps:[ConfigServiceService]
        },
        LoginComponent, SuperadminService, AuthenticateGuard, ViewReportsData,
        DatePipe, NavbarComponent, CookieService,
        SidebarComponent,HomeComponent,CanDeactivateSaveReport,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: MAT_DATETIME_FORMATS,
            useValue: {
              parse: {
                dateInput: 'L',
                monthInput: 'MMMM',
                timeInput: 'HH:mm:ss',
                datetimeInput: 'L HH:mm:ss',
              },
              display: {
                dateInput: "L",
                monthInput: "MMMM",
                datetimeInput: "L HH:mm:ss",
                timeInput: "HH:mm:ss",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY",
                popupHeaderDateLabel: "ddd, DD MMM"
              }
            }
          }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
