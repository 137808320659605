import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class DateAndTimeFunctions {

  constructor() {
    
  }

  public static convertUTCDateToLocalDate(date1) {
    try{
      let dt = this.utcToLocal(date1, moment.tz.guess());
      return dt === "Invalid Timezone Operator" ? date1 : dt;
    }catch(exception){
      return date1;
    }
  }


  public static utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
    var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
    var localDateTime
    var hours = zoneValue.split(":")[0]
    var minutes = zoneValue.split(":")[1]
    if (operator === "-") {
      localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else if (operator) {
      localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else {
      localDateTime = "Invalid Timezone Operator"
    }
    return localDateTime
  }

  convertToIso(date: Date){
    let dt  = date.toISOString();
    return   dt.substring(0,10)+" "+ dt.substring(11,19);
  }
}
