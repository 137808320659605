import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from '../config-service.service';
const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});


@Injectable({
  providedIn: 'root'
})
export class CreatereportService {
  API_URL:string ;


  constructor(public http: HttpClient ,config : ConfigServiceService) { 
    this.API_URL = config.getConfig().URL

  }

  // getTruckAirlineDetailsService() {
  //   ;
  //   return this.http.post<any>(this.API_URL + 'tracking.php/getTruckAirlineDetails', {headers: headers});
  // }

  getTruckAirlineDetailsService(){
    return this.http.get<any>(this.API_URL + 'customerService/customerServicePortal.php/getTruckAirlineData',{headers: headers});
  }

  getActivityService() {
    ;
    return this.http.get<any>(this.API_URL + 'handlerregistershipments/HandlerShipementReg.php/getActivity', {headers: headers});
  }

  getTruckAirportService() {
    ;
    return this.http.post<any>(this.API_URL + 'tracking.php/getTruckAirport', {headers: headers});
  }
  getHandlerStations(orgName){
    return this.http.get<any>(this.API_URL + 'registration.php/getHandlerStations?orgName='+ orgName,{headers: headers});
  }
  saveDamageReportDataService(data) {
    return this.http.post<any>(this.API_URL + 'checkListSave.php/saveDamageReportData', data, {headers: headers});
  }

  checkAWBDuplication(data) {
    return this.http.post<any>(this.API_URL + 'checkAwbDuplication.php/checkAwbDuplication', data, {headers: headers});
  }

  saveWebDamageReportService(data) {
    ;
    return this.http.post<any>(this.API_URL + 'checkListSave.php/saveWebDamageReport', data, {headers: headers});
  }


  sendDamageReportToAirlinesService(data) {
    ;
    return this.http.post<any>(this.API_URL + 'checkListSave.php/sendDamageReportToAirlines', data, {headers: headers});
  }

  sendCurrentStatusService(data){
    return this.http.post<any>(this.API_URL + 'checkListSave.php/setHoldTime', data, {headers: headers});
  }

  emailverifyService(data){
    return this.http.post<any>(this.API_URL + 'checkListSave.php/otpverificationfornonLoginUser', data, {headers: headers});
  }

  verifyOtp(registercall) {
    ;
    return this.http.post<any>(this.API_URL + 'tracking.php/verifyOtp', registercall, {headers: headers});
  }

  checkUserServc(emailId) {
    ;
    return this.http.get<any>(this.API_URL + 'checkUserCclpPortal.php/checkUser?emailId='+emailId);
  }
  
    update_DamageReport_Service(data) {
    return this.http.post<any>(this.API_URL + 'editDamageReport.php/updateDamageReport', data, {headers: headers});
  }

  send_ShortReport(data){
    return this.http.post<any>(this.API_URL + 'checkListSave_Short.php/saveDamageReportData', data, {headers: headers});
  }
  update_ShortReport(data){
 return this.http.post<any>(this.API_URL + 'editDamageReport_Short.php/updateDamageReport', data, {headers: headers});
  }


  get_Equip_rule_behaviourData(){
    return this.http.get<any>(this.API_URL + 'getContributeFactors.php/getContributeFactors?');
    
  }

  _send_Mails(inputObj,type){
    console.log(inputObj);
    
    if(type === 'SAVE'){
      return this.http.post<any>(this.API_URL + 'sendReportEmailPdf.php/sendReportEmailPdf', inputObj, {headers: headers});
    }else{
      return this.http.post<any>(this.API_URL + 'editReportEmailPdf.php/editReportEmailPdf', inputObj, {headers: headers});
    }
   
  }

}
