import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { NgxSpinnerService } from "ngx-spinner";
import { SuperadminService } from "../services/superadmin/superadmin.service";
import { ConfigServiceService } from "../config-service.service";
import { globalConstants } from "src/environments/globalConstants";

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {
  @ViewChild("myGrid") myGrid: jqxGridComponent;

  loginDetails: any;
  static webapi: string;
  static TOTAL_GRIDDATA: any = [];
  static totalGridData: any;
  total_count: any;
  stationResp: any;
  isSlectALL_records: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private superAdminService: SuperadminService,
    private config: ConfigServiceService,
  ) { 
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    StationComponent.webapi = this.config.getConfig().ULD_Url;
  }

  url = "";
  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
  source = {
    url: this.url,
    datatype: "json",
    datafields: [
      { name: "actions_e", type: "string" },
      { name: "port_Id", type: "string"},
      { name: "code", type: "string" },
      { name: "name", type: "string" },
      { name: "cityCode", type: "string" },
      { name: "cityName", type: "string" },
      { name: "countryCode", type: "string" },
      { name: "countryName", type: "string" },
      { name: "lat", type: "string" },
      { name: "lon", type: "string" },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "Rows",
    totalrecords: 800,
    cache: false,
    type: "GET",
    sort: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("sort");
    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata("filter");
      this.spinner.show();
    },
    beforeprocessing: (data: any) => {
      this.spinner.hide();
      if (data.responseCode === 1) {
        if (data.Rows !== undefined) {
          if (data.Rows.length !== 0) {
            data.Rows = data.Rows;
              this.total_count = data.TotalRows;
            this.source.totalrecords = data.TotalRows;
            StationComponent.totalGridData = data.Rows;
            if (StationComponent.TOTAL_GRIDDATA) {
              if (StationComponent.TOTAL_GRIDDATA.length === 0) {
                StationComponent.TOTAL_GRIDDATA = data.Rows;
              } else {
                StationComponent.TOTAL_GRIDDATA =
                  StationComponent.TOTAL_GRIDDATA.concat(data.Rows);
              }
            }

            StationComponent.TOTAL_GRIDDATA = this.removeDuplicates(
              StationComponent.TOTAL_GRIDDATA
            );
          }
        } else {
          data.Rows = [];
          this.source.totalrecords = 0;
        }
      } else {
        data.Rows = [];
        this.source.totalrecords = 0;
      }
    },
    pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
    },
  };

  dataAdapter = new jqx.dataAdapter(this.source, {
    
    beforeSend: function (jqXHR, settings) {
      this.loginresultobject = JSON.parse(
        localStorage.getItem("loginresultobject")
      );
      this.token = this.loginresultobject.token;
      jqXHR.setRequestHeader(
        "cache-control",
        "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
      ),
        jqXHR.setRequestHeader("pragma", "no-cache"),
        jqXHR.setRequestHeader("expires", "0"),
        jqXHR.setRequestHeader("authorization", this.token);
    },
  });
  removeDuplicates(array) {
    let uniq = {};
    return array.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));
  }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    // this.getData();
  }

  // getData() {
  //   StationComponent.TOTAL_GRIDDATA = [];
  //   this.spinner.show();
  //   this.superAdminService.airlines({}).subscribe(res => {
  //     this.stationResp = res;
  //     this.spinner.hide();
  //   })
  // }
  

  ngAfterViewInit() {
    StationComponent.TOTAL_GRIDDATA =[];
    this.source.url =
    StationComponent.webapi +
    "stationApis/GetStationsList.php/stationList?" +
    "loginUserId=" + this.loginDetails.userId;
    this.myGrid.gotopage(0);
    this.myGrid.updatebounddata("refresh");
    this.myGrid.showcolumn("actions_e");
  }

  columns = [
    { text: "Station Name", datafield: "name", width: 200, editable: false },
    // {
    //   text: "Actions",
    //   datafield: "actions_e",
    //   width: 200,
    //   filterable: false,
    //   sortable: false,
    //   groupable: false,
    //   editable: false,
    //   cellsrenderer: function (
    //     row,
    //     columnfield,
    //     value,
    //     defaulthtml,
    //     columnproperties,
    //     columnData
    //   ) {
    //     return '<button type="button" style="width: 43%; margin-left: 5%" class="btn btn-outline-warning btn-sm" id="edit: '+columnData.port_Id+'">Edit</button><button type="button" style="width: 43%; margin-left: 5%" class="btn btn-outline-danger btn-sm" id="delete: '+columnData.id+'">Delete</button>';
        
    //   }
    // },
    { text: "Station Code", datafield: "code", width: 200, editable: false },
    { text: "City Code", datafield: "cityCode", width: 150, editable: false },
    { text: "City Name", datafield: "cityName", width: 150, editable: false },
    { text: "Country Code", datafield: "countryCode", width: 200, editable: false },
    { text: "Country Name", datafield: "countryName", width: 200, editable: false },
    { text: "Lat", datafield: "lat", width: 200, editable: false },
    { text: "Lon", datafield: "lon", width: 200, editable: false },
  ];

  rendergridrows = (params: any): any => {
    return params.data;
  };

  public selectedRowsData = [];
  _onChangeRowSelection(event) {
    // trigger when checkbox checked / unchecked
    this.isSlectALL_records = false;
    if (Array.isArray(event.args.rowindex)) {
      if (event.args.rowindex.length !== 0) {
        this.selectedRowsData = StationComponent.TOTAL_GRIDDATA.filter(item=>item);
        this.isSlectALL_records = true;
      } else {
        this.selectedRowsData = [];
      }
    } else {
      if (event.type === "rowunselect") {
        let index = this.selectedRowsData.findIndex(
          (x) => x.id === event.args.row.id
        );
        this.selectedRowsData.splice(index, 1);
      } else {
        this.selectedRowsData.push(event.args.row);
      }
    }
  }

  onRowDoubleClick(rowData) {
    console.log(rowData);
  }
  

}
