import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoginInput } from '../home/loginModel';

import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { CreatereportService } from '../services/createreport.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { DatePipe } from '@angular/common';
import { ShareLoginDataService } from '../data/share-login-data.service'
import { LoginService } from '../services/login/login.service';
import { LoginDataService } from '../login/login-data.service';
import { SaveReportsComponent } from '../save-reports/save-reports.component';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { RegisterService } from '../services/register/register.service';
import { ConfirmPasswordValidator } from './password.validator';
import { StationService } from '../services/station/station.service';
import { startWith, map } from 'rxjs/operators';
import { SuperadminService } from '../services/superadmin/superadmin.service'
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { SidebarComponent } from '../../app/layouts/sidebar/sidebar.component';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { globalConstants } from 'src/environments/globalConstants';
import { PasswordChecker } from '../set-password/custom-validators/password-checker';
declare var jQuery: any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [SaveReportsComponent,
        ForgotpasswordComponent],
        
})

export class HomeComponent implements OnInit {
    
    loginForm: FormGroup;
    loginInput = new LoginInput();
    loginData: any;
    isEnablePopUp: boolean;
    public static GETLOGINDATA;
    showviewreports: boolean;
    showlogin: boolean;
    forgotEmail: any;
    colorChangeOne: any;
    colorChangeTwo: any;
    colorChangeThree: any;
    isAllApps: boolean;
    isLiveApps: boolean;
    isSoonAvailable: boolean;
    verifyForgotEmailForm: FormGroup;
    //   saveReportObj: SaveReportsComponent;
    //   forgotPwdComponent: any;
    isShowDashBoard: boolean;
    loginDetails: any;
    otppPage: boolean;
    registratonPage: boolean;
    emailPage: boolean;
    registrationForm: FormGroup;
    registrationEmailForm: FormGroup;
    registrationOtpForm: FormGroup;
    registerForm: FormGroup;
    stationCode: FormControl;
    stationsList: any;
    stationsListObj: any;
    stationDetailsObj: any;
    codeList: any;
    codeValue: string;
    OrganizationName: string;
    OrgNamesValue: any = [];
    OrgNameSelectedValue: string;
    OrgNameSelectedValueUpdate = new Subject<string>();
    reasonType: string;
    orgType_temp: any;
    fieldnameport_Code: boolean;
    org_TypeTemp: string;
    orgName_Temp: any = [];
    OrgNameValue: any;
    organizationInputChange: boolean;
    stationCode_temp: any;
    radioButton1: boolean;
    radioButton2: boolean;
    radioButton3: boolean;
    allStationsActive: boolean;
    stationslist: any;
    stationCodeReadOnly: boolean;
    OrgNameFilteredarray: any = [];
    OrgNameFilteredobj: [];
    OrgNamesValueTemp: any = [];
    singleOrgName: string;
    airlinceComapniesData: any;
    OrgNameFiledChange: boolean;
    OrgNameValueTemp: any;
    codeListTemp: any;
    singleOrgStations: any;
    OrgNameReadonly: boolean;
    ResultStations: any;
    AddressRedonly: boolean;
    selectedStation: any;
    orgNameFormcontrolError: boolean;
    stationCodeFormcontrolError: boolean;
    address2ReadOnly: boolean;
    cityReadOnly: boolean;
    countryReadOnly: boolean;
    postalCodeReadOnly: boolean;
    showStationsDropDown: boolean;
    TEMPORARY_CODELIST: any;
    allStationsDetails: any;
    loadingStations: boolean;
    is_StationCode_SelectFrmList: boolean;
    stationCode_Not_Frm_list_err: boolean;
    OrgNamesValueDuplicates: any;
    isListEnable: boolean;
    OrgName_selectedFrm_List: boolean;
    cookieValue: string;
    LoginDetails: any;
    TypeOfInput: string;
    VERSION_NUMBER = globalConstants.VERSION_NUMBER
    // @ViewChild('RegisterPopUp') RegisterPopUp: ElementRef;
    rememberMeStatus :any ;
    constructor(private navbarComponent_Ref:NavbarComponent,private SidebarComponent: SidebarComponent,private cookieService: CookieService,private loginservice: LoginService, private StationService: StationService, private formBuilder: FormBuilder, private RegisterService: RegisterService, private DamageReportServicel: CreatereportService, private atp: AmazingTimePickerService,
        private datePipe: DatePipe, private SuperadminService: SuperadminService, public route: Router, public toast: ToastrService,
        private spinner: NgxSpinnerService, private LoginDataService: LoginDataService, public shareServc: ShareLoginDataService, public loginServc: LoginService, private myAccountService: MyAccountService, private saveReportObj: SaveReportsComponent, private forgotPwdComponent: ForgotpasswordComponent) {
        this.stationCode = new FormControl('', [Validators.required]); 
    }

    regex = /(?=.*[_!@#$%^&*-+=~`()<>])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])^.{12,}$/;

    receiveMessage(event) {
        console.log(event);
        
        alert(event.origin);
        if (event.origin !== "http://testportal.trucking.aero/Forms/bookinglist_handler") {
            alert('wrong url');
            return;
        }
        alert(event.data.data);
        alert('working');
    }

    ngOnInit() {
        // window.addEventListener("message", this.receiveMessage, false);
        this.codeList = [];
        this.TypeOfInput = "search"
        this.orgNameFormcontrolError = false;
        this.stationCodeFormcontrolError = false;
        this.showStationsDropDown = false;
        window.scroll(0, 0);
        this.isAllApps = true;
        this.otppPage = false;
        this.emailPage = true;
        this.registratonPage = false;
        this.colorChangeOne = 'isAPPHighlight';
       this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            passcode: new FormControl('', [Validators.required]),
        });
        this.verifyForgotEmailForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
        });
        const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.loginDetails = loginuserdetails;
        if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
            this.showviewreports = false;
            this.showlogin = true;
            this.isShowDashBoard = false;
        } else {
            
            this.showviewreports = true;
            this.showlogin = false;
            this.isShowDashBoard = true;
        }
        this.registrationEmailForm = this.formBuilder.group({
            'emailId': new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
        });

        this.registrationOtpForm = this.formBuilder.group({
         
            'otpCode': new FormControl('', [Validators.required,Validators.minLength(6)]),
        });
        
        this.registerForm = this.formBuilder.group({
            organisationName: [{ value: '', disabled: false }, Validators.required],
            orgType: ["", Validators.required],
            firstName: [""],
            lastName: [""],
            stationCode: ["", [Validators.required, this.stationFormcontrolError.bind(this)]],
            address: [""],
            jobTitle: [""],
            address2: [""],
            city: [""],
            country: [""],
            postalCode: [""],
            carrierId: [""],
            password: ["", [Validators.required, Validators.pattern(this.regex)]],
            cpassword: ["", [Validators.required, Validators.pattern(this.regex)]],
        },
        {
          validators: PasswordChecker("password", "cpassword"),
        }
        );

        // this.registerForm.controls['password'].valueChanges.subscribe(data => {
        //     this.registerForm.controls['cpassword'].setValue('');
        //     this.registerForm.controls['cpassword'].updateValueAndValidity();
        // });



        let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
        if(remmeberData !== null && remmeberData !== undefined && remmeberData !== undefined){
            this.loginForm.get("username").patchValue(remmeberData.emailId)
            this.loginForm.get("passcode").patchValue(remmeberData.pwd)
        }
    }

    

    OrgNameFormcontrolError(control: FormControl): { [s: string]: boolean } {
        if (this.orgNameFormcontrolError === true) {
            return { 'OrgNameFormcontrolError': true };
        }
        return null;
    }

    stationFormcontrolError(control: FormControl): { [s: string]: boolean } {
        if (this.stationCodeFormcontrolError === true) {
            return { 'stationCodeFormcontrolError': true };
        }
        return null;
    }
    onSecurityCodeKeyUp(event){
    this.registrationOtpForm.controls['otpCode'].setValue(this.registrationOtpForm.controls['otpCode'].value.replace(/[^0-9]*/g, ''))
  
    }
    logout() {
     
        this.spinner.show();
       let loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
     let obj ;
       if(loginDetails != null && loginDetails != undefined && loginDetails != ''){
        obj=  {
          loginUserId: loginDetails.userId
        };
       this.loginservice.logOutService(obj).subscribe(
           data => {
               this.spinner.hide();
               if (data.responseCode === 1) {
                   this.cookieService.delete('cclp', '/', '.cclp.aero');
                   this.cookieService.delete('uld', '/', '.cclp.aero');
                   this.cookieService.delete('airPortClaims', '/', '.cclp.aero');
                   let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
                   localStorage.clear();
                   localStorage.setItem("credentialsForRememberMe",JSON.stringify(remmeberData));

                 this.ngOnInit();
               } else {
                   
                if(data.message === 'Unauthorized User'){
                           
                }else{
                    this.toast.error(data.message);
                }  
                let obj={
                    emailId: '',
                     pwd:''
                 }
                 localStorage.setItem('credentialsForRememberMe', JSON.stringify(obj));
               }
           },
           error => {
               this.spinner.hide();
           }
       );
    }else{
         this.ngOnInit();
    }
    }

    openforgotcall() {
        
        this.verifyForgotEmailForm.reset();
        jQuery('#login').modal('hide');
        jQuery('body').removeClass('modal-open')
        jQuery('.modal-backdrop').remove()
        jQuery('#forgotpasswordpopup').modal({ backdrop: 'static', keyboard: false });
    }

    sendpasswordcall() {
        
        this.forgotEmail = this.forgotEmail.replace(/\s+/g, '');
        let obj = {
            "emailId": this.forgotEmail
        }
        if (this.forgotEmail != "" && this.forgotEmail != undefined && this.forgotEmail != null) {
            this.forgotPwdComponent.sendEmail(obj);
      
        }
    }

    gotomyaccount() {
        this.route.navigateByUrl("/my-account");
    }

    fn_OnLogin(loginData) {
        loginData.rememberMeStatus = this.rememberMeStatus ;
        this.saveReportObj.fn_OnLogin(loginData);
    }

    fn_ToShowModalPopUp() {
        
        this.loginForm.reset();
        this.isEnablePopUp = true;
    }

    fn_ToHidePopUp() {
        this.isEnablePopUp = false;
    }

    onClickApps(valIp) {
        if (valIp === 'All apps') {
            this.colorChangeOne = 'isAPPHighlight'
            this.colorChangeTwo = 'isUnhilight'
            this.colorChangeThree = 'isUnhilight'
            this.isAllApps = true;
            this.isLiveApps = false;
            this.isSoonAvailable = false;
        } else if (valIp === 'Live apps') {
            this.colorChangeOne = 'isUnhilight'
            this.colorChangeTwo = 'isAPPHighlight'
            this.colorChangeThree = 'isUnhilight'
            this.isAllApps = false;
            this.isLiveApps = true;
            this.isSoonAvailable = false;
        } else if (valIp === 'Soon available') {
            this.colorChangeOne = 'isUnhilight'
            this.colorChangeTwo = 'isUnhilight'
            this.colorChangeThree = 'isAPPHighlight'
            this.isAllApps = false;
            this.isLiveApps = false;
            this.isSoonAvailable = true;
        }
    }

    onDismisssPopUp() {
        this.loginForm.reset();
    }

    onDismisssregisterPopUp() {
        
        this.registerForm.reset();
        this.registrationOtpForm.reset();
        this.registrationEmailForm.reset();
        this.OrgNameValue = [];
        this.codeValue = "";
       
        this.ngOnInit();
    }

    onRegister(val) {
       
        this.route.navigate(["registerflow"], { queryParams: { type: val }, skipLocationChange: true });
        
    }

    onCnclForgotPwdPopup() {
        this.verifyForgotEmailForm.reset();
    }

    onClickDashBoard() {
        
        if (this.loginDetails.userType == 'SA') {
            this.route.navigateByUrl('/sa-dashboard');
        } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
            this.route.navigateByUrl('/csa-dashboard');
        }
        else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
            this.route.navigateByUrl('/csa-airline-dashboard');
        }
        else if (this.loginDetails.userType == 'E') {
            this.route.navigateByUrl('/user-dashboard');
        }
        else if (this.loginDetails.userType == 'A') {
            this.route.navigateByUrl('/admin-handler-dashboard');
        }
    }

    // Email Submit,Get Otp Api
    gotToOtp(registrationEmailForm) {
        
        console.log(registrationEmailForm.controls['emailId'].value);
        this.spinner.show();
        this.RegisterService.sendRegisterationOtp(registrationEmailForm.controls['emailId'].value).subscribe(data => {
            
            console.log(data);
            switch (data.responseCode) {
                case 1:
                    
                    console.log(data);
                    this.spinner.hide();
                    this.toast.success(data.message, '', { timeOut: 1500 });
                    this.registerForm.reset();
                    this.OrgNameReadonly = false;
                    this.stationCodeReadOnly = false;
                    this.AddressRedonly = false;
                   
                    this.otppPage = true;
                    this.emailPage = false;
                    this.registratonPage = false;

                case 0:
                    
                    console.log(data);
                    this.spinner.hide();
                    this.toast.error(data.message, '', { timeOut: 1500 })
            }
        },
            error => {
                this.spinner.hide();
                this.toast.error(error, '', { timeOut: 1400 })
            });

    }



    public Multile_Status_HandlerOrgsArray = [];
    public Multile_Status_AirlineOrgsArray = [];
    public Multile_Status_AgentOrgsArray = [];
    //Otp Submit Service

    gotToRegister(registrationOtpForm) {
        
        this.fieldnameport_Code = true;
        this.organizationInputChange = false;
        this.stationCodeReadOnly = false;
        let obj = {
            "emailId": this.registrationEmailForm.controls['emailId'].value,
            "otpCode": registrationOtpForm.controls['otpCode'].value.replace(/[^0-9]*/g, '')
        }

        // get Airline companies Service
        this.spinner.show();
        this.SuperadminService.airlineData().subscribe(data => {
            
            console.log(data);
            if (data.responseCode === 1) {
                this.airlinceComapniesData = data.airline_details;
                this.spinner.hide();
            } else {
                this.airlinceComapniesData = [];
                this.spinner.hide();
            }
        });
        this.getAllStations();
        this.RegisterService.checkEmail(obj).subscribe(data => {
            
            console.log(data);
            this.spinner.hide();
            switch (data.responseCode) {
                case 1:
                    if (data.reason === 'single') {
                        
                        this.OrgNameReadonly = true;
                        this.reasonType = data.reason;
                        if (data.orgType === 'Handler') {
                            this.org_TypeTemp = 'H';
                        } else if (data.orgType === 'Airline') {
                            this.org_TypeTemp = 'A';
                        } else if (data.orgType === 'Agent') {
                            this.org_TypeTemp = 'E';
                        } else {
                            return
                        }
                        this.orgName_Temp = data.OrganisationName;
                        this.OrgNameValue = data.OrganisationName;
                        this.singleOrgStations = data.stations;

                        this.registerForm.controls['orgType'].setValue(this.org_TypeTemp);
                        this.registerForm.controls['organisationName'].setValue(data.OrganisationName)
                        this.registerForm.controls['organisationName'].disable();
                        this.registerForm.controls['orgType'].disable();
                        this.OrgName_selectedFrm_List = true;
                        this.singleOrgName = data.OrganisationName;
                        this.spinner.hide();
                        this.OrgNameFiledChange = false;
                        this.otppPage = false;
                        this.emailPage = false;
                        this.registratonPage = true;

                    } else if (data.reason === 'multiple') {
                        
                        this.reasonType = data.reason;
                        this.OrgNameFiledChange = false;
                        this.otppPage = false;
                        this.emailPage = false;
                        this.registratonPage = true;
                        this.spinner.hide();
                        this.OrgNamesValueTemp = data.orgDetails

                        
                        for (let i = 0; i < data.orgDetails.length; i++) {
                            if (data.orgDetails[i].org_Type === 'E') {
                                this.Multile_Status_AgentOrgsArray.push(data.orgDetails[i]);
                            } else if (data.orgDetails[i].org_Type === 'A') {
                                this.Multile_Status_AirlineOrgsArray.push(data.orgDetails[i]);
                            } else if (data.orgDetails[i].org_Type === 'H') {
                                this.Multile_Status_HandlerOrgsArray.push(data.orgDetails[i]);
                            }
                        }

                    }
                    
                    break;

                case 0:
                    
                    if (data.reason === 'wrong type' && data.toaster === 'Y') {
                        this.toast.error(data.message, '', { timeOut: 1500 });
                    } else if (data.reason === 'not found' && data.toaster === 'N') {
                        
                        this.getAllStations();
                        this.organizationInputChange = true;
                        this.reasonType = data.reason;
                        this.OrgNameFiledChange = false;
                        this.OrgNamesValue = [];
                        this.otppPage = false;
                        this.emailPage = false;
                        this.registratonPage = true;
                        this.AddressRedonly = false;
                    } else {

                        if(data.message === "wrong credentials. "){
                            this.spinner.hide();
                            this.toast.error("Wrong security code", '', { timeOut: 1500 });
                        }else{
                            this.spinner.hide();
                            this.toast.error(data.message, '', { timeOut: 1500 });
                        }
                       
                    }
                    break;
            }
        },
            error => {
                
                this.spinner.hide();
                this.toast.error(error, '', { timeOut: 1500 })
            });
    }


    checkUndefinedFields(group: FormGroup): void {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.get(key);
            if (abstractControl instanceof FormGroup) {
                this.checkUndefinedFields(abstractControl);
            } else {
                if (abstractControl.value === undefined || abstractControl.value === null) {
                    abstractControl.patchValue("");
                }
            }
        });
    }

    //Final registration Form submiting Api
    Register(registerForm) {
        
        console.log(registerForm.value);
        

        this.checkUndefinedFields(this.registerForm);

        let obj = {
            "emailId": this.registrationEmailForm.controls['emailId'].value,
            "organisationName": this.registerForm.controls['organisationName'].value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''),
            "orgType": this.registerForm.controls['orgType'].value,
            "firstName": this.registerForm.controls['firstName'].value.replace(/[^a-zA-Z\s]*/g, ''),
            "lastName": this.registerForm.controls['lastName'].value.replace(/[^a-zA-Z\s]*/g, ''),
            "stationCode": this.registerForm.controls['stationCode'].value.replace(/[^a-zA-Z\-\s]*/g,''),
            "cpassword": this.registerForm.controls['cpassword'].value,
            "address": this.registerForm.controls['address'].value,
            "jobTitle": this.registerForm.controls['jobTitle'].value,
            "address2": this.registerForm.controls['address2'].value,
            "city": this.registerForm.controls['city'].value.replace(/[^a-zA-Z]*/g, ''),
            "country": this.registerForm.controls['country'].value.replace(/[^a-zA-Z]*/g, ''),
            "postalCode": this.registerForm.controls['postalCode'].value,
            "carrierId": this.registerForm.controls['carrierId'].value,
        }
        this.spinner.show();
        this.RegisterService.registration(obj).subscribe(data => {
            
            console.log(data);
            if (data.responseCode === 1) {
                
                this.Multile_Status_HandlerOrgsArray = [];
                this.Multile_Status_AirlineOrgsArray = [];
                this.Multile_Status_AgentOrgsArray = [];
                this.spinner.hide();
                this.toast.success("Registration is completed successfully, Please login", '', { timeOut: 2000 });
                this.emailPage = false;
                this.otppPage = false;
                this.registratonPage = false;
                this.codeValue = ''
                this.OrgNameValue = ''

                jQuery('#register').modal('hide');
                this.ngOnInit();
            } else {
                

                this.Multile_Status_HandlerOrgsArray = [];
                this.Multile_Status_AirlineOrgsArray = [];
                this.Multile_Status_AgentOrgsArray = [];
                this.spinner.hide();
                this.toast.error(data.message, '', { timeOut: 1500 });
                jQuery('#register').modal('hide');
            }
        }, error => {
            
            this.Multile_Status_HandlerOrgsArray = [];
            this.Multile_Status_AirlineOrgsArray = [];
            this.Multile_Status_AgentOrgsArray = [];
            this.spinner.hide();
            this.toast.error(error.statusText, '', { timeOut: 1500 });
            jQuery('#register').modal('hide');
        }
        );

    }

    //OrgName code change Event
    public OrgNameChange(e): void {
        
        let name = e.target.value;
        this.OrgNameValueTemp = this.airlinceComapniesData.filter((x) => x.carrierName.startsWith(name))[0];
    }

    initializeSationCodesData() {
        this.codeList = this.TEMPORARY_CODELIST;
    }

    fn_To_Filter_SatationCodes(ev: any) {
        // Reset items back to all of the items
        this.initializeSationCodesData();
        // set val to the value of the searchbar
        const val = ev.target.value;
        this.is_StationCode_SelectFrmList = false;
        
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            
            this.codeList = this.codeList.filter((item) =>
                item.code.toLowerCase().startsWith(val.toLowerCase()));

            if (!this.codeList) {
                this.showStationsDropDown = false;
            } else {
                this.showStationsDropDown = true;
            }
        } else {
            // hide the results when the query is empty
            this.showStationsDropDown = false;
        }
    }

    // stationCode rest
    stationDropdown() {
       
        this.showStationsDropDown = true;
    }

    stationDropdownHide() {
        this.showStationsDropDown = false;
        this.is_StationCode_Selected_Frm_List();
    }


    //Station code change Event
    public saveCode(e, c, i): void {
        
        console.log(c);
        console.log(i)
        this.codeValue = c.code
        this.is_StationCode_SelectFrmList = true;
        if (this.reasonType === 'multiple') {
            
          
            if (this.allStationsActive === true) {
                this.ResultStations = this.codeList;
                this.codeValue = c.code
                this.showStationsDropDown = false;
            } else {
                this.ResultStations = this.codeList;
               
                this.codeValue = c.code
                this.showStationsDropDown = false;
            }
            if (this.ResultStations === undefined) {
                this.stationCodeFormcontrolError = true;
                this.codeValue = '';
                this.registerForm.controls['stationCode'].setValue('');
                this.registerForm.controls['stationCode'].updateValueAndValidity();

            } else {
                this.stationCodeFormcontrolError = false;
                this.registerForm.controls['stationCode'].setErrors(null);

            }
            if (this.allStationsActive === true) {
                this.AddressRedonly = false;
                this.stationslist = c.code;
               
                if (c === undefined) {
                    this.stationsCodeReset();
                }
            } else {
                this.stationslist = c.code;
               
                if (this.stationslist === undefined) {
                    this.stationsCodeReset();
                }
            }
           
            if (this.stationslist !== undefined && this.stationslist !== null) {
                this.AddressRedonly = false;
                this.address2ReadOnly = false;
                this.cityReadOnly = false;
                this.countryReadOnly = false;
                this.postalCodeReadOnly = false;
                this.registerForm.controls['address'].setValue(c.main_Address);
                this.registerForm.controls['address2'].setValue(c.address2);
                this.registerForm.controls['city'].setValue(c.city);
                this.registerForm.controls['country'].setValue(c.country);
                this.registerForm.controls['postalCode'].setValue(c.postal_code);

                this.AddressRedonly = true;
                this.address2ReadOnly = true;
                this.cityReadOnly = true;
                this.countryReadOnly = true;
                this.postalCodeReadOnly = true;
               

            }
        } else if (this.reasonType === 'not found') {
            
            this.AddressRedonly = false;
            if (this.allStationsActive === true) {
                this.ResultStations = this.codeList;
                this.codeValue = c.code;
                this.showStationsDropDown = false;
            } else {
                this.ResultStations = this.codeList;
                this.codeValue = c.code;
             
                this.showStationsDropDown = false;
            }

          


        } else if (this.reasonType === 'single') {
            
            if (this.registerForm.controls['orgType'].value === "A") {
                this.selectedStation = this.codeList
              
            } else {
                this.selectedStation = this.codeList
              
            }
            
            if (this.allStationsActive === true) {
                this.ResultStations = this.codeList;
                this.codeValue = c.code;
                this.showStationsDropDown = false;
            } else {
                this.ResultStations = this.codeList;
                this.codeValue = c.code;
                
                this.showStationsDropDown = false;
            }
            this.spinner.hide();
            if (this.selectedStation !== undefined && this.selectedStation !== null) {
              
                this.AddressRedonly = false;
                this.address2ReadOnly = false;
                this.cityReadOnly = false;
                this.countryReadOnly = false;
                this.postalCodeReadOnly = false;
                this.registerForm.controls['address'].setValue(c.main_Address);
                this.registerForm.controls['address2'].setValue(c.address2);
                this.registerForm.controls['city'].setValue(c.city);
                this.registerForm.controls['country'].setValue(c.country);
                this.registerForm.controls['postalCode'].setValue(c.postal_code);
                this.AddressRedonly = true;
                this.address2ReadOnly = true;
                this.cityReadOnly = true;
                this.countryReadOnly = true;
                this.postalCodeReadOnly = true;
               
            } else if (this.selectedStation === undefined) {
                this.registerForm.controls['stationCode'].patchValue('');
                this.registerForm.controls['stationCode'].updateValueAndValidity();
                this.registerForm.controls['address'].patchValue('');
                this.registerForm.controls['address'].updateValueAndValidity();
                this.registerForm.controls['address2'].patchValue('');
                this.registerForm.controls['address2'].updateValueAndValidity();
                this.registerForm.controls['city'].patchValue('');
                this.registerForm.controls['city'].updateValueAndValidity();
                this.registerForm.controls['country'].patchValue('');
                this.registerForm.controls['country'].updateValueAndValidity();
                this.registerForm.controls['postalCode'].patchValue('');
                this.registerForm.controls['postalCode'].updateValueAndValidity();
            }
        }

    }


    is_StationCode_Selected_Frm_List() {
        
        let val = this.registerForm.get('stationCode').value
        if (this.is_StationCode_SelectFrmList === true) {
            this.stationCode_Not_Frm_list_err = false;
        } else {
            this.stationCode_Not_Frm_list_err = true;
        }
    }
   

    airlineNameCheck(name) {
        
        console.log(name);
        let list = this.airlinceComapniesData.filter(x => x.carrierName === name)[0];
        console.log(list);
        if (list === undefined) {
            
            this.orgNameFormcontrolError = true;
            this.OrgNameValue = '';
            this.registerForm.controls['organisationName'].setValue('');
            this.registerForm.controls['organisationName'].updateValueAndValidity();
        } else if (list !== undefined) {
            this.orgNameFormcontrolError = false;
            this.registerForm.controls['organisationName'].setErrors(null);
            this.CheckEmailService();
        }
    }

    ListEnableFun() {
        this.initializes_OrgNames_Data()
        this.isListEnable = true;
    }
    ListDisableFun() {
        
        this.isListEnable = false;
        let value = this.registerForm.controls['orgType'].value
        let orgValue = this.registerForm.controls['organisationName'].value
        if (value === 'E') {
            if (this.Multile_Status_AgentOrgsArray.length != 0) {
                if (this.OrgName_selectedFrm_List === true) {
                    this.orgNameFormcontrolError = false;
                    if (orgValue != '' && orgValue != null && orgValue != undefined) {
                        this.CheckEmailService();
                    }
                } else {
                    this.orgNameFormcontrolError = true;
                }
            } else {
                this.OrgName_selectedFrm_List = true;
                this.orgNameFormcontrolError = false;
                if (orgValue != '' && orgValue != null && orgValue != undefined) {
                    this.CheckEmailService();
                }
            }
        } else if (value === 'A') {
            if (this.OrgName_selectedFrm_List === true) {
                this.orgNameFormcontrolError = false;
                if (orgValue != '' && orgValue != null && orgValue != undefined) {
                    this.CheckEmailService();
                }
            } else {
                this.orgNameFormcontrolError = true;
            }
        } else if (value === 'H') {
            if (this.Multile_Status_HandlerOrgsArray.length != 0) {
                if (this.OrgName_selectedFrm_List === true) {
                    this.orgNameFormcontrolError = false;
                    if (orgValue != '' && orgValue != null && orgValue != undefined) {
                        this.CheckEmailService();
                    }
                } else {
                    this.orgNameFormcontrolError = true;
                }
            } else {
                this.OrgName_selectedFrm_List = true;
                this.orgNameFormcontrolError = false;
                if (orgValue != '' && orgValue != null && orgValue != undefined) {
                    this.CheckEmailService();
                }
            }
        }

    }
    selectedOrg(c) {
        
        let val1 = this.registerForm.controls['orgType'].value
        this.OrgName_selectedFrm_List = true;
        if (val1 === 'A') {
            if (this.Multile_Status_AirlineOrgsArray.length != 0) {
                this.registerForm.get('organisationName').patchValue(c.orgName);
            } else {
                this.registerForm.get('organisationName').patchValue(c.carrierName);
                this.registerForm.controls['carrierId'].patchValue(c.carrierId);
            }
        } else {
            this.registerForm.get('organisationName').patchValue(c.orgName);
        }
        this.isListEnable = false;
        let obj = {
            target: {
                value: this.registerForm.get('organisationName').value
            }
        }
      

    }

    initializes_OrgNames_Data() {
        this.OrgNamesValue = this.OrgNamesValueDuplicates;
    }

    fn_toFilter_OrgNames(ev: any) {
        // Reset items back to all of the items
        this.initializes_OrgNames_Data();
        // set val to the value of the searchbar
        this.OrgName_selectedFrm_List = false;
        const name = ev.target.value;
       let orgNameVal = this.registerForm.controls['organisationName'].value
if( orgNameVal != undefined && orgNameVal != null && orgNameVal != ''){
    this.registerForm.controls['organisationName'].setValue(orgNameVal.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
}
        this.orgNameFormcontrolError = false;
        
        
        let value = this.registerForm.controls['orgType'].value
        if (value === 'A') {
            if (name && name.trim() !== '') {
                
                if (this.Multile_Status_AirlineOrgsArray.length != 0) {
                    this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
                        item.orgName.toLowerCase().startsWith(name.toLowerCase()));
                } else {
                    this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
                        item.carrierName.toLowerCase().startsWith(name.toLowerCase()));
                }
            }
        } else {
            if (name && name.trim() !== '') {
                
                this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
                    item.orgName.toLowerCase().startsWith(name.toLowerCase()));
            }
            if (value === 'E') {
                if (this.Multile_Status_AgentOrgsArray.length != 0) {
                } else {
                    this.OrgName_selectedFrm_List = true;
                }
            } else if (value === 'H') {
                if (this.Multile_Status_HandlerOrgsArray.length != 0) {
                } else {
                    this.OrgName_selectedFrm_List = true;
                }
            }
        }
    }
    // method: Organization Name onchange
    public saveOrgName(e, c) {


        let name = e.target.value;
        if (this.registerForm.controls['orgType'].value === 'A') {
            let AirlineOrg = this.airlinceComapniesData.filter(x => x.carrierName === name)[0];
            console.log('AirlineOrg', AirlineOrg);
            if (AirlineOrg != undefined) {
                this.registerForm.controls['carrierId'].patchValue(AirlineOrg.carrierId);
            }

        } else {
            this.registerForm.controls['carrierId'].patchValue("");
        }

        this.codeValue = '';
        this.registerForm.controls['stationCode'].setValue('');
        this.registerForm.controls['stationCode'].updateValueAndValidity();
        if (this.registerForm.controls['orgType'].value !== '' && this.registerForm.controls['orgType'].value !== undefined && this.registerForm.controls['orgType'].value !== null) {
            
            switch (this.reasonType) {
                case undefined:
                    return;
                    break;
                case 'not found':
                    let name = e.target.value;
                    this.airlineNameCheck(name);
                    // let name = e.target.value;
                    break;
                case 'single':
                    if (this.registerForm.controls['orgType'].value === 'A') {
                        this.CheckEmailService();
                    }
                    break;
                case 'multiple':
                    if (this.registerForm.controls['orgType'].value === 'A') {
                        
                      
                        let name = e.target.value;
                        this.airlineNameCheck(name);
                    } else if (this.registerForm.controls['orgType'].value === 'H' || this.registerForm.controls['orgType'].value === 'E') {
                        
                        let name = e.target.value;

                        let list = this.OrgNamesValue.filter(x => x.orgName === name)[0];
                       
                        console.log(list);
                        if (list === undefined) {
                            this.orgNameFormcontrolError = true;
                            this.OrgNameValue = '';
                            this.registerForm.controls['organisationName'].setValue('');
                            this.registerForm.controls['organisationName'].updateValueAndValidity();
                        } else if (list !== undefined) {
                            this.orgNameFormcontrolError = false;
                            this.registerForm.controls['organisationName'].setErrors(null);
                            this.CheckEmailService();
                        }
                    } else {
                        this.CheckEmailService();
                    }

                    break;
            }
            
        } else {
            this.toast.error('Selecte organization type');
            this.registerForm.controls['orgType'].setErrors({ 'incorrect': true });
            this.registerForm.controls['orgType'].updateValueAndValidity();
        }

        
    }

    //CheckEmailService
    CheckEmailService() {
        
        let obj = {
            "orgName": this.registerForm.controls['organisationName'].value,
            "orgType": this.registerForm.controls['orgType'].value,
        }
        this.spinner.show();
        this.RegisterService.companyTypecheck(obj).subscribe(data => {
            
            switch (data.responsecode) {
                case 0:
                    this.spinner.hide();
                    switch (data.toaster) {
                        case 'N':
                            
                            this.spinner.show()
                            this.getAllStations();
                            break;
                        case 'Y':
                            
                            this.spinner.hide();
                            this.toast.error(data.message, '', { timeOut: 1500 });
                            break;
                    }
                    break;
                case 1:
                    
                    this.spinner.hide();
                    this.allStationsActive = false;
                    this.registerForm.controls['stationCode'].reset();
                    this.fieldnameport_Code = true;
                    this.codeList = this.allStationsDetails;
                    break;
            }
        }, error => {
            this.spinner.hide();
            this.toast.error(error.message, '', { timeOut: 1500 })

        });
    }
    // method: Get Organization Type
    OrgType(org_Type) {
        
        if (org_Type === 'A') {
            var OrgType = 'Airline'
        } else if (org_Type === 'H') {
            var OrgType = 'Handler'
        } else {
            var OrgType = 'Agent'
        }
        return OrgType;
    }

    getAllStations() {
        
        this.StationService.GetStationCodes().subscribe(
            data => {
                console.log('getAllStations()', data)
                
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.fieldnameport_Code = false;
                    this.allStationsActive = true;
                    this.codeList = data.airport_details;
                    this.codeListTemp = data.airport_details;
                    this.allStationsDetails = data.airport_details;
                    this.TEMPORARY_CODELIST = data.airport_details;
                    this.spinner.hide();

                } else {
                    this.spinner.hide();
                    this.toast.error(data.message, '', { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
                this.toast.error(error.message, '', { timeOut: 1500 })
                this.spinner.hide();
            }
        )
    }


    keyupMethod(event) {
        

    }

    //method: orgTypeSelected
    orgTypeSelected() {
        
        switch (this.reasonType) {
            case 'multiple':
                if (this.orgType_temp !== undefined && this.orgType_temp !== null) {
                    if (this.registerForm.controls['orgType'].value === this.orgType_temp) {
                        return;
                    } else {
                        this.toast.error('Selected organization belongs to ' + this.orgType_temp, '', { timeOut: 1500 });
                        
                    }
                }


        }
       
    }

    

    // organization Value Rest
    oranizationReset() {
        this.registerForm.controls['organisationName'].setValue('');
       
    }

    RegisterFormRest() {
        this.registerForm.controls['firstName'].setValue('');
        this.registerForm.controls['lastName'].setValue('');
        this.registerForm.controls['address'].setValue('');
        this.registerForm.controls['password'].setValue('');
        this.registerForm.controls['cpassword'].setValue('');
        this.registerForm.controls['jobTitle'].setValue('');

        this.registerForm.controls['address2'].setValue('');
        this.registerForm.controls['city'].setValue('');
        this.registerForm.controls['country'].setValue('');
        this.registerForm.controls['postalCode'].setValue('');
       
    }

    stationsCodeReset() {
        this.registerForm.controls['stationCode'].setValue('');
    }

    
    m_orgType(e) {
        
        this.oranizationReset();
        this.stationsCodeReset();
        this.RegisterFormRest();
        this.isListEnable = false;
        let value = e.target.value;
        this.OrgNamesValueDuplicates = [];
        if (value === 'E') {
            if (this.Multile_Status_AgentOrgsArray.length != 0) {
                this.OrgNamesValue = this.Multile_Status_AgentOrgsArray;
                this.organizationInputChange = false
                this.registerForm.get('organisationName').enable()
                if(this.OrgNamesValue.length === 1){
                    this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
                    this.OrgName_selectedFrm_List = true;
                    this.registerForm.get('organisationName').disable()
                    this.orgNameFormcontrolError = false;
                }
                this.OrgNameFiledChange = false;
            } else {
                this.OrgName_selectedFrm_List = true;
                this.OrgNamesValue = [];
                this.registerForm.get('organisationName').enable()
            }
        } else if (value === 'A') {
            if (this.Multile_Status_AirlineOrgsArray.length != 0) {
                this.OrgNamesValue = this.Multile_Status_AirlineOrgsArray;
                this.organizationInputChange = false
                this.OrgNameFiledChange = false;
                this.registerForm.get('organisationName').enable()
                if(this.OrgNamesValue.length === 1){
                    this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
                    this.OrgName_selectedFrm_List = true;
                    this.registerForm.get('organisationName').disable()
                    this.orgNameFormcontrolError = false;
                }
            } else {
                this.OrgNamesValue = this.airlinceComapniesData;
                this.OrgNameFiledChange = true;
                this.registerForm.get('organisationName').enable()
            }
        } else if (value === 'H') {
            if (this.Multile_Status_HandlerOrgsArray.length != 0) {
                this.OrgNamesValue = this.Multile_Status_HandlerOrgsArray;
                this.organizationInputChange = false;
                this.OrgNameFiledChange = false;
                this.registerForm.get('organisationName').enable()
                if(this.OrgNamesValue.length === 1){
                    this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
                    this.OrgName_selectedFrm_List = true;
                    this.registerForm.get('organisationName').disable()
                    this.orgNameFormcontrolError = false;
                }
            } else {
                this.OrgNamesValue = [];
                this.OrgName_selectedFrm_List = true;
                this.registerForm.get('organisationName').enable()
            }
        }
        this.OrgNamesValueDuplicates = this.OrgNamesValue;
        // ==============old code=====================///
      
       
    }

    onclickRegister() {
        
        this.OrgName_selectedFrm_List = false;
        this.Multile_Status_HandlerOrgsArray = [];
        this.Multile_Status_AirlineOrgsArray = [];
        this.Multile_Status_AgentOrgsArray = [];
        this.OrgNamesValue =[];
        this.registrationEmailForm.reset();
        this.registerForm.reset();
        this.registrationOtpForm.reset();
        this.getAllStations();
        jQuery('#register').modal({ backdrop: 'static', keyboard: false });

    }

    

onClick_Login_Modal(){
    this.rememberMeStatus = true;
    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
        if(remmeberData !== null && remmeberData !== undefined && remmeberData !== ''){
            this.loginForm.get("username").patchValue(remmeberData.emailId)
            this.loginForm.get("passcode").patchValue(remmeberData.pwd)
            if((remmeberData.emailId === '' || remmeberData.emailId === undefined || remmeberData.emailId === null)&& (remmeberData.pwd === '' || remmeberData.pwd === null || remmeberData.pwd === undefined)){
                this.loginForm.reset();
                this.rememberMeStatus = false;
            }
        }else{
            this.rememberMeStatus = false;
        }
    jQuery('#login').modal({ backdrop: 'static', keyboard: false });
}
onClick_Save_Report(){
    let  obj = {
        isFrom :'CREATE_REPORT'
    }
      this.route.navigate(['save-report'],{ queryParams: obj })
  }

  onPwdKeyup(event){
   if(event.target.value.length != 0){
    this.TypeOfInput = "password"
   }else{
    this.TypeOfInput = "search"
   }
  }
  onClickRememberMe(event){
      ;
     (event.srcElement.checked) ? (this.rememberMeStatus = true): (this.rememberMeStatus = false)
  }


onswitch(){
    ;
    // window.name = "testff"
    //  window.open('http://localhost:4201/','cclppp');
    
     let wind=  window.open('',"cclppp");
 try{
  if(wind.location.href){
    window.open('http://localhost:4201/',"cclppp");
  }else{
    
  }
 }catch(exec){
  

 }



}

  



}