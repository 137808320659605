import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SecurityComponent } from './security/security.component';

import { GDashboardComponent } from "../cargoClaimModule/g-dashboard/g-dashboard.component";
import { IrregularCountComponent } from "../cargoClaimModule//g-dashboard/irregular-count/irregular-count.component";
import { InvestigationCountComponent } from "../cargoClaimModule/g-dashboard/investigation-count/investigation-count.component";
import { ClaimCountComponent } from "../cargoClaimModule/g-dashboard/claim-count/claim-count.component";
import { AmountCountComponent } from "../cargoClaimModule/g-dashboard/amount-count/amount-count.component";
import { ViewstationsComponent } from "../cargoClaimModule/stations/viewstations/viewstations.component";
import { AddstationsComponent } from "../cargoClaimModule/stations/addstations/addstations.component";
import { MyaccountComponent } from "../cargoClaimModule/myaccount/myaccount.component";
import { DamagereportsComponent } from "../cargoClaimModule/damagereports/damagereports.component";
import { EditstationsComponent } from "../cargoClaimModule/stations/editstations/editstations.component";
import { DownloaddataComponent } from "../cargoClaimModule/downloaddata/downloaddata.component";
import { ViewdetailsComponent } from "../cargoClaimModule/viewdetails/viewdetails.component";
import { DamageReportsComponent } from "../cargoClaimModule/g-dashboard/damage-reports/damage-reports.component";
import { ClaimAspectsComponent } from "../cargoClaimModule/g-dashboard/claim-aspects/claim-aspects.component";
import { ServiceLevelComponent } from '../cargoClaimModule/g-dashboard/service-level/service-level.component';
import { ClaimActivityComponent } from '../cargoClaimModule/g-dashboard/claim-activity/claim-activity.component';

import { CompareCliamTypeComponent } from '../cargoClaimModule/g-dashboard/compare-cliam-type/compare-cliam-type.component';
import { CompareCliamAspectComponent } from '../cargoClaimModule/g-dashboard/compare-cliam-aspect/compare-cliam-aspect.component';
import { CompareRootCauseComponent } from '../cargoClaimModule/g-dashboard/compare-root-cause/compare-root-cause.component';
import { CompareServiceLevelComponent } from '../cargoClaimModule/g-dashboard/compare-service-level/compare-service-level.component';
import { CompareClaimActivityComponent } from '../cargoClaimModule/g-dashboard/compare-claim-activity/compare-claim-activity.component';
import { CompareFinancialComponent } from '../cargoClaimModule/g-dashboard/compare-financial/compare-financial.component';
import { AwbsComponent } from '../cargoClaimModule/awbs/awbs.component';
import { AwbClaimsComponent } from '../cargoClaimModule/awb-claims/awb-claims.component';
import { ViewUsersCargoClaimComponent } from '../cargoClaimModule/allUsers/view-users/view-users.component';
import { SuperadminComponent } from '../superadmin/superadmin.component';
import { SetRolesCargoClaimComponent } from '../cargoClaimModule/allUsers/set-roles/set-roles.component';
import { EdituserCargoClaimComponent } from '../cargoClaimModule/allUsers/edituser/edituser.component';
import { CreateuserCargoClaimComponent } from '../cargoClaimModule/allUsers/createuser/createuser.component';
import { ResetPasswordCargoClaimComponent } from '../cargoClaimModule/allUsers/reset-password/reset-password.component';
import { globalConstants } from 'src/environments/globalConstants';
import { AuthenticateGuard } from '../AuthGuard/authenticate.guard';
const routes: Routes = [
  {
    path: "dashboard",
    redirectTo: "dashboard",
    pathMatch: "full",
},

{
path: "forgot-password",
component: ForgotPasswordComponent,
},
{
path: "security",component: SecurityComponent,
},

{
  path: "dashboard",
  component: GDashboardComponent, canActivate: [AuthenticateGuard],
  children: [
    { path: "irregular-count", component: IrregularCountComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT  ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT ] } },
    { path: "investigation-count", component: InvestigationCountComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
    { path: "claim-count", component: ClaimCountComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "amount-count", component: AmountCountComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "damage-reports", component: DamageReportsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "claim_aspects", component: ClaimAspectsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "service-level", component: ServiceLevelComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "claim-activity", component: ClaimActivityComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "compare-claimType", component: CompareCliamTypeComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
    { path: "compare-claimAspect", component: CompareCliamAspectComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
    { path: "compare-RootCause", component: CompareRootCauseComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "compare-ServiceLevel", component: CompareServiceLevelComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "compare-ClaimActivity", component: CompareClaimActivityComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
    { path: "compare-Financials", component: CompareFinancialComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
  ],
},
{ path: "stations", component: ViewstationsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "addstation", component: AddstationsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "editstation", component: EditstationsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "viewstation", component: ViewstationsComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
{ path: "myaccount", component: MyaccountComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "damage-reports", component: DamagereportsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "download-data", component: DownloaddataComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "view-Details", component: ViewdetailsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "view-users", component: ViewUsersCargoClaimComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},

{ path: "set-user-roles", component: SetRolesCargoClaimComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
{ path: "edit-user", component: EdituserCargoClaimComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
{ path: "create-user", component: CreateuserCargoClaimComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },
{ path: "reset-password", component: ResetPasswordCargoClaimComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] } },


 { path: "awbs", component: AwbsComponent,canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.CSA_FORWARDER_AUTH_TXT ,globalConstants.ADMIN_FORWARDER_AUTH_TXT, globalConstants.USER_FORWARDER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT, globalConstants.USER_AIRLINE_AUTH_TXT] }},
 { path: "awb-claims", component: AwbClaimsComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CargoclaimDashboardRoutingModule { }
