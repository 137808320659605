import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigServiceService } from '../../config-service.service';

@Injectable({
    providedIn: 'root'
})
export class DeactivatestationemailsService {
    webapi:string
    constructor(private http: HttpClient,config : ConfigServiceService) { 
     this.webapi =  config.getConfig().URL
    }

    addDeactivateStationEmail(obj) {
        ;
        return this.http.post<any>(this.webapi + 'airlineEmailReplacement.php/deactiveAirlineEmailStatus', obj);
    }

    editDeactivateStationEmail(obj) {
         return this.http.post<any>(this.webapi + 'airlineEmailReplacement.php/replacementMailEdit', obj);
    }

    viewDeactivateStationEmail(obj) {
        ;
        return this.http.post<any>(this.webapi + 'airlineEmailReplacement.php/stationTruckingairReplacement', obj);
    }





}
