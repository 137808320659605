import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from '../../config-service.service';
@Injectable({
  providedIn: 'root'
})
export class StationService {
  webapi: string
  constructor(private http: HttpClient, config: ConfigServiceService) {
    this.webapi = config.getConfig().URL
  }


  AddStations(obj) {
    return this.http.post<any>(this.webapi + 'stationsTruckcclp.php/createStation', obj);
  }

  ViewStations(obj) {
    return this.http.get<any>(this.webapi + 'getStationsCclpPortal.php/getStations?orgId=' + obj.orgId,);
  }


  EditStations(obj) {
    return this.http.post<any>(this.webapi + 'stationsTruckcclp.php/editStations', obj);
  }


  DeleteStations(obj) {
    return this.http.post<any>(this.webapi + 'stationsTruckcclp.php/deleteBranch', obj);
  }


  GetStationCodes() {
    return this.http.post<any>(this.webapi + 'tracking.php/getTruckAirport', {});
  }
  GetHandlerName() {
    return this.http.post<any>(this.webapi + 'tracking.php/getAllHandler', {});
  }

  getDeatil(handlerId) {
    return this.http.post<any>(this.webapi + 'stationsTruckcclp.php/getDeatils', { handlerId: handlerId });
  }

  deleteIndividual(inputData) {
    return this.http.post<any>(this.webapi + "/airlineEmailsCCLPPortal.php/deleteEmail", inputData)
  }

  newGetStationsCclpPortal(obj) {
    return this.http.get<any>(this.webapi + 'getLoginCclpPortal.php/getStationsCclpPortal?orgId=' + obj.orgId);
  }

}
