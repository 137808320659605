import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
// import { MustMatch } from './fieldmatchcheck'
// import { matchingPasswords } from './fieldmatchcheck';
import { ConfirmPasswordValidator } from './password.validator';
// import { CONNREFUSED } from 'dns';
import { MyAccountService } from '../../services/myAccount/my-account.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { debug } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
declare var jQuery: any;

import { StationService } from '../../services/station/station.service';
import { NavbarComponent } from 'src/app/layouts/navbar/navbar.component';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';
import { PasswordChecker } from './custom-validators/password-checker';


@Component({
    selector: 'app-myaccount',
    templateUrl: './myaccount.component.html',
    styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
    myAccount;
    Logindetails: any;
    myAccountDetails: any;
    formDirective;

    colorChangeone: string;
    colorChangetwo: string;
    colorChangethree: string;
    colorChangefour: string;
    ChangePasswordForm: FormGroup;
    EditProfileForm: FormGroup;

    show: boolean = true;
    show1: any = true;
    viewreport: boolean;
    editeport: boolean;
    changerpass: boolean;
    generatetoken: boolean;
    LoginData: string;
    isReadOnly: boolean;
    isStationCodeReadOnly: boolean;
    isAddressReadOnly: boolean;
    OldEmail: any;
    isUpdatable: boolean;
    LogindetailsOne: any;
    // stationCode: FormControl;
    stationsList: Observable<any[]>;
    states: any;
    stationsListObj: any;
    showAutocomplete: boolean;
    buttonDisabled: boolean;
    stationDetailsObj: any;
    isEmailReadOnly: boolean;
    loginresultobject: any;
    dashBoradSwitchedtemp: any;
    stationCodeTemp: any;
    StationCodeError: boolean;
    xapikey: string;
    xauthtoken: string;
    isCopied1: boolean = false;
    isCopied2: boolean = false;
    showapitoken: boolean = false;

    
  hide1 = true;
  hide2 = true;
  hide3 = true;

    detailsbm2d() {
        this.show = !this.show;
    }
    detailskod2d() {
        this.show1 = !this.show1;
    }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private router: Router,private navbarComponent: NavbarComponent, 
        private spinner: NgxSpinnerService, private StationService: StationService, private toastr: ToastrService, 
        private breakpointObserver: BreakpointObserver, private formBuilder: FormBuilder, private MyAccountService: MyAccountService,
        private locationStr: LocationStrategy, private _clipboardService: ClipboardService
        ) {
      // preventing back button in browser   
      history.pushState(null, null, window.location.href);  
      this.locationStr.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });


    }
    ngOnInit() {
        
        this.StationCodeError = false;
        window.scroll(0, 0);
       
        this.LogindetailsOne = JSON.parse(localStorage.getItem('loginresultobject'));
        this.Logindetails = JSON.parse(localStorage.getItem('saDashBoardData'));
        console.log(this.Logindetails)
        console.log('LoginDetails ==>', this.LogindetailsOne);
        if (this.LogindetailsOne.userType === 'SA') {
            this.isStationCodeReadOnly = true;
            this.isAddressReadOnly = true;
        } else if (this.LogindetailsOne.userType === 'S') {
            this.isStationCodeReadOnly = false;
            this.isAddressReadOnly = true;
            this.showapitoken = true;
        }
       
        else if (this.LogindetailsOne.userType === 'E' && this.LogindetailsOne.orgType === 'H') {
            this.isStationCodeReadOnly = true;
            this.isEmailReadOnly = true;
            this.isAddressReadOnly = true;
        }
        else if (this.LogindetailsOne.userType === 'E' && this.LogindetailsOne.orgType === 'A') {
            this.isStationCodeReadOnly = true;
            this.isEmailReadOnly = true;
            this.isAddressReadOnly = true;
        }
        else if (this.LogindetailsOne.userType === 'A' && this.LogindetailsOne.orgType === 'A') {
            this.isStationCodeReadOnly = true;
            this.isEmailReadOnly = true;
            this.isAddressReadOnly = true;
        }
        else if (this.LogindetailsOne.userType === 'A' && this.LogindetailsOne.orgType === 'H') {
            this.isStationCodeReadOnly = true;
            this.isEmailReadOnly = true;
            this.isAddressReadOnly = true;
        }
        this.spinner.show();
        let obj = {
            'organizationId': this.LogindetailsOne.orgId
        }
        this.MyAccountService.getOrgStations(obj).subscribe(
            data => {
                
                console.log(data)
                this.stationsListObj = data;
                this.stationDetailsObj = this.stationsListObj.stationDetails;
                
               
            },
            error => {
                this.spinner.hide();
            }
        )
        console.log(this.stationsListObj);
        this.MyAccountService.myAccountDetails(this.LogindetailsOne.userId).subscribe(
            data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    
                    console.log(data);
                    this.myAccountDetails = data.userDetails;
                   
                    console.log(this.myAccountDetails)
                    this.EditProfileForm.setValue({
                        firstname: this.myAccountDetails.firstName,
                        lastname: this.myAccountDetails.lastName,
                        emailid: this.myAccountDetails.loginId,
                        stationcode: this.myAccountDetails.stationCode,
                        contactnumber: this.myAccountDetails.contactNumber,
                        address: this.myAccountDetails.address,
                    });
                    if (this.myAccountDetails.userType === 'SA') {
                       

                        this.isAddressReadOnly = true;
                    } else if (this.myAccountDetails.userType === 'S') {
                       
                        this.isAddressReadOnly = true;
                    }
                    else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                        this.toastr.info("Your session is expired, please login again");
                    } 
                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                 else {
                    this.toastr.success('Data Unavailable', '', {})
                }
            },
        )


        console.log(this.myAccountDetails)
        this.colorChangeone = 'load-delay';
       
        this.viewreport = true;
        this.editeport = false;
        this.changerpass = false;
        this.generatetoken = false;

        // -------ChangePasword
        let regex = /(?=.*[_!@#$%^&*-+=~`()<>])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])^.{12,}$/;

        this.ChangePasswordForm = this.formBuilder.group({
            'OldPassword': [null, [Validators.required]],
            'NewPassword': [null, [Validators.required, Validators.pattern(regex)]],
            'ConfirmPassword': [null, [Validators.required,]]
        },
        {
          validators: PasswordChecker("NewPassword", "ConfirmPassword"),
        });
       
        this.EditProfileForm = this.formBuilder.group({
            'firstname': new FormControl(''),
            'lastname': new FormControl(''),
            'emailid': new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            'stationcode': new FormControl('', [Validators.required]),
            'contactnumber': new FormControl('', [ Validators.pattern('[1-9]\\d{9}')]),
            'address': new FormControl(''),
        })
        console.log(this.LogindetailsOne);
        this.stationsList = this.EditProfileForm.controls['stationcode'].valueChanges
            .pipe(
                startWith(""),
                map(stateobj => stateobj ? this.filterStations(stateobj) : this.stationDetailsObj)
            );


        this.EditProfileForm.valueChanges.subscribe(data => {
            
           
        });


    }

    trimPassword(event, pass){
        let value = event.target.value.trim();
        if (pass === 'old') {
            this.ChangePasswordForm.patchValue({
                OldPassword: value
            })
        } else if(pass === 'new'){
            this.ChangePasswordForm.patchValue({
                NewPassword: value
            })
        } else if(pass === 'confirm'){
            this.ChangePasswordForm.patchValue({
                ConfirmPassword: value
            })
        }
    }

    // Update User Form
    Update() {
        
        if((this.stationCodeTemp !== this.EditProfileForm.controls['stationcode'].value) &&  this.LogindetailsOne.userType === 'S' && this.EditProfileForm.controls['stationcode'].dirty){
            this.StationCodeError = true;
           this.EditProfileForm.controls['stationcode'].setValue('');
            this.EditProfileForm.controls['stationcode'].updateValueAndValidity();
          jQuery('#myModal').modal('hide');
        }else if (this.EditProfileForm.dirty) {
             this.StationCodeError = false;
            const UpdatedDetails = {
                "userId": this.LogindetailsOne.userId,
                "userType": this.LogindetailsOne.userType,
                "firstName": this.EditProfileForm.value.firstname.replace(/[^a-zA-Z\s]*/g, ''),
                "lastName": this.EditProfileForm.value.lastname.replace(/[^a-zA-Z\s]*/g, ''),
                "emailId": this.EditProfileForm.value.emailid,
                "contactNumber": this.EditProfileForm.value.contactnumber,
                "stationCode": this.EditProfileForm.value.stationcode,
                "address": this.EditProfileForm.value.address
            }
            this.spinner.show();
            this.MyAccountService.updateUserDetails(UpdatedDetails).subscribe(
                data => {
                    this.spinner.hide();
                    
                    console.log(data);
                    if (data.responseCode === 1) {
                        
                       
                        this.toastr.success(data.message, '', {})
                        jQuery('#myModal').modal('hide');
                        this.EditProfileForm.setErrors({ 'invalid': true });
                        this.myAccountDetails = "";
                        this.myAccountDetails = data;
                        this.Logindetails = JSON.parse(localStorage.getItem('saDashBoardData'));
                        this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
                        this.loginresultobject.stationCode = data.stationCode;

                        this.loginresultobject.firstName = data.firstName
                        this.loginresultobject.lastName = data.lastName
                        this.loginresultobject.loginID = data.loginId

                        localStorage.setItem('loginresultobject', JSON.stringify(this.loginresultobject));
                        const oldEmail = this.LogindetailsOne.emailId;
                        const NewEmail = this.EditProfileForm.value.emailid;
                      
                        this.EditProfileForm.setErrors(null);

                        if (oldEmail != NewEmail) {
                            
                            this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
                            if (this.dashBoradSwitchedtemp !== null) {
                                if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
                                   
                                   this.navbarComponent.logOut();
                                }
                            } else {
                                
                                this.navbarComponent.logOut();
                            }
                           
                        } else {
                            
                            
                            this.viewpro('view');
                        }
                      
                    } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                        jQuery('#myModal').modal('hide');
                        this.toastr.info("Your session is expired, please login again");
                    } 
                    else {
                        this.toastr.error(data.message, '', {});
                        jQuery('#myModal').modal('hide');
                       
                    }
                }
            )
        }

    }

    updateUserDetails() {
        
        if (this.EditProfileForm.dirty) {
            jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
        } else {
            this.toastr.error('No values changed')
        }

        
    }

    viewpro(val) {
        if (val === 'view') {
            this.colorChangeone = 'load-delay';
            this.colorChangetwo = 'load-delayone ';
            this.colorChangethree = 'load-delayone ';
            this.colorChangefour = 'load-delayone ';
            this.viewreport = true;
            this.editeport = false;
            this.changerpass = false;
            this.generatetoken = false;
          
        } else if (val === 'edit') {
            
            this.colorChangeone = 'load-delayone';
            this.colorChangetwo = 'load-delay ';
            this.colorChangethree = 'load-delayone ';
            this.colorChangefour = 'load-delayone ';
            this.viewreport = false;
            this.editeport = true;
            this.changerpass = false;
            this.generatetoken = false;
            console.log(this.myAccountDetails);

            this.EditProfileForm.setValue({
                firstname: this.myAccountDetails.firstName,
                lastname: this.myAccountDetails.lastName,
                emailid: this.myAccountDetails.loginId,
                stationcode: this.myAccountDetails.stationCode,
                contactnumber: this.myAccountDetails.contactNumber,
                address: this.myAccountDetails.address,
            });
          
            this.EditProfileForm.markAsPristine();
           
        } else if (val === 'change') {
            this.colorChangeone = 'load-delayone';
            this.colorChangetwo = 'load-delayone ';
            this.colorChangethree = 'load-delay';
            this.colorChangefour = 'load-delayone';
            this.viewreport = false;
            this.editeport = false;
            this.changerpass = true;
            this.generatetoken = false;
            this.ChangePasswordForm.reset()
        }else if (val === 'token') {
            this.colorChangeone = 'load-delayone';
            this.colorChangetwo = 'load-delayone ';
            this.colorChangethree = 'load-delayone ';
            this.colorChangefour = 'load-delay ';
            this.viewreport = false;
            this.editeport = false;
            this.changerpass = false;
            this.generatetoken = true;
            const obj: {} = {
                user_id: this.LogindetailsOne.userId,
                org_id: this.LogindetailsOne.orgId,
                loginUserId: this.LogindetailsOne.userId,
                 apiOpeType: 'AC'     //AU for update and AC for new generation
            }
            this.spinner.show();
            this.MyAccountService.generateToken(obj).subscribe(
                data => {
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                        this.xapikey = data.XApiKey;
                        this.xauthtoken = data.XAuthToken;
                    }else {
                        this.spinner.hide();
                        this.toastr.success('Something went wrong please try again.', '', {})
                    }
                }
            )
            // this.xapikey = 'ik4klkk390lskfj4572oolpekdjk00357jj3hny';
            // this.xauthtoken = 'ik4klkk390lskfj4572oolpekdjk00357jj3hny';
          
        }
    }


    //------ChangePasword

    passwordChange(ChangePasswordForm: any, formDirective: FormGroupDirective) {
        if (this.ChangePasswordForm.value.OldPassword === this.ChangePasswordForm.value.NewPassword) {
            this.toastr.error('It Seems You Have Entered Same Password As Old Password!', '', { timeOut: 3000 });
            return;
        }
        const obj: {} = {
            userId: this.LogindetailsOne.userId,
            oldPassword: this.ChangePasswordForm.value.OldPassword,
            newPassword: this.ChangePasswordForm.value.NewPassword
        }
        this.spinner.show();
        this.ChangePasswordForm.invalid
        this.MyAccountService.passwordChange(obj).subscribe(
            data => {
                this.spinner.hide();
                console.log(data)
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
                    if (this.dashBoradSwitchedtemp !== null) {
                        if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
                            
                            this.navbarComponent.logOut();
                        }
                    } else {
                        
                        this.navbarComponent.logOut();

                    }

                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                } 
                else {
                    this.toastr.error(data.message, '', { timeOut: 1200 });
                    this.ChangePasswordForm.valid
                   
                }

            },
            error => {
                this.toastr.error('unable Updated Password', '', {});
                
            }

        )
    }

    resetConfirmPassword() {
        
        
        this.ChangePasswordForm.controls['ConfirmPassword'].reset()

    }

    //------Api Token code start here

    generate(){
        this.isCopied1 = false;
        this.isCopied2 = false;
        jQuery("#re-confirmation").modal({
            backdrop: "static",
            keyboard: false,
          });
    }
    confirm_re(){
        jQuery("#re-confirmation").modal("hide");
        const obj: {} = {
            user_id: this.LogindetailsOne.userId,
            org_id: this.LogindetailsOne.orgId,
            loginUserId: this.LogindetailsOne.userId,
             apiOpeType: 'AU'     //AU for update and AC for new generation
        }
        this.spinner.show();
        this.MyAccountService.generateToken(obj).subscribe(
            data => {
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.xapikey = data.XApiKey;
                    this.xauthtoken = data.XAuthToken;
                }else {
                    this.spinner.hide();
                    this.toastr.success('Something went wrong please try again.', '', {})
                }
            }
        )
    }

    //------Api Token code end here  


    filterStations(stateobj: string) {
        
         console.log(this.stationsListObj)

        return this.stationsListObj.stationDetails.filter(state =>
            state.portCode.toLowerCase().indexOf(stateobj.toLowerCase()) === 0);


    }

    onSelect(state) {
        
     
        console.log(this.EditProfileForm.value);
        this.stationCodeTemp = state.portCode;
        this.StationCodeError = false;
        console.log(this.stationCodeTemp);
        console.log(state)
        this.EditProfileForm.controls['address'].patchValue(state.address);
        this.EditProfileForm.setErrors(null);
        console.log(this.EditProfileForm.value)
        

    }

    updatedVal(e) {
        
        if (e && e.length === 0) {
            this.showAutocomplete = true;
        } else {
            this.showAutocomplete = false;
        }
    }


    onPwdReqClick(){
        alert("hello")
    }

}



