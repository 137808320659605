import { Location, LocationStrategy } from "@angular/common";
import { Compiler, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../../app/config-service.service";
import { ShareLoginDataService } from "../../data/share-login-data.service";
import { NavbarComponent } from "../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../login/login-data.service";
import { LoginComponent } from "../../login/login.component";
import { LoginService } from "../../services/login/login.service";
import { RegisterService } from "../../services/register/register.service";
import { SuperadminService } from "../../services/superadmin/superadmin.service";
declare var jQuery: any;
@Component({
  selector: "app-address-book",
  templateUrl: "./address-book.component.html",
  styleUrls: ["./address-book.component.css"],
})
export class AddressBookComponent implements OnInit {
  loginComponent: LoginComponent;
  loginDetails: any;
  userName: [];
  orgType: string;
  loginUserId: string;
  addressList: any;
  name:string='';
  agent_code:'';
  agent_code_id:any =[];
  email: '';
  mobNumber: '';
  editMode: boolean = false;
  user_id: string = "0";
  id: string = "0";
  deleteItemId:any;
  items: any[]; 
  filteredItems: any[]; 
  searchInput: string = ''; 
  selectedAirports: string[] = []; 
  selectedAirportCodes: string[] = []; 
  selectedAirportId: string[] = []; 
  showList: boolean = false;
  flightshipmentdata: any = [];

  
  constructor(
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private atp: AmazingTimePickerService,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private SidebarComponent: SidebarComponent,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private config: ConfigServiceService
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
    this.initializeItems1();
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.loginUserId = this.loginDetails.userId;
    var orgId = this.loginDetails.orgId;
    this.orgType = this.loginDetails.orgType;

    this.superAdminservice.getUserDetails(orgId, this.loginUserId).subscribe(
      (data) => {
        this.userName = data.userDetails.filter(
          (user) => user.userStatus == "A"
        );
      },
      (error) => {}
    );
    this.spinner.hide();
    this.fetchAirlines();
    
  }

  // fetch airline
  fetchAirlines(){
    
    this.spinner.show();
    this.superAdminservice.getTruckAirportService().subscribe((data) => {
     // console.log("air===>",data);
      if (data.responseCode === 1) {
        this.flightshipmentdata = data.airport_details;
       // console.log("air linew===>", this.flightshipmentdata);
        localStorage.setItem("flightshipmentdata",JSON.stringify(data.airport_details));
      } 
    },(error) => {}
    );
    this.spinner.hide();
  
}


  initializeItems1() {
    this.items = JSON.parse(localStorage.getItem("flightshipmentdata"));
    this.filteredItems = this.items; 
    console.log("filter===>", this.filteredItems);
  }

  getItems(ev: any) {
    this.searchInput = ev.target.value;
    if (this.searchInput && this.searchInput.trim() !== "") {
      this.filteredItems = this.items.filter(item =>
        item.code.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        item.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
      this.showList = this.filteredItems.length > 0; 
    } else {
      this.showList = false;
    }
  }
  
  selectresultoriginid(item) {
    const selectedAirport = item.name + " - " + item.code;
    this.selectedAirports.push(selectedAirport); 
    this.selectedAirportCodes.push(item.code); 
    this.selectedAirportId.push(item.portId);
    this.showList = false;
  }
  removeSelectedAirport(index: number) {
    console.log("delete",index);
    this.selectedAirports.splice(index, 1); 
    this.selectedAirportCodes.splice(index, 1);
    this.selectedAirportId.splice(index,1);
}

  changeairportIdorigin() {}

  reloadCurrentRoute() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]);
    }); 
  }
  onSubmit() {
    if (!this.name) {
      this.toast.error("Please enter shipper name ");
    } else if(this.name.length < 3){
      this.toast.error("Shipper name must be at least 3 characters long");
    } else if(this.agent_code == ""){
      this.toast.error("Please add agent code");
    }
    else{
      let payload = {
        name: this.name,
        email: this.email,
        user_id: this.loginUserId,
        id: this.id,
        number: this.mobNumber,
        agent_code:this.agent_code,
        // airport_code:this.selectedAirportId
        airport_code: this.arrayUnique(this.selectedAirportId.concat(this.agent_code_id))
      };
     // console.log("payload:", payload);
     
      this.superAdminservice.createAddress(payload).subscribe(
        (data) => {
          if(data.responsecode== 1){
            this.toast.success(data.message);
            this.name='';
            this.email='';
            this.mobNumber='';
            this.reloadCurrentRoute();
            jQuery('#myModal').modal('hide');
          }else{
            this.toast.error(data.message)
          }
        
          // window.location.reload();
        },
        (error) => {}
      );
    }
    
  }

  arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
    return a;
  }

  onCancel() {
    this.name='';
          this.email='';
          this.mobNumber='';
          this.agent_code='';
          this.editMode =false;
          this.selectedAirports =[];
   
             }


  handleDeleteClick(rowdata: any) {
    event.preventDefault();
    console.log("Row ID to delete:", rowdata);
   
    this.deleteItemId =rowdata.id
    jQuery('#myModal2').modal('show');
  }

  DeleteReport(){
   
    let payload={
      "addressBookId":this.deleteItemId,
      "loginUserId":this.loginUserId
    }
    //return false;
    this.superAdminservice.deleteAddress(payload).subscribe(
      (data) => {
        console.log("address deleted===>", data);
        this.toast.success(data.message);
       
        jQuery('#myModal2').modal('hide');
       
        this.reloadCurrentRoute();
      },
      (error) => {}
    );
  }

  handleEditClick(rowdata) {
    console.log("row data==>",rowdata);
    this.editMode = true;
    this.name = rowdata.name;
    this.email = rowdata.email;
    this.user_id = rowdata.user_id;
    this.id = rowdata.id;
    this.mobNumber = rowdata.number;
    this.agent_code =rowdata.shipping_agent_code;
    this.agent_code_id =rowdata.airport_code_id;
    this.selectedAirports =rowdata.airport_code.split(',');
   
    jQuery('#myModal').modal('show');
  }
  closeModel(){
    console.log("....")
  }
  
  serviceReportSource: any = {
    datatype: "json",
    datafields: [
    
      { name: "id", type: "string" },
      { name: "name", type: "string" },
      { name: "email", type: "string" },
      { name: "number", type: "string" },
      { name: "shipping_agent_code", type: "string" },
      { name: "airport_code", type: "string" },
      { name: "airport_code_id", type: "string" },
      
    ],
    root: "data",
    url: this.config.getConfig().URL +"customerAddress/customerAddressPortal.php/getCustomerAddressBook?loginUserId="+JSON.parse(localStorage.getItem("loginDetails")).userId,

    sortcolumn: "created_on",
    sortdirection: "desc",
  };
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "100%";
    } else if (document.body.offsetWidth > 1400) {
      return "100%";
    }
    return "100%";
  }

  dataAdapter: any = new jqx.dataAdapter(this.serviceReportSource);
  columns: any[] = [
    {
      text: "Actions",
      datafield: "id",
      cellsalign: "center",
      filterable: false,
      align: "center",
      sortable: false,
      groupable: false,
      width: 60,
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata 
      ) => {
      
        const editButtonId = `editButton-${row}-${columnfield}`;
        const deleteButtonId = `deleteButton-${row}-${columnfield}`;
    
        
        setTimeout(() => {
          // Delete button
          const deleteButton = document.getElementById(deleteButtonId);
          if (deleteButton) {
            deleteButton.addEventListener('click', () => {
              this.handleDeleteClick(rowdata);
            });
          }
          
          // Edit button
          const editButton = document.getElementById(editButtonId);
          if (editButton) {
            editButton.addEventListener('click', () => {
              this.handleEditClick(rowdata); 
            });
          }
        });
    
        return `<div class='jq-actions' style='margin-left: 5px;'>
           <i id='${editButtonId}' class='fa fa-pencil-square-o' aria-hidden='true' data-toggle='tooltip' title='Edit'></i>
            <i class='fa fa-trash-o' aria-hidden='true' title='Delete' id='${deleteButtonId}'></i>
          </div>`
        
      },
    
    },
    {
      text: "Shipper Name",
      datafield: "name",
      align: "center",
    },
    {
      text: "Agent Code",
      datafield: "shipping_agent_code",
      align: "center",
      width: 120,
    },
    {
      text: "Airports",
      datafield: "airport_code",
      align: "center",
    },
    {
      text: "Email",
      datafield: "email",
      align: "center",
    },
    {
      text: "Mobile",
      datafield: "number",
      align: "center",
    },
  ];

}


