import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoginServicesService } from "../../services/login/login-services.service";
import { DatePipe, LocationStrategy } from '@angular/common';
import { DamageReportsService } from '../../services/damageReports/damage-reports.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewReportsData } from '../../../data/DataShareViewDetails';
import { SwitchdashboardService } from "../../../services/switch-dashboard/switchdashboard.service" ;
import { SidebarComponent } from '../../../layouts/sidebar/sidebar.component';
import { ConfigServiceService } from "src/app/config-service.service";
@Component({
  selector: "app-navbarCargoClaim",
  templateUrl: "./navbarCargoClaim.component.html",
  styleUrls: ["./navbarCargoClaim.component.css"]
})
export class NavbarCargoClaimComponent implements OnInit {
  loginDetails: any;
  email: string;
  password: string;
  userID: number;
  switchFrom: string;
  baseURL = this.config.getConfig().switchTruckingURL;
  role: string;
  loginData: any;
  fromDate: any;
  toDate: any;
  totalCount: any;
  dashBoradSwitchedtemp: any;
  switchEnable: string;
  cookieValue: any;
  comapanydetails: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private loginservice: LoginServicesService,
    private datePipe: DatePipe,
    private damageReport_Service: DamageReportsService,
    private cookieService: CookieService,
    private ViewReportsData: ViewReportsData,
    private SwitchdashboardService: SwitchdashboardService,
    private SidebarComponent: SidebarComponent,
    private location: LocationStrategy,
    private config: ConfigServiceService
  ) {}

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails != null || this.loginDetails != undefined) {
        this.email = this.loginDetails.emailId;
        this.password = '';
        this.userID = this.loginDetails.userId;
        this.switchFrom = 'cclp';
    }
    if (this.loginDetails.userType === "S") {
      this.role = "Company Super Admin";
    }else if(this.loginDetails.userType === "A"){
      this.role = "Admin";
    }else if(this.loginDetails.userType === "E"){
      this.role = "User";
    }
    //count api with null values
    this.loginData = JSON.parse(localStorage.getItem("loginDetails"));
    this.fromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    let Object = {
      org_id: this.loginData.orgId,
      year: null,
      fromDate: null,
      toDate: null,
    };
    this.spinner.show();
    this.damageReport_Service.ReportsCount(Object).subscribe(
      (data) => {
        this.totalCount = data["count"];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );


    this.dashBoradSwitchedtemp = JSON.parse(localStorage.getItem('dashBoradSwitchedtemp'));
    console.log('dashBoradSwitched---------------', this.dashBoradSwitchedtemp);
    if (this.dashBoradSwitchedtemp !== null) {
        if (this.dashBoradSwitchedtemp.isSwitched === 'yes') {
            this.switchEnable = 'yes'
          
            this.ViewReportsData.isFromDashboard = 'yes'
        }
    }


  }

  Logout() {
    ;
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if ( this.loginDetails !=null) {
      const obj = {
        orgId: this.loginDetails.orgId,
        userId: this.loginDetails.userId
      };
      this.loginservice.logOut(obj).subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.cookieService.delete('airPortClaims','/','.cclp.aero');
            this.cookieService.delete('uld','/','.cclp.aero');
            this.cookieService.delete('cclp','/','.cclp.aero');
            localStorage.clear();
            // this.toastr.success(data.message);
            this.router.navigateByUrl("/");
          }
          else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          } else {
            this.toastr.success(data.message);
            // localStorage.clear();
         
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    }
    else{
      localStorage.clear();
      this.router.navigateByUrl("/");
    }

  }




  switchToSaDashbord() {
    localStorage.removeItem("currentModule") ;
    let logindetailsForSwitching = JSON.parse(localStorage.getItem('logindetailsForSwitching'));
    this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
    
   let loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
 
//    loginresultobject["token"] = logindetailsForSwitching["token"]
//    localStorage.setItem('loginresultobject', JSON.stringify(loginresultobject));
//     console.log('logindetailsForSwitching', logindetailsForSwitching);
//     console.log('this.comapanydetails ', this.comapanydetails);
    // if(this.comapanydetails === undefined ||this.comapanydetails === null){
    //     let SwitchdashBoardObj = JSON.parse(localStorage.getItem('SwitchdashBoardObj'));
    //     this.comapanydetails.orgId = SwitchdashBoardObj.OrgId
    // }
    localStorage.setItem('tokenTO_SaveReportApi_WhenSwitching', JSON.stringify(loginresultobject));
    let dashBoardObj = {
        emailId: logindetailsForSwitching.emailId,
        OrgId: logindetailsForSwitching.orgId,
        OrgType: logindetailsForSwitching.orgType
    }
    console.log('switching to SA dashboard', dashBoardObj);
    this.spinner.show();
    this.SwitchdashboardService.switchDashBorad(dashBoardObj).subscribe(data => {
      
        console.log(data);
        this.spinner.hide();
        if (data.responseCode === 1) {

            this.cookieService.delete('cclp', '/', '.cclp.aero');
            var expire = new Date();
            var time = Date.now() + ((3600 * 1000) * 8);
            expire.setTime(time);
            this.cookieService.set('cclp', JSON.stringify(data.token), expire, '/', '.cclp.aero',true,"None");
            this.cookieValue = this.cookieService.get('cclp');
            console.log("set cookie value of cclp", this.cookieValue);
;
            if (data.userType === 'SA') {
                localStorage.setItem('loginresultobject', JSON.stringify(data));
                if (this.ViewReportsData.isFromDashboard === 'yes') {
                    this.ViewReportsData.isFromDashboard = 'no';
                    localStorage.removeItem("dashBoradSwitchedtemp");
                    localStorage.removeItem("currentModule");
                    let val = '1'

                    this.SidebarComponent.activecall("1");
                    localStorage.setItem('activation', JSON.stringify(val));
                    localStorage.setItem('reloadRequired', JSON.stringify("true"));
                    this.router.navigateByUrl("/sa-dashboard");
                } else {
                    this.ViewReportsData.isFromDashboard = 'no';
                    this.SidebarComponent.activecall("")
                    this.router.navigate(["viewusers", this.comapanydetails.orgId]);
                }
            } else if (data.responseCode === 0) {
                this.toastr.error(data.message);
            }
        }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
            this.toastr.info("Your session is expired, please login again");
        } else{
            this.toastr.error(data.message);
        }
    }, error => {
      
        this.spinner.hide();
        console.log(error);
        this.toastr.error(error.statusText)
    });
}
}
