import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";
@Component({
  selector: "app-investigation-count",
  templateUrl: "./investigation-count.component.html",
  styleUrls: ["./investigation-count.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InvestigationCountComponent implements OnInit {
  loginDetails: any;
  orgId: any;
  totalinvestigationCount: any;
  Investigationoriginstation: any;
  investOriginCount: any;
  Investigationdeststation: any;
  investDestCount: any;
  investigationDestCount: any;
  inputObj: any;
  investOrigin: any = [];
  topinvestorigin: any = [];
  investdest: any = [];
  topinvestdest: any = [];
  scrollable: boolean;
  scrollable1: boolean;
  graphHeight: number;
  origincountInvestigationWidth: number;
  destinationcountInvestigationWidth: number;
  labelLengthinvestOrg: any;
  labelLengthinvestDest: any;
  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {
    this.investOrigin = [];
    this.topinvestorigin = [];
    this.investdest = [];
    this.topinvestdest = [];
    //getting input object from dashboard
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
  
      //getting login object 
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
      //investigation api integration
      this.spinner.show();
      this.claimcountservice.investigation_count(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.totalinvestigationCount = data.totalinvestigationCount;
            this.investOrigin = data.investigationOrigin;
            this.topinvestorigin = data.topInvestigationOrigin;
            this.investdest = data.investigationDestn;
            this.topinvestdest = data.topInvestigationDestination;
            this.Investigationoriginstation = data.investigationOrigin.labels;
              this.investOriginCount = data.investigationOrigin.count;
                //dymanic scrool start here
                if (data.investigationOrigin.labels!=null && data.investigationOrigin.labels!=undefined) {
                  this.labelLengthinvestOrg = data.investigationOrigin.labels.length; 
                }
           if (data.investigationDestn.labels!=null && data.investigationDestn.labels!=undefined) {
            this.labelLengthinvestDest = data.investigationDestn.labels.length; 
           }
              
          if (this.labelLengthinvestOrg > 60 ) {
            this.scrollable = true
          }
          else{
            this.scrollable = false
          }
          if (this.labelLengthinvestDest > 60 ) {
            this.scrollable1 = true
          }
          else{
            this.scrollable1 = false
          }
             //Investigation Count Origin  line chart  model starts here
             if (data.investigationOrigin.labels !=null && data.investigationOrigin.labels !=undefined) {
          this.origincountInvestigationWidth = this.getWidth(data.investigationOrigin.labels.length);
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("origincountInvestigation")
                ),
                {
                  type: "line",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.Investigationoriginstation,
                    datasets: [
                      {
                        type: "line",
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        data: this.investOriginCount,
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Investigation Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Conclusions per station",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            maxRotation: 60,
                            minRotation: 60,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
          }
            //investigation count origin on load top 10 line chart
            this.Investigationoriginstation =
              data.topInvestigationOrigin.labels,
              this.investOriginCount = data.topInvestigationOrigin.count,
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("origincountInvestigationOne")
                ),
                {
                  type: "line",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.Investigationoriginstation,
                    datasets: [
                      {
                        type: "line",
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        data: this.investOriginCount,
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    title: {
                      display: true,
                      //text: "Top 10 Investigation Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Conclusions per station",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            // max rotation: -90,
                            // min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //investigation Count Destination  line model chart starts here
            if (data.investigationDestn.labels!=null && data.investigationDestn.labels!=undefined) {
              this.destinationcountInvestigationWidth = this.getWidth(data.investigationDestn.labels.length);
            }
        
            
            (this.Investigationdeststation = data.investigationDestn.labels),
              (this.investDestCount = data.investigationDestn.count),
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("destinationcountInvestigation")
                ),
                {
                  type: "line",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.Investigationdeststation,
                    datasets: [
                      {
                        type: "line",
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        data: this.investDestCount,
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio:false,
                    title: {
                      display: true,
                      //text: "Investigation Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Conclusions per station",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            maxRotation: 60,
                            minRotation: 60,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
            //investigation Count Destination  line top 10 on load chart starts here
        
            this.Investigationdeststation =
              data.topInvestigationDestination.labels;
            (this.investigationDestCount =
              data.topInvestigationDestination.count),
              new Chart(
                <HTMLCanvasElement>(
                  document.getElementById("destinationcountInvestigationOne")
                ),
                {
                  type: "line",
                  plugins: [ChartDataLabels],
                  data: {
                    labels: this.Investigationdeststation,
                    datasets: [
                      {
                        type: "line",
                        borderColor: "#ED7D31",
                        backgroundColor: "#ED7D31",
                        hoverBackgroundColor: "#ED7D31",
                        data: this.investigationDestCount,
                        fill: false,
                      },
                    ],
                  },
                  options: {
                    title: {
                      display: true,
                      //text: "Top 10 Investigation Count"
                    },
                    plugins: {
                      // Change options for ALL labels of THIS CHART
                      datalabels: {
                        color: "#000000",
                        anchor: "end",
                        align: "top",
                        rotation: -90,
                      },
                    },
                    legend: { display: false },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Conclusions per station",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            autoSkip: false,
                            //  max rotation: -90,
                            //  min rotation: -90
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: "Count",
                            fontSize: 16,
                            fontColor: "#000000",
                            fontStyle: "bold",
                          },
                          ticks: {
                            beginAtZero: true,
                            //lineHeight:20,
                            // max:plugin.settings.maxDataValue,
                            maxTicksLimit: 10,
                          },
                          id: "y-axis-0",
                          position: "left",
                        },
                      ],
                    },
                  },
                }
              );
              this.scrollView();
          }
          else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          }
          else {
            this.toaster.error(data.message);
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.totalinvestigationCount.org_count_conclusion = 0;
      this.totalinvestigationCount.des_count_conclusion = 0;
    }
  }
  //download charts method
  onClickDownloadTabCharts() {
    this.spinner.show();
    const canvas = document.getElementById("origincountInvestigation") as HTMLCanvasElement;
    const canvas2 = document.getElementById("destinationcountInvestigation")as HTMLCanvasElement;
    var imgData = canvas.toDataURL("image/png");
    var imgData2 = canvas2.toDataURL("image/png");
    var imgWidth = 285;
    var pageHeight = 200;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.setFontSize(18);
    var position = 80; // give some top padding to first page
    var text ="Cargo Claim Root Causes Charts";
    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, 10, text);
    doc.setFontSize(14);
    doc.text(10, 20, 'Root causes Origin');
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    doc.addPage();
    doc.text(10, 20, ' Root causes Destination');
     doc.addImage(imgData2, "PNG", 0, position, imgWidth, imgHeight);
     heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight; // top padding for other pages
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save("file.pdf");
    this.spinner.hide();
  }
  //show and hide model charts methods
  showmodelorg(){
    const element = document.getElementById("investigationorigin");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodelorg(){
    const element = document.getElementById("investigationorigin");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  showmodeldest(){
    const element = document.getElementById("investigationdestination");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodeldest(){
    const element = document.getElementById("investigationdestination");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  //dynamic scroll method
  scrollView(){
    setTimeout(() => {
      const elements =document.getElementsByClassName('chartAreaWrapper_scroll60');
      for(let ele of elements as any){
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
   
  }
  //dynamic width method
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 20) {
        let finalWidth = 1300;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}
