import { Component, OnInit } from "@angular/core";

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { LoginServicesService } from "../services/login/login-services.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BrowserModule } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public static LOGIN_RESPONSE;
  cookieValue: string;
  loginDetails: any;

  constructor(
    private formBuilder: FormBuilder,
    private login_Servc: LoginServicesService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cookieService: CookieService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
  
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (
      this.loginDetails != null &&
      this.loginDetails != undefined &&
      this.loginDetails != ""
    ) {
      this.spinner.show();
      this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      if (this.loginDetails != null) {
        const obj = {
          orgId: this.loginDetails.orgId,
          userId: this.loginDetails.userId,
        };
        this.spinner.show();
        this.login_Servc.logOut(obj).subscribe(
          (data) => {
            this.spinner.hide();
            if (data.responseCode === 1) {
              this.cookieService.delete("airPortClaims", "/", ".cclp.aero");
              this.cookieService.delete("uld", "/", ".cclp.aero");
              this.cookieService.delete("cclp", "/", ".cclp.aero");
              localStorage.clear();
              // this.toastr.success(data.message);
            } else {
              this.toastr.success(data.message);
              // localStorage.clear();
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
      } 
    }
    this.loginForm = this.create_Login_Form();
  }

  create_Login_Form() {
    let EMAILPATTERN =
      "(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}";
    return this.formBuilder.group({
      emailId: new FormControl("", [
        Validators.required,
        Validators.pattern(EMAILPATTERN),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  fn_onSubmit_form(formValues) {
    this.spinner.show();
    this.login_Servc.loginServc(formValues).subscribe(
      (responseData) => {
        if (responseData.responseCode === 1) {
          LoginComponent.LOGIN_RESPONSE = responseData;
          // this.toaster.success("Login successfull");
          localStorage.setItem(
            "loginDetails",
            JSON.stringify(LoginComponent.LOGIN_RESPONSE)
          );
          localStorage.setItem(
            "loginresultobject",
            JSON.stringify(LoginComponent.LOGIN_RESPONSE)
          );
          localStorage.setItem(
            "Token",
            JSON.stringify(LoginComponent.LOGIN_RESPONSE.token)
          );

          var expire = new Date();
          var time = Date.now() + 3600 * 1000 * 8;
          expire.setTime(time);
          this.cookieService.set(
            "airPortClaims",
            JSON.stringify(responseData.token),
            expire,
            "/",
            ".cclp.aero",true,"None"
          );
          this.cookieValue = this.cookieService.get("airPortClaims");
          //console.log("set cookie value of airPortClaims", this.cookieValue);
        
          this.router.navigateByUrl("/cargoClaim/dashboard/claim-count");
        } else if (
          responseData.responseCode === 0 &&
          responseData.message === "Unauthorized User"
        ) {
          this.toastr.warning("your session expired please login again");
          this.router.navigateByUrl("/");
        } else {
          this.toaster.error(responseData.message);
          //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          //   this.router.navigate(["/login"]));
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }


  onSwitchBack(){
    ;
 let wind=  window.open('',"testff");
 try{
  if(wind.location.href){
    window.open('http://localhost:4200/',"testff");
  }else{
    
  }
 }catch(exec){
  

 }
     
  }


}
