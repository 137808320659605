import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LoginComponent } from './login/login.component';
import { AuthenticateGuard } from './AuthGuard/authenticate.guard';
import { AdminComponent } from './admin/admin.component';
import { AddAirlineComponent } from './csa-admin/add-airline/add-airline.component';
import { CreateuserComponent } from './csa-admin/createuser/createuser.component';
import { CSAAdminComponent } from './csa-admin/csa-admin.component';
import { CsaAirlineDashboardComponent } from './csa-admin/csa-airline-dashboard/csa-airline-dashboard.component';
import { EdituserComponent } from './csa-admin/edituser/edituser.component';
import { ViewAirlineEmailsComponent } from './csa-admin/view-airline-emails/view-airline-emails.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { MyaccountComponent } from './shared/myaccount/myaccount.component';
import { SettingsComponent } from './shared/settings/settings.component';
import { AddstationsComponent } from './shared/stations/addstations/addstations.component';
import { EditstationsComponent } from './shared/stations/editstations/editstations.component';
import { ViewstationsComponent } from './shared/stations/viewstations/viewstations.component';
import { ViewReprotsComponent } from './shared/view-reprots/view-reprots.component';
import { ViewUsersComponent } from './shared/view-users/view-users.component';
import { ViewdetailsComponent } from './shared/viewdetails/viewdetails.component';
import { CreateorgComponent } from './superadmin/createorg/createorg.component';
import { EditorgComponent } from './superadmin/editorg/editorg.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { UsersComponent } from './users/users.component';
// 111023 To added the new KPT charts code start
import { KPIChartsEmailSettingsComponent } from "./csa-admin/kpi-charts-email-settings/kpi-charts-email-settings.component";
import { KpichartsComponent } from "./csa-admin/kpicharts/kpicharts.component";
// 111023 To added the new KPT charts code end
import { globalConstants } from 'src/environments/globalConstants';
import { CanDeactivateSaveReport } from "./AuthGuard/deactivateguard";
import { AdminAirlineDashboardComponent } from './admin/admin-airline-dashboard/admin-airline-dashboard.component';
import { AdminHandlerDashboardComponent } from './admin/admin-handler-dashboard/admin-handler-dashboard.component';
import { AirlinesComponent } from './airlines/airlines.component';
import { AccessStationComponent } from './csa-admin/access-station/access-station.component';
import { AddressBookComponent } from "./csa-admin/address-book/address-book.component";
import { CustomerReportComponent } from "./csa-admin/customer-report/customer-report.component";
import { CustomerReportHandlerComponent } from './csa-admin/damage-customer-report/customer-report-handler/customer-report-handler.component';
import { DamageCustomerReportComponent } from "./csa-admin/damage-customer-report/damage-customer-report.component";

import { GridReportDetailsComponent } from './csa-admin/damage-customer-report/grid-report-details/grid-report-details.component';
import { ReportAccessComponent } from './csa-admin/report-access/report-access.component';
import { AddHandlerServicesComponent } from "./csa-admin/service-recovery/add-handler-services/add-handler-services.component";
import { AddServiceRecoveryComponent } from "./csa-admin/service-recovery/add-service-recovery/add-service-recovery.component";
import { EditServiceRecoveryAirlineComponent } from "./csa-admin/service-recovery/edit-service-recovery-airline/edit-service-recovery-airline.component";
import { EditServiceRecoveryComponent } from "./csa-admin/service-recovery/edit-service-recovery/edit-service-recovery.component";
import { ViewServiceRecoveryComponent } from "./csa-admin/service-recovery/view-service-recovery/view-service-recovery.component";
import { UseraccessComponent } from './csa-admin/useraccess/useraccess.component';
import { DownloadComponent } from './download/download.component';
import { AddDSEComponent } from './dse/add-dse/add-dse.component';
import { EditDSEComponent } from './dse/edit-dse/edit-dse.component';
import { ViewDSEComponent } from './dse/view-dse/view-dse.component';
import { HandlerDashboardComponent } from './handler-dashboard/handler-dashboard.component';
import { HomeComponent } from './home/home.component';
import { InvalidRequestComponent } from './invalid-request/invalid-request.component';
import { LoginComponent } from './login/login.component';
import { OpenApiComponent } from './open-api/open-api.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacypoliciesComponent } from './privacypolicies/privacypolicies.component';
import { ReactWebComponent } from './react-web/react-web.component';
import { RegisterflowComponent } from './registerflow/registerflow.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SampleComponent } from './sample/sample.component';
import { SaveReportsComponent } from './save-reports/save-reports.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UnauthroizedUsersComponent } from './shared/unauthroized-users/unauthroized-users.component';
import { UsersListComponent } from './shared/users-list/users-list.component';
import { StationComponent } from './station/station.component';
import { AllusersComponent } from './superadmin/allusers/allusers.component';
import { EdituserOrgComponent } from './superadmin/edituser-org/edituser-org.component';
import { SetRolesComponent } from './superadmin/set-roles/set-roles.component';
import { SwitchToCclpComponent } from './switch-to-cclp/switch-to-cclp.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { UpdatesComponent } from './updates/updates.component';

const routes: Routes = [
   
    { path: 'open-api', component: OpenApiComponent },
    { path: 'save-report', component: SaveReportsComponent ,  canDeactivate:[CanDeactivateSaveReport] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT] }},
    { path: 'Home', component: HomeComponent },
    { path: 'set-roles', component: SetRolesComponent , canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT , globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] } },
    { path: 'edituser-org', component: EdituserOrgComponent, canActivate: [AuthenticateGuard] ,data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] } },
    { path: 'admin-handler-dashboard', component: AdminHandlerDashboardComponent, canActivate: [AuthenticateGuard] ,data: { allowedRoles: [globalConstants.ADMIN_HANDLER_AUTH_TXT] }},
    { path: 'admin-airline-dashboard', component: AdminAirlineDashboardComponent, canActivate: [AuthenticateGuard]  ,data: { allowedRoles: [globalConstants.ADMIN_AIRLINE_AUTH_TXT ] }},
    { path: 'allusers', component: AllusersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT ] }},
    { path: 'updates', component: UpdatesComponent },
    { path: 'download', component: DownloadComponent },
    { path: 'unAuthorized', component: UnAuthorizedComponent },
    { path: 'login', component: LoginComponent },
    { path: 'registerflow', component: RegisterflowComponent },
    { path: 'privacy-policies', component: PrivacypoliciesComponent },
    { path: 'admin-dashboard', component: AdminComponent, canActivate: [AuthenticateGuard] },
    { path: 'sa-dashboard', component: SuperadminComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'csa-dashboard', component: CSAAdminComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT] }  },
    { path: 'user-dashboard', component: UsersComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.USER_HANDLER_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT] }  },
    { path: 'user-dashboard/:type', component: UsersComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.USER_HANDLER_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT] } },
    // { path: 'user-dashboard', component: UsersComponent, canActivate: [AuthenticateGuard] },
    { path: 'myaccount', component: MyaccountComponent, canActivate: [AuthenticateGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_HANDLER_AUTH_TXT] } },
    { path: 'createuser', component: CreateuserComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT , globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: 'edituser', component: EdituserComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: 'edituser/:userId', component: EdituserComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT  ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] } },
    { path: 'unauth-users', component: UnauthroizedUsersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT,globalConstants.CSA_HANDLER_AUTH_TXT] }},
    { path: 'access-stations', component: AccessStationComponent, canActivate: [AuthenticateGuard] },
    { path: 'viewusers', component: ViewUsersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: 'listofusers', component: UsersListComponent, canActivate: [AuthenticateGuard] ,data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT ] } },
    { path: "viewusers/:orgKey/:type", component: ViewUsersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ,globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: "listofusers/:orgKey", component: UsersListComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: "viewusers/:type", component: ViewUsersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: "viewusers/:orgLogo", component: ViewUsersComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: 'viewreports', component: ViewReprotsComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] } },
    { path: 'viewreports/:orgKey', component: ViewReprotsComponent, canActivate: [AuthenticateGuard]  , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'forgotpassword', component: ForgotpasswordComponent },
    { path: 'viewdetails', component: ViewdetailsComponent, canActivate: [AuthenticateGuard] ,data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT ,globalConstants.ADMIN_AIRLINE_AUTH_TXT ] } },
    { path: "viewdetails/:orgKey", component: ViewdetailsComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT  ,globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT , globalConstants.ADMIN_AIRLINE_AUTH_TXT] }},
    { path: "viewdetails/:orgKey/:type", component: ViewdetailsComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.USER_HANDLER_AUTH_TXT ,globalConstants.ADMIN_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.USER_AIRLINE_AUTH_TXT , globalConstants.ADMIN_AIRLINE_AUTH_TXT] }},
    { path: 'addstations', component: AddstationsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT  ] } },
    { path: 'viewstations', component: ViewstationsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT ] }},
    { path: 'editstations', component: EditstationsComponent, canActivate: [AuthenticateGuard], data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT  ] } },
    { path: 'createorg', component: CreateorgComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'editorg', component: EditorgComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'editorg/:orgId/:orgType', component: EditorgComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'addairline_email', component: AddAirlineComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [ globalConstants.CSA_AIRLINE_AUTH_TXT ] }},
    { path: 'csa-airline-dashboard', component: CsaAirlineDashboardComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.CSA_AIRLINE_AUTH_TXT] } },
    //   { path: 'csa-airline-dashboard/:orgKey', component: CsaAirlineDashboardComponent, canActivate: [AuthenticateGuard] },
    { path: 'view-airline-emails', component: ViewAirlineEmailsComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [ globalConstants.CSA_AIRLINE_AUTH_TXT ] } },
    { path: 'reportaccess', component: ReportAccessComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [ globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_FORWARDER_AUTH_TXT] } },
    { path: 'useraccess', component: UseraccessComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [ globalConstants.CSA_HANDLER_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] }},
    { path: 'resetpassword', component: ResetPasswordComponent, canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT , globalConstants.CSA_HANDLER_AUTH_TXT ,globalConstants.CSA_AIRLINE_AUTH_TXT , globalConstants.CSA_FORWARDER_AUTH_TXT] } },
  
    { path: 'adddeactivatestationemail', component: AddDSEComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] } },
    { path: 'editdeactivatestationemail', component: EditDSEComponent ,canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] }},
    { path: 'viewdeactivatestationemail', component: ViewDSEComponent, canActivate: [AuthenticateGuard] , data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT] } },
    
    // 220424 to add address book and customer form
     {path: "address-book",component: AddressBookComponent,canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT,globalConstants.CSA_AIRLINE_AUTH_TXT,globalConstants.CSA_HANDLER_AUTH_TXT ] }},
     {path: "damageCustomerReport",component: DamageCustomerReportComponent, canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT,globalConstants.CSA_AIRLINE_AUTH_TXT] }},
     {path: "customer-report", component: CustomerReportComponent, canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT,globalConstants.CSA_AIRLINE_AUTH_TXT,globalConstants.CSA_HANDLER_AUTH_TXT] } },
    // 111023 To added the new KPT charts code start
    {path: "customerReportHandler",component: CustomerReportHandlerComponent, canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.SUPERADMIN_AUTH_TXT,globalConstants.CSA_AIRLINE_AUTH_TXT,globalConstants.CSA_HANDLER_AUTH_TXT ]}},
    
    // 21-05-2024 to add customer report in handler

    // 03-09-2024  grid report detail 
    {path: "girddetailsreport",component: GridReportDetailsComponent,canActivate: [AuthenticateGuard]},

    //
    {
        path: "kpicharts",
        component: KpichartsComponent,
        canActivate: [AuthenticateGuard],
    },

    {
      path: "kpiChartsEmailSettings",
      component: KPIChartsEmailSettingsComponent,
      canActivate: [AuthenticateGuard],data: { allowedRoles: [globalConstants.CSA_USERTYPE_CD,globalConstants.CSA_AIRLINE_AUTH_TXT,globalConstants.CSA_HANDLER_AUTH_TXT ]}},
      
    
    // 111023 To added the new KPT charts code end

    // 2604204 to merge serveice recovery

    {path: "csa-service-recovery",component: ViewServiceRecoveryComponent,canActivate: [AuthenticateGuard], data: {allowedRoles: [ globalConstants.CSA_AIRLINE_AUTH_TXT, globalConstants.CSA_HANDLER_AUTH_TXT,globalConstants.CSA_AIRLINE_AUTH_TXT]}},
    {path: "addservicerecovery",component: AddServiceRecoveryComponent,canActivate: [AuthenticateGuard],data: {allowedRoles: [ globalConstants.SUPERADMIN_AUTH_TXT, globalConstants.CSA_HANDLER_AUTH_TXT, globalConstants.CSA_AIRLINE_AUTH_TXT] }},
      {
        path: "addhandlerservice",
        component: AddHandlerServicesComponent,
        canActivate: [AuthenticateGuard],
        data: {
          allowedRoles: [
            globalConstants.SUPERADMIN_AUTH_TXT,
            globalConstants.CSA_HANDLER_AUTH_TXT,
            globalConstants.CSA_AIRLINE_AUTH_TXT,
          ],
        },
      },
      {
        path: "editservicerecovery",
        component: EditServiceRecoveryComponent,
        canActivate: [AuthenticateGuard],
        data: {
          allowedRoles: [
            globalConstants.SUPERADMIN_AUTH_TXT,
            globalConstants.CSA_HANDLER_AUTH_TXT,
            globalConstants.CSA_AIRLINE_AUTH_TXT,
          ],
        },
      },
      {
        path: "editservicerecoveryairline",
        component: EditServiceRecoveryAirlineComponent,
        canActivate: [AuthenticateGuard],
        data: {
          allowedRoles: [
            globalConstants.SUPERADMIN_AUTH_TXT,
            globalConstants.CSA_HANDLER_AUTH_TXT,
            globalConstants.CSA_AIRLINE_AUTH_TXT,
          ],
        },
      },


    { path: 'HandlerDashboardComponent', component: HandlerDashboardComponent },
    { path: 'PageNotFound', component: PageNotFoundComponent },
    { path: 'airlines', component: AirlinesComponent },
    { path: 'station', component: StationComponent },
    { path: 'deliveryReciept/:id', component: SampleComponent},

    { path: 'invalid', component: InvalidRequestComponent},
    { path: 'switchToCCLP/:key', component: SwitchToCclpComponent},

    { path: 'CargoAcceptance', loadChildren: 'src/app/cargo-acceptance/cargo-acceptance.module#CargoAcceptanceModule'  },
    { path: 'CargoImageHandler', loadChildren: 'src/app/cargo-image-handler-module/cargo-image-handler-module.module#CargoImageHandlerModuleModule'  },
    { path: 'CargoImage', loadChildren: 'src/app/cargoImageModule/cargo-image.module#CargoImageModule'  },
    { path: 'Uld', loadChildren: 'src/app/uld/uld-damage/uld-damage.module#UldDamageModule'  },
    { path: 'Shared', loadChildren: 'src/app/layouts/shared-layouts/shared-layouts.module#SharedLayoutsModule'  },
    { path: 'cargoClaim', loadChildren: 'src/app/cargoClaimModule/cargoclaim-dashboard.module#CargoclaimDashboardModule'  },
    { path: 'Truckinspection',loadChildren:'src/app/truck-inspection/truck-inspection.module#TruckInspectionModule' },
    { path: 'cargoDamageForwarder',loadChildren:'src/app/cargo-damage-forwarder/cargo-damage-forwarder.module#CargoDamageForwarderModule' },
    { path: 'setPassword/:id', component: SetPasswordComponent},
    { path: 'react/:id', component: ReactWebComponent},
    { path: '', loadChildren: './verification/verification.module#VerificationModule' },
    { path: '**', redirectTo: 'PageNotFound'},
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true 
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
