import { Component, HostListener } from '@angular/core';
import { InactivityService } from './inactivity-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cclp Portal';
  constructor(private inactiveService: InactivityService,private toastr: ToastrService) { }

  @HostListener('window:online', ['$event'])
  Online_Status_Indicator() {
    this.toastr.warning('Online', 'Network information', {
      positionClass: 'toast-top-right' 
   });
  }
@HostListener('window:offline', ['$event'])
Offline_Status_Indicator() {
    this.toastr.warning('Connection lost, Please check Internet connection.', 'Network information', {
   positionClass: 'toast-top-right' 
});
  }



  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  resetTimer() {
    this.inactiveService.notifyUserAction();
  }

 
}
