import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ConfigServiceService } from '../../config-service.service';
const headers = new HttpHeaders(
    {'Content-Type': 'application/json; charset=utf-8',
    'X_VERIFY'  :  'c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4'});

@Injectable({
  providedIn: 'root'
})
export class SettingsServicesService {
  API_URL:string ;
  constructor(public http: HttpClient, config : ConfigServiceService) {
this.API_URL =  config.getConfig().URL
   }



  setAuto_Or_Schedule_EmailS(inputObjData) {
    return this.http.post<any>(this.API_URL + 'organisationTimeSet.php/changeTimeAndSchedule',inputObjData);
  }


  getAuto_Or_Schedule_EmailS(inputOrgId) {
    return this.http.get<any>(this.API_URL + 'organisationTimeSet.php/getAutoSendEmailStatus?orgId='+inputOrgId);
  }

  rootcause_auto_send_emails(inputData) {
    return this.http.post<any>(this.API_URL + 'setRootcauseStatus.php/updateRootcauseStatus', inputData);
  }
}
