import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { globalConstants } from 'src/environments/globalConstants';
import { ConfigServiceService } from '../../../config-service.service';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-active-archive-reports-table",
  templateUrl: "./active-archive-reports-table.component.html",
  styleUrls: ["./active-archive-reports-table.component.css"],
})
export class Active_Archive_ReportsTableComponent implements OnInit {    
  public static orgId = JSON.parse(localStorage.getItem("orgId"));
  public static orgType = JSON.parse(localStorage.getItem("orgType"));
  public static totaldata;
  public static currentReportTab;
  public static WEB_API: string;
  public static TOTAL_GRIDDATA = [];
  public mergedReports: any = [];
  public finalReportArray: any = [];
  public airlineDetails: any = [];
  public ReportsProductionUrl: string;
  public finalReportsCount: number;
  public incomingReportsCount: number;
  public internalReportCount: number;
  reportsTab: any;

  public uldActiveReportsCount: number;
  public uldArchiveReportsCount: number;
  public uldIncomingReportsCount: number;
  @Output() rowDouble_ClickEvent = new EventEmitter<{}>();
  @Output() onRowSelectionChange = new EventEmitter<{}>();
  @Output() allReportData = new EventEmitter<{}>();
  @ViewChild("myGrid") myGrid: jqxGridComponent;

  comapanydetails: any;
  orgIdValue: any;
  userTypeValue: any;

  constructor(
    private config: ConfigServiceService,
    private ViewReportsData: ViewReportsData,
    private route: Router,
    private spinner: NgxSpinnerService,
    public toast: ToastrService
  ) {
    Active_Archive_ReportsTableComponent.WEB_API = this.config.getConfig().URL;
  }

  ngOnInit() {
    this.ReportsProductionUrl = this.config.getConfig().ReportsUrl;
    Active_Archive_ReportsTableComponent.WEB_API = this.config.getConfig().URL;
  }

  public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY;
  logindet = JSON.parse(localStorage.getItem("loginresultobject"));
  url =
    this.config.getConfig().URL +
    "superAdminUserReports.php/getUserReportInfo?organisationId=" +
    this.logindet.orgId +
    "&orderType=A&loginUserType=" +
    this.logindet.userType +
    "&loginUserId=" +
    this.logindet.userId;
  source = {
    url: this.url,
    datatype: "json",
    sortable: true,
    sortname: "Carrier",
    datafields: [
      { name: "carrierId", type: "number" },
      { name: "prefixCarrierId", type: "number" },
      { name: "emailStatus", type: "string" },
      { name: "damageReportType", type: "string" },
      { name: "actions", type: "string" },
      { name: "stationCode", type: "string" },
      { name: "incidentDate", type: "date" },
      { name: "masterAwbNo", type: "string" },
      { name: "safetyLife", type: "string" },
      { name: "reportStatus", type: "string" },
      { name: "rootcausestatus", type: "string" },
      { name: "rootcauseExists", type: "string" },
      { name: "orgRootcauseStatus", type: "string" },
      { name: "reportOrgRootcauseStatus", type: "string" },
      { name: "destAirportName", type: "string" },
      { name: "originAirportName", type: "string" },

      { name: "internalFileName", type: "string" },
      { name: "externalFileName", type: "string" },
      { name: "airlineName", type: "string" },
      { name: "flightTruckNo", type: "string" },
      { name: "originStation", type: "string" },
      { name: "destStation", type: "string" },
      { name: "truckType", type: "string" },

      { name: "reportPreparedName", type: "string" },
      { name: "shipper", type: "string" },
      { name: "consignee", type: "string" },
      { name: "issuingAgent", type: "string" },
      { name: "jobTitle", type: "string" },
      { name: "flightDate", type: "date" },
      { name: "contentNature", type: "string" },
      { name: "damgagePacking", type: "string" },
      { name: "contentAffected", type: "string" },

      { name: "classificationType", type: "string" },
      { name: "damageDiscovered", type: "string" },
      { name: "discoveresCaused", type: "string" },
      { name: "modifiedDate", type: "date" },
      { name: "modifiedTime", type: "string" },
      { name: "reportCreatedTime", type: "string" },

      { name: "reportId", type: "string" },
      { name: "reportOrgType", type: "string" },
      { name: "reportOrgId", type: "string" },
      { name: "userId", type: "string" },
      { name: "firstName", type: "string" },

      { name: 'calculateRisk', type: 'string' },
    ],
    pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
    root: "Rows",
    totalrecords: 800,
    cache: false,
    sort: (dataArray) => {
      this.myGrid.updatebounddata("sort");
    },
    filter: (dataArray) => {
      this.myGrid.updatebounddata("filter");
    },
    beforeprocessing: (data: any) => {
      this.allReportData.emit(data);
      this.mergedReports = [];
      this.finalReportArray = [];
      this.airlineDetails = [];
      this.spinner.hide();
      if (data.responseCode === 1 && data.userReports.length !== 0) {
        this.finalReportArray = data.userReports;
        this.airlineDetails = data.airlineDetails;
        this.mergedReports = data.userReports;
        if (data.userDetails !== null && data.userDetails !== undefined) {
          if (data.userDetails.length !== 0) {
            for (let i = 0; i < data.userDetails.length; i++) {
              for (let j = 0; j < this.finalReportArray.length; j++) {
                if (
                  data.userDetails[i].userId == this.finalReportArray[j].userId
                ) {
                  this.finalReportArray[j].firstName =
                    data.userDetails[i].firstName;
                  this.finalReportArray[j].jobTitle =
                    data.userDetails[i].jobTitle;
                }
              }
            }
          }
        }
        for (let i = 0; i < this.finalReportArray.length; i++) {
          if (
            this.finalReportArray[i].adminAuth === "A" ||
            this.finalReportArray[i].superAdminAuth === "A"
          ) {
            this.finalReportArray[i].authstatus = "Authorized user ";
          } else if (
            this.finalReportArray[i].adminAuth === "I" &&
            this.finalReportArray[i].superAdminAuth === "I"
          ) {
            this.finalReportArray[i].authstatus = "Unauthorized user";
          }
        }

        for (let index = 0; index < this.finalReportArray.length; index++) {
          if (this.finalReportArray[index].emailStatus === "S") {
            this.finalReportArray[index].emailStatus = "Sent to airline";
          } else if (
            this.finalReportArray[index].emailStatus === "N" ||
            this.finalReportArray[index].emailStatus === "C" ||
            this.finalReportArray[index].emailStatus === "P"
          ) {
            this.finalReportArray[index].emailStatus = "Not sent to airline";
          } else if (this.finalReportArray[index].emailStatus === "E") {
            this.finalReportArray[index].emailStatus = "Emails are not found";
          }
        }
        for (var i = 0; i < this.finalReportArray.length; i++) {
          var status = this.containsObject(
            this.finalReportArray[i],
            this.mergedReports
          );
          if (status) {
            var idx = this.mergedReports.findIndex(
              (c) => c.masterAwbNo == this.finalReportArray[i].masterAwbNo
            );
            this.mergedReports[idx].type = this.finalReportArray[i].reportType;
            this.mergedReports[idx].extrenalpath =
              this.finalReportArray[i].reportPath;
          } else {
            this.mergedReports.push(this.finalReportArray[i]);
          }
        }
        for (var i = 0; i < this.mergedReports.length; i++) {
          if (this.mergedReports[i].extrenalpath) {
            let epath1 = this.mergedReports[i].extrenalpath;
            let externalPath = epath1.split("/");
            this.mergedReports[i].externalFileName =
              this.ReportsProductionUrl +
              "externalUserEmailFiles/" +
              externalPath[1];
            this.mergedReports[i].internalFileName =
              this.ReportsProductionUrl + "emailFiles/" + externalPath[1];
          }
          if (this.mergedReports[i].reportPath) {
            let ipath1 = this.mergedReports[i].reportPath;
            let externalPath = ipath1.split("/");
            this.mergedReports[i].externalFileName =
              this.ReportsProductionUrl +
              "externalUserEmailFiles/" +
              externalPath[1];
            this.mergedReports[i].internalFileName =
              this.ReportsProductionUrl + "emailFiles/" + externalPath[1];
          }
        }
        if (this.mergedReports.length != 0) {
          for (let i = 0; i < this.mergedReports.length; i++) {
            let val = this.transformDate(this.mergedReports[i].incidentDate);
            this.mergedReports[i].incidentDate = val;
            let val2 = this.transformDate(this.mergedReports[i].flightDate);
            this.mergedReports[i].flightDate = val2;
          }
        }
        if (this.airlineDetails !== undefined && this.airlineDetails !== null) {
          for (let i = 0; i < this.airlineDetails.length; i++) {
            for (let j = 0; j < this.mergedReports.length; j++) {
              if (
                this.airlineDetails[i].masterAwbNo ===
                this.mergedReports[j].masterAwbNo
              ) {
                if (this.mergedReports[j].stationCodeStatus === undefined) {
                  if (
                    this.airlineDetails[i].stationCode ===
                    this.mergedReports[j].stationCode
                  ) {
                    this.mergedReports[j].stationCodeStatus =
                      this.airlineDetails[i].status;
                  }
                }

                if (this.mergedReports[j].originStationStatus === undefined) {
                  if (
                    this.airlineDetails[i].stationCode ===
                    this.mergedReports[j].originStation
                  ) {
                    this.mergedReports[j].originStationStatus =
                      this.airlineDetails[i].status;
                  }
                }

                if (this.mergedReports[j].destStationStatus === undefined) {
                  if (
                    this.airlineDetails[i].stationCode ===
                    this.mergedReports[j].destStation
                  ) {
                    this.mergedReports[j].destStationStatus =
                      this.airlineDetails[i].status;
                  }
                }
              }
            }
          }
        }
        this.finalReportsCount = data.finalReportsCount;
        this.incomingReportsCount = data.incomingReportsCount;
        this.internalReportCount = data.internalReportsCount;
        this.uldActiveReportsCount = data.uldActiveReportsCount;
        this.uldArchiveReportsCount = data.uldArchiveReportsCount;
        this.uldIncomingReportsCount = data.uldIncomingReportsCount;
        this.source.totalrecords = data.TotalRows;
        data.Rows = this.mergedReports;
        Active_Archive_ReportsTableComponent.totaldata = this.mergedReports;
        if (Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.length === 0) {
          Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA =
            data.userReports;
        } else {
          Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA =
            Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.concat(
              data.userReports
            );
        }
      } else if (
        data.responseCode === 0 &&
        data.message === "Unauthorized User"
      ) {
        this.toast.info("Your session is expired, please login again");
        this.spinner.hide();

        localStorage.clear();
        if (this.route.url === "/Home") {
          this.route.navigateByUrl("/");
        } else {
          this.route.navigateByUrl("/Home");
        }
      } else {
        Active_Archive_ReportsTableComponent.totaldata = [];
        Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
        data.Rows = [];
        this.source.totalrecords = 0;

        if (data.responseCode === 1) {
          this.finalReportsCount = data.finalReportsCount;
        } else {
          this.finalReportsCount = 0;
        }
      }
    },
    pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
      // callback called when a page or page size is changed.
      console.log(pagenum);
    },

    rendergridrows: function (params) {
      return params.data;
    },
  };
  linkrenderer = function (
    row,
    columnfield,
    value,
    defaulthtml,
    columnproperties,
    columndata) {
    console.log(
      row,
      columnfield,
      value,
      defaulthtml,
      columnproperties,
      columndata);
    
    let user = JSON.parse(
      localStorage.getItem("loginresultobject")
    );
    if (columnfield === 'internalFileName') {
      if (Active_Archive_ReportsTableComponent.totaldata[columndata.uid].reportOrgId === user.orgId) {
        var html =
        "<a  style='margin-left: 11px;' href='" +
        value +
        "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
        return html;
      } else {
        return "";
      }
    } else {
      var html =
      "<a  style='margin-left: 11px;' href='" +
      value +
      "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png' style='width: 24px;margin-left: 15px;'></a>";
    return html;
    }
  };

  dataAdapter = new jqx.dataAdapter(this.source, {
    //Set the http header before calling the api.
    beforeSend: (jqXHR, settings) => {
      let loginresultobject;
      let token;
      loginresultobject = JSON.parse(localStorage.getItem("loginresultobject"));
      this.comapanydetails = JSON.parse(
        localStorage.getItem("comapanydetails")
      );
      let orderType = JSON.parse(localStorage.getItem("ordType"));

      if (this.route.url.toString().includes("viewusers")) {
        if (this.comapanydetails === null) {
          this.comapanydetails = loginresultobject;
        }
        if (this.ViewReportsData.isfromSideBarViewReports === "yes") {
          this.orgIdValue = loginresultobject.orgId;
          this.ViewReportsData.isfromSideBarViewReports = "no";
        } else {
          this.orgIdValue = this.comapanydetails.orgId;
        }
        this.userTypeValue = loginresultobject.userType;
        let urlSplit = [];
        urlSplit = settings.url.split("loginUserId=");
        if (orderType === "A" || orderType === "R") {
          settings.url =
            this.config.getConfig().URL +
            "superAdminUserReports.php/getUserReportInfo?organisationId=" +
            this.orgIdValue +
            "&orderType=" +
            orderType +
            "&loginUserType=" +
            this.userTypeValue +
            "&loginUserId=" +
            urlSplit[1];
        } else if (orderType === "IN") {
          settings.url =
            this.config.getConfig().URL +
            "getInternalReportList.php/getInternalReportList?organisationId=" +
            this.orgIdValue +
            "&loginUserType=" +
            this.userTypeValue +
            "&loginUserId=" +
            urlSplit[1];
        } else {
          settings.url =
            this.config.getConfig().URL +
            "getLoginCclpPortal.php/getUserReportInfo?organisationId=" +
            this.orgIdValue +
            "&loginUserId=" +
            urlSplit[1];
        }
      }
      token = loginresultobject.token;
      jqXHR.setRequestHeader(
        "cache-control",
        "no-cache, no-store, must-revalidate,post-check=0, pre-check=0"
      ),
        jqXHR.setRequestHeader("pragma", "no-cache"),
        jqXHR.setRequestHeader("expires", "0"),
        jqXHR.setRequestHeader("authorization", token);
    },
  });

  public compDetails = JSON.parse(localStorage.getItem("comapanydetails"));
  public loginDetailsCopy = JSON.parse(
    localStorage.getItem("loginresultobject")
  );
  columns = [
    {
      text: "Carrier",
      datafield: "emailStatus",
      cellsalign: "center",
      width:
        this.compDetails === null
          ? this.loginDetailsCopy.orgType !== "A"
            ? 160
            : 160
          : this.compDetails.orgType !== "A"
          ? 200
          : 160,
      cellclassname: function (row, column, value, data) {
        this.loginDetails = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        this.comapanydetails = JSON.parse(
          localStorage.getItem("comapanydetails")
        );
        if (this.comapanydetails === null) {
          this.comapanydetails = JSON.parse(
            localStorage.getItem("loginresultobject")
          );
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
          if (this.isFrom === "SidebarAllUsers") {
            this.comapanydetails = JSON.parse(
              localStorage.getItem("loginresultobject")
            );
          }
        }
        if (
          data.emailStatus === "FinalTimer" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.reportAcess === "Y") ||
            (this.loginDetails.orgType === "H" &&
              this.comapanydetails.reportAcess === "Y") ||
            (this.loginDetails.orgType === "A" &&
              this.comapanydetails.reportAcess === "N") ||
            (this.loginDetails.orgType === "A" &&
              this.comapanydetails.reportAcess === "Y"))
        ) {
          return (data.emailStatus = "Not sent to airline");
        } else if (
          data.emailStatus == "Internal email sent" &&
          (this.loginDetails.orgType === "H" ||
            (this.loginDetails.orgType === "SA" &&
              this.comapanydetails.orgType === "H"))
        ) {
          if (
            data.emailStatus === "Internal email sent" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.reportAcess === "Y") ||
              (this.loginDetails.orgType === "H" &&
                this.comapanydetails.reportAcess === "Y"))
          ) {
            return (data.emailStatus = "sent to airline");
          } else {
            return (data.emailStatus = "sent to airline");
          }
        } else if (
          data.emailStatus === "Extrenal email sent to Airlines" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.orgType === "H") ||
            (this.loginDetails.userType === "S" &&
              this.comapanydetails.orgType === "H"))
        ) {
          if (
            data.emailStatus === "Extrenal email sent to Airlines" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.reportAcess === "Y") ||
              (this.loginDetails.orgType === "H" &&
                this.comapanydetails.reportAcess === "Y"))
          ) {
            return (data.emailStatus = "sent to airline");
          } else {
            return (data.emailStatus = "sent to airline");
          }
        } else if (
          data.emailStatus === "Extrenal email sent to Airlines" ||
          (data.emailStatus === "Internal email sent" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.orgType === "A") ||
              (this.loginDetails.userType === "S" &&
                this.comapanydetails.orgType === "A")))
        ) {
          return (data.emailStatus =
            "Received from " +
            Active_Archive_ReportsTableComponent.totaldata[data.uid].orgName +
            "</span>");
        } else if (
          data.emailStatus === "FinalTimer" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.reportAcess === "N") ||
            (this.loginDetails.orgType === "H" &&
              this.comapanydetails.reportAcess === "N"))
        ) {
          return (data.emailStatus = "Not sent to airline");
        } else if (data.emailStatus === "Cancelled") {
          return (data.emailStatus = "Not sent to airline");
        } else if (data.emailStatus === "Emails are not found") {
          return (data.emailStatus = "Emails not found");
        }
      },
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        let reportsTab = JSON.parse(localStorage.getItem("reportsTab"));
        let mailIconShow = 'display: inline';
        if (reportsTab === 'Trash') {
            mailIconShow = 'display: none';
        }
        this.loginDetails = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        this.comapanydetails = JSON.parse(
          localStorage.getItem("comapanydetails")
        );
        if (this.comapanydetails === null) {
          this.comapanydetails = JSON.parse(
            localStorage.getItem("loginresultobject")
          );
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
          if (this.isFrom === "SidebarAllUsers") {
            this.comapanydetails = JSON.parse(
              localStorage.getItem("loginresultobject")
            );
          }
        }

        if (
          Active_Archive_ReportsTableComponent.totaldata.length !== 0 &&
          columndata.uid < Active_Archive_ReportsTableComponent.totaldata.length
        ) {
          if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "Sent to airline" ||
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "Email sent"
          ) {
            if (
              this.comapanydetails.reportAcess === "Y" &&
              Active_Archive_ReportsTableComponent.currentReportTab !==
                "allreports"
            ) {
          // if (
          //   columndata.reportOrgId == Active_Archive_ReportsTableComponent.orgId
          // ) {
            return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span  style="${mailIconShow}"> <img id = 'resend:${columndata.uid}' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>`;
          // } else {
          //   return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>`;
          // }
            } else {
              return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>";
            }
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "Not sent to airline"
          ) {
            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>";
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "Emails are not found"
          ) {
            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger' aria-hidden='true'></i> Not found</div>";
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "A" ||
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "AS"
          ) {
            if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "A"
            ) {
              return `<div class='m-archive' ><i class='fa fa-archive' aria-hidden='true'></i> Manual archived</div>`;
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "AS"
            ) {
          // if (
          //   columndata.reportOrgId == Active_Archive_ReportsTableComponent.orgId
          // ) {
            return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span style="${mailIconShow}"> <img id = 'resend:${columndata.uid}' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>`;
          // } else {
          //   return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>`;
          // }
            }
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .emailStatus === "P"
          ) {
            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>";
          } else {
            if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "FinalTimer" &&
              ((this.loginDetails.orgType === "SA" &&
                this.comapanydetails.reportAcess === "Y") ||
                (this.loginDetails.orgType === "H" &&
                  this.comapanydetails.reportAcess === "Y"))
            ) {
              return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>";
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Internal email sent" &&
              (this.loginDetails.orgType === "H" ||
                (this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.orgType === "H") ||
                this.loginDetails.orgType === "A" ||
                (this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.orgType === "A"))
            ) {
              if (
                Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                  .emailStatus === "Internal email sent" &&
                ((this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.reportAcess === "Y") ||
                  (this.loginDetails.orgType === "H" &&
                    this.comapanydetails.reportAcess === "Y") ||
                  (this.loginDetails.orgType === "A" &&
                    this.comapanydetails.reportAcess === "Y"))
              ) {
                if (
                  columndata.reportOrgId == Active_Archive_ReportsTableComponent.orgId
                ) {
                  return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span> <span style="${mailIconShow}"> <img id = 'resend:${columndata.uid}' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report'/> </span>`;
                } else {
                  return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>`;
                }
              } else {
                return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>";
              }
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Extrenal email sent to Airlines" &&
              ((this.loginDetails.orgType === "SA" &&
                this.comapanydetails.orgType === "H") ||
                (this.loginDetails.userType === "S" &&
                  this.comapanydetails.orgType === "H") ||
                (this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.orgType === "A") ||
                (this.loginDetails.userType === "S" &&
                  this.comapanydetails.orgType === "A"))
            ) {
              if (
                Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                  .emailStatus === "Extrenal email sent to Airlines" &&
                ((this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.reportAcess === "Y") ||
                  (this.loginDetails.orgType === "H" &&
                    this.comapanydetails.reportAcess === "Y") ||
                  (this.loginDetails.orgType === "A" &&
                    this.comapanydetails.reportAcess === "Y"))
              ) {
                if (
                  columndata.reportOrgId == Active_Archive_ReportsTableComponent.orgId
                ) {
                  return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span  style="${mailIconShow}"> <img id = 'resend:${columndata.uid}' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report'/> </span>`;
                } else {
                  return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>`;
                }
              } else {
                return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>";
              }
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Extrenal email sent to Airlines" ||
              (Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Internal email sent" &&
                ((this.loginDetails.orgType === "SA" &&
                  this.comapanydetails.orgType === "A") ||
                  (this.loginDetails.userType === "S" &&
                    this.comapanydetails.orgType === "A")))
            ) {
              if (this.comapanydetails.reportAcess === "Y" && columndata.reportOrgId == Active_Archive_ReportsTableComponent.orgId) {
                return `<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span style="${mailIconShow}"> <img id = 'resend:${columndata.uid}' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report'/> </span>`;
              } else {
                return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>";
              }

              // return "<span style='padding: 2px;position: relative;top:  10px; border-radius: 5px;width: 141px;; margin-left: 10px;'>" + Active_Archive_ReportsTableComponent.totaldata[columndata.uid].orgName + "</span>";
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "FinalTimer" &&
              ((this.loginDetails.orgType === "SA" &&
                this.comapanydetails.reportAcess === "N") ||
                (this.loginDetails.orgType === "H" &&
                  this.comapanydetails.reportAcess === "N"))
            ) {
              return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>";
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Cancelled"
            ) {
              return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>";
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .emailStatus === "Emails are not found"
            ) {
              return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger' aria-hidden='true'></i> Not found</div>";
            }
          }
        }
      },
    },
    {
      text: "Incoming Reports From",
      datafield: "orgName",
      hidden: true,
      cellsalign: "center",
      width: 180,

      cellclassname: function (row, column, value, data) {
        this.loginDetails = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        this.comapanydetails = JSON.parse(
          localStorage.getItem("comapanydetails")
        );
        if (this.comapanydetails === null) {
          this.comapanydetails = JSON.parse(
            localStorage.getItem("loginresultobject")
          );
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
          if (this.isFrom === "SidebarAllUsers") {
            this.comapanydetails = JSON.parse(
              localStorage.getItem("loginresultobject")
            );
          }
        }
        if (
          data.emailStatus === "FinalTimer" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.reportAcess === "Y") ||
            (this.loginDetails.orgType === "H" &&
              this.comapanydetails.reportAcess === "Y"))
        ) {
          return (data.emailStatus = "Not sent to airline");
        } else if (
          data.emailStatus == "Internal email sent" &&
          (this.loginDetails.orgType === "H" ||
            (this.loginDetails.orgType === "SA" &&
              this.comapanydetails.orgType === "H"))
        ) {
          if (
            data.emailStatus === "Internal email sent" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.reportAcess === "Y") ||
              (this.loginDetails.orgType === "H" &&
                this.comapanydetails.reportAcess === "Y"))
          ) {
            return (data.emailStatus = "sent to airline");
          } else {
            return (data.emailStatus = "sent to airline");
          }
        } else if (
          data.emailStatus === "Extrenal email sent to Airlines" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.orgType === "H") ||
            (this.loginDetails.userType === "S" &&
              this.comapanydetails.orgType === "H"))
        ) {
          if (
            data.emailStatus === "Extrenal email sent to Airlines" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.reportAcess === "Y") ||
              (this.loginDetails.orgType === "H" &&
                this.comapanydetails.reportAcess === "Y"))
          ) {
            return (data.emailStatus = "sent to airline");
          } else {
            return (data.emailStatus = "sent to airline");
          }
        } else if (
          data.emailStatus === "Extrenal email sent to Airlines" ||
          (data.emailStatus === "Internal email sent" &&
            ((this.loginDetails.orgType === "SA" &&
              this.comapanydetails.orgType === "A") ||
              (this.loginDetails.userType === "S" &&
                this.comapanydetails.orgType === "A")))
        ) {
          return (data.emailStatus =
            "Received from " +
            Active_Archive_ReportsTableComponent.totaldata[data.uid].orgName +
            "</span>");
        } else if (
          data.emailStatus === "FinalTimer" &&
          ((this.loginDetails.orgType === "SA" &&
            this.comapanydetails.reportAcess === "N") ||
            (this.loginDetails.orgType === "H" &&
              this.comapanydetails.reportAcess === "N"))
        ) {
          return (data.emailStatus = "Not sent to airline");
        } else if (data.emailStatus === "Cancelled") {
          return (data.emailStatus = "Not sent to airline");
        } else if (data.emailStatus === "Emails are not found") {
          return (data.emailStatus = "Emails not found");
        }
      },
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        this.loginDetails = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        this.comapanydetails = JSON.parse(
          localStorage.getItem("comapanydetails")
        );
        if (this.comapanydetails === null) {
          this.comapanydetails = JSON.parse(
            localStorage.getItem("loginresultobject")
          );
        } else if (this.isFrom !== null || this.isFrom !== undefined) {
          if (this.isFrom === "SidebarAllUsers") {
            this.comapanydetails = JSON.parse(
              localStorage.getItem("loginresultobject")
            );
          }
        }

        if (
          Active_Archive_ReportsTableComponent.totaldata.length !== 0 &&
          columndata.uid < Active_Archive_ReportsTableComponent.totaldata.length
        ) {
          return (
            "<span style='padding: 2px;position: relative;top:  10px; border-radius: 5px;width: 141px;; margin-left: 10px;'>" +
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .orgName +
            "</span>"
          );
        }
      },
    },
    { text: "Station Code", datafield: "stationCode", width: 70 },
    {
      text: "Report Status",
      datafield: "damageReportType",
      width: 80,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        if (
          Active_Archive_ReportsTableComponent.totaldata.length != 0 &&
          columndata.uid < Active_Archive_ReportsTableComponent.totaldata.length
        ) {
          if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .damageReportType === "DRAFT" ||
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .damageReportType === "Short"
          ) {
            return "<div style='margin-top:  8.5px; margin-left: 5px;color:black; font-weight:bold'>DRAFT</div>";
          } else {
            return "<div style='margin-top:  8.5px; margin-left: 5px; color:black; font-weight:bold'>FINAL</div>";
          }
        }
      },
    },
    {
      text: "Actions",
      datafield: "actions",
      cellsalign: "center",
      width: 60,
      filterable: false,
      sortable: false,
      groupable: false,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        let user = JSON.parse(
          localStorage.getItem("loginresultobject")
        );
        console.log(
          columndata.reportOrgType,
          columndata.reportOrgId,
          user.orgId
        );
          if (user.orgType === 'A') {
            if (columndata.reportOrgId === user.orgId) {
              return (
                "<div style='margin-top:  8.5px; margin-left: 5px;'><a  href='edit:" +
                columndata.uid +
                "'><i id = 'edit:" +
                columndata.uid +
                "' class='fa fa-pencil-square-o' style='padding:0 15px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a></div>"
              );
            } else {
              return "<div style='margin-top:  8.5px; margin-left: 5px;'><i class='fa fa-pencil-square-o' style='padding:0 15px;font-size:18px;color: #e1e1e3'></i></div>";
            }
        } else {
          return (
            "<div style='margin-top:  8.5px; margin-left: 5px;'><a  href='edit:" +
            columndata.uid +
            "'><i id = 'edit:" +
            columndata.uid +
            "' class='fa fa-pencil-square-o' style='padding:0 15px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a></div>"
          );
        }
      },
    },
    {
      text: "Internal",
      datafield: "internalFileName",
      width: 70,
      filterable: false,
      sortable: false,
      groupable: false,
      cellsrenderer: this.linkrenderer,
    },
    {
      text: "External",
      datafield: "externalFileName",
      width: 70,
      filterable: false,
      sortable: false,
      groupable: false,
      cellsrenderer: this.linkrenderer,
    },
    { text: "Airline", datafield: "airlineName", width: 120 },
    { text: 'Calculated Risk', datafield: 'calculateRisk', width: 120 },
    { text: "Flight-Truck Number", datafield: "flightTruckNo", width: 120 },
    {
      text: "ORG",
      datafield: "originStation",
      width: 90,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        if (
          Active_Archive_ReportsTableComponent.totaldata.length !== 0 &&
          columndata.uid < Active_Archive_ReportsTableComponent.totaldata.length
        ) {
          if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .originStationStatus === "Y"
          ) {
            return (
              "<div style='margin-top: 8.5px; margin-left: 5px;'><span style='font-family: sans-serif;display: inline-block;width: 50px;'>" +
              value +
              "</span><img title='Emails Available'  src='../../../assets/images/emails-available.png' alt='Emails available' height='15' width='18'></div>"
            );
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .originStationStatus === "N"
          ) {
            return (
              "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" +
              value +
              "</span><img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' alt='Emails not available' height='18' width='18'></div>"
            );
          }
        }
      },
    },
    {
      text: "DES",
      datafield: "destStation",
      width: 90,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        if (
          Active_Archive_ReportsTableComponent.totaldata.length !== 0 &&
          columndata.uid < Active_Archive_ReportsTableComponent.totaldata.length
        ) {
          if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .destStationStatus === "Y"
          ) {
            return (
              "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" +
              value +
              "</span><img title='Emails Available'  src='../../../assets/images/emails-available.png' alt='Emails available' height='15' width='18'></div>"
            );
          } else if (
            Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
              .destStationStatus === "N"
          ) {
            return (
              "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>" +
              value +
              "</span><img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' alt='Emails not available' height='18' width='18'></div>"
            );
          }
        }
      },
    },
    { text: "status", datafield: "status", hidden: true },
    {
      text: "Reporting Date",
      datafield: "incidentDate",
      width: 100,
      cellsformat: "dd-MM-yyyy",
      filtertype: "date",
    },
    { text: "Master Airway Bill", datafield: "masterAwbNo", width: 150,
    cellsrenderer: function (
      row,
      columnfield,
      value,
      defaulthtml,
      columnproperties,
      columndata
    ) {
      if (
        (Active_Archive_ReportsTableComponent.totaldata[columndata.uid].prefixCarrierId === null ||
        Active_Archive_ReportsTableComponent.totaldata[columndata.uid].prefixCarrierId === '' ||
        Active_Archive_ReportsTableComponent.totaldata[columndata.uid].prefixCarrierId === undefined) &&
         (Active_Archive_ReportsTableComponent.totaldata[columndata.uid].carrierId === null ||
         Active_Archive_ReportsTableComponent.totaldata[columndata.uid].carrierId === '' ||
         Active_Archive_ReportsTableComponent.totaldata[columndata.uid].carrierId === undefined)
      ) {
        return (
          `<div title="No prefix Flight" style='margin-top: 8.5px;border-left: 8px solid #ffffff;'>&nbsp;
          ${value} </div>`
        );
      } else {
        if (Active_Archive_ReportsTableComponent.totaldata[columndata.uid].prefixCarrierId !== Active_Archive_ReportsTableComponent.totaldata[columndata.uid].carrierId) {
          return (
            `<div title="Defferent prefix Flight" style='margin-top: 8.5px;border-left: 8px solid green;'>&nbsp;
            ${value}</div>`
          );
        } else {
          return (
            `<div title="Same prefix Flight" style='margin-top: 8.5px;border-left: 8px solid #34495E;'>&nbsp;
            ${value}</div>`
          );
        }
      }
    }
    },
    {
      text: "Responsibility",
      datafield: "reportStatus", width: 150,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        columndata
      ) {
        if (
          Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
            .reportStatus === "internal"
        ) {
          if (Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
            .rootcauseExists === 'Y') {
            if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .rootcausestatus === 'Y'
            ) {
              if(value === 'internal') {
                return (
                  `<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;&nbsp;<img title="Rootcause shared" style="background-color: #34495E;border: 2px solid #34495E;border-radius: 50%; margin-left: 10px;display: inline-block;" src="../../../assets/images/flight.png" width="20px" height="20px"></div>`
                );
              }
            } else if (
              Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
                .rootcausestatus === 'N'
            ) {
              if(value === 'internal') {
                return (
                  `<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;&nbsp;<img title="Rootcause not shared" style="background-color: red;border: 2px solid red;border-radius: 50%; margin-left: 10px;display: inline-block;" src="../../../assets/images/flight.png" width="20px" height="20px"></div>`
                );
              }
            }
          } else {
            return (
              `<div style='margin-top: 8.5px; margin-left: 5px;'>Internal &nbsp;&nbsp;<img title="Rootcause not available" style="background-color: #D7DBDD;border: 2px solid #D7DBDD;border-radius: 50%; margin-left: 10px;display: inline-block;" src="../../../assets/images/flight.png" width="20px" height="20px"></div>`
            );
          }
        } else if(Active_Archive_ReportsTableComponent.totaldata[columndata.uid]
          .reportStatus === 'external') {
          return (
            "<div style='margin-top: 8.5px; margin-left: 7px;'>External</div>"
          );
        }
      },
    },
    { text: "Safety", datafield: "safetyLife", width: 70 },
    {
      text: "Modified Date",
      datafield: "modifiedDate",
      width: 90,
      cellsformat: "dd-MM-yyyy",
      filtertype: "date",
    },
    { text: "Modified Time", datafield: "modifiedTime", width: 90 },
    { text: "Created Date", datafield: "reportCreatedTime", width: 150 },

    { text: "Transport Type", datafield: "truckType", width: 90 },
    { text: "Report Prepared By", datafield: "reportPreparedName", width: 150 },
    { text: "Shipper Name", datafield: "shipper", width: 120 },
    { text: "Consignee Name", datafield: "consignee", width: 120 },
    { text: "Issuing Agent", datafield: "issuingAgent", width: 120 },
    { text: "Job Title", datafield: "jobTitle", width: 120 },
    {
      text: "Date",
      datafield: "flightDate",
      width: 120,
      cellsformat: "dd-MM-yyyy",
      filtertype: "date",
    },
    { text: "Content Nature", datafield: "contentNature", width: 150 },

    { text: "Damage to Packing", datafield: "damgagePacking", width: 150 },
    { text: "Content Affected", datafield: "contentAffected", width: 120 },

    { text: "Classification", datafield: "classificationType", width: 120 },
    { text: "Handling Stage", datafield: "damageDiscovered", width: 120 },
    {
      text: "Caused By/Discovered By",
      datafield: "discoveresCaused",
      width: 200,
    },

    { text: "Report Id", datafield: "reportId", width: 200, hidden: true },
    { text: "user Id", datafield: "userId", width: 200, hidden: true },
    { text: "first Name", datafield: "firstName", width: 200, hidden: true },
  ];
  rendergridrows = (params: any): any => {
    return params.data;
  };

  Rowdoubleclick(event): void {
    this.rowDouble_ClickEvent.emit(event);
  }

  getDtotalData(): ReportModelClass[] {
    return this.removeDuplicates(
      Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA
    );
  }

  removeDuplicates(array: ReportModelClass[]): ReportModelClass[] {
    let uniq = {};
    return array.filter(
      (obj) => !uniq[obj.reportId] && (uniq[obj.reportId] = true)
    );
  }

  _onSelectRow(event): void {
    // trigger when checkbox checked / unchecked
    console.log(event);

    this.onRowSelectionChange.emit(event);
  }

  transformDate(date: string): string {
    return date.split("-").reverse().join("-");
  }

  containsObject(obj: ReportModelClass, list: ReportModelClass[]) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].masterAwbNo === obj.masterAwbNo) {
        return true;
      }
    }
    return false;
  }
}


export class ReportModelClass {
public actualReceivedPcs: string;
public adminAuth:  string;
public affectedAwb: string;
public airlineName: string;
public areaSupervisor: string;
public autoSendEmail: string;
public casueOfEvent: string;
public categoryId1: number;
public categoryId2: number;
public categoryId3: number;
public categoryId4: number;
public categoryId5: number;
public categoryId6: number;
public classificationType: string;
public comodityNote: string;
public conditionContent: string;
public consignee: string;
public contentAffected: string;
public contentNature: string;
public contentPieces: string;
public contentWeight: string;
public damageDiscovered: string;
public damageImage: string;
public damageReportType: string;
public damgagePacking: string;
public destAirportName: string;
public destStation: string;
public discoveresCaused: string;
public emailStatus:string;
public exactLocation: string;
public extrenalpath: string;
public finalTimer: string;
public flightDate: string;
public flightDetails: string;
public flightTruckNo: string;
public holdTime: string;
public identificationNo:string;
public incidentDate: string;
public incidentTime: string;
public internalArea: string;
public internalCode: string;
public internalGroup: string;
public internalName: string;
public issuingAgent: string;
public itemMissing: string;
public itemmPcs: string;
public itemmWeight: string;
public jobTitle:string;
public locationId: string;
public masterAwbNo: string;
public mawbTotalPcs: string;
public mawbTotalWeight: string;
public methodPacking: string;
public modifiedDate:string;
public modifiedTime: string;
public movementType: string;
public orgName: string;
public originAirportName: string;
public originStation: string;
public recuperationReq: string;
public recuperationStatus: string;
public reportAcess: string;
public reportCreatedTime: string;
public reportId: number;
public reportPath: string;
public reportPreparedFunction:string;
public reportPreparedName:string;
public reportStatus: string;
public reportType: string;
public safetyLife: string;
public schedulingTime: string;
public shipper:string;
public startTime: string;
public stationCode: string;
public superAdminAuth: string;
public suspectPilf: string;
public truckType:string;
public type: string;
public userId: number;
        
}