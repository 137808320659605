import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DamageReportsService } from "../services/damageReports/damage-reports.service";
import { globalService } from "../services/global.service";
import { AmountCountComponent } from "./amount-count/amount-count.component";
import { ClaimActivityComponent } from './claim-activity/claim-activity.component';
import { ClaimAspectsComponent } from "./claim-aspects/claim-aspects.component";
import { ClaimCountComponent } from "./claim-count/claim-count.component";
import { CompareCliamTypeComponent } from './compare-cliam-type/compare-cliam-type.component';
import { InvestigationCountComponent } from "./investigation-count/investigation-count.component";
import { IrregularCountComponent } from "./irregular-count/irregular-count.component";
import { ServiceLevelComponent } from './service-level/service-level.component';
@Component({
  selector: "app-g-dashboard",
  templateUrl: "./g-dashboard.component.html",
  styleUrls: ["./g-dashboard.component.css"],
  providers: [
    IrregularCountComponent,
    InvestigationCountComponent,
    ClaimCountComponent,
    AmountCountComponent,
    ClaimAspectsComponent,
    ServiceLevelComponent,
    ClaimActivityComponent,
    CompareCliamTypeComponent
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GDashboardComponent implements OnInit {
  loginDetails: any;
  fromDate: any;
  toDate: any;
  tempToDate: any;
  picker: any;
  picker1: any;
  showquerterly: boolean;
  showdate: boolean;
  selectedYear: any = "Pick Year";
  tempSelectedYear: any = "Pick Year";
  tempFromDate: any;
  fromDatePicker: any;
  matSelectedTypes: any;
  selectedMonth: any;
  QuarterlyValuesArray: any[];
  currentYear: number;
  minDate = new Date();
  maxDate = new Date();
  maxDate1 = new Date();
  startmaxDate: Date;
  getdata: any[];
  station: string[];
  dataOne: string[];
  dataTwo: string[];
  ClaimAmount: string[];
  PaidAmount: string[];
  ClaimAmountData: number[];
  PaidAmountData: number[];
  options;
  optionss;
  data:any;
  dataDest;
  IrregularityCount: {
    irregularityOrigin: { count: number[]; labels: string[] };
    topIrregularityOrigin: { count: number[]; labels: string[] };
    irregularityDestn: { count: number[]; labels: string[] };
    topIrregularityDestn: { count: number[]; labels: string[] };
  };
  OriginCount: number[];
  Irregularitystation: string[];
  DestCount: number[];
  Irregularitydeststation: string[];
  Irregularityoriginstation: string[];
  InvestigationCount: {
    investigationOrigin: { count: number[]; labels: string[] };
    topInvestigationOrigin: { count: number[]; labels: string[] };
    investigationDestn: { count: number[]; labels: string[] };
    topInvestigationDestn: { count: number[]; labels: string[] };
  };
  Investigationoriginstation: any;
  Investigationdeststation: any;
  investigationDestCount: any;
  investOriginCount: any;
  investDestCount: number[];
  claimsCount: {
    originAirportCliams: { stations: string[]; count: number[] };
    topOriginAirportCliams: { stations: string[]; count: number[] };
    destAirportCliams: { stations: string[]; count: number[] };
    topDestAirportCliams: { stations: string[]; count: number[] };
  };
  claimpaidAmounts: any;
  originstation: any;
  Originclaimamount: any;
  Originpaidamount: any;
  deststation: any;
  destclaimamount: any;
  destpaidamount: any;
  originclaimstation: string[];
  originclaimCount: number[];
  destclaimstation: string[];
  destclaimCount: number[];
  lrregularcount: boolean;
  investigationcount: boolean;
  claimcount: boolean;
  amountcount: boolean;
  tabChanges: any;
  irregular: string;
  investigation: string;
  claim: string;
  amount: string;
  damage: string;
  damgereport: boolean;
  claimchart: any;
  showdamgesTab: boolean;
  showiregular: boolean;
  showinvestigation: boolean;
  showclaim: boolean;
  showamount: boolean;
  tabIndex: any;
  claim_aspects: any;
  showclaim_aspects: boolean;
  claimaspects: boolean;
  totalCount: any;
  seslectedCount: any;
  selectedCount: any;
  damageReports: string;
  cargoClaim: string;
  selectedTab: any;
  showamountbtn: boolean;
  showservicebtn:boolean;
  showactivitybtn:boolean;
  showclaim_aspectsbtn: boolean;
  showiregularbtn: boolean;
  showclaimbtn: boolean;
  service: string;
  claimactivity: string;
  showservice: boolean;
  showclaimactivity: boolean;
  serviceLevel: boolean;
  claimActvty :boolean;
  showCmprCharts: boolean;
  showCmprDates: boolean;
  selectedYearOne: any;
  selectedYearTwo: string;
  tempToSelectedYear: any = 'Pick to Year';
  tempFromSelectedYear: any = 'Pick from Year';
  showservicebtns: boolean;
  showclaim_aspectsbtns: boolean;
  showamountbtns: boolean;
  showclaimbtns: boolean;
  showiregularbtns: boolean;
  showactivitybtns: boolean;
  Homesubscribe: any;
  orgId: any;

  constructor(
    private atp: AmazingTimePickerService,
    private AmountCountComponent: AmountCountComponent,
    private ServiceLevelComponent: ServiceLevelComponent,
    private ClaimAspectsComponent: ClaimAspectsComponent,
    private IrregularCountComponent: IrregularCountComponent,
    private InvestigationCountComponent: InvestigationCountComponent,
    private ClaimCountComponent: ClaimCountComponent,
    private ClaimActivityComponent: ClaimActivityComponent,
    private CompareCliamTypeComponent:CompareCliamTypeComponent,
    private datePipe: DatePipe,
    private router: Router,
    private damageReport_Service: DamageReportsService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private globalService:globalService,
    private dialog: MatDialog
  ) {}
  //date picker method
  open() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {});
  }
// Open comparing charts year picker
  OpenCompareDates(){
    this.showCmprDates = true;
    this.showclaimbtns=false;
    this.showiregularbtns =false;
    this.showclaim_aspectsbtns=false;
    this.showamountbtns=false;
    this.showservicebtns = false;
    this.showactivitybtns = false;
  }
 // cancel comparing charts year picker
  closeCompareChartsDates(){
    this.showclaimbtns=true;
    this.showiregularbtns =true;
    this.showclaim_aspectsbtns=true;
    this.showamountbtns=true;
    this.showservicebtns = true;
    this.showactivitybtns = true;
    this.tempFromSelectedYear = 'Pick from Year';
    this.tempToSelectedYear='Pick to Year';
    this.showCmprDates = false;
    this.showCmprCharts = false;

    this.tabIndex = JSON.parse(localStorage.getItem("tabIndex"));
          if (this.tabIndex === "1") {
            this.tabChange("irregular");
          } else if (this.tabIndex === "2") {
            this.tabChange("investigation");
          } else if (this.tabIndex === "3") {
            this.tabChange("claim");
          } else if (this.tabIndex === "4") {
            this.tabChange("amount");
          } else if (this.tabIndex === "6") {
            this.tabChange("claim_aspects");
          } else if (this.tabIndex === "8") {
            this.tabChange("service");
          } else if (this.tabIndex === "9") {
            this.tabChange("claimactivity");
          }
  }

  // GotoAWBclaims(){
  //   ;
  //    this.router.navigateByUrl("/awb-claims");
  // }
  Gotoclaims(value){
    ;
    if (value ==='claims') {
      this.router.navigateByUrl("/cargoClaim/dashboard/claim-count");
    }else if(value ==='awbs') {
      this.router.navigateByUrl("/cargoClaim/awb-claims");
    }
  }
    ngOnInit() {
      this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
      this.spinner.hide();
      this.matSelectedTypes = 'Yearly';
    this.showCmprDates = false;
    this.showCmprCharts = false;
    this.showclaimbtns=true;
    this.showclaimbtn =true;
    this.cargoClaim='dcharthighlight';
     this.globalService.setDashBoardState('claims');
    localStorage.setItem("activation", JSON.stringify("1"));
    this.fromDate = "";
    this.tempFromDate = "";
    this.toDate = "";
    this.tempToDate = "";
    this.selectedYear = this.datePipe.transform(new Date(), "yyyy");
    this.selectedYearOne = this.datePipe.transform(new Date(), "yyyy");
    this.selectedYearTwo = this.datePipe.transform(new Date(), "yyyy");
    this.tempSelectedYear = this.selectedYear;
    this.lrregularcount = true;
    localStorage.setItem("tabIndex", JSON.stringify("1"));
      this.showdamgesTab = false;
      this.showiregular = true;
      this.showinvestigation = true;
      this.showclaim = true;
      this.showamount = true;
      this.showclaim_aspects = true;
      this.showservice = true;
      this.showclaimactivity= true;
      //for tabs colour change methods
      this.tabChange("claim");
      this.activecall("3");
      this.router.navigateByUrl("/cargoClaim/dashboard/irregular-count");
      const currentDate = new Date();
      if (this.tempSelectedYear < currentDate.getFullYear()) {
        this.minDate = new Date(this.tempSelectedYear, 0, 1);
        this.startmaxDate = new Date(this.tempSelectedYear, 11, 31);
      } else {
        this.minDate = new Date(this.tempSelectedYear, 0, 1);
        this.startmaxDate = new Date(
          this.tempSelectedYear,
          currentDate.getMonth(),
          currentDate.getDate()
        );
      }
      this.getHomesubscriber();
    this.spinner.show();
    //getting login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }else{
      this.orgId =''
    }
    this.fromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    //input Object
    let ObjectOne = {
      org_id:this.orgId,
      year: this.tempSelectedYear,
      fromDate: null,
      toDate: null,
    };
    //reports count Api
    this.damageReport_Service.ReportsCount(ObjectOne).subscribe(
      data => {
        if (data.responseCode === 1 ) {
          this.selectedCount = data["count"];
          this.spinner.hide();
        }else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.router.navigateByUrl("/");
        }
      
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  //get method
  getHomesubscriber() {
    this.Homesubscribe = this.globalService.getHomeState().subscribe(res => {
      this.tabChange("claim");
      this.activecall("3");
    });
  }
  //To go to cargo claims dashboard
  // Gotoclaims(value) {
  //   ;
  //   localStorage.setItem("claimchart", JSON.stringify(value));
  //   if (value === "claims") {
  //     this.globalService.setDashBoardState('claims');
  //     localStorage.setItem("tabIndex", JSON.stringify("1"));
  //     this.showdamgesTab = false;
  //     this.showiregular = true;
  //     this.showinvestigation = true;
  //     this.showclaim = true;
  //     this.showamount = true;
  //     this.showclaim_aspects = true;
  //     this.showclaimbtns=true;
  //     this.showiregularbtns =false;
  //     this.showclaim_aspectsbtns=false;
  //     this.showamountbtns=false;
  //     this.showservicebtns = false;
  //     this.showactivitybtns = false;
  //     this.showservice = true;
  //     this.showclaimactivity= true;
  //     this.tabChange("claim");
  //     this.activecall("3");
  //     this.cargoClaim='dcharthighlight'
  //     this.damageReports = "sidenavdefault";
  //     this.router.navigateByUrl("/dashboard/claim-count");
  //   }
  //   //To go to damage reports
  // else if (value === "awbs") {
  //   this.globalService.setDashBoardState('awbs');
  //    localStorage.setItem("activation", JSON.stringify("1"));
  //   this.showdamgesTab = false;
  //   this.showiregular = false;
  //   this.showinvestigation = false;
  //   this.showclaim = false;
  //   this.showamount = false;
  //   this.showclaim_aspects = false;
  //   this.showclaimbtns=false;
  //   this.showiregularbtns =false;
  //   this.showclaim_aspectsbtns=false;
  //   this.showamountbtns=false;
  //   this.showservicebtns=false;
  //   this.showactivitybtns = false;
  //   this.damage = "sidenavhighlight";
  //   this.damageReports='dcharthighlight'
  //   this.cargoClaim='sidenavdefault',
  //   this.showservice = false;
  //   this.showclaimactivity= false;
  //   this.showdate=false
  //   this.showquerterly=false
  //   this.router.navigateByUrl("/dashboard/awb-claims");
  // }
  // }
  //To clear date picker
  startYearClosed() {
    this.tempToDate = "";
  }
  //To clear date picker
  closeDatePicker(year, elem: any,type) {
    if (type === 'to') {
      this.tempToSelectedYear = Number(year.getFullYear());
    }else if(type === 'from'){
      this.tempFromSelectedYear = Number(year.getFullYear());
    }else {
      this.tempSelectedYear = Number(year.getFullYear());
    }
    this.tempFromDate = "";
    this.tempToDate = "";

    //  this.tempSelectedYear = Number(year.getFullYear());
    const currentDate = new Date();

    if (this.tempSelectedYear < currentDate.getFullYear()) {
      this.minDate = new Date(this.tempSelectedYear, 0, 1);
      this.startmaxDate = new Date(this.tempSelectedYear, 11, 31);
    } else {
      this.minDate = new Date(this.tempSelectedYear, 0, 1);
      this.startmaxDate = new Date(
        this.tempSelectedYear,
        currentDate.getMonth(),
        currentDate.getDate()
      );
    }
    this.showquerterly = false;
    this.showdate = false;
    this.matSelectedTypes = 'Yearly';
    //this.matSelectedTypes = null;
    elem.close();
  }
//Comparing charts button
  OpenCompareCharts(){
     if(this.tempFromSelectedYear === null || this.tempFromSelectedYear  === "" || this.tempFromSelectedYear === undefined || this.tempFromSelectedYear === "Pick from Year" || this.tempFromSelectedYear === "Pick Year") {
      this.toaster.error("please select from year");
     }
     else if(this.tempToSelectedYear === null || this.tempToSelectedYear === ""  || this.tempToSelectedYear === undefined || this.tempToSelectedYear === "Pick to Year" || this.tempToSelectedYear === "Pick Year") {
      this.toaster.error("please select to year");
     }else if (this.tempFromSelectedYear === this.tempToSelectedYear) {
      this.toaster.error("cannot select same year");
     }
     else{
      let Object = {
        org_id: this.loginDetails.orgId,
        yearOne:this.tempFromSelectedYear,
        yearTwo:this.tempToSelectedYear,
       fromDate: null,
       toDate: null,
       yearTwoFromDate:null,
       YearTwoToDate:null,
       };
       localStorage.setItem("inputObj", JSON.stringify(Object));
      this.tabIndex = JSON.parse(localStorage.getItem("tabIndex"));
      if (this.tabIndex === "1") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-RootCause"]));
        // this.router.navigateByUrl('/dashboard/compare-RootCause');
      } else if (this.tabIndex === "2") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-RootCause"]));
        // this.router.navigateByUrl('/dashboard/compare-RootCause');
      } else if (this.tabIndex === "3") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-claimType"]));
        // this.router.navigate(["/dashboard/compare-claimType"])
      } else if (this.tabIndex === "4") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-Financials"]));
        // this.router.navigateByUrl('/dashboard/compare-Financials');
      } else if (this.tabIndex === "6") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-claimAspect"]));
        // this.router.navigateByUrl('/dashboard/compare-claimAspect');
      } else if (this.tabIndex === "8") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-ServiceLevel"]));
        // this.router.navigateByUrl('/dashboard/compare-ServiceLevel');
      } else if (this.tabIndex === "9") {
        this.router.navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/compare-ClaimActivity"]));
        // this.router.navigateByUrl('/dashboard/compare-ClaimActivity');
      }
    }
  }
  //To get submit dates and quaterly method
  onClickSubmit() {
    this.selectedYear = this.tempSelectedYear;
    this.fromDate = this.datePipe.transform(this.tempFromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.tempToDate, "yyyy-MM-dd");
    let Object = {
      org_id: this.loginDetails.orgId,
      year: this.selectedYear,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    if (
      this.selectedYear === null ||
      this.selectedYear === "" ||
      this.selectedYear === undefined ||
      this.selectedYear === "Pick Year"
    ) {
      this.toaster.error("please select to year");
    } else {
      if (this.matSelectedTypes != null && this.matSelectedTypes === "Dates") {
        if (
          this.fromDate === null ||
          this.fromDate === "" ||
          this.fromDate === undefined
        ) {
          this.toaster.error("please select start date");
          return;
        } else if (
          this.toDate === null ||
          this.toDate === "" ||
          this.toDate === undefined
        ) {
          this.toaster.error("please select end date");
          return;
        } else {
          this.tabIndex = JSON.parse(localStorage.getItem("tabIndex"));
          if (this.tabIndex === "1") {
            this.tabChange("irregular");
          } else if (this.tabIndex === "2") {
            this.tabChange("investigation");
          } else if (this.tabIndex === "3") {
            this.tabChange("claim");
          } else if (this.tabIndex === "4") {
            this.tabChange("amount");
          } else if (this.tabIndex === "6") {
            this.tabChange("claim_aspects");
          } else if (this.tabIndex === "8") {
            this.tabChange("service");
          } else if (this.tabIndex === "9") {
            this.tabChange("claimactivity");
          }
        }
      }
      this.tabIndex = JSON.parse(localStorage.getItem("tabIndex"));
      if (this.tabIndex === "1") {
        this.tabChange("irregular");
      } else if (this.tabIndex === "2") {
        this.tabChange("investigation");
      } else if (this.tabIndex === "3") {
        this.tabChange("claim");
      } else if (this.tabIndex === "4") {
        this.tabChange("amount");
      } else if (this.tabIndex === "6") {
        this.tabChange("claim_aspects");
      } else if (this.tabIndex === "8") {
        this.tabChange("service");
      } else if (this.tabIndex === "9") {
        this.tabChange("claimactivity");
      }
    }
    //count Api with inputs
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }else{
      this.orgId =''
    }
    let ObjectOne = {
      org_id: this.orgId,
      year: this.selectedYear,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    this.damageReport_Service.ReportsCount(ObjectOne).subscribe(
      (data) => {
        if (data.responseCode === 1 ) {
        this.selectedCount = data["count"];
        this.spinner.hide();
        }
        else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toaster.warning("your session expired please login again");
          this.router.navigateByUrl("/");
        }

      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  //To select querterly method
  onSelectionChange(val) {
    this.tempFromDate = "";
    this.tempToDate = "";
    this.QuarterlyValuesArray = [];
    if (
      this.tempSelectedYear != null &&
      this.tempSelectedYear != "" &&
      this.tempSelectedYear != undefined &&
      this.tempSelectedYear != "Pick Year"
    ) {
      if (val === "Quarterly") {
        this.showquerterly = true;
        this.showdate = false;
        this.QuarterlyValuesArray = [];
        let date = new Date();
        this.currentYear = date.getFullYear();
        this.selectedMonth = date.getMonth() + 1;
        if (this.tempSelectedYear.toString() === this.currentYear.toString()) {
          if (this.selectedMonth > "00" && this.selectedMonth <= "03") {
            this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: true });
            this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
            this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
          } else if (this.selectedMonth > "03" && this.selectedMonth <= "06") {
            this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: true });
            this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
          } else if (this.selectedMonth > "06" && this.selectedMonth <= "09") {
            this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: true });
          } else if (this.selectedMonth > "09" && this.selectedMonth <= "12") {
            this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
            this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: false });
          }
        } else if (this.tempSelectedYear < this.currentYear) {
          this.QuarterlyValuesArray.push({ val: "Q1", isDisabled: false });
          this.QuarterlyValuesArray.push({ val: "Q2", isDisabled: false });
          this.QuarterlyValuesArray.push({ val: "Q3", isDisabled: false });
          this.QuarterlyValuesArray.push({ val: "Q4", isDisabled: false });
        }
      } else if (val === "Dates") {
        this.showquerterly = false;
        this.showdate = true;
      }
      else if (val === "Yearly") {
        this.showquerterly = false;
        this.showdate = false;
      }
    } else {
      this.matSelectedTypes = null;
      this.toaster.error("please select Year");
    }
  }
  SELECTED_QuarterValuesArray = [];
  onSelectCheckBoxes(value) {
    if (value === "Q1") {
      this.tempToDate = new Date("03-31-" + this.tempSelectedYear);
      this.tempFromDate = new Date("01-01-" + this.tempSelectedYear);
    } else if (value === "Q2") {
      this.tempFromDate = new Date("04-01-" + this.tempSelectedYear);
      this.tempToDate = new Date("06-30-" + this.tempSelectedYear);
    } else if (value === "Q3") {
      this.tempFromDate = new Date("07-01-" + this.tempSelectedYear);
      this.tempToDate = new Date("09-30-" + this.tempSelectedYear);
    } else if (value === "Q4") {
      this.tempFromDate = new Date("10-01-" + this.tempSelectedYear);
      this.tempToDate = new Date("12-31-" + this.tempSelectedYear);
    }
  }
  //tab changing method
  tabChange(data) {
    this.tempFromSelectedYear = 'Pick from Year';
    this.tempToSelectedYear='Pick to Year';
    localStorage.setItem("selectedTab", JSON.stringify(data));
    if (!Number(this.selectedYear)) {
      return this.toaster.error("please select year");
    }
    
    this.loginDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }else{
      this.orgId =''
    }
    this.fromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    let Object = {
      org_id:  this.orgId,
      year: this.selectedYear,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    localStorage.setItem("inputObj", JSON.stringify(Object));
    if (data == "irregular") {
     this.showCmprDates = false;
      this.IrregularCountComponent.ngOnInit();
      this.lrregularcount = true;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =true;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=false;
      this.showiregularbtn =true;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn = false;
      
      // this.router.navigateByUrl('/dashboard/irregular-count');
      this.router
        .navigateByUrl("/cargoClaim/dashboard/investigation-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/irregular-count"]));
    } else if (data == "investigation") {
      this.showCmprDates = false;
      this.InvestigationCountComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = true;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =true;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=false;
      this.showiregularbtn =true;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn = false;
      //this.router.navigateByUrl('/dashboard/investigation-count');
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/investigation-count"]));
    } else if (data == "claim") {
      this.showCmprDates = false;
      this.ClaimCountComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = true;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=true;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=true;
      this.showiregularbtn =false;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn = false;
      //this.router.navigateByUrl('/dashboard/claim-count');
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/claim-count"]));
    } else if (data == "amount") {
      this.showCmprDates = false;
      this.AmountCountComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = true;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=true;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=false;
      this.showiregularbtn =false;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=true;
      this.showservicebtn=false;
      this.showactivitybtn= false;
      // this.router.navigateByUrl('/dashboard/amount-count');
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/amount-count"]));
    } else if (data == "damage") {
      this.showCmprDates = false;
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = true;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=false;
      this.showiregularbtn =false;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn= false;
    }
     else if (data == "claim_aspects") {
      this.showCmprDates = false;
      this.ClaimAspectsComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = true;
      this.serviceLevel = false;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=true;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;

      this.showclaimbtn=false;
      this.showiregularbtn =false;
      this.showclaim_aspectsbtn=true;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn = false;
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/claim_aspects"]));
    }
    else if (data == "service") {
      this.showCmprDates = false;
      this.ServiceLevelComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = true;
      this.claimActvty= false
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=true;
      this.showactivitybtns= false;

      this.showclaimbtn=false;
      this.showiregularbtn =false;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=true;
      this.showactivitybtn= false;
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/service-level"]));
      // this.router.navigateByUrl('/dashboard/service-level');
    }
    else if (data == "claimactivity") {
      this.showCmprDates = false;
      this.ClaimActivityComponent.ngOnInit();
      this.lrregularcount = false;
      this.investigationcount = false;
      this.claimcount = false;
      this.amountcount = false;
      this.damgereport = false;
      this.claimaspects = false;
      this.serviceLevel = false;
      this.claimActvty= true;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = true;

      this.showclaimbtn=false;
      this.showiregularbtn=false;
      this.showclaim_aspectsbtn=false;
      this.showamountbtn=false;
      this.showservicebtn=false;
      this.showactivitybtn = true;
      this.router
        .navigateByUrl("/cargoClaim/dashboard/irregular-count", {
          skipLocationChange: true,
        })
        .then(() => this.router.navigate(["/cargoClaim/dashboard/claim-activity"]));

     
     
    }
  }
  //tabs active colour  method
  activecall(val) {
    if (!Number(this.selectedYear)) {
      return;
    }
    localStorage.setItem("tabIndex", JSON.stringify(val));
    if (val === "1") {
      this.irregular = "sidenavhighlight";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    } else if (val === "2") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavhighlight";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    } else if (val === "3") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavhighlight";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    } else if (val === "4") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavhighlight";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    } else if (val === "5") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavhighlight";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    } else if (val === "6") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavhighlight";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavdefault";
    }
    else if (val === "8") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavhighlight"
      this.claimactivity = "sidenavdefault";
    }
    else if (val === "9") {
      this.irregular = "sidenavdefault";
      this.investigation = "sidenavdefault";
      this.claim = "sidenavdefault";
      this.amount = "sidenavdefault";
      this.damage = "sidenavdefault";
      this.claim_aspects = "sidenavdefault";
      this.service = "sidenavdefault"
      this.claimactivity = "sidenavhighlight";

    }
  }
  //downloading charts method
  onClickDownload(){
    this.selectedTab = JSON.parse(localStorage.getItem("selectedTab"));
    if ( this.selectedTab  === 'irregular') {
      this.showiregularbtns =true;
      this.showclaimbtns=false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
      this.IrregularCountComponent.onClickDownloadTabCharts();
       //this.router.navigateByUrl('/dashboard/irregular-count');
    }else if (this.selectedTab == "investigation") {
      this.showiregularbtns =true;
      this.showclaimbtns=false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
      this.InvestigationCountComponent.onClickDownloadTabCharts();
      //this.router.navigateByUrl('/dashboard/investigation-count');
    } else if (this.selectedTab == "claim") {
      this.showclaimbtns=true;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
      this.ClaimCountComponent.onClickDownloadTabCharts();
      //this.router.navigateByUrl('/dashboard/claim-count');
    } else if (this.selectedTab == "amount") {
      this.showamountbtns = true;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
      this.AmountCountComponent.onClickDownloadTabCharts();
       //this.router.navigateByUrl('/dashboard/amount-count');
    } else if (this.selectedTab == "damage") {
      this.showamountbtns = false;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showclaim_aspectsbtns=false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
    } else if (this.selectedTab == "claim_aspects") {
      this.showclaim_aspectsbtns=true;
      this.showamountbtns = false;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showservicebtns=false;
      this.showactivitybtns = false;
      this.ClaimAspectsComponent.onClickDownloadTabCharts();
      //this.router.navigateByUrl('/dashboard/claim_aspects');
    
    }
    else if (this.selectedTab == "service") {
      this.showservicebtns = true
      this.showclaim_aspectsbtns=false;
      this.showamountbtns = false;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showactivitybtns = false;
      this.ServiceLevelComponent.onClickDownloadTabCharts();
      //this.router.navigateByUrl('/dashboard/claim_aspects');
    
    } else if (this.selectedTab == "claimactivity") {
      this.showservicebtns = false;
      this.showclaim_aspectsbtns=false;
      this.showamountbtns = false;
      this.showclaimbtns=false;
      this.showiregularbtns =false;
      this.showactivitybtns = true;
      this.ClaimActivityComponent.onClickDownloadTabCharts();
      //this.router.navigateByUrl('/dashboard/claim_aspects');
    
    }
   
  }
  //redirect to damage reports
  onClickDamage_Reports() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
    ;
    if (this.loginDetails.userType == 'SA') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/sa-dashboard');
    } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/csa-dashboard');
    }
    else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/csa-airline-dashboard');
    }
    else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/admin-airline-dashboard');
    }
    else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/admin-handler-dashboard');
    }
    else if (this.loginDetails.userType == 'E') {
      // this.SidebarComponent.activecall("1")
      this.router.navigateByUrl('/user-dashboard');
    }else if (this.loginDetails.orgType == 'E') {
      this.router.navigateByUrl('/HandlerDashboardComponent');
    }else{
      localStorage.clear()
      this.router.navigateByUrl('/Home');
    }
  }, 200);
  // this.openDialog()
  
  // let loginDetails = localStorage.getItem('loginDetails');
  // window.location.replace(`http://localhost:4200/cargo-damage?values=${loginDetails}`);
}

openDialog() {
  // const dialogRef = this.dialog.open(DialogContentExampleDialog);  
  // dialogRef.afterClosed().subscribe(result => {
  //   console.log('The dialog was closed', result);
  // });
}
  //redirect to Uld damage reports
  onClickUldDamage_Reports() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
    this.router.navigateByUrl('/Uld/viewuld');
  }, 200);

 
}

onClickCargoImage_Reports(){
  localStorage.setItem("currentModule",JSON.stringify("cargoImage"))
  this.router.navigateByUrl('/CargoImage/view-shipment');
}

onClickCargoAcceptance_Reports(){
  localStorage.setItem("currentModule",JSON.stringify("CargoAcceptance"))
  this.router.navigateByUrl('/CargoAcceptance/view-cargo-acceptance');
}

onclick_damageCustomerReport(){
  localStorage.setItem("currentModule",JSON.stringify("damageCustomerReport"))
  this.router.navigateByUrl("damageCustomerReport");
}

onClick_CargoServiceRecovery(){
  localStorage.setItem(
      "currentModule",
      JSON.stringify("csa-service-recovery")
    );
    this.router.navigateByUrl("/csa-service-recovery");
}

OnClickTruckInspection() {
  this.spinner.show();
  setTimeout(() => {
    localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
    this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
  }, 200);
}

OnClickCargoDmgForwarder(){
  this.spinner.show();
  setTimeout(() => {
      localStorage.setItem("currentModule",JSON.stringify("CARGODAMAGE-FORWARDER"))
  this.router.navigateByUrl('/cargoDamageForwarder/view-cargoDamageForwarder'); 
  }, 200);
}

ngOnDestroy() {
  this.spinner.hide();
  this.Homesubscribe.unsubscribe();
} 
OnClickImportAcceptance(){
  // this.spinner.show();
    // setTimeout(() => {
        // localStorage.setItem("currentModule",JSON.stringify("IMPORT_FORWARDER"))
        this.router.navigateByUrl("/CargoAcceptance/view-import-acceptance");
    // }, 200);
}
}   
