export class airwaybillinfo {
    
        public categoryId: any;
        public userId: any;
        public masterAwbNo: any;
        public airlineId: any;
        public movementType: any;
        public truckType: any;
        public flightTruckNo: any;
        public flightDate: any;
        public originAirportId: any;
        public destairportId: any;
        public trucking_flight_info: any;
        public flightIssuingAgent: string;
        public rootcauseExists: string;
        public rootcauseSendstatus: string;
        public reportOrgRootcauseStatus: string;
        public orgRootcauseStatus: string;
    

}
