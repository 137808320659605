import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ViewRecordsTableComponent } from './view-records-table/view-records-table.component';
import { ViewDetailedRecordComponent } from './view-detailed-record/view-detailed-record.component';
import { SharedLayoutsModule } from 'src/app/layouts/shared-layouts/shared-layouts.module';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { MatAutocompleteModule, MatButtonModule, MatCardModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatStepperModule, MatToolbarModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [ViewRecordsTableComponent, ViewDetailedRecordComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    SharedLayoutsModule,
    jqxGridModule,
    FormsModule,
    jqxButtonModule,
    jqxNumberInputModule,
    jqxInputModule,
    jqxWindowModule,

    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
    MatRadioModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  
  ],
  exports:[
    ViewRecordsTableComponent, ViewDetailedRecordComponent,
    SharedLayoutsModule,
    jqxGridModule,
    FormsModule,
    jqxButtonModule,
    jqxNumberInputModule,
    jqxInputModule,
    jqxWindowModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    NgDatepickerModule,
    AmazingTimePickerModule,
    jqxGridModule,
    MatRadioModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ]
})
export class SharedModule { }
