import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticateGuard } from 'src/app/AuthGuard/authenticate.guard';
import { DetailedUldComponent } from 'src/app/uld/detailed-uld/detailed-uld.component';
import { ViewUldComponent } from 'src/app/uld/view-uld/view-uld.component';
import { NavbarComponent } from '../navbar/navbar.component';

const routes: Routes = [

  { path: 'viewuld', component: ViewUldComponent, canActivate: [AuthenticateGuard] },
  { path: 'DetailedUld', component: DetailedUldComponent, canActivate: [AuthenticateGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedLayoutsRoutingModule { }
