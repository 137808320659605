import { Component, OnInit } from '@angular/core';
import { NavbarCargoClaimComponent } from '../layouts/navbar/navbarCargoClaim.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-security',
  // templateUrl: './security.component.html',
  template: '<ngx-spinner></ngx-spinner>',
  styleUrls: ['./security.component.css'],
  providers: [
    NavbarCargoClaimComponent 
  ]
})
export class SecurityComponent implements OnInit {
  token: any;
  loginresultobject: any;

  constructor(private navbarComponent :NavbarCargoClaimComponent ,  private router: Router) { }

  ngOnInit() {
    this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
    if (this.loginresultobject != null) {
    this.navbarComponent.Logout();
    }
    else{
      this.router.navigateByUrl("/cargoClaim/forgot-password", {
        skipLocationChange: true,
      })
      .then(() => this.router.navigate(["/"])); 
 }
  }

}
