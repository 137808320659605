
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import * as jsPDF from "jspdf";
@Component({
  selector: 'app-service-level',
  templateUrl: './service-level.component.html',
  styleUrls: ['./service-level.component.css']
})
export class ServiceLevelComponent implements OnInit {
  originstations: string[];
  origincount: number[];
  deststations: string[];
  destcount: number[];
  inputObj: any;
  topServiceLevel: any;
  topServiceLevelLabel: any=[];
  topServiceLevelCount: any=[];
  ServiceLevelLabel: any=[];
  ServiceLevelCount: any=[];
  loginDetails: any;
  orgId: any;
  currentyear: any;
  topSlaLevelOne: any;
  slaLevelOne: any;
  scrollable: boolean;
  serviceLevelModelWidth: any;
  graphHeight: number;
  constructor(
    private claimcountservice: ClaimCountService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private datePipe: DatePipe,
    
  ) {}
  ngOnInit() {
this.slaLevelOne = [];
this.topSlaLevelOne = [];
    //getting input object from dashboard
    this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
    //getting login data
    this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (this.loginDetails !=null) {
      this.orgId = this.loginDetails.orgId;
    }
    this.currentyear = this.datePipe.transform(new Date(), "yyyy");
    if (
      this.inputObj.year !== null &&
      this.inputObj.year !== "" &&
      this.inputObj.year !== undefined &&
      this.inputObj.year !== "Pick Year"
    ) {
      this.spinner.show();
      //service level api integration
      this.spinner.show();
      this.claimcountservice.serviceLevel_count(this.inputObj).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.slaLevelOne = data.slaLevel.count;
            this.topSlaLevelOne = data.topSlaLevel.count;
            this.ServiceLevelLabel = data.slaLevel.labels;
            this.ServiceLevelCount = data.slaLevel.count;
            //dymanic scroll start here
            let labelLengthsalLevel = data.slaLevel.labels.length; 
          
            if (labelLengthsalLevel > 60 ) {
              this.scrollable = true
            }
            else{
              this.scrollable = false
            }
                  //service level bar model chart
            this.serviceLevelModelWidth = this.getWidth(data.slaLevel.labels.length);
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("serviceLevelModel")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.ServiceLevelLabel,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.ServiceLevelCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio:false,
                  title: {
                    display: true,
                    //text: "Count Irregularity"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                      
                        scaleLabel: {
                          display: true,
                          labelString: "Service Level",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                          
                        },
                        ticks: {
                          autoSkip: false,
                        },
                        
                      },
                      
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 10,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
            //service level load top 10 bar chart
            this.topServiceLevelLabel = data.topSlaLevel.labels;
            this.topServiceLevelCount = data.topSlaLevel.count;
            new Chart(
              <HTMLCanvasElement>(
                document.getElementById("serviceLevelTop")
              ),
              {
                type: "bar",
                plugins: [ChartDataLabels],
                data: {
                  labels: this.topServiceLevelLabel,
                  datasets: [
                    {
                      type: "bar",
                      borderColor: "#ED7D31",
                      backgroundColor: "#ED7D31",
                      hoverBackgroundColor: "#ED7D31",
                      data: this.topServiceLevelCount,
                      fill: false,
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    //text: "Top 10 Irregularity Count"
                  },
                  plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                      color: "#000000",
                      anchor: "end",
                      align: "top",
                      rotation: -90,
                    },
                  },
                  legend: { display: false },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Service Level",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          autoSkip: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Count",
                          fontSize: 16,
                          fontColor: "#000000",
                          fontStyle: "bold",
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 20,
                        },
                        id: "y-axis-0",
                        position: "left",
                      },
                    ],
                  },
                },
              }
            );
          }
          else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toaster.warning("your session expired please login again");
            this.router.navigateByUrl("/");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      // this.origincountcard = 0;
      // this.destcountcard = 0;
    }
  }
  //download method
  onClickDownloadTabCharts() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    const canvas7 = document.getElementById("serviceLevelTop") as HTMLCanvasElement;
    // var imgData6 = canvas6.toDataURL("image/png");
    var imgData7 = canvas7.toDataURL("image/png");
    var imgWidth = 285;
    var pageHeight = 200;
    var imgHeight = (canvas7.height * imgWidth) / canvas7.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.setFontSize(18);
    var position = 80; // give some top padding to first page
    var text ="Cargo Claim Service Level Charts";
    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, 10, text);
    doc.setFontSize(14);
    doc.text(15, 30, 'Service Level');
    doc.addImage(imgData7, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position += heightLeft - imgHeight; // top padding for other pages
      // doc.addPage();
      doc.addImage(imgData7, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
   
    doc.save("Service_Level_Charts.pdf");
  }
 
  //show hide  model charts methods
  showmodelorg(){
    const element = document.getElementById("myModals");
    element.style.display = '';
    element.style.visibility = 'visible';
  }
  hidemodelorg(){
    const element = document.getElementById("myModals");
    element.style.display = 'block';
    element.style.visibility = 'hidden';
  }
  //dynamic scroll
  scrollView(){
    setTimeout(() => {
      const elements =document.getElementsByClassName('chartAreaWrapper_scroll60');
      for(let ele of elements as any){
        ele.scrollTop = ele.scrollHeight;
      }
    }, 2000);
   
  }
  //dynamic width
  getWidth(number) {
    this.graphHeight = 420;
    if (number < 20) {
        let finalWidth = 150 * number;
        return finalWidth;
    } else {
        let finalWidth = 60 * number;
        return finalWidth;
    }
  }
}