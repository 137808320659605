import { Component, OnInit, enableProdMode, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ViewReportsData } from '../data/DataShareViewDetails';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DxDataGridComponent } from 'devextreme-angular';
import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';
import { environment } from 'src/environments/environment.prod';
import { RefreshService } from '../services/Refresh/refresh.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../config-service.service';
import { Active_Archive_ReportsTableComponent } from '../shared/dataGrid-tables/active-reports-table/active-archive-reports-table.component';
import { ExcelService } from "src/app/services/excel.service";
import * as moment from 'moment-timezone';
import { Chart } from 'chart.js';
declare var jQuery: any;
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    @ViewChild('reportsDataGrid') reportsDataGrid_Ref: Active_Archive_ReportsTableComponent;
    
    public static totaldata = [];
    public static userlist: any;
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    @ViewChild('myGrid') myGrid: jqxGridComponent;
    dataGrid1: DxDataGridComponent;
    Always;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    userName: any;
    organizationDetails: any;
    recentActivites: any;

    Obj: any;
    orgType: any;
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    ObjectOne: any;
    ReportsProductionUrl: string;
    reportAcessType: any;
    Emailstatus: any;
    disabledValues: number[];
    multipleReportList: any;
    userId: string;
    masterAwbNo: string;
    stationCode: string;
    firstName: string;
    classificationType: string;
    disabledKeys: any[];
    dataGridInstance: any;
    loginDetails: any;
    justDeselected: any;
    mergedReportsFinal: any[];
    archieveTab: boolean;
    airlineDetails: any;
    showAll: boolean;
    showArchived: boolean;
    comnyorgid: any;
    isEnable_Refresh: boolean;
    anchors: any;
    images: any;
    editicons: any;
    orgId: any;
    loginUserId: any;
    shortReports: any;
    finalobj: any;
    selreportsarray: any[];
    public static reportAccessGlobal :any
    currentTabName: any;
    currentReportId: any;
    finalReportsCount: any;
    archiveReportsCount: any;
    incomingReportsCount: any;
    internalReportCount: number;
    archveActive: boolean = false;
    trashActive: boolean = false;
    rootcauseshare: boolean = false;
    rootcauseshareshow: boolean = false;
    carrierId: any;

    /* 
        111023 To remove the old KPT charts code start

        public damageReportbyAirlineChart:any;
        public reportbyAirlineLabeldata: any[] = [];
        public reportbyAirlineRealdata: any[] = [];

        public ResposibilityLabeldata: any[] = [];
        public ResposibilityRealdata: any[] = [];
        public damageResposibilityChart:any;

        public contentAffectedLabeldata: any[] = [];
        public contentAffectedRealdata: any[] = [];
        public contentAffectedChart:any;

        public classificationLabeldata: any[] = [];
        public classificationRealdata: any[] = [];
        public classificationChart:any;

        public reportsbyStationLabeldata: any[] = [];
        public reportsbyStationRealdata: any[] = [];
        public reportsbyStationChart:any;

        public damageReportsperMonthLabelsData: any[] = [];
        public damageReportsperMonthRealData: any[] = [];
        public damageReportsperMonthChart:any;

        public testGroupChart:any;

        public totalDamageReport:any;

        111023 To remove the old KPT charts code end
    */

    constructor(private config : ConfigServiceService,private location: LocationStrategy,private renderer: Renderer2, private elementRef: ElementRef, private refreshServiceRef: RefreshService, private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService,
        private excelService:ExcelService,) {
        this.disabledValues = [1];
       
      
         // preventing back button in browser   
         history.pushState(null, null, window.location.href);  
         this.location.onPopState(() => {
           history.pushState(null, null, window.location.href);
         });
    }
    ngAfterViewInit() {
        this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
        let lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
        
        if (lastSelectedTAb != null && lastSelectedTAb != undefined) {
            if (lastSelectedTAb === "Active") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
               
            } else if (lastSelectedTAb === "Archived") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
               
            } else if (lastSelectedTAb === "Trash") {
                this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
            } else  if (lastSelectedTAb === "Internal") {
                this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;

                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
               
            } 
             else if (lastSelectedTAb === "SHARED_TAB"){
                this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
                this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;

                this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
                this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;
            }
        } else {
            this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
            this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;


            this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
            this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;
        }
        
    }
    ngAfterViewChecked() {
        this.Obj = JSON.parse(localStorage.getItem("loginresultobject"))
        if(this.Obj.reportAcess === 'Y'){
            if(this.reportsDataGrid_Ref !== undefined){
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                   
                }else if(selectedreports === "Archived"){
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                  
                }else if(selectedreports === "Trash"){
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                }else  if(selectedreports === "Internal"){
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                   
                }
                else if(selectedreports === "SHARED_TAB"){
                    // this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link active';
                    }
                    document.getElementById('internal_Reports').className = 'nav-link';
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                }
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
            }
        }else{
            if(this.reportsDataGrid_Ref !== undefined){
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.incomingReportsCount = this.reportsDataGrid_Ref.incomingReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                  
                }else if(selectedreports === "Archived"){
                    this.showAll = false;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                }else if(selectedreports === "Trash"){
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                }else  if(selectedreports === "Internal"){
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link';
                    }
                }
                
                else if(selectedreports === "SHARED_TAB"){
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('trash_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                    if(this.Obj.orgType==="A"){
                        document.getElementById('sharedReports').className = 'nav-link active';
                       
                    }
                }
                // this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
            }
        }




        // let doc = document.getElementById('')
        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.images = this.elementRef.nativeElement.querySelectorAll('img');
        this.editicons = this.elementRef.nativeElement.querySelectorAll('i');
        // 
        //console.log("anchors1",this.anchors);
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            // 
            //console.log("anchors1innerText",anchor.innerText);
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
                else if (anchor.innerText === 'Delete' && href_Valid_Check_Var === 'delete') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
            }
        })
        this.images.forEach((image: HTMLImageElement) => {
            //
            //console.log("anchors1innerText",anchor.innerText);
            if (image.id != '') {
                let href_Split_Array = [];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                //
                if ((href_Valid_Check_Var === 'resend')) {
                    image.addEventListener('click', this.handleImageClick)
                }

            }
        })
        this.editicons.forEach((editicon: HTMLImageElement) => {
            //
            //console.log("anchors1innerText",anchor.innerText);
            if (editicon.id != '') {
                let href_Split_Array = [];
                href_Split_Array = editicon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                //
                if ((href_Valid_Check_Var === 'edit')) {
                    editicon.addEventListener('click', this.handleiconClick)
                } else if (href_Valid_Check_Var === 'resend') {
                    editicon.addEventListener('click', this.handleiconClick)

                }

            }
        })
    }
    ngOnInit() {
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
      
        this.disabledKeys = [];
        this.showAll = true;
        this.showArchived = false;
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'Y' && this.loginDetails.orgType === "H") {
            this.reportAcessType = true;
            UsersComponent.reportAccessGlobal = true ;
        } else if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'I' && this.loginDetails.userSuperAdminAuth === 'I') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "H") {
            this.reportAcessType = false;
            UsersComponent.reportAccessGlobal = false ;
        } else if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "H") {
            this.reportAcessType = false;
            UsersComponent.reportAccessGlobal = false ; 
        }
        if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'Y' && this.loginDetails.orgType === "A") {
            this.reportAcessType = true;
            UsersComponent.reportAccessGlobal = true ;
        } else if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'I' && this.loginDetails.userSuperAdminAuth === 'I') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "A") {
            this.reportAcessType = false;
            UsersComponent.reportAccessGlobal = false ;
        } else if (this.loginDetails.userType == 'E' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "A") {
            this.reportAcessType = false;
            UsersComponent.reportAccessGlobal = false ; 
        }
        // if (this.Object.userType == 'E' && (this.Object.userAdminAuth === 'A' || this.Object.userSuperAdminAuth === 'A') && this.Object.reportAcess === 'Y' && this.Object.orgType === "H") {
        this.archieveTab = true
        // }
        this.emailid = this.Object.emailId;

        this.usertype = this.Object.userType;
        this.orgType = this.Object.orgType;
        this.isEnable_Refresh = true;
        // if ((this.loginDetails.orgType === 'A') || (this.loginDetails.orgType === 'H')) {
            let lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
            
            if (lastSelectedTAb != null && lastSelectedTAb != undefined) {
                if (lastSelectedTAb === "Active") {
                    this.fn_OnTab_Changes('Active','A')
                } else if (lastSelectedTAb === "Archived") {
                    // this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    this.fn_OnTab_Changes('Archived','R')
                }else  if (lastSelectedTAb === "Internal") {
                    this.fn_OnTab_Changes('Internal','I')
                }  else if (lastSelectedTAb === "Trash") {
                    this.fn_OnTab_Changes('Trash','T')
                }
                 else{
                    UsersComponent.currentSelectedTab= 'SHARED_TAB'
                    this.fn_toGetAirlineDAshboardData()
                }
            } else {
                if ((this.loginDetails.orgType === 'A') ){
                    UsersComponent.currentSelectedTab= 'SHARED_TAB'
                    this.fn_toGetAirlineDAshboardData()
                 }
                  else if  (this.loginDetails.orgType === 'H'){
                    this.fn_OnTab_Changes('Active','A')
                    }
            }
    }


public static currentSelectedTab ;
    fn_toGetAirlineDAshboardData() {
        this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
        this.archveActive = true;
        this.trashActive = true;
        localStorage.setItem('reportsTab', JSON.stringify("SHARED_TAB"));
        localStorage.setItem('selectedreports', JSON.stringify("Incoming"));
     
        this.spinner.show();
        this.selectedRowsData =[]
        try{
            let logindet = JSON.parse(localStorage.getItem('loginresultobject'));
            this.reportsDataGrid_Ref.source.url = this.config.getConfig().URL + 'getLoginCclpPortal.php/getUserDashboard?emailId=' + logindet.emailId + '&userType=' + logindet.userType + '&orgType=' + logindet.orgType + '&loginUserId=' +logindet.userId
             this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
             this.reportsDataGrid_Ref.myGrid.gotopage(0)
            
           this.reportsDataGrid_Ref.myGrid.hidecolumn("emailStatus") ;
           this.reportsDataGrid_Ref.myGrid.showcolumn("orgName") ;
           this.reportsDataGrid_Ref.myGrid.hidecolumn("internalFileName") ;
           this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;


           this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedDate") ;
           this.reportsDataGrid_Ref.myGrid.hidecolumn("modifiedTime") ;
             Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
        }catch(exception){
            this.spinner.hide()
            console.log("heyyyyyyyyyyyiiiiii-------11")
        }
        
    }
    public handleiconClick = (event: Event) => {
      
        // Prevent opening anchors the default way
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;

        this.fn_alerticon(editicon, event);
    }
    fn_alerticon(editicon, event) {
      
        let href_Split_Array = [];
        href_Split_Array = editicon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'edit') {
            this.mergedReportsFinal[href_Valid_Check_Var];
            this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }


    }
    public handleAnchorClick = (event: Event) => {
        
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        let href_Split_Array = [];
        href_Split_Array = anchor.href.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        
        let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
        this.mergedReportsFinal[href_Valid_Check_Var];
        this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
    }
    public handleImageClick = (event: Event) => {
        //
        // Prevent opening anchors the default way
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;

        this.fn_alertImage(editicon, event);
    }
    fn_alertImage(image, event) {
        
        let href_Split_Array = [];
        href_Split_Array = image.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        if (href_Split_Array[0] === 'resend') {
            this.mergedReportsFinal[href_Valid_Check_Var]
            this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var], 'S');
        }


    }

    manualarchive(){
    
        if (this.selectedRowsData.length != 0) {
            this.selreportsarray = [];
        for(let i=0; i<this.selectedRowsData.length; i++){
            this.userId = this.selectedRowsData[i].userId,
            this.masterAwbNo = this.selectedRowsData[i].masterAwbNo,
            this.currentReportId = this.selectedRowsData[i].reportId,
            this.selreportsarray.push({
                userId: this.userId,
                awbNumber: this.masterAwbNo,
                reportId: this.currentReportId
            });
        }
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.loginUserId = this.Object.userId;
    
            this.finalobj = {
              updatedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
              createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
              loginUserId: this.loginUserId,
              reportsList: this.selreportsarray,
            };
        this.spinner.show();
        this.superAdminservice.SetReportArchive(this.finalobj).subscribe(
            data => {
               
                
                if (data.responseCode == '1') {
                   
                    this.toastr.success(data.message, '', {});
                   
                    jQuery('#manarchModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#manarchModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                   
                    this.toastr.error(data.message, '', {});
                    jQuery('#manarchModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                    this.spinner.hide();
                }
            },
            error => {
              
                this.spinner.hide();
                jQuery('#manarchModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            }
        )
        }
        else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
    }

    confirm_manualarchive(){
        if (this.selectedRowsData.length != 0) {
            jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
        }else{
            this.spinner.hide();
            this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
        }
    }

    onEditClick(rowData) {
        
        localStorage.setItem('awbNumber', JSON.stringify(rowData.masterAwbNo));
        localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
        rowData.isFrom = 'EDIT_REPORT'
        rowData.from_userDashboard = 'true';
        this.router.navigate(['save-report'], { queryParams: rowData, skipLocationChange: true })
    }

    calculateCellValue(data) {

        return {
            stationcode: data.stationCode,
            status: data.stationCodeStatus
        };
    }

    fn_ToRefreshReports() {
        this.comnyorgid = JSON.parse(localStorage.getItem('loginresultobject'));
        this.spinner.show();
        this.refreshServiceRef.servc_To_Refresh_Reports(this.comnyorgid.userId).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.ngOnInit();
                this.selectedRowsData = [];
            }
            else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
            }else{
                this.toastr.error(data.message);
            }
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
        })
    }
 
 
  
    
    static currentReportTab 
   
  
    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }

    Rowdoubleclick(event: any): void {
        status = ('viewreports');
        localStorage.setItem("isFromreports", JSON.stringify(status));

        if (this.mergedReportsFinal[event.args.row.bounddata.uid].length != 0) {
            console.log("fgasdgahgdhdagshdhgashghdg", this.mergedReportsFinal[event.args.row.bounddata.uid]);
            // localStorage.setItem("eachReport", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0]));
            localStorage.setItem("reportStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].masterAwbNo));
            localStorage.setItem('awbNumber_reportId', JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].emailStatus));
           // this.router.navigate(["/viewdetails", this.Object.orgId]);//neww
           this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId }});
            this.ViewReportsData.isfromusersDashboard = 'yes'
        }
    }
    
    getSelectedData(status) {
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.dataGrid.instance.getSelectedRowsData().length != 0) {
            console.log("fgasdgahgdhdagshdhgashghdg", this.dataGrid.instance.getSelectedRowsData()[0]);
            // localStorage.setItem("eachReport", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0]));
            localStorage.setItem("reportStatus", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].masterAwbNo));
            localStorage.setItem('awbNumber_reportId', JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].emailStatus));
           // this.router.navigate(["/viewdetails", this.Object.orgId]); //neww
           this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId }});
            this.ViewReportsData.isfromusersDashboard = 'yes'
        }
    }

    navigateToViewReports(obj, status) {

        localStorage.setItem("isFromreports", JSON.stringify(status));
        localStorage.setItem("awbnoObj", JSON.stringify(obj));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.router.navigate(["/viewdetails"]);
    }
    getSelectedDataOfReport(val, status) {

        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val.data));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataCancel(val, status) {

        console.log(val, "deleteData");
        localStorage.setItem("Cancelstatus", JSON.stringify(status));
        localStorage.setItem("reportData", JSON.stringify(val.data));
        jQuery('#cancelsingleModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataOfresendReport(val, status) {

        console.log(val, "reportData");
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }
    CancelUser() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let Cancelstatus = JSON.parse(localStorage.getItem('Cancelstatus'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: Cancelstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.ngOnInit();

             
                this.selectedRowsData = [];


                this.toastr.success(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');

            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#cancelsingleModal').modal('hide');
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#cancelsingleModal').modal('hide');
            })
    }
    sendManualReportEmail() {

        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.ngOnInit();
                this.selectedRowsData = [];
                this.toastr.success(data.message, '', {});
                jQuery('#myModal').modal('hide');

            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#myModal').modal('hide');
            })

    }
    resendManualReportEmail() {
        
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.superAdminservice.setholdTime(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.ngOnInit();
                this.selectedRowsData = [];
                this.toastr.success(data.message, '', {});
                jQuery('#resendModal').modal('hide');

            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
                jQuery('#resendModal').modal('hide');
            }
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#resendModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#resendModal').modal('hide');
            });


   

    }
    notSent() {
        
        jQuery('#Activation').modal('hide');

    }
    notCancel() {
        this.rootcauseshareshow = false;
        jQuery('#Activation').modal('hide');

    }
  

 
    OpenEmailPopup(status, event: any) {
      
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to send');
        }
        else {
            this.currentTabName = JSON.parse(localStorage.getItem('reportsTab'))
            localStorage.setItem("Emailstatus", JSON.stringify(status));
            this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            let showCheckbox = false;
            if (this.loginDetails.reportAcess === 'Y') {
                for(let item of this.selectedRowsData) {
                    if (this.selectedRowsData[0].orgRootcauseStatus === 'Y') {
                        this.rootcauseshare = true;
                    }
                    if (item.rootcauseExists === 'Y') {
                        if ((item.rootcausestatus === 'N' || item.rootcausestatus === 'Y') && item.reportStatus === 'internal') {
                            showCheckbox = true;
                        }
                    }
                    if (item.reportOrgId !== this.loginDetails.orgId) {
                        this.toastr.error('Other organisations reports Not Allowed');
                        this.rootcauseshareshow = false;
                        return;
                    }
                }
            }
            if (showCheckbox === true) {
                this.rootcauseshareshow = true;
            }
            jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
        }
    }
 
    sentMultipleEmailReport() {
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            let rootcauseStatus = 'N';
            for (let index = 0; index < this.selectedRowsData.length; index++) {
               
                    if (this.selectedRowsData[index].emailStatus === 'Not sent to airline'
                        || this.selectedRowsData[index].emailStatus === 'Emails are not found'
                        || this.selectedRowsData[index].emailStatus === 'Sent to airline'
                    ) {
                        this.userId = this.selectedRowsData[index].userId,
                            this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                            this.stationCode = this.selectedRowsData[index].stationCode,
                            this.classificationType = this.selectedRowsData[index].classificationType,
                            this.firstName = this.selectedRowsData[index].reportPreparedName;
                            this.currentReportId = this.selectedRowsData[index].reportId,
                            this.carrierId = this.selectedRowsData[index].carrierId
                        this.multipleReportList.push({
                            userId: this.userId,
                            masterAwbNo: this.masterAwbNo,
                            stationCode: this.stationCode,
                            classificationType: this.classificationType,
                            firstName: this.firstName,
                            reportId: this.currentReportId,
                            carrierId: this.carrierId,
                            destairportId: this.selectedRowsData[index].destAirportName,
                            originAirportId: this.selectedRowsData[index].originAirportName,
                            internalResponsibility: this.selectedRowsData[index].reportStatus,
                            rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                            rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                            reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                        });
                    }
                // }


            }
            if (this.rootcauseshare === true) {
                rootcauseStatus = 'Y'
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                rootcause: rootcauseStatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#emailModal').modal('hide');
                //this.myGrid.unselectrow(this.myGrid
                this.myGrid.clearselection();
                this.toastr.error('No emails for this report');
                return false;
            }
            this.spinner.show();
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
                
                console.log('data.responseCode', data.responseCode)
                this.spinner.hide();
                if (data.responseCode === 1) {
                    this.rootcauseshare = false;
                    this.rootcauseshareshow = false;
                    this.myGrid.clearselection();
                    this.toastr.success(data.message, '', { timeOut: 1300 });
                    jQuery('#emailModal').modal('hide');
                    this.ngOnInit();
                    this.selectedRowsData = [];
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#emailModal').modal('hide');
                }
                else {
                    
                    jQuery('#emailModal').modal('hide');
                    this.toastr.error(data.message, '', { timeOut: 1300 });
                    this.ngOnInit();
                    this.selectedRowsData = [];

                }
            },
                error => {
                    
                    jQuery('#emailModal').modal('hide');
                    this.toastr.error(error.message, '', { timeOut: 1300 });
                    this.ngOnInit();
                    this.selectedRowsData = [];
                    this.spinner.hide();

                })

        }
        else {
            jQuery('#emailModal').modal('hide');
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
    OpenCancelPopup(status) {
        // 
        // if (this.myGrid.getselectedrowindexes.length === 0) {
        //     this.toastr.error('Select reports to cancel');
        // } else {
        //     localStorage.setItem("Emailstatus", JSON.stringify(status));
        //     jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
        // }


        // console.log(this.myGrid.getselectedrowindexes.length);
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to hold');
        } else {
            let selectedRowsDAtaArray = []
            for (let i = 0; i < this.selectedRowsData.length; i++) {
                selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
            }
            
            if(selectedRowsDAtaArray.length !== 0){
              let isExist = selectedRowsDAtaArray.some((element) => {
                  return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
                });
               
                if(isExist){
                    jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
                    return false;
                }else{
                    localStorage.setItem("Emailstatus", JSON.stringify(status));
                    jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
                }
             
            }
        }
    }

    cancelunselectSentReportExistPopup(){
        jQuery('#unselectSentReportExistPopup').modal("hide");
    }
    cancelMultipleEmailReport() {
        
      
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            for (let index = 0; index < this.selectedRowsData.length; index++) {
           
                if (

                    this.selectedRowsData[index].emailStatus === 'Not sent to airline'
                        || this.selectedRowsData[index].emailStatus === 'Emails are not found'
                      
         ) {
                this.userId = this.selectedRowsData[index].userId;
                this.masterAwbNo = this.selectedRowsData[index].masterAwbNo;
                this.stationCode = this.selectedRowsData[index].stationCode;
                this.classificationType = this.selectedRowsData[index].classificationType;
                this.firstName = this.selectedRowsData[index].reportPreparedName;
                this.currentReportId = this.selectedRowsData[index].reportId,
                this.multipleReportList.push({
                    userId: this.userId,
                    masterAwbNo: this.masterAwbNo,
                    stationCode: this.stationCode,
                    classificationType: this.classificationType,
                    firstName: this.firstName,
                    reportId: this.currentReportId,
                    destairportId: this.selectedRowsData[index].destAirportName,
                    originAirportId: this.selectedRowsData[index].originAirportName,
                    internalResponsibility: this.selectedRowsData[index].reportStatus,
                    rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                    rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                    reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                });
            }
            // }
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#cancelModal').modal('hide');
                //this.dataGrid.instance.deselectAll();
                this.myGrid.clearselection();
                this.toastr.error('No reports to cancel');
                return false;
            }
            this.spinner.show();
            this.superAdminservice.sendMultipleReportEmail(dataObject).subscribe(data => {
                
                console.log('data.responseCode', data.responseCode)
                this.spinner.hide();
                if (data.responseCode === 1) {
                    
                    // this.dataGrid.instance.deselectAll();
                    this.myGrid.clearselection();
                    this.toastr.success(data.message, '', { timeOut: 1300 });
                    jQuery('#cancelModal').modal('hide');
                    this.ngOnInit();
                    this.selectedRowsData = [];
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#cancelModal').modal('hide');
                }
                else {
                    
                    this.toastr.error(data.message, '', { timeOut: 1300 });
                    jQuery('#cancelModal').modal('hide');
                    this.ngOnInit();
                    this.selectedRowsData = [];

                }
            },
                error => {
                    jQuery('#cancelModal').modal('hide');
                    this.toastr.error(error.message, '', { timeOut: 1300 });
                    this.ngOnInit();
                    this.selectedRowsData = [];
                    this.spinner.hide();
                 
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
  
    AirportClaim() {
        window.open('https://dashboard.cclp.aero/ ', '_blank');
    }

    onClickUld() {
        localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
        this.router.navigateByUrl('/Uld/viewuld');
        // window.open("https://uld.cclp.aero/ ", "_blank"); 
    }

    // redirect to handler cargo image
    onClickCargo_Image(){
        localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"))
        this.router.navigateByUrl('/CargoImageHandler/view-shipment');
    }


    onPageChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }
    onPageSizeChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }


    
fn_OnTab_Changes(tabName : string , type: string){
    if(type === 'R'){
        this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
        this.archveActive = false;
        this.trashActive = false;
    }else if(type === 'T'){
        this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
        this.archveActive = false;
        this.trashActive = true;
    } else {
        this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
        this.archveActive = true;
        this.trashActive = false;
    }
    localStorage.setItem('reportsTab', JSON.stringify(tabName));
    localStorage.setItem("ordType",JSON.stringify(type));
    localStorage.removeItem("selectedreports");
    this.spinner.show();
    this.selectedRowsData =[]
    try{
        let logindet = JSON.parse(localStorage.getItem('loginresultobject'));
        if(tabName === 'Internal'){
            this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;
            this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
            this.reportsDataGrid_Ref.source.url =  this.config.getConfig().URL + 'getInternalReportList.php/getInternalReportList?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId ;
        } else if(tabName === 'Trash'){
            this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox");
            this.reportsDataGrid_Ref.source.url =  this.config.getConfig().URL + 'getTrashReports.php/getTrashReports?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId;
            setTimeout(() => {
                this.reportsDataGrid_Ref.myGrid.hidecolumn("actions") ;
            }, 0);
        } else{ 
            this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;
            this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
        this.reportsDataGrid_Ref.source.url =  this.config.getConfig().URL + 'superAdminUserReports.php/getUserReportInfo?organisationId=' + logindet.orgId + "&orderType="+type +"&loginUserType=" + logindet.userType + '&loginUserId=' + logindet.userId;
        }
        this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
         this.reportsDataGrid_Ref.myGrid.gotopage(0)
         this.reportsDataGrid_Ref.myGrid.clearselection()
       this.reportsDataGrid_Ref.myGrid.showcolumn("emailStatus") ;
       
       this.reportsDataGrid_Ref.myGrid.hidecolumn("orgName") ;
       this.reportsDataGrid_Ref.myGrid.showcolumn("internalFileName") ;
    //    this.reportsDataGrid_Ref.myGrid.showcolumn("actions") ;


       this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedDate") ;
           this.reportsDataGrid_Ref.myGrid.showcolumn("modifiedTime") ;
         Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
    }catch(exception){
        this.spinner.hide()
        // console.log("heyyyyyyyyyyyiiiiii-------11")
    }
 
}



public selectedRowsData = [] ;
_onSelectRow(event){ // trigger when checkbox checked / unchecked 
    
    if(Array.isArray(event.args.rowindex)){ 
       if(event.args.rowindex.length !== 0){
        this.selectedRowsData = this.reportsDataGrid_Ref.getDtotalData() ;
       }else{
        this.selectedRowsData = [];
       }
    }else{
        if( event.type === "rowunselect"){
            let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
            this.selectedRowsData.splice(index, 1);
        }else{
            var ind = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.findIndex(x => x.reportId === event.args.row.reportId);
            event.args.row.carrierId = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA[ind].carrierId;
            this.selectedRowsData.push(event.args.row) ; 
        }
    }
        console.log("this.selectedRowsData",this.selectedRowsData)
}


onClick_CargoClaim(){
    localStorage.setItem("currentModule",JSON.stringify("cargoClaim"))
    this.router.navigateByUrl('/cargoClaim/dashboard');
}


onClick_Save_Report() {
    const  obj = {
        isFrom : 'CREATE_REPORT'
    };
    this.router.navigate(['save-report'], { queryParams: obj });
  }

  OnClickTruckInspection() {
    this.spinner.show();
    setTimeout(() => {
      localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
      this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
    }, 200);
  }
  scaleColumnWidth(width:any,scale :number =10){
    return (width / scale)
  }
  onExportClick(): void {
    let deleteColumns = ['actions','uid','boundindex','uniqueid','internalFileName','externalFileName','visibleindex','forwarder_org_id','uldCargoId','uldCargoImages','uld_cargo_pdf','userId','firstName','reportId','orgName','status'];
    
    let reportsArray = [];
    // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
    reportsArray = this.reportsDataGrid_Ref.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
    let columnsData = this.reportsDataGrid_Ref.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
            return (!deleteColumns.includes(item.datafield)) ;
    });  //Excel columns Header And width obj array
    // for (let i = 0; i < reportsArray.length; i++) { 
    //   reportsArray[i]['take_in'] = CsaDashboardComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
    //   let imgTempArray = [];
    //     if(reportsArray[i]['cargoImages'].length !== 0){
    //     for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
    //       imgTempArray.push({
    //         image : this.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
        
    //       })
    //     }
    //   }
    //     reportsArray[i]['Images'] = imgTempArray;
    //   //  columnsData.push("Images");
    //   for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
    //       delete  reportsArray[i][deleteColumns[j]];
    //   }
    // }
    this.excelService.exportAsExcelFile(reportsArray,`userDashboard_${(new Date()).toString().substring(0,24)}_page_${this.reportsDataGrid_Ref.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
  }




    // 111023 To remove the old KPT charts code start by iwi 
    /*
        eventHandler(event: any): void {
            this.spinner.show();
            this.totalDamageReport = event.TotalRows;
            let $this = this;
            // damageReportbyAirline chart code start by iwi
                this.damageReportbyAirlineChart = event.damageReportbyAirline;
                this.reportbyAirlineLabeldata= [];
                this.reportbyAirlineRealdata= [];
                if (this.damageReportbyAirlineChart != null) {
                    for (let i = 0; i < this.damageReportbyAirlineChart.length; i++) {
                        this.reportbyAirlineLabeldata.push(this.damageReportbyAirlineChart[i].airline_name);
                        this.reportbyAirlineRealdata.push(this.damageReportbyAirlineChart[i].Count);
                    }
                }
                var ReportbyAirline_New = {
                    labels: this.reportbyAirlineLabeldata,
                    datasets: [
                        {
                            label: "Total Damage Report",
                            data: this.reportbyAirlineRealdata,
                            backgroundColor:  "#ff7a15",
                            barThickness: 15,
                            hoverOffset: 4,
                            borderRadius: Number.MAX_VALUE
                            //hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.damageReportbyAirlineChart = new Chart("damageReportbyAirlineChart", {
                    type: 'horizontalBar',
                    data: ReportbyAirline_New,
                    options:{
                        maintainAspectRatio: false,
                        scales:{
                            xAxes:[{
                               display: true,
                                ticks: {
                                    display: true,
                                    min: 0
                                 },
                                
                            }],
                            yAxes: [{
                                display: true,
                                ticks:{
                                    display:true
                                }
                            }]
                        },
                        legend:{
                            display:false
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                    
                });
            // damageReportbyAirline chart code end by iwi


            // damageReportResposibility chart code start by iwi
                this.damageResposibilityChart = event.damageResponsibilityStatus;
                this.ResposibilityLabeldata= [];      
                this.ResposibilityRealdata= [];
                if (this.damageResposibilityChart != null) {
                    for (let i = 0; i < this.damageResposibilityChart.length; i++) {
                        this.ResposibilityLabeldata.push(this.damageResposibilityChart[i].reportStatus);
                        this.ResposibilityRealdata.push(this.damageResposibilityChart[i].Count);
                    }
                }
                var damageResposibility_New = {
                    labels: this.ResposibilityLabeldata,
                    datasets: [
                        {
                            label: "Total Damage Report",
                            data: this.ResposibilityRealdata,
                            backgroundColor:  [
                              'rgb(255, 99, 132)',
                              'rgb(54, 162, 235)',
                              'rgb(255, 205, 86)'
                            ] ,
                            hoverOffset: 4,
                            borderWidth:1,
                            borderRadius: Number.MAX_VALUE
                            //hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.damageResposibilityChart = new Chart("damageResposibilityChart", {
                    type: 'doughnut',
                    data: damageResposibility_New,
                    options:{
                        scales:{
                            xAxes:[{
                                //barThickness: 6
                                display: false,
                                ticks: {
                                    display: false //this will remove only the label
                                 },
                                // angleLines: {
                                //     display: false
                                // }
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                    
                });
            // damageReportResposibility chart code end by iwi


            // contentAffectedChart chart code start by iwi
                this.contentAffectedChart = event.damageContentAffected;
                this.contentAffectedLabeldata= [];
                this.contentAffectedRealdata= [];
                if (this.contentAffectedChart != null) {
                    for (let i = 0; i < this.contentAffectedChart.length; i++) {
                        this.contentAffectedLabeldata.push(this.contentAffectedChart[i].discoveresCaused);
                        this.contentAffectedRealdata.push(this.contentAffectedChart[i].Count);
                    }
                }
                var contentAffectedData = {
                    labels: this.contentAffectedLabeldata,
                    datasets: [
                        {
                            label: "Content Affected",
                            data: this.contentAffectedRealdata,
                            borderRadius: Number.MAX_VALUE, 
                            barThickness: 12,
                            grouped: true,
                            backgroundColor:  [
                                '#009EF7',
                                '#ff6384',
                                '#7239EA',
                                '#EDC539',
                                '#50cd89'
                                ],
                            hoverBackgroundColor: "#8bc7f7"
                        }]
                };
                this.contentAffectedChart = new Chart("contentAffectedChart", {
                    type: 'pie',
                    data: contentAffectedData,
                    options: {
                        // indexAxis: 'y',
                        scales: {
                            xAxes: [{
                                ticks: {
                                    display: false // Edit the value according to what you need
                                },
                                gridLines: {
                                    display:false
                                }
                            }],
                            yAxes: [{
                                stacked: false,
                                ticks:{display:false},
                                gridLines: {
                                    display:false
                                }
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });
            // contentAffectedChart chart code end by iwi

            // classificationChart chart code start by iwi
                this.classificationChart = event.damageClassificationType;
                this.classificationLabeldata= [];
                this.classificationRealdata= [];
                if (this.classificationChart != null) {
                    for (let i = 0; i < this.classificationChart.length; i++) {
                        this.classificationLabeldata.push(this.classificationChart[i].classification_type);
                        this.classificationRealdata.push(this.classificationChart[i].Count);
                    }
                }
                var classificationData_New = {
                    labels: this.classificationLabeldata,
                    datasets: [
                        {
                            label: "Classification",
                            data: this.classificationRealdata,
                            grouped: true,
                            backgroundColor:  [
                                '#009EF7',
                                '#ff6384',
                                '#7239EA',
                                '#EDC539',
                                '#50cd89',
                                '#c0c7eb'
                                ],
                            hoverBackgroundColor: "#8bc7f7"
                        }]
                };

                this.classificationChart = new Chart("classificationChart", {
                    type: 'pie',
                    data: classificationData_New,
                    options: {
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    display: false // Edit the value according to what you need
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    display: false // Edit the value according to what you need
                                },
                                stacked: true
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });

            // classificationChart chart code end by iwi

            // reports per month Chart code start by iwi
                this.damageReportsperMonthChart = event.damageReportEveryMonth;
                this.damageReportsperMonthLabelsData= [];
                this.damageReportsperMonthRealData= [];
                if (this.damageReportsperMonthChart != null) {
                    for (let i = 0; i < this.damageReportsperMonthChart.length; i++) {
                        this.damageReportsperMonthLabelsData.push(this.damageReportsperMonthChart[i].year);
                        this.damageReportsperMonthRealData.push(this.damageReportsperMonthChart[i].Count);
                    }
                }
                var monthGroupData_fifth = {
                    labels: this.damageReportsperMonthLabelsData,
                    datasets: [
                        {
                            label: "Reports Per Month",
                            data: this.damageReportsperMonthRealData,
                            backgroundColor:  "#009EF7" ,
                            barThickness:15,
                            hoverBackgroundColor: "#8bc7f7",
                            fill: true
                        }]
                };
                this.damageReportsperMonthChart = new Chart("damageReportsperMonthChart", {
                    type: 'bar',
                    data: {
                        labels: this.damageReportsperMonthLabelsData,
                        datasets: [{
                            label: '',
                            data: this.damageReportsperMonthRealData,
                            barThickness:15,
                            backgroundColor: "#7239EA"
                        }, {
                            label: '',
                            data: this.damageReportsperMonthRealData,
                            backgroundColor: "#F5F0FF",
                            borderColor: "#F5F0FF",

                            // Changes this dataset to become a line
                            type: 'line'
                        }],
                        // labels: ['January', 'February', 'March', 'April']
                    },
                    // data: monthGroupData_fifth,
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    min: 0 
                                }
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        legend: {
                          display: false

                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                        },
                        onClick: this.chartClick.bind(this)
                        // onClick: function (e) {
                        //     var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                        //     $this.handleChartClick("reportsByStation",activePointLabel);
                        // }
                    }
                });
            // reports per month Chart code end by iwi


            // reportsby Station Chart chart code start by iwi
                this.reportsbyStationChart = event.damageReportbyStationCode;
                this.reportsbyStationLabeldata= [];
                this.reportsbyStationRealdata= [];
                if (this.reportsbyStationChart != null) {
                    for (let i = 0; i < this.reportsbyStationChart.length; i++) {
                        this.reportsbyStationLabeldata.push(this.reportsbyStationChart[i].station_code);
                        this.reportsbyStationRealdata.push(this.reportsbyStationChart[i].Count);
                    }
                }
                var reportsbyStationData_fourth = {
                    labels: this.reportsbyStationLabeldata,
                    datasets: [
                        {
                            label: "Reports by Station",
                            data: this.reportsbyStationRealdata,
                            backgroundColor:  "#009EF7" ,
                            barThickness:15,
                            hoverBackgroundColor: "#8bc7f7",
                            fill: true
                        }]
                };
                this.reportsbyStationChart = new Chart("reportsbyStationChart", {
                    type: 'bar',
                    data: reportsbyStationData_fourth,
                    options: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    min: 0 // Edit the value according to what you need
                                }
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        plugins: {
                            datalabels: {
                              display: false
                            }
                          },
                        onClick: this.chartClick.bind(this)
                    }
                });
            // reportsby Station Chart chart code end by iwi

            this.spinner.hide();
        }

        handleChartClick(activePoint,activePointLabel) : void {
            console.log("1828 activePoint ",activePoint);    
            console.log("1829 activePointLabel ",activePointLabel);    
        }

        chartClick(event: MouseEvent) {

        }
    */
    // 111023 To remove the old KPT charts code end by iwi end
}
