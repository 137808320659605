import { DatePipe, Location, LocationStrategy } from '@angular/common';
import { Compiler, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from 'amazing-time-picker';
import * as enLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import * as watermark from 'watermarkjs';
import { globalConstants } from '../../environments/globalConstants';
import { ConfigServiceService } from '../config-service.service';
import { ShareLoginDataService } from '../data/share-login-data.service';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { LoginInput } from '../home/loginModel';
import { ConfirmPasswordValidator } from '../home/password.validator';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { SidebarComponent } from '../layouts/sidebar/sidebar.component';
import { LoginDataService } from '../login/login-data.service';
import { LoginComponent } from '../login/login.component';
import { LoginService } from '../services/login/login.service';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { RegisterService } from '../services/register/register.service';
import { StationService } from '../services/station/station.service';
import { SuperadminService } from '../services/superadmin/superadmin.service';
import { CreatereportService } from './../services/createreport.service';
import { airwaybillinfo } from './airwaybillinfo';
import { damageinfo } from './damageinfo';
import { generalinfo } from './generalinfo';
import { investigation } from './investigation';
import { RootCauseModel } from './rootCauseModel';
import { shipmentinfo } from './shipmentinfo';
import { Uploadpicdoc } from './uploadpicdoc';
declare var jQuery: any;
@Component({
  selector: 'app-save-reports',
  templateUrl: './save-reports.component.html',
  styleUrls: ['./save-reports.component.scss'],
  providers: [ForgotpasswordComponent]
})
export class SaveReportsComponent implements OnInit {

  VERSION_NUMBER = globalConstants.VERSION_NUMBER
  isLinear = true;
  @ViewChildren('verifyEmailForm') rows: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

    status1: boolean = false;
  status2: boolean = false;
  status3: boolean = false;
  status4: boolean = false;
  status5: boolean = false;
  status6: boolean = false;
  awbAlreadyExisted = true;

  checkStatus: boolean = false;
  selectionStatus: boolean = false;
  alreadyExistMsg: any;
  isAlredyExistErr: boolean;
  itemData: any[];
  loginForm: FormGroup;
  verifyEmailForm: FormGroup;
  loginInput = new LoginInput();
  loginData: any;
  public static MAWBCONCATEDVALUE;
  public static ISCOUNT;

  shipmentobj = new generalinfo();
  airwaybillobj = new airwaybillinfo();
  flightobj = new shipmentinfo();
  aspectsobj = new damageinfo();
  assessmentobj = new investigation();
  uploadpicsobj = new Uploadpicdoc();
  rootCauseObj = new RootCauseModel();
  showflighttrucknum: boolean;
  airlinedata: any;
  flightshipmentdata: any;
  masterAwbNo1: any;
  airlineId: any;
  originAirportId: any;
  destairportId: any;
  flight0: any;
  flight1: any;
  flight2: any;
  awb: boolean;
  showitemssq: boolean;
  show998: boolean;
  items: any;
  showListair: boolean;
  showList: boolean;
  originport: boolean;
  showList1: boolean;
  destport: boolean;
  duringbuildup: boolean;
  duringstorage: boolean;
  duringloading: boolean;
  external: boolean;
  showbelowfields: boolean;
  showotheraspectsobj: boolean;
  showotherinternalArea: boolean;
  showothermethodPacking: boolean;
  showotherdamagePacking: boolean;
  showPiecesAffected: boolean;
  showCondition: boolean = false;
  showItemsMissing: boolean;
  showotherconditioncontent: boolean;
  showotherrecuperatoinStatus: boolean;
  dd: string;
  imageLists: any;
  reVerifyMail: any
  items1: any
  itemsair: any;
  awb1: string;
  damagepresent: boolean;
  mpa: string;
  rsa: string;
  cca: string;
  dpa: string;
  damageCategoryItems: any[];
  imageslist: any[];
  othermppresent: boolean;
  otherdppresent: boolean;
  otherccpresent: boolean;
  otherrqpresent: boolean;
  othermethodpacking: string;
  otherdamagepacking: string;
  otherconditioncontent: string;
  otherrecuperatoinStatus: string;
  showotherprocessstage: boolean;
  otherprocessstage: any;
  otherprocessarea: any;
  currentStatusInputObj: any;
  affectedAwbarray: any;
  showviewreports: boolean;
  showlogin: boolean;
  verifymail: any;
  showotpfield: boolean;
  showverifybutton: boolean;
  otp: any;
  showListstation: boolean;
  showstationwithoutfilter: boolean;
  showstationfilter: boolean;
  emailid: any;
  val: string;
  userdetailswithoutlogin: any;
  userridd: any;
  firstnname: any;
  showrecoveryactions: boolean;
  damageDiscoveredstore: any;
  internalAreastore: any;
  stationCodestore: any;
  isnotCheckedTd: boolean;
  forgotEmail: any;
  istermsAndCond_chked: string;
  checkedT_d: boolean;
  items3: any;
  DummyMawb: any;
  stationcodeData: any;
  isAirportCodeReadOnly: boolean;
  isOrgNameFilled: boolean;
  prevSelectedStCode: any;
  isshowListstation: boolean;
  selectedAddress: any;
  split: any;
  verifyForgotEmailForm: FormGroup;
  suffixAwbNo: any;
  loginComponent: LoginComponent;
  userCheckEmailId: any;
  userCheckConfirmEmailId: any;

  secondTB_COmp: boolean = false;
  thirdTB_COmp: boolean = false;
  firstTB_COmp: boolean = false;
  fourthTB_COmp: boolean = false;
  fifthTB_COmp: boolean = false;
  sixthTB_COmp: boolean = false;

  loginuserdetailsTest: any;
  affectedAwbTempArray: any[];
  checkUserForm: FormGroup;
  isUserExist: boolean;
  isUserMailExist: boolean;
  userExistedData: any;
  is_From: any;
  edit_Report_Data: any;
  ReportsProductionUrl: string;
  damage_Report_Images: any;
  urls: any;
  urlArrayTotal: any;
  isMawb_ReadOnly: boolean;
  isSave_Enable: boolean;
  is_From_sideReports: any;
  from_csaDashBoard: any;
  from_view_Users: any;
  from_userDashboard: any;
  eachReport: any;
  modifiedReports: any;
  emailStatus: any;
  SelectedawbNumber: string;
  LoginDetails: any;
  is_AirlineFeild_Readonly: boolean;
  isFlight_Selected_Frm_List: boolean;
  firstTabHide: boolean;
  firstFormGroup: any;
  secondFormGroup: any;
  fourthFormGroup: any;
  fifthFormGroup: any;
  thirdFormGroup: any;
  sixthFormGroup: any;
  registrationForm: FormGroup;
  registrationEmailForm: FormGroup;
  registrationOtpForm: FormGroup;
  registerForm: FormGroup;
  stationCodeFormcontrolError: boolean;
  orgNameFormcontrolError: boolean;
  showStationsDropDown: boolean;
  otppPage: boolean;
  emailPage: boolean;
  registratonPage: boolean;
  codeList: any;
  OrgName_selectedFrm_List: boolean;

  Multile_Status_HandlerOrgsArray: any[];
  Multile_Status_AgentOrgsArray: any[];
  OrgNamesValue: any[];
  Multile_Status_AirlineOrgsArray: any[];
  fieldnameport_Code: boolean;
  allStationsActive: boolean;
  codeListTemp: any;
  allStationsDetails: any;

  TEMPORARY_CODELIST: any;
  OrgNameReadonly: boolean;
  stationCodeReadOnly: boolean;
  AddressRedonly: boolean;
  organizationInputChange: boolean;
  airlinceComapniesData: any;
  reasonType: any;
  org_TypeTemp: string;
  orgName_Temp: any;
  OrgNameValue: any;
  singleOrgStations: any;
  singleOrgName: any;
  OrgNameFiledChange: boolean;
  OrgNamesValueTemp: any;
  codeValue: string;
  OrgNameValueTemp: any;
  is_StationCode_SelectFrmList: boolean;
  ResultStations: any;
  stationslist: any;
  address2ReadOnly: boolean;
  cityReadOnly: boolean;
  countryReadOnly: boolean;
  postalCodeReadOnly: boolean;
  selectedStation: any;
  stationCode_Not_Frm_list_err: boolean;
  isListEnable: boolean;
  OrgNamesValueDuplicates: any[];
  orgType_temp: any;
  isUnAuthReportAccess: boolean;
  existedReportData: any;
  isSavedOnce: boolean;
  rememberMeStatus: boolean;
  navigationEnable: boolean = false;
  apiCallCurrentTab: any;
  isFromNextButton: boolean;
  isThirdTabHasChanges: boolean;
  isSecondTabHasChanges: boolean;
  isFirstTabHasChanges: boolean;
  error: boolean;
  stepFilled: boolean;
  isVerifyingawb: boolean;
  SelectedawbNumber_ReportId: any;
  otherbehaviorpresent: boolean;
  show_behaviour_Other: boolean;
  otherBehaviour_Model: string;
  otherEquipmentpresent: boolean;
  show_Equipment_Other: boolean;
  otherEquipment_Model: string;
  otherRulespresent: boolean;
  behaviourpresent: boolean;
  equipmentpresent: boolean;
  rulespresent: boolean;
  show_rules_Other: boolean;
  otherrules_Model: string;
  only_scratches_tears_dents: any;
  isDamageToPackingDisable: boolean = true;
  isItemMissingDisabled: boolean = true;
  isUnlikelyHidden: boolean;
  is_Behaviour_And_Other_FieldsEnable: boolean =true;
  isExtraTabEnable: boolean;
  show_Organisation_Other: boolean;
  otherOrganisation_Model: string;
  show_CapaType_Other: boolean;
  otherCapaType_Model: string;
  confirmSendStatus = 'N';
  hitTypeValue;
  updateTypeValue;
  existedPopup = true;
  storeModuleNumber:any;


  capaType_ArrayDuplicate:any =[]
  //--- date picker options -----//
  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'DD-MM-YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown

  };

  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'DD-MM-YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,

    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker1', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  options2: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'DD-MM-YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    minDate: new Date(this.minDate()) ,
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker2', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  showitemPrefix: boolean;
  selectedPrefixId: string;
  iscarrier_Selected_Frm_List: boolean;
  prefixArray = [];

minDate() : string {
  var today ,dd ,mm
   today = new Date();
   dd = today.getDate() - 1;
   mm = today.getMonth()+1; 
  var yyyy = today.getFullYear();
  if(dd<10){
    dd='0'+dd
  } 
  if(mm<10){
    mm='0'+mm
  } 
 return  today = yyyy+'-'+mm+'-'+dd;
}
 

  //---------Initialize Input drop downs ---------//
  methodpackingarray = [
    { name: 'Bags', id: 1, checked: false },
    { name: 'Barrel', id: 2, checked: false },
    { name: 'Carton box', id: 3, checked: false },
    { name: 'Container', id: 4, checked: false },
    { name: 'Crate', id: 5, checked: false },
    { name: 'Frame', id: 6, checked: false },
    { name: 'Suitcase', id: 7, checked: false },
    { name: 'ULD', id: 8, checked: false },
    { name: 'Wood pallet', id: 9, checked: false },
    { name: 'Pouch (airline)', id: 10, checked: false },
    { name: 'Pouch (agent)', id: 11, checked: false },
    { name: 'Other', id: 12, checked: false }
  ];




  damagepackingarray = [
    { name: 'Broken', id: 1, checked: false },
    { name: 'collapsed', id: 17, checked: false },
    { name: 'Crushed', id: 2, checked: false },
    { name: 'Damp', id: 3, checked: false },
    { name: 'Dented', id: 4, checked: false },
    { name: 'Hole in', id: 5, checked: false },
    { name: 'Open', id: 6, checked: false },
    { name: 'Item missing', id: 7, checked: false },
    { name: 'Seal open / broken', id: 8, checked: false },
    { name: 'Shock watch', id: 9, checked: false },

    { name: 'Tape loose and/or torn', id: 10, checked: false },
    // { name: 'Tape torn', id: 11, checked: false },
    // { name: 'Temperature control', id: 12, checked: false },
    // { name: 'Tip and tell indicator', id: 13, checked: false },
    { name: 'Torn', id: 14, checked: false },
    { name: 'Wet', id: 15, checked: false },
    { name: 'Other', id: 16, checked: false }
  ];



  conditioncontentarray = [
    { name: 'Broken', id: 1, checked: false },
    { name: 'Crushed', id: 2, checked: false },
    { name: 'Damp', id: 3, checked: false },
    { name: 'Dented', id: 4, checked: false },
    { name: 'Destroyed', id: 5, checked: false },

    { name: 'Empty', id: 6, checked: false },
    { name: 'Injured [AVI]', id: 7, checked: false },
    { name: 'Leaking', id: 8, checked: false },
    { name: 'Overheated', id: 9, checked: false },

    { name: 'Sick [AVI]', id: 10, checked: false },
    { name: 'Soiled', id: 11, checked: false },
    { name: 'Mortality [AVI]', id: 12, checked: false },
    { name: 'Wet', id: 13, checked: false },

    { name: 'Other', id: 14, checked: false }
  ];
  behaviour_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
  rules_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
  equipments_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
 organisation_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
 capaType_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]



  recuparationstatusarray = [
    { name: 'Contents destroyed', id: 1, checked: false },
    { name: 'Contents repacked', id: 2, checked: false },
    { name: 'No lack of time', id: 3, checked: false },
    { name: 'Package corded', id: 4, checked: false },
    { name: 'Package sealed', id: 5, checked: false },
    { name: 'Package strapped', id: 6, checked: false },
    { name: 'Package taped', id: 7, checked: false },
    { name: 'Other', id: 9, checked: false }
  ];

  


  constructor(private sanitizer: DomSanitizer, private toastr: ToastrService, private cookieService: CookieService, private navbarComponent: NavbarComponent, private DamageReportServicel: CreatereportService, private atp: AmazingTimePickerService,
    private datePipe: DatePipe, private ViewReportsData: ViewReportsData, public route: Router, public activatedRoute: ActivatedRoute, public toast: ToastrService,
    private spinner: NgxSpinnerService, private LoginDataService: LoginDataService, public shareServc: ShareLoginDataService, public loginServc: LoginService,
    private myAccountService: MyAccountService, private SidebarComponent: SidebarComponent, private fb: FormBuilder, private forgotPwdComponent: ForgotpasswordComponent,
    private superAdminservice: SuperadminService, private location: Location, private _compiler: Compiler, private StationService: StationService, private RegisterService: RegisterService, private locationStr: LocationStrategy,private config : ConfigServiceService) {
    this.loginComponent = new LoginComponent(cookieService, shareServc, SidebarComponent, LoginDataService, route, toast, loginServc, spinner);
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
  }

  matchPath(storeroute){
    // <-- Handlers and airlines routes -->
    if(storeroute.match('admin-handler-dashboard')){
        return 1;
    } 
    else if(storeroute.match('csa-dashboard')){
        return 1;
    }
    else if(storeroute.match('csa-airline-dashboard')){
        return 1;
    } 
    else if(storeroute.match('admin-airline-dashboard')){
        return 1;
    }
    else if(storeroute.match('user-dashboard')){
        return 1;
    }
    else if(storeroute.match('viewdetails')){
        return 1;
    }else if(storeroute.match('save-report')){
        return 1;
    }else if(storeroute.match('Uld')){
        return 2;
    }else if(storeroute.match('CargoImageHandler')){
        return 3;
    }else if(storeroute.match('Truckinspection')){  //also for forwarders
        return 4;
    }else if(storeroute.match('cargoClaim')){   //alse for forwarders
        return 5;
    }else if (storeroute.match('csa-service-recovery')){
        return 7
    }else if(storeroute.match('damageCustomerReport')){
        return 8;
    }
    // <-- forwarders routes -->
    else if(storeroute.match('cargoDamageForwarder')){
        return 1;
    }else if(storeroute.match('CargoImage')){
        return 2;
    } else if(storeroute.match('CargoAcceptance/view-cargo-acceptance')){
        return 3;
    }
    else if(storeroute.match('CargoAcceptance/view-import-acceptance')){
        return 6;
    }
    
    else {
        return 0;
    }
}
  
  ngOnInit() {

    const storeroute = this.route.url;
    this.storeModuleNumber =  this.matchPath(storeroute)   
    localStorage.setItem('bookmarkNumber', JSON.stringify(this.storeModuleNumber));

    this.error = true;
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"))
    this.imageslist = []
    SaveReportsComponent._imagesArrayTotal = []
    this.stationCodeFormcontrolError = false;
    this.codeList = [];
    this.orgNameFormcontrolError = false;
    this.showStationsDropDown = false;
    window.scroll(0, 0);
    this.otppPage = false;
    this.emailPage = true;
    this.registratonPage = false;
    this.firstFormGroup;
    this.secondFormGroup;
    this.fourthFormGroup;
    this.fifthFormGroup;
    this.thirdFormGroup;
    this.sixthFormGroup;

    //-----register----//
    localStorage.setItem("isSaveHitDOne", JSON.stringify("no"));

    this.registrationEmailForm = this.fb.group({
      'emailId': new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
    });

    this.registrationOtpForm = this.fb.group({
      'otpCode': new FormControl('', [Validators.required, Validators.minLength(6)]),
    });


    // registerForm
    this.registerForm = this.fb.group({
      "organisationName": new FormControl({ value: '', disabled: false }, [Validators.required]),
      "orgType": new FormControl('', [Validators.required]),
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "stationCode": new FormControl('', [Validators.required, this.stationFormcontrolError.bind(this)]),
      "address": new FormControl(''),
      "password": new FormControl('', [Validators.required, Validators.pattern(".{6,}"), Validators.maxLength(20), Validators.minLength(6)]),
      "cpassword": new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(6)]),
      "jobTitle": new FormControl(''),
      "address2": new FormControl(''),
      "city": new FormControl(''),
      "country": new FormControl(''),
      "postalCode": new FormControl(''),
      "carrierId": new FormControl(''),
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword
      }
    );

    this.registerForm.controls['password'].valueChanges.subscribe(data => {
      this.registerForm.controls['cpassword'].setValue('');
      this.registerForm.controls['cpassword'].updateValueAndValidity();
    });
    //-----//
    this._compiler.clearCache();
    localStorage.removeItem('conditioncontentarrayL');
    localStorage.removeItem('damagepackingarrayL');
    localStorage.removeItem('recuparationstatusarrayL');

    this.ReportsProductionUrl = this.config.getConfig().URL
    const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.loginuserdetailsTest = JSON.parse(localStorage.getItem('loginresultobject'));
    this.shipmentobj.categoryId = '10001';
    this.shipmentobj.iswebormobile = '';
    this.shipmentobj.userId = '';
    this.shipmentobj.organizationId = '';
    this.shipmentobj.incidentDate = '';
    this.shipmentobj.incidentTime = '';
    this.shipmentobj.masterAwbNo = '';
    this.shipmentobj.emailId = '';
    this.shipmentobj.address = '';
    this.shipmentobj.stationCode = '';
    this.shipmentobj.reportPreparedName = '';
    this.shipmentobj.reportPreparedFunction = '';
    this.shipmentobj.privacyStatus = '';
    this.shipmentobj.orgName = '';
    
    this.airwaybillobj.categoryId = '10002';
    this.airwaybillobj.userId = '';
    this.airwaybillobj.masterAwbNo = '';
    this.airwaybillobj.airlineId = '';
    this.airwaybillobj.movementType = '';
    this.airwaybillobj.truckType = '';
    this.airwaybillobj.flightTruckNo = '';
    this.airwaybillobj.flightDate = '';
    this.airwaybillobj.originAirportId = '';
    this.airwaybillobj.destairportId = '';
    this.airwaybillobj.trucking_flight_info = '' ;
    this.airwaybillobj.flightIssuingAgent = '' ;
    this.airwaybillobj.rootcauseExists = '';
    this.airwaybillobj.rootcauseSendstatus = '';
    this.airwaybillobj.reportOrgRootcauseStatus = '';
    this.airwaybillobj.orgRootcauseStatus = '';

    this.flightobj.categoryId = '10003'
    this.flightobj.userId = '';
    this.flightobj.masterAwbNo = '';
    this.flightobj.contentNature = '';
    this.flightobj.comodityNote = '';
    this.flightobj.mawbTotalpcs = '';
    this.flightobj.mawbTotalWeight = '';
    this.flightobj.actualReceivedPcs = '';
    this.flightobj.actualRevicedWeight = '';
    this.flightobj.shipper = '';
    this.flightobj.consignee = '';
    this.flightobj.issuingAgent = '';

    this.aspectsobj.categoryId = '10004'
    this.aspectsobj.userId = '';
    this.aspectsobj.masterAwbNo = '';
    this.aspectsobj.methodPacking = '';
    this.aspectsobj.identificationNo = '';
    this.aspectsobj.damagePacking = '';
    this.aspectsobj.contentAffected = '';
    this.aspectsobj.contentPieces = '';
    this.aspectsobj.contentWeight = '';
    this.aspectsobj.itemMissing = '';
    this.aspectsobj.itemPcs = '';
    this.aspectsobj.itemWeight = '';
    this.aspectsobj.suspectPilf = '';
    this.aspectsobj.safetyLife = '';
    this.aspectsobj.conditioncontent = '';
    this.aspectsobj.recuperationReq = '';
    this.aspectsobj.recuperatoinStatus = '';
    this.aspectsobj.affectedAwb = '';
    this.aspectsobj.classificationType = '';
    this.aspectsobj.dentsOrTears = '';

    this.assessmentobj.categoryId = '10005';
    this.assessmentobj.userId = '';
    this.assessmentobj.masterAwbNo = '';
    this.assessmentobj.damageDiscovered = '';
    this.assessmentobj.discoveresCaused = '';
    this.assessmentobj.internalName = '';
    this.assessmentobj.internalGroup = '';
    this.assessmentobj.internalcode = '';
    this.assessmentobj.internalResponsibility = '';
    this.assessmentobj.internalArea = '';
    this.assessmentobj.areaSupervisor = '';
    this.assessmentobj.exactLocation = '';
    this.assessmentobj.locatonId = '';
    this.assessmentobj.casueOfEvent = '';
   







    this.uploadpicsobj.categoryId = '10006';
    this.uploadpicsobj.userId = '';
    this.uploadpicsobj.masterAwbNo = '';
    this.uploadpicsobj.damageImage = '';
    this.uploadpicsobj.oldDamageImages = '';

    this.rootCauseObj.categoryId ="10007";
    this.rootCauseObj.userId ='';
    this.rootCauseObj.masterAwbNo ='';
    this.rootCauseObj.behaviourId ='';
    this.rootCauseObj.behaviourOther ='';
    this.rootCauseObj.rulesId ='';
    this.rootCauseObj.rulesOther ='';
    this.rootCauseObj.equipmentId ='';
    this.rootCauseObj.equipmentOther ='';
    this.rootCauseObj.organisationId ='';
    this.rootCauseObj.organisationOther ='';
    this.rootCauseObj.rootCauseInvestigation ='';
    this.rootCauseObj.correctiveActions ='';
    this.rootCauseObj.capaTypeId ='';
    this.rootCauseObj.capaTypeOther ='';
    this.rootCauseObj.evaluationDone ='';
    this.rootCauseObj.evaluationDate ='';
    this.rootCauseObj.organisationDesc = '';

    if (loginuserdetails != null && loginuserdetails != '' && loginuserdetails != undefined) {
      this.activatedRoute.queryParams.subscribe(
        params => {
          this.is_From = params['isFrom'];
          this.is_From_sideReports = params["is_sideReports"];
          this.from_csaDashBoard = params["from_csaDashBoard"];
          this.from_view_Users = params["from_view_Users"];
          this.from_userDashboard = params["from_userDashboard"];
          
          if (this.is_From === 'EDIT_REPORT') {
            this.is_AirlineFeild_Readonly = true;
            this.firstTabHide = true;
            this.secondTB_COmp = true;
            this.thirdTB_COmp = true;
            this.firstTB_COmp = true;
            this.fourthTB_COmp = true;
            this.fifthTB_COmp = true;
            this.sixthTB_COmp = true;
          } else {
            this.is_AirlineFeild_Readonly = false;
            this.firstTabHide = true;
            this.secondTB_COmp = false;
            this.thirdTB_COmp = false;
            this.firstTB_COmp = false;
            this.fourthTB_COmp = false;
            this.fifthTB_COmp = false;
            this.sixthTB_COmp = false;

          }
        });

    } else {
      this.is_From = 'CREATE_REPORT'
      this.is_AirlineFeild_Readonly = false;
      this.firstTabHide = false;
      this.secondTB_COmp = true;
      this.thirdTB_COmp = true;
      this.firstTB_COmp = true;
      this.fourthTB_COmp = true;
      this.fifthTB_COmp = true;
      this.sixthTB_COmp = true;
    }

    //-----------------------------airwaybill----------------------------------------
    this.showflighttrucknum = false;
    this.airwaybillobj.truckType = 'flight';
    this.airwaybillobj.flightDate = new Date().toISOString();
    this.rootCauseObj.evaluationDate = new Date().toISOString();

    this.spinner.show();
    this.DamageReportServicel.getTruckAirlineDetailsService().subscribe(
      data => {

        if (data.responseCode === 1) {
          this.airlinedata = data.airline_details;
          localStorage.setItem('airlinedata', JSON.stringify(data.airline_details));
          if (this.is_From === 'EDIT_REPORT') {
            this.masterAwbNochange_update();
          }

        } else {
        }
      },
      error => {
        this.spinner.hide();
      });
    this.DamageReportServicel.getTruckAirportService().subscribe(
      data => {
        this.spinner.hide();
        if (data.responseCode === 1) {
          this.flightshipmentdata = data.airport_details;
          localStorage.setItem('flightshipmentdata', JSON.stringify(data.airport_details));
          localStorage.setItem('stationcodeData', JSON.stringify(data.airport_details));
          localStorage.setItem('airportdataPREV', JSON.stringify(data.airport_details));
          const airwaybillobject = JSON.parse(localStorage.getItem('airwaybillobject'));
          if (airwaybillobject !== null) {
            this.airwaybillobj = airwaybillobject;
            const split = this.airwaybillobj.masterAwbNo.split('-');
            this.airwaybillobj.masterAwbNo = split[0];
            this.masterAwbNo1 = split[1];
            this.spacecall(this.masterAwbNo1);
          }
        } else {
        }
      },
      error => {

      });
    //-----------------------------airwaybill----------------------------------------




    this.apiCallCurrentTab = 'first';
    window.scroll(0, 0);
    this.checkUserForm = this.create_formGroup();
    this.isMawb_ReadOnly = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.isOrgNameFilled = true;
    this.isAlredyExistErr = false;
    this.istermsAndCond_chked = 'N';
    this.checkedT_d = false;
    this.isUserExist = false;
    this.isUserMailExist = false;
    this.isSave_Enable = false;


    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
      passcode: new FormControl('', [Validators.required, Validators.minLength(6),]),
    });
    this.verifyForgotEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
    });
    this.verifyEmailForm = new FormGroup({
      verifymail: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
      reVerifyMail: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
    });
    this.shipmentobj.incidentDate = new Date();
    this.airwaybillobj.flightDate = new Date();
    this.shipmentobj.incidentTime = new Date();
    this.rootCauseObj.evaluationDate = new Date();
    this.shipmentobj.incidentTime = this.datePipe.transform(this.shipmentobj.incidentTime, 'H:mm');
    this.affectedAwbarray = [{
      awb: ''
    }];


    if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
      this.showviewreports = false;
      this.showlogin = true;
      this.showstationfilter = true;
      this.showstationwithoutfilter = false;
      this.selectionStatus = false;
    } else {
      if (loginuserdetails.orgType === 'H' || loginuserdetails.orgType === 'A' || this.is_From === 'EDIT_REPORT') {
        if (this.is_From === 'EDIT_REPORT') {
          this.firstTabHide = true;
          this.editReport("edit", "notSaved");
        }
        else {
          this.firstTabHide = true;
          this.selectionStatus = true;
          this.showstationfilter = false;
          this.showstationwithoutfilter = true;
          this.showviewreports = true;
          this.showlogin = false;
          this.shipmentobj.reportPreparedName = loginuserdetails.firstName;
          this.shipmentobj.stationCode = loginuserdetails.stationCode;
          this.shipmentobj.reportPreparedFunction = loginuserdetails.jobTitle;
          this.shipmentobj.userId = loginuserdetails.userId;
          this.airwaybillobj.userId = loginuserdetails.userId;
          this.flightobj.userId = loginuserdetails.userId;
          this.assessmentobj.userId = loginuserdetails.userId;
          this.aspectsobj.userId = loginuserdetails.userId;
          this.uploadpicsobj.userId = loginuserdetails.userId;
          this.rootCauseObj.userId = loginuserdetails.userId;
          this.emailid = loginuserdetails.emailId;
          this.shipmentobj.orgName = loginuserdetails.organisationName;
          this.shipmentobj.organizationId = loginuserdetails.orgId;
          this.airwaybillobj.masterAwbNo = '';
          this.masterAwbNo1 = '';
          this.flight1 = '';
          this.flight2 = '';
          this.airlineId = '';
          this.airwaybillobj.truckType = 'flight';
          this.airwaybillobj.movementType = '';
          this.originAirportId = '';
          this.destairportId = '';
          this.flightobj.mawbTotalpcs = '';
          this.flightobj.actualReceivedPcs = '';
          this.flightobj.contentNature = '';
          this.flightobj.shipper = '';
          this.flightobj.consignee = '';
          this.flightobj.issuingAgent = '';
          this.aspectsobj.methodPacking = '';
          this.othermethodpacking = '';
          this.aspectsobj.identificationNo = '';
          this.aspectsobj.damagePacking = '';
          this.otherdamagepacking = '';
          this.aspectsobj.contentAffected = '';
          this.aspectsobj.contentAffected = '';
          this.aspectsobj.contentPieces = '';
          this.aspectsobj.itemWeight = '';
          this.aspectsobj.itemMissing = '';
          this.aspectsobj.itemPcs = '';
          this.aspectsobj.suspectPilf = '';
          this.aspectsobj.safetyLife = '';
          this.aspectsobj.conditioncontent = '';
          this.otherconditioncontent = '';
          this.aspectsobj.recuperationReq = '';
          this.aspectsobj.recuperatoinStatus = '';
          this.aspectsobj.dentsOrTears = '';
          this.otherrecuperatoinStatus = '';

          this.assessmentobj.discoveresCaused = '';
          this.assessmentobj.internalName = '';
          this.assessmentobj.internalGroup = '';
          this.assessmentobj.damageDiscovered = '';
          this.damageDiscoveredstore = '';
          this.otherprocessstage = '';
          this.assessmentobj.internalResponsibility = '';
          this.rootCauseObj.behaviourId = '';   //neww
          this.rootCauseObj.behaviourOther = '';
          this.rootCauseObj.rulesId = '';
          this.rootCauseObj.rulesOther = '';
          this.rootCauseObj.equipmentId = '';
          this.rootCauseObj.equipmentOther = '';
          this.rootCauseObj.organisationDesc = '';
          this.rootCauseObj.organisationId = '';
          this.rootCauseObj.organisationOther = '';
          this.isMawb_ReadOnly = false;
          this.checkStatus = false;
        }
      } else if (this.is_From != 'EDIT_REPORT_SUCCESS') {
        this.route.navigateByUrl("/unAuthorized")
      } else if (this.is_From === 'EDIT_REPORT_SUCCESS') {

        this.isSave_Enable = true;
      }


    }
    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
    if (remmeberData !== null && remmeberData !== undefined && remmeberData !== undefined) {
      this.loginForm.get("username").patchValue(remmeberData.emailId)
      this.loginForm.get("passcode").patchValue(remmeberData.pwd)
    }


    this.fn_To_Get_Equipment_Behaviour_Rules_Data();
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
 
  fn_To_Get_Equipment_Behaviour_Rules_Data() {
    this.DamageReportServicel.get_Equip_rule_behaviourData().subscribe(responseData => {
      
      if (responseData.responseCode === 1) {
        this.equipments_Array = responseData.equipment;
        this.rules_Array = responseData.rules;
        this.behaviour_Array = responseData.behaviour;
        this.organisation_Array = responseData.organisation;
        this.capaType_Array = responseData.capaType;
        

          this.equipments_Array.forEach(element => {
            element.checked = false
          });

          this.organisation_Array.forEach(element => {
            element.checked = false
          });

         this.capaType_Array.forEach(element => {
            element.checked = false
          });

          this.rules_Array.forEach(element => {
            element.checked = false
          });

           this.behaviour_Array.forEach(element => {
              element.checked = false
             });




        let rulesIdsArray = '' ;
        let behaviourIdsArray = '' ;
        let equipmentIdsArray = '' ;
        let organisationIdsArray = '' ;
        let capaIdsArray = '' ;

        if(this.edit_Report_Data !== null && this.edit_Report_Data !== undefined && this.edit_Report_Data !== ''){


        if(this.edit_Report_Data.rulesId !== null && this.edit_Report_Data.rulesId !== undefined){
        rulesIdsArray = this.edit_Report_Data.rulesId.split(",") ;
        }
        if(this.edit_Report_Data.behaviourId !== null && this.edit_Report_Data.behaviourId !== undefined){
          behaviourIdsArray = this.edit_Report_Data.behaviourId.split(",") ;
        }
        if(this.edit_Report_Data.equipmentId !== null && this.edit_Report_Data.equipmentId !== undefined){
        equipmentIdsArray = this.edit_Report_Data.equipmentId.split(",") ;
        }
        if(this.edit_Report_Data.organisationId !== null && this.edit_Report_Data.organisationId !== undefined){
          organisationIdsArray = this.edit_Report_Data.organisationId.split(",") ;
        }
        if(this.edit_Report_Data.capaTypeId !== null && this.edit_Report_Data.capaTypeId !== undefined){
          capaIdsArray = this.edit_Report_Data.capaTypeId.split(",") ;
        }

if(this.rules_Array !== null&& this.rules_Array!==  undefined){
  this.rules_Array.forEach(element=>{
    if(rulesIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.rules_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}


if(this.behaviour_Array !== null&& this.behaviour_Array !==  undefined){
  this.behaviour_Array.forEach(element=>{
    if(behaviourIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.behaviour_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}

if(this.equipments_Array !== null&& this.equipments_Array !==  undefined){
  this.equipments_Array.forEach(element=>{
    if(equipmentIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.equipment_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}
if(this.organisation_Array !== null&& this.organisation_Array !==  undefined){
  this.organisation_Array.forEach(element=>{
    if(organisationIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.organisation_ArrayDuplicate.push(Object.assign({},element))
    }
  })
} 
if(this.capaType_Array !== null&& this.capaType_Array !==  undefined){
  this.capaType_Array.forEach(element=>{
    if(capaIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.capaType_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}

        }

      } else {

      }
    }, error => {

    })
  }




  editReport(type, saveType) {

    console.log("sfffds");

    return false;
     
    if (type !== 'view') {
      this.isVerifyingawb = false;
    } else {
      this.isVerifyingawb = true;
    }
    this.spinner.show();
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this.SelectedawbNumber = JSON.parse(localStorage.getItem('awbNumber'));
    this.SelectedawbNumber_ReportId = JSON.parse(localStorage.getItem('awbNumber_reportId'));
    let obj
    if (this.LoginDetails != null && this.LoginDetails != undefined && this.LoginDetails != '') {
      this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
      obj = {
        "loginUserId": this.LoginDetails.userId,
        "awbNumber": this.SelectedawbNumber,
        "orgIdNumber": this.LoginDetails.orgId,
        "reportId": (type !== 'view' ? (this.SelectedawbNumber_ReportId) : undefined)
      }
      this.firstTabHide = true;
    } else {
      obj = {
        "loginUserId": JSON.parse(localStorage.getItem('withoutlogin_userID')),
        "awbNumber": this.SelectedawbNumber,
        "orgIdNumber": this.LoginDetails.orgId,
        "reportId": (type !== 'view' ? (this.SelectedawbNumber_ReportId) : undefined)
      }
    }
    this.fetch();
    obj.type = type;
    this.superAdminservice.viewDetails(obj).subscribe(data => {

      if (data.responseCode === 1) {
        if(data.damageReport.airlineName !== undefined){
          this.airwaybillobj.airlineId = data.damageReport.airlineName;
        }
        this.spinner.hide();
        this.isVerifyingawb = false;
        this.isUnAuthReportAccess = false;
        if (data.damageReport.prefixCarrierId !== null && data.damageReport.prefixCarrierId !== undefined && data.damageReport.prefixCarrierId !== '') {
          this.selectedPrefixId = data.damageReport.prefixCarrierId;
        }
        if (type === 'view') {

          if (data.newOrExisted === 'E' && data.damageReport.length === 0) { //--->Report exised but not accessible

            jQuery('#reportExistedPopup').modal({ backdrop: 'static', keyboard: false });
            this.existedPopup = true;
            this.isUnAuthReportAccess = true;
            return false
          } else if (data.newOrExisted === 'E' && data.damageReport.length !== 0) {

            if (saveType === "saveDone" || saveType === "savenotDone") {
              this.isMawb_ReadOnly = false;

              // this.is_From = 'EDIT_REPORT';
              this.existedReportData = data;
              if (this.existedReportData.damageReport) {
                for(let report of this.existedReportData.damageReport) {
                  report.originAirport = report.originAirportName.split('-')[1];
                  report.destAirport = report.destAirportName.split('-')[1]
                  
                }
              }
              this.isUnAuthReportAccess = false;

              this.editData_BindFun(this.existedReportData, saveType)
            } else {
              this.isMawb_ReadOnly = false;

              // this.is_From = 'EDIT_REPORT';
              this.existedReportData = data;
              if (this.existedReportData.damageReport) {
                for(let report of this.existedReportData.damageReport) {
                  report.originAirport = report.originAirportName.split('-')[1];
                  report.destAirport = report.destAirportName.split('-')[1]
                }
              }
              jQuery('#reportExistedConfirm_Popup').modal({ backdrop: 'static', keyboard: false });
              this.existedPopup = true;
            }

          } else if (data.newOrExisted === 'N' && data.damageReport.length === 0) {
            // this.is_From = 'CREATE_REPORT';

            this.selectionStatus = true;
            this.showstationfilter = false;
            this.showstationwithoutfilter = true;
            this.showviewreports = true;
            this.showlogin = false;
            this.shipmentobj.reportPreparedName = this.LoginDetails.firstName;
            this.shipmentobj.stationCode = this.LoginDetails.stationCode;
            this.shipmentobj.reportPreparedFunction = this.LoginDetails.jobTitle;
            this.shipmentobj.userId = this.LoginDetails.userId;
            this.airwaybillobj.userId = this.LoginDetails.userId;

            this.flightobj.userId = this.LoginDetails.userId;
            this.assessmentobj.userId = this.LoginDetails.userId;
            this.aspectsobj.userId = this.LoginDetails.userId;
            this.uploadpicsobj.userId = this.LoginDetails.userId;
            this.rootCauseObj.userId = this.LoginDetails.userId;
            this.emailid = this.LoginDetails.emailId;
            this.shipmentobj.orgName = this.LoginDetails.organisationName;
            this.shipmentobj.organizationId = this.LoginDetails.orgId;

            this.airwaybillobj.truckType = 'flight';

            // this.originAirportId = ''
            // this.destairportId = ''
            this.isMawb_ReadOnly = false;
            this.checkStatus = false
            this.secondTB_COmp = false;
            this.thirdTB_COmp = false;
            this.firstTB_COmp = false;
            this.fourthTB_COmp = false;
            this.fifthTB_COmp = false;
            this.sixthTB_COmp = false;
          }
          this.tabModelVAlidationCheck('firstTab');
        } else {
          if (data.damageReport.length != 0) {
            this.isMawb_ReadOnly = false;  /// changed
            this.editData_BindFun(data.damageReport, "notSaved")
          } else {
            this.is_From = 'CREATE_REPORT';
            this.selectionStatus = true;
            this.showstationfilter = false;
            this.showstationwithoutfilter = true;
            this.showviewreports = true;
            this.showlogin = false;
            this.shipmentobj.reportPreparedName = this.LoginDetails.firstName;
            this.shipmentobj.stationCode = this.LoginDetails.stationCode;
            this.shipmentobj.reportPreparedFunction = this.LoginDetails.jobTitle;
            this.shipmentobj.userId = this.LoginDetails.userId;
            this.airwaybillobj.userId = this.LoginDetails.userId;
            this.flightobj.userId = this.LoginDetails.userId;
            this.assessmentobj.userId = this.LoginDetails.userId;
            this.aspectsobj.userId = this.LoginDetails.userId;
            this.uploadpicsobj.userId = this.LoginDetails.userId;
            this.rootCauseObj.userId = this.LoginDetails.userId;
            
            this.emailid = this.LoginDetails.emailId;
            this.shipmentobj.orgName = this.LoginDetails.organisationName;
            this.shipmentobj.organizationId = this.LoginDetails.orgId;

            this.airwaybillobj.truckType = 'flight';

            this.isMawb_ReadOnly = false;
            this.checkStatus = false

            this.secondTB_COmp = false;
            this.thirdTB_COmp = true;
            this.firstTB_COmp = false;
            this.fourthTB_COmp = true;
            this.fifthTB_COmp = true;
            this.sixthTB_COmp = true;
          }

        }
        this.spinner.hide();
      } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
        this.toastr.info("Your session expired, please login again");
        this.spinner.hide();
      } else {
        this.toastr.error(data.message, '', { timeOut: 1500 });
        this.spinner.hide();
      }

    }, error => {
      this.spinner.hide();
    });
  }

   // =================iwi 12062024| start===================================

   fetch() {
    this.spinner.show();
    this.initializeItems2();
    let payload = {
      awb_no:this.SelectedawbNumber,
      loginUserId:  this.LoginDetails.userId,
      orgId:this.LoginDetails.orgId,
      orderType:JSON.parse(localStorage.getItem("ordType")),
      loginUserType:this.LoginDetails.userType,
      orgType:this.LoginDetails.orgType,
      master_awb_no:this.LoginDetails.awbPrefix
    };
    this.superAdminservice.checkAWBNumber(payload).subscribe(
      (data) => {
        console.log("cargo damage=>",data);
       this.flight2=data.data.FlightNo.substring(2);
       this.airwaybillobj.flightIssuingAgent =data.data.AgentName;
       this.flightobj.mawbTotalpcs=data.data.PiecesCount;
       this.flightobj.shipper=data.data.ShipperName;
       this.flightobj.consignee=data.data.ConsigneeName;
       this.flightobj.issuingAgent=data.data.AgentName;
       this.flightobj.contentNature=data.data.CommodityDesc;
       
        var originAirportIdVal = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(data.data.originAirportName.toLowerCase())
        );
        if(originAirportIdVal.length>0){
          this.originAirportId = originAirportIdVal[0].name + '-' + originAirportIdVal[0].code;

          this.airwaybillobj.originAirportId = this.originAirportId;
        }
       
        var destAirportIdVal = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(data.data.destAirportName.toLowerCase())
        );
        if(destAirportIdVal.length>0){
          this.destairportId = destAirportIdVal[0].name + '-' + destAirportIdVal[0].code;

          this.airwaybillobj.destairportId = this.destairportId;
        }
       // this.iS_firtTab_Valid("apiValidation");
        this.tabModelVAlidationCheck('firstTab');
        this.spinner.hide();
      },
      (error) => {}
    );
  }


  // =================iwi 12062024| end=====================================

  closeExistedReportPopup() {
    jQuery('#reportExistedPopup').modal("hide");
  }

  editExistedPopup() {
    this.existedPopup = false;
  }

  closeExistedAWBNumPopup() {
    jQuery('#AwbNumExistedPopup').modal("hide");
    this.confirmSendStatus = 'N';
    this._update_ShortReport_ApiCall(this.updateTypeValue, this.hitTypeValue)
    // this.awbAlreadyExisted = false;
    // this._submitShortReport('last','SAVEANDEXIT','fromButtonHit')
  }

  closePopup() {
    jQuery('#reportExistedConfirm_Popup').modal("hide");
    this.ngOnInit();
    // this.route.navigateByUrl("/");
  }

  closeExistedConfirmReportPopup() {
    this.createNewReport_Popup() ;
    this.isUnAuthReportAccess = true;
    jQuery('#reportExistedConfirm_Popup').modal("hide");
  }
  
  createNewReport_Popup() {  //--------create new report (reset inputs) from already existed reports popup
    this.existedPopup = true;

    this.isUnAuthReportAccess = false;
    this.shipmentobj.organizationId = this.LoginDetails.orgId;

    this.flight2 = '';
   
    this.originAirportId = '';
    this.destairportId = '';
    this.aspectsobj.methodPacking = '';
    this.aspectsobj.contentPieces = '';
    this.aspectsobj.identificationNo = '';
    this.aspectsobj.itemWeight = '';
    this.aspectsobj.damagePacking = '';
    this.aspectsobj.conditioncontent = '';
    this.aspectsobj.contentAffected = '';
    this.aspectsobj.itemMissing = '';
    this.aspectsobj.itemPcs = '';
    this.aspectsobj.suspectPilf = '';
    this.aspectsobj.safetyLife = '';
    this.aspectsobj.recuperationReq = '';
    this.aspectsobj.recuperatoinStatus = '';
    this.aspectsobj.dentsOrTears = '';
    this.otherrecuperatoinStatus = '';

    this.assessmentobj.discoveresCaused = '';
    this.assessmentobj.internalName = '';
    this.assessmentobj.internalGroup = '';
    this.assessmentobj.damageDiscovered = '';
    this.damageDiscoveredstore = '';
    this.otherprocessstage = '';
    this.assessmentobj.internalResponsibility = '';
    this.assessmentobj.internalArea = '';
    this.internalAreastore = '';
    this.otherprocessarea = '';
    this.assessmentobj.areaSupervisor = '';
    this.assessmentobj.exactLocation = '';
    this.assessmentobj.casueOfEvent = '';
    this.rootCauseObj.behaviourId = '';
    this.rootCauseObj.behaviourOther = '';
    this.rootCauseObj.rulesId = '';
    this.rootCauseObj.rulesOther = '';
    this.rootCauseObj.equipmentId = '';
    this.rootCauseObj.equipmentOther = '';
    this.rootCauseObj.organisationDesc = '';
    this.flightobj.mawbTotalpcs = '';
    this.flightobj.actualReceivedPcs = '';
    this.flightobj.contentNature = '';
    this.flightobj.shipper = '';
    this.flightobj.consignee = '';
    this.flightobj.issuingAgent = '';
    this.damage_Report_Images = [];
    this.imageslist = []
    this.urls = []
    this.methodpackingarray = [
      { name: 'Bags', id: 1, checked: false },
      { name: 'Barrel', id: 2, checked: false },
      { name: 'Carton box', id: 3, checked: false },
      { name: 'Container', id: 4, checked: false },
      { name: 'Crate', id: 5, checked: false },
      { name: 'Frame', id: 6, checked: false },
      { name: 'Suitcase', id: 7, checked: false },
      { name: 'ULD', id: 8, checked: false },
      { name: 'Wood pallet', id: 9, checked: false },
      { name: 'Other', id: 10, checked: false }
    ];




    this.damagepackingarray = [
      { name: 'Broken', id: 1, checked: false },
      { name: 'collapsed', id: 17, checked: false },
      { name: 'Crushed', id: 2, checked: false },
      { name: 'Damp', id: 3, checked: false },
      { name: 'Dented', id: 4, checked: false },
      { name: 'Hole in', id: 5, checked: false },
      { name: 'Open', id: 6, checked: false },
      { name: 'Item missing', id: 7, checked: false },
      { name: 'Seal open / broken', id: 8, checked: false },
      { name: 'Shock watch', id: 9, checked: false },

      { name: 'Tape loose and/or torn', id: 10, checked: false },
      // { name: 'Tape torn', id: 11, checked: false },
      // { name: 'Temperature control', id: 12, checked: false },
      // { name: 'Tip and tell indicator', id: 13, checked: false },
      { name: 'Torn', id: 14, checked: false },
      { name: 'Wet', id: 15, checked: false },
      { name: 'Other', id: 16, checked: false }
    ];



    this.conditioncontentarray = [
      { name: 'Broken', id: 1, checked: false },
      { name: 'Crushed', id: 2, checked: false },
      { name: 'Damp', id: 3, checked: false },
      { name: 'Dented', id: 4, checked: false },
      { name: 'Destroyed', id: 5, checked: false },

      { name: 'Empty', id: 6, checked: false },
      { name: 'Injured [AVI]', id: 7, checked: false },
      { name: 'Leaking', id: 8, checked: false },
      { name: 'Overheated', id: 9, checked: false },

      { name: 'Sick [AVI]', id: 10, checked: false },
      { name: 'Soiled', id: 11, checked: false },
      { name: 'Mortality [AVI]', id: 12, checked: false },
      { name: 'Wet', id: 13, checked: false },

      { name: 'Other', id: 14, checked: false }
    ];

    this.recuparationstatusarray = [
      { name: 'Contents destroyed', id: 1, checked: false },
      { name: 'Contents repacked', id: 2, checked: false },
      { name: 'No lack of time', id: 3, checked: false },
      { name: 'Package corded', id: 4, checked: false },
      { name: 'Package sealed', id: 5, checked: false },
      { name: 'Package strapped', id: 6, checked: false },
      { name: 'Package taped', id: 7, checked: false },
      { name: 'Other', id: 9, checked: false }
    ];

    this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 1);
    this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 2);
    this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
    this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 4);

    this._setHeaderColourToFourthTab();
    this._setHeaderColourToFifthTab();
    this._isImagesSelected();
    jQuery('#reportExistedConfirm_Popup').modal("hide");
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"))
    this.is_From = 'CREATE_REPORT' ;
    if(this.masterAwbNo1.length === 9){
      document.getElementById("next_ToAwb_flightnumber").focus()
    }

  }

  okExistedConfirmReportPopup(eachReport) {  //-------->  edit already existed report if user needs
    
    this.is_From = 'EDIT_REPORT';
    this.isUnAuthReportAccess = false;
    jQuery('#reportExistedConfirm_Popup').modal("hide");
    this.editData_BindFun(eachReport, "notSaved") 
  }





  //---------Before leaving page alert functionality-------------//
  isReportSaveAlert_Ok(tab, saveType) {
    if (this.is_From === 'CREATE_REPORT') {
      this._submitShortReport('first', 'SAVEANDEXIT', 'fromDestroyHit')
    } else {
      this.update_ShortReport('first', 'UpdateAndExit', 'fromDestroyHit')
    }
  }
  isReportSaveAlert_Close() {
    jQuery('#isReportSaveAlert').modal("hide");
  }






  editData_BindFun(data, type) {
    this.edit_Report_Data = data;


    this.emailStatus = JSON.parse(localStorage.getItem('emailStatus'));
    this.selectionStatus = true;
    this.showstationfilter = false;
    this.showstationwithoutfilter = true;
    this.showviewreports = true;
    this.showlogin = false;
    this.shipmentobj.categoryId = '10001'
    this.shipmentobj.iswebormobile = '1';
    this.shipmentobj.userId = this.edit_Report_Data.userId;
    this.shipmentobj.organizationId = '';// ?
    this.shipmentobj.emailId = '';// ?
    this.shipmentobj.address = '';// ?
    this.shipmentobj.incidentDate = this.edit_Report_Data.incidentDate;
    this.uploadpicsobj.categoryId = '10006';
    this.uploadpicsobj.userId = this.edit_Report_Data.userId;
    this.uploadpicsobj.masterAwbNo = this.edit_Report_Data.masterAwbNo;
    if (this.edit_Report_Data.incidentTime != "" && this.edit_Report_Data.incidentTime != null && this.edit_Report_Data.incidentTime != undefined) {
      this.shipmentobj.incidentTime = this.edit_Report_Data.incidentTime;
    }
    this.shipmentobj.masterAwbNo = this.edit_Report_Data.masterAwbNo;
    this.shipmentobj.stationCode = this.LoginDetails.stationCode;

    if (this.edit_Report_Data.reportPreparedName != undefined && this.edit_Report_Data.reportPreparedName != null && this.edit_Report_Data.reportPreparedName != '') {
      this.shipmentobj.reportPreparedName = this.edit_Report_Data.reportPreparedName;
    }
    if (this.edit_Report_Data.reportPreparedFunction != undefined && this.edit_Report_Data.reportPreparedFunction != null && this.edit_Report_Data.reportPreparedFunction != '') {
      this.shipmentobj.reportPreparedFunction = this.edit_Report_Data.reportPreparedFunction
    }


    this.shipmentobj.privacyStatus = '';
    this.shipmentobj.orgName = this.edit_Report_Data.orgName;

    this.airwaybillobj.categoryId = '10002';
    this.airwaybillobj.userId = this.edit_Report_Data.userId;

    let mawb_Split_values = [];
    if (this.edit_Report_Data.masterAwbNumber != "" && this.edit_Report_Data.masterAwbNumber != null && this.edit_Report_Data.masterAwbNumber != undefined) {
      mawb_Split_values = this.edit_Report_Data.masterAwbNumber.split("-");
    }


    this.airwaybillobj.masterAwbNo = mawb_Split_values[0];
    this.masterAwbNo1 = mawb_Split_values[1];

    // this.masterAwbNochange();
    this.masterAwbNochange_update();

    this.spacecall(this.masterAwbNo1);


    this.airwaybillobj.movementType = '';

    this.airwaybillobj.truckType = this.edit_Report_Data.truckType;
    if (this.edit_Report_Data.flightDetails != "" && this.edit_Report_Data.flightDetails != null && this.edit_Report_Data.flightDetails != undefined) {
      this.flight0 = this.edit_Report_Data.flightDetails.split("-")[0];
    }
    let flight_Trk_Num_Split_Vals = [];
    if (this.edit_Report_Data.flightTruckNo != "" && this.edit_Report_Data.flightTruckNo != null && this.edit_Report_Data.flightTruckNo != undefined) {
      flight_Trk_Num_Split_Vals = this.edit_Report_Data.flightTruckNo.split("-");
    }

    this.flight1 = flight_Trk_Num_Split_Vals[0]
    this.flight2 = flight_Trk_Num_Split_Vals[1]
    this.airwaybillobj.flightTruckNo = this.flight1 + '-' + this.flight2;
    this.airwaybillobj.flightDate = this.edit_Report_Data.flightDate;
     
    this.airlineId = this.edit_Report_Data.airlineName

    this.airwaybillobj.originAirportId = this.edit_Report_Data.originAirportName;
    this.originAirportId = this.edit_Report_Data.originAirportName // for display

    this.airwaybillobj.destairportId = this.edit_Report_Data.destAirportName;
    this.destairportId = this.edit_Report_Data.destAirportName //for display

    this.airwaybillobj.rootcauseSendstatus = this.edit_Report_Data.rootcausestatus
    this.airwaybillobj.rootcauseExists = this.edit_Report_Data.rootcauseExists
    this.airwaybillobj.orgRootcauseStatus = this.edit_Report_Data.orgRootcauseStatus
    this.airwaybillobj.reportOrgRootcauseStatus = this.edit_Report_Data.reportOrgRootcauseStatus
    this.airwaybillobj.orgRootcauseStatus = this.edit_Report_Data.orgRootcauseStatus

    this.airwaybillobj.trucking_flight_info = '';
    this.airwaybillobj.flightIssuingAgent = '' ;

    this.flightobj.categoryId = '10003'
    this.flightobj.userId = this.edit_Report_Data.userId;
    this.flightobj.masterAwbNo = this.edit_Report_Data.masterAwbNo;

    if (this.edit_Report_Data.contentNature != null && this.edit_Report_Data.contentNature != undefined && this.edit_Report_Data.contentNature != "") {
      this.flightobj.contentNature = this.edit_Report_Data.contentNature;
    }
    if (this.edit_Report_Data.comodityNote != null && this.edit_Report_Data.comodityNote != undefined && this.edit_Report_Data.comodityNote != '') {
      this.flightobj.comodityNote = this.edit_Report_Data.comodityNote;
    }
    if (this.edit_Report_Data.mawbTotalPcs != null && this.edit_Report_Data.mawbTotalPcs != undefined && this.edit_Report_Data.mawbTotalPcs != '') {
      if (this.edit_Report_Data.mawbTotalPcs != 0) {
        this.flightobj.mawbTotalpcs = this.edit_Report_Data.mawbTotalPcs;
      } else {
        this.flightobj.mawbTotalpcs = '';
      }

    } else {
      this.flightobj.mawbTotalpcs = null;
    }
    if (this.edit_Report_Data.mawbTotalWeight != null && this.edit_Report_Data.mawbTotalWeight != undefined && this.edit_Report_Data.mawbTotalWeight != '') {


      if (this.edit_Report_Data.mawbTotalWeight != 0) {
        this.flightobj.mawbTotalWeight = this.edit_Report_Data.mawbTotalWeight;
      } else {
        this.flightobj.mawbTotalWeight = '';
      }
    } else {
      this.flightobj.mawbTotalWeight = null;
    }
    if (this.edit_Report_Data.actualReceivedPcs != null && this.edit_Report_Data.actualReceivedPcs != undefined && this.edit_Report_Data.actualReceivedPcs != '') {
      this.flightobj.actualReceivedPcs = this.edit_Report_Data.actualReceivedPcs;
    } else {
      this.flightobj.actualReceivedPcs = null;
    }
    if (this.edit_Report_Data.actualReceivedWeight != null && this.edit_Report_Data.actualReceivedWeight != undefined && this.edit_Report_Data.actualReceivedWeight != '') {
      this.flightobj.actualRevicedWeight = this.edit_Report_Data.actualReceivedWeight;
    } else {
      this.flightobj.actualRevicedWeight = null;
    }
    if (this.edit_Report_Data.shipper != null && this.edit_Report_Data.shipper != undefined && this.edit_Report_Data.shipper != '') {
      this.flightobj.shipper = this.edit_Report_Data.shipper;
    }
    if (this.edit_Report_Data.consignee != null && this.edit_Report_Data.consignee != undefined && this.edit_Report_Data.consignee != '') {
      this.flightobj.consignee = this.edit_Report_Data.consignee;
    }
    if (this.edit_Report_Data.issuingAgent != null && this.edit_Report_Data.issuingAgent != undefined && this.edit_Report_Data.issuingAgent != '') {
      this.flightobj.issuingAgent = this.edit_Report_Data.issuingAgent;
    }

    this.aspectsobj.categoryId = '10004';
    this.aspectsobj.userId = this.edit_Report_Data.userId;
    this.aspectsobj.masterAwbNo = this.edit_Report_Data.masterAwbNo;
    this.airwaybillobj.flightIssuingAgent = this.edit_Report_Data.flightIssuingAgent ;
    if (this.edit_Report_Data.methodPacking != null && this.edit_Report_Data.methodPacking != '' && this.edit_Report_Data.methodPacking != undefined) {
      this.aspectsobj.methodPacking = this.edit_Report_Data.methodPacking;
      this.othermppresent = false;
      let methodPacking_split_Val_Array = this.edit_Report_Data.methodPacking.split(","); //to split based on comma

      for (let j = 0; j < this.methodpackingarray.length; j++) { // to loop methodpackingarray and assign true to resulted values
        for (let i = 0; i < methodPacking_split_Val_Array.length; i++) { // to loop splitted values
          if (methodPacking_split_Val_Array[i] === this.methodpackingarray[j].name) {

            this.methodpackingarray[j].checked = true;
          } else {

            let val = this.methodpackingarray.filter((item) => item.name.toLowerCase().startsWith(methodPacking_split_Val_Array[i].toLowerCase()))
            if (val.length != 0) {
            } else {

              this.othermethodpacking = methodPacking_split_Val_Array[i];
              this.othermppresent = true;

              this.methodpackingarray[9].checked = true;
            }
          }
        }
      }
      if (this.othermppresent !== false) {
        this.showothermethodPacking = true;
      } else {
        this.showothermethodPacking = false;
        this.othermethodpacking = '';
      }
    }

    if (this.edit_Report_Data.identificationNo != null && this.edit_Report_Data.identificationNo != undefined && this.edit_Report_Data.identificationNo != '') {
      this.aspectsobj.identificationNo = this.edit_Report_Data.identificationNo;
    }
    if (this.edit_Report_Data.damgagePacking != null && this.edit_Report_Data.damgagePacking != undefined && this.edit_Report_Data.damgagePacking != '') {
      this.aspectsobj.damagePacking = this.edit_Report_Data.damgagePacking;
    }


    //
    if (this.edit_Report_Data.damgagePacking != null && this.edit_Report_Data.damgagePacking != '' && this.edit_Report_Data.damgagePacking != undefined) {
      let damagePacking_split_Val_Array = [];
      this.otherdppresent = false;
      damagePacking_split_Val_Array = this.edit_Report_Data.damgagePacking.split(","); //to split based on comma
      for (let i = 0; i < damagePacking_split_Val_Array.length; i++) { // to loop splitted values
        for (let j = 0; j < this.damagepackingarray.length; j++) { // to loop damagepackingarray and assign true to resulted values
          if (damagePacking_split_Val_Array[i] === this.damagepackingarray[j].name) {
            this.damagepackingarray[j].checked = true;
          } else {
            let val = this.damagepackingarray.filter((item) => item.name.toLowerCase().startsWith(damagePacking_split_Val_Array[i].toLowerCase()))
            if (val.length != 0) {

            } else {
              this.otherdamagepacking = damagePacking_split_Val_Array[i];
              this.otherdppresent = true;
              this.damagepackingarray[13].checked = true;
            }
          }

        }

        if (this.otherdppresent != false) {
          this.showotherdamagePacking = true;
        } else {
          this.showotherdamagePacking = false;
          this.otherdamagepacking = '';
        }
      }
      this.okdamagetopacking()
    }

    let contentaffctdval = {
      target: {
        value: this.edit_Report_Data.contentAffected
      }
    }
    this.contentaffctd(contentaffctdval)

    if (this.edit_Report_Data.contentPieces != null && this.edit_Report_Data.contentPieces != undefined && this.edit_Report_Data.contentPieces != '') {
      this.aspectsobj.contentPieces = this.edit_Report_Data.contentPieces;
      this.showPiecesAffected = true;
    } else {
      this.aspectsobj.contentPieces = null;
      this.showPiecesAffected = false;
    }
    if (this.edit_Report_Data.contentWeight != null && this.edit_Report_Data.contentWeight != undefined && this.edit_Report_Data.contentWeight != '') {
      this.aspectsobj.contentWeight = this.edit_Report_Data.contentWeight;
    } else {
      this.aspectsobj.contentWeight = null;
    }
    if (this.edit_Report_Data.itemMissing != null && this.edit_Report_Data.itemMissing != undefined && this.edit_Report_Data.itemMissing != '') {
      this.aspectsobj.itemMissing = this.edit_Report_Data.itemMissing;
    } else {
      this.aspectsobj.itemMissing = null;
    }

    let itemMissingval = {
      target: {
        value: this.edit_Report_Data.itemMissing
      }
    }

    if (this.aspectsobj.itemMissing != undefined && this.aspectsobj.itemMissing != '' && this.aspectsobj.itemMissing != null) {
      this.itemmiss(itemMissingval);
    }

    if (this.edit_Report_Data.itemPcs != null && this.edit_Report_Data.itemPcs != undefined && this.edit_Report_Data.itemPcs != '') {
      this.aspectsobj.itemPcs = this.edit_Report_Data.itemPcs;
    } else {
      this.aspectsobj.itemPcs = null;
    }
    if (this.edit_Report_Data.itemWeight != null && this.edit_Report_Data.itemWeight != undefined && this.edit_Report_Data.itemWeight != '') {
      this.aspectsobj.itemWeight = this.edit_Report_Data.itemWeight;
      this.showPiecesAffected = true;
    } else {
      this.aspectsobj.itemWeight = null;


    }
    if (this.edit_Report_Data.suspectPilf != null && this.edit_Report_Data.suspectPilf != undefined && this.edit_Report_Data.suspectPilf != '') {
      this.aspectsobj.suspectPilf = this.edit_Report_Data.suspectPilf;
    }
    if (this.edit_Report_Data.safetyLife != null && this.edit_Report_Data.safetyLife != undefined && this.edit_Report_Data.safetyLife != '') {
      this.aspectsobj.safetyLife = this.edit_Report_Data.safetyLife;
    }
    if (this.edit_Report_Data.conditionContent != null && this.edit_Report_Data.conditionContent != undefined && this.edit_Report_Data.conditionContent != '') {
      this.aspectsobj.conditioncontent = this.edit_Report_Data.conditionContent;
    }

    if (this.edit_Report_Data.conditionContent != null && this.edit_Report_Data.conditionContent != '' && this.edit_Report_Data.conditionContent != undefined) {
      let conditionContent_split_Val_Array = this.edit_Report_Data.conditionContent.split(","); //to split based on comma
      this.otherccpresent = false;
      for (let i = 0; i < conditionContent_split_Val_Array.length; i++) { // to loop splitted values
        for (let j = 0; j < this.conditioncontentarray.length; j++) { // to loop conditioncontentarray and assign true to resulted values
          if (conditionContent_split_Val_Array[i] === this.conditioncontentarray[j].name) {
            this.conditioncontentarray[j].checked = true;

          } else {
            let val = this.conditioncontentarray.filter((item) => item.name.toLowerCase().startsWith(conditionContent_split_Val_Array[i].toLowerCase()))
            if (val.length != 0) {

            } else {
              this.otherconditioncontent = conditionContent_split_Val_Array[i];
              this.otherccpresent = true;
              this.conditioncontentarray[13].checked = true;
            }
          }
        }
      }
      this.okConditionofContent();
      if (this.otherccpresent !== false) {
        this.showotherconditioncontent = true;
      } else {
        this.showotherconditioncontent = false;
        this.otherconditioncontent = '';
      }
    }

    if (this.edit_Report_Data.recuperationReq != null && this.edit_Report_Data.recuperationReq != '' && this.edit_Report_Data.recuperationReq != undefined) {
      this.aspectsobj.recuperationReq = this.edit_Report_Data.recuperationReq;
      this.onChangerecoveryreq();
    }


    if (this.edit_Report_Data.recuperationStatus != null && this.edit_Report_Data.recuperationStatus != '' && this.edit_Report_Data.recuperationStatus != undefined) {
      this.aspectsobj.recuperatoinStatus = this.edit_Report_Data.recuperationStatus;
      let recuparationstatusarray_split_Val_Array = this.edit_Report_Data.recuperationStatus.split(","); //to split based on comma
      this.otherrqpresent = false;
      for (let i = 0; i < recuparationstatusarray_split_Val_Array.length; i++) { // to loop splitted values
        for (let j = 0; j < this.recuparationstatusarray.length; j++) { // to loop recuparationstatusarray and assign true to resulted values
          if (recuparationstatusarray_split_Val_Array[i] === this.recuparationstatusarray[j].name) {
            this.recuparationstatusarray[j].checked = true;
            //
          } else {
            let val = this.recuparationstatusarray.filter((item) => item.name.toLowerCase().startsWith(recuparationstatusarray_split_Val_Array[i].toLowerCase()))
            if (val.length != 0) {

            } else {
              this.otherrecuperatoinStatus = recuparationstatusarray_split_Val_Array[i];
              this.otherrqpresent = true;
              this.recuparationstatusarray[7].checked = true;
            }
          }
        }
        this.okrecoveryactions()
      }


      if (this.otherrqpresent != false) {
        this.showotherrecuperatoinStatus = true;
        // this.otherrecuperatoinStatus = ;
      } else {
        this.showotherrecuperatoinStatus = false;
        this.otherrecuperatoinStatus = '';
      }
    }




    this.aspectsobj.affectedAwb = this.edit_Report_Data.affectedAwb;
    let affectedAwb_split_Val_Array = [];
    if (this.edit_Report_Data.affectedAwb != null && this.edit_Report_Data.affectedAwb != '' && this.edit_Report_Data.affectedAwb != undefined) {
      affectedAwb_split_Val_Array = this.edit_Report_Data.affectedAwb.split(","); //to split based on comma
    }
    if (affectedAwb_split_Val_Array.length != 0) {
      for (let i = 1; i < affectedAwb_split_Val_Array.length; i++) { // to loop splitted values and form input feilds s
        this.morehawscall();
      }
      for (let i = 0; i < this.affectedAwbarray.length; i++) { // to loop upto input feilds length and assign values
        for (let j = 0; j < affectedAwb_split_Val_Array.length; j++) {
          if (i === j) {
            this.affectedAwbarray[i].awb = affectedAwb_split_Val_Array[j]
          }
        }
      }
    }

    if (this.edit_Report_Data.classificationType != null && this.edit_Report_Data.classificationType != undefined && this.edit_Report_Data.classificationType != '') {
      this.aspectsobj.classificationType = this.edit_Report_Data.classificationType;
    }


    this.assessmentobj.categoryId = '10005';
    this.assessmentobj.userId = this.edit_Report_Data.userId;
    this.assessmentobj.masterAwbNo = this.edit_Report_Data.masterAwbNo;
    if (this.edit_Report_Data.discoveresCaused != null && this.edit_Report_Data.discoveresCaused != undefined && this.edit_Report_Data.discoveresCaused != '') {
      this.assessmentobj.discoveresCaused = this.edit_Report_Data.discoveresCaused;
    }
    if (this.edit_Report_Data.damageDiscovered != null && this.edit_Report_Data.damageDiscovered != undefined && this.edit_Report_Data.damageDiscovered != '') {
      this.assessmentobj.damageDiscovered = this.edit_Report_Data.damageDiscovered;
    }




    if (this.edit_Report_Data.reportStatus != null && this.edit_Report_Data.reportStatus != undefined && this.edit_Report_Data.reportStatus != '') {
      this.assessmentobj.internalResponsibility = this.edit_Report_Data.reportStatus;
      this.isExtraTabEnable = true ;
      if (this.assessmentobj.internalResponsibility === 'internal') {
        this.showbelowfields = true;
      }
    }

    if (this.edit_Report_Data.internalName != null && this.edit_Report_Data.internalName != undefined && this.edit_Report_Data.internalName != '') {
      this.assessmentobj.internalName = this.edit_Report_Data.internalName;
    }
    if (this.edit_Report_Data.internalGroup != null && this.edit_Report_Data.internalGroup != undefined && this.edit_Report_Data.internalGroup != '') {
      this.assessmentobj.internalGroup = this.edit_Report_Data.internalGroup;
    }
    if (this.edit_Report_Data.internalcode != null && this.edit_Report_Data.internalcode != undefined && this.edit_Report_Data.internalcode != '') {
      this.assessmentobj.internalcode = this.edit_Report_Data.internalcode;
    }
    if (this.edit_Report_Data.internalArea != null && this.edit_Report_Data.internalArea != undefined && this.edit_Report_Data.internalArea != '') {
      this.assessmentobj.internalArea = this.edit_Report_Data.internalArea;
      this.showotherinternalArea = false
      let internalArea_other_Check_array = [
        "Warehouse",
        "Landside",
        "Airside",
        "Mail",
        "Terminal",
        "Other",
      ]

      let val = internalArea_other_Check_array.includes(this.assessmentobj.internalArea);
      if (val) {
        this.internalAreastore = this.edit_Report_Data.internalArea
      } else {
        this.showotherinternalArea = true;
        this.internalAreastore = 'Other';
        this.otherprocessarea = this.assessmentobj.internalArea;
      }

    }
    if (this.edit_Report_Data.areaSupervisor != null && this.edit_Report_Data.areaSupervisor != undefined && this.edit_Report_Data.areaSupervisor != '') {
      this.assessmentobj.areaSupervisor = this.edit_Report_Data.areaSupervisor;
    }
    if (this.edit_Report_Data.exactLocation != null && this.edit_Report_Data.exactLocation != undefined && this.edit_Report_Data.exactLocation != '') {
      this.assessmentobj.exactLocation = this.edit_Report_Data.exactLocation;
    }
    if (this.edit_Report_Data.locatonId != null && this.edit_Report_Data.locatonId != undefined && this.edit_Report_Data.locatonId != '') {
      this.assessmentobj.locatonId = this.edit_Report_Data.locatonId;
    }
    if (this.edit_Report_Data.causeOfEvent != null && this.edit_Report_Data.causeOfEvent != undefined && this.edit_Report_Data.causeOfEvent != '') {
      this.assessmentobj.casueOfEvent = this.edit_Report_Data.causeOfEvent;
    }

    this.damage_Report_Images = [];
    if (this.edit_Report_Data.damageImage != null && this.edit_Report_Data.damageImage != undefined && this.edit_Report_Data.damageImage != '') {
      this.uploadpicsobj.damageImage = '';

      this.damage_Report_Images = this.edit_Report_Data.damageImage.split(',');
    }
    let damageDiscoveredstore_other_Check_array = [
      "Other",

      "Unloading aircraft",
      "Unloading truck",
      "Loading aircraft",
      "Loading truck",
      "Breakdown / check-in",
      "Warehouse storage",
      "Delivery to agent",
      "During build-up",
      "Transport in warehouse",
      "Transport to/from aircraft"
    ]
    this.showotherprocessstage = false;
    if (this.edit_Report_Data.damageDiscovered != null && this.edit_Report_Data.damageDiscovered != undefined && this.edit_Report_Data.damageDiscovered != '') {
      let damageDiscovered = this.edit_Report_Data.damageDiscovered;
      let val = damageDiscoveredstore_other_Check_array.includes(damageDiscovered);
      if (val) {
        this.damageDiscoveredstore = damageDiscovered;
      } else {
        this.damageDiscoveredstore = 'Other';
        this.showotherprocessstage = true;
        this.otherprocessstage = damageDiscovered;

      }
      // this.okprocessstage()

    }


   
    this.secondTB_COmp = true;
    this.thirdTB_COmp = true;
    this.firstTB_COmp = true;
    this.fourthTB_COmp = true;
    this.fifthTB_COmp = true;
    this.sixthTB_COmp = true;
    if (type === "saveDone") {
      this.stepper.next();
    }
  

    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"))

let rulesIdsArray = '' ;
let behaviourIdsArray = '' ;
let equipmentIdsArray = '' ;
let organisationIdsArray = '' ;
let capaTypeIdsArray = '' ;


if(data.rulesId !== null && data.rulesId !== undefined){
rulesIdsArray = data.rulesId.split(",") ;
}
if(data.behaviourId !== null && data.behaviourId !== undefined){
  behaviourIdsArray = data.behaviourId.split(",") ;
}
if(data.equipmentId !== null && data.equipmentId !== undefined){
equipmentIdsArray = data.equipmentId.split(",") ;
}
if(data.organisationId !== null && data.organisationId !== undefined){
  organisationIdsArray = data.organisationId.split(",") ;
}
if(data.capaTypeId !== null && data.capaTypeId !== undefined){
  capaTypeIdsArray = data.capaTypeId.split(",") ;
}



if(this.rules_Array !== null&& this.rules_Array!==  undefined){
  this.rules_Array.forEach(element=>{
    if(rulesIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.rules_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}

if(this.behaviour_Array !== null&& this.behaviour_Array !==  undefined){
  this.behaviour_Array.forEach(element=>{
    if(behaviourIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.behaviour_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}

if(this.equipments_Array !== null&& this.equipments_Array !==  undefined){
  this.equipments_Array.forEach(element=>{
    if(equipmentIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.equipment_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}
if(this.organisation_Array !== null&& this.organisation_Array !==  undefined){
  this.organisation_Array.forEach(element=>{
    if(organisationIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.organisation_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}

if(this.capaType_Array !== null&& this.capaType_Array !==  undefined){
  this.capaType_Array.forEach(element=>{
    if(capaTypeIdsArray.includes(element.id.toString())){
      element.checked = true ;
      this.capaType_ArrayDuplicate.push(Object.assign({},element))
    }
  })
}
    this.rootCauseObj.equipmentDesc = data.equipmentDesc ;
    this.rootCauseObj.equipmentId = data.equipmentId ;
    this.rootCauseObj.rulesDesc = data.rulesDesc ;
    this.rootCauseObj.rulesId = data.rulesId ;
    this.rootCauseObj.behaviourDesc = data.behaviourDesc ;
    this.rootCauseObj.behaviourId = data.behaviourId ;

    this.rootCauseObj.capaTypeDesc = data.capaTypeDesc ;
    this.rootCauseObj.capaTypeId = data.capaTypeId ;

    this.rootCauseObj.organisationDesc = data.organisationDesc ;
    this.rootCauseObj.organisationId = data.organisationId ;

    this.aspectsobj.dentsOrTears = data.dentsOrTears ;
    this.only_scratches_tears_dents = data.dentsOrTears ;
     this.onChangeScratchesAndDents() ;

    this.rootCauseObj.rulesOther = data.rulesOther ;
    this.otherrules_Model =  data.rulesOther ;
    if (this.edit_Report_Data.contentAffected != null && this.edit_Report_Data.contentAffected != undefined && this.edit_Report_Data.contentAffected != '') {
      this.aspectsobj.contentAffected = this.edit_Report_Data.contentAffected;
    }



    this.rootCauseObj.equipmentOther = data.equipmentOther ;
    this.otherEquipment_Model = data.equipmentOther ;

    this.rootCauseObj.behaviourOther = data.behaviourOther ;
    this.otherBehaviour_Model = data.behaviourOther ;

    this.rootCauseObj.capaTypeOther = data.behaviourOther ;
    this.otherCapaType_Model = data.behaviourOther ;

    this.rootCauseObj.organisationOther = data.organisationOther ;
    this.otherOrganisation_Model = data.organisationOther ;

    this.rootCauseObj.rootCauseInvestigation = data.rootCauseInvestigation ;
    this.rootCauseObj.correctiveActions = data.correctiveActions ;
    this.rootCauseObj.evaluationDone = data.evaluationDone ;
    this.rootCauseObj.evaluationDate = data.evaluatioDate ;
    
    if(this.rootCauseObj.evaluationDone === 'yes'){
      this.evaluationObj.checked = true;
    }else{
      this.evaluationObj.checked = false;
    }
    setTimeout(() => {
      this._setHeaderColourToFifthTab();
      this._setHeaderColourToFourthTab();
      this._isImagesSelected();
  
    }, 500);
    
    if(data.reportStatus ==='internal'){
      this.is_Behaviour_And_Other_FieldsEnable = true ;
    }else if(data.reportStatus === null){
      this.is_Behaviour_And_Other_FieldsEnable = true ;
    }else if(data.reportStatus === "external"){
      this.is_Behaviour_And_Other_FieldsEnable = false ;
    }


  }

  create_formGroup() {  //---> used in before login report creation 
    // let EMAILPATTERN = "(?!.*@.*@)[a-zA-Z0-9.-_-]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}"
    return this.fb.group({
      userCheckEmailId: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
      userCheckConfirmEmailId: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)])
    })
  }

  open() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {

    });
  }

  viewcall() {
    // this.route.navigateByUrl("/");
  }

  fn_OnLogin(loginData) { //---> used in before login report creation 
    this.loginInput.iswebormobile = 1;
    this.loginInput.username = loginData.username.replace(/\s+/g, '');
    this.loginInput.passcode = loginData.passcode.replace(/\s+/g, '');
    let obj = {
      "emailId": this.loginInput.username,
      "password": this.loginInput.passcode,
      "rememberMeStatus": loginData.rememberMeStatus
    }
    this.loginComponent.Login(obj);
  }

  gotomyaccount() {
    this.route.navigateByUrl("/my-account");
  }


  onChangerecoveryreq() {
    if (this.aspectsobj.recuperationReq === 'yes') {
      this.showrecoveryactions = true;
    } else {
      this.aspectsobj.recuperatoinStatus = ''
      this.recuparationstatusarray.forEach((item) => {
        item.checked = false;
      });
      this.showrecoveryactions = false;
    }
  }
  cancelCall() {
    jQuery('.custom_modal').modal('hide');
  }

  //----- process area drop down functionalities ----//
  openprocessarea() {
    jQuery('#ProcessArea').modal({ backdrop: 'static', keyboard: false });
    if (this.assessmentobj.internalArea === 'Other' || ((this.assessmentobj.internalArea === this.otherprocessarea) && (this.otherprocessarea != '' && this.otherprocessarea != null && this.otherprocessarea != undefined))) {
      this.showotherinternalArea = true;
      this.internalAreastore = 'Other'
    } else {
      this.otherprocessarea = ''
      this.showotherinternalArea = false;
    }
  }

  cancelprocessarea() {

    if ((this.assessmentobj.internalArea === this.otherprocessarea) && (this.otherprocessarea != undefined && this.otherprocessarea != '' && this.otherprocessarea != null)) {
      this.internalAreastore = 'Other';
    } else {
      this.internalAreastore = this.assessmentobj.internalArea;
      if (this.internalAreastore != '' && this.internalAreastore != null && this.internalAreastore != undefined) {
        let internalArea_other_Check_array = [
          "Warehouse",
          "Landside",
          "Airside",
          "Mail",
          "Terminal",
          "Other",
        ]
        let val = internalArea_other_Check_array.includes(this.internalAreastore);
        if (!val) {
          this.otherprocessarea = this.internalAreastore
          this.internalAreastore = 'Other';

        }
      }


    }
    jQuery('.custom_modal').modal('hide');
  }

  okprocessarea() {
    if (this.otherprocessarea != undefined && this.otherprocessarea != '' && this.otherprocessarea != null) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }
    if ((this.internalAreastore === 'Other') && (this.otherprocessarea === undefined || this.otherprocessarea === '' || this.otherprocessarea === null)) {
      this.toast.error("Please enter other's description");
    } else {
      if (this.internalAreastore === 'Other') {

        this.assessmentobj.internalArea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
      } else {
        if (this.internalAreastore != '' && this.internalAreastore != null && this.internalAreastore != undefined) {
          this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z\s]*/g, '');
        }
        this.otherprocessarea = '';
        this.assessmentobj.internalArea = this.internalAreastore;
      }


      jQuery('#ProcessArea').modal('hide');
      jQuery('body').removeClass('modal-open');
      jQuery('.modal-backdrop').remove();
    }

  }

//----- process Stage drop down functionalities ----//
  openprocessstage() {
    jQuery('#ProcessStage').modal({ backdrop: 'static', keyboard: false });
    let damageDiscoveredstore_other_Check_array = [
      "Other",
      "Unloading aircraft",
      "Unloading truck",
      "Loading aircraft",
      "Loading truck",
      "Breakdown / check-in",
      "Warehouse storage",
      "Delivery to agent",
      "During build-up",
      "Transport in warehouse",
      "Transport to/from aircraft"
    ]

    let val = damageDiscoveredstore_other_Check_array.includes(this.assessmentobj.damageDiscovered);
    val ? (this.showotherprocessstage = false) : ((this.assessmentobj.damageDiscovered != '' && this.assessmentobj.damageDiscovered != null && this.assessmentobj.damageDiscovered != undefined) ? this.showotherprocessstage = true : this.showotherprocessstage = false);

  }

  cancelprocessstage() {
    let damageDiscoveredstore_other_Check_array = [
      "Other",
      "Unloading aircraft",
      "Unloading truck",
      "Loading aircraft",
      "Loading truck",
      "Breakdown / check-in",
      "Warehouse storage",
      "Delivery to agent",
      "During build-up",
      "Transport in warehouse",
      "Transport to/from aircraft"
    ]
    let damage_discovered_Duplicate = this.assessmentobj.damageDiscovered;
    let val = damageDiscoveredstore_other_Check_array.includes(this.assessmentobj.damageDiscovered);
    if (val) {
      if (this.assessmentobj.damageDiscovered != '' && this.assessmentobj.damageDiscovered != null && this.assessmentobj.damageDiscovered != undefined) {
        this.damageDiscoveredstore = this.assessmentobj.damageDiscovered;
      } else {
        this.damageDiscoveredstore = ''
      }
    } else {
      if (this.assessmentobj.damageDiscovered != '' && this.assessmentobj.damageDiscovered != null && this.assessmentobj.damageDiscovered != undefined) {
        this.damageDiscoveredstore = 'Other';
        this.otherprocessstage = damage_discovered_Duplicate;
      } else {
        this.damageDiscoveredstore = ''
      }
    }
    jQuery('.custom_modal').modal('hide');
  }

  okprocessstage() {
    if (this.otherprocessstage != undefined && this.otherprocessstage != '' && this.otherprocessstage != null) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }
    if ((this.damageDiscoveredstore === 'Other') && (this.otherprocessstage === undefined || this.otherprocessstage === '' || this.otherprocessstage === null)) {
      this.toast.error("Please enter other's description");
    } else {


      if (this.damageDiscoveredstore === 'Other') {
        this.assessmentobj.damageDiscovered = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
      } else {
        this.otherprocessstage = '';
        this.assessmentobj.damageDiscovered = this.damageDiscoveredstore;
      }

      jQuery('.custom_modal').modal('hide');

      if (this.assessmentobj.discoveresCaused === 'discoveredby') {
        if ((this.assessmentobj.damageDiscovered === 'During build-up') ||
          (this.assessmentobj.damageDiscovered === 'Warehouse storage') ||
          (this.assessmentobj.damageDiscovered === 'Loading truck') ||
          ((
            this.assessmentobj.damageDiscovered !== "Unloading aircraft" &&
            this.assessmentobj.damageDiscovered !== "Unloading truck" &&
            this.assessmentobj.damageDiscovered !== "Loading aircraft" &&
            this.assessmentobj.damageDiscovered !== "Breakdown / check-in" &&
            this.assessmentobj.damageDiscovered !== "Delivery to agent" &&
            this.assessmentobj.damageDiscovered !== "Transport in warehouse" &&
            this.assessmentobj.damageDiscovered !== "Transport to/from aircraft" &&
            this.assessmentobj.damageDiscovered !== "" &&
            this.assessmentobj.damageDiscovered !== undefined &&
            this.assessmentobj.damageDiscovered !== null
          ))) {
          this.assessmentobj.internalResponsibility = 'internal';
          this.showbelowfields = true;
          this.isExtraTabEnable =true ;
            
          this.is_Behaviour_And_Other_FieldsEnable = true ;
          this.reset_RootCause() ;

        } else {
          if (this.damageDiscoveredstore != null && this.damageDiscoveredstore != undefined && this.damageDiscoveredstore != '') { //new
            this.assessmentobj.internalResponsibility = 'external';
            this.showbelowfields = false;
            this.isExtraTabEnable = false ;
        
            this.assessmentobj.internalArea = '';
            this.assessmentobj.areaSupervisor = '';
            this.assessmentobj.exactLocation = '';
            this.assessmentobj.locatonId = '';
            this.assessmentobj.casueOfEvent = '';
            this.internalAreastore = '';
            this.otherprocessarea = '';

            this.is_Behaviour_And_Other_FieldsEnable = false ;
           

           this.reset_RootCause() ;
          
          }
        }
      }

    }

    this.tabModelVAlidationCheck('thirdTab')

  }

reset_RootCause(){
  if(this.is_From === 'CREATE_REPORT'){

    this.rootCauseObj.equipmentDesc = '' ;
    this.rootCauseObj.equipmentId = '' ;
    this.rootCauseObj.rulesDesc = '' ;
    this.rootCauseObj.rulesId = '' ;
    this.rootCauseObj.behaviourDesc = '' ;
    this.rootCauseObj.behaviourId = '' ;
    this.rootCauseObj.correctiveActions = '' ;
    this.rootCauseObj.rootCauseInvestigation = '' ;

    this.rootCauseObj.capaTypeDesc = '' ;
    this.rootCauseObj.organisationDesc = '' ;

    this.evaluationObj.checked = false ;
    this.rootCauseObj.organisationDesc = '' ;
    this.rootCauseObj.rulesOther = '' ;
    this.otherrules_Model =  '' ;

    this.rootCauseObj.equipmentOther = '' ;
    this.otherEquipment_Model = '' ;

    this.rootCauseObj.behaviourOther ='' ;
    this.otherBehaviour_Model ='' ;
    this.equipments_Array.forEach(element => {
      element.checked = false
    });
    this.equipment_ArrayDuplicate =[];
    this.rules_Array.forEach(element => {
      element.checked = false
    });
    this.rules_ArrayDuplicate =[];
  this.behaviour_Array.forEach(element => {
    element.checked = false
  });
  this.behaviour_ArrayDuplicate =[] ;
  this.organisation_Array.forEach(element => {
    element.checked = false
  });
  this.organisation_ArrayDuplicate =[];
  this.capaType_Array.forEach(element => {
    element.checked = false
  });
  this.capaType_ArrayDuplicate = [];

  }

  setTimeout(() => {
    this._setHeaderColourToFifthTab();
    this._setHeaderColourToFourthTab();
    this._isImagesSelected();

  }, 500);
  
}
 
//----- Method of packing  popup functionalities ----//
  openmethodofpacking() {

    this.othermppresent = false;
    if (this.methodpackingarray != null) {
      this.methodpackingarray.forEach((item) => {
        if (item.checked === true && item.name === 'Other') {
          this.othermppresent = true;
        }
      });
    }
    if (this.othermppresent !== false) {
      this.showothermethodPacking = true;
    } else {
      this.showothermethodPacking = false;
      this.othermethodpacking = '';
    }
    jQuery('#Methodofpacking').modal({ backdrop: 'static', keyboard: false });
  }

  cancelmethodofpacking() {
    if (this.aspectsobj.methodPacking === '' || this.aspectsobj.methodPacking === null || this.aspectsobj.methodPacking === undefined) {
      this.showothermethodPacking = false;
      for (let i = 0; i < this.methodpackingarray.length; i++) {
        this.methodpackingarray[i].checked = false;
      }
    } else {
      this.methodpackingarray = JSON.parse(localStorage.getItem('methodpackingarrayL'));
      this.othermppresent = false;
      if (this.methodpackingarray != null) {
        if (this.methodpackingarray.length != 0) {
          this.methodpackingarray.forEach((item) => {
            if (item.checked === true && item.name === 'Other') {
              this.othermppresent = true;
            }
          });
        }
      }



      if (this.othermppresent !== false) {
        this.showothermethodPacking = true;
      } else {
        this.showothermethodPacking = false;
      }
    }

    jQuery('.custom_modal').modal('hide');
  }
  okmethodofpacking() {
    if (this.othermethodpacking != undefined && this.othermethodpacking != '' && this.othermethodpacking != null) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if ((this.showothermethodPacking === true) && (this.othermethodpacking === undefined || this.othermethodpacking === '' || this.othermethodpacking === null)) {
      this.toast.error('Please enter other field for method of packing');
    } else {
      localStorage.setItem('methodpackingarrayL', JSON.stringify(this.methodpackingarray));
      this.mpa = '';
      for (let i = 0; i < this.methodpackingarray.length; i++) {
        if (this.methodpackingarray[i].checked === true) {

          if (this.methodpackingarray[i].name === 'Other') {

            this.mpa = this.mpa + this.othermethodpacking;
          } else {
            if (i + 1 === this.methodpackingarray.length) {
              this.mpa = this.mpa + this.methodpackingarray[i].name;
            } else {
              this.mpa = this.mpa + this.methodpackingarray[i].name + ',';
            }
          }


        }
      }
      if (this.mpa != '' && this.mpa != null && this.mpa != undefined) {
        let val = this.mpa.charAt(this.mpa.length - 1)
        if (val === ',') {
          this.mpa = this.mpa.slice(0, this.mpa.length - 1);
        }
      }
      this.aspectsobj.methodPacking = this.mpa;
      jQuery('.custom_modal').modal('hide');
    }

  }

//----- Damage to packing popup functionalities ----//
  opendamagetopacking() {
    this.otherdppresent = false;
    if (this.damagepackingarray.length != 0) {
      this.damagepackingarray.forEach((item) => {
        if (item.checked === true) {
          this.damagepresent = true;
        }
        if (item.checked === true && item.name === 'Other') {
          this.otherdppresent = true;
        }
      });
    }


    if (this.otherdppresent !== false) {
      this.showotherdamagePacking = true;
    } else {
      this.showotherdamagePacking = false;
      this.otherdamagepacking = '';
    }

    jQuery('#DamagetoPacking').modal({ backdrop: 'static', keyboard: false });
  }



  canceldamagetopacking() {

    if (this.aspectsobj.damagePacking === '') {
      this.showotherdamagePacking = false;
      for (let i = 0; i < this.damagepackingarray.length; i++) {
        this.damagepackingarray[i].checked = false;
      }
    } else {
      this.damagepackingarray = JSON.parse(localStorage.getItem('damagepackingarrayL'));
      this.otherdppresent = false;
      if (this.damagepackingarray != undefined && this.damagepackingarray != null) {

        this.damagepackingarray.forEach((item) => {
          if (item.checked === true) {
            this.damagepresent = true;
          }
          if (item.checked === true && item.name === 'Other') {
            this.otherdppresent = true;
          }
        });

      }
      if (this.otherdppresent !== false) {
        this.showotherdamagePacking = true;
      } else {
        this.showotherdamagePacking = false;
        this.otherdamagepacking = '';
      }
    }

    jQuery('.custom_modal').modal('hide');

  }

  okdamagetopacking() {
    if (this.otherdamagepacking != undefined && this.otherdamagepacking != '' && this.otherdamagepacking != null) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if ((this.showotherdamagePacking === true) && (this.otherdamagepacking === undefined || this.otherdamagepacking === '' || this.otherdamagepacking === null)) {
      this.toast.error('Please enter other field for damage to packing');
    } else {

      localStorage.setItem('damagepackingarrayL', JSON.stringify(this.damagepackingarray));
      this.dpa = '';
      for (let i = 0; i < this.damagepackingarray.length; i++) {
        if (this.damagepackingarray[i].checked === true) {

          if (this.damagepackingarray[i].name === 'Other') {

            this.dpa = this.dpa + this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
          } else {
            if (i + 1 === this.damagepackingarray.length) {
              this.dpa = this.dpa + this.damagepackingarray[i].name;
            } else {
              this.dpa = this.dpa + this.damagepackingarray[i].name + ',';
            }
          }


        }
      }
      if (this.dpa != '' && this.dpa != null && this.dpa != undefined) {
        let val = this.dpa.charAt(this.dpa.length - 1)
        if (val === ',') {
          this.dpa = this.dpa.slice(0, this.dpa.length - 1);
        }
      }

      this.aspectsobj.damagePacking = this.dpa;

      this.damagepresent = false;
      this.otherdppresent = false;
      this.damagepackingarray.forEach((item) => {
        if (item.checked === true) {
          this.damagepresent = true;
        }
        if (item.checked === true && item.name === 'Other') {
          this.otherdppresent = true;
        }
      });

      if (this.damagepresent === false) {
        this.showotherdamagePacking = false;
        this.aspectsobj.classificationType = '';
      } else {
        if (this.aspectsobj.contentAffected === 'unlikely') {
          // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
          this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
        } else if (this.aspectsobj.contentAffected === 'possibly') {
          this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
        } else if (this.aspectsobj.contentAffected === 'yes') {
          this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
        } else {
          this.aspectsobj.classificationType = '';
        }
      }


      if (this.otherdppresent !== false) {
        this.showotherdamagePacking = true;
      } else {
        this.showotherdamagePacking = false;
      }


      jQuery('.custom_modal').modal('hide');

    }
  }

//----- Beahaviour popup functionalities ----//
  isAnyOptionChecked(array: [{ category: string, description: string, id: number, status: string, checked: boolean }]): boolean {
    let isExist = array.some((element) => {
      return (element.checked === true);
    });
    return isExist
  }

  open_BehaviourPopup() {   // new
    this.show_behaviour_Other = false;
    this.behaviour_Array.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.description === 'Other (specify)') {
        this.show_behaviour_Other = true;
      }
    });
    jQuery('#behaviour_popup').modal({ backdrop: 'static', keyboard: false });
  }
  onBehaviourChange(obj) {
    
    if (obj.description === "Other (specify)") {
      if (obj.checked === true) {
        this.show_behaviour_Other = true;
      } else {
        this.show_behaviour_Other = false;
      }
    }
  }
  cancel_BehaviourPopup() {
    
    this.rootCauseObj.behaviourDesc = ''
    this.rootCauseObj.behaviourId = ''
    if (this.behaviour_ArrayDuplicate.length !== 0) {
      this.behaviour_ArrayDuplicate.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.behaviourDesc = this.rootCauseObj.behaviourDesc + element.description + ','
          this.rootCauseObj.behaviourId = this.rootCauseObj.behaviourId + element.id + ','
        }
      })
      this.behaviour_Array.forEach(element1 => {
        let index = this.behaviour_ArrayDuplicate.findIndex(x => x.id === element1.id);
        if (index === -1) {
          element1.checked = false;
        } else {
          element1.checked = true;
        }
      })
    } else {
      this.behaviour_Array.forEach(element => {
        element.checked = false;
      })
    }

    if (this.rootCauseObj.behaviourId != '' && this.rootCauseObj.behaviourId != null && this.rootCauseObj.behaviourId != undefined) {
      let val = this.rootCauseObj.behaviourId.charAt(this.rootCauseObj.behaviourId.length - 1)
      if (val === ',') {
        this.rootCauseObj.behaviourId = this.rootCauseObj.behaviourId.slice(0, this.rootCauseObj.behaviourId.length - 1);
      }
    }
    if (this.rootCauseObj.behaviourDesc != '' && this.rootCauseObj.behaviourDesc != null && this.rootCauseObj.behaviourDesc != undefined) {
      let val = this.rootCauseObj.behaviourDesc.charAt(this.rootCauseObj.behaviourDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.behaviourDesc = this.rootCauseObj.behaviourDesc.slice(0, this.rootCauseObj.behaviourDesc.length - 1);
      }

      if(!this.rootCauseObj.behaviourDesc.includes("Other (specify)")){
        this.rootCauseObj.behaviourOther = '';
        this.otherBehaviour_Model  = '' ;
      }else{
        this.otherBehaviour_Model  =  this.rootCauseObj.behaviourOther ;
      }
    } else {
      this.rootCauseObj.behaviourOther = '';
      this.otherBehaviour_Model = '' ;
    }
    jQuery('#behaviour_popup').modal('hide');
  }
  behaviour_ArrayDuplicate: any = []
  ok_BehaviourPopup() {
    
    if(this.behaviour_Array[this.behaviour_Array.length -1].checked){
      if(this.otherBehaviour_Model === '' ||this.otherBehaviour_Model === null || this.otherBehaviour_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
       return false ;
      }
    }
    this.behaviour_ArrayDuplicate = [];
    if (this.isAnyOptionChecked(this.behaviour_Array)) {
      this.rootCauseObj.behaviourDesc = '';
      this.rootCauseObj.behaviourId = '';
      this.behaviour_Array.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.behaviourDesc = this.rootCauseObj.behaviourDesc + element.description + ','
          this.rootCauseObj.behaviourId = this.rootCauseObj.behaviourId + element.id + ','
          this.behaviour_ArrayDuplicate.push(Object.assign({}, element));
        }
      })


    } else {
      this.rootCauseObj.behaviourDesc = '';
      this.rootCauseObj.behaviourId = '';
    }

    if (this.rootCauseObj.behaviourId != '' && this.rootCauseObj.behaviourId != null && this.rootCauseObj.behaviourId != undefined) {
      let val = this.rootCauseObj.behaviourId.charAt(this.rootCauseObj.behaviourId.length - 1)
      if (val === ',') {
        this.rootCauseObj.behaviourId = this.rootCauseObj.behaviourId.slice(0, this.rootCauseObj.behaviourId.length - 1);
      }
    }
    if (this.rootCauseObj.behaviourDesc != '' && this.rootCauseObj.behaviourDesc != null && this.rootCauseObj.behaviourDesc != undefined) {
      let val = this.rootCauseObj.behaviourDesc.charAt(this.rootCauseObj.behaviourDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.behaviourDesc = this.rootCauseObj.behaviourDesc.slice(0, this.rootCauseObj.behaviourDesc.length - 1);
      }
      if(!this.rootCauseObj.behaviourDesc.includes("Other (specify)")){
        this.rootCauseObj.behaviourOther = ''
        this.otherBehaviour_Model = ''
      }else{

        this.rootCauseObj.behaviourOther = this.otherBehaviour_Model ;
      }
    } else {
      this.rootCauseObj.behaviourOther = ''
      this.otherBehaviour_Model = ''
    }
      jQuery('#behaviour_popup').modal('hide');
  }




//----- Rules popup functionalities ----//
  open_RulesPopup() {   // new
    this.show_rules_Other = false;
    this.rules_Array.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.description === 'Other (specify)') {
        this.show_rules_Other = true;
      }
    });
    jQuery('#rules_popup').modal({ backdrop: 'static', keyboard: false });
  }

   onRulesChange(obj) {
    
    if (obj.description === "Other (specify)") {
      if (obj.checked === true) {
        this.show_rules_Other = true;
      } else {
        this.show_rules_Other = false;
      }
    }
  }
  rules_ArrayDuplicate : any = []
  organisation_ArrayDuplicate : any = []
  cancelRulesPopup() {
    this.rootCauseObj.rulesDesc = '' ;
    this.rootCauseObj.rulesId = '' ;
    if (this.rules_ArrayDuplicate.length !== 0) {
      this.rules_ArrayDuplicate.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.rulesDesc = this.rootCauseObj.rulesDesc + element.description + ','
          this.rootCauseObj.rulesId = this.rootCauseObj.rulesId + element.id + ','
        }
      })
      this.rules_Array.forEach(element1 => {
        let index = this.rules_ArrayDuplicate.findIndex(x => x.id === element1.id);
        if (index === -1) {
          element1.checked = false;
        } else {
          element1.checked = true;
        }
      })

    } else {
      this.rules_Array.forEach(element => {
        element.checked = false;
      })
    }

    if (this.rootCauseObj.rulesId != '' && this.rootCauseObj.rulesId != null && this.rootCauseObj.rulesId != undefined) {
      let val = this.rootCauseObj.rulesId.charAt(this.rootCauseObj.rulesId.length - 1)
      if (val === ',') {
        this.rootCauseObj.rulesId = this.rootCauseObj.rulesId.slice(0, this.rootCauseObj.rulesId.length - 1);
      }
    }
    if (this.rootCauseObj.rulesDesc != '' && this.rootCauseObj.rulesDesc != null && this.rootCauseObj.rulesDesc != undefined) {
      let val = this.rootCauseObj.rulesDesc.charAt(this.rootCauseObj.rulesDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.rulesDesc = this.rootCauseObj.rulesDesc.slice(0, this.rootCauseObj.rulesDesc.length - 1);
      }

      if(!this.rootCauseObj.rulesDesc.includes("Other (specify)")){
        this.rootCauseObj.rulesOther = '';
         this.otherrules_Model  = '' ;
      }else{
        this.otherrules_Model  =  this.rootCauseObj.rulesOther ;
      }
    } else {
      this.rootCauseObj.rulesOther =  '';
      this.otherrules_Model  = '' ;
    }
    jQuery('#rules_popup').modal('hide');
  }

  ok_RulesPopup() {
    
    if(this.rules_Array[this.rules_Array.length -1].checked){
      if(this.otherrules_Model === '' ||this.otherrules_Model === null || this.otherrules_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
        return false ;
      }
    }
    this.rules_ArrayDuplicate = [];

    if (this.isAnyOptionChecked(this.rules_Array)) {

      this.rootCauseObj.rulesDesc = '';
      this.rootCauseObj.rulesId = '';

      this.rules_Array.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.rulesDesc = this.rootCauseObj.rulesDesc + element.description + ','
          this.rootCauseObj.rulesId = this.rootCauseObj.rulesId + element.id + ','
          this.rules_ArrayDuplicate.push(Object.assign({}, element));
        }
      })


    } else {
      this.rootCauseObj.rulesDesc = '';
      this.rootCauseObj.rulesId = '';
    }

    if (this.rootCauseObj.rulesId != '' && this.rootCauseObj.rulesId != null && this.rootCauseObj.rulesId != undefined) {
      let val = this.rootCauseObj.rulesId.charAt(this.rootCauseObj.rulesId.length - 1)
      if (val === ',') {
        this.rootCauseObj.rulesId = this.rootCauseObj.rulesId.slice(0, this.rootCauseObj.rulesId.length - 1);
      }
    }
    if (this.rootCauseObj.rulesDesc !== '' && this.rootCauseObj.rulesDesc !== null && this.rootCauseObj.rulesDesc !== undefined) {
      let val = this.rootCauseObj.rulesDesc.charAt(this.rootCauseObj.rulesDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.rulesDesc = this.rootCauseObj.rulesDesc.slice(0, this.rootCauseObj.rulesDesc.length - 1);
      }
      if(!this.rootCauseObj.rulesDesc.includes("Other (specify)")){
        this.rootCauseObj.rulesOther = ''
        this.otherrules_Model  = ''
      }else{

        this.rootCauseObj.rulesOther = this.otherrules_Model ;
      }
    } else {
      this.rootCauseObj.rulesOther = ''
      this.otherrules_Model  = ''
    }
      jQuery('#rules_popup').modal('hide');
  }

//----- Equpiment popup functionalities ----//
  open_EquipmentPopup() {   // new
    this.show_Equipment_Other = false;
    this.equipments_Array.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.description === 'Other (Specify)') {
        this.show_Equipment_Other = true;
      }
    });
    jQuery('#equipment_popup').modal({ backdrop: 'static', keyboard: false });
  }
  onEquipmentChange(obj) {
    
    if (obj.description === "Other (Specify)") {
      if (obj.checked === true) {
        this.show_Equipment_Other = true;
      } else {
        this.show_Equipment_Other = false;
      }
    }
  }
  equipment_ArrayDuplicate : any = []
  cancelEquipmentPopup() {
    

    this.rootCauseObj.equipmentDesc = '' ;
    this.rootCauseObj.equipmentId = '' ;
    if (this.equipment_ArrayDuplicate.length !== 0) {
      this.equipment_ArrayDuplicate.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.equipmentDesc = this.rootCauseObj.equipmentDesc + element.description + ','
          this.rootCauseObj.equipmentId = this.rootCauseObj.equipmentId + element.id + ','
        }
      })
      this.equipments_Array.forEach(element1 => {
        let index = this.equipment_ArrayDuplicate.findIndex(x => x.id === element1.id);
        if (index === -1) {
          element1.checked = false;
        } else {
          element1.checked = true;
        }
      })

    } else {
      this.equipments_Array.forEach(element => {
        element.checked = false;
      })
    }

    if (this.rootCauseObj.equipmentId != '' && this.rootCauseObj.equipmentId != null && this.rootCauseObj.equipmentId != undefined) {
      let val = this.rootCauseObj.equipmentId.charAt(this.rootCauseObj.equipmentId.length - 1)
      if (val === ',') {
        this.rootCauseObj.equipmentId = this.rootCauseObj.equipmentId.slice(0, this.rootCauseObj.equipmentId.length - 1);
      }
    }
    if (this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== undefined) {
      let val = this.rootCauseObj.equipmentDesc.charAt(this.rootCauseObj.equipmentDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.equipmentDesc = this.rootCauseObj.equipmentDesc.slice(0, this.rootCauseObj.equipmentDesc.length - 1);
      }
      if(!this.rootCauseObj.equipmentDesc.includes("Other (Specify)")){
        this.rootCauseObj.equipmentOther = '' ;
        this.otherEquipment_Model = '' ;
      }else{
        this.otherEquipment_Model =   this.rootCauseObj.equipmentOther ;
      }
    } else {
      this.rootCauseObj.equipmentOther =  '' ;
      this.otherEquipment_Model = '' ;
    }

      jQuery('#equipment_popup').modal('hide');


  }

  ok_EquipmentPopup() {
    
    if(this.equipments_Array[this.equipments_Array.length -1].checked){
      if(this.otherEquipment_Model === '' ||this.otherEquipment_Model === null || this.otherEquipment_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
        return false ;
      }
    }
    this.equipment_ArrayDuplicate = [];

    if (this.isAnyOptionChecked(this.equipments_Array)) {


      this.rootCauseObj.equipmentDesc = '';
      this.rootCauseObj.equipmentId = '';
      this.equipments_Array.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.equipmentDesc = this.rootCauseObj.equipmentDesc + element.description + ','
          this.rootCauseObj.equipmentId = this.rootCauseObj.equipmentId + element.id + ','
          this.equipment_ArrayDuplicate.push(Object.assign({}, element));
        }
      })




    } else {
      this.rootCauseObj.equipmentDesc = '';
      this.rootCauseObj.equipmentId = '';
    }
    if (this.rootCauseObj.equipmentId != '' && this.rootCauseObj.equipmentId != null && this.rootCauseObj.equipmentId != undefined) {
      let val = this.rootCauseObj.equipmentId.charAt(this.rootCauseObj.equipmentId.length - 1)
      if (val === ',') {
        this.rootCauseObj.equipmentId = this.rootCauseObj.equipmentId.slice(0, this.rootCauseObj.equipmentId.length - 1);
      }
    }
    if (this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== undefined) {
      let val = this.rootCauseObj.equipmentDesc.charAt(this.rootCauseObj.equipmentDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.equipmentDesc = this.rootCauseObj.equipmentDesc.slice(0, this.rootCauseObj.equipmentDesc.length - 1);
      }
      if(!this.rootCauseObj.equipmentDesc.includes("Other (Specify)")){
        this.rootCauseObj.equipmentOther = ''
        this.otherEquipment_Model = ''
      }else{

        this.rootCauseObj.equipmentOther = this.otherEquipment_Model ;
      }
    } else {
      this.rootCauseObj.equipmentOther = ''
       this.otherEquipment_Model = ''
    }
      jQuery('#equipment_popup').modal('hide');
  }

  //----- Organisation  popup functionalities ----//
  open_OrganisationPopup() {   // new
    this.show_Organisation_Other = false;
    this.organisation_Array.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.description === 'Other (specify)') {
        this.show_Organisation_Other = true;
      }
    });
    jQuery('#Organisation_popup').modal({ backdrop: 'static', keyboard: false });
  }
  onOrganisationChange(obj) {
    
    if (obj.description === "Other (specify)") {
      if (obj.checked === true) {
        this.show_Organisation_Other = true;
      } else {
        this.show_Organisation_Other = false;
      }
    }
  }

  cancelOrganisationPopup() {
    

    this.rootCauseObj.organisationDesc = '' ;
    this.rootCauseObj.organisationId = '' ;
    if (this.organisation_ArrayDuplicate.length !== 0) {
      this.organisation_ArrayDuplicate.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.organisationDesc = this.rootCauseObj.organisationDesc + element.description + ','
          this.rootCauseObj.organisationId = this.rootCauseObj.organisationId + element.id + ','
        }
      })
      this.organisation_Array.forEach(element1 => {
        let index = this.organisation_ArrayDuplicate.findIndex(x => x.id === element1.id);
        if (index === -1) {
          element1.checked = false;
        } else {
          element1.checked = true;
        }
      })

    } else {
      this.organisation_Array.forEach(element => {
        element.checked = false;
      })
    }

    if (this.rootCauseObj.organisationId != '' && this.rootCauseObj.organisationId != null && this.rootCauseObj.organisationId != undefined) {
      let val = this.rootCauseObj.organisationId.charAt(this.rootCauseObj.organisationId.length - 1)
      if (val === ',') {
        this.rootCauseObj.organisationId = this.rootCauseObj.organisationId.slice(0, this.rootCauseObj.organisationId.length - 1);
      }
    }
    if (this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== undefined) {
      let val = this.rootCauseObj.organisationDesc.charAt(this.rootCauseObj.organisationDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.organisationDesc = this.rootCauseObj.organisationDesc.slice(0, this.rootCauseObj.organisationDesc.length - 1);
      }
      if(!this.rootCauseObj.organisationDesc.includes("Other (specify)")){
        this.rootCauseObj.organisationOther = '' ;
        this.otherOrganisation_Model = '' ;
      }else{
        this.otherOrganisation_Model =   this.rootCauseObj.organisationOther ;
      }
    } else {
      this.rootCauseObj.organisationOther =  '' ;
      this.otherOrganisation_Model = '' ;
    }

      jQuery('#Organisation_popup').modal('hide');


  }

evaluationObj = {checked : false}
  on_evaluationChange(event){
    
    ( event === true ) ? ( this.rootCauseObj.evaluationDone =  'yes' ) : ( this.rootCauseObj.evaluationDone =  'no' ) ;
    this.isFifthTabContainsData();
  }
  ok_OrganisationPopup() {
    
    if(this.organisation_Array[this.organisation_Array.length -1].checked){
      if(this.otherOrganisation_Model === '' ||this.otherOrganisation_Model === null || this.otherOrganisation_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
        return false ;
      }
    }
    this.organisation_ArrayDuplicate = [];

    if (this.isAnyOptionChecked(this.organisation_Array)) {


      this.rootCauseObj.organisationDesc = '';
      this.rootCauseObj.organisationId = '';
      this.organisation_Array.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.organisationDesc = this.rootCauseObj.organisationDesc + element.description + ','
          this.rootCauseObj.organisationId = this.rootCauseObj.organisationId + element.id + ','
          this.organisation_ArrayDuplicate.push(Object.assign({}, element));
        }
      })
    } else {
      this.rootCauseObj.organisationDesc = '';
      this.rootCauseObj.organisationId = '';
    }
    if (this.rootCauseObj.organisationId != '' && this.rootCauseObj.organisationId != null && this.rootCauseObj.organisationId != undefined) {
      let val = this.rootCauseObj.organisationId.charAt(this.rootCauseObj.organisationId.length - 1)
      if (val === ',') {
        this.rootCauseObj.organisationId = this.rootCauseObj.organisationId.slice(0, this.rootCauseObj.organisationId.length - 1);
      }
    }
    if (this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== undefined) {
      let val = this.rootCauseObj.organisationDesc.charAt(this.rootCauseObj.organisationDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.organisationDesc = this.rootCauseObj.organisationDesc.slice(0, this.rootCauseObj.organisationDesc.length - 1);
      }
      if(!this.rootCauseObj.organisationDesc.includes("Other (specify)")){
        this.rootCauseObj.organisationOther = ''
        this.otherOrganisation_Model = ''
      }else{

        this.rootCauseObj.organisationOther = this.otherOrganisation_Model ;
      }
    } else {
      this.rootCauseObj.organisationOther = ''
       this.otherOrganisation_Model = ''
    }
      jQuery('#Organisation_popup').modal('hide');
  }


//----- CapaType popup functionalities ----//
  open_CapaTypePopup() {   // new
    this.show_CapaType_Other = false;
    this.capaType_Array.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.description === 'Other (specify)') {
        this.show_CapaType_Other = true;
      }
    });
    jQuery('#CaPa_popup').modal({ backdrop: 'static', keyboard: false });
  }
  onCapaTypeChange(obj) {
    
    if (obj.description === "Other (specify)") {
      if (obj.checked === true) {
        this.show_CapaType_Other = true;
      } else {
        this.show_CapaType_Other = false;
      }
    }
  }

  cancelCapaTypePopup() {
    
    this.rootCauseObj.capaTypeDesc = '' ;
    this.rootCauseObj.capaTypeId = '' ;
    if (this.capaType_ArrayDuplicate.length !== 0) {
      this.capaType_ArrayDuplicate.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.capaTypeDesc = this.rootCauseObj.capaTypeDesc + element.description + ','
          this.rootCauseObj.capaTypeId = this.rootCauseObj.capaTypeId + element.id + ','
        }
      })
      this.capaType_Array.forEach(element1 => {
        let index = this.capaType_ArrayDuplicate.findIndex(x => x.id === element1.id);
        if (index === -1) {
          element1.checked = false;
        } else {
          element1.checked = true;
        }
      })

    } else {
      this.capaType_Array.forEach(element => {
        element.checked = false;
      })
    }

    if (this.rootCauseObj.capaTypeId != '' && this.rootCauseObj.capaTypeId != null && this.rootCauseObj.capaTypeId != undefined) {
      let val = this.rootCauseObj.capaTypeId.charAt(this.rootCauseObj.capaTypeId.length - 1)
      if (val === ',') {
        this.rootCauseObj.capaTypeId = this.rootCauseObj.capaTypeId.slice(0, this.rootCauseObj.capaTypeId.length - 1);
      }
    }
    if (this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== undefined) {
      let val = this.rootCauseObj.capaTypeDesc.charAt(this.rootCauseObj.capaTypeDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.capaTypeDesc = this.rootCauseObj.capaTypeDesc.slice(0, this.rootCauseObj.capaTypeDesc.length - 1);
      }
      if(!this.rootCauseObj.capaTypeDesc.includes("Other (specify)")){
        this.rootCauseObj.capaTypeOther = '' ;
        this.otherCapaType_Model = '' ;
      }else{
        this.otherCapaType_Model =   this.rootCauseObj.capaTypeOther ;
      }
    } else {
      this.rootCauseObj.capaTypeOther =  '' ;
      this.otherCapaType_Model = '' ;
    }
      jQuery('#CaPa_popup').modal('hide');
  }

  ok_CapaTypePopup() {
    
    if(this.capaType_Array[this.capaType_Array.length -1].checked){
      if(this.otherCapaType_Model === '' ||this.otherCapaType_Model === null || this.otherCapaType_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
        return false ;
      }
    }
    this.capaType_ArrayDuplicate = [];

    if (this.isAnyOptionChecked(this.capaType_Array)) {


      this.rootCauseObj.capaTypeDesc = '';
      this.rootCauseObj.capaTypeId = '';
      this.capaType_Array.forEach(element => {
        if (element.checked === true) {
          this.rootCauseObj.capaTypeDesc = this.rootCauseObj.capaTypeDesc + element.description + ','
          this.rootCauseObj.capaTypeId = this.rootCauseObj.capaTypeId + element.id + ','
          this.capaType_ArrayDuplicate.push(Object.assign({}, element));
        }
      })
    } else {
      this.rootCauseObj.capaTypeDesc = '';
      this.rootCauseObj.capaTypeId = '';
    }
    if (this.rootCauseObj.capaTypeId != '' && this.rootCauseObj.capaTypeId != null && this.rootCauseObj.capaTypeId != undefined) {
      let val = this.rootCauseObj.capaTypeId.charAt(this.rootCauseObj.capaTypeId.length - 1)
      if (val === ',') {
        this.rootCauseObj.capaTypeId = this.rootCauseObj.capaTypeId.slice(0, this.rootCauseObj.capaTypeId.length - 1);
      }
    }
    if (this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== undefined) {
      let val = this.rootCauseObj.capaTypeDesc.charAt(this.rootCauseObj.capaTypeDesc.length - 1)
      if (val === ',') {
        this.rootCauseObj.capaTypeDesc = this.rootCauseObj.capaTypeDesc.slice(0, this.rootCauseObj.capaTypeDesc.length - 1);
      }
      if(!this.rootCauseObj.capaTypeDesc.includes("Other (specify)")){
        this.rootCauseObj.capaTypeOther = ''
        this.otherCapaType_Model = ''
      }else{

        this.rootCauseObj.capaTypeOther = this.otherCapaType_Model ;
      }
    } else {
      this.rootCauseObj.capaTypeOther = ''
       this.otherCapaType_Model = ''
    }
      jQuery('#CaPa_popup').modal('hide');
  }



  onChangeScratchesAndDents() {
    
    this.aspectsobj.dentsOrTears = this.only_scratches_tears_dents ;
    if (this.only_scratches_tears_dents === 'yes') {
      this.aspectsobj.contentAffected = "unlikely";
      this.isDamageToPackingDisable = true;
      this.isItemMissingDisabled = true;
      this.isUnlikelyHidden = false;
      this.damagepackingarray = [
        { name: 'Broken', id: 1, checked: false },
        { name: 'collapsed', id: 17, checked: false },
        { name: 'Crushed', id: 2, checked: false },
        { name: 'Damp', id: 3, checked: false },
        { name: 'Dented', id: 4, checked: false },
        { name: 'Hole in', id: 5, checked: false },
        { name: 'Open', id: 6, checked: false },
        { name: 'Item missing', id: 7, checked: false },
        { name: 'Seal open / broken', id: 8, checked: false },
        { name: 'Shock watch', id: 9, checked: false },
        { name: 'Tape loose and/or torn', id: 10, checked: false },
        { name: 'Torn', id: 14, checked: false },
        { name: 'Wet', id: 15, checked: false },
        { name: 'Other', id: 16, checked: false }
      ];
      this.aspectsobj.damagePacking = '';
      this.aspectsobj.itemMissing = '';
      this.aspectsobj.itemPcs = '';
      this.showItemsMissing = false;
      this.otherdamagepacking = '';
      this.showotherdamagePacking = false;
    } else if (this.only_scratches_tears_dents === 'no') {
      this.aspectsobj.contentAffected = "";
      this.isDamageToPackingDisable = false;
      this.isItemMissingDisabled = false;
      this.isUnlikelyHidden = true;

    }

  }

  //----- Condition of content functionalities ----//
  openConditionofContent() {

    this.otherccpresent = false;
    this.conditioncontentarray.forEach((item) => {
      if (item.checked === true && item.name === 'Other') {
        this.otherccpresent = true;
      }
    });

    if (this.otherccpresent !== false) {
      this.showotherconditioncontent = true;
    } else {
      this.showotherconditioncontent = false;
      this.otherconditioncontent = '';
    }
    jQuery('#ConditionofContent').modal({ backdrop: 'static', keyboard: false });
  }
  cancelConditionofContent() {

    if (this.aspectsobj.conditioncontent === '' || this.aspectsobj.conditioncontent === null || this.aspectsobj.conditioncontent === undefined) {
      this.showotherconditioncontent = false;
      for (let i = 0; i < this.conditioncontentarray.length; i++) {
        this.conditioncontentarray[i].checked = false;
      }
    } else {
      this.conditioncontentarray = JSON.parse(localStorage.getItem('conditioncontentarrayL'));
      this.otherccpresent = false;
      this.conditioncontentarray.forEach((item) => {
        if (item.checked === true && item.name === 'Other') {
          this.otherccpresent = true;
        }
      });

      if (this.otherccpresent !== false) {
        this.showotherconditioncontent = true;
      } else {
        this.showotherconditioncontent = false;
        this.otherconditioncontent = '';
      }
    }

    jQuery('.custom_modal').modal('hide');

  }
  okConditionofContent() {
    if (this.otherconditioncontent != undefined && this.otherconditioncontent != '' && this.otherconditioncontent != null) {
      this.otherconditioncontent = this.otherconditioncontent.replace(/[^a-zA-Z\s]*/g, '')
    }
    if ((this.showotherconditioncontent === true) && (this.otherconditioncontent === undefined || this.otherconditioncontent === '' || this.otherconditioncontent === null)) {
      this.toast.error('Please enter other field for condition of content');
    } else {
      localStorage.setItem('conditioncontentarrayL', JSON.stringify(this.conditioncontentarray));

      this.cca = '';
      for (let i = 0; i < this.conditioncontentarray.length; i++) {
        if (this.conditioncontentarray[i].checked === true) {
          if (this.conditioncontentarray[i].name === 'Other') {
            this.cca = this.cca + this.otherconditioncontent.replace(/[^a-zA-Z\s]*/g, '');
          } else {
            if (i + 1 === this.conditioncontentarray.length) {
              this.cca = this.cca + this.conditioncontentarray[i].name;
            } else {
              this.cca = this.cca + this.conditioncontentarray[i].name + ',';
            }
          }

        }

      }

      if (this.cca != '' && this.cca != null && this.cca != undefined) {
        let val = this.cca.charAt(this.cca.length - 1)
        if (val === ',') {
          this.cca = this.cca.slice(0, this.cca.length - 1);
        }
      }
      this.aspectsobj.conditioncontent = this.cca;

      jQuery('.custom_modal').modal('hide');
    }
  }

//----- Recovery actions  functionalities ----//
  openrecoveryactions() {
    jQuery('#RecoveryActions').modal({ backdrop: 'static', keyboard: false });
    this.otherrqpresent = false;
    this.recuparationstatusarray.forEach((item) => {
      if (item.checked === true && item.name === 'Other') {
        this.otherrqpresent = true;
      }
    });

    if (this.otherrqpresent !== false) {
      this.showotherrecuperatoinStatus = true;
    } else {
      this.showotherrecuperatoinStatus = false;
      this.otherrecuperatoinStatus = '';
    }

  }
  cancelrecoveryactions() {

    if (this.aspectsobj.recuperatoinStatus === '') {
      this.showotherrecuperatoinStatus = false;
      for (let i = 0; i < this.recuparationstatusarray.length; i++) {
        this.recuparationstatusarray[i].checked = false;
      }
    } else {
      this.recuparationstatusarray = JSON.parse(localStorage.getItem('recuparationstatusarrayL'));
      this.otherrqpresent = false;
      if (this.recuparationstatusarray != null && this.recuparationstatusarray != undefined) {
        this.recuparationstatusarray.forEach((item) => {
          if (item.checked === true && item.name === 'Other') {
            this.otherrqpresent = true;
          }
        });
      }


      if (this.otherrqpresent !== false) {
        this.showotherrecuperatoinStatus = true;
      } else {
        this.showotherrecuperatoinStatus = false;
        this.otherrecuperatoinStatus = '';
      }
    }

    jQuery('.custom_modal').modal('hide');

  }
  okrecoveryactions() {

    if (this.otherrecuperatoinStatus != undefined && this.otherrecuperatoinStatus != '' && this.otherrecuperatoinStatus != null) {
      this.otherrecuperatoinStatus = this.otherrecuperatoinStatus.replace(/[^a-zA-Z]*/g, '');
    }
    if ((this.showotherrecuperatoinStatus === true) && (this.otherrecuperatoinStatus === undefined || this.otherrecuperatoinStatus === '' || this.otherrecuperatoinStatus === null)) {
      this.toast.error('Please enter other field for recovery actions');
    } else {
      localStorage.setItem('recuparationstatusarrayL', JSON.stringify(this.recuparationstatusarray));


      this.rsa = '';
      for (let i = 0; i < this.recuparationstatusarray.length; i++) {
        if (this.recuparationstatusarray[i].checked === true) {

          if (this.recuparationstatusarray[i].name === 'Other') {
            this.rsa = this.rsa + this.otherrecuperatoinStatus;
          } else {
            if (i + 1 === this.recuparationstatusarray.length) {
              this.rsa = this.rsa + this.recuparationstatusarray[i].name;
            } else {
              this.rsa = this.rsa + this.recuparationstatusarray[i].name + ',';
            }
          }

        }

      }

      if (this.rsa != '' && this.rsa != null && this.rsa != undefined) {
        let val = this.rsa.charAt(this.rsa.length - 1)
        if (val === ',') {
          this.rsa = this.rsa.slice(0, this.rsa.length - 1);
        }
      }
      this.aspectsobj.recuperatoinStatus = this.rsa;
      jQuery('.custom_modal').modal('hide');
    }



  }

  openforgotcall() { //-----used in without login report creation

    jQuery('#login').modal('hide');
    jQuery('body').removeClass('modal-open')
    jQuery('.modal-backdrop').remove()
    jQuery('#forgotpasswordpopup').modal({ backdrop: 'static', keyboard: false });
  }

  loginClick() { //-----used in without login report creation
    this.rememberMeStatus = true;
    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
    if (remmeberData !== null && remmeberData !== undefined && remmeberData !== '') {
      this.loginForm.get("username").patchValue(remmeberData.emailId)
      this.loginForm.get("passcode").patchValue(remmeberData.pwd)
      if ((remmeberData.emailId === '' || remmeberData.emailId === undefined || remmeberData.emailId === null) && (remmeberData.pwd === '' || remmeberData.pwd === null || remmeberData.pwd === undefined)) {
        this.loginForm.reset();
        this.rememberMeStatus = false;
      }
    } else {
      this.rememberMeStatus = false;
    }
  }

  fn_toClearForgetForm() { //-----used in without login report creation
    this.verifyForgotEmailForm.reset();
  }

  sendpasswordcall() { //-----used in without login report creation
    this.forgotEmail = this.forgotEmail.replace(/\s+/g, '');
    let obj = {
      "emailId": this.forgotEmail
    }

    this.forgotPwdComponent.sendEmail(obj);

  }



  // ----------------- To get the Handler stations-------------------//
  fn_toGetHandlerStations(val: any) {  //-----used in without login register 
    let valueArray = val.target.value.split("&");
    let inputOrgName = '';
    if (valueArray.length > 1) {
      for (let i = 0; i < valueArray.length; i++) {
        inputOrgName = inputOrgName + "_" + valueArray[i];
      }

      if (inputOrgName.charAt(0) === '_') {
        inputOrgName = inputOrgName.slice(1);
      }
    } else {
      inputOrgName = valueArray[0];
    }
    let inputOrgNameCompar = inputOrgName.charAt(0).toUpperCase() + inputOrgName.slice(1);
    if (inputOrgName != null && inputOrgName != undefined && inputOrgName != '') {
      this.DamageReportServicel.getHandlerStations(inputOrgName).subscribe(data => {
        // this.spinner.hide();
        if (data.responseCode === 1 && data.stationDetails.length != 0) {
          this.itemData = [];
          this.isAlredyExistErr = false;
          for (let eachObj of data.stationDetails) {
            let itemValues = {
              code: eachObj.portCode,
              name: eachObj.airportName,
              address: eachObj.address
            }
            this.itemData.push(itemValues);
          }
          this.isshowListstation = true;
          localStorage.setItem('stationcodeData', JSON.stringify(this.itemData));

        } else if (data.responseCode === 0 && data.message === inputOrgName + " is doesn't exists") {
          this.isAlredyExistErr = false;
          this.isshowListstation = false;
          let prevData = JSON.parse(localStorage.getItem("airportdataPREV"));
          localStorage.setItem('stationcodeData', JSON.stringify(prevData));
        } else if (data.responseCode === 0 && data.message === inputOrgNameCompar + " is already registered as Airline/Trucking company, Please check the Organisation Name.") {
          this.alreadyExistMsg = inputOrgName + " is already registered as Airline/Trucking company, Please check the Organisation Name.";
          this.isAlredyExistErr = true;
          this.isshowListstation = false;
          let prevData = JSON.parse(localStorage.getItem("airportdataPREV"));
          localStorage.setItem('stationcodeData', JSON.stringify(prevData));
        }
      },
        error => {
          this.spinner.hide();
        });
    }

  }

  //----------------------airwaybill ,airline , origin ,destination airports search functionalities----------------------------------------
  initializeItems() {
    this.itemsair = JSON.parse(localStorage.getItem('airlinedata'));
    this.items = JSON.parse(localStorage.getItem('airlinedata'));
    this.items3 = JSON.parse(localStorage.getItem('airlinedata'));
    this.items = JSON.parse(localStorage.getItem('flightshipmentdata'));
  }
  initializeItems1() {
    this.items = JSON.parse(localStorage.getItem('flightshipmentdata'));
  }
  initializeItems2() {
    this.items1 = JSON.parse(localStorage.getItem('flightshipmentdata'));
  }
  initilizeStationCodeData() {

    this.stationcodeData = JSON.parse(localStorage.getItem('stationcodeData'));
  }

  getstationItems(ev: any) {

    this.prevSelectedStCode = JSON.parse(localStorage.getItem('prevSelectedStCode'));
    if (this.prevSelectedStCode != undefined && this.prevSelectedStCode != null && this.prevSelectedStCode != '') {

      if (this.prevSelectedStCode === ev) {
        this.selectionStatus = true;
      } else {
        this.selectionStatus = false;

      }

    }

    // Reset items back to all of the items
    this.initilizeStationCodeData()

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initilizeStationCodeData()

      this.stationcodeData = this.stationcodeData.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );

      if (this.stationcodeData.length === 0) {
        this.initializeItems1();
        this.stationcodeData = this.stationcodeData.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showListstation = true;
    } else {

      // hide the results when the query is empty
      this.showListstation = false;
    }
  }

  getItems(ev: any) {
    this.airwaybillobj.originAirportId = ''
    // Reset items back to all of the items
    this.initializeItems2();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.items1 = this.items1.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );

      if (this.items1.length === 0) {
        this.initializeItems2();
        this.items1 = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showList = true;
    } else {

      // hide the results when the query is empty
      this.showList = false;
    }
  }

  getItems1(ev: any) {
    this.airwaybillobj.destairportId = ''
    // Reset items back to all of the items
    this.initializeItems1();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.items = this.items.filter((item) => item.code.toLowerCase().startsWith(val.toLowerCase()));

      if (this.items.length === 0) {
        this.initializeItems1();
        this.items = this.items.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showList1 = true;
    } else {

      // hide the results when the query is empty
      this.showList1 = false;
    }
  }
  getItemsair(ev: any) {

    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initializeItems();
      this.itemsair = this.itemsair.filter((item) => item.carrierName.toLowerCase().startsWith(val.toLowerCase()));

      // Show the results
      this.showListair = true;
    } else {

      // hide the results when the query is empty
      this.showListair = false;
    }
  }
  getitemssq(ev: any) {
    this.isFlight_Selected_Frm_List = false;
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initializeItems();
      this.items3 = this.items3.filter((item) => item.IATA.toLowerCase().startsWith(val.toLowerCase()));

      // Show the results
      this.showitemssq = true;
    } else {

      // hide the results when the query is empty
      this.showitemssq = false;
    }
  }
  getitemsPrefix(ev: any) {

    this.iscarrier_Selected_Frm_List = false;
    // Reset items back to all of the items
    this.initializeItems();
    this.prefixArray = this.items3;

    

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      
      this.initializeItems();
      this.prefixArray = this.prefixArray.filter((item) => {
        if (item.AWB_prefix !== null && item.AWB_prefix !== undefined) {
         return item.AWB_prefix.toLowerCase().startsWith(val.toLowerCase())
        }
      });

      // Show the results
      this.showitemPrefix = true;
    } else {

      // hide the results when the query is empty
      this.showitemPrefix = false;
    }
  }

  changeairportId() {
    // this.flightshipmentdata = JSON.parse(localStorage.getItem('flightshipmentdata'));
    // this.destport = false;
    // if ((this.destairportId === '') || (this.destairportId === undefined)) {
    //   this.airwaybillobj.destairportId = '';
    // }
    // if (this.flightshipmentdata != null && this.flightshipmentdata != '' && this.flightshipmentdata != undefined) {
    //   this.flightshipmentdata.forEach((item) => {
    //     if (item.name === this.destairportId) {
    //       this.airwaybillobj.destairportId = item.name + '-' + item.code;
    //       this.destairportId = item.name + '-' + item.cityCode;
    //       this.destport = true;
    //     }
    //   });
    // }

    // if (this.destport === false) {
    //   this.airwaybillobj.destairportId = '';
    // }

  }
  selectresult(item) {

    this.airwaybillobj.destairportId = item.name + '-' + item.code;
    this.destairportId = item.name + '-' + item.code;
    this.showList1 = false;
  }

  changeairportIdorigin() {
    //
    // this.flightshipmentdata = JSON.parse(localStorage.getItem('flightshipmentdata'));
    // this.originport = false;
    // if ((this.originAirportId === '') || (this.originAirportId === undefined)) {
    //   this.airwaybillobj.originAirportId = '';
    // }
    // if (this.flightshipmentdata != null && this.flightshipmentdata != '' && this.flightshipmentdata != undefined) {
    //   this.flightshipmentdata.forEach((item) => {
    //     if (item.name === this.originAirportId) {
    //       this.airwaybillobj.originAirportId = item.name + '-' + item.code;
    //       this.originAirportId = item.name + '-' + item.cityCode; //new
    //       this.originport = true;
    //     }
    //   });
    // }

    // if (this.originport === false) {
    //   this.airwaybillobj.originAirportId = '';
    // }
  }
  selectresultoriginid(item) {

    this.airwaybillobj.originAirportId = item.name + '-' + item.code;
    this.originAirportId = item.name + '-' + item.code;
    this.showList = false;
  }

  selectstationcode(item) {

    this.stationCodestore = item.code;
    this.shipmentobj.stationCode = item.code;

    if (this.isshowListstation === true) {
      this.selectedAddress = item.address;
    } else {
      this.selectedAddress = '';
    }
    this.selectionStatus = true;

    this.showListstation = false;
  }

  slectflightcall(item) {
     
    this.airwaybillobj.airlineId = item.carrierName;
    this.airlineId = item.carrierName;
    this.shipmentobj.carrierId = item.carrierId;
    this.isFlight_Selected_Frm_List = true;
    this.flight1 = item.IATA;
    this.flight0 = item.AWB_prefix;
    this.showitemssq = false;
  }

  slectPrefixcall(item) {
     this.selectedPrefixId = item.carrierId;
    this.showitemPrefix = false;
    this.selectresultairline(item);
    this.slectflightcall(item);
    
    document.getElementById("_awbSuffix").focus()
  }

  selectresultairline(item) {
    
     
    // this.airwaybillobj.masterAwbNo = item.AWB_prefix;
    this.airwaybillobj.airlineId = item.carrierName;
    this.airlineId = item.carrierName;
    // if ((this.flight1 === '') || (this.flight1 === undefined)) {
      
    this.shipmentobj.carrierId = item.carrierId;
      this.flight1 = item.IATA;
      this.flight0 = item.AWB_prefix;
    // }
    this.showListair = false;
  }

  transportcall(eve) {
    this.airwaybillobj.truckType = eve.target.value;
    if (eve.target.value === 'flight') {
      this.showflighttrucknum = false;
    } else {
      this.showflighttrucknum = true;
    }
  }


  momntcall(val) {
    this.airwaybillobj.movementType = val.target.value;
  }

  spacecall(event) {
     
    if (event !== undefined && event !== null) {
      this.masterAwbNo1 = event;
      let trimmed =event.replace(/\s+/g, '');
      if (trimmed.length > 16) {
        trimmed = trimmed.substr(0, 16);
      }
      const numbers = [];
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
      this.masterAwbNo1 = numbers.join(' ');
    }

    if(this.masterAwbNo1.length === 9){
      document.getElementById("next_ToAwb_flightnumber").focus()
    }
    // this.awbExistedCheck();
  }

  masterAwbNochange() {
     
    this.awb = false;
    this.airlinedata = JSON.parse(localStorage.getItem('airlinedata'));
    if (this.airlinedata != '' && this.airlinedata != null && this.airlinedata != undefined) {
      this.airlinedata.forEach((item) => {
        if (item.AWB_prefix === this.airwaybillobj.masterAwbNo) {
          this.airwaybillobj.airlineId = item.carrierName;
          this.airlineId = item.carrierName;
          this.shipmentobj.carrierId = item.carrierId;
          // if ((this.flight1 === '') || (this.flight1 === undefined)) {
            if(this.airwaybillobj.masterAwbNo === '203'){
              // if(this.airlineId === "Airtstname"){
                this.flight1 = '5J';
                this.flight0 = item.AWB_prefix;
                this.airlineId = "Cebu Pacific Air";
                this.airwaybillobj.airlineId = this.airlineId;
                this.shipmentobj.carrierId = 182;
              // }else{
              //   this.flight1 = '5J';
              //   this.flight0 = item.AWB_prefix;
              //   this.airlineId = "Cebu Pacific Air";
              //   this.airwaybillobj.airlineId = this.airlineId;
              // }
              
            } else{
              this.flight1 = item.IATA;
              this.flight0 = item.AWB_prefix;
              // if(this.airwaybillobj.masterAwbNo === '998'){
              //   // Show the results
              //   this.show998 = true;
              // }
            }
            // this.flight1 = item.IATA;
            // this.flight0 = item.AWB_prefix;
          // }
          this.awb = true;
          this.isFlight_Selected_Frm_List = true;
        }
      });
    }

    if (this.awb === false) {
      this.airwaybillobj.airlineId = '';
      this.airlineId = '';
    }

    this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '')
    // if(this.airwaybillobj.masterAwbNo.length === 3){
    //     document.getElementById("_awbSuffix").focus()
    // }

  }

  masterAwbNochange_update(){
    this.awb = false;
    this.airlinedata = JSON.parse(localStorage.getItem('airlinedata'));
    if (this.airlinedata != '' && this.airlinedata != null && this.airlinedata != undefined) {
      this.airlinedata.forEach((item) => {
        if (item.carrierName === this.airwaybillobj.airlineId) {
          this.airwaybillobj.airlineId = item.carrierName;
          this.airlineId = item.carrierName;
          this.shipmentobj.carrierId = item.carrierId;
          // if ((this.flight1 === '') || (this.flight1 === undefined)) {
            if(this.airwaybillobj.masterAwbNo === '203'){
              this.flight1 = '5J';
              this.flight0 = item.AWB_prefix;
              this.airlineId = "Cebu Pacific Air";
            } else{
              this.flight1 = item.IATA;
              this.flight0 = item.AWB_prefix;
            }
            // this.flight1 = item.IATA;
            // this.flight0 = item.AWB_prefix;
          // }
          this.awb = true;
          this.isFlight_Selected_Frm_List = true;
        }
      });
    }

    if (this.awb === false) {
      this.airwaybillobj.airlineId = '';
      this.airlineId = '';
    }

    this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '')
    // if(this.airwaybillobj.masterAwbNo.length === 3){
    //     document.getElementById("_awbSuffix").focus()
    // }
  }
  //----------------------airwaybill----------------------------------------//

  //-----------------------investigation--------------------------------------
  onChange(locationIncident) {

    this.duringbuildup = false;
    this.duringstorage = false;
    this.duringloading = false;
    this.external = false;

    const obj = locationIncident.target.value;
    this.showotherprocessstage = false;

    if (locationIncident.target.value === 'Other') {
      this.showotherprocessstage = true;
    }

    if (obj.length === 0) {
      this.showotheraspectsobj = false;
    }
    if (obj === 'Other') {
      this.showotheraspectsobj = true;
    } else {

      this.showotheraspectsobj = false;
      if (this.damageDiscoveredstore === 'Other') {
        this.showotherprocessstage = true;
      }
    }
    if (this.damageDiscoveredstore != 'Other') {
      this.otherprocessstage = ''
    }
  }
  respocall(eve) {

    if (eve.target.value === 'external') {
      this.showbelowfields = false;
      this.assessmentobj.internalArea = '';
      this.assessmentobj.areaSupervisor = '';
      this.assessmentobj.exactLocation = '';
      this.assessmentobj.locatonId = '';
      // this.assessmentobj.casueOfEvent = '';
      this.internalAreastore = '';
      this.otherprocessarea = '';
     this.is_Behaviour_And_Other_FieldsEnable = false ;
     this.isExtraTabEnable = false;
     this.reset_RootCause() ;
    } else {
      this.showbelowfields = true;
      this.is_Behaviour_And_Other_FieldsEnable = true ;
      
      // this.rootCauseObj.equipmentDesc = '' ;
      // this.rootCauseObj.equipmentId = '' ;
      // this.rootCauseObj.rulesDesc = '' ;
      // this.rootCauseObj.rulesId = '' ;
      // this.rootCauseObj.behaviourDesc = '' ;
      // this.rootCauseObj.behaviourId = '' ;

      // this.rootCauseObj.organisationDesc = '' ;
      // this.rootCauseObj.rulesOther = '' ;
      // this.otherrules_Model =  '' ;

      // this.rootCauseObj.equipmentOther = '' ;
      // this.otherEquipment_Model = '' ;

      // this.rootCauseObj.behaviourOther ='' ;
      // this.otherBehaviour_Model ='' ;

       this.isExtraTabEnable = true;
         

       this.reset_RootCause() ;




    }
  }
  onChangeia(locationIncident) {

    const obj = this.internalAreastore;
    if (obj.length === 0) {
      this.showotherinternalArea = false;
    }
    if (obj === 'Other') {
      this.showotherinternalArea = true;
    } else {
      this.showotherinternalArea = false;
      this.otherprocessarea = ''
    }

  }

  changecaused(val) {
    this.assessmentobj.discoveresCaused = val.target.value;
    const aspectsobject = this.assessmentobj.damageDiscovered;
    const obj = val.target.value;

    if (obj === 'discoveredby') {
      if (aspectsobject !== null) {
        if ((aspectsobject !== '') && (aspectsobject !== null) && (aspectsobject.length !== 0)) {

          if ((aspectsobject === 'During build-up') || (aspectsobject === 'Warehouse storage') || (aspectsobject === 'Loading truck') || (
            (

              aspectsobject !== "Unloading aircraft" &&
              aspectsobject !== "Unloading truck" &&
              aspectsobject !== "Loading aircraft" &&
              aspectsobject !== "Breakdown / check-in" &&
              aspectsobject !== "Delivery to agent" &&
              aspectsobject !== "Transport in warehouse" &&
              aspectsobject !== "Transport to/from aircraft" &&
              aspectsobject !== "" &&
              aspectsobject !== undefined &&
              aspectsobject !== null
            )



          )) {
            this.assessmentobj.internalResponsibility = 'internal';
            this.showbelowfields = true;
            this.isExtraTabEnable = true ;
            this.reset_RootCause() ;
          } else {
            this.assessmentobj.internalResponsibility = 'external';
            this.isExtraTabEnable = false ;
            this.reset_RootCause() ;
            this.assessmentobj.internalArea = '';
            this.assessmentobj.areaSupervisor = '';
            this.assessmentobj.exactLocation = '';
            this.assessmentobj.locatonId = '';
            this.assessmentobj.casueOfEvent = '';
            this.internalAreastore = '';
            this.otherprocessarea = '';

            this.showbelowfields = false;
          }
        } else {
          this.assessmentobj.internalResponsibility = '';
        }


      } else {
        this.assessmentobj.internalResponsibility = '';
      }
    } else if (obj === 'causedby') {
      this.assessmentobj.internalResponsibility = 'internal';
      this.showbelowfields = true;
      this.isExtraTabEnable = true ;
      this.reset_RootCause() ;
    } else {
      this.assessmentobj.internalResponsibility = '';
    }
  }
  //-----------------------investigation--------------------------------------

  //-----------------------damageinfo------------------------------------------
  onChangemp(val) {

    this.othermppresent = false;
    this.methodpackingarray.forEach((item) => {
      if (item.checked === true && item.name === 'Other') {
        this.othermppresent = true;
      }
    });

    if (this.othermppresent !== false) {
      this.showothermethodPacking = true;
    } else {
      this.showothermethodPacking = false;
    }


  }

  onChangedp(val) {

    this.damagepresent = false;
    this.otherdppresent = false;
    this.damagepackingarray.forEach((item) => {
      if (item.checked === true) {
        this.damagepresent = true;
      }
      if (item.checked === true && item.name === 'Other') {
        this.otherdppresent = true;
      }
    });

    if (this.damagepresent === false) {
      this.showotherdamagePacking = false;
      this.aspectsobj.classificationType = '';
    } else {
      if (this.aspectsobj.contentAffected === 'unlikely') {
        // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
        this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
      } else if (this.aspectsobj.contentAffected === 'possibly') {
        this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
      } else if (this.aspectsobj.contentAffected === 'yes') {
        this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
      } else {
        this.aspectsobj.classificationType = '';
      }
    }


    if (this.otherdppresent !== false) {
      this.showotherdamagePacking = true;
    } else {
      this.showotherdamagePacking = false;
    }
  }


  contentaffctd(val) {
    if (val.target.value === 'no') {
      this.aspectsobj.conditioncontent = '';
      this.showPiecesAffected = false;
      this.showCondition = false;
    } else {
      
      this.conditioncontentarray.forEach(element => {
        element.checked = false
      });
      this.aspectsobj.conditioncontent = '';
      this.showPiecesAffected = true;
      this.showCondition = false;
    }
    if (val.target.value === 'yes') {
      this.aspectsobj.conditioncontent = '';
      this.conditioncontentarray.forEach(element => {
        element.checked = false
      });
      this.showCondition = true;
    }
    this.damagepresent = false;
    this.damagepackingarray.forEach((item) => {
      if (item.checked === true) {
        this.damagepresent = true;
      }
    });

    if ((this.damagepresent !== false) && (val.target.value === 'unlikely')) {
      // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
      this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
    } else if ((this.damagepresent !== false) && (val.target.value === 'possibly')) {
      this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
    } else if ((this.damagepresent !== false) && (val.target.value === 'yes')) {
      this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
    } else {
      this.aspectsobj.classificationType = '';
    }
  }
  itemmiss(val) {

    if (val.target.value === 'no') {
      this.showItemsMissing = false;
      this.aspectsobj.itemPcs = ''
      this.aspectsobj.suspectPilf = ''
    } else {
      this.showItemsMissing = true;
    }
  }
  onChangecc(val) {
    this.otherccpresent = false;
    this.conditioncontentarray.forEach((item) => {
      if (item.checked === true && item.name === 'Other') {
        this.otherccpresent = true;
      }
    });

    if (this.otherccpresent !== false) {
      this.showotherconditioncontent = true;
    } else {
      this.showotherconditioncontent = false;
    }
  }
  pilferagechange(val) {

    if (val.target.value === 'yes') {
      this.aspectsobj.classificationType = 'Pilferage - Cargo suspected of Pilferage Inspection is Required';
    } else {
      // new change
      this.aspectsobj.classificationType = ''
    }
  }
  lifethreatchange(val) {
    if (val.target.value === 'yes') {
      this.aspectsobj.classificationType = 'Life Threat - Damage involving safety of life or property Inspection, Decontamination and verification is required.(e.g. chemical, biological, radioactive leakage or spillage)';
      this.aspectsobj.recuperationReq = 'yes';
      this.showrecoveryactions = true;
    } else {
      // new change
      this.aspectsobj.recuperationReq = '';
      this.aspectsobj.classificationType = '';
      this.showrecoveryactions = false;
      this.aspectsobj.recuperatoinStatus = '';
      this.recuparationstatusarray = [
        { name: 'Contents destroyed', id: 1, checked: false },
        { name: 'Contents repacked', id: 2, checked: false },
        { name: 'No lack of time', id: 3, checked: false },
        { name: 'Package corded', id: 4, checked: false },
        { name: 'Package sealed', id: 5, checked: false },
        { name: 'Package strapped', id: 6, checked: false },
        { name: 'Package taped', id: 7, checked: false },
        { name: 'Other', id: 9, checked: false }
      ];
    }
  }
  onChangers(val) {


    this.otherrqpresent = false;
    this.recuparationstatusarray.forEach((item) => {
      if (item.checked === true && item.name === 'Other') {
        this.otherrqpresent = true;
      }
    });

    if (this.otherrqpresent !== false) {
      this.showotherrecuperatoinStatus = true;
    } else {
      this.showotherrecuperatoinStatus = false;
    }


  }
  morehawscall() {

    this.affectedAwbarray.push({
      awb: ''
    });
  }

  cancelmorehawscall(i) {

    this.affectedAwbarray.splice(i, 1);
  }



  //-----------------------damageinfo------------------------------------------

  //----------------------------uploadimg-----------------------------------------------

  blobToBase64(blob, callback) {
    let reader = new FileReader();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    const watermarkOptions = {
      type: 'image/jpeg',
    };
    reader.onload = function () {
      let dataUrl = reader.result;
      dataUrl = blob;
      watermark([dataUrl],watermarkOptions)
      .dataUrl(watermark.text.lowerLeft('', "0px Arial", '#bfbfbf', 0))
      .then(function (img) {
        let imgElement = new Image();
        imgElement.src = img;
        imgElement.onload = function() {
          let imageWidth = imgElement.width;
          let imageHeight = imgElement.height;
          let fontSize = Math.min(imageWidth, imageHeight) * 0.07; // Adjust the multiplier as needed
          watermark([img],watermarkOptions)
          .dataUrl(watermark.text.lowerLeft(formattedDate, `${fontSize}px Arial`, '#bfbfbf', 1))
          .then(function (img2) {
            callback(img2); // Call the callback with the watermarked image
          });
        }
      });
    };
    reader.readAsDataURL(blob);
  };
  public static _imagesArrayTotal = [];
  selectimages(val) {
    let c = 0;
    const self = this;
    this.imageslist = [];
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(',')[1];
        SaveReportsComponent._imagesArrayTotal.push(base64);
        self.imageslist = SaveReportsComponent._imagesArrayTotal
        self.preview(SaveReportsComponent._imagesArrayTotal);
        self._isImagesSelected()
      })
    }
  }
  
  //--------To preview images------------//
  preview(files) {

    this.urls = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = 'data:image/png;base64,' + file;
        this.urls.push(image);
        this.urlArrayTotal = this.urls
      }
    }
  }

  on_Cancel_Image(i) {
    this.imageslist.splice(i, 1);
    this.urls.splice(i, 1);
    this._isImagesSelected()

  }


  on_Cancel_PreviousImage(i) {
    localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    // if(this.is_From === 'EDIT_REPORT'){

    // }
    this.damage_Report_Images.splice(i, 1)
    this._isImagesSelected()
  }
  //---------------------------End images uploadimg-----------------------------------------------


  onCancelPage() { ///--- used for resticting user navigation to other page if report is not saved
    let isleavePage = JSON.parse(localStorage.getItem("isSaveReportLeave"));
    if (isleavePage === 'yes') {
      this.navigationEnable = true;
      localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
      if ((this.is_From === 'EDIT_REPORT') && (!this.isSavedOnce)) {
        if (this.loginuserdetailsTest.userType === 'S') {
          if (this.from_csaDashBoard != undefined) {
            if (this.from_csaDashBoard === 'true') {

              if (this.loginuserdetailsTest.orgType === 'A') {
                this.route.navigateByUrl("/csa-airline-dashboard");
              } else {
                this.route.navigateByUrl("/csa-dashboard");
              }
            }
          } else if (this.from_view_Users != undefined) {
            if (this.from_view_Users === 'true') {
              let obj = {
                is_From_Edit_DamageReport: true
              }
              this.route.navigate(["/viewusers"], { queryParams: obj });
            }
          } else {
            // this.location.back(); //changed
            if (this.loginuserdetailsTest.orgType === 'A') {
              this.route.navigateByUrl("/csa-airline-dashboard");
            } else {
              this.route.navigateByUrl("/csa-dashboard");
            }
          }
        } else if (this.loginuserdetailsTest.userType === 'E') {


          if (this.from_userDashboard != undefined) {
            if (this.from_userDashboard === 'true') {
              this.route.navigateByUrl("/user-dashboard");
            }
          } else if (this.from_view_Users != undefined) {
            if (this.from_view_Users === 'true') {
              this.route.navigateByUrl("/viewusers");
            }
          } else {
            // this.location.back(); //changed
            this.route.navigateByUrl("/user-dashboard");
          }

        } else if (this.loginuserdetailsTest.userType === 'SA') {
          if (this.is_From_sideReports != undefined && this.is_From_sideReports != null && this.is_From_sideReports != '') {
            if (this.is_From_sideReports === "true") {
              this.route.navigate(["/viewreports"]);
            } else {
              this.route.navigateByUrl("/sa-dashboard");
            }
          } else {
            let obj = {
              is_From_Edit_DamageReport: true
            }

            this.route.navigate(["/viewusers"], { queryParams: obj });
          }

        } else if (this.loginuserdetailsTest.userType === 'A') {
          this.ViewReportsData.isFromDashboard = 'yes';
          if (this.loginuserdetailsTest.orgType === 'A') {
            this.route.navigate(['admin-airline-dashboard'], {

              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          } else {
            this.route.navigate(['/admin-handler-dashboard'], {
              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          }
        }
      } else {
        if (this.loginuserdetailsTest.userType === 'S') {
          if (this.loginuserdetailsTest.orgType === 'A') {
            this.route.navigateByUrl("/csa-airline-dashboard");
          } else {
            this.route.navigateByUrl("/csa-dashboard");
          }
        } else if (this.loginuserdetailsTest.userType === 'E') {
          this.route.navigateByUrl("/user-dashboard");
        } else if (this.loginuserdetailsTest.userType === 'SA') {
          this.route.navigateByUrl("/sa-dashboard");
        } else if (this.loginuserdetailsTest.userType === 'A') {
          this.ViewReportsData.isFromDashboard = 'yes';
          if (this.loginuserdetailsTest.orgType === 'A') {
            this.route.navigate(['admin-airline-dashboard'], {

              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          } else {
            this.route.navigate(['/admin-handler-dashboard'], {
              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          }
        }
      }
    } else {
      jQuery('#isLeavePageWithouSave').modal({ backdrop: 'static', keyboard: false });

    }
  }


  onClick_YesLeavepagePopup() { ///--- used when user wish to navigate to other page even though report is not saved
    jQuery('#isLeavePageWithouSave').modal("hide");
    this.navigationEnable = true;
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
    if ((this.is_From === 'EDIT_REPORT') && (!this.isSavedOnce)) {
      if (this.loginuserdetailsTest.userType === 'S') {
        if (this.from_csaDashBoard != undefined) {
          if (this.from_csaDashBoard === 'true') {
            if (this.loginuserdetailsTest.orgType === 'A') {
              this.route.navigateByUrl("/csa-airline-dashboard");
            } else {
              this.route.navigateByUrl("/csa-dashboard");
            }
          }
        } else if (this.from_view_Users != undefined) {
          if (this.from_view_Users === 'true') {
            let obj = {
              is_From_Edit_DamageReport: true
            }
            this.route.navigate(["/viewusers"], { queryParams: obj });
          }
        } else {
          // this.location.back();  //changed
          if (this.loginuserdetailsTest.orgType === 'A') {
            this.route.navigateByUrl("/csa-airline-dashboard");
          } else {
            this.route.navigateByUrl("/csa-dashboard");
          }

        }
      } else if (this.loginuserdetailsTest.userType === 'E') {


        if (this.from_userDashboard != undefined) {
          if (this.from_userDashboard === 'true') {
            this.route.navigateByUrl("/user-dashboard");
          }
        } else if (this.from_view_Users != undefined) {
          if (this.from_view_Users === 'true') {
            this.route.navigateByUrl("/viewusers");
          }
        } else {
          // this.location.back(); //changed

          this.route.navigateByUrl("/user-dashboard");
        }

      } else if (this.loginuserdetailsTest.userType === 'SA') {
        if (this.is_From_sideReports != undefined && this.is_From_sideReports != null && this.is_From_sideReports != '') {
          if (this.is_From_sideReports === "true") {
            this.route.navigate(["/viewreports"]);
          } else {
            this.route.navigateByUrl("/sa-dashboard");
          }
        } else {
          let obj = {
            is_From_Edit_DamageReport: true
          }

          this.route.navigate(["/viewusers"], { queryParams: obj });
        }

      } else if (this.loginuserdetailsTest.userType === 'A') {
        this.ViewReportsData.isFromDashboard = 'yes';
        if (this.loginuserdetailsTest.orgType === 'A') {
          this.route.navigate(['admin-airline-dashboard'], {

            queryParams: {
              DashBorad: 'switched'
            },
            queryParamsHandling: 'merge',
          });
        } else {
          this.route.navigate(['/admin-handler-dashboard'], {
            queryParams: {
              DashBorad: 'switched'
            },
            queryParamsHandling: 'merge',
          });
        }
      }
    } else {
      if (this.loginuserdetailsTest.userType === 'S') {
        if (this.loginuserdetailsTest.orgType === 'A') {
          this.route.navigateByUrl("/csa-airline-dashboard");
        } else {
          this.route.navigateByUrl("/csa-dashboard");
        }
      } else if (this.loginuserdetailsTest.userType === 'E') {
        this.route.navigateByUrl("/user-dashboard");
      } else if (this.loginuserdetailsTest.userType === 'SA') {
        this.route.navigateByUrl("/sa-dashboard");
      } else if (this.loginuserdetailsTest.userType === 'A') {
        this.ViewReportsData.isFromDashboard = 'yes';
        if (this.loginuserdetailsTest.orgType === 'A') {
          this.route.navigate(['admin-airline-dashboard'], {

            queryParams: {
              DashBorad: 'switched'
            },
            queryParamsHandling: 'merge',
          });
        } else {
          this.route.navigate(['/admin-handler-dashboard'], {
            queryParams: {
              DashBorad: 'switched'
            },
            queryParamsHandling: 'merge',
          });
        }
      }
    }
  }

  onClick_NoleavePage() {
    jQuery('#isLeavePageWithouSave').modal("hide");
  }


  onClickT_D(event) {  // terms and conditions check 
    if (event.target.checked === true) {
      this.istermsAndCond_chked = 'Y';
      this.checkedT_d = true;
      this.checkStatus = true
    } else {
      this.istermsAndCond_chked = 'N';
      this.checkedT_d = false;
    }
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }




  ///------ below functions used in previous ui (without tabs) present not using -------//
  savedamagecall() {
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.stationCode != null && this.shipmentobj.stationCode != '' && this.shipmentobj.stationCode != undefined) {
      this.shipmentobj.stationCode = this.shipmentobj.stationCode.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.reportPreparedName != null && this.shipmentobj.reportPreparedName != '' && this.shipmentobj.reportPreparedName != undefined) {
      this.shipmentobj.reportPreparedName = this.shipmentobj.reportPreparedName.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.reportPreparedFunction != null && this.shipmentobj.reportPreparedFunction != '' && this.shipmentobj.reportPreparedFunction != undefined) {
      this.shipmentobj.reportPreparedFunction = this.shipmentobj.reportPreparedFunction.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.masterAwbNo1 != '' && this.masterAwbNo1 != null && this.masterAwbNo1 != undefined) {
      this.masterAwbNo1 = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    }
    if (this.airwaybillobj.masterAwbNo != '' && this.airwaybillobj.masterAwbNo != null && this.airwaybillobj.masterAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '');
    }
    if (this.flight2 != '' && this.flight2 != null && this.flight2 != undefined) {
      this.flight2 = this.flight2.replace(/[^0-9A-Za-z]*/g, '');
    }
    if (this.airlineId != null && this.airlineId != '' && this.airlineId != undefined) {
      this.airlineId = this.airlineId.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.originAirportId != null && this.originAirportId != '' && this.originAirportId != undefined) {
      this.originAirportId = this.originAirportId.replace(/[^a-zA-Z\-\s]*/g, '');
    }
    if (this.destairportId != null && this.destairportId != '' && this.destairportId != undefined) {
      this.destairportId = this.destairportId.replace(/[^a-zA-Z\-\s]*/g, '')
    }
    if (this.flightobj.mawbTotalpcs != null && this.flightobj.mawbTotalpcs != '' && this.flightobj.mawbTotalpcs != undefined) {
      this.flightobj.mawbTotalpcs = this.flightobj.mawbTotalpcs.replace(/[^0-9]*/g, '');
    }
    if (this.flightobj.actualReceivedPcs != null && this.flightobj.actualReceivedPcs != '' && this.flightobj.actualReceivedPcs != undefined) {
      this.flightobj.actualReceivedPcs = this.flightobj.actualReceivedPcs.replace(/[^0-9]*/g, '');
    }
    if (this.othermethodpacking != null && this.othermethodpacking != '' && this.othermethodpacking != undefined) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.otherdamagepacking != null && this.otherdamagepacking != '' && this.otherdamagepacking != undefined) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.aspectsobj.contentPieces != null && this.aspectsobj.contentPieces != '' && this.aspectsobj.contentPieces != undefined) {
      this.aspectsobj.contentPieces = this.aspectsobj.contentPieces.replace(/[^0-9]*/g, '');
    }
    if (this.aspectsobj.itemWeight != null && this.aspectsobj.itemWeight != '' && this.aspectsobj.itemWeight != undefined) {
      this.aspectsobj.itemWeight = this.aspectsobj.itemWeight.replace(/[^0-9\.]*/g, '');
    }
    if (this.aspectsobj.itemPcs != null && this.aspectsobj.itemPcs != '' && this.aspectsobj.itemPcs != undefined) {
      this.aspectsobj.itemPcs = this.aspectsobj.itemPcs.replace(/[^0-9]*/g, '');
    }
    if (this.affectedAwbarray.length != 0) {
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (this.affectedAwbarray[i].awb != '' && this.affectedAwbarray[i].awb != null && this.affectedAwbarray[i].awb != undefined) {
          this.affectedAwbarray[i].awb = this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        }
      }
    }
    if (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) {
      this.assessmentobj.internalName = this.assessmentobj.internalName.replace(/[^a-zA-Z\.\s]*/g, '');
    }
    if (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) {
      this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }


    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != '' && this.loginuserdetailsTest != undefined) {
      this.checkUserForm.controls['userCheckEmailId'].setValue('dummy');
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue('dummy');
    }

    //--------------validations----------------//
    if (this.shipmentobj.incidentDate === '' || this.shipmentobj.incidentDate === null) {
      this.toast.error('Please enter incident date');
    } else if (this.shipmentobj.incidentTime === '' || this.shipmentobj.incidentTime === null) {
      this.toast.error('Please enter incident time');
    }
    else if ((this.checkUserForm.controls['userCheckEmailId'].value == null) || (this.checkUserForm.controls['userCheckEmailId'].value === undefined) || (this.checkUserForm.controls['userCheckEmailId'].value === '')) {
      this.toast.error('Please enter email');
    } else if ((this.checkUserForm.controls['userCheckConfirmEmailId'].value === null) || (this.checkUserForm.controls['userCheckConfirmEmailId'].value === undefined) || (this.checkUserForm.controls['userCheckConfirmEmailId'].value === '')) {
      this.toast.error('Please enter confirm email');
    }
    else if (this.shipmentobj.stationCode === '' || this.shipmentobj.stationCode === null) {
      this.toast.error('Please enter your station code');
    } else if (this.selectionStatus == false) {
      this.toast.error('Please enter valid station code');
    } else if (this.shipmentobj.orgName === '' || this.shipmentobj.orgName === null) {
      this.toast.error('Please enter your organization name');
    } else if (this.airwaybillobj.masterAwbNo === '' || this.airwaybillobj.masterAwbNo === null) {
      this.toast.error('Please enter master airway bill');
    } else if (this.masterAwbNo1 === undefined || this.masterAwbNo1 === '' || this.masterAwbNo1 === null) {
      this.toast.error('Please enter master airway bill');
    } else if (this.flight1 === undefined || this.flight1 === '' || this.flight1 === null) {
      this.toast.error('Please enter flight field');
    } else if (this.isFlight_Selected_Frm_List === false) {
      this.toast.error('Please select flight field only from list');
    }
    else if (this.flight2 === undefined || this.flight2 === '' || this.flight2 === null) {
      this.toast.error('Please enter flight field');
    } else if (this.airwaybillobj.flightDate === '' || this.airwaybillobj.flightDate === null) {
      this.toast.error('Please enter flight date');
    } else if (this.airwaybillobj.originAirportId === '' || this.airwaybillobj.originAirportId === null) {
      this.toast.error('Please enter airport origin');
    } else if (this.airwaybillobj.destairportId === '' || this.airwaybillobj.destairportId === null) {
      this.toast.error('Please enter airport destination');
    } else if (this.airwaybillobj.originAirportId === this.airwaybillobj.destairportId) {
      this.toast.error('airport origin and destination should not be same');
    }
     else if (this.only_scratches_tears_dents === null || this.only_scratches_tears_dents === '' || this.only_scratches_tears_dents === undefined) {
      this.toast.error('Please select dents or tears field');
    }
    else if ((this.only_scratches_tears_dents === 'no'  ) && (this.aspectsobj.damagePacking === '' || this.aspectsobj.damagePacking === null)) {
      this.toast.error('Please select damage to packing');
    }else if (((this.aspectsobj.contentAffected === 'possibly') || (this.aspectsobj.contentAffected === 'yes')) && (this.aspectsobj.contentPieces === '')) {

      this.toast.error('Please enter Pieces Affected field');
    }
    else if (this.aspectsobj.contentAffected === '' || this.aspectsobj.contentAffected === null) {
      this.toast.error('Please select content affected');
    }
    else if (((this.aspectsobj.itemMissing === 'possibly') || (this.aspectsobj.itemMissing === 'yes')) && (this.aspectsobj.suspectPilf === '' || this.aspectsobj.suspectPilf === null)) {
      this.toast.error('Please select suspected of pilferage');
    }
    else if (this.aspectsobj.safetyLife === '' || this.aspectsobj.safetyLife === null) {
      this.toast.error('Please select safety involved');
    }
    else if ((this.assessmentobj.damageDiscovered === 'Other') && (this.otherprocessstage === undefined || this.otherprocessstage === '' || this.otherprocessstage === null)) {
      this.toast.error('Please enter other field for process stage');
    } else if ((this.assessmentobj.internalArea === 'Other') && (this.otherprocessarea === undefined || this.otherprocessarea === '' || this.otherprocessarea === null)) {
      this.toast.error('Please enter other field for process area');
    } else if ((this.showothermethodPacking === true) && (this.othermethodpacking === undefined || this.othermethodpacking === '' || this.othermethodpacking === null)) {
      this.toast.error('Please enter other field for method of packing');
    } else if ((this.showotherdamagePacking === true) && (this.otherdamagepacking === undefined || this.otherdamagepacking === '' || this.otherdamagepacking === null)) {
      this.toast.error('Please enter other field for damage to packing');
    } else if ((this.showotherconditioncontent === true) && (this.otherconditioncontent === undefined || this.otherconditioncontent === '' || this.otherconditioncontent === null)) {
      this.toast.error('Please enter other field for condotion of content');
    } else if ((this.showotherrecuperatoinStatus === true) && (this.otherrecuperatoinStatus === undefined || this.otherrecuperatoinStatus === '' || this.otherrecuperatoinStatus === null)) {
      this.toast.error('Please enter other field for recovery actions');
    } else if (this.assessmentobj.discoveresCaused === '' || this.assessmentobj.discoveresCaused === null) {
      this.toast.error('Please select Discovered by or Caused by');
    } else if (this.assessmentobj.internalName === '' || this.assessmentobj.internalName === null) {
      this.toast.error('Please enter name');
    } else if (this.assessmentobj.damageDiscovered === '' || this.assessmentobj.damageDiscovered === null) {
      +      this.toast.error('Please select During which handling stage');
    }
    else if (this.assessmentobj.internalResponsibility === '' || this.assessmentobj.internalResponsibility === null) {
      this.toast.error('Please select Responsibility');
    }

    else {
      if (this.showotherinternalArea === true) {
        this.assessmentobj.internalArea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
      }
      this.shipmentobj.incidentDate = this.datePipe.transform(this.shipmentobj.incidentDate, 'yyyy-MM-dd');
      this.airwaybillobj.flightDate = this.datePipe.transform(this.airwaybillobj.flightDate, 'yyyy-MM-dd');
      if(this.rootCauseObj.evaluationDate){

        this.rootCauseObj.evaluationDate = this.datePipe.transform(this.rootCauseObj.evaluationDate, 'yyyy-MM-dd');
      }

      const dr = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
      this.DummyMawb = this.airwaybillobj.masterAwbNo
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo + '-' + dr;
      localStorage.setItem('masterAwbNolocalstrg', JSON.stringify(this.airwaybillobj.masterAwbNo));
      localStorage.setItem('presentMawb', JSON.stringify(this.airwaybillobj.masterAwbNo));
      this.shipmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.flightobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.assessmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.aspectsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.uploadpicsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;

      this.airwaybillobj.trucking_flight_info = this.flight0 + '-' + this.flight2;

      this.airwaybillobj.flightTruckNo = this.flight1 + '-' + this.flight2;


      this.awb1 = '';
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (i + 1 === this.affectedAwbarray.length) {
          this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        } else {
          this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '') + ',';
        }

      }
      this.aspectsobj.affectedAwb = this.awb1;
      if (this.imageslist !== undefined) {
        this.dd = '';
        for (let i = 0; i < this.imageslist.length; i++) {
          if (i + 1 === this.imageslist.length) {
            this.dd = this.dd + this.imageslist[i];
          } else {
            this.dd = this.dd + this.imageslist[i] + '#..';
          }
        }
        this.uploadpicsobj.damageImage = this.dd;
      }




      for (const property in this.shipmentobj) {
        if (this.shipmentobj[property] === '') {
          this.shipmentobj[property] = null;
        }
      }

      for (const property in this.airwaybillobj) {
        if (this.airwaybillobj[property] === '') {
          this.airwaybillobj[property] = null;
        }
      }

      for (const property in this.flightobj) {
        if (this.flightobj[property] === '') {
          this.flightobj[property] = null;
        }
      }

      for (const property in this.aspectsobj) {
        if (this.aspectsobj[property] === '') {
          this.aspectsobj[property] = null;
        }
      }

      for (const property in this.assessmentobj) {
        if (this.assessmentobj[property] === '') {
          this.assessmentobj[property] = null;
        }
      }

      for (const property in this.uploadpicsobj) {
        if (this.uploadpicsobj[property] === '') {
          this.uploadpicsobj[property] = null;
        }
      }


      //-----------classification type change---------------//
      if (this.aspectsobj.safetyLife === 'yes') {
        this.aspectsobj.classificationType = 'Life Threat - Damage involving safety of life or property Inspection, Decontamination and verification is required.(e.g. chemical, biological, radioactive leakage or spillage)';
      } else if (this.aspectsobj.suspectPilf === 'yes') {
        this.aspectsobj.classificationType = 'Pilferage - Cargo suspected of Pilferage Inspection is Required';
      } else if (this.aspectsobj.contentAffected === 'possibly' || this.aspectsobj.contentAffected === 'yes') {
        if (this.aspectsobj.contentAffected === 'possibly') {
          this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
        } else if (this.aspectsobj.contentAffected === 'yes') {
          this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
        }
      } else {
        if ((this.aspectsobj.safetyLife === 'no') && (this.aspectsobj.suspectPilf === 'no' || this.aspectsobj.suspectPilf === null) && (this.aspectsobj.contentAffected === 'unlikely')) {
          // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
          this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
        }
      }
      //-----------End classification type change---------------//
      const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));

      if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
        this.showotpfield = false;
        this.showverifybutton = true;
        this.verifyEmailForm.reset();
        this.saveonlywithservice('without');
      } else {
        this.saveonlywithservice('with');
      }
    }
  }

  cancelwithoutlogin() {
    localStorage.setItem('prevSelectedStCode', JSON.stringify(this.shipmentobj.stationCode));
    this.verifyEmailForm.reset();

    let mawbVal = JSON.parse(localStorage.getItem('presentMawb'));
    let split = []
    split = mawbVal.split('-');
    this.airwaybillobj.masterAwbNo = null;
    this.airwaybillobj.masterAwbNo = split[0];
    this.masterAwbNo1 = split[1];
  }


  fn_toString(inputData) {
    return inputData.toString();
  }


  saveonlywithservice(val) {
    this.affectedAwbTempArray = [];
    if (this.affectedAwbarray.length > 1) {
      for (const each of this.affectedAwbarray) {
        if (each.awb != '' && each != null && each != undefined) {
          this.affectedAwbTempArray.push(each.awb);
        }
      }
      let toStringVal = this.fn_toString(this.affectedAwbTempArray)
      this.aspectsobj.affectedAwb = toStringVal;
    }
    if (val === 'with') {
      this.damageCategoryItems = [];
      this.shipmentobj.privacyStatus = this.istermsAndCond_chked;
      this.shipmentobj.address = this.selectedAddress;
      if (this.airwaybillobj.masterAwbNo.length < 4 && this.suffixAwbNo != undefined) {
        this.airwaybillobj.masterAwbNo = this.suffixAwbNo + '-' + this.masterAwbNo1;
      }
      this.damageCategoryItems.push(this.shipmentobj);
      this.damageCategoryItems.push(this.airwaybillobj);
      this.damageCategoryItems.push(this.flightobj);
      this.damageCategoryItems.push(this.aspectsobj);
      this.damageCategoryItems.push(this.assessmentobj);
      this.damageCategoryItems.push(this.uploadpicsobj);

      //--------------------service -----------------------------------------//
      
      const finalobj = {
        damageCategoryItems: this.damageCategoryItems,
        prefixCarrierId: this.selectedPrefixId,
        carrierId: this.shipmentobj.carrierId,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      };
      this.spinner.show();

      this.DamageReportServicel.saveDamageReportDataService(finalobj).subscribe(
        data => {

          this.spinner.hide();

          if (data.responseCode === 1) {
            let obj = {
              isFrom: 'CREATE_REPORT'
            }
            if (this.assessmentobj.internalResponsibility === 'external') {

              this.toast.success('Report submitted successfully');
              this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.route.navigate(["/save-report"], { queryParams: obj }));

            } else if (this.assessmentobj.internalResponsibility === 'internal') {
              this.toast.success('Internal Report Has been sent successfully to User');
              this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.route.navigate(["/save-report"], { queryParams: obj }));
            } else {
              this.toast.success('Report submitted successfully');
              this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.route.navigate(["/save-report"], { queryParams: obj }));
            }
          } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
            this.toastr.info("Your session is expired, please login again");
            this.spinner.hide();
          } else {
            let split = []
            let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
            split = duplicateMawb.split('-');
            this.airwaybillobj.masterAwbNo = split[0];
            this.masterAwbNo1 = split[1];
            this.suffixAwbNo = split[0];
            this.spinner.hide();
            this.toast.error(data.saveDataReport.message);
          }
        },
        error => {

          let split = []
          let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
          split = duplicateMawb.split('-');
          this.airwaybillobj.masterAwbNo = split[0];
          this.suffixAwbNo = split[0];
          this.masterAwbNo1 = split[1];
          this.spinner.hide();    
          this.toast.error('Report is not generated due to server issue.');
        });
    } else {

      if (this.checkUserForm.controls['userCheckEmailId'].value === this.checkUserForm.controls['userCheckConfirmEmailId'].value) {
        this.damageCategoryItems = [];

        this.shipmentobj.emailId = this.checkUserForm.controls['userCheckEmailId'].value;
        this.shipmentobj.privacyStatus = this.istermsAndCond_chked;
        this.shipmentobj.address = this.selectedAddress;
        if (this.airwaybillobj.masterAwbNo.length < 4 && this.suffixAwbNo != undefined) {
          this.airwaybillobj.masterAwbNo = this.suffixAwbNo + '-' + this.masterAwbNo1;
        }
        this.damageCategoryItems.push(this.shipmentobj);
        this.damageCategoryItems.push(this.airwaybillobj);
        this.damageCategoryItems.push(this.flightobj);
        this.damageCategoryItems.push(this.aspectsobj);
        this.damageCategoryItems.push(this.assessmentobj);
        this.damageCategoryItems.push(this.uploadpicsobj);
        //--------------------service -----------------------------------------//
        const finalobj = {
          damageCategoryItems: this.damageCategoryItems,
          prefixCarrierId: this.selectedPrefixId,
          carrierId: this.shipmentobj.carrierId,
          createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        };
        this.spinner.show();


        this.DamageReportServicel.saveWebDamageReportService(finalobj).subscribe(
          data => {

            this.spinner.hide();

            if (data.responseCode === 1) {
              this.selectionStatus = false;
              jQuery('#withoutloginpopup').modal('hide');
              this.selectionStatus = false;

              jQuery('body').removeClass('modal-open');
              jQuery('.modal-backdrop').remove();
              let obj = {
                isFrom: 'CREATE_REPORT'
              }
              this.userdetailswithoutlogin = data.user_Id;
              if (this.assessmentobj.internalResponsibility === 'external') {
                this.toast.success('Report submitted successfully');
                this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.route.navigate(["/save-report"], { queryParams: obj }));
              } else if (this.assessmentobj.internalResponsibility === 'internal') {

                this.toast.success('Internal Report Has been sent successfully to User');
                this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.route.navigate(["/save-report"], { queryParams: obj }));


              } else {
                this.toast.success('Report submitted successfully');
                this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.route.navigate(["/save-report"], { queryParams: obj }));

              }

            } else {
              let split = []
              let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
              split = duplicateMawb.split('-');
              this.airwaybillobj.masterAwbNo = split[0];
              this.suffixAwbNo = split[0];
              this.masterAwbNo1 = split[1];
              this.spinner.hide();
              this.toast.error(data.saveDataReport.message);
            }
          },
          error => {

            let split = []
            let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
            split = duplicateMawb.split('-');
            this.airwaybillobj.masterAwbNo = split[0];
            this.suffixAwbNo = split[0];
            this.masterAwbNo1 = split[1];
            this.spinner.hide();
            this.toast.error('Report is not generated due to server issue.');
          });
      } else {
        this.toast.error('Email and confirm email is not matched');
      }

    }
  }

  verifyemailcall() {
    if (this.assessmentobj.internalResponsibility === 'external') {
      this.val = 'E';
    } else {
      this.val = 'I';
    }
    const id = {
      userId: this.verifymail,
      reportStatus: this.val
    }
    this.spinner.show();
    this.DamageReportServicel.emailverifyService(id).subscribe(
      data => {
        this.spinner.hide();
        this.toast.info(data.message);
        if (data.responseCode === 1) {
          if (this.assessmentobj.internalResponsibility === 'external') {
            this.showotpfield = true;
            this.showverifybutton = false;
          } else {
            jQuery('#withoutloginpopup').modal('hide');
            this.selectionStatus = false;
            jQuery('body').removeClass('modal-open');
            jQuery('.modal-backdrop').remove();
            this.saveonlywithservice('without');
          }

        } else {

        }
      },
      error => {
        this.spinner.hide();
      });
  }

  verifyotpcall() {


    this.otp = this.otp.replace(/\s+/g, '');
    const obj = {
      emailId: this.verifymail,
      passcode: this.otp
    }
    this.spinner.show();
    this.DamageReportServicel.verifyOtp(obj).subscribe(
      data => {
        this.spinner.hide();


        this.toast.info(data.message);

        if (data.responseCode === 1) {

          jQuery('#withoutloginpopup').modal('hide');
          this.selectionStatus = false;
          jQuery('body').removeClass('modal-open');
          jQuery('.modal-backdrop').remove();

          this.saveonlywithservice('without');
        } else {

        }
      },
      error => {
        this.spinner.hide();
      });
  }


  sendtoairlinecall() {

    const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
    const masterAwbNolocalstrg = JSON.parse(localStorage.getItem('masterAwbNolocalstrg'));
    if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
      this.userridd = this.userdetailswithoutlogin.user_key;
    } else {
      this.userridd = loginuserdetails.userId;
    }
    const obj = {
      userId: this.userridd,
      awbNo: masterAwbNolocalstrg,
      originAirportName: this.airwaybillobj.originAirportId,
      destAirportName: this.airwaybillobj.destairportId,
      stationCode: this.shipmentobj.stationCode,
      classificationDesc: this.aspectsobj.classificationType,
      trucking_flight_info: this.airwaybillobj.trucking_flight_info,
      reportStatus: 'I'
    };
    this.spinner.show();
    this.DamageReportServicel.sendDamageReportToAirlinesService(obj).subscribe(
      data => {
        this.spinner.hide();
        jQuery('#sampleService').modal('hide');
        jQuery('body').removeClass('modal-open');
        jQuery('.modal-backdrop').remove();


        this.toast.info(data.message);
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.route.navigate(["/save-report"]));
        if (data.responseCode === 1) {

        } else {

        }
      },
      error => {

        this.spinner.hide();
      });

  }

  closenotauthorized() {
    jQuery('#sample1').modal('hide');
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.route.navigate(["/save-report"]));
  }

  closeexwithoutl() {

    jQuery('#externalwol').modal('hide');
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.route.navigate(["/save-report"]));
  }

  closeModalWthOutSelect() {


    const logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
    if (logindetails === null || logindetails === undefined || logindetails === '') {
      this.userridd = this.userdetailswithoutlogin.user_key;
      this.firstnname = this.userdetailswithoutlogin.first_Name;
    } else {
      this.userridd = logindetails.userId;
      this.firstnname = logindetails.firstName;
    }
    this.currentStatusInputObj = {
      userId: '',
      masterAwbNo: '',
      reportStatus: '',
      firstName: '',
      stationCode: '',
      classificationType: ''
    }
    this.currentStatusInputObj.userId = this.userridd;
    this.currentStatusInputObj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.currentStatusInputObj.reportStatus = "C";
    this.currentStatusInputObj.firstName = this.firstnname;
    this.currentStatusInputObj.stationCode = this.shipmentobj.stationCode;
    this.currentStatusInputObj.classificationType = this.aspectsobj.classificationType;
    this.spinner.show();
    this.DamageReportServicel.sendCurrentStatusService(this.currentStatusInputObj).subscribe(data => {
      this.spinner.hide();
      this.toast.info(data.message);
      jQuery('#sampleService').modal('hide');
      jQuery('body').removeClass('modal-open');
      jQuery('.modal-backdrop').remove();
      if (data.responseCode === 1) {
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.route.navigate(["/save-report"]));
      } else {
        let split = []
        let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
        split = duplicateMawb.split('-');
        this.airwaybillobj.masterAwbNo = split[0];
        this.masterAwbNo1 = split[1];
      }

    }, error => {
      let split = []
      let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
      split = duplicateMawb.split('-');
      this.airwaybillobj.masterAwbNo = split[0];
      this.masterAwbNo1 = split[1];
      this.spinner.hide();
    });

  }

  logout() {
    this.navbarComponent.logOut();
  }

  onDismisssPopUp() {
    this.loginForm.reset();
  }


  onOrgEnter(val: any) {
    if (((this.checkUserForm.controls['userCheckEmailId'].value != null) && (this.checkUserForm.controls['userCheckEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckEmailId'].value != '')) && (
      (this.checkUserForm.controls['userCheckConfirmEmailId'].value != null) && (this.checkUserForm.controls['userCheckConfirmEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckConfirmEmailId'].value != '')
    )) {
      if ((this.checkUserForm.controls['userCheckEmailId'].value === this.checkUserForm.controls['userCheckConfirmEmailId'].value)) {

        if (!this.checkUserForm.controls['userCheckEmailId'].valid || !this.checkUserForm.controls['userCheckConfirmEmailId'].valid) {
          this.shipmentobj.orgName = '';
          this.toast.error('Please provide valid emails');
        } else {
          this.shipmentobj.stationCode = '';
          this.showList1 = false;
          this.showListstation = false;
          if (val.target.value != '' && val.target.value != null && val.target.value != undefined) {
            this.isOrgNameFilled = false;
          } else {
            this.isOrgNameFilled = true;
          }
        }

      } else {
        this.shipmentobj.orgName = ''
        this.fn_checkUserForm_IsFilled();
      }
    } else {
      this.shipmentobj.orgName = ''
      this.fn_checkUserForm_IsFilled();
    }

  }

  onDisadbleClick() {
    if (this.shipmentobj.orgName != '' && this.shipmentobj.orgName != null && this.shipmentobj.orgName != undefined) {
      this.isOrgNameFilled = false;
      if (this.isshowListstation === true) {
        this.stationcodeData = JSON.parse(localStorage.getItem('stationcodeData'));
        this.showListstation = true;
      } else {
        this.showListstation = false;
      }
    } else {
      this.isOrgNameFilled = true;
      this.showListstation = false;
      this.toast.error('Please enter organization name');
    }
  }

  onCnclForgotPwdPopup() {
    this.verifyForgotEmailForm.reset();
  }

  fn_To_Check_EmailIdExist(emailId) {
    if ((this.checkUserForm.controls['userCheckEmailId'].value != null) && (this.checkUserForm.controls['userCheckEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckEmailId'].value != '')) {
      this.DamageReportServicel.checkUserServc(emailId).subscribe(data => {

        if (data.responseCode === 1) {
          this.isUserMailExist = true;
          this.userExistedData = data;
          localStorage.setItem("withoutlogin_userID", JSON.stringify(data.userId))
        } else if (data.responseCode === 0 && data.message === 'This user is not registered under the handler company') {
          localStorage.removeItem("withoutlogin_userID");
          this.checkUserForm.controls['userCheckConfirmEmailId'].setValue(null);
          this.checkUserForm.controls['userCheckEmailId'].setValue(null);
          alert(data.message + ', ' + "Please enter another email");
        } else {
          this.isUserMailExist = false;
          localStorage.removeItem("withoutlogin_userID");

          jQuery('#Modal').modal({ backdrop: 'static', keyboard: false, ignoreBackdropClick: true });
        }

      }, error => {
        this.toast.error('Unexpected service error');
      })
    } else {
      this.toast.error('Please enter emailid');
    }
  }

  fn_checkUserForm_IsFilled() {
    if ((this.checkUserForm.controls['userCheckEmailId'].value != null) && (this.checkUserForm.controls['userCheckEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckEmailId'].value != '')) {

      if ((this.checkUserForm.controls['userCheckConfirmEmailId'].value != null) && (this.checkUserForm.controls['userCheckConfirmEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckConfirmEmailId'].value != '')) {

        if (this.checkUserForm.controls['userCheckEmailId'].value === this.checkUserForm.controls['userCheckConfirmEmailId'].value) {

        } else {

          this.toast.error('Email and confirm email not matched');
        }
      } else {
        this.toast.error('Please enter confirm emailId');
      }
    } else {
      this.toast.error('Please enter emailid');
    }
  }

  fn_toDisableOrEnableuserFeilds(event) {
    this.checkUserForm.controls['userCheckConfirmEmailId'].patchValue(this.checkUserForm.controls['userCheckConfirmEmailId'].value.replace(/[^a-zA-Z0-9\.\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\@]*/g, ''));
    if ((this.checkUserForm.controls['userCheckEmailId'].value != null) && (this.checkUserForm.controls['userCheckEmailId'].value != undefined) && (this.checkUserForm.controls['userCheckEmailId'].value != '')) {
      if (this.isUserMailExist === true) {
        if (this.checkUserForm.controls['userCheckEmailId'].value === this.checkUserForm.controls['userCheckConfirmEmailId'].value) {
          this.isUserExist = this.isUserMailExist;
          this.shipmentobj.reportPreparedName = this.userExistedData.reportPreparedName;
          this.shipmentobj.stationCode = this.userExistedData.stationCode;
          this.selectionStatus = true;
          this.shipmentobj.reportPreparedFunction = this.userExistedData.reportPreparedFunction;
          this.shipmentobj.orgName = this.userExistedData.orgName;
          this.selectedAddress = this.userExistedData.address;
        } else {
          this.isUserExist = false;
          this.selectionStatus = false;
          this.shipmentobj.reportPreparedName = '';
          this.shipmentobj.stationCode = '';
          this.shipmentobj.reportPreparedFunction = '';
          this.shipmentobj.orgName = '';
          this.selectedAddress = '';

        }
      }
    } else {
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue(null);
      this.fn_checkUserForm_IsFilled();
    }
  }

  onEmailIdKeyUp(event) {

    if ((event.ctrlKey === true && event.code === "KeyC") || (event.key === "Control")) {

    } else {
      this.checkUserForm.controls['userCheckEmailId'].patchValue(this.checkUserForm.controls['userCheckEmailId'].value.replace(/[^a-zA-Z0-9\.\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\@]*/g, ''));
      this.isUserExist = false;
      this.selectionStatus = false;
      this.shipmentobj.reportPreparedName = '';
      this.shipmentobj.stationCode = '';
      this.shipmentobj.reportPreparedFunction = '';
      this.shipmentobj.orgName = '';
      this.selectedAddress = ''
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue(null);
    }
  }



  update_damageReport_call() { //---- used in before tabs ui presently not using

    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.stationCode != null && this.shipmentobj.stationCode != '' && this.shipmentobj.stationCode != undefined) {
      this.shipmentobj.stationCode = this.shipmentobj.stationCode.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.reportPreparedName != null && this.shipmentobj.reportPreparedName != '' && this.shipmentobj.reportPreparedName != undefined) {
      this.shipmentobj.reportPreparedName = this.shipmentobj.reportPreparedName.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.reportPreparedFunction != null && this.shipmentobj.reportPreparedFunction != '' && this.shipmentobj.reportPreparedFunction != undefined) {
      this.shipmentobj.reportPreparedFunction = this.shipmentobj.reportPreparedFunction.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.masterAwbNo1 != '' && this.masterAwbNo1 != null && this.masterAwbNo1 != undefined) {
      this.masterAwbNo1 = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    }
    if (this.airwaybillobj.masterAwbNo != '' && this.airwaybillobj.masterAwbNo != null && this.airwaybillobj.masterAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '');
    }
    if (this.flight2 != '' && this.flight2 != null && this.flight2 != undefined) {
      this.flight2 = this.flight2.replace(/[^0-9A-Za-z]*/g, '');
    }
    if(this.airwaybillobj.airlineId === undefined || this.airwaybillobj.airlineId === '' || this.airwaybillobj.airlineId === null){
      this.airwaybillobj.airlineId = this.edit_Report_Data.airlineName;
    }
    if (this.airlineId != null && this.airlineId != '' && this.airlineId != undefined) {
      this.airlineId = this.airlineId.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.originAirportId != null && this.originAirportId != '' && this.originAirportId != undefined) {
      this.originAirportId = this.originAirportId.replace(/[^a-zA-Z\-\s]*/g, '');
    }
    if (this.destairportId != null && this.destairportId != '' && this.destairportId != undefined) {
      this.destairportId = this.destairportId.replace(/[^a-zA-Z\-\s]*/g, '')
    }
    if (this.flightobj.mawbTotalpcs != null && this.flightobj.mawbTotalpcs != '' && this.flightobj.mawbTotalpcs != undefined) {
      this.flightobj.mawbTotalpcs = this.flightobj.mawbTotalpcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.flightobj.actualReceivedPcs != null && this.flightobj.actualReceivedPcs != '' && this.flightobj.actualReceivedPcs != undefined) {
      this.flightobj.actualReceivedPcs = this.flightobj.actualReceivedPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.othermethodpacking != null && this.othermethodpacking != '' && this.othermethodpacking != undefined) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.otherdamagepacking != null && this.otherdamagepacking != '' && this.otherdamagepacking != undefined) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.aspectsobj.contentPieces != null && this.aspectsobj.contentPieces != '' && this.aspectsobj.contentPieces != undefined) {
      this.aspectsobj.contentPieces = this.aspectsobj.contentPieces.toString().replace(/[^0-9]*/g, '');
    }
    if (this.aspectsobj.itemWeight != null && this.aspectsobj.itemWeight != '' && this.aspectsobj.itemWeight != undefined) {
      this.aspectsobj.itemWeight = this.aspectsobj.itemWeight.replace(/[^0-9\.]*/g, '');
    }
    if (this.aspectsobj.itemPcs != null && this.aspectsobj.itemPcs != '' && this.aspectsobj.itemPcs != undefined) {
      this.aspectsobj.itemPcs = this.aspectsobj.itemPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.affectedAwbarray.length != 0) {
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (this.affectedAwbarray[i].awb != '' && this.affectedAwbarray[i].awb != null && this.affectedAwbarray[i].awb != undefined) {
          this.affectedAwbarray[i].awb = this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        }
      }
    }
    if (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) {
      this.assessmentobj.internalName = this.assessmentobj.internalName.replace(/[^a-zA-Z\.\s]*/g, '');
    }
    if (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) {
      this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }


    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != '' && this.loginuserdetailsTest != undefined) {
      this.checkUserForm.controls['userCheckEmailId'].setValue('dummy');
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue('dummy');
    }

    //--------------validations----------------//
    if (this.shipmentobj.incidentDate === '' || this.shipmentobj.incidentDate === null) {
      this.toast.error('Please enter incident date');
    } else if (this.shipmentobj.incidentTime === '' || this.shipmentobj.incidentTime === null) {
      this.toast.error('Please enter incident time');
    }
    else if ((this.checkUserForm.controls['userCheckEmailId'].value == null) || (this.checkUserForm.controls['userCheckEmailId'].value === undefined) || (this.checkUserForm.controls['userCheckEmailId'].value === '')) {
      this.toast.error('Please enter email');
    } else if ((this.checkUserForm.controls['userCheckConfirmEmailId'].value === null) || (this.checkUserForm.controls['userCheckConfirmEmailId'].value === undefined) || (this.checkUserForm.controls['userCheckConfirmEmailId'].value === '')) {
      this.toast.error('Please enter confirm email');
    }
    else if (this.shipmentobj.stationCode === '' || this.shipmentobj.stationCode === null) {
      this.toast.error('Please enter your station code');
    } else if (this.selectionStatus == false) {
      this.toast.error('Please enter valid station code');
    } else if (this.shipmentobj.orgName === '' || this.shipmentobj.orgName === null) {
      this.toast.error('Please enter your organization name');
    } else if (this.airwaybillobj.masterAwbNo === '' || this.airwaybillobj.masterAwbNo === null) {
      this.toast.error('Please enter master airway bill');
    } else if (this.masterAwbNo1 === undefined || this.masterAwbNo1 === '' || this.masterAwbNo1 === null) {
      this.toast.error('Please enter master airway bill');
    } else if (this.flight1 === undefined || this.flight1 === '' || this.flight1 === null) {
      this.toast.error('Please enter flight field');
    } else if (this.isFlight_Selected_Frm_List === false) {
      this.toast.error('Please select flight field only from list');
    }
    else if (this.flight2 === undefined || this.flight2 === '' || this.flight2 === null) {
      this.toast.error('Please enter flight field');
    } else if (this.airwaybillobj.flightDate === '' || this.airwaybillobj.flightDate === null) {
      this.toast.error('Please enter flight date');
    } else if (this.airwaybillobj.originAirportId === '' || this.airwaybillobj.originAirportId === null) {
      this.toast.error('Please enter airport origin');
    } else if (this.airwaybillobj.destairportId === '' || this.airwaybillobj.destairportId === null) {
      this.toast.error('Please enter airport destination');
    } else if (this.airwaybillobj.originAirportId === this.airwaybillobj.destairportId) {
      this.toast.error('airport origin and destination should not be same');
    }else if (this.only_scratches_tears_dents === null || this.only_scratches_tears_dents === '' || this.only_scratches_tears_dents === undefined) {
      this.toast.error('Please select dents or tears field');
    }
     else if ((this.only_scratches_tears_dents === 'no') && (this.aspectsobj.damagePacking === '' || this.aspectsobj.damagePacking === null)) {
      this.toast.error('Please select damage to packing');
    }else if (((this.aspectsobj.contentAffected === 'possibly') || (this.aspectsobj.contentAffected === 'yes')) && (this.aspectsobj.contentPieces === '' || this.aspectsobj.contentPieces === null)) {

      this.toast.error('Please enter Pieces Affected field');
    }
    else if (this.aspectsobj.contentAffected === '' || this.aspectsobj.contentAffected === null) {
      this.toast.error('Please select content affected');
    }

    else if (((this.aspectsobj.itemMissing === 'possibly') || (this.aspectsobj.itemMissing === 'yes')) && (this.aspectsobj.suspectPilf === '' || this.aspectsobj.suspectPilf === null)) {
      this.toast.error('Please select suspected of pilferage');
    }
    else if (this.aspectsobj.safetyLife === '' || this.aspectsobj.safetyLife === null) {
      this.toast.error('Please select safety involved');
    }
    else if ((this.assessmentobj.damageDiscovered === 'Other') && (this.otherprocessstage === undefined || this.otherprocessstage === '' || this.otherprocessstage === null)) {
      this.toast.error('Please enter other field for process stage');
    } else if ((this.assessmentobj.internalArea === 'Other') && (this.otherprocessarea === undefined || this.otherprocessarea === '' || this.otherprocessarea === null)) {
      this.toast.error('Please enter other field for process area');
    } else if ((this.showothermethodPacking === true) && (this.othermethodpacking === undefined || this.othermethodpacking === '' || this.othermethodpacking === null)) {
      this.toast.error('Please enter other field for method of packing');
    } else if ((this.showotherdamagePacking === true) && (this.otherdamagepacking === undefined || this.otherdamagepacking === '' || this.otherdamagepacking === null)) {
      this.toast.error('Please enter other field for damage to packing');
    } else if ((this.showotherconditioncontent === true) && (this.otherconditioncontent === undefined || this.otherconditioncontent === '' || this.otherconditioncontent === null)) {
      this.toast.error('Please enter other field for condotion of content');
    } else if ((this.showotherrecuperatoinStatus === true) && (this.otherrecuperatoinStatus === undefined || this.otherrecuperatoinStatus === '' || this.otherrecuperatoinStatus === null)) {
      this.toast.error('Please enter other field for recovery actions');
    } else if (this.assessmentobj.discoveresCaused === '' || this.assessmentobj.discoveresCaused === null) {
      this.toast.error('Please select Discovered by or Caused by');
    } else if (this.assessmentobj.internalName === '' || this.assessmentobj.internalName === null) {
      this.toast.error('Please enter name');
    } else if (this.assessmentobj.damageDiscovered === '' || this.assessmentobj.damageDiscovered === null) {
      this.toast.error('Please select During which handling stage');
    }
    else if (this.assessmentobj.internalResponsibility === '' || this.assessmentobj.internalResponsibility === null) {
      this.toast.error('Please select Responsibility');
    }

    else {
      if (this.showotherinternalArea === true) {
        this.assessmentobj.internalArea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
      }
      this.shipmentobj.incidentDate = this.datePipe.transform(this.shipmentobj.incidentDate, 'yyyy-MM-dd');
      this.airwaybillobj.flightDate = this.datePipe.transform(this.airwaybillobj.flightDate, 'yyyy-MM-dd');
      if(this.rootCauseObj.evaluationDate){

        this.rootCauseObj.evaluationDate = this.datePipe.transform(this.rootCauseObj.evaluationDate, 'yyyy-MM-dd');
      }
      const dr = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
      this.DummyMawb = this.airwaybillobj.masterAwbNo
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo + '-' + dr;
      localStorage.setItem('masterAwbNolocalstrg', JSON.stringify(this.airwaybillobj.masterAwbNo));
      localStorage.setItem('presentMawb', JSON.stringify(this.airwaybillobj.masterAwbNo));
      this.shipmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.flightobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.assessmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.aspectsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
      this.uploadpicsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;

      this.airwaybillobj.trucking_flight_info = this.flight0 + '-' + this.flight2;

      this.airwaybillobj.flightTruckNo = this.flight1 + '-' + this.flight2;


      this.awb1 = '';
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (i + 1 === this.affectedAwbarray.length) {
          this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        } else {
          this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '') + ',';
        }
      }
      this.aspectsobj.affectedAwb = this.awb1;

      if (this.imageslist !== undefined) {
        this.dd = '';
        for (let i = 0; i < this.imageslist.length; i++) {
          if (i + 1 === this.imageslist.length) {
            this.dd = this.dd + this.imageslist[i];
          } else {
            this.dd = this.dd + this.imageslist[i] + '#..';
          }
        }

        this.uploadpicsobj.damageImage = this.dd;

      }

      if (this.edit_Report_Data.damageImage != null && this.edit_Report_Data.damageImage != undefined && this.edit_Report_Data.damageImage != '') {
        if (this.damage_Report_Images !== null && this.damage_Report_Images !== undefined) {
          if (this.damage_Report_Images.length != 0) {
            this.uploadpicsobj.oldDamageImages = this.damage_Report_Images.toString();
          }
        }

      }



      for (const property in this.shipmentobj) {
        if (this.shipmentobj[property] === null) {
          this.shipmentobj[property] = '';
        }
      }

      for (const property in this.airwaybillobj) {
        if (this.airwaybillobj[property] === null) {
          this.airwaybillobj[property] = '';
        }
      }

      for (const property in this.flightobj) {
        if (this.flightobj[property] === '') {
          this.flightobj[property] = null;
        }
      }

      for (const property in this.aspectsobj) {
        if (this.aspectsobj[property] === '') {
          this.aspectsobj[property] = null;
        }
      }


      for (const property in this.assessmentobj) {
        if (this.assessmentobj[property] === null) {
          this.assessmentobj[property] = '';
        }
      }

      for (const property in this.uploadpicsobj) {
        if (this.uploadpicsobj[property] === null) {
          this.uploadpicsobj[property] = '';
        }
      }


      //-----------classification type change---------------//
      if (this.aspectsobj.safetyLife === 'yes') {
        this.aspectsobj.classificationType = 'Life Threat - Damage involving safety of life or property Inspection, Decontamination and verification is required.(e.g. chemical, biological, radioactive leakage or spillage)';
      } else if (this.aspectsobj.suspectPilf === 'yes') {
        this.aspectsobj.classificationType = 'Pilferage - Cargo suspected of Pilferage Inspection is Required';
      } else if (this.aspectsobj.contentAffected === 'possibly' || this.aspectsobj.contentAffected === 'yes') {
        if (this.aspectsobj.contentAffected === 'possibly') {
          this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
        } else if (this.aspectsobj.contentAffected === 'yes') {
          this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
        }
      } else {
        if ((this.aspectsobj.safetyLife === 'no') && (this.aspectsobj.suspectPilf === 'no' || this.aspectsobj.suspectPilf === null) && (this.aspectsobj.contentAffected === 'unlikely')) {
          this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
        }
      }
      //-----------End classification type change---------------//

      this.updateService_call();

    }
  }

  updateService_call() { //---- used in before tabs ui presently not using


    this.damageCategoryItems = [];
    this.shipmentobj.privacyStatus = this.istermsAndCond_chked;
    this.shipmentobj.address = this.selectedAddress;
    if (this.airwaybillobj.masterAwbNo.length < 4 && this.suffixAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.suffixAwbNo + '-' + this.masterAwbNo1;
    }

    this.airwaybillobj.destairportId = this.airwaybillobj.destairportId
    this.airwaybillobj.originAirportId = this.airwaybillobj.originAirportId
    this.damageCategoryItems.push(this.shipmentobj);
    this.damageCategoryItems.push(this.airwaybillobj);
    this.damageCategoryItems.push(this.flightobj);
    this.damageCategoryItems.push(this.aspectsobj);
    this.damageCategoryItems.push(this.assessmentobj);
    this.damageCategoryItems.push(this.uploadpicsobj);

    //--------------------service -----------------------------------------//
    const finalobj = {
      damageCategoryItems: this.damageCategoryItems,
      prefixCarrierId: this.selectedPrefixId,
      carrierId: this.shipmentobj.carrierId,
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    };
    this.spinner.show();

    this.DamageReportServicel.update_DamageReport_Service(finalobj).subscribe(
      data => {



        if (data.responseCode === 1) {
          this.toast.success('Report submitted successfully');
          let rowData = {
            isFrom: 'EDIT_REPORT_SUCCESS'
          }
          if (this.loginuserdetailsTest.userType === 'S') {


            if (this.from_csaDashBoard != undefined) {
              if (this.from_csaDashBoard === 'true') {
                this.route.navigateByUrl("/csa-dashboard");
              }
            } else if (this.from_view_Users != undefined) {
              if (this.from_view_Users === 'true') {
                let obj = {
                  is_From_Edit_DamageReport: true
                }
                this.route.navigate(["/viewusers"], { queryParams: obj });
              }
            } else {
              this.location.back();
            }
          } else if (this.loginuserdetailsTest.userType === 'E') {


            if (this.from_userDashboard != undefined) {
              if (this.from_userDashboard === 'true') {
                this.route.navigateByUrl("/user-dashboard");
              }
            } else if (this.from_view_Users != undefined) {
              if (this.from_view_Users === 'true') {
                this.route.navigateByUrl("/viewusers");
              }
            } else {
              // this.location.back(); //changed
              this.route.navigateByUrl("/user-dashboard");

            }

          } else if (this.loginuserdetailsTest.userType === 'SA') {
            if (this.is_From_sideReports != undefined && this.is_From_sideReports != null && this.is_From_sideReports != '') {
              if (this.is_From_sideReports === "true") {

                this.route.navigate(["/viewreports"]);
                this.spinner.hide();
              } else {

                this.route.navigateByUrl("/sa-dashboard");
                this.spinner.hide();
              }
            } else {
              let obj = {
                is_From_Edit_DamageReport: true
              }
              this.spinner.hide();
              this.route.navigate(["/viewusers"], { queryParams: obj });
            }

          } else if (this.loginuserdetailsTest.userType === 'A') {
            this.ViewReportsData.isFromDashboard = 'yes';
            if (this.loginuserdetailsTest.orgType === 'A') {
              this.route.navigate(['admin-airline-dashboard'], {

                queryParams: {
                  DashBorad: 'switched'
                },
                queryParamsHandling: 'merge',
              });
            } else {
              this.route.navigate(['/admin-handler-dashboard'], {
                queryParams: {
                  DashBorad: 'switched'
                },
                queryParamsHandling: 'merge',
              });
            }
          }
          this.spinner.hide();

        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          this.spinner.hide();
        }
        else {
          let split = []
          let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
          split = duplicateMawb.split('-');
          this.airwaybillobj.masterAwbNo = split[0];
          this.masterAwbNo1 = split[1];
          this.suffixAwbNo = split[0];
          this.spinner.hide();
          this.toast.error(data.editDamageReport.message);
        }
      },
      error => {

        let split = []
        let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
        split = duplicateMawb.split('-');
        this.airwaybillobj.masterAwbNo = split[0];
        this.suffixAwbNo = split[0];
        this.masterAwbNo1 = split[1];
        this.spinner.hide();
        this.toast.error('Report is not generated due to server issue.');
      });
  }

  //------register------//
  stationFormcontrolError(control: FormControl): { [s: string]: boolean } {
    if (this.stationCodeFormcontrolError === true) {
      return { 'stationCodeFormcontrolError': true };
    }
    return null;
  }


  onclickRegister() {

    this.OrgName_selectedFrm_List = false;
    this.Multile_Status_HandlerOrgsArray = [];
    this.Multile_Status_AirlineOrgsArray = [];
    this.Multile_Status_AgentOrgsArray = [];
    this.OrgNamesValue = [];
    this.registrationEmailForm.reset();
    this.registerForm.reset();
    this.registrationOtpForm.reset();
    this.getAllStations();
    jQuery('#Modal').modal('hide');
    jQuery('#register').modal({ backdrop: 'static', keyboard: false });

  }


  // Email Submit,Get Otp Api
  gotToOtp(registrationEmailForm) {

    this.spinner.show();
    this.RegisterService.sendRegisterationOtp(registrationEmailForm.controls['emailId'].value).subscribe(data => {

      switch (data.responseCode) {
        case 1:

          this.spinner.hide();
          this.toast.success(data.message, '', { timeOut: 1500 });
          this.registerForm.reset();
          this.OrgNameReadonly = false;
          this.stationCodeReadOnly = false;
          this.AddressRedonly = false;

          this.otppPage = true;
          this.emailPage = false;
          this.registratonPage = false;

        case 0:

          this.spinner.hide();
          this.toast.error(data.message, '', { timeOut: 1500 })
      }
    },
      error => {
        this.spinner.hide();
        this.toast.error(error, '', { timeOut: 1400 })
      });

  }



  gotToRegister(registrationOtpForm) {

    this.fieldnameport_Code = true;
    this.organizationInputChange = false;
    this.stationCodeReadOnly = false;
    let obj = {
      "emailId": this.registrationEmailForm.controls['emailId'].value,
      "otpCode": registrationOtpForm.controls['otpCode'].value.replace(/[^0-9]*/g, '')
    }


    this.spinner.show();
    this.superAdminservice.airlineData().subscribe(data => {

      if (data.responseCode === 1) {
        this.airlinceComapniesData = data.airline_details;
        this.spinner.hide();
      } else {
        this.airlinceComapniesData = [];
        this.spinner.hide();
      }
    });
    this.getAllStations();
    this.RegisterService.checkEmail(obj).subscribe(data => {

      this.spinner.hide();
      switch (data.responseCode) {
        case 1:
          if (data.reason === 'single') {

            this.OrgNameReadonly = true;
            this.reasonType = data.reason;
            if (data.orgType === 'Handler') {
              this.org_TypeTemp = 'H';
            } else if (data.orgType === 'Airline') {
              this.org_TypeTemp = 'A';
            } else if (data.orgType === 'Agent') {
              this.org_TypeTemp = 'E';
            } else {
              return
            }
            this.orgName_Temp = data.OrganisationName;
            this.OrgNameValue = data.OrganisationName;
            this.singleOrgStations = data.stations;

            this.registerForm.controls['orgType'].setValue(this.org_TypeTemp);
            this.registerForm.controls['organisationName'].setValue(data.OrganisationName)
            this.registerForm.controls['organisationName'].disable();
            this.registerForm.controls['orgType'].disable();
            this.OrgName_selectedFrm_List = true;
            this.singleOrgName = data.OrganisationName;
            this.spinner.hide();
            this.OrgNameFiledChange = false;
            this.otppPage = false;
            this.emailPage = false;
            this.registratonPage = true;

          } else if (data.reason === 'multiple') {

            this.reasonType = data.reason;
            this.OrgNameFiledChange = false;
            this.otppPage = false;
            this.emailPage = false;
            this.registratonPage = true;
            this.spinner.hide();
            this.OrgNamesValueTemp = data.orgDetails


            for (let i = 0; i < data.orgDetails.length; i++) {
              if (data.orgDetails[i].org_Type === 'E') {
                this.Multile_Status_AgentOrgsArray.push(data.orgDetails[i]);
              } else if (data.orgDetails[i].org_Type === 'A') {
                this.Multile_Status_AirlineOrgsArray.push(data.orgDetails[i]);
              } else if (data.orgDetails[i].org_Type === 'H') {
                this.Multile_Status_HandlerOrgsArray.push(data.orgDetails[i]);
              }
            }

          }

          break;

        case 0:

          if (data.reason === 'wrong type' && data.toaster === 'Y') {
            this.toast.error(data.message, '', { timeOut: 1500 });
          } else if (data.reason === 'not found' && data.toaster === 'N') {

            this.getAllStations();
            this.organizationInputChange = true;
            this.reasonType = data.reason;
            this.OrgNameFiledChange = false;
            this.OrgNamesValue = [];
            this.otppPage = false;
            this.emailPage = false;
            this.registratonPage = true;
            this.AddressRedonly = false;
          } else {

            if (data.message === "wrong credentials. ") {
              this.spinner.hide();
              this.toast.error("Wrong security code", '', { timeOut: 1500 });
            } else {
              this.spinner.hide();
              this.toast.error(data.message, '', { timeOut: 1500 });
            }

          }
          break;
      }
    },
      error => {

        this.spinner.hide();
        this.toast.error(error, '', { timeOut: 1500 })
      });
  }


  checkUndefinedFields(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.checkUndefinedFields(abstractControl);
      } else {
        if (abstractControl.value === undefined || abstractControl.value === null) {
          abstractControl.patchValue("");
        }
      }
    });
  }

  //Final registration Form submiting Api
  Register(registerForm) {
    this.checkUndefinedFields(this.registerForm);
    let obj = {
      "emailId": this.registrationEmailForm.controls['emailId'].value,
      "organisationName": this.registerForm.controls['organisationName'].value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''),
      "orgType": this.registerForm.controls['orgType'].value,
      "firstName": this.registerForm.controls['firstName'].value.replace(/[^a-zA-Z\s]*/g, ''),
      "lastName": this.registerForm.controls['lastName'].value.replace(/[^a-zA-Z\s]*/g, ''),
      "stationCode": this.registerForm.controls['stationCode'].value.replace(/[^a-zA-Z\-\s]*/g, ''),
      "cpassword": this.registerForm.controls['cpassword'].value,
      "address": this.registerForm.controls['address'].value,
      "jobTitle": this.registerForm.controls['jobTitle'].value,
      "address2": this.registerForm.controls['address2'].value,
      "city": this.registerForm.controls['city'].value.replace(/[^a-zA-Z]*/g, ''),
      "country": this.registerForm.controls['country'].value.replace(/[^a-zA-Z]*/g, ''),
      "postalCode": this.registerForm.controls['postalCode'].value,
      "carrierId": this.registerForm.controls['carrierId'].value,
    }
    this.spinner.show();
    this.RegisterService.registration(obj).subscribe(data => {

      if (data.responseCode === 1) {

        this.Multile_Status_HandlerOrgsArray = [];
        this.Multile_Status_AirlineOrgsArray = [];
        this.Multile_Status_AgentOrgsArray = [];
        this.spinner.hide();
        this.toast.success("Registration is completed successfully, Please login", '', { timeOut: 2000 });
        this.emailPage = false;
        this.otppPage = false;
        this.registratonPage = false;
        this.codeValue = ''
        this.OrgNameValue = ''

        jQuery('#register').modal('hide');
        this.fn_To_Check_EmailIdExist(this.checkUserForm.controls['userCheckEmailId'].value)

      } else {

        this.Multile_Status_HandlerOrgsArray = [];
        this.Multile_Status_AirlineOrgsArray = [];
        this.Multile_Status_AgentOrgsArray = [];
        this.spinner.hide();
        this.toast.error(data.message, '', { timeOut: 1500 });
        jQuery('#register').modal('hide');
      }
    }, error => {

      this.Multile_Status_HandlerOrgsArray = [];
      this.Multile_Status_AirlineOrgsArray = [];
      this.Multile_Status_AgentOrgsArray = [];
      this.spinner.hide();
      this.toast.error(error.statusText, '', { timeOut: 1500 });
      jQuery('#register').modal('hide');
    }
    );

  }

  //OrgName code change Event
  public OrgNameChange(e): void {

    let name = e.target.value;
    this.OrgNameValueTemp = this.airlinceComapniesData.filter((x) => x.carrierName.startsWith(name))[0];
  }

  initializeSationCodesData() {
    this.codeList = this.TEMPORARY_CODELIST;
  }

  fn_To_Filter_SatationCodes(ev: any) {
    // Reset items back to all of the items
    this.initializeSationCodesData();
    // set val to the value of the searchbar
    const val = ev.target.value;
    this.is_StationCode_SelectFrmList = false;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {

      this.codeList = this.codeList.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase()));

      if (!this.codeList) {
        this.showStationsDropDown = false;
      } else {
        this.showStationsDropDown = true;
      }
    } else {
      // hide the results when the query is empty
      this.showStationsDropDown = false;
    }
  }

  // stationCode rest
  stationDropdown() {

    this.showStationsDropDown = true;
  }

  stationDropdownHide() {
    this.showStationsDropDown = false;
    this.is_StationCode_Selected_Frm_List();
  }


  //Station code change Event
  public saveCode(e, c, i): void {

    this.codeValue = c.code
    this.is_StationCode_SelectFrmList = true;
    if (this.reasonType === 'multiple') {
      if (this.allStationsActive === true) {
        this.ResultStations = this.codeList;
        this.codeValue = c.code
        this.showStationsDropDown = false;
      } else {
        this.ResultStations = this.codeList;
        this.codeValue = c.code
        this.showStationsDropDown = false;
      }
      if (this.ResultStations === undefined) {
        this.stationCodeFormcontrolError = true;
        this.codeValue = '';
        this.registerForm.controls['stationCode'].setValue('');
        this.registerForm.controls['stationCode'].updateValueAndValidity();

      } else {
        this.stationCodeFormcontrolError = false;
        this.registerForm.controls['stationCode'].setErrors(null);

      }
      if (this.allStationsActive === true) {
        this.AddressRedonly = false;
        this.stationslist = c.code;
        if (c === undefined) {
          this.stationsCodeReset();
        }
      } else {
        this.stationslist = c.code;
        if (this.stationslist === undefined) {
          this.stationsCodeReset();
        }
      }

      if (this.stationslist !== undefined && this.stationslist !== null) {
        this.AddressRedonly = false;
        this.address2ReadOnly = false;
        this.cityReadOnly = false;
        this.countryReadOnly = false;
        this.postalCodeReadOnly = false;
        this.registerForm.controls['address'].setValue(c.main_Address);
        this.registerForm.controls['address2'].setValue(c.address2);
        this.registerForm.controls['city'].setValue(c.city);
        this.registerForm.controls['country'].setValue(c.country);
        this.registerForm.controls['postalCode'].setValue(c.postal_code);

        this.AddressRedonly = true;
        this.address2ReadOnly = true;
        this.cityReadOnly = true;
        this.countryReadOnly = true;
        this.postalCodeReadOnly = true;


      }
    } else if (this.reasonType === 'not found') {

      this.AddressRedonly = false;
      if (this.allStationsActive === true) {
        this.ResultStations = this.codeList;
        this.codeValue = c.code;
        this.showStationsDropDown = false;
      } else {
        this.ResultStations = this.codeList;
        this.codeValue = c.code;

        this.showStationsDropDown = false;
      }

    } else if (this.reasonType === 'single') {

      if (this.registerForm.controls['orgType'].value === "A") {
        this.selectedStation = this.codeList

      } else {
        this.selectedStation = this.codeList

      }

      if (this.allStationsActive === true) {
        this.ResultStations = this.codeList;
        this.codeValue = c.code;
        this.showStationsDropDown = false;
      } else {
        this.ResultStations = this.codeList;
        this.codeValue = c.code;

        this.showStationsDropDown = false;
      }
      this.spinner.hide();
      if (this.selectedStation !== undefined && this.selectedStation !== null) {

        this.AddressRedonly = false;
        this.address2ReadOnly = false;
        this.cityReadOnly = false;
        this.countryReadOnly = false;
        this.postalCodeReadOnly = false;
        this.registerForm.controls['address'].setValue(c.main_Address);
        this.registerForm.controls['address2'].setValue(c.address2);
        this.registerForm.controls['city'].setValue(c.city);
        this.registerForm.controls['country'].setValue(c.country);
        this.registerForm.controls['postalCode'].setValue(c.postal_code);
        this.AddressRedonly = true;
        this.address2ReadOnly = true;
        this.cityReadOnly = true;
        this.countryReadOnly = true;
        this.postalCodeReadOnly = true;

      } else if (this.selectedStation === undefined) {
        this.registerForm.controls['stationCode'].patchValue('');
        this.registerForm.controls['stationCode'].updateValueAndValidity();
        this.registerForm.controls['address'].patchValue('');
        this.registerForm.controls['address'].updateValueAndValidity();
        this.registerForm.controls['address2'].patchValue('');
        this.registerForm.controls['address2'].updateValueAndValidity();
        this.registerForm.controls['city'].patchValue('');
        this.registerForm.controls['city'].updateValueAndValidity();
        this.registerForm.controls['country'].patchValue('');
        this.registerForm.controls['country'].updateValueAndValidity();
        this.registerForm.controls['postalCode'].patchValue('');
        this.registerForm.controls['postalCode'].updateValueAndValidity();
      }
    }

  }


  is_StationCode_Selected_Frm_List() {

    let val = this.registerForm.get('stationCode').value
    if (this.is_StationCode_SelectFrmList === true) {
      this.stationCode_Not_Frm_list_err = false;
    } else {
      this.stationCode_Not_Frm_list_err = true;
    }
  }


  airlineNameCheck(name) {

    let list = this.airlinceComapniesData.filter(x => x.carrierName === name)[0];
    if (list === undefined) {

      this.orgNameFormcontrolError = true;
      this.OrgNameValue = '';
      this.registerForm.controls['organisationName'].setValue('');
      this.registerForm.controls['organisationName'].updateValueAndValidity();
    } else if (list !== undefined) {
      this.orgNameFormcontrolError = false;
      this.registerForm.controls['organisationName'].setErrors(null);
      this.CheckEmailService();
    }
  }

  ListEnableFun() {
    this.initializes_OrgNames_Data()
    this.isListEnable = true;
  }
  ListDisableFun() {

    this.isListEnable = false;
    let value = this.registerForm.controls['orgType'].value
    let orgValue = this.registerForm.controls['organisationName'].value
    if (value === 'E') {
      if (this.Multile_Status_AgentOrgsArray.length != 0) {
        if (this.OrgName_selectedFrm_List === true) {
          this.orgNameFormcontrolError = false;
          if (orgValue != '' && orgValue != null && orgValue != undefined) {
            this.CheckEmailService();
          }
        } else {
          this.orgNameFormcontrolError = true;
        }
      } else {
        this.OrgName_selectedFrm_List = true;
        this.orgNameFormcontrolError = false;
        if (orgValue != '' && orgValue != null && orgValue != undefined) {
          this.CheckEmailService();
        }
      }
    } else if (value === 'A') {
      if (this.OrgName_selectedFrm_List === true) {
        this.orgNameFormcontrolError = false;
        if (orgValue != '' && orgValue != null && orgValue != undefined) {
          this.CheckEmailService();
        }
      } else {
        this.orgNameFormcontrolError = true;
      }
    } else if (value === 'H') {
      if (this.Multile_Status_HandlerOrgsArray.length != 0) {
        if (this.OrgName_selectedFrm_List === true) {
          this.orgNameFormcontrolError = false;
          if (orgValue != '' && orgValue != null && orgValue != undefined) {
            this.CheckEmailService();
          }
        } else {
          this.orgNameFormcontrolError = true;
        }
      } else {
        this.OrgName_selectedFrm_List = true;
        this.orgNameFormcontrolError = false;
        if (orgValue != '' && orgValue != null && orgValue != undefined) {
          this.CheckEmailService();
        }
      }
    }

  }
  selectedOrg(c) {
     
    let val1 = this.registerForm.controls['orgType'].value
    this.OrgName_selectedFrm_List = true;
    if (val1 === 'A') {
      if (this.Multile_Status_AirlineOrgsArray.length != 0) {
        this.registerForm.get('organisationName').patchValue(c.orgName);
      } else {
        this.registerForm.get('organisationName').patchValue(c.carrierName);
        this.registerForm.controls['carrierId'].patchValue(c.carrierId);
      }
    } else {
      this.registerForm.get('organisationName').patchValue(c.orgName);
    }
    this.isListEnable = false;
    let obj = {
      target: {
        value: this.registerForm.get('organisationName').value
      }
    }



  }

  initializes_OrgNames_Data() {
    this.OrgNamesValue = this.OrgNamesValueDuplicates;
  }

  fn_toFilter_OrgNames(ev: any) {
    // Reset items back to all of the items
    this.initializes_OrgNames_Data();
    // set val to the value of the searchbar
    this.OrgName_selectedFrm_List = false;
    const name = ev.target.value;
    let orgNameVal = this.registerForm.controls['organisationName'].value
    if (orgNameVal != undefined && orgNameVal != null && orgNameVal != '') {
      this.registerForm.controls['organisationName'].setValue(orgNameVal.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
    }
    this.orgNameFormcontrolError = false;

    // if the value is an empty string don't filter the items
    let value = this.registerForm.controls['orgType'].value
    if (value === 'A') {
      if (name && name.trim() !== '') {

        if (this.Multile_Status_AirlineOrgsArray.length != 0) {
          this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
            item.orgName.toLowerCase().startsWith(name.toLowerCase()));
        } else {
          this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
            item.carrierName.toLowerCase().startsWith(name.toLowerCase()));
        }
      }
    } else {
      if (name && name.trim() !== '') {

        this.OrgNamesValue = this.OrgNamesValue.filter((item) =>
          item.orgName.toLowerCase().startsWith(name.toLowerCase()));
      }
      if (value === 'E') {
        if (this.Multile_Status_AgentOrgsArray.length != 0) {
        } else {
          this.OrgName_selectedFrm_List = true;
        }
      } else if (value === 'H') {
        if (this.Multile_Status_HandlerOrgsArray.length != 0) {
        } else {
          this.OrgName_selectedFrm_List = true;
        }
      }
    }
  }
  // method: Organization Name onchange
  public saveOrgName(e, c) {


    let name = e.target.value;
    if (this.registerForm.controls['orgType'].value === 'A') {
      let AirlineOrg = this.airlinceComapniesData.filter(x => x.carrierName === name)[0];
      if (AirlineOrg != undefined) {
        this.registerForm.controls['carrierId'].patchValue(AirlineOrg.carrierId);
      }

    } else {
      this.registerForm.controls['carrierId'].patchValue("");
    }

    this.codeValue = '';
    this.registerForm.controls['stationCode'].setValue('');
    this.registerForm.controls['stationCode'].updateValueAndValidity();
    if (this.registerForm.controls['orgType'].value !== '' && this.registerForm.controls['orgType'].value !== undefined && this.registerForm.controls['orgType'].value !== null) {

      switch (this.reasonType) {
        case undefined:
          return;
          break;
        case 'not found':
          let name = e.target.value;
          this.airlineNameCheck(name);

          break;
        case 'single':
          if (this.registerForm.controls['orgType'].value === 'A') {
            this.CheckEmailService();
          }
          break;
        case 'multiple':
          if (this.registerForm.controls['orgType'].value === 'A') {

            let name = e.target.value;
            this.airlineNameCheck(name);
          } else if (this.registerForm.controls['orgType'].value === 'H' || this.registerForm.controls['orgType'].value === 'E') {

            let name = e.target.value;

            let list = this.OrgNamesValue.filter(x => x.orgName === name)[0];

            if (list === undefined) {
              this.orgNameFormcontrolError = true;
              this.OrgNameValue = '';
              this.registerForm.controls['organisationName'].setValue('');
              this.registerForm.controls['organisationName'].updateValueAndValidity();
            } else if (list !== undefined) {
              this.orgNameFormcontrolError = false;
              this.registerForm.controls['organisationName'].setErrors(null);
              this.CheckEmailService();
            }
          } else {
            this.CheckEmailService();
          }

          break;
      }

    } else {
      this.toast.error('Selecte organization type');
      this.registerForm.controls['orgType'].setErrors({ 'incorrect': true });
      this.registerForm.controls['orgType'].updateValueAndValidity();
    }


  }

  CheckEmailService() {

    let obj = {
      "orgName": this.registerForm.controls['organisationName'].value,
      "orgType": this.registerForm.controls['orgType'].value,
    }
    this.spinner.show();
    this.RegisterService.companyTypecheck(obj).subscribe(data => {

      switch (data.responsecode) {
        case 0:
          this.spinner.hide();
          switch (data.toaster) {
            case 'N':

              this.spinner.show()
              this.getAllStations();
              break;
            case 'Y':

              this.spinner.hide();
              this.toast.error(data.message, '', { timeOut: 1500 });
              break;
          }
          break;
        case 1:

          this.spinner.hide();
          this.allStationsActive = false;
          this.registerForm.controls['stationCode'].reset();
          this.fieldnameport_Code = true;
          this.codeList = this.allStationsDetails;
          break;
      }
    }, error => {
      this.spinner.hide();
      this.toast.error(error.message, '', { timeOut: 1500 })

    });
  }
  // method: Get Organization Type
  OrgType(org_Type) {

    if (org_Type === 'A') {
      var OrgType = 'Airline'
    } else if (org_Type === 'H') {
      var OrgType = 'Handler'
    } else {
      var OrgType = 'Agent'
    }
    return OrgType;
  }

  getAllStations() {

    this.StationService.GetStationCodes().subscribe(
      data => {

        this.spinner.hide();
        if (data.responseCode === 1) {
          this.fieldnameport_Code = false;
          this.allStationsActive = true;
          this.codeList = data.airport_details;
          this.codeListTemp = data.airport_details;
          this.allStationsDetails = data.airport_details;
          this.TEMPORARY_CODELIST = data.airport_details;
          this.spinner.hide();

        } else {
          this.spinner.hide();
          this.toast.error(data.message, '', { timeOut: 1500 });
        }
      },
      error => {
        this.spinner.hide();
        this.toast.error(error.message, '', { timeOut: 1500 })
        this.spinner.hide();
      }
    )
  }


  keyupMethod(event) {


  }


  orgTypeSelected() {

    switch (this.reasonType) {
      case 'multiple':
        if (this.orgType_temp !== undefined && this.orgType_temp !== null) {
          if (this.registerForm.controls['orgType'].value === this.orgType_temp) {
            return;
          } else {
            this.toast.error('Selected organization belongs to ' + this.orgType_temp, '', { timeOut: 1500 });

          }
        }


    }

  }


  oranizationReset() {
    this.registerForm.controls['organisationName'].setValue('');

  }

  RegisterFormRest() {
    this.registerForm.controls['firstName'].setValue('');
    this.registerForm.controls['lastName'].setValue('');
    this.registerForm.controls['address'].setValue('');
    this.registerForm.controls['password'].setValue('');
    this.registerForm.controls['cpassword'].setValue('');
    this.registerForm.controls['jobTitle'].setValue('');

    this.registerForm.controls['address2'].setValue('');
    this.registerForm.controls['city'].setValue('');
    this.registerForm.controls['country'].setValue('');
    this.registerForm.controls['postalCode'].setValue('');

  }

  stationsCodeReset() {
    this.registerForm.controls['stationCode'].setValue('');
  }

  m_orgType(e) {
    this.oranizationReset();
    this.stationsCodeReset();
    this.RegisterFormRest();
    this.isListEnable = false;

    let value = e.target.value;
    this.OrgNamesValueDuplicates = [];
    if (value === 'E') {
      if (this.Multile_Status_AgentOrgsArray.length != 0) {
        this.OrgNamesValue = this.Multile_Status_AgentOrgsArray;
        this.organizationInputChange = false
        this.OrgNameFiledChange = false;
        this.registerForm.get('organisationName').enable()
        if (this.OrgNamesValue.length === 1) {
          this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
          this.registerForm.get('organisationName').disable();
          this.OrgName_selectedFrm_List = true;
          this.orgNameFormcontrolError = false;
        }
      } else {
        this.OrgName_selectedFrm_List = true;
        this.OrgNamesValue = [];
        this.registerForm.get('organisationName').enable()
      }
    } else if (value === 'A') {
      if (this.Multile_Status_AirlineOrgsArray.length != 0) {
        this.OrgNamesValue = this.Multile_Status_AirlineOrgsArray;
        this.organizationInputChange = false
        this.OrgNameFiledChange = false;
        this.registerForm.get('organisationName').enable()
        if (this.OrgNamesValue.length === 1) {
          this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
          this.registerForm.get('organisationName').disable();
          this.OrgName_selectedFrm_List = true;
          this.orgNameFormcontrolError = false;
        }
      } else {
        this.OrgNamesValue = this.airlinceComapniesData;
        this.OrgNameFiledChange = true;
        this.registerForm.get('organisationName').enable()
      }
    } else if (value === 'H') {
      if (this.Multile_Status_HandlerOrgsArray.length != 0) {
        this.OrgNamesValue = this.Multile_Status_HandlerOrgsArray;
        this.organizationInputChange = false;
        this.OrgNameFiledChange = false;
        this.registerForm.get('organisationName').enable()
        if (this.OrgNamesValue.length === 1) {
          this.registerForm.get('organisationName').patchValue(this.OrgNamesValue[0].orgName);
          this.registerForm.get('organisationName').disable();
          this.OrgName_selectedFrm_List = true;
          this.orgNameFormcontrolError = false;
        }
      } else {
        this.OrgNamesValue = [];
        this.OrgName_selectedFrm_List = true;
        this.registerForm.get('organisationName').enable()
      }
    }
    this.OrgNamesValueDuplicates = this.OrgNamesValue;
  }

  onDismisssregisterPopUp() {

    this.registerForm.reset();
    this.registrationOtpForm.reset();
    this.registrationEmailForm.reset();
    this.OrgNameValue = [];
    this.codeValue = "";
    this.ngOnInit();
  }

  onSecurityCodeKeyUp(event) {
    this.registrationOtpForm.controls['otpCode'].setValue(this.registrationOtpForm.controls['otpCode'].value.replace(/[^0-9]*/g, ''))
  }

  deleteNo() {
    jQuery('#Modal').modal('hide');
  }
 ///------End for functions used in previous ui (without tabs) present not using -------//

  awbExistedCheck(event) {
    const loginuserdetails = JSON.parse(localStorage.getItem('loginresultobject'));
    const withoutlogin_userID = JSON.parse(localStorage.getItem('withoutlogin_userID'));
    if (loginuserdetails != null && loginuserdetails != undefined && loginuserdetails != '') {
      localStorage.setItem("awbNumber", JSON.stringify(this.airwaybillobj.masterAwbNo + '-' + this.masterAwbNo1))
      if (
        (event.code !== "KeyC") &&
        (event.ctrlKey !== true) &&
        (event.key !== "c") && (event.key !== "Control")
      ) {
        if (event.target !== undefined) {
          if (event.target.value.length === 9) {
            this.editReport("view", "notsaved");
          }
        } else if (event !== undefined && event !== '' && event !== null) {
          if (event.length === 9) {
            this.editReport("view", "notsaved");
          }
        }
      }
    }
  }


  //------currently using SUBMIT and UPDATE reports functionalities-----//

  _submitShortReport(currentTab, saveType, hitType) {
    // this.apiCallCurrentTab = currentTab   
    if (
      this.airwaybillobj.masterAwbNo == '' || this.airwaybillobj.masterAwbNo == null || this.airwaybillobj.masterAwbNo == undefined ||
      this.aspectsobj.methodPacking == '' || this.aspectsobj.methodPacking == null || this.aspectsobj.methodPacking == undefined ||
      this.assessmentobj.discoveresCaused == '' || this.assessmentobj.discoveresCaused == null || this.assessmentobj.discoveresCaused == undefined
      ) {
        this.toast.error('Please Fill the Mandatory Fields');
        return;
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.stationCode != null && this.shipmentobj.stationCode != '' && this.shipmentobj.stationCode != undefined) {
      this.shipmentobj.stationCode = this.shipmentobj.stationCode.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.reportPreparedName != null && this.shipmentobj.reportPreparedName != '' && this.shipmentobj.reportPreparedName != undefined) {
      this.shipmentobj.reportPreparedName = this.shipmentobj.reportPreparedName.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.reportPreparedFunction != null && this.shipmentobj.reportPreparedFunction != '' && this.shipmentobj.reportPreparedFunction != undefined) {
      this.shipmentobj.reportPreparedFunction = this.shipmentobj.reportPreparedFunction.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.masterAwbNo1 != '' && this.masterAwbNo1 != null && this.masterAwbNo1 != undefined) {
      this.masterAwbNo1 = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    }
    if (this.airwaybillobj.masterAwbNo != '' && this.airwaybillobj.masterAwbNo != null && this.airwaybillobj.masterAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '');
    }
    if (this.flight2 != '' && this.flight2 != null && this.flight2 != undefined) {
      this.flight2 = this.flight2.replace(/[^0-9A-Za-z]*/g, '');
    }
    if (this.airlineId != null && this.airlineId != '' && this.airlineId != undefined) {
      this.airlineId = this.airlineId.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.originAirportId != null && this.originAirportId != '' && this.originAirportId != undefined) {
      this.originAirportId = this.originAirportId.replace(/[^a-zA-Z\-\s]*/g, '');
    }
    if (this.destairportId != null && this.destairportId != '' && this.destairportId != undefined) {
      this.destairportId = this.destairportId.replace(/[^a-zA-Z\-\s]*/g, '')
    }
    if (this.flightobj.mawbTotalpcs != null && this.flightobj.mawbTotalpcs != '' && this.flightobj.mawbTotalpcs != undefined) {
      this.flightobj.mawbTotalpcs = this.flightobj.mawbTotalpcs.replace(/[^0-9]*/g, '');
    }
    if (this.flightobj.actualReceivedPcs != null && this.flightobj.actualReceivedPcs != '' && this.flightobj.actualReceivedPcs != undefined) {
      this.flightobj.actualReceivedPcs = this.flightobj.actualReceivedPcs.replace(/[^0-9]*/g, '');
    }
    if (this.othermethodpacking != null && this.othermethodpacking != '' && this.othermethodpacking != undefined) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.otherdamagepacking != null && this.otherdamagepacking != '' && this.otherdamagepacking != undefined) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.aspectsobj.contentPieces != null && this.aspectsobj.contentPieces != '' && this.aspectsobj.contentPieces != undefined) {
      this.aspectsobj.contentPieces = this.aspectsobj.contentPieces.replace(/[^0-9]*/g, '');
    }
    if (this.aspectsobj.itemWeight != null && this.aspectsobj.itemWeight != '' && this.aspectsobj.itemWeight != undefined) {
      this.aspectsobj.itemWeight = this.aspectsobj.itemWeight.replace(/[^0-9\.]*/g, '');
    }
    if (this.aspectsobj.itemPcs != null && this.aspectsobj.itemPcs != '' && this.aspectsobj.itemPcs != undefined) {
      this.aspectsobj.itemPcs = this.aspectsobj.itemPcs.replace(/[^0-9]*/g, '');
    }
    if (this.affectedAwbarray.length != 0) {
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (this.affectedAwbarray[i].awb != '' && this.affectedAwbarray[i].awb != null && this.affectedAwbarray[i].awb != undefined) {
          this.affectedAwbarray[i].awb = this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        }
      }
    }
    if (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) {
      this.assessmentobj.internalName = this.assessmentobj.internalName.replace(/[^a-zA-Z\.\s]*/g, '');
    }
    if (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) {
      this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }


    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != '' && this.loginuserdetailsTest != undefined) {
      this.checkUserForm.controls['userCheckEmailId'].setValue('dummy');
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue('dummy');
    }
    this.isFromNextButton = false;
    if (currentTab === 'first') {
      if (this.iS_firtTab_Valid("apiValidation")) {
        this.submitShortReport_ApiCall(saveType, hitType);
      }
    } else if (currentTab === 'second') {
      if (this.is_SecondTab_Valid('apiValidation')) {
        this.submitShortReport_ApiCall(saveType, hitType);
      }
    } else if (currentTab === 'third') {
      if (this.is_ThirdTab_Valid('apiValidation')) {
        this.submitShortReport_ApiCall(saveType, hitType);
      }
    } else {
      this.submitShortReport_ApiCall(saveType, hitType);
    }
  }

  submitShortReport_ApiCall(saveType, hitType) {
    const dr1 = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    let forDupObj = {
      stationCode: this.LoginDetails.stationCode,
      masterAwbNum: this.airwaybillobj.masterAwbNo + '-' + dr1
    }
    if (this.showotherinternalArea === true) {
      this.assessmentobj.internalArea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }
    this.shipmentobj.incidentDate = this.datePipe.transform(this.shipmentobj.incidentDate, 'yyyy-MM-dd');
    this.airwaybillobj.flightDate = this.datePipe.transform(this.airwaybillobj.flightDate, 'yyyy-MM-dd');
    if(this.rootCauseObj.evaluationDate){
      this.rootCauseObj.evaluationDate = this.datePipe.transform(this.rootCauseObj.evaluationDate, 'yyyy-MM-dd');
    }
    const dr = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    this.DummyMawb = this.airwaybillobj.masterAwbNo
    this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo + '-' + dr;
    localStorage.setItem('masterAwbNolocalstrg', JSON.stringify(this.airwaybillobj.masterAwbNo));
    localStorage.setItem('presentMawb', JSON.stringify(this.airwaybillobj.masterAwbNo));
    this.shipmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.flightobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.assessmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.aspectsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.uploadpicsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.rootCauseObj.masterAwbNo = this.airwaybillobj.masterAwbNo;


    this.airwaybillobj.trucking_flight_info = this.flight0 + '-' + this.flight2;
    this.airwaybillobj.flightTruckNo = this.flight1 + '-' + this.flight2;

    this.awb1 = '';
    for (let i = 0; i < this.affectedAwbarray.length; i++) {
      if (i + 1 === this.affectedAwbarray.length) {
        this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
      } else {
        this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '') + ',';
      }

    }
    this.aspectsobj.affectedAwb = this.awb1;
    if (this.imageslist !== undefined) {
      this.dd = '';
      for (let i = 0; i < this.imageslist.length; i++) {
        if (i + 1 === this.imageslist.length) {
          this.dd = this.dd + this.imageslist[i];
        } else {
          this.dd = this.dd + this.imageslist[i] + '#..';
        }
      }
      this.uploadpicsobj.damageImage = this.dd;
    }

    for (const property in this.shipmentobj) {
      if (this.shipmentobj[property] === '') {
        this.shipmentobj[property] = null;
      }
    }

    for (const property in this.airwaybillobj) {
      if (this.airwaybillobj[property] === '') {
        this.airwaybillobj[property] = null;
      }
    }

    for (const property in this.flightobj) {
      if (this.flightobj[property] === '') {
        this.flightobj[property] = null;
      }
    }

    for (const property in this.aspectsobj) {
      if (this.aspectsobj[property] === '') {
        this.aspectsobj[property] = null;
      }
    }

    for (const property in this.assessmentobj) {
      if (this.assessmentobj[property] === '') {
        this.assessmentobj[property] = null;
      }
    }

    for (const property in this.uploadpicsobj) {
      if (this.uploadpicsobj[property] === '') {
        this.uploadpicsobj[property] = null;
      }
    }


    //-----------classification type change---------------//
    if (this.aspectsobj.safetyLife === 'yes') {
      this.aspectsobj.classificationType = 'Life Threat - Damage involving safety of life or property Inspection, Decontamination and verification is required.(e.g. chemical, biological, radioactive leakage or spillage)';
    } else if (this.aspectsobj.suspectPilf === 'yes') {
      this.aspectsobj.classificationType = 'Pilferage - Cargo suspected of Pilferage Inspection is Required';
    } else if (this.aspectsobj.contentAffected === 'possibly' || this.aspectsobj.contentAffected === 'yes') {
      if (this.aspectsobj.contentAffected === 'possibly') {
        this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
      } else if (this.aspectsobj.contentAffected === 'yes') {
        this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
      }
    } else {
      if ((this.aspectsobj.safetyLife === 'no') && (this.aspectsobj.suspectPilf === 'no' || this.aspectsobj.suspectPilf === null) && (this.aspectsobj.contentAffected === 'unlikely')) {
        // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
        this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
      }
    }
    //-----------End classification type change---------------//

    this.affectedAwbTempArray = [];
    if (this.affectedAwbarray.length > 1) {
      for (const each of this.affectedAwbarray) {
        if (each.awb != '' && each != null && each != undefined) {
          this.affectedAwbTempArray.push(each.awb);
        }
      }
      let toStringVal = this.fn_toString(this.affectedAwbTempArray)
      this.aspectsobj.affectedAwb = toStringVal;
    }

    this.damageCategoryItems = [];
    this.shipmentobj.privacyStatus = this.istermsAndCond_chked;
    this.shipmentobj.address = this.selectedAddress;
    if (this.airwaybillobj.masterAwbNo.length < 4 && this.suffixAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.suffixAwbNo + '-' + this.masterAwbNo1;
    }



    this.damageCategoryItems.push(this.shipmentobj);
    this.damageCategoryItems.push(this.airwaybillobj);
    this.damageCategoryItems.push(this.flightobj);
    this.damageCategoryItems.push(this.aspectsobj);
    this.damageCategoryItems.push(this.assessmentobj);
    this.damageCategoryItems.push(this.uploadpicsobj);
    this.damageCategoryItems.push(this.rootCauseObj);

    //--------------------service -----------------------------------------//
    const finalobj = {
      damageCategoryItems: this.damageCategoryItems,
      prefixCarrierId: this.selectedPrefixId,
      carrierId: this.shipmentobj.carrierId,
      rootcauseExists: 'N',
      createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
    
    if ((this.rootCauseObj.behaviourDesc !== '' && this.rootCauseObj.behaviourDesc !== null && this.rootCauseObj.behaviourDesc !== undefined) ||
    (this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== undefined) ||
    (this.rootCauseObj.correctiveActions !== '' && this.rootCauseObj.correctiveActions !== null && this.rootCauseObj.correctiveActions !== undefined) ||
    (this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== undefined) ||
    (this.rootCauseObj.evaluationDone !== '' && this.rootCauseObj.evaluationDone !== null && this.rootCauseObj.evaluationDone !== undefined) ||
    (this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== undefined) ||
    (this.rootCauseObj.rootCauseInvestigation !== '' && this.rootCauseObj.rootCauseInvestigation !== null && this.rootCauseObj.rootCauseInvestigation !== undefined) ||
    (this.rootCauseObj.rulesDesc !== '' && this.rootCauseObj.rulesDesc !== null && this.rootCauseObj.rulesDesc !== undefined)) {
      finalobj.rootcauseExists = 'Y'
    } else {
      finalobj.rootcauseExists = 'N'
    }
    this.spinner.show();
    this.DamageReportServicel.send_ShortReport(finalobj).subscribe(
      data => {
        if (data.responseCode === 1) {
          let inputObj = {
            carrierId: this.shipmentobj.carrierId,
            awbNumber: this.airwaybillobj.masterAwbNo,
            organizationId: this.loginuserdetailsTest.orgId,
            originAirportId: this.airwaybillobj.originAirportId,
            destairportId: this.airwaybillobj.destairportId,
            userId: this.loginuserdetailsTest.userId,
            rootcauseExists: this.airwaybillobj.rootcauseExists,
            rootcauseSendstatus: this.airwaybillobj.rootcauseSendstatus,
            reportOrgRootcauseStatus: this.airwaybillobj.reportOrgRootcauseStatus,
            orgRootcauseStatus: this.airwaybillobj.orgRootcauseStatus,
            reportId: data.reportId,
            prefixCarrierId: this.selectedPrefixId,
            createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          };

          this.DamageReportServicel._send_Mails(inputObj, "SAVE").subscribe(responseData => {
            this.spinner.hide();
          }, error => {
            this.spinner.hide();
          });
          
          if (saveType === 'SAVEANDEXIT') {
            this.navigationEnable = true;

            localStorage.setItem("isSaveHitDOne", JSON.stringify("no"));
            let obj = {
              isFrom: 'CREATE_REPORT'
            }
            if (hitType === 'fromButtonHit') {
              localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
              localStorage.setItem('activation', JSON.stringify("1"));
              if (this.assessmentobj.internalResponsibility === 'external') {
                this.toast.success('Report submitted successfully');
                if (this.loginuserdetailsTest.userType === 'S') {
                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigateByUrl("/csa-airline-dashboard");
                  } else {
                    this.route.navigateByUrl("/csa-dashboard");
                  }
                } else if (this.loginuserdetailsTest.userType === 'E') {
                  this.route.navigateByUrl("/user-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'SA') {
                  this.route.navigateByUrl("/sa-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'A') {

                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigate(['admin-airline-dashboard'], {

                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  } else {
                    this.route.navigate(['/admin-handler-dashboard'], {
                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  }

                }

              } else if (this.assessmentobj.internalResponsibility === 'internal') {
                this.toast.success('Internal Report Has been sent successfully to User');
                if (this.loginuserdetailsTest.userType === 'S') {
                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigateByUrl("/csa-airline-dashboard");
                  } else {
                    this.route.navigateByUrl("/csa-dashboard");
                  }
                } else if (this.loginuserdetailsTest.userType === 'E') {
                  this.route.navigateByUrl("/user-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'SA') {
                  this.route.navigateByUrl("/sa-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'A') {
                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigate(['admin-airline-dashboard'], {

                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  } else {
                    this.route.navigate(['/admin-handler-dashboard'], {
                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  }
                }

              } else {
                this.toast.success('Report submitted successfully');

                if (this.loginuserdetailsTest.userType === 'S') {
                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigateByUrl("/csa-airline-dashboard");
                  } else {
                    this.route.navigateByUrl("/csa-dashboard");
                  }
                } else if (this.loginuserdetailsTest.userType === 'E') {
                  this.route.navigateByUrl("/user-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'SA') {
                  this.route.navigateByUrl("/sa-dashboard");
                } else if (this.loginuserdetailsTest.userType === 'A') {
                  if (this.loginuserdetailsTest.orgType === 'A') {
                    this.route.navigate(['admin-airline-dashboard'], {

                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  } else {
                    this.route.navigate(['/admin-handler-dashboard'], {
                      queryParams: {
                        DashBorad: 'switched'
                      },
                      queryParamsHandling: 'merge',
                    });
                  }
                }
              }
            } else {
              this.toast.success('Report submitted successfully');
            }
          } else {
            this.toast.success('Report submitted successfully');
            let split = []
            let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
            split = duplicateMawb.split('-');
            this.airwaybillobj.masterAwbNo = '';
            this.masterAwbNo1 = '';
            this.suffixAwbNo = '';
            this.airwaybillobj.masterAwbNo = split[0];
            this.suffixAwbNo = split[0];
            this.masterAwbNo1 = split[1];
            localStorage.setItem("isSaveHitDOne", JSON.stringify("yes"));
            this.isSavedOnce = true;
            this.is_From = 'EDIT_REPORT';
            this.editReport("view", 'savenotDone');
          }
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          this.spinner.hide();
        } else {
          let split = []
          let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
          split = duplicateMawb.split('-');
          this.airwaybillobj.masterAwbNo = '';
          this.masterAwbNo1 = '';
          this.suffixAwbNo = '';


          this.airwaybillobj.masterAwbNo = split[0];
          this.masterAwbNo1 = split[1];
          this.suffixAwbNo = split[0];
          this.spinner.hide();
          this.toast.error(data.saveDataReport.message);
        }
      },
      error => {
        let split = []
        let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
        split = duplicateMawb.split('-');
        this.airwaybillobj.masterAwbNo = '';
        this.masterAwbNo1 = '';
        this.suffixAwbNo = '';
        this.airwaybillobj.masterAwbNo = split[0];
        this.suffixAwbNo = split[0];
        this.masterAwbNo1 = split[1];
        this.spinner.hide();
        this.toast.error('Report is not generated due to server issue.');
      });
  }

  update_ShortReport(currentTab: String, updateType, hitType) {
    if (
      this.airwaybillobj.masterAwbNo == '' || this.airwaybillobj.masterAwbNo == null || this.airwaybillobj.masterAwbNo == undefined ||
      this.aspectsobj.methodPacking == '' || this.aspectsobj.methodPacking == null || this.aspectsobj.methodPacking == undefined ||
      this.assessmentobj.discoveresCaused == '' || this.assessmentobj.discoveresCaused == null || this.assessmentobj.discoveresCaused == undefined
      ) {
        this.toast.error('Please Fill the Mandatory Fields');
        return;
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.stationCode != null && this.shipmentobj.stationCode != '' && this.shipmentobj.stationCode != undefined) {
      this.shipmentobj.stationCode = this.shipmentobj.stationCode.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.orgName != null && this.shipmentobj.orgName != '' && this.shipmentobj.orgName != undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.reportPreparedName != null && this.shipmentobj.reportPreparedName != '' && this.shipmentobj.reportPreparedName != undefined) {
      this.shipmentobj.reportPreparedName = this.shipmentobj.reportPreparedName.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.reportPreparedFunction != null && this.shipmentobj.reportPreparedFunction != '' && this.shipmentobj.reportPreparedFunction != undefined) {
      this.shipmentobj.reportPreparedFunction = this.shipmentobj.reportPreparedFunction.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.masterAwbNo1 != '' && this.masterAwbNo1 != null && this.masterAwbNo1 != undefined) {
      this.masterAwbNo1 = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    }
    if (this.airwaybillobj.masterAwbNo != '' && this.airwaybillobj.masterAwbNo != null && this.airwaybillobj.masterAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo.replace(/[^0-9]*/g, '');
    }
    if (this.flight2 != '' && this.flight2 != null && this.flight2 != undefined) {
      this.flight2 = this.flight2.replace(/[^0-9A-Za-z]*/g, '');
    }
    if(this.airwaybillobj.airlineId === undefined || this.airwaybillobj.airlineId === '' || this.airwaybillobj.airlineId === null){
      this.airwaybillobj.airlineId = this.edit_Report_Data.airlineName;
    }
    if (this.airlineId != null && this.airlineId != '' && this.airlineId != undefined) {
      this.airlineId = this.airlineId.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.originAirportId != null && this.originAirportId != '' && this.originAirportId != undefined) {
      this.originAirportId = this.originAirportId.replace(/[^a-zA-Z\-\s]*/g, '');
    }
    if (this.destairportId != null && this.destairportId != '' && this.destairportId != undefined) {
      this.destairportId = this.destairportId.replace(/[^a-zA-Z\-\s]*/g, '')
    }
    if (this.flightobj.mawbTotalpcs != null && this.flightobj.mawbTotalpcs != '' && this.flightobj.mawbTotalpcs != undefined) {
      this.flightobj.mawbTotalpcs = this.flightobj.mawbTotalpcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.flightobj.actualReceivedPcs != null && this.flightobj.actualReceivedPcs != '' && this.flightobj.actualReceivedPcs != undefined) {
      this.flightobj.actualReceivedPcs = this.flightobj.actualReceivedPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.othermethodpacking != null && this.othermethodpacking != '' && this.othermethodpacking != undefined) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.otherdamagepacking != null && this.otherdamagepacking != '' && this.otherdamagepacking != undefined) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.aspectsobj.contentPieces != null && this.aspectsobj.contentPieces != '' && this.aspectsobj.contentPieces != undefined) {
      this.aspectsobj.contentPieces = this.aspectsobj.contentPieces.toString().replace(/[^0-9]*/g, '');
    }
    if (this.aspectsobj.itemWeight != null && this.aspectsobj.itemWeight != '' && this.aspectsobj.itemWeight != undefined) {
      this.aspectsobj.itemWeight = this.aspectsobj.itemWeight.replace(/[^0-9\.]*/g, '');
    }
    if (this.aspectsobj.itemPcs != null && this.aspectsobj.itemPcs != '' && this.aspectsobj.itemPcs != undefined) {
      this.aspectsobj.itemPcs = this.aspectsobj.itemPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.affectedAwbarray.length != 0) {
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (this.affectedAwbarray[i].awb != '' && this.affectedAwbarray[i].awb != null && this.affectedAwbarray[i].awb != undefined) {
          this.affectedAwbarray[i].awb = this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        }
      }
    }
    if (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) {
      this.assessmentobj.internalName = this.assessmentobj.internalName.replace(/[^a-zA-Z\.\s]*/g, '');
    }
    if (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) {
      this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != '' && this.loginuserdetailsTest != undefined) {
      this.checkUserForm.controls['userCheckEmailId'].setValue('dummy');
      this.checkUserForm.controls['userCheckConfirmEmailId'].setValue('dummy');
    }
    this.isFromNextButton = false; 
      if(this.edit_Report_Data.autoSendEmailInternal == 'Y') {
            if(this.edit_Report_Data.rootcausestatus === 'Y' && this.edit_Report_Data.orgRootcauseStatus === 'N'){
              this.showRootCauseAlert(updateType, hitType)
              return;
            }else if(this.edit_Report_Data.rootcausestatus !== 'Y' && this.edit_Report_Data.orgRootcauseStatus === 'Y'){
              this.confirmSendStatus = 'Y';
              this._update_ShortReport_ApiCall(updateType, hitType);
            }else if(this.edit_Report_Data.rootcausestatus !== 'Y' && this.edit_Report_Data.orgRootcauseStatus !== 'Y'){
              this.confirmSendStatus = 'N';
              this._update_ShortReport_ApiCall(updateType, hitType);
            }else{
              this.confirmSendStatus = 'Y';
              this._update_ShortReport_ApiCall(updateType, hitType);
            }
      } else {
        this.confirmSendStatus = this.edit_Report_Data.rootcausestatus;
        this._update_ShortReport_ApiCall(updateType, hitType);
      }
  }

  showRootCauseAlert(updateType, hitType) {
    this.hitTypeValue = hitType;
    this.updateTypeValue = updateType;
    this.confirmSendStatus = 'Y';
    jQuery('#AwbNumExistedPopup').modal({ backdrop: 'static', keyboard: false });
  }

  _update_ShortReport_ApiCall(updateType, hitType) {

    if (this.showotherinternalArea === true) {
      this.assessmentobj.internalArea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }
    this.shipmentobj.incidentDate = this.datePipe.transform(this.shipmentobj.incidentDate, 'yyyy-MM-dd');
    this.airwaybillobj.flightDate = this.datePipe.transform(this.airwaybillobj.flightDate, 'yyyy-MM-dd');
    if(this.rootCauseObj.evaluationDate){

      this.rootCauseObj.evaluationDate = this.datePipe.transform(this.rootCauseObj.evaluationDate, 'yyyy-MM-dd');
    }
    const dr = this.masterAwbNo1.replace(/[^0-9\s]*/g, '');
    this.DummyMawb = this.airwaybillobj.masterAwbNo
    this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo + '-' + dr;
    localStorage.setItem('masterAwbNolocalstrg', JSON.stringify(this.airwaybillobj.masterAwbNo));
    localStorage.setItem('presentMawb', JSON.stringify(this.airwaybillobj.masterAwbNo));
    this.shipmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.flightobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.assessmentobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.aspectsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.uploadpicsobj.masterAwbNo = this.airwaybillobj.masterAwbNo;
    this.rootCauseObj.masterAwbNo = this.airwaybillobj.masterAwbNo;

    this.airwaybillobj.trucking_flight_info = this.flight0 + '-' + this.flight2;

    this.airwaybillobj.flightTruckNo = this.flight1 + '-' + this.flight2;


    this.awb1 = '';
    for (let i = 0; i < this.affectedAwbarray.length; i++) {
      if (i + 1 === this.affectedAwbarray.length) {
        this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
      } else {
        this.awb1 = this.awb1 + this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '') + ',';
      }
    }
    this.aspectsobj.affectedAwb = this.awb1;

    if (this.imageslist !== undefined) {
      this.dd = '';
      for (let i = 0; i < this.imageslist.length; i++) {
        if (i + 1 === this.imageslist.length) {
          this.dd = this.dd + this.imageslist[i];
        } else {
          this.dd = this.dd + this.imageslist[i] + '#..';
        }
      }

      this.uploadpicsobj.damageImage = this.dd;

    }

    if (this.edit_Report_Data.damageImage != null && this.edit_Report_Data.damageImage != undefined && this.edit_Report_Data.damageImage != '') {
      if (this.damage_Report_Images !== null && this.damage_Report_Images !== undefined) {
        if (this.damage_Report_Images.length != 0) {
          this.uploadpicsobj.oldDamageImages = this.damage_Report_Images.toString();
        }
      }
    }



    for (const property in this.shipmentobj) {
      if (this.shipmentobj[property] === null) {
        this.shipmentobj[property] = '';
      }
    }

    for (const property in this.airwaybillobj) {
      if (this.airwaybillobj[property] === null) {
        this.airwaybillobj[property] = '';
      }
    }

    for (const property in this.flightobj) {
      if (this.flightobj[property] === '') {
        this.flightobj[property] = null;
      }
    }

    for (const property in this.aspectsobj) {
      if (this.aspectsobj[property] === '') {
        this.aspectsobj[property] = null;
      }
    }


    for (const property in this.assessmentobj) {
      if (this.assessmentobj[property] === null) {
        this.assessmentobj[property] = '';
      }
    }

    for (const property in this.uploadpicsobj) {
      if (this.uploadpicsobj[property] === null) {
        this.uploadpicsobj[property] = '';
      }
    }


    //-----------classification type change---------------//
    if (this.aspectsobj.safetyLife === 'yes') {
      this.aspectsobj.classificationType = 'Life Threat - Damage involving safety of life or property Inspection, Decontamination and verification is required.(e.g. chemical, biological, radioactive leakage or spillage)';
    } else if (this.aspectsobj.suspectPilf === 'yes') {
      this.aspectsobj.classificationType = 'Pilferage - Cargo suspected of Pilferage Inspection is Required';
    } else if (this.aspectsobj.contentAffected === 'possibly' || this.aspectsobj.contentAffected === 'yes') {
      if (this.aspectsobj.contentAffected === 'possibly') {
        this.aspectsobj.classificationType = 'Moderate - Damage might have affected the content Inspection is advised';
      } else if (this.aspectsobj.contentAffected === 'yes') {
        this.aspectsobj.classificationType = 'Major - Damage has affected the condition of the contents. Inspection is Required';
      }
    } else {
      if ((this.aspectsobj.safetyLife === 'no') && (this.aspectsobj.suspectPilf === 'no' || this.aspectsobj.suspectPilf === null) && (this.aspectsobj.contentAffected === 'unlikely')) {
        // this.aspectsobj.classificationType = 'Minor - Small tears, dents or scratches to the outer packaging only';
        this.aspectsobj.classificationType = 'Minor - Damage to outer packaging only. (small tears, dents or scratches)';
      }
    }
    //-----------End classification type change---------------//


    this.damageCategoryItems = [];
    this.shipmentobj.privacyStatus = this.istermsAndCond_chked;
    this.shipmentobj.address = this.selectedAddress;
    if (this.airwaybillobj.masterAwbNo.length < 4 && this.suffixAwbNo != undefined) {
      this.airwaybillobj.masterAwbNo = this.suffixAwbNo + '-' + this.masterAwbNo1;
    }

    this.airwaybillobj.destairportId = this.airwaybillobj.destairportId
    this.airwaybillobj.originAirportId = this.airwaybillobj.originAirportId
    this.damageCategoryItems.push(this.shipmentobj);
    this.damageCategoryItems.push(this.airwaybillobj);
    this.damageCategoryItems.push(this.flightobj);
    this.damageCategoryItems.push(this.aspectsobj);
    this.damageCategoryItems.push(this.assessmentobj);
    this.damageCategoryItems.push(this.uploadpicsobj);
    this.damageCategoryItems.push(this.rootCauseObj);

    //--------------------service -----------------------------------------//
    const finalobj = {
      damageCategoryItems: this.damageCategoryItems,
      updateType: (this.isSavedOnce) ? "NU" : "U",
      reportId: this.edit_Report_Data.reportId,
      rootcauseExists: 'N',
      prefixCarrierId: this.selectedPrefixId,
      rootcauseConfirmSendStatus: this.confirmSendStatus,
      rootcauseSendstatus: this.airwaybillobj.rootcauseSendstatus,
      reportOrgRootcauseStatus: this.airwaybillobj.reportOrgRootcauseStatus,
      orgRootcauseStatus: this.airwaybillobj.orgRootcauseStatus,
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    };
    
    if ((this.rootCauseObj.behaviourDesc !== '' && this.rootCauseObj.behaviourDesc !== null && this.rootCauseObj.behaviourDesc !== undefined) ||
    (this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== undefined) ||
    (this.rootCauseObj.correctiveActions !== '' && this.rootCauseObj.correctiveActions !== null && this.rootCauseObj.correctiveActions !== undefined) ||
    (this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== undefined) ||
    (this.rootCauseObj.evaluationDone !== '' && this.rootCauseObj.evaluationDone !== null && this.rootCauseObj.evaluationDone !== undefined) ||
    (this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== undefined) ||
    (this.rootCauseObj.rootCauseInvestigation !== '' && this.rootCauseObj.rootCauseInvestigation !== null && this.rootCauseObj.rootCauseInvestigation !== undefined) ||
    (this.rootCauseObj.rulesDesc !== '' && this.rootCauseObj.rulesDesc !== null && this.rootCauseObj.rulesDesc !== undefined)) {
      finalobj.rootcauseExists = 'Y'
    } else {
      finalobj.rootcauseExists = 'N'
    }
    this.airwaybillobj.rootcauseExists = finalobj.rootcauseExists;
    this.spinner.show();
    this.DamageReportServicel.update_ShortReport(finalobj).subscribe(
      data => {

        if (data.responseCode === 1) {

    let inputObj = {
      carrierId: this.shipmentobj.carrierId,
      awbNumber: this.airwaybillobj.masterAwbNo,
      organizationId: this.loginuserdetailsTest.orgId,
      originAirportId: this.airwaybillobj.originAirportId,
      orgType: this.loginuserdetailsTest.orgType,
      destairportId: this.airwaybillobj.destairportId,
      userId: this.loginuserdetailsTest.userId,
      rootcauseExists: this.airwaybillobj.rootcauseExists,
      rootcauseSendstatus: this.airwaybillobj.rootcauseSendstatus,
      reportOrgRootcauseStatus: this.airwaybillobj.reportOrgRootcauseStatus,
      rootcauseConfirmSendStatus: this.confirmSendStatus,
      orgRootcauseStatus: this.airwaybillobj.orgRootcauseStatus,
      reportId: this.edit_Report_Data.reportId,
      prefixCarrierId: this.selectedPrefixId,
      createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.DamageReportServicel._send_Mails(inputObj, "UPDATE").subscribe(responseData => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    
    let rowData = {
      isFrom: 'EDIT_REPORT_SUCCESS'
    }

    if (updateType === "UpdateAndExit") {
      this.navigationEnable = true;
      if (hitType === 'fromButtonHit') {
        localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
        this.toast.success('Report submitted successfully');
        if (this.loginuserdetailsTest.userType === 'S') {
          if (this.from_csaDashBoard != undefined) {
            if (this.from_csaDashBoard === 'true') {
              if (this.loginuserdetailsTest.orgType === 'A') {
                this.route.navigateByUrl("/csa-airline-dashboard");
              } else {
                this.route.navigateByUrl("/csa-dashboard");
              }
            }
          } else if (this.from_view_Users != undefined) {
            if (this.from_view_Users === 'true') {
              let obj = {
                is_From_Edit_DamageReport: true
              }
              this.route.navigate(["/viewusers"], { queryParams: obj });
            }
          } else {
            if (this.loginuserdetailsTest.orgType === 'A') {
              this.route.navigateByUrl("/csa-airline-dashboard");
            } else {
              this.route.navigateByUrl("/csa-dashboard");
            }

          }
        } else if (this.loginuserdetailsTest.userType === 'E') {


          if (this.from_userDashboard != undefined) {
            if (this.from_userDashboard === 'true') {
              this.route.navigateByUrl("/user-dashboard");
            }
          } else if (this.from_view_Users != undefined) {
            if (this.from_view_Users === 'true') {
              this.route.navigateByUrl("/viewusers");
            }
          } else {
            this.route.navigateByUrl("/user-dashboard");

          }

        } else if (this.loginuserdetailsTest.userType === 'SA') {
          if (this.is_From_sideReports != undefined && this.is_From_sideReports != null && this.is_From_sideReports != '') {
            if (this.is_From_sideReports === "true") {

              this.route.navigate(["/viewreports"]);
              // this.spinner.hide();
            } else {

              this.route.navigateByUrl("/sa-dashboard");
              // this.spinner.hide();
            }
          } else {
            let obj = {
              is_From_Edit_DamageReport: true
            }

            this.route.navigate(["/viewusers"], { queryParams: obj });
          }

        } else if (this.loginuserdetailsTest.userType === 'A') {
          this.ViewReportsData.isFromDashboard = 'yes';
          if (this.loginuserdetailsTest.orgType === 'A') {
            this.route.navigate(['admin-airline-dashboard'], {

              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          } else {
            this.route.navigate(['/admin-handler-dashboard'], {
              queryParams: {
                DashBorad: 'switched'
              },
              queryParamsHandling: 'merge',
            });
          }
        }
      } else {
        this.toast.success('Report updated successfully');
      }
    } else {
      let split = []
      let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
      split = duplicateMawb.split('-');
      this.airwaybillobj.masterAwbNo = '';
      this.masterAwbNo1 = '';
      this.suffixAwbNo = '';
      this.airwaybillobj.masterAwbNo = split[0];
      this.suffixAwbNo = split[0];
      this.masterAwbNo1 = split[1];
    }
        } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
          this.toastr.info("Your session is expired, please login again");
          this.spinner.hide();
        }
        else {
          let split = []
          let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
          split = duplicateMawb.split('-');
          this.airwaybillobj.masterAwbNo = '';
          this.suffixAwbNo = '';
          this.masterAwbNo1 = ''

          this.airwaybillobj.masterAwbNo = split[0];
          this.masterAwbNo1 = split[1];
          this.suffixAwbNo = split[0];
          this.spinner.hide();
          this.toast.error(data.editDamageReport.message);
        }
      },
      error => {

        let split = []
        let duplicateMawb = JSON.parse(localStorage.getItem('presentMawb'));
        split = duplicateMawb.split('-');
        this.airwaybillobj.masterAwbNo = '';
        this.suffixAwbNo = '';
        this.masterAwbNo1 = ''
        this.airwaybillobj.masterAwbNo = split[0];
        this.suffixAwbNo = split[0];
        this.masterAwbNo1 = split[1];
        this.spinner.hide();
        this.toast.error('Report is not generated due to server issue.');
      });
  }

  //-----below funcs checks  Tabs  validation-----///

  iS_firtTab_Valid(type: String): boolean {

    if ((type !== "disabledCheck") && (this.isFromNextButton === false)) {
      localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    }


    if (this.shipmentobj.incidentDate === '' || this.shipmentobj.incidentDate === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter incident date');
      }

      return false;
    } else if (this.shipmentobj.incidentTime === '' || this.shipmentobj.incidentTime === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter incident time');
      }
      return false;
    }
    else if (this.shipmentobj.stationCode === '' || this.shipmentobj.stationCode === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter your station code');
      }
      return false;
    } else if (this.selectionStatus == false) {
      if (type === "apiValidation") {
        this.toast.error('Please enter valid station code');
      }
      return false;
    } else if (this.shipmentobj.orgName === '' || this.shipmentobj.orgName === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter your organization name');
      }
      return false;
    } else if (this.airwaybillobj.masterAwbNo === '' || this.airwaybillobj.masterAwbNo === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter master airway bill');
      }
      return false;
    } else if (this.masterAwbNo1 === undefined || this.masterAwbNo1 === '' || this.masterAwbNo1 === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter master airway bill');
      }
      return false;
    }
    else if (this.masterAwbNo1.toString().length < 9) {
      if (type === "apiValidation") {
        this.toast.error('Master airway bill must be 11 digits');
      }
      return false;
    }
    else if (this.flight1 === undefined || this.flight1 === '' || this.flight1 === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter flight field');
      }
      return false;
    } else if (this.isFlight_Selected_Frm_List === false) {
      if (type === "apiValidation") {
        this.toast.error('Please select flight field only from list');
      }
      return false;
    }
    else if (this.flight2 === undefined || this.flight2 === '' || this.flight2 === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter flight field');
      }
      return false;
    } else if (this.airwaybillobj.flightDate === '' || this.airwaybillobj.flightDate === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter flight date');
      }
      return false;
    } else if (this.airwaybillobj.originAirportId === '' || this.airwaybillobj.originAirportId === null || this.originAirportId === '' || this.originAirportId === undefined || this.originAirportId === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter airport origin');
      }
      return false;
    } else if (this.airwaybillobj.destairportId === '' || this.airwaybillobj.destairportId === null || this.destairportId === '' || this.destairportId === undefined || this.destairportId === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter airport destination');
      }
      return false;
    } else if (this.airwaybillobj.originAirportId === this.airwaybillobj.destairportId) {
      if (type === "apiValidation") {
        this.toast.error('airport origin and destination should not be same');
      }
      return false;
    } else {
      this._setColoursToEach_Stepper_Header("green", 0);
      return true;
    }
  }

  is_SecondTab_Valid(type): boolean {

    if ((type !== "disabledCheck") && (this.isFromNextButton === false)) {

      localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));

    }
    if ((this.aspectsobj.methodPacking === undefined || this.aspectsobj.methodPacking === '' || this.aspectsobj.methodPacking === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter method of packing field');
      }
      return false;
    }
    else if ((this.showothermethodPacking === true) && (this.othermethodpacking === undefined || this.othermethodpacking === '' || this.othermethodpacking === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for method of packing');
      }
      return false;
    }

    else if (this.only_scratches_tears_dents === null || this.only_scratches_tears_dents === '' || this.only_scratches_tears_dents === undefined) {
      if (type === "apiValidation") {
        this.toast.error('Please select dents or tears field');
      }
      return false;
    }
    else if ((this.only_scratches_tears_dents === 'no') && (this.aspectsobj.damagePacking === '' || this.aspectsobj.damagePacking === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please select damage to packing');
      }
      return false;
    }
    else if ((this.aspectsobj.contentPieces === undefined || this.aspectsobj.contentPieces === '' || this.aspectsobj.contentPieces === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter AWB pieces affected field');
      }
      return false;
    }
    else if (this.aspectsobj.contentAffected === '' || this.aspectsobj.contentAffected === null) {
      if (type === "apiValidation") {
        this.toast.error('Please select content affected');
      }
      return false;
    }


    else if (((this.aspectsobj.contentAffected === 'possibly') || (this.aspectsobj.contentAffected === 'yes')) && (this.aspectsobj.contentPieces === '' || this.aspectsobj.contentPieces === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter Pieces Affected field');
      }
      return false;
    }
    else if (((this.aspectsobj.itemMissing === 'possibly') || (this.aspectsobj.itemMissing === 'yes')) && (this.aspectsobj.suspectPilf === '' || this.aspectsobj.suspectPilf === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please select suspected of pilferage');
      }
      return false;
    }
    else if (this.aspectsobj.safetyLife === '' || this.aspectsobj.safetyLife === null) {
      if (type === "apiValidation") {
        this.toast.error('Please select safety involved');
      }
      return false;
    } else if (this.aspectsobj.recuperationReq === 'yes') {
      if (this.isRecoveryRequiredSelected()) {
        this._setColoursToEach_Stepper_Header("green", 1);
        return true;
      } else {
        if (type === "apiValidation") {
          this.toast.error('Please select recovery actions');
        }
        return false;
      }

    }
    else {
      this._setColoursToEach_Stepper_Header("green", 1);
      return true;
    }
  }

  isRecoveryRequiredSelected(): boolean {
    let isExist = this.recuparationstatusarray.some((element) => {
      return (element.checked === true);
    });
    return isExist;
  }


  is_ThirdTab_Valid(type): boolean {
    if ((type !== "disabledCheck") && (this.isFromNextButton === false)) {
      localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    }

    if ((this.assessmentobj.damageDiscovered === 'Other') && (this.otherprocessstage === undefined || this.otherprocessstage === '' || this.otherprocessstage === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for process stage');
      }
      return false;
    } else if ((this.assessmentobj.internalArea === 'Other') && (this.otherprocessarea === undefined || this.otherprocessarea === '' || this.otherprocessarea === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for process area');
      }
      return false;
    } else if ((this.showothermethodPacking === true) && (this.othermethodpacking === undefined || this.othermethodpacking === '' || this.othermethodpacking === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for method of packing');
      }
      return false;
    } else if ((this.showotherdamagePacking === true) && (this.otherdamagepacking === undefined || this.otherdamagepacking === '' || this.otherdamagepacking === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for damage to packing');
      }
      return false;
    } else if ((this.showotherconditioncontent === true) && (this.otherconditioncontent === undefined || this.otherconditioncontent === '' || this.otherconditioncontent === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for condotion of content');
      }
      return false;
    } else if ((this.showotherrecuperatoinStatus === true) && (this.otherrecuperatoinStatus === undefined || this.otherrecuperatoinStatus === '' || this.otherrecuperatoinStatus === null)) {
      if (type === "apiValidation") {
        this.toast.error('Please enter other field for recovery actions');
      }
      return false;
    } else if (this.assessmentobj.discoveresCaused === '' || this.assessmentobj.discoveresCaused === null) {
      if (type === "apiValidation") {
        this.toast.error('Please select Discovered by or Caused by');
      }
      return false;
    } else if (this.assessmentobj.internalName === '' || this.assessmentobj.internalName === null) {
      if (type === "apiValidation") {
        this.toast.error('Please enter name');
      }
      return false;
    } else if ((this.assessmentobj.damageDiscovered === '' || this.assessmentobj.damageDiscovered === undefined || this.assessmentobj.damageDiscovered === null) || ((this.damageDiscoveredstore === null || this.damageDiscoveredstore === '' || this.damageDiscoveredstore === undefined))) {
      if (type === "apiValidation") {
        this.toast.error('Please select During which handling stage');
      }
      return false;
    }
    else if (this.assessmentobj.internalResponsibility === '' || this.assessmentobj.internalResponsibility === null) {
      if (type === "apiValidation") {
        this.toast.error('Please select Responsibility');
      }
      return false;
    } else {
      this._setColoursToEach_Stepper_Header("green", 2);
      return true;
    }
  }

  isFourthTabContainsData() {
    if (this.is_From === 'EDIT_REPORT') {
      if (
        (this.flightobj.mawbTotalpcs !== '' && this.flightobj.mawbTotalpcs !== null && this.flightobj.mawbTotalpcs !== undefined) ||
        (this.flightobj.actualReceivedPcs !== '' && this.flightobj.actualReceivedPcs !== null && this.flightobj.actualReceivedPcs !== undefined) ||
        (this.flightobj.contentNature !== '' && this.flightobj.contentNature !== null && this.flightobj.contentNature !== undefined) ||
        (this.flightobj.shipper !== '' && this.flightobj.shipper !== null && this.flightobj.shipper !== undefined) ||
        (this.flightobj.consignee !== '' && this.flightobj.consignee !== null && this.flightobj.consignee !== undefined) ||
        (this.flightobj.issuingAgent !== '' && this.flightobj.issuingAgent !== null && this.flightobj.issuingAgent !== undefined)
      ) {
        this._setColoursToEach_Stepper_Header("green", 3);
        localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
      } else {
        if ((this.isThirdTabHasChanges === true) || (this.isSecondTabHasChanges === true) || (this.isFirstTabHasChanges === true)) {
          localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
        } else {
          localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
        }
        this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 4);
      }
    } else {
      this._setHeaderColourToFourthTab()
    }
  }

  isFifthTabContainsData() {
    if (this.is_From === 'EDIT_REPORT') {
      if (
        (this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== undefined) ||
        (this.rootCauseObj.rulesDesc !== null && this.rootCauseObj.rulesDesc !== '' && this.rootCauseObj.rulesDesc !== undefined) ||
        (this.rootCauseObj.behaviourDesc !== null && this.rootCauseObj.behaviourDesc !== '' && this.rootCauseObj.behaviourDesc !== undefined) ||
        (this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== undefined)||
        (this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== undefined)||
        (this.rootCauseObj.rootCauseInvestigation !== null && this.rootCauseObj.rootCauseInvestigation !== '' && this.rootCauseObj.rootCauseInvestigation !== undefined)||
        (this.rootCauseObj.correctiveActions !== null && this.rootCauseObj.correctiveActions !== '' && this.rootCauseObj.correctiveActions !== undefined)||
        (this.rootCauseObj.evaluationDone !== null && this.rootCauseObj.evaluationDone !== '' && this.rootCauseObj.evaluationDone !== undefined && this.rootCauseObj.evaluationDone !== 'no')
      ) {
        this._setColoursToEach_Stepper_Header("green", 4);
        localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
      } else {
        if ((this.isThirdTabHasChanges === true) || (this.isSecondTabHasChanges === true) || (this.isFirstTabHasChanges === true)) {
          localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
        } else {
          localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
        }
        this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
      }
    } else {
      this._setHeaderColourToFifthTab()
    }
  }


  _setHeaderColourToFourthTab() {
    if (
      (this.flightobj.mawbTotalpcs !== '' && this.flightobj.mawbTotalpcs !== null && this.flightobj.mawbTotalpcs !== undefined) ||
      (this.flightobj.actualReceivedPcs !== '' && this.flightobj.actualReceivedPcs !== null && this.flightobj.actualReceivedPcs !== undefined) ||
      (this.flightobj.contentNature !== '' && this.flightobj.contentNature !== null && this.flightobj.contentNature !== undefined) ||
      (this.flightobj.shipper !== '' && this.flightobj.shipper !== null && this.flightobj.shipper !== undefined) ||
      (this.flightobj.consignee !== '' && this.flightobj.consignee !== null && this.flightobj.consignee !== undefined) ||
      (this.flightobj.issuingAgent !== '' && this.flightobj.issuingAgent !== null && this.flightobj.issuingAgent !== undefined)
   
   
      ) {
      this._setColoursToEach_Stepper_Header("green", 3);

    } else {
      this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 3);
    }
  }
  _setHeaderColourToFifthTab() {
    
    if (
      (this.rootCauseObj.equipmentDesc !== null && this.rootCauseObj.equipmentDesc !== '' && this.rootCauseObj.equipmentDesc !== undefined) ||
        (this.rootCauseObj.rulesDesc !== null && this.rootCauseObj.rulesDesc !== '' && this.rootCauseObj.rulesDesc !== undefined) ||
        (this.rootCauseObj.behaviourDesc !== null && this.rootCauseObj.behaviourDesc !== '' && this.rootCauseObj.behaviourDesc !== undefined) ||
        (this.rootCauseObj.organisationDesc !== null && this.rootCauseObj.organisationDesc !== '' && this.rootCauseObj.organisationDesc !== undefined)||
        (this.rootCauseObj.capaTypeDesc !== null && this.rootCauseObj.capaTypeDesc !== '' && this.rootCauseObj.capaTypeDesc !== undefined)||
        (this.rootCauseObj.rootCauseInvestigation !== null && this.rootCauseObj.rootCauseInvestigation !== '' && this.rootCauseObj.rootCauseInvestigation !== undefined)||
        (this.rootCauseObj.correctiveActions !== null && this.rootCauseObj.correctiveActions !== '' && this.rootCauseObj.correctiveActions !== undefined)||
        (this.rootCauseObj.evaluationDone !== null && this.rootCauseObj.evaluationDone !== '' && this.rootCauseObj.evaluationDone !== undefined && this.rootCauseObj.evaluationDone !== 'no')
      ) {
      this._setColoursToEach_Stepper_Header("green", 4);
    } else {
      this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 4);
    }
  }


  

  goForward(stepper: MatStepper, tab, presentTab) {  //----> fun calls on next button clicks and validates current tab and shows messages incase any field is required 

    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != undefined && this.loginuserdetailsTest != '') {

      this.apiCallCurrentTab = tab;
      if (tab === 'first') {
        this.isFromNextButton = true;
        if (this.iS_firtTab_Valid("apiValidation")) {
          this.stepFilled = true;
          this.status1 = !this.status1;
          stepper.next()
          this.apiCallCurrentTab = presentTab
        }
      }
      else if (tab === 'second') {
        this.isFromNextButton = true;
        if (
          (this.aspectsobj.methodPacking != '' && this.aspectsobj.methodPacking != null && this.aspectsobj.methodPacking != undefined) ||
          (this.aspectsobj.identificationNo != null && this.aspectsobj.identificationNo != '' && this.aspectsobj.identificationNo != undefined) ||
          ((this.only_scratches_tears_dents === 'no' || this.only_scratches_tears_dents === null || this.only_scratches_tears_dents === '' || this.only_scratches_tears_dents === undefined) && (this.aspectsobj.damagePacking != null && this.aspectsobj.damagePacking != '' && this.aspectsobj.damagePacking != undefined)) ||
          (this.aspectsobj.conditioncontent != null && this.aspectsobj.conditioncontent != '' && this.aspectsobj.conditioncontent != undefined) ||
          (this.aspectsobj.contentAffected != null && this.aspectsobj.contentAffected != '' && this.aspectsobj.contentAffected != undefined) ||
          (this.aspectsobj.contentPieces != null && this.aspectsobj.contentPieces != '' && this.aspectsobj.contentPieces != undefined) ||
          (this.aspectsobj.itemWeight != null && this.aspectsobj.itemWeight != '' && this.aspectsobj.itemWeight != undefined) ||
          (this.aspectsobj.itemMissing != null && this.aspectsobj.itemMissing != '' && this.aspectsobj.itemMissing != undefined) ||
          (this.aspectsobj.itemPcs != null && this.aspectsobj.itemPcs != '' && this.aspectsobj.itemPcs != undefined) ||
          (this.aspectsobj.suspectPilf != null && this.aspectsobj.suspectPilf != '' && this.aspectsobj.suspectPilf != undefined) ||
          (this.aspectsobj.safetyLife != null && this.aspectsobj.safetyLife != '' && this.aspectsobj.safetyLife != undefined) ||
          (this.aspectsobj.recuperationReq != null && this.aspectsobj.recuperationReq != '' && this.aspectsobj.recuperationReq != undefined) ||
          (this.aspectsobj.recuperatoinStatus != null && this.aspectsobj.recuperatoinStatus != '' && this.aspectsobj.recuperatoinStatus != undefined) ||
          (this.aspectsobj.safetyLife != null && this.aspectsobj.safetyLife != '' && this.aspectsobj.safetyLife != undefined) ||
          (this.aspectsobj.dentsOrTears != null && this.aspectsobj.dentsOrTears != '' && this.aspectsobj.dentsOrTears != undefined) ||
          this.moreAwbValidationCheck() ||
          this.damagePackingValidationCheck()
        ) {
          if (this.is_SecondTab_Valid("apiValidation")) {

            stepper.next()
            this.apiCallCurrentTab = presentTab
          }
        } else {
          stepper.next()
        }
      }
      else if (tab === 'third') {
        this.isFromNextButton = true;
        if (
          (this.assessmentobj.discoveresCaused != '' && this.assessmentobj.discoveresCaused != null && this.assessmentobj.discoveresCaused != undefined) ||
          (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) ||
          (this.assessmentobj.internalGroup != null && this.assessmentobj.internalGroup != '' && this.assessmentobj.internalGroup != undefined) ||
          (this.assessmentobj.damageDiscovered != null && this.assessmentobj.damageDiscovered != '' && this.assessmentobj.damageDiscovered != undefined) ||
          (this.damageDiscoveredstore != null && this.damageDiscoveredstore != '' && this.damageDiscoveredstore != undefined) ||
          (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) ||
          (this.assessmentobj.internalResponsibility != null && this.assessmentobj.internalResponsibility != '' && this.assessmentobj.internalResponsibility != undefined) ||
          (this.assessmentobj.internalArea != null && this.assessmentobj.internalArea != '' && this.assessmentobj.internalArea != undefined) ||
          (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) ||
          (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) ||
          (this.assessmentobj.areaSupervisor != null && this.assessmentobj.areaSupervisor != '' && this.assessmentobj.areaSupervisor != undefined) ||
          (this.assessmentobj.exactLocation != null && this.assessmentobj.exactLocation != '' && this.assessmentobj.exactLocation != undefined) ||
          (this.assessmentobj.casueOfEvent != null && this.assessmentobj.casueOfEvent != '' && this.assessmentobj.casueOfEvent != undefined) ||
          (this.rootCauseObj.equipmentDesc != null && this.rootCauseObj.equipmentDesc != '' && this.rootCauseObj.equipmentDesc != undefined) ||
          (this.rootCauseObj.rulesDesc != null && this.rootCauseObj.rulesDesc != '' && this.rootCauseObj.rulesDesc != undefined) ||
          (this.rootCauseObj.behaviourDesc != null && this.rootCauseObj.behaviourDesc != '' && this.rootCauseObj.behaviourDesc != undefined) ||
          (this.rootCauseObj.organisationDesc != null && this.rootCauseObj.organisationDesc != '' && this.rootCauseObj.organisationDesc != undefined)
        ) {
          if (this.is_ThirdTab_Valid("apiValidation")) {

            stepper.next()
            this.apiCallCurrentTab = presentTab
          }
        } else {
          stepper.next()
        }
      }
    } else {
      stepper.next()
    }

  }

  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number) {  // set header icon and text colours  based on fields fill
    const test = Array.from(document.getElementsByClassName('mat-step-icon'));
   
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  fn_toRemoveUnmatchedfeildType() { // funtion to check pattern of input ngmodel variables

    if (this.shipmentobj.orgName !== null && this.shipmentobj.orgName !== '' && this.shipmentobj.orgName !== undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.stationCode !== null && this.shipmentobj.stationCode !== '' && this.shipmentobj.stationCode !== undefined) {
      this.shipmentobj.stationCode = this.shipmentobj.stationCode.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.orgName !== null && this.shipmentobj.orgName !== '' && this.shipmentobj.orgName !== undefined) {
      this.shipmentobj.orgName = this.shipmentobj.orgName.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, '');
    }
    if (this.shipmentobj.reportPreparedName !== null && this.shipmentobj.reportPreparedName !== '' && this.shipmentobj.reportPreparedName !== undefined) {
      this.shipmentobj.reportPreparedName = this.shipmentobj.reportPreparedName.replace(/[^a-zA-Z]*/g, '');
    }
    if (this.shipmentobj.reportPreparedFunction !== null && this.shipmentobj.reportPreparedFunction !== '' && this.shipmentobj.reportPreparedFunction !== undefined) {
      this.shipmentobj.reportPreparedFunction = this.shipmentobj.reportPreparedFunction.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.masterAwbNo1 !== '' && this.masterAwbNo1 !== null && this.masterAwbNo1 !== undefined) {
      this.masterAwbNo1 = this.masterAwbNo1.toString().replace(/[^0-9\s]*/g, '');
    }
    if (this.airwaybillobj.masterAwbNo !== '' && this.airwaybillobj.masterAwbNo !== null && this.airwaybillobj.masterAwbNo !== undefined) {
      this.airwaybillobj.masterAwbNo = this.airwaybillobj.masterAwbNo.toString().replace(/[^0-9]*/g, '');
    }
    if (this.flight2 !== '' && this.flight2 !== null && this.flight2 !== undefined) {
      this.flight2 = this.flight2.replace(/[^0-9A-Za-za-za-z]*/g, '');
    }
    if (this.airlineId !== null && this.airlineId !== '' && this.airlineId !== undefined) {
      this.airlineId = this.airlineId.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.originAirportId !== null && this.originAirportId !== '' && this.originAirportId !== undefined) {
      this.originAirportId = this.originAirportId.replace(/[^a-zA-Z\-\s]*/g, '');
    }
    if (this.destairportId !== null && this.destairportId !== '' && this.destairportId !== undefined) {
      this.destairportId = this.destairportId.replace(/[^a-zA-Z\-\s]*/g, '')
    }
    if (this.flightobj.mawbTotalpcs !== null && this.flightobj.mawbTotalpcs !== '' && this.flightobj.mawbTotalpcs !== undefined) {
      this.flightobj.mawbTotalpcs = this.flightobj.mawbTotalpcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.flightobj.actualReceivedPcs !== null && this.flightobj.actualReceivedPcs !== '' && this.flightobj.actualReceivedPcs !== undefined) {
      this.flightobj.actualReceivedPcs = this.flightobj.actualReceivedPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.othermethodpacking !== null && this.othermethodpacking !== '' && this.othermethodpacking !== undefined) {
      this.othermethodpacking = this.othermethodpacking.replace(/[^a-zA-Z\s]*/g, '');
    }
    if (this.otherdamagepacking !== null && this.otherdamagepacking !== '' && this.otherdamagepacking !== undefined) {
      this.otherdamagepacking = this.otherdamagepacking.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.aspectsobj.contentPieces !== null && this.aspectsobj.contentPieces !== '' && this.aspectsobj.contentPieces !== undefined) {
      this.aspectsobj.contentPieces = this.aspectsobj.contentPieces.toString().replace(/[^0-9]*/g, '');
    }
    if (this.aspectsobj.itemWeight !== null && this.aspectsobj.itemWeight !== '' && this.aspectsobj.itemWeight !== undefined) {
      this.aspectsobj.itemWeight = this.aspectsobj.itemWeight.toString().replace(/[^0-9\.]*/g, '');
    }
    if (this.aspectsobj.itemPcs !== null && this.aspectsobj.itemPcs !== '' && this.aspectsobj.itemPcs !== undefined) {
      this.aspectsobj.itemPcs = this.aspectsobj.itemPcs.toString().replace(/[^0-9]*/g, '');
    }
    if (this.affectedAwbarray.length !== 0) {
      for (let i = 0; i < this.affectedAwbarray.length; i++) {
        if (this.affectedAwbarray[i].awb !== '' && this.affectedAwbarray[i].awb !== null && this.affectedAwbarray[i].awb !== undefined) {
          this.affectedAwbarray[i].awb = this.affectedAwbarray[i].awb.replace(/[^a-zA-Z0-9\-\s]*/g, '');
        }
      }
    }
    if (this.assessmentobj.internalName !== null && this.assessmentobj.internalName !== '' && this.assessmentobj.internalName !== undefined) {
      this.assessmentobj.internalName = this.assessmentobj.internalName.replace(/[^a-zA-Z\.\s]*/g, '');
    }
    if (this.otherprocessstage !== null && this.otherprocessstage !== '' && this.otherprocessstage !== undefined) {
      this.otherprocessstage = this.otherprocessstage.replace(/[^a-zA-Z\s]*/g, '');
    }

    if (this.internalAreastore !== null && this.internalAreastore !== '' && this.internalAreastore !== undefined) {
      this.internalAreastore = this.internalAreastore.replace(/[^a-zA-Z]*/g, '');
    }

    if (this.otherprocessarea !== null && this.otherprocessarea !== '' && this.otherprocessarea !== undefined) {
      this.otherprocessarea = this.otherprocessarea.replace(/[^a-zA-Z\s]*/g, '');
    }
  }

  tabModelVAlidationCheck(tab) {  // fun which checks tab valid or not .if valid enables other tabs ,if not disable movement to other tabs

    if (this.loginuserdetailsTest != null && this.loginuserdetailsTest != '' && this.loginuserdetailsTest != undefined) {
      this.apiCallCurrentTab = tab  // variable used when leaving page
      this.fn_toRemoveUnmatchedfeildType();
      this.isFromNextButton = false;
      if (tab === 'firstTab') {
        if (
          (this.airwaybillobj.masterAwbNo !== '' && this.airwaybillobj.masterAwbNo !== null && this.airwaybillobj.masterAwbNo !== undefined) ||
          (this.masterAwbNo1 !== null && this.masterAwbNo1 !== '' && this.masterAwbNo1 !== undefined) ||
          (this.flight1 !== null && this.flight1 !== '' && this.flight1 !== undefined) ||
          (this.flight2 !== null && this.flight2 !== '' && this.flight2 !== undefined) ||
          (this.airlineId !== null && this.airlineId !== '' && this.airlineId !== undefined) ||
          (this.originAirportId !== null && this.originAirportId !== '' && this.originAirportId !== undefined) ||
          (this.destairportId !== null && this.destairportId !== '' && this.destairportId !== undefined) || 
          (this.airwaybillobj.flightIssuingAgent !== '' && this.airwaybillobj.flightIssuingAgent !== null && this.airwaybillobj.flightIssuingAgent !== undefined) 
        ) {
          this.isFirstTabHasChanges = true;
          if (this.iS_firtTab_Valid("ngModelValidation")) {
            this.secondTB_COmp = true;
            this.firstTB_COmp = true;
            this.thirdTB_COmp = true;
            this.fourthTB_COmp = true;
            this.fifthTB_COmp = true;
            this.sixthTB_COmp = true;
          } else {
            this.secondTB_COmp = false;
            this.firstTB_COmp = false;
            this.thirdTB_COmp = false;
            this.fourthTB_COmp = false;
            this.fifthTB_COmp = false;
            this.sixthTB_COmp = false;
          }
        }
      } else if (tab === 'secondTab') {

        if (
          (this.aspectsobj.methodPacking !== '' && this.aspectsobj.methodPacking !== null && this.aspectsobj.methodPacking !== undefined) ||
          (this.aspectsobj.identificationNo !== null && this.aspectsobj.identificationNo !== '' && this.aspectsobj.identificationNo !== undefined) ||
          ((this.only_scratches_tears_dents === 'no' || this.only_scratches_tears_dents === null || this.only_scratches_tears_dents === '' || this.only_scratches_tears_dents === undefined) && (this.aspectsobj.damagePacking !== null && this.aspectsobj.damagePacking !== '' && this.aspectsobj.damagePacking !== undefined)) ||
          (this.aspectsobj.conditioncontent !== null && this.aspectsobj.conditioncontent !== '' && this.aspectsobj.conditioncontent !== undefined) ||
          (this.aspectsobj.contentAffected !== null && this.aspectsobj.contentAffected !== '' && this.aspectsobj.contentAffected !== undefined) ||
          (this.aspectsobj.contentPieces !== null && this.aspectsobj.contentPieces !== '' && this.aspectsobj.contentPieces !== undefined) ||
          (this.aspectsobj.itemWeight !== null && this.aspectsobj.itemWeight !== '' && this.aspectsobj.itemWeight !== undefined) ||
          (this.aspectsobj.itemMissing !== null && this.aspectsobj.itemMissing !== '' && this.aspectsobj.itemMissing !== undefined) ||
          (this.aspectsobj.itemPcs !== null && this.aspectsobj.itemPcs !== '' && this.aspectsobj.itemPcs !== undefined) ||
          (this.aspectsobj.suspectPilf !== null && this.aspectsobj.suspectPilf !== '' && this.aspectsobj.suspectPilf !== undefined) ||


          (this.aspectsobj.recuperationReq !== null && this.aspectsobj.recuperationReq !== '' && this.aspectsobj.recuperationReq !== undefined) ||
          (this.aspectsobj.recuperatoinStatus !== null && this.aspectsobj.recuperatoinStatus !== '' && this.aspectsobj.recuperatoinStatus !== undefined) ||
          (this.aspectsobj.safetyLife !== null && this.aspectsobj.safetyLife !== '' && this.aspectsobj.safetyLife !== undefined) ||
          (this.aspectsobj.dentsOrTears !== null && this.aspectsobj.dentsOrTears !== '' && this.aspectsobj.dentsOrTears !== undefined) ||
          this.moreAwbValidationCheck() ||
          this.damagePackingValidationCheck()
        ) {
          this.isSecondTabHasChanges = true;
          if (this.is_SecondTab_Valid("ngModelValidation")) {
            this.secondTB_COmp = true;
            this.firstTB_COmp = true;
            this.thirdTB_COmp = true;
            this.fourthTB_COmp = true;
            this.fifthTB_COmp = true;
            this.sixthTB_COmp = true;
          } else {
            this.secondTB_COmp = false;
            this.firstTB_COmp = false;
            this.thirdTB_COmp = false;
            this.fourthTB_COmp = false;
            this.fifthTB_COmp = false;
            this.sixthTB_COmp = false;
          }
        } else {
          this.secondTB_COmp = true;
          this.firstTB_COmp = true;
          this.thirdTB_COmp = true;
          this.fourthTB_COmp = true;
          this.fifthTB_COmp = true;
          this.sixthTB_COmp = true;
        }
      } else if (tab === 'thirdTab') {
        this.rootCauseObj.rootCauseInvestigation = this.assessmentobj.casueOfEvent;
        if (
          (this.assessmentobj.discoveresCaused != '' && this.assessmentobj.discoveresCaused != null && this.assessmentobj.discoveresCaused != undefined) ||
          (this.assessmentobj.internalName != null && this.assessmentobj.internalName != '' && this.assessmentobj.internalName != undefined) ||
          (this.assessmentobj.internalGroup != null && this.assessmentobj.internalGroup != '' && this.assessmentobj.internalGroup != undefined) ||
          (this.assessmentobj.damageDiscovered != null && this.assessmentobj.damageDiscovered != '' && this.assessmentobj.damageDiscovered != undefined) ||
          (this.damageDiscoveredstore != null && this.damageDiscoveredstore != '' && this.damageDiscoveredstore != undefined) ||
          (this.otherprocessstage != null && this.otherprocessstage != '' && this.otherprocessstage != undefined) ||
          (this.assessmentobj.internalResponsibility != null && this.assessmentobj.internalResponsibility != '' && this.assessmentobj.internalResponsibility != undefined) ||
          (this.assessmentobj.internalArea != null && this.assessmentobj.internalArea != '' && this.assessmentobj.internalArea != undefined) ||
          (this.internalAreastore != null && this.internalAreastore != '' && this.internalAreastore != undefined) ||
          (this.otherprocessarea != null && this.otherprocessarea != '' && this.otherprocessarea != undefined) ||
          (this.assessmentobj.areaSupervisor != null && this.assessmentobj.areaSupervisor != '' && this.assessmentobj.areaSupervisor != undefined) ||
          (this.assessmentobj.exactLocation != null && this.assessmentobj.exactLocation != '' && this.assessmentobj.exactLocation != undefined) ||
          (this.assessmentobj.casueOfEvent != null && this.assessmentobj.casueOfEvent != '' && this.assessmentobj.casueOfEvent != undefined) 
        
        ) {
          this.isThirdTabHasChanges = true;
          if (this.is_ThirdTab_Valid("ngModelValidation")) {
            this.secondTB_COmp = true;
            this.firstTB_COmp = true;
            this.thirdTB_COmp = true;
            this.fourthTB_COmp = true;
            this.fifthTB_COmp = true;
            this.sixthTB_COmp = true;
          } else {
            this.secondTB_COmp = false;
            this.firstTB_COmp = false;
            this.thirdTB_COmp = false;
            this.fourthTB_COmp = false;
            this.fifthTB_COmp = false;
            this.sixthTB_COmp = false;
          }
        } else {
          this.secondTB_COmp = true;
          this.firstTB_COmp = true;
          this.thirdTB_COmp = true;
          this.fourthTB_COmp = true;
          this.fifthTB_COmp = true;
          this.sixthTB_COmp = true;
        }
      }
    }
  }

  moreAwbValidationCheck(): boolean { 
    let isExist = this.affectedAwbarray.some((element) => {
      return (element.awb !== "" && element.awb !== null && element.awb !== undefined);
    });
    return isExist;
  }

  damagePackingValidationCheck(): boolean {
    let isExist = this.damagepackingarray.some((element) => {
      return (element.checked === true);
    });
    return isExist;
  }

  _isImagesSelected() { // func checks images selected or not and sets header icon and text colours based on images selection for both edit and create
    if (this.is_From === 'EDIT_REPORT') {
      if (this.imageslist.length !== 0 || this.damage_Report_Images.length !== 0) {
        this._setColoursToEach_Stepper_Header("green", (this.isExtraTabEnable ? 5: 4))
      } else {
        // this._setColoursToEach_Stepper_Header("rgb(242 242 242)", (this.isExtraTabEnable ? 5: 4));
        this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 5);
      }
    } else {
      if (this.imageslist.length !== 0) {
        this._setColoursToEach_Stepper_Header("green",  (this.isExtraTabEnable ? 5: 4))
      } else {
        this._setColoursToEach_Stepper_Header("rgb(242 242 242)", (this.isExtraTabEnable ? 5: 4));
      }
    }

  }


  onClickRememberMe(event) { //---- > used in without login 
    (event.srcElement.checked) ? (this.rememberMeStatus = true) : (this.rememberMeStatus = false)
  }


  ngOnDestroy() {
    this.spinner.hide();
    localStorage.removeItem('conditioncontentarrayL');
    localStorage.removeItem('damagepackingarrayL');
    localStorage.removeItem('recuparationstatusarrayL');
    let saveDone = JSON.parse(localStorage.getItem("isSaveHitDOne"));
    SaveReportsComponent._imagesArrayTotal = []
  }
}


