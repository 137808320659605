import { AbstractControl } from "@angular/forms";
export class ConfirmPasswordValidator {
  static MatchPassword(AC: AbstractControl) {
    let password = AC.get("NewPassword").value;
    if (AC.get("ConfirmPassword").touched || AC.get("ConfirmPassword").dirty) {
      let verifyPassword = AC.get("ConfirmPassword").value;

      if (password != verifyPassword) {
        AC.get("ConfirmPassword").setErrors({ ConfirmPassword: true });
      } else {
        return null;
      }
    }
  }
}
