import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MyAccountService } from './services/myAccount/my-account.service';
import { NavbarComponent } from './layouts/navbar/navbar.component';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  constructor( private navbarComponent: NavbarComponent,) {

  }
  _userActionOccured: Subject<void> = new Subject();
  loginresultobject: any;
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };

  notifyUserAction() {
    this._userActionOccured.next();
  }

  loginUser() {
   
  }

  logOutUser() {
    this.loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
    if (this.loginresultobject != null && this.loginresultobject != undefined && this.loginresultobject != '') {
      this.navbarComponent.logOut();
    }
  }

}