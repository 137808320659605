import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { ConfigServiceService } from '../../config-service.service';



@Injectable({
  providedIn: 'root'
})
export class AdminService {
  webapi:string ;
  constructor(private http: HttpClient,config : ConfigServiceService) { 
    this.webapi =    config.getConfig().URL
  }


  service_To_Get_AdminDashBoard_Data(emailId,userType,orgType){
    return this.http.get<any>(this.webapi+ "adminDashboardCclpPortal.php/getHandlerAdminDashboard?emailId="+emailId +"&userType="+ userType+"&orgType="+orgType)
   
  }

  setholdTime(obj) {
    ;
    return this.http.post<any>(this.webapi + 'checkListSave.php/setHoldTime', obj)
}
sendMultipleReportEmail(obj) {
  ;
  console.log(obj,'obj')
  return this.http.post<any>(this.webapi + 'sendEmails.php/sendEmails', obj)
}




//---------------airline admin services-------------//

service_To_Get_Airline_AdminDashBoard_Data(emailId,userType,orgType){
  return this.http.get<any>(this.webapi+ "adminDashboardCclpPortal.php/getAirlineAdminDashboard?emailId="+emailId +"&userType="+ userType+"&orgType="+orgType)
 
}

}
