import { Component, ElementRef, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ShipmentsService } from "src/app/services/shipments/shipments.service";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { ConfigServiceService } from "../../config-service.service";
import { airwaybillinfo } from "src/app/save-reports/airwaybillinfo";
import { DatepickerOptions } from "ng2-datepicker";
import * as enLocale from "date-fns/locale/en";
import {RootCauseModel} from 'src/app/save-reports/rootCauseModel'
import { Workorder } from "./workorder";
import { CreatereportService } from "src/app/services/createreport.service";
import { DatePipe } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: "app-create-shipment",
  templateUrl: "./create-shipment.component.html",
  styleUrls: ["./create-shipment.component.css"],
})
export class CreateShipmentComponent implements OnInit {
  createShipmentForm: FormGroup;
  orgDetails: any;
  workorderobj = new Workorder();
  isUnAuthReportAccess: boolean;
  isFirstTabCompleted: boolean = false;
  firstFormGroup: any;
  secondFormGroup: any;
  flight2: any;
  airlineId: any;
  thirdFormGroup: any;
  isLinear = true;
  checkedT_d: boolean;
  shipment_TotalData_ToUpdate: any;
  old_Images: any = [];
  deleted_Old_Images: any = [];
  webapi;
  formSubmissionType: any;
  mawb_Num_SuffixModel: any = "";
  flight1: any;
  showitemssq: boolean;
  items3: any;
  isMawb_ReadOnly: boolean;
  masterAwbNo1 = '';
  show_behaviour_Other: boolean;
  behaviourpresent: boolean;
  rootCauseObj = new RootCauseModel();



  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "DD-MM-YYYY",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,

    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  behaviour_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
  rules_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
  equipments_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
 organisation_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
 capaType_Array: [{ category: string, description: string, id: number, status: string, checked: boolean }]
  showairline: boolean;
  showagent: boolean;
  airlinedata: any;
  airportdata: any;
  itemsair: any;
  showListair: boolean;
  activitydata: any;
  airwaybilldata: any[];
  airAgentName: any;
  otherBehaviour_Model: any;
  behaviour_ArrayDuplicate: any[];
  finalairwaybillarray: any[];
  validateairwaybill: boolean;
  awbobj: any;
  updatedelarray: any[];

  constructor(
    private formBuilder: FormBuilder,
    private shipmentServc: ShipmentsService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private toastr: ToastrService,
    private ViewReportsData: ViewReportsData,
    private el: ElementRef,
    private config: ConfigServiceService,
    private DamageReportServicel: CreatereportService,
    private datePipe: DatePipe
  ) {
    this.webapi = this.config.getConfig().URL;
  }

  ngOnInit() {
    this.getairlinedata();
    this.updatedelarray = [];
    this.showairline = true;
    this.showagent = true;
    this.showListair = false;
    this.showitemssq = false;
    this.airwaybilldata = [{
       "masterAwbNo": '',
       "masterAwbNo1": ''
    }];
    this.createShipmentForm = this.createFormGroup(); // create form group
    this.orgDetails = JSON.parse(localStorage.getItem("loginresultobject"));
    this.isFirstTabCompleted = false;
    this.firstFormGroup;
    this.secondFormGroup;
    this.formSubmissionType = this.ViewReportsData.shipmentIsFrom;
    if (this.ViewReportsData.shipmentIsFrom === "Edit") {
      this.fn_ToGet_ShipmentData();
    }
  }

  addairwaybill(){
    ;
    if (this.ViewReportsData.shipmentIsFrom === "Edit") {
      this.awbobj = {
        "id": '',
        "masterAwbNo": '',
        "masterAwbNo1": '',
        "status":"A"
      }
    }else{
      this.awbobj = {
        "masterAwbNo": '',
        "masterAwbNo1": ''
      }
    }
    
    this.airwaybilldata.push(this.awbobj);
  }
  closeairwaybill(obj,i){
    
    if (this.ViewReportsData.shipmentIsFrom === "Edit") {
      if(obj.id !== ''){
        this.updatedelarray.push(this.airwaybilldata[i]);
      }
      
      this.airwaybilldata.splice(i, 1);
    }else{
      this.airwaybilldata.splice(i, 1);
    }
  }

  getairlinedata(){
    this.spinner.show();
    this.DamageReportServicel.getTruckAirlineDetailsService().subscribe(
      data => {
        this.spinner.hide();
        if (data.responseCode === 1) {
          this.airlinedata = data.airline_details;
          localStorage.setItem('airlinedata', JSON.stringify(data.airline_details));
         

        } else {
        }
      },
      error => {
        this.spinner.hide();
      });
    
      // activity data
      this.DamageReportServicel.getActivityService().subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode === 1) {
            this.activitydata = data.activity;
            localStorage.setItem('activitydata', JSON.stringify(data.activity));
           
            this.activitydata.forEach(element => {
              element.checked = false
             });
          } else {
          }
        },
        error => {
          this.spinner.hide();
        });
  }

  initializeItems() {
    this.itemsair = JSON.parse(localStorage.getItem('airlinedata'));
    // this.items = JSON.parse(localStorage.getItem('airlinedata'));
    this.items3 = JSON.parse(localStorage.getItem('airlinedata'));
    // this.items = JSON.parse(localStorage.getItem('flightshipmentdata'));
  }

  getItemsair(ev: any) {

    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initializeItems();
      this.itemsair = this.itemsair.filter((item) => item.carrierName.toLowerCase().startsWith(val.toLowerCase()));

      // Show the results
      this.showListair = true;

      
    } else {

      // hide the results when the query is empty
      this.showListair = false;
    }
  }

  getItemsairedit(ev: any) {

    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initializeItems();
      this.itemsair = this.itemsair.filter((item) => item.carrierName.toLowerCase().startsWith(val.toLowerCase()));

      // Show the results
      this.showListair = true;

      if (this.ViewReportsData.shipmentIsFrom === "Edit") {
        this.selectresultairline(this.itemsair[0]);
        this.showListair = false;
      }
    } else {

      // hide the results when the query is empty
      this.showListair = false;
    }
  }

  getitemssq(ev: any) {
    // this.isFlight_Selected_Frm_List = false;
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.initializeItems();
      this.items3 = this.items3.filter((item) => item.IATA.toLowerCase().startsWith(val.toLowerCase()));

      // Show the results
      this.showitemssq = true;
    } else {

      // hide the results when the query is empty
      this.showitemssq = false;
    }
  }

  slectflightcall(item) {

    // this.isFlight_Selected_Frm_List = true;
    this.flight1 = item.IATA;
    // this.flight0 = item.AWB_prefix;
    this.showitemssq = false;
  }

  selectresultairline(item) {
    ;
    this.workorderobj.airAgentName = item.carrierId;
    this.airAgentName = item.carrierName;
    // this.airwaybillobj.masterAwbNo = item.AWB_prefix;
    // this.airwaybillobj.airlineId = item.carrierName;
    // this.airlineId = item.carrierName;
    if ((this.flight1 === '') || (this.flight1 === undefined)) {
      this.flight1 = item.IATA;
      // this.flight0 = item.AWB_prefix;
    }
    this.showListair = false;
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      boundType: new FormControl(""),
      mawb_Num_Prefix: new FormControl(""),
      mawb_Num_Suffix: new FormControl(""),
      referenceNumber: new FormControl("", [Validators.required]),
      shiperName: new FormControl("", [Validators.required]),
    });
  }

  prefixValidate() {
    this.createShipmentForm
      .get("mawb_Num_Prefix")
      .value.replace(/[^0-9]*/g, "");
    if (this.createShipmentForm.get("mawb_Num_Prefix").value.length === 3) {
      document.getElementById("mawb_Num_Suffix").focus();
    }
  }
  suffixValidate() {
    this.createShipmentForm
      .get("mawb_Num_Suffix")
      .value.replace(/[^0-9]*/g, "");
    if (this.createShipmentForm.get("mawb_Num_Suffix").value.length === 9) {
      document.getElementById("referenceNumber").focus();
    }
  }

  onValueChanges(fieldName: string, event) {
    let trimmed;
    this.isLinear = true;
    this.isFirstTabCompleted = false;
    if (this.createShipmentForm.valid) {
      if (
        (this.createShipmentForm.get("mawb_Num_Prefix").value === "" &&
          this.createShipmentForm.get("mawb_Num_Suffix").value !== "") ||
        (this.createShipmentForm.get("mawb_Num_Prefix").value !== "" &&
          this.createShipmentForm.get("mawb_Num_Suffix").value === "") ||
        (this.createShipmentForm.get("mawb_Num_Prefix").value === undefined &&
          this.createShipmentForm.get("mawb_Num_Suffix").value !== undefined) ||
        (this.createShipmentForm.get("mawb_Num_Prefix").value !== undefined &&
          this.createShipmentForm.get("mawb_Num_Suffix").value === undefined) ||
        (this.createShipmentForm.get("mawb_Num_Prefix").value === null &&
          this.createShipmentForm.get("mawb_Num_Suffix").value !== null) ||
        (this.createShipmentForm.get("mawb_Num_Prefix").value !== null &&
          this.createShipmentForm.get("mawb_Num_Suffix").value === null)
      ) {
        this.isFirstTabCompleted = false;
        this.isLinear = true;
      } else {
        this.isFirstTabCompleted = true;
        this.isLinear = false;
      }
      this._setColoursToEach_Stepper_Header("green", 0);
    } else {
      this.isFirstTabCompleted = false;
      this.isLinear = true;
    }
    if (fieldName === "mawb_Num_Prefix") {
      trimmed = this.createShipmentForm
        .get("mawb_Num_Prefix")
        .value.replace(/[^0-9]*/g, "");
      this.createShipmentForm.get(fieldName).setValue(trimmed);
    } else if (fieldName === "mawb_Num_Suffix") {
      if (event !== undefined && event !== null) {
        this.mawb_Num_SuffixModel = event.target.value;
        let trimmed = this.mawb_Num_SuffixModel.replace(/\s+/g, "");
        if (trimmed.length > 16) {
          trimmed = trimmed.substr(0, 16);
        }
        const numbers = [];
        for (let i = 0; i < trimmed.length; i += 4) {
          numbers.push(trimmed.substr(i, 4));
        }
        this.mawb_Num_SuffixModel = numbers.join(" ");
      }
    } else if (fieldName === "referenceNumber") {
      trimmed = this.createShipmentForm
        .get("referenceNumber")
        .value.replace(/[^a-zA-Z0-9\-]*/g, "");
      this.createShipmentForm.get(fieldName).setValue(trimmed);
    } else if (fieldName === "shiperName") {
      trimmed = this.createShipmentForm
        .get("shiperName")
        .value.replace(/[^a-zA-Z\s\-]*/g, "");
      this.createShipmentForm.get(fieldName).setValue(trimmed);
    }
  }

  goForward(stepper, tab): void {
    ;
    
    if (tab === "firstTab") {
      if (this.is_firstTab_Valid('disabledCheck')) {
        if (this.isFirstTabCompleted === true) {
          stepper.next();
          this._setColoursToEach_Stepper_Header("green", 0);
        }
      } else {
        // for (const key of Object.keys(this.createShipmentForm.controls)) {
        //   if (this.createShipmentForm.controls[key].invalid) {
        //     const invalidControl = this.el.nativeElement.querySelector(
        //       '[formcontrolname="' + key + '"]'
        //     );
        //     invalidControl.focus();
        //     break;
        //   }
        // }
      }
    } else {
      stepper.next();
    }
  }

  changerequestby(val){
   ;
   this.airAgentName = '';
   this.workorderobj.airAgentName = '';
   if(val === "I"){
     this.showairline = true;
     this.showagent = false;
   }else{
    this.showairline = false;
    this.showagent = true;
   }
  }

  checkairwaybillvalidation(){
    this.validateairwaybill = true;
    for(let i=0; i<this.airwaybilldata.length; i++){
       if(this.airwaybilldata[i].masterAwbNo === '' || this.airwaybilldata[i].masterAwbNo1 === '' || this.airwaybilldata[i].masterAwbNo1.length !== 9){
        this.validateairwaybill = false;
       }
    }
    if(this.validateairwaybill === true){
      return false;
    }else{
      return true;
    }
  }
  is_firstTab_Valid(type){
    this.isFirstTabCompleted = false;
    this.isLinear = true;
    if(this.workorderobj.boundType === '' || this.workorderobj.boundType ===  null || this.workorderobj.boundType === undefined){
      return false;
    }else if(this.workorderobj.airAgentName === '' || this.workorderobj.airAgentName ===  null || this.workorderobj.airAgentName === undefined){
      return false;
    }else if(this.flight1 === '' || this.flight1 ===  null || this.flight1 === undefined){
      return false;
    }else if(this.flight2 === '' || this.flight2 ===  null || this.flight2 === undefined){
      return false;
    }else if(this.workorderobj.flightDate === '' || this.workorderobj.flightDate ===  null || this.workorderobj.flightDate === undefined){
      return false;
    }else if(this.checkairwaybillvalidation()){
      return false;
    }else{
      this.isFirstTabCompleted = true;
      this.isLinear = false;
      return true;
    }
  }
  onSubmitFormData(formData): void {
    ;
    // let inputObj = {
    //   boundType: formData.boundType,
    //   orgId: this.orgDetails.orgId,
    //   shiperName: formData.shiperName,
    //   awbNumber:
    //     formData.mawb_Num_Prefix + "-" + formData.mawb_Num_Suffix !== "-"
    //       ? formData.mawb_Num_Prefix + "-" + formData.mawb_Num_Suffix
    //       : "",
    //   referenceNumber: formData.referenceNumber,
    //   imagesList: [],
    // };
    if(formData.description === undefined){
      formData.description = '';
    }
    if(formData.activity === undefined){
      formData.activity = '';
    }
    if(formData.activityOther === undefined){
      formData.activityOther = '';
    }
    let inputObj = {
      boundType: formData.boundType,
      loginUserId:this.orgDetails.userId,
      orgId: this.orgDetails.orgId,
      airAgentName: formData.airAgentName.toString(),
      awbNumber:[],
      flightNumber: this.flight1 + '-' + this.flight2,
      flightDate: this.datePipe.transform(this.workorderobj.flightDate, 'yyyy-MM-dd'),
      description: formData.description,
      activity: formData.activity,
      activityOther: formData.activityOther,
      imagesList: [],
    };
    this.finalairwaybillarray = [];
    for(let i=0; i<this.airwaybilldata.length; i++){
      let airobj = {
        "f": this.airwaybilldata[i].masterAwbNo + '-' + this.airwaybilldata[i].masterAwbNo1
      }
        this.finalairwaybillarray.push(this.airwaybilldata[i].masterAwbNo + '-' + this.airwaybilldata[i].masterAwbNo1);
    }
    inputObj.awbNumber = this.finalairwaybillarray;
    if (this._imagesArrayTotal.length !== 0) {
      for (let i = 0; i < this._imagesArrayTotal.length; i++) {
        let obj = { imageURL: this._imagesArrayTotal[i] };
        inputObj.imagesList.push(obj);
      }
    } else {
      // let obj = { 'imageURL':"" }
      // inputObj.imagesList.push(obj) ;
    }
    this.spinner.show();
    this.shipmentServc.api_To_Create_Shipment_handler(inputObj).subscribe(
      (responseData) => {
        this.spinner.hide();
        if (responseData.responseCode === 1) {
          this.toastr.success(responseData.message);
          this.route.navigateByUrl("/CargoImageHandler/view-shipment");
        } else {
          this.toastr.error(responseData.message);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  //--------Images selection related------------//
  public _imagesArrayTotal = [];
  selectimages(val) {
    const self = this;
    const images = val.target.files;
    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match('image.*|application.*')) {
        this.toastr.error("Please select image and document format only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(",")[1];
        self._imagesArrayTotal.push(base64);
        self.preview(self._imagesArrayTotal);
      });
    }
    console.log(self._imagesArrayTotal, "this.imageslist");
  }

  urls: any = [];
  preview(files) {
    this.urls = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = "data:image/png;base64," + file;
        this.urls.push(image);
      }
    }
    this._isImagesSelected(); // to change color of stepper
  }

  blobToBase64(blob, callback): void {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }

  on_Cancel_Image(i: number, type: string): void {
    ;
    if (type === "NEW") {
      this.urls.splice(i, 1);
      this._imagesArrayTotal.splice(i, 1);
    } else {
      this.old_Images[i].status = 0;
      let img_split = [];
      img_split = this.old_Images[i].image_path.split("/");
      this.old_Images[i].deleteImageName = img_split[img_split.length - 1];
      this.old_Images[i].imageURL = "";
      this.deleted_Old_Images.push(this.old_Images[i]);
      this.old_Images.splice(i, 1);
    }
    this._isImagesSelected(); // to change color of stepper
  }

  _isImagesSelected(): void {
    if (this.urls.length !== 0 || this.old_Images.length !== 0) {
      this._setColoursToEach_Stepper_Header("green", 1);
    } else {
      this._setColoursToEach_Stepper_Header("rgb(242 242 242)", 1);
    }
  }

  //--------End Images selection related------------//

  _setColoursToEach_Stepper_Header(colour: string, setAtIndex: number): void {
    const test = Array.from(document.getElementsByClassName("mat-step-icon"));
    test.forEach((element: any, index) => {
      if (index === setAtIndex) {
        element.style.backgroundColor = colour;
        element.style.color = "#0a0a0a";
      }
    });
  }

  onClickT_D(event): void {
    if (event.target.checked) {
      this.checkedT_d = true;
    } else {
      this.checkedT_d = false;
    }
  }

  /// ------- Edit functionalities -------- ///
  fn_ToGet_ShipmentData(): void {
    // get data to view
    if (
      this.ViewReportsData.shipmentId !== null &&
      this.ViewReportsData.shipmentId !== undefined &&
      this.ViewReportsData.organizationId !== null &&
      this.ViewReportsData.organizationId !== undefined
    ) {
      let inputObj = {
        orgId: this.ViewReportsData.organizationId,
        shiperId: this.ViewReportsData.shipmentId,
      };
      this.spinner.show();
      this.shipmentServc.api_To_GetShipment_Details_handler(inputObj).subscribe(
        (responseData) => {
          ;
          this.spinner.hide();
          if (responseData.responseCode === 1) {
            this.isFirstTabCompleted = true;
            this.isLinear = false;
            this.shipment_TotalData_ToUpdate = responseData.shipmentDetails;
            this.old_Images = responseData.shipmentDetails.shipmentImages;
            if (this.old_Images === null || this.old_Images === undefined) {
              this.old_Images = [];
            }
            this._isImagesSelected();
            if (
              this.shipment_TotalData_ToUpdate.type !== "" &&
              this.shipment_TotalData_ToUpdate.type !== null &&
              this.shipment_TotalData_ToUpdate.type !== undefined
            ) {
              if(this.shipment_TotalData_ToUpdate.type === "Airline"){
                this.workorderobj.boundType = "I";
                let val = this.shipment_TotalData_ToUpdate.shiper_name;
                if(val !== '' && val !== null && val !== undefined){
                  this.getItemsairedit(val);
                  // this.workorderobj.airAgentName = val;
                }
                this.showairline = true;
                this.showagent = false;
              }else{
                this.workorderobj.boundType = "O";
                let val = this.shipment_TotalData_ToUpdate.shiper_name;
                if(val !== '' && val !== null && val !== undefined){
                  this.workorderobj.airAgentName = val;
                }
                this.showairline = false;
                this.showagent = true;
              }
            }
            if(this.shipment_TotalData_ToUpdate.flight_no !== '' && this.shipment_TotalData_ToUpdate.flight_no !== null){
               let data = this.shipment_TotalData_ToUpdate.flight_no.split("-");
               this.flight1 = data[0];
               this.flight2 = data[1];
            }
            if(this.shipment_TotalData_ToUpdate.flight_date !== '' && this.shipment_TotalData_ToUpdate.flight_date !== null){
              this.workorderobj.flightDate = this.shipment_TotalData_ToUpdate.flight_date;
            }
            if(this.shipment_TotalData_ToUpdate.description !== '' && this.shipment_TotalData_ToUpdate.description !== null){
              this.workorderobj.description = this.shipment_TotalData_ToUpdate.description;
            }
            if (
              this.shipment_TotalData_ToUpdate.awb_numbers !== "" &&
              this.shipment_TotalData_ToUpdate.awb_numbers !== null &&
              this.shipment_TotalData_ToUpdate.awb_numbers !== undefined
            ) {
              this.airwaybilldata = [];
              for(let i=0; i<this.shipment_TotalData_ToUpdate.awb_numbers.length; i++){
                 let splitawb = this.shipment_TotalData_ToUpdate.awb_numbers[i].awb_number.split("-");
                 this.shipment_TotalData_ToUpdate.awb_numbers[i].masterAwbNo = splitawb[0];
                 this.shipment_TotalData_ToUpdate.awb_numbers[i].masterAwbNo1 = splitawb[1];
                 this.airwaybilldata.push(this.shipment_TotalData_ToUpdate.awb_numbers[i]);
              }
              
            }
          if(this.shipment_TotalData_ToUpdate.actions !== '' && this.shipment_TotalData_ToUpdate.actions !== null && this.shipment_TotalData_ToUpdate.actions !== undefined){
            this.workorderobj.activityDesc = '';
            this.workorderobj.activity = '';
            for(let i=0; i<this.shipment_TotalData_ToUpdate.actions.length; i++){
              if (i + 1 === this.shipment_TotalData_ToUpdate.actions.length) {
                this.workorderobj.activityDesc = this.workorderobj.activityDesc + this.shipment_TotalData_ToUpdate.actions[i].activity;
                this.workorderobj.activity = this.workorderobj.activity + this.shipment_TotalData_ToUpdate.actions[i].id;
              } else {
                this.workorderobj.activityDesc = this.workorderobj.activityDesc + this.shipment_TotalData_ToUpdate.actions[i].activity + ',';
                this.workorderobj.activity = this.workorderobj.activity + this.shipment_TotalData_ToUpdate.actions[i].id + ',';
              }

              for(let j=0; j<this.activitydata.length; j++){
                if(this.activitydata[j].id === this.shipment_TotalData_ToUpdate.actions[i].id){
                  this.activitydata[j].checked = true;
                }
              }

              if(this.shipment_TotalData_ToUpdate.actions[i].activity.includes("Others")){
                this.show_behaviour_Other = true;
              }
             }
             
          }
          this.workorderobj.activityOther = this.shipment_TotalData_ToUpdate.activityOther ;
          this.otherBehaviour_Model = this.shipment_TotalData_ToUpdate.activityOther ;
            this._setColoursToEach_Stepper_Header("green", 0);
          } else {
            this.toastr.error(responseData.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.route.navigateByUrl("/CargoImageHandler/view-shipment");
    }
  }

  onUpdateFormData(formData) {
    ;
    if(formData.description === undefined){
      formData.description = '';
    }
    if(formData.activity === undefined){
      formData.activity = '';
    }
    if(formData.activityOther === undefined){
      formData.activityOther = '';
    }
    if(formData.airAgentName === undefined){
      formData.airAgentName = '';
    }
    let inputObj = {
      shiperId: this.ViewReportsData.shipmentId,
      boundType: formData.boundType,
      loginUserId:this.orgDetails.userId,
      orgId: this.orgDetails.orgId,
      airAgentName: formData.airAgentName.toString(),
      awbNumber:[],
      flightNumber: this.flight1 + '-' + this.flight2,
      flightDate: this.datePipe.transform(this.workorderobj.flightDate, 'yyyy-MM-dd'),
      description: formData.description,
      activity: formData.activity,
      activityOther: formData.activityOther,
      imagesList: [],
    };
    this.finalairwaybillarray = [];
    for(let i=0; i<this.airwaybilldata.length; i++){
      let airobj = {
        "id": this.airwaybilldata[i].id,
        "awb_number": this.airwaybilldata[i].masterAwbNo + '-' + this.airwaybilldata[i].masterAwbNo1,
        "status":this.airwaybilldata[i].status
      }
        this.finalairwaybillarray.push(airobj);
    }
    for(let i=0; i<this.updatedelarray.length; i++){
      let airobj = {
        "id": this.updatedelarray[i].id,
        "awb_number": this.updatedelarray[i].masterAwbNo + '-' + this.updatedelarray[i].masterAwbNo1,
        "status":'D'
      }
        this.finalairwaybillarray.push(airobj);
    }
    inputObj.awbNumber = this.finalairwaybillarray;
    
    if (
      this._imagesArrayTotal.length !== 0 ||
      this.deleted_Old_Images.length !== 0
    ) {
      if (this._imagesArrayTotal.length !== 0) {
        for (let i = 0; i < this._imagesArrayTotal.length; i++) {
          let obj = {
            id: null,
            status: 1,
            deleteImageName: "",
            imageURL: this._imagesArrayTotal[i],
          };
          inputObj.imagesList.push(obj);
        }
      }
      if (this.deleted_Old_Images.length !== 0) {
        for (let i = 0; i < this.deleted_Old_Images.length; i++) {
          inputObj.imagesList.push(this.deleted_Old_Images[i]);
        }
      }
    } else {
      let obj = {
        id: null,
        status: 0,
        deleteImageName: "",
        imageURL: "",
      };
      inputObj.imagesList.push(obj);
    }
    this.spinner.show();
    this.shipmentServc.api_To_Update_Shipment_handler(inputObj).subscribe(
      (responseData) => {
        this.spinner.hide();
        if (responseData.responseCode === 1) {
          this.toastr.success(responseData.message);
          this.route.navigateByUrl("/CargoImageHandler/view-shipment");
        } else {
          this.toastr.error(responseData.message);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  onCancelPage(): void {
    this.route.navigateByUrl("/CargoImageHandler/view-shipment");
  }

 

  tabModelVAlidationCheck(tab) {
    //  this.workorderobj.airAgentName = '';
  }

 

  

  masterAwbNochange(){

  }

  spacecall(event, i) {
    if (event !== undefined && event !== null) {
      this.airwaybilldata[i].masterAwbNo1 = event;
      // this.masterAwbNo1 = event;
      let trimmed =event.replace(/\s+/g, '');
      if (trimmed.length > 16) {
        trimmed = trimmed.substr(0, 16);
      }
      const numbers = [];
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
      this.airwaybilldata[i].masterAwbNo1 = numbers.join(' ');
      // this.masterAwbNo1 = numbers.join(' ');
    }

    if(this.masterAwbNo1.length === 9){
      // document.getElementById("next_ToAwb_flightnumber").focus()
    }
    // this.awbExistedCheck();
  }

  awbExistedCheck(event) {

  }

  open_BehaviourPopup() {   // new
    this.show_behaviour_Other = false;
    this.activitydata.forEach((item) => {
      if (item.checked === true) {
        this.behaviourpresent = true;
      }
      if (item.checked === true && item.action_name === 'Others') {
        this.show_behaviour_Other = true;
      }
    });
    jQuery('#activity_popup').modal({ backdrop: 'static', keyboard: false });
  }

  onBehaviourChange(obj) {
    ;
    if (obj.action_name === "Others") {
      if (obj.checked === true) {
        this.show_behaviour_Other = true;
      } else {
        this.show_behaviour_Other = false;
      }
    }
  }
  cancel_BehaviourPopup() {
    ;
    
    jQuery('#activity_popup').modal('hide');
  }

  isAnyOptionChecked(array: [{ category: string, description: string, id: number, status: string, checked: boolean }]): boolean {
    let isExist = array.some((element) => {
      return (element.checked === true);
    });
    return isExist
  }

  ok_BehaviourPopup() {
    ;
    if(this.activitydata[this.activitydata.length -1].checked){
      if(this.otherBehaviour_Model === '' ||this.otherBehaviour_Model === null || this.otherBehaviour_Model === undefined ){
        this.toastr.error("Please enter other field value") ;
       return false ;
      }
    }
    this.behaviour_ArrayDuplicate = [];
    if (this.isAnyOptionChecked(this.activitydata)) {
      this.workorderobj.activityDesc = '';
      this.workorderobj.activity = '';
      this.activitydata.forEach(element => {
        if (element.checked === true) {
          this.workorderobj.activityDesc = this.workorderobj.activityDesc + element.action_name + ','
          this.workorderobj.activity = this.workorderobj.activity + element.id + ','
          this.behaviour_ArrayDuplicate.push(Object.assign({}, element));
        }
      })


    } else {
      this.workorderobj.activityDesc = '';
      this.workorderobj.activity = '';
    }

    if (this.workorderobj.activity != '' && this.workorderobj.activity != null && this.workorderobj.activity != undefined) {
      let val = this.workorderobj.activity.charAt(this.workorderobj.activity.length - 1)
      if (val === ',') {
        this.workorderobj.activity = this.workorderobj.activity.slice(0, this.workorderobj.activity.length - 1);
      }
    }
    if (this.workorderobj.activityDesc != '' && this.workorderobj.activityDesc != null && this.workorderobj.activityDesc != undefined) {
      let val = this.workorderobj.activityDesc.charAt(this.workorderobj.activityDesc.length - 1)
      if (val === ',') {
        this.workorderobj.activityDesc = this.workorderobj.activityDesc.slice(0, this.workorderobj.activityDesc.length - 1);
      }
      if(!this.workorderobj.activityDesc.includes("Others")){
        this.workorderobj.activityOther = ''
        this.otherBehaviour_Model = ''
      }else{

        this.workorderobj.activityOther = this.otherBehaviour_Model ;
      }
    } else {
      this.workorderobj.activityOther = ''
      this.otherBehaviour_Model = ''
    }
      jQuery('#activity_popup').modal('hide');
  }

}
