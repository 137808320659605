import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  masterAwbNo1: any;
  flight1: any;
  flight2: any;
  airlineId: any;
  originAirportId: any;
  destairportId: any;
  airwaybillobj: any;
  damageDiscoveredstore: any;
  otherprocessstage: any;
  internalAreastore: any;
  otherprocessarea: any;
  assessmentobj: any;
  aspectsobj: any;
  moreAwbValidationCheck(): boolean;
  damagePackingValidationCheck(): any;
  navigationEnable: any;
  apiCallCurrentTab: string;
  iS_firtTab_Valid(arg0: string);
  is_From: string;
  _submitShortReport(arg0: string, arg1: string, arg2: string);
  update_ShortReport(arg0: string, arg1: string, arg2: string);
  is_SecondTab_Valid(arg0: string);
  is_ThirdTab_Valid(arg0: string);
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateSaveReport implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate) {
    ;
    let isNavigate = JSON.parse(localStorage.getItem("isSaveReportLeave"));
    if (isNavigate === 'no') {
      if ((!component.navigationEnable)) {
        if (
          (component.airwaybillobj.masterAwbNo !== '' && component.airwaybillobj.masterAwbNo !== null && component.airwaybillobj.masterAwbNo !== undefined) ||
          (component.masterAwbNo1 !== null && component.masterAwbNo1 !== '' && component.masterAwbNo1 !== undefined) ||
          (component.flight1 !== null && component.flight1 !== '' && component.flight1 !== undefined) ||
          (component.flight2 !== null && component.flight2 !== '' && component.flight2 !== undefined) ||
          (component.airlineId !== null && component.airlineId !== '' && component.airlineId !== undefined) ||
          (component.originAirportId !== null && component.originAirportId !== '' && component.originAirportId !== undefined) ||
          (component.destairportId !== null && component.destairportId !== '' && component.destairportId !== undefined)
        ) {
        if (confirm("Do you want to " + ((component.is_From === 'CREATE_REPORT') ? ("save") : ("update")) + " damage report information ?")) {

          if (component.apiCallCurrentTab === "firstTab" || component.apiCallCurrentTab === "first") {
            if (component.iS_firtTab_Valid("apiValidation") === false) {
              return false;
            } else {
              this.fn_ToCallApi(component);
              return true;
            }
          } else if (component.apiCallCurrentTab === "secondTab" || component.apiCallCurrentTab === "second") {

            if (
              (component.aspectsobj.methodPacking !== '' && component.aspectsobj.methodPacking !== null && component.aspectsobj.methodPacking !== undefined) ||
              (component.aspectsobj.identificationNo !== null && component.aspectsobj.identificationNo !== '' && component.aspectsobj.identificationNo !== undefined) ||
              (component.aspectsobj.damagePacking !== null && component.aspectsobj.damagePacking !== '' && component.aspectsobj.damagePacking !== undefined) ||
              (component.aspectsobj.conditioncontent !== null && component.aspectsobj.conditioncontent !== '' && component.aspectsobj.conditioncontent !== undefined) ||
              (component.aspectsobj.contentAffected !== null && component.aspectsobj.contentAffected !== '' && component.aspectsobj.contentAffected !== undefined) ||
              (component.aspectsobj.contentPieces !== null && component.aspectsobj.contentPieces !== '' && component.aspectsobj.contentPieces !== undefined) ||
              (component.aspectsobj.itemWeight !== null && component.aspectsobj.itemWeight !== '' && component.aspectsobj.itemWeight !== undefined) ||
              (component.aspectsobj.itemMissing !== null && component.aspectsobj.itemMissing !== '' && component.aspectsobj.itemMissing !== undefined) ||
              (component.aspectsobj.itemPcs !== null && component.aspectsobj.itemPcs !== '' && component.aspectsobj.itemPcs !== undefined) ||
              (component.aspectsobj.suspectPilf !== null && component.aspectsobj.suspectPilf !== '' && component.aspectsobj.suspectPilf !== undefined) ||
              (component.aspectsobj.safetyLife !== null && component.aspectsobj.safetyLife !== '' && component.aspectsobj.safetyLife !== undefined) ||
              (component.aspectsobj.recuperationReq !== null && component.aspectsobj.recuperationReq !== '' && component.aspectsobj.recuperationReq !== undefined) ||
              (component.aspectsobj.recuperatoinStatus !== null && component.aspectsobj.recuperatoinStatus !== '' && component.aspectsobj.recuperatoinStatus !== undefined) ||
              (component.aspectsobj.safetyLife !== null && component.aspectsobj.safetyLife !== '' && component.aspectsobj.safetyLife !== undefined) ||
              component.moreAwbValidationCheck() ||
              component.damagePackingValidationCheck()
            ) {
              if (component.is_SecondTab_Valid("apiValidation") === false) {
                return false;
              } else {
                this.fn_ToCallApi(component);
                return true;
              }
            } else {
              component.apiCallCurrentTab = "firstTab";
              this.fn_ToCallApi(component);
              return true;
            }

          } else if (component.apiCallCurrentTab === "thirdTab" || component.apiCallCurrentTab === "third") {

            if (
              (component.assessmentobj.discoveresCaused != '' && component.assessmentobj.discoveresCaused != null && component.assessmentobj.discoveresCaused != undefined) ||
              (component.assessmentobj.internalName != null && component.assessmentobj.internalName != '' && component.assessmentobj.internalName != undefined) ||
              (component.assessmentobj.internalGroup != null && component.assessmentobj.internalGroup != '' && component.assessmentobj.internalGroup != undefined) ||
              (component.assessmentobj.damageDiscovered != null && component.assessmentobj.damageDiscovered != '' && component.assessmentobj.damageDiscovered != undefined) ||
              (component.damageDiscoveredstore != null && component.damageDiscoveredstore != '' && component.damageDiscoveredstore != undefined) ||
              (component.otherprocessstage != null && component.otherprocessstage != '' && component.otherprocessstage != undefined) ||
              (component.assessmentobj.internalResponsibility != null && component.assessmentobj.internalResponsibility != '' && component.assessmentobj.internalResponsibility != undefined) ||
              (component.assessmentobj.internalArea != null && component.assessmentobj.internalArea != '' && component.assessmentobj.internalArea != undefined) ||
              (component.internalAreastore != null && component.internalAreastore != '' && component.internalAreastore != undefined) ||
              (component.otherprocessarea != null && component.otherprocessarea != '' && component.otherprocessarea != undefined) ||
              (component.assessmentobj.areaSupervisor != null && component.assessmentobj.areaSupervisor != '' && component.assessmentobj.areaSupervisor != undefined) ||
              (component.assessmentobj.exactLocation != null && component.assessmentobj.exactLocation != '' && component.assessmentobj.exactLocation != undefined) ||
              (component.assessmentobj.casueOfEvent != null && component.assessmentobj.casueOfEvent != '' && component.assessmentobj.casueOfEvent != undefined)

            ) {
              if (component.is_ThirdTab_Valid("apiValidation") === false) {
                return false;
              } else {
                this.fn_ToCallApi(component);
                return true;
              }
            } else {
              
              component.apiCallCurrentTab = "firstTab";
              this.fn_ToCallApi(component);
              return true;
            }
          } else {
            this.fn_ToCallApi(component);
            return true;
          }
        } else {
          return true;
        }

      }else{
        return true;
      }

      }

    } else {
      return true;
    }
  }


  fn_ToCallApi(component,) {
    let tab;
    if (component.apiCallCurrentTab === "firstTab") {
      tab = 'first'
    } else if (component.apiCallCurrentTab === "secondTab") {
      tab = 'second'
    } else if (component.apiCallCurrentTab === "thirdTab") {
      tab = 'third'
    } else {
      if (component.apiCallCurrentTab === 'first' || component.apiCallCurrentTab === 'second' || component.apiCallCurrentTab === 'third') {
        tab = component.apiCallCurrentTab
      }
    }
    if (component.is_From === 'CREATE_REPORT') {
      component._submitShortReport(tab, 'SAVEANDEXIT', 'fromDestroyHit')
    } else {
      component.update_ShortReport(tab, 'UpdateAndExit', 'fromDestroyHit')
    }
  }
}
