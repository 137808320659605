export class Workorder {
    public boundType: any;
    public loginUserId: any;
    public orgId: any;
    public airAgentName: any;
    public awbNumber: any;
    public flightNumber: any;
    public flightDate: any;
    public description: any;
    public activity: any;
    public activityOther: any;
    public activityDesc: any;
    public imagesList: any;
   
   
}
