import { Component, OnInit } from '@angular/core';
import { CreatereportService } from '../services/createreport.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginDataService } from '../login/login-data.service';
import { SaveReportsComponent } from '../save-reports/save-reports.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginInput } from '../home/loginModel';
import { ShareLoginDataService } from '../data/share-login-data.service';
import { LoginService } from '../services/login/login.service';
import { MyAccountService } from '../services/myAccount/my-account.service';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-privacypolicies',
  templateUrl: './privacypolicies.component.html',
  styleUrls: ['./privacypolicies.component.scss'],
  providers:[SaveReportsComponent,
  ForgotpasswordComponent]
})
export class PrivacypoliciesComponent implements OnInit {
//   saveReportObj: SaveReportsComponent;
VERSION_NUMBER = globalConstants.VERSION_NUMBER
  forgotEmail: any;
  loginForm: FormGroup;
  showlogin: boolean;
  showviewreports: boolean;
  loginInput = new LoginInput();
  verifyEmailForm: FormGroup;
  isShowDashBoard: boolean;
  loginDetails: any;
  rememberMeStatus: boolean;
  constructor( private DamageReportServicel: CreatereportService, private atp: AmazingTimePickerService,
    private datePipe: DatePipe, public route: Router, public toast: ToastrService,private navbarComponent: NavbarComponent,
    private spinner: NgxSpinnerService,  private LoginDataService: LoginDataService, public shareServc: ShareLoginDataService, public loginServc: LoginService,private myAccountService: MyAccountService,private saveReportObj:SaveReportsComponent) {

  
  }
  ngOnInit() {
   
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
    
      passcode: new FormControl('', [Validators.required, Validators.minLength(6),]),
    });
    this.verifyEmailForm =  new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN),]),
  

    });
const loginuserdetails = JSON.parse(localStorage.getItem('loginuserDetails'));
this.loginDetails = loginuserdetails;
if (loginuserdetails === null || loginuserdetails === undefined || loginuserdetails === '') {
  this.showlogin = true;
  this.showviewreports = false;
  this.isShowDashBoard = false;
} else {
  this.showlogin = false;
  this.showviewreports = true;
  this.isShowDashBoard = true;
}

let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
if(remmeberData !== null && remmeberData !== undefined && remmeberData !== undefined){
    this.loginForm.get("username").patchValue(remmeberData.emailId)
    this.loginForm.get("passcode").patchValue(remmeberData.pwd)
}
  }


  onDismisssPopUp() {
    this.saveReportObj.loginForm.reset();
   

  }

  fn_OnLogin(loginData){
;
loginData.rememberMeStatus = this.rememberMeStatus ;
this.saveReportObj.fn_OnLogin(loginData);


  }

  openforgotcall(){
    this.verifyEmailForm.reset();
    this.saveReportObj.openforgotcall();
  }
  loginClick(){
    this.rememberMeStatus = true;
    let remmeberData = JSON.parse(localStorage.getItem("credentialsForRememberMe"));
        if(remmeberData !== null && remmeberData !== undefined && remmeberData !== ''){
            this.loginForm.get("username").patchValue(remmeberData.emailId)
            this.loginForm.get("passcode").patchValue(remmeberData.pwd)
            if((remmeberData.emailId === '' || remmeberData.emailId === undefined || remmeberData.emailId === null)&& (remmeberData.pwd === '' || remmeberData.pwd === null || remmeberData.pwd === undefined)){
                this.loginForm.reset();
                this.rememberMeStatus = false;
            }
        }else{
            this.rememberMeStatus = false;
        }
    }
  logout() {
   
    this.navbarComponent.logOut();
  }

  sendpasswordcall( ){
    ;
    this.saveReportObj.forgotEmail = this.forgotEmail;
    this.saveReportObj.sendpasswordcall();
  }
  onCnclForgotPwdPopup(){
    this.verifyEmailForm.reset();
  }

  onClickDashBoard(){
   ;
   if (this.loginDetails.userType == 'SA') {
    this.route.navigateByUrl('/sa-dashboard');
  } else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
    this.route.navigateByUrl('/csa-dashboard');
  }
  else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
    this.route.navigateByUrl('/csa-airline-dashboard');
  }
  else if (this.loginDetails.userType == 'E') {
    this.route.navigateByUrl('/user-dashboard');
  }
  }


  onClickRememberMe(event){
    ;
   (event.srcElement.checked) ? (this.rememberMeStatus = true): (this.rememberMeStatus = false)
}

}
