import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    VERSION,
} from '@angular/material';
import { Subscription } from 'rxjs/Subscription';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ResizedEvent } from 'angular-resize-event';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform, } from 'ngx-image-cropper';

declare var jQuery: any;

@Component({
    selector: 'app-editorg',
    templateUrl: './editorg.component.html',
    styleUrls: ['./editorg.component.css']
})
export class EditorgComponent implements OnInit, AfterViewInit, OnDestroy {
    OrgDetails: any;
    editOrgForm: FormGroup;
    orgId: {};
    isStationCodeReadOnly: boolean;
    isOrgTypeReadOnly: boolean;
    states: any;
    filteredStates: Observable<any[]>;
    companyName: FormControl;
    OrgDetailsObj: any;
    handlerInput: boolean;
    airlineInput: boolean;
    subscription: Subscription;
    OrgName: string;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    errorMessageShow: boolean = false;
    editOrgObj: any;
    companydetails: string;
    CompanyLogoimgurl: string;
    OrganisationName: string;
    emailsButton: boolean;
    activationDiv: boolean;
    airlineStatus: any;
    selectedIng_BAse64Str: string = '';
    selected_image: any = [];
    isPNG: boolean = false;
    validImage: boolean=true;
    isImageChanged: boolean =false;
    nullimg: boolean = false;

    constructor(private router: Router,
        private ActivatedRoute: ActivatedRoute, private SuperadminService: SuperadminService, private toastr: ToastrService,
        private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private locationStr: LocationStrategy,) {
        this.companyName = new FormControl('', [Validators.required]);

        this.filteredStates = this.companyName.valueChanges
            .pipe(
                startWith(''),
                map(state => state ? this.filterStates(state) : this.states)
            );

        // preventing back button in browser   
        history.pushState(null, null, window.location.href);
        this.locationStr.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });

    }

    ngOnInit() {

        this.editOrgObj = JSON.parse(localStorage.getItem('editOrgObj'));
        console.log('this.editOrgObj', this.editOrgObj);
        this.activationDiv = false
        this.airlineStatus = this.editOrgObj.airlineStatus

        let pinCodePattern = "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$";
        this.emailsButton = true

        this.editOrgForm = this.formBuilder.group({
            organisationName: new FormControl(this.companyName.value),
            orgType: new FormControl('', [Validators.required]),
            firstName: new FormControl(''),
            lastName: new FormControl(''),
            emailId: new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            contactNumber: new FormControl(null, [Validators.pattern('[1-9]\\d{9}')]),
            address: new FormControl('', [Validators.required]),
            stationCode: new FormControl('', [Validators.required]),
            carrierId: new FormControl(''),
            organisationId: new FormControl(''),
            userId: new FormControl(''),
            address2: new FormControl(''),
            city: new FormControl('', [Validators.required]),
            country: new FormControl('', [Validators.required]),
            postalCode: new FormControl('', [Validators.pattern(pinCodePattern)]),
            logo: new FormControl(null),
        });

        window.scroll(0, 0);
        this.ActivatedRoute.queryParams.subscribe(
            data => {
                console.log(data);
                this.OrgDetailsObj = data
                console.log('this.OrgDetailsObj : ', this.OrgDetailsObj);
                ;
            }
        )



        this.isStationCodeReadOnly = true;
        this.isOrgTypeReadOnly = true;

        this.orgId = {
            "organisationId": this.OrgDetailsObj.orgId
        }
        this.spinner.show();
        this.selected_image = ["default"];
        this.SuperadminService.viewOrg(this.orgId).subscribe(
            data => {
                ;
                this.spinner.hide();
                if (data.responseCode === 1) {
                   
                    console.log('view organisationData => ', data);
                    if(data.organisationDetails.orgLogo === null){
                        this.nullimg = true;
                    }
                    this.editOrgForm = this.formBuilder.group({
                        organisationName: new FormControl(data.organisationDetails.orgName),
                        orgType: new FormControl(data.organisationDetails.orgType, [Validators.required]),
                        firstName: new FormControl(data.organisationDetails.firstName),
                        lastName: new FormControl(data.organisationDetails.lastName),
                        emailId: new FormControl(data.organisationDetails.emailId, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
                        contactNumber: new FormControl(data.organisationDetails.contactNumber, [Validators.pattern('[1-9]\\d{9}')]),
                        address: new FormControl(data.organisationDetails.address, [Validators.required]),
                        stationCode: new FormControl(data.organisationDetails.stationCode, [Validators.required]),
                        carrierId: new FormControl(data.organisationDetails.carrierId),
                        organisationId: new FormControl(data.organisationDetails.orgId),
                        userId: new FormControl(data.organisationDetails.userId),
                        address2: new FormControl(data.organisationDetails.address2),
                        city: new FormControl(data.organisationDetails.city, [Validators.required]),
                        country: new FormControl(data.organisationDetails.country, [Validators.required]),
                        postalCode: new FormControl(data.organisationDetails.postalCode, [Validators.pattern(pinCodePattern)]),
                    });

                    this.companyName.setValue(data.organisationDetails.orgName);
                    this.editOrgForm.setErrors(null);
                 
                        var output: any = document.getElementById('output');
                        output.src = data.organisationDetails.orgLogo;
                        // output.style.backgroundColor = (data.organisationDetails.orgLogo.toString().includes(".png") === true) ? "#2c365a" : '';
                        var inp_logo: any = document.getElementById('inp_logo');
                        inp_logo.style.color = "transparent";
                } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                    this.toastr.info("Your session is expired, please login again");
                }
            },error=>{
                this.selected_image = [];
                this.spinner.hide();
            }
        )
        console.log('selected Organiztion => ', this.OrgDetailsObj)
        if (this.OrgDetailsObj.orgType === "A") {
            this.spinner.show();
            this.SuperadminService.airlineData().subscribe(
                data => {
                    ;

                    if (data.responseCode === 1) {
                        this.states = data.airline_details;
                        console.log(this.states)
                        console.log(data)
                        this.spinner.hide();
                    } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toastr.info("Your session is expired, please login again");
                        this.spinner.hide();
                    } else {
                        this.toastr.error(data.message)
                    }

                }
            )
        } else {
            return null
        }


        console.log('%c%s', 'color: #0aefff', this.companyName.value);
        console.log('this.companyName: ', this.companyName);

        this.editOrgForm.setErrors(null);
        console.log('--------------------', this.editOrgForm)

    }






    filterStates(carrierName: string) {
        ;
        if (this.OrgDetailsObj.orgType === "H") {

        } else {
            if (this.states === undefined) {
                return null
            } else {
                this.errorMessageShow = false;
                return this.states.filter(state =>
                    state.carrierName.toLowerCase().indexOf(carrierName.toLowerCase()) === 0);
            }
        }

    }





    onEnter(state) {
        ;
        console.log(state)
        this.editOrgForm.controls['organisationName'].patchValue(state.carrierName);
        this.editOrgForm.controls['organisationName'].setErrors(null);
        this.editOrgForm.controls['carrierId'].setValue(state.carrierId);
        this.editOrgForm.updateValueAndValidity();
        console.log(this.editOrgForm)

        console.log('%c%s', 'color: #0aefff', this.companyName.value);
        console.log('this.companyName: ', this.companyName);
        this.OrgName = state.carrierName
    }
    city() {
        if (this.editOrgForm.value.city != '' && this.editOrgForm.value.city != null) {
            this.editOrgForm.controls['city'].patchValue(this.editOrgForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }
    country() {
        if (this.editOrgForm.value.country != '' && this.editOrgForm.value.country != null) {
            this.editOrgForm.controls['country'].patchValue(this.editOrgForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
    }
    fn_EdiFormSubmit(editOrgForm) {
        ;

        // if(this.validImage === false){
        //     this.toastr.error("Height and Width should meet requirements");
        //     return;
        // }
        if (this.editOrgForm.value.firstName != '' && this.editOrgForm.value.firstName != null) {
            this.editOrgForm.controls['firstName'].patchValue(this.editOrgForm.value.firstName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if (this.editOrgForm.value.lastName != '' && this.editOrgForm.value.lastName != null) {
            this.editOrgForm.controls['lastName'].patchValue(this.editOrgForm.value.lastName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if (this.editOrgForm.value.city != '' && this.editOrgForm.value.city != null) {
            this.editOrgForm.controls['city'].patchValue(this.editOrgForm.value.city.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if (this.editOrgForm.value.country != '' && this.editOrgForm.value.country != null) {
            this.editOrgForm.controls['country'].patchValue(this.editOrgForm.value.country.replace(/[^a-zA-Z\s]*/g, ''));
        }
        if (this.editOrgForm.value.address != '' && this.editOrgForm.value.address != null) {
            this.editOrgForm.controls['address'].patchValue(this.editOrgForm.value.address.replace(/[^a-zA-Z0-9\!\#\%\&\'\-\/\@\.\s]*/g, ''));
        }

        if (this.editOrgForm.value.address2 != null && this.editOrgForm.value.address2.value != '' && this.editOrgForm.value.address2.value != null) {
            this.editOrgForm.value.address2.patchValue(this.editOrgForm.value.address2.value.replace(/[^a-zA-Z0-9\!\#\%\&\'\+\-\/\@\.\s]*/g, ''));
        }


        ;
        if (editOrgForm.value.organisationName != '' && editOrgForm.value.organisationName != null) {

            this.editOrgForm.controls['organisationName'].patchValue(this.editOrgForm.value.organisationName.replace(/[^a-zA-Z\s]*/g, ''));
        }
        console.log(this.companyName.value)
        if (this.editOrgForm.pristine && this.companyName.pristine && this.selectedIng_BAse64Str === '') {
            this.toastr.error('No fields Updated');
        } else if (this.editOrgForm.controls['orgType'].value === 'A' && this.companyName.dirty && this.OrgName === undefined) {
            this.companyName.setErrors({ 'incorrect': true });
        } else if (this.editOrgForm.controls['orgType'].value === 'A' && this.OrgName === undefined) {
            console.log('editOrgForm =>', editOrgForm.value)
            this.editOrgForm.value.orgLogo = this.selectedIng_BAse64Str  === null ? '' : this.selectedIng_BAse64Str ;
            this.spinner.show();
            this.SuperadminService.editOrg(this.editOrgForm.value).subscribe(
                data => {
                    ;
                    if (data.responseCode === 1) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/sa-dashboard']);
                        this.spinner.hide();
                    } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toastr.info("Your session is expired, please login again");
                        this.spinner.hide();
                    }
                    else {
                        this.spinner.hide();
                        this.toastr.error(data.message)
                    }
                }
            )
        }
        else {
            if (this.editOrgForm.controls['orgType'].value === 'H') {
                this.editOrgForm.controls['organisationName'].patchValue(this.companyName.value.replace(/[^a-zA-Z0-9\&\@\$\.\,\:\;\-\£\$\€\¥\s]*/g, ''));
                this.editOrgForm.controls['organisationName'].setErrors(null);
            } else if (this.editOrgForm.controls['orgType'].value === 'A' && this.companyName.dirty) {
                ;

                this.editOrgForm.controls['organisationName'].patchValue(this.OrgName.replace(/[^a-zA-Z\s]*/g, ''));
                this.editOrgForm.controls['organisationName'].setErrors(null);


            } else {
                return null
            }

            console.log('editOrgForm =>', editOrgForm.value)
            this.editOrgForm.value.orgLogo = this.selectedIng_BAse64Str === null ? '' : this.selectedIng_BAse64Str;
            this.spinner.show();
            this.SuperadminService.editOrg(this.editOrgForm.value).subscribe(
                data => {
                    ;
                    if (data.responseCode === 1) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/sa-dashboard']);
                        this.spinner.hide();
                    } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toastr.info("Your session is expired, please login again");
                        this.spinner.hide();
                    }
                    else {
                        this.spinner.hide();
                        this.toastr.error(data.message)

                    }

                }
            )
        }


    }

    postalCode(event) {
        ;
        if (event.target.value.length > 8) {
            (event.target.value = event.target.value.slice(0, 9));
        } else if (event.target.value.length <= 8) {
            this.editOrgForm.controls['postalCode'].setValue(event.target.value);
            this.editOrgForm.controls['postalCode'].setErrors(null);
            this.editOrgForm.updateValueAndValidity();
        }
    }


    private _subscribeToClosingActions(): void {
        ;
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.trigger.panelClosingActions
            .subscribe(e => {
                ;
                if (!e || !e.source) {
                    if (this.OrgDetailsObj.orgType === "A") {
                        this.companyName.setValue(null);

                        this.errorMessageShow = true;
                        console.log(this.editOrgForm.controls['organisationName'].value)
                    } else if (this.OrgDetailsObj.orgType === "H") {
                        this.editOrgForm.controls['organisationName'].patchValue(this.companyName.value);
                        this.editOrgForm.controls['organisationName'].setErrors(null);
                    }
                }
            },
                err => this._subscribeToClosingActions(),
                () => this._subscribeToClosingActions());
    }

    activeAlEmailStatusPopUp() {
        jQuery('#Adddeactivationemail').modal({ backdrop: 'static', keyboard: false });
    }

    activeAlEmailStatus() {
        ;
        let obj = {
            "orgId": this.editOrgObj.orgId,
            "loginUserId": this.editOrgObj.userId
        }
        console.log('obj', obj)
        this.spinner.show();
        this.SuperadminService.activeAirlineEmailStatus(obj).subscribe(data => {
            ;
            jQuery('#Adddeactivationemail').modal('hide');
            this.spinner.hide();
            console.log('activeAirlineEmailStatus', data);
            if (data.responseCode === 1) {
                this.emailsButton = false

                this.router.navigate(['/sa-dashboard'])
                this.toastr.success(data.message, '', { timeOut: 1300 })
            } else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                this.toastr.info("Your session is expired, please login again");
            }
            else {
                this.toastr.error(data.message, '', { timeOut: 1300 })
            }
        }, error => {
            this.spinner.hide();
            this.toastr.success(error, '', { timeOut: 1300 })
        })



    }


    ngAfterViewInit() {
        this._subscribeToClosingActions();
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }


    loadFile(event) {
        ;
        this.selectedIng_BAse64Str = '';
        this.validImage = true ;
        // var reader = new FileReader();
        // reader.onload = function(){
        //   var output:any = document.getElementById('output');
        //   output.src = reader.result;
        //   var inp_logo:any = document.getElementById('inp_logo');
        //           inp_logo.style.color = "black";
        // };
        // reader.readAsDataURL(event.target.files[0]);
         
        this.selected_image = event.target.files[0];
        this.isPNG = event.target.files[0].name.toString().includes(".png");
        let self = this;
        this.blobToBase64(event.target.files[0], function (base64String) {
            const base64 = base64String.split(",")[1];
            self.selectedIng_BAse64Str = base64;
            console.log("selectedIng_BAse64StrselectedIng_BAse64StrselectedIng_BAse64Str", self.selectedIng_BAse64Str);
        });
    };

    blobToBase64(blob, callback): void {
        const reader = new FileReader();
        reader.onload = () => {

            //Initiate the JavaScript Image object.
            var image: any = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = reader.result;
            var self = this;
            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
               
                // alert("Uploaded image has valid Height and Width.");
                var output: any = document.getElementById('output');
                output.src = reader.result;
                // output.style.backgroundColor = (self.isPNG === true) ? "#2c365a" : '';
                var inp_logo: any = document.getElementById('inp_logo');
                inp_logo.style.color = "black";
                const dataUrl = reader.result;
                callback(dataUrl);
                if (height !== 56 && width !== 85) {
                    self.validImage = false ;
                    // alert("Height and Width should not exceed limit.");
                    self.toastr.error("Height and Width should meet requirements");
                    return;
                }
            };

        };
        reader.readAsDataURL(blob);
    }

    on_Cancel_Image() {
        ;
        this.selected_image = [];
   
        this.validImage = true;
        this.selectedIng_BAse64Str =null;
    }

    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        this.isImageChanged = true;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.selected_image = ["selected"];
        this.croppedImage = event.base64;
        this.selectedIng_BAse64Str = event.base64.split(",")[1];
        this.nullimg = false;
    }

    imageLoaded() {
        this.imageChangedEvent =  this.imageChangedEvent ;
        this.showCropper = true;
        jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

    
    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
