import { Location, LocationStrategy } from "@angular/common";
import { Compiler, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    FormControl
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfigServiceService } from "../../../app/config-service.service";
import { ShareLoginDataService } from "../../data/share-login-data.service";
import { NavbarComponent } from "../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../login/login-data.service";
import { LoginComponent } from "../../login/login.component";
import { LoginService } from "../../services/login/login.service";
import { MyAccountService } from "../../services/myAccount/my-account.service";
import { RegisterService } from "../../services/register/register.service";
import { SuperadminService } from "../../services/superadmin/superadmin.service";
declare var jQuery: any;
@Component({
  selector: 'app-kpicharts',
  templateUrl: './kpicharts.component.html',
  styleUrls: ['./kpicharts.component.css']
})
export class KpichartsComponent implements OnInit {
  @ViewChild('collapseExamplePrint') chartContainer: ElementRef;
    @ViewChild('buttonContainer') buttonContainer: ElementRef;

  public filteredList: any = [];
  status1: boolean = false;
  loginComponent: LoginComponent;
  isUnAuthReportAccess: boolean;
  apiCallCurrentTab: any;
  loginuserdetailsTest: any;
  items3: any;
  showitemssq: boolean;
  aspectsFilterObj:any = {};
  loginDetails: any;
  Object: any;
  isVerifyingawb: boolean = false;

  public damageReportbyAirlineChart:any;
  public reportbyAirlineLabeldata: any[] = [];
  public reportbyAirlineLabelName: any[] = [];
  public reportbyAirlineRealdata: any[] = [];

  public ResposibilityLabeldata: any[] = [];
  public ResposibilityLabelName: any[] = [];
  public ResposibilityRealdata: any[] = [];
  public damageResposibilityChart:any;

  public contentAffectedLabeldata: any[] = [];
  public contentAffectedLabelName: any[] = [];
  public contentAffectedRealdata: any[] = [];
  public contentAffectedChart:any;

  public classificationLabeldata: any[] = [];
  public classificationLabelName: any[] = [];
  public classificationRealdata: any[] = [];
  public classificationChart:any;

  public reportsbyStationLabeldata: any[] = [];
  public reportsbyStationLabelName: any[] = [];
  public reportsbyStationRealdata: any[] = [];
  public reportsbyStationChart:any;

  public damageReportsperMonthLabelsData: any[] = [];
  public damageReportsperMonthLabelsName: any[] = [];
  public damageReportsperMonthRealData: any[] = [];
  public damageReportsperMonthChart:any;

  public damageReportbyDiscoveredChart:any;
  public reportbyDiscoveredLabeldata: any[] = [];
  public reportbyDiscoveredLabelName: any[] = [];
  public reportbyDiscoveredRealdata: any[] = [];

  public damageBehaviourLabeldata: any[] = [];
  public damageBehaviourLabelName: any[] = [];
  public damageBehaviourRealdata: any[] = [];
  public damageBehaviourChart:any;

  public damageRulesLabeldata: any[] = [];
  public damageRulesLabelName: any[] = [];
  public damageRulesRealdata: any[] = [];
  public damageRulesChart:any;

  public damageEquipmentLabeldata: any[] = [];
  public damageEquipmentLabelName: any[] = [];
  public damageEquipmentRealdata: any[] = [];
  public damageEquipmentChart:any;

  public damageOrganisationLabeldata: any[] = [];
  public damageOrganisationLabelName: any[] = [];
  public damageOrganisationRealdata: any[] = [];
  public damageOrganisationChart:any;

  public damageCapaTypeLabeldata: any[] = [];
  public damageCapaTypeLabelName: any[] = [];
  public damageCapaTypeRealdata: any[] = [];
  public damageCapaTypeChart:any;

  public damageInternalAreaLabeldata: any[] = [];
  public damageInternalAreaLabelName: any[] = [];
  public damageInternalAreaRealdata: any[] = [];
  public damageInternalAreaChart:any;

  public damageConditionContentLabeldata: any[] = [];
  public damageConditionContentLabelName: any[] = [];
  public damageConditionContentRealdata: any[] = [];
  public damageConditionContentChart:any;

  public damageMethodPackingLabeldata: any[] = [];
  public damageMethodPackingLabelName: any[] = [];
  public damageMethodPackingRealdata: any[] = [];
  public damageMethodPackingChart:any;

  public damageReportsSDRperMonthLabelsData: any[] = [];
  public damageReportsSDRperMonthLabelsName: any[] = [];
  public damageReportsSDRperMonthRealData: any[] = [];
  public damageReportsSDRperMonthChart:any;

  public damageReportsUSDperMonthLabelsData: any[] = [];
  public damageReportsUSDperMonthLabelsName: any[] = [];
  public damageReportsUSDperMonthRealData: any[] = [];
  public damageReportsUSDperMonthChart:any;
  
  public totalDamageReport:any;
    selectedOption: string;
    hideSelect: boolean= false;
   


  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private atp: AmazingTimePickerService,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private myAccountService: MyAccountService,
    private SidebarComponent: SidebarComponent,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private config: ConfigServiceService
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
    
   
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.spinner.show();
    this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    this._submitChartReport('reset');
    this.spinner.hide();
    tracingDate: new FormControl("")
  }


  _submitChartReport(action) {
      if(action == 'reset'){
          this.aspectsFilterObj.RS = 'all';
          this.aspectsFilterObj.CA = 'all';
          this.aspectsFilterObj.CF = 'all';
          this.aspectsFilterObj.DRM = 'all';
          this.aspectsFilterObj.DRS = [];
          this.aspectsFilterObj.DRA = 'all';
          this.aspectsFilterObj.DRMstartDate = "" ;
          this.aspectsFilterObj.DRMendDate = "" ;

          this.hideDropdown('reset');
      }
      console.log("drs value", this.aspectsFilterObj.DRS);
      jQuery("#damageResposibilityChart").remove();
      jQuery("#damageResposibilityChartP").append('<canvas id="damageResposibilityChart">  </canvas>');
      jQuery("#contentAffectedChart").remove();
      jQuery("#contentAffectedChartP").append('<canvas id="contentAffectedChart">  </canvas>');
      jQuery("#classificationChart").remove();
      jQuery("#classificationChartP").append('<canvas id="classificationChart">  </canvas>');
      jQuery("#damageReportsperMonthChart").remove();
      jQuery("#damageReportsperMonthChartP").append('<canvas id="damageReportsperMonthChart">  </canvas>');
      jQuery("#reportsbyStationChart").remove();
      jQuery("#reportsbyStationChartP").append('<canvas id="reportsbyStationChart">  </canvas>');
      jQuery("#damageReportbyAirlineChart").remove();
      jQuery("#damageReportbyAirlineChartP").append('<canvas id="damageReportbyAirlineChart">  </canvas>');
      

      jQuery("#damageReportbyDiscoveredChart").remove();
      jQuery("#damageReportbyDiscoveredChartP").append('<canvas id="damageReportbyDiscoveredChart">  </canvas>');
      
      jQuery("#damageBehaviourChart").remove();
      jQuery("#damageBehaviourChartP").append('<canvas id="damageBehaviourChart">  </canvas>');


      jQuery("#damageRulesChart").remove();
      jQuery("#damageRulesChartP").append('<canvas id="damageRulesChart">  </canvas>');


      jQuery("#damageEquipmentChart").remove();
      jQuery("#damageEquipmentChartP").append('<canvas id="damageEquipmentChart">  </canvas>');


      jQuery("#damageCapaTypeChart").remove();
      jQuery("#damageCapaTypeChartP").append('<canvas id="damageCapaTypeChart">  </canvas>');
      

      jQuery("#damageInternalAreaChart").remove();
      jQuery("#damageInternalAreaChartP").append('<canvas id="damageInternalAreaChart">  </canvas>');


      jQuery("#damageConditionContentChart").remove();
      jQuery("#damageConditionContentChartP").append('<canvas id="damageConditionContentChart">  </canvas>');

      jQuery("#damageMethodPackingChart").remove();
      jQuery("#damageMethodPackingChartP").append('<canvas id="damageMethodPackingChart">  </canvas>');
      console.log("payload 225",this.aspectsFilterObj);
      if(this.loginDetails.orgType =='H' && this.loginDetails.userType =='E'){
          this.spinner.show();
          this.aspectsFilterObj.organisationId = this.loginDetails.orgId;
          this.aspectsFilterObj.orderType = "A"//this.orderType;
          this.aspectsFilterObj.loginUserType = this.loginDetails.userType;
          this.aspectsFilterObj.loginUserId = this.loginDetails.userId;
         
          this.superAdminservice.getUserReportInfoFilterChart(this.aspectsFilterObj).subscribe((data) => {
          if(data.responseCode == 1){
              this.eventHandler(data.data,action);
          }},(error) => {
            this.toastr.error("Report is not generated due to network issue.");
          });
          this.spinner.hide();
      }else if(this.loginDetails.orgType =='A' && this.loginDetails.userType =='E'){
          this.spinner.show();
          this.aspectsFilterObj.orgId = this.loginDetails.orgId;
          this.aspectsFilterObj.reportAccess = this.loginDetails.reportAcess;
          this.aspectsFilterObj.stationCode = this.loginDetails.stationCode;
          this.aspectsFilterObj.awbPrefix = this.loginDetails.awbPrefix;
          this.aspectsFilterObj.userId = this.loginDetails.userId;
          this.aspectsFilterObj.loginUserId = this.loginDetails.userId;
          console.log("payload 248",this.aspectsFilterObj);
          this.superAdminservice.getUserDashboardFilterChart(this.aspectsFilterObj).subscribe((data) => {
              if(data.responseCode == 1){
                  this.eventHandler(data.data,action);
              }
          },(error) => {
            this.toastr.error("Report is not generated due to network issue.");
          });
          this.spinner.hide();
      }else if(this.loginDetails.orgType =='H' && this.loginDetails.userType =='S'){
        
        this.aspectsFilterObj.organisationId = this.loginDetails.orgId;
        this.aspectsFilterObj.orderType = "A";//this.orderType;
        this.aspectsFilterObj.loginUserType = this.loginDetails.userType;
        this.aspectsFilterObj.loginUserId = this.loginDetails.userId;

        this.superAdminservice.getUserReportInfoFilterChart(this.aspectsFilterObj).subscribe((data) => {
          if(data.responseCode == 1){
            this.eventHandler(data.data,action);
          } 
        },(error) => {
          this.toastr.error("Report is not generated due to network issue.");
        });
      
      }else if(this.loginDetails.orgType =='A' && this.loginDetails.userType =='S'){
        this.aspectsFilterObj.orgId = this.loginDetails.orgId;
        this.superAdminservice.getCompanyAdminDashboardFilterChart(this.aspectsFilterObj).subscribe((data) => {
          if(data.responseCode == 1){
              this.eventHandler(data.data,action);
          }
        });
      }
    }

    eventHandler(event: any,action): void {
        this.spinner.show();
        console.log("237 event =>",event);
        this.totalDamageReport = event.TotalRows;
        let $this = this;
        if(action =='reset'){
            this.reportbyAirlineLabelName= [];
            this.ResposibilityLabelName= [];
            this.contentAffectedLabelName= [];
            this.classificationLabelName= [];
            this.reportsbyStationLabelName= [];
            this.damageReportsperMonthLabelsName= [];
        }

        // damageReportbyAirline chart code start by iwi
            this.damageReportbyAirlineChart = event.damageReportbyAirline;
            this.reportbyAirlineLabeldata= [];
            
            this.reportbyAirlineRealdata= [];
            
            if (this.damageReportbyAirlineChart != null) {
                for (let i = 0; i < this.damageReportbyAirlineChart.length; i++) {
                    this.reportbyAirlineLabeldata.push(this.damageReportbyAirlineChart[i].airline_name);
                    if(action =='reset'){
                       this.reportbyAirlineLabelName.push(this.damageReportbyAirlineChart[i].airline_name);
                    }
                    this.reportbyAirlineRealdata.push(this.damageReportbyAirlineChart[i].Count);
                }
            }
            
           
            var ReportbyAirline_New = {
                labels: this.reportbyAirlineLabeldata,
                datasets: [
                    {
                        label: "Total Damage Report",
                        data: this.reportbyAirlineRealdata,
                        backgroundColor:  "#ff7a15",
                        barThickness: 10,
                        hoverOffset: 4,
                        borderRadius: Number.MAX_VALUE
                        //hoverBackgroundColor: "#8bc7f7"  
                    }]
            };

            this.damageReportbyAirlineChart = new Chart("damageReportbyAirlineChart", {
                type: 'bar',
                data: ReportbyAirline_New,
                options:{
                    maintainAspectRatio: false,
                    scales:{
                        xAxes:[{
                           display: true,
                           
                            ticks: {
                                display: true,
                                fontSize: 10,
                                min: 0
                             },
                            
                        }],
                        yAxes: [{
                            display: true,
                            ticks:{
                                display:true
                            }
                        }]
                    },
                    legend:{
                        display:false
                    },
                    plugins: {
                        datalabels: {
                          color: 'blue',
                          labels: {
                            title: {
                              font: {
                                size: 10
                              }
                            }},
                            anchor: 'end',
                          align: 'right', 
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
                
            });
        // damageReportbyAirline chart code end by iwi


        // damageReportResposibility chart code start by iwi
            this.damageResposibilityChart = event.damageResponsibilityStatus;
            this.ResposibilityLabeldata= [];      
            this.ResposibilityRealdata= [];
            if (this.damageResposibilityChart != null) {
                for (let i = 0; i < this.damageResposibilityChart.length; i++) {
                    this.ResposibilityLabeldata.push(this.damageResposibilityChart[i].reportStatus);
                    if(action =='reset' && this.damageResposibilityChart[i].reportStatus != 'unknown'){
                       this.ResposibilityLabelName.push(this.damageResposibilityChart[i].reportStatus);
                    }
                    this.ResposibilityRealdata.push(this.damageResposibilityChart[i].Count);
                }
            }
            var damageResposibility_New = {
                labels: this.ResposibilityLabeldata,
                datasets: [  
                    {
                        label: "Total Damage Report",
                        data: this.ResposibilityRealdata,
                        backgroundColor:  [
                            '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                          ] ,
                        barThickness: 12,
                        hoverOffset: 4,
                        borderWidth:1,
                        borderRadius: Number.MAX_VALUE
                    }]
            };

            this.damageResposibilityChart = new Chart("damageResposibilityChart", {
                type: 'doughnut',
                data: damageResposibility_New,
                options:{
                    scales:{
                        xAxes:[{
                            //barThickness: 6
                            //barThickness: 12,
                            display: false,
                            ticks: {
                                display: false //this will remove only the label
                             },
                            // angleLines: {
                            //     display: false
                            // }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
                
            });
        // damageReportResposibility chart code end by iwi


        // contentAffectedChart chart code start by iwi
            this.contentAffectedChart = event.damageContentAffected;
            this.contentAffectedLabeldata= [];
            this.contentAffectedRealdata= [];
            if (this.contentAffectedChart != null) {
                for (let i = 0; i < this.contentAffectedChart.length; i++) {
                    this.contentAffectedLabeldata.push(this.contentAffectedChart[i].discoveresCaused);
                    if(action =='reset' && this.contentAffectedChart[i].discoveresCaused != 'unknown'){
                        this.contentAffectedLabelName.push(this.contentAffectedChart[i].discoveresCaused);
                    }
                    this.contentAffectedRealdata.push(this.contentAffectedChart[i].Count);
                }
            }
            var contentAffectedData = {
                labels: this.contentAffectedLabeldata,
                datasets: [
                    {
                        label: "Content Affected",
                        data: this.contentAffectedRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.contentAffectedChart = new Chart("contentAffectedChart", {
                type: 'doughnut',
                data: contentAffectedData,
                options: {
                    // indexAxis: 'y',
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // contentAffectedChart chart code end by iwi

        // classificationChart chart code start by iwi
            this.classificationChart = event.damageClassificationType;
            this.classificationLabeldata= [];
            this.classificationRealdata= [];
            if (this.classificationChart != null) {
                for (let i = 0; i < this.classificationChart.length; i++) {
                    this.classificationLabeldata.push(this.classificationChart[i].classification_type);
                    if(action =='reset'  && this.classificationChart[i].classification_type != 'unknown'){
                        this.classificationLabelName.push(this.classificationChart[i].classification_type);
                    }
                    this.classificationRealdata.push(this.classificationChart[i].Count);
                }
            }
            var classificationData_New = {
                labels: this.classificationLabeldata,
                datasets: [
                    {
                        label: "Classification",
                        data: this.classificationRealdata,
                        grouped: true,
                        backgroundColor:  [
                            '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };

            this.classificationChart = new Chart("classificationChart", {
                type: 'doughnut',
                data: classificationData_New,
                options: {
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                display: false // Edit the value according to what you need
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            stacked: true
                        }]
                    },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                      legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    onClick: this.chartClick.bind(this)
                }
            });

        // classificationChart chart code end by iwi

        // reports per month Chart code start by iwi
            this.damageReportsperMonthChart = event.damageReportEveryMonth;
            this.damageReportsperMonthLabelsData= [];
            this.damageReportsperMonthRealData= [];
            if (this.damageReportsperMonthChart != null) {
                for (let i = 0; i < this.damageReportsperMonthChart.length; i++) {
                    this.damageReportsperMonthLabelsData.push(this.damageReportsperMonthChart[i].year);
                    
                    if(action =='reset'){
                        this.damageReportsperMonthLabelsName.push(this.damageReportsperMonthChart[i].year);
                    }
                    this.damageReportsperMonthRealData.push(this.damageReportsperMonthChart[i].Count);
                }
            }
            var monthGroupData_fifth = {
                labels: this.damageReportsperMonthLabelsData,
                datasets: [
                    {
                        label: "Reports Per Month",
                        data: this.damageReportsperMonthRealData,
                        backgroundColor:  "#ceeafe" ,
                        barThickness:15,
                        // hoverBackgroundColor: "#8bc7f7",
                        fill: true
                    }]
            };
            this.damageReportsperMonthChart = new Chart("damageReportsperMonthChart", {
                type: 'bar',
                data: {
                    labels: this.damageReportsperMonthLabelsData,
                    datasets: [{
                        label: '',
                        data: this.damageReportsperMonthRealData,
                        barThickness:8,
                        backgroundColor: "#107ff7"
                    },  ],
                    // labels: ['January', 'February', 'March', 'April']
                },
                // data: monthGroupData_fifth,
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0 
                            }
                        }],
                        yAxes: [{
                            stacked: false
                        }]
                    },
                    legend: {
                      display: false

                    },
                    plugins: {
                        datalabels: {
                            color: 'blue',
                            anchor: 'end',
                            align: 'right',
                            
                          display: true
                        }
                    },
                    onClick: this.chartClick.bind(this)
                    // onClick: function (e) {
                    //     var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                    //     $this.handleChartClick("reportsByStation",activePointLabel);
                    // }
                }
            });
        // reports per month Chart code end by iwi


        // reportsby Station Chart chart code start by iwi
            this.reportsbyStationChart = event.damageReportbyStationCode;
            this.reportsbyStationLabeldata= [];
            this.reportsbyStationRealdata= [];
            if (this.reportsbyStationChart != null) {
                for (let i = 0; i < this.reportsbyStationChart.length; i++) {
                    this.reportsbyStationLabeldata.push(this.reportsbyStationChart[i].station_code);
                    if(action =='reset'){
                        this.reportsbyStationLabelName.push(this.reportsbyStationChart[i].station_code);
                    }
                    this.reportsbyStationRealdata.push(this.reportsbyStationChart[i].Count);
                }
            }
            var reportsbyStationData_fourth = {
                labels: this.reportsbyStationLabeldata,
                datasets: [
                    {
                        label: "Reports by Station",
                        data: this.reportsbyStationRealdata,
                        backgroundColor:  "#009EF7" ,
                        barThickness:15,
                        // hoverBackgroundColor: "#8bc7f7",
                        fill: true
                    }]
            };
            this.reportsbyStationChart = new Chart("reportsbyStationChart", {
                type: 'bar',
                data: reportsbyStationData_fourth,
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0 // Edit the value according to what you need
                            }
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    },
                    plugins: {
                        datalabels: {
                            color: 'blue',
                            anchor: 'end',
                             align: 'right', 
                             display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // reportsby Station Chart chart code end by iwi

        // damageReportbyDiscovered chart code start by iwi
            this.damageReportbyDiscoveredChart = event.damageReportDiscovered;
            this.reportbyDiscoveredLabeldata= [];
            this.reportbyDiscoveredRealdata= [];
            if (this.damageReportbyDiscoveredChart != null) {
                for (let i = 0; i < this.damageReportbyDiscoveredChart.length; i++) {
                    this.reportbyDiscoveredLabeldata.push(this.damageReportbyDiscoveredChart[i].damage_discovered.charAt(0).toUpperCase() + this.damageReportbyDiscoveredChart[i].damage_discovered.slice(1));
                    if(action =='reset'){
                       this.reportbyDiscoveredLabelName.push(this.damageReportbyDiscoveredChart[i].damage_discovered);
                    }
                    this.reportbyDiscoveredRealdata.push(this.damageReportbyDiscoveredChart[i].Count);
                }
            }
            var ReportbyDiscovered_New = {
                labels: this.reportbyDiscoveredLabeldata,
                datasets: [
                    {
                        label: "Total Damage Report",
                        data: this.reportbyDiscoveredRealdata,
                        backgroundColor:  "#3e70e5",
                        barThickness: 15,
                        hoverOffset: 4,
                        borderRadius: Number.MAX_VALUE
                        //hoverBackgroundColor: "#8bc7f7"
                    }]
            };

            this.damageReportbyDiscoveredChart = new Chart("damageReportbyDiscoveredChart", {
                type: 'bar',
                data: ReportbyDiscovered_New,
                options:{
                    maintainAspectRatio: false,
                    scales:{
                        xAxes:[{
                            display: true,
                            ticks: {
                                display: true,
                                min: 0
                            },
                            
                        }],
                        yAxes: [{
                            display: true,
                            ticks:{
                                display:true
                            },
                        }]
                    },
                    legend:{
                        display:false
                    },
                    plugins: {
                        datalabels: {
                          color: 'blue',
                            anchor: 'end',
            align: 'top',
                            
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
                
            });
        // damageReportbyDiscovered chart code end by iwi


        // damageBehaviourChart chart code start by iwi
            this.damageBehaviourChart = event.damageBehaviourDataResult;
            this.damageBehaviourLabeldata= [];
            this.damageBehaviourRealdata= [];
            if (this.damageBehaviourChart != null) {
                for(let i = 0; i < this.damageBehaviourChart.length; i++) {
                    this.damageBehaviourLabeldata.push(this.damageBehaviourChart[i].behaviour.charAt(0).toUpperCase() + this.damageBehaviourChart[i].behaviour.slice(1));
                    if(action =='reset' && this.damageBehaviourChart[i].behaviour != 'unknown'){
                        this.damageBehaviourLabelName.push(this.damageBehaviourChart[i].behaviour);
                    }
                    this.damageBehaviourRealdata.push(this.damageBehaviourChart[i].Count);
                }
            }
            var damageBehaviourData = {
                labels: this.damageBehaviourLabeldata,
                datasets: [
                    {
                        label: "Content Affected",
                        data: this.damageBehaviourRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.damageBehaviourChart = new Chart("damageBehaviourChart", {
                // chart.getDatasetMeta(1).hidden = true;
                
                type: 'pie',//radar
                data: damageBehaviourData,
                options: {
                    // maintainAspectRatio: false,
                    // indexAxis: 'y',
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // damageBehaviourChart chart code end by iwi


        // damageRulesChart chart code start by iwi
            this.damageRulesChart = event.damageRulesDataResult;
            this.damageRulesLabeldata= [];
            this.damageRulesRealdata= [];
            if (this.damageRulesChart != null) {
                for(let i = 0; i < this.damageRulesChart.length; i++) {
                    this.damageRulesLabeldata.push(this.damageRulesChart[i].rules.charAt(0).toUpperCase() + this.damageRulesChart[i].rules.slice(1));
                    if(action =='reset' && this.damageRulesChart[i].rules != 'unknown'){
                        this.damageRulesLabelName.push(this.damageRulesChart[i].rules);
                    }
                    this.damageRulesRealdata.push(this.damageRulesChart[i].Count);
                }
            }
            var damageRulesData = {
                labels: this.damageRulesLabeldata,
                datasets: [
                    {
                        label: "Content Affected",
                        data: this.damageRulesRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#ad8cf3','#80d1ff','#ff809a','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                        // hoverBackgroundColor: "#8bc7f7"
                    }]
            };
            this.damageRulesChart = new Chart("damageRulesChart", {
                type: 'pie',//radar
                data: damageRulesData,
                options: {
                    // maintainAspectRatio: false,
                    // indexAxis: 'y',
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
            // this.damageRulesChart.getDatasetMeta(1).hidden = true;
            // this.damageRulesChart.update();
        // damageRulesChart chart code end by iwi


        // damageEquipmentChart chart code start by iwi
            this.damageEquipmentChart = event.damageEquipmentDataResult;
            this.damageEquipmentLabeldata= [];
            this.damageEquipmentRealdata= [];
            if (this.damageEquipmentChart != null) {
                for(let i = 0; i < this.damageEquipmentChart.length; i++) {
                    this.damageEquipmentLabeldata.push(this.damageEquipmentChart[i].equipment.charAt(0).toUpperCase() + this.damageEquipmentChart[i].equipment.slice(1));
                    if(action =='reset' && this.damageEquipmentChart[i].equipment != 'unknown'){
                        this.damageEquipmentLabelName.push(this.damageEquipmentChart[i].equipment);
                    }
                    this.damageEquipmentRealdata.push(this.damageEquipmentChart[i].Count);
                }
            }
            var damageEquipmentData = {
                labels: this.damageEquipmentLabeldata,
                datasets: [
                    {
                        label: "Equipment",
                        data: this.damageEquipmentRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#9ce3bc','#ff809a','#80d1ff','#ad8cf3','#f4dd8a','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.damageEquipmentChart = new Chart("damageEquipmentChart", {
                type: 'pie',//radar
                data: damageEquipmentData,
                options: {
                    // maintainAspectRatio: false,
                    // indexAxis: 'y',
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // damageEquipmentChart chart code end by iwi


        // damageOrganisationChart chart code start by iwi
            this.damageOrganisationChart = event.damageOrganisationDataResult;
            this.damageOrganisationLabeldata= [];
            this.damageOrganisationRealdata= [];
            if (this.damageOrganisationChart != null) {
                for(let i = 0; i < this.damageOrganisationChart.length; i++) {
                    this.damageOrganisationLabeldata.push(this.damageOrganisationChart[i].organisation.charAt(0).toUpperCase() + this.damageOrganisationChart[i].organisation.slice(1));
                    if(action =='reset' && this.damageOrganisationChart[i].organisation != 'unknown'){
                        this.damageOrganisationLabelName.push(this.damageOrganisationChart[i].organisation);
                    }
                    this.damageOrganisationRealdata.push(this.damageOrganisationChart[i].Count);
                }
            }
            var damageOrganisationData = {
                labels: this.damageOrganisationLabeldata,
                datasets: [
                    {
                        label: "Organisation",
                        data: this.damageOrganisationRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#9ea9e0','#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.damageOrganisationChart = new Chart("damageOrganisationChart", {
                type: 'pie',//radar
                data: damageOrganisationData,
                options: {
                    // maintainAspectRatio: false,
                    // indexAxis: 'y',
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // damageOrganisationChart chart code end by iwi


        // damageCapaTypeChart chart code start by iwi
            this.damageCapaTypeChart = event.damageCapaTypeDataResult;
            this.damageCapaTypeLabeldata= [];
            this.damageCapaTypeRealdata= [];
            if (this.damageCapaTypeChart != null) {
                for(let i = 0; i < this.damageCapaTypeChart.length; i++) {
                    this.damageCapaTypeLabeldata.push(this.damageCapaTypeChart[i].CapaType);
                    if(action =='reset' && this.damageCapaTypeChart[i].CapaType != 'unknown'){
                        this.damageCapaTypeLabelName.push(this.damageCapaTypeChart[i].CapaType);
                    }
                    this.damageCapaTypeRealdata.push(this.damageCapaTypeChart[i].Count);
                }
            }
            var damageCapaTypeData = {
                labels: this.damageCapaTypeLabeldata,
                datasets: [
                    {
                        label: "CapaType",
                        data: this.damageCapaTypeRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#f4dd8a','#80d1ff','#ff809a','#ad8cf3','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.damageCapaTypeChart = new Chart("damageCapaTypeChart", {
                type: 'pie',//radar
                data: damageCapaTypeData,
                options: {
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // damageCapaTypeChart chart code end by iwi


        // damageInternalAreaChart chart code start by iwi
            this.damageInternalAreaChart = event.finalInternalArea;
            this.damageInternalAreaLabeldata= [];
            this.damageInternalAreaRealdata= [];
            if (this.damageInternalAreaChart != null) {
                for(let i = 0; i < this.damageInternalAreaChart.length; i++) {
                    this.damageInternalAreaLabeldata.push(this.damageInternalAreaChart[i].name.charAt(0).toUpperCase() + this.damageInternalAreaChart[i].name.slice(1));
                    this.damageInternalAreaRealdata.push(this.damageInternalAreaChart[i].Count);
                }
            }
            var damageInternalAreaData = {
                labels: this.damageInternalAreaLabeldata,
                datasets: [
                    {
                        label: "InternalArea",
                        data: this.damageInternalAreaRealdata,
                        borderRadius: Number.MAX_VALUE, 
                        barThickness: 12,
                        grouped: true,
                        backgroundColor:  [
                            '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
                            '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
                            '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
                            '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
                        ],
                    }]
            };
            this.damageInternalAreaChart = new Chart("damageInternalAreaChart", {
                type: 'pie',//radar
                data: damageInternalAreaData,
                options: {
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false // Edit the value according to what you need
                            },
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            stacked: false,
                            ticks:{display:false},
                            gridLines: {
                                display:false
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            fontSize:10,
                            boxWidth:20,
                            padding:4

                        },
                        align:'start'
                      },
                    plugins: {
                        datalabels: {
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
            });
        // damageInternalAreaChart chart code end by iwi

        // MethodPacking chart code start by iwi  
             this.damageMethodPackingChart = event.finalData_method_packing;
             this.damageMethodPackingLabeldata= [];
             this.damageMethodPackingRealdata= [];
             if (this.damageMethodPackingChart != null) {
                 for(let i = 0; i < this.damageMethodPackingChart.length; i++) {
                     this.damageMethodPackingLabeldata.push(this.damageMethodPackingChart[i].name);
                     this.damageMethodPackingRealdata.push(this.damageMethodPackingChart[i].Count.toString());
                 }
             }
                var damageMethodPackingData = {
                 labels: this.damageMethodPackingLabeldata,
                 datasets: [
                     {
                        label: "MethodPacking",
                        data: this.damageMethodPackingRealdata,
                        backgroundColor:  "#3e70e5",
                        barThickness: 15,
                        hoverOffset: 4,
                        borderRadius: Number.MAX_VALUE
                     }]
                };
             this.damageMethodPackingChart = new Chart("damageMethodPackingChart", {
                 type: 'horizontalBar',//radar,bar
                 data: damageMethodPackingData,
                 options:{
                    maintainAspectRatio: false,
                    scales:{
                        xAxes:[{
                            display: true,
                            ticks: {
                                display: true,
                                min: 0
                            },
                            
                        }],
                        yAxes: [{
                            display: true,
                            ticks:{
                                display:true,
                                min: 0
                            },
                        }]
                    },
                    legend:{
                        display:false
                    },
                    plugins: {
                        datalabels: {
                          color: 'blue',
                            anchor: 'end',
                           align: 'right', 
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
             });
        // MethodPacking chart code end by iwi

        // conditionContent chart code start by iwi

            this.damageConditionContentChart = event.conditionContent;
            this.damageConditionContentLabeldata= [];
            this.damageConditionContentRealdata= [];
            if (this.damageConditionContentChart != null) {
                for (let i = 0; i < this.damageConditionContentChart.length; i++) {
                    this.damageConditionContentLabeldata.push(this.damageConditionContentChart[i].name.charAt(0).toUpperCase() + this.damageConditionContentChart[i].name.slice(1));
                    this.damageConditionContentRealdata.push(this.damageConditionContentChart[i].Count);
                }
            }
            var ConditionContent_New = {
                labels: this.damageConditionContentLabeldata,
                datasets: [
                    {
                        label: "Condition Content",
                        data: this.damageConditionContentRealdata,
                        backgroundColor:  "#3e70e5",
                        barThickness: 15,
                        hoverOffset: 4,
                        borderRadius: Number.MAX_VALUE
                        //hoverBackgroundColor: "#8bc7f7"
                    }]
            };

            this.damageConditionContentChart = new Chart("damageConditionContentChart", {
                type: 'horizontalBar',//horizontalBar
                data: ConditionContent_New,
                options:{
                    maintainAspectRatio: false,
                    scales:{
                        xAxes:[{
                            display: true,
                            ticks: {
                                display: true,
                                min: 0
                            },
                            
                        }],
                        yAxes: [{
                            display: true,
                            ticks:{
                                display:true,
                                min: 0
                            },
                        }]
                    },
                    legend:{
                        display:false
                    },
                    plugins: {
                        datalabels: {
                          color: 'blue',
                            anchor: 'end',
            align: 'right',
                            
                          display: true
                        }
                      },
                    onClick: this.chartClick.bind(this)
                }
                
            });
        // conditionContent chart code end by iwi

        // SDR per month Chart code start by iwi
    this.damageReportsSDRperMonthChart = event.damageSDRDataResult;
    this.damageReportsSDRperMonthLabelsData= [];
    this.damageReportsSDRperMonthRealData= [];
    if (this.damageReportsSDRperMonthChart != null) {
        for (let i = 0; i < this.damageReportsSDRperMonthChart.length; i++) {
            this.damageReportsSDRperMonthLabelsData.push(this.damageReportsSDRperMonthChart[i].year);
            
            // if(action =='reset'){
            //     this.damageReportsSDRperMonthLabelsData.push(this.damageReportsSDRperMonthChart[i].year);
            // }
            this.damageReportsSDRperMonthRealData.push(this.damageReportsSDRperMonthChart[i].Count);
        }
    }
    // var monthGroupData_six = {
    //     labels: this.damageReportsSDRperMonthLabelsData,
    //     datasets: [
    //         {
    //             label: "SDR Per Month",
    //             data: this.damageReportsSDRperMonthRealData,
    //             backgroundColor:  "#ceeafe" ,
    //             barThickness:15,
    //             fill: true
    //         }]
    // };
    this.damageReportsSDRperMonthChart = new Chart("damageReportsSDRperMonthChart", {
        type: 'horizontalBar',//bar,horizontalBar
        data: {
            labels: this.damageReportsSDRperMonthLabelsData,
            datasets: [{
                label: '',
                data: this.damageReportsSDRperMonthRealData,
                barThickness:8,
                backgroundColor: "#107ff7"
            },  ],
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        min: 0 
                    }
                }],
                yAxes: [{
                    stacked: false
                }]
            },
            legend: {
                display: false

            },
            plugins: {
                datalabels: {
                    color: 'blue',
                    anchor: 'end',
                    align: 'right',
                    
                    display: true
                }
            },
            onClick: this.chartClick.bind(this)
        }
    });
// SDR per month Chart code end by iwi


        // USD per month Chart code start by iwi
            this.damageReportsUSDperMonthChart = event.damageSDRDataResult;
            this.damageReportsUSDperMonthLabelsData= [];
            this.damageReportsUSDperMonthRealData= [];
            if (this.damageReportsUSDperMonthChart != null) {
                for (let i = 0; i < this.damageReportsUSDperMonthChart.length; i++) {
                    this.damageReportsUSDperMonthLabelsData.push(this.damageReportsUSDperMonthChart[i].year);
                    this.damageReportsUSDperMonthRealData.push((this.damageReportsUSDperMonthChart[i].Count * 1.3).toFixed(2));
                }
            }

            this.damageReportsUSDperMonthChart = new Chart("damageReportsUSDperMonthChart", {
                type: 'horizontalBar',//bar,horizontalBar
                data: {
                    labels: this.damageReportsUSDperMonthLabelsData,
                    datasets: [{
                        label: '',
                        data: this.damageReportsUSDperMonthRealData,
                        barThickness:8,
                        backgroundColor: "#107ff7"
                    },  ],
                },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0 
                            }
                        }],
                        yAxes: [{
                            stacked: false
                        }]
                    },
                    legend: {
                        display: false
        
                    },
                    plugins: {
                        datalabels: {
                            color: 'blue',
                            anchor: 'end',
                            align: 'right',
                            
                            display: true
                        }
                    },
                    onClick: this.chartClick.bind(this)
                }
            });
        // USD per month Chart code end by iwi




        this.spinner.hide();
    }

    chartClick(e): void {
        console.log(e);
    }
    
    downloadPdf() {
      this.spinner.show();
      const chartContainer = this.chartContainer.nativeElement;
      const buttonContainer = this.buttonContainer.nativeElement;
      const reportPageWidth = chartContainer.offsetWidth;
      const reportPageHeight = chartContainer.offsetHeight;
      const buffer = 100;
  
      const pdfCanvas = document.createElement('canvas');
      pdfCanvas.id = 'canvaspdf';
      pdfCanvas.width = reportPageWidth;
      pdfCanvas.height = reportPageHeight;
      console.log(pdfCanvas.width);
      console.log(pdfCanvas.width);
      const pdfctx = pdfCanvas.getContext('2d') as CanvasRenderingContext2D;
  
      let pdfctxX = 0;
      let pdfctxY = 0;
  
      html2canvas(chartContainer).then(canvas => {  
        pdfctx.drawImage(canvas, pdfctxX, pdfctxY, canvas.width, canvas.height);
        const pdf = new jsPDF('p', 'pt', [reportPageWidth, reportPageHeight]);
        const x = 20;const y = 50; const width = pdfCanvas.width-40;  const height = pdfCanvas.height-60;
        
        const titleText = 'Cargo Damage KPI Charts';
        const pageWidth = pdf.internal.pageSize.getWidth();
        const textWidth = pdf.getStringUnitWidth(titleText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        pdf.setFontSize(18);
        pdf.setFontStyle('bold');
       // const titleX = 100; 
       const titleX = (pageWidth - textWidth) / 2;
        const titleY = 30;  
       
        pdf.addImage(pdfCanvas.toDataURL('image/jpeg', 1.0), 'JPEG', 30, 80,  pdfCanvas.width-60, pdfCanvas.height-100);
        pdf.rect(x, y, width, height, 'S');
        pdf.text(titleText, titleX, titleY);
        pdf.save('kpicharts.pdf');
      });
      this.spinner.hide();
    }


    //redirect to damage reports
    onClickDamage_Reports() {
        this.spinner.show();
        setTimeout(() => {
            
            localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
            if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'H') {
            this.route.navigateByUrl('/csa-dashboard');
            }
            else if (this.loginDetails.userType == 'S' && this.loginDetails.orgType == 'A') {
            this.route.navigateByUrl('/csa-airline-dashboard');
            }
            else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'A') {
            this.route.navigateByUrl('/admin-airline-dashboard');
            }
            else if (this.loginDetails.userType == 'A' && this.loginDetails.orgType == 'H') {
            this.route.navigateByUrl('/admin-handler-dashboard');
            }
            else if (this.loginDetails.userType == 'E') {
            this.route.navigateByUrl('/user-dashboard');
            }else if (this.loginDetails.orgType == 'E') {
            this.route.navigateByUrl('/HandlerDashboardComponent');
            }else{
            localStorage.clear()
            this.route.navigateByUrl('/Home');
            }
        }, 200);
    }

    hideDropdown(action: string) {
        // Logic to hide the dropdown based on the selected option
        console.log('T');  
        console.log("value",this.aspectsFilterObj.DRM);
        if (this.aspectsFilterObj.DRM === 'custom') {
          // Perform actions to hide the dropdown or its associated content
          console.log('t');
          this.hideSelect = true;
          
        }
        if (action === 'reset') {this.hideSelect = false;}
      }

    
    onClickUldDamage_Reports() {
        localStorage.setItem("currentModule", JSON.stringify("ULD_DAMAGE"));
        this.route.navigateByUrl("/Uld/viewuld");
    }

    onClick_CargoClaim() {
        localStorage.setItem("currentModule", JSON.stringify("cargoClaim"));
        this.route.navigateByUrl("/cargoClaim/dashboard");
    }

    onClick_CargoServiceRecovery(){
        localStorage.setItem("currentModule",JSON.stringify("csa-service-recovery"))
        this.route.navigateByUrl('/csa-service-recovery');
    }
    onClick_EmailSettings(){
        localStorage.setItem("currentModule",JSON.stringify("kpiChartsEmailSettings"))
        this.route.navigateByUrl('/kpiChartsEmailSettings');
    }

    calculateHeight(length){
        return 30*length+'px';
    }
    calculateHeightMax(length){
        return 32*length+'px';
    }
}
