import { Component, OnInit, ViewChild, Renderer2, ElementRef, Input } from '@angular/core';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid'
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DamageReportsService } from '../services/damageReports/damage-reports.service';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYY',
  },
};



declare var jQuery: any;

@Component({
  selector: 'app-awbs',
  templateUrl: './awbs.component.html',
  styleUrls: ['./awbs.component.css'],

  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AwbsComponent implements OnInit {
  public static logindata;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  webapi: string;
  ReportsProductionUrl: string;
  viewulds: {}[];
  LogindetailsOne: any;
  receiptDetails: any;
  receiptno: any;
  anchors: any;
  public static totaldata;
  editicons: any;
  buttons: any;
  id: any[];
  addIssuedAwbs: any;
  orgId: NgxSpinnerService;
  year: NgxSpinnerService;
  month: any;
  issuedAwbs: any;
  userId: any;
  selected_year: any;
  is_add: boolean;
  selected_row_data: any;
  selected_id: any;
  @Input() max: any;
  tomorrow = new Date();
  constructor(private fb: FormBuilder, private toaster: ToastrService, private spinner: NgxSpinnerService, private damageReportsService: DamageReportsService, private renderer: Renderer2, private elementRef: ElementRef) { 
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }
 
   



  ngOnInit() {
    this.addIssuedAwbs = this.create_formGroup();
  }
  dateRange = [new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 11, 31)]

  date = new FormControl(moment());
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    
    datepicker.close();
    let year_val = normalizedYear.year()
    this.get_view_Issued_Awbs(year_val)
  }

  get_view_Issued_Awbs(year) {
    this.selected_year = year
    this.spinner.show()
    this.damageReportsService.viewIssuedAwbs(AwbsComponent.logindata.userId, year, AwbsComponent.logindata.orgId).subscribe(data => {
      this.spinner.hide()
      console.log("output", data)
      if (data.responseCode === 1) {
        AwbsComponent.totaldata = data.viewAwbs;
        this.source.localdata = data.viewAwbs;
        this.myGrid.source(this.dataAdapter);
      } else {
        AwbsComponent.totaldata = [];
        this.source.localdata = [];
        this.myGrid.source(this.dataAdapter);
      }
    }, error => {
      this.spinner.hide()
    })
  }

  ngAfterViewInit() {

    AwbsComponent.logindata = JSON.parse(localStorage.getItem("loginresultobject"))
    console.log("LoginObject", AwbsComponent.logindata)
    this.get_view_Issued_Awbs((new Date()).getFullYear());
    this.myGrid.refresh();
  }
  ngAfterViewChecked() {

    this.buttons = this.elementRef.nativeElement.querySelectorAll('button');
    this.editicons = this.elementRef.nativeElement.querySelectorAll('i');
    this.buttons.forEach((button: HTMLButtonElement) => {

      if (button.id != '') {
        let href_Split_Array = [];
        href_Split_Array = button.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[0];
        if ((button.innerText === 'CHANGE') && (href_Valid_Check_Var === 'edit')) {
          button.addEventListener('click', this.handleAnchorClick)
        } else if (button.innerText === 'ADD' && href_Valid_Check_Var === 'add') {
          button.addEventListener('click', this.handleAnchorClick)
        } else if (button.innerText === 'CLEAR' && href_Valid_Check_Var === 'clear') {
          button.addEventListener('click', this.handleAnchorClick)
        }
      }
    })

    this.editicons.forEach((editicon: HTMLImageElement) => {
      if (editicon.id != '') {
        let href_Split_Array = [];
        href_Split_Array = editicon.id.split(":");
        let href_Valid_Check_Var = href_Split_Array[0];
        if (href_Valid_Check_Var === 'edit') {
          editicon.addEventListener('click', this.handleIconClick)
        } else if (href_Valid_Check_Var === 'add') {
          editicon.addEventListener('click', this.handleIconClick)
        } else if (href_Valid_Check_Var === 'clear') {
          editicon.addEventListener('click', this.handleIconClick)
        }
      }
    })
  }

  public handleAnchorClick = (event: Event) => {
    event.preventDefault();
    const button = event.target as HTMLButtonElement;
    this.fn_alert(button, event);
  }

  public handleIconClick = (event: Event) => {
    event.preventDefault();
    const editicon = event.target as HTMLImageElement;
    this.fn_icon_alert(editicon, event);
  }

  fn_alert(button, event) {
    if (button.innerText === 'CHANGE') {
      console.log(this.myGrid.getselectedcell());
      let href_Split_Array = [];
      href_Split_Array = button.id.split(":");
      let href_Valid_Check_Var = href_Split_Array[1];
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.edit_popUp(totalRow, "edit");
    } else if (button.innerText === 'ADD') {
      let href_Split_Array = [];
      href_Split_Array = button.id.split(":");
      let href_Valid_Check_Var = href_Split_Array[1];
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.edit_popUp(totalRow, "add");
    } else if (button.innerText === 'CLEAR') {
      let href_Split_Array = [];
      href_Split_Array = button.id.split(":");
      let href_Valid_Check_Var = href_Split_Array[1];
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.selected_row_data = totalRow
      this.delete_Awbs(totalRow.id, href_Valid_Check_Var);
    }
  }

  fn_icon_alert(editicon, event) {
    let href_Split_Array = [];
    href_Split_Array = editicon.id.split(":");
    let href_Valid_Check_Var = href_Split_Array[1];
    if (href_Split_Array[0] === 'edit') {
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.edit_popUp(totalRow, "edit");
    } else if (href_Split_Array[0] === 'add') {
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.edit_popUp(totalRow, "add");
    } else if (href_Split_Array[0] === 'clear') {
      let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
      this.selected_row_data = totalRow
      this.delete_Awbs(totalRow.id, href_Valid_Check_Var);

    }

  }

  edit_popUp(id, type) {
    if (type === "edit") {
      this.is_add = false
      this.selected_row_data = id
      this.addIssuedAwbs.get('year').setValue(this.selected_year)
      this.addIssuedAwbs.get('month').setValue(id.monthId)
      this.addIssuedAwbs.get('issuedAwbs').setValue(id.awbsIssued)
    } else if (type === "add") {
      this.is_add = true
      this.selected_row_data = id
      this.addIssuedAwbs.get('year').setValue(this.selected_year)
      this.addIssuedAwbs.get('month').setValue(id.monthId)
      this.addIssuedAwbs.get('issuedAwbs').setValue("")
      this.addIssuedAwbs.get('issuedAwbs').touched = false;
    }
    jQuery('#addPopUp').modal({ backdrop: 'static', keyboard: false });
  }

  create_formGroup() {
    return this.fb.group({
      year: new FormControl(''),
      month: new FormControl(''),
      issuedAwbs: new FormControl('', [Validators.required, Validators.maxLength(7)]),
    })
  }

  onSubmitFormData(formdata) {
    ;
    if (this.is_add === true) {
      if (this.selected_row_data.id === "" || this.selected_row_data.id === null || this.selected_row_data.id === undefined) {
        this.addissued_Awbs(formdata)
      } else {
        this.editissued_Awbs(formdata)
      }

    } else {
      this.editissued_Awbs(formdata)
    }
  }

  addissued_Awbs(formdata) {
    formdata.orgId = AwbsComponent.logindata.orgId
    formdata.userId = AwbsComponent.logindata.userId
    this.spinner.show();
    jQuery('#addPopUp').modal('hide');
    this.damageReportsService.saveAwbsIssued(formdata).subscribe(data => {
      this.spinner.hide();
      if (data.responseCode === 1) {
          ;
        this.get_view_Issued_Awbs(this.selected_year)
        this.toaster.success(data.message);

      }else if (data.responseCode === 0){
        this.toaster.error(data.message);
          ;
      }
    }, error => {
      this.spinner.hide()
      this.toaster.error("service error occured");
    })
  }

  editissued_Awbs(formdata) {
    this.selected_row_data
    formdata.id = this.selected_row_data.id
    formdata.awbIssuedCount = formdata.issuedAwbs
    formdata.orgId = AwbsComponent.logindata.orgId
    formdata.userId = AwbsComponent.logindata.userId
    this.spinner.show();
    jQuery('#addPopUp').modal('hide');
    this.damageReportsService.editAwbsIssued(formdata).subscribe(data => {
      this.spinner.hide()
      if (data.responseCode === 1) {
        this.get_view_Issued_Awbs(this.selected_year)
        this.toaster.success(data.message);

      }

    }, error => {
      this.spinner.hide()
      this.toaster.error("service error occured");
    })
  }


  delete_Awbs(id, href_Valid_Check_Var) {
    jQuery('#deletePopUp').modal({ backdrop: 'static', keyboard: false });
  }

  on_confirm_delete(event: any): void {
    this.spinner.show();
    const deleteAwbs = {
      orgId: AwbsComponent.logindata.orgId,
      userId: AwbsComponent.logindata.userId,
      id: this.selected_row_data.id
    }
     jQuery('#deletePopUp').modal('hide');
    this.damageReportsService.deleteAwbsIssued(deleteAwbs).subscribe(data => {
      this.spinner.hide();
      if (data.responseCode === 1) {
        this.toaster.success(data.message);

        this.get_view_Issued_Awbs(this.selected_year)
      } else {
        this.toaster.error(data.message);
      }
      this.myGrid.refreshdata();
    },
      error => {
        this.spinner.hide();
        this.toaster.error("service error occured");
      });

  }
  source =
    {
      localdata: this.receiptDetails,
      datatype: 'json',
      datafields:
        [
          { name: 'id', type: 'string' },
          { name: 'monthId', type: 'string' },
          { name: 'month', type: 'string' },
          { name: 'issuedDate', type: 'string' },
          { name: 'awbsIssued', type: 'string' },
          { name: 'actions', type: 'string' },
        ],
      pagesize: 50,
      beforeprocessing: function (data) {
      },
      pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
        console.log(pagenum);
      },
      rendergridrows: function (params) {
        return params.data;
      }
    };

  dataAdapter = new jqx.dataAdapter(this.source);

  columns =
    [
      { text: 'id', datafield: 'id', hidden: true },
      { text: 'monthId', datafield: 'monthId', hidden: true },
      { text: 'Month', datafield: 'month', width: 200 },
      {
        text: 'Issued Dates', datafield: 'issuedDate', width: 200,
        cellclassname: function (row, column, value, data) {
          let result = " ";
          if (data.issuedDate === null) {
            return data.issuedDate = " ";
          }
          else {
            return;
          }
        }
      },
      {
        text: 'Issued AWbs', datafield: 'awbsIssued', width: 150,
        cellclassname: function (row, column, value, data) {
          let result = " ";
          if (data.awbsIssued === null) {
            return data.awbsIssued = " ";
          }
          else {
            return;
          }
        }
      },
      {
        text: 'Actions', datafield: 'actions', width: 220,
        cellsrenderer: function (row, index, data) {
          if ((AwbsComponent.totaldata[row].issuedDate != null) || (AwbsComponent.totaldata[row].awbsIssued != null)) {
            return "<div style='margin-top:  8.5px; margin-left: 5px;'><button id = 'edit:" + row + "' ><i id = 'edit:" + row + "' class='fa fa-pencil-square-o' aria-hidden='true'></i>CHANGE</button> &nbsp &nbsp &nbsp &nbsp &nbsp<button  id = 'clear:" + row + "'><i id = 'clear:" + row + "' class='fa fa-trash-o' aria-hidden='true'></i>CLEAR</button></div>";
          } else {
            return "<div style='margin-top:  8.5px; margin-left: 5px;'><button id = 'add:" + row + "' style='background: green;color: #fff;' ><i id = 'add:" + row + "' class='fa fa-plus' aria-hidden='true'></i>ADD</button> &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp<button  ><i class='fa fa-trash-o' aria-hidden='true'></i>CLEAR</button></div>";
          }
        },
      },
    ];
  onPageChanged(event: any): void {
    let args = event.args;
    let pagenumber = args.pagenum;
    let pagesize = args.pagesize;

  }
  issued_number_validation(event) {

    const initalValue = event.target.value;
    event.target.value = initalValue.trim().replace(/[^0-9]*/g, '');
    if (initalValue !== event.target.value) {
      event.stopPropagation();
    }

  }
}
