import { LocationStrategy } from "@angular/common";
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    enableProdMode
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import "rxjs/add/operator/filter";
import { ConfigServiceService } from "../../../config-service.service";
import { ViewReportsData } from "../../../data/DataShareViewDetails";
import { StationService } from "../../../services/station/station.service";
// declare var jQuery: any;
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxTreeGridComponent } from "jqwidgets-ng/jqxtreegrid";
import { ServiceRecovery } from "../../../services/serviceRecovery/recovery.service";

if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
declare var jQuery: any;
@Component({selector: "app-service-recovery", 
    templateUrl: "./view-service-recovery.component.html", 
    styleUrls: ["./view-service-recovery.component.css"]})
export class ViewServiceRecoveryComponent implements OnInit,OnDestroy {
    stationDetails : any;
    ServiceRecorverydata : any;
    Logindetails : any;
    comapanydetails : any;
    obj : {
        orgId: any
    };
    deleteObj : any;
    searchItem : any;
    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 10;
    unauthlowValue = 0;
    unauthhighValue = 5;
    CompanyLogoimgurl : string;
    CompanyName : any;
    NavigatebackTo : any;
    stationsFrom : any;
    archiveData: any = {};
    icons : any;
    anchors : any;
    editicons : any;
    static webapi: string;
    public static TOTAL_GRIDDATA = [];
    apiEndPOint:any;
    currentTab:any ='masterRecoveryTab';
    archive:boolean=true;
    currentTablist:any = 'activeTab';
    previousTab:any;
    @ViewChild('myGrid', {})myGrid : jqxGridComponent;
    @ViewChild("TreeGrid", {})treeGrid : jqxTreeGridComponent;
    constructor(private cookieService : CookieService, private readonly route : ActivatedRoute, private ViewReportsData : ViewReportsData, private router : Router, private toastr : ToastrService, private stationService : StationService, private spinner : NgxSpinnerService, private locationStr : LocationStrategy, private ServiceRecovery : ServiceRecovery, private elementRef : ElementRef, private config : ConfigServiceService) {
        ViewServiceRecoveryComponent.webapi = this.config.getConfig().ULD_Url;
        // preventing back button in browser
        // history.pushState(null, null, window.location.href);
        // this.locationStr.onPopState(() => {
        // history.pushState(null, null, window.location.href);
        // });
    }
    // dataGrid1: DxDataGridComponent;
    viewData : any;
    stationCode : any;
    // dataAdapter:any;
    public static stationsListFromService;

    ngAfterViewInit() {
        if (this.treeGrid !== undefined && this.treeGrid !== null) {
            this.treeGrid.refresh();
        }
    }
    
    ngAfterViewChecked() {
        this.anchors = this.elementRef.nativeElement.querySelectorAll("a");
        this.icons = this.elementRef.nativeElement.querySelectorAll("i");
        this.icons.forEach((icon : HTMLImageElement) => {
            if (icon.id != "") {
                let href_Split_Array = [];
                href_Split_Array = icon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                //
                if (href_Valid_Check_Var === "edit") {
                    icon.addEventListener("click", this.handleiconClick);
                }
            }
        });
    }
    public handleiconClick = (event : Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
        this.fn_alerticon(editicon, event);
    };

    fn_alerticon(icon, event) {
        let href_Split_Array = [];
        href_Split_Array = icon.id.split(":");
        console.log("href_Split_Array 102 ",href_Split_Array);
        let href_Valid_Check_Var = href_Split_Array[1];

        let recovery_data_access_handler_id = href_Split_Array[2];
        let recovery_data_access_permission = href_Split_Array[3];
        // ,recovery_data_access_handler_id,recovery_data_access_permission
        if (href_Split_Array[0] === "edit") {
            this.editServiceRecorveryAirline(href_Valid_Check_Var);
        }
    }

    ngOnInit() {

        this.archiveData.serviceRecoveryId ="";
        this.archiveData.status ="";

        this.route.queryParams.filter((params) => params.from).subscribe((params) => {
            this.NavigatebackTo = params.from;
        });

        this.Logindetails = JSON.parse(localStorage.getItem("loginresultobject"));
        this.comapanydetails = JSON.parse(localStorage.getItem("comapanydetails"));
        this.stationsFrom = JSON.parse(localStorage.getItem("stationsFrom"));

        if (this.Logindetails.orgType == "H") {
            this.viewServiceRecorverydata();
        }
      
        if (this.NavigatebackTo !== "SidebarStations" || this.stationsFrom !== "sidebarStations") {
            if (this.comapanydetails === null) {
                this.CompanyLogoimgurl = "";
            } else {
                this.CompanyLogoimgurl = this.comapanydetails.orgLogo;
                this.CompanyName = this.comapanydetails.orgName;
            }
        } else {
            this.CompanyLogoimgurl = this.Logindetails.orgLogo;
            this.CompanyName = this.Logindetails.organisationName;
        }

        let arrayData = this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllServiceRecoveryNew?orgId=" + this.logindet.orgId + "&loginUserId=" + this.logindet.userId + "&orgIdType=" + this.logindet.orgType + "";
        console.log("array adata===>",arrayData);
        // let reportsArray = [];
        // reportsArray = this.reportsDataGrid_Ref.myGrid.getrows().map(x => Object.assign({}, x));

        let archive_data = this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllArchivedServiceRecovery?orgId=126&loginUserId=358&orgIdType=A&filterscount=0&groupscount=0&pagenum=0&pagesize=10&recordstartindex=0&recordendindex=12&_=1699516041937";
        console.log("data 151 ===>",archive_data);
    }

    // redirect to Uld damage reports
    onClickDamage_Reports() {
        this.spinner.show();
        setTimeout(() => {
            
            localStorage.setItem("currentModule",JSON.stringify("CARGO_DAMAGE"))
            if (this.Logindetails.userType == 'S' && this.Logindetails.orgType == 'H') {
            this.router.navigateByUrl('/csa-dashboard');
            }
            else if (this.Logindetails.userType == 'S' && this.Logindetails.orgType == 'A') {
            this.router.navigateByUrl('/csa-airline-dashboard');
            }
            else if (this.Logindetails.userType == 'A' && this.Logindetails.orgType == 'A') {
            this.router.navigateByUrl('/admin-airline-dashboard');
            }
            else if (this.Logindetails.userType == 'A' && this.Logindetails.orgType == 'H') {
            this.router.navigateByUrl('/admin-handler-dashboard');
            }
            else if (this.Logindetails.userType == 'E') {
            this.router.navigateByUrl('/user-dashboard');
            }else if (this.Logindetails.orgType == 'E') {
            this.router.navigateByUrl('/HandlerDashboardComponent');
            }else{
            localStorage.clear()
            this.router.navigateByUrl('/Home');
            }
        }, 200);
    }

    onClickUldDamage_Reports() {
        localStorage.setItem("currentModule", JSON.stringify("ULD_DAMAGE"));
        this.router.navigateByUrl("/Uld/viewuld");
    }

    onClick_CargoClaim() {
        localStorage.setItem("currentModule", JSON.stringify("cargoClaim"));
        this.router.navigateByUrl("/cargoClaim/dashboard");
    }

    onClick_AirlineCreateRecovery() {
        console.log("onClick_AirlineCreateRecovery");
        localStorage.setItem("currentModule", JSON.stringify("addServiceRecovery"));
        this.router.navigateByUrl("/addservicerecovery");
    }

    onClick_HandlerCreateRecovery() {
        
        console.log("addhandlerservice");
        localStorage.setItem("currentModule", JSON.stringify("addHandlerServiceRecovery"));
        this.router.navigateByUrl("/addhandlerservice");
    }

    onclick_damageCustomerReport(){
        console.log("damageCustomerReport");
        localStorage.setItem("currentModule", JSON.stringify("damageCustomerReport"));
        this.router.navigateByUrl("/damageCustomerReport");
    }

    onclick_CustomerReportHandler(){
        console.log("customerReportHandler");
        localStorage.setItem("currentModule", JSON.stringify("customerReportHandler"));
        this.router.navigateByUrl("/customerReportHandler");
    }


    fn_ToServiceRecovery() {
        this.currentTab  = 'recoveryTab';
        this.archive=false
        // this.previousTab = 'masterRecoveryTab';
        // console.log("fn_ToServiceRecovery");
    }

    fn_ToMasterServiceRecovery() {
         this.currentTab  = 'masterRecoveryTab';
         this.archive=true
        // console.log("fn_ToMasterServiceRecovery");
    }
    // service recorvery start
    fn_ToArchivedHandler(){
        this.currentTab  = 'archivedTab';
        this.archive=false

    }
    fn_ToActicveTab() {
        this.currentTablist  = 'activeTab';
        this.archive=true
        
    }        

    fn_ToArchived() {
         this.currentTablist  = 'archivedTab';
         this.archive=false
    }

    viewServiceRecorverydata() {
        this.Logindetails = JSON.parse(localStorage.getItem("loginresultobject"));

        var loginUserId = this.Logindetails.orgId;
        var orgIdType = this.Logindetails.orgType;

        this.spinner.show();
        this.ServiceRecovery.getServiceRecovery(loginUserId, orgIdType).subscribe((data) => {
            this.ServiceRecorverydata = data;

            localStorage.setItem("viewServiceRecorveryData",JSON.stringify(this.ServiceRecorverydata));

             console.log("ServiceRecorverydata 197 : ", this.ServiceRecorverydata);
            this.spinner.hide();
        }, (error) => {
            this.spinner.hide();
            ViewServiceRecoveryComponent.stationsListFromService = [];
        });
         this.spinner.hide();
    }

    editServiceRecorveryAirline(object) {
        // console.log("object 203 ",object);
        // console.log("object orgType 204 ",this.Logindetails.orgType);
        // console.log("object orgId 205 ",this.Logindetails.orgId);


        this.router.navigate(["/editservicerecoveryairline"], {
            queryParams: {
                serviceId: object
            }
        });
    }

    editServiceRecorvery(object) {
        this.router.navigate(["/editservicerecovery"], {
            queryParams: {
                serviceId: object
            }
        });
    }
    // service recorvery end


    logindet = JSON.parse(localStorage.getItem("loginresultobject"));
    
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
        
    source: any = {
        datafields: [
            { name: 'id' },
          { name: 'id' },
          
          { name: 'serviceRecoveryId' },
          { name: "flightDate", type: "date" },
          { name: "createdon", type: "date" },
          { name: "status", type: "string" },
          { name: "masterAwbNo", type: "string" },
          { name: "totalMAWBPieces", type: "string" },
          { name: "totalMAWBWeight", type: "string" },
          { name: "piecesInvolved", type: "string" },
          { name: "piecesInvolvedWeight", type: "string" },
          { name: "flight", type: "string" },
          { name: "recoveryAspcts", type: "string" },
          { name: "progressStatus", type: "string" },

          { name: "recoveryCreatedByHandler", type: "string" },
          { name: "serviceRecoveryPermission", type: "string" },
         

        ],
        root: 'airlineData',
        id: 'id',
        datatype: 'json',
        url: this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllServiceRecoveryNew?orgId=" + this.logindet.orgId + "&loginUserId=" + this.logindet.userId + "&orgIdType=" + this.logindet.orgType + "",
        beforeprocessing: (data: any) => {
            console.log(" 292  data 320  ",data);
            ViewServiceRecoveryComponent.TOTAL_GRIDDATA = ViewServiceRecoveryComponent.TOTAL_GRIDDATA.concat(data.airlineData) ;
        }
    };

  employeesAdapter: any = new jqx.dataAdapter(this.source);
 

  getWidth(): any {
     if (document.body.offsetWidth < 850) {
            return "100%";
        } else if (document.body.offsetWidth > 1400) {
            return "100%";
        }
        return "100%";
    }

  ordersSource: any = {
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'serviceRecoveryId', type: 'string' },
      { name: 'handlerName', type: 'string' },
      { name: 'airport_type', type: 'string' },
      { name: 'recoveryAspctsByhandler', type: 'string' },
      { name: 'status', type: 'string' },
      { name: 'piecesTraced', type: 'string' },
      { name: 'tracingDate', type: 'string' },
    ],
    root: 'handlerData',
    datatype: 'json',
    url: this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllServiceRecoveryNew?orgId=" + this.logindet.orgId + "&loginUserId=" + this.logindet.userId + "&orgIdType=" + this.logindet.orgType + "",
  };

  ordersDataAdapter = new jqx.dataAdapter(this.ordersSource, {
    autoBind: true,
  });
 
  nestedGrids: any[] = new Array();

  // create nested grid.
  initRowDetails = (
    index: number,
    parentElement: any,
    gridElement: any,
    record: any
  ): void => {

    let id = record.uid.toString();
    let nestedGridContainer = parentElement.children[0];
    this.nestedGrids[index] = nestedGridContainer;
    let filtergroup = new jqx.filter();
    let filter_or_operator = 1;
    let filtervalue = id;
    let filtercondition = 'equal';
    let filter = filtergroup.createfilter('stringfilter',filtervalue,filtercondition);
    // fill the orders depending on the id.
    let orders = this.ordersDataAdapter.records;

    let ordersbyid = [];
    for (let i = 0; i < orders.length; i++) {
      let result = filter.evaluate(orders[i]['serviceRecoveryId']);
      if (result) ordersbyid.push(orders[i]);
    }
    let ordersSource = {
      datafields: [
        { name: 'id', type: 'string' },
        { name: 'serviceRecoveryId', type: 'string' },
        { name: 'handlerName', type: 'string' },
        { name: 'airport_type', type: 'string' },
        { name: 'recoveryAspctsByhandler', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'piecesTraced', type: 'string' },
        { name: 'tracingDate', type: 'string' },
      
      ],
      id: 'serviceRecoveryId',
      localdata: ordersbyid,
    };
    let nestedGridAdapter = new jqx.dataAdapter(ordersSource);
    if (nestedGridContainer != null) {
      let settings = {
        theme: 'material',
        width: '100%',
        height: 200,
        source: nestedGridAdapter,
        columns: [
          { text: 'Handler Name', datafield: 'handlerName', width: '15%', cellsalign: 'center', align: 'center' },
          { text: 'Type', datafield: 'airport_type', width: '15%',cellsalign: 'center', align: 'center' },
          { text: 'Handler Action', datafield: 'recoveryAspctsByhandler', width: '15%', cellsalign: 'center', align: 'center' },
          {
                text: "Status",
                dataField: "status",
                width: '15%',
                cellsalign: 'center', align: 'center',
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    if (columndata.status == 'open') {
                        return("<span style='color: #ffffff; background: #f15454; padding: 3px 30px; font-size: 12px; width:90px; text-align: center;'>Open</span>");
                    } else if (columndata.status == 'closed') {
                        return("<span style='color: #ffffff;background: #5bbd5b; padding: 3px 25px; font-size: 12px;width:90px; text-align: center;'>Closed</span>");
                    }else if (columndata.status == 'view') {
                        return("<span style='color: #ffffff; background: #f5a258; padding: 3px 10px; font-size: 12px;width:90px; text-align: center;'>View</span>");
                    }else if (columndata.status == 'traced') {
                        return("<span style='color: #ffffff;background: #f5a258; padding: 3px 10px; font-size: 12px;width:90px; text-align: center;'>Traced</span>");
                    }
                }
           },
           { text: 'Pieces Traced', datafield: 'piecesTraced', width: '25%', cellsalign: 'center', align: 'center' },
           { text: 'Tracing Date', datafield: 'tracingDate', width: '15%' ,cellsalign: 'center', align: 'center'},
          
        ],
      };

      jqwidgets.createInstance(
        `#${nestedGridContainer.id}`,
        'jqxGrid',
        settings
      );
    }
  };


  renderer = (row: number, column: any, value: string): string => {
    return (
      '<span style="margin-left: 4px; float: left;">' +
      value +
      '</span>'
    );
  };

  rowdetailstemplate: any = {
    rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
    rowdetailsheight: 220,
    rowdetailshidden: true,
  };

  ready = (): void => {
    this.myGrid.showrowdetails(0);
  };

  columns: any[] = [
    {
        text: "Actions",
        datafield: "id",
        cellsalign: 'center', align: 'center',
        width: 60,
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
           return("<div class='jq-actions' style='margin-left: 5px;'><a  href='edit:" + value + "'><i id = 'edit:" + value +':' + columndata.serviceRecoveryCreatedBy + ':' + columndata.serviceRecoveryPermission + "' class='fa fa-pencil-square-o' aria-hidden='true'   data-toggle='tooltip' title='Edit'></i></a></div>");
        }
    },
    {
        text: "Flight Date",
        datafield: "flightDate",
        width: 100,
        cellsformat: "dd-MM-yyyy",
        filtertype: "date", cellsalign: 'center', align: 'center'
    },
    {
        text: " Create Date",
        datafield: "createdon",
        width: 100,
        cellsformat: "dd-MM-yyyy",
        filtertype: "date",
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Status",
        dataField: "status",
        width: 120,
        cellsalign: 'center', align: 'center',
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
            if (columndata.progressStatus == 'open') {
                return("<span style='color: #ffffff;background: #f15454;padding: 3px 30px;font-size: 12px;width: 90px;text-align: center;'>Open</span>");
            } else if (columndata.progressStatus == 'closed') {
                return("<span style='color: #ffffff; background: #5bbd5b;padding: 3px 25px;font-size: 12px;width: 90px;text-align: center;'>Closed</span>");
            } else {
                return "<span style='color: #ffffff; background: #f5a258;padding: 3px 10px;font-size: 12px;width: 90px;text-align: center;'>" + columndata.progressStatus + "</span>";
            }
        }
    },
    {
        text: "AWB No",
        dataField: "masterAwbNo",
        width: 110,
        cellsalign: 'center', align: 'center'
    }, {
        text: "MAWB Pieces ",
        dataField: "totalMAWBPieces",
        width: 100,
        cellsalign: 'center', align: 'center'
    }, {
        text: "MAWB weight",
        dataField: "totalMAWBWeight",
        width: 100,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Pieces Involved",
        dataField: "piecesInvolved",
        width: 120,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Involved weight",
        dataField: "piecesInvolvedWeight",
        width: 120,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Flight",
        dataField: "flight",
        width: 80,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Aspects By Admin",
        dataField: "recoveryAspcts",
        width: 150,
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Created By",
        dataField: "recoveryCreatedByHandler",
        width: 150,
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Handler Permission",
        dataField: "serviceRecoveryPermission",
        width: 150,
        cellsalign: 'center', align: 'center',
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
            if (columndata.serviceRecoveryPermission == '1') {
                return("<span style='color: #ffffff;background:#3ba13b;padding: 3px 10px;font-size: 13px;'>True</span>");
            } else if (columndata.serviceRecoveryPermission == '2') {
                return("<span style='color: #ffffff; background: #14ad14;padding: 3px 10px;font-size: 13px;'>False</span>");
            } else {
                return "";//<span style='color: #ffffff; background: #f5841f;padding: 3px 10px;font-size: 13px;'>" + columndata.serviceRecoveryPermission + "</span>
            }
        }
    },
  ];

//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////




    // nested grid end

   
    //=========================== grid row selection==================== //

    public selectedRowsData = [] ;
    _onSelectRow(event: any): void {
       
        if(Array.isArray(event.args.rowindex)){ 
            console.log("572");
            if(event.args.rowindex.length !== 0){
               // this.selectedRowsData = ViewServiceRecoveryComponent.TOTAL_GRIDDATA;//this.getDtotalData() ;
               for (var i = 0; i < ViewServiceRecoveryComponent.TOTAL_GRIDDATA.length; i++) {
              
                if (ViewServiceRecoveryComponent.TOTAL_GRIDDATA[i]) {
                    this.selectedRowsData.push(ViewServiceRecoveryComponent.TOTAL_GRIDDATA[i].id);
                }
            }
            
            console.log("572 Id ===>", this.selectedRowsData);
            }else{
            this.selectedRowsData = [];
            }
        }else{
            if( event.type === "rowunselect"){
                let index = this.selectedRowsData.findIndex(x => x.id === event.args.row.id);
                this.selectedRowsData.splice(index, 1);
            }else{
                var ind = ViewServiceRecoveryComponent.TOTAL_GRIDDATA.findIndex(x => x.id === event.args.row.id);
                event.args.row = ViewServiceRecoveryComponent.TOTAL_GRIDDATA[ind];
                this.selectedRowsData.push(event.args.row.id) ; 
            }
        }

        console.log("577 selected data==>",this.selectedRowsData);
   
      }



    getDtotalData():any{ // return total selected data from all pages 
        return  this.removeDuplicates(ViewServiceRecoveryComponent.TOTAL_GRIDDATA)
    }
    
    removeDuplicates(array :any) : any[] {
        let uniq = {};
        return array.filter(obj => !uniq[obj.reportId] && (uniq[obj.reportId] = true))
    }
    

//=================model for archive===============================//
    confirm_manualarchive(){
       
    // jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    if (this.selectedRowsData.length != 0) {
        jQuery('#manarchModal').modal({ backdrop: 'static', keyboard: false });
    }else{
        this.spinner.hide();
        this.toastr.error('Please select atleast one report', '', { timeOut: 1200 });
    }
        
    }
    
    manualarchive(){
        this.archiveData.serviceRecoveryId =  this.selectedRowsData;
        this.archiveData.status=  "1";
        console.log("payload",this.archiveData);
        this.ServiceRecovery.archivedUpdateserviceRecovery(this.archiveData).subscribe((data) => {
            console.log("response===>",data);
            this.reloadCurrentRoute();
            
            this.toastr.success(data.message);          
            jQuery('#manarchModal').modal('hide');
        },(error) => {
            this.toastr.error("Report is not archived successfully.");
            jQuery('#manarchModal').modal('hide');
            }
        );
    }
//=============== end model archive==========================================


//====================== archived data grid start =============================================
archivedsource: any = {
    datafields: [
        { name: 'id' },
      { name: 'id' },
      
      { name: 'serviceRecoveryId' },
      { name: "flightDate", type: "date" },
      { name: "createdon", type: "date" },
      { name: "status", type: "string" },
      { name: "masterAwbNo", type: "string" },
      { name: "totalMAWBPieces", type: "string" },
      { name: "totalMAWBWeight", type: "string" },
      { name: "piecesInvolved", type: "string" },
      { name: "piecesInvolvedWeight", type: "string" },
      { name: "flight", type: "string" },
      { name: "recoveryAspcts", type: "string" },
      { name: "progressStatus", type: "string" },

      { name: "recoveryCreatedByHandler", type: "string" },
      { name: "serviceRecoveryPermission", type: "string" },
     

    ],
    root: 'airlineArchivedData',
    id: 'id',
    datatype: 'json',
    url: this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllServiceRecoveryNew?orgId=" + this.logindet.orgId + "&loginUserId=" + this.logindet.userId + "&orgIdType=" + this.logindet.orgType + "",
    beforeprocessing: (data: any) => {
        console.log("archived 662 ===> ",data);
     
    }
};

archiveDataAdapter = new jqx.dataAdapter(this.archivedsource, {
    autoBind: true,
  });
 
  archivenestedGrids: any[] = new Array();

  // create nested grid.
  archiveinitRowDetails = (
    index: number,
    parentElement: any,
    gridElement: any,
    record: any
  ): void => {

    let id = record.uid.toString();
    let nestedGridContainer = parentElement.children[0];
    this.nestedGrids[index] = nestedGridContainer;
    let filtergroup = new jqx.filter();
    let filter_or_operator = 1;
    let filtervalue = id;
    let filtercondition = 'equal';
    let filter = filtergroup.createfilter('stringfilter',filtervalue,filtercondition);
    // fill the orders depending on the id.
    let orders = this.ordersDataAdapter.records;

    let ordersbyid = [];
    for (let i = 0; i < orders.length; i++) {
      let result = filter.evaluate(orders[i]['serviceRecoveryId']);
      if (result) ordersbyid.push(orders[i]);
    }
    let ordersSource = {
      datafields: [
        { name: 'id', type: 'string' },
        { name: 'serviceRecoveryId', type: 'string' },
        { name: 'handlerName', type: 'string' },
        { name: 'airport_type', type: 'string' },
        { name: 'recoveryAspctsByhandler', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'piecesTraced', type: 'string' },
        { name: 'tracingDate', type: 'string' },
      
      ],
      id: 'serviceRecoveryId',
      localdata: ordersbyid,
    };
    let nestedGridAdapter = new jqx.dataAdapter(ordersSource);
    if (nestedGridContainer != null) {
      let settings = {
        theme: 'material',
        width: '100%',
        height: 200,
        source: nestedGridAdapter,
        columns: [
          { text: 'Handler Name', datafield: 'handlerName', width: '15%', cellsalign: 'center', align: 'center' },
          { text: 'Type', datafield: 'airport_type', width: '15%',cellsalign: 'center', align: 'center' },
          { text: 'Handler Action', datafield: 'recoveryAspctsByhandler', width: '15%', cellsalign: 'center', align: 'center' },
          {
                text: "Status",
                dataField: "status",
                width: '15%',
                cellsalign: 'center', align: 'center',
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
                    if (columndata.status == 'open') {
                        return("<span style='color: #ffffff; background: #f15454; padding: 3px 30px; font-size: 12px; width:90px; text-align: center;'>Open</span>");
                    } else if (columndata.status == 'closed') {
                        return("<span style='color: #ffffff;background: #5bbd5b; padding: 3px 25px; font-size: 12px;width:90px; text-align: center;'>Closed</span>");
                    }else if (columndata.status == 'view') {
                        return("<span style='color: #ffffff; background: #f5a258; padding: 3px 10px; font-size: 12px;width:90px; text-align: center;'>View</span>");
                    }else if (columndata.status == 'traced') {
                        return("<span style='color: #ffffff;background: #f5a258; padding: 3px 10px; font-size: 12px;width:90px; text-align: center;'>Traced</span>");
                    }
                }
           },
           { text: 'Pieces Traced', datafield: 'piecesTraced', width: '25%', cellsalign: 'center', align: 'center' },
           { text: 'Tracing Date', datafield: 'tracingDate', width: '15%' ,cellsalign: 'center', align: 'center'},
          
        ],
      };

      jqwidgets.createInstance(
        `#${nestedGridContainer.id}`,
        'jqxGrid',
        settings
      );
    }
  };


//   renderer = (row: number, column: any, value: string): string => {
//     return (
//       '<span style="margin-left: 4px; float: left;">' +
//       value +
//       '</span>'
//     );
//   };

//   rowdetailstemplate: any = {
//     rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
//     rowdetailsheight: 220,
//     rowdetailshidden: true,
//   };

//   ready = (): void => {
//     this.myGrid.showrowdetails(0);
//   };

  columns1: any[] = [
    {
        text: "Actions",
        datafield: "id",
        cellsalign: 'center', align: 'center',
        width: 60,
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
           return("<div class='jq-actions' style='margin-left: 5px;'><a  href='edit:" + value + "'><i id = 'edit:" + value +':' + columndata.serviceRecoveryCreatedBy + ':' + columndata.serviceRecoveryPermission + "' class='fa fa-pencil-square-o' aria-hidden='true'   data-toggle='tooltip' title='Edit'></i></a></div>");
        }
    },
    {
        text: "Flight Date",
        datafield: "flightDate",
        width: 100,
        cellsformat: "dd-MM-yyyy",
        filtertype: "date", cellsalign: 'center', align: 'center'
    },
    {
        text: " Create Date",
        datafield: "createdon",
        width: 100,
        cellsformat: "dd-MM-yyyy",
        filtertype: "date",
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Status",
        dataField: "status",
        width: 120,
        cellsalign: 'center', align: 'center',
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
            if (columndata.progressStatus == 'open') {
                return("<span style='color: #ffffff;background: #f15454;padding: 3px 30px;font-size: 12px;width: 90px;text-align: center;'>Open</span>");
            } else if (columndata.progressStatus == 'closed') {
                return("<span style='color: #ffffff; background: #5bbd5b;padding: 3px 25px;font-size: 12px;width: 90px;text-align: center;'>Closed</span>");
            } else {
                return "<span style='color: #ffffff; background: #f5a258;padding: 3px 10px;font-size: 12px;width: 90px;text-align: center;'>" + columndata.progressStatus + "</span>";
            }
        }
    },
    {
        text: "AWB No",
        dataField: "masterAwbNo",
        width: 110,
        cellsalign: 'center', align: 'center'
    }, {
        text: "MAWB Pieces ",
        dataField: "totalMAWBPieces",
        width: 100,
        cellsalign: 'center', align: 'center'
    }, {
        text: "MAWB weight",
        dataField: "totalMAWBWeight",
        width: 100,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Pieces Involved",
        dataField: "piecesInvolved",
        width: 120,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Involved weight",
        dataField: "piecesInvolvedWeight",
        width: 120,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Flight",
        dataField: "flight",
        width: 80,
        cellsalign: 'center', align: 'center'
    }, {
        text: "Aspects By Admin",
        dataField: "recoveryAspcts",
        width: 150,
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Created By",
        dataField: "recoveryCreatedByHandler",
        width: 150,
        cellsalign: 'center', align: 'center'
    },
    {
        text: "Handler Permission",
        dataField: "serviceRecoveryPermission",
        width: 150,
        cellsalign: 'center', align: 'center',
        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, columndata) {
            if (columndata.serviceRecoveryPermission == '1') {
                return("<span style='color: #ffffff;background:#3ba13b;padding: 3px 10px;font-size: 13px;'>True</span>");
            } else if (columndata.serviceRecoveryPermission == '2') {
                return("<span style='color: #ffffff; background: #14ad14;padding: 3px 10px;font-size: 13px;'>False</span>");
            } else {
                return "";//<span style='color: #ffffff; background: #f5841f;padding: 3px 10px;font-size: 13px;'>" + columndata.serviceRecoveryPermission + "</span>
            }
        }
    },
  ];

//=======================archived data grid end=================================================

    ngOnDestroy() {}

    reloadCurrentRoute() {
        console.log("jgbjsdd",);
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        }); 
        console.log("route",currentRoute);
      }


      //=========================================== handler archive===========================================
      archivedhandlersource: any = {
        datafields: [
            { name: 'id' },
          { name: 'id' },
          
          { name: 'serviceRecoveryId' },
          { name: "flightDate", type: "date" },
          { name: "createdon", type: "date" },
          { name: "status", type: "string" },
          { name: "masterAwbNo", type: "string" },
          { name: "totalMAWBPieces", type: "string" },
          { name: "totalMAWBWeight", type: "string" },
          { name: "piecesInvolved", type: "string" },
          { name: "piecesInvolvedWeight", type: "string" },
          { name: "flight", type: "string" },
          { name: "recoveryAspcts", type: "string" },
          { name: "progressStatus", type: "string" },
    
          { name: "recoveryCreatedByHandler", type: "string" },
          { name: "serviceRecoveryPermission", type: "string" },
         
    
        ],
        root: 'serviceRecorveryData',
        id: 'id',
        datatype: 'json',
        url: this.config.getConfig().URL + "getServiceRecoveryCclpPortal.php/getAllServiceRecoveryNew?orgId=" + this.logindet.orgId + "&loginUserId=" + this.logindet.userId + "&orgIdType=" + this.logindet.orgType + "",
        beforeprocessing: (data: any) => {
            console.log("handler archived 927 ===> ",data);
         
        }
    };
    


      //===========================================end handler archive=========================================
}
