import { Component, OnInit, ViewChild } from "@angular/core";
import { generalinfo } from "../../../../app/save-reports/generalinfo";
import { serviceRecoveryinfo } from "../serviceRecoveryinfo";

import { DatePipe, Location, LocationStrategy } from "@angular/common";
import { Compiler } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AmazingTimePickerService } from "amazing-time-picker";
import * as enLocale from "date-fns/locale/en";
import { DatepickerOptions } from "ng2-datepicker";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ViewReportsData } from "src/app/data/DataShareViewDetails";
import { ConfigServiceService } from "../../../../app/config-service.service";
import { CreatereportService } from "../../../../app/services/createreport.service";
import { ShareLoginDataService } from "../../../data/share-login-data.service";
import { NavbarComponent } from "../../../layouts/navbar/navbar.component";
import { SidebarComponent } from "../../../layouts/sidebar/sidebar.component";
import { LoginDataService } from "../../../login/login-data.service";
import { LoginComponent } from "../../../login/login.component";
import { LoginService } from "../../../services/login/login.service";
import { MyAccountService } from "../../../services/myAccount/my-account.service";
import { RegisterService } from "../../../services/register/register.service";
import { ServiceRecovery } from "../../../services/serviceRecovery/recovery.service";
import { StationService } from "../../../services/station/station.service";
import { SuperadminService } from "../../../services/superadmin/superadmin.service";
declare var jQuery: any;
@Component({
  selector: "app-edit-service-recovery-airline",
  templateUrl: "./edit-service-recovery-airline.component.html",
  styleUrls: ["./edit-service-recovery-airline.component.css"],
})
export class EditServiceRecoveryAirlineComponent implements OnInit {
  @ViewChild("stepper") stepper: MatStepper;

  public filteredList: any = [];
  status1: boolean = false;
  loginComponent: LoginComponent;
  secondTB_COmp: boolean = false;
  firstTB_COmp: boolean = false;
  isFlight_Selected_Frm_List: boolean;
  flight0: any;
  flight1: any;
  flight2: any;
  isUnAuthReportAccess: boolean;
  apiCallCurrentTab: any;
  isFromNextButton: boolean;
  loginuserdetailsTest: any;
  items3: any;
  imageslist: any[];
  urlsNew: any = {};
  urlsNew2: any = {};
  urlArrayTotal: any;
  damage_Report_Images: any;
  is_From: any;
  isExtraTabEnable: boolean;
  showitemssq: boolean;
  airwaybillobj = new serviceRecoveryinfo();
  aspectsobj: any = {}; // new serviceRecoveryinfo();
  airlineId: any;
  handlerPermissionValue:any;
  serviceRecoveryHandlerName:any;
  serviceRecoveryHandlerPermission:any;
  shipmentobj = new generalinfo();
  awb: boolean;
  airlinedata: any;
  items1: any;
  items: any;
  showList1: boolean;
  showList: boolean;
  originAirportId: any ='';
  destairportId: any ='';
  affectedAwbarray: any;
  preSelectAffectedAwbarray: any;
  navigationEnable: boolean = false;
  masterAwbNo1: any;
  flightshipmentdata: any = [];
  recoveryAspcts: any;
  isEditable: boolean = false;
  isDisabledForm: boolean = false;
  // handler
  isCloseRecoveryReport: boolean = true;
  isConfirmCloseRecoveryReport: boolean = false;
  thirdTB_COmp: boolean = false;
  recoveryaspctsLable = ["MSCA", "FDCA", "DMA"];
  actionTakenLable = ["Warehouse Check", "Contacted Agent"];
  recoveryAspctsArray: any = [];
  allAirportName: any = [];
  options1: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: "DD-MM-YYYY",
    barTitleFormat: "MMMM YYYY",
    dayNamesFormat: "dd",
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,

    barTitleIfEmpty: "Click to select a date",
    placeholder: "Click to select a date", // HTML input placeholder attribute (default: '')
    addClass: "form-control", // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: "my-date-picker1", // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  preSelectrecoveryaspctsLable: any = [];
  serviceId: any = [];
  serviceReportsProductionUrl: string;
  commentForm: any = {};
  pHandler: any = {};
  dd: any = [];
  dd2: any = [];
  allAirport:any=[];
  findDuplicateVal:any=[];
  handlerpermission : any;
  handlerpermissionText : string;
  createByhandlerName:any;
  permissionHandler:any;
  checkedPermission:boolean=true;
  // 29042024 create by iwi | start
  deleteMoreAirportInfoId:any = [];
  // 29042024 create by iwi | start

  isReopenRecoveryReport: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private navbarComponent: NavbarComponent,
    private DamageReportServicel: CreatereportService,
    private editserviceRecoveryAirline: ServiceRecovery,
    private atp: AmazingTimePickerService,
    private datePipe: DatePipe,
    private ViewReportsData: ViewReportsData,
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public toast: ToastrService,
    private spinner: NgxSpinnerService,
    private LoginDataService: LoginDataService,
    public shareServc: ShareLoginDataService,
    public loginServc: LoginService,
    private myAccountService: MyAccountService,
    private SidebarComponent: SidebarComponent,
    private fb: FormBuilder,
    private superAdminservice: SuperadminService,
    private location: Location,
    private _compiler: Compiler,
    private StationService: StationService,
    private RegisterService: RegisterService,
    private locationStr: LocationStrategy,
    private ServiceRecovery: ServiceRecovery,
    private config: ConfigServiceService
  ) {
    this.loginComponent = new LoginComponent(
      cookieService,
      shareServc,
      SidebarComponent,
      LoginDataService,
      route,
      toast,
      loginServc,
      spinner
    );
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
  }

  fetchEvent() {
    this.spinner.show();
    this.DamageReportServicel.getTruckAirportService().subscribe(
      (data) => {
        if (data.responseCode === 1) {
          this.flightshipmentdata = data.airport_details;
          localStorage.setItem(
            "flightshipmentdata",
            JSON.stringify(data.airport_details)
          );
        }
      },
      (error) => {}
    );
    this.spinner.hide();
  }

  ngAfterViewInit() {
    this.fetchEvent();
  }

  ngOnInit() {
    window.scroll(0, 0);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.serviceId = params["serviceId"];
    });

    this.serviceReportsProductionUrl = this.config.getConfig().URL;
  
    this.fetchEvent();

    this.pHandler.serviceRecoveryId ="";
    this.pHandler.permission ="";

    this.loginuserdetailsTest = JSON.parse(
      localStorage.getItem("loginresultobject")
    );

    if (this.loginuserdetailsTest.orgType == "A" ||this.loginuserdetailsTest.orgType == "H") {
      this.firstTB_COmp = true;
      this.secondTB_COmp = true;
      this.thirdTB_COmp = true;
    } else {
      this.thirdTB_COmp = true;
    }

    this.aspectsobj.flightDate = "";
    this.aspectsobj.masterAwbNo = "";
    this.aspectsobj.airlineId = "";

    this.aspectsobj.originAirportAction = false;
    this.aspectsobj.originAirportHandlerId = 0;
    this.aspectsobj.originAirportHandlerName = "";
    this.aspectsobj.destairportAction = false;
    this.aspectsobj.destairportHandlerId = 0;
    this.aspectsobj.destairportHandlerName = "";

    this.aspectsobj.flightDate = new Date().toISOString();
    this.affectedAwbarray = [
      {
        airportName: "",
        oldAirportName: "",
        airportAction: true,
        airportHandlerId: 0,
        airportHandlerName: "",
      },
    ];

    // this.aspectsobj.categoryId = "10004";
    this.aspectsobj.totalMAWBPieces = "";
    this.aspectsobj.totalMAWBWeight = "";
    this.aspectsobj.piecesInvolved = "";
    this.aspectsobj.piecesInvolvedWeight = "";
    this.aspectsobj.recoveryAspcts = "";
    this.aspectsobj.comment = "";
    this.aspectsobj.status = "";
    this.aspectsobj.flightDate = new Date().toISOString();
    this.aspectsobj.carrierId = "";

    this.filteredList = JSON.parse(localStorage.getItem("flightshipmentdata"));
    this.spinner.show();
    this.DamageReportServicel.getTruckAirlineDetailsService().subscribe(
      (data) => {
        if (data.responseCode === 1) {
          this.airlinedata = data.airline_details;
          localStorage.setItem("airlinedata",JSON.stringify(data.airline_details));
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );

    this.getServiceRecoveryById();

    // /////////////// COMMENT ///////////////////////////////////
    this.commentForm.comment = "";

    // /////////////// COMMENT ///////////////////////////////////
    
    
  }

  getServiceRecoveryById() {
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    this.spinner.show();
    this.ServiceRecovery.getServiceRecoveryById(
      this.loginuserdetailsTest.orgId,this.serviceId,this.loginuserdetailsTest.orgType).subscribe((data) => {
        if (data.responsecode == 1) {
          this.aspectsobj.masterAwbNo = data.data.masterAwbNo;
          this.masterAwbNo1 = data.data.masterAwbNo1;
          this.flight1 = data.data.flight1;
          this.flight2 = data.data.flight2;
          this.aspectsobj.flightDate = data.data.flightDate;
          this.aspectsobj.createdon = data.data.createdon;
          this.aspectsobj.progressStatus = data.data.progressStatus;
          this.aspectsobj.totalMAWBPieces = data.data.totalMAWBPieces;
          this.aspectsobj.totalMAWBWeight = data.data.totalMAWBWeight;
          this.aspectsobj.piecesInvolved = data.data.piecesInvolved;
          this.aspectsobj.piecesInvolvedWeight = data.data.piecesInvolvedWeight;
          this.aspectsobj.comment = data.data.comment;
          this.aspectsobj.files = data.data.files;
          this.aspectsobj.airline = data.data.airlineId;
          this.aspectsobj.status = data.data.status;
          this.createByhandlerName = data.data.serviceRecoveryHandlerName;
          this.permissionHandler = data.data.serviceRecoveryHandlerPermission;
          var permissionHandlerN = this.permissionHandler;
          console.log("298==>", this.aspectsobj.file);
          jQuery(document).ready(function(){
           
            if(permissionHandlerN =="2"){
              jQuery("#flexCheckIndeterminate").prop("checked", false);
            
            }else{
              jQuery("#flexCheckIndeterminate").prop("checked", true);
              jQuery("#flexCheckIndeterminate").addClass("checked");
            
            }

            jQuery(document).on('click','#pills-all-tab',function(){
              jQuery("#serrecacc").addClass('active');
              jQuery(".toggle-accordion").data("closedAll",true);
            });
            
            jQuery(document).on('click','#serrecacc',function(){
              if (jQuery("#serrecacc").hasClass("active")) {
              jQuery(".d-flex.justify-content-between").addClass('collapsed');
              jQuery(" .mt-2.collapse").removeClass('show');
              jQuery("#serrecacc").removeClass('active');
              
              }else{
                jQuery(" .d-flex.justify-content-between").removeClass('collapsed');
                jQuery(".mt-2.collapse").addClass('show');
                jQuery("#serrecacc").addClass('active');
              }
            });
                      
            jQuery(".toggle-accordion").click(function(){
              if (jQuery(this).data("closedAll")) {
                jQuery(".collapse").collapse("show");
                jQuery(this).addClass('active');
              }
              else {
                jQuery(".collapse").collapse("hide");
                jQuery(this).removeClass('active');
              }
              jQuery(this).data("closedAll",!jQuery(this).data("closedAll")); 
            });

            
            jQuery(".toggle-accordion").data("closedAll",true);
          });


          this.aspectsobj.id = data.data.id;
          if (this.aspectsobj.status == "closed") {
            this.isDisabledForm = true;
            this.isCloseRecoveryReport = false;
          } else {
            this.isDisabledForm = false;
          }
          this.recoveryAspctsArray = data.data.recoveryAspcts;
          this.aspectsobj.originAirportAction = data.data.origin_airport.action;
          this.aspectsobj.originAirportId =  data.data.origin_airport.airport_name;
          this.aspectsobj.oldOriginAirportId =  data.data.origin_airport.airport_name;
          this.aspectsobj.originAirportHandlerId =  data.data.origin_airport.handler_id;
          this.aspectsobj.originAirportHandlerName =  data.data.origin_airport.handler_name;

          // if(this.aspectsobj.originAirportId != "") {
          //   var splitOriginAirport = this.aspectsobj.originAirportId.split("-");
          //   this.checkDuplicateAirport(splitOriginAirport[0],splitOriginAirport[1]);
          // }

          this.aspectsobj.destairportAction = data.data.destination_airport.action;
          this.aspectsobj.destairportId = data.data.destination_airport.airport_name;
          this.aspectsobj.oldDestairportId = data.data.destination_airport.airport_name;
          this.aspectsobj.destairportHandlerId = data.data.destination_airport.handler_id;
          this.aspectsobj.destairportHandlerName = data.data.destination_airport.handler_name;

          // if(this.aspectsobj.destairportId != "") {
          //   var splitDestinationAirport = this.aspectsobj.destairportId.split("-");
          //   this.checkDuplicateAirport(splitDestinationAirport[0],splitDestinationAirport[1]);
          // }
          console.log("data.data.more_airport 370",data.data.more_airport);
          if(data.data.more_airport.length > 0){
            this.affectedAwbarray = [];
            for(let val of data.data.more_airport){
              var moreAirportData  ={
                airportInfoId: val.id,
                airportName: val.airport_name,
                oldAirportName: val.airport_name,
                airportAction: val.action,
                airportHandlerId: val.handler_id,
                airportHandlerName: val.handler_name,
              };
              this.affectedAwbarray.push(moreAirportData);
            }
          }
          
          this.preSelectAffectedAwbarray = data.data.more_airport;
          this.aspectsobj.viewHandlerDetails = data.data.viewHandlerDetails;
          console.log("line 394==>",this.aspectsobj.viewHandlerDetails)
        } else {
          this.toast.error("data not found");
        }
        this.spinner.hide();
      },(error) => {
        this.toast.error(error);
      }
    );
    this.spinner.hide();
    
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  previousAirportChangeValue(name, code, i) {
    this.aspectsobj.affectedAwbarray[i].airport_name = name + "-" + code;
    this.aspectsobj.affectedAwbarray[i].edit = true;
  }

  previousAirportSelectAction(value, i) {
    this.aspectsobj.affectedAwbarray[i].action = value;
  }

  moreAirportSelectValue(name, code, i) {
    this.affectedAwbarray[i].airportName = name + "-" + code;
    this.handlerExistsByAirport(code,'moreairport', i);
  }

  moreAirportSelectAction(value, i) {
    this.affectedAwbarray[i].airportAction = value;
  }

  morehawscall() {
    this.affectedAwbarray.push({ airportName: "", airportAction: "" });
  }

  cancelmorehawscall(i,id) {
    console.log("i===>",i);
    console.log("id===>",id);
    // 29042024 create by iwi | start
    this.deleteMoreAirportInfoId.push(id);
    // 29042024 create by iwi | end
    this.affectedAwbarray.splice(i, 1);
  }

  tabModelVAlidationCheck(tab) {
    if (
      this.loginuserdetailsTest != null &&
      this.loginuserdetailsTest != "" &&
      this.loginuserdetailsTest != undefined
    ) {
      this.apiCallCurrentTab = tab;
      if (tab === "firstTab") {
        this.secondTB_COmp = true;
        this.firstTB_COmp = true;
      }
    }
  }

  checkDuplicateAirportName(){
    var allAirport = [];
    allAirport.push(this.originAirportId); 
    allAirport.push(this.destairportId);
    this.affectedAwbarray.forEach(function(key, val) {
       allAirport.push(key.airportName);
    });
    var findDuplicateAirport =  allAirport.filter((item, index) => allAirport.indexOf(item) != index);
    return findDuplicateAirport;
  }
 checkDuplicate(){
  this.allAirport.splice(0, this.allAirport.length); 
  this.allAirport.push(this.aspectsobj.originAirportId); 
  this.allAirport.push(this.aspectsobj.destairportId);  
  for(let i=0;i<this.affectedAwbarray.length;i++)
  {this.allAirport.push(this.affectedAwbarray[i].airportName);
  }
  this.findDuplicateVal = this.allAirport.filter((item, index) => this.allAirport.indexOf(item) != index);
  return  this.findDuplicateVal;
 }

  goForward(stepper: MatStepper, tab, presentTab) {
    // var duplicateAirportName = this.checkDuplicateAirportName();
  //  console.log("chk duplicate===>",duplicateAirportName);
    if (tab === "first") {
      // validaction code start
      this.allAirport.splice(0, this.allAirport.length); 
      this.allAirport.push(this.originAirportId); 
      this.allAirport.push(this.destairportId);
  
      for(let i=0;i<this.affectedAwbarray.length;i++)
      {this.allAirport.push(this.affectedAwbarray[i].airportName);
      }
      const checkDuplicateAirports = !this.allAirportName.some(
        (v, i) => this.allAirportName.indexOf(v) < i
      );

      if (
        this.masterAwbNo1 === undefined ||
        this.masterAwbNo1 === "" ||
        this.masterAwbNo1 === null
      ) {
        this.toast.error("Please enter master airway bill");
        return false;
      } else if (
        this.shipmentobj.incidentDate === "" ||
        this.shipmentobj.incidentDate === null
      ) {
        this.toast.error("Please enter incident date");
        return false;
      } else if (
        this.aspectsobj.originAirportId === "" ||
        this.aspectsobj.originAirportId === null||
        this.aspectsobj.originAirportId === undefined
      ) {
        this.toast.error("Please enter origin airport");
        return false;
      } else if (
        this.aspectsobj.destairportId === "" ||
        this.aspectsobj.destairportId === null ||
        this.aspectsobj.destairportId === undefined
      ) {
        this.toast.error("Please enter destination airport");
        return false;
      } 
      else if (this.flight1 === "" || this.flight1 === null) {
        this.toast.error("Please enter flight");
        return false;
      } else if (this.flight2 === "" || this.flight2 === null) {
        this.toast.error("Please enter number");
        return false;
      }
      else if (this.findDuplicateVal.length) {
        this.toast.error("Please remove duplicate airport");
        this.allAirport.splice(0, this.allAirport.length); 
        return false;
      }
      // validaction code end

      this.isFromNextButton = true;
      this.status1 = !this.status1;
      stepper.next();
    }
    if (tab === "second") {
      var checkAirport =this.checkDuplicate();
      console.log("check====>",checkAirport);

      if (
        this.aspectsobj.totalMAWBPieces === "" ||
        this.aspectsobj.totalMAWBPieces === null
      ) {
        this.toast.error("Please enter MAWB Pieces");
        return false;
      } else if (
        this.aspectsobj.totalMAWBWeight === "" ||
        this.aspectsobj.totalMAWBWeight === null
      ) {
        this.toast.error("Please enter weight");
        return false;
      } else if (
        this.aspectsobj.piecesInvolved === "" ||
        this.aspectsobj.piecesInvolved === null
      ) {
        this.toast.error("Please enter pieces involved");
        return false;
      }else if (
        this.aspectsobj.originAirportId === "" ||
        this.aspectsobj.originAirportId === null||
        this.aspectsobj.originAirportId === undefined
      ) {
        this.toast.error("Please enter origin airport");
        return false;
      } else if (
        this.aspectsobj.destairportId === "" ||
        this.aspectsobj.destairportId === null ||
        this.aspectsobj.destairportId === undefined
      ) {
        this.toast.error("Please enter destination airport");
        return false;
      } 
      else if (checkAirport.length>0) {
        this.toast.error("Please remove duplicate : " + checkAirport[0]);
        this.allAirport.splice(0, this.allAirport.length); 
        return false;
      }
      this.isFromNextButton = true;
      this.status1 = !this.status1;

      this.apiCallCurrentTab = presentTab;
      this.firstTB_COmp = true;
      this.secondTB_COmp = true;
      stepper.next();
    }
    if (tab === "third") {
      stepper.next();
      this.firstTB_COmp = true;
      this.secondTB_COmp = true;
      this.thirdTB_COmp = true;
    }
  }

  masterAwbNochange() {
    this.awb = false;
    this.airlinedata = JSON.parse(localStorage.getItem("airlinedata"));
    if (
      this.airlinedata != "" &&
      this.airlinedata != null &&
      this.airlinedata != undefined
    ) {
      this.airlinedata.forEach((item) => {
        if (item.AWB_prefix === this.aspectsobj.masterAwbNo) {
          this.aspectsobj.airlineId = item.carrierName;
          this.airlineId = item.carrierName;
          this.aspectsobj.carrierId = item.carrierId;
          // if ((this.flight1 === '') || (this.flight1 === undefined)) {
          if (this.aspectsobj.masterAwbNo === "203") {
            // if(this.airlineId === "Airtstname"){
            this.flight1 = "5J";
            this.flight0 = item.AWB_prefix;
            this.airlineId = "Cebu Pacific Air";
            this.aspectsobj.airlineId = this.airlineId;
            this.aspectsobj.carrierId = 182;
          } else {
            this.flight1 = item.IATA;
            this.flight0 = item.AWB_prefix;
            console.log(" flight1 310 ", this.flight1);
            console.log(" flight0 310 ", this.flight0);
          }
          this.awb = true;
          this.isFlight_Selected_Frm_List = true;
        }
      });
    }

    if (this.awb === false) {
      this.aspectsobj.airlineId = "";
      this.airlineId = "";
    }

    this.aspectsobj.masterAwbNo.replace(/[^0-9]*/g, "");
    if (this.aspectsobj.masterAwbNo.length === 3) {
      document.getElementById("_awbSuffix").focus();
    }
  }

  onCancelPage() {
    jQuery("#isLeavePageWithouSave").modal("show");
    let isleavePage = JSON.parse(localStorage.getItem("isSaveReportLeave"));
  }

  onClick_YesLeavepagePopup() {
    jQuery("#isLeavePageWithouSave").modal("hide");
    localStorage.setItem("isSaveReportLeave", JSON.stringify("yes"));
    this.route.navigateByUrl("/csa-service-recovery");
  }

  onClick_NoleavePage() {
    jQuery("#isLeavePageWithouSave").modal("hide");
  }

  // ----------------------airwaybill ,airline , origin ,destination airports search functionalities----------------------------------------
  initializeItems() {
    this.items3 = JSON.parse(localStorage.getItem("airlinedata"));
  }

  getitemssq(ev: any) {
    // this.isFlight_Selected_Frm_List = false;
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev;
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== "") {
      this.initializeItems();
      this.items3 = this.items3.filter((item) =>
        item.IATA.toLowerCase().startsWith(val.toLowerCase())
      );
      // Show the results
      this.showitemssq = true;
    } else {
      // hide the results when the query is empty
      this.showitemssq = false;
    }
  }

  slectflightcall(item) {
    this.aspectsobj.airlineId = item.carrierName;
    this.airlineId = item.carrierName;
    this.aspectsobj.carrierId = item.carrierId;
    this.isFlight_Selected_Frm_List = true;
    this.flight1 = item.IATA;
    this.flight0 = item.AWB_prefix;
    this.showitemssq = false;
  }

  getItems(ev: any) {
    console.log("getItems ev 559", ev);

    this.aspectsobj.originAirportId = "";
    this.initializeItems2();
    const val = ev;
    if (val && val.trim() !== "") {
      this.items1 = this.items1.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
      if (this.items1.length === 0) {
        this.initializeItems2();
        this.items1 = this.items1.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  getItems1(ev: any) {
    this.aspectsobj.destairportId = "";
    // Reset items back to all of the items
    this.initializeItems1();
    // set val to the value of the searchbar
    const val = ev;
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== "") {
      this.items = this.items.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );

      if (this.items.length === 0) {
        this.initializeItems1();
        this.items = this.items.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showList1 = true;
    } else {
      // hide the results when the query is empty
      this.showList1 = false;
    }
  }

  changeairportId() {}

  changeairportIdorigin() {}

  selectresult(item) {
    // var checkDuplicateAirport = this.checkDuplicateAirport(
    //   item.name,
    //   item.code
    // );
    // if (checkDuplicateAirport == true) {
    //   this.aspectsobj.destairportId = item.name + "-" + item.code;
    //   this.destairportId = item.name + "-" + item.code;
    //   this.showList1 = false;
    // }

      this.aspectsobj.destairportId = item.name + "-" + item.code;
      this.destairportId = item.name + "-" + item.code;
      this.showList1 = false;

      this.handlerExistsByAirport(item.code,'destination');
  }

  initializeItems1() {
    this.items = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  initializeItems2() {
    this.items1 = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  initializeMoreAirports() {
    this.filteredList = JSON.parse(localStorage.getItem("flightshipmentdata"));
  }

  selectresultoriginid(item) {
    // var checkDuplicateAirport = this.checkDuplicateAirport(
    //   item.name,
    //   item.code
    // );
    // if (checkDuplicateAirport == true) {
    //   this.aspectsobj.originAirportId = item.name + "-" + item.code;
    //   this.originAirportId = item.name + "-" + item.code;
    //   this.showList = false;
    // }

    this.handlerExistsByAirport(item.code,'origin');

    this.aspectsobj.originAirportId = item.name + "-" + item.code;
    this.originAirportId = item.name + "-" + item.code;
    this.showList = false;

  }

  filterDropdown(e, i) {
    this.initializeMoreAirports();
    const val = e;
    if (val && val.trim() != "") {
      this.filteredList = this.filteredList.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
    }
  }

  spacecall(event) {
    if (event !== undefined && event !== null) {
      this.masterAwbNo1 = event;
      let trimmed = event.replace(/\s+/g, "");
      if (trimmed.length > 16) {
        trimmed = trimmed.substr(0, 16);
      }
      const numbers = [];
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
      this.masterAwbNo1 = numbers.join(" ");
    }
    if (this.masterAwbNo1.length === 9) {
      document.getElementById("next_ToAwb_flightnumber").focus();
    }
  }

  getCheckboxValues(ev, data) {
    let obj = data;
    if (ev.target.checked) {
      // Pushing the object into array
      this.recoveryAspctsArray.push(obj);
    } else {
      let el = this.recoveryAspctsArray.find((itm) => itm === data);
      if (el)
        this.recoveryAspctsArray.splice(
          this.recoveryAspctsArray.indexOf(el),
          1
        );
    }
  }

  isSelectedHandlerRecoveryAspctsLable(topic, index) {
    return (
      this.aspectsobj.viewHandlerDetails[index].recoveryAspctsByhandler.indexOf(
        topic
      ) >= 0
    );
  }

  isSelectedRecoveryAspctsLable(topic) {
    return this.recoveryAspctsArray.indexOf(topic) >= 0;
  }

  // ----------------------------uploadimg-----------------------------------------------

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  blobToBase64(blob, callback) {
    var reader = new FileReader();

    reader.onload = function () {
      var dataUrl = reader.result;

      callback(dataUrl);
    };
    reader.readAsDataURL(blob);
  }

  public static _imagesArrayTotalNew = [];
  public static _imagesArrayTotalData = [];
  selectimages(val, action) {
    const self = this;
    const images = val.target.files;
    if(EditServiceRecoveryAirlineComponent._imagesArrayTotalNew[action] == undefined){
      EditServiceRecoveryAirlineComponent._imagesArrayTotalNew[action] = [];
      EditServiceRecoveryAirlineComponent._imagesArrayTotalData[action] = [];
    }

    for(let i = 0; i < images.length; i++){
      const file: File = images[0];
      if (!file.type.match("image.*|application.*")) {
        this.toast.error("Please select image or pdf only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(",")[1];
        var base64Full = base64String;
        EditServiceRecoveryAirlineComponent._imagesArrayTotalData[action].push(base64Full);
        EditServiceRecoveryAirlineComponent._imagesArrayTotalNew[action].push(base64);
        self.preview(EditServiceRecoveryAirlineComponent._imagesArrayTotalNew[action],action);
      });
    }
  }
  // --------To preview images------------//
  preview(files, action) {
    this.urlsNew[action] = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = "data:image/png;base64," + file;
        // document.body.appendChild(image);
        this.urlsNew[action].push(image);
        // this.urls.push(image);
        this.urlArrayTotal = this.urlsNew[action];
      }
    }
  }

  on_Cancel_Image(i, action) {    
    this.urlsNew[action].splice(i, 1);
    EditServiceRecoveryAirlineComponent._imagesArrayTotalNew[action].splice(i, 1);
    EditServiceRecoveryAirlineComponent._imagesArrayTotalData[action].splice(i, 1);
  }

  on_Cancel_PreviousImage(i) {
    localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    this.damage_Report_Images.splice(i, 1);
  }

  _isImagesSelected() {}


  // ---------------------------End images uploadimg-----------------------------------------------

  _submitServiceRecovery() {
   // var duplicateAirportName = this.checkDuplicateAirportName();
   var checkAirport =this.checkDuplicate();
 
    if (
      this.aspectsobj.totalMAWBPieces === "" ||
      this.aspectsobj.totalMAWBPieces === null
    ) {
      this.toast.error("Please enter MAWB Pieces");
      return false;
    } else if (
      this.aspectsobj.totalMAWBWeight === "" ||
      this.aspectsobj.totalMAWBWeight === null
    ) {
      this.toast.error("Please enter weight");
      return false;
    } else if (
      this.aspectsobj.piecesInvolved === "" ||
      this.aspectsobj.piecesInvolved === null
    ) {
      this.toast.error("Please enter pieces involved");
      return false;
    }else if (
      this.aspectsobj.originAirportId === "" ||this.aspectsobj.originAirportId === null ||
      this.aspectsobj.originAirportId === undefined
    ) {
      this.toast.error("Please enter origin airport");
      return false;
    } else if (
      this.aspectsobj.destairportId === "" ||
      this.aspectsobj.destairportId === null || this.aspectsobj.destairportId === undefined
    ) {
      this.toast.error("Please enter destination airport");
      return false;
    }
    else if (checkAirport.length>0) {
      this.toast.error("Please remove duplicate : " + checkAirport[0]);
      this.allAirport.splice(0, this.allAirport.length); 
      return false;
    }
    // else if (this.findDuplicateVal.length) {
    //   this.toast.error("Please remove duplicate airport");
    //   this.allAirport.splice(0, this.allAirport.length); 
    //   return false;
    // }
    
    // else  if(duplicateAirportName.length > 0 ){
    //   this.toast.error("Please remove duplicate : "+duplicateAirportName[0]);
    //     return false;
    //   }
    this.aspectsobj.masterAwbNo1 = this.masterAwbNo1;
    this.aspectsobj.userId = this.loginuserdetailsTest.userId;
    this.aspectsobj.orgId = this.loginuserdetailsTest.orgId;
    this.aspectsobj.flight1 = this.flight1;
    this.aspectsobj.flight2 = this.flight2;
    this.aspectsobj.comment = this.aspectsobj.comment;
    this.aspectsobj.recoveryAspcts = this.recoveryAspctsArray;
    this.aspectsobj.id = this.serviceId;
    this.aspectsobj.affectedAwbarray = this.affectedAwbarray;
    var arrayIndexName = "comment";
    this.aspectsobj.handlerPermissionValue = this.handlerpermission;
    this.aspectsobj.serviceRecoveryHandlerName=this.createByhandlerName;  
    this.aspectsobj.serviceRecoveryHandlerPermission=this.permissionHandler;  
    this.aspectsobj.deleteMoreAirportInfoId = this.deleteMoreAirportInfoId;
    if (EditServiceRecoveryAirlineComponent._imagesArrayTotalData2 !== undefined) {
      this.dd2 = "";
      for (
        let i = 0;
        i < EditServiceRecoveryAirlineComponent._imagesArrayTotalData2.length;
        i++
      ) {
        if (
          i + 1 ===
          EditServiceRecoveryAirlineComponent._imagesArrayTotalData2.length
        ) {
          this.dd2 =
            this.dd2 + EditServiceRecoveryAirlineComponent._imagesArrayTotalData2[i];
        } else {
          this.dd2 =
            this.dd2 +
            EditServiceRecoveryAirlineComponent._imagesArrayTotalData2[i] +
            "#..";
        }
      }
      this.aspectsobj.imgUrl = this.dd2;
    }
    
    // console.log("this.aspectsobj  978", this.aspectsobj);
    // return false;
    this.ServiceRecovery.updateServiceRecoveryByAirline(this.aspectsobj).subscribe((data) => {
      this.spinner.hide();
      if (data.responseCode === 1) {
        this.ngOnInit();
        this.toast.success("Services Report Has been update successfully");
        this.urlsNew2 = [];
      } else if (
        data.responseCode === 0 &&
        data.message === "Unauthorized User"
      ) {
        this.toast.info("Your session is expired, please login again");
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toast.error(data.saveDataReport.message);
      }
    },
    (error) => {
        this.toast.error("Report is not generated due to Low Network issue.");
    }
    );
  }
  // ---------------toggle eitable----------------------------//
  toggleEditable() {
    this.isEditable = !this.isEditable;
  }
  // -------------------end toggle editable ----------------------------//

  // --------------- Recovery Report ----------------------------//
  closeRecoveryReport() {
    this.isCloseRecoveryReport = !this.isCloseRecoveryReport;
    if (this.isCloseRecoveryReport == false) {
      this.commentForm.comment = "Report closed by airline";
    } else {
      this.commentForm.comment = "";
    }
  }

  onClick_YesCloseReport() {
    jQuery("#isRecoveryReportClose").modal("hide");
    this.isCloseRecoveryReport = false;
    this.isConfirmCloseRecoveryReport = true;
    this.onSubmitComment("all", "2", 0, 0);
  }

  onClick_NoCloseReport() {
    this.isCloseRecoveryReport = true;
    this.isConfirmCloseRecoveryReport = false;
    jQuery("#isRecoveryReportClose").modal("hide");
    this.commentForm.comment = "";
  }

  // -------------------end  Recovery Report  ----------------------------//

  // ---------------comment form----------------------------//
  onSubmitComment(airportName, sendBy, handlerId, serviceRecoveryId,handlerName='') {
    if(this.aspectsobj.viewHandlerDetails === undefined ||  this.aspectsobj.viewHandlerDetails.length == 0){
      this.toast.error("No handler available");
      return false;
      
    }
    if (
      this.isCloseRecoveryReport == false &&
      this.isConfirmCloseRecoveryReport == false
    ) {
      jQuery("#isRecoveryReportClose").modal("show");
      return false;
    }

    this.commentForm.airportName = airportName;
    if (airportName == "all") {
      this.commentForm.serviceRecoveryid = this.serviceId;
    } else {
      this.commentForm.serviceRecoveryid = serviceRecoveryId;
    }
    this.commentForm.sendBy = sendBy;
    this.commentForm.handlerId = handlerId;
    this.commentForm.orgId = this.loginuserdetailsTest.orgId;
    this.commentForm.orgType = this.loginuserdetailsTest.orgType;
    this.commentForm.isCloseRecoveryReport = this.isConfirmCloseRecoveryReport;
    this.commentForm.userId = this.loginuserdetailsTest.userId;

    if (EditServiceRecoveryAirlineComponent._imagesArrayTotalData[airportName] !== undefined) {
      this.dd = "";
      for(let i = 0;i <EditServiceRecoveryAirlineComponent._imagesArrayTotalData[airportName].length;i++) {
        if (
          i + 1 ===
          EditServiceRecoveryAirlineComponent._imagesArrayTotalData[airportName]
            .length
        ) {
          this.dd =  this.dd +  EditServiceRecoveryAirlineComponent._imagesArrayTotalData[airportName
            ][i];
        } else {
          this.dd =
            this.dd +
            EditServiceRecoveryAirlineComponent._imagesArrayTotalData[
              airportName
            ][i] +
            "#..";
        }
      }
      this.commentForm.files = this.dd;
    }
     if(this.commentForm.comment===''){
      this.toast.error("Comment should not be blank");
      return false
     }
    this.spinner.show();
    this.ServiceRecovery.saveRecoveryDiscussionData(this.commentForm).subscribe(
      (data) => {
        if (data.responsecode == 1) {
          this.toast.success(data.message);
          EditServiceRecoveryAirlineComponent._imagesArrayTotalData[
            airportName
          ] = [];
          this.urlsNew[airportName] = [];
          this.commentForm.comment = "";
          this.dd = "";
          this.commentForm.files = "";
          // message data append start
            this.ServiceRecovery.getServiceRecoveryById(
              this.loginuserdetailsTest.orgId,this.serviceId,this.loginuserdetailsTest.orgType,handlerName).subscribe((data) => {
                if (data.responsecode == 1) {
                  this.aspectsobj.viewHandlerDetails = data.data.viewHandlerDetails;
                  this.aspectsobj.progressStatus = data.data.progressStatus;
                  this.aspectsobj.status = data.data.status;
                  if(data.data.status == "closed"){
                    this.isDisabledForm = true;
                    this.isEditable = false;
                  }
                } else {
                  this.toast.error("data not found");
                 
                }
                this.spinner.hide();
              },(error) => {
                this.toast.error(error);
              }
            );
           // message data append end
        } else {
          this.toast.error("please try again later.");
        }
        this.spinner.hide();
      },
      (error) => {
        this.toast.error("Report is not generated due to network issue.");
        this.spinner.hide();
      }
    );
  }
  // ---------------comment form----------------------------//

  // openModal(i, image_name) {
  //   const modal = document.getElementById("myModal" + i + image_name);
  //   modal.style.display = "block";
  // }

  openModal(handDetiValId: number, f: number, image: string) {
    const modalId = 'myModal' + handDetiValId + f + image;
    const modal = document.getElementById(modalId);
    if (modal) {
        modal.style.display = 'block'; 
        console.error('Modal element not found');
    }

}

closeModal(handDetiValId: number, f: number, image: string) {
  const modalId = 'myModal' + handDetiValId + f + image;
  const modal = document.getElementById(modalId);
  if (modal) {
      modal.style.display = 'none'; 
  } else {
      console.error('Modal element not found');
  }
}

  // closeModal(i, image_name) {
  //   const modal = document.getElementById("myModal" + i + image_name);
  //   modal.style.display = "none";
  // }

  openAirlineMsgModal(i) {
    const modal = document.getElementById("airlineMsgModal" + i);
    modal.style.display = "block";
  }
  closeAirlineMsgModal(i) {
    const modal = document.getElementById("airlineMsgModal" + i);
    modal.style.display = "none";
  }
  checkDuplicateAirport(airport, airportCode) {
    this.handlerExistsByAirport(airportCode,'');
    if (this.allAirportName.indexOf(airport) === -1) {
      this.allAirportName.push(airport);
      return true;
    } else {
      // this.allAirportName.push(airport);
      this.toast.error(airport + "  already added");
      // this.ngOnInit();
      return false;
    }
  }

  handlerExistsByAirport(airport,action,index=0) {
    this.spinner.show();
    let handlerExistsPayload: any = {};
    handlerExistsPayload.portCode = airport;
    // console.log("handlerExistsPayload", handlerExistsPayload);
    this.ServiceRecovery.checkHandlerExistsByAirport(handlerExistsPayload).subscribe((data) => {
        

        if(action == 'origin' && data.responseCode == 1) {
          this.aspectsobj.originAirportHandlerId   = data.handlerId;
          this.aspectsobj.originAirportHandlerName = data.handlerName;
        }else if(action == 'origin' && data.responseCode == 0){
          this.aspectsobj.originAirportHandlerId   = 0;
          this.aspectsobj.originAirportHandlerName = "No handler available";
        }

        if(action == 'destination' && data.responseCode == 1) {
          this.aspectsobj.destairportHandlerId   = data.handlerId;
          this.aspectsobj.destairportHandlerName = data.handlerName;
        }else if(action == 'destination' && data.responseCode == 0){
          this.aspectsobj.destairportHandlerId   = 0;
          this.aspectsobj.destairportHandlerName = "No handler available";
        }

        if(action == 'moreairport' && data.responseCode == 1) {
          this.affectedAwbarray[index].airportHandlerId =  data.handlerId;
          this.affectedAwbarray[index].airportHandlerName      =  data.handlerName;
        }else if(action == 'moreairport' && data.responseCode == 0){
          this.affectedAwbarray[index].airportHandlerId =  0;
          this.affectedAwbarray[index].airportHandlerName =  "No handler available";
        }
        // if (data.responseCode == 0) {
        //   this.toastr.info("No handler available with this airport");
        // }
        this.spinner.hide();
      },
      (error) => {
        this.toast.error("Report is not generated due to network issue.");
        this.spinner.hide();
      }
    );
  }
  // togglepermission() {
  //   this.handlerpermission = !this.handlerpermission;
  // }

  onCancelPermission(event) {
    jQuery("#handlerPermisionClose").modal("show");

    // this.handlerpermission = true;
    
    if(jQuery("#flexCheckIndeterminate").attr('value')=="1"){
      
      this.handlerpermissionText = "Revoke permission form handler ?";
    }
    else{
      this.handlerpermissionText = "Do you want to give permission to handler ?";
    }
    // event.target.checked = true;
    // console.log("value", event.target.value)
  
  }

  onClick_YesPermission(event) {
    jQuery("#handlerPermisionClose").modal("hide");
      
     console.log("poermission====>",this.handlerpermission);
     if(jQuery("#flexCheckIndeterminate").attr('value')=="1"){
      jQuery("#flexCheckIndeterminate").prop("checked", false);
      this.handlerpermission = '2';
      // alert('d')
      // this.permissionHandler = "2";
      jQuery("#flexCheckIndeterminate").attr("value", "2");
      // console.log("aaa", this.permissionHandler)
      
     }
     else{
      this.handlerpermission = '1';
      jQuery("#flexCheckIndeterminate").prop("checked", true);
      jQuery("#flexCheckIndeterminate").addClass("checked");
      jQuery("#flexCheckIndeterminate").attr("value", '1');
     }

      this.pHandler.serviceRecoveryId = this.aspectsobj.id;
      this.pHandler.permission= this.handlerpermission;
      this.ServiceRecovery.updatePermissionHandlerStatus(this.pHandler).subscribe((data) => {
      this.toast.success("Permission updated successfully");
      },
      (error) => {
        this.toast.error("Permission not updated due to network issue.");
       
      }
    );

}


  


  onClick_NoPermission(event) {
    jQuery("#handlerPermisionClose").modal("hide");
    if(jQuery("#flexCheckIndeterminate").attr('value')=="1"){
      jQuery("#flexCheckIndeterminate").prop("checked", true);
      jQuery("#flexCheckIndeterminate").attr("value", '1');
     }
     else{
      jQuery("#flexCheckIndeterminate").prop("checked", false);
      jQuery("#flexCheckIndeterminate").removeClass("checked");
      jQuery("#flexCheckIndeterminate").attr("value", '2');
     }
    // jQuery("#flexCheckIndeterminate").prop("checked", false);
    // jQuery("#flexCheckIndeterminate").removeClass("checked");
    // this.handlerpermission=false;
    // event.value = false;
    // jQuery("#flexCheckIndeterminate").attr("value", this.handlerpermission);
    // event.target.checked = false;
    // console.log("value 2", event);
    
  }
  onAllowPermission(event) {
    jQuery("#handlerAllowPermisionClose").modal("show");
     this.handlerpermission = false;
     event.target.checked = true
     
  }
  onClick_allowPermission(event) {
    jQuery("#handlerAllowPermisionClose").modal("hide");
    this.handlerpermission = !this.handlerpermission;
    console.log("yessss",this.handlerpermission);
  }

  onClick_deniedPermission(event) {
    jQuery("#handlerAllowPermisionClose").modal("hide");
    this.handlerpermission=false;
    event.target.checked = false;
    
  }
  
  
  // --------------- Recovery Report ----------------------------//
  reopenRecoveryReport() {
    jQuery("#isRecoveryReportReopen").modal("show");
    return false;
  }

  onClick_YesReopenReport() {
    jQuery("#isRecoveryReportReopen").modal("hide");
    this.isReopenRecoveryReport = true;
    var payload = { 'serviceRecoveryId' : this.serviceId };
    this.ServiceRecovery.reopenServicerecovery(payload).subscribe((data) => {
      if(data.responseCode == 1){
        this.toast.success(data.message);
        this.isDisabledForm = false;
        this.isEditable = true;
        this.isCloseRecoveryReport = true;
        this.aspectsobj.status = data.status;
        this.aspectsobj.progressStatus = data.status;
      }else{
        this.toast.error("Please try again later.");
      }
    },(error) => {
      this.toast.error("Permission not updated due to network issue.");
    });

  }

  onClick_NoReopenReport() {
    jQuery("#isRecoveryReportReopen").modal("hide");
    this.isReopenRecoveryReport = false;
  }

  reloadCurrentRoute() {
    const currentRoute = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.route.navigate([currentRoute]);
    }); 
  }

  ngOnDestroy() {
    this.spinner.hide();
  }

  //-----------//
  public static _imagesArrayTotalNew2 = [];
  public static _imagesArrayTotalData2 = [];
  selectimages2(val) {
    const self = this;
    const images = val.target.files;

    for (let i = 0; i < images.length; i++) {
      const file: File = images[0];
      if (!file.type.match("image.*|application.*")) {
        this.toast.error("Please select image or pdf only.");
        return;
      }
      this.blobToBase64(images[i], function (base64String) {
        var base64 = base64String.split(",")[1];
        var base64Full = base64String;
        EditServiceRecoveryAirlineComponent._imagesArrayTotalData2.push(base64Full);

        EditServiceRecoveryAirlineComponent._imagesArrayTotalNew.push(base64);
        self.preview2(EditServiceRecoveryAirlineComponent._imagesArrayTotalNew);
      });
    }
  }

  preview2(files) {
    this.urlsNew2 = [];
    if (files.length != 0) {
      for (let file of files) {
        var image = new Image();
        image.src = "data:image/png;base64," + file;
        // document.body.appendChild(image);
        this.urlsNew2.push(image);
        // this.urls.push(image);
        this.urlArrayTotal = this.urlsNew2;
      }
    }
  }

  on_Cancel_Image2(i) {
    EditServiceRecoveryAirlineComponent._imagesArrayTotalNew2.splice(i, 1);
    EditServiceRecoveryAirlineComponent._imagesArrayTotalData2.splice(i, 1);
    this.urlsNew2.splice(i, 1);
  }

  on_Cancel_PreviousImage2(i) {
    localStorage.setItem("isSaveReportLeave", JSON.stringify("no"));
    this.damage_Report_Images.splice(i, 1);
  }


  
}
