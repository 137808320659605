import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigServiceService } from 'src/app/config-service.service';

@Injectable({
  providedIn: 'root'
})
export class CargoAcceptanceService {

  WEB_API: string;
  constructor(private http: HttpClient, config: ConfigServiceService) {
    this.WEB_API = config.getConfig().ULD_Url;
  }

  createCargo(form) {
    return this.http.post(`${this.WEB_API}cargoAccept/CargoAccept.php/createCargo`, form);
  }

  getCargoPackingAndLocation(id: number) {
    return this.http.get(`${this.WEB_API}cargoAccept/getCargoAccept.php/cargoData?loginUserId=${id}`);
  }

  api_To_Archive_cargoacceptance(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/archiveCargoAccept.php/archiveCargo`, inputData);
  }

  api_To_GetCargo_Details(inputData) {
    console.log(inputData);
    return this.http.post(`${this.WEB_API}cargoAccept/getDetailedCargoAccept.php/detailedCargoAccept`, inputData);
  }

  uniqueShipmentNumber(){
    return this.http.get(`${this.WEB_API}cargoAccept/getAcceptList.php/generateShipmentNumber`);
  }
  updateCargo(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/editCargoAccept.php/editCargo`, inputData);
  }

  createShipmentConsolidate(inputData) {
    return this.http.post(`${this.WEB_API}exportConsolidate/shipmentsConsolidate.php/createConsolidate`, inputData);
  }

  updateStorageLocation(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/editStorageLocation.php/editStorageLoc`, inputData);
  }

  getNestedHouseAwbs(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/ImportAcceptance/GetHawbList.php/getHouseAwbs`, inputData);
  }

  getSubNestedHouseAwbs(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/ImportAcceptance/GetHawbData.php/hawbData`, inputData);
  }

  createImport(data) {
    return this.http.post(`${this.WEB_API}cargoAccept/ImportAcceptance/CreateImportAccept.php/createImportAccept`, data);
  }

  get_airport_list(obj) {
    return this.http.get(`${this.WEB_API}cargoAccept/ImportAcceptance/GetStations.php/getStations?orgId=`+obj.orgId+`&loginUserId=`+obj.loginUserId);
  }

  api_To_GetImport_Details(inputData) {
    return this.http.post(`${this.WEB_API}cargoAccept/ImportAcceptance/DetailedImportAccept.php/getDetailedImport`, inputData);
  }

  updateImport(data) {
    return this.http.post(`${this.WEB_API}cargoAccept/ImportAcceptance/EditImportAccept.php/editImport`, data);
  }
}
