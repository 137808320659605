import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigServiceService } from "../../config-service.service";
const headers = new HttpHeaders({
  "Content-Type": "application/json; charset=utf-8",
});

@Injectable({
  providedIn: "root",
})
export class ServiceRecovery {
  API_URL: string;

  constructor(public http: HttpClient, config: ConfigServiceService) {
    this.API_URL = config.getConfig().URL;
  }

  getServiceRecovery(orgId, orgIdType) {
    return this.http.get<any>(
      this.API_URL +
        "getServiceRecoveryCclpPortal.php/getAllServiceRecovery?orgId=" +
        orgId +
        "&orgIdType=" +
        orgIdType,
      { headers: headers }
    );
  }

  getServiceRecoveryById(orgId, serInfoId, orgIdType, handlerName='') {
    console.log("handlerName : ",handlerName);
    return this.http.get<any>(
      this.API_URL +
        "getServiceRecoveryCclpPortal.php/getServiceRecoveryById?orgId=" +
        orgId +
        "&serInfoId=" +
        serInfoId +
        "&orgIdType=" +
        orgIdType +
        "&handlerShortName=" +
        handlerName,
      { headers: headers }
    );
  }

  saveServiceRecovery(data) {
    return this.http.post<any>(
      this.API_URL +
        "serviceRecoveryCclpPortalSave.php/saveServiceRecoveryData",
      data,
      { headers: headers }
    );
  }

  updateServiceRecovery(data) {
    return this.http.post<any>(
      this.API_URL +
        "serviceRecoveryCclpPortalSave.php/updateServiceRecoveryData",
      data,
      { headers: headers }
    );
  }

  updateServiceRecoveryByAirline(data){
    return this.http.post<any>(
      this.API_URL + "serviceRecoveryCclpPortalSave.php/updateServiceRecoveryByAirline", data,{ headers: headers }
    );
  }

  updateServiceRecoveryHandlerStatus(data) {
    return this.http.post<any>(
      this.API_URL + 
        "serviceRecoveryCclpPortalSave.php/updateServiceRecoveryViewStatus",
      data,
      { headers: headers }
    );
  }

  saveRecoveryDiscussionData(data) {
    return this.http.post<any>(
      this.API_URL +
        "serviceRecoveryCclpPortalSave.php/saveRecoveryDiscussionData",
      data,
      { headers: headers }
    );
  }

  checkHandlerExistsByAirport(data) {
    return this.http.post<any>(
      this.API_URL +
        "getServiceRecoveryCclpPortal.php/checkHandlerExistsByAirport",
      data,
      { headers: headers }
    );
  }
  

  getAllAirlineData() {
    return this.http.get<any>(
      this.API_URL + "getServiceRecoveryCclpPortal.php/getAllAirlineData",
      { headers: headers }
    );
  }

  updatePermissionHandlerStatus(data) {
    return this.http.post<any>(
      this.API_URL + 
        "serviceRecoveryCclpPortalSave.php/handlerPermissionUpdate",
      data,
      { headers: headers }
    );
  }
  reopenServicerecovery(data) {
    return this.http.post<any>(
      this.API_URL + 
        "serviceRecoveryCclpPortalSave.php/reopenServiceRecovery",
      data,
      { headers: headers }
    );
  }

  archivedUpdateserviceRecovery(data){
    return this.http.post<any>(
      this.API_URL + 
        "serviceRecoveryCclpPortalSave.php/updateArchiveServiceRecovery",
      data,
      { headers: headers }
    );
  }
}
