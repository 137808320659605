import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DeactivatestationemailsService } from 'src/app/services/dsc/deactivatestationemails.service';
import { ToastrService } from 'ngx-toastr';
import { globalConstants } from 'src/environments/globalConstants';
declare var jQuery: any;
@Component({
    selector: 'app-edit-dse',
    templateUrl: './edit-dse.component.html',
    styleUrls: ['./edit-dse.component.css']
})
export class EditDSEComponent implements OnInit {
    editActivationEmails: any;
    editAddDSEForm: FormGroup;
    FormBuilder: any;
    isReadonly: boolean;
    orgDetails: any;

    constructor(private router: Router,
     private DeactivatestationemailsService: DeactivatestationemailsService,
      private formBuilder: FormBuilder, private toastr: ToastrService,private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.isReadonly = true
        this.editActivationEmails = JSON.parse(localStorage.getItem('editActivationEmails'));
    
        this.editAddDSEForm = this.formBuilder.group({
            'stationCode': new FormControl(this.editActivationEmails.stationCode, [Validators.required]),
            'emailId': new FormControl(this.editActivationEmails.emailId, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)])
        });
    }

    editDeactStationsPopUp(){
         jQuery('#editdeactivationemail').modal({ backdrop: 'static', keyboard: false });
    }

    updateDeactStations() {
        ;
        this.orgDetails = JSON.parse(localStorage.getItem('comapanydetails'));
        let obj = {
            "repStationId": this.editActivationEmails.repStationId,
            "loginUserId": this.orgDetails.userId,
            "emailID": this.editAddDSEForm.controls['emailId'].value
        }
        this.DeactivatestationemailsService.editDeactivateStationEmail(obj).subscribe(data => {
            ;

            console.log('this.DeactivatestationemailsService', data);
            if (data.responseCode === 1) {
                this.spinner.hide();
                jQuery('#editdeactivationemail').modal('hide');
                this.toastr.success(data.message, '', { timeOut: 1200 });
                this.router.navigateByUrl('/viewdeactivatestationemail');
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.spinner.hide();
                jQuery('#editdeactivationemail').modal('hide');
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
                this.spinner.hide();
                jQuery('#editdeactivationemail').modal('hide');
                this.toastr.error(data.message, '', { timeOut: 1200 });
            }
        }, error => {
             this.spinner.hide();
             jQuery('#editdeactivationemail').modal('hide');
            this.toastr.error(error.message, '', { timeOut: 1200 })
        })
    }
}