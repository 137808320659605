import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var jQuery: any;
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormGroupDirective,
  AbstractControl,
  FormArray,
} from "@angular/forms";
import { StationService } from "../../services/stations/stations.service";
@Component({
  selector: "app-addstations",
  templateUrl: "./addstations.component.html",
  styleUrls: ["./addstations.component.css"],
})
export class AddstationsComponent implements OnInit {
  Logindetails: any;
  addStationForm: FormGroup;
  airportDetails = [];
  stationcodeData: any;
  showListstation: boolean;
  items: any;
  stationCode: any;
  comapanydetails: any;
  orgId: any;
  minus: boolean;
  secondaryEmailIds: any[];
  isSubmitEnable: boolean;
  val: any;
  index: any;
  stationCodeCheck: any;
  CompanyLogoimgurl: string;
  CompanyName: any;
  // emailsData: any;
  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private stationService: StationService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    this.stationCodeCheck = "";
  }
  initilizeStationCodeData() {
    this.items = JSON.parse(localStorage.getItem("stationsList"));
    //this.droplist=true;
  }
  getstationItems(ev: any) {
    // Reset items back to all of the items
    this.initilizeStationCodeData();
    // set val to the value of the searchbar
    const val = ev;
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== "") {
      this.initilizeStationCodeData();
      this.stationcodeData = this.items.filter((item) =>
        item.code.toLowerCase().startsWith(val.toLowerCase())
      );
      if (this.stationcodeData.length === 0) {
        this.stationcodeData = this.stationcodeData.filter((item) =>
          item.code.toLowerCase().startsWith(val.toLowerCase())
        );
      }
      // Show the results
      this.showListstation = true;
    } else {
      // hide the results when the query is empty
      this.showListstation = false;
    }
  }
  stationcodekeyup() {
    this.stationCodeCheck = "";
  }
  leavestationcode() {
    this.addStationForm.controls["stationCode"].value;
    if (
      this.stationCodeCheck === "" &&
      this.addStationForm.controls["stationCode"].value !== ""
    ) {
      this.addStationForm.controls["stationCode"].setValue("");
      this.toastr.error("Please select stationCode from list", "", {});
    }
  }
  selectstationcode(item) {
    this.stationCodeCheck = item.code;
    let val = item.code + "-" + item.name;
    this.addStationForm.get("stationCode").patchValue(item.code);
    this.showListstation = false;
  }
  ngOnInit() {
    this.isSubmitEnable = false;
    window.scroll(0, 0);
    let loginInformation = JSON.parse(localStorage.getItem("loginDetails"));
    this.spinner.show();
    this.stationService.GetStationCodes().subscribe(
      (data) => {
        this.airportDetails = data.airport_details;
        localStorage.setItem(
          "stationsList",
          JSON.stringify(this.airportDetails)
        );
        data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
    // ------------validation------------//
    let EMAILPATTERN =
      "(?!.*@.*@)[a-zA-Z0-9.-_]{1,}@{1,}[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}";
    this.addStationForm = this.formBuilder.group({
      contactName: new FormControl(""),
      stationCode: new FormControl("", [Validators.required]),
      orgId: new FormControl(""),
      emailId: new FormControl(""),
      secondarymail1: this.formBuilder.array([
        this.formBuilder.group({
          email: new FormControl("", [
            Validators.required,
            Validators.pattern(EMAILPATTERN),
          ]),
        }),
      ]),
      address: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      secondaddress: new FormControl(""),
      postalcode: new FormControl(""),
      latitude: new FormControl(""),
      longitude: new FormControl(""),
    });
    // ------------validation------------//
    this.addStationForm.controls["orgId"].setValue(loginInformation.orgId);
    this.addStationForm.controls["secondarymail1"].valueChanges.subscribe(
      (value) => {
        value;
        this.fn_To_Enable_Submit();
      }
    );
  }
  addStation(formData) {
    if (this.stationCodeCheck === "") {
      this.addStationForm.controls["stationCode"].setValue(null);
    } else {
      this.Logindetails = JSON.parse(localStorage.getItem("loginDetails"));
      formData.emailId = formData.secondarymail1[0].email;
      formData.secondarymail = [];
      for (let i = 0; i < formData.secondarymail1.length; i++) {
        if (i > 0) {
          formData.secondarymail.push(formData.secondarymail1[i].email);
        }
      }
      formData.createdBy = this.Logindetails.userId;
      if (this.Logindetails.userType === "SA") {
        formData.orgId = this.comapanydetails.orgId;
      } else {
        formData.orgId = this.Logindetails.orgId;
      }
      this.spinner.show();
      this.stationService.AddStations(formData).subscribe(
        (data) => {
          data;
          this.spinner.hide();
          if (data.status == "1") {
            this.toastr.success("Successfully created", "", { timeOut: 1500 });
            this.router.navigateByUrl("/cargoClaim/viewstation");
            // this.router.navigate(["viewstations"], { queryParams: { from: 'ViewUsersStations' } });
          } else {
            this.toastr.error(data.message, "", {});
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }
  gotoback() {
    this.router.navigateByUrl("/cargoClaim/viewstation");
    // this.router.navigate(['/viewstation'], { queryParams: { from: 'ViewUsersStations' } });
  }
  // ----------------emailpart-------------------------------//
  get emailsData() {
    return this.addStationForm.get("secondarymail1") as FormArray;
  }
  add_email_Field() {
    let EMAILPATTERN = /(?!.\.{2})^([a-z\d!#$%&@'+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-.~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    this.emailsData.push(
      this.formBuilder.group({
        email: new FormControl("", [
          Validators.required,
          Validators.pattern(EMAILPATTERN),
        ]),
      })
    );
  }
  delete_email_Field(index) {
    this.index = index;
    jQuery("#delete-confirmation").modal({
      backdrop: "static",
      keyboard: false,
    });
  }
  confirm_delete_email_Field() {
    if (this.emailsData.length > 1) {
      this.emailsData.removeAt(this.index);
    }
    jQuery("#delete-confirmation").modal("hide");
  }
  getEmailIds(form): Array<any> {
    //
    return form.controls.secondarymail1.controls;
  }
  findDuplicate(email, p): boolean {
    //
    let myArray = this.getEmailIds(this.addStationForm);
    let totalArray = [];
    if (email != "" && email != null && email != undefined) {
      for (let i = 0; i < myArray.length; i++) {
        totalArray.push(myArray[i].value);
      }
      this.fn_To_Enable_Submit();
      let test = totalArray.filter(
        (data) => data.email == email && email != ""
      );
      return test.length > 1 ? true : false;
    }
  }
  fn_To_Enable_Submit() {
    //
    let totalArray = [];
    const controlArray = <FormArray>this.addStationForm.get("secondarymail1");
    for (let i = 0; i < controlArray.value.length; i++) {
      totalArray.push(controlArray.value[i].email);
    }
    ("working");
    this.isSubmitEnable = this.find_duplicate_in_array(totalArray);
  }
  find_duplicate_in_array(array) {
    //
    return new Set(array).size !== array.length;
  }
  // ----------------emailpart-------------------------------//
}
