import { Component, OnInit } from '@angular/core';
import { AddAirlineEmailsService } from 'src/app/services/addAirlineEmail/add-airline-emails.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;
@Component({
  selector: 'app-view-airline-emails',
  templateUrl: './view-airline-emails.component.html',
  styleUrls: ['./view-airline-emails.component.css']
})
export class ViewAirlineEmailsComponent implements OnInit {

  unauthp = 1;
  unauthpageIndex = 0;
  unauthpageSize = 10;
  unauthlowValue = 0;
  unauthhighValue = 10;

  airlinesEmailData: any;
  deleteInput: any;
  public static loginInformation;
  constructor(private viewEmailServc: AddAirlineEmailsService, private spinner: NgxSpinnerService, private router: Router, private toastr: ToastrService) {
    // ViewAirlineEmailsComponent.loginInformation = JSON.parse(localStorage.getItem('loginresultobject'));
    // this.fn_toGetAirlineEmailData(ViewAirlineEmailsComponent.loginInformation.orgId)
  }

  ngOnInit() {
      window.scroll(0, 0);
    ViewAirlineEmailsComponent.loginInformation = JSON.parse(localStorage.getItem('loginresultobject'));
    this.fn_toGetAirlineEmailData(ViewAirlineEmailsComponent.loginInformation.orgId)
  }

  fn_toGetAirlineEmailData(orgId) {
    this.spinner.show();
    this.viewEmailServc.getAirlineEmails(orgId).subscribe(data => {
      this.spinner.hide();
      console.log("viewEmailServc", data);
      if (data.responseCode == 1 && data.airlineStations.length != 0 ) {
        for (let i = 0; i < data.airlineStations.length; i++) {
          data.airlineStations[i].totalEmailsArray = [];
          data.airlineStations[i].mailAutoIncrementId = [];
          data.airlineStations[i].emailIdsString = '';
          if(data.airlineStationEmails.length != 0){
            for (let j = 0; j < data.airlineStationEmails.length; j++) {
              if (data.airlineStations[i].handlerId === data.airlineStationEmails[j].emailTypeId) {
                data.airlineStations[i].totalEmailsArray.push(data.airlineStationEmails[j].emailId);
                data.airlineStations[i].mailAutoIncrementId.push(data.airlineStationEmails[j].mailAutoIncrementId)
              }
            }
          }
        }
        for (let each of data.airlineStations) {
          each.emailIdsString = this.fn_toString(each.totalEmailsArray);
        }
        this.airlinesEmailData = data.airlineStations;
      }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
        this.toastr.info("Your session is expired, please login again");
    } else{
      
    }
    }, error => {
      this.spinner.hide();
    });
  }

  fn_toString(inputData) {
    return inputData.toString();
  }


  onEdit(obj) {
    ;
    obj.isForEdit = "YES";
    this.router.navigate(["addairline_email"], { queryParams: obj, skipLocationChange: true });
  }




  fn_toDelete(obj) {
    obj.isForEdit = "DELETE";
    this.router.navigate(["addairline_email"], { queryParams: obj, skipLocationChange: true });
    
  }

  onDelete(obj) {
    ;

  }

}
