import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-compare-cliam-type',
    templateUrl: './compare-cliam-type.component.html',
    styleUrls: ['./compare-cliam-type.component.css']
})

export class CompareCliamTypeComponent implements OnInit {
    originstation: any = [];
    Originclaimamount: any = [];
    Originpaidamount: any = [];
    destoriginstation: any = [];
    destOriginclaimamount: any = [];
    destOriginpaidamount: any = [];
    destOriginpaidamountFormaml: any = [];
    destOriginclaimamountFormaml: any = [];
    destoriginstationFormaml: any = [];
    originstationFormaml: any = [];
    OriginclaimamountFormaml: any = [];
    OriginpaidamountFormaml: any = [];
    inputObj: any;
    loginDetails: any;
    orgId: any;
    totalDestinationAirportClaimCount: any;
    totalOriginAirportClaimCount: any;
    primilaryCount: any;
    formalCount: any;
    primilaryYearOneCount: any;
    primilaryYearTwoCount: any;
    formalCountYearTwo: any;
    formalCountYearOne: any;
    PFYearTwo: any;
    PFYearOne: any;
    scrollable: boolean;
    scrollable1: boolean;
    scrollable2: boolean;
    scrollable3: boolean;
    canvaslinedestFormalwidth: any;
    canvaslineoriginFormalwidth: any;
    canvaslineOnewidth: any;
    canvaslinewidth: any;
    height: number;

    constructor(
        private claimcountservice: ClaimCountService,
        private toaster: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }
    ngOnInit() {
        this.inputObj = JSON.parse(localStorage.getItem("inputObj"));
        this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        this.orgId = this.loginDetails.orgId;
        if (this.inputObj.yearOne && this.inputObj.yearTwo) {
            //api integration starts here
            this.spinner.show();
            this.claimcountservice.Compare_claim_count(this.inputObj).subscribe(
                (data) => {
                    ;
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                       
                        this.PFYearOne = data.yearOne;
                        this.PFYearTwo = data.yearTwo
                        this.totalDestinationAirportClaimCount =
                            data.totalDestinationAirportClaimCount;
                        this.totalOriginAirportClaimCount =
                            data.totalOriginAirportClaimCount;
                        if (this.totalOriginAirportClaimCount != 0) {
                            this.primilaryYearOneCount = 0;
                            this.primilaryYearTwoCount =  0;
                            this.formalCountYearOne = 0;
                            this.formalCountYearTwo = 0;
                            for (
                                let i = 0;
                                i < this.totalOriginAirportClaimCount.length;
                                i++
                            ) {
                                for (
                                    let j = 0;
                                    j < this.totalDestinationAirportClaimCount.length;
                                    j++
                                ) {
                                   
                                    if (this.totalOriginAirportClaimCount[i].status ==="Preliminary" ) {
                                        this.primilaryYearOneCount = this.totalOriginAirportClaimCount[i].yearOneCount; 
                                        this.primilaryYearTwoCount = this.totalOriginAirportClaimCount[i].yearTwoCount; 
                                    } else if (
                                        this.totalOriginAirportClaimCount[i].status === "Formal" ) {
                                        this.formalCountYearOne = this.totalOriginAirportClaimCount[i].yearOneCount;
                                        this.formalCountYearTwo = this.totalOriginAirportClaimCount[i].yearTwoCount;
                                    }
                                }
                            }
                        }else{
                                this.primilaryYearOneCount = 0;
                                this.primilaryYearTwoCount =  0;
                                this.formalCountYearOne = 0;
                                this.formalCountYearTwo = 0;
                        }
                        //dymanic scroll start here
                        let labelLengthorg = data.originAirportCliams.stations.length;
                        let labelLengthdest = data.destAirportCliams.stations.length;
                        let labelLengthorgFormal = data.originAirportCliamsFormal.stations.length;
                        let labelLengthdestFormal = data.destnAirportCliamsFormal.stations.length;

                        if (labelLengthorg > 60) {
                            this.scrollable = true
                        }
                        else {
                            this.scrollable = false
                        }
                        if (labelLengthdest > 60) {
                            this.scrollable1 = true
                        }
                        else {
                            this.scrollable1 = false
                        }
                        if (labelLengthorgFormal > 60) {
                            this.scrollable2 = true
                        }
                        else {
                            this.scrollable2 = false
                        }
                        if (labelLengthdestFormal > 60) {
                            this.scrollable3 = true
                        }
                        else {
                            this.scrollable3 = false
                        }


                        //Preliminary Claim type by Origin
                        this.canvaslinewidth = this.getWidth(data.originAirportCliams.stations.length);
                        this.originstation = data.originAirportCliams.stations,
                            this.Originclaimamount = data.originAirportCliams.yearOneCount,
                            this.Originpaidamount = data.originAirportCliams.yearTwoCount,
                           setTimeout(() => {
                        new Chart(
                            <HTMLCanvasElement>document.getElementById("canvasline"),
                            {
                                type: "bar",
                                plugins: [ChartDataLabels],
                                data: {
                                    labels: this.originstation,
                                    datasets: [
                                        {
                                            barThickness: 10,
                                            // type: "line",
                                            borderColor: "rgba(0,0,255,1)",
                                            backgroundColor: "rgba(0,0,255,1)",
                                            hoverBorderColor: "rgba(0,0,255,1)",
                                            // yAxisID: "y-axis-0",
                                            data: this.Originclaimamount,
                                            fill: false,
                                            label: data.yearOne,
                                            stack: "a",

                                        },
                                        {
                                            barThickness: 10,
                                            // type: "bar",
                                            borderColor: "rgba(255,165,0,1)",
                                            backgroundColor: "rgba(255,165,0,1)",
                                            hoverBackgroundColor: "rgba(255,165,0,1)",
                                            // yAxisID: "y-axis-1",
                                            data: this.Originpaidamount,
                                            fill: false,
                                            label: data.yearTwo,
                                            stack: "b",
                                        },
                                    ],
                                },
                                options: {
                                    maintainAspectRatio: false,
                                    title: {
                                        display: true,
                                        //text: "Origin Claim Count"
                                    },
                                    plugins: {
                                        // Change options for ALL labels of THIS CHART
                                        datalabels: {
                                            color: "#000000",
                                            anchor: "end",
                                            align: "top",
                                            rotation: -90,
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                    },

                                    scales: {
                                        xAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Stations",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    autoSkip: false,
                                                    // max rotation: -90,
                                                    // min rotation: -90
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Claim count",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    beginAtZero: true,
                                                    //lineHeight:20,
                                                    // max:plugin.settings.maxDataValue,
                                                    maxTicksLimit: 10,
                                                },
                                                id: "y-axis-0",
                                                position: "left",
                                            },
                                        ],
                                    },
                                },
                            }
                        );

                        //Preliminary Claim type by Destination
                        this.canvaslineOnewidth = this.getWidth(data.destAirportCliams.stations.length);
                        this.destoriginstation = data.destAirportCliams.stations,
                            this.destOriginclaimamount = data.destAirportCliams.yearOneCount,
                            this.destOriginpaidamount = data.destAirportCliams.yearTwoCount,
                           
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineOne"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstation,
                                        datasets: [
                                            {
                                                barThickness: 10,
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.destOriginclaimamount,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                // type: "bar",
                                                barThickness: 10,
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.destOriginpaidamount,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim count"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {

                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Stations",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim count ",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,
                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );

                        //Formal Claim type by Origin
                        this.canvaslineoriginFormalwidth = this.getWidth(data.originAirportCliamsFormal.stations.length);
                        this.originstationFormaml = data.originAirportCliamsFormal.stations,
                            this.OriginclaimamountFormaml = data.originAirportCliamsFormal.yearOneCount,
                            this.OriginpaidamountFormaml = data.originAirportCliamsFormal.yearTwoCount,
                          
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineoriginFormal"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.originstationFormaml,
                                        datasets: [
                                            {
                                                barThickness: 10,
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.OriginclaimamountFormaml,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                barThickness: 10,
                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.OriginpaidamountFormaml,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        tooltips: {

                                        },
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim count"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Stations",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim count ",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,

                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );

                        // Formal Claim type by Destination
                        this.canvaslinedestFormalwidth = this.getWidth(data.destnAirportCliamsFormal.stations.length);
                        this.destoriginstationFormaml = data.destnAirportCliamsFormal.stations,
                            this.destOriginclaimamountFormaml = data.destnAirportCliamsFormal.yearOneCount,
                            this.destOriginpaidamountFormaml = data.destnAirportCliamsFormal.yearTwoCount,
                         
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslinedestFormal"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstationFormaml,
                                        datasets: [
                                            {
                                                barThickness: 10,
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.destOriginclaimamountFormaml,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                barThickness: 10,
                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.destOriginpaidamountFormaml,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        tooltips: {

                                        },
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim count"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Stations",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Claim count ",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,

                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );
                            this.spinner.hide();
                        }, 500);
                        // this.scrollView();
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toaster.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.spinner.hide();
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            );

        }
        //api integration ends here
    }

    // scrollView() {
    //     setTimeout(() => {
    //         const elements = document.getElementsByClassName('chartAreaWrapper_comparescroll60');
    //         for (let ele of elements as any) {
    //             ele.scrollTop = ele.scrollHeight;
    //         }
    //     }, 2000);

    // }
    getWidth(number) {
        this.height = 420;
        if (number < 10) {
            let finalWidth = 230 * number;
            return finalWidth;
        } else {
            let finalWidth = 60 * number;
            return finalWidth;
        }
    }

}
