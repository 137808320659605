import { Component, OnInit, enableProdMode, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DxDataGridComponent } from 'devextreme-angular';
import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';
import { environment } from 'src/environments/environment.prod';
import { HostListener } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';
import { RefreshService } from '../../services/Refresh/refresh.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { globalConstants } from 'src/environments/globalConstants';
import { LocationStrategy } from '@angular/common';
import { ConfigServiceService } from '../../config-service.service';
import { Active_Archive_ReportsTableComponent } from '../../shared/dataGrid-tables/active-reports-table/active-archive-reports-table.component';
import { ExcelService } from "src/app/services/excel.service";

if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
declare var jQuery: any;

@Component({
    selector: 'app-admin-handler-dashboard',
    templateUrl: './admin-handler-dashboard.component.html',
    styleUrls: ['./admin-handler-dashboard.component.css']
})
export class AdminHandlerDashboardComponent implements OnInit {
    @ViewChild('reportsDataGrid') reportsDataGrid_Ref: Active_Archive_ReportsTableComponent;
 

    @ViewChild('myGridhandler') myGrid: jqxGridComponent;

    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    Obj: any;
    orgType: any;
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    Always;
    finalReportArray = [];
    mergedReports = [];
    mergedReportsFinal = [];
    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    public static obj :any;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    ObjectOne: any;
    ReportsProductionUrl: string;
    loginDetails: any;
    reportpreparename: string;
    Airlinename: string;
    Emailstatus: any;
    disabledValues: number[];
    multipleReportList: any;
    userId: string;
    masterAwbNo: string;
    stationCode: string;
    firstName: string;
    classificationType: string;
    disabledKeys: any[];
    dataGridInstance: any;
    justDeselected: any;
    
    airlineDetails: any;
    showAll: boolean;
    showArchived: boolean;
    comnyorgid: any;
    is_Refresh_Enable: boolean;
    anchors: any;
    images: any;
    public static totaldata = [];
    orgId: any;
    loginUserId: any;
    shortReports: any;
    reportAcessType: boolean;
    editicons: any;
    currentTabName: any;
    currentReportId: any;
    finalReportsCount: any;
    archiveReportsCount: any;
    internalReportCount: number;
    carrierId: any;
    constructor( private superAdminservice: SuperadminService,private renderer: Renderer2, private elementRef: ElementRef,private refreshServiceRef: RefreshService, private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, 
        private adminServiceRef: AdminService, private spinner: NgxSpinnerService,private location: LocationStrategy ,private config : ConfigServiceService, private excelService:ExcelService) {
        this.disabledValues = [1];
        this.disablechbx = this.disablechbx.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        // preventing back button in browser   
        history.pushState(null, null, window.location.href);  
        this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
        }); 

    }
    @ViewChild('grid') dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
  
    ngAfterViewInit() {
        // this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
    }

    ngAfterViewChecked() {
        this.Obj = JSON.parse(localStorage.getItem("loginresultobject"))
        if(this.Obj.reportAcess === 'Y'){
            if(this.reportsDataGrid_Ref !== undefined){
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Archived"){
                    this.is_Refresh_Enable = false;
                    this.showAll = false;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Internal"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    this.reportsDataGrid_Ref.myGrid.selectionmode("checkbox") ;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                }
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports;
                this.myGrid =  this.reportsDataGrid_Ref.myGrid;
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
            }
        }else{
            if(this.reportsDataGrid_Ref !== undefined){
                this.mergedReportsFinal =  this.reportsDataGrid_Ref.mergedReports
                this.myGrid =  this.reportsDataGrid_Ref.myGrid
                this.finalReportsCount = this.reportsDataGrid_Ref.finalReportsCount;
                this.internalReportCount = this.reportsDataGrid_Ref.internalReportCount;
                let selectedreports = JSON.parse(localStorage.getItem('reportsTab'));
                if(selectedreports === "Active"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link active';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else if(selectedreports === "Archived"){
                    this.is_Refresh_Enable = false;
                    this.showAll = false;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link active';
                    document.getElementById('internal_Reports').className = 'nav-link';
                }else  if(selectedreports === "Internal"){
                    this.is_Refresh_Enable = true;
                    this.showAll = true;
                    document.getElementById('active_Reports').className = 'nav-link';
                    document.getElementById('archive_Reports').className = 'nav-link';
                    document.getElementById('internal_Reports').className = 'nav-link active';
                }
                this.reportsDataGrid_Ref.myGrid.selectionmode("none") ;
            }
        }
     



        this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
        this.images=this.elementRef.nativeElement.querySelectorAll('img');
        this.editicons = this.elementRef.nativeElement.querySelectorAll('i');

        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            if (anchor.href != '') {
                let href_Split_Array = [];
                href_Split_Array = anchor.href.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
                if ((anchor.innerText === 'Edit') && (href_Valid_Check_Var === 'edit')) {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
                else if (anchor.innerText === 'Delete' && href_Valid_Check_Var === 'delete') {
                    anchor.addEventListener('click', this.handleAnchorClick)
                }
            }
        })
        this.images.forEach((image: HTMLImageElement) => {
   
            if(image.id != ''){
                let href_Split_Array =[];
                href_Split_Array = image.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];

                if(  (href_Valid_Check_Var === 'resend' ) ){
                    image.addEventListener('click', this.handleImageClick)
                }
               
            }  
        })
        this.editicons.forEach((editicon: HTMLImageElement) => {
            if(editicon.id != ''){
                let href_Split_Array =[];
                href_Split_Array = editicon.id.split(":");
                let href_Valid_Check_Var = href_Split_Array[0];
 
                if(  (href_Valid_Check_Var === 'edit' ) ){
                    editicon.addEventListener('click', this.handleiconClick)
                }
               
            }  
        })
    }

    public handleiconClick = (event: Event) => {
        //
        // Prevent opening anchors the default way
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
       
      this.fn_alerticon(editicon,event);
      }

      fn_alerticon(editicon,event){
          let href_Split_Array = [];
          href_Split_Array = editicon.id.split(":");
          let href_Valid_Check_Var = href_Split_Array[1];
        if(href_Split_Array[0]==='edit'){
         this.mergedReportsFinal[href_Valid_Check_Var];
         this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
        }
    }

    public handleAnchorClick = (event: Event) => {
        
        // Prevent opening anchors the default way
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        let href_Split_Array = [];
        href_Split_Array = anchor.href.split(":");
        let href_Valid_Check_Var = href_Split_Array[1];
        
        let totalRow = this.myGrid.getrowdata(href_Valid_Check_Var)
        this.mergedReportsFinal[href_Valid_Check_Var];
        this.onEditClick(this.mergedReportsFinal[href_Valid_Check_Var]);
    }
    public handleImageClick = (event: Event) => {
        event.preventDefault();
        const editicon = event.target as HTMLImageElement;
       
      this.fn_alertImage(editicon,event);
      }
       fn_alertImage(image,event){
             
              let href_Split_Array = [];
              href_Split_Array = image.id.split(":");
              let href_Valid_Check_Var = href_Split_Array[1];
            if(href_Split_Array[0]==='resend'){
             this.mergedReportsFinal[href_Valid_Check_Var]
              this.getSelectedDataOfresendReport(this.mergedReportsFinal[href_Valid_Check_Var],'S');
            }
           
         
        }
        onEditClick(rowData){
            localStorage.setItem('awbNumber',JSON.stringify(rowData.masterAwbNo));
             localStorage.setItem('awbNumber_reportId', JSON.stringify(rowData.reportId));
                rowData.isFrom= 'EDIT_REPORT';
                rowData.from_csaDashBoard= 'true';
        
                this.router.navigate(['save-report'],{ queryParams: rowData ,skipLocationChange: true})
            }

    ngOnInit() {
        this.showAll = true;
        this.is_Refresh_Enable = true;
        this.showArchived = false;
        this.disabledKeys = [];
        window.scroll(0, 0);
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.emailid = this.Object.emailId;
        this.Password = this.Object.password;
        this.usertype = this.Object.userType;
        this.orgType = this.Object.orgType;
        this.webapi =this.config.getConfig().URL
        this.ReportsProductionUrl = this.config.getConfig().ReportsUrl
        let  lastSelectedTAb = JSON.parse(localStorage.getItem("reportsTab"));
        if(lastSelectedTAb != null && lastSelectedTAb != undefined){
            if(lastSelectedTAb ==="Active" ){
                 this.fn_OnTab_Changes('Active','A')
            }else if( lastSelectedTAb ==="Archived"){
                 this.fn_OnTab_Changes('Archived','R')
            }else if(lastSelectedTAb ==="Internal" ){
                this.fn_OnTab_Changes('Internal','I')
           }
        }else{
            this.fn_OnTab_Changes('Active','A')
        }
      
        if (this.loginDetails.userType == 'A' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'Y' && this.loginDetails.orgType === "H") {
           this.reportAcessType = true;
        } else if (this.loginDetails.userType == 'A' && (this.loginDetails.userAdminAuth === 'I' && this.loginDetails.userSuperAdminAuth === 'I') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "H") {
            this.reportAcessType = false;
        } else if (this.loginDetails.userType == 'A' && (this.loginDetails.userAdminAuth === 'A' || this.loginDetails.userSuperAdminAuth === 'A') && this.loginDetails.reportAcess === 'N' && this.loginDetails.orgType === "H") {
            this.reportAcessType = false;
        }
    }


    fn_ToRefreshReports() {
        this.comnyorgid = JSON.parse(localStorage.getItem('loginresultobject'));
        this.spinner.show();
        this.refreshServiceRef.servc_To_Refresh_Reports(this.comnyorgid.userId).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } else{
                this.toastr.info(data.message);
            }
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
        })
    }

   

   
 
static currentReportTab;
    transformDate(date) {
        return date.split('-').reverse().join('-');
    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }
    Rowdoubleclick(event: any): void {
        status = ('viewreports');
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.mergedReportsFinal[event.args.row.bounddata.uid].length != 0) {
            localStorage.setItem("reportStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].masterAwbNo));
             localStorage.setItem('awbNumber_reportId', JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.mergedReportsFinal[event.args.row.bounddata.uid].emailStatus));
            if (this.subscribedParam === undefined) {
                // this.router.navigate(["/viewdetails", this.Object.orgId]);//new
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId }});
            } else {
                // this.router.navigate(["/viewdetails", this.Object.orgId, this.subscribedParam]);//new 
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId,type : this.subscribedParam} });
            }
        }
    }

    getUser(obj, type) {
     
        let comapanydetails = {
            orgId: obj.organisationId,
            orgLogo: obj.orgLogo,
            orgName: obj.organisationName,
            orgType: obj.orgType,
            userId: obj.userId,
            reportAcess: obj.reportAcess

        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        this.router.navigate(["viewusers", obj.organisationId, type]);
        if (type === 'report') {
            this.ViewReportsData.isFrmViewDetails = "yes"
        }
    }
    getSelectedData(status) {
        localStorage.setItem("isFromreports", JSON.stringify(status));
        if (this.dataGrid.instance.getSelectedRowsData().length != 0) {
          
            localStorage.setItem("reportStatus", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].reportStatus));
            localStorage.setItem("awbNumber", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].masterAwbNo));
           localStorage.setItem('awbNumber_reportId', JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].reportId));
            localStorage.setItem("emailStatus", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0].emailStatus));
            if (this.subscribedParam === undefined) {
                this.ViewReportsData.isfromHandler_Admin_Dashboard = 'yes'
                this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
                // this.router.navigate(["/viewdetails", this.Object.orgId]);
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId }});
            } else {
                this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
                this.ViewReportsData.isfromHandler_Admin_Dashboard = 'yes'
                // this.router.navigate(["/viewdetails", this.Object.orgId, this.subscribedParam]);
                this.router.navigate(["/viewdetails"], { queryParams: { orgKey: this.Object.orgId,type : this.subscribedParam} });
            }
        }
    }
    getSelectedDataOfReport(val, status) {
        
         
        localStorage.setItem("reportData", JSON.stringify(val.data));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataCancel(val, status) {
        
       
        localStorage.setItem("Cancelstatus", JSON.stringify(status));
        localStorage.setItem("reportData", JSON.stringify(val.data));
        jQuery('#cancelsingleModal').modal({ backdrop: 'static', keyboard: false });
    }
    getSelectedDataOfresendReport(val, status) {
       
        localStorage.setItem("reportData", JSON.stringify(val));
        localStorage.setItem("status", JSON.stringify(status));
        jQuery('#resendModal').modal({ backdrop: 'static', keyboard: false });
    }
    CancelUser() {
        
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let Cancelstatus = JSON.parse(localStorage.getItem('Cancelstatus'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: Cancelstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.adminServiceRef.setholdTime(dataObject).subscribe(data => {
           
            if (data.responseCode === 1) {
              
                this.toastr.success(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            } else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#cancelsingleModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#cancelsingleModal').modal('hide');
            })
    }
    sendManualReportEmail() {
         
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.adminServiceRef.setholdTime(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
           
                this.toastr.success(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            } 
             else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#myModal').modal('hide');
            })

    }
    resendManualReportEmail() {
         
        let val = JSON.parse(localStorage.getItem('reportData'));
        let loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        let reportstatus = JSON.parse(localStorage.getItem('status'));
        let dataObject = {
            reportSendBy: loginDetails.userId,
            userId: val.userId,
            masterAwbNo: val.masterAwbNo,
            stationCode: val.stationCode,
            classificationType: val.classificationType,
            reportStatus: reportstatus,
            firstName: val.reportPreparedName,
            reportId:val.reportId,
            carrierId: val.carrierId
        }
        this.spinner.show();
        this.adminServiceRef.setholdTime(dataObject).subscribe(data => {
            
            if (data.responseCode === 1) {
                
                this.toastr.success(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                this.reportsDataGrid_Ref.myGrid.gotopage(0)
                this.reportsDataGrid_Ref.myGrid.clearselection()
                this.selectedRowsData = [];
            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            } 
            else if (data.responseCode === 0) {
                this.toastr.error(data.message, '', {});
                jQuery('#resendModal').modal('hide');
                this.spinner.hide();
            }
        },
            error => {
                this.spinner.hide();
                jQuery('#resendModal').modal('hide');
            })

    }
    navigateToViewReports(obj, status) {
        
        localStorage.setItem("isFromreports", JSON.stringify(status));
        localStorage.setItem("awbnoObj", JSON.stringify(obj));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.router.navigate(["/viewdetails"]);
    }
    notCancel() {
        
        jQuery('#Activation').modal('hide');

    }

    disablechbx(e) {
        
      
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";
        if (e.data.emailStatus === a || e.data.emailStatus === b || e.data.emailStatus === c || e.data.emailStatus === d) {
            const instance = CheckBox.getInstance(e.cellElement.querySelector('.dx-select-checkbox'));
            instance.option('disabled', true);
            events.off(e.cellElement);
        }
    }

    onSelectionChanged(e) {
        
      
        this.dataGrid.instance.getSelectedRowsData().length;

        this.disabledKeys = [];
        var a = "Internal email sent";
        var b = "Emails are not found";
        var c = "Cancelled";
        var d = "Extrenal email sent to Airlines";

        const checkarray = e.currentSelectedRowKeys;
        for (let i = 0; i < checkarray.length; i++) {
            if (checkarray[i].emailStatus === a || checkarray[i].emailStatus === b || checkarray[i].emailStatus === c || checkarray[i].emailStatus === d) {
                this.disabledKeys.push(checkarray[i]);
            }

        }
        if (this.disabledKeys.length > 0) {

            if (this.justDeselected) {
                this.justDeselected = false;
                e.component.deselectAll();
            }
            else {
                this.justDeselected = true;
                e.component.deselectRows(this.disabledKeys);
            }


        }
    }
    OpenEmailPopup(status) {
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to send');
        } else {
            this.currentTabName = JSON.parse(localStorage.getItem('reportsTab'))
            localStorage.setItem("Emailstatus", JSON.stringify(status));
            jQuery('#emailModal').modal({ backdrop: 'static', keyboard: false });
        }

    }
    

    sentMultipleEmailReport() {
        // if (this.mergedReports.length === 0) {
        //     this.toastr.error('No reports to sent');
        // }
         
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            for (let index = 0; index < this.selectedRowsData.length; index++) {
                        this.userId = this.selectedRowsData[index].userId,
                            this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                            this.stationCode = this.selectedRowsData[index].stationCode,
                            this.classificationType = this.selectedRowsData[index].classificationType,
                            this.firstName = this.selectedRowsData[index].firstName
                            this.currentReportId = this.selectedRowsData[index].reportId,
                            this.carrierId = this.selectedRowsData[index].carrierId
                        this.multipleReportList.push({
                            userId: this.userId,
                            masterAwbNo: this.masterAwbNo,
                            stationCode: this.stationCode,
                            classificationType: this.classificationType,
                            firstName: this.firstName,
                            reportId: this.currentReportId,
                            carrierId: this.carrierId,
                            destairportId: this.selectedRowsData[index].destAirportName,
                            originAirportId: this.selectedRowsData[index].originAirportName,
                            internalResponsibility: this.selectedRowsData[index].reportStatus,
                            rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                            rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                            reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                        });
                    // }
                // }
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#emailModal').modal('hide');
                this.myGrid.clearselection();
                this.toastr.error('No emails for this report');
                return false;
            }
            this.spinner.show();
            this.adminServiceRef.sendMultipleReportEmail(dataObject).subscribe(data => {
              
                if (data.responseCode === 1) {
                 
                   
                    this.toastr.success(data.message, '', {});
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#emailModal').modal('hide');
                    this.spinner.hide();
                } else {
                  
                    this.toastr.error(data.message, '', {});
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                    this.spinner.hide();
                }
            },
                error => {
                  
                    this.spinner.hide();
                    jQuery('#emailModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
    OpenCancelPopup(status) {
        if (this.selectedRowsData.length === 0) {
            this.toastr.error('Select reports to hold');
        } else {


            let selectedRowsDAtaArray = []
            for (let i = 0; i < this.selectedRowsData.length; i++) {
                selectedRowsDAtaArray.push(this.selectedRowsData[i]) ;
            }
            
            if(selectedRowsDAtaArray.length !== 0){
              let isExist = selectedRowsDAtaArray.some((element) => {
                  return (element.emailStatus === "sent to airline" || element.emailStatus === "Sent to airline");
                });
               
                if(isExist){
                    jQuery('#unselectSentReportExistPopup').modal({ backdrop: 'static', keyboard: false });
                    return false;
                }else{
                    localStorage.setItem("Emailstatus", JSON.stringify(status));
                    jQuery('#cancelModal').modal({ backdrop: 'static', keyboard: false });
                }
             
            }
         
        }

    }
    cancelunselectSentReportExistPopup(){
        jQuery('#unselectSentReportExistPopup').modal("hide");
    }

    cancelMultipleEmailReport() {
        
        // if (this.mergedReports.length === 0) {
        //     this.toastr.error('No reports to sent');
        // }
        this.Emailstatus = JSON.parse(localStorage.getItem('Emailstatus'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.selectedRowsData.length != 0) {
            this.multipleReportList = [];
            for (let index = 0; index < this.selectedRowsData.length; index++) {
        
                    this.userId = this.selectedRowsData[index].userId,
                        this.masterAwbNo = this.selectedRowsData[index].masterAwbNo,
                        this.stationCode = this.selectedRowsData[index].stationCode,
                        this.classificationType = this.selectedRowsData[index].classificationType,
                        this.firstName = this.selectedRowsData[index].firstName
                        this.currentReportId = this.selectedRowsData[index].reportId,
                    this.multipleReportList.push({
                        userId: this.userId,
                        masterAwbNo: this.masterAwbNo,
                        stationCode: this.stationCode,
                        classificationType: this.classificationType,
                        firstName: this.firstName,
                        reportId: this.currentReportId,
                        destairportId: this.selectedRowsData[index].destAirportName,
                        originAirportId: this.selectedRowsData[index].originAirportName,
                        internalResponsibility: this.selectedRowsData[index].reportStatus,
                        rootcauseExists: this.selectedRowsData[index].rootcauseExists,
                        rootcauseSendstatus: this.selectedRowsData[index].rootcausestatus,
                        reportOrgRootcauseStatus: this.selectedRowsData[index].reportOrgRootcauseStatus
                    });
                // }
            // }
            }
            let ORGrootcause = 'N'
            if (this.selectedRowsData[0].orgRootcauseStatus !== null && this.selectedRowsData[0].orgRootcauseStatus !== undefined) {
                ORGrootcause = this.selectedRowsData[0].orgRootcauseStatus
            }
            let dataObject = {
                reportStatus: this.Emailstatus,
                reportSendBy: this.loginDetails.userId,
                reportsList: this.multipleReportList,
                orgRootcauseStatus: ORGrootcause,
                carrierId: this.carrierId
            }
            if (this.multipleReportList.length === 0) {
                jQuery('#cancelModal').modal('hide');
                this.myGrid.clearselection();
                this.toastr.error('No reports to hold');
                return false;
            }
            this.spinner.show();
            this.adminServiceRef.sendMultipleReportEmail(dataObject).subscribe(data => {
               
                if (data.responseCode === 1) {
                  
                    this.toastr.success(data.message, '', {});
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    jQuery('#cancelModal').modal('hide');
                    this.spinner.hide();
                }
                else {
                 
                    this.toastr.error(data.message, '', {});
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                    this.spinner.hide();
                }
            },
                error => {
                   
                    this.spinner.hide();
                    jQuery('#cancelModal').modal('hide');
                    this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
                    this.reportsDataGrid_Ref.myGrid.gotopage(0)
                    this.reportsDataGrid_Ref.myGrid.clearselection()
                    this.selectedRowsData = [];
                })

        }
        else {
            this.spinner.hide();
            this.toastr.error('Please select atleast one report');
        }
    }
    saveGridInstance(e) {
        
      
        this.dataGridInstance = e.component;
        this.dataGridInstance.refresh();
        this.dataGridInstance.clearSelection();
        this.dataGridInstance.deselectRows();
        this.dataGridInstance.deselectAll();
        // this.dataGrid.instance.refresh();
    }

    gotoLocations(data) {
        this.router.navigate(["viewstations"],
            { queryParams: { from: 'HandlerDashboard' } }
        );

    }

    testing(data) {
        
        console.log('data', data)

    }
    //redirect to Uld damage reports
    onClickUldDamage_Reports() {
        localStorage.setItem("currentModule",JSON.stringify("ULD_DAMAGE"))
        this.router.navigateByUrl('/Uld/viewuld');
    }

    // redirect to handler cargo image
    onClickCargo_Image(){
        localStorage.setItem("currentModule",JSON.stringify("cargoImage_Handler"))
        this.router.navigateByUrl('/CargoImageHandler/view-shipment');
    }


    public JqWIDGETPAGESIZESARRAY = globalConstants.JQWIDGET_PAGEsIZESARRAY
    source =
        {

            localdata: this.mergedReportsFinal,
            datatype: 'json',
            datafields:
                [
                    { name: 'emailStatus', type: 'string' },
                    { name: 'actions', type: 'string' },
                    { name: 'stationCode', type: 'string' },
                    { name: 'incidentDate', type: 'date' },
                    { name: 'masterAwbNo', type: 'string' },
                    
                    { name: 'id', type: 'Number' },
                    { name: 'reportStatus', type: 'String' },
                    { name: 'internalFileName', type: 'String' },
                    { name: 'externalFileName', type: 'String' },
                    { name: 'airlineName', type: 'String' },
                    { name: 'modifiedDate', type: 'string' },
                    { name: 'modifiedTime', type: 'string' },
                  
                  
                    { name: 'originStation', type: 'string' },
                    { name: 'destStation', type: 'string' },
                    { name: 'truckType', type: 'string' },
                    { name: 'flightTruckNo', type: 'string' },
                    { name: 'reportPreparedName', type: 'string' },
                    { name: 'shipper', type: 'string' },
                    { name: 'consignee', type: 'String' },
                    { name: 'issuingAgent', type: 'String' },
                    { name: 'jobTitle', type: 'String' },
                    { name: 'flightDate', type: 'date' },

                    { name: 'contentNature', type: 'string' },
                    { name: 'damgagePacking', type: 'string' },
                    { name: 'contentAffected', type: 'string' },
                    { name: 'safetyLife', type: 'string' },
                    { name: 'classificationType', type: 'string' },
                    { name: 'damageDiscovered', type: 'string' },
                    { name: 'discoveresCaused', type: 'String' },
                    { name: 'reportCreatedTime', type: 'string' },

                ],
                pagesize: globalConstants.JQWIDGET_DEFAULT_PAGESIZE,
            beforeprocessing: function (data) {
                // this.source.totalrecords = data[0].TotalRows;
            },
            pager: (pagenum: any, pagesize: any, oldpagenum: any): void => {
                // callback called when a page or page size is changed.
               
            },
            rendergridrows: function (params) {
                return params.data;
            }
        };
    linkrenderer = function (row, column, value) {
        var html = "<a  href='" + value + "' target='_blank'> <img src='../../../assets/images/Pdf_icon.png'   style='width: 24px;margin-left: 15px;'></a>";
        return html;
    }

    columnsrenderer = function (value) {
        return '<div style="text-align: center; margin-top: 5px;">' + value + '</div>';
    }
    dataAdapter = new jqx.dataAdapter(this.source);
    columns =
        [
            { text: "Carrier", datafield: "emailStatus", width: 150,
            cellclassname: function(row, column, value, data) {
                this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
                if( (data.emailStatus === 'FinalTimer')&& (this.loginDetails.orgType === 'SA' ||(this.loginDetails.orgType === 'H' && AdminHandlerDashboardComponent.obj.reportAcess === 'Y') )){
                    return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
                    
                      
                     }else if((data.emailStatus=== 'FinalTimer')&& this.loginDetails.orgType === 'H' && AdminHandlerDashboardComponent.obj.reportAcess === 'N' ){
                        return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
                       
                     }else if((data.emailStatus === 'Extrenal email sent to Airlines')&&  this.loginDetails.orgType === 'H'){
                       if(data.emailStatus === 'Extrenal email sent to Airlines'&&  this.loginDetails.orgType === 'H' && this.loginDetails.reportAcess === 'Y' ){
                        // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"
                        return "<span   class='label label-default'>Sent to airline</span>"

                       }else{
                        // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"
                        return "<span   class='label label-default'>Sent to airline</span>"

                       }
                     
     
                   }else if((data.emailStatus=== 'Internal email sent')&&  this.loginDetails.orgType === 'H'){
                   if(data.emailStatus === 'Internal email sent'&&  this.loginDetails.orgType === 'H' && this.loginDetails.reportAcess === 'Y' ){
                    // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"
                    return "<span   class='label label-default'>Sent to airline</span>"

                     }else{
                        // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"
                        return "<span   class='label label-default'>Sent to airline</span>"

                     }
                  
                 }else if( (data.emailStatus === 'Hold')&& (this.loginDetails.orgType === 'SA' || this.loginDetails.orgType === 'H') ){
                    return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
                   
                     
                   
               
               } else if( data.emailStatus === 'Cancelled'){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
                    
                         
               } else if( (data.emailStatus === 'Authorization Failed')&& (this.loginDetails.orgType === 'SA'|| (this.loginDetails.orgType === 'H'&& AdminHandlerDashboardComponent.obj.reportAcess === 'Y'))){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
                           
                             
              } else if( (data.emailStatus === 'Authorization Failed')&& this.loginDetails.orgType === 'H'&& AdminHandlerDashboardComponent.obj.reportAcess === 'N'){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
               
                 
                }else if( data.emailStatus === 'Emails are not found'){
                    return "<img src='../../../assets/images/mailnotfound.png' class='reportStatus'>" 
                
                     
                    } 
                  
            },
            cellsrenderer: function (row,index,data) {

                
               
               this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
           
                if(AdminHandlerDashboardComponent.totaldata.length !=0 && (row < AdminHandlerDashboardComponent.totaldata.length)){
                    if( AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Sent to airline'){
                        if( AdminHandlerDashboardComponent.obj.reportAcess === 'Y' &&  AdminHandlerDashboardComponent.currentReportTab !== 'Active'){
                            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span> <img id = 'resend:" + row + "' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>";
                            // return "<span   class='label label-default'>Sent to airline</span><span> <img id = 'resend:" + row + "' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report' /> </span>";
                         }else{
                            return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span>"
                         }
                         } 
                         else if( AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Not sent to airline'){
                            return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-bell-slash text-warning' aria-hidden='true'></i> Not notified</div>"

                             } 
                             else if( AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Emails are not found'){
                                return "<div style='margin-top:  8.5px; margin-left: 13px; color:black; font-weight:bold'><i class='fa fa-exclamation-circle text-danger' aria-hidden='true'></i> Not found</div>"
                                  
                                 }  else if (AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'A' || AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'AS' ) {
                                      if(AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'A'){
                                        return "<div class='m-archive' ><i class='fa fa-archive' aria-hidden='true'></i> Manual archived</div>";
                                      }else{
                                        return "<span   class='label label-default'><i class='fa fa-bell text-success' aria-hidden='true'></i> Notified</span><span> <img id = 'resend:" + row + "' src='../../../assets/images/resend_mail.png' style='width: 21px;margin-left: 5px;cursor: pointer;background: transparent;margin-top: 5px;' data-toggle='tooltip' title='Resend report'/></span>";
                                      }
                                   
                                   
                                    // return "<span style='padding: 2px;position: relative;top:  10px; width: 141px;; margin-left: 10px;'> Manual archived</span>";
                                } 
                else{
                  if( (AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'FinalTimer')&& (this.loginDetails.orgType === 'SA' ||(this.loginDetails.orgType === 'H' && AdminHandlerDashboardComponent.obj.reportAcess === 'Y') )){
                    return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
  
                   
                  }else if((AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'FinalTimer')&& this.loginDetails.orgType === 'H' && AdminHandlerDashboardComponent.obj.reportAcess === 'N' ){
                    return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"   
 
                  }else if((AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Extrenal email sent to Airlines')&&  this.loginDetails.orgType === 'H'){
                    if(AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Extrenal email sent to Airlines'&&  this.loginDetails.orgType === 'H' && this.loginDetails.reportAcess === 'Y' ){
                        // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'><span> <img id = 'resend:"+ row+"' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' /> </span>";
                        return "<span   class='label label-default'>Sent to airline</span><span> <img id = 'resend:"+ row+"' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report'/> </span>";
                    }else{
                        // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"
                        return "<span   class='label label-default'>Sent to airline</span>"
                      
                    }
                  
  
                }else if((AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Internal email sent')&&  this.loginDetails.orgType === 'H'){
                if(AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Internal email sent'&&  this.loginDetails.orgType === 'H' && this.loginDetails.reportAcess === 'Y' ){
                    // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'><span> <img id = 'resend:"+ row+"' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' /> </span>";
                    return "<span   class='label label-default'>Sent to airline</span><span> <img id = 'resend:"+ row+"' src='../../../assets/images/resend_mail.png' style='width: 24px;  margin-left: 1em;cursor: pointer;background: transparent; margin-top: 4.5px;' data-toggle='tooltip' title='Resend report'/> </span>";
                  }else{
                    // return "<img src='../../../assets/images/mailsent.png' class='reportStatus'>"                  }
                    return "<span   class='label label-default'>Sent to airline</span>"                  }
               
              }else if( (AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Hold')&& (this.loginDetails.orgType === 'SA' || this.loginDetails.orgType === 'H') ){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                  
                
            
            } else if( AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Cancelled'){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                      
            } else if( (AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Authorization Failed')&& (this.loginDetails.orgType === 'SA'|| (this.loginDetails.orgType === 'H'&& AdminHandlerDashboardComponent.obj.reportAcess === 'Y'))){
                return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"
                          
           } else if( (AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Authorization Failed')&& this.loginDetails.orgType === 'H'&& AdminHandlerDashboardComponent.obj.reportAcess === 'N'){
            return "<img src='../../../assets/images/mailnotsent.png' class='reportStatus'>"              
             }else if( AdminHandlerDashboardComponent.totaldata[row].emailStatus === 'Emails are not found'){
                return "<img src='../../../assets/images/mailnotfound.png' class='reportStatus'>" 
                  
                 } 
               }
            }
             },
        
        },
        {
            text: 'Report Status', datafield: 'damageReportType', width: 90, cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties,columnData) {
                if ((AdminHandlerDashboardComponent.totaldata.length != 0)  && (row < AdminHandlerDashboardComponent.totaldata.length)) {
                    if (AdminHandlerDashboardComponent.totaldata[row].damageReportType === 'DRAFT') {
                        return "<div style='margin-top:  8.5px; margin-left: 5px;color:black; font-weight:bold'>DRAFT</div>";
                    } else {
                        return "<div style='margin-top:  8.5px; margin-left: 5px; color:black; font-weight:bold'>FINAL</div>";
                    }
                }
            }
        },
        {
            text: 'Actions', datafield: 'actions', width: 60, cellsalign: 'center',cellsrenderer: function (row, index) {
                // return '<button type="button" class="btn default grey-cascade" onclick="UpdateClick(' + rowdata + ')">Delete</button><button type="button" class="btn default red" onclick="DeleteClick(' + rowdata.ModulID + ')">Delete</button>';
                return "<div style='margin-top:  8.5px; margin-left: 20px;'><a  href='edit:" + row + "'style='text-align: center; margin-top: 5px;cursor: pointer;'><i id = 'edit:"+ row +"' class='fa fa-pencil-square-o' style='padding:0 2px;font-size:18px;' aria-hidden='true' data-toggle='tooltip' title='Edit'></i></a>";
            }, renderer: this.columnsrenderer
        },
        { text: 'Internal', datafield: 'internalFileName', width: 70, cellsrenderer: this.linkrenderer },
        { text: 'External', datafield: 'externalFileName', width: 70, cellsrenderer: this.linkrenderer },
        { text: "Airline", datafield: "airlineName", width: 120 },
            {
                text: 'Station', datafield: 'stationCode', width: 80, cellsrenderer: function (row, data, value, index) {
                    if (AdminHandlerDashboardComponent.totaldata.length != 0 && (row < AdminHandlerDashboardComponent.totaldata.length)) {
                        if (AdminHandlerDashboardComponent.totaldata[row].stationCodeStatus === 'Y') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'>" + value + "<img title='Emails Available'  src='../../../assets/images/emails-available.png' style='margin-left:5px' alt='Emails available' height='15' width='18'></div>"
                        } else if (AdminHandlerDashboardComponent.totaldata[row].stationCodeStatus === 'N') {
                            return "<div style='margin-top: 8.5px; margin-left: 5px;'>" + value + "<img title='Emails Not available'  src='../../../assets/images/emails-not-available.png' style='margin-left:5px' alt='Emails not available' height='18' width='18'></div>"
                        }
                    }
                }
            },
            { text: 'Reporting Date', datafield: 'incidentDate', width: 100, cellsformat: 'd-MM-yyyy',filtertype: 'date',},
            { text: "Master Airway Bill", datafield: "masterAwbNo", width: 130 },
            { text: 'Responsibility', datafield: 'reportStatus', width: 90 },
            { text: 'Modified Date', datafield: 'modifiedDate', width: 90, },
            { text: 'Modified Time', datafield: 'modifiedTime', width: 90, },  
            { text: 'Created Date and Time', datafield: 'reportCreatedTime', width: 150, },
          
            { text: 'Airport Origin', datafield: 'originStation', width:80 ,
            cellsrenderer: function(row, column, value, data) {
                if(AdminHandlerDashboardComponent.totaldata.length !=0 && (row < AdminHandlerDashboardComponent.totaldata.length)){
                   if(AdminHandlerDashboardComponent.totaldata[row].originStationStatus === 'Y'){
                    return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>"+ value +"</span><img title='Emails Available' style='margin-left:5px'  src='../../../assets/images/emails-available.png' alt='Emails available' height='15' width='18'></div>"
        
                    } else if(AdminHandlerDashboardComponent.totaldata[row].originStationStatus === 'N'){
        
                 return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>"+ value +"</span><img title='Emails Not available'  style='margin-left:5px' src='../../../assets/images/emails-not-available.png' alt='Emails not available' height='18' width='18'></div>"
                      
                   }
                }   
              
                  },
           
       }
            ,
            { text: 'Airport Destination', datafield: 'destStation', width: 80 ,
            cellsrenderer: function(row, column, value, data) {
                if(AdminHandlerDashboardComponent.totaldata.length !=0 && (row < AdminHandlerDashboardComponent.totaldata.length)){
                    if(AdminHandlerDashboardComponent.totaldata[row].destStationStatus === 'Y'){
                     return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>"+ value +"</span><img title='Emails Available' style='margin-left:5px'  src='../../../assets/images/emails-available.png' alt='Emails available' height='15' width='18'></div>"
        
                   } else if(AdminHandlerDashboardComponent.totaldata[row].destStationStatus === 'N'){
        
                 return "<div style='margin-top: 8.5px; margin-left: 5px;'><span  style='font-family: sans-serif;display: inline-block;width: 50px;'>"+ value +"</span><img title='Emails Not available' style='margin-left:5px'  src='../../../assets/images/emails-not-available.png' alt='Emails not available' height='18' width='18'></div>"
                      
                  }
                }   
              
                  },
            
            },
            { text: 'Transport Type', datafield: 'truckType', width: 100 },
            { text: "Flight-Truck Number", datafield: "flightTruckNo", width: 120 },
            { text: 'Report Prepared By', datafield: 'reportPreparedName', width: 120 },
            { text: 'Consignee Name', datafield: 'consignee', width: 120 },
            { text: 'Shipper Name', datafield: 'shipper', width: 120 },
            { text: 'Issuing Agent', datafield: 'issuingAgent', width: 120 },
            { text: "Job Title", datafield: "jobTitle", width: 120 },
            { text: 'Date', datafield: 'flightDate', width: 120, cellsformat: 'd-MM-yyyy',filtertype: 'date'  },
            { text: 'Content Nature', datafield: 'contentNature', width: 150 },
            { text: 'Damage to Packing', datafield: 'damgagePacking', width: 150 },
            { text: "Content Affected", datafield: "contentAffected", width: 120 },
            { text: 'Safety or Life Threat', datafield: 'safetyLife', width: 150 },
            { text: 'Classification', datafield: 'classificationType', width: 350 },
            { text: 'Handling Stage', datafield: 'damageDiscovered', width: 150 },
            { text: "Caused By/Discovered By", datafield: "discoveresCaused", width: 150 },

            //{text: 'Status', datafield: 'status'}  
        ];


    onPageChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }
    onPageSizeChanged(event: any): void {
        let args = event.args;
        let pagenumber = args.pagenum;
        let pagesize = args.pagesize;
    }

    fn_OnTab_Changes(tabName : string , type: string){
        localStorage.setItem('reportsTab', JSON.stringify(tabName));
        localStorage.setItem("ordType",JSON.stringify(type));
        this.spinner.show();
        this.selectedRowsData =[]
        try{
            let logindet = JSON.parse(localStorage.getItem('loginresultobject'));
            if(tabName === 'Internal'){
                this.reportsDataGrid_Ref.source.url =  this.webapi + 'getInternalReportList.php/getInternalReportList?organisationId='+ logindet.orgId +'&loginUserType='+ logindet.userType + '&loginUserId='+ logindet.userId
            }else{ 
            this.reportsDataGrid_Ref.source.url = this.webapi + 'superAdminUserReports.php/getUserReportInfo?organisationId=' + logindet.orgId + "&orderType="+type +"&loginUserType=" + logindet.userType + '&loginUserId=' + logindet.userId;
            }
            this.reportsDataGrid_Ref.myGrid.updatebounddata("refresh") ;
             this.reportsDataGrid_Ref.myGrid.gotopage(0)
             this.reportsDataGrid_Ref.myGrid.clearselection()
             Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA = [];
        }catch(exception){
            this.spinner.hide()
          
        }
     
}



    public selectedRowsData = [] ;
    _onSelectRow(event){ // trigger when checkbox checked / unchecked 
        
        if(Array.isArray(event.args.rowindex)){ 
           if(event.args.rowindex.length !== 0){
            this.selectedRowsData = this.reportsDataGrid_Ref.getDtotalData() ;
           }else{
            this.selectedRowsData = [];
           }
        }else{
            if( event.type === "rowunselect"){
                let index = this.selectedRowsData.findIndex(x => x.reportId === event.args.row.reportId);
                this.selectedRowsData.splice(index, 1);
            }else{
                var ind = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA.findIndex(x => x.reportId === event.args.row.reportId);
                event.args.row.carrierId = Active_Archive_ReportsTableComponent.TOTAL_GRIDDATA[ind].carrierId;
                this.selectedRowsData.push(event.args.row) ; 
            }
        }
          
    }
    
    onClick_Save_Report() {
        const  obj = {
            isFrom : 'CREATE_REPORT'
        };
        this.router.navigate(['save-report'], { queryParams: obj });
      }

      OnClickTruckInspection() {
        this.spinner.show();
        setTimeout(() => {
          localStorage.setItem("currentModule", JSON.stringify("Truckinspection"));
          this.router.navigateByUrl('/Truckinspection/view-truck-inspection');
        }, 200);
      }

      scaleColumnWidth(width:any,scale :number =10){
        return (width / scale)
      }

      onExportClick(): void {
        let deleteColumns = ['actions','uid','boundindex','uniqueid','internalFileName','externalFileName','visibleindex','forwarder_org_id','uldCargoId','uldCargoImages','uld_cargo_pdf','userId','firstName','reportId','orgName','status'];
        
        let reportsArray = [];
        // reportsArray = this.myGrid.getboundrows().map(x => Object.assign({}, x)); //deep cloned array
        reportsArray = this.reportsDataGrid_Ref.myGrid.getrows().map(x => Object.assign({}, x)); //deep cloned array
        let columnsData = this.reportsDataGrid_Ref.myGrid.attrColumns.map(item=>{ return {datafield : item.datafield, text : item.text,width:(item.width && this.scaleColumnWidth(item.width , 6))}}).filter(item=>{
                return (!deleteColumns.includes(item.datafield)) ;
        });  //Excel columns Header And width obj array
        // for (let i = 0; i < reportsArray.length; i++) { 
        //   reportsArray[i]['take_in'] = CsaDashboardComponent.convertUTCDateToLocalDate(reportsArray[i]['take_in']) ; //converting date columns 
        //   let imgTempArray = [];
        //     if(reportsArray[i]['cargoImages'].length !== 0){
        //     for (let j = 0; j < reportsArray[i]['cargoImages'].length; j++) {
        //       imgTempArray.push({
        //         image : this.webapi+(reportsArray[i]['cargoImages'][j]["image_path"].charAt(0) === "/" ?  reportsArray[i]['cargoImages'][j]["image_path"].slice(1)  : reportsArray[i]['cargoImages'][j]["image_path"])  ,
            
        //       })
        //     }
        //   }
        //     reportsArray[i]['Images'] = imgTempArray;
        //   //  columnsData.push("Images");
        //   for (let j = 0; j < deleteColumns.length; j++) { // deleting extra columns from datagrid cloned object
        //       delete  reportsArray[i][deleteColumns[j]];
        //   }
        // }
        this.excelService.exportAsExcelFile(reportsArray,`adminHandlerDashboard_${(new Date()).toString().substring(0,24)}_page_${this.reportsDataGrid_Ref.myGrid.getpaginginformation().pagenum+1}`,['take_in'],columnsData);
      }
    
}

