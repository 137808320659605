import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StationService } from 'src/app/services/station/station.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    VERSION,
} from '@angular/material';
declare var jQuery: any;

import { catchError } from 'rxjs/operators/catchError';
import { Subscription } from 'rxjs/Subscription'
import { UserService } from 'src/app/services/user/user.service';

import { User } from '../../../models/user.model'
import { SuperadminService } from 'src/app/services/superadmin/superadmin.service';

import { Location } from '@angular/common';
import { globalConstants } from 'src/environments/globalConstants';
import { NavbarCargoClaimComponent } from '../../layouts/navbar/navbarCargoClaim.component';
@Component({
    selector: 'app-edituser',
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.css']
})
export class EdituserCargoClaimComponent implements OnInit {
    updateUserForm: FormGroup;
    Logindetails: any;
    stationsListObj: any;
    stationsList: Observable<any[]>;
    stationCode: FormControl;
    obj: { orgId: any; };
    subscription: Subscription;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    userObj: Subscription;
    userId: any;
    userObject: { this: number; };
    viewUserData: any;
    firstName: any;
    orgDetails: any;
    orgDetailsObj: any;
    Object: any;
    companydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: any;
    activateButton: boolean;
    editUserData: any;
    userDetails: any;
    stationCodeTemp: any;
   
    constructor(private location: Location, private navbarComponent: NavbarCargoClaimComponent, private route: ActivatedRoute, private superAdminservice: SuperadminService, private router: Router, private spinner: NgxSpinnerService, private userService: UserService, private formBuilder: FormBuilder, private toastr: ToastrService, private StationsService: StationService) {
        this.stationCode = new FormControl('', [Validators.required]);
     

    }

    ngOnInit() {
        
        window.scroll(0, 0);
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.orgDetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.companydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.editUserData = JSON.parse(localStorage.getItem('editUserData')); // selected user data for edit
      
        if (this.editUserData !== undefined) {
            if (this.editUserData.userStatus === "I") {
                this.activateButton = true;
            } else {
                this.activateButton = false;
            }
        }

        if (this.Logindetails.userType === 'SA') {
            
            this.orgDetailsObj = {
               
                "organizationType": this.orgDetails.orgType,
                "userType": this.Logindetails.userType,
                "organisationName": this.orgDetails.orgName
            }
        } else if (this.Logindetails.userType === 'S') {
            
            this.orgDetailsObj = {
                
                "organizationType": this.Logindetails.orgType,
                "userType": this.Logindetails.userType,
                "organisationName": this.Logindetails.organisationName
            }

        }

        if (this.companydetails === null || this.companydetails === undefined) {
            this.CompanyLogoimgurl = '';
            this.CompanyName = this.Object.organisationName
        } else {
            this.CompanyLogoimgurl = this.orgDetails.orgLogo
            this.CompanyName = this.orgDetails.orgName
        }
        console.log('%c%s', 'color: #eeff04', 'ORGANIZATION DETAILS OBJECT', this.orgDetailsObj);

      
        this.spinner.show();
        this.userObj = this.route.queryParams.subscribe(params => {
            this.userId = params['userId']; // (+) converts string 'id' to a number
            console.log('userId--------', this.userId)
        });
        this.updateUserForm = this.formBuilder.group({
            'firstName': new FormControl(''),
            'lastName': new FormControl(''),
            'loginId': new FormControl('', [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
            'stationCode': new FormControl('""', [Validators.required]),
            'contactNumber': new FormControl("", [Validators.required, Validators.pattern('[1-9]\\d{9}')]),
            'address': new FormControl(''),
            "userId": new FormControl(this.userId),
            "organizationType": new FormControl(this.orgDetailsObj.organizationType),
            "jobTitle": new FormControl(''),
            "dob": new FormControl(''),
            "userType": new FormControl(this.orgDetailsObj.userType),
            "landLine": new FormControl(''),
            "hintQuestion": new FormControl(''),
            "hintAnswer": new FormControl(''),
            "branchId": new FormControl(''),
            "regionId": new FormControl(''),
            "userPrivileges": new FormControl(''),
            "organisationName": new FormControl(this.orgDetailsObj.organisationName),
            "gblUserType": new FormControl(this.Logindetails.userType)
        });


        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        
        this.userService.viewUser(this.userId).subscribe(data => {
            
            console.log(data)
            if (data.responseCode === 1) {
                
                this.userDetails = data.userDetails;
               
                this.updateUserForm = this.formBuilder.group({
                    'firstName': new FormControl(data.userDetails.firstName),
                    'lastName': new FormControl(data.userDetails.lastName),
                    'loginId': new FormControl(data.userDetails.loginId, [Validators.required, Validators.pattern(globalConstants.EMAIL_PATTERN)]),
                    'stationCode': new FormControl(data.userDetails.stationCode, [Validators.required]),
                    'contactNumber': new FormControl(data.userDetails.contactNumber, [Validators.pattern('[1-9]\\d{9}')]),
                    'address': new FormControl(data.userDetails.address),
                    "userId": new FormControl(this.userId),
                    "organizationType": new FormControl(this.orgDetailsObj.organizationType),
                    "jobTitle": new FormControl(''),
                    "dob": new FormControl(''),
                    "userType": new FormControl(this.orgDetailsObj.userType),
                    "landLine": new FormControl(''),
                    "hintQuestion": new FormControl(''),
                    "hintAnswer": new FormControl(''),
                    "branchId": new FormControl('1'),
                    "regionId": new FormControl('1'),
                    "userPrivileges": new FormControl(''),
                    "organisationName": new FormControl(this.orgDetailsObj.organisationName),
                    "gblUserType": new FormControl(this.Logindetails.userType),
                    "modifiedBy": new FormControl(this.Logindetails.userId),
                });
                this.stationCode.setValue(data.userDetails.stationCode);
               
                this.spinner.hide();
                console.log('orgname------****', this.updateUserForm.controls['organisationName'].value)

            } else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
            else {
                this.spinner.hide();
            }
        })

        if (this.Logindetails.userType === 'S') {
            this.obj = {
                orgId: this.Logindetails.orgId
            }
        } else {
            this.obj = {
                orgId: this.orgDetails.orgId
            }
        }

        this.StationsService.newGetStationsCclpPortal(this.obj).subscribe(
            data => {
                
              
                console.log('stations Data', data);
                this.stationsListObj = data.stationDetails
               
                this.stationsList = this.stationCode.valueChanges
                    .pipe(
                        startWith(''),
                        map(state => state ? this.filterStations(state) : this.stationsListObj)
                    );
                this.spinner.hide();
            },
            error => {
                
                console.log(error)
                this.toastr.error('Unable to fetch stations list', '', { timeOut: 1200 })
                this.spinner.hide();
            }
        );
       
        this.spinner.show();

    }

    filterStations(code: string) {
        

      
        console.log(this.stationsListObj);
        return this.stationsListObj.filter(state =>
            state.portCode.toLowerCase().indexOf(code.toLowerCase()) === 0);
    }

    onstationSelect(state): void {
        
        console.log('state', state)
        this.stationCode.setValue(state.portCode);
        this.stationCodeTemp = state.portCode;
        this.stationCode.setErrors(null);
        this.updateUserForm.controls['stationCode'].setValue(this.stationCode.value);
        this.updateUserForm.controls['stationCode'].setErrors(null);
        this.updateUserForm.controls['address'].patchValue(state.address);
        this.updateUserForm.updateValueAndValidity();
    }


    ngAfterViewInit() {
        this._subscribeToClosingActions();
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
        this.spinner.hide();
    }

    private _subscribeToClosingActions(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.trigger.panelClosingActions
            .subscribe(e => {
                
                console.log('eeeeeeeeeee', e);
                if (!e || !e.source) {
                    this.stationCode.setValue(null);
                }
               
            },
                err => this._subscribeToClosingActions(),
                () => this._subscribeToClosingActions());
    }



    updateUser() {
        
        this.spinner.show();
        if (this.stationCode.dirty) {
            if (this.stationCodeTemp !== this.stationCode.value) {
                this.stationCodeTemp = undefined;
                return;
            }
        }
        if (this.stationCode.pristine && this.updateUserForm.pristine) {
            this.toastr.error('No values changed');
            this.spinner.hide();
        } else {
            console.log(this.updateUserForm.value);
           
            if (this.updateUserForm.value.firstName != '' && this.updateUserForm.value.firstName != null) {
                this.updateUserForm.controls['firstName'].patchValue(this.updateUserForm.value.firstName.replace(/[^a-zA-Z\s]*/g, ''));
            }
            if (this.updateUserForm.value.lastName != '' && this.updateUserForm.value.lastName != null) {
                this.updateUserForm.controls['lastName'].patchValue(this.updateUserForm.value.lastName.replace(/[^a-zA-Z\s]*/g, ''));

            }
            this.userService.updateUser(this.updateUserForm.value).subscribe(data => {
                

                console.log('Update user service response', data)
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                   
                    if (this.Logindetails.userType === 'S' && this.editUserData.userType === 'S') {
                        
                        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
                        this.Logindetails.firstName = data.firstName;
                        this.Logindetails.lastName = data.lastName;
                        this.Logindetails.stationCode = data.stationCode;
                        localStorage.setItem('loginresultobject', JSON.stringify(this.Logindetails));
                        if (this.userDetails.loginId !== this.updateUserForm.controls['loginId'].value) {
                            this.navbarComponent.Logout() ;

                        }
                       
                        this.location.back();
                        
                    } else {

                        this.location.back();
                       
                    }
                    

                }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                    this.toastr.info("Your session is expired, please login again");
                    this.spinner.hide()
                } 
                 else {
                    this.toastr.error(data.message);
                    this.spinner.hide()
                }
            })
        }
    }

    // navigation
    backtodashboard() {
       
        this.location.back();
    }


    sendActivatonLink() {
        
        let ActivationData = JSON.parse(localStorage.getItem('ActivationData'));
        console.log('ActivationData', ActivationData);
        jQuery('#myActivateModal').modal('hide');
        jQuery('#myResendLinkModal').modal('hide');
        this.spinner.show();
        this.superAdminservice.ActivateResentLink(ActivationData).subscribe(data => {
            
            this.spinner.hide();
            if (data.responseCode === 1) {
                
                console.log(data);
                this.toastr.success(data.message, '', { timeOut: 1200 })
                if (data.message === "User activated successfully") {
                 
                    this.activateButton = false;
                }
            }else if(data.responseCode === 0 && data.message === 'Unauthorized User'){
                this.toastr.info("Your session is expired, please login again");
            } 
             else {
                
                this.toastr.error(data.message, '', { timeOut: 1200 })
            }

        }, error => {
            this.toastr.error(error.message, '', { timeOut: 1200 })
          
            this.spinner.hide();

        });
    }

  

    Activate(event) {
        

        var ActivationData = {
            userId: this.editUserData.userId,
            type: event,
            roleType: this.Logindetails.userType
        }
        localStorage.setItem("ActivationData", JSON.stringify(ActivationData));
        if (event === 'A') {
            jQuery('#myActivateModal').modal({ backdrop: 'static', keyboard: false });
        } else if (event === "R") {
            jQuery('#myResendLinkModal').modal({ backdrop: 'static', keyboard: false });
        }
    }


    
}
