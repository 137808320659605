import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { ConfigServiceService } from '../../config-service.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
webAPI :string;
  constructor(private http:HttpClient,config : ConfigServiceService) { 
    this.webAPI =    config.getConfig().URL
  }


  servc_To_Refresh_Reports(userId){
    return this.http.get<any>(this.webAPI +'airlineRefresh.php/airlineSingleRefresh?userId='+userId);
  }
}
