import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ClaimCountService } from "../../services/claim-count.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";

@Component({
    selector: 'app-compare-root-cause',
    templateUrl: './compare-root-cause.component.html',
    styleUrls: ['./compare-root-cause.component.css']
})

export class CompareRootCauseComponent implements OnInit {

    originstation: any = [];
    OriginyearOnecount: any = [];
    OriginyearTwocount: any = [];
    destoriginstation: any = [];
    destOriginyearOnecount: any = [];
    destOriginyearTwocount: any = [];
    originstationFormaml: any = [];
    OriginyearOnecountFormaml: any = [];
    OriginyearTwocountFormaml: any = [];
    destoriginstationFormaml: any = [];
    destOriginyearOnecountFormaml: any = [];
    destOriginyearTwocountFormaml: any = [];
    inputObj: any;
    loginDetails: any;
    orgId: any;
    iregularityDescription: any = [];
    irregularityCount: any = [];
    origincountcardYearOne: any;
    origincountcardYearTwo: any;
    destcountcardYearOne: any;
    destcountcardYearTwo: any;
    orgcountconclusionyearone: any;
    orgcountconclusionyeartwo: any;
    descountconclusionyearone: any;
    descountconclusionyeartwo: any;
    scrollable: boolean;
    scrollable1: boolean;
    scrollable2: boolean;
    scrollable3: boolean;
    YearOne: any;
    YearTwo: any;
    canvaslineOnewidth: number;
    canvaslinewidth: number;
    canvaslineoriginFormalwidth: number;
    canvaslinedestFormalwidth: number;
    height: number;
    constructor(
        private claimcountservice: ClaimCountService,
        private toaster: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }
    ngOnInit() {
        this.inputObj = JSON.parse(localStorage.getItem("inputObj"));

     
        this.loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        this.orgId = this.loginDetails.orgId;
        if (this.inputObj.yearOne && this.inputObj.yearTwo) {
            //Irregularity Api integartion starts here
            this.spinner.show();
            this.claimcountservice.compare_irregularuty_count(this.inputObj).subscribe(
                (data) => {
                    this.spinner.hide();
                    if (data.responseCode === 1) {
                        this.YearOne = data.yearOne;
                        this.YearTwo = data.yearTwo
                        this.iregularityDescription = data.iregularityDescription.description;
                        this.irregularityCount = data.irregularityCount;
                        for (let i = 0; i < this.irregularityCount.length; i++) {
                            if (this.irregularityCount[i].Origin === "Origin") {
                                this.origincountcardYearOne = this.irregularityCount[i].yearOneCount;
                                this.origincountcardYearTwo = this.irregularityCount[i].yearTwoCount;
                            } else {
                                this.destcountcardYearOne = this.irregularityCount[i].yearOneCount;
                                this.destcountcardYearTwo = this.irregularityCount[i].yearTwoCount;
                            }
                        }

                        //dynamic scroll start here
                        let labelLengthirregularOrg = data.irregularityOrigin.labels.length;
                        let labelLengthirregularDest = data.irregularityDestn.labels.length;

                        if (labelLengthirregularOrg > 60) {
                            this.scrollable = true
                        }
                        else {
                            this.scrollable = false
                        }
                        if (labelLengthirregularDest > 60) {
                            this.scrollable1 = true
                        }
                        else {
                            this.scrollable1 = false
                        }
                        this.originstation = data.irregularityOrigin.labels,
                            this.canvaslinewidth = this.getWidth(data.irregularityOrigin.labels.length);
                        this.OriginyearOnecount = data.irregularityOrigin.yearOneCount,
                            this.OriginyearTwocount = data.irregularityOrigin.yearTwoCount,
                            setTimeout(() => {

                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvasline"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.originstation,
                                        datasets: [
                                            {
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.OriginyearOnecount,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.OriginyearTwocount,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        title: {
                                            display: true,
                                            //text: "Origin Claim Count"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                color: "#000000",
                                                anchor: "end",
                                                align: "top",
                                                rotation: -90,
                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },

                                        scales: {
                                            xAxes: [
                                                {
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Irregularity per station",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                        // max rotation: -90,
                                                        // min rotation: -90
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },
                                            ],
                                        },
                                    },
                                }
                            );


                        this.destoriginstation = data.irregularityDestn.labels,
                            this.canvaslineOnewidth = this.getWidth(data.irregularityDestn.labels.length);
                        this.destOriginyearOnecount = data.irregularityDestn.yearOneCount,
                            this.destOriginyearTwocount = data.irregularityDestn.yearTwoCount,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslineOne"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstation,
                                        datasets: [
                                            {
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.destOriginyearOnecount,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.destOriginyearTwocount,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim amount"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Irregularity per station",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,
                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );
                        }, 500);
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.toaster.warning("your session expired please login again");
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            )
            //Irregularity Api integartion ends here

            //investigation Api integartion starts here

            this.claimcountservice.compare_investigation_count(this.inputObj).subscribe(
                (data) => {
                    if (data.responseCode === 1) {
                        this.orgcountconclusionyearone = data.totalinvestigationCount.org_count_conclusion_yearone;
                        this.orgcountconclusionyeartwo = data.totalinvestigationCount.org_count_conclusion_yeartwo;
                        this.descountconclusionyearone = data.totalinvestigationCount.des_count_conclusion_yearone;
                        this.descountconclusionyeartwo = data.totalinvestigationCount.des_count_conclusion_yeartwo;
                        this.originstationFormaml = data.investigationOrigin.labels,
                            this.canvaslineoriginFormalwidth = this.getWidth(data.investigationOrigin.labels.length);
                        this.OriginyearOnecountFormaml = data.investigationOrigin.yearOneCount,
                            this.OriginyearTwocountFormaml = data.investigationOrigin.yearTwoCount;
                        //dymanic scroll start here
                        let labelLengthinvestOrg = data.investigationOrigin.labels.length;
                        let labelLengthinvestDest = data.investigationDestn.labels.length;

                        if (labelLengthinvestOrg > 60) {
                            this.scrollable2 = true
                        }
                        else {
                            this.scrollable2 = false
                        }
                        if (labelLengthinvestDest > 60) {
                            this.scrollable3 = true
                        }
                        else {
                            this.scrollable3 = false
                        }
                        new Chart(
                            <HTMLCanvasElement>document.getElementById("canvaslineoriginFormal"),
                            {
                                type: "bar",
                                plugins: [ChartDataLabels],
                                data: {
                                    labels: this.originstationFormaml,
                                    datasets: [
                                        {
                                            // type: "line",
                                            borderColor: "rgba(0,0,255,1)",
                                            backgroundColor: "rgba(0,0,255,1)",
                                            hoverBorderColor: "rgba(0,0,255,1)",
                                            // yAxisID: "y-axis-0",
                                            data: this.OriginyearOnecountFormaml,
                                            fill: false,
                                            label: data.yearOne,
                                            stack: "a",
                                        },
                                        {
                                            // type: "bar",
                                            borderColor: "rgba(255,165,0,1)",
                                            backgroundColor: "rgba(255,165,0,1)",
                                            hoverBackgroundColor: "rgba(255,165,0,1)",
                                            // yAxisID: "y-axis-1",
                                            data: this.OriginyearTwocountFormaml,
                                            fill: false,
                                            label: data.yearTwo,
                                            stack: "b",
                                        },
                                    ],
                                },
                                options: {
                                    maintainAspectRatio: false,
                                    tooltips: {

                                    },
                                    title: {
                                        display: true,
                                        //text: "Paid amount vs Claim amount"
                                    },
                                    plugins: {
                                        // Change options for ALL labels of THIS CHART
                                        datalabels: {
                                            display: true,
                                            color: "#000000",
                                            anchor: "center",
                                            align: "top",
                                            rotation: -90,

                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                    },
                                    scales: {
                                        xAxes: [
                                            {
                                                gridLines: {
                                                    drawOnChartArea: false,
                                                },
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Conclusions per station",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    autoSkip: false,
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                gridLines: {
                                                    drawOnChartArea: false,
                                                },
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: "Count",
                                                    fontSize: 16,
                                                    fontColor: "#000000",
                                                    fontStyle: "bold",
                                                },
                                                ticks: {
                                                    beginAtZero: true,
                                                    //lineHeight:20,
                                                    // max:plugin.settings.maxDataValue,
                                                    maxTicksLimit: 10,
                                                    // reverse:true,

                                                },
                                                id: "y-axis-0",
                                                position: "left",
                                            },

                                        ],
                                    },
                                },
                            }
                        );


                        this.destoriginstationFormaml = data.investigationDestn.labels,
                            this.canvaslinedestFormalwidth = this.getWidth(data.investigationDestn.labels.length);
                        console.log('this.canvaslinedestFormalwidth', this.canvaslinedestFormalwidth)
                        this.destOriginyearOnecountFormaml = data.investigationDestn.yearOneCount,
                            this.destOriginyearTwocountFormaml = data.investigationDestn.yearTwoCount,
                            new Chart(
                                <HTMLCanvasElement>document.getElementById("canvaslinedestFormal"),
                                {
                                    type: "bar",
                                    plugins: [ChartDataLabels],
                                    data: {
                                        labels: this.destoriginstationFormaml,
                                        datasets: [
                                            {
                                                // type: "line",
                                                borderColor: "rgba(0,0,255,1)",
                                                backgroundColor: "rgba(0,0,255,1)",
                                                hoverBorderColor: "rgba(0,0,255,1)",
                                                // yAxisID: "y-axis-0",
                                                data: this.destOriginyearOnecountFormaml,
                                                fill: false,
                                                label: data.yearOne,
                                                stack: "a",
                                            },
                                            {
                                                // type: "bar",
                                                borderColor: "rgba(255,165,0,1)",
                                                backgroundColor: "rgba(255,165,0,1)",
                                                hoverBackgroundColor: "rgba(255,165,0,1)",
                                                // yAxisID: "y-axis-1",
                                                data: this.destOriginyearTwocountFormaml,
                                                fill: false,
                                                label: data.yearTwo,
                                                stack: "b",
                                            },
                                        ],
                                    },
                                    options: {
                                        maintainAspectRatio: false,
                                        tooltips: {

                                        },
                                        title: {
                                            display: true,
                                            //text: "Paid amount vs Claim amount"
                                        },
                                        plugins: {
                                            // Change options for ALL labels of THIS CHART
                                            datalabels: {
                                                display: true,
                                                color: "#000000",
                                                anchor: "center",
                                                align: "top",
                                                rotation: -90,

                                            },
                                        },
                                        legend: {
                                            display: true,
                                            position: "bottom",
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Conclusions per station",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        drawOnChartArea: false,
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: "Count",
                                                        fontSize: 16,
                                                        fontColor: "#000000",
                                                        fontStyle: "bold",
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        //lineHeight:20,
                                                        // max:plugin.settings.maxDataValue,
                                                        maxTicksLimit: 10,
                                                        // reverse:true,

                                                    },
                                                    id: "y-axis-0",
                                                    position: "left",
                                                },

                                            ],
                                        },
                                    },
                                }
                            );
                            
                        // this.scrollView();
                    }
                    else if (data.responseCode === 0 && data.message === 'Unauthorized User') {
                        this.router.navigateByUrl("/");
                    }
                    else {
                        this.toaster.error(data.message);
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                }
            )
            //investigation Api integartion ends here
        }

    }

    scrollView() {
        setTimeout(() => {
            const elements = document.getElementsByClassName('chartAreaWrapper_comparescroll60');
            for (let ele of elements as any) {
                ele.scrollTop = ele.scrollHeight;
            }
        }, 2000);

    }

    getWidth(number) {
        this.height = 420;
        if (number < 10) {
            let finalWidth = 230 * number;
            return finalWidth;
        } else {
            let finalWidth = 60 * number;
            return finalWidth;
        }

    }


}
